import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { setClientStorageValue } from '../../components/sections/owners-osb/osb-utils';
import {
    ServiceLocationDetails,
    ServiceLocationDetailsAction,
} from '../../models/osb-model/osb-service-location';

export const serviceLocationReducer = (
    state: ServiceLocationDetails,
    action: ServiceLocationDetailsAction
): ServiceLocationDetails => {
    let newState = null;
    switch (action.type) {
        case 'SET':
            newState = { ...state, ...action.payload };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_LOCATION_STEP_KEY,
                newState,
                action.localStorageExpiry || 0
            );
            return newState;
        default:
            return state;
    }
};
