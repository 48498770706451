export interface ConnectedVehicleResponse {
    vehiclestatus: Vehiclestatus;
    error?: ErrorBlock;
}

interface ErrorBlock {
    status?: string;
    code?: string;
    message?: string;
}
export interface Vehiclestatus {
    vin: string;
    uomDistance: UomDistance;
    lockStatus: StringStatus;
    alarm: StringStatus;
    odometer: NumberStatus;
    fuel: Fuel;
    gps: Gps;
    gpsState: string;
    remoteStart: RemoteStart;
    battery: Battery;
    oil: Oil;
    tirePressure: StringStatus;
    authorization: string;
    firmwareUpgInProgress: FirmwareUpgInProgress;
    deepSleepInProgress: FirmwareUpgInProgress;
    ccsSettings: CcsSettings;
    lastRefresh: string;
    lastModifiedDate: string;
    serverTime: string;
    batteryFillLevel: BatteryFillLevel;
    elVehDTE: NumberStatus;
    hybridModeStatus: StringStatus;
    chargingStatus: StringStatus;
    plugStatus: NumberStatus;
    chargeStartTime: StringStatus;
    chargeEndTime: StringStatus;
    preCondStatusDsply: StringStatus;
    chargerPowertype?: any;
    batteryPerfStatus: StringStatus;
    outandAbout: StringStatus;
    batteryChargeStatus: StringStatus;
    dcFastChargeData: DcFastChargeData;
    windowPosition: WindowPosition;
    doorStatus: DoorStatus;
    ignitionStatus: StringStatus;
    batteryTracLowChargeThreshold: StringStatus;
    battTracLoSocDDsply: StringStatus;
    trailerLightCheckStatus?: any;
    prmtAlarmEvent: StringStatus;
    remoteStartStatus: NumberStatus;
    TPMS: TPMS;
    ZoneLighting: ZoneLighting;
}

interface DoorStatus {
    rightRearDoor: StringStatus;
    leftRearDoor: StringStatus;
    driverDoor: StringStatus;
    passengerDoor: StringStatus;
    hoodDoor: StringStatus;
    tailgateDoor: StringStatus;
    innerTailgateDoor: StringStatus;
}

interface WindowPosition {
    driverWindowPosition: StringStatus;
    pasWindowPosition: StringStatus;
    rearDriverWindowPos: StringStatus;
    rearPassWindowPos: StringStatus;
}

export interface BatteryFillLevel {
    value: number;
    status: string;
    timestamp: string;
    quarterRange: string;
}

interface CcsSettings {
    timestamp: string;
    location: number;
    vehicleConnectivity: number;
    vehicleData: number;
    drivingCharacteristics: number;
    contacts: number;
}

interface ZoneLighting {
    activationData: ActivationData;
    zoneStatusData: ZoneStatusData;
    lightSwitchStatusData: LightSwitchStatusData;
    zoneLightingFaultStatus: StringStatus;
}

interface LightSwitchStatusData {
    frontFogLamps: StringStatus;
    frontHighBeams: StringStatus;
    frontParkLamp: StringStatus;
    rearBedLamps: StringStatus;
    rearReverseLamps: StringStatus;
    rearLicensePlateLamps: StringStatus;
    rearTrailerAssistLamps: StringStatus;
    leftRightPuddleLamp: StringStatus;
    leftSpotLight: StringStatus;
    rightSpotLight: StringStatus;
    rearCargoLights: StringStatus;
}

interface ZoneStatusData {
    front: StringStatus;
    right: StringStatus;
    left: StringStatus;
    rear: StringStatus;
}

interface ActivationData {
    value?: any;
    status: string;
    timestamp: string;
}

interface FirmwareUpgInProgress {
    value: boolean;
    status: string;
    timestamp: string;
}

export interface TPMS {
    tirePressureByLocation: NumberStatus;
    tirePressureSystemStatus: StringStatus;
    dualRearWheel: NumberStatus;
    leftFrontTireStatus: StringStatus;
    leftFrontTirePressure: StringStatus;
    rightFrontTireStatus: StringStatus;
    rightFrontTirePressure: StringStatus;
    outerLeftRearTireStatus: StringStatus;
    outerLeftRearTirePressure: StringStatus;
    outerRightRearTireStatus: StringStatus;
    outerRightRearTirePressure: StringStatus;
    innerLeftRearTireStatus: StringStatus;
    innerLeftRearTirePressure: StringStatus;
    innerRightRearTireStatus: StringStatus;
    innerRightRearTirePressure: StringStatus;
    recommendedFrontTirePressure: NumberStatus;
    recommendedRearTirePressure: NumberStatus;
    uomPressure: StringStatus;
}

export interface Oil {
    oilLife: string;
    oilLifeActual: number;
    status: string;
    timestamp: string;
}

export interface Battery {
    batteryHealth: StringStatus;
    batteryStatusActual: NumberStatus;
}

interface RemoteStart {
    remoteStartDuration: number;
    remoteStartTime: number;
    status: string;
    timestamp: string;
}

interface Gps {
    latitude?: any;
    longitude?: any;
    status: string;
    timestamp: string;
}

export interface Fuel {
    fuelLevel: number;
    distanceToEmpty: number;
    status: string;
    timestamp: string;
    quarterRange: string;
}

export interface NumberStatus {
    value: number;
    status: string;
    timestamp: string;
}

export interface StringStatus {
    value: string;
    status: string;
    timestamp: string;
}

interface DcFastChargeData {
    fstChrgBulkTEst: StringStatus;
    fstChrgCmpltTEst: StringStatus;
}

export enum UomDistance {
    Miles = 1,
    Kilometers = 2,
}
