import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './error-display.scss';

interface ErrorDisplayProps {
    icon?: React.ReactNode;
    title: string;
    errorMessage: string;
    suggestion: string;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
    icon = '⚠️',
    title,
    errorMessage,
    suggestion,
}) => {
    const [visible, setVisible] = useState(true);

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div
            className="osb-v3-vehicle-info-error-container"
            data-testid="error-display"
        >
            <button className="close-button" onClick={handleClose}>
                &times;
            </button>
            <div className="error-icon">{icon}</div>
            <div className="error-message">
                <h2>{title}</h2>
                <p>{errorMessage}</p>
                <p>{suggestion}</p>
            </div>
        </div>
    );
};

ErrorDisplay.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    suggestion: PropTypes.string.isRequired,
};

export default ErrorDisplay;
