import React, { useContext, useEffect } from 'react';
import { Accordion, PrimaryButton } from '../../../../common';
import { ActivityIndicator } from '../../../../common/activity-indicator/activity-indicator';
import { UteListData } from './../../components/ute-list-data/ute-list-data';
import { UteContext } from '../../../../../views/ultimate-towing-experience-view/context/ute-context';
import { useContentFragment } from '../../../../utils/aem-content-utils/use-content-fragment';
import { ViewTrailerProfileContentProps } from '../../../../../models/service-maintenance-models/ute';
import './ute-view-trailer-profile.ford.scss';
export interface Props {
    profileContent: any;
    handleBreadCrumb: (
        currentPage: string,
        pageTitle: string,
        isPopup: boolean
    ) => void;
}
export const UteViewTrailerProfile = (props: Props) => {
    const viewTrailerProfileContent: ViewTrailerProfileContentProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/view-trailer-profile'
    );
    const { uteContextFormData } = useContext(UteContext);
    const trailerProfileData = uteContextFormData['profileDetails'];
    const {
        basicInfoLabel,
        trailerNickNameLabel,
        trailerMileageLabel,
        manufacturerNameLabel,
        makeLabel,
        modelLabel,
        yearLabel,
        moreInformationLabel,
        trailerType,
        brakeType,
        trailerLength,
        trailerWidth,
        trailerHeight,
        trailerWeightUnloaded,
        EasLabel,
        editCTA,
        conventionalLabel,
        fifthWheelLabel,
        gooseNeckLabel,
        electricLabel,
        hydraulicLabel,
        defaultLabel,
        unitFeet,
        unitPound,
        unitMileage,
    } = viewTrailerProfileContent;
    const accordionTypes = [basicInfoLabel, moreInformationLabel];
    const getTrailerType = (trailerType: string) => {
        switch (trailerType) {
            case '1':
                return conventionalLabel;
            case '2':
                return fifthWheelLabel;
            case '3':
                return gooseNeckLabel;
        }
    };
    const getBrakeType = (trailerType: string) => {
        switch (trailerType) {
            case '0':
                return defaultLabel;
            case '1':
                return electricLabel;
            case '2':
                return hydraulicLabel;
        }
    };
    const viewTrailerDetails = (key: number) => {
        let trailerData: any;
        if (key === 0) {
            trailerData = [
                {
                    label: trailerNickNameLabel,
                    value: trailerProfileData.name,
                },
                {
                    label: manufacturerNameLabel,
                    value: trailerProfileData.mfgName,
                },
                {
                    label: makeLabel,
                    value: trailerProfileData.mfgMake,
                },
                {
                    label: modelLabel,
                    value: trailerProfileData.mfgModel,
                },
                {
                    label: yearLabel,
                    value: trailerProfileData.mfgYear,
                },
            ];
        } else if (key === 1) {
            trailerData = [
                {
                    label: trailerType,
                    value: getTrailerType(trailerProfileData.type),
                },
                {
                    label: brakeType,
                    value: getBrakeType(trailerProfileData.brakeType),
                },
                {
                    label: trailerLength,
                    value: trailerProfileData.smartNavLength + ' ' + unitFeet,
                },
                {
                    label: trailerWidth,
                    value: trailerProfileData.smartNavWidth + ' ' + unitFeet,
                },
                {
                    label: trailerHeight,
                    value: trailerProfileData.smartNavHeight + ' ' + unitFeet,
                },
                {
                    label: trailerWeightUnloaded,
                    value: trailerProfileData.smartNavWeight + ' ' + unitPound,
                },
                {
                    label: trailerMileageLabel,
                    value: trailerProfileData.distance + ' ' + unitMileage,
                },
            ];
        }

        return (
            <>
                <UteListData items={trailerData} editCTA={editCTA} />
            </>
        );
    };
    useEffect(() => {
        props.handleBreadCrumb(
            trailerProfileData?.name,
            props.profileContent.pageTitle,
            false
        );
    }, [uteContextFormData['profileDetails']]);
    return (
        <>
            {!viewTrailerProfileContent ? (
                <ActivityIndicator />
            ) : (
                <section className="ute-view-trailer-profile-section">
                    <section className="ute-heading-section">
                        <h1 className="ute-header">
                            {trailerProfileData?.name?.toUpperCase()}
                        </h1>
                    </section>
                    <div className="ute-view-trailer-profile">
                        {trailerProfileData &&
                            accordionTypes?.map((index, key) => (
                                <Accordion
                                    expandPanel={true}
                                    key={key}
                                    header={accordionTypes[key]}
                                    panel={viewTrailerDetails(key)}
                                    className={'ute-view-profile-accordion'}
                                    index={key.toString()}
                                    expandMultiplePanels={true}
                                    borderBottom={true}
                                    accordionName={accordionTypes[key]}
                                />
                            ))}
                    </div>
                    <div className={'button-section'}>
                        <div className="edit-btn">
                            <div className="eas-Label">{EasLabel}</div>
                            <PrimaryButton
                                color="dark"
                                fill="fill"
                                disabled={true}
                                chevron={false}
                                ariaLabel={editCTA}
                            >
                                {editCTA}
                            </PrimaryButton>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};
