import React from 'react';
import { useHistory } from 'react-router-dom';
import FMActionItem from '../../common/fm-action-item/fm-action-item';
import { ActionItemProps } from '../../../models/experiencefragments/action-item';

const FMActionItemYFA = (props: ActionItemProps) => {
    const { content, requiredLabel, recommendedLabel } = props;

    const history = useHistory();

    const onClick = () => {
        history.push('/yfa-optin', {
            vin: sessionStorage.getItem('selectedVin'),
        });
    };

    return (
        <FMActionItem
            required={false}
            content={content}
            requiredLabel={requiredLabel}
            recommendedLabel={recommendedLabel}
            onClick={onClick}
        />
    );
};

export default FMActionItemYFA;
