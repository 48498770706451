import { useEffect } from 'react';
import {
    OsbPathBookingRetrieveStep,
    OsbPathDealerStep,
    OsbPathStartStep,
} from '../../components/sections/owners-osb/osb-controller';
import {
    isAuthenticationActive,
    isClientStorageExpired,
} from '../../components/sections/owners-osb/osb-utils';
import { useOSBStep } from './use-osb-step';
import ServerSideService from '../../services/server-side-service/server-side-service';

export const usePageRefresh = () => {
    const { osbStep } = useOSBStep();
    const alertUser = (e: any) => {
        // should not show leave page pop up, if authentication is not active.
        // below condition prevents, pop up, when navigate to FMA login screen
        if (
            !osbStep.isAuthenticatedFlow ||
            (osbStep.isAuthenticatedFlow && isAuthenticationActive(osbStep))
        ) {
            e.preventDefault();
            e.returnValue = '';
        } else {
            window.removeEventListener('beforeunload', alertUser);
        }
    };
    useEffect(() => {
        const pathname = window.location.pathname;
        const queryParams = ServerSideService.isClientSide()
            ? filterXSS(window.location.search)
            : null;
        // not registering 'beforeunload' event in dealer step.
        // But called usePageRefresh() in dealerfilow to not showing pop up when dealer page refresh and navigate to service flow
        if (!pathname.includes(OsbPathDealerStep())) {
            window.addEventListener('beforeunload', alertUser);
        }
        if (window.performance.navigation.type === 1) {
            if (pathname.includes('/retrieve-booking-summary')) {
                if (isClientStorageExpired()) {
                    window.location.href =
                        pathname.split(OsbPathStartStep())[0] +
                        OsbPathBookingRetrieveStep();
                }
            } else {
                window.location.href =
                    pathname.split(OsbPathStartStep())[0] +
                    OsbPathStartStep() +
                    queryParams;
            }
        }

        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    }, []);
};
