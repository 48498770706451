import { useContent } from '../../../../hooks/use-server-content';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { getVehicleDataSelector } from '../../../../services/content-service/content-service-util';

export interface SearchContentProps {
    hide: boolean;
    hideInput: boolean;
    searchTitle: string;
    searchBarHelperText: string;
    searchLink: string;
    searchBackgroundImageDesktop: string;
    searchBackgroundImageMobile: string;
    ctaTargetBlank: boolean;
    searchBarAriaLabel: string;
    ulAriaLabel: string;
    searchButtonAriaLabel: string;
}
export const useSearchContent = (
    vehicleAttributes?: VehicleAttributes
): SearchContentProps | null => {
    const [content, getValueByName] = useContent(
        'vehicle-data',
        getVehicleDataSelector('search', vehicleAttributes),
        undefined,
        true
    );

    let contentFragment: any = {};
    content?.elements?.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.searchLink) {
            contentFragment = null;
        }
    }
    return contentFragment as SearchContentProps;
};
