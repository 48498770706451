import { HeroArticle } from '../hero-article/hero-article';
import React from 'react';
import './article-container.scss';
import { SubCategoryViewContent } from '../../../../../models/experiencefragments/category-view';
import { PopularLinksSection } from '../popular-links-section/popular-links-section';
import { LatestArticles } from '../latest-articles/latest-articles';

interface Props {
    categoryPath?: string;
    eventName?: string;
    currentVersion?: boolean;
}

export const ArticleContainer = (props: SubCategoryViewContent & Props) => {
    return (
        <div className="sub-category-articles-container">
            <div className="item-1">
                {props.heroArticle && (
                    <HeroArticle
                        content={props?.heroArticle}
                        class={!props.currentVersion ? 'preimagine' : ''}
                    />
                )}
            </div>
            <div className="item-2">
                {props.latestArticles && (
                    <LatestArticles
                        latestArticlesContent={props.latestArticles}
                        componentBox5050Content={props.componentBox5050}
                        categoryPath={props.categoryPath}
                        eventName={props?.eventName}
                        class={!props.currentVersion ? 'preimagine' : ''}
                    />
                )}
            </div>
            {props.popularLinksSection && !props.popularLinksSection.hide && (
                <PopularLinksSection
                    content={props.popularLinksSection}
                    categoryType={'subcategory'}
                    categoryPath={props.categoryPath}
                />
            )}
        </div>
    );
};
