import { VehicleData } from '../../models/vehicle-data';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { StringUtil } from '../../components/utils/string-util/string-util';
import { VehicleListServiceAem } from '../vehicle-list-service-aem/vehicle-list-service-aem';

export class ModelYearVehicleService {
    private readonly make: string;
    private readonly model: string;
    private readonly year: number;

    appConfig = new AppConfigurationService();

    constructor(model: string, year: number, make?: string) {
        this.model = model;
        this.year = year;
        this.make = !make
            ? (this.make =
                  this.appConfig.brand === 'demo'
                      ? 'Ford'
                      : StringUtil.capitalizeFirstLetter(this.appConfig.brand))
            : make;
    }

    async request(): Promise<VehicleData> {
        const { currentRegionCode, currentLanguageRegionCode } = this.appConfig;

        const service = new VehicleListServiceAem();
        return service
            .getVehicles(
                this.make,
                currentRegionCode,
                currentLanguageRegionCode
            )
            .then(
                async (): Promise<VehicleData> => {
                    return {
                        recalls: {},
                        ownersManual: {
                            link: `https://owner.ford.com/tools/account/how-tos/owner-manuals.html?year=${this.year}&make=${this.make}&model=${this.model}`,
                        },
                        modelYear: this.year,
                        modelName: this.model,
                        vehicleMake: this.make,
                    };
                }
            );
    }
}
