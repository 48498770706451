import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { DealerStep } from '../../models/osb-model/osb-dealer-step';
import {
    StoreState,
    DealerStepState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { DealerProfileInfo } from '../../models/osb-model/osb-dealer-info';
import { FilterServicesInfo } from '../../models/osb-model/osb-dealerservice-info';
import {
    DEALER_STEP_KEYS,
    OSB_CLIENT_STORAGE_KEYS,
} from '../../components/sections/owners-osb/osb-constant';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { useOSBStep } from './use-osb-step';

export const getInitialDealerState = (): DealerStep => {
    return {
        geocoordinate: [],
        searchDistanceIndex: 0,
        groupDealerCode: '',
        isDealerSelected: false,
        //allOSBDealers: [],
        dealerProfiles: [],
        filteredDealerProfiles: [],
        selectedFilterServices: [],
        selectedDealerName: '',
        osbEnabledDealerFromBing: [],
        selectedLocation: '',
        dealerDetailsServiceError: '',
        selectedDealerProfile: {
            dealerCode: '',
            dealerName: '',
            street: '',
            town: '',
            postalCode: '',
            openingHours: {
                monday: '',
                tuesday: '',
                wednesday: '',
                thursday: '',
                friday: '',
                saturday: '',
                sunday: '',
            },
            customerRating: 0,
            customerRespondentsService: 0,
            distance: 0,
            primaryPhone: '',
            location: {
                lat: 0,
                lng: 0,
            },
            dealerType: '',
            additionalInfo: {
                earliestAvailableTimeslot: '',
                dealerFilterServiceAvailability: [],
                nextAppointmentDateAm: '',
                nextAppointmentDatePm: '',
                nextAppointmentDateWeekend: '',
            },
            specialServices: [],
            serviceDepartmentCV: false,
            serviceDepartmentPV: false,
            electricService: false,
        },
        filterDeliveryServices: [],
        filterDidServices: [],
        filterRegularServices: [],
        selectedSortOption: '',
        selectedDealerId: '',
        searchDepth: '',
        searchLocationType: '',
        errorDescription: '',
        isDealerFilterOptionsEnabled: false,
        hasMobileService: false,
        isDealerHasDeliveryServices: true,
        searchType: DEALER_STEP_KEYS.LOCATION,
    };
};
//Configure and initialize dealer step state in global store
const configureDealerStepStore = () => {
    const actions = {
        //Action to set the dealer step details to global store
        SET_DEALER_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as DealerStepState).dealerStep,
                ...(payload as DealerStep),
            };

            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_DEALER_STEP_KEY,
                newState,
                localStorageExpiry
            );

            return { dealerStep: newState };
        },

        SET_DEALER_STEP_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as DealerStepState).dealerStep,
                ...(payload as {
                    [key: string]:
                        | string
                        | DealerProfileInfo
                        | number
                        | boolean
                        | []
                        | {}
                        | FilterServicesInfo[];
                }),
            };

            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_DEALER_STEP_KEY,
                newState,
                localStorageExpiry
            );

            return { dealerStep: newState };
        },
    };
    initializeStore(actions, {
        dealerStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_DEALER_STEP_KEY
            ) || getInitialDealerState(),
    });
};

configureDealerStepStore();

// custom hook for dealer step to handle the actions and updating payload in global store
export const useDealerStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalDealerStep = (
        dealerStep: DealerStep,
        shouldReload = true
    ) => {
        dispatch(
            'SET_DEALER_STEP',
            dealerStep,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const setGlobalDealerStepPayload = (
        payload: {
            [key: string]:
                | string
                | DealerProfileInfo
                | number
                | boolean
                | []
                | {}
                | FilterServicesInfo[];
        },
        shouldReload = true
    ) => {
        dispatch(
            'SET_DEALER_STEP_PAYLOAD',
            payload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetDealerStep = (shouldReload = true) => {
        dispatch(
            'SET_DEALER_STEP',
            getInitialDealerState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbDealerStep: (globalState as DealerStepState).dealerStep,
        setOSBDealerStep: setGlobalDealerStep,
        setOSBDealerStepPayload: setGlobalDealerStepPayload,
        resetDealerStep: resetDealerStep,
        callContentService: callContentService,
    };
};
