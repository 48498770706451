import ConnectedVehicle, {
    HeaderType,
} from '../connected-vehicle/connected-vehicle';
import { OilLifeContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import React from 'react';
import './connected-vehicle-oil-life.scss';
import { Oil, Vehiclestatus } from '../../../models/connected-vehicle';
interface Props {
    vin: string;
    connectedVehicleInfo: Vehiclestatus;
    isBev: boolean;
}

const ConnectedVehicleOilLife = (props: Props & OilLifeContent) => {
    const oil: Oil = props.connectedVehicleInfo.oil;
    const { isBev } = props;
    const headerType = () => {
        let headerType: HeaderType = 'none';
        if (oil && oil.oilLife === 'STATUS_LOW') {
            headerType = 'moderate';
        }
        return headerType;
    };
    return (
        <>
            {oil && !isBev && (
                <ConnectedVehicle
                    className={'oil-life'}
                    header={props.title}
                    headerType={headerType()}
                    iconPath={props.icon}
                    number={oil.oilLifeActual + '%'}
                />
            )}
        </>
    );
};
export default ConnectedVehicleOilLife;
