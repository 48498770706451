import React, { useCallback, useEffect, useState } from 'react';
import './login.scss';
import {
    SignIn,
    SignInAuthItem,
} from '../../../../models/experiencefragments/header';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import useUserProfile from '../../../../hooks/use-user-profile';

const userLoggedOut = './icons/user-profile-loggedout-outline.svg';
const userLoggedIn = './icons/user-profile-loggedin-outline.svg';

interface Props {
    content: SignIn;
}

export const Login = (props: Props) => {
    const authService = new AuthenticationService();
    const profile = useUserProfile();

    const determineUserIcon = useCallback(() => {
        let icon = userLoggedOut;
        if (
            serverSideService.isClientSide() &&
            document.body.clientWidth >= 768 &&
            profile
        )
            icon = userLoggedIn;
        return icon;
    }, [profile]);

    const [userIcon, setUserIcon] = useState<string>(determineUserIcon);

    useEffect(() => {
        if (profile) {
            setUserIcon(determineUserIcon());
        }
    }, [profile, determineUserIcon]);

    const toggleDropwdownAndOverlay = () => {
        document
            .getElementsByClassName('login-dropdown')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('login-button')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('main-overlay')[0]
            .classList.toggle('open');
    };

    if (serverSideService.isClientSide()) {
        window.onresize = () => {
            setUserIcon(determineUserIcon());
        };
        document.body.onclick = (e: MouseEvent) => {
            const eventSource = e.target as HTMLElement;
            const loginDropdown = document.getElementsByClassName(
                'login-dropdown'
            )[0];
            if (
                !eventSource.closest('.login-container') &&
                loginDropdown &&
                loginDropdown.classList.contains('active')
            )
                toggleDropwdownAndOverlay();
        };
    }

    const loginText = () => {
        return profile ? '' : props.content.signInTitle;
    };

    const toggleDisplayOrLogin = () => {
        if (profile) {
            toggleDropwdownAndOverlay();
        } else authService.login();
    };

    const getAuthenticatedDropDownContentRenderer = (
        signInAuthItem: SignInAuthItem,
        index: number
    ) => {
        const myProfileTitle = profile
            ? profile.profile.firstName.toUpperCase()
            : '';
        return (
            <li key={index} className="login-dropdown-list-item">
                <a
                    rel="noreferrer"
                    aria-label={signInAuthItem.ariaLabel}
                    href={signInAuthItem.url}
                    target={signInAuthItem.targetBlank ? '_blank' : ''}
                >
                    {signInAuthItem.title
                        ? signInAuthItem.title.replace(
                              '%firstName',
                              myProfileTitle
                          )
                        : ''}
                </a>
            </li>
        );
    };

    return (
        <>
            {props.content.signInTitle && (
                <div className="login-container">
                    <div>
                        <button
                            className="login-button"
                            onClick={toggleDisplayOrLogin}
                        >
                            <img src={userIcon} alt="user-profile" />
                            <i className="icon linc1638-lw-icons-38px-c16-expanded-06 hide-me" />
                            <span className="login-text">{loginText()}</span>
                        </button>
                    </div>
                    {profile && (
                        <div
                            data-testid="login-dropdown"
                            className="login-dropdown"
                        >
                            <ul className="login-dropdown-list">
                                {props.content.signInAuthItems.map(
                                    getAuthenticatedDropDownContentRenderer
                                )}
                                <li className="login-dropdown-list-item">
                                    <button onClick={authService.logout}>
                                        {props.content.signOutTitle}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
