import React from 'react';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';

const VehicleCheckListEsb = (
    props: VehicleAttributes & VehicleChecklistContent
) => {
    return (
        <VehicleChecklist
            header={props.title}
            type={props.cardTypes}
            description={props.staticDescription}
            ctaTitle={props.ctaTitle}
            ctaPath={props.staticCtaPath}
            iconPath={props.iconPath}
            ctaAriaLabel={props.ctaAriaLabel}
            ctaTargetBlank={props.ctaTargetBlank}
        />
    );
};

export default VehicleCheckListEsb;
