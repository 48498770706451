import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CouponsService } from '../../../../../services/coupons-service/coupons-service';
import './coupons-detail.scss';
import parse from 'html-react-parser';
import printIcon from '../../../../../assets/print-icon.svg';
import { useSearchContent } from '../../../search-bar/hooks/use-search-content';
import { LiveChatSearchBar } from '../../../search-bar/live-chat-search-bar';
import Helmet from 'react-helmet';
import useSeoConfig from '../../../../../hooks/use-seo-config';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { useServerData } from '../../../../../hooks/use-server-data';
import ServerContext from '../../../..../../../../contexts/serverContext';
import {
    RewardsApiResponse,
    CouponSeoAttributes,
    subcategoryTitle,
    transform,
} from '../../constants/route-constants';
import { useLiveChatHelpCardsContent } from '../../../tirefinder/hooks/use-livechat-helpcard';
import Moment from 'moment';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';

import { CardDescription } from '../cao-common/card-description';
import { getConfiguredMetaContent } from './meta-tags-builder';
import ServerSideService from '../../../../../../src/services/server-side-service/server-side-service';
import CouponDetailBreadcrumb from './coupons-detail-breadcrumb';

export const viewOffersPrint = () => {
    window.print();
};
export const focusOnCouponsDetail = () => {
    const focusable = document.getElementsByClassName('breadcrumb-link');
    const firstFocus = focusable[0] as HTMLElement;
    firstFocus?.focus();
};
export const CouponsDetail = () => {
    const location = useLocation();
    const searchContent = useSearchContent();
    const helpCardsContent = useLiveChatHelpCardsContent();
    const [rewardsContent, setRewardsContent] = useState<RewardsApiResponse>();
    const [seoCouponAttributes, setSeoCouponAttributes] = useState<
        CouponSeoAttributes
    >();
    const context = useContext(ServerContext);
    const currentRoot = context.root
        ? context.root.substring(0, context.root.length)
        : '';
    const couponId = ServerSideService.isServerSide()
        ? context.urlParams.couponId?.toUpperCase()
        : new URLSearchParams(location?.search).get('couponId')?.toUpperCase();

    let title: any;
    let PrimarySubcategory: string;
    const flag = true;
    const [fireEvents] = useAnalytics();
    const appConfiguration = new AppConfigurationService().getAppConfiguration(
        undefined,
        context?.root,
        undefined,
        context?.brand,
        context?.currentLanguageRegionCode
    );
    const brandVar =
        appConfiguration.brand === 'ford' ? 'Ford Owner' : 'Lincoln Owner';

    let value: string;

    const [serverData] = useServerData('CouponsData', () => {
        return new CouponsService().request(context?.brand);
    });

    const getCouponById = (
        couponsData: any,
        id: string
    ): CouponSeoAttributes[] => {
        return couponsData?.coupons
            .filter((coupon: any) => coupon.couponId === id)
            .map(
                ({
                    couponId,
                    disclaimer,
                    expiryDate,
                    subCopy,
                    offer,
                    commodityDescription,
                }: any) =>
                    ({
                        couponId,
                        disclaimer,
                        expiryDate,
                        subCopy,
                        offer,
                        categoryName: commodityDescription.split(':')[1],
                    } as CouponSeoAttributes)
            );
    };

    const setSeoAttributes = (context: any, couponsData: any, id: string) => {
        const pageTitle = getConfiguredMetaContent(context, 'pageTitle');
        const metaDescription = getConfiguredMetaContent(
            context,
            'metaDescription'
        );
        const currentCoupons = getCouponById(couponsData, id);

        if (currentCoupons?.length) {
            setSeoCouponAttributes({
                ...currentCoupons[0],
                metaDescription,
                pageTitle,
            });
        }
    };

    if (serverData && !seoCouponAttributes) {
        const couponsData = serverData.data;
        setRewardsContent(couponsData);
        const id: string =
            context.urlParams?.couponId?.toUpperCase() || couponId;

        if (couponsData && couponsData?.coupons.length > 0) {
            setSeoAttributes(context, couponsData, id);
        }
    }

    useEffect(() => {
        !rewardsContent &&
            new CouponsService()
                .request(context?.brand)
                .then(couponData => {
                    setRewardsContent(couponData);
                    window.scrollTo(0, 0);
                })
                .catch(error => {
                    console.log(error);
                });
    }, []);

    const couponsCategoryName = {
        couponsCategoryName: couponId,
        couponsId: couponId,
    };

    useSeoConfig('coupon-details', 'coupon', [
        {
            fieldName: 'coupon-detail-subcopy',
            fieldValue: seoCouponAttributes?.subCopy || '',
        },
        {
            fieldName: 'coupon-detail-expiry-date',
            fieldValue: seoCouponAttributes
                ? seoCouponAttributes.expiryDate
                : '',
        },
        {
            fieldName: 'coupon-detail-disclaimer',
            fieldValue: seoCouponAttributes
                ? seoCouponAttributes.disclaimer
                : '',
        },
        {
            fieldName: 'coupon-detail-offer',
            fieldValue: seoCouponAttributes ? seoCouponAttributes.offer : '',
        },
        {
            fieldName: 'coupon-detail-category',
            fieldValue: seoCouponAttributes
                ? seoCouponAttributes.categoryName
                : '',
        },
        {
            fieldName: seoCouponAttributes
                ? seoCouponAttributes.staticTitlePlaceholder
                : '',
            fieldValue: seoCouponAttributes
                ? seoCouponAttributes.staticTitleText
                : '',
        },
    ]);

    useAnalytics(['coupons-detail'], undefined, couponsCategoryName);
    const CouponsDetailCard = (id: string) =>
        rewardsContent?.coupons
            .filter(coupon => coupon.couponId.indexOf(id) !== -1)
            .map(couponDetails => {
                const commodityDescriptionElement = couponDetails.commodityDescription.split(
                    ':'
                )[1];
                PrimarySubcategory = commodityDescriptionElement;
                [title, value] = subcategoryTitle(
                    PrimarySubcategory,
                    title,
                    value,
                    flag
                );

                const tileOnClickCtaInfo = {
                    couponsCategoryName: title,
                };

                function getConvertedDate(expiryDate: string) {
                    return expiryDate.length > 0
                        ? Moment(expiryDate)
                              .format('YYYY-MM-DD')
                              .toString()
                        : 'Unknown';
                }

                const couponsCategoryScript = `<script type="application/ld+json">
                    {
                    "@context": "http://schema.org/",
                    "@type": "Service",
                    "serviceType": "${title}",
                    "provider": {
                        "@type": "organization",
                        "image" : "${couponDetails.imageURLXLarge}",
                        "name": "${brandVar}"
                    },
                    "areaServed": {
                        "@type": "country",
                        "name": "US"
                    },
                    "hasOfferCatalog": {
                        "@type": "OfferCatalog",
                        "name": "Rebate",
                        "itemListElement": [
                        {
                            "@type": "OfferCatalog",
                            "name": "${value}",
                            "itemListElement": [
                            {
                                "@type": "OfferCatalog",
                                "name": "${couponDetails.offer}",
                                "itemListElement": [
                                {
                                    "@type": "Offer",
                                        "itemOffered": {
                                        "@type": "Service",
                                        "name": "${
                                            couponDetails.offer.split('*')[0]
                                        } ${
                    couponDetails.subCopy
                } Expires ${getConvertedDate(couponDetails.expiryDate)}"
                                    },
                                    "validThrough": "${getConvertedDate(
                                        couponDetails.expiryDate
                                    )}"
                                }
                                ]
                            }
                            ]
                        }
                        ]
                    }
                    }
                    </script>`;

                return (
                    <div
                        className="viewoffers-top"
                        key={couponDetails.couponId}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: couponsCategoryScript || '',
                            }}
                        />
                        <Helmet>
                            <title>
                                {seoCouponAttributes?.offer}
                                {seoCouponAttributes?.categoryName}
                                {seoCouponAttributes?.staticTitleText}
                            </title>
                            <meta
                                name="description"
                                content={couponDetails.subCopy
                                    .replace(/<br\s*\/?>/g, ',')
                                    .replace(/%/g, ' percent')
                                    .replace(/([^\w'$,-])/g, ' ')
                                    .replace(',,', ', ')}
                            ></meta>
                        </Helmet>
                        <CouponDetailBreadcrumb
                            PrimarySubcategory={PrimarySubcategory}
                            currentPage={'Offer Details'}
                            isWebview={false}
                            currentRoot={currentRoot}
                        />
                        <div className="viewoffers">
                            <div className="rewards-card">
                                <div className="card-image-div">
                                    <img
                                        className="card-image"
                                        src={couponDetails.imageURLXLarge}
                                        alt="coupon-image"
                                    ></img>
                                </div>
                                <div className="card-body">
                                    <button
                                        className="print-button"
                                        aria-label="Print offer"
                                        onClick={() => {
                                            fireEvents(
                                                'coupons-detail-cta-onclick-event',
                                                undefined,
                                                tileOnClickCtaInfo,
                                                false
                                            );
                                            window.print();
                                        }}
                                    >
                                        <img
                                            src={printIcon}
                                            alt=""
                                            className="close-icon"
                                        />
                                    </button>
                                    <h1 className="title">{title}</h1>
                                    <div className="coupon-offer-text">
                                        <CardDescription {...couponDetails} />
                                    </div>
                                    <div className="coupon-description">
                                        {parse(couponDetails.subCopy, {
                                            replace: transform,
                                        })}
                                    </div>
                                    {couponDetails.code &&
                                        !couponDetails.code.includes('E') && (
                                            <div className="expiry">
                                                EXPIRES{' '}
                                                {couponDetails.expiryDate}
                                            </div>
                                        )}
                                    <div className="disclaimer">
                                        {parse(couponDetails.disclaimer)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {searchContent && helpCardsContent && (
                            <LiveChatSearchBar
                                searchContent={searchContent}
                                helpCardsContent={helpCardsContent}
                            />
                        )}
                    </div>
                );
            });

    return <>{couponId && CouponsDetailCard(couponId)}</>;
};
