import { ExperienceFragmentModel } from '../../../../../services/content-service/content-service.interface';
import { ReactNode } from 'react';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

export interface FeatureCardsFragment extends ExperienceFragmentModel {
    hide: boolean;
    title: string;
    titleWithDisclaimer?: ReactNode;
    subtitle: string;
    subtitleWithDisclaimer?: ReactNode;
    ariaLive?: string;
    ariaLabel?: string;
    role?: string;
    paginationText?: string;
    rightButtonAriaLabel?: string;
    leftButtonAriaLabel?: string;
    disclaimer: string;
    cards: FMCardProps[];
    openInNewTabAriaLabel?: string;
}

export class FeatureCardsService {
    public getFeatureCards(
        experienceContent: FeatureCardsFragment
    ): FeatureCardsFragment | null {
        let filteredCard: FMCardProps[];
        if (experienceContent) {
            if (!experienceContent.hide) {
                filteredCard = experienceContent.cards.filter(
                    card => !card.hide
                );
                if (filteredCard.length > 0) {
                    return {
                        hide: experienceContent.hide,
                        title: experienceContent.title,
                        subtitle: experienceContent.subtitle,
                        disclaimer: experienceContent.disclaimer,
                        paginationText: experienceContent.paginationText,
                        ariaLive: experienceContent.ariaLive,
                        role: experienceContent.role,
                        ariaLabel: experienceContent.ariaLabel,
                        rightButtonAriaLabel:
                            experienceContent.rightButtonAriaLabel,
                        leftButtonAriaLabel:
                            experienceContent.leftButtonAriaLabel,
                        cards: filteredCard,
                        openInNewTabAriaLabel:
                            experienceContent.openInNewTabAriaLabel,
                    } as FeatureCardsFragment;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
