import { PageType } from '../../components/sections/view-template/page-type';
import { ExperienceFragmentModel } from '../../services/content-service/content-service.interface';
import { VehicleAttributes } from '../vehicle-attributes';
import { ReactNode } from 'react';

export enum VehicleType {
    BEV = 'BEV',
    ICE = 'ICE',
    ALL = 'All',
}

export interface SmartTilesFragment extends ExperienceFragmentModel {
    informationGridTitle?: string;
    informationGridTitleWithDisclaimer?: ReactNode;
    informationGridSubCopy?: string;
    informationGridSubCopyWithDisclaimer?: ReactNode;
    informationGridDisclaimer?: string;
    heroRowTilesTitle?: string;
    heroRowTilesTitleWithDisclaimer?: ReactNode;
    heroRowTilesSubCopy?: string;
    heroRowTilesSubCopyWithDisclaimer?: ReactNode;
    heroRowTilesDisclaimer?: string;
    cta: HyperLink;
    smartTiles: SmartTileContent[];
}

export interface HyperLink {
    title: string;
    url: string;
    ariaLabel: string;
    targetBlank: boolean;
}

export interface SmartTileContent {
    type: string;
    vehicleType: string;
    staticTitle: string;
    dynamicTitle: string;
    iconPath: string;
    iconAriaLabel: string;
    staticSubCopy: string;
    dynamicSubCopy: string;
    staticCTATitle: string;
    dynamicCTATitle: string;
    staticCTAPath: string;
    dynamicCTAPath: string;
    ctaAriaLabel: string;
    ctaTargetBlank: boolean;
    showOnlyInAuth: boolean;
    recallModalCtaSecondaryAriaLabel?: string;
    recallModalCtaSecondaryDynamicPath?: string;
    recallModalCtaSecondaryText?: string;
    recallModalTitle?: string;
    noRecallModalTitle?: string;
    recallModalCtaPrimaryAriaLabel?: string;
    recallModalCtaPrimaryDynamicPath?: string;
    recallModalCtaPrimaryText?: string;
    hideNoActionStateCTA?: boolean;
    preDeliveryRank: string;
    postDeliveryRank: string;
    subheader: string;
    toolTipMsg?: string;
    dismissTooltipAriaLabel?: string;
    showTooltipAriaLabel?: string;
    addVehicleCTATitle?: string;
    subCopyDescription?: string;
    wifiNotSupportedStaticTitle?: string;
    wifiNotSupportedIconPath?: string;
    wifiNotSupportedIconAriaLabel?: string;
    wifiNotSupportedStaticSubCopy?: string;
    wifiNotSupportedSubCopyDescription?: string;
    isHeroRow?: boolean;
}

const filterBasedOnVehicleType = (
    tile: SmartTileContent,
    isBev: boolean | undefined,
    ownerState: number
) => {
    const rank =
        (ownerState === 1 ? tile.postDeliveryRank : tile.preDeliveryRank) ||
        '0';
    if (isBev)
        return (
            rank !== '0' &&
            (tile.vehicleType === VehicleType.BEV ||
                tile.vehicleType === VehicleType.ALL)
        );
    else return rank !== '0' && tile.vehicleType !== VehicleType.BEV;
};

const filterAuthOnlyBasedTiles = (tile: SmartTileContent, isAuth: boolean) => {
    if (isAuth) return true;
    else return !tile.showOnlyInAuth;
};
const removeRvsTile = (tile: SmartTileContent) => {
    return tile.type !== 'remote-vehicle-setup';
};
const filterDYFTile = (
    tile: SmartTileContent,
    page: PageType,
    currentRegionCode: string,
    vehicleAttributes?: VehicleAttributes
) => {
    if (tile.type === 'new-vehicle-orientation' && vehicleAttributes) {
        if (page === 'Landing') return false;
        else if (!['us', 'ca'].includes(currentRegionCode)) return false;
        else if (currentRegionCode === 'us' && vehicleAttributes.year < 2013)
            return false;
        else if (currentRegionCode === 'ca' && vehicleAttributes.year < 2016)
            return false;
        else return true;
    } else return true;
};

const sortBasedOnVehicleTypeRanking = (
    ownerState: number,
    tileB: SmartTileContent,
    tileA: SmartTileContent
) => {
    const tileARank =
        (ownerState === 1 ? tileA.postDeliveryRank : tileA.preDeliveryRank) ||
        '0';
    const tileBRank =
        (ownerState === 1 ? tileB.postDeliveryRank : tileB.preDeliveryRank) ||
        '0';
    return Number.parseInt(tileARank) - Number.parseInt(tileBRank);
};

export const transformSmartTileContent = (
    experienceContent: SmartTilesFragment | null,
    ownerState: number,
    isBev: boolean | undefined,
    page: PageType,
    vehicleAttributes: VehicleAttributes | undefined,
    currentRegionCode: string,
    startIndex: number,
    endIndex?: number,
    analyticsEventName?: string
) => {
    let smartTilesArray: any[];
    if (experienceContent && experienceContent.smartTiles) {
        smartTilesArray = experienceContent.smartTiles
            .filter(tile => {
                return filterBasedOnVehicleType(tile, isBev, ownerState);
            })
            .filter(tile => {
                return filterAuthOnlyBasedTiles(tile, page === 'DASHBOARD');
            })
            .filter(title => removeRvsTile(title))
            .filter(tile =>
                filterDYFTile(tile, page, currentRegionCode, vehicleAttributes)
            )
            .sort((tileA, tileB) => {
                return sortBasedOnVehicleTypeRanking(ownerState, tileB, tileA);
            })
            .map(tile => tile);
        if (!endIndex) {
            endIndex = smartTilesArray.length;
        }
        smartTilesArray = smartTilesArray.slice(startIndex, endIndex);
    } else {
        smartTilesArray = [];
    }
    if (analyticsEventName && smartTilesArray.length > 0) {
        smartTilesArray.forEach(smartTile => {
            smartTile.analyticsEventName = analyticsEventName;
        });
    }
    return smartTilesArray;
};
