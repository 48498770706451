import React, { useState } from 'react';

import {
    CategoryItem,
    HeaderFragment,
} from '../../../models/experiencefragments/header';
import { Link } from '../../common';
import { LanguageToggle } from '../language-toggle/language-toggle';
import { Login } from './login/login';
import Hamburger from 'hamburger-react';
import './header.scss';
import './lincoln-na-header/lincoln-na-header.scss';
import SidebarMenu from './sidebar-menu/sidebar-menu';
import { SiteSearch } from '../search/site-search';
import { useExperienceContent } from '../../../hooks/use-server-content';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDesktopSubnav, setActiveDesktopSubnav] = useState('');
    const [experienceContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );
    const { brand } = new AppConfigurationService();

    const toggleMenu = () => {
        const bodyElem = document.querySelector('body') as HTMLBodyElement;
        bodyElem.classList.toggle('no-scroll-sm');
        setMenuOpen(!menuOpen);
    };

    const handleClick = () => {
        if (activeDesktopSubnav) {
            const containerElem = document.querySelector(
                '.show-subnav'
            ) as HTMLBodyElement;
            containerElem?.classList?.toggle('visible-xs');
        }
    };

    const subnavCategoryItems = (subnav: CategoryItem[]) => {
        return (
            <div className="subnav-link-container">
                {subnav
                    .map((sideNav, inx) => {
                        return inx % 6 === 0 && subnav.slice(inx, inx + 6);
                    })
                    .filter(sideNav => sideNav)
                    .map((subnav, index) => {
                        return (
                            <ul key={index}>
                                {subnav &&
                                    subnav.map((sideNav, inx) => {
                                        return (
                                            <li
                                                key={inx}
                                                className="subnav-link"
                                            >
                                                <Link
                                                    target={
                                                        sideNav.targetBlank
                                                            ? '_blank'
                                                            : ''
                                                    }
                                                    href={sideNav.url}
                                                    aria-label={
                                                        sideNav.ariaLabel
                                                    }
                                                >
                                                    {sideNav.title}
                                                </Link>
                                            </li>
                                        );
                                    })}
                            </ul>
                        );
                    })}
            </div>
        );
    };

    function subnavCategoryImageItems(
        categoryTitle: string,
        categoryItems: CategoryItem[],
        trueLength: boolean
    ) {
        return (
            <>
                {categoryItems.map((sideNav, inx) => {
                    return (
                        <ul
                            className={`subnav-image-container ${
                                trueLength && categoryItems.length == inx + 1
                                    ? ''
                                    : 'border-right'
                            }`}
                            key={inx}
                        >
                            <li>
                                <Link
                                    target={sideNav.targetBlank ? '_blank' : ''}
                                    href={sideNav.url}
                                    aria-label={sideNav.ariaLabel}
                                >
                                    <img
                                        className="subnav-image"
                                        src={
                                            process.env.REACT_APP_AEM_BASE_URL +
                                            sideNav.thumbnailImagePath
                                        }
                                        alt={sideNav.title}
                                    />
                                </Link>
                            </li>
                            <li>
                                <h2 className="category-title">
                                    {categoryTitle}
                                </h2>
                            </li>
                            <li className="title">{sideNav.title}</li>
                            <li className="description">
                                {sideNav.description}
                            </li>
                            <li className="price">{sideNav.price}</li>
                            <li className="link">
                                <Link
                                    target={sideNav.targetBlank ? '_blank' : ''}
                                    href={sideNav.url}
                                    aria-label={sideNav.ariaLabel}
                                >
                                    {sideNav.urlText}
                                </Link>
                            </li>
                            <li className="link">
                                <Link
                                    target={
                                        sideNav.targetBlank1 ? '_blank' : ''
                                    }
                                    href={sideNav.url1}
                                    aria-label={sideNav.ariaLabel1}
                                >
                                    {sideNav.urlText1}
                                </Link>
                            </li>
                        </ul>
                    );
                })}
            </>
        );
    }

    return (
        <>
            <header className="header">
                {experienceContent && (
                    <>
                        <div className="visible-in-mobile">
                            <div
                                data-testid="menu-toggle"
                                style={{
                                    paddingLeft: '6px',
                                    paddingBottom: '6px',
                                }}
                            >
                                <Hamburger
                                    direction="right"
                                    toggled={menuOpen}
                                    toggle={toggleMenu}
                                    size={35}
                                    color={
                                        brand === 'lincoln'
                                            ? '#324047'
                                            : '#208BC8'
                                    }
                                />
                            </div>
                            <SidebarMenu
                                isOpen={menuOpen}
                                className="mobile"
                                toggleMenu={toggleMenu}
                            />
                        </div>
                        <a
                            tabIndex={0}
                            href={experienceContent.logo.url}
                            target={
                                experienceContent.logo.targetBlank
                                    ? '_blank'
                                    : '_self'
                            }
                            className="logo-link"
                            aria-label={experienceContent.logo.ariaLabel}
                            rel="noopener noreferrer"
                        >
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${experienceContent.logo.logoPath}`}
                                className={'ford-logo'}
                                alt={experienceContent.logo.ariaLabel}
                            />
                        </a>

                        {experienceContent['main-nav-left'].map(
                            (navsub, index) => {
                                if (!navsub.categories && navsub.noFlyout) {
                                    return (
                                        <nav
                                            aria-label={navsub.title}
                                            key={index}
                                            className={`navsub${index}`}
                                        >
                                            <Link
                                                href={navsub.url}
                                                className="desktop first-header-link"
                                                rel="noopener noreferrer"
                                                aria-label={navsub.ariaLabel}
                                            >
                                                {navsub.title}
                                            </Link>
                                        </nav>
                                    );
                                } else if (
                                    navsub.categories &&
                                    !navsub.noFlyout &&
                                    !navsub.categories[0].categoryItems[0]
                                        .urlText &&
                                    !navsub.categories[0].categoryItems[0]
                                        .urlText1
                                ) {
                                    return (
                                        <nav
                                            aria-label={navsub.title}
                                            key={index}
                                            className={`navsub${index}`}
                                        >
                                            <button
                                                aria-label={navsub.title}
                                                key={index}
                                                className={`desktop ${
                                                    activeDesktopSubnav ===
                                                    navsub.title
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    setActiveDesktopSubnav(
                                                        prevState => {
                                                            if (
                                                                prevState ===
                                                                navsub.title
                                                            ) {
                                                                return '';
                                                            } else {
                                                                return navsub.title;
                                                            }
                                                        }
                                                    );
                                                    handleClick();
                                                }}
                                            >
                                                <span className="main-item">
                                                    <Link
                                                        href={navsub.url}
                                                        aria-label={
                                                            navsub.ariaLabel
                                                        }
                                                    >
                                                        {navsub.title}
                                                    </Link>
                                                </span>
                                                {brand === 'lincoln' && (
                                                    <i
                                                        className={`icon iconl-chevron-toggle hidden-xs ${activeDesktopSubnav ===
                                                            navsub.title &&
                                                            'up'}`}
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>

                                            <div
                                                id={`subNav${index}`}
                                                className={`desktop-subnav ${
                                                    activeDesktopSubnav ===
                                                    navsub.title
                                                        ? 'show-subnav'
                                                        : 'hide-nav'
                                                }`}
                                            >
                                                <ul className="main-nav-item sidebar-subnav">
                                                    {navsub.categories.map(
                                                        (subnav, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="subnav-title-container"
                                                                >
                                                                    <span
                                                                        className={
                                                                            'drawer-title'
                                                                        }
                                                                    >
                                                                        {
                                                                            subnav.categoryTitle
                                                                        }
                                                                    </span>
                                                                    {subnavCategoryItems(
                                                                        subnav.categoryItems
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </nav>
                                    );
                                } else if (
                                    navsub.categories &&
                                    !navsub.noFlyout &&
                                    navsub.categories[0].categoryItems[0]
                                        .urlText &&
                                    navsub.categories[0].categoryItems[0]
                                        .urlText1
                                ) {
                                    return (
                                        <nav
                                            aria-label={navsub.title}
                                            key={index}
                                            className={`navsub${index}`}
                                        >
                                            <button
                                                aria-label={navsub.title}
                                                key={index}
                                                className={`desktop ${
                                                    activeDesktopSubnav ===
                                                    navsub.title
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    setActiveDesktopSubnav(
                                                        prevState => {
                                                            if (
                                                                prevState ===
                                                                navsub.title
                                                            ) {
                                                                return '';
                                                            } else {
                                                                return navsub.title;
                                                            }
                                                        }
                                                    );
                                                    handleClick();
                                                }}
                                            >
                                                <span className="main-item">
                                                    <Link
                                                        href={navsub.url}
                                                        aria-label={
                                                            navsub.ariaLabel
                                                        }
                                                    >
                                                        {navsub.title}
                                                    </Link>
                                                </span>
                                                {brand === 'lincoln' && (
                                                    <i
                                                        className={`icon iconl-chevron-toggle hidden-xs ${activeDesktopSubnav ===
                                                            navsub.title &&
                                                            'up'}`}
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>

                                            <div
                                                id={`subNav${index}`}
                                                className={`desktop-subnav ${
                                                    activeDesktopSubnav ===
                                                    navsub.title
                                                        ? 'show-subnav'
                                                        : 'hide-nav'
                                                }`}
                                            >
                                                <ul className="main-nav-item sidebar-img-subnav">
                                                    {navsub.categories.map(
                                                        (subnav, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="subnav-image-containers"
                                                                >
                                                                    {subnavCategoryImageItems(
                                                                        subnav.categoryTitle,
                                                                        subnav.categoryItems,
                                                                        navsub
                                                                            .categories
                                                                            .length ==
                                                                            index +
                                                                                1
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </nav>
                                    );
                                }
                            }
                        )}
                        <span className="right-align desktop">
                            {experienceContent['main-nav-right'].utilityLinks &&
                                experienceContent[
                                    'main-nav-right'
                                ].utilityLinks.map((nav, index) => {
                                    return (
                                        <Link
                                            key={index}
                                            className="locate-dealer"
                                            href={nav.url}
                                            aria-label={nav.title}
                                        >
                                            {nav.icon && (
                                                <img
                                                    src={
                                                        process.env
                                                            .REACT_APP_AEM_BASE_URL +
                                                        nav.icon
                                                    }
                                                    alt={nav.ariaLabel}
                                                />
                                            )}
                                            <span>{nav.title}</span>
                                        </Link>
                                    );
                                })}
                        </span>
                        <Login
                            content={experienceContent['main-nav-right'].signIn}
                        />
                        {experienceContent['main-nav-right'].search && (
                            <span className="desktop">
                                <SiteSearch
                                    content={
                                        experienceContent['main-nav-right']
                                            .search
                                    }
                                ></SiteSearch>
                            </span>
                        )}
                        {experienceContent['main-nav-right'].language
                            .languageItems && (
                            <span className="desktop">
                                <LanguageToggle tabIndex={0} />
                            </span>
                        )}
                        <div className="mobile-fill" />
                    </>
                )}
            </header>
            <div
                style={{
                    display: activeDesktopSubnav ? 'block' : 'none',
                }}
                className="background-overlay"
                onClick={() => {
                    setActiveDesktopSubnav('');
                }}
            />
        </>
    );
};
export default Header;
