import { useContent } from '../../../../../hooks/use-server-content';
export interface ChooseMethodContentProps {
    chooseMethodDescription: string;
    tipText: string;
    dealerInformationText: string;
    rewardPointsSubtitle: string;
    debitCardSubtitle: string;
    clearButtonText: string;
    continueButtonText: string;
    chooseMethodErrorMessage: string;
    noRebatesErrorMessage: string;
    searchAgainButtonText: string;
}

export const useChooseMethodContent = (): ChooseMethodContentProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates/request-form-steps',
        'choose-method'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ChooseMethodContentProps;
};
