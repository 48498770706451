import React, { ChangeEvent, forwardRef, Ref, useRef, useEffect } from 'react';
import { KEYBOARD_KEYS } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import isValidVinOrReg, {
    vinStartsWithWFO,
} from '../../../services/valid-vin-service/ValidVinService';
import isSupportedVin, {
    decodeVin,
} from '../../../services/vin-decoder-service/VinDecoderService';
import { VinSelectorProps } from '../../sections/vehicle-selector-fds/vin-selector/hook/use-vin-selector';
import InputField from '../input-field/input-field';
import './vin-input.scss';

interface VinInputProps {
    name: string;
    vinSelectorContent?: VinSelectorProps;
    label?: string;
    ariaLabel?: string;
    vin?: string;
    placeholder?: string;
    errorMessage?: string;
    onSubmit?: (vin: string) => void;
    setParentVin: React.Dispatch<React.SetStateAction<string>>;
    setParentError: React.Dispatch<React.SetStateAction<string | undefined>>;
    focusOnLoad?: boolean;
    decodeVin?: boolean;
    altLabel?: string;
}

const VinInput = forwardRef(
    (props: VinInputProps, ref: Ref<HTMLButtonElement>) => {
        const regionEU = new AppConfigurationService().isRegionEU();
        const inputRef = useRef<HTMLInputElement>(null);
        useEffect(() => {
            if (props.focusOnLoad && inputRef.current) {
                inputRef.current.focus();
            }
        }, [props.focusOnLoad]);

        if (props.focusOnLoad) inputRef.current?.focus();
        const validVin = (vin?: string) => {
            if (vin) {
                if (regionEU && vin.length <= 10) {
                    if (!isValidVinOrReg(vin, true))
                        return props.vinSelectorContent?.invalidVin;
                    if (props.onSubmit) props.onSubmit(vin);
                } else {
                    if (regionEU && vinStartsWithWFO(vin))
                        return props.vinSelectorContent?.vinWfoError;
                    const decodedVin = decodeVin(vin);
                    if (!isValidVinOrReg(decodedVin))
                        return props.vinSelectorContent?.invalidVin;
                    if (!isSupportedVin(decodedVin))
                        return props.vinSelectorContent
                            ?.vinNotFordLincolnMercury;
                    if (
                        props.decodeVin &&
                        decodedVin !== vin &&
                        props.setParentVin
                    )
                        props.setParentVin(decodedVin);
                    if (props.onSubmit)
                        props.onSubmit(props.decodeVin ? decodedVin : vin);
                }
            } else return props.vinSelectorContent?.noEmptyField;
        };

        const validateOnEnter = () => {
            const vinErrorMessage = validVin(props.vin);
            if (vinErrorMessage) {
                props.setParentError(vinErrorMessage);
            }
        };

        const onEnter = () => {
            props.setParentError('');
            validateOnEnter();
        };

        const onInputKeyPress = (
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            if (event.key === KEYBOARD_KEYS.ENTER) {
                props.setParentError('');
                validateOnEnter();
            }
        };

        return (
            <div className="vin-input-field-container">
                {props.vinSelectorContent && (
                    <InputField
                        ref={inputRef}
                        maxLength={17}
                        label={
                            props.altLabel ||
                            props.label ||
                            props.vinSelectorContent.vinInputLabel ||
                            'VIN'
                        }
                        ariaLabel={
                            props.ariaLabel ||
                            props.vinSelectorContent.ariaLabel ||
                            'VIN'
                        }
                        name={`vin-field-${props.name}`}
                        type="string"
                        value={props.vin}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.setParentError('');
                            props.setParentVin &&
                                props.setParentVin(
                                    e.currentTarget.value.toUpperCase()
                                );
                        }}
                        placeHolder={props.placeholder || 'Enter VIN'}
                        errorMessage={props.errorMessage}
                        onKeyUp={onInputKeyPress}
                    />
                )}

                <button
                    ref={ref}
                    id={`vin-input-${props.name}-submit`}
                    style={{ display: 'none' }}
                    onClick={onEnter}
                ></button>
            </div>
        );
    }
);
VinInput.displayName = 'VinInput';
export default VinInput;
