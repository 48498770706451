import {
    AvailableDates,
    DealerCalendarDetails,
} from '../../../../models/osb-model/dealer-calendar-details';
import { CalendarAvailabilityResponse } from '../../../../models/osb-model/osb-calendar-step';
import { SERVICE_DELIVERY_TYPE } from '../osb-constant';

export const transformServiceResponse = (
    serviceAPIResponse: CalendarAvailabilityResponse,
    serviceDeliveryType?: string
): DealerCalendarDetails => {
    const availableDates: AvailableDates[] = [];
    let startDate = '',
        endDate = '';

    for (const calendarAvailability of serviceAPIResponse.dailyAvailability) {
        let availableDateTime: AvailableDates;
        if (serviceDeliveryType === SERVICE_DELIVERY_TYPE.MOBILESERVICE) {
            availableDateTime = {
                date: calendarAvailability.date,
                availableTimeRanges: calendarAvailability.slotGroups,
                sameDayPickUp: calendarAvailability.sameDayPickUp,
            };
        } else {
            availableDateTime = {
                date: calendarAvailability.date,
                availableTimeSlots: calendarAvailability.times,
                sameDayPickUp: calendarAvailability.sameDayPickUp,
            };
        }
        availableDates.push(availableDateTime);
    }

    if (
        serviceAPIResponse.dailyAvailability &&
        serviceAPIResponse.dailyAvailability.length > 0
    ) {
        startDate = serviceAPIResponse.dailyAvailability[0].date;
        endDate =
            serviceAPIResponse.dailyAvailability[
                serviceAPIResponse.dailyAvailability.length - 1
            ].date;
    }
    const dealerCalendarDetails: DealerCalendarDetails = {
        availableDates: availableDates,
        calendarStart: startDate,
        calendarEnd: endDate,
        timeSlotDuration: 0,
        campaignStates: serviceAPIResponse?.campaignStates,
    };

    return dealerCalendarDetails;
};
