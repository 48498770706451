import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { OSBStep } from '../../models/osb-model/osb-step';
import {
    StoreState,
    OSBStepState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { DealerProfileInfo } from '../../models/osb-model/osb-dealer-info';

export const getInitialOSBState = (): OSBStep => {
    return {
        isVehicleDeepLinked: false,
        isDealerDeepLinked: false,
        isServiceDeepLinked: false,
        isCalendarDeepLinked: false,
        isPersonalDetailDeepLinked: false,
        isVINDeepLinked: false,
        isMileageDeepLinked: false,
        isVoucherDeepLinked: false,
        isRetrieveFlow: false,
        backgroundImagePathDesktop: '',
        backgroundImagePathMobile: '',
        backgroundImagePathTablet: '',
        backgroundImagePathLargeDesktop: '',
        polygon: '',
        serviceBookingTitle: '',
        pricePrefix: '',
        priceSuffix: '',
        pricePrecision: 0,
        priceSeparator: '',
        decimalSeparator: '',
        porLabel: '',
        freeLabel: '',
        isMobileView: false,
        isValidVoucher: false,
        isMainWrapperInitialized: false,
        isAuthenticatedFlow: false,
        timeSuffixLabel: '',
        twelveHrsTimeFormat: false,
        amTimeLabel: '',
        pmTimeLabel: '',
        brandName: '',
        isVideoLinkEnabled: false,
        videoLinkLabel: '',
        videoPlayerBackButtonLabel: '',
        osbThreeLetterCountryCode: '',
        osbTwoLetterCountryCode: '',
        localStorageExpiry: 0,
        geoCountryCode: '',
        locationStepLabel: '',
        deliveryStepLabel: '',
        osbEnableImage: false,
        UrlQueryParams: '',
        isGASkipped: false,
        preSelectedServices: '',
        isFmaAuthenticated: false,
        isWebViewJourney: false,
        dealerAddressOrder: '',
        enablePickupNDeliveryLocationDateAndTime: false,
        displayProgressBar: false,
        internalErrorMessage: '',
        isPcFlow: false,
        enableOsbHomeRetrieveBooking: false,
        fordAccountEnable: false,
        osbHomeRetrieveBookingHeader: '',
        osbHomeRetrieveBookingCopyText: '',
        osbHomeRetrieveBookingButtonLabel: '',
        numberOfLeadingCharactersToStrip: 2,
        osbShowTooltipAriaLabel: '',
        osbDismissTooltipAriaLabel: '',
        voucherSuccessMessagelabel: '',
        enableMobileService: false,
        displayCountryDialCodeComponent: false,
        source: '',
        DSLAPIVersion: 'V5',
        enableNewLandingPage: false,
        preferredDealerJourney: false,
    };
};
//Configure and initialize OSB step state in global store
const configureOSBStepStore = () => {
    const actions = {
        //Action to set the OSB step details to global store
        SET_OSB_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as OSBStepState).osbStep,
                ...(payload as OSBStep),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_STEP_KEY,
                newState,
                localStorageExpiry,
                true
            );
            return { osbStep: newState };
        },
    };
    initializeStore(actions, {
        osbStep:
            getClientStorageValue(OSB_CLIENT_STORAGE_KEYS.OSB_STEP_KEY) ||
            getInitialOSBState(),
    });
};

configureOSBStepStore();

// custom hook for OSB step to handle the actions and updating payload in global store
export const useOSBStep = () => {
    const { globalState, dispatch } = useStore();

    const setGlobalOSBStep = (osbStep: OSBStep, shouldReload = true) => {
        dispatch('SET_OSB_STEP', osbStep, shouldReload, 0);
    };

    const setGlobalOSBStepPayload = (
        payload: {
            [key: string]: boolean | string | number | DealerProfileInfo[];
        },
        shouldReload = true
    ) => {
        dispatch('SET_OSB_STEP', payload, shouldReload, 0);
    };

    const resetOSBStep = (shouldReload = true) => {
        dispatch('SET_OSB_STEP', getInitialOSBState(), shouldReload, 0);
    };
    const invalidateAuthentication = () => {
        setGlobalOSBStepPayload({ isFmaAuthenticated: false });
    };

    return {
        osbStep: (globalState as OSBStepState).osbStep,
        setOSBStep: setGlobalOSBStep,
        setOSBStepPayload: setGlobalOSBStepPayload,
        resetOSBStep: resetOSBStep,
        invalidateAuthentication: invalidateAuthentication,
    };
};
