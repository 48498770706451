import React, { useRef, useEffect } from 'react';
import PrimaryButton from '../primary-button/primary-button';
import closeIcon from '../../../assets/close.svg';
import closeLincolnIcon from '../../../assets/lincoln-icons/close.svg';
import { KEYBOARD_KEYS } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import './modal.scss';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import { loopFocusWithinComponent } from '../../utils/keyboard-event-utils/keyboard-event-utils';
import { FMButton } from '@own/fds-react';

interface Props extends React.HTMLProps<HTMLDivElement> {
    name: string;
    primaryBtnLabel?: string;
    secondaryBtnLabel?: string;
    onPrimaryBtnClick?: (event: React.MouseEvent) => void;
    onSecondaryBtnClick?: (event: React.MouseEvent) => void;
    preventClose?: boolean;
    onClose?: () => void;
    role?: string;
    'aria-label'?: string;
    isVisible?: boolean;
    onBodyScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    disablePrimaryButton?: boolean;
    modalNote?: string;
    chevron?: boolean;
    osbEnabled?: boolean;
}

export const Modal = (props: Props) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const closeIconRef = useRef<HTMLButtonElement>(null);
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const secondaryButtonRef = useRef<HTMLButtonElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const brand = new AppConfigurationService().brand;
    const focusableElementsSelector =
        'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    const isMobile = ServerSideService.isClientSide()
        ? window.innerWidth <= 780
        : false;

    const closeModal = (e: Event | React.MouseEvent) => {
        if (props.preventClose !== true) {
            const [modal, closeIcon] = [modalRef.current, closeIconRef.current];
            if (modal && (e.target === modal || e.currentTarget === closeIcon))
                modal.style.display = 'none';
            props.onClose && props.onClose();
        }
    };

    const setDefaultFocus = () => {
        if (props.preventClose) {
            const focusableElements = modalRef.current?.querySelectorAll(
                focusableElementsSelector
            );
            //default to first available focusable element
            if (focusableElements && focusableElements.length > 0) {
                const first = focusableElements.item(0) as HTMLElement;
                first && first.focus();
            }
        } else closeIconRef?.current?.focus();
    };

    const showModal = () => {
        const element = document.getElementsByClassName(
            props.name
        )[0] as HTMLDivElement;
        element && (element.style.display = 'block');
        setDefaultFocus();
    };

    const hideModal = () => {
        const element = document.getElementsByClassName(
            props.name
        )[0] as HTMLDivElement;
        element && (element.style.display = 'none');
    };

    const onDocumentClicked = (event: MouseEvent) => {
        if (
            modalContentRef.current &&
            modalContentRef.current.contains(event.target as Node)
        ) {
            return;
        }
        closeModal(event);
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            closeModal(event);
        }
        if (event.key === KEYBOARD_KEYS.TAB && modalRef.current) {
            loopFocusWithinComponent(event, modalRef.current);
        }
    };

    const handleTabKey = (event: any) => {
        if (event.key === KEYBOARD_KEYS.TAB && !event.shiftKey) {
            const closeElement = document.getElementsByClassName(
                'modal-close'
            )[0] as HTMLElement;
            closeElement.focus();
        }
    };

    useEffect(() => {
        if (props.isVisible === true) {
            showModal();
            setTimeout(() => {
                window.addEventListener('click', onDocumentClicked, false);
                window.addEventListener('keydown', onKeyDown, false);
            });
        }
        return () => {
            hideModal();
            window.removeEventListener('click', onDocumentClicked, false);
            window.removeEventListener('keydown', onKeyDown, false);
        };
    }, [props.isVisible]);

    return (
        <div
            ref={modalRef}
            className={`modal ${props.name}`}
            data-testid={props.name}
            role={props.role}
            style={{ display: 'none' }}
            aria-label={props['aria-label']}
        >
            <div className="modal-content" ref={modalContentRef}>
                {props.preventClose !== true && (
                    <div className="modal-header">
                        <button
                            ref={closeIconRef}
                            data-testid="test-close"
                            onClick={closeModal}
                            aria-label="Close Dialog"
                            className="modal-close"
                        >
                            <img
                                src={
                                    brand === 'ford'
                                        ? closeIcon
                                        : closeLincolnIcon
                                }
                                alt=""
                                className="close-icon"
                            />
                        </button>
                    </div>
                )}
                <div className="modal-body" onScroll={props.onBodyScroll}>
                    {props.children}
                </div>
                <div className="modal-footer">
                    {props.modalNote && (
                        <div className="modal-note">{props.modalNote}</div>
                    )}

                    {props.osbEnabled ? (
                        <>
                            <div className="osb-modal-footer-buttons">
                                {props.secondaryBtnLabel && (
                                    <FMButton
                                        type="secondary"
                                        label={props.secondaryBtnLabel}
                                        onClick={(e: React.MouseEvent) =>
                                            props.onSecondaryBtnClick &&
                                            props.onSecondaryBtnClick(e)
                                        }
                                        className="text-button"
                                    />
                                )}
                                {!isMobile && props.primaryBtnLabel && (
                                    <FMButton
                                        type="primary"
                                        label={props.primaryBtnLabel}
                                        onClick={props.onPrimaryBtnClick}
                                        disabled={!!props.disablePrimaryButton}
                                        className="text-button"
                                    />
                                )}
                            </div>
                            {isMobile && props.primaryBtnLabel && (
                                <div className="osb-modal-footer-buttons">
                                    <FMButton
                                        type="primary"
                                        label={props.primaryBtnLabel}
                                        onClick={props.onPrimaryBtnClick}
                                        disabled={!!props.disablePrimaryButton}
                                        className="text-button"
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="modal-footer-buttons">
                            {props.primaryBtnLabel && (
                                <PrimaryButton
                                    ref={primaryButtonRef}
                                    color="dark"
                                    fill="fill"
                                    chevron={props.chevron === true}
                                    onClick={props.onPrimaryBtnClick}
                                    disabled={!!props.disablePrimaryButton}
                                >
                                    {props.primaryBtnLabel}
                                </PrimaryButton>
                            )}
                            {props.secondaryBtnLabel && (
                                <PrimaryButton
                                    ref={secondaryButtonRef}
                                    className="text-button"
                                    onClick={(e: React.MouseEvent) =>
                                        props.onSecondaryBtnClick &&
                                        props.onSecondaryBtnClick(e)
                                    }
                                    color="dark"
                                    chevron={false}
                                    fill="outline"
                                >
                                    {props.secondaryBtnLabel}
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="modal-bottom"
                    tabIndex={-1}
                    onKeyUp={e => handleTabKey(e)}
                />
            </div>
        </div>
    );
};
