import { AxiosRequestConfig } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export interface ChooseRebates {
    fordCreditCombinationOffers: any[];
    quicklaneCombinationOffers: any[];
    nonCreditIndividualOffers: NonCreditIndividualOffer[];
    lincolnCreditCombinationOffers: any[];
    fordCreditIndividualOffers: FordCreditIndividualOffer[];
    nonCreditCombinationOffers: any[];
    lincolnCreditIndividualOffers: LincolnCreditIndividualOffer[];
    quicklaneIndividualOffers: QuicklaneIndividualOffer[];
}

export interface NonCreditIndividualOffer {
    rebateGroupTitle: string;
    rebateGroupDescription: string;
    rebateGroupImageURL: string;
    rebateGroupPDFFile: string;
    rebateGroupDisclaimerFile: string;
    rebateName: string;
    rebateDescription: string;
    rebateImageURL: string;
    rebateOfferID: number;
    optionalDetailsURL: string;
    rebateOfferPDFFile: string;
    rebateOfferDisclaimerFile: string;
    rebateUDBDealerFlag: string;
    rebateCreditCardOffer: string;
    rebateOAROffer: string;
    rebateCreditCardOfferType: string;
    rebateOfferCombinable: string;
    rebateCombinableGroupID?: any;
    rebateRewardsPointsOffer?: string;
    rewardsPointsAmount?: string;
    debitCardAmount: string;
    specialOfferType?: any;
    specialOfferInfo?: any;
    oarcreditAmount: string;
    rebateRewardsPointsOfferDisplayName?: string;
}

export interface FordCreditIndividualOffer {
    rebateGroupTitle: string;
    rebateGroupDescription: string;
    rebateGroupImageURL: string;
    rebateGroupPDFFile: string;
    rebateGroupDisclaimerFile: string;
    rebateName: string;
    rebateDescription: string;
    rebateImageURL: string;
    rebateOfferID: number;
    optionalDetailsURL: string;
    rebateOfferPDFFile: string;
    rebateOfferDisclaimerFile: string;
    rebateUDBDealerFlag: string;
    rebateCreditCardOffer: string;
    rebateOAROffer: string;
    rebateCreditCardOfferType: string;
    rebateRewardsPointsOffer?: string;
    rewardsPointsAmount?: string;
    rebateOfferCombinable: string;
    rebateCombinableGroupID?: any;
    debitCardAmount: string;
    specialOfferType?: any;
    specialOfferInfo?: any;
    oarcreditAmount: string;
}

export interface LincolnCreditIndividualOffer {
    rebateGroupTitle: string;
    rebateGroupDescription: string;
    rebateGroupImageURL: string;
    rebateGroupPDFFile: string;
    rebateGroupDisclaimerFile: string;
    rebateName: string;
    rebateDescription: string;
    rebateImageURL: string;
    rebateOfferID: number;
    optionalDetailsURL: string;
    rebateOfferPDFFile: string;
    rebateOfferDisclaimerFile: string;
    rebateUDBDealerFlag: string;
    rebateCreditCardOffer: string;
    rebateOAROffer: string;
    rebateCreditCardOfferType: string;
    rebateRewardsPointsOffer?: string;
    rewardsPointsAmount?: string;
    rebateOfferCombinable: string;
    rebateCombinableGroupID?: any;
    debitCardAmount: string;
    specialOfferType?: any;
    specialOfferInfo?: any;
    oarcreditAmount: string;
}
export interface QuicklaneIndividualOffer {
    rebateGroupTitle: string;
    rebateGroupDescription: string;
    rebateGroupImageURL: string;
    rebateGroupPDFFile: string;
    rebateGroupDisclaimerFile: string;
    rebateName: string;
    rebateDescription: string;
    rebateImageURL: string;
    rebateOfferID: number;
    optionalDetailsURL: string;
    rebateOfferPDFFile: string;
    rebateOfferDisclaimerFile: string;
    rebateUDBDealerFlag: string;
    rebateCreditCardOffer: string;
    rebateOAROffer: string;
    rebateCreditCardOfferType: string;
    rebateRewardsPointsOffer?: string;
    rewardsPointsAmount?: string;
    rebateOfferCombinable: string;
    rebateCombinableGroupID?: any;
    debitCardAmount: string;
    specialOfferType?: any;
    specialOfferInfo?: any;
    oarcreditAmount: string;
}
export default class ChooseMethodService {
    public async getRebateOfferService(
        dealerCode?: number,
        serviceDate?: string,
        vin?: string,
        oarDealer?: string,
        creditDealer?: string,
        brand?: string
    ): Promise<ChooseRebates> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };
        if (vin) config.headers = { ...config?.headers, vin };

        const url =
            DSL_BASE_URL +
            DSL_API_PATH.REBATE_OFFERS +
            `?dealerCode=${dealerCode}&serviceDate=${serviceDate}&creditDealer=${creditDealer}&oarDealer=${oarDealer}&brand=${brand}`;
        return HttpService.get<ChooseRebates>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }
}
