import React from 'react';

import { VehicleSelectorFds, ViewTemplate } from '../../components/sections';
import { useLocation } from 'react-router-dom';

import './vehicle-not-found-view.scss';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import {
    HomeContentProps,
    useHomeContent,
} from '../home-page/hooks/use-home-content';
import { useAppInformationContent } from '../../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import { PageType } from '../../components/sections/view-template/page-type';
import useVehicleNotFoundViewContent from './use-vehicle-not-found-view-content';

const VehicleNotFoundCTAVehicleSelector = (homeContent: HomeContentProps) =>
    homeContent && (
        <VehicleSelectorFds
            labels={{
                vinHeader: homeContent?.vehicleSelectorVinSubtitle,
                vinPlaceholder: homeContent?.vehicleSelectorVinInput,
                yearDropdown: homeContent?.vehicleSelectorYearDropdownLabel,
                modelDropdown: homeContent?.vehicleSelectorModelDropdownLabel,
                submit: homeContent?.vehicleSelectorSubmitButtonLabel,
                disclaimer: homeContent?.disclaimerLabel,
                header: homeContent?.vehicleSelectorModelSubtitle,
            }}
        />
    );
interface VehicleNotFoundProps {
    title?: string;
    description?: string;
}
const VehicleNotFoundCtaText = ({
    title,
    description,
}: VehicleNotFoundProps) => (
    <>
        <div className="vehicle-not-found-cta-text">
            <h1 className="title">{title}</h1>
            <p className="paragraph">{description}</p>
        </div>
    </>
);
export const VehicleNotFoundView = () => {
    const page: PageType = 'Vehicle Not Found';
    const content = useVehicleNotFoundViewContent();
    const homeContent = useHomeContent();
    const appInformationContent = useAppInformationContent();
    const location = useLocation();
    const getTitle = () => {
        const params = new URLSearchParams(location.search);
        const failedVin = params.get('vin');
        const year = params.get('year');
        const make = params.get('make');
        const model = params.get('model');

        if (year && make && model) return `${year} ${make} ${model}`;
        if (failedVin) {
            if (failedVin.length < 17 && content.regNoLabel)
                return `${content.regNoLabel} ${failedVin}`;
            else return `${content.vinLabel} ${failedVin}`;
        }
    };

    return (
        <>
            {content && homeContent && appInformationContent ? (
                <ViewTemplate
                    seoConfig={{
                        pageTitle: getTitle() || '',
                        applicationName: appInformationContent.applicationName,
                    }}
                    page={page}
                    breadcrumb={content.breadcrumbLabel}
                    ctaTopComponent={
                        <VehicleNotFoundCtaText
                            title={getTitle()}
                            description={content.vehicleNotFoundDescription}
                        />
                    }
                    ctaBottomComponent={
                        <VehicleNotFoundCTAVehicleSelector {...homeContent} />
                    }
                    backgroundImageDesktop={
                        process.env.REACT_APP_AEM_BASE_URL +
                        content.backgroundImageDesktop
                    }
                    backgroundImageMobile={
                        process.env.REACT_APP_AEM_BASE_URL +
                        content.backgroundImageMobile
                    }
                />
            ) : (
                <ActivityIndicator />
            )}
        </>
    );
};
export default VehicleNotFoundView;
