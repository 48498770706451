import {
    DisclaimerContent,
    DisclaimersService,
} from '../../../services/disclaimers-service/disclaimers-service';
import { FMFiftyFiftyProps, useFmFiftyFifty } from './hook/use-fm-fifty-fifty';
import { FMBannersProps, useFMBanners } from './hook/use-fm-banners';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { FMCarouselFragment } from '../../../models/experiencefragments/fm-carousel';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
interface Props {
    vehicleAttributes: VehicleAttributes;
}
export function useProcessedFmVehiclePortalContent(
    disclaimers: DisclaimerContent[],
    props: Props
) {
    const fmFiftyFiftyContentLeft = useFmFiftyFifty(
        'vehicle-data',
        getVehicleDataSelector('fm-5050-content-left', props.vehicleAttributes),
        true
    );
    const fmFiftyFiftyContentRight = useFmFiftyFifty(
        'vehicle-data',
        getVehicleDataSelector(
            'fm-5050-content-right',
            props.vehicleAttributes
        ),
        true
    );
    const fmBannerContent = useFMBanners(
        'vehicle-data',
        getVehicleDataSelector('fm-full-width-banner', props.vehicleAttributes),
        true
    );
    const [fmCarouselContent] = useExperienceContent<FMCarouselFragment>(
        'vehicles',
        getVehicleDataSelector('fm-carousel', props.vehicleAttributes),
        'fm_carousel',
        undefined,
        true
    );
    const processCardFields = (card: FMCardProps, fields: any) => {
        return {
            ...card,
            title: fields[0]?.plain,
            titleWithDisclaimer: fields[0]?.node,
            description: fields[1]?.plain,
            descriptionWithDisclaimer: fields[1]?.node,
        };
    };
    let processedFMCardFiftyFiftyLeft: FMFiftyFiftyProps | undefined;
    if (fmFiftyFiftyContentLeft) {
        const [
            processedFMFiftyFifty,
            processedDisclaimers,
        ] = DisclaimersService.processFMFiftyFifty(
            fmFiftyFiftyContentLeft,
            disclaimers
        );
        processedFMCardFiftyFiftyLeft = processedFMFiftyFifty;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }
    let processedFMCardFiftyFiftyRight: FMFiftyFiftyProps | undefined;
    let processedFMFullWidthBanner: FMBannersProps | undefined;
    if (fmBannerContent) {
        const [
            processedFMBanner,
            processedDisclaimers,
        ] = DisclaimersService.processFMBanners(fmBannerContent, disclaimers);
        processedFMFullWidthBanner = processedFMBanner;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }
    if (fmFiftyFiftyContentRight) {
        const [
            processedFMFiftyFifty,
            processedDisclaimers,
        ] = DisclaimersService.processFMFiftyFifty(
            fmFiftyFiftyContentRight,
            disclaimers
        );
        processedFMCardFiftyFiftyRight = processedFMFiftyFifty;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }
    let processedFMCarousel: FMCarouselFragment | null = null;
    if (fmCarouselContent) {
        const [
            processedFields,
            processedDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['title', 'description'],
            'disclaimer',
            disclaimers,
            fmCarouselContent
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedCards,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                fmCarouselContent.fmCards,
                (card, fields) => processCardFields(card, fields)
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            processedFMCarousel = {
                ...fmCarouselContent,
                title: processedFields[0]?.node,
                description: processedFields[1]?.node,
                fmCards: processedCards,
            };
        }
    }
    return {
        processedFMCardFiftyFiftyLeft: processedFMCardFiftyFiftyLeft,
        processedFMCardFiftyFiftyRight: processedFMCardFiftyFiftyRight,
        processedFMFullWidthBanner: processedFMFullWidthBanner,
        processedFMCarousel: processedFMCarousel,
        processedDisclaimers: disclaimers,
        useProcessedFmVehiclePortalContent: useProcessedFmVehiclePortalContent,
    };
}
