import React from 'react';
import { BreadcrumbWrapperReimagine } from '../../../common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';

interface TrailerChecklistDetailBreadcrumbProps {
    breadcrumbTitle: string;
    currentRoot: string;
    pageTitle: string;
    isWebview: boolean;
    path: string;
}

interface TrailerChecklistLandingBreadCrumbProps {
    breadcrumbText: string;
    currentRoot: string;
    path: string;
}

const TrailerCheckListDetailBreadCrumb: React.FC<TrailerChecklistLandingBreadCrumbProps> = ({
    breadcrumbText,
    currentRoot,
    path,
}) => {
    const handleClick = () => {
        sessionStorage.removeItem('selectedVin');
        window.location.href = `${currentRoot}${path}`;
    };

    return (
        <>
            <span
                className="breadcrumb-link-ellipses-expanded"
                aria-label={`Click to goto ${breadcrumbText} page`}
                onClick={handleClick}
            >
                <span>{breadcrumbText}</span>
            </span>
            <span className="back-slash">{'/'}</span>
        </>
    );
};

const TrailerChecklistDetailBreadcrumb: React.FC<TrailerChecklistDetailBreadcrumbProps> = ({
    breadcrumbTitle,
    currentRoot,
    pageTitle,
    isWebview,
    path,
}) => {
    const breadcrumbText = breadcrumbTitle
        ?.toLowerCase()
        ?.split(' ')
        ?.map(word => word.charAt(0).toUpperCase() + word.substring(1))
        ?.join(' ');

    return (
        <div>
            {isWebview ? (
                ''
            ) : (
                <BreadcrumbWrapperReimagine
                    currentPage={pageTitle}
                    hashId="support"
                    hideFirstBreadcrumb={isWebview}
                    title={
                        isWebview ? (
                            ''
                        ) : (
                            <TrailerCheckListDetailBreadCrumb
                                currentRoot={currentRoot}
                                path={path}
                                breadcrumbText={breadcrumbText}
                            />
                        )
                    }
                />
            )}
        </div>
    );
};

export default TrailerChecklistDetailBreadcrumb;
