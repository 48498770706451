import React, { useEffect, useState } from 'react';
import LightServicesFlow from '../../../components/sections/owners-osb/services-step/light-journey/light-services-flow/light-services-flow';
import {
    useOSBStep,
    useVehicleStep,
    useDealerStep,
    useServiceStep,
    useHttp,
} from '../../../hooks/owners-osb';
import './services-step-view.scss';
import { getLightVehicleData } from '../../../components/sections/owners-osb/osb-utils';
import { OsbLoader } from '../../../components/common/osb-loader/osb-loader';
import ServiceHandler from '../../../services/service-handler';
import { OsbAlertMessagePopup } from '../../../components/sections/owners-osb/common/osb-alert-message-popup/osb-alert-message-popup';
import { SERVICE_DELIVERY_TYPE } from '../../../components/sections/owners-osb/osb-constant';
import { useCampaignStatesStep } from '../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../hooks/owners-osb/use-all-osb-state';

export function ServicesStepView() {
    let isValidVoucher = false;
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbDealerStep } = useDealerStep();
    const { httpState, dispatch } = useHttp();
    const [vehicleAlertMessage, setVehicleAlertMessage] = useState<string>('');
    const [vehicleAlertSuccess, setvehicleAlertSuccess] = useState<boolean>(
        false
    );
    const bookable = useAllOSBState();
    const { setOSBCampaignStates } = useCampaignStatesStep();

    // added this method to handle VehicleAlertMessage from light vehicle section to VehicleAlertMessage component
    const osbAlertMessagePopup = (message: string, success: boolean) => {
        setVehicleAlertMessage(message);
        setvehicleAlertSuccess(success);
    };

    const closeOsbAlertPopup = () => {
        setVehicleAlertMessage('');
    };

    useEffect(() => {
        dispatch({ type: 'RESPONSE' });
        const checkVoucherDeeplinkedIsValid = async () => {
            dispatch({ type: 'REQUEST' });

            const vehicleData = getLightVehicleData(osbVehicleStep);
            const selectedDealerCode =
                osbDealerStep.selectedDealerProfile.dealerCode ||
                osbDealerStep.selectedDealerId ||
                '';
            await ServiceHandler.OsbDealerServices.getServicesList({
                dealerCode: selectedDealerCode,
                vehicleData: vehicleData,
                vouchers: osbServiceStep.voucherCode
                    ? [osbServiceStep.voucherCode]
                    : [],
                serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                    ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                    : SERVICE_DELIVERY_TYPE.WORKSHOP,
                requestSourceType: osbStep.source,
                bookable: bookable,
                DSLAPIVersion: 'V5',
            })
                .then(results => {
                    isValidVoucher = true;
                    setOSBStepPayload({ isValidVoucher: isValidVoucher });
                    setOSBServiceStepPayload({
                        dealerServiceInfo: results.value,
                        voucherDesc:
                            results.value.dealerServices.serviceVouchers
                                .length > 0
                                ? results.value.dealerServices
                                      .serviceVouchers[0].description
                                : '',
                    });
                    if (
                        Array.isArray(
                            results.value.dealerServices?.campaignStates
                        )
                    ) {
                        setOSBCampaignStates({
                            campaignStates: [
                                ...results.value.dealerServices.campaignStates,
                            ],
                        });
                    }
                    dispatch({ type: 'RESPONSE' });
                    setVehicleAlertMessage(
                        osbServiceStep.voucherCode.toUpperCase() +
                            ' ' +
                            osbStep.voucherSuccessMessagelabel
                    );
                    setvehicleAlertSuccess(true);
                })
                .catch(() => {
                    isValidVoucher = false;
                    setOSBStepPayload({ isValidVoucher: isValidVoucher });
                    setOSBServiceStepPayload({ voucherCode: '' });
                });

            dispatch({ type: 'RESPONSE' });
        };
        if (osbStep.isVoucherDeepLinked) {
            checkVoucherDeeplinkedIsValid();
        }
    }, [isValidVoucher]);

    return (
        <>
            {osbStep.isMainWrapperInitialized && !httpState.isLoading ? (
                <>
                    {vehicleAlertMessage && (
                        <OsbAlertMessagePopup
                            alertMessage={vehicleAlertMessage}
                            alertSuccess={vehicleAlertSuccess}
                            closeOsbAlertPopup={closeOsbAlertPopup}
                        />
                    )}

                    <div className="services-step-view-container">
                        <LightServicesFlow
                            osbAlertMessagePopup={osbAlertMessagePopup}
                        />
                    </div>
                </>
            ) : (
                <OsbLoader osbEmptyContentHeight={true} />
            )}
        </>
    );
}
