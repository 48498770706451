import React, { useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { InfoIconToolTip } from '../../../../common/info-icon-tooltip/info-icon-tooltip';
import {
    useOSBStep,
    useViewport,
    useVehicleStep,
} from '../../../../../hooks/owners-osb';
import { OSBVideoImageOverlay } from '../osb-overlay/osb-video-image-overlay';
import './osb-file-uploader-lincoln.scss';
import { OSBOverlayImage } from '../osb-overlay/osb-overlay-image';
import { getObjectFromAEMJson } from '../../osb-utils';
import { SERVICES_STEP_KEYS, SERVICE_FLOW } from '../../osb-constant';
import { FileUploadPopup } from './drag-fileUpload-popup';
import { triggerImageUploadAnalytics } from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';

export interface FileProperties {
    fileName: string;
    fileSize: number;
    fileType: string;
}

export interface AcceptedFileInfo extends FileProperties {
    encodedFileContent: string;
}

interface RejectedFileInfo extends FileProperties {
    rejectionMessage: string;
}

interface Props {
    uploadImageInStore?: AcceptedFileInfo[];
    setUploadImageInStore: (payload: {
        [key: string]: AcceptedFileInfo[];
    }) => void;
    serviceAemContent?: any;
}

export const OSBFileUploader = (props: Props) => {
    const { osbStep } = useOSBStep();
    const { osbVehicleStep } = useVehicleStep();
    const [fireEvents] = useAnalytics();
    const fileUpload = useRef<HTMLInputElement>(null);
    const [acceptedFiles, setAcceptedFiles] = useState<AcceptedFileInfo[]>([]);
    const [rejectedFiles, setRejectedFiles] = useState<RejectedFileInfo[]>([]);
    const [fileToRemove, setFileToRemove] = useState('');
    const [isImageOverlayOpened, setIsImageOverlayOpened] = useState(false);
    const [fileContent, setFileContent] = useState('');
    const [imageFileName, setImageFileName] = useState('');

    const fileExtensionFromAEM = getObjectFromAEMJson(
        SERVICES_STEP_KEYS.IMAGE_ALLOWED_EXT_ARRAY,
        props.serviceAemContent
    );
    const fileExtensions = fileExtensionFromAEM
        ? fileExtensionFromAEM.replace(/\s/g, '').split(',')
        : SERVICE_FLOW.DEFAULT_IMAGE_EXT;

    let maxAcceptedFileCount = acceptedFiles.length;
    const { isMobileView } = useViewport();
    const [openModel, setOpenModel] = useState(false);
    useEffect(() => {
        if (props.uploadImageInStore && props.uploadImageInStore?.length > 0) {
            setAcceptedFiles(props.uploadImageInStore);
        }
    }, [props.uploadImageInStore]);

    const resizeFile = async (file: File) =>
        new Promise(resolve => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                'jpeg',
                100,
                0,
                uri => {
                    resolve(uri);
                },
                'base64'
            );
        });
    const checkIfValidDuplicateFile = (
        fileName: string,
        acceptedFileList: AcceptedFileInfo[]
    ) => {
        return (
            acceptedFileList.filter(
                acceptedFile => acceptedFile.fileName.toLowerCase() === fileName
            ).length > 0
        );
    };

    const checkIfRejectedDuplicateFile = (
        fileName: string,
        rejectedFileList: RejectedFileInfo[]
    ) => {
        return (
            rejectedFileList.filter(
                rejectedFile => rejectedFile.fileName.toLowerCase() === fileName
            ).length > 0
        );
    };
    const validateFile = (file: File, maxFileSize: any) => {
        return file?.size && file.size / (1024 * 1024) < Number(maxFileSize);
    };

    const validateFileExtension = (fileExtension: string) => {
        return fileExtensions.includes(fileExtension.toLowerCase());
    };
    const checkDuplicateFiles = (
        file: File,
        isValidFile: number | boolean,
        acceptedFileList: AcceptedFileInfo[],
        rejectedFileList: RejectedFileInfo[]
    ) => {
        let isValidDuplicateFile = false;
        let isRejectDuplicateFile = false;

        if (isValidFile) {
            isValidDuplicateFile = checkIfValidDuplicateFile(
                file.name.toLowerCase(),
                acceptedFileList
            );
        } else {
            isRejectDuplicateFile = checkIfRejectedDuplicateFile(
                file.name.toLowerCase(),
                rejectedFileList
            );
        }

        return { isValidDuplicateFile, isRejectDuplicateFile };
    };
    const checkMaxAcceptedFileCount = (
        maxAcceptedFileCount: number,
        maxFileCount: any
    ) => {
        return maxAcceptedFileCount + 1 <= Number(maxFileCount);
    };
    const processValidFile = async (
        file: File,
        acceptedFileList: {
            fileName: string;
            fileSize: any;
            encodedFileContent: string;
            fileType: string;
        }[]
    ) => {
        maxAcceptedFileCount++;
        const compressedFile = await resizeFile(file);
        acceptedFileList.push({
            fileName:
                file.name
                    .toString()
                    .split('.')
                    .slice(0, -1)
                    .join('.') + '.jpeg',
            fileSize: file.size,
            encodedFileContent: String(compressedFile),
            fileType: 'NEW',
        });
    };

    const processRejectedFile = (
        file: File,
        rejectedFileList: {
            fileName: any;
            fileSize: any;
            rejectionMessage: any;
            fileType: string;
        }[]
    ) => {
        rejectedFileList.push({
            fileName: file.name,
            fileSize: file.size,
            rejectionMessage: getObjectFromAEMJson(
                SERVICES_STEP_KEYS.IMAGE_UPLOAD_ERROR,
                props.serviceAemContent
            ),
            fileType: 'NEW',
        });
    };
    const handleFileProcessing = async (
        file: File,
        isValidFile: number | boolean,
        acceptedFileList: AcceptedFileInfo[],
        rejectedFileList: RejectedFileInfo[]
    ) => {
        if (isValidFile) {
            await processValidFile(file, acceptedFileList);
            triggerImageUploadAnalytics(
                'upload complete',
                osbVehicleStep,
                fireEvents
            );
        } else {
            processRejectedFile(file, rejectedFileList);
            triggerImageUploadAnalytics('error', osbVehicleStep, fireEvents);
        }
    };
    const processFile = async (
        file: File,
        maxFileSize: any,
        maxFileCount: any,
        acceptedFileList: AcceptedFileInfo[],
        rejectedFileList: RejectedFileInfo[]
    ) => {
        const fileExtension = file?.name?.split('.')?.pop();
        let isValidFile = validateFile(file, maxFileSize);

        if (isValidFile && fileExtension) {
            isValidFile = validateFileExtension(fileExtension);
        }

        const {
            isValidDuplicateFile,
            isRejectDuplicateFile,
        } = checkDuplicateFiles(
            file,
            isValidFile,
            acceptedFileList,
            rejectedFileList
        );

        if (
            checkMaxAcceptedFileCount(maxAcceptedFileCount, maxFileCount) &&
            !isRejectDuplicateFile &&
            !isValidDuplicateFile
        ) {
            await handleFileProcessing(
                file,
                isValidFile,
                acceptedFileList,
                rejectedFileList
            );
        }
    };
    const updateAcceptedAndRejectedFiles = (
        acceptedFileList: AcceptedFileInfo[],
        rejectedFileList: RejectedFileInfo[]
    ) => {
        setAcceptedFiles([...acceptedFiles, ...acceptedFileList]);
        setRejectedFiles([...rejectedFiles, ...rejectedFileList]);
    };

    const updateUploadedImageInStore = (
        acceptedFileList: AcceptedFileInfo[]
    ) => {
        props.setUploadImageInStore({
            uploadedImage: [...acceptedFiles, ...acceptedFileList],
        });
    };
    const setFileUploadList = async (files: FileList) => {
        const acceptedFileList: AcceptedFileInfo[] = [];
        const rejectedFileList: RejectedFileInfo[] = [];
        const maxFileCountAEM = getObjectFromAEMJson(
            SERVICES_STEP_KEYS.IMAGE_UPLOAD_MAX_COUNT,
            props.serviceAemContent
        );
        const maxFileCount = maxFileCountAEM
            ? maxFileCountAEM
            : SERVICE_FLOW.DEFAULT_MAX_FILE_COUNT;
        const maxFileSizeAEM = getObjectFromAEMJson(
            SERVICES_STEP_KEYS.IMAGE_UPLOAD_MAX_SIZE,
            props.serviceAemContent
        );
        const maxFileSize = maxFileSizeAEM
            ? maxFileSizeAEM
            : SERVICE_FLOW.DEFAULT_MAX_FILE_SIZE;

        for (const file of Array.from(files)) {
            await processFile(
                file,
                maxFileSize,
                maxFileCount,
                acceptedFileList,
                rejectedFileList
            );
        }

        updateAcceptedAndRejectedFiles(acceptedFileList, rejectedFileList);
        updateUploadedImageInStore(acceptedFileList);
    };

    const onFileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event?.dataTransfer?.files;
        if (files?.length > 0) setFileUploadList(files);
        setOpenModel(false);
    };

    const onFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const onDragFileEnter = (event: React.DragEvent<HTMLDivElement>) => {
        setOpenModel(true);
        event.preventDefault();
    };

    const handleUploadedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const files = event?.target?.files;
        if (files && files?.length > 0) {
            setFileUploadList(files);
        }
    };

    const onInputClick = (
        event: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
        const element = event.target as HTMLInputElement;
        element.value = '';
        triggerImageUploadAnalytics('select file', osbVehicleStep, fireEvents);
    };

    const handleUpload = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.preventDefault();
        fileUpload?.current?.click();
    };

    const ConfirmRemove = (fileName: string) => {
        setFileToRemove(fileName);
    };

    const CancelFileRemoval = () => {
        setFileToRemove('');
    };

    const ProceedFileRemoval = (fileName: string) => {
        const existingAcceptedFiles = acceptedFiles.filter(
            file => file.fileName != fileName
        );
        setAcceptedFiles(existingAcceptedFiles);
        props.setUploadImageInStore({ uploadedImage: existingAcceptedFiles });
        setFileToRemove('');
        triggerImageUploadAnalytics('remove file', osbVehicleStep, fireEvents);
    };

    const showImageOverlay = (file: AcceptedFileInfo) => {
        setIsImageOverlayOpened(true);
        setFileContent(file.encodedFileContent);
        setImageFileName(file.fileName);
        triggerImageUploadAnalytics('view file', osbVehicleStep, fireEvents);
    };

    const hideImageOverlay = () => {
        setIsImageOverlayOpened(false);
        setFileContent('');
        setImageFileName('');
    };

    const setStatus = (status: boolean) => {
        setOpenModel(status);
    };

    const getAcceptedFiles = (): JSX.Element => {
        return (
            <>
                {acceptedFiles.map((file: AcceptedFileInfo) => {
                    return (
                        <div
                            key={file.fileName}
                            className="osb-fu-selected-files"
                        >
                            <div className="osb-fu-selected-file-status"></div>
                            <div className="osb-fu-selected-file-name">
                                {file.fileName}
                            </div>
                            {fileToRemove === file.fileName ? (
                                <>
                                    <div className="osb-fu-selected-file-confirm-remove">
                                        {getObjectFromAEMJson(
                                            SERVICES_STEP_KEYS.IMAGE_REMOVE_LINK,
                                            props.serviceAemContent
                                        )}
                                    </div>
                                    <span>
                                        <a
                                            data-testid="RemoveLinkForUploadedFile"
                                            className="osb-fu-selected-file-remove-decision"
                                            onClick={() =>
                                                ProceedFileRemoval(
                                                    file.fileName
                                                )
                                            }
                                        >
                                            {getObjectFromAEMJson(
                                                SERVICES_STEP_KEYS.IMAGE_REMOVE_YES_LINK,
                                                props.serviceAemContent
                                            )}
                                        </a>
                                        <a
                                            data-testid="CancelLinkForFileRemoval"
                                            className="osb-fu-selected-file-remove-decision"
                                            onClick={() => CancelFileRemoval()}
                                        >
                                            {getObjectFromAEMJson(
                                                SERVICES_STEP_KEYS.IMAGE_REMOVE_NO_LINK,
                                                props.serviceAemContent
                                            )}
                                        </a>
                                    </span>
                                </>
                            ) : (
                                <>
                                    {osbStep.isRetrieveFlow &&
                                    file.fileType !== 'NEW' ? (
                                        <div className="osb-fu-selected-file-remove">
                                            {' '}
                                        </div>
                                    ) : (
                                        <a
                                            data-testid="RemoveLinkForUploadedFile"
                                            className="osb-fu-selected-file-remove"
                                            onClick={() =>
                                                ConfirmRemove(file.fileName)
                                            }
                                        >
                                            {getObjectFromAEMJson(
                                                SERVICES_STEP_KEYS.IMAGE_REMOVE_LINK,
                                                props.serviceAemContent
                                            )}
                                        </a>
                                    )}

                                    <a
                                        data-testid="ViewLinkUploadedFile"
                                        className="osb-fu-selected-file-view"
                                        onClick={() => showImageOverlay(file)}
                                    >
                                        {getObjectFromAEMJson(
                                            SERVICES_STEP_KEYS.IMAGE_VIEW_LINK,
                                            props.serviceAemContent
                                        )}
                                    </a>
                                </>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    const getRejectedFiles = (): JSX.Element => {
        return (
            <>
                {rejectedFiles.map((file: RejectedFileInfo) => {
                    return (
                        <div
                            key={file.fileName}
                            className="osb-fu-rejected-files"
                        >
                            <div className="osb-fu-rejected-file-status"></div>
                            <div className="osb-fu-rejected-file-name">
                                {file.fileName}
                            </div>
                            <a className="osb-fu-rejected-message">
                                {file.rejectionMessage}
                            </a>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div
            className={`osb-file-uploader-container brand-${osbStep.brandName}`}
            data-testid="component-container"
        >
            <div
                className="osb-fu-main-title-container"
                data-testid="component-main-title-container"
            >
                <label className="osb-fu-main-title">
                    {getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.IMAGE_UPLOAD_HEADER_TITLE,
                        props.serviceAemContent
                    )}
                </label>
                <InfoIconToolTip
                    osbServiceTooltip={true}
                    tooltipInfoIconClass={'dark'}
                    tooltipContent={getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.IMAGE_UPLOAD_HEADER_INFO_ICON_TEXT,
                        props.serviceAemContent
                    )}
                    customDismissTooltipAriaLabel={
                        osbStep.osbDismissTooltipAriaLabel
                    }
                    customShowTooltipAriaLabel={osbStep.osbShowTooltipAriaLabel}
                />
            </div>
            <div className="osb-drag-drop-container">
                <div className="osb-drag-drop-title">
                    {getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.IMAGE_UPLOAD_SUB_TITLE,
                        props.serviceAemContent
                    )}
                </div>
                {!isMobileView ? (
                    <div
                        className="osb-drag-drop-panel"
                        onDrop={event => onFileDrop(event)}
                        onDragEnter={event => onDragFileEnter(event)}
                        onDragOver={event => onFileDragOver(event)}
                    >
                        {openModel ? (
                            <div>
                                <FileUploadPopup
                                    setStatus={setStatus}
                                    openPopupData={true}
                                    serviceAemContent={props.serviceAemContent}
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <div>
                            {getObjectFromAEMJson(
                                SERVICES_STEP_KEYS.IMAGE_UPLOAD_DROP_ZONE_TEXT,
                                props.serviceAemContent
                            )}{' '}
                            <input
                                id="osbInputFileUpload"
                                aria-label="osbInputFileUpload"
                                type="file"
                                ref={fileUpload}
                                onChange={e => handleUploadedFile(e)}
                                onClick={e => onInputClick(e)}
                            />
                            <a
                                data-testid="SelectFilelink"
                                className="osb-fu-upload-link"
                                onClick={e => handleUpload(e)}
                            >
                                {getObjectFromAEMJson(
                                    SERVICES_STEP_KEYS.IMAGE_UPLOAD_SELECT_FILE_TEXT,
                                    props.serviceAemContent
                                )}
                            </a>
                        </div>
                    </div>
                ) : (
                    <div
                        className="osb-drag-drop-panel"
                        onDrop={event => onFileDrop(event)}
                        onDragEnter={event => onDragFileEnter(event)}
                        onDragOver={event => onFileDragOver(event)}
                    >
                        <div>
                            <input
                                id="osbInputFileUpload"
                                type="file"
                                aria-label="osbInputFileUpload"
                                ref={fileUpload}
                                onChange={e => handleUploadedFile(e)}
                                onClick={e => onInputClick(e)}
                            />
                            <a
                                data-testid="SelectFilelink"
                                className="osb-fu-upload-link"
                                onClick={e => handleUpload(e)}
                            >
                                {getObjectFromAEMJson(
                                    SERVICES_STEP_KEYS.IMAGE_UPLOAD_SELECT_FILE_TEXT,
                                    props.serviceAemContent
                                )}
                            </a>
                        </div>
                    </div>
                )}

                <div className="osb-drag-drop-valid-file-label">
                    {getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.IMAGE_UPLOAD_DOCUMENT_TYPE,
                        props.serviceAemContent
                    )}{' '}
                    {!isMobileView ? (
                        getObjectFromAEMJson(
                            SERVICES_STEP_KEYS.IMAGE_UPLOAD_DOCUMENT_TYPE_VALUE,
                            props.serviceAemContent
                        )
                    ) : (
                        <div>
                            {getObjectFromAEMJson(
                                SERVICES_STEP_KEYS.IMAGE_UPLOAD_DOCUMENT_TYPE_VALUE,
                                props.serviceAemContent
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="osb-fu-status-grid-container">
                {getAcceptedFiles()}
                {getRejectedFiles()}
            </div>
            {isImageOverlayOpened && fileContent && (
                <OSBVideoImageOverlay
                    isOpen={isImageOverlayOpened}
                    hideVideoOverlay={hideImageOverlay}
                    hasCloseButton={true}
                    videoImageTitle={imageFileName}
                >
                    <OSBOverlayImage
                        fileContent={fileContent}
                        fileName={imageFileName}
                    />
                </OSBVideoImageOverlay>
            )}
        </div>
    );
};
