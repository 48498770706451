import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import PrimaryButton from '../../../../common/primary-button/primary-button';
import './category-5050-carousel.scss';
import { SwipePoint, useSwipe } from '../../../../../hooks/use-swipe';
import { HorizontalCard } from './horizontal-card/horizontal-card';
import { Articles } from '../../../../../models/experiencefragments/category-view';
import { FdsChevron } from '../../../../../components/common/chevron/fds-chevron';
import { SecondaryButton } from '../../../../common';

export const Category5050Carousel = (props: Articles) => {
    const cards = props.articles.slice(0, 7);
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const maxCardIndex = cards.length - 1;

    const moveToNextCard = () => {
        if (currentCardIndex < maxCardIndex) {
            setCurrentCardIndex(currentCardIndex + 1);
        }
    };
    const moveToPreviousCard = () => {
        if (currentCardIndex > 0) {
            setCurrentCardIndex(currentCardIndex - 1);
        }
    };
    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX, setSwipeOffsetX]
    );
    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint) => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );
    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (let cardIndex = 0; cardIndex <= maxCardIndex; cardIndex++) {
            const cardPosX =
                cardIndex *
                (cardRef.current?.getBoundingClientRect().width || 0);
            if (
                Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
            ) {
                closestCardIndex = cardIndex;
                closestCardX = cardPosX;
            }
        }
        setCurrentCardIndex(closestCardIndex);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        cardRef,
        swipeOffsetX,
        setSwipeStartX,
        setCurrentCardIndex,
        maxCardIndex,
        cardsPositionX,
    ]);
    const containerRef = useSwipe(
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );
    const updateCardPositions = useCallback(() => {
        setCardsPositionX(
            -currentCardIndex *
                (cardRef.current?.getBoundingClientRect().width || 0)
        );
    }, [cardRef, currentCardIndex, setCardsPositionX]);

    useEffect(() => {
        window.addEventListener('resize', updateCardPositions);
        return () => {
            window.removeEventListener('resize', updateCardPositions);
        };
    }, [updateCardPositions]);

    useLayoutEffect(() => {
        updateCardPositions();
    }, [currentCardIndex, updateCardPositions]);

    return (
        <article className="category-5050-carousel-container">
            <section className="category-5050-carousel" ref={containerRef}>
                <div className="category-5050-carousel-heading">
                    <div className="category-5050-carousel__title">
                        {props.sectionHeading}
                    </div>
                    {props.cta?.title && props.cta?.url && (
                        <SecondaryButton
                            linkTarget={props.cta?.targetBlank ? '_blank' : ''}
                            link={props.cta?.url}
                            noChevron={false}
                            aria-label={props.cta?.ariaLabel}
                            dataTestId="explore-all-link"
                        >
                            {props.cta?.title}
                        </SecondaryButton>
                    )}
                </div>
                <div
                    className="category-5050-carousel__cards"
                    style={{
                        transform: `translateX(${cardsPositionX +
                            swipeOffsetX}px)`,
                        transition: swipeStartX
                            ? 'none'
                            : 'transform 300ms linear',
                    }}
                >
                    {cards.map((article, index) => (
                        <div
                            ref={cardRef}
                            key={index}
                            className="category-5050-carousel__card-wrapper"
                        >
                            <HorizontalCard
                                {...article}
                                categoryType={props.categoryType}
                                categoryPath={props.categoryPath}
                            />
                        </div>
                    ))}
                </div>
            </section>
            {maxCardIndex > 0 && (
                <section className="category-5050-carousel__controls">
                    <PrimaryButton
                        className={[
                            'category-5050-carousel-button',
                            currentCardIndex === 0 ? 'disabled' : '',
                        ]
                            .join(' ')
                            .trim()}
                        color="dark"
                        fill="fill"
                        chevron={false}
                        onClick={moveToPreviousCard}
                        ariaLabel={props.leftButtonAriaLabel || 'previous'}
                    >
                        <span className="category-5050-carousel__arrow">
                            <FdsChevron type="unfilled" direction="left" />
                        </span>
                    </PrimaryButton>
                    <div className="category-5050-carousel__indicator">
                        <span
                            aria-live={(props.ariaLive as any) || 'polite'}
                            role={props.role || 'alert'}
                            aria-label={props.ariaLabel || 'current card'}
                        >
                            {`${currentCardIndex + 1}-${props.paginationText ||
                                ''}${maxCardIndex + 1}`}
                        </span>
                    </div>

                    <PrimaryButton
                        className={`category-5050-carousel-button ${
                            currentCardIndex === maxCardIndex ? 'disabled' : ''
                        }`}
                        color="dark"
                        fill="fill"
                        chevron={false}
                        onClick={moveToNextCard}
                        ariaLabel={props.rightButtonAriaLabel || 'next'}
                    >
                        <span className="category-5050-carousel__arrow">
                            <FdsChevron type="unfilled" direction="right" />
                        </span>
                    </PrimaryButton>
                </section>
            )}
        </article>
    );
};
