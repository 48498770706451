import React from 'react';
import {
    StringStatus,
    TPMS,
    Vehiclestatus,
} from '../../../models/connected-vehicle';
import ConnectedVehicle from '../connected-vehicle/connected-vehicle';
import './connected-vehicle-tire-pressure.scss';
import { TirePressureContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import { VehicleType } from '../../../services/vehicle-type-service/vehicle-type-service';

interface Props {
    vin: string;
    connectedVehicleInfo: Vehiclestatus;
    vehicleType: VehicleType;
}

const ConnectedVehicleTirePressure = (props: Props & TirePressureContent) => {
    const tpms: TPMS = props.connectedVehicleInfo.TPMS;
    const allTiresStatus: StringStatus =
        props.connectedVehicleInfo.tirePressure;
    const ceilValue = (number?: string) => {
        if (tpms && number && !Number.isNaN(Number(number))) {
            const pressureUnits =
                String(tpms.uomPressure).toLocaleLowerCase() == 'kpa'
                    ? 'kPa'
                    : String(tpms.uomPressure).toLocaleLowerCase();
            if (pressureUnits?.toLocaleLowerCase() === 'bar')
                return (
                    Math.round((Number(number) + Number.EPSILON) * 100) / 100
                );
            return Math.ceil(Number(number));
        }
        return number;
    };

    const requiredTpmsAttributesAvailable = (tpms: TPMS): boolean => {
        return (
            tpms?.leftFrontTireStatus?.value !== null &&
            tpms?.leftFrontTirePressure?.value !== null &&
            tpms?.innerLeftRearTireStatus?.value !== null &&
            tpms?.outerLeftRearTirePressure?.value !== null &&
            tpms?.rightFrontTireStatus?.value !== null &&
            tpms?.rightFrontTirePressure?.value !== null &&
            tpms?.innerRightRearTireStatus?.value !== null &&
            tpms?.outerRightRearTirePressure?.value !== null
        );
    };

    return (
        <>
            {props?.vehicleType?.isTpmAvailable &&
                requiredTpmsAttributesAvailable(tpms) && (
                    <ConnectedVehicle
                        {...props}
                        iconPath={''}
                        headerType={
                            allTiresStatus?.value !== 'STATUS_GOOD'
                                ? 'moderate'
                                : 'none'
                        }
                        header={props.title}
                    >
                        <div className="flex-container">
                            <div className="flex-item">
                                <span
                                    className={
                                        tpms.leftFrontTireStatus?.value?.toLowerCase() !==
                                        'normal'
                                            ? 'tire-value'
                                            : ''
                                    }
                                >
                                    {ceilValue(
                                        tpms.leftFrontTirePressure?.value
                                    )}
                                </span>
                                <span
                                    className={
                                        tpms.innerLeftRearTireStatus?.value?.toLowerCase() !==
                                        'normal'
                                            ? 'tire-value'
                                            : ''
                                    }
                                >
                                    {ceilValue(
                                        tpms.outerLeftRearTirePressure?.value
                                    )}
                                </span>
                            </div>
                            <img
                                className={'car-image'}
                                alt={'aria-label'}
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    props.icon
                                }
                            />
                            <div className="flex-item">
                                <span
                                    className={
                                        tpms.rightFrontTireStatus?.value?.toLowerCase() !==
                                        'normal'
                                            ? 'tire-value'
                                            : ''
                                    }
                                >
                                    {ceilValue(
                                        tpms.rightFrontTirePressure?.value
                                    )}
                                </span>
                                <span
                                    className={
                                        tpms.innerRightRearTireStatus?.value?.toLowerCase() !==
                                        'normal'
                                            ? 'tire-value'
                                            : ''
                                    }
                                >
                                    {ceilValue(
                                        tpms.outerRightRearTirePressure?.value
                                    )}
                                </span>
                            </div>
                        </div>
                    </ConnectedVehicle>
                )}
        </>
    );
};

export default ConnectedVehicleTirePressure;
