import React, { useState } from 'react';
import { Link } from '../../common';
import { EuLogin } from './eu-login/eu-login';
import './eu-header.scss';
import EuSidebarMenu from '../../../../src/components/sections/eu-header/eu-sidebar-menu/eu-sidebar-menu';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { HeaderFragment } from '../../../models/experiencefragments/header';
import { SiteSearch } from '../search/site-search';
import Hamburger from 'hamburger-react';

const EuHeader = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showLogo, setShowLogo] = useState(true);
    const [activeDesktopSubnav, setActiveDesktopSubnav] = useState('');
    const [experienceContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );

    function getModalStatus() {
        setShowLogo(prevState => !prevState);
    }

    const toggleMenu = () => {
        const bodyElem = document.querySelector('body') as HTMLBodyElement;
        bodyElem.classList.toggle('no-scroll-sm');
        setMenuOpen(!menuOpen);
    };
    const handleQuickClick = (navSubTitle: any) => {
        if (activeDesktopSubnav && navSubTitle === activeDesktopSubnav) {
            setActiveDesktopSubnav('');
        }
    };

    const handleClick = () => {
        if (activeDesktopSubnav) {
            const containerElem = document.querySelector(
                '.show-subnav'
            ) as HTMLBodyElement;
            containerElem?.classList?.toggle('visible-xs');
        }
    };

    return (
        <header className="euheader" id="euheaderId" data-testid="header">
            <div className="euheadernav" data-testid="menu-toggle">
                {experienceContent && (
                    <>
                        <div
                            className="visible-in-mobile"
                            data-testid="menu-toggle"
                        >
                            <Hamburger
                                toggled={menuOpen}
                                toggle={toggleMenu}
                                size={18}
                                color="#666666"
                                distance="sm"
                            />
                            <EuSidebarMenu
                                isOpen={menuOpen}
                                className="mobile"
                                toggleMenu={toggleMenu}
                            />
                        </div>
                        <div
                            className="nav-header"
                            style={{
                                zIndex: showLogo ? '99' : '-99',
                            }}
                        >
                            <a
                                tabIndex={0}
                                href={experienceContent.logo.url}
                                target={
                                    experienceContent.logo.targetBlank
                                        ? '_blank'
                                        : '_self'
                                }
                                className="eulogo-link"
                                aria-label={experienceContent.logo.ariaLabel}
                                rel="noopener noreferrer"
                            >
                                <span
                                    style={{
                                        backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${experienceContent.logo.logoPath})`,
                                    }}
                                    className={'euford-logo'}
                                />
                            </a>
                        </div>
                        <span className="eyebrow">
                            {experienceContent['main-nav-right'].utilityLinks &&
                                experienceContent[
                                    'main-nav-right'
                                ].utilityLinks.map(nav => {
                                    return (
                                        <Link
                                            key={nav.title}
                                            href={nav.url}
                                            aria-label={nav.title}
                                        >
                                            <span className="eyebrowtext">
                                                {nav.title}
                                            </span>
                                        </Link>
                                    );
                                })}
                            {experienceContent['main-nav-right'].signIn && (
                                <EuLogin
                                    content={
                                        experienceContent['main-nav-right']
                                            .signIn
                                    }
                                />
                            )}
                            {experienceContent['main-nav-right'].search && (
                                <span className="eusite-search-container">
                                    <SiteSearch
                                        content={
                                            experienceContent['main-nav-right']
                                                .search
                                        }
                                        isModalOpen={getModalStatus}
                                    ></SiteSearch>
                                </span>
                            )}
                        </span>

                        <div className="block-header">
                            <div className="left-nav">
                                {experienceContent['main-nav-left'].map(
                                    navsub => {
                                        if (
                                            !navsub.categories &&
                                            navsub.noFlyout
                                        ) {
                                            return (
                                                <nav
                                                    key={navsub.title}
                                                    aria-label={
                                                        navsub.ariaLabel
                                                    }
                                                >
                                                    <span className="desktop">
                                                        <Link
                                                            href={navsub.url}
                                                            className="desktop first-header-link"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {navsub.title}
                                                        </Link>
                                                    </span>
                                                </nav>
                                            );
                                        } else if (
                                            navsub.categories &&
                                            !navsub.noFlyout
                                        ) {
                                            return (
                                                <nav
                                                    key={navsub.title}
                                                    aria-label={
                                                        navsub.ariaLabel
                                                    }
                                                >
                                                    <button
                                                        key={navsub.title}
                                                        className={`desktop ${
                                                            activeDesktopSubnav ===
                                                            navsub.title
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            setActiveDesktopSubnav(
                                                                navsub.title
                                                            );
                                                            handleClick();
                                                            handleQuickClick(
                                                                navsub.title
                                                            );
                                                        }}
                                                    >
                                                        <span>
                                                            <Link
                                                                className="main-nav"
                                                                href={
                                                                    navsub.url
                                                                }
                                                                aria-label={
                                                                    navsub.ariaLabel
                                                                }
                                                            >
                                                                {navsub.title}
                                                            </Link>
                                                        </span>
                                                    </button>
                                                    <div
                                                        className={`subNav${
                                                            navsub.title
                                                        } desktop-subnav ${
                                                            activeDesktopSubnav ===
                                                            navsub.title
                                                                ? 'show-subnav'
                                                                : 'hide-nav'
                                                        }`}
                                                    >
                                                        <div
                                                            className="background-overlay"
                                                            onClick={() => {
                                                                setActiveDesktopSubnav(
                                                                    ''
                                                                );
                                                            }}
                                                        />
                                                        <ul className="main-nav-item sidebar-subnav">
                                                            {navsub.categories.map(
                                                                subnav => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                subnav.categoryTitle
                                                                            }
                                                                            className="eusubnav-title-container"
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    'eudrawer-title'
                                                                                }
                                                                            >
                                                                                {
                                                                                    subnav.categoryTitle
                                                                                }
                                                                            </span>

                                                                            <ul>
                                                                                {subnav.categoryItems.map(
                                                                                    sideNav => {
                                                                                        return (
                                                                                            <li
                                                                                                key={
                                                                                                    sideNav.title
                                                                                                }
                                                                                                className="subnav-link"
                                                                                            >
                                                                                                <Link
                                                                                                    target={
                                                                                                        sideNav.targetBlank
                                                                                                            ? '_blank'
                                                                                                            : ''
                                                                                                    }
                                                                                                    href={
                                                                                                        sideNav.url
                                                                                                    }
                                                                                                    aria-label={
                                                                                                        sideNav.ariaLabel
                                                                                                    }
                                                                                                >
                                                                                                    <span className="subnavtitle">
                                                                                                        {
                                                                                                            sideNav.title
                                                                                                        }
                                                                                                    </span>
                                                                                                </Link>
                                                                                            </li>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </nav>
                                            );
                                        }
                                    }
                                )}
                            </div>

                            <div className="mobile-fill" />
                        </div>
                    </>
                )}
            </div>
        </header>
    );
};
export default EuHeader;
