import React from 'react';
import FMConnectedVehicle from '../fm-connected-vehicle/fm-connected-vehicle';
import { OilLifeContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import { Vehiclestatus } from '../../../models/connected-vehicle';

interface Props {
    connectedVehicleInfo: Vehiclestatus;
}

const FMConnectedVehicleOilLife = (props: Props & OilLifeContent) => {
    const oilLife = Math.round(props?.connectedVehicleInfo?.oil?.oilLifeActual);
    const progressBarColor = oilLife <= 5 ? 'red' : 'green';

    return (
        <>
            {oilLife ? (
                <FMConnectedVehicle
                    className="fm-oil-life"
                    header={props.title}
                    iconPath={props.icon}
                    number={`${oilLife}%`}
                    showProgressBar={true}
                    progressBarPercentage={oilLife}
                    progressBarColor={progressBarColor}
                    progressBarHeight={12}
                    progressBarWidth={64}
                    progressBarAria={'oil-life-percent'}
                    progressBarIsGradient={false}
                />
            ) : (
                <></>
            )}
        </>
    );
};
export default FMConnectedVehicleOilLife;
