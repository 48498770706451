import React from 'react';
import './livestream-upcoming-schedule.scss';
import { PrimaryButton } from '../../../../../components/common';
import calenderIcon from '../../../../../assets/calender.svg';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import { ModelNotSupportedProps } from '../../../../../views/live-stream-view/hook/use-live-stream-view';
import { ScheduleItemsInterface } from '../../gvt-interfaces';
import { useAnalytics } from '../../../../../hooks/use-analytics';

interface Props {
    scheduleItems: any;
    liveStreami18nAEM?: any;
    vehicleAttributes?: VehicleAttributes;
    modelNotSupportedContent?: ModelNotSupportedProps | null;
}

export const LivestreamUpcomingSchedule = (props: Props) => {
    const getFormattedDate = (date: any) => {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];
        const year = date.getFullYear();
        return `${monthName} ${day}, ${year}`;
    };

    const getFormattedTime = (time: any) => {
        let hours = time.getHours();
        let minutes = time.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
    };

    const getTimeZoneString = (dateInput: Date) => {
        const dateObject = dateInput || new Date();
        const dateString = dateObject + '';
        let timeZoneAbbr: any =
            dateString.match(/\(([^)]+)\)$/) ||
            dateString.match(/([A-Z]+) [\d]{4}$/);

        if (timeZoneAbbr) {
            timeZoneAbbr = timeZoneAbbr[1].match(/[A-Z]/g).join('');
        }

        return timeZoneAbbr;
    };

    const formatDate = (itemDate: any) => {
        const date = new Date(itemDate);
        return {
            date: getFormattedDate(date),
            time: getFormattedTime(date),
            timeZone: getTimeZoneString(date),
        };
    };

    const htmlDecode = (input: any) => {
        if (serverSideService.isClientSide()) {
            const e = document.createElement('textarea');
            e.innerHTML = input;
            return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
        }
    };

    const [fireEvents] = useAnalytics();
    const triggerEvent = () => {
        if (props.vehicleAttributes) {
            fireEvents(
                'dyf-livestream-cta-onclick-event',
                undefined,
                undefined,
                false
            );
        }
    };
    const downloadFile = (e: any) => {
        e.preventDefault();
        let url = e.currentTarget.getAttribute('href');
        url = 'data:text/calendar;charset=utf8,' + encodeURIComponent(url);
        window.open(url, '_blank');
    };

    const handleClick = (item: ScheduleItemsInterface) => {
        return `/vehicle/${item.seoKey}/${item.year}/discover-your-ford/livestream/`;
    };
    return (
        <div className="tile-row">
            {props.scheduleItems.map(
                (item: ScheduleItemsInterface, index: number) => {
                    return (
                        <>
                            <div key={index} className="tile-grid">
                                <div className="tile-item">
                                    <img
                                        src={item.thumbnail.url}
                                        alt={item.thumbnail.altText}
                                        className="schedule-item-image"
                                    />
                                </div>
                                <div className="tile-item">
                                    {item.startTime && item.endTime && (
                                        <>
                                            <div className="schedule-date-and-time">
                                                {
                                                    formatDate(item.startTime)
                                                        .date
                                                }
                                                ,&nbsp;
                                                {
                                                    formatDate(item.startTime)
                                                        .time
                                                }
                                                ,&nbsp;
                                                {
                                                    formatDate(item.startTime)
                                                        .timeZone
                                                }
                                            </div>
                                        </>
                                    )}
                                    <h3 className="schedule-title">
                                        {htmlDecode(item.title)}
                                    </h3>
                                    <div className="schedule-topic">
                                        {htmlDecode(item.topics['1'])}
                                    </div>
                                </div>

                                {item.startTime && item.endTime && (
                                    <>
                                        <div className="tile-item add-to-calendar-wrapper">
                                            {!props.vehicleAttributes && (
                                                <div className="view-schedule-button">
                                                    <PrimaryButton
                                                        color="dark"
                                                        fill="outline"
                                                        chevron={false}
                                                        href={handleClick(item)}
                                                        ariaLabel={
                                                            props.liveStreami18nAEM
                                                                ? props
                                                                      .liveStreami18nAEM
                                                                      .viewScheduleCta
                                                                      .ariaLabel
                                                                : props
                                                                      .modelNotSupportedContent
                                                                      ?.viewScheduleButtonAriaLabel
                                                        }
                                                    >
                                                        {props.liveStreami18nAEM
                                                            ? props
                                                                  .liveStreami18nAEM
                                                                  .viewScheduleCta
                                                                  .title
                                                            : props
                                                                  .modelNotSupportedContent
                                                                  ?.viewScheduleButton}
                                                    </PrimaryButton>
                                                </div>
                                            )}
                                            <a
                                                className="add-to-calendar-button"
                                                onClick={(event: any) => {
                                                    triggerEvent();
                                                    downloadFile(event);
                                                }}
                                                href={item.icsInvite}
                                                target={
                                                    props.liveStreami18nAEM
                                                        ? props
                                                              .liveStreami18nAEM
                                                              .addToCalendarCta
                                                              .targetBlank
                                                        : ''
                                                }
                                            >
                                                <div className="calender-headline">
                                                    {props.liveStreami18nAEM
                                                        ? props
                                                              .liveStreami18nAEM
                                                              .addToCalendarCta
                                                              .title
                                                        : props
                                                              .modelNotSupportedContent
                                                              ?.addToCalendarButton}
                                                </div>
                                                <div className="calender-img">
                                                    <img
                                                        className="notification-icon"
                                                        src={calenderIcon}
                                                        alt="notification"
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    );
                }
            )}
        </div>
    );
};
