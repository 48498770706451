import React from 'react';
import './error-message.scss';

interface Props {
    errorMessage: string;
}

export const ErrorMessage = (props: Props) => {
    return (
        <div
            className="error-message"
            dangerouslySetInnerHTML={{ __html: props.errorMessage }}
        ></div>
    );
};
