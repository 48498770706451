import React, { useEffect, useState, useCallback } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { ServiceHistory } from '../../components/sections';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import ServerSideService from '../../services/server-side-service/server-side-service';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';
import { PageType } from '../../components/sections/view-template/page-type';
import { useAnalytics } from '../../hooks/use-analytics';
import useUserProfile from '../../hooks/use-user-profile';
import useSeoConfig from '../../hooks/use-seo-config';

export function ServiceHistoryView(props: RouteProps) {
    const [fireEvents] = useAnalytics();
    const selectedVin = ServerSideService.isClientSide()
        ? window.sessionStorage.getItem('selectedVin')
        : false;
    const [
        vehicleAttributes,
        setVehicleAttributes,
    ] = useState<VehicleAttributes | null>(null);
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const authenticationService = new AuthenticationService();
    const profile = useUserProfile();
    const [userGuid, setUserGuid] = useState<string>('');
    const history = useHistory();
    const goToHomePage = useCallback(() => {
        history.push('/');
    }, [history]);
    const routeState = props.location?.state as
        | { fromPage: PageType }
        | undefined;
    const appConfig = new AppConfigurationService();
    const [uomDistance, setUomDistance] = useState<number>(0);
    useSeoConfig('service-history', 'service-history');
    useEffect(() => {
        const vehicleAttributesSession = sessionStorage.getItem(
            'vehicleAttributes'
        );

        if (profile) {
            if (profile.profile) {
                setUserGuid(profile.profile.userGuid);
                setUomDistance(profile.profile.uomDistance);
            }
            if (
                appConfig.isRegionEU() &&
                routeState?.fromPage === 'VIN' &&
                vehicleAttributesSession
            ) {
                setVehicleAttributes(JSON.parse(vehicleAttributesSession));
            } else if (profile.vehicles.length > 0) {
                setVehiclesData(
                    profile.vehicles.map(vehicle => {
                        return {
                            year: parseInt(vehicle.modelYear, 10),
                            make: vehicle.make,
                            model: vehicle.modelName,
                            vin: vehicle.vin,
                            uomSpeed: profile.profile.uomSpeed,
                        };
                    })
                );
            } else {
                goToHomePage();
            }
        } else if (
            appConfig.isRegionEU() &&
            routeState?.fromPage === 'VIN' &&
            vehicleAttributesSession
        ) {
            setVehicleAttributes(JSON.parse(vehicleAttributesSession));
        } else {
            authenticationService.onIsAuthenticated().then(authenticated => {
                !authenticated && authenticationService.login();
            });
        }
    }, [profile, goToHomePage]);
    const triggerLoadAnalytics = (selectedVehicleData: any) => {
        if (selectedVin) {
            fireEvents(
                ['target-trigger-view', 'service-history'],
                'service-history',
                { ymm: Promise.resolve(selectedVehicleData) },
                false
            );
        }
    };
    useEffect(() => {
        if (vehiclesData.length > 0) {
            const selectedVehicleData =
                vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                vehiclesData[0];
            triggerLoadAnalytics(selectedVehicleData);
            setVehicleAttributes(
                selectedVin ? selectedVehicleData : vehiclesData[0]
            );
        }
    }, [vehiclesData]);

    return (
        <>
            {vehicleAttributes ? (
                <ServiceHistory
                    vehicleAttributes={vehicleAttributes}
                    userGuid={userGuid}
                    uomDistance={uomDistance}
                    page={'Service History'}
                />
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
}
export default ServiceHistoryView;
