import { useContext, useEffect, useState } from 'react';

import { getNameFromContent } from '../services/content-service/content-service-util';
import ServerSideService from '../services/server-side-service/server-side-service';
import ServerContext from '../contexts/serverContext';
import {
    ContentResponse,
    ExperienceFragmentModel,
} from '../services/content-service/content-service.interface';
import contentService from '../services/content-service/content-service';
import AppConfigurationService from '../services/app-configuration-service/app-configuration-service';

type ReturnType = [ContentResponse | null, (title: string) => string | boolean];

const appConfig = new AppConfigurationService();
const getWebviewLocale = () =>
    ServerSideService.isClientSide()
        ? window.location.href?.split('webviewlocale')[1]
        : null;

const setRegionLanguageDefaults = (serverContext: any) => {
    if (!serverContext.currentRegionCode)
        serverContext.currentRegionCode = appConfig.getAppConfiguration().countryCode;

    if (!serverContext.currentLanguageRegionCode)
        serverContext.currentLanguageRegionCode = appConfig.getAppConfiguration().languageRegionCode;
    if (!serverContext.brand)
        serverContext.brand = appConfig.getAppConfiguration().brand;
};
const getRegionLanguguageRegionCode = (
    regionCodeOverride: string | undefined,
    serverContext: any,
    currentLanguageRegionCodeOverride: string | undefined
): [any, any] => {
    return [
        regionCodeOverride
            ? regionCodeOverride
            : serverContext.currentRegionCode,
        currentLanguageRegionCodeOverride
            ? currentLanguageRegionCodeOverride
            : serverContext.currentLanguageRegionCode,
    ];
};

const getRegionCodeOverride = (
    currentLanguageRegionCodeOverride: string | undefined
) => {
    return currentLanguageRegionCodeOverride?.split('-')[1];
};

const getCurrentLanguageRegionCodeOverrides = (
    webviewLocale: string | null
) => {
    return webviewLocale?.split('=')[1];
};

const getCurrentLanguageRegionCodeOverride = (
    currentLanguageRegionCodeOverride: string | undefined,
    serverContext: any
) => {
    return currentLanguageRegionCodeOverride
        ? currentLanguageRegionCodeOverride
        : serverContext.currentLanguageRegionCode;
};
const getbrandRegionLanguageCode = (
    brandOverride: string | undefined,
    serverContext: any,
    isCurrentRegionCodeOverride: any,
    isCurrentLanguageRegionCodeOverride: any
): [any, any, any] => {
    return [
        brandOverride ? brandOverride : serverContext.brand,
        isCurrentRegionCodeOverride,
        isCurrentLanguageRegionCodeOverride,
    ];
};

const getIsCurrentRegionCodeOverride = (
    currentRegionCodeOverride: string | undefined,
    serverContext: any
) => {
    return currentRegionCodeOverride
        ? currentRegionCodeOverride
        : serverContext.currentRegionCode;
};
const buildServerContext = (
    serverContext: any,
    category: string,
    name: string,
    brand?: string,
    componentName?: string
) => {
    const webviewLocale = ServerSideService.isClientSide()
        ? window.location.href?.split('webviewlocale')[1]
        : null;

    const currentLanguageRegionCodeOverride = getCurrentLanguageRegionCodeOverrides(
        webviewLocale
    );
    const regionCodeOverride = getRegionCodeOverride(
        currentLanguageRegionCodeOverride
    );

    const [
        currentRegionCode,
        currentLanguageRegionCode,
    ] = getRegionLanguguageRegionCode(
        regionCodeOverride,
        serverContext,
        currentLanguageRegionCodeOverride
    );

    if (brand) {
        if (!serverContext.content[brand])
            serverContext.content[brand] = {
                [currentRegionCode]: {
                    [currentLanguageRegionCode]: { [category]: { [name]: {} } },
                },
            };
        else if (!serverContext.content[brand][currentRegionCode])
            serverContext.content[brand][currentRegionCode] = {
                [currentLanguageRegionCode]: { [category]: { [name]: {} } },
            };
        else if (
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ] = { [category]: { [name]: {} } };
        else if (
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category] = { [name]: {} };
        else if (
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name] = {};
        else if (
            componentName &&
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName] = {};
    } else {
        if (!serverContext.content[currentRegionCode])
            serverContext.content[currentRegionCode] = {
                [currentLanguageRegionCode]: { [category]: { [name]: {} } },
            };
        else if (
            !serverContext.content[currentRegionCode][currentLanguageRegionCode]
        )
            serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ] = { [category]: { [name]: {} } };
        else if (
            !serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ][category]
        )
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ] = { [name]: {} };
        else if (
            !serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ][category][name]
        )
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ][name] = {};
        else if (
            componentName &&
            !serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName]
        )
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ][name][componentName] = {};
    }
};

function setContentServerContext(
    brand: string,
    serverContext: any,
    currentRegionCode: string,
    currentLanguageRegionCode: string,
    category: string,
    name: string,
    response: ContentResponse
) {
    if (brand)
        serverContext.content[brand][currentRegionCode][
            currentLanguageRegionCode
        ][category][name] = response;
    else
        serverContext.content[currentRegionCode][currentLanguageRegionCode][
            category
        ][name] = response;
}

function pushPromiseToServerContext(serverContext: any, promise: Promise<any>) {
    if (ServerSideService.isServerSide()) serverContext.promises.push(promise);
}

export function useContent(
    category: string,
    name: string,
    brandOverride?: string,
    ymmServlet?: boolean
): ReturnType {
    const serverContext = useContext(ServerContext);
    setRegionLanguageDefaults(serverContext);
    const webviewLocale = getWebviewLocale();
    const currentLanguageRegionCodeOverride = webviewLocale?.split('=')[1];
    const currentRegionCodeOverride = currentLanguageRegionCodeOverride?.split(
        '-'
    )[1];
    const isCurrentLanguageRegionCodeOverride = getCurrentLanguageRegionCodeOverride(
        currentLanguageRegionCodeOverride,
        serverContext
    );
    const isCurrentRegionCodeOverride = getIsCurrentRegionCodeOverride(
        currentRegionCodeOverride,
        serverContext
    );

    const [
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
    ] = getbrandRegionLanguageCode(
        brandOverride,
        serverContext,
        isCurrentRegionCodeOverride,
        isCurrentLanguageRegionCodeOverride
    );

    const [content, setContent] = useState<ContentResponse | null>(null);
    const [contentRequested, setContentRequested] = useState<boolean>(false);

    const loadContent = async () => {
        if (content) return;
        buildServerContext(serverContext, category, name, brand);
        if (
            brand &&
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name] &&
            Object.keys(
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name]
            ).length !== 0
        ) {
            setContent(
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name]
            );
        } else if (
            !brand &&
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ][name] &&
            Object.keys(
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name]
            ).length !== 0
        ) {
            setContent(
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name]
            );
        } else if (!contentRequested) {
            const promise = contentService.getContent(
                category,
                name,
                brand,
                currentRegionCode,
                currentLanguageRegionCode,
                ymmServlet
            );
            pushPromiseToServerContext(serverContext, promise);
            promise
                .then((response: ContentResponse) => {
                    if (ServerSideService.isServerSide()) {
                        setContentServerContext(
                            brand,
                            serverContext,
                            currentRegionCode,
                            currentLanguageRegionCode,
                            category,
                            name,
                            response
                        );
                    }
                    setContent(response);
                })
                .catch(
                    err =>
                        ServerSideService.isServerSide() &&
                        console.log(
                            `error resolving promise for $category:${category} name:${name}. ${err}`
                        )
                );

            setContentRequested(true);
        }
    };

    loadContent();

    function getValueByName(name: string): string | boolean {
        if (content) return getNameFromContent(content, name);
        else return '';
    }

    useEffect(() => {
        setContent(null);
        setContentRequested(false);
    }, [JSON.stringify({ category, name, brandOverride, ymmServlet })]);

    return [content, getValueByName];
}

function setExperienceServerContext<T>(
    brand: string,
    serverContext: any,
    currentRegionCode: string,
    currentLanguageRegionCode: string,
    category: string,
    name: string,
    componentName: string,
    response: T
) {
    if (brand) {
        serverContext.content[brand][currentRegionCode][
            currentLanguageRegionCode
        ][category][name][componentName] = response;
    } else {
        serverContext.content[currentRegionCode][currentLanguageRegionCode][
            category
        ][name][componentName] = response;
    }
}

export function useExperienceContent<T extends ExperienceFragmentModel>(
    category: string,
    name: string,
    componentName: string,
    brandOverride?: string,
    ymmServlet?: boolean
): [T | null, boolean] {
    const serverContext = useContext(ServerContext);
    setRegionLanguageDefaults(serverContext);
    const [brand, currentRegionCode, currentLanguageRegionCode] = [
        brandOverride ? brandOverride : serverContext.brand,
        serverContext.currentRegionCode,
        serverContext.currentLanguageRegionCode,
    ];
    const [content, setContent] = useState<T | null>(null);
    const [contentRequested, setContentRequested] = useState<boolean>(false);
    const [requestFinished, setRequestFinished] = useState<boolean>(false);

    const loadContent = async () => {
        if (content) return;
        buildServerContext(serverContext, category, name, brand, componentName);
        if (
            brand &&
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName] &&
            Object.keys(
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name][componentName]
            ).length !== 0
        ) {
            setContent(
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name][componentName]
            );
            setRequestFinished(true);
        } else if (
            !brand &&
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ][name][componentName] &&
            Object.keys(
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name][componentName]
            ).length !== 0
        ) {
            setContent(
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name][componentName]
            );
            setRequestFinished(true);
        } else if (!contentRequested) {
            const promise = contentService.getExperience<T>(
                category,
                name,
                componentName,
                brand,
                currentRegionCode,
                currentLanguageRegionCode,
                ymmServlet
            );
            pushPromiseToServerContext(serverContext, promise);
            promise
                .then(response => {
                    if (ServerSideService.isServerSide()) {
                        setExperienceServerContext(
                            brand,
                            serverContext,
                            currentRegionCode,
                            currentLanguageRegionCode,
                            category,
                            name,
                            componentName,
                            response
                        );
                    }
                    setContent(response);
                    setRequestFinished(true);
                })
                .catch(err => {
                    ServerSideService.isServerSide() &&
                        console.log(
                            `error resolving promise for ${`brand:${brand}`} category:${category} name:${name} componentName:${componentName}. ${err}`
                        );
                    setRequestFinished(true);
                });
            setContentRequested(true);
        }
    };

    loadContent();

    useEffect(() => {
        setContent(null);
        setContentRequested(false);
    }, [
        JSON.stringify({
            category,
            name,
            componentName,
            brandOverride,
            ymmServlet,
        }),
    ]);

    return [content, requestFinished];
}
