import { useContent } from '../../../hooks/use-server-content';

export interface DyfVehicleSelectorContentProps {
    bannerImageDesktop: string;
    bannerImageMobile: string;
    bannerImageText: string;
    bannerImageAltText: string;
    unauthHeadline: string;
    unauthSubHeadline: string;
    authHeadline: string;
    authSubHeadline: string;
    addVehicleButtonText: string;
    addVehicleButtonAriaLabel: string;
    signInButtonText: string;
    signInButtonAriaLabel: string;
    vehicleSelectorTitle: string;
    vehicleSelectorDescription: string;
    vinLabelText: string;
    orText: string;
    yearLabelText: string;
    modelLabelText: string;
    whereDoIFindVinText: string;
    whereDoIFindVinLink: string;
    searchButtonText: string;
    searchButtonAriaLabel: string;
    vinInputErrorMessage: string;
}

export const useDyfVehicleSelectorContent = (): DyfVehicleSelectorContentProps => {
    const [content, getValueByName] = useContent('dyf', `dyf-vehicle-selector`);
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as DyfVehicleSelectorContentProps;
};
