export enum UTE_ROUTES {
    HOME = '/category/fordpass/trailer-profiles/early-access',
    BASIC_INFO = '/category/fordpass/trailer-profiles/early-access/basic',
    FINAL_FORM = '/category/fordpass/trailer-profiles/early-access/final',
    FAQS = '/category/fordpass/trailer-profiles/early-access/faqs',
    VIEW_TRAILOR_PROFILE = '/category/fordpass/trailer-profiles/early-access/profile',
}

export enum UTE_PAGE_TITLE {
    BASIC_INFO = 'Create',
    FINAL_FORM = 'Final',
}

export interface UTE_LANDING {
    addProfileTitle: string;
    addTrailerProfileLabel?: string;
    trailerProfileDescription?: string;
    faqDescriptionWithCta: string;
    submitDisable?: boolean;
    nextBtnDisable?: boolean;
    uteExistingTrailerProfiles: (uteProfile: any) => void;
    trailerProfileSuccess: string;
}

export interface UTEFormData {
    mfgName: string;
    mfgMake: string;
    mfgModel: string;
    mfgYear: string;
    name: string;
    type: string;
    distance: string;
    brakeType: string;
    smartNavLength: string;
    smartNavWidth: string;
    smartNavHeight: string;
    smartNavWeight: string;
    brakeEffort: string;
    brakeGain: string;
}
export interface trailerProfileTypes {
    trailerList?: UTEFormData[];
}
