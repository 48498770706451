import { useEffect, useRef } from 'react';
import { CardState } from '../../../models/service-maintenance-models/vehicle-health-alerts';
import { replacePlaceholderByValue } from '../../utils/placeholder-util/replace-placeholder-by-value';
import './linear-progress.lincoln.scss';

interface Props {
    icon?: string;
    progresslevel: number; // Decides the progress bar width
    progressState?: string; // Decides the progress bar color
    description?: string;
}

export const LinearProgress = (props: Props) => {
    const innerBar = useRef<HTMLDivElement>(null);
    useEffect(() => {
        let width = '';

        if (props.progresslevel === 0) {
            width = '0';
        } else if (props.progresslevel > 0 && props.progresslevel <= 1) {
            width = '5%';
        } else if (props.progresslevel > 1 && props.progresslevel <= 5) {
            width = '10%';
        } else if (props.progresslevel > 5 && props.progresslevel <= 20) {
            width = '20%';
        } else {
            width = `${props.progresslevel}%`;
        }

        if (innerBar.current) {
            innerBar.current.style.width = width;

            if (props.progresslevel === 100) {
                innerBar.current.style.borderRadius = '6px';
            }
        }
    }, []);

    const getProgressBarStyle = () => {
        if (props.progressState === CardState.ALERT) {
            return 'inner-bar-alert';
        } else if (props.progressState === CardState.WARNING) {
            return 'inner-bar-warning';
        }
    };

    return (
        <div className="linear-progress-wrapper">
            <div className="lp-icon-container" data-testid="testLpIcon">
                <img
                    className="lp-icon"
                    alt="Linear Progress Icon"
                    src={props.icon}
                />
            </div>
            <div className="linear-bar-wrapper" data-testid="testProgessBar">
                <div className="outer-bar">
                    <div
                        ref={innerBar}
                        className={`inner-bar ${getProgressBarStyle()}`}
                    ></div>
                </div>
            </div>
            <div className="lp-description" data-testid="testLpDescription">
                {replacePlaceholderByValue(props.description ?? '', {
                    value: props.progresslevel.toString(),
                })}
            </div>
        </div>
    );
};
