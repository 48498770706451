import { FMButton } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import { VehicleInfo } from '../../../../../models/osb-model/osb-vehicle-lookup';
import './ford-account-vehicle.scss';
import ProfileService from '../../../../../services/profile-service/profile-service';
import { useHttp, useViewport } from '../../../../../hooks/owners-osb';

interface Props {
    vehicleInfo: VehicleInfo;
    vehicleImageUrl: string;
}
const FordAccountVehicle = (props: Props) => {
    const { vehicleInfo, vehicleImageUrl } = props;
    const profileService = new ProfileService();
    const { dispatch } = useHttp();
    const { isMobileView } = useViewport();
    const vehicleStepContent =
        useOSBContentFragment('common/osb-v3', 'vehicle-step') || {};
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'vehicle-step-extension') || {};

    const addVehicleToFordAccount = async (vehicleInfo: VehicleInfo) => {
        if (vehicleInfo && vehicleInfo?.vin) {
            dispatch({ type: 'REQUEST' });
            const nickname: string =
                vehicleInfo?.modelName +
                ' ' +
                parseInt(vehicleInfo?.buildDate?.substring(0, 4));

            await profileService
                .addVehicle(vehicleInfo?.vin, nickname)
                .then(result => {
                    if (result.status !== 200 || result.error) {
                        console.log('vehicle is not saved');
                    } else if (result.status === 200) {
                        console.log('vehicle saved successfully');
                    }
                    dispatch({ type: 'RESPONSE' });
                })
                .catch((error: any) => {
                    dispatch({
                        type: 'ERROR',
                        error: error.message,
                    });
                });
        }
    };

    return (
        <>
            <div
                className="osb-v3-vehicle-detail-info-container"
                data-testId="osb-v3-vehicle-detail-info-container"
            >
                <div className="osb-v3-vehicle-detail-info-section">
                    <div
                        className="osb-v3-vehicle-detail-info-title"
                        data-testId="vehicle-detail-info-title"
                    >
                        {vehicleStepContent?.addAVehicleTitleLabel}
                    </div>
                    <div className="osb-v3-vehicle-model-and-year">
                        {vehicleInfo.modelName} {vehicleInfo.buildDate}
                    </div>
                    <div
                        className="osb-v3-vehicle-detail-desc"
                        data-testId="vehicle-detail-desc"
                    >
                        {vehicleStepContent?.addAVehicleDescriptionLabel}
                    </div>

                    <div className="osb-v3-vehicle-detail-info-other-section">
                        {isMobileView && (
                            <div className="osb-v3-vehicle-detail-info-image">
                                {vehicleImageUrl && (
                                    <img
                                        src={vehicleImageUrl}
                                        alt="Vehicle"
                                        crossOrigin="anonymous"
                                        role="img"
                                    />
                                )}
                            </div>
                        )}

                        <div className="osb-v3-vehicle-detail-info-content">
                            {/* Information Section */}
                            {vehicleInfo.registrationNumber && (
                                <div className="osb-v3-vehicle-detail-info-item">
                                    <div className="osb-v3-registration-number-label">
                                        {
                                            osbContentData?.vehicleRegistrationNumberLabel
                                        }
                                    </div>
                                    <div className="osb-v3-registration-number-value">
                                        {vehicleInfo.registrationNumber}
                                    </div>
                                </div>
                            )}

                            {vehicleInfo.vin && (
                                <div className="osb-v3-vehicle-detail-info-item">
                                    <div className="osb-v3-vin-label">
                                        {
                                            osbContentData?.vehicleIdentificationNumberLabel
                                        }
                                    </div>
                                    <div className="osb-v3-vin-value">
                                        {vehicleInfo.vin}
                                    </div>
                                </div>
                            )}

                            {vehicleInfo.vin && (
                                <div className="osb-v3-vehicle-detail-info-item">
                                    <div className="osb-v3-mileage-label">
                                        {osbContentData?.currentMileageLabel}
                                    </div>
                                    <div className="osb-v3-mileage-value">
                                        {vehicleInfo.mileageInKm}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* Vehicle Image */}
                        {!isMobileView && (
                            <div className="osb-v3-vehicle-detail-info-image">
                                {vehicleImageUrl && (
                                    <img
                                        src={vehicleImageUrl}
                                        alt="Vehicle"
                                        crossOrigin="anonymous"
                                        role="img"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="osb-v3-vehicle-detail-cta-section"
                    data-testId="cta-section"
                >
                    <div
                        className="osb-v3-vehicle-detail-save-button"
                        data-testId="save-button"
                    >
                        <FMButton
                            className="save-button"
                            href=""
                            label={vehicleStepContent?.saveButtonLabel}
                            theme="light"
                            type="primary"
                            onClick={() => addVehicleToFordAccount(vehicleInfo)}
                        />
                    </div>
                    <div
                        className="osb-v3-vehicle-detail-no-thanks-button"
                        data-testId="no-thanks-button"
                    >
                        <FMButton
                            className="no-thanks-button"
                            href=""
                            label={vehicleStepContent?.noThanksButtonLabel}
                            theme="light"
                            type="secondary"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FordAccountVehicle;
