import React from 'react';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
const VehicleChecklistOrderStatus = (
    props: VehicleChecklistContent & VehicleAttributes
) => {
    return (
        <>
            <VehicleChecklist
                header={props.title}
                type={props.cardTypes}
                ctaTitle={props.ctaTitle}
                description={props.dynamicDescription}
                iconPath={props.iconPath}
                ctaPath={props.staticCtaPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
            />
        </>
    );
};
export default VehicleChecklistOrderStatus;
