import React from 'react';
import './support-fds-wrapper.scss';

interface SupportFdsWrapperProps {
    children?: React.ReactNode;
    className?: string;
}

export default function SupportFdsWrapper(props: SupportFdsWrapperProps) {
    const className = props.className
        ? `support-fds-wrapper ${props.className}`
        : 'support-fds-wrapper';
    return <div className={className}>{props.children}</div>;
}
