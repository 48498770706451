import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import GoogleMapService from '../../../../services/osb-service/google-map-service/google-map-service';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { OSB_SERVICE_PRICE_TYPE } from '../../owners-osb/osb-constant';
import {
    getObjectFromAEMJson,
    isThisVIN,
    isValidVin,
} from '../../owners-osb/osb-utils';
import {
    PRICE_CALCULATOR_CONSTANTS,
    PRICE_CALCULATOR_RESULT_CONSTANTS,
} from '../price-calculator-constant';

export const validateVinRegNo = (
    vinRegNo: string,
    priceCalculatorContent: ContentElements[]
) => {
    if (vinRegNo === '') {
        return getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Vin_Reg_Validation_Message,
            priceCalculatorContent
        );
    }
    const isVin = isThisVIN(vinRegNo);
    const errorMsg = isVin
        ? PRICE_CALCULATOR_CONSTANTS.Invalid_Vin_Error_Message
        : PRICE_CALCULATOR_CONSTANTS.Invalid_Reg_Error_Message;
    const VIN_REG_NO_PATTERN = getObjectFromAEMJson(
        PRICE_CALCULATOR_CONSTANTS.Vin_RegNo_Pattern,
        priceCalculatorContent
    );
    if (!isValidVin(vinRegNo, VIN_REG_NO_PATTERN)) {
        return getObjectFromAEMJson(errorMsg, priceCalculatorContent);
    }
    return '';
};
export const validateLocation = (
    location: string,
    priceCalculatorContent: ContentElements[]
) => {
    if (location.trim().length > 0) {
        return '';
    } else {
        return getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Location_Validation_Message,
            priceCalculatorContent
        );
    }
};

export const validateMileage = (
    mileage: string,
    priceCalculatorContent: ContentElements[]
) => {
    if (mileage === '') {
        return getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Mileage_Validation_Message,
            priceCalculatorContent
        );
    }
    const correctMileage = mileage.replace(/[^0-9 .,']+$/, '');
    const sanitisedMileage = correctMileage
        ? correctMileage.replace(/[ .,']/g, '')
        : '';
    const finalMileage = sanitisedMileage.replace(/\B(?=(\d{3})+(?!\d))/g, '');
    if (finalMileage === '') {
        const errorMsg = getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Mileage_Validation_Message,
            priceCalculatorContent
        );
        return errorMsg;
    } else {
        return '';
    }
};

export const getLocation = (priceCalculatorContent: ContentElements[]) => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                GoogleMapService.getReverseGeoCoding(
                    position.coords.latitude,
                    position.coords.longitude,
                    function(data: any) {
                        if (data) {
                            resolve(data);
                        }
                    }
                );
            },
            error => {
                let errorMsg;
                if (error.code === 1) {
                    errorMsg = getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.Location_Permission_Denied_Label,
                        priceCalculatorContent
                    );
                } else if (error.code === 2) {
                    errorMsg = getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.Location_Position_Unavailable_Label,
                        priceCalculatorContent
                    );
                } else if (error.code === 3) {
                    errorMsg = getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.Location_Timeout_Label,
                        priceCalculatorContent
                    );
                } else {
                    errorMsg = getObjectFromAEMJson(
                        PRICE_CALCULATOR_CONSTANTS.Location_Unknown_Label,
                        priceCalculatorContent
                    );
                }
                reject(errorMsg);
            },
            { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 }
        );
    });
};

export const getFormattedPrice = (
    price: string,
    priceCalculatorResultContent: ContentElements[]
) => {
    const {
        PRICE_POR_LABEL,
        PRICE_FREE_LABEL,
        PRICE_PRECISION,
        PRICE_SEPARATOR,
        PRICE_PREFIX,
        PRICE_POSTFIX,
    } = PRICE_CALCULATOR_RESULT_CONSTANTS;
    const numPrice = Number(price);

    if (
        numPrice === OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
        numPrice === OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
    ) {
        return getObjectFromAEMJson(
            PRICE_POR_LABEL,
            priceCalculatorResultContent
        );
    }

    if (numPrice === OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE) {
        return getObjectFromAEMJson(
            PRICE_FREE_LABEL,
            priceCalculatorResultContent
        );
    }

    const precision = getObjectFromAEMJson(
        PRICE_PRECISION,
        priceCalculatorResultContent
    );
    const separator = getObjectFromAEMJson(
        PRICE_SEPARATOR,
        priceCalculatorResultContent
    );
    const priceWithPrecision = OsbUtilService.addPrecision(price, precision);
    const priceWithSeparators = OsbUtilService.addSeparators(
        priceWithPrecision,
        separator
    );

    return `${getObjectFromAEMJson(
        PRICE_PREFIX,
        priceCalculatorResultContent
    )}${OsbUtilService.addDecimalSeparator(
        priceWithSeparators,
        separator
    )}${getObjectFromAEMJson(PRICE_POSTFIX, priceCalculatorResultContent)}`;
};

export const limitArray = (array: DealerProfileInfo[], limit: any) => {
    if (array) {
        return array.slice(0, limit);
    }
};
