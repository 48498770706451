import { OSBAPIResponse } from '../service-orchestration/service-adapter';
import {
    ErrorResponse,
    transformErrorResponse,
} from '../../../components/sections/owners-osb/osb-utils';
import { DSLAPIService } from '../service-orchestration/service-dsl';
import { DSL_API_PATH } from '../../../constants';

import {
    DealerArray,
    OsbDealerDetailsRequest,
    OsbDealerDetailsResponse,
} from '../../../models/osb-model/osb-dealer-info';
import { transformDealerDetailsResponse } from '../../../components/sections/owners-osb/osb-service-adapters/dealer-details-adapter';

export class OsbDealerDetailsServiceClient {
    public getDealerDetails() {
        return new Promise<OSBAPIResponse<DealerArray>>((resolve, reject) => {
            const transformedResponse: OSBAPIResponse<DealerArray> = {
                value: {} as DealerArray,
                responseError: {} as ErrorResponse,
            };
            new DSLAPIService()
                .callServiceAPI<
                    Omit<OsbDealerDetailsRequest, 'vehicle'>,
                    OsbDealerDetailsResponse
                >('', DSL_API_PATH.OSB_DEALER_DETAILS, true)
                .then(response => {
                    try {
                        transformedResponse.value = transformDealerDetailsResponse(
                            response
                        );
                        resolve(transformedResponse);
                    } catch (err) {
                        transformedResponse.responseError.errorMessage =
                            'DEALER_SERVICES_CLIENT_TRANSFORMATION_ERROR: ' +
                            (err as Error)?.message;
                        reject(transformedResponse.responseError);
                    }
                })
                .catch(_error => {
                    transformedResponse.responseError = transformErrorResponse(
                        _error
                    );
                    reject(transformedResponse.responseError);
                    console.error(
                        'Rejected the promise - ',
                        transformedResponse.responseError
                    );
                });
        });
    }
}
