import { HttpsProxyAgent } from 'https-proxy-agent';
import HttpService from '../http-service/http-service';

export interface CategoryList {
    title: string;
    path: string;
}

export class CategoryServiceAem {
    async getCatSubcatPageUrls(
        brand: string,
        countryCode: string,
        languageRegionCode: string
    ): Promise<CategoryList[]> {
        const categoryList: CategoryList[] = [];
        const httpsAgent = new HttpsProxyAgent(
            process.env.http_proxy || 'http://internet.ford.com:83'
        );
        const response = HttpService.get(
            process.env.REACT_APP_AEM_BASE_URL +
                `/content/experience-fragments/global-owner/${brand.toLowerCase()}/${countryCode}/${languageRegionCode}/category.category-subcategory-path.json`,
            true,
            { httpsAgent }
        );
        response.catch(err => {
            console.error(
                'Error retrieving categories list from AEM for ',
                countryCode,
                languageRegionCode
            );
            console.error(err);
            return Promise.resolve(categoryList);
        });
        return response.then(axiosResponse => {
            return Promise.resolve(axiosResponse.data);
        });
    }
}
