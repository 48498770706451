import { FeatureCardsFragment } from '../../../components/sections/vehicle-portal/services/feature-cards/feature-card-article-service';
import {
    AemVideoQuietCardDetails,
    VideoQuietCardDetails,
} from '../../../models/experiencefragments/category-view';
import {
    SamCategoryViewFragment,
    SamCategoryViewContent,
} from '../../../models/experiencefragments/sam-category-view';
import {
    DisclaimersService,
    DisclaimerContent,
    ProcessedField,
} from './../../../services/disclaimers-service/disclaimers-service';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

export interface UseCategoryViewContentReturn {
    disclaimers: DisclaimerContent[];
    content: SamCategoryViewContent;
}

const processCardFields = (card: FMCardProps, fields: any) => {
    return {
        ...card,
        title: fields[0]?.plain,
        titleWithDisclaimer: fields[0]?.node,
        description: fields[1]?.plain,
        descriptionWithDisclaimer: fields[1]?.node,
    };
};

export function useSamCategoryViewContent(
    categoryViewFragment: SamCategoryViewFragment
): UseCategoryViewContentReturn | undefined {
    const content: SamCategoryViewContent = {};
    let disclaimers: DisclaimerContent[] = [];

    if (categoryViewFragment) {
        if (
            categoryViewFragment.masthead &&
            !categoryViewFragment.masthead.hide
        ) {
            const [
                processedArticle,
                processedDisclaimers,
            ] = DisclaimersService.processArticle(
                categoryViewFragment.masthead,
                disclaimers
            );
            content.masthead = processedArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        const featureTrioContent = (
            processedFeatureTrioContent: FeatureCardsFragment
        ) => {
            if (processedFeatureTrioContent) {
                const [
                    processedFields,
                    processedDisclaimers,
                ] = DisclaimersService.processFieldsWithDisclaimers(
                    ['title', 'subtitle'],
                    'disclaimer',
                    disclaimers,
                    processedFeatureTrioContent
                );
                disclaimers = disclaimers.concat(processedDisclaimers);
                {
                    const [
                        processedCards,
                        processedDisclaimers,
                    ] = DisclaimersService.processItemsWithDisclaimers(
                        ['title', 'description'],
                        'disclaimer',
                        disclaimers,
                        processedFeatureTrioContent.cards,
                        (card, fields) => processCardFields(card, fields)
                    );
                    disclaimers = disclaimers.concat(processedDisclaimers);
                    return {
                        featureTrioContent: {
                            ...processedFeatureTrioContent,
                            title: processedFields[0]?.plain || '',
                            titleWithDisclaimer: processedFields[0]?.node,
                            subtitle: processedFields[1]?.plain || '',
                            subtitleWithDisclaimer: processedFields[1]?.node,
                            cards: processedCards,
                        },
                    };
                }
            }
        };

        if (
            categoryViewFragment.featureTrioOne &&
            !categoryViewFragment.featureTrioOne.hide
        ) {
            const contents = featureTrioContent(
                categoryViewFragment.featureTrioOne
            );
            content.featureTrioOne = contents?.featureTrioContent;
        }

        if (
            categoryViewFragment.videoQuietCard &&
            !categoryViewFragment.videoQuietCard.hide &&
            categoryViewFragment.videoQuietCard.videoQuietCardDetails
        ) {
            const [
                processedVideoQuietCardDetails,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers<
                AemVideoQuietCardDetails,
                VideoQuietCardDetails,
                keyof AemVideoQuietCardDetails
            >(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                categoryViewFragment.videoQuietCard.videoQuietCardDetails,
                (item, fields) => {
                    return {
                        ...item,
                        title: fields[0]?.node,
                        description: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.videoQuietCard = {
                videoQuietCardDetails: processedVideoQuietCardDetails,
            };
        }

        if (
            categoryViewFragment.fiftyFiftyCarousel &&
            !categoryViewFragment.fiftyFiftyCarousel.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.fiftyFiftyCarousel.articles,
                disclaimers
            );
            content.fiftyFiftyCarousel = {
                ...categoryViewFragment.fiftyFiftyCarousel,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.quietCards &&
            !categoryViewFragment.quietCards.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.quietCards.articles,
                disclaimers
            );
            content.quietCards = {
                ...categoryViewFragment.quietCards,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.quietCardsTwo &&
            !categoryViewFragment.quietCardsTwo.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.quietCardsTwo.articles,
                disclaimers
            );
            content.quietCardsTwo = {
                ...categoryViewFragment.quietCardsTwo,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.componentBox5050 &&
            !categoryViewFragment.componentBox5050.hide
        ) {
            const [
                processedArticle,
                processedDisclaimers,
            ] = DisclaimersService.processArticle(
                categoryViewFragment.componentBox5050,
                disclaimers
            );
            content.componentBox5050 = processedArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.featureTrioTwo &&
            !categoryViewFragment.featureTrioTwo.hide
        ) {
            const contents = featureTrioContent(
                categoryViewFragment.featureTrioTwo
            );
            content.featureTrioTwo = contents?.featureTrioContent;
        }

        if (
            categoryViewFragment.articlesTrio &&
            !categoryViewFragment.articlesTrio.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.articlesTrio.articles,
                disclaimers
            );
            content.articlesTrio = {
                ...categoryViewFragment.articlesTrio,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        } else {
            delete categoryViewFragment.articlesTrio;
        }

        if (
            categoryViewFragment.faqs &&
            !categoryViewFragment.faqs?.hide &&
            categoryViewFragment.faqs?.questions
        ) {
            let savedFields: (ProcessedField | null)[] = [];
            const [
                ,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers(
                ['title', 'subtitle'],
                'disclaimer',
                disclaimers,
                categoryViewFragment.faqs,
                (_item, fields) => {
                    savedFields = fields;
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            {
                const [
                    processedQuestions,
                    processedDisclaimers,
                ] = DisclaimersService.processItemsWithDisclaimers(
                    ['question', 'text'],
                    'disclaimer',
                    disclaimers,
                    categoryViewFragment.faqs.questions,
                    (_item, fields) => {
                        return {
                            question: fields[0]?.node,
                            text: fields[1]?.node,
                        };
                    }
                );
                disclaimers = disclaimers.concat(processedDisclaimers);
                content.faqs = {
                    title: savedFields[0]?.node,
                    subtitle: savedFields[1]?.node,
                    questions: processedQuestions,
                };
            }
        }

        return {
            disclaimers,
            content: content,
        };
    }
}
