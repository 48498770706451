import React, { ReactNode, useEffect, useRef } from 'react';
import { KEYBOARD_KEYS } from '../../../constants';
import { StringUtil } from '../../utils/string-util/string-util';
import './fm-drawer.scss';
import { loopFocusWithinComponent } from '../../utils/keyboard-event-utils/keyboard-event-utils';
import { useAnalytics } from '../../../hooks/use-analytics';
import { SmashHomeClick } from '../../../services/shortcode-service/shortcode-service';

interface FMDrawerProps {
    className: string;
    closeButtonAriaLabel?: string;
    showDraw: boolean;
    onClose: () => void;
    children: ReactNode;
}

const FMDrawer = (props: FMDrawerProps) => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const drawerBackdropRef = useRef<HTMLDivElement>(null);
    const closeIconRef = useRef<HTMLButtonElement>(null);
    const [fireEvents] = useAnalytics();
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            closeDrawer();
        }
        if (event.key === KEYBOARD_KEYS.TAB && drawerRef.current) {
            loopFocusWithinComponent(event, drawerRef.current);
        }
    };

    const setDefaultFocus = () => {
        closeIconRef?.current?.focus();
    };

    const showDrawer = () => {
        const drawerElement = document.getElementsByClassName(
            props.className
        )[0] as HTMLDivElement;
        drawerElement && (drawerElement.style.visibility = 'visible');
        setDefaultFocus();
    };

    const closeDrawer = () => {
        const smashHomeClick: SmashHomeClick = {
            ctaName: 'close',
        };
        fireEvents('fm-search-vehicle-cta-event', undefined, {
            smashHomeClick,
        });
        props.onClose && props.onClose();
    };

    const onDocumentClicked = (event: MouseEvent) => {
        if (
            drawerBackdropRef.current &&
            drawerBackdropRef.current.contains(event.target as Node)
        ) {
            closeDrawer();
        }
    };

    useEffect(() => {
        if (props.showDraw) {
            showDrawer();
            window.addEventListener('click', onDocumentClicked, false);
            window.addEventListener('keydown', onKeyDown, false);
            document.body.classList.add('open');
        }
        return () => {
            window.removeEventListener('click', onDocumentClicked, false);
            window.removeEventListener('keydown', onKeyDown, false);
            document.body.classList.remove('open');
        };
    }, [props.showDraw]);

    return (
        <>
            <div
                ref={drawerBackdropRef}
                id="fm-drawer-backdrop"
                className={props.showDraw ? 'open' : ''}
            ></div>
            <div
                ref={drawerRef}
                className={`fm-drawer-container ${
                    props.showDraw ? 'open' : ''
                } ${props.className}`}
                data-testid={props.className}
                role="dialog"
                aria-label={StringUtil.removeHyphen(props.className)}
            >
                {props.showDraw && (
                    <>
                        <button
                            ref={closeIconRef}
                            className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
                            aria-label={
                                props.closeButtonAriaLabel ?? 'Close drawer'
                            }
                            data-testid="closeButton"
                            onClick={closeDrawer}
                        />
                        <div className={'fm-drawer-slot-component'}>
                            {props.children}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default FMDrawer;
