import { useContent } from '../../../../hooks/use-server-content';
import { ReactNode } from 'react';

export interface FMBannersProps {
    hide: boolean;
    title: string | ReactNode;
    disclaimer?: string | ReactNode;
    description: string | ReactNode;
    imagePath: string;
    imageAltText: string;
    cta1Label: string;
    cta1Path: string;
    cta1AriaLabel: string;
    openCTA1InNewTab: boolean;
    cta2Label: string;
    cta2Path: string;
    cta2AriaLabel: string;
    openCTA2InNewTab: boolean;
}

export const useFMBanners = (
    category: string,
    name: string,
    ymmServlet: boolean
): FMBannersProps | null => {
    const [content, getValueByName] = useContent(
        category,
        name,
        undefined,
        ymmServlet
    );
    let contentFragment: any = {};
    if (content) {
        content?.elements.forEach(element => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.title) {
            contentFragment = null;
        }
    }
    return contentFragment as FMBannersProps;
};
