import { useContent } from '../../../../hooks/use-server-content';

export interface SkinnyBannerProps {
    hide: boolean;
    bannerDesktop: string;
    bannerTablet: string;
    bannerMobile: string;
    isDarkBackground?: boolean;
    imageAlt: string;
    url: string;
    target: string;
    tooltipHeader?: string;
    tooltipCloseButtonAriaLabel: string;
    tooltipContent: string;
}
export const useSkinnyBannerContent = (
    category: string,
    name: string,
    ymmServlet?: boolean
): SkinnyBannerProps | null => {
    const [content, getValueByName] = useContent(
        category,
        name,
        undefined,
        ymmServlet
    );
    let contentFragment: any = {};
    content?.elements?.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    const validImages =
        contentFragment?.bannerDesktop && contentFragment?.bannerMobile;
    if (contentFragment) {
        if (contentFragment.hide || !validImages) {
            contentFragment = null;
        }
    }
    return contentFragment as SkinnyBannerProps;
};
