export interface VehicleYmm {
    year: number;
    marketName: string;
    model: string;
    make: string;
    seoKey: string;
    id: string;
}
export interface VehicleYmmGroup {
    year: number;
    vehicles: VehicleYmm[];
}
const sortByNumber = (a: number, b: number) => {
    if (a < b) return -1;
    if (a > b) return 1;
    else return 0;
};
export const getYearOptions = (groups: VehicleYmmGroup[]) => {
    return groups
        .map(group => group.year)
        .sort(sortByNumber)
        .reverse()
        .map(year => String(year));
};
export const getModelOptions = (groups: VehicleYmmGroup[]) => {
    const modelOptions = new Map<string, VehicleYmm>();
    const models: VehicleYmm[] = [];
    groups.forEach(group =>
        group.vehicles.forEach(vehicle =>
            modelOptions.set(vehicle.model, vehicle)
        )
    );
    modelOptions.forEach(model => models.push(model));
    return models.sort((model1, model2) =>
        model1.model?.toUpperCase().localeCompare(model2.model)
    );
};
export const getYearOptionsForModel = (
    groups: VehicleYmmGroup[],
    model: string
): number[] => {
    const yearByModel: VehicleYmm[] = [];
    groups.forEach(group =>
        group.vehicles.forEach(vehicle => {
            if (vehicle.seoKey === model) yearByModel.push(vehicle);
        })
    );

    const yearOptions: number[] = [];
    yearByModel &&
        yearByModel.forEach(vehicle => {
            if (vehicle) yearOptions.push(vehicle?.year);
        });
    return yearOptions.sort(sortByNumber);
};
export const getModelOptionsForYear = (
    groups: VehicleYmmGroup[],
    year: number
): string[] => {
    const modelsByYear = groups.find(group => group.year === year);

    let modelOptions: string[] = [];
    if (modelsByYear) {
        modelOptions = modelsByYear.vehicles.map(vehicle => vehicle.model);
    }

    return modelOptions.sort((model1, model2) =>
        model1.toUpperCase().localeCompare(model2)
    );
};
export const getVehicleModelsForYear = (
    groups: VehicleYmmGroup[],
    year: number
): VehicleYmm[] => {
    const modelsByYear = groups.find(group => group.year === year);
    return (modelsByYear && modelsByYear.vehicles) || [];
};
