export const throttle = (
    throttleFunction: () => void,
    timing: number
): (() => void) => {
    let lastTime = new Date() as any;
    const method = throttleFunction;
    const rate = timing || 500;

    return () => {
        const now = new Date() as any;
        if (now - lastTime >= rate) {
            method && method();
            lastTime = now;
        }
    };
};
