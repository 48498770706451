import { PERSISTENCE_MESSAGE_TYPES } from '../eu-help-router';
import { REPLACE_CHAT_HISTORY } from '../actionTypes';
import { Dispatch, SetStateAction } from 'react';

interface HelpRouterHandleMessage {
    isMessageTypeNewMessage: (message: any) => boolean;
    persistentMessageType: (message: any) => void;
    message: string;
}

interface ProcessFeedbackMessage {
    message: type;
}

type type = {
    type: string;
};

interface ReceiverMessageType {
    payload: any;
    dispatch: any;
}

interface Cookies {
    cookies: any;
    setCookie: any;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
}

interface IsOpen {
    isOpen: boolean;
    onEscape: any;
}

interface HandleAnalogClickProps {
    isOpen: boolean;
    onAnalogClick?: () => void;
    closedRouter: any;
    closedBackground: any;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const EuHelpRouterHandleMessage = (props: HelpRouterHandleMessage) => {
    if (props.isMessageTypeNewMessage(props.message)) {
        props.persistentMessageType(props.message);
    }
};

export const EuHelpRouterProcessFeedbackMessage = (
    props: ProcessFeedbackMessage
) => {
    if (props.message?.type !== PERSISTENCE_MESSAGE_TYPES.FEEDBACK_RECEIVED)
        return null;
};

export const EuHelpRouterFeedbackReceiverMessageType = (
    props: ReceiverMessageType
) => {
    if (props.payload) {
        props.dispatch({ type: REPLACE_CHAT_HISTORY, payload: props.payload });
    }
};

export const EUHelpRouterCookie = (props: Cookies) => {
    if (props.cookies['sticky-popup-displayed'] != 1) {
        props.setCookie('sticky-popup-displayed', 1, {
            expires: new Date(Date.now() + 86400000),
        });
    } else props.setIsVisible(false);
};

export const EuHelpRouterIsOpen = (props: IsOpen) => {
    if (props.isOpen) {
        document.addEventListener('keydown', props.onEscape, false);
    }
    return () => {
        if (props.isOpen) {
            document.removeEventListener('keydown', props.onEscape, false);
        }
    };
};

export const handleAnalogClickOpen = (props: HandleAnalogClickProps) => {
    if (!props.isOpen) {
        props.onAnalogClick && props.onAnalogClick();
        props.closedRouter.classList.add('open');
        props.closedRouter.classList.remove('closed');
        props.closedBackground.classList.add('open');
        props.closedBackground.classList.remove('closed');
        props.setIsOpen(true);
        document.body.classList.add('no-scroll');
    } else {
        props.closedRouter.classList.add('closed');
        props.closedBackground.classList.add('closed');
        document.body.classList.remove('no-scroll');
        setTimeout(() => {
            props.closedRouter.classList.remove('open');
            props.closedBackground.classList.remove('open');
            document.body.classList.remove('no-scroll');
            props.setIsOpen(false);
        }, 800);
    }
};
