import React from 'react';
import { useOSBStep } from '../../../../../hooks/owners-osb';
import { ServiceData } from '../../../../../models/osb-model/osb-dealerservice-info';
import './osb-video-link-lincoln.scss';

interface Props {
    selectedService: ServiceData;
    showVideoOverlay: (
        brightCoveAccountId?: string,
        brightCoveVideoId?: string,
        brightCovePlayerId?: string,
        brightCoveVideoName?: string
    ) => void;
}
export const OSBVideoLink = (props: Props) => {
    const { osbStep } = useOSBStep();
    if (
        props.selectedService.brightCoveAccountId &&
        props.selectedService.brightCoveVideoId &&
        props.selectedService.brightCovePlayerId &&
        osbStep.isVideoLinkEnabled
    ) {
        return (
            <div className="osb-show-video-link">
                <a
                    className="show-video-container"
                    onClick={() => {
                        props.showVideoOverlay(
                            props.selectedService.brightCoveAccountId,
                            props.selectedService.brightCoveVideoId,
                            props.selectedService.brightCovePlayerId,
                            props.selectedService.brightCoveVideoName
                        );
                    }}
                >
                    <div className="show-video-icon"></div>

                    <div className="show-video-text">
                        {osbStep.videoLinkLabel}
                    </div>
                </a>
            </div>
        );
    }

    return <></>;
};
