import {
    AemArticle,
    AemArticles,
    AemFAQs,
    AemHeroArticle,
    AemSimpleCards,
    AemVideoQuietCard,
    AemVideoQuietCardDetails,
    KeyValueItemsBar,
    SubCategoryViewContent,
    SubCategoryViewFragment,
    VideoQuietCardDetails,
} from '../../../models/experiencefragments/category-view';
import {
    DisclaimerContent,
    DisclaimersService,
    ProcessedField,
} from '../../../services/disclaimers-service/disclaimers-service';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { FeatureCardsFragment } from '../../../components/sections/vehicle-portal/services/feature-cards/feature-card-article-service';

export interface UseSubCategoryViewContentReturn {
    disclaimers: DisclaimerContent[];
    content: SubCategoryViewContent;
}

const processCardFields = (card: FMCardProps, fields: any) => {
    return {
        ...card,
        title: fields[0]?.plain,
        titleWithDisclaimer: fields[0]?.node,
        description: fields[1]?.plain,
        descriptionWithDisclaimer: fields[1]?.node,
    };
};
const isFragmentProcessable = (
    fragment: any,
    key: string,
    additionalCheck = true
): boolean => {
    return fragment[key] && !fragment[key].hide && additionalCheck;
};

const getProcessedFieldString = (processedFieldsString: string | undefined) => {
    return processedFieldsString || '';
};

export function useSubCategoryViewContent(
    subCategoryViewFragment: SubCategoryViewFragment
): UseSubCategoryViewContentReturn | undefined {
    const content: SubCategoryViewContent = {};
    let disclaimers: DisclaimerContent[] = [];
    if (!subCategoryViewFragment) return;
    if (isFragmentProcessable(subCategoryViewFragment, 'masthead')) {
        const [
            processedArticle,
            processedDisclaimers,
        ] = DisclaimersService.processArticle(
            subCategoryViewFragment.masthead as AemArticle,
            disclaimers
        );
        content.masthead = processedArticle;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    if (subCategoryViewFragment.imgCxArticles) {
        const [
            processedContent,
            newDisclaimers,
        ] = DisclaimersService.processItemsWithDisclaimers(
            ['text'],
            'disclaimer',
            disclaimers,
            subCategoryViewFragment.imgCxArticles?.heroArticleList,
            (article, fields) => {
                return {
                    ...article,
                    text: fields[0]?.node,
                };
            }
        );
        disclaimers = disclaimers.concat(newDisclaimers);
        content.imgCxArticles = {
            ...subCategoryViewFragment.imgCxArticles,
            heroArticleList: processedContent,
        };
    }

    if (isFragmentProcessable(subCategoryViewFragment, 'featureTrio')) {
        const [
            processedFields,
            processedDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['title', 'subtitle'],
            'disclaimer',
            disclaimers,
            subCategoryViewFragment.featureTrio as FeatureCardsFragment
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedCards,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                (subCategoryViewFragment.featureTrio as FeatureCardsFragment)
                    ?.cards,
                (card, fields) => processCardFields(card, fields)
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.featureTrio = {
                ...(subCategoryViewFragment.featureTrio as FeatureCardsFragment),
                title: getProcessedFieldString(processedFields[0]?.plain),
                titleWithDisclaimer: processedFields[0]?.node,
                subtitle: getProcessedFieldString(processedFields[1]?.plain),
                subtitleWithDisclaimer: processedFields[1]?.node,
                cards: processedCards,
            };
        }
    }

    if (
        isFragmentProcessable(
            subCategoryViewFragment,
            'videoQuietCard',
            Boolean(
                subCategoryViewFragment.videoQuietCard?.videoQuietCardDetails
            )
        )
    ) {
        const [
            processedVideoQuietCardDetails,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers<
            AemVideoQuietCardDetails,
            VideoQuietCardDetails,
            keyof AemVideoQuietCardDetails
        >(
            ['title', 'description'],
            'disclaimer',
            disclaimers,
            (subCategoryViewFragment.videoQuietCard as AemVideoQuietCard)
                ?.videoQuietCardDetails,
            (item, fields) => {
                return {
                    ...item,
                    title: fields[0]?.node,
                    description: fields[1]?.node,
                };
            }
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        content.videoQuietCard = {
            videoQuietCardDetails: processedVideoQuietCardDetails,
        };
    }

    if (
        isFragmentProcessable(
            subCategoryViewFragment,
            'keyValueItemsBar',
            (subCategoryViewFragment.keyValueItemsBar as KeyValueItemsBar)
                ?.keyValueItems?.length > 0
        )
    ) {
        content.keyValueItemsBar = subCategoryViewFragment.keyValueItemsBar;
    }

    if (
        isFragmentProcessable(
            subCategoryViewFragment,
            'simpleCards',
            (subCategoryViewFragment.simpleCards as AemSimpleCards)?.simpleCards
                ?.length > 0
        )
    ) {
        const [
            processedCards,
            newDisclaimers,
        ] = DisclaimersService.processItemsWithDisclaimers(
            ['title', 'body'],
            'disclaimer',
            disclaimers,
            (subCategoryViewFragment.simpleCards as AemSimpleCards)
                ?.simpleCards,
            (aemCard, fields) => {
                return {
                    ...aemCard,
                    title: fields[0]?.node,
                    plainTitle: getProcessedFieldString(fields[0]?.plain),
                    body: fields[1]?.node,
                };
            }
        );
        disclaimers = disclaimers.concat(newDisclaimers);
        content.simpleCards = {
            ...(subCategoryViewFragment.simpleCards as AemSimpleCards),
            simpleCards: processedCards,
        };
    }

    if (isFragmentProcessable(subCategoryViewFragment, 'heroArticle')) {
        const [
            processedHeroArticle,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['text'],
            'disclaimer',
            disclaimers,
            subCategoryViewFragment.heroArticle as AemHeroArticle,
            (article, fields) => {
                return {
                    ...article,
                    text: fields[0]?.node,
                };
            }
        );
        content.heroArticle = processedHeroArticle;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    if (isFragmentProcessable(subCategoryViewFragment, 'latestArticles')) {
        const [
            processedArticles,
            processedDisclaimers,
        ] = DisclaimersService.processArticles(
            (subCategoryViewFragment.latestArticles as AemArticles)?.articles,
            disclaimers
        );
        content.latestArticles = {
            ...subCategoryViewFragment.latestArticles,
            articles: processedArticles,
        };
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    if (isFragmentProcessable(subCategoryViewFragment, 'componentBox5050')) {
        const [
            processedArticle,
            processedDisclaimers,
        ] = DisclaimersService.processArticle(
            subCategoryViewFragment.componentBox5050 as AemArticle,
            disclaimers
        );
        content.componentBox5050 = processedArticle;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    if (isFragmentProcessable(subCategoryViewFragment, 'popularLinksSection')) {
        content.popularLinksSection =
            subCategoryViewFragment.popularLinksSection;
    }

    if (isFragmentProcessable(subCategoryViewFragment, 'fiftyFiftyCarousel')) {
        const [
            processedArticles,
            processedDisclaimers,
        ] = DisclaimersService.processArticles(
            (subCategoryViewFragment.fiftyFiftyCarousel as AemArticles)
                ?.articles,
            disclaimers
        );
        content.fiftyFiftyCarousel = {
            ...subCategoryViewFragment.fiftyFiftyCarousel,
            articles: processedArticles,
        };
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    if (
        isFragmentProcessable(
            subCategoryViewFragment,
            'faqs',
            Boolean(subCategoryViewFragment.faqs?.questions)
        )
    ) {
        let savedFields: (ProcessedField | null)[] = [];
        const [
            ,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'subtitle'],
            'disclaimer',
            disclaimers,
            subCategoryViewFragment.faqs as AemFAQs,
            (_item, fields) => {
                savedFields = fields;
            }
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedQuestions,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['question', 'text'],
                'disclaimer',
                disclaimers,
                (subCategoryViewFragment.faqs as AemFAQs)?.questions,
                (_item, fields) => {
                    return {
                        question: fields[0]?.node,
                        text: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.faqs = {
                title: savedFields[0]?.node,
                subtitle: savedFields[1]?.node,
                questions: processedQuestions,
            };
        }
    }

    return {
        disclaimers,
        content: content,
    };
}
