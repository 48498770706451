import React from 'react';
import './service-history-notification.scss';

interface Props {
    customClass?: string;
    notificationMessage: string;
    notificationStatus: string;
    isLincoln: boolean;
}

export const ServiceHistoryNotification = (props: Props) => {
    const {
        notificationMessage,
        notificationStatus,
        customClass,
        isLincoln,
    } = props;

    const getTopPosition = () => {
        if (isLincoln) {
            const lincolnHeader = document.querySelector(
                '.lincoln-na-header .navigation-bar'
            );
            return lincolnHeader
                ? `${lincolnHeader.getBoundingClientRect().bottom}px`
                : '0px';
        }
        const headerEle = (document.querySelector('.header') ||
            document.querySelector('.euheadernav')) as HTMLElement;
        return headerEle ? `${headerEle.offsetHeight}px` : '0px';
    };

    return (
        <div
            className={`notification-message ${notificationStatus} ${customClass}`}
            style={{ top: getTopPosition() }}
        >
            <img className="notification-icon" alt="notification icon" />
            <div
                className="notification-text"
                dangerouslySetInnerHTML={{
                    __html: notificationMessage,
                }}
            />
        </div>
    );
};
