import React, { useState, useRef } from 'react';
import { PrimaryButton, Dropdown, Link } from '../../../../common';
import stateOptions from '../../../../common/states/state';
import DatePicker from 'react-datepicker';
import { InfoIconToolTip } from '../../../../common/info-icon-tooltip/info-icon-tooltip';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useRedeemRebateContent } from '../../hooks/redeem-rebates-steps/use-redeem-rebate-content';

import 'react-datepicker/dist/react-datepicker.css';
import './redeem-rebate.scss';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import useUserProfile from '../../../../../hooks/use-user-profile';
import { DealerListResponse } from '../../../../../services/redeem-rebate-service/redeem-rebate-service';
import ServiceHandler from '../../../../../services/service-handler';

interface Props {
    redeemFormData: RedeemFormData;
    setCurrentStep(stepNumber: number): void;
    setFormData(data: RedeemFormData): void;
}

interface ValidationMessageProps {
    isTouched?: boolean;
    validate?: any;
}

const determineFormVIN = (selectVin: any, data: any) => {
    return selectVin === 'add-new-vin' || !selectVin ? data.VIN : selectVin;
};

const determineFormDealerName = (selectedDealer: any, data: any) => {
    return selectedDealer === 'locate-another-dealer' || !selectedDealer
        ? data.dealerName
        : selectedDealer;
};

const getLabelClassName = (condition: any) =>
    `rebates__form__label${condition ? ' up' : ''}`;

const getLabelVINClassName = (dataVIN: any, selectVin: any) => {
    return `rebates__form__label${dataVIN || selectVin ? ' up' : ''}`;
};

const getClassName = (isTouched: any, validate: any) =>
    `rebates__form__input${isTouched && validate ? ' invalid' : ''}`;

const ValidationMessage = (props: ValidationMessageProps) => {
    return (
        <>
            {props.isTouched && props.validate && (
                <div>
                    <span className="error_warning">{props.validate}</span>
                </div>
            )}
        </>
    );
};

export const RedeemRebate = (props: Props) => {
    const redeemrebateContent = useRedeemRebateContent();
    const [fireEvents] = useAnalytics();
    useAnalytics('redeem-rebates-landing');
    const step1InputAnalytics = () => {
        fireEvents(
            ['redeem-rebates-input-onclick-event'],
            'redeem-rebates-input-onclick-event',
            undefined,
            false
        );
    };
    const step1ContinueAnalytics = () => {
        fireEvents(
            ['redeem-rebates-continue-onclick-event'],
            'redeem-rebates-continue-onclick-event',
            undefined,
            false
        );
    };
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const [selectedDate, setSelectedDate] = useState<any>();
    const [selectState, setSelectState] = useState(
        redeemrebateContent && redeemrebateContent.statePlaceholderText
            ? redeemrebateContent.statePlaceholderText
            : 'State'
    );
    const profile = useUserProfile();
    const userVehicles = profile?.vehicles;
    const userVins: string[] = [];
    let userVinList: string[] = [];
    let ownerDealerList: string[] = [];
    const [selectVin, setSelectVin] = useState('');
    const [selectedDealer, setSelectedDealer] = useState('');
    const [invalidVin, setInvalidVin] = useState(false);

    const setDate = (date: any) => {
        setSelectedDate(date);
        step1InputAnalytics();
    };

    const [data, setData] = useState({
        date: '',
        VIN: '',
        ZIPCode: '',
        city: '',
        dealerName: '',
    });

    const [isTouched, setTouched] = useState({
        date: false,
        VIN: false,
        ZIPCode: false,
        city: false,
        state: false,
        dealerName: false,
    });
    const [dealerSearch, setDealerSearch] = useState({
        zip: true,
        citystate: false,
        dealerName: false,
    });

    function isValidVin(vin: string): boolean {
        const vinRegex = /^[A-Za-z0-9]{17}$/;
        if (vinRegex.test(vin)) {
            return true;
        }
        return false;
    }
    function isValidZIPCode(zip: string): boolean {
        const ZIPCodeRegex = /^\d{5}$/;
        if (ZIPCodeRegex.test(zip)) {
            return true;
        }
        return false;
    }
    function isValidCity(city: string): boolean {
        const EntryRegex = /[A-Za-z]/;
        if (EntryRegex.test(city)) {
            return true;
        }
        return false;
    }
    function isValidDealerName(dealer: string): boolean {
        const EntryRegex = /[A-Za-z]/;
        if (EntryRegex.test(dealer)) {
            return true;
        }
        return false;
    }
    const onVINentry = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            VIN: event.target.value,
        });
    };
    const onZIPCodeentry = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setData({
            ...data,
            ZIPCode: event.target.value,
        });
    };
    const onCityentry = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            city: event.target.value,
        });
    };

    const onDealerNameentry = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setData({
            ...data,
            dealerName: event.target.value,
        });
    };

    const validateField = (
        fieldName: string,
        isValid: boolean,
        errorMessage: string,
        defaultErrorMessage: string
    ) => {
        return {
            [`${fieldName}Required`]: isValid
                ? ''
                : errorMessage || defaultErrorMessage,
            [`is${fieldName.charAt(0).toUpperCase() +
                fieldName.slice(1)}Valid`]: isValid,
        };
    };

    const validate = () => {
        const errors = {
            dateRequired: '',
            VINRequired: '',
            ZIPCodeRequired: '',
            CityRequired: '',
            StateRequired: '',
            DealerNameRequired: '',
            isDateValid: false,
            isVINValid: false,
            isZIPCodeValid: false,
            isCityValid: false,
            isStateValid: false,
            isDealerNameValid: false,
            allValid: false,
        };

        Object.assign(
            errors,
            validateField(
                'date',
                selectedDate,
                redeemrebateContent.dateOfServiceErrorMessage,
                'Please choose your date of service.'
            )
        );
        Object.assign(
            errors,
            validateField(
                'VIN',
                !!data.VIN && isValidVin(data.VIN),
                redeemrebateContent.vinErrorMessage,
                'Please enter a VIN.'
            )
        );
        Object.assign(
            errors,
            validateField(
                'ZIPCode',
                !!data.ZIPCode && isValidZIPCode(data.ZIPCode),
                redeemrebateContent.zipCodeErrorMessage,
                'Please enter a valid ZIP'
            )
        );
        Object.assign(
            errors,
            validateField(
                'city',
                typeof data.city === 'string' && isValidCity(data.city),
                redeemrebateContent.cityErrorMessage,
                'Please enter a City'
            )
        );
        Object.assign(
            errors,
            validateField(
                'state',
                dealerSearch.citystate &&
                    selectState !==
                        (redeemrebateContent.statePlaceholderText || 'State'),
                redeemrebateContent.stateErrorMessage,
                'Please select a State'
            )
        );
        Object.assign(
            errors,
            validateField(
                'dealerName',
                !!data.dealerName && isValidDealerName(data.dealerName),
                redeemrebateContent.dealerNameErrorMessage,
                'Please enter a Dealer Name'
            )
        );

        if (selectVin && selectVin !== '' && selectVin !== 'add-new-vin') {
            errors.isVINValid = true;
        }
        if (
            selectedDealer &&
            selectedDealer !== 'locate-another-dealer' &&
            selectedDealer !== ''
        ) {
            errors.isDealerNameValid = true;
        }

        errors.allValid =
            errors.isDateValid &&
            errors.isVINValid &&
            (errors.isZIPCodeValid ||
                (errors.isCityValid && errors.isStateValid) ||
                errors.isDealerNameValid);

        console.log('errors', errors);
        return errors;
    };

    const tooltipDefaultContent = `Your vehicle identification number, or VIN, is a 17-digit alphanumeric code. You can typically find it in these three locations:
    <br /><br />
    1. The driver's side doorframe.<br />
    2. The lower, driver's side corner of your windshield -viewable from outside your vehicle.<br />
    3. Your vehicle's point of purchase documents, insurance card and registration form.<br />`;

    const formVIN = determineFormVIN(selectVin, data);
    const formDealerName = determineFormDealerName(selectedDealer, data);

    const getVINService = () => {
        ServiceHandler.RedeemRebateService.decodeAnyVin(formVIN)
            .then(response => {
                if (
                    response.decodedVin &&
                    !response.decodedVin.vinvalidationResult.invalidVIN
                ) {
                    props.setFormData({
                        serviceDate: selectedDate || '',
                        vin: formVIN,
                        searchBy: {
                            zipCode: data.ZIPCode,
                            city: data.city,
                            state: selectState,
                            dealerName: formDealerName,
                        },
                        year: response.decodedVin.modelYear.attributeValue,
                        make: response.decodedVin.make.attributeValue,
                        model: response.decodedVin.model.attributeValue,
                    });
                    props.setCurrentStep(2);
                }
            })
            .catch(error => {
                console.error(error);
                setInvalidVin(true);
            });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        getVINService();
        step1ContinueAnalytics();
    };

    const setDealerSearchFunction = (
        zipstate: boolean,
        citystatestate: boolean,
        dealerNamestate: boolean
    ): any => {
        setDealerSearch({
            ...dealerSearch,
            zip: zipstate,
            citystate: citystatestate,
            dealerName: dealerNamestate,
        });
    };

    const preferreddealersID: any = new Set();
    const DealerList = () => {
        profile?.vehicles.map(dealerList => {
            if (dealerList.preferredDealer !== null) {
                preferreddealersID.add(dealerList.preferredDealer);
            }
        });
        const preferredDealersName: string[] = [];
        const getDealersID: string[] = [];
        {
            preferreddealersID.forEach((dealercode: string) => {
                getDealersID.push(dealercode);
            });
        }
        const dealerList: Promise<DealerListResponse> = ServiceHandler.RedeemRebateService.getByDealerCode(
            getDealersID,
            getDealersID.length
        );
        dealerList &&
            dealerList
                .then(serviceProvider => {
                    serviceProvider.dealerResultList.forEach((dealers, i) => {
                        preferredDealersName[i] = dealers.dealerName;
                    });
                    const count = preferredDealersName.length;
                    preferredDealersName[count] = 'locate-another-dealer';
                })
                .catch(console.error);
        return preferredDealersName;
    };

    const VinList = () => {
        userVehicles?.map((vehvin, index) => {
            userVins[index] = vehvin.vin;
        });
        if (userVehicles) {
            userVins[userVehicles?.length] = 'add-new-vin';
        }
        return userVins;
    };

    userVinList = VinList();
    ownerDealerList = DealerList();

    const onVinChange = () => {
        step1InputAnalytics();
        setTouched({
            ...isTouched,
            VIN: true,
        });
        setInvalidVin(false);
    };

    return (
        <>
            {redeemrebateContent && (
                <div className="redeem-rebates-form">
                    <>
                        <div className="rebates_form_elements">
                            <div className="rebates-field-row">
                                <div className="field-row-elements">
                                    <div className="rebates__form__div">
                                        <DatePicker
                                            maxDate={new Date()}
                                            id="service_date"
                                            selected={selectedDate}
                                            onChange={setDate}
                                            onBlur={() => {
                                                setTouched({
                                                    ...isTouched,
                                                    date: true,
                                                });
                                                step1InputAnalytics();
                                            }}
                                            customInput={
                                                <input
                                                    id="service_date"
                                                    className="rebates__date__form__input"
                                                ></input>
                                            }
                                            ariaLabelledBy="service_date"
                                        />
                                        <label
                                            htmlFor="service_date"
                                            className={getLabelClassName(
                                                selectedDate
                                            )}
                                        >
                                            {redeemrebateContent.dateOfServicePlaceholderText ||
                                                'Date of Service'}
                                        </label>
                                    </div>
                                    <ValidationMessage
                                        isTouched={isTouched.date}
                                        validate={validate().dateRequired}
                                    />
                                </div>
                                <div className="field-row-elements">
                                    <div className="rebates__form__div">
                                        {(!profile ||
                                            selectVin === 'add-new-vin') && (
                                            <input
                                                id="VIN"
                                                value={data.VIN}
                                                onInput={onVINentry}
                                                placeholder=" "
                                                onBlur={onVinChange}
                                                onChange={onVinChange}
                                                className={getClassName(
                                                    isTouched.VIN,
                                                    validate().VINRequired
                                                )}
                                                aria-label={
                                                    redeemrebateContent.vinPlaceholderText ||
                                                    '17 Digit VIN'
                                                }
                                            />
                                        )}
                                        {profile &&
                                            selectVin !== 'add-new-vin' && (
                                                <Dropdown
                                                    label={selectVin}
                                                    className="state-dropdown"
                                                    showLabel={false}
                                                    options={userVinList}
                                                    value={selectVin}
                                                    onChange={value => {
                                                        setTouched({
                                                            ...isTouched,
                                                            state: true,
                                                        });
                                                        setSelectVin(value);
                                                        step1InputAnalytics();
                                                    }}
                                                    aria-label={'Vin Dropdown'}
                                                />
                                            )}
                                        <label
                                            htmlFor="VIN"
                                            className={getLabelVINClassName(
                                                data.VIN,
                                                selectVin
                                            )}
                                        >
                                            {redeemrebateContent.vinPlaceholderText ||
                                                '17 Digit VIN'}
                                        </label>
                                    </div>
                                    <ValidationMessage
                                        isTouched={isTouched.VIN}
                                        validate={validate().VINRequired}
                                    />
                                    {invalidVin && (
                                        <div className="error_warning">
                                            {redeemrebateContent.invalidVinErrorMessage ||
                                                'Entered VIN is invalid. Please enter a valid VIN.'}
                                        </div>
                                    )}
                                    <a
                                        className="find-vin-clickbox"
                                        onClick={e => {
                                            const toolTip: HTMLElement = e
                                                ?.currentTarget
                                                ?.nextElementSibling
                                                ?.firstChild as HTMLElement;
                                            toolTip && toolTip.click();
                                            fireEvents(
                                                'redeem-rebates-cta-onclick-event',
                                                undefined,
                                                {
                                                    ctaType:
                                                        'how do I find my vin',
                                                },
                                                false
                                            );
                                        }}
                                    >
                                        <span className="find-vin-text">
                                            {redeemrebateContent.vinHelpText ||
                                                'Where do I find my VIN?'}
                                        </span>
                                    </a>
                                    <InfoIconToolTip
                                        tooltipContent={
                                            redeemrebateContent.vinHelpTooltipText ||
                                            tooltipDefaultContent
                                        }
                                    />
                                </div>

                                <div className="field-row-elements">
                                    <div className="zipcode-search-tabs">
                                        <span className="search-tab">
                                            {' '}
                                            {redeemrebateContent.searchByText ||
                                                'Search By:'}
                                        </span>
                                        <a
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => {
                                                setDealerSearchFunction(
                                                    true,
                                                    false,
                                                    false
                                                );
                                                setData({
                                                    ...data,
                                                    city: '',
                                                    dealerName: '',
                                                });
                                                setSelectState(
                                                    redeemrebateContent.statePlaceholderText ||
                                                        'State'
                                                );
                                                fireEvents(
                                                    'redeem-rebates-cta-onclick-event',
                                                    undefined,
                                                    { ctaType: 'zip' },
                                                    false
                                                );
                                            }}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    setDealerSearchFunction(
                                                        true,
                                                        false,
                                                        false
                                                    );
                                                    setData({
                                                        ...data,
                                                        city: '',
                                                        dealerName: '',
                                                    });
                                                    setSelectState(
                                                        redeemrebateContent.statePlaceholderText ||
                                                            'State'
                                                    );
                                                }
                                            }}
                                            className={`active-dealer-search-tab ${
                                                dealerSearch.zip ? 'active' : ''
                                            }`}
                                            aria-label={`Search By ${redeemrebateContent.searchTabOption1 ||
                                                'ZIP'}`}
                                        >
                                            {redeemrebateContent.searchTabOption1 ||
                                                'ZIP'}
                                        </a>
                                        <span> / </span>
                                        <a
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => {
                                                setDealerSearchFunction(
                                                    false,
                                                    true,
                                                    false
                                                );
                                                setData({
                                                    ...data,
                                                    ZIPCode: '',
                                                    dealerName: '',
                                                });
                                                fireEvents(
                                                    'redeem-rebates-cta-onclick-event',
                                                    undefined,
                                                    { ctaType: 'city,state' },
                                                    false
                                                );
                                            }}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    setDealerSearchFunction(
                                                        false,
                                                        true,
                                                        false
                                                    );
                                                    setData({
                                                        ...data,
                                                        ZIPCode: '',
                                                        dealerName: '',
                                                    });
                                                }
                                            }}
                                            className={`active-dealer-search-tab ${
                                                dealerSearch.citystate
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            aria-label={`Search By ${redeemrebateContent.searchTabOption2 ||
                                                'City, State'}`}
                                        >
                                            {redeemrebateContent.searchTabOption2 ||
                                                'City, State'}
                                        </a>
                                        <span> / </span>
                                        <a
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => {
                                                setDealerSearchFunction(
                                                    false,
                                                    false,
                                                    true
                                                );
                                                setData({
                                                    ...data,
                                                    city: '',
                                                    ZIPCode: '',
                                                });
                                                setSelectState(
                                                    redeemrebateContent.statePlaceholderText ||
                                                        'State'
                                                );
                                                fireEvents(
                                                    'redeem-rebates-cta-onclick-event',
                                                    undefined,
                                                    { ctaType: 'dealer name' },
                                                    false
                                                );
                                            }}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    setDealerSearchFunction(
                                                        false,
                                                        false,
                                                        true
                                                    );
                                                    setData({
                                                        ...data,
                                                        city: '',
                                                        ZIPCode: '',
                                                    });
                                                    setSelectState(
                                                        redeemrebateContent.statePlaceholderText ||
                                                            'State'
                                                    );
                                                }
                                            }}
                                            className={`active-dealer-search-tab ${
                                                dealerSearch.dealerName
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            aria-label={`Search By ${redeemrebateContent.searchTabOption3 ||
                                                'Dealer Name'}`}
                                        >
                                            {redeemrebateContent.searchTabOption3 ||
                                                'Dealer Name'}
                                        </a>
                                    </div>
                                    {dealerSearch.zip && (
                                        <div className="rebates__form__div">
                                            <input
                                                id="ZIPCode"
                                                value={data.ZIPCode}
                                                onInput={onZIPCodeentry}
                                                placeholder=" "
                                                onBlur={() => {
                                                    setTouched({
                                                        ...isTouched,
                                                        ZIPCode: true,
                                                    });
                                                    step1InputAnalytics();
                                                }}
                                                onChange={() =>
                                                    setTouched({
                                                        ...isTouched,
                                                        ZIPCode: true,
                                                    })
                                                }
                                                className={getClassName(
                                                    isTouched.ZIPCode,
                                                    validate().ZIPCodeRequired
                                                )}
                                                aria-label={
                                                    redeemrebateContent.zipCodePlaceholderText ||
                                                    'ZIP Code'
                                                }
                                            />
                                            <label
                                                htmlFor="ZIPCode"
                                                className={getLabelClassName(
                                                    data.ZIPCode
                                                )}
                                            >
                                                {redeemrebateContent.zipCodePlaceholderText ||
                                                    'ZIP Code'}
                                            </label>
                                            <ValidationMessage
                                                isTouched={isTouched.ZIPCode}
                                                validate={
                                                    validate().ZIPCodeRequired
                                                }
                                            />
                                        </div>
                                    )}

                                    {dealerSearch.citystate && (
                                        <div className="rebates__form__div">
                                            <div className="city_state_fields">
                                                <div className="city_input_field">
                                                    <input
                                                        id="City-State"
                                                        value={data.city}
                                                        onInput={onCityentry}
                                                        placeholder=" "
                                                        onBlur={() => {
                                                            setTouched({
                                                                ...isTouched,
                                                                city: true,
                                                            });
                                                            step1InputAnalytics();
                                                        }}
                                                        onChange={() =>
                                                            setTouched({
                                                                ...isTouched,
                                                                city: true,
                                                            })
                                                        }
                                                        className={getClassName(
                                                            isTouched.city,
                                                            validate()
                                                                .CityRequired
                                                        )}
                                                        aria-label={
                                                            redeemrebateContent.cityPlaceholderText ||
                                                            'City'
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="City-State"
                                                        className={getLabelClassName(
                                                            data.city
                                                        )}
                                                    >
                                                        {redeemrebateContent.cityPlaceholderText ||
                                                            'City'}
                                                    </label>
                                                </div>
                                                <Dropdown
                                                    label={
                                                        redeemrebateContent.statePlaceholderText ||
                                                        'State'
                                                    }
                                                    className="state-dropdown"
                                                    showLabel={false}
                                                    options={stateOptions}
                                                    value={selectState}
                                                    onChange={value => {
                                                        setTouched({
                                                            ...isTouched,
                                                            state: true,
                                                        });
                                                        setSelectState(value);
                                                    }}
                                                    aria-label={
                                                        redeemrebateContent.statePlaceholderText ||
                                                        'State'
                                                    }
                                                />
                                            </div>
                                            <div className="city_state_error_warning">
                                                <ValidationMessage
                                                    isTouched={isTouched.city}
                                                    validate={
                                                        validate().CityRequired
                                                    }
                                                />
                                                <ValidationMessage
                                                    isTouched={isTouched.state}
                                                    validate={
                                                        validate().StateRequired
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {dealerSearch.dealerName && (
                                        <div className="rebates__form__div">
                                            {(!profile ||
                                                selectedDealer ==
                                                    'locate-another-dealer') && (
                                                <input
                                                    id="DealerName"
                                                    value={data.dealerName}
                                                    onInput={onDealerNameentry}
                                                    placeholder=" "
                                                    onBlur={() => {
                                                        setTouched({
                                                            ...isTouched,
                                                            dealerName: true,
                                                        });
                                                        step1InputAnalytics();
                                                    }}
                                                    onChange={() =>
                                                        setTouched({
                                                            ...isTouched,
                                                            dealerName: true,
                                                        })
                                                    }
                                                    className={`rebates__form__input${
                                                        isTouched.dealerName &&
                                                        validate()
                                                            .DealerNameRequired
                                                            ? ' invalid'
                                                            : ''
                                                    }`}
                                                    aria-label={
                                                        redeemrebateContent.dealerNamePlaceholderText ||
                                                        'Dealer Name'
                                                    }
                                                />
                                            )}
                                            {profile &&
                                                selectedDealer !=
                                                    'locate-another-dealer' && (
                                                    <Dropdown
                                                        label={selectedDealer}
                                                        className="state-dropdown"
                                                        showLabel={false}
                                                        options={
                                                            ownerDealerList
                                                        }
                                                        value={selectedDealer}
                                                        onChange={value => {
                                                            setTouched({
                                                                ...isTouched,
                                                                state: true,
                                                            });
                                                            setSelectedDealer(
                                                                value
                                                            );
                                                        }}
                                                        aria-label={
                                                            'Preferred Dealer Dropdown'
                                                        }
                                                    />
                                                )}
                                            <label
                                                htmlFor="ZIPCode"
                                                className={getLabelClassName(
                                                    data.dealerName ||
                                                        selectedDealer
                                                )}
                                            >
                                                {redeemrebateContent.dealerNamePlaceholderText ||
                                                    'Dealer Name'}
                                            </label>
                                            <ValidationMessage
                                                isTouched={isTouched.dealerName}
                                                validate={
                                                    validate()
                                                        .DealerNameRequired
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="rebates-link-button">
                                {!profile && (
                                    <div className="signin-text">
                                        <Link
                                            className="anchor-link"
                                            href={
                                                redeemrebateContent.sigInUrl ||
                                                '#$userSignIn'
                                            }
                                        >
                                            {redeemrebateContent.signInText ||
                                                'Sign In'}
                                        </Link>
                                        {redeemrebateContent.textAfterSignIn ||
                                            'to view your service history'}
                                    </div>
                                )}
                                <div className="field-row-button">
                                    <PrimaryButton
                                        ref={primaryButtonRef}
                                        color="dark"
                                        fill="fill"
                                        disabled={!validate().allValid}
                                        chevron={true}
                                        ariaLabel={
                                            redeemrebateContent.clickButtonText ||
                                            'Continue'
                                        }
                                        onClick={handleSubmit}
                                    >
                                        {redeemrebateContent.clickButtonText ||
                                            'Continue'}
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            )}
        </>
    );
};
