import React, { useState } from 'react';
import { ServiceLocation } from '../../../components/sections/owners-osb/service-location/service-location';
import { useOSBStep } from '../../../hooks/owners-osb';
import { OsbLoader } from '../../../components/common/osb-loader/osb-loader';
import './service-location-view.scss';
import { OsbAlertMessagePopup } from '../../../components/sections/owners-osb/common/osb-alert-message-popup/osb-alert-message-popup';

export function ServiceLocationView() {
    const { osbStep } = useOSBStep();
    const [voucherAlertMessage, setVoucherAlertMessage] = useState<string>('');
    const [voucherAlertSuccess, setVoucherAlertSuccess] = useState<boolean>(
        false
    );

    // added this method to handle OsbAlertMessage from light vehicle section to OsbAlertMessage component
    const osbAlertMessagePopup = (message: string, success: boolean) => {
        setVoucherAlertMessage(message);
        setVoucherAlertSuccess(success);
    };

    const closeOsbAlertPopup = () => {
        setVoucherAlertMessage('');
    };
    return (
        <>
            {osbStep.isMainWrapperInitialized ? (
                <>
                    {voucherAlertMessage && (
                        <OsbAlertMessagePopup
                            alertMessage={voucherAlertMessage}
                            alertSuccess={voucherAlertSuccess}
                            closeOsbAlertPopup={closeOsbAlertPopup}
                        />
                    )}
                    <div className="service-location-view-container">
                        <ServiceLocation
                            osbAlertMessagePopup={osbAlertMessagePopup}
                        />
                    </div>
                </>
            ) : (
                <OsbLoader osbEmptyContentHeight={true} />
            )}
        </>
    );
}
