import ServiceHandler from '../../services/service-handler';
import { SERVICE_DELIVERY_TYPE } from '../../components/sections/owners-osb/osb-constant';
import { getLightVehicleData } from '../../components/sections/owners-osb/osb-utils';
import { LogService } from '../../services/log-service/log-service';
import { useVehicleStep } from './use-vehicle-step';
import { useDealerStep } from './use-dealer-step';
import { useServiceStep } from './use-service-step';
import { useOSBStep } from './use-osb-step';
import { useAllOSBState } from './use-all-osb-state';
import { useCampaignStatesStep } from './use-campaign-states-step';
import { useRetrieveBookingStep } from './use-retrieve-booking-step';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { useLightDeliveryOptionServiceStep } from './use-light-delivery-service-step';

const useDealerCalendarAvailability = () => {
    const { osbVehicleStep } = useVehicleStep();
    const { osbDealerStep } = useDealerStep();
    const { osbServiceStep } = useServiceStep();
    const { osbStep } = useOSBStep();
    const bookable = useAllOSBState();
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const { osbRetrieveBookingStep } = useRetrieveBookingStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();

    const getSelectedServicePayLoad = () => {
        const serviceStepServiceIds = OsbUtilService.getServiceIDs(
            osbServiceStep.selectedServices
        );

        const deliveryStepServiceIds = OsbUtilService.getServiceIDs(
            osbLightDeliveryServiceStep.selectedServices
        );

        return [...serviceStepServiceIds, ...deliveryStepServiceIds];
    };
    const fetchAvailability = async () => {
        const vehicleData = getLightVehicleData(osbVehicleStep);
        const selectedDealerProfile = osbDealerStep.selectedDealerProfile;
        const bookingDetails = osbRetrieveBookingStep.retrieveBookingDetails;
        const selectedDealerCode =
            bookingDetails?.bookings[0]?.dealer?.dealerCode ||
            selectedDealerProfile?.dealerCode ||
            osbDealerStep.selectedDealerId ||
            '';

        try {
            const results = await ServiceHandler.OsbDealerCalendarAvailability.getCalendarInfo(
                {
                    dealerCode: selectedDealerCode,
                    serviceDeliveryType:
                        osbDealerStep.hasMobileService &&
                        osbServiceStep.isMobileServiceSelected
                            ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                            : '',
                    selectedServices: getSelectedServicePayLoad(),
                    vehicleData,
                    requestSourceType: osbStep.source,
                    bookable: bookable,
                    DSLAPIVersion: osbStep.DSLAPIVersion,
                }
            );

            if (Array.isArray(results.value?.campaignStates)) {
                setOSBCampaignStates({
                    campaignStates: [...results.value.campaignStates],
                });
            }
        } catch (error) {
            LogService.log('DataLayer', osbStep.internalErrorMessage);
        }
    };
    return {
        fetchAvailability,
    };
};
export default useDealerCalendarAvailability;
