import React from 'react';

import FMConnectedVehicleOilLife from '../../fm-connected-vehicle/fm-connected-vehicle-oil-life/fm-connected-vehicle-oil-life';
import FMConnectedVehicleMileage from '../../fm-connected-vehicle/fm-connected-vehicle-mileage/fm-connected-vehicle-mileage';
import FmConnectedBatteryCharge from '../../fm-connected-vehicle/fm-connected-battery-charge/fm-connected-battery-charge';
import FMConnectedVehicleRange from '../../fm-connected-vehicle/fm-connected-vehicle-range/fm-connected-vehicle-range';
import FMConnectedTirePressure from '../../fm-connected-vehicle/fm-connected-tire-pressure/fm-connected-tire-pressure';
import FMConnectedFuelLevel from '../../fm-connected-vehicle/fm-connected-fuel-level/fm-connected-fuel-level';
import { ConnectedVehicleBarFragment } from '../../../models/experiencefragments/connected-vehicle-bar';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { CONNECTED_VEHICLE_CARD_TYPE_V3 } from '../../../constants';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { Vehiclestatus } from '../../../models/connected-vehicle';

import './fm-connected-vehicle-bar.scss';

interface Props {
    vehicleAttributes: VehicleAttributes;
    connectedVehicleInfo: Vehiclestatus | null;
    isTpmAvailable: boolean;
}

export const FMConnectedVehicleBar = (props: Props) => {
    const [vehicleTileAemContent] = useExperienceContent<
        ConnectedVehicleBarFragment
    >('vehicles', 'reimagine-connected-vehicle-bar', 'connected_vehicle_ba');
    let tilesToRender = null;
    const vehicleTilesArray = [];

    const isBev = props?.vehicleAttributes?.engineType === 'BEV';

    if (
        vehicleTileAemContent &&
        Object.keys(vehicleTileAemContent).length > 0
    ) {
        for (const type in vehicleTileAemContent) {
            if (Object.values(CONNECTED_VEHICLE_CARD_TYPE_V3).includes(type)) {
                vehicleTilesArray.push({
                    type,
                    ...(vehicleTileAemContent as { [key: string]: any })[type],
                });
            }
        }
    }
    const typeOrder = Object.values(CONNECTED_VEHICLE_CARD_TYPE_V3);
    vehicleTilesArray.sort((a, b) => {
        return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
    });

    if (props?.connectedVehicleInfo) {
        const { connectedVehicleInfo } = props;
        const tileComponents = {
            [CONNECTED_VEHICLE_CARD_TYPE_V3.CURRENT_MILEAGE]: FMConnectedVehicleMileage,
            [CONNECTED_VEHICLE_CARD_TYPE_V3.TIRE_PRESSURE]: FMConnectedTirePressure,
            [CONNECTED_VEHICLE_CARD_TYPE_V3.BEV_CHARGE_LEVEL]: FmConnectedBatteryCharge,
            [CONNECTED_VEHICLE_CARD_TYPE_V3.OIL_LIFE]: FMConnectedVehicleOilLife,
            [CONNECTED_VEHICLE_CARD_TYPE_V3.FUEL_LEVEL]: FMConnectedFuelLevel,
            [CONNECTED_VEHICLE_CARD_TYPE_V3.BATTERY_RANGE]: FMConnectedVehicleRange,
        };
        tilesToRender = vehicleTilesArray?.slice(0, 6).map((tile: any) => {
            const TileComponent = tileComponents[tile.type];
            if (!TileComponent) return null;
            const commonProps = {
                ...tile,
                connectedVehicleInfo,
            };
            switch (tile.type) {
                case CONNECTED_VEHICLE_CARD_TYPE_V3.TIRE_PRESSURE:
                    return (
                        <TileComponent
                            key={tile.title}
                            {...commonProps}
                            tpms={connectedVehicleInfo?.TPMS}
                            isTpmsAvaialble={props?.isTpmAvailable}
                        />
                    );
                case CONNECTED_VEHICLE_CARD_TYPE_V3.BEV_CHARGE_LEVEL:
                case CONNECTED_VEHICLE_CARD_TYPE_V3.BATTERY_RANGE:
                    if (isBev) {
                        return (
                            <TileComponent
                                key={tile.title}
                                {...commonProps}
                                batteryFillLevel={
                                    connectedVehicleInfo?.batteryFillLevel
                                }
                            />
                        );
                    }
                    break;
                case CONNECTED_VEHICLE_CARD_TYPE_V3.OIL_LIFE:
                case CONNECTED_VEHICLE_CARD_TYPE_V3.FUEL_LEVEL:
                    if (!isBev) {
                        return (
                            <TileComponent
                                key={tile.title}
                                {...commonProps}
                                isBev={isBev}
                            />
                        );
                    }
                    break;
                default:
                    return <TileComponent key={tile.title} {...commonProps} />;
            }
        });
    }

    return (
        <>
            {vehicleTileAemContent &&
                !vehicleTileAemContent?.hideVehicleBar &&
                tilesToRender != null && (
                    <section className="fm-connected-vehicle-bar">
                        <div className="fm-connected-vehicle-bar__grid">
                            {tilesToRender}
                        </div>
                    </section>
                )}
        </>
    );
};
