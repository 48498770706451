import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { PersonalDetail } from '../../models/osb-model/osb-personal-details';
import {
    StoreState,
    StorePayload,
    PersonalDetailStepState,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { useOSBStep } from './use-osb-step';

export const getInitialPersonalDetailState = (): PersonalDetail => {
    return {
        title: '',
        firstName: '',
        fordId: '',
        lastName: '',
        contactNumber: '',
        email: '',
        registrationNumber: '',
        isFirstConsentEnabled: false,
        isSecondConsentEnabled: false,
        countryDialCodeObject: {
            countryDisplayName: '',
            countryRegex: '',
            countryIconPath: '',
            countryDialCode: '',
            countryCode: '',
        },
    };
};
//Configure and initialize personal detail step state in global store
const configurePersonalDetailStepStore = () => {
    const actions = {
        //Action to set the manual personal detail step form details to global store
        SET_PERSONAL_DETAIL_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as PersonalDetailStepState).personalDetailStep,
                ...(payload as PersonalDetail),
            };

            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_PERSONAL_DETAIL_STEP_KEY,
                newState,
                localStorageExpiry
            );

            return { personalDetailStep: newState };
        },
    };
    initializeStore(actions, {
        personalDetailStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_PERSONAL_DETAIL_STEP_KEY
            ) || getInitialPersonalDetailState(),
    });
};

configurePersonalDetailStepStore();

// custom hook for manual personal detail step to handle the actions and updating payload in global store
export const usePersonalDetailStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalPersonalDetail = (
        personalDetails: PersonalDetail,
        shouldReload = true
    ) => {
        dispatch(
            'SET_PERSONAL_DETAIL_STEP',
            personalDetails,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetPersonalDetailStep = (shouldReload = true) => {
        dispatch(
            'SET_PERSONAL_DETAIL_STEP',
            getInitialPersonalDetailState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbPersonalDetail: (globalState as PersonalDetailStepState)
            .personalDetailStep,
        setOSBPersonalDetail: setGlobalPersonalDetail,
        resetPersonalDetailStep: resetPersonalDetailStep,
        callContentService: callContentService,
    };
};
