import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import CheckDeviceType from '../../services/check-device-type/check-device-type';
import { useDeviceAwareNaEuViewContent } from './hook/use-device-aware-NaEu-view';

export enum DeviceType {
    IOS = 'ios',
    ANDROID = 'android',
    UNDETERMINED = 'undetermined-device',
}

export const DeviceAwareNaEuView = () => {
    const [device, setDevice] = useState<DeviceType>();
    const deviceAwareNaEu = useDeviceAwareNaEuViewContent();
    const urlString = window.location.search;
    const url = new URLSearchParams(urlString);
    const urlSearch =
        url
            .get('country')
            ?.toString()
            .trim()
            .toUpperCase() || '';
    const naCountryIsoCode =
        deviceAwareNaEu &&
        deviceAwareNaEu.naIsoCountryCodes?.map(country =>
            country.toUpperCase()
        );
    const euCountryIsoCode =
        deviceAwareNaEu &&
        deviceAwareNaEu.euIsoCountryCodes?.map(country =>
            country.toUpperCase()
        );
    useEffect(() => {
        setDevice(CheckDeviceType());
    }, []);
    let region = '';

    if (naCountryIsoCode?.includes(urlSearch)) {
        region = 'NA';
    } else if (euCountryIsoCode?.includes(urlSearch)) {
        region = 'EU';
    }

    if (device === DeviceType.ANDROID && deviceAwareNaEu) {
        if (region === 'NA') {
            if (deviceAwareNaEu.naAndroidLink) {
                window.location.href = deviceAwareNaEu.naAndroidLink;
            } else if (
                !deviceAwareNaEu.naAndroidLink &&
                deviceAwareNaEu.naFallbackLink
            ) {
                window.location.href = deviceAwareNaEu.naFallbackLink;
            }
        } else if (region === 'EU') {
            if (deviceAwareNaEu.euAndroidLink) {
                window.location.href = deviceAwareNaEu.euAndroidLink;
            } else if (
                !deviceAwareNaEu.euAndroidLink &&
                deviceAwareNaEu.naFallbackLink
            ) {
                window.location.href = deviceAwareNaEu.naFallbackLink;
            }
        } else if (region === '' && deviceAwareNaEu.naFallbackLink) {
            window.location.href = deviceAwareNaEu.naFallbackLink;
        }
    } else if (device === DeviceType.IOS && deviceAwareNaEu) {
        if (region === 'NA') {
            if (deviceAwareNaEu.naIosLink) {
                window.location.href = deviceAwareNaEu.naIosLink;
            } else if (
                !deviceAwareNaEu.naIosLink &&
                deviceAwareNaEu.naFallbackLink
            ) {
                window.location.href = deviceAwareNaEu.naFallbackLink;
            }
        } else if (region === 'EU') {
            if (deviceAwareNaEu.euIosLink) {
                window.location.href = deviceAwareNaEu.euIosLink;
            } else if (
                !deviceAwareNaEu.euIosLink &&
                deviceAwareNaEu.naFallbackLink
            ) {
                window.location.href = deviceAwareNaEu.naFallbackLink;
            }
        } else if (region === '' && deviceAwareNaEu.naFallbackLink) {
            window.location.href = deviceAwareNaEu.naFallbackLink;
        }
    } else if (device === DeviceType.UNDETERMINED && deviceAwareNaEu) {
        if (region === 'EU' && deviceAwareNaEu.euFallbackLink) {
            window.location.href = deviceAwareNaEu.euFallbackLink;
        } else if (deviceAwareNaEu.naFallbackLink) {
            window.location.href = deviceAwareNaEu.naFallbackLink;
        }
    }
    return (
        <>
            <ActivityIndicator className="full-height" />
        </>
    );
};
export default DeviceAwareNaEuView;
