import React, { Dispatch, SetStateAction } from 'react';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { SyncMapsConsolidatedResponse } from '../use-syncs-maps-status-updates';
import VehicleTypeService from '../../../../services/vehicle-type-service/vehicle-type-service';
import { SyncMapService } from '../../../../services/sync-service/sync-map-service';
import { SYNC_MAPS } from '../../../../support/support-constants';

export const getSyncMapsUpdatesResponse = async (
    vin: string,
    currentLanguageRegionCode: string,
    current3LetterCountryCode: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setVinLookupError: React.Dispatch<React.SetStateAction<string>>,
    setVehicleInfo: Dispatch<SetStateAction<VehicleAttributes | undefined>>,
    setSyncMapsUpdates: React.Dispatch<
        React.SetStateAction<SyncMapsConsolidatedResponse | undefined>
    >,
    setHasTicket: React.Dispatch<React.SetStateAction<boolean>>
) => {
    setLoading(true);
    const syncMapsUpdatesConsolidatedResponse = {} as SyncMapsConsolidatedResponse;
    try {
        let vehicleDataGPRS = await new VehicleTypeService().vehicleInfo(
            vin,
            currentLanguageRegionCode
        );
        if (vehicleDataGPRS && !vehicleDataGPRS.citivanVin) {
            const { vehicleData } = await new VehicleTypeService().request(
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            );
            if (vehicleData) {
                vehicleData.citivanVin = false;
                vehicleDataGPRS = vehicleData;
            }
        }
        const vehicleData = vehicleDataGPRS;
        const { year, model, make, vin: _vin, citivanVin } = vehicleData ?? {};
        setVinLookupError('');
        const vehicleAttributes: VehicleAttributes = {
            year: Number(year),
            model,
            make,
            vin: _vin,
            ownerState: 1,
            citivanVin,
        } as VehicleAttributes;
        setVehicleInfo(vehicleAttributes);
        if (vehicleAttributes?.citivanVin) {
            const vwService = await new SyncMapService().getVwMaps();
            syncMapsUpdatesConsolidatedResponse.vwMapsUpdateResponse = vwService;
        } else {
            const syncMapService = await new SyncMapService().requestSyncMapUpdates(
                vin
            );
            syncMapsUpdatesConsolidatedResponse.syncMapUpdateResponse = syncMapService;
        }
        syncMapsUpdatesConsolidatedResponse.vehicleAttributes = vehicleAttributes;
        setSyncMapsUpdates(syncMapsUpdatesConsolidatedResponse);

        setHasTicket(
            SYNC_MAPS.OPEN_TICKET_STATUS_VALUES.includes(
                syncMapsUpdatesConsolidatedResponse?.syncMapUpdateResponse?.syncUpdateResponse?.jiraTicketStatusResponse?.ticketStatus?.toUpperCase() ||
                    ''
            )
        );

        setLoading(false);
    } catch (error) {
        const myError = error as Error;
        setVinLookupError(myError.message);
        console.error(error);
        setLoading(false);
    }
};
