import React, { ReactNode } from 'react';
import { Link } from '..';
import { useAnalytics } from '../../../hooks/use-analytics';
import PrimaryButton, {
    PrimaryButtonColor,
    PrimaryButtonFill,
} from '../primary-button/primary-button';

import './quiet-card.scss';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { CategoryOnClickCtaInfo } from '../../../services/shortcode-service/shortcode-service';

interface Props {
    subtitle?: ReactNode;
    title: ReactNode;
    body: ReactNode;
    ctaUrl: string;
    ctaLabel: string;
    ctaIconPath?: string;
    ctaAriaLabel: string;
    mobileImageUrl: string;
    desktopImageUrl: string;
    format?: 'image-content' | 'content-image';
    targetBlank?: boolean;
    cta2Label?: string;
    cta2IconPath?: string;
    cta2Url?: string;
    cta2AriaLabel?: string;
    target2Blank?: boolean;
    buttonColor?: PrimaryButtonColor;
    buttonFill?: PrimaryButtonFill;
    eventNames?: string;
    categoryType?: string;
    categoryPath?: string;
    imageAltText?: string;
}

export const QuietCard = (props: Props) => {
    const format = 'category-quiet-card__'.concat(
        props.format || 'content-image'
    );

    const [fireEvents] = useAnalytics();
    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
        ctaName: props.ctaLabel,
        categoryType: props.categoryType,
        categoryPath: props.categoryPath,
        ctaUrl: props.ctaUrl,
    };
    const triggerAnalytic = async () => {
        if (
            props.categoryType == 'category' ||
            props.categoryType == 'subcategory'
        ) {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                props.ctaUrl
            );
            if (referralExit) {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            } else {
                fireEvents('category-onclick-event', undefined, {
                    categoryOnClickCtaInfo,
                });
            }
        }
    };

    return (
        <div className={['category-quiet-card', format].join(' ')}>
            <div
                className={[
                    'category-quiet-card__content-wrap',
                    format.concat('__content-wrap'),
                ].join(' ')}
            >
                {props.subtitle && (
                    <h2 className="category-quiet-card__content-wrap__subtitle">
                        {props.subtitle}
                    </h2>
                )}
                {props.title && (
                    <h3 className="category-quiet-card__content-wrap__title">
                        {props.title}
                    </h3>
                )}
                <div className="category-quiet-card__content-wrap__body">
                    {props.body}
                </div>
                {props.ctaUrl && (
                    <div className="category-quiet-card__content-wrap__cta">
                        {props.ctaLabel && (
                            <PrimaryButton
                                className="category-quiet-card__content-wrap__cta__button"
                                href={props.ctaUrl}
                                color={
                                    props.buttonColor
                                        ? props.buttonColor
                                        : 'dark'
                                }
                                fill={
                                    props.buttonFill ? props.buttonFill : 'fill'
                                }
                                chevron={true}
                                ariaLabel={props.ctaAriaLabel}
                                target={props.targetBlank ? '_blank' : ''}
                                onClick={triggerAnalytic}
                            >
                                {props.ctaLabel}
                            </PrimaryButton>
                        )}
                        {props.ctaIconPath && (
                            <Link
                                href={props.ctaUrl}
                                target="_blank"
                                aria-label={props.ctaAriaLabel}
                                className="category-quiet-card__content-wrap__cta__link"
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        props.ctaIconPath
                                    }
                                    alt=""
                                />
                            </Link>
                        )}
                        {props.cta2Label && (
                            <PrimaryButton
                                className="category-quiet-card__content-wrap__cta__button"
                                href={props.cta2Url}
                                color={
                                    props.buttonColor
                                        ? props.buttonColor
                                        : 'dark'
                                }
                                fill={
                                    props.buttonFill ? props.buttonFill : 'fill'
                                }
                                chevron={true}
                                ariaLabel={props.cta2AriaLabel}
                                target={props.target2Blank ? '_blank' : ''}
                            >
                                {props.cta2Label}
                            </PrimaryButton>
                        )}
                        {props.cta2IconPath && (
                            <Link
                                href={props.cta2Url}
                                target="_blank"
                                aria-label={props.cta2AriaLabel}
                                className="category-quiet-card__content-wrap__cta__link"
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        props.cta2IconPath
                                    }
                                    alt=""
                                />
                            </Link>
                        )}
                    </div>
                )}
            </div>
            <div
                className={[
                    'category-quiet-card__image-wrap',
                    format.concat('__image-wrap'),
                ].join(' ')}
            >
                <div
                    className="category-quiet-card__image-wrap__mobile-image"
                    style={{
                        backgroundImage: `url('${props.mobileImageUrl}')`,
                    }}
                    aria-label={props.imageAltText || ''}
                    role={'img'}
                />
                <div
                    className="category-quiet-card__image-wrap__desktop-image"
                    style={{
                        backgroundImage: `url('${props.desktopImageUrl}')`,
                    }}
                    aria-label={props.imageAltText || ''}
                    role={'img'}
                />
            </div>
        </div>
    );
};
