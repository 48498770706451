import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import { ConnectedVehicleResponse } from '../../models/connected-vehicle';
import ServiceHandler from '../service-handler';
import HttpService from '../http-service/http-service';

export default class ConnectedVehicleService {
    public async getVehicleStatus(
        vin: string,
        year?: number
    ): Promise<ConnectedVehicleResponse | undefined> {
        if (year && !(year < 2017)) {
            const {
                currentRegionCode,
            } = ServiceHandler.AppConfigurationService;
            const url = DSL_BASE_URL + DSL_API_PATH.CONNECTED_VEHICLE;
            const params = {
                countryCode: currentRegionCode,
            };
            const headers = {
                ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
                vin,
            };
            const response = HttpService.get<ConnectedVehicleResponse>(
                url,
                true,
                {
                    params,
                    headers,
                }
            );
            response.catch(error => {
                console.error(error);
                return;
            });
            return response.then(ConnectedVehicleService.parseResponse);
        } else {
            return;
        }
    }

    private static parseResponse(
        response: AxiosResponse<ConnectedVehicleResponse>
    ): ConnectedVehicleResponse | undefined {
        if (response.data) {
            return response.data;
        }
    }
}
