import React, { useEffect, useRef, useState } from 'react';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import './service-price-calculator-mobile-list-dealer-details.scss';
import { FormatAddress } from '../../owners-osb/common/osb-common-components/osb-common-components';
import {
    getObjectFromAEMJson,
    isPreferredDealer,
} from '../../owners-osb/osb-utils';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../price-calculator-constant';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import PrimaryButton from '../../../common/primary-button/primary-button';
import filterIcon from '../../../../assets/owners-osb/dealer-filter.png';
import listIcon from '../../../../assets/owners-osb/mapView_24.svg';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import PcServiceRenderer from './pc-service-renderer';
import { DealerFilterOptions } from '../dealer-filter-options/dealer-filter-options';
import { useVehicleStep } from '../../../../hooks/owners-osb/use-vehicle-step';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import { useOSBStep } from '../../../../hooks/owners-osb/use-osb-step';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { triggerDealerSearchResultAnalytics } from '../analytics/price-calculator-analytics';

interface Props {
    profile?: ProfileWithVehiclesResponse;
    dealers: DealerProfileInfo[];
    preferredDealerIds: string[];
    priceCalculatorContent: ContentElements[];
    priceCalculatorResultContent: ContentElements[];
    isViewMoreButton: boolean;
    showMaxDealers: () => void;
    onMapsLinkClick: () => void;
    refreshDealerList?: (dealerProfiles: DealerProfileInfo[]) => void;
    goToServicesStep: (dealer: DealerProfileInfo) => void;
}
const ServicePriceCalculatorMobileListDealerDetails = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { osbVehicleStep } = useVehicleStep();
    const { osbStep } = useOSBStep();
    const listRef = useRef<HTMLDivElement>(null);
    const [isListFixed, setIsListFixed] = useState(false);
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);
    function getDistanceValueAndMilesLabel(dealer: any) {
        const resultConstants = {
            distanceUnit:
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_UNIT,
                    props.priceCalculatorResultContent
                )?.toLowerCase() ?? '',
            milesLabel:
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_FROM_YOU,
                    props.priceCalculatorResultContent
                ) ?? '',
        };

        const distanceValue =
            resultConstants.distanceUnit === 'miles'
                ? OsbUtilService.getDistanceInMiles(Number(dealer?.distance))
                : OsbUtilService.getDistanceInKm(Number(dealer?.distance));

        return `${distanceValue} ${resultConstants.milesLabel}` ?? '';
    }

    const handleListLinkClick = () => {
        props.onMapsLinkClick();
    };

    const toggleLightDealerFilterOptions = () => {
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };

    useEffect(() => {
        const vehicleInfoContainer = document.querySelector(
            '.dealer-detail-container'
        );
        if (vehicleInfoContainer) {
            const topValue = vehicleInfoContainer.getBoundingClientRect().top;
            if (serverSideService.isClientSide()) {
                window.scrollTo({ top: topValue, behavior: 'smooth' });
            }
        }
    }, [osbVehicleStep.vehicleDetails]);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { top } = listRef.current.getBoundingClientRect();
                if (top <= 77) {
                    setIsListFixed(true);
                } else {
                    setIsListFixed(false);
                }
            }
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
        triggerDealerSearchResultAnalytics(fireEvents);
    }, []);
    return (
        <>
            <div className={`list-actions ${isListFixed ? 'fixed' : ''}`}>
                {props.dealers.length > 1 && (
                    <button
                        className="filter-link"
                        onClick={toggleLightDealerFilterOptions}
                    >
                        <img src={filterIcon} alt="dealer-filter-icon" />
                        <span className="filters-text">
                            {' '}
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_LABEL,
                                props.priceCalculatorResultContent
                            )}
                        </span>
                    </button>
                )}

                {props.dealers.length > 1 && (
                    <button className="list-link" onClick={handleListLinkClick}>
                        <img src={listIcon} alt="dealer-filter-icon" />
                        <span className="list-text">
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.MAP_VIEW_LABEL,
                                props.priceCalculatorContent
                            )}
                        </span>
                    </button>
                )}
            </div>
            {showDealerFilterOptions && (
                <div className="spc-desktop-map-filter-component">
                    <DealerFilterOptions
                        toggleLightDealerFilterOptions={
                            toggleLightDealerFilterOptions
                        }
                        priceCalculatorResultContent={
                            props.priceCalculatorResultContent
                        }
                        refreshDealerDetailsList={props.refreshDealerList}
                    />
                </div>
            )}
            {!showDealerFilterOptions && (
                <div className="dealer-detail-container">
                    {props.dealers.map((dealer, index) => (
                        <>
                            {isPreferredDealer(
                                osbVehicleStep.vehicleDetails?.vin,
                                props.profile?.vehicles || [],
                                dealer,
                                osbStep.numberOfLeadingCharactersToStrip
                            ) && (
                                <div className="preferred-dealer-label">
                                    {getObjectFromAEMJson(
                                        PRICE_CALCULATOR_RESULT_CONSTANTS.PREFERRED_DEALER_LABEL,
                                        props.priceCalculatorContent
                                    )}
                                </div>
                            )}
                            <div
                                className={`dealer-details-section dealer-${index}`}
                            >
                                <div className="dealer-sequence-number">
                                    {index + 1}
                                </div>
                                <div className="dealer-info">
                                    <div className="dealer-name">
                                        {dealer.dealerName}
                                    </div>
                                    <div className="dealer-address-container">
                                        <div className="address-container">
                                            <div className="dealer-tile-address-text">
                                                <FormatAddress
                                                    addressData={dealer}
                                                    addressOrder={getObjectFromAEMJson(
                                                        PRICE_CALCULATOR_RESULT_CONSTANTS.DEALER_ADDRESS_ORDER,
                                                        props.priceCalculatorContent
                                                    )}
                                                    isDisplaySingleRow={true}
                                                />
                                            </div>
                                            {dealer.distance > 0 && (
                                                <div className="dealer-distance">
                                                    <span>
                                                        {getObjectFromAEMJson(
                                                            PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_LABEL,
                                                            props.priceCalculatorContent
                                                        )}
                                                        :
                                                    </span>{' '}
                                                    {getDistanceValueAndMilesLabel(
                                                        dealer
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <PcServiceRenderer
                                        dealer={dealer}
                                        priceCalculatorResultContent={
                                            props.priceCalculatorResultContent
                                        }
                                        priceCalculatorContent={
                                            props.priceCalculatorContent
                                        }
                                    />

                                    <div className="dealer-cta-container">
                                        <PrimaryButton
                                            role="link"
                                            color={'dark'}
                                            fill={'fill'}
                                            chevron={false}
                                            aria-label="select-dealer-cta"
                                            aria-labelledby="select dealer cta"
                                            dataTestId={`SelectDealer-${dealer.dealerCode}`}
                                            onClick={() =>
                                                props.goToServicesStep(dealer)
                                            }
                                        >
                                            {getObjectFromAEMJson(
                                                PRICE_CALCULATOR_RESULT_CONSTANTS.BOOK_NOW_BUTTON,
                                                props.priceCalculatorResultContent
                                            )}
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>

                            <hr className="dealer-separator" />
                        </>
                    ))}
                    {props.isViewMoreButton && (
                        <div className="spc-desktop-map-overlay-row spc-desktop-map-overlay-view-more">
                            <span
                                className="spc-desktop-map-view-more-text"
                                onClick={props.showMaxDealers}
                            >
                                {getObjectFromAEMJson(
                                    PRICE_CALCULATOR_RESULT_CONSTANTS.VIEW_MORE,
                                    props.priceCalculatorResultContent
                                )}
                            </span>
                            <FdsChevron direction={'down'} type="filled" />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ServicePriceCalculatorMobileListDealerDetails;
