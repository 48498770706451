import { useContent } from '../../../../hooks/use-server-content';

export interface RewardsContentProps {
    rewardsTitle: string;
    colOneIcon: string;
    colOneDescription: string;
    colTwoIcon: string;
    colTwoDescription: string;
    colThreeIcon: string;
    colThreeDescription: string;
    ctaLabel: string;
    ctaHref: string;
    rewardsDisclaimer: string;
    rewardsAdditionalText: string;
}
export const useRewardsContent = (): RewardsContentProps => {
    const [content, getValueByName] = useContent(
        'vehicles/vehicle-health-reports',
        'rewards'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as RewardsContentProps;
};
export default useRewardsContent;
