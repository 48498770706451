import React, { useEffect, useRef, useState } from 'react';
import {
    Link,
    PrimaryButton,
    SecondaryButton,
} from '../../../../../components/common';
import { ActivityIndicator } from '../../../../../components/common/activity-indicator/activity-indicator';
import {
    ForgotTrackingNumberProps,
    useForgotTrackingNumberContent,
} from '../../hooks/use-forgot-tracking-number-content';

import './forgot-tracking-number.scss';
import ServiceHandler from '../../../../../services/service-handler';
import { useAnalytics } from '../../../../../hooks/use-analytics';

interface Props {
    click: () => void;
}

const responseState = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    ERROR: 'ERROR',
    NOT_DETERMINED: 'NOT_DETERMINED',
};

interface SuccessMessageProps {
    forgotTrackingNumber: ForgotTrackingNumberProps | null;
    click: () => void;
    checkRebatresScroll: () => void;
    primaryButtonRef: React.RefObject<HTMLButtonElement>;
    setIsSuccess: React.Dispatch<React.SetStateAction<string>>;
}
const SuccessMessage = (props: SuccessMessageProps) => {
    return (
        props.forgotTrackingNumber && (
            <>
                <div className="success-banner">
                    <div className="success-message">
                        <h5 className="success-header">
                            {props.forgotTrackingNumber.checkRebatesTitle}
                        </h5>
                        <p
                            className="success-info"
                            data-testid="message-success"
                        >
                            {props.forgotTrackingNumber.onValidEmailSubmit}
                        </p>
                        <div className="button">
                            <PrimaryButton
                                ref={props.primaryButtonRef}
                                color="dark"
                                fill="fill"
                                chevron={true}
                                onClick={() => {
                                    props.checkRebatresScroll();
                                    props.click();
                                    props.setIsSuccess(
                                        responseState.NOT_DETERMINED
                                    );
                                }}
                            >
                                {props.forgotTrackingNumber.checkAnotherStatus}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

export const ForgotTrackingNumber = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const [email, setEmail] = useState('');
    const [isSuccess, setIsSuccess] = useState(responseState.NOT_DETERMINED);

    const forgotTrackingNumber = useForgotTrackingNumberContent();

    const [size, setSize] = useState(window.innerWidth);

    const checkRebatresScroll = () => {
        if (size < 350) {
            window.scrollTo({
                top: 2300,
                behavior: 'smooth',
            });
        } else if (size < 500) {
            window.scrollTo({
                top: 1900,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const VALID_MESSAGE = 'Check your email for further steps';
    const INVALID_MESSAGE = forgotTrackingNumber?.emailNotFound;
    const RETRY_MESSAGE = 'Error, please retry again after a minute';

    const INVALID_EMAIL_MESSAGE = forgotTrackingNumber?.inValidemailMsg;
    const EMPTY_EMAIL_MESSAGE = forgotTrackingNumber?.emailIdErrorMsg;

    const [fireEvents] = useAnalytics();

    const [isTouched, setTouched] = useState({
        email: false,
        submit: false,
    });

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    async function getUser() {
        setLoading(true);
        try {
            const response = await ServiceHandler.CheckStatusService.forgotTrackingNumber(
                email
            );
            setLoading(false);
            const outputData = response.emailTrackingInfoResult;
            if (outputData === 'Y') {
                setIsSuccess(responseState.SUCCESS);
            } else if (outputData === 'N') {
                setIsSuccess(responseState.FAILURE);
            }
        } catch (error) {
            setIsSuccess(responseState.ERROR);
            setLoading(false);
            console.error(error);
        }
    }

    const isValidEmail = () => {
        if (
            !email ||
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        ) {
            return false;
        }
        return true;
    };

    const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        checkRebatresScroll();
        if (isValidEmail()) {
            getUser();
        }
    };

    const getEmailWarning = () => {
        if (!email) {
            return EMPTY_EMAIL_MESSAGE;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            return INVALID_EMAIL_MESSAGE;
        }
        return '';
    };

    const getWarningMessage = () => {
        if (isSuccess === responseState.FAILURE) {
            return (
                <div className="warningft" tabIndex={0}>
                    {INVALID_MESSAGE}
                </div>
            );
        }
        if (isSuccess === responseState.SUCCESS) {
            return <div className="messageft">{VALID_MESSAGE}</div>;
        }
        if (isSuccess === responseState.ERROR) {
            return (
                <div className="warningft" tabIndex={0}>
                    {RETRY_MESSAGE}
                </div>
            );
        }
        return null;
    };

    const renderForm = () => {
        let responseStatus = '';

        if (isSuccess === responseState.SUCCESS) {
            responseStatus = 'valid';
        } else if (isSuccess !== responseState.NOT_DETERMINED) {
            responseStatus = 'invalid';
        }

        return (
            forgotTrackingNumber && (
                <>
                    <div className="check-rebates-formft">
                        <div className="check-rebate-statusft">
                            <h2 className="form__titleft">
                                {forgotTrackingNumber.checkRebatesTitle}
                            </h2>
                            <p className="form-textft">
                                {forgotTrackingNumber.questionsPartOneText}{' '}
                                <Link
                                    className="form-linkft"
                                    href={
                                        'mailto:' +
                                        forgotTrackingNumber.questionsPartTwoEmail
                                    }
                                    onClick={() => {
                                        fireEvents(
                                            'rr-check-status-cta-onclick-event',
                                            undefined,
                                            {
                                                ctaType: 'email',
                                            },
                                            false
                                        );
                                    }}
                                >
                                    {forgotTrackingNumber.questionsPartTwoEmail}
                                </Link>{' '}
                                {forgotTrackingNumber.questionsPartThreeText}{' '}
                                {forgotTrackingNumber.questionsPartFourNumber}
                            </p>
                        </div>
                        <div className="l-formft">
                            {loading ? (
                                <ActivityIndicator />
                            ) : (
                                <>
                                    <form
                                        data-testid="form"
                                        onSubmit={handelSubmit}
                                        className="form_elementsft"
                                    >
                                        <div className="service-rebates-rowft">
                                            <div className="row-elementsft">
                                                <div className="form__divft">
                                                    <input
                                                        data-testid="forgotEmail"
                                                        id="forgot-emailid"
                                                        placeholder=" "
                                                        className={`form__inputft ${responseStatus}`}
                                                        value={email}
                                                        onChange={onEmailChange}
                                                        onBlur={() =>
                                                            setTouched({
                                                                ...isTouched,
                                                                email: true,
                                                            })
                                                        }
                                                        aria-label={
                                                            'Enter' +
                                                            forgotTrackingNumber.emailText +
                                                            ` ${
                                                                isTouched.email
                                                                    ? getEmailWarning()
                                                                    : ''
                                                            }`
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="forgot-emailid"
                                                        className={`form__labelft ${responseStatus}`}
                                                    >
                                                        {
                                                            forgotTrackingNumber.emailText
                                                        }
                                                    </label>
                                                </div>
                                                {isTouched.email &&
                                                getEmailWarning() ? (
                                                    <div className="form__errorft">
                                                        {getEmailWarning()}
                                                    </div>
                                                ) : null}
                                                <div className="form__buttonft">
                                                    <SecondaryButton
                                                        className=""
                                                        onClick={() => {
                                                            checkRebatresScroll();
                                                            props.click();
                                                            fireEvents(
                                                                'rr-check-status-cta-onclick-event',
                                                                undefined,
                                                                {
                                                                    ctaType:
                                                                        'have your tracking number',
                                                                },
                                                                false
                                                            );
                                                        }}
                                                        noChevron={true}
                                                        type={'button'}
                                                    >
                                                        {
                                                            forgotTrackingNumber.haveTrackingNumber
                                                        }
                                                    </SecondaryButton>
                                                </div>
                                            </div>
                                            <div className="row-buttonft">
                                                <PrimaryButton
                                                    ref={primaryButtonRef}
                                                    color="dark"
                                                    fill="fill"
                                                    type={'submit'}
                                                    disabled={!isValidEmail()}
                                                    chevron={true}
                                                >
                                                    {
                                                        forgotTrackingNumber.formButtonText
                                                    }
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                        {getWarningMessage()}
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )
        );
    };
    return (
        <>
            {isSuccess === responseState.SUCCESS ? (
                <SuccessMessage
                    forgotTrackingNumber={forgotTrackingNumber}
                    click={props.click}
                    checkRebatresScroll={checkRebatresScroll}
                    primaryButtonRef={primaryButtonRef}
                    setIsSuccess={setIsSuccess}
                />
            ) : (
                renderForm()
            )}
        </>
    );
};
