import { useContent } from '../../../../hooks/use-server-content';

export interface LiveChatHelpCardsProps {
    hide: boolean;
    firstCardTitle: string;
    secondCardTitle: string;
    thirdCardTitle: string;
    firstCardUrl: string;
    secondCardUrl: string;
    thirdCardIcon: string;
    secondCardIcon: string;
    thirdCardUrl: string;
    firstCardIcon: string;
    firstCardTarget: string;
    secondCardTarget: string;
    thirdCardTarget: string;
}
export const useLiveChatHelpCardsContent = (): LiveChatHelpCardsProps => {
    const [content, getValueByName] = useContent(
        'common',
        'livechat-help-cards'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });

    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.firstCardTitle) {
            contentFragment = null;
        }
    }
    return contentFragment as LiveChatHelpCardsProps;
};
