import React, { ReactNode } from 'react';
import './heading.scss';

interface Props {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    children: ReactNode;
    className?: string;
    id?: string;
}
export const Heading = (props: Props) => {
    switch (props.type) {
        case 'h1':
            return (
                <h1
                    className={`heading-h1${
                        props.className ? ' ' + props.className : ''
                    }`}
                    id={props.id}
                >
                    {props.children}
                </h1>
            );
        case 'h2':
            return (
                <h2
                    className={`heading-h2${
                        props.className ? ' ' + props.className : ''
                    }`}
                    id={props.id}
                >
                    {props.children}
                </h2>
            );
        case 'h3':
            return (
                <h3
                    className={`heading-h3${
                        props.className ? ' ' + props.className : ''
                    }`}
                    id={props.id}
                >
                    {props.children}
                </h3>
            );
        case 'h4':
            return (
                <h4
                    className={`heading-h4${
                        props.className ? ' ' + props.className : ''
                    }`}
                    id={props.id}
                >
                    {props.children}
                </h4>
            );
        case 'h5':
            return (
                <h5
                    className={`heading-h5${
                        props.className ? ' ' + props.className : ''
                    }`}
                    id={props.id}
                >
                    {props.children}
                </h5>
            );
        case 'h6':
            return (
                <h6
                    className={`heading-h6${
                        props.className ? ' ' + props.className : ''
                    }`}
                    id={props.id}
                >
                    {props.children}
                </h6>
            );
    }
};
