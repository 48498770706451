import FMConnectedVehicle from '../fm-connected-vehicle/fm-connected-vehicle';
import { BatteryRangeContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import React from 'react';
import { UomDistance, Vehiclestatus } from '../../../models/connected-vehicle';

interface Props {
    connectedVehicleInfo: Vehiclestatus;
}

const FMConnectedVehicleRange = (props: Props & BatteryRangeContent) => {
    const elVehDTE = props.connectedVehicleInfo.elVehDTE;
    const uomDistance: UomDistance = props.connectedVehicleInfo.uomDistance;
    const nf = new Intl.NumberFormat();
    const rangeValue = () => {
        let rangeValue = '';
        if (elVehDTE) {
            rangeValue = nf.format(elVehDTE.value);
            if (rangeValue !== '0') {
                const uom = uomDistance === UomDistance.Miles ? ' MI' : ' KM';
                rangeValue += uom;
            }
        }
        return rangeValue;
    };
    return (
        <>
            {elVehDTE?.value ? (
                <FMConnectedVehicle
                    className="charge-level"
                    header={props.title}
                    iconPath={props.icon}
                    number={rangeValue()}
                />
            ) : (
                <></>
            )}
        </>
    );
};
export default FMConnectedVehicleRange;
