import { useEffect, useState } from 'react';
import { useVehicleStep } from '../../../../../hooks/owners-osb';
import PreferredServiceCentre from '../preferred-service-centre/preferred-service-centre';
import SearchServiceCentre from '../search-service-centre/search-service-centre';
import { useLocation } from 'react-router-dom';
import useMarketDealerInfoService from '../../../../../hooks/owners-osb/use-market-dealerInfo-service';
import { AllOsbDealersResponse } from '../../../../../models/osb-model/osb-dealer-info';
import PreferredServiceCentreSkeleton from '../preferred-service-centre-skeleton/preferred-service-centre-skeleton';
import ErrorDisplay from '../../common/error-display/error-display';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';

const ServiceCentreHandler = () => {
    const { osbVehicleStep } = useVehicleStep();
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'service-centre-step') || {};
    const osbConfigData =
        useOSBContentFragment('common/osb-v3', 'configuration') || {};

    const [dealerInfo, setDealerInfo] = useState<AllOsbDealersResponse | null>(
        null
    );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showPreferred, setShowPreferred] = useState(true);

    useEffect(() => {
        const fetchDealers = async () => {
            try {
                const response = await fetchMarketDealerInfo(deepLinkParams);
                if (response) {
                    setDealerInfo(response);
                    setError(null);
                } else {
                    setDealerInfo(null);
                    setError(
                        osbContentData?.serviceCentreErrorMessage as string
                    );
                }
            } catch (error) {
                setError(osbContentData?.serviceCentreErrorMessage as string);
            } finally {
                setLoading(false);
            }
        };

        fetchDealers();
    }, []);

    if (loading) {
        return <PreferredServiceCentreSkeleton />;
    }

    if (error) {
        return (
            <ErrorDisplay
                title={`${osbContentData?.serviceCentreErrorTitle}`}
                errorMessage={error}
                suggestion={`${osbContentData?.serviceCentreErrorSuggestion}`}
            />
        );
    }

    const preferredDealerCode = osbVehicleStep?.vehicleDetail?.preferredDealer;

    const dealerCodePrefixLength =
        parseInt(String(osbConfigData?.dealerCodePrefixLength), 10) || 0;
    const trimmedPreferredDealerCode = preferredDealerCode
        ? preferredDealerCode.slice(dealerCodePrefixLength)
        : null;

    const preferredDealer = dealerInfo?.dealers.find(
        dealer => dealer.dealerCode === trimmedPreferredDealerCode
    );
    const handleSearchDealerClick = () => {
        setShowPreferred(false);
    };

    const handlePreferredDealerClick = () => {
        setShowPreferred(true);
    };

    return (
        <div data-testid="service-centre-handler">
            {showPreferred && preferredDealer ? (
                <PreferredServiceCentre
                    preferredServiceCentre={preferredDealer.dealerName}
                    onSearchDealerClick={handleSearchDealerClick}
                />
            ) : (
                <SearchServiceCentre
                    onPreferredDealerClick={handlePreferredDealerClick}
                    hasPreferredDealer={!!preferredDealer}
                />
            )}
        </div>
    );
};

export default ServiceCentreHandler;
