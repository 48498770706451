import { useContent } from '../../../../hooks/use-server-content';

export interface PaginationCxProps {
    hidePagination?: boolean;
    showingText: string;
    ofText: string;
    articlesText: string;
    previousAriaLabel?: string;
    nextAriaLabel?: string;
    numberOfItemsPerPageInMobile: number;
    numberOfItemsPerPageInDesktop: number;
}
export const usePaginationCxContent = (): PaginationCxProps | null => {
    const [content, getValueByName] = useContent(
        'learning-hub',
        'pagination-cx'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hidePagination || !contentFragment.showingText) {
            contentFragment = null;
        }
    }
    return contentFragment as PaginationCxProps;
};
