import React from 'react';
import './connected-vehicle-current-mileage.ford.scss';
import {
    NumberStatus,
    UomDistance,
    Vehiclestatus,
} from '../../../models/connected-vehicle';
import { CurrentMileageContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import ConnectedVehicle from '../connected-vehicle/connected-vehicle';
interface Props {
    vin: string;
    connectedVehicleInfo: Vehiclestatus;
}

const ConnectedVehicleCurrentMileage = (
    props: Props & CurrentMileageContent
) => {
    const odometer: NumberStatus = props.connectedVehicleInfo.odometer;
    const uomDistance: UomDistance = props.connectedVehicleInfo.uomDistance;
    const nf = new Intl.NumberFormat();
    const odometerReading = () => {
        let odometerReading = '';
        if (odometer) {
            odometerReading = nf.format(odometer.value);
            if (odometerReading !== '0') {
                const uom = uomDistance === UomDistance.Miles ? ' MI' : ' KM';
                odometerReading += uom;
            }
        }
        return odometerReading;
    };
    return (
        <>
            {odometer && (
                <ConnectedVehicle
                    className="current-mileage"
                    header={props.title}
                    iconPath={props.icon}
                    number={odometerReading()}
                ></ConnectedVehicle>
            )}
        </>
    );
};
export default ConnectedVehicleCurrentMileage;
