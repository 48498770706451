import {
    PersonalDetail,
    PersonalDetailAction,
} from '../../models/osb-model/osb-personal-details';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { setClientStorageValue } from '../../components/sections/owners-osb/osb-utils';

export const personalDetailReducer = (
    state: PersonalDetail,
    action: PersonalDetailAction
): PersonalDetail => {
    let newState = null;
    switch (action.type) {
        case 'SET':
            newState = { ...state, ...action.payload };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_PERSONAL_DETAIL_STEP_KEY,
                newState,
                action.localStorageExpiry || 0
            );
            return newState;
        default:
            return state;
    }
};
