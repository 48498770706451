import React, { useState } from 'react';
import './datetime-step-view.scss';
import { useOSBStep } from '../../../hooks/owners-osb';
import { LightDateTime } from '../../../components/sections/owners-osb/datetime-step/light-journey/light-datetime';
import { OsbLoader } from '../../../components/common/osb-loader/osb-loader';
import { OsbAlertMessagePopup } from '../../../components/sections/owners-osb/common/osb-alert-message-popup/osb-alert-message-popup';

export function DateTimeStepView() {
    const { osbStep } = useOSBStep();
    const [voucherAlertMessage, setVoucherAlertMessage] = useState<string>('');
    const [voucherAlertSuccess, setVoucherAlertSuccess] = useState<boolean>(
        false
    );

    // added this method to handle OsbAlertMessage from light vehicle section to OsbAlertMessage component
    const osbAlertMessagePopup = (message: string, success: boolean) => {
        setVoucherAlertMessage(message);
        setVoucherAlertSuccess(success);
    };

    const closeOsbAlertPopup = () => {
        setVoucherAlertMessage('');
    };
    return (
        <>
            {osbStep.isMainWrapperInitialized ? (
                <>
                    {voucherAlertMessage && (
                        <OsbAlertMessagePopup
                            alertMessage={voucherAlertMessage}
                            alertSuccess={voucherAlertSuccess}
                            closeOsbAlertPopup={closeOsbAlertPopup}
                        />
                    )}
                    <div className="datetime-step-view-container">
                        <LightDateTime
                            osbAlertMessagePopup={osbAlertMessagePopup}
                        />
                    </div>
                </>
            ) : (
                <OsbLoader osbEmptyContentHeight={true} />
            )}
        </>
    );
}
