import React from 'react';
import { LightRetrieveAccessCode } from '../../../components/sections/owners-osb/retrieve-access-code/light-journey/light-retrieve-access-code';
import './retrieve-access-code-view.scss';

export function RetrieveAccessCodeView() {
    return (
        <>
            <div className="retrieve-access-code-view-container">
                <LightRetrieveAccessCode />
            </div>
        </>
    );
}
