import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { PageType } from '../view-template/page-type';
import {
    SmartTilesFragment,
    transformSmartTileContent,
} from '../../../models/experiencefragments/smarttiles';
import SmartTileIcon from '../../smart-tiles/smart-tile-icon/smart-tile-icon';
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { LoginButton } from '../login-button/login-button';
import { PrimaryButton } from '../../common';
import './smart-tile-grid.scss';
import { useSwipeable } from 'react-swipeable';
import { FdsChevron } from '../../common/chevron/fds-chevron';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import ServerContext from '../../../contexts/serverContext';
import {
    CONNECTED_VEHICLE_CARD_TYPE,
    VEHICLE_CHECKLIST_CARD_TYPE,
} from '../../../constants';

interface Props {
    vehicleAttributes?: VehicleAttributes;
    page: PageType;
    section: 'hero-row' | 'information-grid';
    className?: string;
    smartTileContent: SmartTilesFragment | null;
    analyticsEventName?: string;
    enableCarousel?: boolean;
}

export const SmartTileGrid = (props: Props) => {
    const isBev = props.vehicleAttributes?.engineType === 'BEV';
    const { smartTileContent, enableCarousel } = props;
    const sectionIds = ['HR', 'G', 'UA'];
    const vehicleName = `${props.vehicleAttributes?.year} ${props.vehicleAttributes?.make} ${props.vehicleAttributes?.model}`;
    let gridPage: number;
    let maxTileIndex = 1;
    let sectionId: any = '';
    let sectionHeading: React.ReactNode | null = null;
    const [tilesPositionX, setTilesPositionX] = useState<number>(0);
    const [currentTileIndex, setCurrentTileIndex] = useState<number>(0);
    const tileRef = useRef<HTMLDivElement>(null);
    const [showCarousel, setShowCarousel] = useState<boolean>(!!enableCarousel);
    const context = useContext(ServerContext);

    const isHeroRow = props.section === 'hero-row' && smartTileContent;
    const title = isHeroRow
        ? smartTileContent?.heroRowTilesTitle
        : smartTileContent?.informationGridTitle;
    sectionId = title?.replace(/ /g, '-').toLowerCase();

    const titleWithDisclaimer = isHeroRow
        ? smartTileContent?.heroRowTilesTitleWithDisclaimer
        : smartTileContent?.informationGridTitleWithDisclaimer;
    const subCopyWithDisclaimer = isHeroRow
        ? smartTileContent?.heroRowTilesSubCopyWithDisclaimer
        : smartTileContent?.informationGridSubCopyWithDisclaimer;

    sectionHeading = (
        <>
            <h2>{titleWithDisclaimer || title}</h2>
            {subCopyWithDisclaimer && (
                <p className="paragraph">
                    {subCopyWithDisclaimer} {vehicleName}
                </p>
            )}
            {isHeroRow && props.page !== 'DASHBOARD' && (
                <LoginButton page={props.page} />
            )}
        </>
    );

    let ownerState = 1;
    if (props.vehicleAttributes?.ownerState === 0) {
        ownerState = 0;
    }

    const smartTilesArray = transformSmartTileContent(
        smartTileContent,
        ownerState,
        isBev,
        props.page,
        props.vehicleAttributes,
        context.currentRegionCode,
        0,
        props.section === 'hero-row' ? 4 : undefined,
        props.analyticsEventName
    );
    maxTileIndex = smartTilesArray?.length - 1;
    const tilesToRender = smartTilesArray
        ? smartTilesArray.map(tile => {
              if (props.className?.startsWith('dashboard')) {
                  gridPage = 0;
              } else if (props.className?.startsWith('generic-grid')) {
                  gridPage = 2;
              } else {
                  gridPage = 1;
              }

              if (props.section === 'hero-row') {
                  tile.isHeroRow = true;
              }

              if (
                  [
                      ...Object.values(CONNECTED_VEHICLE_CARD_TYPE),
                      ...Object.values(VEHICLE_CHECKLIST_CARD_TYPE),
                  ].includes(tile.type)
              ) {
                  return <></>;
              } else {
                  return (
                      <SmartTileIcon
                          key={tile.staticTitle}
                          {...tile}
                          pageType={props.page}
                      />
                  );
              }
          })
        : null;

    const updateTilePositions = useCallback(() => {
        const deviceWidth = ServerSideService.isClientSide()
            ? document?.documentElement.clientWidth || window.innerWidth
            : 0;
        setTilesPositionX(
            -currentTileIndex *
                (tileRef.current?.getBoundingClientRect().width || 0)
        );
        setShowCarousel(
            (tileRef.current?.getBoundingClientRect().width || 1) *
                (tilesToRender ? tilesToRender.length : 0) >
                deviceWidth
        );
    }, [tileRef, currentTileIndex, setTilesPositionX]);

    useEffect(() => {
        if (enableCarousel) {
            window.addEventListener('resize', updateTilePositions);
            return () => {
                window.removeEventListener('resize', updateTilePositions);
            };
        }
    }, [smartTileContent]);

    function moveToNextTile() {
        if (enableCarousel && currentTileIndex < maxTileIndex) {
            setCurrentTileIndex(currentTileIndex + 1);
        }
    }

    function moveToPreviousTile() {
        if (enableCarousel && currentTileIndex > 0) {
            setCurrentTileIndex(currentTileIndex - 1);
        }
    }

    useEffect(() => {
        updateTilePositions();
    }, [currentTileIndex, updateTilePositions]);

    const handlers = useSwipeable({
        onSwipedLeft: moveToNextTile,
        onSwipedRight: moveToPreviousTile,
    });

    return (
        <>
            {smartTileContent && (
                <div
                    className={`smart-tile-grid-wrapper ${
                        enableCarousel ? 'carousel-wrap' : ''
                    } ${props.className || ''}`}
                    id={sectionId}
                >
                    {sectionHeading}
                    <div
                        {...handlers}
                        className={`inner-smart-tile-grid${
                            enableCarousel ? '-carousel' : ''
                        } ${props.className || ''}`}
                        style={{
                            transform: `translateX(${tilesPositionX}px)`,
                            transition: 'transform 300ms linear',
                        }}
                    >
                        {tilesToRender &&
                            tilesToRender.map((tile, index) => {
                                return (
                                    <div
                                        key={index}
                                        id={sectionIds[gridPage] + (index + 1)}
                                        ref={tileRef}
                                    >
                                        {tile}
                                    </div>
                                );
                            })}
                    </div>
                    {smartTileContent?.cta?.title && (
                        <PrimaryButton
                            dataTestId="primary-cta"
                            href={smartTileContent?.cta.url}
                            ariaLabel={smartTileContent.cta.ariaLabel}
                            target={
                                smartTileContent?.cta.targetBlank
                                    ? '_blank'
                                    : ''
                            }
                            color="dark"
                            fill="fill"
                            chevron={false}
                            className="smart-tiles-cta"
                        >
                            {smartTileContent?.cta.title}
                        </PrimaryButton>
                    )}
                    {enableCarousel && showCarousel && (
                        <div className={'carousel-buttons'}>
                            <PrimaryButton
                                className={`smart-tile-carousel-button ${
                                    currentTileIndex === 0 ? 'disabled' : ''
                                }`}
                                color="dark"
                                fill="fill"
                                chevron={false}
                                onClick={moveToPreviousTile}
                            >
                                <FdsChevron type="unfilled" direction="left" />
                            </PrimaryButton>
                            <span className="indicator-text">
                                {currentTileIndex + 1}-
                                {tilesToRender ? tilesToRender.length : 1}
                            </span>
                            <PrimaryButton
                                className={`smart-tile-carousel-button ${
                                    currentTileIndex === maxTileIndex
                                        ? 'disabled'
                                        : ''
                                }`}
                                color="dark"
                                fill="fill"
                                chevron={false}
                                onClick={moveToNextTile}
                            >
                                <FdsChevron type="unfilled" direction="right" />
                            </PrimaryButton>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
