import React, { useContext } from 'react';
import { BRAND, BRIGHTCOVE } from '../../../../../constants';
import ServerContext from '../../../../../contexts/serverContext';
import { HeroArticleData } from '../../../../../models/experiencefragments/category-view';
import { Video } from '../../../../common/video/video';
import './hero-article.scss';

export const HeroArticle = (props: {
    class?: string;
    category?: string;
    content: HeroArticleData;
}) => {
    const serverContext = useContext(ServerContext);

    const display = (): boolean | '' => {
        return !!(props?.content?.videoId || props?.content?.desktopImagePath);
    };

    const getDataPlayer = (): string => {
        return serverContext.brand === BRAND.lincoln.LONG_NAME
            ? BRIGHTCOVE.lincoln.PLAYER_ID
            : BRIGHTCOVE.ford.PLAYER_ID;
    };
    const getAccountId = (): string => {
        return serverContext.brand === BRAND.lincoln.LONG_NAME
            ? BRIGHTCOVE.lincoln.ACCOUNT_ID
            : BRIGHTCOVE.ford.ACCOUNT_ID;
    };

    return props?.content?.text ? (
        <div className={`hero-article ${props.class ? props.class : ''}`}>
            {display() && (
                <div className="asset-container">
                    {props?.content?.videoId ? (
                        <Video
                            videoId={props.content.videoId}
                            accountId={
                                props?.content?.accountId || getAccountId()
                            }
                            dataPlayer={
                                props?.content?.dataPlayerId || getDataPlayer()
                            }
                        />
                    ) : (
                        <img
                            src={
                                process.env.REACT_APP_AEM_BASE_URL +
                                props.content?.desktopImagePath
                            }
                            alt={props.content?.imageAltText}
                        />
                    )}
                    {props.content?.mediaDisclaimer ? (
                        <div className="media-disclaimer">
                            {props.content?.mediaDisclaimer}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            )}
            <div className="info-text-container">{props.content?.text}</div>
        </div>
    ) : null;
};
