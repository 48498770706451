import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import React, { useEffect, useReducer, useState } from 'react';
import {
    buildNavigationUrl,
    getObjectFromAEMJson,
    hasMobileService,
} from '../../../osb-utils';
import {
    DEALER_STEP_KEYS,
    STEP_PROGRESS_BAR_KEYS,
} from '../../../osb-constant';
import {
    getInitialDealerState,
    useCalendarStep,
    useDealerStep,
    useLightDeliveryOptionServiceStep,
    useOSBStep,
    useServiceLocationStep,
    useServiceStep,
    useStepProgressBar,
    useVehicleStep,
    useViewport,
} from '../../../../../../hooks/owners-osb';
import { dealerStepReducer } from '../../../../../../reducers/osb-reducers';
import { useHistory } from 'react-router-dom';
import { OsbPathServicesStep } from '../../../osb-controller';
import { DealerMoreInfo } from '../light-dealer-more-info/dealer-more-info';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import DealerDetailsMobileMap from './dealer-details-mobile-map';
import DealerDetailsMobileList from './dealer-details-mobile-list';
import DealerDetailsDesktop from './dealer-details-desktop';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { triggerSelectDealerCtaAnalytics } from '../../../analytics/osb-analytics';

interface Props {
    selectedLocation: string;
    dealers: DealerProfileInfo[];
    preferredDealerIds: string[];
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    dealerStepSpecialServicesContent: JsonFromAEMResponse[];
    showViewMoreButton: boolean;
    searchResultsCount: string;
    showMaxDealers: () => void;
    refreshDealerDetailsList?: (dealerProfiles: DealerProfileInfo[]) => void;
    isDealerFilterOptionsEnabled: boolean;
}
export default function DealerDetailsWrapper(props: Props) {
    const {
        selectedLocation,
        dealers,
        preferredDealerIds,
        dealerStepContent,
        dealerStepMoreContent,
        dealerStepSpecialServicesContent,
        showViewMoreButton,
        searchResultsCount,
        showMaxDealers,
    } = props;
    const [isMapView, setIsMapView] = useState<boolean>(true);
    const { isMobileView } = useViewport();
    const [fireEvents] = useAnalytics();
    const {
        updateProgressBarStatus,
        resetStepProgressBar,
    } = useStepProgressBar();
    const {
        osbStep,
        invalidateAuthentication,
        setOSBStepPayload,
    } = useOSBStep();
    const {
        resetLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const { resetServiceLocationStep } = useServiceLocationStep();
    const { resetCalendarStep } = useCalendarStep();
    const { osbDealerStep, setOSBDealerStep } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const [dealerStep] = useReducer(dealerStepReducer, getInitialDealerState());
    const history = useHistory();
    const handleViewMoreClick = () => {
        showMaxDealers();
    };
    const [isDealerMoreInfoView, setIsDealerMoreInfoView] = useState<boolean>(
        false
    );
    const [moreInfoDealerProfile, setMoreInfoDealerProfile] = useState<
        DealerProfileInfo
    >(dealers[0]);
    const [isDealerIndexValue, setIsDealerIndexValue] = useState<number>(0);

    const handleDealerDetails = (dealer: DealerProfileInfo, index: number) => {
        setIsDealerMoreInfoView(true);
        setMoreInfoDealerProfile(dealer);
        setIsDealerIndexValue(index);
    };
    const goToLightDealerDetail = () => {
        invalidateAuthentication();
        setIsDealerMoreInfoView(false);
    };
    const handleSelectDealer = async (
        dealer: DealerProfileInfo,
        index: number
    ) => {
        setOSBStepPayload({
            preferredDealerJourney: false,
        });
        const {
            selectedDealerProfile,
            searchType,
            osbEnabledDealerFromBing,
            dealerProfiles,
            filteredDealerProfiles,
            isDealerFilterOptionsEnabled,
            filterDeliveryServices,
            filterRegularServices,
            filterDidServices,
        } = osbDealerStep;
        const { isDealerDeepLinked, isVoucherDeepLinked } = osbStep;
        if (selectedDealerProfile?.dealerCode !== dealer.dealerCode) {
            if (isVoucherDeepLinked) {
                const tempVoucher = osbServiceStep.voucherCode;
                setOSBServiceStepPayload({ voucherCode: tempVoucher });
            }
            resetLightDeliveryServiceStep();
            resetServiceLocationStep();
            resetCalendarStep();
            resetStepProgressBar();
        }
        dealerStep.selectedDealerProfile = dealer;
        dealerStep.hasMobileService = hasMobileService(dealer.delivery || []);
        dealerStep.selectedLocation = selectedLocation?.trim() || '';
        dealerStep.selectedDealerName = dealer.dealerName?.trim() || '';
        dealerStep.searchDepth = `${
            isDealerDeepLinked
                ? DEALER_STEP_KEYS.SEARCH_DEPTH_FOR_DEALER_DEEPLINK
                : index + 1
        }:${getObjectFromAEMJson(
            DEALER_STEP_KEYS.MAX_DEALERS_SHOWN,
            dealerStepMoreContent
        )}`;
        dealerStep.dealerProfiles = dealerProfiles;
        dealerStep.filteredDealerProfiles = filteredDealerProfiles;
        dealerStep.searchType = searchType;
        dealerStep.osbEnabledDealerFromBing = osbEnabledDealerFromBing;
        dealerStep.isDealerFilterOptionsEnabled = isDealerFilterOptionsEnabled;
        dealerStep.filterDeliveryServices = filterDeliveryServices;
        dealerStep.filterRegularServices = filterRegularServices;
        dealerStep.filterDidServices = filterDidServices;
        triggerSelectDealerCtaAnalytics(
            dealerStep.selectedDealerProfile?.dealerCode,
            dealerStep.searchDepth,
            osbVehicleStep,
            fireEvents
        );
        setOSBDealerStep(dealerStep, false);
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DEALER, true);
        invalidateAuthentication();
        history.push(
            buildNavigationUrl(OsbPathServicesStep(), osbStep.UrlQueryParams)
        );
    };
    const handleListLinkClick = () => {
        setIsMapView(false);
    };
    const handleMapsLinkClick = () => {
        setIsMapView(true);
    };
    useEffect(() => {
        const progressBarSteps = document.getElementById('progress-bar-steps');
        const dealerLandingScreen = document.querySelector(
            '.dealer-landing-screen'
        );
        if (progressBarSteps && dealerLandingScreen) {
            const { top } = progressBarSteps.getBoundingClientRect();
            const progressBarHeight = progressBarSteps.clientHeight;
            const landingScreenHeight = dealerLandingScreen.clientHeight;

            setTimeout(() => {
                if (serverSideService.isClientSide()) {
                    window.scrollTo({
                        top: top + progressBarHeight + landingScreenHeight - 60,
                        behavior: 'smooth',
                    });
                }
            }, 500);
        }
    }, [selectedLocation, isMapView, props.refreshDealerDetailsList]);

    let componentToRender;
    if (isDealerMoreInfoView) {
        componentToRender = (
            <DealerMoreInfo
                dealerStepContent={dealerStepContent}
                dealerStepMoreContent={dealerStepMoreContent}
                dealerProfile={moreInfoDealerProfile}
                dealers={dealers}
                selectedLocation={props.selectedLocation}
                goToLightDealerDetail={goToLightDealerDetail}
                setIsDealerIndexValue={isDealerIndexValue}
                goToServicesStep={handleSelectDealer}
                dealerStepSpecialServicesContent={
                    dealerStepSpecialServicesContent
                }
                searchResultsCount={searchResultsCount}
                preferredDealerIds={preferredDealerIds}
            />
        );
    } else if (isMobileView && isMapView) {
        componentToRender = (
            <DealerDetailsMobileMap
                selectedLocation={selectedLocation}
                dealers={dealers}
                preferredDealerIds={preferredDealerIds}
                dealerStepContent={dealerStepContent}
                dealerStepMoreContent={dealerStepMoreContent}
                dealerStepSpecialServicesContent={
                    dealerStepSpecialServicesContent
                }
                showViewMoreButton={showViewMoreButton}
                searchResultsCount={searchResultsCount}
                showMaxDealers={showMaxDealers}
                onListLinkClick={handleListLinkClick}
                handleSelectDealer={handleSelectDealer}
                handleDealerDetails={handleDealerDetails}
                refreshDealerDetailsList={props.refreshDealerDetailsList}
                isDealerFilterOptionsEnabled={
                    props.isDealerFilterOptionsEnabled
                }
            />
        );
    } else if (isMobileView) {
        componentToRender = (
            <DealerDetailsMobileList
                dealers={dealers}
                preferredDealerIds={preferredDealerIds}
                dealerStepContent={dealerStepContent}
                dealerStepMoreContent={dealerStepMoreContent}
                showViewMoreButton={showViewMoreButton}
                onMapsLinkClick={handleMapsLinkClick}
                handleSelectDealer={handleSelectDealer}
                handleDealerDetails={handleDealerDetails}
                showMaxDealers={showMaxDealers}
                refreshDealerDetailsList={props.refreshDealerDetailsList}
                isDealerFilterOptionsEnabled={
                    props.isDealerFilterOptionsEnabled
                }
            />
        );
    } else {
        componentToRender = (
            <DealerDetailsDesktop
                dealers={dealers}
                dealerStepContent={dealerStepContent}
                dealerStepMoreContent={dealerStepMoreContent}
                showViewMoreButton={showViewMoreButton}
                showMaxDealers={handleViewMoreClick}
                handleSelectDealer={handleSelectDealer}
                handleDealerDetails={handleDealerDetails}
                preferredDealerIds={preferredDealerIds}
                refreshDealerDetailsList={props.refreshDealerDetailsList}
                isDealerFilterOptionsEnabled={
                    props.isDealerFilterOptionsEnabled
                }
            />
        );
    }
    return <React.Fragment>{componentToRender}</React.Fragment>;
}
