import { useMemo, useState } from 'react';
import ServiceHandler from '../../services/service-handler';
import {
    AllOsbDealersResponse,
    DealerProfileInfo,
} from '../../models/osb-model/osb-dealer-info';
import { LogService } from '../../services/log-service/log-service';
import { REGEX_PATTERNS } from '../../components/sections/owners-osb/osb-constant';

export const useMarketDealerInfoService = () => {
    const [allOsbDealers, setAllOsbDealers] = useState<
        AllOsbDealersResponse | undefined
    >(undefined);

    const marketDealerInfoService = async (
        deeplinkedDealerGroup: string | null,
        deeplinkedDealerCode: string | null
    ): Promise<AllOsbDealersResponse | undefined> => {
        try {
            const results = await ServiceHandler.OsbDslDealerDetailsService.getDealerDetails();
            let filteredDealers = results.value.dealers.filter(
                item => item.dealerProfile
            );
            const numericPattern = new RegExp(REGEX_PATTERNS.NUMERIC_PATTERN);
            if (
                deeplinkedDealerGroup &&
                deeplinkedDealerGroup?.match(numericPattern)
            ) {
                filteredDealers = filteredDealers.filter(
                    item =>
                        item.dealerProfile?.dealerGroup ===
                        deeplinkedDealerGroup
                );
            } else if (deeplinkedDealerCode) {
                filteredDealers = filteredDealers.filter(
                    item =>
                        item.dealerProfile?.dealerCode?.toUpperCase() ===
                        deeplinkedDealerCode.toUpperCase()
                );
            }
            const allDealers: AllOsbDealersResponse = {
                dealers: filteredDealers
                    .map(item => item.dealerProfile)
                    .filter(
                        (profile): profile is DealerProfileInfo =>
                            profile !== undefined
                    ),
                filterDeliveryServices:
                    results.value.filterDeliveryServices || [],
                filterDidServices: results.value.filterDidServices || [],
                filterRegularServices:
                    results.value.filterRegularServices || [],
            };
            setAllOsbDealers(allDealers);
            return Promise.resolve(allDealers);
        } catch (e) {
            LogService.log('DataLayer', e);
            return Promise.resolve(undefined);
        }
    };

    const fetchMarketDealerInfo = useMemo(
        () => async (
            deepLinkParams: URLSearchParams
        ): Promise<AllOsbDealersResponse | undefined> => {
            try {
                const deeplinkedDealerGroup =
                    deepLinkParams?.get('gdc') || null;
                const deeplinkedDealerCode = deepLinkParams?.get('dc') || null;
                const allDealers = await marketDealerInfoService(
                    deeplinkedDealerGroup,
                    deeplinkedDealerCode
                );
                return allDealers ?? undefined;
            } catch (error) {
                // Handle the error gracefully
                console.error('Error fetching market dealer info:', error);
                return undefined; // Return undefined in case of error
            }
        },
        [marketDealerInfoService]
    );

    const getDealer = useMemo(
        () => (dealerCode: string) => {
            if (!dealerCode || !allOsbDealers) {
                return undefined;
            }
            return allOsbDealers?.dealers?.find(
                dealer => dealer.dealerCode === dealerCode
            );
        },
        [allOsbDealers]
    );

    const getDealers = useMemo(
        () => (dealerCodes: string[]) => {
            if (!dealerCodes || !allOsbDealers) {
                return [];
            }
            return allOsbDealers?.dealers?.filter(dealer => {
                return dealerCodes.includes(dealer.dealerCode);
            });
        },
        [allOsbDealers]
    );

    return { fetchMarketDealerInfo, getDealer, getDealers };
};
export default useMarketDealerInfoService;
