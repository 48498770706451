import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ute-landing.ford.scss';
import plus from '../../../../../assets/plus-icon.svg';
import ServiceHandler from '../../../../../services/service-handler';
import { UteContext } from '../../../../../views/ultimate-towing-experience-view/context/ute-context';
import { getVin } from '../../../../utils/getVin-util/getVin-util';
import { UTE_ROUTES, trailerProfileTypes, UTEFormData } from '../types';
interface Props {
    uteExistingTrailerProfiles: any;
    profileContent: any;
    successMessageFlag: boolean;
}
export const UteLanding = (props: Props) => {
    const history = useHistory();
    const { uteContextFormData } = useContext(UteContext);
    const [uteTrailerProfiles, setUteTrailerProfiles] = useState<
        trailerProfileTypes | undefined
    >();
    const userSelectedVin = getVin();
    const getData = (selectedVin: any) => {
        ServiceHandler.RetrieveProfileUTE.request(selectedVin)
            .then(function(response) {
                return response;
            })
            .then(function(myJson) {
                setUteTrailerProfiles(myJson);
            });
    };
    const handleProfileButton = (uteProfile: any) => {
        uteContextFormData['profileDetails'] = uteProfile;
        history.push(UTE_ROUTES.VIEW_TRAILOR_PROFILE);
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        userSelectedVin && getData(userSelectedVin);
    }, [userSelectedVin]);

    return (
        <>
            <section className="ute-wrapper-section">
                <div className="ute-heading-section">
                    <h1 className="ute-header">
                        {props.profileContent.addProfileTitle}
                    </h1>
                </div>
                {props.successMessageFlag && (
                    <div className="ute-FormSucess">
                        <p>{props.profileContent.trailerProfileSuccess}</p>
                    </div>
                )}

                <div className="ute-content">
                    <div className="ute-desc">
                        <p> {props.profileContent.trailerProfileDescription}</p>
                    </div>

                    <div className="uteTrailerProfiles">
                        <ul>
                            <li className="add-trailerProfiles">
                                <button
                                    disabled={
                                        uteTrailerProfiles &&
                                        uteTrailerProfiles?.trailerList
                                            ? uteTrailerProfiles.trailerList
                                                  .length >= 10
                                                ? true
                                                : false
                                            : false
                                    }
                                    onClick={() => {
                                        props.uteExistingTrailerProfiles(
                                            uteTrailerProfiles
                                        );
                                        history.push(UTE_ROUTES.BASIC_INFO);
                                    }}
                                >
                                    {props.profileContent.addTrailerProfile}
                                    <img
                                        className="add-profiles"
                                        src={plus}
                                        alt={'Add Profiles'}
                                    />
                                </button>
                            </li>
                            {uteTrailerProfiles &&
                                uteTrailerProfiles.trailerList?.map(
                                    (uteProfile: UTEFormData, index: any) => (
                                        <li key={index}>
                                            <button
                                                className="trailer_list"
                                                onClick={() =>
                                                    handleProfileButton(
                                                        uteProfile
                                                    )
                                                }
                                            >
                                                {uteProfile.name}
                                            </button>
                                        </li>
                                    )
                                )}
                        </ul>
                    </div>
                    <div className="faq_wrap">
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html:
                                    props.profileContent.faqDescriptionWithCta,
                            }}
                        ></div>
                    </div>
                </div>
            </section>
        </>
    );
};
