import HttpService from '../http-service/http-service';
import { PriceCalculatorContentResponse } from '../../models/priceCalculator-content-details';
import OsbUtilService from '../osb-service/osb-util-service/osb-util-service';

export default class PriceCalculatorContentService {
    public async getPriceCalculatorContentModel(
        brand: string,
        region: string,
        language: string,
        modelName: string
    ): Promise<PriceCalculatorContentResponse> {
        const countryCode =
            OsbUtilService.getAppConfiguration().countryName === 'Mothersite'
                ? 'mothersite'
                : region;
        const url = `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/${brand}/${countryCode}/${language}/content-fragments/common/price-calculator/${modelName}.global-owner-model.json`;
        const response = HttpService.get(url, true);
        const responseData = await response;
        return responseData.data;
    }
}
