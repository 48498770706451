import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import ProfileService from '../../services/profile-service/profile-service';
import useUserProfile from '../../hooks/use-user-profile';

import { VehicleHealthReport } from '../../components/sections';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import { GoBackButton } from '../../components/sections/go-back-button/go-back-button';
import { ErrorMessage } from '../../components/sections/error-message/error-message';
import {
    VehicleHealthReportContentProps,
    useVehicleHealthReportContent,
} from '../../components/sections/vehicle-health-report/hooks/use-vehicle-health-report-content';
import { sha256 } from 'js-sha256';
import useSeoConfig from '../../hooks/use-seo-config';
import ServerContext from '../../contexts/serverContext';

const VehicleNotFoundText = (props: {
    content: VehicleHealthReportContentProps;
}) => {
    return (
        <>
            <section className="vehicle-health-report-page">
                <section className="vhr-page-header">
                    <div className="header-links">
                        {props.content.backButtonLabel && (
                            <GoBackButton
                                goBackLabel={props.content.backButtonLabel}
                                goBackUrl={props.content.backButtonTarget}
                            />
                        )}
                    </div>
                    <div className="vhr-page-title">
                        <h1>{props.content.headline}</h1>
                    </div>
                </section>

                <ErrorMessage errorMessage={props.content.vehicleNotFound} />
            </section>
        </>
    );
};

export function VehicleHealthReportView() {
    const authenticationService = new AuthenticationService();
    const content = useVehicleHealthReportContent();
    const profileService = new ProfileService();
    const profile = useUserProfile();
    const [
        vehicleAttributes,
        setVehicleAttributes,
    ] = useState<VehicleAttributes | null>(null);
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const { vin } = useParams<{ vin: string }>();
    const serverContext = useContext(ServerContext);

    const selectedVin =
        typeof window === 'undefined'
            ? serverContext.webviewData?.vin
            : window.sessionStorage.getItem('selectedVin');
    const [uomDistance, setUomDistance] = useState<number>(0);
    const [vinNumber, setVinNumber] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useSeoConfig('vehicle-health-report', 'vehicle-health-report');
    useEffect(() => {
        if (profile) {
            if (profile.profile) {
                setUomDistance(profile.profile.uomDistance);
            }
            profileService.persistVehicleSelection(profile.profile.email, vin);
            if (profile.vehicles.length > 0) {
                setVehiclesData(
                    [...profile.vehicles]
                        .sort(
                            (vehicle1, vehicle2) =>
                                parseInt(vehicle2.modelYear, 10) -
                                parseInt(vehicle1.modelYear, 10)
                        )
                        .map(vehicle => {
                            return {
                                year: parseInt(vehicle.modelYear, 10),
                                make: vehicle.make,
                                model: vehicle.modelName,
                                vin: vehicle.vin,
                                ownerState: vehicle.ownerState,
                                registrationNo: vehicle.licenseplate,
                            };
                        })
                );
            } else {
                setIsLoading(false);
            }
        } else {
            authenticationService.onIsAuthenticated().then(authenticated => {
                !authenticated && authenticationService.login();
            });
        }
    }, [profile]);

    const checkVin = (vehicle: any) => {
        if (selectedVin?.length === 17) {
            return vehicle.vin === selectedVin;
        } else if (vin?.length === 17) {
            return vehicle.vin === vin;
        } else if (vin?.length === 64) {
            return sha256(vehicle.vin).toUpperCase() === vin.toUpperCase();
        }
    };
    const getSelectedVehicleData = () => {
        return (
            vehiclesData.find(vehicle => {
                if (vehicle?.vin) {
                    return checkVin(vehicle);
                }
            }) || null
        );
    };
    const handleProfile = () => {
        if ((selectedVin || vin) && vehiclesData.length > 0) {
            const selectedVehicleData = getSelectedVehicleData();
            setVehicleAttributes(selectedVehicleData);
            setVinNumber(selectedVehicleData?.vin);
            setIsLoading(false);
        }
        if (!selectedVin && !vin && vehiclesData.length > 0) {
            setIsLoading(false);
        }
    };

    const handleAuthentication = () => {
        authenticationService.onIsAuthenticated().then(authenticated => {
            !authenticated && authenticationService.login();
        });
    };

    useEffect(() => {
        if (profile) {
            handleProfile();
        } else {
            handleAuthentication();
        }
    }, [vehiclesData]);

    return (
        <>
            {(() => {
                if (isLoading) {
                    return <ActivityIndicator />;
                } else {
                    if (vehicleAttributes && (selectedVin || vin)) {
                        return (
                            <VehicleHealthReport
                                page="Vehicle Health Report"
                                vehicleAttributes={vehicleAttributes}
                                vinNumber={vinNumber}
                                uomDistance={uomDistance}
                            />
                        );
                    } else if (profile && !vin && !selectedVin) {
                        return <VehicleNotFoundText content={content} />;
                    } else {
                        return <ActivityIndicator />;
                    }
                }
            })()}
        </>
    );
}

export default VehicleHealthReportView;
