import { FMButton } from '@own/fds-react';
import React, { ReactNode } from 'react';
import {
    AccordionItem,
    ListItem,
} from '../detailed-notification-item/detailed-notification-item';

export interface Props {
    headText?: string;
    subText?: string;
    iconPath?: string;
    detailedIconPath?: string;
    buttonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
    secondaryButtonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
    notificationDetails?: ListItem[];
    notificationAccordionDetails?: AccordionItem[];
    notificationName?: string;
    category?: string;
}

export interface NotificationItemButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    link?: string;
    linkTarget?: string;
    children: ReactNode;
    className?: string;
    noChevron?: boolean;
    ariaLabel?: string;
}

export const PreImagineNotificationItem = (props: Props) => {
    return (
        <div className="fm-notification-item-wrapper">
            <div className="fm-details-item">
                <div className="fm-details-message">{props.headText}</div>
                <div className="fm-details-subtext">{props.subText}</div>
            </div>
            {props.buttonProps && (
                <div className="fm-button-item">
                    <FMButton
                        href={props.buttonProps.link}
                        target={props.buttonProps.linkTarget || '_blank'}
                        aria-label={props.buttonProps['aria-label']}
                        onClick={props.buttonProps.onClick}
                        label={props.buttonProps.children}
                        type={'tertiary'}
                        chevron={'right'}
                    ></FMButton>
                </div>
            )}
        </div>
    );
};
