import AppConfigurationService from '../app-configuration-service/app-configuration-service';

class CafexService {
    private UAT_SCRIPT = 'cafex/js/QACafeXJS.js';
    private PROD_SCRIPT = 'cafex/js/ProdCafeXJS.js';
    public getCafexScriptPath(): string {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        if (appConfiguration.environment === 'prod') {
            return appConfiguration.domain
                ? appConfiguration.root + this.PROD_SCRIPT
                : `/${this.PROD_SCRIPT}`;
        }
        if (appConfiguration.environment === 'uat') {
            return appConfiguration.domain
                ? appConfiguration.root + this.UAT_SCRIPT
                : `/${this.UAT_SCRIPT}`;
        }
        return '';
    }
}

export const cafexService = new CafexService();
