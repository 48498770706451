import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import React, { useContext } from 'react';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import ServerContext from '../../../contexts/serverContext';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import {
    COUNTRIES_WITH_WARRANTY_DATE,
    IMG_COUNTRIES_WITH_WARRANTY_STATUS,
} from '../../../constants';
import './vehicle-checklist-warranty.scss';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import { replacePlaceholderByValue } from '../../utils/placeholder-util/replace-placeholder-by-value';

interface Props {
    warrantyDate: string;
    warrantyPeriod?: string;
    warrantyOdometer?: string;
}

const VehicleCheckListWarranty = (
    props: Props & VehicleAttributes & VehicleChecklistContent
) => {
    const serverContext = useContext(ServerContext);
    const appConfig = new AppConfigurationService();
    const { warrantyDate } = props;

    const warrantyDateByLocale = warrantyDate
        ? new DateTimeUtil().formatDate(
              warrantyDate,
              DateTimeUtil.intlFullYearDateOptions
          )?.currentRegionDate
        : warrantyDate;

    const displayWarrantyDate = (): boolean => {
        return (
            COUNTRIES_WITH_WARRANTY_DATE.includes(
                appConfig.currentCountryCode
            ) || appConfig.isRegionEU()
        );
    };
    const displayWarrantyStatus = (): boolean => {
        return IMG_COUNTRIES_WITH_WARRANTY_STATUS.includes(
            appConfig.currentCountryCode
        );
    };

    const warrantyCta = (): string => {
        if (props.vin && props.dynamicCtaPath) {
            serverContext.profile = {
                vin: props.vin,
            };
            return props.dynamicCtaPath.replace('%vin', '');
        }
        return props.staticCtaPath;
    };

    if (props?.vin) {
        if (displayWarrantyDate() && warrantyDateByLocale) {
            return (
                <VehicleChecklist
                    header={props.title}
                    type={props.cardTypes}
                    description={props.dynamicDescription}
                    ctaTitle={props.ctaTitle}
                    ctaPath={warrantyCta()}
                    iconPath={props.iconPath}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                >
                    <div className="vehicle-checklist-warranty-date">
                        {warrantyDateByLocale}
                    </div>
                </VehicleChecklist>
            );
        } else if (
            displayWarrantyStatus() &&
            props.warrantyPeriod &&
            props.warrantyOdometer
        ) {
            return (
                <VehicleChecklist
                    header={props.title}
                    type={props.cardTypes}
                    description={props.staticDescription}
                    ctaTitle={props.ctaTitle}
                    ctaPath={warrantyCta()}
                    iconPath={props.iconPath}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                >
                    <div className="vehicle-checklist-warranty-status">
                        {replacePlaceholderByValue(props.dynamicDescription, {
                            warrantyPeriod: props.warrantyPeriod,
                            warrantyOdometer: props.warrantyOdometer,
                        })}
                    </div>
                </VehicleChecklist>
            );
        } else {
            return (
                <VehicleChecklist
                    header={props.title}
                    type={props.cardTypes}
                    description={props.staticDescription}
                    ctaTitle={props.ctaTitle}
                    ctaPath={warrantyCta()}
                    iconPath={props.iconPath}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                />
            );
        }
    } else {
        return (
            <VehicleChecklist
                header={props.title}
                type={props.cardTypes}
                description={props.staticDescription}
                ctaTitle={props.ctaTitle}
                ctaPath={props.staticCtaPath}
                iconPath={props.iconPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
            />
        );
    }
};

export default VehicleCheckListWarranty;
