import React, { useEffect } from 'react';
import './warranty-information.scss';
import { useWarrantyQuickGuideContent } from './hooks/use-warranty-information-content';
import { VinInputSection } from './vin-section/vin-input-section';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import { RelatedTopicsSection } from './related-topics/related-topics';
import { WarrantyQuickGuideAccordion } from './warranty-quick-guide-accordion/warranty-quick-guide-accordion';
import { useAnalytics } from '../../../hooks/use-analytics';
import { SupportTrio } from '../../common/support-trio/support-trio';
import { BreadcrumbWrapperReimagine } from '../../../components/common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';
import { ViewTemplate } from '../view-template/view-template';
import { PageType } from '../view-template/page-type';
import isWebviewCheck from '../../utils/webviewCheck/webviewCheck';
import { findByAlias } from '../../../routes';
import { VehicleSelectorFds } from '../vehicle-selector-fds/vehicle-selector-fds';
import { InfoIconToolTip } from '../../common/info-icon-tooltip/info-icon-tooltip';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';

interface Props {
    loginClick: (action: string) => void;
    goToWarrantyDetails: (vin: string) => void;
    page: PageType;
    warrantyInformationContent: any;
}

export const WarrantyInformation = (props: Props) => {
    const appConfig = new AppConfigurationService();
    const isRegionNA = appConfig.isRegionNA();
    const isContentAvailable = !!Object.keys(props.warrantyInformationContent)
        .length;
    const {
        title,
        vinInputHeader,
        vinInputLabel,
        vinInputPlaceholder,
        vinInputDividerText,
        warrantyHeader,
        warrantyQuickGuideHeading,
        warrantyQuickGuideSubtext,
        warrantyQuickGuideReferences,
        relatedTopics,
        vehicleSelectorYearDropdownLabel,
        vehicleSelectorVinSubtitle,
        vehicleSelectorVinInput,
        seeYourWarrantyCtaLabel,
        chooseVehicleGarageHeadLine,
        loginHeading,
        loginDescription,
        signinButtonText,
        invalidVinValidationMessage,
        invalidVinOptionalValidationMessage,
        vehicleVinTooltipHeader,
        vehicleVinTooltipContent,
        registerLinkCtaLabel,
        warrantyNotFoundErrorMessage,
        registrationLookup,
        regNumberValidationMessage,
        vehicleSelectorModelDropdownLabel,
        vehicleSelectorDisclaimerLabel,
        vehicleSelectorModelSubtitle,
        vehicleSelectorDividerText,
        loginSwitchLabel,
        loggedInSwitchLabel,
        vehicleSwitchLabel,
        vinTooltipHeader,
        vinTooltipAriaLabel,
        vinTooltipContent,
    } = props.warrantyInformationContent;
    const warrantyQuickGuideContent: any = useWarrantyQuickGuideContent(
        warrantyQuickGuideReferences
    );
    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    const isWebview = isWebviewCheck();

    const [fireEvents] = useAnalytics();
    const triggerLoadAnalytics = () => {
        fireEvents(
            ['target-trigger-view', 'warranty-landing'],
            'warranty-landing'
        );
    };
    const vinTooltip = (
        <InfoIconToolTip
            osbServiceTooltip={true}
            tooltipInfoIconClass={'dark'}
            tooltipHeader={vinTooltipHeader}
            tooltipContent={vinTooltipContent || ''}
            customShowTooltipAriaLabel={vinTooltipAriaLabel}
        />
    );
    useEffect(() => {
        scrollPageToTop();
        triggerLoadAnalytics();
    }, []);
    return (
        <>
            <ViewTemplate
                page={props.page}
                className="trailer-checklist-view"
                hideLinksList={true}
                hideSearchBar={isWebview}
            >
                {isContentAvailable ? (
                    <>
                        {isWebview ? (
                            ''
                        ) : (
                            <BreadcrumbWrapperReimagine
                                currentPage={title}
                                hashId="support"
                            />
                        )}
                        <section className="warranty-information-section">
                            <section className="warranty-information-section-header">
                                <div
                                    className="warranty-information-title"
                                    dangerouslySetInnerHTML={{
                                        __html: warrantyHeader,
                                    }}
                                ></div>
                            </section>
                            {!isRegionNA && (
                                <VinInputSection
                                    contentLabels={{
                                        vinInputHeader,
                                        vinInputLabel,
                                        vinInputPlaceholder,
                                        vinInputDividerText,
                                        chooseVehicleGarageHeadLine,
                                        seeYourWarrantyCtaLabel,
                                        loginHeading,
                                        loginDescription,
                                        signinButtonText,
                                        invalidVinValidationMessage,
                                        invalidVinOptionalValidationMessage,
                                        vehicleVinTooltipHeader,
                                        vehicleVinTooltipContent,
                                        registerLinkCtaLabel,
                                        registrationLookup,
                                        regNumberValidationMessage,
                                        warrantyNotFoundErrorMessage,
                                    }}
                                    loginClick={props.loginClick}
                                    goToWarrantyDetails={
                                        props.goToWarrantyDetails
                                    }
                                />
                            )}
                            {isRegionNA && (
                                <VehicleSelectorFds
                                    tooltip={vinTooltip}
                                    redirectData={{
                                        path: findByAlias(
                                            'WarrantyInformationViewRoute'
                                        ),
                                        id: 'warranty-information',
                                    }}
                                    labels={{
                                        vinHeader: vehicleSelectorVinSubtitle,
                                        vinPlaceholder: vehicleSelectorVinInput,
                                        yearDropdown: vehicleSelectorYearDropdownLabel,
                                        modelDropdown: vehicleSelectorModelDropdownLabel,
                                        submit: seeYourWarrantyCtaLabel,
                                        disclaimer: vehicleSelectorDisclaimerLabel,
                                        header: vehicleSelectorVinSubtitle,
                                        dividerText: vehicleSelectorDividerText,
                                        vehicleSelectorModelSubtitle: vehicleSelectorModelSubtitle,
                                        loginSwitch: loginSwitchLabel,
                                        loggedInSwitch: loggedInSwitchLabel,
                                        vehicleSwitch: vehicleSwitchLabel,
                                    }}
                                    eventName={'warranty-cta'}
                                    handleVinSubmit={props.goToWarrantyDetails}
                                />
                            )}
                            <section className="warranty-quickguide-section">
                                <h3 className="warranty-quickguide-heading">
                                    {warrantyQuickGuideHeading}
                                </h3>
                                <div
                                    className="warranty-quickguide-subtext"
                                    dangerouslySetInnerHTML={{
                                        __html: warrantyQuickGuideSubtext,
                                    }}
                                ></div>
                            </section>
                            {warrantyQuickGuideReferences && (
                                <WarrantyQuickGuideAccordion
                                    warrantyQuickGuideReferences={
                                        warrantyQuickGuideContent
                                    }
                                />
                            )}
                            {relatedTopics && (
                                <RelatedTopicsSection
                                    relatedTopicsContent={relatedTopics}
                                />
                            )}
                        </section>
                        <SupportTrio
                            folder="common"
                            experienceFragmentName="support-trio"
                        />
                    </>
                ) : (
                    <ActivityIndicator />
                )}
            </ViewTemplate>
        </>
    );
};
