import React from 'react';
import { ToolTip } from '../tooltip/tooltip';

import './disclaimer-tooltip.scss';

interface Props {
    symbol: string;
    disclaimer: string;
}
export const DisclaimerTooltip = (props: Props) => {
    return (
        <ToolTip
            className="disclaimer__tooltip"
            tooltipSymbol={
                <span className="disclaimer__tooltip-symbol">
                    {props.symbol}
                </span>
            }
            tooltipContent={props.disclaimer}
        ></ToolTip>
    );
};
