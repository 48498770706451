import React, { useEffect, useState } from 'react';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import {
    BOOKING_CONFIRMATION_KEYS,
    BOOKING_SUMMARY_CONTAINER_KEYS,
    SERVICE_FLOW,
    SERVICES_STEP_KEYS,
} from '../../osb-constant';
import './light-booking-details-lincoln.scss';
import {
    useOSBStep,
    useLightDeliveryOptionServiceStep,
    useVehicleStep,
    useServiceStep,
    useDealerStep,
    useHttp,
    useViewport,
    useServiceLocationStep,
    useOsbImageUpload,
    useCalendarStep,
} from '../../../../../hooks/owners-osb';
import ServiceHandler from '../../../../../services/service-handler';
import { OSBVideoLink } from '../../common/osb-video-link/osb-video-link';
import { OSBVideoImageOverlay } from '../../common/osb-overlay/osb-video-image-overlay';
import { OSBBrightcoveVideoPlayer } from '../../common/osb-brightcove-video-player/osb-brightcove-video-player';
import {
    isConvenienceOptionIncluded,
    showServiceLocationDetails,
} from '../../osb-utils';
import { FormatAddress } from '../../common/osb-common-components/osb-common-components';

interface Props {
    isLoading: boolean;
    isDealerDeepLinked: boolean;
    dealerName: string;
    dealerAddress: string;
    dealerPostalCode: string;
    selectedDate: string;
    selectedTime: string;
    bookingNumber: string;
    accessCode: string;
    bookingConfirmationStepContent?: OsbContentResponse;
    handlePrint?: () => void;
}

export const LightBookingDetails = (props: Props) => {
    const { osbServiceLocationDetail } = useServiceLocationStep();
    const { isMobileView } = useViewport();
    const { osbStep } = useOSBStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbServiceStep } = useServiceStep();
    const { osbDealerStep } = useDealerStep();
    const { osbImageUploadStep } = useOsbImageUpload();
    const [reviewBookingContent, setReviewBookingContent] = useState<
        OsbContentResponse
    >();
    const { osbCalendarStep } = useCalendarStep();
    const { httpState, dispatch } = useHttp();
    const vehicleDetails = osbVehicleStep.vehicleDetails;
    const vehicleName =
        vehicleDetails?.modelName +
        ' ' +
        vehicleDetails?.buildDate.substring(0, 4);
    const selectedServicesList = osbServiceStep.selectedServices;

    const selectedOtherOptionsServices = osbServiceStep.isMobileServiceSelected
        ? osbServiceLocationDetail.selectedOtherOptions
        : osbLightDeliveryServiceStep.selectedServices;
    const [dateAndMonthFormat, setdateandMonthFormat] = useState('');
    const [isVideoOverlayOpened, setIsVideoOverlayOpened] = useState(false);
    const [brightCoveAccountId, setBrightCoveAccountId] = useState('');
    const [brightCoveVideoId, setBrightCoveVideoId] = useState('');
    const [brightCovePlayerId, setBrightCovePlayerId] = useState('');
    const [brightCoveVideoName, setBrightCoveVideoName] = useState('');

    let dateFormat = '';
    function getReviewBookingContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-review-booking'
        )
            .then(results => {
                setReviewBookingContent(results);
                dateFormat =
                    results?.elements.find(
                        e =>
                            e.name ===
                            BOOKING_SUMMARY_CONTAINER_KEYS.DATE_FORMAT_SUMMARY
                    )?.value + ''.toString().trim();
                setdateandMonthFormat(dateFormat);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.error.message;
                    dispatch({ type: 'ERROR', error: errorMessage });
                }
            });
    }
    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getReviewBookingContent();
    }, []);

    const showVideoOverlay = (
        accountId = '',
        videoId = '',
        playerId = '',
        videoName = ''
    ) => {
        if (accountId && videoId && playerId) {
            setIsVideoOverlayOpened(true);
            setBrightCoveAccountId(accountId);
            setBrightCoveVideoId(videoId);
            setBrightCovePlayerId(playerId);
            setBrightCoveVideoName(videoName);
        }
    };

    const hideVideoOverlay = () => {
        setIsVideoOverlayOpened(false);
        setBrightCoveAccountId('');
        setBrightCoveVideoId('');
        setBrightCovePlayerId('');
        setBrightCoveVideoName('');
    };

    return (
        <div className="osb-light-booking-details-section">
            <div className="osb-light-booking-details-header">
                {!props.isLoading
                    ? props.bookingConfirmationStepContent?.elements.find(
                          key =>
                              key.name ===
                              BOOKING_CONFIRMATION_KEYS.BOOKING_DETAIL_TITLE
                      )?.value + ''.toString()
                    : ''}
            </div>
            <div className="osb-light-booking-details-container">
                {!isMobileView && <div className="hr-line"></div>}
                {!OsbUtilService.isEmptyObject(vehicleDetails) &&
                    vehicleDetails?.modelName !== '' &&
                    vehicleDetails?.modelName.toUpperCase() !==
                        osbVehicleStep.defaultModelName?.toUpperCase() && (
                        <div className="vehicle-section">
                            <div className="vehicle-title">
                                {!httpState.isLoading
                                    ? reviewBookingContent?.elements.find(
                                          e => e.name === 'Vehicle_Label'
                                      )?.value + ''.toString()
                                    : ''}
                            </div>
                            <div className="vehicle-name">{vehicleName}</div>

                            <div className="hr-line-style"></div>
                        </div>
                    )}
                {selectedServicesList.length === 0 &&
                    osbServiceStep.isMobileServiceSelected && (
                        <div className="service-section">
                            <div className="service-title">
                                {!httpState.isLoading
                                    ? reviewBookingContent?.elements.find(
                                          e =>
                                              e.name ===
                                              BOOKING_SUMMARY_CONTAINER_KEYS.SERVICES_LABEL
                                      )?.value + ''.toString()
                                    : ''}
                            </div>
                            <div className="service-sub-title">
                                {!httpState.isLoading
                                    ? reviewBookingContent?.elements.find(
                                          e =>
                                              e.name ===
                                              BOOKING_SUMMARY_CONTAINER_KEYS.MOBILE_SERVICE_TITLE
                                      )?.value + ''.toString()
                                    : ''}
                            </div>
                            <div className="hr-line-style"></div>
                        </div>
                    )}
                {selectedServicesList.length > 0 && (
                    <div className="service-section">
                        <div className="service-title">
                            {!httpState.isLoading
                                ? reviewBookingContent?.elements.find(
                                      e =>
                                          e.name ===
                                          BOOKING_SUMMARY_CONTAINER_KEYS.SERVICES_LABEL
                                  )?.value + ''.toString()
                                : ''}
                        </div>
                        <div>
                            {selectedServicesList[0].serviceOption ===
                            SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE ? (
                                <>
                                    <div className="service-sub-title">
                                        {!httpState.isLoading
                                            ? reviewBookingContent?.elements.find(
                                                  e =>
                                                      e.name ===
                                                      BOOKING_SUMMARY_CONTAINER_KEYS.GENERAL_APPOINTMENT_SERVICE_LABEL
                                              )?.value + ''.toString()
                                            : ''}
                                    </div>
                                    <OSBVideoLink
                                        selectedService={
                                            selectedServicesList[0]
                                        }
                                        showVideoOverlay={showVideoOverlay}
                                    />
                                </>
                            ) : (
                                <div className="service-sub-title">
                                    {!httpState.isLoading
                                        ? osbServiceStep.isMobileServiceSelected
                                            ? reviewBookingContent?.elements.find(
                                                  e =>
                                                      e.name ===
                                                      BOOKING_SUMMARY_CONTAINER_KEYS.MOBILE_SERVICE_TITLE
                                              )?.value + ''.toString()
                                            : reviewBookingContent?.elements.find(
                                                  e =>
                                                      e.name ===
                                                      BOOKING_SUMMARY_CONTAINER_KEYS.SPECIFIC_SERVICES_LABEL
                                              )?.value + ''.toString()
                                        : ''}
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="services-list">
                                {selectedServicesList[0].serviceOption !==
                                    SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE &&
                                    selectedServicesList.map(
                                        (selectedService: any) => (
                                            <div key={selectedService.id}>
                                                <div className="single-service">
                                                    <div className="service-name">
                                                        {
                                                            selectedService.serviceName
                                                        }
                                                        {selectedService.subType &&
                                                            selectedService.subType ===
                                                                SERVICE_FLOW.SERVICE_SUBTYPE_RECALL && (
                                                                <div className="light-recall-service-desc">
                                                                    {osbServiceStep.recallServiceDescription.map(
                                                                        desc => (
                                                                            <div
                                                                                key={
                                                                                    selectedService.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    desc
                                                                                }
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                        {selectedService.subType &&
                                                            selectedService.subType ===
                                                                SERVICE_FLOW.SERVICE_SUBTYPE_VHA && (
                                                                <div className="light-recall-service-desc">
                                                                    {osbServiceStep.vhaServiceDescription?.map(
                                                                        desc => (
                                                                            <div
                                                                                key={
                                                                                    selectedService.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    desc
                                                                                }
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                        {selectedService.subType &&
                                                            selectedService.subType ===
                                                                SERVICE_FLOW.SERVICE_SUBTYPE_OIL && (
                                                                <div className="light-recall-service-desc">
                                                                    <div>
                                                                        {
                                                                            osbServiceStep.oilLifeServiceReminderDescription
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {selectedService.subType &&
                                                            selectedService.subType ===
                                                                SERVICE_FLOW.OTHER_CUSTOMSERVICE && (
                                                                <div className="light-recall-service-desc">
                                                                    -{' '}
                                                                    {
                                                                        osbServiceStep.otherServiceDesc
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <OSBVideoLink
                                                    selectedService={
                                                        selectedService
                                                    }
                                                    showVideoOverlay={
                                                        showVideoOverlay
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                        {osbImageUploadStep.uploadedImage &&
                            osbImageUploadStep.uploadedImage?.length > 0 &&
                            reviewBookingContent?.elements.find(
                                e =>
                                    e.name ===
                                    SERVICES_STEP_KEYS.IMAGE_UPLOADED_LABEL
                            )?.value && (
                                <div className="image-upload-count">
                                    {`${
                                        !httpState.isLoading
                                            ? reviewBookingContent?.elements.find(
                                                  e =>
                                                      e.name ===
                                                      SERVICES_STEP_KEYS.IMAGE_UPLOADED_LABEL
                                              )?.value + ''.toString()
                                            : ''
                                    } (${
                                        osbImageUploadStep.uploadedImage?.length
                                    })`}
                                </div>
                            )}
                        <div className="hr-line-style"></div>
                    </div>
                )}
                {selectedOtherOptionsServices.length > 0 && (
                    <div className="service-section">
                        <div className="service-title">
                            {!httpState.isLoading
                                ? reviewBookingContent?.elements.find(
                                      e =>
                                          e.name ===
                                          BOOKING_SUMMARY_CONTAINER_KEYS.DELIVERY_SERVICES_LABEL
                                  )?.value + ''.toString()
                                : ''}
                        </div>
                        <div>
                            <div className="services-list">
                                {selectedOtherOptionsServices.map(
                                    (selectedOptionalService: any) => (
                                        <div key={selectedOptionalService.id}>
                                            <div className="single-service">
                                                <div className="service-name">
                                                    {
                                                        selectedOptionalService.serviceName
                                                    }
                                                    {selectedOptionalService.convenienceOptionTypes &&
                                                        isConvenienceOptionIncluded(
                                                            selectedOptionalService.convenienceOptionTypes,
                                                            SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE
                                                        ) && (
                                                            <div className="pick-up-details">
                                                                <div className="selected-pickup-date">
                                                                    {osbCalendarStep
                                                                        .selectedPickupDate
                                                                        .length >
                                                                        0 &&
                                                                        OsbUtilService.constructDisplayDate(
                                                                            osbCalendarStep.selectedPickupDate,
                                                                            dateAndMonthFormat,
                                                                            OsbUtilService.getAppConfiguration()
                                                                                .languageRegionCode
                                                                        )}
                                                                </div>
                                                                <div className="selected-pickup-time">
                                                                    {osbCalendarStep
                                                                        .selectedPickupTime
                                                                        .length >
                                                                        0 &&
                                                                        OsbUtilService.getTimeBasedOnAuthoring(
                                                                            osbCalendarStep.selectedPickupTime,
                                                                            osbStep.twelveHrsTimeFormat,
                                                                            osbStep.amTimeLabel,
                                                                            osbStep.pmTimeLabel,
                                                                            osbStep.timeSuffixLabel
                                                                        )}
                                                                </div>
                                                                <div className="customer-address">
                                                                    <FormatAddress
                                                                        addressData={
                                                                            osbServiceLocationDetail
                                                                        }
                                                                        addressOrder={
                                                                            osbStep.dealerAddressOrder
                                                                        }
                                                                        isDisplaySingleRow={
                                                                            false
                                                                        }
                                                                    />
                                                                </div>
                                                                {osbServiceLocationDetail.comment.trim()
                                                                    .length >
                                                                    0 && (
                                                                    <div className="sl-comments">
                                                                        {`"${osbServiceLocationDetail.comment}"`}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <OSBVideoLink
                                                selectedService={
                                                    selectedOptionalService
                                                }
                                                showVideoOverlay={
                                                    showVideoOverlay
                                                }
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="hr-line-style"></div>
                    </div>
                )}
                {isVideoOverlayOpened && (
                    <OSBVideoImageOverlay
                        isOpen={isVideoOverlayOpened}
                        backButtonText={osbStep.videoPlayerBackButtonLabel}
                        hideVideoOverlay={hideVideoOverlay}
                        isVideo={true}
                    >
                        <OSBBrightcoveVideoPlayer
                            accountId={brightCoveAccountId}
                            videoId={brightCoveVideoId}
                            playerId={brightCovePlayerId}
                            videoName={brightCoveVideoName}
                        />
                    </OSBVideoImageOverlay>
                )}
                <div className="when-section">
                    <div className="when-title">
                        {!props.isLoading
                            ? props.bookingConfirmationStepContent?.elements.find(
                                  key =>
                                      key.name ===
                                      BOOKING_CONFIRMATION_KEYS.WHEN_LABEL
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div className="date-time">
                        {OsbUtilService.constructDisplayDate(
                            props.selectedDate,
                            dateAndMonthFormat,
                            OsbUtilService.getAppConfiguration()
                                .languageRegionCode
                        )}
                        <br></br>
                        {osbCalendarStep.selectedTime && (
                            <div>
                                {!httpState.isLoading
                                    ? reviewBookingContent?.elements.find(
                                          e =>
                                              e.name ===
                                              BOOKING_SUMMARY_CONTAINER_KEYS.TIME_AT_LABEL
                                      )?.value + ''.toString()
                                    : ''}{' '}
                                {OsbUtilService.getTimeBasedOnAuthoring(
                                    props.selectedTime,
                                    osbStep.twelveHrsTimeFormat,
                                    osbStep.amTimeLabel,
                                    osbStep.pmTimeLabel,
                                    osbStep.timeSuffixLabel
                                )}
                            </div>
                        )}

                        {osbCalendarStep.selectedTimeRange && (
                            <div>
                                {!httpState.isLoading
                                    ? reviewBookingContent?.elements.find(
                                          e => e.name === 'Time_Between_Label'
                                      )?.value + ''.toString()
                                    : ''}{' '}
                                {osbCalendarStep.selectedTimeRange}
                            </div>
                        )}
                    </div>
                    <div className="hr-line-style"></div>
                </div>
                {showServiceLocationDetails(
                    osbServiceStep.isMobileServiceSelected,
                    osbServiceLocationDetail
                ) && (
                    <div className="service-location-container">
                        <div className="service-location-title">
                            {!httpState.isLoading
                                ? reviewBookingContent?.elements.find(
                                      e =>
                                          e.name ===
                                          BOOKING_SUMMARY_CONTAINER_KEYS.SERVICE_LOCATION_TITLE
                                  )?.value + ''.toString()
                                : ''}
                        </div>
                        <div className="service-location-details">
                            <div className="customer-address">
                                <FormatAddress
                                    addressData={osbServiceLocationDetail}
                                    addressOrder={osbStep.dealerAddressOrder}
                                    isDisplaySingleRow={false}
                                />
                            </div>
                            <div className="parking-type">
                                {osbServiceLocationDetail.parkingType}
                            </div>
                            {osbServiceLocationDetail.comment.trim().length >
                                0 && (
                                <div className="sl-comments">
                                    {`"${osbServiceLocationDetail.comment}"`}
                                </div>
                            )}
                        </div>
                        <div className="hr-line-style"></div>
                    </div>
                )}
                <div className="where-section">
                    <div className="where-title">
                        {!props.isLoading
                            ? props.bookingConfirmationStepContent?.elements.find(
                                  key =>
                                      key.name ===
                                      BOOKING_CONFIRMATION_KEYS.WHERE_LABEL
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div className="dealer-name">{props.dealerName}</div>
                    <div className="dealer-address">
                        <FormatAddress
                            addressData={osbDealerStep.selectedDealerProfile}
                            addressOrder={osbStep.dealerAddressOrder}
                            isDisplaySingleRow={false}
                        />
                    </div>

                    <div className="hr-line-style"></div>
                </div>

                <div className="booking-number-section">
                    <div className="booking-number-title">
                        {!props.isLoading
                            ? props.bookingConfirmationStepContent?.elements.find(
                                  key =>
                                      key.name ===
                                      BOOKING_CONFIRMATION_KEYS.BOOKING_NUMBER_LABEL
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div className="booking-number">{props.bookingNumber}</div>
                    <div className="hr-line-style"></div>
                </div>
                <div className="access-code-section">
                    <div className="access-code-title">
                        {!props.isLoading
                            ? props.bookingConfirmationStepContent?.elements.find(
                                  key =>
                                      key.name ===
                                      BOOKING_CONFIRMATION_KEYS.ACCESS_CODE_LABEL
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div className="access-code">{props.accessCode}</div>
                    {isMobileView && <div className="hr-line-style"></div>}
                </div>
                <div className="links-section">
                    <div className="print-booking-details">
                        <a className="link-style" onClick={props.handlePrint}>
                            {!props.isLoading
                                ? props.bookingConfirmationStepContent?.elements.find(
                                      key =>
                                          key.name ===
                                          BOOKING_CONFIRMATION_KEYS.PRINT_BOOKING_LINK
                                  )?.value + ''.toString()
                                : ''}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
