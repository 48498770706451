import React, { useEffect, useState } from 'react';
import ServerSideService from '../../../../../../services/server-side-service/server-side-service';
import { Article } from '../../../../../../models/experiencefragments/category-view';
import { Heading } from '../../../../../common/heading/heading';
import { Modal } from '../../../../../common/modal/modal';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { CategoryOnClickCtaInfo } from '../../../../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../../../../services/new-analytics-service/new-analytics-service';
import { FMButton } from '@own/fds-react';

import './preimagine-category-masthead.scss';
import PrimaryButton from '../../../../../common/primary-button/primary-button';
import { transformRelativeUrlValidateRoot } from '../../../../../utils/router-util/router-util';

interface Props extends Article {
    class?: string;
    categoryId?: string;
    showLogo?: boolean;
    leftLogoPath?: string;
    rightLogoPath?: string;
    cxCta?: any;
    eventName?: string;
    categoryPath?: string;
    root?: string;
}

export const PreImagineCategoryMasthead = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const [backgroundUrl, setBackgroundUrl] = useState<string>('');
    const [isQRCode, setIsQRCode] = useState<boolean>(false);

    const target = props?.targetBlank ? '_blank' : '';
    const headline = props?.headline || props?.categoryId;
    const isMobile =
        ServerSideService.isClientSide() &&
        window?.matchMedia('(max-width: 991px)').matches;
    const mobileCTA = isMobile ? props?.cxCta?.mobileCTALink : '';

    const mastheadClass = [
        'preimagine-category-masthead',
        props.class ? props.class : '',
    ]
        .join(' ')
        .trim();

    const onShowQRCode = () => {
        fireEvents(
            'image-recog-cta-onclick-event',
            undefined,
            undefined,
            false
        );
        setIsQRCode(isMobile);
    };

    const onCancel = () => {
        setIsQRCode(false);
    };

    const onClose = () => {
        setIsQRCode(false);
    };

    const handleWindowResize = () => {
        setBackgroundUrl(
            ServerSideService.isClientSide() && isMobile
                ? encodeURI(
                      `url(${process.env.REACT_APP_AEM_BASE_URL}${props?.mobileImagePath})`
                  )
                : encodeURI(
                      `url(${process.env.REACT_APP_AEM_BASE_URL}${props?.desktopImagePath})`
                  )
        );
    };

    const eventClick = async (ctaLabel: string, ctaPath: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            ctaName: ctaLabel,
            categoryPath: props.categoryPath,
            ctaUrl: ctaPath,
        };
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (props?.eventName) {
            fireEvents(props?.eventName, undefined, {
                categoryOnClickCtaInfo,
            });
        }
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <article className="preimagine-category-masthead-container">
            <section
                className={mastheadClass}
                style={{
                    backgroundImage: backgroundUrl ?? '',
                }}
            >
                {headline && (
                    <h1 className="preimagine-category-masthead__category-headline">
                        {headline}
                    </h1>
                )}
            </section>
            <section className="preimagine-category-masthead__wrapper">
                {props.title && (
                    <Heading
                        type={'h2'}
                        className="preimagine-category-masthead__category-title"
                    >
                        {props.title}
                    </Heading>
                )}
                {props.description && (
                    <div className="preimagine-category-masthead__description">
                        {props.description}
                    </div>
                )}
                {props.ctaPath && (
                    <div className="preimagine-category-masthead__cta">
                        <FMButton
                            href={transformRelativeUrlValidateRoot(
                                props.ctaPath ?? '',
                                props?.root ?? ''
                            )}
                            type={'primary'}
                            label={props?.ctaLabel}
                            ariaLabel={props.ctaAriaLabel}
                            target={target}
                            theme={'light'}
                            onClick={() => {
                                eventClick(props.ctaLabel, props.ctaPath);
                            }}
                        />
                    </div>
                )}
                {props.cxCta && !props.cxCta.hideCTA && (
                    <>
                        <div className="preimagine-cx-category-masthead__cta">
                            <PrimaryButton
                                href={mobileCTA()}
                                color="light"
                                fill="outline"
                                chevron={true}
                                ariaLabel={props.cxCta.mastheadButtonAriaLabel}
                                target={props.targetBlank ? '_blank' : ''}
                                onClick={() => {
                                    eventClick(
                                        props.cxCta.mastheadButtonName,
                                        mobileCTA()
                                    );
                                    onShowQRCode();
                                }}
                            >
                                {props.cxCta.mastheadButtonName}
                            </PrimaryButton>
                        </div>
                        <Modal
                            name="qr-code-model"
                            role="dialog"
                            aria-label={props.cxCta?.desktopDialogAriaLabel}
                            isVisible={isQRCode}
                            onClose={onClose}
                            id="qr-code-model-id"
                        >
                            <div className="modal-text-center">
                                <h2 className="qr-code-modal-header">
                                    {props.cxCta.desktopDialogTitle}
                                </h2>
                                <img
                                    style={{ height: '400px' }}
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        props.cxCta.desktopQRCodeImagePath
                                    }
                                    alt={props.cxCta.desktopQRCodeImageAltText}
                                ></img>
                                <div className="qr-code-modal-description">
                                    {props.cxCta?.desktopDialogDescription}
                                </div>
                                <PrimaryButton
                                    color="dark"
                                    fill="outline"
                                    chevron={true}
                                    ariaLabel={
                                        props.cxCta.desktopDialogCancelAriaLabel
                                    }
                                    target={props.targetBlank ? '_blank' : ''}
                                    onClick={() => onCancel()}
                                >
                                    {props.cxCta.desktopDialogCancelText}
                                </PrimaryButton>
                            </div>
                        </Modal>
                        <PrimaryButton
                            href={mobileCTA()}
                            color="light"
                            fill="outline"
                            chevron={true}
                            ariaLabel={props.cxCta.mastheadButtonAriaLabel}
                            target={props.targetBlank ? '_blank' : ''}
                            onClick={() => {
                                eventClick(
                                    props.cxCta.mastheadButtonName,
                                    mobileCTA()
                                );
                                onShowQRCode();
                            }}
                        >
                            {props.cxCta.mastheadButtonName}
                        </PrimaryButton>
                    </>
                )}
                {props.showLogo && (
                    <div className="preimagine-cx-category-masthead__logo">
                        <div className="logo">
                            <img
                                className="logo-left"
                                src={props.leftLogoPath}
                                alt=""
                            ></img>
                        </div>
                        <div className="line"></div>
                        <div className="logo">
                            <img
                                className="logo-right"
                                src={props.rightLogoPath}
                                alt=""
                            ></img>
                        </div>
                    </div>
                )}
            </section>
        </article>
    );
};
