import parse from 'html-react-parser';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { AutoComplete } from '../../../../../common';
import { getObjectFromAEMJson } from '../../../osb-utils';
import './light-dealer-search-lincoln.scss';
import { DEALER_STEP_KEYS } from '../../../osb-constant';
import { useDealerStep } from '../../../../../../hooks/owners-osb';
import RadioButton from '../../../../../common/radio-button/radio-button';
import { useEffect, useState } from 'react';
import { OSBUseLocation } from '../../../common/osb-use-location/osb-use-location';
import { useMarketDealerInfoService } from '../../../../../../hooks/owners-osb/use-market-dealerInfo-service';
import { useLocation } from 'react-router-dom';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
interface Props {
    errorMsgDealer: string;
    errorMsgDealerDetails: string;
    fetchSelectedLocation: (location: string, searchType?: string) => void;
    selectedLocation: string;
    selectedDealerName?: string;
    locationErrorMsgDetails: string;
    fetchChosenLocation: (input: string) => void;
    showCurrentLocation: () => void;
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    dealerCount?: number;
    autocompleteSearchStringLength?: number;
    hideTitle?: boolean;
    clearErrorMessage?: () => void;
    fetchErrorCode?: (errorCode: string) => void;
    isUseLocationLinkClicked: boolean;
    triggerSearchTypeChanged: () => void;
    includeUseMyLocationLink?: boolean;
}
export const LightDealerSearch = (props: Props) => {
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const { osbDealerStep } = useDealerStep();
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const [searchType, setSearchType] = useState<string>(
        props.isUseLocationLinkClicked
            ? DEALER_STEP_KEYS.LOCATION
            : osbDealerStep.searchType
    );
    const [placeHolderText, setPlaceHolderText] = useState<string>('');
    const [dealerList, setDealerList] = useState<
        DealerProfileInfo[] | undefined
    >([]);
    const onSearchTypeChange = (searchtype: string) => {
        if (searchtype === DEALER_STEP_KEYS.LOCATION) {
            setPlaceHolderText(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.LOCATION_SEARCH_LABEL,
                    props.dealerStepContent
                )
            );
        } else {
            setPlaceHolderText(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.DEALER_NAME_SEARCH_LABEL,
                    props.dealerStepContent
                )
            );
        }
        setSearchType(searchtype);
        props.triggerSearchTypeChanged();
    };
    useEffect(() => {
        async function fetchData() {
            const response = await fetchMarketDealerInfo(deepLinkParams);
            setDealerList(response?.dealers);
        }
        fetchData();
    }, []);
    useEffect(() => {
        if (props.dealerStepContent && props.dealerStepContent.length > 0) {
            if (
                props.isUseLocationLinkClicked ||
                osbDealerStep.searchType === DEALER_STEP_KEYS.LOCATION
            ) {
                setPlaceHolderText(
                    getObjectFromAEMJson(
                        DEALER_STEP_KEYS.LOCATION_SEARCH_LABEL,
                        props.dealerStepContent
                    )
                );
            } else {
                setPlaceHolderText(
                    getObjectFromAEMJson(
                        DEALER_STEP_KEYS.DEALER_NAME_SEARCH_LABEL,
                        props.dealerStepContent
                    )
                );
            }
        }
    }, [props.dealerStepContent]);

    useEffect(() => {
        setSearchType(
            props.isUseLocationLinkClicked
                ? DEALER_STEP_KEYS.LOCATION
                : searchType
        );
        if (props.isUseLocationLinkClicked) {
            setPlaceHolderText(
                getObjectFromAEMJson(
                    DEALER_STEP_KEYS.LOCATION_SEARCH_LABEL,
                    props.dealerStepContent
                )
            );
        }
    }, [props.isUseLocationLinkClicked]);

    return (
        <div className="osb-light-dealer-search-container">
            {props.errorMsgDealer &&
                props.errorMsgDealer === 'OSB_NO_DATAFOUND' && (
                    <div className="dealer-error-message">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.NO_RECORDS_FOUND_LABEL,
                            props.dealerStepContent
                        )}
                    </div>
                )}
            {props.errorMsgDealer &&
                props.errorMsgDealer === 'OSB_INVALID_MARKETCODE' && (
                    <div className="dealer-error-message">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.POSITION_UNAVAILABLE_LABEL,
                            props.dealerStepContent
                        )}
                    </div>
                )}
            {props.errorMsgDealer &&
                props.errorMsgDealer === 'SERVICE_ERROR' && (
                    <div className="dealer-error-message">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.TRY_AGAIN_LATER_LABEL,
                            props.dealerStepContent
                        )}
                    </div>
                )}
            {props.errorMsgDealerDetails && (
                <div className="dealer-error-message">
                    {props.errorMsgDealerDetails}
                </div>
            )}
            {props.hideTitle !== true && (
                <div className="light-first-heading">
                    {((props.dealerCount && props.dealerCount > 0) ||
                        osbDealerStep.selectedFilterServices?.length > 0) &&
                    props.hideTitle &&
                    props.hideTitle === true ? (
                        <div className="select-a-dealer-header-container">
                            <div>
                                {parse(
                                    getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.SELECT_A_DEALER,
                                        props.dealerStepContent
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        parse(
                            getObjectFromAEMJson(
                                'title',
                                props.dealerStepContent
                            )
                        )
                    )}
                </div>
            )}
            <div className="osb-light-dealer-search-box-container">
                <div className="serach-type-label">
                    {getObjectFromAEMJson(
                        DEALER_STEP_KEYS.SEARCH_BY_LABEL,
                        props.dealerStepMoreContent
                    )}
                </div>
                <div className="radio-buttons">
                    <RadioButton
                        dataTestId="SearchByLocation"
                        value="location"
                        label={getObjectFromAEMJson(
                            DEALER_STEP_KEYS.SEARCH_BY_LOCATION_LABEL,
                            props.dealerStepMoreContent
                        )}
                        checked={searchType === DEALER_STEP_KEYS.LOCATION}
                        onChange={() => {
                            onSearchTypeChange(DEALER_STEP_KEYS.LOCATION);
                        }}
                    />
                    <RadioButton
                        dataTestId="SearchByDealerName"
                        value="dealername"
                        label={getObjectFromAEMJson(
                            DEALER_STEP_KEYS.SEARCH_BY_DEALER_NAME_LABEL,
                            props.dealerStepMoreContent
                        )}
                        checked={searchType === DEALER_STEP_KEYS.DEALER_NAME}
                        onChange={() => {
                            onSearchTypeChange(DEALER_STEP_KEYS.DEALER_NAME);
                        }}
                    />
                </div>
                <div className="light-dealer-search-box">
                    <div className="autocomplete-box">
                        <AutoComplete
                            name="selectLocation"
                            id="selectLocation"
                            text={
                                searchType === DEALER_STEP_KEYS.LOCATION
                                    ? props.selectedLocation
                                    : props.selectedDealerName
                            }
                            google={true}
                            placeHolderText={placeHolderText}
                            setCode={props.fetchSelectedLocation}
                            message={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.SEARCH_HINT_LABEL,
                                props.dealerStepContent
                            )}
                            setInputText={props.fetchChosenLocation}
                            searchStringLength={
                                props.autocompleteSearchStringLength
                            }
                            error={props.locationErrorMsgDetails}
                            clearErrorMessage={props.clearErrorMessage}
                            setErrorCode={props.fetchErrorCode}
                            searchType={searchType}
                            dealerList={dealerList}
                        />
                        {props.locationErrorMsgDetails && (
                            <div className="location-error-message">
                                {props.locationErrorMsgDetails}
                            </div>
                        )}
                    </div>
                    {props.includeUseMyLocationLink &&
                        props.includeUseMyLocationLink === true && (
                            <div className="use-location-box">
                                <div
                                    className={`location-link-container ${
                                        props.locationErrorMsgDetails
                                            ? 'location-error'
                                            : ''
                                    }`}
                                >
                                    <OSBUseLocation
                                        showCurrentLocation={
                                            props.showCurrentLocation
                                        }
                                        useLocationLabel={getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.USE_MY_LOCATION_LABEL,
                                            props.dealerStepMoreContent
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};
