import { MMOTAStatusResponse } from './mmota-status.interface';
import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import { AxiosResponse } from 'axios';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

export default class MmotaStatusService {
    public async getStatus(
        vin: string
    ): Promise<MMOTAStatusResponse | undefined> {
        const {
            current3LetterCountryCode,
            currentLanguageCode,
            currentRegionCode,
        } = new AppConfigurationService();

        const url = DSL_BASE_URL + DSL_API_PATH.MMOTA;

        const body = {
            countryCode: current3LetterCountryCode?.toUpperCase(),
            locale: `${currentLanguageCode}-${currentRegionCode?.toLocaleUpperCase()}`,
            vin: vin,
        };

        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        const response = HttpService.post<MMOTAStatusResponse>(url, body, {
            headers,
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(MmotaStatusService.parseResponse);
    }

    private static parseResponse(
        response: AxiosResponse<MMOTAStatusResponse>
    ): MMOTAStatusResponse | undefined {
        if (response.data) {
            return response.data;
        }
    }
}
