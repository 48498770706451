import { useContent } from '../../../hooks/use-server-content';

export interface ModelNotSupportedProps {
    upcomingLivestreamTitle: string;
    upcomingLivestreamDescription: string;
    addToCalendarButton: string;
    addToCalendarButtonAriaLabel: string;
    viewScheduleButton: string;
    viewScheduleButtonAriaLabel: string;
    livestreamTitle: string;
    livestreamDescription: string;
    popularVideosTitle: string;
    tooltipHeader: string;
}
export const useLiveStreamViewContent = (): ModelNotSupportedProps | null => {
    const [content, getValueByName] = useContent('dyf', 'live-stream');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ModelNotSupportedProps;
};
