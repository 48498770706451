import React, { useState, useEffect, useRef } from 'react';
import useUserProfile from '../../../../../hooks/use-user-profile';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import { Dropdown, InputField, PrimaryButton } from '../../../../common';
import { VehicleUtil } from '../../../../utils/vehicle-util/vehicle-util';
import './ute-vehicle-selector.ford.scss';
import { UTEVehicleSelectorContentProps } from '../../../../../models/service-maintenance-models/ute';

interface Props {
    contentLabels: UTEVehicleSelectorContentProps;
    isValidVin: (state: boolean) => void;
}

export const UTEVehicleSelector = (props: Props) => {
    const {
        vehicleNickname,
        vinInputHeader,
        vinInputLabel,
        vinInputPlaceholder,
        vinInputDividerText,
        chooseVehicleGarageHeadLine,
        uteVehicleCtaLabel,
        invalidVinValidationMessage,
        invalidVinOptionalValidationMessage,
        registrationLookup,
        regNumberValidationMessage,
    } = props.contentLabels;
    const [vinRegNo, setVinRegNo] = useState<string>('');
    const [isActive, setActive] = useState(false);
    const [vinErrorMessage, setVinErrorMessage] = useState<string>('');
    const [defaultstatus, setDefaultStatus] = useState<boolean>(true);
    const [selectedVinFromGarage, setSelectedVinFromGarage] = useState<
        string
    >();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const garageOptions: any = [];
    const profile = useUserProfile();
    const vehicleUtil = new VehicleUtil();
    const warrantyButtonElement = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (profile) {
            setVehiclesData(
                profile.vehicles.map(vehicle => {
                    return {
                        year: parseInt(vehicle.modelYear, 10),
                        make: vehicle.make,
                        model: vehicle.modelName,
                        vin: vehicle.vin,
                        uomSpeed: profile.profile.uomSpeed,
                        nickName: vehicle.nickName,
                        modelYear: vehicle.modelYear,
                        vehicleMake: vehicle.make,
                        modelName: vehicle.modelName,
                    };
                })
            );
        }
    }, [profile]);

    const isVinOptionalValidationRequired = () => {
        return (
            invalidVinOptionalValidationMessage === '' ||
            invalidVinOptionalValidationMessage === null
        );
    };
    const getVinValidationMessage = (vin: string) => {
        const vinValidationMessage = !isVinOptionalValidationRequired()
            ? invalidVinValidationMessage
            : invalidVinOptionalValidationMessage;

        return vin.length <= 10 && registrationLookup
            ? regNumberValidationMessage
            : vinValidationMessage;
    };

    const vinInputFocus = () => {
        if (warrantyButtonElement.current) {
            warrantyButtonElement.current.focus();
        }
    };
    const onUTEContinueClick = () => {
        if (!isActive) {
            setActive(!isActive);
        }
        if (vinRegNo === '') {
            setVinErrorMessage(getVinValidationMessage);
            vinInputFocus();
            props.isValidVin(false);
            return;
        }
        if (!isActive) {
            setActive(!isActive);
        }

        if (vinRegNo !== '' && vinErrorMessage !== '') {
            vinInputFocus();
        }
        if (vinRegNo !== '' && vinErrorMessage === '' && vinRegNo.length > 10) {
            sessionStorage.setItem('selectedVin', vinRegNo);
            props.isValidVin(true);
        }
    };

    const onGarageSeeYourWarrantyClick = () => {
        if (!isActive) {
            setActive(!isActive);
        }

        if (
            selectedVinFromGarage === '' ||
            selectedVinFromGarage === undefined
        ) {
            props.isValidVin(false);
            return;
        }
        if (
            selectedVinFromGarage !== '' &&
            selectedVinFromGarage !== undefined
        ) {
            sessionStorage.setItem('selectedVin', selectedVinFromGarage);
            props.isValidVin(true);
        }
    };

    const getVinRegex = () => {
        const vinOnlyRegex = !isVinOptionalValidationRequired()
            ? /^[A-Za-z0-9]{17}$/
            : /(^(?!WFO)[A-Za-z0-9]{17}$)/;
        const vinWithRegNumRegex = !isVinOptionalValidationRequired()
            ? /(^[A-Za-z0-9]{17}$)|(^[A-Za-z0-9 -]{1,10}$)/i
            : /(^(?!WFO)[A-Za-z0-9]{17}$)|(^[A-Za-z0-9 -]{1,10}$)/i;

        return registrationLookup ? vinWithRegNumRegex : vinOnlyRegex;
    };

    const updateVinInput = (e: any) => {
        if (e.type === 'blur' || e.type === 'focus' || e.type === 'change') {
            const vinValue = e.target.value.toUpperCase();
            e.target.value = vinValue;
            const vinRegEx = getVinRegex();
            if (vinValue.match(vinRegEx)) {
                setVinErrorMessage('');
                setVinRegNo(vinValue);
                setDefaultStatus(false);
            } else {
                setVinErrorMessage(getVinValidationMessage(vinValue));
                setVinRegNo(vinValue);
            }
        }
    };

    const chooseGarage = () => {
        vehiclesData.forEach(vehicle => {
            garageOptions.push({
                label: vehicleUtil.getGarageVehicleName(vehicle),
                value: vehicle.vin,
            });
        });
        return garageOptions;
    };
    const chooseGarageChange = (
        label: string,
        id: number,
        value: string | undefined
    ) => {
        setSelectedVinFromGarage(value);
    };

    return (
        <div className="ute-vehicle-selector-main">
            <section className="ute-vin-input-section">
                <div className="vehicle-details-wrapper">
                    {vehiclesData.length > 0 && (
                        <>
                            <div className="ute-garage-section">
                                <div className="heading-with-icon">
                                    <h2>{chooseVehicleGarageHeadLine}</h2>
                                </div>
                                <Dropdown
                                    id="warranty-garage-dropdown"
                                    label={vehicleNickname}
                                    noLabel={true}
                                    onChange={(e, index, value) =>
                                        chooseGarageChange(e, index, value)
                                    }
                                    options={chooseGarage()}
                                    value={
                                        garageOptions.find(
                                            (opt: any) =>
                                                opt.value ===
                                                selectedVinFromGarage
                                        )?.label || selectedVinFromGarage
                                    }
                                    className="ute-garage-dropdown-section"
                                />
                                <PrimaryButton
                                    className={`see-your-warranty-button ${
                                        isActive
                                            ? 'see-your-warranty-button-clicked'
                                            : ''
                                    }`}
                                    color={'dark'}
                                    fill={'fill'}
                                    chevron={true}
                                    onClick={onGarageSeeYourWarrantyClick}
                                    ariaLabel={uteVehicleCtaLabel}
                                >
                                    {uteVehicleCtaLabel}
                                </PrimaryButton>
                            </div>
                            <div className="divider-section">
                                <hr className="before-dash" />{' '}
                                <span>{vinInputDividerText}</span>{' '}
                                <hr className="after-dash" />
                            </div>
                        </>
                    )}
                    <div className="enter-your-vin-section">
                        <div className="heading-with-icon">
                            <h2>{vinInputHeader}</h2>
                        </div>
                        <InputField
                            label={vinInputLabel}
                            placeHolder={vinInputPlaceholder}
                            ariaLabel={`warranty-${vinInputLabel}`}
                            name="VIN"
                            maxLength={17}
                            className="warranty-vin vin-textField"
                            value={vinRegNo}
                            errorMessage={vinErrorMessage}
                            ref={warrantyButtonElement}
                            onChange={e => updateVinInput(e)}
                            onBlur={e => updateVinInput(e)}
                            onFocus={e => updateVinInput(e)}
                            fdsStyle={false}
                            validate={defaultstatus}
                        />
                        <PrimaryButton
                            className={`see-your-warranty-button vin ${
                                isActive
                                    ? 'see-your-warranty-button-clicked'
                                    : ''
                            }`}
                            color={'dark'}
                            fill={'fill'}
                            chevron={true}
                            onClick={onUTEContinueClick}
                            ariaLabel={uteVehicleCtaLabel}
                        >
                            {uteVehicleCtaLabel}
                        </PrimaryButton>
                    </div>
                </div>
            </section>
        </div>
    );
};
