import React from 'react';
import './vehicle-info-skeleton.scss'; // Ensure to create and import relevant styles
import { ReactComponent as DefaultCarImage } from '../../../../../../src/assets/osb-v3/default-vehicle.svg'; // Adjust the import path as necessary

const VehicleInfoSkeleton: React.FC = () => {
    return (
        <div
            className="osb-v3-vehicle-info-skeleton"
            data-testid="vehicle-skeleton"
        >
            {/* Vehicle Image Skeleton */}
            <div className="osb-v3-vehicle-info-skeleton-image">
                <DefaultCarImage className="osb-v3-vehicle-info-skeleton-default-car-image" />
            </div>

            {/* Information Section Skeleton */}
            <div className="osb-v3-vehicle-info-skeleton-details">
                <div className="osb-v3-vehicle-info-skeleton-item">
                    <div className="osb-v3-vehicle-info-skeleton-label"></div>
                    <div className="osb-v3-vehicle-info-skeleton-value"></div>
                </div>

                <div className="osb-v3-vehicle-info-skeleton-item">
                    <div className="osb-v3-vehicle-info-skeleton-label"></div>
                    <div className="osb-v3-vehicle-info-skeleton-value"></div>
                </div>

                <div className="osb-v3-vehicle-info-skeleton-item">
                    <div className="osb-v3-vehicle-info-skeleton-label"></div>
                    <div className="osb-v3-vehicle-info-skeleton-value"></div>
                </div>

                <div className="osb-v3-vehicle-info-skeleton-item">
                    <div className="osb-v3-vehicle-info-skeleton-label"></div>
                    <div className="osb-v3-vehicle-info-skeleton-textbox"></div>
                </div>

                <div className="osb-v3-vehicle-info-skeleton-continue-button">
                    <div className="osb-v3-vehicle-info-skeleton-button"></div>
                </div>
            </div>
        </div>
    );
};

export default VehicleInfoSkeleton;
