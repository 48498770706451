import React, { useEffect, useState } from 'react';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import DealerTile from '../dealer-tile/dealer-tile';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import { OsbMap } from '../../owners-osb/dealer-step/light-journey/osb-map';
import './service-price-calculator-desktop-dealer-details.scss';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../price-calculator-constant';
import {
    getObjectFromAEMJson,
    isPreferredDealer,
} from '../../owners-osb/osb-utils';
import filterIcon from '../../../../assets/owners-osb/dealer-filter.png';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import { DealerFilterOptions } from '../dealer-filter-options/dealer-filter-options';
import { useOSBStep, useVehicleStep } from '../../../../hooks/owners-osb';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import { useAnalytics } from '../../../../hooks/use-analytics';
import {
    triggerDealerSearchResultAnalytics,
    triggerFiltersClick,
} from '../analytics/price-calculator-analytics';
interface Props {
    profile?: ProfileWithVehiclesResponse;
    dealers: DealerProfileInfo[];
    findMyVinContent?: ContentElements[];
    priceCalculatorContent: ContentElements[];
    priceCalculatorResultContent: ContentElements[];
    isViewMoreButton: boolean;
    showMaxDealers: () => void;
    serviceApiError?: (error: string) => void;
    refreshDealerList?: (dealerProfiles: DealerProfileInfo[]) => void;
    goToServicesStep: (dealer: DealerProfileInfo) => void;
}
const ServicePriceCalculatorDesktopDealerDetails = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { osbStep } = useOSBStep();
    const { osbVehicleStep } = useVehicleStep();
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);

    const serviceApiError = (error: string) => {
        props.serviceApiError && props.serviceApiError(error);
    };
    const toggleLightDealerFilterOptions = () => {
        triggerFiltersClick(
            fireEvents,
            osbVehicleStep.vehicleDetails.buildDate,
            osbVehicleStep.vehicleDetails.modelName
        );
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };
    const handlePushpinClick = (dealerCode: string) => {
        const dealerTile = document.querySelector(
            `[data-dealercode="${dealerCode}"]`
        );
        if (dealerTile) {
            setTimeout(() => {
                const dealerTileAgain = document.querySelector(
                    `[data-dealercode="${dealerCode}"]`
                );
                if (dealerTileAgain) {
                    const container = document.querySelector(
                        '.container-class'
                    );
                    if (container) {
                        container.scrollLeft = (dealerTileAgain as HTMLElement).offsetLeft;
                    }
                    dealerTileAgain.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }, 100);
        }
    };

    useEffect(() => {
        const vehicleImageContainer = document.querySelector(
            '.spc-vehicle-image-container'
        );
        if (vehicleImageContainer) {
            const topValue = vehicleImageContainer.getBoundingClientRect().top;
            if (serverSideService.isClientSide()) {
                window.scrollTo({ top: topValue, behavior: 'smooth' });
            }
        }
        triggerDealerSearchResultAnalytics(fireEvents);
    }, [osbVehicleStep.vehicleDetails]);
    return (
        <>
            {props.dealers.length > 0 && (
                <div className="spc-desktop-map">
                    <OsbMap
                        dealers={props.dealers}
                        fullscreenControlPosition="LEFT_TOP"
                        zoomControlPosition="LEFT_BOTTOM"
                        leftShiftDegree={-0.2}
                        newUIDesign={true}
                        onDealerClick={handlePushpinClick}
                    />
                    <div className="spc-desktop-map-overlay">
                        {showDealerFilterOptions && (
                            <div className="spc-desktop-map-filter-component">
                                <DealerFilterOptions
                                    toggleLightDealerFilterOptions={
                                        toggleLightDealerFilterOptions
                                    }
                                    priceCalculatorResultContent={
                                        props.priceCalculatorResultContent
                                    }
                                    refreshDealerDetailsList={
                                        props.refreshDealerList
                                    }
                                />
                            </div>
                        )}

                        {!showDealerFilterOptions && (
                            <>
                                {props.dealers?.length > 1 && (
                                    <div className="spc-desktop-map-overlay-row spc-desktop-map-overlay-filters">
                                        <button
                                            className="spc-desktop-map-filter-button"
                                            onClick={
                                                toggleLightDealerFilterOptions
                                            }
                                        >
                                            <img
                                                src={filterIcon}
                                                alt="dealer-filter-icon"
                                            />
                                            <span className="spc-desktop-map-filters-text">
                                                {getObjectFromAEMJson(
                                                    PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_LABEL,
                                                    props.priceCalculatorResultContent
                                                )}
                                            </span>
                                        </button>
                                    </div>
                                )}
                                <div className="spc-desktop-map-overlay-row spc-desktop-map-overlay-dealer-info">
                                    <div className="spc-desktop-dealer-info-content">
                                        {props.dealers.map((dealer, index) => (
                                            <div
                                                key={dealer.dealerCode}
                                                data-dealercode={
                                                    dealer.dealerCode
                                                }
                                            >
                                                <DealerTile
                                                    key={dealer.dealerCode}
                                                    index={index}
                                                    label={getObjectFromAEMJson(
                                                        PRICE_CALCULATOR_RESULT_CONSTANTS.PREFERRED_DEALER_LABEL,
                                                        props.priceCalculatorContent
                                                    )}
                                                    dealer={dealer}
                                                    isPreferredDealer={isPreferredDealer(
                                                        osbVehicleStep
                                                            .vehicleDetails
                                                            ?.vin,
                                                        props.profile
                                                            ?.vehicles || [],
                                                        dealer,
                                                        osbStep.numberOfLeadingCharactersToStrip
                                                    )}
                                                    priceCalculatorContent={
                                                        props.priceCalculatorContent
                                                    }
                                                    priceCalculatorResultContent={
                                                        props.priceCalculatorResultContent
                                                    }
                                                    serviceApiError={
                                                        serviceApiError
                                                    }
                                                    goToServicesStep={
                                                        props.goToServicesStep
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {props.isViewMoreButton && (
                                    <div className="spc-desktop-map-overlay-row spc-desktop-map-overlay-view-more">
                                        <button
                                            className="spc-desktop-map-view-more-button"
                                            onClick={props.showMaxDealers}
                                        >
                                            <span className="spc-desktop-map-view-more-text">
                                                {getObjectFromAEMJson(
                                                    PRICE_CALCULATOR_RESULT_CONSTANTS.VIEW_MORE,
                                                    props.priceCalculatorResultContent
                                                )}
                                            </span>
                                            <FdsChevron
                                                direction={'down'}
                                                type="filled"
                                            />
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ServicePriceCalculatorDesktopDealerDetails;
