import React, { useEffect, useState } from 'react';
import './service-price-calculator-desktop-search.scss';
import { AutoComplete, PrimaryButton } from '../../../common';
import InputWithPlaceholder from '../input-with-placeholder/input-with-placeholder';
import {
    getClientStorage,
    getObjectFromAEMJson,
    getVehiclePreferredDealer,
    isAuthenticatedFlow,
} from '../../owners-osb/osb-utils';
import {
    PRICE_CALCULATOR_ANALYTICS,
    PRICE_CALCULATOR_CONSTANTS,
} from '../price-calculator-constant';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import {
    getLocation,
    validateLocation,
    validateMileage,
    validateVinRegNo,
} from './service-price-calculator-search';
import { OSBUseLocation } from '../../owners-osb/common/osb-use-location/osb-use-location';
import VehicleDropdown from '../vehicle-dropdown/vehicle-dropdown';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import {
    useDealerStep,
    useOSBStep,
    useVehicleStep,
} from '../../../../hooks/owners-osb';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import errorWarning from '../../../../assets/error-warning.svg';
import useMarketDealerInfoService from '../../../../hooks/owners-osb/use-market-dealerInfo-service';
import { useLocation } from 'react-router-dom';
import {
    triggerLocationSignInAnalytics,
    triggerUserInputAnalytics,
} from '../analytics/price-calculator-analytics';
import { useAnalytics } from '../../../../hooks/use-analytics';
interface Props {
    priceCalculatorContent: ContentElements[];
    priceCalculatorResultContent: ContentElements[];
    findMyVinContent: ContentElements[];
    profile?: ProfileWithVehiclesResponse | undefined;
    getVinSelectionType: (type: string) => void;
    getQuoteHandler: (
        vin: string,
        mileage: string,
        location: string,
        isLocationDisabled: boolean
    ) => void;
}
const ServicePriceCalculatorDesktopSearch = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { fetchMarketDealerInfo } = useMarketDealerInfoService();
    const { osbStep } = useOSBStep();
    const { osbVehicleStep, setOSBVehicleStepPayload } = useVehicleStep();
    const { osbDealerStep } = useDealerStep();
    const authService = new AuthenticationService();
    const [vinRegError, setVinRegError] = useState('');
    const [formattedMileage, setFormattedMileage] = useState<string>(
        osbVehicleStep.vinMileage
    );
    const [mileageError, setMileageError] = useState('');
    const [vinRegInput, setVinRegInput] = useState<string>(
        osbVehicleStep.vinRegNo || props.profile?.vehicles?.[0]?.vin || ''
    );

    const [mileageInput, setMileageInput] = useState<string>(
        osbVehicleStep.vinMileage || ''
    );
    const [locationInput, setLocationInput] = useState<string>('');
    const [locationError, setLocationError] = useState<string>('');
    const [vinSelectionType, setVinSelectionType] = useState<string>(
        getClientStorage('pc-manual-vin') === 'false'
            ? 'DROPDOWN_VIN_AUTH'
            : 'INPUT_VIN'
    );
    const locatonFieldDisbledMessage = getObjectFromAEMJson(
        PRICE_CALCULATOR_CONSTANTS.LOCATION_FIELD_DISABLED_MESSAGE,
        props.priceCalculatorResultContent
    );
    const deepLinkParams = new URLSearchParams(useLocation().search);
    const getPreferredDealer = async (input: string | number) => {
        try {
            const results = await fetchMarketDealerInfo(deepLinkParams);
            return (
                (props.profile?.vehicles &&
                    getVehiclePreferredDealer(
                        props.profile?.vehicles,
                        input,
                        osbStep.numberOfLeadingCharactersToStrip,
                        results?.dealers || [], // Access dealers from the resolved value
                        true
                    )) ||
                ''
            );
        } catch (error) {
            console.error(
                'An error occurred while fetching market dealer info:',
                error
            );
            return '';
        }
    };
    const [preferredDealer, setPreferredDealer] = useState<string>('');
    const [isLocationDisabled, setIsLocationDisabled] = useState<boolean>(
        preferredDealer.length > 0
    );
    const handleVinRegChange = async (value: string) => {
        setOSBVehicleStepPayload({
            vinRegNo: value,
        });
        if (!value) return;
        if (value === 'enter_vin_manually') {
            setIsLocationDisabled(false);
            setOSBVehicleStepPayload({
                vinRegNo: '',
            });
            setPreferredDealer('');
            return;
        }
        setVinRegInput(value);
        const error = validateVinRegNo(value, props.priceCalculatorContent);
        setVinRegError(error);
        if (error !== '') {
            return;
        }
        let preferredDlr = '';
        if (vinSelectionType === 'DROPDOWN_VIN_AUTH') {
            try {
                preferredDlr = await getPreferredDealer(value);
                setPreferredDealer(preferredDlr);
                setIsLocationDisabled(preferredDlr !== '');
            } catch (error) {
                // Handle any errors from getPreferredDealer function
            }
        }
        triggerUserInputAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.VIN_LABEL
        );
    };
    const handleSignInClick = () => {
        authService.login();
    };
    const handleMileageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value;
        setMileageInput(value);
        const correctMileage = value.replace(/[^0-9 .,']+$/, '');
        const sanitizedMileage = correctMileage
            ? correctMileage.replace(/[ .,']/g, '')
            : '';
        const finalMileage = sanitizedMileage.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ''
        );
        setFormattedMileage(finalMileage);
        const error = validateMileage(value, props.priceCalculatorContent);
        if (error.trim() !== '') {
            setMileageError(error);
            return;
        } else {
            setMileageError('');
        }
        triggerUserInputAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.MILEAGE_LABEL
        );
    };
    const fetchLocationInput = (input: string) => {
        setLocationInput(input);
        if (input == '') {
            setLocationError('');
            return;
        }
        triggerUserInputAnalytics(
            fireEvents,
            PRICE_CALCULATOR_ANALYTICS.LOCATION_LABEL
        );
    };
    const getQuote = () => {
        const vinErr = validateVinRegNo(
            vinRegInput,
            props.priceCalculatorContent
        );
        const mileErr = validateMileage(
            mileageInput,
            props.priceCalculatorContent
        );
        const locationIn =
            locationInput.length > 0
                ? locationInput
                : osbDealerStep.selectedLocation;
        let locationErr;
        setLocationInput(locationIn);
        if (!isLocationDisabled) {
            locationErr = validateLocation(
                locationIn,
                props.priceCalculatorContent
            );
        } else {
            locationErr = '';
        }

        if (
            vinErr.trim().length > 0 ||
            mileErr.trim().length > 0 ||
            locationErr.trim().length > 0
        ) {
            setVinRegError(vinErr);
            setMileageError(mileErr);
            setLocationError(locationErr);
            return;
        }
        if (
            preferredDealer.trim().length <= 0 &&
            locationIn.trim().length <= 0
        ) {
            return;
        }

        props.getQuoteHandler(
            vinRegInput,
            mileageInput,
            locationIn,
            isLocationDisabled
        );
    };
    const onVinSelectionChange = async (section: string) => {
        let preferredDlr = '';
        if (section === 'DROPDOWN_VIN_AUTH') {
            preferredDlr = await getPreferredDealer(0);
            setLocationInput('');
            setPreferredDealer(preferredDlr);
            setIsLocationDisabled(preferredDlr !== '');
            setVinRegInput(
                osbVehicleStep.vinRegNo ||
                    props.profile?.vehicles?.[0]?.vin ||
                    ''
            );
        } else {
            setIsLocationDisabled(preferredDealer.length > 0);
        }
        setVinSelectionType(section);
        props.getVinSelectionType(section);
    };

    useEffect(() => {
        const fetchPreferredDealer = async () => {
            let preferredDlr = '';
            if (vinSelectionType === 'DROPDOWN_VIN_AUTH') {
                try {
                    preferredDlr = await getPreferredDealer(vinRegInput);
                    setPreferredDealer(preferredDlr);
                    setIsLocationDisabled(preferredDlr !== '');
                } catch (error) {
                    console.error('ERROR');
                }
            } else {
                setIsLocationDisabled(preferredDealer.length > 0);
            }
        };
        fetchPreferredDealer();
    }, []);

    const subheader = getObjectFromAEMJson(
        PRICE_CALCULATOR_CONSTANTS.PC_SUBHEADER,
        props.priceCalculatorContent
    );
    return (
        <div className="spc-desktop-search-wrapper">
            <div className="spc-desktop-search-heading-container">
                <span>
                    {subheader.split('##')[0]}
                    {!isAuthenticatedFlow && (
                        <>
                            {subheader.split('##')[1]}
                            <span
                                className="sign-in"
                                onClick={handleSignInClick}
                            >
                                {subheader.split('##')[2]}
                            </span>
                            {subheader.split('##')[3]}
                        </>
                    )}
                </span>
            </div>
            <div className="spc-desktop-search-input-container">
                <div className="spc-desktop-search-input-vin-container">
                    <div className="spc-desktop-search-input-vin">
                        <VehicleDropdown
                            priceCalculatorContent={
                                props.priceCalculatorContent
                            }
                            vehicles={props.profile?.vehicles || []}
                            vinRegError={vinRegError}
                            onVehicleSelect={handleVinRegChange}
                            onVinSelectionChange={onVinSelectionChange}
                            findMyVinContent={props.findMyVinContent}
                        />
                    </div>
                </div>
                <div className="spc-desktop-search-input-mileage-container">
                    <InputWithPlaceholder
                        placeholder={getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Mileage_PlaceHolder,
                            props.priceCalculatorContent
                        )}
                        onChange={handleMileageChange}
                        error={mileageError}
                        formattedValue={formattedMileage}
                    />
                </div>
                <div className="spc-desktop-search-input-location-container">
                    <div
                        className={`spc-desktop-search-input-autocomplete ${
                            isLocationDisabled ? 'disabled' : ''
                        }`}
                    >
                        {locationError && (
                            <img
                                className="osb-error-icon"
                                src={errorWarning}
                            />
                        )}

                        <AutoComplete
                            name="myLocation"
                            id="myLocation"
                            google={true}
                            placeHolderText={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.My_Location_PlaceHolder,
                                props.priceCalculatorContent
                            )}
                            text={osbDealerStep.selectedLocation}
                            message={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Search_Hint_Label,
                                props.priceCalculatorContent
                            )}
                            errorClass={`${
                                locationError ? 'pc-input-text-error' : ''
                            } `}
                            fetchAutoCompleteInput={fetchLocationInput}
                            searchStringLength={getObjectFromAEMJson(
                                PRICE_CALCULATOR_CONSTANTS.Autocomplete_Search_String_Length,
                                props.priceCalculatorContent
                            )}
                        />
                        {locationError && (
                            <div className="spc-input-error">
                                {locationError}
                            </div>
                        )}
                    </div>
                    {isLocationDisabled && (
                        <>
                            <div
                                className="spc-enable-location-link"
                                onClick={() => {
                                    setIsLocationDisabled(false);
                                }}
                            >
                                {getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.FIND_DEALERS_BY_LOCATION_BUTTON_LABEL,
                                    props.priceCalculatorResultContent
                                )}
                            </div>
                            {locatonFieldDisbledMessage && (
                                <div className="spc-preferred-dealer-message">
                                    {locatonFieldDisbledMessage}
                                </div>
                            )}
                        </>
                    )}
                    {!isLocationDisabled && (
                        <div className="spc-desktop-search-input-use-my-location">
                            <OSBUseLocation
                                showCurrentLocation={() => {
                                    getLocation(props.priceCalculatorContent)
                                        .then(locationData => {
                                            setLocationInput(
                                                locationData as string
                                            );
                                        })
                                        .catch(errorMsg => {
                                            setLocationError(
                                                errorMsg as string
                                            );
                                        });
                                    triggerLocationSignInAnalytics(
                                        fireEvents,
                                        PRICE_CALCULATOR_ANALYTICS.USE_MY_LOCATION_LABEL
                                    );
                                }}
                                useLocationLabel={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.Use_My_Location_Label,
                                    props.priceCalculatorContent
                                )}
                            />
                        </div>
                    )}
                </div>
                <div className="spc-desktop-search-input-button-container">
                    <PrimaryButton
                        role="link"
                        color={'dark'}
                        fill={'fill'}
                        chevron={false}
                        aria-label="get-a-price-cta"
                        aria-labelledby="get a price cta"
                        onClick={getQuote}
                    >
                        {getObjectFromAEMJson(
                            PRICE_CALCULATOR_CONSTANTS.Get_A_Quote_Btn_Label,
                            props.priceCalculatorContent
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default ServicePriceCalculatorDesktopSearch;
