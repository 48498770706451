import React, {
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    VehicleChecklistContent,
    VehicleChecklistFragment,
} from '../../../models/experiencefragments/vehicle-checklist';
import { useExperienceContent } from '../../../hooks/use-server-content';
import './vehicle-checklist-carousel.scss';
import VehicleChecklistRecallsCard from '../../vehicle-checklist/vehicle-checklist-recalls/vehicle-checklist-recalls';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { MMOTAStatusResponse } from '../../../services/mmota-status-service/mmota-status.interface';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import { BRAND, VEHICLE_CHECKLIST_CARD_TYPE } from '../../../constants';
import VehicleChecklistSoftwareUpdate from '../../vehicle-checklist/vehicle-checklist-software-update/vehicle-checklist-software-update';
import VehicleChecklistOrderStatus from '../../vehicle-checklist/vehicle-checklist-order-status/vehicle-checklist-order-status';
import ServerContext from '../../../contexts/serverContext';
import VehicleChecklistDyf from '../../vehicle-checklist/vehicle-checklist-dyf/vehicle-checklist-dyf';
import VehicleChecklistSync from '../../vehicle-checklist/vehicle-checklist-sync/vehicle-checklist-sync';
import VehicleChecklistVhr from '../../vehicle-checklist/vehicle-checklist-vhr/vehicle-checklist-vhr';
import VhrService, {
    VhrLanding,
    VHRStatus,
} from '../../../services/vhr-service/vhr-service';
import ServiceHandler from '../../../services/service-handler';
import VehicleCheckListWarranty from '../../vehicle-checklist/vehicle-checklist-warranty/vehicle-checklist-warranty';
import WarrantyService from '../../../services/warranty-service/warranty-service';
import VehicleChecklistFPR from '../../vehicle-checklist/vehicle-checklist-fpr/vehicle-checklist-fpr';
import VehicleChecklistSyncMap from '../../vehicle-checklist/vehicle-checklist-sync-map/vehicle-checklist-sync-map';
import VehicleChecklistCSP from '../../vehicle-checklist/vehicle-checklist-csp/vehicle-checklist-csp';
import {
    MapTile,
    SyncMapService,
    SyncTile,
} from '../../../services/sync-service/sync-map-service';
import { useSyncMapsStatusUpdates } from '../../../views/sync-maps-updates-view/hooks/use-syncs-maps-status-updates';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import VehicleCheckListEsb from '../../vehicle-checklist/vehicle-checklist-esb/vehicle-checklist-esb';
import RecallsService, {
    RecallsApiResponse,
} from '../../../services/recalls-service/recalls-service';
import VehicleChecklistVehicleHealth from '../../vehicle-checklist/vehicle-checklist-vehicle-health/vehicle-checklist-vehicle-health';
import { PageType } from '../view-template/page-type';
import { ConnectedVehicleResponse } from '../../../models/connected-vehicle';
import VehicleCheckListYFA from '../../vehicle-checklist/vehicle-checklist-yfa/vehicle-checklist-yfa';
import VehicleCheckListSubscriptions from '../../vehicle-checklist/vehicle-checklist-subscriptions/vehicle-checklist-subscriptions';
import SubscriptionsService, {
    SubscriptionsReponse,
} from '../../../services/subscriptions-service/subscriptions-service';
import { FMCarousel } from '@own/fds-react';
import { Carousel } from '../../common/carousel/carousel';
import { isIMGTakataCampaignIdPresent } from '../../../support/pages/recalls-details/recalls-util';
import VehicleChecklistTakataRecallsCard from '../../vehicle-checklist/vehicle-checklist-takata-recalls/vehicle-checklist-takata-recalls';
import useOrderStatus from '../../../hooks/use-order-status';
import useYfaEligibility from '../../../hooks/use-yfa-eligibility';
import { useDyfEligibleModel } from '../../../hooks/dyf/use-dyf-eligible-model/use-dyf-eligible-model';
import { useDyfQueryParams } from '../../../hooks/dyf/use-dyf-query-params/use-dyf-query-params';

interface Props {
    vehicleAttributes: VehicleAttributes;
    displayMMOTAModal: () => void;
    otaStatus: MMOTAStatusResponse | undefined;
    pageType: PageType;
    vehicleStatus: ConnectedVehicleResponse | undefined;
}

export const VehicleChecklistCarousel = (props: Props) => {
    const [vehicleChecklistContent] = useExperienceContent<
        VehicleChecklistFragment
    >('vehicles', 'vehicle-checklist', 'vehicle_checklist');
    const [authenticationState] = useAuthentication();
    const serverContext = useContext(ServerContext);
    const appConfig = new AppConfigurationService();
    const orderStatus = useOrderStatus(
        props.vehicleAttributes.vin,
        authenticationState
    );
    const [recallDataForTile, setRecallDataForTile] = useState<
        RecallsApiResponse
    >();
    const [vhrData, setVhrData] = useState<VhrLanding | null>();
    const [warrantyDate, setWarrantyDate] = useState<string>();
    const [syncData, setSyncData] = useState<SyncTile>();
    const [syncMapData, setSyncMapData] = useState<MapTile>();
    const [warrantyPeriod, setWarrantyPeriod] = useState<number>();
    const [warrantyOdometer, setwarrantyOdometer] = useState<string>();
    const [esbContracts, setEsbContracts] = useState(false);
    const [subscriptionsData, setSubscriptionsData] = useState<
        SubscriptionsReponse
    >();
    const isMobile = window?.innerWidth < 992;

    const isYfaEligible = useYfaEligibility(
        vehicleChecklistContent,
        props.vehicleAttributes,
        authenticationState
    );
    const hasSubscriptionsContent = vehicleChecklistContent?.checkListTiles
        .map(tile => tile?.cardTypes)
        .includes(VEHICLE_CHECKLIST_CARD_TYPE.SUBSCRIPTIONS);

    const { syncGeneration } = useSyncMapsStatusUpdates(
        props.vehicleAttributes.vin ?? '',
        '',
        ''
    );
    const { vin } = props.vehicleAttributes;

    const LATEST_SYNC_GENERATION = '4';
    const isIMGTakataCampaignIdPresentValue = recallDataForTile?.recalls?.some(
        takataRecall =>
            isIMGTakataCampaignIdPresent(
                serverContext.currentLanguageRegionCode,
                takataRecall.fsaNumber
            )
    );
    const filterByCondition = (
        condition: undefined | boolean,
        cardType: string
    ) => (tile: VehicleChecklistContent) =>
        condition ? tile : tile.cardTypes !== cardType;

    const filterOTA = filterByCondition(
        props.otaStatus &&
            [LATEST_SYNC_GENERATION, '', '-1'].includes(syncGeneration),
        VEHICLE_CHECKLIST_CARD_TYPE.OTA
    );

    const filterSyncStatus = filterByCondition(
        syncData?.showSyncTile &&
            ![LATEST_SYNC_GENERATION, '', '-1'].includes(syncGeneration),
        VEHICLE_CHECKLIST_CARD_TYPE.SYNC
    );

    const filterOrderStatus = filterByCondition(
        orderStatus,
        VEHICLE_CHECKLIST_CARD_TYPE.RESERVATION_ORDER_STATUS
    );

    const filterVhrStatus = filterByCondition(
        !!vhrData &&
            vhrData?.vhrStatus != null &&
            vhrData?.vhrStatus !== VHRStatus.INELIGIBLE,
        VEHICLE_CHECKLIST_CARD_TYPE.VHR
    );

    const filterVehicleHealth = filterByCondition(
        props.vehicleStatus?.vehiclestatus?.vin ===
            props.vehicleAttributes.vin && props.pageType === 'DASHBOARD',
        VEHICLE_CHECKLIST_CARD_TYPE.VEHICLE_HEALTH
    );

    const filterEsbStatus = filterByCondition(
        esbContracts,
        VEHICLE_CHECKLIST_CARD_TYPE.ESB
    );

    const filterRecallsStatus = filterByCondition(
        Boolean(
            props.vehicleAttributes.vin &&
                recallDataForTile &&
                recallDataForTile?.recallsCount > 0
        ),
        VEHICLE_CHECKLIST_CARD_TYPE.RECALLS
    );

    const filterTakataRecallsStatus = filterByCondition(
        Boolean(
            props.vehicleAttributes.vin &&
                recallDataForTile &&
                recallDataForTile?.recallsCount > 0 &&
                isIMGTakataCampaignIdPresentValue
        ),
        VEHICLE_CHECKLIST_CARD_TYPE.TAKATA_RECALLS
    );

    const filterYfa = filterByCondition(
        isYfaEligible,
        VEHICLE_CHECKLIST_CARD_TYPE.YFA
    );

    const filterSubscriptions = filterByCondition(
        authenticationState === AuthenticationState.Authenticated,
        VEHICLE_CHECKLIST_CARD_TYPE.SUBSCRIPTIONS
    );
    const { queryModel, queryYear } = useDyfQueryParams();

    const {
        data: dyfEligible,
        loading: dyfElgLoading,
        executeQuery,
    } = useDyfEligibleModel(null, true);

    useEffect(() => {
        executeQuery(
            vin && vin !== ''
                ? { vin }
                : { modelAlias: queryModel, year: queryYear }
        );
    }, [props.vehicleAttributes, vin]);

    const isDyfEligible = useMemo(() => dyfEligible !== null, [
        dyfElgLoading,
        dyfEligible,
    ]);

    const filterDyf = filterByCondition(
        isDyfEligible,
        VEHICLE_CHECKLIST_CARD_TYPE.DYF
    );

    const filters = [
        filterOTA,
        filterOrderStatus,
        filterVhrStatus,
        filterSyncStatus,
        filterEsbStatus,
        filterRecallsStatus,
        filterTakataRecallsStatus,
        filterVehicleHealth,
        filterYfa,
        filterSubscriptions,
        filterDyf,
    ];
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;

    const setWarrantyDateAndOdometer = (
        warrantyStartDate: string | undefined,
        warrantyEndDate: string | undefined,
        warrantyEndOdometer: string | undefined
    ) => {
        if (warrantyStartDate && warrantyEndDate && warrantyEndOdometer) {
            setWarrantyPeriod(
                new Date(warrantyEndDate).getFullYear() -
                    new Date(warrantyStartDate).getFullYear()
            );
            setwarrantyOdometer(warrantyEndOdometer);
        }
    };

    const setWarrantyServiceResponse = (response: any) => {
        if (response?.value) {
            setEsbContracts(response.value.contracts?.length > 0);
            const { warrantyStartDate, warrantyEndDate, warrantyEndOdometer } =
                response.value.baseWarranty || {};
            setWarrantyDateAndOdometer(
                warrantyStartDate,
                warrantyEndDate,
                warrantyEndOdometer
            );
        }
    };

    const countryZAFSetUp = () => {
        if (appConfig.isCountryZAF()) {
            props.vehicleAttributes.warrantyStartDate &&
                setWarrantyDate(
                    new DateTimeUtil().formatDateByRegion(
                        props.vehicleAttributes.warrantyStartDate
                    )
                );
            ServiceHandler.ZafEsbService.getEsbForZAF(
                props.vehicleAttributes.vin ?? ''
            ).then(response => setEsbContracts(!!response?.contracts?.length));
        } else {
            new WarrantyService()
                .getImgWarrantyService(props.vehicleAttributes.vin ?? '')
                .then(response => setWarrantyServiceResponse(response));
        }
    };

    const regionAPASetUp = () => {
        if (appConfig.isRegionAPA()) {
            countryZAFSetUp();
        } else {
            new WarrantyService()
                .getWarrantyDate(
                    props.vehicleAttributes.vin ?? '',
                    authenticationState === AuthenticationState.Authenticated
                )
                .then(warrantyDate => {
                    setWarrantyDate(warrantyDate);
                });
        }
    };

    useEffect(() => {
        setWarrantyDate(undefined);
        setWarrantyPeriod(undefined);
        setwarrantyOdometer(undefined);
        if (props.vehicleAttributes.vin) {
            new VhrService()
                .request(props.vehicleAttributes.vin)
                .then(response => {
                    setVhrData(response);
                });
            regionAPASetUp();
            new SyncMapService()
                .request(props.vehicleAttributes.vin)
                .then(response => {
                    response?.syncTile && setSyncData(response.syncTile);
                    response?.mapTile && setSyncMapData(response.mapTile);
                });
            new RecallsService()
                .request(props.vehicleAttributes.vin)
                .then(response => {
                    setRecallDataForTile(response);
                })
                .catch(() => setRecallDataForTile(undefined));
        }
    }, [props.vehicleAttributes.vin, authenticationState]);

    useEffect(() => {
        if (
            authenticationState === AuthenticationState.Authenticated &&
            hasSubscriptionsContent &&
            vin
        ) {
            new SubscriptionsService()
                .getSubscriptions(vin)
                .then(response => setSubscriptionsData(response));
        }
        return () => {
            setSubscriptionsData(undefined);
        };
    }, [vin, authenticationState, hasSubscriptionsContent]);

    const getFilteredCards = (): VehicleChecklistContent[] => {
        return (
            vehicleChecklistContent?.checkListTiles.filter(tile => {
                return filters.every(fn => fn(tile));
            }) || []
        );
    };

    const renderVehicleChecklistCard = (item: any): ReactNode => {
        const { cardTypes } = item;
        const { vehicleAttributes } = props;

        switch (cardTypes) {
            case VEHICLE_CHECKLIST_CARD_TYPE.RECALLS:
                return (
                    <VehicleChecklistRecallsCard
                        {...item}
                        {...vehicleAttributes}
                        recallDataWithVin={recallDataForTile}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.TAKATA_RECALLS:
                return (
                    <VehicleChecklistTakataRecallsCard
                        {...item}
                        {...vehicleAttributes}
                        recallDataWithVin={recallDataForTile}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.OTA:
                return (
                    <VehicleChecklistSoftwareUpdate
                        {...item}
                        {...vehicleAttributes}
                        displayModal={props.displayMMOTAModal}
                        otaApiStatus={props.otaStatus}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.SYNC:
                return (
                    <VehicleChecklistSync
                        {...item}
                        {...vehicleAttributes}
                        syncData={syncData}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.RESERVATION_ORDER_STATUS:
                return (
                    <VehicleChecklistOrderStatus
                        {...item}
                        {...vehicleAttributes}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.DYF:
                return <VehicleChecklistDyf {...item} {...vehicleAttributes} />;
            case VEHICLE_CHECKLIST_CARD_TYPE.VHR:
                return (
                    <VehicleChecklistVhr
                        {...item}
                        {...vehicleAttributes}
                        vhrData={vhrData}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.WARRANTY:
                return (
                    <VehicleCheckListWarranty
                        {...item}
                        {...vehicleAttributes}
                        warrantyDate={warrantyDate}
                        warrantyPeriod={warrantyPeriod}
                        warrantyOdometer={warrantyOdometer}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.FPR:
                return <VehicleChecklistFPR {...item} {...vehicleAttributes} />;
            case VEHICLE_CHECKLIST_CARD_TYPE.SYNC_MAPS:
            case VEHICLE_CHECKLIST_CARD_TYPE.SOFTWARE_UPDATE_MAPS:
                return (
                    <VehicleChecklistSyncMap
                        {...item}
                        {...vehicleAttributes}
                        syncMapData={syncMapData}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.CSP:
                return <VehicleChecklistCSP {...item} {...vehicleAttributes} />;
            case VEHICLE_CHECKLIST_CARD_TYPE.ESB:
                return <VehicleCheckListEsb {...item} {...vehicleAttributes} />;
            case VEHICLE_CHECKLIST_CARD_TYPE.VEHICLE_HEALTH:
                return (
                    <VehicleChecklistVehicleHealth
                        {...item}
                        {...vehicleAttributes}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.YFA:
                return (
                    <VehicleCheckListYFA
                        {...item}
                        isYfaEligible={isYfaEligible}
                    />
                );
            case VEHICLE_CHECKLIST_CARD_TYPE.SUBSCRIPTIONS:
                return (
                    <VehicleCheckListSubscriptions
                        {...item}
                        vin={vin}
                        data={subscriptionsData}
                    />
                );
            default:
                return null;
        }
    };
    return vehicleChecklistContent &&
        !vehicleChecklistContent?.hideVehicleChecklist &&
        getFilteredCards().length ? (
        <div className={'vehicle-checklist-carousel-wrapper'}>
            {getFilteredCards().length > 3 ||
            (isMobile && getFilteredCards().length > 1) ||
            !currentVersion ? (
                <>
                    {currentVersion ? (
                        <>
                            <div className={'vehicle-checklist-title'}>
                                {vehicleChecklistContent?.checklistTitle}
                            </div>
                            <Carousel<VehicleChecklistContent>
                                items={getFilteredCards()}
                                render={renderVehicleChecklistCard}
                                paginationText={
                                    vehicleChecklistContent?.carouselIndicator
                                }
                            />
                        </>
                    ) : (
                        <>
                            {vehicleChecklistContent.checklistTitle && (
                                <h2 className={'fm-vehicle-checklist__title'}>
                                    {vehicleChecklistContent?.checklistTitle}
                                </h2>
                            )}
                            <FMCarousel
                                instanceName="vehicle-checklist-carousel"
                                items={getFilteredCards()}
                                render={renderVehicleChecklistCard}
                                isUniMode={getFilteredCards()?.length < 4}
                            />
                        </>
                    )}
                </>
            ) : (
                <div className={'no-carousel-container'}>
                    <div className={'vehicle-checklist-title'}>
                        {vehicleChecklistContent?.checklistTitle}
                    </div>
                    <div className={'no-carousel'}>
                        {getFilteredCards().map(item =>
                            renderVehicleChecklistCard(item)
                        )}
                    </div>
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};
