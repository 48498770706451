import React from 'react';
import './privacy-text.scss';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
interface PrivacyTextProps {
    fullPrivacyText: string;
    linkText?: string;
    privacyUrl?: string;
}

const PrivacyText: React.FC<PrivacyTextProps> = ({
    fullPrivacyText,
    linkText = '',
    privacyUrl = '',
}) => {
    const hasLink = linkText && privacyUrl;
    const parts = hasLink ? fullPrivacyText.split(linkText) : [fullPrivacyText];

    const handlePrivacyPolicyClick = () => {
        if (privacyUrl) {
            if (serverSideService.isClientSide()) {
                window.open(privacyUrl, '_blank');
            }
        }
    };

    return (
        <p className="osb-v3-privacy-text">
            {parts[0]}{' '}
            {hasLink && (
                <span
                    className="osb-v3-privacy-text__link"
                    onClick={handlePrivacyPolicyClick}
                >
                    {linkText}
                </span>
            )}
            {hasLink ? parts[1] : ''}
        </p>
    );
};

export default PrivacyText;
