import { SyncMapApiResponse } from '../sync-map-service';

interface Props {
    response: SyncMapApiResponse | any;
    syncTile: syncTile;
}

type syncTile = {
    vehicleSyncEquipped: boolean;
    syncUpdateAvailable: boolean;
    syncUpToDate: boolean;
    lastUpdatedDate: string;
    currentSyncVersion: string;
    hasCtrUpdate: boolean;
    showSyncTile: boolean;
};

export const SyncMapVehicleCtrEquipped = (props: Props) => {
    if (
        props.response.data.sync?.vehicleSyncEquipped ||
        props.response.data.sync?.vehicleCTREquipped
    ) {
        if (
            Object.entries(props.response.data.sync.vehicleSyncGenerationStatus)
                .length === 0 ||
            props.response.data.sync.vehicleSyncGenerationStatus?.genId === '-1'
        ) {
            props.syncTile.showSyncTile = false;
        } else {
            props.syncTile.showSyncTile = true;
        }
    }
};
