// import { HttpsProxyAgent } from 'https-proxy-agent';
import {
    ASTUTE,
    OWNER_MANUALS,
    RECALLS,
    SYNC_MAPS,
} from '../../support/support-constants';
// import HttpService from '../http-service/http-service';
// const httpsAgent = new HttpsProxyAgent(
//     process.env.http_proxy || 'http://internet.ford.com:83'
// );

//const apiEndPoint = 'https://reverseproxy.globalowner.ford.com/slack/';
const apiEndPoint = 'https://decomm-reverseproxy.globalowner.ford.com/slack/';

const API_ALERTS_SLACK_HOOK_URL = `${apiEndPoint}api-alert`;

const ARTICLE_ALERTS_SLACK_HOOK_URL = `${apiEndPoint}article-alerts`;

const OWNER_MANUALS_AEM_API_ALERTS_SLACK_HOOK_URL = `${apiEndPoint}owner-manuals-aem-api-alerts`;

const RECALLS_API_ALERTS_SLACK_HOOK_URL = `${apiEndPoint}recalls-aem-api-alerts`;

const SYNC_MAPS_ALERTS_SLACK_HOOK_URL = `${apiEndPoint}sync-maps-alert`;

const SYNC_MAPS_UPDATE_ALERTS_SLACK_HOOK_URL = `${apiEndPoint}sync-maps-updates-alert`;
const notify = async (body: string, slackChannel?: string) => {
    let apiEndPoint: string;
    switch (slackChannel) {
        case ASTUTE.SLACK_ALERT_INVALID_URL:
            apiEndPoint = ARTICLE_ALERTS_SLACK_HOOK_URL;
            break;

        case OWNER_MANUALS.SLACK_ALERT_OWNER_MANUALS_AEM_API:
            apiEndPoint = OWNER_MANUALS_AEM_API_ALERTS_SLACK_HOOK_URL;
            break;

        case RECALLS.SLACK_ALERT_RECALLS_AEM_API:
            apiEndPoint = RECALLS_API_ALERTS_SLACK_HOOK_URL;
            break;
        case SYNC_MAPS.SLACK_ALERT_SYNC_MAPS:
            apiEndPoint = SYNC_MAPS_ALERTS_SLACK_HOOK_URL;
            break;
        case SYNC_MAPS.SLACK_ALERT_SYNC_MAPS_UPDATES:
            apiEndPoint = SYNC_MAPS_UPDATE_ALERTS_SLACK_HOOK_URL;
            break;
        default:
            apiEndPoint = API_ALERTS_SLACK_HOOK_URL;
    }

    // process.env.REACT_APP_ACTIVE_PROFILE &&
    //     process.env.REACT_APP_ACTIVE_PROFILE === 'prod' &&
    //     (await HttpService.post(
    //         apiEndPoint,
    //         {
    //             text: '```' + body + '```',
    //         },
    //         { headers: { 'Content-type': 'application/json' }, httpsAgent }
    //     ).catch(error => {
    //         console.error(error.message);
    //         return error.message;
    //     }));
    console.log(body, apiEndPoint);
};

const SlackMessages = {
    API_YEAR_MODEL_ERROR:
        'API returns an error for the following year & model: ',
    NON_MATCHING_VIN_ERROR:
        'Did not hit API. Non Ford, Lincoln, or Mercury VIN entered with following VIN: ',
    API_RETURNS_ERROR:
        'API returns an error in the response data with the following VIN: ',
    INVALID_VIN_FORMAT:
        "Did not hit API. Invalid VIN Format ( allowed pattern : '[A-Za-z0-9]{17}') user entered the VIN ",
    AEM_CONTENT_MISSING: 'AEM content missing for ',
    TOPIC_PAGE_INVALID_URL: 'Invalid Topic URL for:  ',
    OLD_TOPIC_PAGE_URL: 'Old Topic URL for:  ',
    CATEGORY_PAGE_INVALID_URL: 'Invalid Category URL for: ',
    SUBCATEGORY_PAGE_INVALID_URL: 'Invalid SubCategory URL for: ',
    BBT_CONTENT_DISABLED: 'BBT content is Hidden or Not Present in AEM for: ',
    VIN_API_FAILURE:
        'VIN passed WMI and REGEX, but failed API call. The failed VIN is ',
    AIRBAG_RECALL_TAKATA:
        'A Takata Airbag Recall Campaign Id was Searched for with ',
    API_VEHICLE_ATTRIBUTES_FAILURE:
        'On Recall Details, vehicle attributes could not be retrieved for ',
    SYNC_MAPS_FILE_UPLOAD_ERROR: 'SYNC XML File Upload error for VIN ',
    SYNC_MAPS_UPDATE_ERROR: 'SYNC update failed for VIN ',
};

export { SlackMessages, notify };
