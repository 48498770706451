import { useContent } from '../../../../hooks/use-server-content';

export interface AppInformationProps {
    hide: boolean;
    applicationName: string;
    breadcrumbStart?: string;
}
export const useAppInformationContent = (): AppInformationProps | null => {
    const [content, getValueByName] = useContent('common', 'app-information');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.applicationName) {
            contentFragment = null;
        }
    }
    return contentFragment as AppInformationProps;
};
