import React, { useState, useEffect } from 'react';
import PickupAndDeliveryAddressForm from '../pickup-and-delivery-address-form/pickup-and-delivery-address-form';
import ContactDetailsForm from '../contact-details-form/contact-details-form';

const ContactDetailsHandler = () => {
    const [canSwitchToPickupForm, setCanSwitchToPickupForm] = useState(false);
    const [showPickupForm, setShowPickupForm] = useState(false);

    const allowedDeliveryServiceIds = ['12', '13'];

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const deliveryServiceId = urlParams.get('deliveryService') || '10';

        if (
            deliveryServiceId &&
            allowedDeliveryServiceIds.includes(deliveryServiceId)
        ) {
            setCanSwitchToPickupForm(true);
        }
    }, []);

    const handleContinue = () => {
        if (canSwitchToPickupForm) {
            setShowPickupForm(true);
        }
    };

    const handleEdit = () => {
        setShowPickupForm(false);
    };

    return (
        <div data-testid="contact-details-handler">
            {showPickupForm ? (
                <PickupAndDeliveryAddressForm
                    onEditClick={handleEdit}
                    data-testid="pickup-form"
                />
            ) : (
                <ContactDetailsForm
                    onContinueClick={handleContinue}
                    data-testid="contact-form"
                />
            )}
        </div>
    );
};

export default ContactDetailsHandler;
