import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    useDealerStep,
    useHttp,
    useLightDeliveryOptionServiceStep,
    useOSBStep,
    useServiceLocationStep,
    useServiceStep,
    useStepProgressBar,
    useVehicleStep,
    useViewport,
} from '../../../../../hooks/owners-osb';
import {
    ServiceData,
    ServiceInfo,
} from '../../../../../models/osb-model/osb-dealerservice-info';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../../../services/service-handler';
import { OSBSecondaryButton } from '../../common/osb-secondary-button/osb-secondary-button';
import { OsbServiceRenderer } from '../../common/osb-service-renderer/osb-service-renderer';
import './light-delivery-options-ford.scss';
import {
    DEALER_STEP_KEYS,
    DELIVERY_STEPS_KEYS,
    SERVICES_STEP_KEYS,
    STEP_PROGRESS_BAR_KEYS,
    OSB_SERVICE_TYPE,
    LIGHT_JOURNEY_ANALYTICS,
    SERVICE_FLOW,
} from '../../osb-constant';
import { OsbPathCalenderStep, OsbPathServicesStep } from '../../osb-controller';
import {
    getObjectFromAEMJson,
    isAuthenticationActive,
    buildNavigationUrl,
    getDeliveryOptionServiceFromDealerServices,
    getDropoffServiceFromDealerServices,
    sortOrderComparer,
    isServiceFoundInSelectedList,
    frameServiceData,
    isConvenienceOptionTypePresent,
    getGoMainHeaderHeight,
} from '../../osb-utils';
import { LightBookingSummary } from '../../light-booking-summary/light-booking-summary';
import BookingSummaryAccordion from '../../light-booking-summary/booking-summary-accordion/booking-summary-accordion';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import {
    triggerBookServiceGlobalCTAOnClickAnalytics,
    triggerDeliveryOptionsLoadAnalytics,
} from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { ServiceLocation } from '../../service-location/service-location';
import AuthenticationService from '../../../../../services/authentication-service/authentication-service';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import GoogleMapService from '../../../../../services/osb-service/google-map-service/google-map-service';
import { FMButton } from '@own/fds-react';

interface Props {
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}

export default function DeliveryOptions(props: Props) {
    const { osbDealerStep } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const { httpState, dispatch } = useHttp();
    const { isMobileView } = useViewport();
    const [fireEvents] = useAnalytics();
    const {
        osbLightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const [deliverySectionJson, setDeliverySectionJson] = useState<any>();
    const { updateProgressBarStatus } = useStepProgressBar();
    const history = useHistory();
    const { osbStep, invalidateAuthentication } = useOSBStep();
    const { osbServiceStep } = useServiceStep();
    const [listOfNoTouchServices, setListOfNoTouchServices] = useState<
        ServiceInfo[]
    >();
    const [isDeliveryRefreshed, setIsDeliveryRefreshed] = useState(false);
    const [loadLocationComponent, setLoadLocationComponent] = useState(false);
    const { resetServiceLocationStep } = useServiceLocationStep();

    const getDeliveryOptionsContent = async (modelName: string) => {
        return ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    };

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    const gotoServiceStep = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        invalidateAuthentication();
        if (
            !osbStep.isAuthenticatedFlow ||
            (osbStep.isAuthenticatedFlow && isAuthenticationActive(osbStep))
        ) {
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DELIVERY, false);
            history.push(
                buildNavigationUrl(
                    OsbPathServicesStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else {
            if (!isAuthenticationActive(osbStep)) {
                new AuthenticationService().login();
            }
        }
    };
    function selectDropoffByDefault() {
        if (osbLightDeliveryServiceStep.selectedServices.length <= 0) {
            addService(
                getDropoffServiceFromDealerServices(
                    osbServiceStep?.dealerServiceInfo?.dealerServices
                )[0]
            );
        }
    }
    const goToDateTimeStep = async () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.CONTINUE_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        const isPickUpAndDeliverySelected =
            isConvenienceOptionTypePresent(
                SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE,
                osbLightDeliveryServiceStep.selectedServices
            ) || false;
        if (
            isPickUpAndDeliverySelected &&
            osbStep.enablePickupNDeliveryLocationDateAndTime
        ) {
            setLoadLocationComponent(true);
        } else {
            // If no delivery option is selected, select Dropoff by default.
            selectDropoffByDefault();
            // swapped back name and description
            updateDropOffServiceNameWithDescription(
                getDeliveryOptionServiceFromDealerServices(
                    osbServiceStep?.dealerServiceInfo?.dealerServices
                )
            );
            invalidateAuthentication();
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DELIVERY, true);
            if (osbStep.isRetrieveFlow) {
                updateProgressBarStatus(
                    STEP_PROGRESS_BAR_KEYS.DATETIME,
                    false,
                    true
                );
            }
            history.push(
                buildNavigationUrl(
                    OsbPathCalenderStep(),
                    osbStep.UrlQueryParams
                )
            );
        }
    };
    function updateDropOffServiceNameWithDescription(
        updatedServiceList: ServiceInfo[]
    ) {
        updatedServiceList.forEach(delivery => {
            if (
                delivery?.additionalInfo?.serviceType?.toUpperCase() ===
                DELIVERY_STEPS_KEYS.DROP_OFF_SERVICE_TYPE.toUpperCase()
            ) {
                [delivery.name, delivery.description] = [
                    delivery.description,
                    delivery.name,
                ];
            }
        });
        return updatedServiceList;
    }
    function alwaysDisplayDropOffAsFirstService(
        sortedServicesList: ServiceInfo[]
    ) {
        let servicesList = sortedServicesList;
        const index = servicesList.findIndex(
            service =>
                service?.additionalInfo?.serviceType ===
                DELIVERY_STEPS_KEYS.DROP_OFF_SERVICE_TYPE
        );
        if (index > -1) {
            const dropOffService = servicesList.splice(index, 1);
            servicesList = dropOffService.concat(servicesList);
        }

        return servicesList;
    }
    const setDeliveryOptionServices = () => {
        if (osbServiceStep?.dealerServiceInfo?.dealerServices) {
            let deliveryOptionServices = getDeliveryOptionServiceFromDealerServices(
                osbServiceStep?.dealerServiceInfo?.dealerServices
            );
            //We need to show the description in the list of convenient options and keep the  service name in the booking summary and
            // communication towards customer as well as dealer
            deliveryOptionServices = updateDropOffServiceNameWithDescription(
                deliveryOptionServices
            );
            selectDropoffByDefault();
            if (deliveryOptionServices) {
                setListOfNoTouchServices(
                    alwaysDisplayDropOffAsFirstService(
                        [...deliveryOptionServices].sort(sortOrderComparer)
                    )
                );
            }
        }
    };
    function buildServiceObject(service: any): ServiceData {
        return {
            id: service?.id,
            serviceType: service?.additionalInfo?.serviceType,
            serviceName:
                service?.additionalInfo?.serviceType ===
                OSB_SERVICE_TYPE.DROPOFF
                    ? service?.description
                    : service?.name,
            serviceCode: service?.serviceId.toString(),
            priceAfterDiscount: service?.priceAfterDiscount,
            price: service?.price,
            subType: service?.subType
                ? service?.subType
                : service?.additionalInfo?.subType,
            serviceOption:
                service?.additionalInfo?.serviceType ===
                OSB_SERVICE_TYPE.DROPOFF
                    ? DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD
                    : 'Delivery Other Option',
            brightCoveAccountId:
                service?.additionalInfo?.brightCoveAccountId || '',
            brightCoveVideoId: service?.additionalInfo?.brightCoveVideoId || '',
            brightCovePlayerId:
                service?.additionalInfo?.brightCovePlayerId || '',
            type: service?.type || '',
            convenienceOptionTypes: service?.convenienceOptionTypes || '',
        };
    }
    const addService = (service: any) => {
        const serviceData: ServiceData = buildServiceObject(service);
        const selectedServices = [serviceData];
        setOSBLightDeliveryServiceStep({
            selectedServices: selectedServices,
        });
    };

    const handleServicePerformedSelected = (e: boolean, refObj: any) => {
        if (e) {
            addService(refObj);
            if (
                !(
                    refObj?.convenienceOptionTypes?.includes(
                        SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE
                    ) || false
                )
            ) {
                resetServiceLocationStep();
            }
        }
    };
    const hasServiceSelected = (serviceId: number) => {
        if (!osbLightDeliveryServiceStep?.selectedServices) {
            return false;
        }
        const selectedService = osbLightDeliveryServiceStep?.selectedServices?.find(
            item => item?.serviceCode === serviceId.toString()
        );
        return selectedService ? true : false;
    };
    const handleSummaryRefresh = () => {
        setIsDeliveryRefreshed(!isDeliveryRefreshed);
    };
    const buildPriceDisclaimers = () => {
        const priceDisclaimer: string[] = [];
        osbServiceStep?.dealerServiceInfo?.dealerServices?.additionalServices?.forEach(
            (item: any) => {
                if (
                    item?.additionalInfo?.priceDisclaimerText?.trim() &&
                    item?.additionalInfo?.serviceType?.trim() ===
                        OSB_SERVICE_TYPE.DELIVERY
                ) {
                    priceDisclaimer.push(
                        '[' +
                            item?.additionalInfo?.priceDisclaimerSymbol +
                            '] ' +
                            item?.additionalInfo?.priceDisclaimerText
                    );
                }
            }
        );
        return priceDisclaimer;
    };

    const preselectDeeplinkedServices = (extraServices: ServiceInfo[]) => {
        if (
            osbStep.preSelectedServices &&
            osbStep.preSelectedServices?.length > 0
        ) {
            const deeplinkedServices = osbStep.preSelectedServices;
            const preSelectedServices = deeplinkedServices.split(',');
            const preSelectedExtraServices: ServiceData[] = [];
            extraServices.forEach(service => {
                if (
                    preSelectedServices.includes(
                        service.serviceId.toString()
                    ) &&
                    (service?.additionalInfo?.serviceType ==
                        OSB_SERVICE_TYPE.DELIVERY ||
                        service?.additionalInfo?.serviceType ==
                            OSB_SERVICE_TYPE.DROPOFF) &&
                    !isServiceFoundInSelectedList(
                        service,
                        osbLightDeliveryServiceStep.selectedServices
                    )
                ) {
                    const serviceOption =
                        service?.additionalInfo?.serviceType ===
                        OSB_SERVICE_TYPE.DROPOFF
                            ? DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD
                            : OSB_SERVICE_TYPE.DELIVERY;
                    const serviceData = frameServiceData(
                        service?.additionalInfo?.serviceType,
                        serviceOption,
                        service
                    );
                    preSelectedExtraServices.push(serviceData);
                }
            });
            if (preSelectedExtraServices.length > 0) {
                setOSBLightDeliveryServiceStep({
                    selectedServices: [
                        ...osbLightDeliveryServiceStep.selectedServices,
                        ...preSelectedExtraServices,
                    ],
                });
            }
        }
    };
    const triggerAnalytics = () => {
        if (osbStep.isRetrieveFlow) {
            triggerDeliveryOptionsLoadAnalytics(
                osbDealerStep,
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
            );
        } else {
            triggerDeliveryOptionsLoadAnalytics(
                osbDealerStep,
                osbVehicleStep,
                osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT
            );
        }
    };

    useEffect(() => {
        if (serverSideService.isClientSide() && !httpState.isLoading) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, [history.location.pathname, httpState.isLoading]);

    useEffect(() => {
        GoogleMapService.loadScript('google');
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DELIVERY, false, true);
        dispatch({ type: 'REQUEST' });
        setDeliveryOptionServices();
        getDeliveryOptionsContent(SERVICES_STEP_KEYS.DELIVERY_CARD_SECTION)
            .then(async results => {
                try {
                    setDeliverySectionJson(results?.elements);
                    preselectDeeplinkedServices(
                        osbServiceStep?.dealerServiceInfo?.dealerServices
                            ?.additionalServices
                    );
                    triggerAnalytics();
                    dispatch({ type: 'RESPONSE' });
                } catch (error) {
                    dispatch({ type: 'RESPONSE' });
                    console.log(error);
                }
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error?.message });
            });
    }, []);
    return (
        <>
            {httpState.isLoading ? (
                <OsbLoader />
            ) : (
                <>
                    {loadLocationComponent ? (
                        <ServiceLocation
                            backButtonTrigger={() => {
                                setLoadLocationComponent(false);
                            }}
                            osbAlertMessagePopup={loadVoucherAlertMessage}
                        />
                    ) : (
                        <div className="light-journey-delivery-options">
                            <div className="delivery-options-container">
                                {(osbStep.isWebViewJourney || !isMobileView) &&
                                    !osbStep.isRetrieveFlow && (
                                        <OSBSecondaryButton
                                            dataTestId="BackToSpecificServicePage"
                                            type="svg"
                                            direction="left"
                                            text={getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.BACK_BUTTON_LABEL,
                                                deliverySectionJson
                                            )}
                                            onClickHandler={gotoServiceStep}
                                        />
                                    )}
                                <div className="delivery-options-title">
                                    {getObjectFromAEMJson(
                                        SERVICES_STEP_KEYS.DELIVERY_OPTIONS_TITLE,
                                        deliverySectionJson
                                    )}
                                </div>
                                <div
                                    className={`delivery-option-service-list  brand-${osbStep.brandName}`}
                                >
                                    <OsbServiceRenderer
                                        servicesList={listOfNoTouchServices}
                                        handleServiceSelection={
                                            handleServicePerformedSelected
                                        }
                                        hasServiceSelected={hasServiceSelected}
                                        selectionControl={'radio'}
                                    />
                                </div>
                                <div className="delivery-option-price-disclaimers">
                                    {buildPriceDisclaimers().map(
                                        (disclaimer, index: number) => (
                                            <div key={index}>{disclaimer}</div>
                                        )
                                    )}
                                </div>
                                <div className="continue-button">
                                    <FMButton
                                        type="primary"
                                        onClick={goToDateTimeStep}
                                        label={getObjectFromAEMJson(
                                            SERVICES_STEP_KEYS.CONTINUE_BUTTON,
                                            deliverySectionJson
                                        )}
                                        ariaLabel="delivery-continue"
                                    />
                                </div>
                            </div>
                            {!isMobileView && (
                                <div className="osb-light-journey-booking-summary">
                                    <LightBookingSummary
                                        stepName={
                                            STEP_PROGRESS_BAR_KEYS.DELIVERY
                                        }
                                        refreshBookingSummary={
                                            handleSummaryRefresh
                                        }
                                        showVoucherLink={true}
                                        loadVoucherAlertMessage={
                                            loadVoucherAlertMessage
                                        }
                                    />
                                </div>
                            )}
                            {isMobileView && (
                                <div className="osb-mobile-booking-summary">
                                    <div className="view-summary-accordion">
                                        <BookingSummaryAccordion
                                            index="0"
                                            className=""
                                            expandMultiplePanels={true}
                                            refreshBookingSummary={
                                                handleSummaryRefresh
                                            }
                                            stepName={
                                                STEP_PROGRESS_BAR_KEYS.DELIVERY
                                            }
                                            showVoucherLink={true}
                                            loadVoucherAlertMessage={
                                                loadVoucherAlertMessage
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
}
