import { useLocation } from 'react-router-dom';
import AppConfigurationService from '../services/app-configuration-service/app-configuration-service';

export const useRouterLocation = () => {
    const location = useLocation();
    const { currentRoot } = new AppConfigurationService();
    return {
        pathname: currentRoot.concat(location.pathname.substring(1)),
    };
};
