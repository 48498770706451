import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import ServiceHandler from '../service-handler';
import httpAdapter from 'axios/lib/adapters/http';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

interface WarrantyType {
    warrantyStartDate: string;
    warrantyEndDate: string;
    url: string;
    coverages: [
        {
            type: string;
            description: string;
            yearDuration: string;
            mileageDuration: string;
            mileageType: string;
            additionalInformation: string;
        }
    ];
    esp: {
        coverages: [];
        contractExpirationDate: string;
        contractExpirationDistanceMeasure: string;
        termPeriodMonths: string;
        contractType: string;
        mileageUnit: string;
    };
    warrantyGuide: [
        {
            link: string;
            title: string;
            month: number;
            year: number;
        }
    ];
    odometer: string;
}

interface WarrantyNetstore {
    market: string;
    mileageUnit: string;
    values: [
        {
            operations: [
                {
                    distance: number;
                    heading: string;
                    warrantyType: number;
                    years: number;
                    footers: string[];
                }
            ];
        }
    ];
}
interface ImgWarrantyResponse {
    requestStatus: string;
    error: {
        statusCode: number;
    };
    lastRequested: string;
    cachedOn: string;
    value: {
        baseWarranty: {
            warrantyStartDate: string;
            warrantyEndDate: string;
            warrantyEndOdometer: string;
        };
        contracts: [
            {
                contractExpirationDate: string;
                contractProductName: string;
                contractState: string;
                contractExpirationDistanceMeasure: string;
                contractProductDescription: string;
            }
        ];
    };
}

export default class WarrantyService {
    public async getWarrantyDate(vin: string, isAuthenticated: boolean) {
        const appConfig = new AppConfigurationService();
        const { currentLanguageCode, current3LetterCountryCode } = appConfig;
        const params = {
            country: current3LetterCountryCode.toUpperCase(),
            language: currentLanguageCode.toUpperCase(),
        };
        const WARRANTY_INFORMATION = isAuthenticated
            ? DSL_API_PATH.WARRANTY_INFORMATION_AUTH
            : DSL_API_PATH.WARRANTY_INFORMATION_UNAUTH;
        const headers = isAuthenticated
            ? { ...HttpService.getAppIdAndAuthTokenRequestHeaders(), vin }
            : { ...HttpService.getAppIdRequestHeaders(), vin };
        const warrantyUrl = DSL_BASE_URL + WARRANTY_INFORMATION;

        const response = HttpService.get<WarrantyType>(warrantyUrl, true, {
            params,
            headers,
            adapter: httpAdapter,
        });
        return response.then(response => {
            if (
                response?.data?.warrantyStartDate ||
                response?.data?.warrantyEndDate
            ) {
                const date = new Date(
                    !appConfig.isRegionEU()
                        ? response.data.warrantyStartDate
                        : response.data.warrantyEndDate
                );
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const year = date
                    .getFullYear()
                    .toString()
                    .substr(2, 2);
                return `${month}/${day}/${year}`;
            }
        });
    }

    public async getWarrantyService(
        vin: string,
        isAuthenticated: boolean
    ): Promise<WarrantyType | null> {
        const {
            currentLanguageCode,
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;
        const WARRANTY_INFORMATION = isAuthenticated
            ? DSL_API_PATH.WARRANTY_INFORMATION_AUTH
            : DSL_API_PATH.WARRANTY_INFORMATION_UNAUTH;
        const warrantyBaseUrl = DSL_BASE_URL + WARRANTY_INFORMATION;
        const warrantyTypeUrl = `${warrantyBaseUrl}?country=${current3LetterCountryCode.toUpperCase()}&language=${currentLanguageCode.toUpperCase()}`;
        const headers = isAuthenticated
            ? { ...HttpService.getAppIdAndAuthTokenRequestHeaders(), vin }
            : { ...HttpService.getAppIdRequestHeaders(), vin };
        const response = HttpService.get<WarrantyType>(warrantyTypeUrl, true, {
            headers,
        });
        return response.then(this.parseResponse);
    }

    public async getWarrantyNetstore(
        url: string
    ): Promise<WarrantyNetstore | null> {
        const response = HttpService.get<WarrantyNetstore>(url, true, {});
        return response.then(this.parseResponse);
    }

    private parseResponse(response: AxiosResponse<any>): any {
        if (response.data) {
            return response.data;
        }
        return null;
    }

    public async getImgWarrantyService(
        vin: string
    ): Promise<ImgWarrantyResponse | null> {
        const {
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;
        const url =
            DSL_BASE_URL +
            DSL_API_PATH.IMG_WARRANTY_DETAILS +
            `?country=${current3LetterCountryCode.toUpperCase()}`;
        const response = HttpService.get<ImgWarrantyResponse>(url, true, {
            headers: {
                'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                    'Consumer-Key'
                ],
                'Auth-Token': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                    'Auth-Token'
                ],
                vin: vin,
            },
        });
        return response.then(this.parseResponse);
    }
}
