import React, { useEffect, useState } from 'react';
import './comment-input.scss';
interface CommentInputProps {
    maxChars: number;
    boxHeight: number;
    charsRemainingText: string;
    onCommentChange: (comment: string) => void;
    initialComment?: string;
}
const CommentInput: React.FC<CommentInputProps> = ({
    maxChars,
    boxHeight,
    charsRemainingText,
    onCommentChange,
    initialComment = '',
}) => {
    const [comment, setComment] = useState(initialComment);

    useEffect(() => {
        setComment(initialComment);
    }, [initialComment]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length <= maxChars) {
            setComment(e.target.value);
            onCommentChange(e.target.value);
        }
    };

    return (
        <div className="comment-input-container" data-testid="comment-input">
            <textarea
                value={comment}
                onChange={handleInputChange}
                style={{ height: `${boxHeight}px` }}
            />
            <div className="characters-remaining">
                {charsRemainingText}: {maxChars - comment.length}
            </div>
        </div>
    );
};

export default CommentInput;
