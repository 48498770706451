import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { CalendarStep } from '../../models/osb-model/osb-calendar-step';
import {
    StoreState,
    CalendarStepState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { DealerCalendarDetails } from '../../models/osb-model/dealer-calendar-details';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { useOSBStep } from './use-osb-step';

export const getInitialCalendarState = (): CalendarStep => {
    return {
        selectedDate: '',
        selectedTime: '',
        selectedTimeRange: '',
        dealerCalendarInfo: {
            calendarStart: '',
            calendarEnd: '',
            timeSlotDuration: 0,
            availableDates: [],
        },
        selectedPickupDate: '',
        selectedPickupTime: '',
    };
};
//Configure and initialize calendar step state in global store
const configureCalendarStepStore = () => {
    const actions = {
        //Action to set the manual calendar step details to global store
        SET_CALENDAR_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as CalendarStepState).calendarStep,
                ...(payload as CalendarStep),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_CALENDAR_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { calendarStep: newState };
        },

        SET_CALENDAR_STEP_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as CalendarStepState).calendarStep,
                ...payload,
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_CALENDAR_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { calendarStep: newState };
        },
    };
    initializeStore(actions, {
        calendarStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_CALENDAR_STEP_KEY
            ) || getInitialCalendarState(),
    });
};

configureCalendarStepStore();

// custom hook for manual calendar step to handle the actions and updating payload in global store
export const useCalendarStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalCalendarStep = (
        calendarStep: CalendarStep,
        shouldReload = true
    ) => {
        dispatch(
            'SET_CALENDAR_STEP',
            calendarStep,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const setGlobalCalendarStepPayload = (
        payload: {
            [key: string]: string | DealerCalendarDetails;
        },
        shouldReload = true
    ) => {
        dispatch(
            'SET_CALENDAR_STEP_PAYLOAD',
            payload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetCalendarStep = (shouldReload = true) => {
        dispatch(
            'SET_CALENDAR_STEP',
            getInitialCalendarState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbCalendarStep: (globalState as CalendarStepState).calendarStep,
        setOSBCalendarStep: setGlobalCalendarStep,
        setOSBCalendarStepPayload: setGlobalCalendarStepPayload,
        resetCalendarStep: resetCalendarStep,
        callContentService: callContentService,
    };
};
