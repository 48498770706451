import React, { useContext, useState, useEffect, useRef } from 'react';
import {
    InputField,
    PrimaryButton,
    SecondaryButton,
    Dropdown,
} from '../../../common';
import ServiceHandler from '../../../../services/service-handler';
import Popover from '../../../common/popover/popover';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import useUserProfile from '../../../../hooks/use-user-profile';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { VehicleUtil } from '../../../utils/vehicle-util/vehicle-util';
import ServerSideService from '../../../../services/server-side-service/server-side-service';
import VehicleTypeService from '../../../../services/vehicle-type-service/vehicle-type-service';
import ServerContext from '../../../../contexts/serverContext';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import { KEYBOARD_KEYS } from '../../../../constants';
import {
    API_REQUEST_SOURCE_TYPE,
    SERVICE_DELIVERY_TYPE,
} from '../../owners-osb/osb-constant';
import { useOSBStep, useServiceStep } from '../../../../hooks/owners-osb';

interface Props {
    contentLabels: {
        vinInputHeader: string;
        vinInputLabel: string;
        vinInputPlaceholder: string;
        vinInputDividerText: string;
        chooseVehicleGarageHeadLine: string;
        seeYourWarrantyCtaLabel: string;
        loginHeading: string;
        loginDescription: string;
        signinButtonText: string;
        invalidVinValidationMessage: string;
        invalidVinOptionalValidationMessage: string;
        vehicleVinTooltipHeader: string;
        vehicleVinTooltipContent: string;
        registerLinkCtaLabel: string;
        registrationLookup: boolean;
        regNumberValidationMessage: string;
        warrantyNotFoundErrorMessage: string;
    };
    loginClick: (action: string) => void;
    goToWarrantyDetails: (vin: string) => void;
}

export const VinInputSection = (props: Props) => {
    const [AuthenticatedUser, setAuthenticatedUser] = useState('');
    const authenticationService = new AuthenticationService();
    const {
        vinInputHeader,
        vinInputLabel,
        vinInputDividerText,
        chooseVehicleGarageHeadLine,
        seeYourWarrantyCtaLabel,
        loginHeading,
        loginDescription,
        signinButtonText,
        invalidVinValidationMessage,
        invalidVinOptionalValidationMessage,
        vehicleVinTooltipHeader,
        vehicleVinTooltipContent,
        registerLinkCtaLabel,
        registrationLookup,
        regNumberValidationMessage,
        warrantyNotFoundErrorMessage,
    } = props.contentLabels;
    const [vinRegNo, setVinRegNo] = useState<string>('');
    const [isActive, setActive] = useState(false);
    const [vinErrorMessage, setVinErrorMessage] = useState<string>('');
    const [showPopover, setPopover] = useState<boolean>(false);
    const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 });
    const [defaultstatus, setDefaultStatus] = useState<boolean>(true);
    const { osbServiceStep } = useServiceStep();
    const { osbStep } = useOSBStep();
    const isMobile = ServerSideService.isClientSide()
        ? window.innerWidth <= 780
        : false;
    const [selectedVinFromGarage, setSelectedVinFromGarage] = useState<
        string
    >();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const garageOptions: any = [];
    const profile = useUserProfile();
    const [fireEvents] = useAnalytics();
    const vehicleUtil = new VehicleUtil();
    const warrantyButtonElement = useRef<HTMLInputElement>(null);
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    useEffect(() => {
        if (profile) {
            setVehiclesData(
                profile.vehicles.map(vehicle => {
                    return {
                        year: parseInt(vehicle.modelYear, 10),
                        make: vehicle.make,
                        model: vehicle.modelName,
                        vin: vehicle.vin,
                        uomSpeed: profile.profile.uomSpeed,
                        nickName: vehicle.nickName,
                        modelYear: vehicle.modelYear,
                        vehicleMake: vehicle.make,
                        modelName: vehicle.modelName,
                    };
                })
            );
        }
    }, [profile]);
    useEffect(() => {
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setAuthenticatedUser('loggedIn');
                } else {
                    setAuthenticatedUser('notLoggedIn');
                }
            });
    }, []);
    const triggerWarrantyCTAOnClickAnalyticsEvent = () => {
        fireEvents(
            ['warranty-information-cta-onclick-event'],
            undefined,
            {
                fieldName: 'see your warranty',
            },
            false
        );
    };

    const goToWarrantyDetails = (vin: string) => {
        if (vin) {
            props.goToWarrantyDetails(vin);
        }
    };

    const isVinOptionalValidationRequired = () => {
        return (
            invalidVinOptionalValidationMessage === '' ||
            invalidVinOptionalValidationMessage === null
        );
    };
    const getVinValidationMessage = (vin: string) => {
        const vinValidationMessage = !isVinOptionalValidationRequired()
            ? invalidVinValidationMessage
            : invalidVinOptionalValidationMessage;

        return vin.length <= 10 && registrationLookup
            ? regNumberValidationMessage
            : vinValidationMessage;
    };

    const vinInputFocus = () => {
        if (warrantyButtonElement.current) {
            warrantyButtonElement.current.focus();
        }
    };

    const onSeeYourWarrantyClick = () => {
        if (!isActive) {
            setActive(!isActive);
        }
        if (vinRegNo === '') {
            setVinErrorMessage(getVinValidationMessage);
            vinInputFocus();
        }
        if (vinRegNo !== '' && vinErrorMessage !== '') {
            vinInputFocus();
        }

        // Execute a vehicle type call first for checking if the vin is valid before calling warranty service
        if (vinRegNo) {
            new VehicleTypeService()
                .request(
                    vinRegNo,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                )
                .then(() => {
                    callWarrantyOnValidVin(vinRegNo);
                })
                .catch(() => {
                    setVinErrorMessage(warrantyNotFoundErrorMessage);
                    vinInputFocus();
                });
        }

        triggerWarrantyCTAOnClickAnalyticsEvent();
    };

    const callWarrantyOnValidVin = (vinRegNo: string) => {
        if (vinRegNo !== '' && vinErrorMessage === '') {
            if (vinRegNo.length > 10) {
                getWarrantyService(vinRegNo);
            } else if (vinRegNo.length <= 10) {
                getVehicleInfo(vinRegNo);
            }
        }
    };

    const getWarrantyService = (vinRegNo: string) => {
        ServiceHandler.WarrantyService.getWarrantyService(
            vinRegNo,
            AuthenticatedUser === 'loggedIn'
        )
            .then((result: any) =>
                handleWarrantyServiceResult(result, vinRegNo)
            )
            .catch(handleWarrantyServiceError);
    };

    const handleWarrantyServiceResult = (result: any, vinRegNo: string) => {
        if (result === undefined || result === null || !result) {
            setVinErrorMessage(warrantyNotFoundErrorMessage);
            vinInputFocus();
        } else {
            setVinErrorMessage('');
            if (vinRegNo !== '' && vinErrorMessage === '') {
                goToWarrantyDetails(vinRegNo);
            }
        }
    };

    const handleWarrantyServiceError = () => {
        setVinErrorMessage(warrantyNotFoundErrorMessage);
        vinInputFocus();
    };

    const getVehicleInfo = (vinRegNo: string) => {
        const vehicleData = {
            registrationNumber: vinRegNo.replace(/[ -]/g, ''),
            mileage: '100',
        };
        ServiceHandler.OsbVehicleLookUp.getVehicleInfo({
            vehicleData,
            delivery: osbServiceStep?.isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            source: osbStep.source || API_REQUEST_SOURCE_TYPE.WEB,
        })
            .then(result => handleVehicleInfoResult(result))
            .catch(handleVehicleInfoError);
    };

    const handleVehicleInfoResult = (result: any) => {
        if (result === undefined || result === null || !result) {
            setVinErrorMessage(regNumberValidationMessage);
            vinInputFocus();
        } else {
            setVinErrorMessage('');
            const vinNumber = result.value.vin;
            if (vinNumber !== '' && vinNumber !== null) {
                goToWarrantyDetails(vinNumber);
            }
        }
    };

    const handleVehicleInfoError = () => {
        setVinErrorMessage(regNumberValidationMessage);
        vinInputFocus();
    };

    const onGarageSeeYourWarrantyClick = () => {
        if (!isActive) {
            setActive(!isActive);
        }
        if (
            selectedVinFromGarage !== '' &&
            selectedVinFromGarage !== undefined
        ) {
            goToWarrantyDetails(selectedVinFromGarage);
        }
        triggerWarrantyCTAOnClickAnalyticsEvent();
    };

    const setPopoverPositionValue = (rect: DOMRect) => {
        const newPosition = {
            left: !isMobile ? rect.x + rect.width - 325 : 0,
            top: !isMobile ? rect.y + window.scrollY - 480 : 0,
        };
        setPopoverPosition(newPosition);
    };

    const updatePopoverPositions = () => {
        const popoverTriggerEle = document.getElementsByClassName(
            'vin-info-icon'
        )[0];
        if (popoverTriggerEle) {
            const rect = popoverTriggerEle.getBoundingClientRect();
            setPopoverPositionValue(rect);
        }
    };

    const getVinRegex = () => {
        const vinOnlyRegex = !isVinOptionalValidationRequired()
            ? /^[A-Za-z0-9]{17}$/
            : /(^(?!WFO)[A-Za-z0-9]{17}$)/;
        const vinWithRegNumRegex = !isVinOptionalValidationRequired()
            ? /(^\w{17}$)|(^\w[ -]{1,10}$)/i
            : /(^(?!WFO)\w{17}$)|(^[\w -]{1,10}$)/i;

        return registrationLookup ? vinWithRegNumRegex : vinOnlyRegex;
    };

    const updateVinInput = (e: any) => {
        if (e.type === 'blur') {
            const vinValue = e.target.value.toUpperCase();
            e.target.value = vinValue;
            const vinRegEx = getVinRegex();
            if (vinValue.match(vinRegEx)) {
                const trimmedRegNo = vinValue.replace(/[ -]/g, '');
                new VehicleTypeService()
                    .request(
                        trimmedRegNo,
                        currentLanguageRegionCode,
                        current3LetterCountryCode?.toUpperCase()
                    )
                    .then(() => {
                        setVinErrorMessage('');
                        setVinRegNo(trimmedRegNo);
                        setDefaultStatus(false);
                    })
                    .catch(() => {
                        setVinErrorMessage(warrantyNotFoundErrorMessage);
                        vinInputFocus();
                    });
            } else {
                setVinErrorMessage(getVinValidationMessage(vinValue));
                setVinRegNo(vinValue);
            }
        }
        if (e.type === 'change') {
            setVinRegNo(e.target.value);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updatePopoverPositions);
        return () => {
            window.removeEventListener('resize', updatePopoverPositions);
        };
    }, []);

    const togglePopover = (e: any, popoverState: boolean) => {
        if (e.currentTarget.getBoundingClientRect || e.keyCode === 13) {
            const rect = e.currentTarget.getBoundingClientRect();
            setPopoverPositionValue(rect);
            setPopover(popoverState);
            if (!isMobile && popoverState) window.scrollTo(0, 60);
        } else {
            setPopover(false);
        }
        e.preventDefault();
    };

    const renderPopover = () => {
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: vehicleVinTooltipContent,
                    }}
                />
            </div>
        );

        return (
            <Popover
                name="warranty-vehicle-vin-popover"
                tooltipPosition="right"
                mask={true}
                handleClose={e => togglePopover(e, false)}
                popoverPosition={popoverPosition}
                heading={vehicleVinTooltipHeader}
                content={popoverContent}
                focusElement={'.vin-info-icon.info-icon'}
            />
        );
    };

    const togglePopoverClicked = (event: any, value: boolean) => {
        if (
            event.key === KEYBOARD_KEYS.ENTER ||
            event.key === KEYBOARD_KEYS.SPACE
        ) {
            togglePopover(event, value);
        }
    };

    const onSignInClick = (action: string) => {
        props.loginClick(action);
    };
    const chooseGarage = () => {
        vehiclesData.forEach(vehicle => {
            garageOptions.push({
                label: vehicleUtil.getGarageVehicleName(vehicle),
                value: vehicle.vin,
            });
        });
        return garageOptions;
    };
    const chooseGarageChange = (
        label: string,
        id: number,
        value: string | undefined
    ) => {
        setSelectedVinFromGarage(value);
    };
    return (
        <section className="warranty-vin-input-section">
            <div className="vehicle-details-wrapper">
                {AuthenticatedUser === 'notLoggedIn' && (
                    <div className="login-section">
                        <h2>{loginHeading}</h2>
                        <p className="login-desc">{loginDescription}</p>
                        <div className={'login-btn-wrap'}>
                            <PrimaryButton
                                className="signin-button"
                                role="link"
                                ariaLabel="Goto Sign In page"
                                color="dark"
                                fill="fill"
                                chevron={true}
                                onClick={() => onSignInClick('signin')}
                            >
                                {signinButtonText}
                            </PrimaryButton>
                            <SecondaryButton
                                className="warranty-register"
                                onClick={() => onSignInClick('register')}
                            >
                                {registerLinkCtaLabel}
                            </SecondaryButton>
                        </div>
                    </div>
                )}
                {AuthenticatedUser === 'loggedIn' && vehiclesData.length > 0 && (
                    <div className="warranty-garage-section">
                        <div className="heading-with-icon">
                            <h2>{chooseVehicleGarageHeadLine}</h2>
                        </div>
                        <Dropdown
                            id="warranty-garage-dropdown"
                            label={''}
                            noLabel={true}
                            onChange={(e, index, value) =>
                                chooseGarageChange(e, index, value)
                            }
                            options={chooseGarage()}
                            value={
                                garageOptions.find(
                                    (opt: any) =>
                                        opt.value === selectedVinFromGarage
                                )?.label || selectedVinFromGarage
                            }
                            className="warranty-garage-dropdown-section"
                        />
                        <PrimaryButton
                            className={`see-your-warranty-button ${
                                isActive
                                    ? 'see-your-warranty-button-clicked'
                                    : ''
                            }`}
                            color={'dark'}
                            fill={'fill'}
                            chevron={true}
                            onClick={onGarageSeeYourWarrantyClick}
                            ariaLabel={seeYourWarrantyCtaLabel}
                        >
                            {seeYourWarrantyCtaLabel}
                        </PrimaryButton>
                    </div>
                )}
                {(AuthenticatedUser !== 'loggedIn' ||
                    vehiclesData.length > 0) && (
                    <div className="divider-section">
                        <hr className="before-dash" />{' '}
                        <span>{vinInputDividerText}</span>{' '}
                        <hr className="after-dash" />
                    </div>
                )}
                <div className="enter-your-vin-section">
                    <div className="heading-with-icon">
                        <h2>{vinInputHeader}</h2>
                        <div className="info-wrapper">
                            {showPopover && renderPopover()}
                            {vehicleVinTooltipContent ? (
                                <img
                                    className="vin-info-icon info-icon"
                                    alt=""
                                    aria-label={vinInputHeader + ' TOOLTIP'}
                                    role="button"
                                    onClick={(e: any) => togglePopover(e, true)}
                                    onKeyUp={(e: any) =>
                                        togglePopoverClicked(e, true)
                                    }
                                    tabIndex={0}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <InputField
                        label={vinInputLabel}
                        ariaLabel={`warranty-${vinInputLabel}`}
                        name="VIN"
                        maxLength={17}
                        className="warranty-vin vin-textField"
                        value={vinRegNo}
                        errorMessage={vinErrorMessage}
                        ref={warrantyButtonElement}
                        onChange={e => updateVinInput(e)}
                        onBlur={e => updateVinInput(e)}
                        onFocus={e => updateVinInput(e)}
                        fdsStyle={true}
                        validate={defaultstatus}
                    />
                    <PrimaryButton
                        className={`see-your-warranty-button vin ${
                            isActive ? 'see-your-warranty-button-clicked' : ''
                        }`}
                        color={'dark'}
                        fill={'fill'}
                        chevron={true}
                        onClick={onSeeYourWarrantyClick}
                        ariaLabel={seeYourWarrantyCtaLabel}
                    >
                        {seeYourWarrantyCtaLabel}
                    </PrimaryButton>
                </div>
            </div>
        </section>
    );
};
