import { SyncMapsCard, SyncMapStatus } from '../use-syncs-maps-status-updates';

export const getVWCards = (
    syncMapContent: SyncMapStatus | null,
    syncOption: string,
    desktopOrLarger: boolean
): SyncMapsCard[] | undefined => {
    const desktopFilter = (card: SyncMapsCard) =>
        desktopOrLarger ? !card.isMobileCard : card.isMobileCard;
    return (
        syncMapContent?.vwCards &&
        syncMapContent?.vwCards
            .filter(card => card.syncMapsCardType === syncOption)
            .filter(card =>
                card.syncMapsCardType === 'usb' ? desktopFilter(card) : true
            )
    );
};
