import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import { trailerProfileTypes } from '../../components/sections/ultimate-towing-experience/components/types';

export default class RetrieveProfileUTE {
    async request(vin: string): Promise<trailerProfileTypes> {
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        const uteUrl =
            DSL_BASE_URL + DSL_API_PATH.UTE_RETRIEVE_PROFILE + '?vin=' + vin;

        const response = HttpService.get<trailerProfileTypes>(uteUrl, true, {
            headers,
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<trailerProfileTypes>
    ): trailerProfileTypes {
        if (response.data) {
            return response.data;
        } else {
            return {
                trailerList: [],
            };
        }
    }
}
