import React, { useEffect, useState, useReducer } from 'react';
import ServiceHandler from '../../../../../services/service-handler';
import './light-retrieve-booking-lincoln.scss';
import {
    RETRIEVE_BOOKING_STEP_KEYS,
    SERVICE_FLOW,
    SERVICES_STEP_KEYS,
    OSB_SERVICE_TYPE,
    DELIVERY_STEPS_KEYS,
    SERVICE_GROUP_TYPE,
    SERVICE_DELIVERY_TYPE,
    STEP_PROGRESS_BAR_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
} from '../../osb-constant';
import { useHistory } from 'react-router-dom';
import {
    OsbContentResponse,
    ContentElements,
} from '../../../../../models/osb-model/osb-content-details';
import parse from 'html-react-parser';
import {
    triggerBookServiceGlobalCTAOnClickAnalytics,
    triggerRetrieveBookingAnalytics,
    triggerRetrieveBookingErrorAnalytics,
} from '../../analytics/osb-analytics';

import {
    usePersonalDetailStep,
    useCalendarStep,
    useServiceStep,
    useDealerStep,
    useVehicleStep,
    getInitialVehicleState,
    getInitialVehicleDetails,
    useRetrieveBookingStep,
    getInitialRetrieveBookingState,
    useReviewBookingStep,
    useOSBStep,
    useLightDeliveryOptionServiceStep,
    getInitialVehicleRecallService,
    useOsbImageUpload,
    useStepProgressBar,
    useServiceLocationStep,
    useCampaignStatesStep,
} from '../../../../../hooks/owners-osb';
import { retrieveBookingStepReducer } from '../../../../../reducers/osb-reducers';
import { RetrieveBookingStep } from '../../../../../models/osb-model/osb-retrieve-booking-step';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import { ServiceStep } from '../../../../../models/osb-model/osb-service-step';
import { PersonalDetail } from '../../../../../models/osb-model/osb-personal-details';
import errorWarning from '../../../../../assets/error-warning.svg';
import {
    removeAllClientStorage,
    getLightVehicleData,
    getObjectFromAEMJson,
    buildNavigationUrl,
    getRecallServiceDescription,
    getGoMainHeaderHeight,
    hasMobileService,
} from '../../osb-utils';
import {
    DealerServicesArray,
    ServiceInfo,
} from '../../../../../models/osb-model/osb-dealerservice-info';
import { LightDeliveryServiceStep } from '../../../../../models/osb-model/osb-light-delivery-service-step';
import {
    OsbPathBookingAccessCodeStep,
    OsbPathBookingRetrieveSummaryStep,
} from '../../osb-controller';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { VehicleStep } from '../../../../../models/osb-model/osb-vehicle-step';
import { OsbVhaAlertResponse } from '../../../../../services/vha-service/vha-service.interface';
import { PrognosticsDataV2 } from '../../../../../models/vehicle-prognostics';
import { AcceptedFileInfo } from '../../common/osb-file-uploader/osb-file-uploader';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import { BookingAuthorization } from '../../../../../models/osb-model/osb-retrieve-booking';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { getBookableFromBookingApiResponse } from '../../../../../hooks/owners-osb/use-all-osb-state';
import { getVoucherDetailsFromStore } from '../../../../../hooks/owners-osb/use-campaign-states-step';
import { FMButton } from '@own/fds-react';

interface Props {
    retrieveBookingContent: OsbContentResponse | undefined;
    osbAppMoreContent: ContentElements[] | undefined;
}

export const LightRetrieveBooking = (props: Props) => {
    const { osbPersonalDetail, setOSBPersonalDetail } = usePersonalDetailStep();
    const { setOSBCalendarStepPayload } = useCalendarStep();
    const { setOSBDealerStepPayload } = useDealerStep();
    const { setOSBServiceStep } = useServiceStep();
    const { setOSBImageServiceStep } = useOsbImageUpload();
    const { setOSBReviewDetail } = useReviewBookingStep();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailAdd, setEmailAdd] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [emailError, setEmailError] = useState<string>('');
    const [accessCodeError, setAccessCodeError] = useState<string>('');
    const [isEmailLabelShow, setIsEmailLabelShow] = useState<boolean>(false);
    const [isAccessCodeLabelShow, setIsAccessCodeLabelShow] = useState<boolean>(
        false
    );
    const [fireEvents] = useAnalytics();
    const {
        setOSBStepProgressBarPayload,
        manageOsbStepVisibility,
    } = useStepProgressBar();
    const { setOSBVehicleStep, osbVehicleStep } = useVehicleStep();
    const { setOSBServiceLocationStepPayload } = useServiceLocationStep();
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const {
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();

    const {
        osbRetrieveBookingStep,
        setOSBRetrieveBookingStep,
    } = useRetrieveBookingStep();
    const { setOSBCampaignStates } = useCampaignStatesStep();

    const [retrieveBookingStep, setRetrieveBookingStep] = useReducer(
        retrieveBookingStepReducer,
        getInitialRetrieveBookingState()
    );

    const setComponentPayload = (
        payload: { [key: string]: string } | RetrieveBookingStep
    ): void => {
        setRetrieveBookingStep({ type: 'SET', payload: payload });
    };

    const onEmailAddressChange = (value: string) => {
        setEmailAdd(value);
        if (
            value.length <= 0 ||
            (value.length > 0 &&
                !value.match(
                    props.retrieveBookingContent?.elements
                        .find(
                            e =>
                                e.name ===
                                RETRIEVE_BOOKING_STEP_KEYS.EMAIL_REGEX
                        )
                        ?.value.toString() ?? ''
                ))
        ) {
            const error =
                props.retrieveBookingContent?.elements
                    .find(
                        e => e.name === RETRIEVE_BOOKING_STEP_KEYS.EMAIL_ERROR
                    )
                    ?.value.toString() ?? '';
            setEmailError(error);
        } else {
            setEmailError('');
        }
    };

    const emailLabelShow = () => {
        setIsEmailLabelShow(true);
    };

    const emailLabelHide = () => {
        setIsEmailLabelShow(false);
    };

    const accessCodeLabelShow = () => {
        setIsAccessCodeLabelShow(true);
    };

    const accessCodeLabelHide = () => {
        setIsAccessCodeLabelShow(false);
    };

    const onAccessCodeChange = (value: string) => {
        setAccessCode(value);
        if (
            value.length <= 0 ||
            (value.length > 0 &&
                !value.match(
                    props.retrieveBookingContent?.elements
                        .find(
                            e =>
                                e.name ===
                                RETRIEVE_BOOKING_STEP_KEYS.ACCESS_CODE_REGEX
                        )
                        ?.value.toString() ?? ''
                ))
        ) {
            const err =
                props.retrieveBookingContent?.elements
                    .find(
                        e =>
                            e.name ===
                            RETRIEVE_BOOKING_STEP_KEYS.ACCESS_CODE_ERROR
                    )
                    ?.value.toString() ?? '';
            setAccessCodeError(err);
        } else {
            setAccessCodeError('');
        }
    };

    useEffect(() => {
        triggerRetrieveBookingAnalytics(fireEvents);
        setComponentPayload(osbRetrieveBookingStep);
        setOSBStepPayload({ UrlQueryParams: '', displayProgressBar: true });
    }, []);

    if (serverSideService.isClientSide()) {
        window.scrollTo({
            top: getGoMainHeaderHeight() - 2,
            behavior: 'smooth',
        });
    }

    let totalPrice = '0';
    let totalDiscountPrice = '0';
    let totalPriceForAnalytics = '0';
    let comments = '';
    const getTotalPrice = (selectedServices: any) => {
        if (selectedServices.length > 0) {
            totalPrice = selectedServices.filter(
                (result: { price: any }) => result.price < 0
            );
            if (totalPrice.length > 0) {
                totalPrice =
                    props.osbAppMoreContent &&
                    getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.PRICE_ON_REQUEST,
                        props.osbAppMoreContent
                    );
                return;
            }
            totalPrice = selectedServices
                .map((result: { price: any }) => Number(result.price))
                .reduce((total: any, currentPrice: any) =>
                    currentPrice > 0 ? total + currentPrice : total
                )
                .toFixed(2);
        }
        totalPriceForAnalytics = totalPrice;
    };
    const getTotalPriceWithDiscount = (selectedServices: any) => {
        let totalPriceWithDiscount = '0';
        if (selectedServices.length > 0) {
            totalPriceWithDiscount = selectedServices.filter(
                (result: { priceAfterDiscount: any }) =>
                    result.priceAfterDiscount < 0
            );
            if (totalPriceWithDiscount.length > 0) {
                totalPriceWithDiscount =
                    props.osbAppMoreContent &&
                    getObjectFromAEMJson(
                        SERVICES_STEP_KEYS.PRICE_ON_REQUEST,
                        props.osbAppMoreContent
                    );
                return;
            }
            totalPriceWithDiscount = selectedServices
                .map((result: { priceAfterDiscount: any }) =>
                    Number(result.priceAfterDiscount)
                )
                .reduce((total: any, currentPrice: any) =>
                    currentPrice > 0 ? total + currentPrice : total
                )
                .toFixed(2);
        }
        totalPriceForAnalytics = totalPriceWithDiscount;
    };

    const getTotalDiscountedPrice = (selectedServices: any) => {
        totalDiscountPrice = selectedServices
            .map(
                (result: { price: any; priceAfterDiscount: any }) =>
                    result.price - result.priceAfterDiscount
            )
            .reduce((total: any, currentPrice: any) =>
                currentPrice > 0 ? total + currentPrice : total
            )
            .toFixed(2);
    };

    const getVehicleRecall = async (vin: string) => {
        if (vin !== '') {
            return await ServiceHandler.OsbVehicleRecallService.request(
                vin,
                osbStep.osbThreeLetterCountryCode
            );
        }
    };

    const vhaServiceDescription = (vehicleStepInfo: VehicleStep) => {
        const vehicleVHAInfo: OsbVhaAlertResponse | undefined =
            vehicleStepInfo.VhaResponse;

        const VHAData: string[] = [];
        let i = 0;
        if (vehicleVHAInfo?.activeAlerts !== undefined) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const vha of vehicleVHAInfo?.activeAlerts) {
                VHAData[i] = vha.headline;
                i++;
            }
            return VHAData;
        }
    };
    const oilLifeServiceDescription = (vehicleStepInfo: VehicleStep) => {
        const vehicleOilLifeInfo: PrognosticsDataV2 | undefined =
            vehicleStepInfo.oilLifeData;
        return vehicleOilLifeInfo?.dtsMessage?.para1 || '';
    };

    function getLocalDate(field: any) {
        if (field) {
            const tokens = field.split('T');
            return tokens[1];
        } else {
            console.log(field + 'does not exist on object ');
        }
    }

    const uploadedImages: AcceptedFileInfo[] = [];

    const createBookingDetailsInSession = async () => {
        const vehicleStepInfo = getInitialVehicleState();
        const bookingDetails = retrieveBookingStep.retrieveBookingDetails;
        const vehicleDetails = bookingDetails.bookings[0].vehicleDetails;
        setOSBStepPayload({ source: bookingDetails.bookings[0].source });
        const isMobileServiceSelected =
            bookingDetails.bookings[0].vehicleLocation?.locationType ===
            SERVICE_DELIVERY_TYPE.MOBILESERVICE
                ? true
                : false;
        const serviceLocationDetails =
            bookingDetails.bookings[0]?.vehicleLocation;
        let vehicleDetailsInfo = getInitialVehicleDetails();
        let vehicleRecallService = getInitialVehicleRecallService();
        vehicleDetailsInfo = {
            modelName: vehicleDetails.modelName,
            engine: vehicleDetails.engine,
            transmission: vehicleDetails.transmission,
            fuelType: vehicleDetails.fuelType,
            color: vehicleDetails.color,
            registrationNumber: vehicleDetails.registrationNumber,
            vin: vehicleDetails.vin,
            buildDate: vehicleDetails.buildDate,
        };
        if (serviceLocationDetails) {
            setOSBServiceLocationStepPayload({
                streetName: serviceLocationDetails.street,
                houseNumber: serviceLocationDetails.house,
                postalCode: serviceLocationDetails.postCode,
                town: serviceLocationDetails.city,
                parkingType: serviceLocationDetails.parking,
                comment: serviceLocationDetails.comment,
                street: serviceLocationDetails.street,
            });
            if (serviceLocationDetails.pickupDateTimeLocal) {
                const dt = serviceLocationDetails.pickupDateTimeLocal.substring(
                    0,
                    10
                );
                setOSBCalendarStepPayload({
                    selectedPickupDate:
                        dt.substring(0, 4) +
                        dt.substring(4, 8) +
                        dt.substring(8, 10),
                    selectedPickupTime: JSON.stringify(
                        serviceLocationDetails.pickupDateTimeLocal
                    ).substring(12, 17),
                });
            }
        }
        vehicleStepInfo.vehicleDetails = vehicleDetailsInfo;

        if (vehicleDetails.modelName === osbVehicleStep.defaultModelName) {
            vehicleStepInfo.vehicleStepFlow = 'manual';
            vehicleStepInfo.model = vehicleDetails.modelName;
            vehicleStepInfo.modelYear = vehicleDetails.buildDate.substring(
                0,
                4
            );
            vehicleStepInfo.manualMileage = vehicleDetails.mileage;
            vehicleStepInfo.defaultModelName = osbVehicleStep.defaultModelName;
            vehicleStepInfo.defaultBuildYear = osbVehicleStep.defaultBuildYear;
            vehicleStepInfo.defaultMileage = osbVehicleStep.defaultMileage;
            vehicleStepInfo.vinRegNo = vehicleDetails.vin;
        } else if (vehicleDetails.vin !== null || vehicleDetails.vin !== '') {
            await getVehicleRecall(vehicleDetails.vin).then(result => {
                if (result) {
                    vehicleRecallService = result;
                }
            });
            vehicleStepInfo.vehicleRecallService = vehicleRecallService;
            vehicleStepInfo.vehicleStepFlow = 'vin-flow';
            vehicleStepInfo.vinRegNo = vehicleDetails.vin;
            vehicleStepInfo.vinMileage = vehicleDetails.mileage;
        }
        setOSBVehicleStep(vehicleStepInfo, false);

        setOSBDealerStepPayload(
            {
                selectedDealerProfile: bookingDetails.bookings[0].dealer,
                selectedDealerName:
                    bookingDetails.bookings[0].dealer.dealerName,
                hasMobileService: hasMobileService(
                    bookingDetails.bookings[0].dealer.vehicleDeliveryTypes || []
                ),
            },
            false
        );
        let dealerServiceInfo = {} as DealerServicesArray;
        const vehicleData = getLightVehicleData(vehicleStepInfo);

        const bookable = getBookableFromBookingApiResponse(
            bookingDetails.bookings?.[0],
            vehicleStepInfo
        );

        await ServiceHandler.OsbDealerServices.getServicesList({
            dealerCode: bookingDetails.bookings[0].dealer.dealerCode,
            vehicleData: vehicleData,
            vouchers: [],
            bookingReferenceNumber:
                bookingDetails.bookings[0].bookingReferenceNumber,
            serviceDeliveryType: isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            requestSourceType: osbStep.source,
            bookable,
            DSLAPIVersion: osbStep.DSLAPIVersion,
        })
            .then(result => {
                setError('');
                dealerServiceInfo = result.value;
            })
            .catch((error: any) => {
                if (error?.errorMessage) {
                    setError(error.errorMessage);
                } else {
                    const errorMessage = osbStep.internalErrorMessage;
                    setError(errorMessage);
                }
            });

        const dt = bookingDetails.bookings[0].appointmentDateTimeLocal.substring(
            0,
            10
        );

        setOSBCalendarStepPayload({
            selectedDate:
                dt.substring(0, 4) + dt.substring(4, 8) + dt.substring(8, 10),
            selectedTime: isMobileServiceSelected
                ? ''
                : JSON.stringify(
                      bookingDetails.bookings[0].appointmentDateTimeLocal
                  ).substring(12, 17),
        });
        if (bookingDetails.bookings && bookingDetails.bookings.length > 0) {
            const customer = bookingDetails.bookings[0].customer;
            const vehicle = bookingDetails.bookings[0].vehicleDetails;

            if (customer) {
                const customerDetails: PersonalDetail = {
                    title: customer?.title || '',
                    firstName: customer?.firstName || '',
                    lastName: customer?.lastName || '',
                    fordId: customer?.fordId || '',
                    contactNumber: customer?.contactNumber || '',
                    email: customer?.email || '',
                    registrationNumber:
                        vehicle && !vehicle?.vin && vehicle?.registrationNumber
                            ? vehicle.registrationNumber
                            : osbPersonalDetail?.registrationNumber || '',
                    isFirstConsentEnabled: false,
                    isSecondConsentEnabled: false,
                    countryDialCodeObject: {
                        countryDisplayName: '',
                        countryRegex: '',
                        countryIconPath: '',
                        countryDialCode: '',
                        countryCode: '',
                    },
                };
                setOSBPersonalDetail(customerDetails, false);
            }
        }
        comments = bookingDetails.bookings[0].comments;

        const servicesList: any[] = [];
        let otherServiceDesc = '';
        const deliveryServicesList: any[] = [];

        if (bookingDetails?.bookings[0]?.additionalServices?.length > 0) {
            const additionalServiceList =
                bookingDetails.bookings[0].additionalServices;
            additionalServiceList.forEach((additionalService: any) => {
                if (
                    dealerServiceInfo?.dealerServices?.additionalServices &&
                    dealerServiceInfo?.dealerServices?.additionalServices
                        ?.length > 0
                ) {
                    dealerServiceInfo.dealerServices.additionalServices.forEach(
                        (dealerSerInfo: ServiceInfo) => {
                            if (
                                additionalService.serviceId ===
                                    dealerSerInfo.serviceId &&
                                (dealerSerInfo?.additionalInfo?.serviceType?.toUpperCase() ===
                                    OSB_SERVICE_TYPE.DROPOFF.toUpperCase() ||
                                    dealerSerInfo?.additionalInfo?.serviceType?.toUpperCase() ===
                                        OSB_SERVICE_TYPE.DELIVERY.toUpperCase())
                            ) {
                                additionalService.serviceCode = additionalService.serviceId.toString();
                                additionalService.serviceType =
                                    OSB_SERVICE_TYPE.DELIVERY;
                                additionalService.type = dealerSerInfo.type;
                                additionalService.subType =
                                    dealerSerInfo?.additionalInfo?.subType ||
                                    dealerSerInfo?.subType ||
                                    '';
                                additionalService.serviceUniqueId =
                                    dealerSerInfo.serviceId +
                                    (dealerSerInfo?.additionalInfo?.subType ||
                                        dealerSerInfo.subType ||
                                        '');
                                if (
                                    dealerSerInfo?.additionalInfo?.serviceType?.toUpperCase() ===
                                    OSB_SERVICE_TYPE.DROPOFF.toUpperCase()
                                ) {
                                    additionalService.serviceOption =
                                        DELIVERY_STEPS_KEYS.DROP_OFF_DELIVERY_OPTION_CARD;
                                    additionalService.serviceName =
                                        dealerSerInfo?.name;
                                    additionalService.summaryServiceName =
                                        additionalService?.name;
                                } else {
                                    additionalService.serviceName =
                                        additionalService.name;
                                    additionalService.summaryServiceName =
                                        additionalService.name;
                                }
                                additionalService.brightCoveAccountId =
                                    dealerSerInfo.additionalInfo?.brightCoveAccountId;
                                additionalService.brightCoveVideoId =
                                    dealerSerInfo.additionalInfo?.brightCoveVideoId;
                                additionalService.brightCovePlayerId =
                                    dealerSerInfo.additionalInfo?.brightCovePlayerId;
                                additionalService.brightCoveVideoName =
                                    dealerSerInfo.additionalInfo?.brightCoveVideoName;
                                additionalService.convenienceOptionTypes =
                                    dealerSerInfo.convenienceOptionTypes || '';
                                deliveryServicesList.push(additionalService);
                            } else if (
                                additionalService.serviceId ===
                                dealerSerInfo.serviceId
                            ) {
                                additionalService.serviceCode = additionalService.serviceId.toString();
                                additionalService.serviceName =
                                    additionalService.name;
                                additionalService.serviceType =
                                    OSB_SERVICE_TYPE.EXTRA_SERVICES;
                                additionalService.type = dealerSerInfo.type;
                                additionalService.subType =
                                    dealerSerInfo.additionalInfo?.subType ||
                                    dealerSerInfo.subType ||
                                    '';
                                additionalService.serviceUniqueId =
                                    dealerSerInfo.serviceId +
                                    (dealerSerInfo.additionalInfo?.subType ||
                                        dealerSerInfo.subType ||
                                        '');
                                additionalService.brightCoveAccountId =
                                    dealerSerInfo.additionalInfo
                                        ?.brightCoveAccountId || '';
                                additionalService.brightCoveVideoId =
                                    dealerSerInfo.additionalInfo
                                        ?.brightCoveVideoId || '';
                                additionalService.brightCovePlayerId =
                                    dealerSerInfo.additionalInfo
                                        ?.brightCovePlayerId || '';
                                additionalService.brightCoveVideoName =
                                    dealerSerInfo.additionalInfo
                                        ?.brightCoveVideoName || '';
                                additionalService.convenienceOptionTypes =
                                    dealerSerInfo.convenienceOptionTypes || '';

                                servicesList.push(additionalService);
                            }

                            if (
                                additionalService.serviceId ===
                                    dealerSerInfo.serviceId &&
                                additionalService.subType ===
                                    SERVICE_FLOW.OTHER_CUSTOMSERVICE
                            ) {
                                otherServiceDesc = additionalService.comment;
                            }
                        }
                    );
                }
            });
        }

        if (bookingDetails?.bookings[0]?.oldServices?.length > 0) {
            const oldServicesList = bookingDetails.bookings[0].oldServices;
            oldServicesList.forEach((oldServices: any) => {
                oldServices.serviceCode = oldServices.serviceId.toString();
                oldServices.serviceName = oldServices.name;
                oldServices.serviceType =
                    oldServices.additionalInfo?.serviceType;
                oldServices.subType =
                    oldServices.additionalInfo?.subType ||
                    oldServices.subType ||
                    '';
                if (!oldServices.serviceType) {
                    if (
                        oldServices.subType === SERVICE_FLOW.SERVICE_SUBTYPE_MOT
                    ) {
                        oldServices.type = SERVICE_GROUP_TYPE.MOT;
                        oldServices.serviceType =
                            OSB_SERVICE_TYPE.EXTRA_SERVICES;
                    } else if (
                        oldServices.subType ===
                        SERVICE_FLOW.SUBTYPE_VALUE_SERVICE
                    ) {
                        oldServices.type = SERVICE_GROUP_TYPE.VALUE;
                        oldServices.serviceType = OSB_SERVICE_TYPE.SERVICES;
                    } else if (
                        oldServices.subType ===
                        SERVICE_FLOW.SERVICE_SUBTYPE_REPAIR
                    ) {
                        oldServices.type = SERVICE_GROUP_TYPE.REPAIR;
                        oldServices.serviceType =
                            OSB_SERVICE_TYPE.EXTRA_SERVICES;
                    }
                }

                oldServices.serviceUniqueId =
                    oldServices.serviceId +
                    (oldServices.additionalInfo?.subType ||
                        oldServices.subType ||
                        '');
                if (
                    dealerServiceInfo?.dealerServices?.oldServices &&
                    dealerServiceInfo?.dealerServices?.oldServices?.length > 0
                ) {
                    const serviceSelected = dealerServiceInfo.dealerServices.oldServices.find(
                        service =>
                            service.serviceId === oldServices.serviceId &&
                            (service?.subType ||
                                service?.additionalInfo?.subType) ===
                                oldServices.subType
                    );
                    if (serviceSelected) {
                        oldServices.type = serviceSelected.type;
                        oldServices.brightCoveAccountId =
                            serviceSelected.additionalInfo
                                ?.brightCoveAccountId || '';
                        oldServices.brightCoveVideoId =
                            serviceSelected.additionalInfo?.brightCoveVideoId ||
                            '';
                        oldServices.brightCovePlayerId =
                            serviceSelected.additionalInfo
                                ?.brightCovePlayerId || '';
                        oldServices.brightCoveVideoName =
                            serviceSelected.additionalInfo
                                ?.brightCoveVideoName || '';
                    }
                }
                servicesList.push(oldServices);
            });
        }

        if (bookingDetails?.bookings[0]?.mainService !== null) {
            const mainServiceId =
                bookingDetails.bookings[0].mainService.serviceId;
            bookingDetails.bookings[0].mainService.serviceCode = mainServiceId.toString();
            bookingDetails.bookings[0].mainService.serviceName =
                bookingDetails.bookings[0].mainService.name;
            bookingDetails.bookings[0].mainService.serviceType =
                OSB_SERVICE_TYPE.SERVICES;
            bookingDetails.bookings[0].mainService.type =
                SERVICE_GROUP_TYPE.MAIN;
            const mainServiceSubType =
                bookingDetails.bookings[0].mainService.additionalInfo
                    ?.subType ||
                bookingDetails.bookings[0].mainService?.subType ||
                '';

            bookingDetails.bookings[0].mainService.serviceUniqueId =
                mainServiceId + mainServiceSubType;

            if (
                dealerServiceInfo?.dealerServices?.mainServices &&
                dealerServiceInfo?.dealerServices?.mainServices?.length > 0
            ) {
                const serviceSelected = dealerServiceInfo.dealerServices.mainServices.find(
                    service =>
                        service.serviceId === mainServiceId &&
                        (service?.subType ||
                            service?.additionalInfo?.subType) ===
                            mainServiceSubType
                );
                if (serviceSelected) {
                    bookingDetails.bookings[0].mainService.brightCoveAccountId =
                        serviceSelected.additionalInfo?.brightCoveAccountId ||
                        '';
                    bookingDetails.bookings[0].mainService.brightCoveVideoId =
                        serviceSelected.additionalInfo?.brightCoveVideoId || '';
                    bookingDetails.bookings[0].mainService.brightCovePlayerId =
                        serviceSelected.additionalInfo?.brightCovePlayerId ||
                        '';
                }
            }
            if (isMobileServiceSelected) {
                if (
                    bookingDetails.bookings[0].mainService.serviceId.toString() !==
                        SERVICE_FLOW.GA_SERVICE_ID &&
                    bookingDetails.bookings[0].mainService.serviceId.toString() !==
                        '0'
                ) {
                    servicesList.push(bookingDetails.bookings[0].mainService);
                }
            } else {
                if (
                    bookingDetails.bookings[0].mainService.serviceId.toString() ===
                        SERVICE_FLOW.GA_SERVICE_ID &&
                    servicesList.length === 0
                ) {
                    bookingDetails.bookings[0].mainService.serviceOption =
                        SERVICE_FLOW.CARD_TYPE_GENERAL_APPOINTMENT_SERVICE;
                    servicesList.push(bookingDetails.bookings[0].mainService);
                }
                if (
                    bookingDetails.bookings[0].mainService.serviceId.toString() !==
                        SERVICE_FLOW.GA_SERVICE_ID &&
                    bookingDetails.bookings[0].mainService.serviceId.toString() !==
                        '0'
                ) {
                    servicesList.push(bookingDetails.bookings[0].mainService);
                }
            }
        }

        getTotalPrice([...servicesList, ...deliveryServicesList]);
        const voucherCodeInfo = getVoucherDetailsFromStore(
            bookingDetails.bookings[0].campaignStates
        );
        if (voucherCodeInfo.voucherKey) {
            if (servicesList.length > 0) {
                getTotalDiscountedPrice([
                    ...servicesList,
                    ...deliveryServicesList,
                ]);
            }
            getTotalPriceWithDiscount([
                ...servicesList,
                ...deliveryServicesList,
            ]);
        }

        const serviceDetails: ServiceStep = {
            voucherCode: voucherCodeInfo.voucherKey || '',
            voucherDesc: voucherCodeInfo.voucherDescription || '',
            totalPrice: totalPrice,
            totalDiscountPrice: totalDiscountPrice,
            totalPriceForAnalytics: totalPriceForAnalytics,
            comment: comments,
            otherServiceDesc: otherServiceDesc,
            recallServiceDescription: getRecallServiceDescription(
                vehicleStepInfo.vehicleRecallService
            ),
            vhaServiceDescription: vhaServiceDescription(vehicleStepInfo),
            oilLifeServiceReminderDescription: oilLifeServiceDescription(
                vehicleStepInfo
            ),
            selectedServices:
                OsbUtilService.sortServicesByRank(servicesList) || [],
            dealerServiceInfo: dealerServiceInfo,
            userVehicles: [],
            isMobileServiceSelected: isMobileServiceSelected,
        };
        setOSBServiceStep(serviceDetails, false);

        if (isMobileServiceSelected) {
            const savedDate = bookingDetails.bookings[0].appointmentDateTimeLocal.substring(
                0,
                10
            );
            setOSBCalendarStepPayload({
                selectedDate:
                    savedDate.substring(0, 4) +
                    savedDate.substring(4, 8) +
                    savedDate.substring(8, 10),
                selectedTimeRange: getLocalDate(
                    bookingDetails.bookings[0]
                        ?.mobileServiceVanAppointmentTimeRange
                ),
            });
        }
        setOSBImageServiceStep({ uploadedImage: uploadedImages });

        const deliveryOptionDettails: LightDeliveryServiceStep = {
            selectedServices:
                OsbUtilService.sortServicesByRank(deliveryServicesList) || [],
        };
        setOSBLightDeliveryServiceStep(deliveryOptionDettails, false);
        if (isMobileServiceSelected) {
            setOSBServiceLocationStepPayload({
                selectedOtherOptions:
                    OsbUtilService.sortServicesByRank(deliveryServicesList) ||
                    [],
            });
            manageOsbStepVisibility(
                STEP_PROGRESS_BAR_KEYS.LOCATION,
                true,
                STEP_PROGRESS_BAR_KEYS.SERVICE,
                STEP_PROGRESS_BAR_KEYS.DELIVERY
            );
        }
    };

    const history = useHistory();
    const submitRetrieveBooking = async () => {
        if (emailAdd?.trim() !== '' && accessCode?.trim() !== '') {
            triggerBookServiceGlobalCTAOnClickAnalytics(
                LIGHT_JOURNEY_ANALYTICS.RETRIEVE_BOOKING_BTN_CTA_NAME,
                fireEvents,
                osbVehicleStep
            );
            setLoading(true);
            const bookingAuthorization: BookingAuthorization = {
                identifier: emailAdd,
                accessCode: accessCode,
            };
            await ServiceHandler.OSBRetrieveBooking.retrieveServiceBooking(
                bookingAuthorization
            )
                .then(async results => {
                    setError('');
                    results.value.bookings[0].accesscode = accessCode;
                    retrieveBookingStep.retrieveBookingDetails = results.value;
                    retrieveBookingStep.isRetrieveBookingStep = true;
                    setOSBRetrieveBookingStep(retrieveBookingStep);
                    if (osbStep.osbEnableImage) {
                        await ServiceHandler.OsbImageUploadService.getOsbBookigImages(
                            emailAdd.toLowerCase(),
                            accessCode,
                            results.value.bookings[0].bookingReferenceNumber
                        ).then(async imageResult => {
                            await Promise.all(
                                imageResult.map((uploadedImage: any) => {
                                    return ServiceHandler.OsbImageUploadService.getOsbBookigImage(
                                        accessCode,
                                        emailAdd.toLowerCase(),
                                        results.value.bookings[0]
                                            .bookingReferenceNumber,
                                        uploadedImage.fileId
                                    )
                                        .then(result => {
                                            const uploadedImagesData: AcceptedFileInfo = {
                                                encodedFileContent:
                                                    'data:' +
                                                    result.mimeType +
                                                    ';base64,' +
                                                    result.encodedFileContent,
                                                fileName:
                                                    uploadedImage.fileName,
                                                fileSize:
                                                    uploadedImage.fileSize,
                                                fileType: 'EXISTING',
                                            };
                                            uploadedImages.push(
                                                uploadedImagesData
                                            );
                                        })
                                        .catch();
                                })
                            );
                        });
                    }
                    removeAllClientStorage();

                    const campaignStates =
                        retrieveBookingStep.retrieveBookingDetails.bookings[0]
                            .campaignStates;
                    if (Array.isArray(campaignStates)) {
                        setOSBCampaignStates({
                            campaignStates: [...campaignStates],
                        });
                    }

                    const bookingReferenceNumber =
                        retrieveBookingStep.retrieveBookingDetails.bookings[0]
                            .bookingReferenceNumber;

                    setOSBReviewDetail({
                        identifier: emailAdd,
                        accessCode,
                        bookingReferenceNumber,
                    });

                    await createBookingDetailsInSession();
                    setOSBStepPayload({ isRetrieveFlow: true });
                    setOSBStepProgressBarPayload({ isContentLoaded: false });
                    setLoading(false);
                    history.push(
                        buildNavigationUrl(
                            OsbPathBookingRetrieveSummaryStep(),
                            osbStep.UrlQueryParams
                        )
                    );
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (error?.errorCode) {
                        const errorCode = error.errorCode;
                        setError(errorCode);
                    } else {
                        setError(osbStep.internalErrorMessage);
                    }
                    triggerRetrieveBookingErrorAnalytics(fireEvents);
                });
        } else {
            if (emailAdd?.trim() === '' && accessCode?.trim() === '') {
                onEmailAddressChange('');
                onAccessCodeChange('');
            } else if (emailAdd?.trim() === '') {
                onEmailAddressChange('');
            } else if (accessCode?.trim() === '') {
                onAccessCodeChange('');
            }
        }
    };
    const retrieveAccessCodeSuccessMessage = history.location.state
        ? 'show-retrieve-access-code-msg'
        : 'hide-retrieve-access-code-msg';

    const gotoRetrieveAccessCode = () => {
        history.push(
            buildNavigationUrl(
                OsbPathBookingAccessCodeStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    return (
        <div className="osb-light-retrieve-booking-container">
            <div className="osb-retrieve-booking-view-title">
                {parse(
                    props.retrieveBookingContent?.elements
                        .find(
                            e =>
                                e.name ===
                                RETRIEVE_BOOKING_STEP_KEYS.RETRIEVE_BOOKING_TITLE
                        )
                        ?.value.toString() ?? ''
                )}
            </div>
            <div className="retrieve-booking-section">
                <div className={retrieveAccessCodeSuccessMessage}>
                    {(!error &&
                        props.retrieveBookingContent?.elements
                            .find(
                                e =>
                                    e.name ===
                                    RETRIEVE_BOOKING_STEP_KEYS.FORGOT_ACCESS_CODE
                            )
                            ?.value.toString()) ??
                        ''}
                </div>
                {loading && <OsbLoader />}
                {error &&
                    error ===
                        RETRIEVE_BOOKING_STEP_KEYS.OSB_NO_BOOKING_FOUND_ERROR && (
                        <div className="error">
                            {parse(
                                props.retrieveBookingContent?.elements
                                    .find(
                                        e =>
                                            e.name ===
                                            RETRIEVE_BOOKING_STEP_KEYS.BOOKING_NOT_FOUND
                                    )
                                    ?.value.toString() ?? ''
                            )}
                        </div>
                    )}
                <div className="email-container">
                    <div className="input-textbox-label">
                        {(emailAdd.length > 0 || isEmailLabelShow) && (
                            <div>
                                {parse(
                                    props.retrieveBookingContent?.elements
                                        .find(
                                            e =>
                                                e.name ===
                                                RETRIEVE_BOOKING_STEP_KEYS.EMAIL_ADDRESS_LABEL
                                        )
                                        ?.value.toString() ?? ''
                                )}
                            </div>
                        )}
                        {emailError && (
                            <img
                                className="osb-error-icon"
                                id="retrieve-booking-email-error-icon"
                                src={errorWarning}
                            />
                        )}
                    </div>
                    <input
                        aria-errormessage="retrieve-booking-email-error-icon"
                        id="emailAddress"
                        data-testid="EnterBookingEmailAddress"
                        type="text"
                        className={`input-textbox ${
                            emailError ? 'input-textbox-error' : ''
                        }`}
                        placeholder={props.retrieveBookingContent?.elements
                            .find(
                                e =>
                                    e.name ===
                                    RETRIEVE_BOOKING_STEP_KEYS.EMAIL_ADDRESS_LABEL
                            )
                            ?.value.toString()}
                        aria-label="emailAddress"
                        aria-labelledby="emailAddress"
                        name="emailAddress"
                        value={emailAdd}
                        onChange={e => onEmailAddressChange(e.target.value)}
                        onBlur={emailLabelHide}
                        onFocus={emailLabelShow}
                    />
                    {emailError && (
                        <div className="input-textbox-error-message">
                            {emailError}
                        </div>
                    )}
                </div>
                <div className="access-code-container">
                    <div className="input-textbox-label">
                        {(accessCode.length > 0 || isAccessCodeLabelShow) && (
                            <div>
                                {parse(
                                    props.retrieveBookingContent?.elements
                                        .find(
                                            e =>
                                                e.name ===
                                                RETRIEVE_BOOKING_STEP_KEYS.ACCESS_CODE_LABEL
                                        )
                                        ?.value.toString() ?? ''
                                )}
                            </div>
                        )}
                        {accessCodeError && (
                            <img
                                className="osb-error-icon"
                                src={errorWarning}
                            />
                        )}
                    </div>
                    <input
                        id="accessCode"
                        data-testid="EnterBookingAccessCode"
                        type="text"
                        className={`input-textbox ${
                            accessCodeError ? 'input-textbox-error' : ''
                        }`}
                        placeholder={props.retrieveBookingContent?.elements
                            .find(
                                e =>
                                    e.name ===
                                    RETRIEVE_BOOKING_STEP_KEYS.ACCESS_CODE_LABEL
                            )
                            ?.value.toString()}
                        aria-label="accessCode"
                        aria-labelledby="accessCode"
                        name="accessCode"
                        value={accessCode}
                        onChange={e => onAccessCodeChange(e.target.value)}
                        onBlur={accessCodeLabelHide}
                        onFocus={accessCodeLabelShow}
                    />
                    {accessCodeError && (
                        <div className="input-textbox-error-message">
                            {' '}
                            {accessCodeError}{' '}
                        </div>
                    )}
                </div>
                <div
                    data-testid="ForgottenAccessCodeLink"
                    className="forgotten-access-code-link"
                    onClick={gotoRetrieveAccessCode}
                >
                    {parse(
                        props.retrieveBookingContent?.elements
                            .find(
                                e =>
                                    e.name ===
                                    RETRIEVE_BOOKING_STEP_KEYS.FORGOT_ACCESS_CODE_LINK
                            )
                            ?.value.toString() ?? ''
                    )}
                </div>
                <div className="retrieve-booking-button">
                    <FMButton
                        type="primary"
                        label={parse(
                            props.retrieveBookingContent?.elements
                                .find(
                                    e =>
                                        e.name ===
                                        RETRIEVE_BOOKING_STEP_KEYS.RETRIEVE_BOOKING_BUTTON
                                )
                                ?.value.toString() ?? ''
                        )}
                        onClick={submitRetrieveBooking}
                        data-testid="submitRetrieveBookingButton"
                    />
                </div>
            </div>
        </div>
    );
};
