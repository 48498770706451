import { FdsChevron } from '../../chevron/fds-chevron';
import React, { CSSProperties, ReactNode, Ref } from 'react';

export type Direction = 'up' | 'down' | 'left' | 'right';

interface Props {
    dataTestId?: string;
    color: string;
    fill: string;
    disabled?: boolean;
    className?: string;
    onClick?: (event: any) => void;
    onKeyDown?: (event: any) => void;
    onMouseDown?: (event: any) => void;
    style?: CSSProperties;
    ariaLabel?: string;
    role?: string;
    type?: any;
    ref?: Ref<HTMLButtonElement>;
    children?: ReactNode;
    chevron?: boolean;
    chevronFill?: boolean;
    chevronDirection?: Direction;
    newTabAriaLabel?: string;
}

const primaryButtonChevron = (props: Props) => {
    return (
        <button
            data-testid={props.dataTestId || ''}
            className={[
                'primary-button',
                `color-${props.color}`,
                `color-${props.fill}`,
                `${props.disabled ? 'disabled' : ''}`,
                `${props.className ? props.className : ''}`,
            ]
                .join(' ')
                .trim()}
            onClick={props.onClick}
            onKeyDown={props.onKeyDown}
            onMouseDown={props.onMouseDown}
            style={props.style}
            disabled={props.disabled}
            aria-label={props.ariaLabel}
            role={props.role}
            type={props.type}
            ref={props.ref}
        >
            <span
                className={[
                    'button-label',
                    `${props.chevron ? '' : 'no-chevron'}`,
                ]
                    .join(' ')
                    .trim()}
            >
                {props.children}
            </span>
            {props.newTabAriaLabel && (
                <>
                    <span>&nbsp;</span>
                    <span className="screen-reader-only">
                        {props.newTabAriaLabel}
                    </span>
                </>
            )}
            {props.chevron && (
                <FdsChevron
                    type={props.chevronFill ? 'filled' : 'unfilled'}
                    direction={props.chevronDirection || 'right'}
                />
            )}
        </button>
    );
};

export default primaryButtonChevron;
