import { CardState } from '../../../../../models/service-maintenance-models/vehicle-health-alerts';
import './vha-alert-list.lincoln.scss';
import { RecallsAlert } from './recalls/recalls';
import SyncAndUpdates from './sync-updates/vha-sync-updates';
import { OilLifeAlert } from './oillife/oillife';
import { BookAServiceCTA } from '../book-a-service/book-a-service';
import { isMobileView } from '../../vha-utils';
import { ServiceReminders } from './service-reminders/service-reminders';
import { useEffect, useState } from 'react';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import { replacePlaceholderByValue } from '../../../../utils/placeholder-util/replace-placeholder-by-value';
import { PrimaryButton } from '../../../../common';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { DiagnosticAlertCards } from './diagnostic-alerts/diagnostic-alerts';
import downloadCopyIcon from '../../../../../assets/download-icon-solid.svg';
import { SlowTireLeak } from './slow-tire-leak/slow-tire-leak';
import { ScheduleReport } from '../../../vehicle-health-report/schedule-report/schedule-report';
import { getVin } from '../../../../utils/getVin-util/getVin-util';
import {
    getStatusTextStyle,
    getVehicleCondition,
    getVehicleHealthDescription,
} from './vha-alert-list.utils';

interface Props {
    content: any;
    vehicleAttributes: VehicleAttributes;
}

export const VHAAlertList = (props: Props) => {
    const {
        vehicleHealthTitle,
        downloadButtonCTALabel,
        enableDownloadCopy,
        scheduleReportCTALabel,
        enableScheduleReport,
        is12HourClock,
    } = props.content;
    const [alertStatusList, setAlertStatusList] = useState<string[]>([]);
    const [alertState, setAlertState] = useState('');
    const [showAlertText, setShowAlertText] = useState<boolean>(false);
    const selectedVin = getVin();

    useEffect(() => {
        // To decide the consolidated status of VHA List
        if (alertStatusList.indexOf(CardState.ALERT) > -1) {
            setAlertState(CardState.ALERT);
            setShowAlertText(true);
            // return;
        } else if (alertStatusList.indexOf(CardState.WARNING) > -1) {
            setAlertState(CardState.WARNING);
            setShowAlertText(true);
            // return;
        } else {
            setAlertState(CardState.NORMAL);
            setShowAlertText(true);
            // return;
        }
    }, [alertStatusList]);

    const getVehicleAlertStatus = (alertType: string) => {
        // Consolidating the status from each VHA card
        setAlertStatusList(alertStatusList => [...alertStatusList, alertType]);
    };
    const getLastUpdatedDate12Hour = () => {
        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date()
            .toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            })
            .split(' ');

        return replacePlaceholderByValue(props.content.lastUpdatedLabel, {
            time: currentTime[0],
            timeUnit: currentTime[1],
            date: currentDate,
        });
    };

    const getLastUpdatedDate24Hour = () => {
        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });

        return replacePlaceholderByValue(props.content.lastUpdatedLabel, {
            time: currentTime,
            timeUnit: '',
            date: currentDate,
        });
    };

    const goToBookAService = () => {
        if (serverSideService.isClientSide()) {
            window.location.href = props.content.vehicleHealthCTALink;
        }
    };

    const printVHAPage = () => {
        if (serverSideService.isClientSide()) {
            window.print();
        }
    };

    const downloadACopy = () => {
        return (
            <div className="vha-download-copy-wrapper" onClick={printVHAPage}>
                <img
                    src={downloadCopyIcon}
                    alt={downloadButtonCTALabel}
                    className="vha-download-copy-icon"
                    aria-label="vha-download-copy-icon"
                />
                <div className="vha-download-copy-label">
                    {downloadButtonCTALabel}
                </div>
            </div>
        );
    };

    const showScheduleReport = () => {
        return (
            scheduleReportCTALabel && (
                <ScheduleReport
                    vinNumber={selectedVin}
                    scheduleReportLabel={scheduleReportCTALabel}
                    aem_category="vehicles"
                    aem_name="vehicle-health-alerts/schedule-report"
                />
            )
        );
    };

    return (
        <>
            <div
                className="alert-list-wrapper"
                data-testid="testAlertListWrapper"
            >
                <div
                    className="alert-list-header"
                    data-testid="testAlertListHeader"
                >
                    {vehicleHealthTitle}
                </div>
                <div
                    className={`status-text ${getStatusTextStyle(alertState)}`}
                    data-testid="testStatusText"
                    dangerouslySetInnerHTML={{
                        __html: showAlertText
                            ? getVehicleCondition(
                                  alertState,
                                  props.vehicleAttributes.model,
                                  props.content
                              )
                            : '',
                    }}
                ></div>
                <div
                    className="last-updated-text"
                    data-testid="testLastUpdatedText"
                >
                    {is12HourClock
                        ? getLastUpdatedDate12Hour()
                        : getLastUpdatedDate24Hour()}
                </div>
                <div
                    className="concerns-text"
                    data-testid="testConcernsText"
                    dangerouslySetInnerHTML={{
                        __html: showAlertText
                            ? getVehicleHealthDescription(
                                  alertState,
                                  alertStatusList,
                                  props.content
                              )
                            : '',
                    }}
                ></div>
                {!isMobileView() && (
                    <div className="vha-list-book-service">
                        <PrimaryButton
                            fill="fill"
                            color="dark"
                            chevron={false}
                            ariaLabel="book-a-service-cta"
                            onClick={goToBookAService}
                        >
                            {props.content.vehicleHealthCTALabel}
                        </PrimaryButton>
                        <div className="link-wrapper-desktop">
                            {enableScheduleReport && showScheduleReport()}
                            {enableDownloadCopy && downloadACopy()}
                        </div>
                    </div>
                )}
                <div className="link-wrapper-mobile">
                    {isMobileView() &&
                        enableScheduleReport &&
                        showScheduleReport()}
                    {isMobileView() && enableDownloadCopy && downloadACopy()}
                </div>
                <div className="cards-list">
                    {props.content.tireLeak.enableTireLeakAlerts && (
                        <SlowTireLeak
                            tireLeakContent={props.content.tireLeak}
                            handleVehicleStatus={getVehicleAlertStatus}
                        />
                    )}
                    {props.content.recall.enableRecallAlerts && (
                        <RecallsAlert
                            recallContent={props.content.recall}
                            handleVehicleStatus={getVehicleAlertStatus}
                        />
                    )}
                    {props.content.oilLife.enableOilLifeAlerts && (
                        <OilLifeAlert
                            oilLifeContent={props.content.oilLife}
                            handleVehicleStatus={getVehicleAlertStatus}
                        />
                    )}
                    {props.content.syncUpdates.enableSyncUpdatesAlerts && (
                        <SyncAndUpdates
                            syncUpdateContent={props.content.syncUpdates}
                            handleVehicleStatus={getVehicleAlertStatus}
                        />
                    )}
                    {props.content.serviceReminders
                        .enableServiceRemindersAlerts && (
                        <ServiceReminders
                            serviceRemindersContent={
                                props.content.serviceReminders
                            }
                            handleVehicleStatus={getVehicleAlertStatus}
                        />
                    )}
                    <DiagnosticAlertCards
                        aemContent={props.content}
                        handleVehicleStatus={getVehicleAlertStatus}
                    />
                </div>
            </div>
            {isMobileView() && (
                <BookAServiceCTA
                    label={props.content.vehicleHealthCTALabel}
                    redirectUrl={props.content.vehicleHealthCTALink}
                />
            )}
        </>
    );
};
