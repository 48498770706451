import { useEffect, useState } from 'react';
import { VehicleYmm } from '../models/vehicle-ymm';
import { VehicleListServiceAem } from '../services/vehicle-list-service-aem/vehicle-list-service-aem';

type MATCH_MODEL_WITH = 'PTS_MARKET_NAME' | 'DISPLAY_MODEL_NAME' | 'SEO_KEY';
export const useAemVehicleModelData = (
    brand: string,
    countryCode: string,
    languageRegionCode: string,
    year: number | string,
    toMatch: string,
    toMatchType: MATCH_MODEL_WITH
): VehicleYmm | undefined => {
    const [vehicleYmm, setVehicleYmm] = useState<VehicleYmm>();
    const service = new VehicleListServiceAem();

    function parsedYearToMatchType() {
        const parsedYear =
            typeof year === 'string' ? Number.parseInt(year) : year;
        service
            .getVehicles(brand, countryCode, languageRegionCode, true)
            .then(data => {
                if (data.length > 0) {
                    const yearData = data.find(d => d.year === parsedYear);
                    if (yearData) {
                        const vehicle = yearData.vehicles.find(vehicle => {
                            switch (toMatchType) {
                                case 'PTS_MARKET_NAME':
                                    return (
                                        vehicle.marketName.toLowerCase() ===
                                        toMatch.toLowerCase()
                                    );
                                case 'DISPLAY_MODEL_NAME':
                                    return vehicle.model === toMatch;
                                case 'SEO_KEY':
                                    return vehicle.seoKey === toMatch;
                                default:
                                    console.error(
                                        `useAemVehicleModelData: supplied predicate ${toMatch} doesn't match ${toMatchType} in vehicleYmm`,
                                        vehicleYmm
                                    );
                                    return false;
                            }
                        });
                        setVehicleYmm(vehicle);
                    }
                }
            });
    }

    useEffect(() => {
        if (
            !vehicleYmm &&
            brand &&
            countryCode &&
            languageRegionCode &&
            year &&
            toMatch &&
            toMatchType
        ) {
            parsedYearToMatchType();
        }
    }, [brand, countryCode, languageRegionCode, year, toMatch, toMatchType]);
    return vehicleYmm;
};

export const getAemVehicleModelData = async (
    brand: string,
    countryCode: string,
    languageRegionCode: string,
    year: number | string,
    toMatch: string,
    toMatchType: MATCH_MODEL_WITH
): Promise<VehicleYmm | undefined> => {
    const service = new VehicleListServiceAem();
    if (
        brand &&
        countryCode &&
        languageRegionCode &&
        year &&
        toMatch &&
        toMatchType
    ) {
        const parsedYear =
            typeof year === 'string' ? Number.parseInt(year) : year;
        const data = await service.getVehicles(
            brand,
            countryCode,
            languageRegionCode,
            true
        );
        if (data.length > 0) {
            const yearData = data.find(data => data.year === parsedYear);
            if (yearData) {
                const vehicle = yearData.vehicles.find(vehicle => {
                    switch (toMatchType) {
                        case 'PTS_MARKET_NAME':
                            return (
                                vehicle.marketName.toLowerCase() ===
                                toMatch.toLowerCase()
                            );
                        case 'DISPLAY_MODEL_NAME':
                            return vehicle.model === toMatch;
                        case 'SEO_KEY':
                            return vehicle.seoKey === toMatch;
                        default:
                            console.error(
                                `getAemVehicleModelData: supplied predicate ${toMatch} doesn't match ${toMatchType}`
                            );
                            return false;
                    }
                });
                return vehicle;
            }
        }
    }
    return Promise.resolve(undefined);
};

export default useAemVehicleModelData;
