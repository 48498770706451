import React from 'react';
import './contact-details-step-view.scss';
import ContactDetailsHandler from '../../../components/sections/osb-v3/contact-details-step/contact-details-handler/contact-details-handler';

export function ContactDetailsStepView() {
    return (
        <div className="osb-v3-contact-details-step-view-container">
            <ContactDetailsHandler />
        </div>
    );
}
