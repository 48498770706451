import { useContent } from '../../../hooks/use-server-content';

export interface DeviceAwareNaEuViewProps {
    naFallbackLink: string;
    naAndroidLink: string;
    naIosLink: string;
    naIsoCountryCodes: string[];
    euFallbackLink: string;
    euAndroidLink: string;
    euIosLink: string;
    euIsoCountryCodes: string[];
}
export const useDeviceAwareNaEuViewContent = (): DeviceAwareNaEuViewProps => {
    const [content, getValueByName] = useContent('common', 'device-aware-naeu');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as DeviceAwareNaEuViewProps;
};
