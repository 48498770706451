import { OSB_AEM_PROPERTY_KEYS } from '../../osb-constant';
import React from 'react';
interface Props {
    addressData: any;
    addressOrder: string;
    isDisplaySingleRow: boolean;
}
export function FormatAddress({
    addressData,
    addressOrder,
    isDisplaySingleRow,
}: Props): React.JSX.Element {
    const DEFAULT_ADDRESS_ORDER = OSB_AEM_PROPERTY_KEYS.OSB_ADDRESS_ORDER;
    addressOrder = addressOrder || DEFAULT_ADDRESS_ORDER;
    // ex. addressOrder - town,housenumber,city,postcode,streetname|postalcitywithspace|housestreetwithspace
    const orderArray = addressOrder.split(/[,|]/).map(field => field.trim());
    let cityPostalRow: string | undefined;
    let houseStreetRow: string | undefined;
    const result = orderArray
        .map(field => {
            if (
                field === 'postalcitywithspace' &&
                addressData['town'] &&
                addressData['postalCode']
            ) {
                // If addressOrder contains the string 'postalcitywithspace'
                // the function constructs a string cityPostalRow containing the town and postal code properties separated by a space.
                cityPostalRow = `${addressData['postalCode']} ${addressData['town']}`;
            } else if (
                field === 'citypostalwithspace' &&
                addressData['town'] &&
                addressData['postalCode']
            ) {
                // If addressOrder contains the string 'citypostalwithspace'
                // the function constructs a string cityPostalRow containing the postal code and the town properties separated by a space.
                cityPostalRow = `${addressData['town']} ${addressData['postalCode']}`;
            } else if (
                field === 'housestreetwithspace' &&
                addressData['houseNumber'] &&
                addressData['street']
            ) {
                // If addressOrder contains the string 'housestreetwithspace'
                // the function constructs a string houseStreetRow containing the house number and street properties separated by a space.
                houseStreetRow = `${addressData['houseNumber']} ${addressData['street']}`;
            } else if (
                field === 'streethousewithspace' &&
                addressData['houseNumber'] &&
                addressData['street']
            ) {
                // If addressOrder contains the string 'streethousewithspace'
                // the function constructs a string houseStreetRow containing the street and house number properties separated by a space.
                houseStreetRow = `${addressData['street']} ${addressData['houseNumber']}`;
            } else {
                // adds the property values from addressData to a result array if the value is not null or undefined.
                const value = addressData[field];
                if (value) {
                    return value;
                }
            }
        })
        .filter(field => field);
    if (cityPostalRow) {
        // removes the town and postalCode properties from result if cityPostalRow was constructed.
        result.splice(result.indexOf(addressData['town']), 1);
        result.splice(result.indexOf(addressData['postalCode']), 1);
    }
    if (houseStreetRow) {
        // removes the houseNumber and street properties from result if houseStreetRow was constructed.
        result.splice(result.indexOf(addressData['houseNumber']), 1);
        result.splice(result.indexOf(addressData['street']), 1);
    }
    let houseStreetLine: React.JSX.Element | undefined = undefined;
    let addressLine: React.JSX.Element | undefined = undefined;
    let cityPostalLine: React.JSX.Element | undefined = undefined;
    if (houseStreetRow) {
        // houseStreetRow variable has a value
        // isDisplaySingleRow variable is used to determine whether to add the houseNumber
        // and street in the same line (using a comma separator) or on separate lines (using an HTML <div> element).
        houseStreetLine = isDisplaySingleRow ? (
            <>{houseStreetRow},</>
        ) : (
            <div>{houseStreetRow}</div>
        );
    }
    if (isDisplaySingleRow) {
        // joins the result array with commas to create a single line address
        addressLine = <>{result.join(', ')}</>;
    } else {
        // otherwise, wraps each property value with a div tag
        const addressLineArray = result.map(value => {
            return <div key={value}>{value}</div>;
        });
        addressLine = <div>{addressLineArray}</div>;
    }
    if (cityPostalRow) {
        // cityPostalRow variable has a value
        // isDisplaySingleRow variable is used to determine whether to add the city
        // and postal code in the same line (using a comma separator) or on separate lines (using an HTML <div> element).
        cityPostalLine = isDisplaySingleRow ? (
            <>{`, ${cityPostalRow}`}</>
        ) : (
            <div>{cityPostalRow}</div>
        );
    }
    return (
        <>
            {houseStreetLine}
            {addressLine}
            {cityPostalLine}
        </>
    );
}
