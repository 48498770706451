import { useContent } from '../../../../hooks/use-server-content';

export interface FeatureContentBProps {
    hide: boolean;
    backgroundImage: string;
    backgroundImageMobile: string;
    backgroundImageAlt: string;
    title: string;
    subtitle: string;
    description: string;
    ctaLabel: string;
    ctaLink: string;
    ctaAriaLabel: string;
    ctaLinkTarget: string;
}
export const useFeatureContentB = (
    category: string,
    name: string,
    ymmServlet: boolean
): FeatureContentBProps | null => {
    const [content, getValueByName] = useContent(
        category,
        name,
        undefined,
        ymmServlet
    );
    let contentFragment: any = {};
    if (content) {
        content?.elements.forEach(element => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.title) {
            contentFragment = null;
        }
    }
    return contentFragment as FeatureContentBProps;
};
