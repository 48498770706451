import { BookingCredentials } from '../../../../models/osb-model/osb-booking-credentials';
import { ServiceBookingResponse } from '../../../../models/osb-model/osb-review-booking';

export const transformAmendBookingResponse = (
    serviceAPIResponse: ServiceBookingResponse
): BookingCredentials => {
    const bookingCredentials: BookingCredentials = {
        accessCode: serviceAPIResponse.accessCode,
        bookingReferenceNumber: serviceAPIResponse.bookingId,
    };

    return bookingCredentials;
};
