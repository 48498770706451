import { useContent } from '../../../../../hooks/use-server-content';

export interface SelectDealerContentProps {
    searchAgainButtonText: string;
    searchAgainButtonAriaLabel: string;
    errorMessage: string;
    filterText: string;
    filterAriaLabel: string;
    selectDealerButtonText: string;
    selectDealerButtonAriaLabel: string;
    showMoreText: string;
    showMoreAriaLabel: string;
    displayingText?: string;
    ofText?: string;
}
export const useSelectDealerContent = (): SelectDealerContentProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates/request-form-steps',
        'select-dealer'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (Object.entries(contentFragment).length === 0) {
        contentFragment = null;
    }
    return contentFragment as SelectDealerContentProps;
};
