import { AppEnvironmentConfiguration } from '../app-configuration';
export const appConfigurationLocal: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'Canada',
            countryCode: 'ca',
            threeLetterCountryCode: 'can',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-9c6de279ddb8-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'CYRL',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    enableDebugLogs: true,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ca',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    enableDebugLogs: true,
                    languageISOCode: 'FRA',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                    enableDebugLogs: true,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ca',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                    enableDebugLogs: true,
                    languageISOCode: 'FRA',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'CYRL',
                    languageRegionCode: 'en-ca',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 1,
                    enableDebugLogs: true,
                    languageISOCode: 'ENG',
                },
            ],
        },
        {
            name: 'Germany',
            countryCode: 'de',
            threeLetterCountryCode: 'deu',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-de',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    medalliaCommentCardId: 13855,
                    enableDebugLogs: true,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'German',
                    langScript: 'LATN',
                    languageRegionCode: 'de-de',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 2,
                    enableDebugLogs: true,
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryCode: 'gb',
            threeLetterCountryCode: 'gbr',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    medalliaCommentCardId: 13891,
                    enableDebugLogs: true,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 2,
                    enableDebugLogs: true,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 1,
                    enableDebugLogs: true,
                },
            ],
        },
        {
            name: 'Italy',
            countryCode: 'it',
            threeLetterCountryCode: 'ita',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Italian',
                    langScript: 'LATN',
                    languageRegionCode: 'it-it',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Spain',
            countryCode: 'es',
            threeLetterCountryCode: 'esp',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-es',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Nederland',
            countryCode: 'nl',
            threeLetterCountryCode: 'nld',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Dutch',
                    langScript: 'LATN',
                    languageRegionCode: 'nl-nl',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Norway',
            countryCode: 'no',
            threeLetterCountryCode: 'nor',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Norwegian',
                    langScript: 'LATN',
                    languageRegionCode: 'no-no',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'United States',
            countryCode: 'us',
            threeLetterCountryCode: 'usa',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'lincolndemo',
                    languageSetId: 3,
                    languageISOCode: 'ENG',
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 4,
                    languageISOCode: 'ENG',
                },
            ],
        },
        {
            name: 'France',
            countryCode: 'fr',
            threeLetterCountryCode: 'fra',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-fr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    medalliaCommentCardId: 13873,
                    enableDebugLogs: true,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-fr',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 2,
                    enableDebugLogs: true,
                },
            ],
        },
        {
            name: 'Thailand',
            countryCode: 'th',
            threeLetterCountryCode: 'tha',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Thai',
                    langScript: 'THAI',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    enableDebugLogs: true,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                    enableDebugLogs: true,
                },
            ],
        },
        {
            name: 'Mexico Ford',
            countryCode: 'mx',
            threeLetterCountryCode: 'mex',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na', // this needs to be revisited whether na or sa
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Mexico Lincoln',
            countryCode: 'mx',
            threeLetterCountryCode: 'mex',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/9399f7284f40/launch-b611207f24ae-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-mx',
                    blockSearchIndexing: true,
                    brand: 'lincolndemo',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Switzerland',
            countryCode: 'ch',
            threeLetterCountryCode: 'che',
            fmaUrl: 'https://www.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-8127180cdaa4.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Français',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-ch',
                    blockSearchIndexing: false,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Australia',
            countryCode: 'au',
            threeLetterCountryCode: 'aus',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Brazil',
            countryCode: 'br',
            threeLetterCountryCode: 'bra',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Portuguese',
                    langScript: 'LATN',
                    languageRegionCode: 'pt-br',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Argentina',
            countryCode: 'ar',
            threeLetterCountryCode: 'arg',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Espanol',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ar',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Portugal',
            countryCode: 'pt',
            threeLetterCountryCode: 'prt',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Portuguese',
                    langScript: 'LATN',
                    languageRegionCode: 'pt-pt',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Luxembourg',
            countryCode: 'lu',
            threeLetterCountryCode: 'lux',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'French',
                    langScript: 'LATN',
                    languageRegionCode: 'fr-lu',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Romania',
            countryCode: 'ro',
            threeLetterCountryCode: 'rou',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Romanian',
                    langScript: 'LATN',
                    languageRegionCode: 'ro-ro',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Peru',
            countryCode: 'pe',
            threeLetterCountryCode: 'per',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pe',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Venezuela',
            countryCode: 've',
            threeLetterCountryCode: 'ven',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ve',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Sweden',
            countryCode: 'se',
            threeLetterCountryCode: 'swe',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Swedish',
                    langScript: 'LATN',
                    languageRegionCode: 'sv-se',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Chile',
            countryCode: 'cl',
            threeLetterCountryCode: 'chl',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-cl',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Colombia',
            countryCode: 'co',
            threeLetterCountryCode: 'col',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-co',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'New Zealand',
            countryCode: 'nz',
            threeLetterCountryCode: 'nzl',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Puerto Rico Ford',
            countryCode: 'pr',
            threeLetterCountryCode: 'pri',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-pr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Puerto Rico Lincoln',
            countryCode: 'pr',
            threeLetterCountryCode: 'pri',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/9399f7284f40/launch-b611207f24ae-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pr',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                },
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-pr',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Antigua and Barbuda',
            countryCode: 'ag',
            threeLetterCountryCode: 'atg',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ag',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Aruba',
            countryCode: 'aw',
            threeLetterCountryCode: 'abw',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-aw',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Bahamas',
            countryCode: 'bs',
            threeLetterCountryCode: 'bhs',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bs',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Barbados',
            countryCode: 'bb',
            threeLetterCountryCode: 'brb',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bb',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Bermuda',
            countryCode: 'bm',
            threeLetterCountryCode: 'bmu',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-bm',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Cayman Islands',
            countryCode: 'ky',
            threeLetterCountryCode: 'cym',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ky',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Curacao',
            countryCode: 'cw',
            threeLetterCountryCode: 'cuw',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-cw',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Dominica',
            countryCode: 'dm',
            threeLetterCountryCode: 'dma',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-dm',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Dominican Republic',
            countryCode: 'do',
            threeLetterCountryCode: 'dom',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-do',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Grenada',
            countryCode: 'gd',
            threeLetterCountryCode: 'grd',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gd',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Haiti',
            countryCode: 'ht',
            threeLetterCountryCode: 'hti',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ht',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Jamaica',
            countryCode: 'jm',
            threeLetterCountryCode: 'jam',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-jm',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'St Lucia',
            countryCode: 'lc',
            threeLetterCountryCode: 'lca',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-lc',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'St Maarten',
            countryCode: 'mf',
            threeLetterCountryCode: 'maf',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-mf',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Suriname',
            countryCode: 'sr',
            threeLetterCountryCode: 'sur',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-sr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Trinidad and Tobago',
            countryCode: 'tt',
            threeLetterCountryCode: 'tto',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-tt',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'South Africa',
            countryCode: 'za',
            threeLetterCountryCode: 'zaf',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Belice',
            countryCode: 'bz',
            threeLetterCountryCode: 'blz',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-bz',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Costa Rica',
            countryCode: 'cr',
            threeLetterCountryCode: 'cri',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-cr',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'El Salvador',
            countryCode: 'sv',
            threeLetterCountryCode: 'slv',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-sv',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Guatemala',
            countryCode: 'gt',
            threeLetterCountryCode: 'gtm',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-gt',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Honduras',
            countryCode: 'hn',
            threeLetterCountryCode: 'hnd',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-hn',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Nicaragua',
            countryCode: 'ni',
            threeLetterCountryCode: 'nic',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-ni',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Panama',
            countryCode: 'pa',
            threeLetterCountryCode: 'pan',
            fmaUrl: '',
            fmaRegion: '',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Spanish',
                    langScript: 'LATN',
                    languageRegionCode: 'es-pa',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Vietnam',
            countryCode: 'vn',
            threeLetterCountryCode: 'vnm',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Vietnamese',
                    langScript: 'LATN',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Philippines',
            countryCode: 'ph',
            threeLetterCountryCode: 'phl',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
    ],
};
