import React from 'react';
import FMActionItem from '../../common/fm-action-item/fm-action-item';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { ActionItemProps } from '../../../models/experiencefragments/action-item';

interface Props {
    vehicleAttributes: VehicleAttributes;
}
const FMActionItemVHA = (props: Props & ActionItemProps) => {
    const {
        content,
        requiredLabel,
        recommendedLabel,
        vehicleAttributes,
    } = props;

    return (
        <FMActionItem
            required={true}
            content={{
                ...content,
                ctaPath: content.ctaPath
                    ?.replace('%vin', vehicleAttributes?.vin ?? '')
                    ?.replace(':vin', vehicleAttributes?.vin ?? ''),
            }}
            requiredLabel={requiredLabel}
            recommendedLabel={recommendedLabel}
        />
    );
};

export default FMActionItemVHA;
