import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Link } from '../../../common';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import { useExperienceContent } from '../../../../hooks/use-server-content';
import './eu-sidebar-menu.scss';
import {
    HeaderFragment,
    MainNavLeft,
} from '../../../../models/experiencefragments/header';

interface SidebarMenuProps {
    isOpen: boolean;
    className: string;
    toggleMenu: React.MouseEventHandler<HTMLElement>;
}

const EuSidebarMenu = (props: SidebarMenuProps) => {
    const [experienceHeaderContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );

    const [activeSubnav] = useState('');

    const [activeIndex, setActiveIndex] = useState('');
    const animationProps = useSpring({
        left: props.isOpen ? '0%' : '-100%',
    });

    function chooseClassForNavMenuButton(navToCheckAgainst: string) {
        if (activeSubnav === '') {
            return '';
        }

        return activeSubnav === navToCheckAgainst
            ? 'euselected-sidebar-menu-item'
            : 'euunselected-sidebar-menu-item';
    }

    const noCategories = (nav: MainNavLeft): boolean => {
        return !nav.categories && nav.noFlyout;
    };

    const hasCategories = (nav: MainNavLeft): boolean => {
        return nav.categories && !nav.noFlyout;
    };

    const renderCategories = (nav: MainNavLeft): any => {
        return (
            <ul className="eumain-nav-title">
                {nav.categories.map(subnav => {
                    return (
                        <li key={subnav.categoryTitle}>
                            <span>{subnav.categoryTitle}</span>

                            <ul>
                                {subnav.categoryItems.map(sideNav => {
                                    return (
                                        <li key={sideNav.title}>
                                            <Link
                                                target={
                                                    sideNav.targetBlank
                                                        ? '_blank'
                                                        : ''
                                                }
                                                href={sideNav.url}
                                                aria-label={sideNav.ariaLabel}
                                            >
                                                {sideNav.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderNavWithNoCategories = (
        nav: MainNavLeft,
        index: number
    ): any => {
        return (
            <ul key={index}>
                <li className={chooseClassForNavMenuButton(nav.title)}>
                    <Link
                        href={nav.url}
                        target={nav.targetBlank ? '_blank' : '_self'}
                        className="eusidebar-menu-item"
                        aria-label={nav.ariaLabel}
                    >
                        <span className="eusidebar-menu-item-text">
                            {nav.title}
                        </span>
                    </Link>
                </li>
            </ul>
        );
    };

    const renderNavWithCategories = (nav: MainNavLeft, index: number): any => {
        return (
            <ul aria-label={nav.title} key={index}>
                <li className={chooseClassForNavMenuButton(nav.title)}>
                    <button
                        onClick={() => {
                            setActiveIndex(
                                `${
                                    activeIndex === index.toString()
                                        ? ''
                                        : index
                                }`
                            );
                        }}
                        className="eusidebar-menu-item"
                        aria-label={nav.ariaLabel}
                    >
                        <span
                            className={`eusidebar-menu-item-text ${
                                activeIndex === index.toString()
                                    ? 'eusidebar-menu-item-text-active'
                                    : ''
                            } `}
                        >
                            {nav.title}
                        </span>
                        <FdsChevron
                            type="unfilled"
                            direction={
                                activeIndex === index.toString() ? 'up' : 'down'
                            }
                        />
                    </button>
                    <div
                        id={`subNav${index}`}
                        className={`
                                          ${
                                              activeIndex === index.toString()
                                                  ? 'show-nav'
                                                  : 'hide-me'
                                          }
                                          `}
                    >
                        {renderCategories(nav)}
                    </div>
                </li>
            </ul>
        );
    };

    return (
        experienceHeaderContent && (
            <animated.div
                id="sidebar-menu"
                className={`eusidebar-container ${props.className} ${
                    activeSubnav ? 'grey-border' : ''
                }`}
                data-testid="sidebar-menu"
                style={animationProps}
            >
                <div
                    className={`eusidebar-menu-container  ${
                        activeSubnav ? 'eusidebar-minimized' : ''
                    }`}
                >
                    <nav tabIndex={props.isOpen ? 0 : -1}>
                        {experienceHeaderContent['main-nav-left'].map(
                            (navsub, index) => {
                                if (noCategories(navsub)) {
                                    return renderNavWithNoCategories(
                                        navsub,
                                        index
                                    );
                                } else if (hasCategories(navsub)) {
                                    return renderNavWithCategories(
                                        navsub,
                                        index
                                    );
                                }
                            }
                        )}
                        <ul>
                            {experienceHeaderContent['main-nav-right']
                                .utilityLinks &&
                                experienceHeaderContent[
                                    'main-nav-right'
                                ].utilityLinks.map(navright => {
                                    return (
                                        <li
                                            key={`${navright.title}`}
                                            className="eusidebar-menu-item1"
                                        >
                                            <Link
                                                href={navright.url}
                                                rel="noopener noreferrer"
                                                aria-label={navright.ariaLabel}
                                            >
                                                {navright.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                        </ul>
                    </nav>
                </div>
            </animated.div>
        )
    );
};
export default EuSidebarMenu;
