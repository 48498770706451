import React from 'react';
import { QuietCard } from './quiet-card/quiet-card';
import { Articles } from '../../../../../models/experiencefragments/category-view';
import { SecondaryButton } from '../../../../../components/common';
import './category-popular-articles-trio.scss';

export const CategoryPopularArticlesTrio = (props: Articles) => {
    return (
        <article className="category-quiet-cards-trio">
            <div className="category-quiet-cards-heading">
                <h2 className="category-quiet-cards-trio__title">
                    {props.sectionHeading}
                </h2>
                {props.cta?.title && props.cta?.url && (
                    <SecondaryButton
                        linkTarget={props.cta?.targetBlank ? '_blank' : ''}
                        link={props.cta?.url}
                        noChevron={false}
                        aria-label={props.cta?.ariaLabel}
                        dataTestId="explore-all-link"
                    >
                        {props.cta?.title}
                    </SecondaryButton>
                )}

                <div className="category-quiet-cards-trio__subcopy">
                    {props.sectionSubCopy}
                </div>
            </div>
            <hr className="category-quiet-cards-trio__divider" />
            <section className="category-quiet-cards">
                {props.articles.map((article, index) => {
                    return (
                        <QuietCard
                            key={index}
                            {...article}
                            categoryType={props.categoryType}
                            categoryPath={props.categoryPath}
                        />
                    );
                })}
            </section>
        </article>
    );
};
