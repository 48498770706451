import React from 'react';
import PrimaryButton from '../../../common/primary-button/primary-button';
import './subcategory-component-box-5050.scss';
import { Article } from '../../../../models/experiencefragments/category-view';
import { Picture } from '../../../common/picture/picture';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../../services/new-analytics-service/new-analytics-service';
import { CategoryOnClickCtaInfo } from '../../../../services/shortcode-service/shortcode-service';

interface Props {
    content: Article;
    categoryPath?: string;
    eventName?: string;
}

export const SubcategoryComponentBox5050 = (props: Props) => {
    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
        ctaName: props.content.ctaLabel,
        categoryPath: props.categoryPath,
        ctaUrl: props.content.ctaPath,
    };
    const [fireEvents] = useAnalytics();
    const eventClick = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            props.content.ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (props?.eventName) {
            fireEvents(props.eventName, undefined, {
                categoryOnClickCtaInfo,
            });
        }
    };
    return (
        <section className="subcategory-component-box-5050">
            <div className="subcategory-component-box-5050__content-wrapper">
                <h2 className="subcategory-component-box-5050__subtitle">
                    {props.content.headline}
                </h2>
                <div className="subcategory-component-box-5050__body">
                    {props.content.description}
                </div>
                {props.content.ctaLabel && (
                    <PrimaryButton
                        chevron={false}
                        href={props.content.ctaPath}
                        color="dark"
                        fill="outline"
                        className="subcategory-component-box-5050__primary-button"
                        ariaLabel={props.content.ctaAriaLabel}
                        target={props.content.targetBlank ? '_blank' : ''}
                        onClick={() => eventClick()}
                    >
                        {props.content.ctaLabel}
                    </PrimaryButton>
                )}
            </div>
            <div className="subcategory-component-box-5050__image">
                <Picture
                    largeDesktopImagePath={props.content.desktopImagePath}
                    desktopImagePath={props.content.mobileImagePath}
                    tabletImagePath={props.content.desktopImagePath}
                    mobileImagePath={props.content.mobileImagePath}
                    imageAltText={props.content.imageAltText}
                />
            </div>
        </section>
    );
};
