import React from 'react';

export const PlusMinus = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <div className="plus-minus-icon-container">
            {isOpen ? (
                <img src="icons/minus-close.svg" className="open" alt="" />
            ) : (
                <img src="icons/plus-open.svg" className="close" alt="" />
            )}
        </div>
    );
};
