import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './light-booking-confirmation-lincoln.scss';
import {
    BOOKING_CONFIRMATION_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
} from '../../osb-constant';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../../../services/service-handler';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import {
    useDealerStep,
    useHttp,
    useViewport,
} from '../../../../../hooks/owners-osb';
import { FordAccount } from '../../ford-account/ford-account';
import { LightBookingDetails } from './light-booking-details';
import {
    triggerBookingCreateCompleteAnalytics,
    triggerPrintBookingAnalytics,
} from '../../analytics/osb-analytics';
import { OSBStep } from '../../../../../models/osb-model/osb-step';
import { VehicleStep } from '../../../../../models/osb-model/osb-vehicle-step';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { formatMobileNumber, getGoMainHeaderHeight } from '../../osb-utils';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import serverSideService from '../../../../../services/server-side-service/server-side-service';

interface Props {
    dealerName: string;
    dealerAddress: string;
    dealerPostalCode: string;
    selectedDate: string;
    selectedTime: string;
    bookingNumber: string;
    accessCode: string;
    isDealerDeepLinked: boolean;
    selectedServices: any[];
    osbStep: OSBStep;
    osbVehicleStep: VehicleStep;
}
export const LightBookingConfirmation = (props: Props) => {
    const { httpState, dispatch } = useHttp();
    const { osbDealerStep } = useDealerStep();
    const { isMobileView } = useViewport();
    const [fireEvents] = useAnalytics();
    const [
        bookingConfirmationStepContent,
        setBookingConfirmationStepContent,
    ] = useState<OsbContentResponse>();

    function getBookingConfirmationContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-booking-confirmation'
        )
            .then(results => {
                setBookingConfirmationStepContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
                console.log('unable to load content from AEM', error);
            });
    }

    const getDealerContact = () => {
        return osbDealerStep.selectedDealerProfile.primaryPhone;
    };

    useEffect(() => {
        getBookingConfirmationContent();
        if (props.osbStep.isRetrieveFlow) {
            triggerBookingCreateCompleteAnalytics(
                props.selectedServices,
                props.osbVehicleStep,
                props.osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT,
                props.bookingNumber
            );
        } else {
            triggerBookingCreateCompleteAnalytics(
                props.selectedServices,
                props.osbVehicleStep,
                props.osbStep,
                fireEvents,
                LIGHT_JOURNEY_ANALYTICS.NO_AMEND_FLOW_CONTENT,
                props.bookingNumber
            );
        }
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, []);

    const printDocument = () => {
        triggerPrintBookingAnalytics(props.osbVehicleStep, fireEvents);
        if (typeof window !== 'undefined') {
            window.print();
        }
    };

    const getBookingDetails = () => {
        return (
            <LightBookingDetails
                isLoading={httpState.isLoading}
                isDealerDeepLinked={props.isDealerDeepLinked}
                dealerName={props.dealerName}
                dealerAddress={props.dealerAddress}
                dealerPostalCode={props.dealerPostalCode}
                selectedDate={props.selectedDate}
                selectedTime={props.selectedTime}
                bookingNumber={props.bookingNumber}
                accessCode={props.accessCode}
                bookingConfirmationStepContent={bookingConfirmationStepContent}
                handlePrint={printDocument}
            />
        );
    };

    return (
        <div className="osb-light-booking-confirmation-outer-div">
            {httpState.isLoading ? (
                <OsbLoader osbEmptyContentHeight={true} />
            ) : (
                <>
                    {isMobileView && (
                        <div className="osb-booking-confirmatin-header-section">
                            <div className="bc-header">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            bookingConfirmationStepContent?.elements.find(
                                                e =>
                                                    e.name ===
                                                    BOOKING_CONFIRMATION_KEYS.BOOKING_CONFIRMATION_LABEL
                                            )?.value + ''.toString() ?? '',
                                    }}
                                />
                            </div>

                            <div className="bc-sub-header">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            bookingConfirmationStepContent?.elements.find(
                                                e =>
                                                    e.name ===
                                                    BOOKING_CONFIRMATION_KEYS.BOOKING_CONFIRMATION_SUBTITLE
                                            )?.value + ''.toString() ?? '',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="osb-light-booking-confirmation-container">
                        <div className="what-happens-next-container">
                            {!isMobileView && (
                                <div className="osb-booking-confirmatin-header-section">
                                    <div className="bc-header">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    bookingConfirmationStepContent?.elements.find(
                                                        e =>
                                                            e.name ===
                                                            BOOKING_CONFIRMATION_KEYS.BOOKING_CONFIRMATION_LABEL
                                                    )?.value + ''.toString() ??
                                                    '',
                                            }}
                                        />
                                    </div>

                                    <div className="bc-sub-header">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    bookingConfirmationStepContent?.elements.find(
                                                        e =>
                                                            e.name ===
                                                            BOOKING_CONFIRMATION_KEYS.BOOKING_CONFIRMATION_SUBTITLE
                                                    )?.value + ''.toString() ??
                                                    '',
                                            }}
                                        />
                                    </div>

                                    <div className="hr-line-style"></div>
                                </div>
                            )}

                            <div className="what-happens-next-section">
                                <div className="what-happens-next-title">
                                    {bookingConfirmationStepContent?.elements.find(
                                        e =>
                                            e.name ===
                                            BOOKING_CONFIRMATION_KEYS.WHAT_HAPPEN_LABEL
                                    )?.value + ''.toString()}
                                </div>

                                <div className="what-happens-next-desc">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                bookingConfirmationStepContent?.elements.find(
                                                    e =>
                                                        e.name ===
                                                        BOOKING_CONFIRMATION_KEYS.WHAT_HAPPEN_DESC_LABEL
                                                )?.value +
                                                    ''.toString().trim() ?? '',
                                        }}
                                    />
                                    {getDealerContact()?.length === 0 && (
                                        <span>
                                            {bookingConfirmationStepContent?.elements.find(
                                                e =>
                                                    e.name ===
                                                    BOOKING_CONFIRMATION_KEYS.CONTACT_DEALER_LINK
                                            )?.value + ''.toString()}
                                        </span>
                                    )}
                                    {getDealerContact()?.length > 0 && (
                                        <div className="call-dealer-popup">
                                            {!isMobileView && (
                                                <Popup
                                                    trigger={
                                                        <div className="link-style">
                                                            {bookingConfirmationStepContent?.elements.find(
                                                                e =>
                                                                    e.name ===
                                                                    BOOKING_CONFIRMATION_KEYS.CONTACT_DEALER_LINK
                                                            )?.value +
                                                                ''.toString()}
                                                        </div>
                                                    }
                                                    className="dealer-call-cta-popup"
                                                    position="right center"
                                                    modal={false}
                                                    arrow={true}
                                                    closeOnDocumentClick={true}
                                                >
                                                    {getDealerContact()}
                                                </Popup>
                                            )}
                                            {isMobileView && (
                                                <a
                                                    className="link-style"
                                                    href={`tel:+${formatMobileNumber(
                                                        getDealerContact()
                                                    )}`}
                                                    title={getDealerContact()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {bookingConfirmationStepContent?.elements.find(
                                                        e =>
                                                            e.name ===
                                                            BOOKING_CONFIRMATION_KEYS.CONTACT_DEALER_LINK
                                                    )?.value + ''.toString()}
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!props.osbStep.isWebViewJourney && (
                                <div className="manage-your-booking-section">
                                    <div className="manage-your-booking-title">
                                        {bookingConfirmationStepContent?.elements.find(
                                            e =>
                                                e.name ===
                                                BOOKING_CONFIRMATION_KEYS.MANAGE_YOUR_BOOKING
                                        )?.value + ''.toString()}
                                    </div>

                                    <div className="manage-your-booking-desc">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    bookingConfirmationStepContent?.elements.find(
                                                        e =>
                                                            e.name ===
                                                            BOOKING_CONFIRMATION_KEYS.MANAGE_BOOKING_DESC_LABEL
                                                    )?.value +
                                                        ':<b>' +
                                                        props.accessCode.toString() +
                                                        '</b>' ?? '',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {!props.osbStep.isAuthenticatedFlow && (
                                <FordAccount />
                            )}
                        </div>
                        {getBookingDetails()}
                    </div>
                </>
            )}
        </div>
    );
};
