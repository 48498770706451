import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useConfirmationContent } from '../../hooks/redeem-rebates-steps/use-confirmation-content';
import printIcon from '../../../../../assets/print-icon.svg';
import parse from 'html-react-parser';
import { PrimaryButton } from '../../../../common';
import {
    RebateConfirmationService,
    RebatesSubmitResponse,
} from '../../../../../services/rebate-confirmation-service/rebate-confirmation-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { ActivityIndicator } from '../../../../common/activity-indicator/activity-indicator';

import './rebates-confirmation.scss';

interface Props {
    redeemFormData: RedeemFormData;
    setCurrentStep(stepNumber: number): void;
}
interface RebateInfoProps {
    title: string;
    content: any;
}

const RebateInfo = (props: RebateInfoProps) => (
    <div className="confirm-rebatesRes-elements">
        <div className="confirm-rebate-status">{props.title}</div>
        <div className="confirm-rebate-response">{props.content}</div>
    </div>
);

const findRebateDetail = (
    offerRequests: any[],
    rebateOfferID: number,
    detailType: string
) => {
    const detail = offerRequests.find(
        item => item.rebateOfferID === rebateOfferID
    );
    if (!detail) {
        return '';
    } else if (detailType === 'status') {
        return detail.rebateStatus;
    } else {
        return detail.rebateTrackingNumber;
    }
};

const formatDealerInfo = (selectedDealerDetails: any) => {
    if (!selectedDealerDetails) return '';
    const {
        dealerName,
        streetAddress,
        city,
        state,
        dealerZipCode,
        phone,
    } = selectedDealerDetails;
    return `${dealerName}<br />${streetAddress}${
        !streetAddress.endsWith('.') ? '.' : ''
    } ${city}, ${state} ${dealerZipCode}<br />${phone}`;
};

export const RebatesConfirmation = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const rebatesStepName = {
        rebatesStepName: 'confirmation',
    };
    useAnalytics(['redeem-rebates-submit'], undefined, rebatesStepName);
    const confirmationContent = useConfirmationContent();
    const [printcard, setprintcard] = useState<ReactNode | null>();
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const selectedDate: any = props.redeemFormData.serviceDate;
    const dateFormatUS = new Date(selectedDate).toLocaleDateString('en-US');
    const [
        rebateSubmitServiceProviders,
        setRebateSubmitServiceProviders,
    ] = useState<RebatesSubmitResponse>();
    useEffect(() => {
        RebateConfirmationService(props.redeemFormData)
            .then(serviceResponse => {
                setRebateSubmitServiceProviders(serviceResponse);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const getCurrentDate = (separator = '/') => {
        const newDate = new Date();
        const date = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();

        const paddedMonth = `${month}`.padStart(2, '0');
        const paddedDate = `${date}`.padStart(2, '0');

        return `${paddedMonth}${separator}${paddedDate}${separator}${year}`;
    };
    const printPage = (index: any) => {
        const id = `rebates${index}`;

        const element = document.getElementById(id);
        const printcard: any = element?.innerHTML || 'error';
        const printcardParse: any = parse(printcard);
        setprintcard(printcardParse);
        setTimeout(() => {
            window.print();
        }, 10);
    };

    const handleDone = () => {
        props.setCurrentStep(1);
    };
    const shouldRender = confirmationContent && rebateSubmitServiceProviders;
    return shouldRender ? (
        <>
            <div className="confirm-rebates-offer-print">{printcard}</div>
            <div className="entire-confirm-rebate-status-response">
                <div className="confirm-rebate-status-response">
                    <h5 className="confirm-header-text">
                        {confirmationContent.title}
                    </h5>
                    {props.redeemFormData.rebateData &&
                        props.redeemFormData.rebateData.map(
                            (
                                list: {
                                    key: string;
                                    value: string;
                                    pdf: string;
                                    disclaimerFile: string;
                                    rebateOfferName: string;
                                    rebatePointsOrAmount: string;
                                    isPointsClicked: boolean;
                                    rebateOfferID: number;
                                },
                                index
                            ) => {
                                if (list.value !== '') {
                                    return (
                                        <>
                                            <hr />
                                            <div
                                                className="confirm-rebates-content"
                                                id={`rebates${index}`}
                                                key={list.rebateOfferName}
                                            >
                                                <div className="confirm-rebates-print">
                                                    <button
                                                        className="confirm-print-button"
                                                        aria-label="Click to print offer"
                                                        onClick={() => {
                                                            setprintcard(null);
                                                            printPage(index);
                                                            fireEvents(
                                                                'rr-submit-cta-onclick-event',
                                                                undefined,
                                                                undefined,
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src={printIcon}
                                                            alt=""
                                                            className="confirm-close-icon"
                                                        />
                                                    </button>
                                                </div>
                                                <h3 className="confirm-rewards-point">
                                                    {list.value}
                                                    {'**'}
                                                </h3>
                                                <div className="confirm-rebates-row">
                                                    <div className="confirm-rebates-elementals">
                                                        <RebateInfo
                                                            title={
                                                                confirmationContent.rebateStatusText
                                                            }
                                                            content={findRebateDetail(
                                                                rebateSubmitServiceProviders?.offerRequests,
                                                                list.rebateOfferID,
                                                                'status'
                                                            )}
                                                        />
                                                        <RebateInfo
                                                            title={
                                                                confirmationContent.dateOfServiceText
                                                            }
                                                            content={
                                                                dateFormatUS
                                                            }
                                                        />
                                                        <RebateInfo
                                                            title={
                                                                confirmationContent.vinText
                                                            }
                                                            content={
                                                                props
                                                                    .redeemFormData
                                                                    .vin
                                                            }
                                                        />
                                                        <RebateInfo
                                                            title={
                                                                confirmationContent.dealerText
                                                            }
                                                            content={formatDealerInfo(
                                                                props
                                                                    .redeemFormData
                                                                    .selectedDealerDetails
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="confirm-rebates-elementals">
                                                        <RebateInfo
                                                            title={
                                                                confirmationContent.submissionDateText
                                                            }
                                                            content={getCurrentDate()}
                                                        />
                                                        <RebateInfo
                                                            title={
                                                                confirmationContent.trackingText
                                                            }
                                                            content={findRebateDetail(
                                                                rebateSubmitServiceProviders?.offerRequests,
                                                                list.rebateOfferID,
                                                                'tracking'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                            }
                        )}
                    <div className="confirm-rebate-response-button">
                        <PrimaryButton
                            ref={primaryButtonRef}
                            chevron={true}
                            color="dark"
                            fill="fill"
                            onClick={handleDone}
                        >
                            {confirmationContent.doneButtonText}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <ActivityIndicator className="full-height" />
    );
};
