import { useExperienceContent } from '../../../hooks/use-server-content';
import { ContainerFragment } from '../../../models/service-maintenance-models/vehicle-health-alerts';

export const useExperienceFragmentContent = (
    category: string,
    name: string,
    componentName: string
): ContainerFragment => {
    const [containerContent] = useExperienceContent<ContainerFragment>(
        category,
        name,
        componentName
    );
    return containerContent as ContainerFragment;
};
