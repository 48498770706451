import React, { useEffect, useState } from 'react';
import serverSideService from '../../services/server-side-service/server-side-service';

interface Props {
    children: React.JSX.Element;
}

interface ContextProps {
    viewportWidth: number;
    viewportHeight: number;
}
const initialState: ContextProps = {
    viewportWidth: 0,
    viewportHeight: 0,
};

export const ViewportContext = React.createContext(initialState);

export const ViewportContextProvider = (props: Props): React.JSX.Element => {
    const [viewportWidth, setViewportWidth] = useState(
        initialState.viewportWidth
    );
    const [viewportHeight, setViewportHeight] = useState(
        initialState.viewportHeight
    );

    const handleViewport = () => {
        if (serverSideService.isClientSide()) {
            setViewportWidth(window.screen.width);
            setViewportHeight(window.screen.height);
        }
    };

    useEffect(() => {
        handleViewport();
        window.addEventListener('resize', handleViewport);
        return () => window.removeEventListener('resize', handleViewport);
    }, []);

    return (
        <ViewportContext.Provider value={{ viewportWidth, viewportHeight }}>
            {props.children}
        </ViewportContext.Provider>
    );
};
