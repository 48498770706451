import React, { useContext, useEffect } from 'react';
import { HeroTiles, ViewTemplate } from '../../components/sections';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { useErrorsContent } from './hooks/use-errors-content';
import { useHelpCardsContent } from './hooks/use-help-cards-content';
import './page-not-found-view.scss';
import { useAppInformationContent } from '../../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import SearchBarInput from '../../components/sections/search-bar/search-bar-input/search-bar-input';
import {
    SearchContentProps,
    useSearchContent,
} from '../../components/sections/search-bar/hooks/use-search-content';
import ServerContext from '../../contexts/serverContext';

interface PageNotFoundTopSectionProps {
    title: string;
    body: string;
    btnLabel: string;
    btnLink: string;
    searchContent: SearchContentProps | null;
}
export const PageNotFoundTopSection = (props: PageNotFoundTopSectionProps) => {
    const { searchContent } = props;

    return (
        <div className="error-404-text-container">
            <h1 className="title">{props.title}</h1>
            <p className="paragraph">{props.body}</p>
            {searchContent && (
                <SearchBarInput
                    searchContent={searchContent}
                    hideSearchBarTitle={true}
                />
            )}
        </div>
    );
};

export const PageNotFoundView = () => {
    const serverContext = useContext(ServerContext);
    const helpCardsContent = useHelpCardsContent();
    const errorsContent = useErrorsContent();
    const appInformationContent = useAppInformationContent();
    const searchContent = useSearchContent();
    const page = '404';
    const breadcrumb = errorsContent && errorsContent.errorTitle404;
    serverContext.pageNotFound = true;
    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    useEffect(() => {
        scrollPageToTop();
    }, []);

    return (
        <>
            {breadcrumb &&
            errorsContent &&
            helpCardsContent &&
            appInformationContent ? (
                <ViewTemplate
                    seoConfig={{
                        pageTitle: breadcrumb,
                        applicationName: appInformationContent.applicationName,
                    }}
                    page={page}
                    breadcrumb={breadcrumb}
                    hideSearchBar
                    hideLinksList
                    ctaTopComponent={
                        <PageNotFoundTopSection
                            title={breadcrumb}
                            body={errorsContent.errorDescription404}
                            btnLabel={errorsContent.errorButtonLabel404}
                            btnLink={errorsContent.errorButtonLink404}
                            searchContent={searchContent}
                        />
                    }
                    ctaBottomComponent={
                        <HeroTiles helpCardsContent={helpCardsContent} />
                    }
                    backgroundImageDesktop={
                        process.env.REACT_APP_AEM_BASE_URL +
                        errorsContent.errorBackgroundImageDesktop
                    }
                    backgroundImageMobile={
                        process.env.REACT_APP_AEM_BASE_URL +
                        errorsContent.errorBackgroundImageMobile
                    }
                />
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};
export default PageNotFoundView;
