import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import { AxiosRequestConfig } from 'axios';
import {
    RetrieveRequestBody,
    RetrieveResponseBody,
    RetrieveResponseBodyUnsuccess,
    UpdateRequestBody,
    UpdateResponseBody,
    UpdateResponseBodyUnsuccess,
} from '../../models/communication-preference-center-model';

export default class CommunicationPreferenceCenter {
    async retrievePreferences(
        retrieveRequestBody: RetrieveRequestBody,
        config?: AxiosRequestConfig
    ) {
        const retrievePreferencesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.COMMUNICATION_PREFERENCES_IMG}/retrieve`;

        return await HttpService.post<
            RetrieveResponseBody | RetrieveResponseBodyUnsuccess
        >(retrievePreferencesUrl, retrieveRequestBody, {
            headers: {
                'Content-Type': 'application/json',
                ...HttpService.getAppIdRequestHeaders(),
            },
            ...config,
        }).then(({ data }) => data);
    }

    async updatePreferences(
        updateRequestBody: UpdateRequestBody,
        config?: AxiosRequestConfig
    ) {
        const updatePreferencesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.COMMUNICATION_PREFERENCES_IMG}/update`;

        return await HttpService.post<
            UpdateResponseBody | UpdateResponseBodyUnsuccess
        >(updatePreferencesUrl, updateRequestBody, {
            headers: {
                'Content-Type': 'application/json',
                ...HttpService.getAppIdRequestHeaders(),
            },
            ...config,
        }).then(({ data }) => data);
    }
}
