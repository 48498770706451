import {
    CancelBookingResponse,
    CancelBookingOSBResponse,
} from '../../../../models/osb-model/osb-retrieve-booking';

export const transformCancelBookingResponse = (
    cancelBookingResponse: CancelBookingResponse
): CancelBookingOSBResponse => {
    const cancelBookingOSBResponse: CancelBookingOSBResponse = {
        bookingCancelled: cancelBookingResponse.bookingCanceled,
    };

    return cancelBookingOSBResponse;
};
