import { HowToVideoContentProps } from '../use-how-to-video-content/use-how-to-video-content';

export const mockHowToVideoContent: HowToVideoContentProps = {
    hide: false,
    htvBannerTitle: 'SEARCH HOW-TO VIDEOS',
    htvBannerAuthDescription:
        'Browse our collection of How-To Videos to help make the most of your journey with Ford. ',
    htvBannerUnauthDescription:
        'Browse our collection of How-To Videos to help make the most of your journey with Ford. For a personalized experience, sign in below.',
    htvBackgroundImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/how-to-videos-desktop.png',
    htvBackgroundImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/how-to-videos-mobile.png',
    htvQuickTipImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-quicktip-desktop.png',
    htvQuickTipImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-quicktip-mobile.png',
    htvElectricVehiclesImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-electric-vehicles-desktop.png',
    htvElectricVehiclesImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-electric-vehicles-mobile.png',
    htvFordPassImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-ford-pass-desktop.png',
    htvFordPassImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-ford-pass-mobile.png',
    htvFordServicesImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-ford-services-desktop.png',
    htvFordServicesImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-ford-services-mobile.png',
    lincolnServicesImageDesktop: null,
    lincolnServicesImageMobile: null,
    lincolnTechnologyImageDesktop: null,
    lincolnTechnologyImageMobile: null,
    lincolnWayAppImageDesktop: null,
    lincolnWayAppImageMobile: null,
    htvFordTechnologyImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-ford-technology-desktop.png',
    htvFordTechnologyImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-ford-technology-mobile.png',
    htvKeysAndLocksImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-keys-and-locks-desktop.png',
    htvKeysAndLocksImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-keys-and-locks-mobile.png',
    htvOilChangeImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-oil-change-desktop.png',
    htvOilChangeImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-oil-change-mobile.png',
    htvOwnerResourcesImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-owner-resources-desktop.png',
    htvOwnerResourcesImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-owner-resources-mobile.png',
    htvRecallsImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-recalls-desktop.png',
    htvRecallsImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-recalls-mobile.png',
    htvSyncImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-sync-desktop.png',
    htvSyncImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-sync-mobile.png',
    htvTiresAndWheelsImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-tires-and-wheels-desktop.png',
    htvTiresAndWheelsImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-tires-and-wheels-mobile.png',
    htvWarrantyImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-warranty-desktop.png',
    htvWarrantyImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-warranty-mobile.png',
    htvMoreVehicleTopicsImageDesktop:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/desktop-images/how-to-categoryvideo-more-vehicle-topics-desktop.png',
    htvMoreVehicleTopicsImageMobile:
        '/content/dam/global-owner/ford/us/en-us/images/how-to-videos/mobile-images/how-to-categoryvideo-more-vehicle-topics-mobile.png',
    howToVideosBreadcrumbSupport: 'Support',
    howToVideosBreadcrumbHowToVideos: 'How-To Videos',
    popularHowToVideosTitle: 'POPULAR HOW-TO VIDEOS',
    moreDyfVideosTitle: 'MORE DISCOVER YOUR FORD VIDEOS',
    searchByVehicleTitle: 'SEARCH BY VEHICLE',
    yearTitle: 'Year',
    modelTitle: 'Model',
    searchPrimaryButton: 'Search',
    searchPrimaryButtonAriaLabel: 'Search Videos',
    viewAllModelsSecondaryButton: 'View All Models',
    viewAllModelsSecondaryButtonAriaLabel: 'Link to models',
    videoLibraryPageSubTitle: 'DISCOVER YOUR FORD',
    htvLibrarylandingPageTitle: '%vehicle% VIDEO LIBRARY',
    htvLibraryBannerDescription:
        'We took a deep dive into your %vehicle%. Watch the videos below to learn about it from the inside out.',
    searchAnotherVehicleSecondaryButton: 'Search Another Vehicle',
    searchAnotherVehicleSecondaryButtonAriaLabel:
        'Link for Search Another Vehicle',
    dyfCategoryTitle: 'DISCOVER YOUR FORD',
    dyfCategoryDescription:
        'Now that you’ve got the keys, it’s time to explore the new features your vehicle has to offer.',
    viewAllVideosSecondaryButton: 'View All Videos',
    viewAllVideosSecondaryButtonAriaLabel: 'Link for View All Videos',
    viewMoreVideos: '%category% VIDEOS',
    viewAllHtvTitle: 'View All How-to Videos',
    htvLibraryErrorDescription:
        'Sorry, we dont have any videos for this vehicle yet. Check back for future updates.',
    videoPageErrorDescription:
        "Sorry, videos aren't available for this vehicle.",
    livestreamBannerYmmText: 'PREVIOUSLY RECORDED LIVESTREAM',
    livestreamBannerCtaText: 'View Now',
    livestreamBannerCtaAriaLabel: 'View Now',
};
