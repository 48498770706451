import { useContent } from '../../../../../hooks/use-server-content';

export interface FinalizeDetailsProps {
    finalizeDetailsDescription: string;
    wantToMailText: string;
    downloadLinkText: string;
    mailInTitle: string;
    mailInDescription: string;
    mailInDownloadLinkText: string;
    registerSignInButtonText: string;
    memberIdTitle: string;
    memberIdPlaceholderText: string;
    memberIdErrorMessage: string;
    memberIdHelpLinkText: string;
    memberIdHelpLink: string;
    addressTitle: string;
    firstNamePlaceholderText: string;
    firstNameErrorMessage: string;
    lastNamePlaceholderText: string;
    lastNameErrorMessage: string;
    streetAddressPlaceholderText: string;
    streetAddressErrorMessage: string;
    cityPlaceholderText: string;
    cityErrorMessage: string;
    statePlaceholderText: string;
    stateErrorMessage: string;
    zipCodePlaceholderText: string;
    zipCodeErrorMessage: string;
    emailTitle: string;
    emailPlaceholderText: string;
    emailErrorMessage: string;
    reEnterEmailPlaceholderText: string;
    reEnterEmailErrorMessage: string;
    mailCheckboxText: string;
    noteText: string;
    continueButtonText: string;
    registerSignInUrl: string;
}

export const useFinalizeDetailsContent = (): FinalizeDetailsProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates/request-form-steps',
        'finalize-details'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as FinalizeDetailsProps;
};
