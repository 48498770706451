import './osb-alert-message-popup-lincoln.scss';
import { useEffect, useState } from 'react';
import successIcon from '../../../../../assets/icons-notification-checkmark-secondary.svg';
import errorIcon from '../../../../../assets/warning-icon.svg';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
interface Props {
    alertMessage: string;
    alertSuccess: boolean;
    closeOsbAlertPopup: () => void;
}

export const OsbAlertMessagePopup = (props: Props) => {
    const [top, setTop] = useState<number>(0);

    useEffect(() => {
        // for displaying OsbAlertMessage after the banner we have calculated both browser position from top to banner and osb bannner height and applied to OsbAlertMessage top
        const browserTop =
            document.getElementById('osbBannerId')?.getBoundingClientRect()
                .top || 0;
        const bannerTop =
            document.getElementById('osbBannerId')?.clientHeight || 0;
        setTop(browserTop + bannerTop);
        const handleTop = () => {
            const bannerTop =
                document.getElementById('osbBannerId')?.clientHeight || 0;
            const browserTop =
                document.getElementById('osbBannerId')?.getBoundingClientRect()
                    .top || 0;
            setTop(browserTop + bannerTop);
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleTop);
            window.addEventListener('resize', handleTop);
            return () => {
                window.removeEventListener('scroll', handleTop);
                window.removeEventListener('resize', handleTop);
            };
        }
    }, []);

    return (
        <>
            <div
                style={{ top }}
                className={`alert ${props.alertSuccess ? 'success' : 'error'}`}
            >
                <div className="error-text">
                    <img
                        src={`${props.alertSuccess ? successIcon : errorIcon}`}
                        className="vehicle-added-success-icon"
                        alt="vehicle-added-success"
                    />
                    <p>{props.alertMessage}</p>
                </div>
                <div className="closebtn" onClick={props.closeOsbAlertPopup}>
                    &times;
                </div>
            </div>
        </>
    );
};
