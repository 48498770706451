import { useContent } from '../../../../hooks/use-server-content';

export interface ForgotTrackingNumberProps {
    emailText: string;
    formButtonText: string;
    haveTrackingNumber: string;
    checkRebatesTitle: string;
    questionsPartOneText: string;
    questionsPartTwoEmail: string;
    questionsPartThreeText: string;
    questionsPartFourNumber: string;
    emailIdErrorMsg: string;
    inValidemailMsg: string;
    emailNotFound: string;
    onValidEmailSubmit: string;
    checkAnotherStatus: string;
}

export const useForgotTrackingNumberContent = (): ForgotTrackingNumberProps | null => {
    const [content, getValueByName] = useContent(
        'redeem-rebates',
        'forgot-tracking-number'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ForgotTrackingNumberProps;
};
