import { useContent } from '../../../../hooks/use-server-content';

export interface SupportCommonProps {
    relatedTopics: string;
    paginationOf: string;
    topicErrorMessage: string;
    printAriaLabel: string;
    releaseDateLabel: string;
    releaseDate: string;
}
export const useSupportCommonContent = (): SupportCommonProps | null => {
    const [content, getValueByName] = useContent(
        'common',
        'support-generic-fields'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as SupportCommonProps;
};
