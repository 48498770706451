import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import {
    notify,
    SlackMessages,
} from '../slack-message-service/SlackMessageService';
import { decodeVin } from '../vin-decoder-service/VinDecoderService';

const sendSlackNotification = (vin: string) => {
    notify(SlackMessages.INVALID_VIN_FORMAT + vin);
};

export default function isValidVinOrReg(vin: string, eu = false): boolean {
    const vinRegex = eu
        ? new RegExp('^[a-zA-Z0-9 -]+$')
        : new RegExp('^[A-Za-z0-9]{17}$');
    if (!vinRegex.test(vin)) {
        sendSlackNotification(vin);
        return false;
    }
    return true;
}

export function charCheck(vin: string) {
    const { fmaRegion } = new AppConfigurationService().getAppConfiguration();
    const euVinOrReg = fmaRegion === 'eu' && vin.length <= 10;
    const decodedVin = euVinOrReg ? vin : decodeVin(vin);
    return decodedVin === vin;
}

export const vinStartsWithWFO = (value: string) => {
    if (value && value.slice(0, 3).toLowerCase() == 'wfo') {
        return true;
    }
    return false;
};
