import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
    ReactNode,
} from 'react';
import { SwipePoint, useSwipe } from '../../../hooks/use-swipe';
import PrimaryButton from '../primary-button/primary-button';

import './carousel.scss';
import { FdsChevron } from '../chevron/fds-chevron';
import ServerSideService from '../../../services/server-side-service/server-side-service';

interface Props<T> {
    className?: string;
    items: T[];
    render: (item: T) => ReactNode;
    onChange?: (item: T) => void;
    role?: string;
    ariaLive?: string;
    ariaLabel?: string;
    paginationText?: string;
    leftButtonArialLabel?: string;
    rightButtonAriaLabel?: string;
    shortPagination?: boolean;
}

export const Carousel = <T,>(props: Props<T>) => {
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
    const itemsRef = useRef<HTMLDivElement>(null);
    const maxCardIndex = props.items.length - 1;
    const [visibleRange, setVisibleRange] = useState<{
        start: number;
        end: number;
    }>();

    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 500px)').matches
            : false
    );
    const handleWindowResize = useCallback(() => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 500px)').matches
                : false
        );
    }, [setMobile]);
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX, setSwipeOffsetX]
    );

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (let cardIndex = 0; cardIndex <= maxCardIndex; cardIndex++) {
            if (itemsRef.current) {
                const parentLeft = itemsRef.current.getBoundingClientRect()
                    .left;
                const itemLeft = itemsRef.current
                    .getElementsByClassName('carousel-wrap__items-item')
                    .item(cardIndex)
                    ?.getBoundingClientRect().left;
                if (itemLeft) {
                    const cardPosX = itemLeft - parentLeft;
                    if (
                        Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                        Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
                    ) {
                        closestCardIndex = cardIndex;
                        closestCardX = cardPosX;
                    }
                }
            }
        }
        setCurrentCardIndex(closestCardIndex);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        itemsRef,
        swipeOffsetX,
        setSwipeStartX,
        setCurrentCardIndex,
        maxCardIndex,
        cardsPositionX,
    ]);

    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint) => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );

    const containerRef = useSwipe(
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );

    const updateCardPositions = useCallback(() => {
        if (itemsRef.current) {
            const parentLeft = itemsRef.current.getBoundingClientRect().left;
            const itemLeft = itemsRef.current
                .getElementsByClassName('carousel-wrap__items-item')
                .item(currentCardIndex)
                ?.getBoundingClientRect().left;
            if (itemLeft) {
                const offset = itemLeft - parentLeft;
                setCardsPositionX(-offset);
            }
        }
    }, [itemsRef, currentCardIndex, setCardsPositionX]);

    useEffect(() => {
        window.addEventListener('resize', updateCardPositions);
        return () => {
            window.removeEventListener('resize', updateCardPositions);
        };
    }, [updateCardPositions]);

    const calculateVisibleRange = useCallback(() => {
        if (itemsRef.current) {
            let endVisible = props.items.length;
            Array.from(
                itemsRef.current.getElementsByClassName(
                    'carousel-wrap__items-item'
                )
            ).forEach((element, index) => {
                const boundingRect = element.getBoundingClientRect();
                const windowInnerWidth = window.innerWidth;
                if (
                    boundingRect.left < windowInnerWidth &&
                    boundingRect.right < windowInnerWidth
                ) {
                    endVisible = index + 1;
                }
            });
            setVisibleRange({ start: currentCardIndex, end: endVisible });
        }
    }, [itemsRef, currentCardIndex]);

    useEffect(() => {
        if (itemsRef.current) {
            itemsRef.current.addEventListener(
                'transitionend',
                calculateVisibleRange
            );
        }
        return () => {
            if (itemsRef.current) {
                itemsRef.current.removeEventListener(
                    'transitionend',
                    calculateVisibleRange
                );
            }
        };
    }, [calculateVisibleRange, itemsRef]);

    useEffect(() => {
        calculateVisibleRange();
    }, []);

    useLayoutEffect(() => {
        updateCardPositions();
    }, [currentCardIndex, updateCardPositions]);

    function moveToNextCard() {
        if (currentCardIndex < maxCardIndex) {
            setCurrentCardIndex(currentCardIndex + 1);
        }
    }

    function moveToPreviousCard() {
        if (currentCardIndex > 0) {
            setCurrentCardIndex(currentCardIndex - 1);
        }
    }

    return (
        <>
            <div className={['carousel', props.className].join(' ')}>
                <div
                    className="carousel-wrap"
                    ref={(instance: HTMLDivElement) =>
                        (containerRef.current = instance)
                    }
                >
                    <div
                        ref={itemsRef}
                        className="carousel-wrap__items"
                        style={{
                            transform: `translateX(${cardsPositionX +
                                swipeOffsetX}px)`,
                            transition: swipeStartX
                                ? 'none'
                                : 'transform 300ms linear',
                        }}
                    >
                        {props.items.map((item, index) => {
                            return (
                                <div
                                    className="carousel-wrap__items-item"
                                    key={index}
                                >
                                    {props.render(item)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="carousel-controls">
                <PrimaryButton
                    className="carousel-controls__button"
                    color="dark"
                    fill="fill"
                    chevron={false}
                    onClick={moveToPreviousCard}
                    ariaLabel={props.leftButtonArialLabel || 'previous'}
                    disabled={currentCardIndex === 0}
                >
                    <FdsChevron type="unfilled" direction="left" />
                </PrimaryButton>
                <div className="carousel-controls__range">
                    <span
                        aria-live={(props.ariaLive as any) || 'polite'}
                        role={props.role || 'alert'}
                        aria-label={props.ariaLabel || 'current card'}
                    >
                        {visibleRange &&
                            !isMobile &&
                            !props.shortPagination &&
                            `${visibleRange.start + 1} - ${
                                visibleRange.end
                            } ${props.paginationText || 'of'} ${
                                props.items.length
                            }`}
                        {(!visibleRange || isMobile || props.shortPagination) &&
                            `${currentCardIndex + 1} ${props.paginationText ||
                                'of'} ${props.items.length}`}
                    </span>
                </div>
                <PrimaryButton
                    className="carousel-controls__button"
                    color="dark"
                    fill="fill"
                    chevron={false}
                    onClick={moveToNextCard}
                    ariaLabel={props.rightButtonAriaLabel || 'next'}
                    disabled={currentCardIndex === props.items.length - 1}
                >
                    <FdsChevron type="unfilled" direction="right" />
                </PrimaryButton>
            </div>
        </>
    );
};
