import React, { useEffect } from 'react';
import './activity-indicator.scss';

interface Props {
    className?: string;
    loaderMessage?: string;
    radius?: string;
    progressPercent?: string;
}

export const ActivityIndicator = (props: Props) => {
    useEffect(() => {
        setTimeout(() => {
            const loaderMessage = document.querySelector(
                '.osb-loader-message'
            ) as HTMLElement;
            if (
                loaderMessage &&
                props.progressPercent &&
                parseInt(props.progressPercent) < 70
            ) {
                loaderMessage.style.display = 'block';
            }
        }, 5000);
    }, []);
    return (
        <div
            className={`fds-activity-indicator fds-activity-indicator--circular fds-color__bg--light fds-flex ${
                props.className ? props.className : ''
            }`}
            data-testid="activity-indicator"
        >
            <div
                className="fds-activity-indicator__progress-wrapper"
                role="progressbar"
                aria-label="progress-loader"
            >
                <div className="fds-activity-indicator__progress-line">
                    <svg
                        className={`fds-activity-indicator__progress-line_svg ${
                            props.progressPercent &&
                            props.progressPercent?.trim() != ''
                                ? 'osb-percentage'
                                : ''
                        }`}
                        viewBox="25 25 50 50"
                    >
                        <circle
                            className="fds-activity-indicator__progress-line_line--indeterminate"
                            cx="50"
                            cy="50"
                            r={props.radius || '23'}
                            fill="none"
                            strokeMiterlimit="10"
                        />
                        {props.progressPercent &&
                            props.progressPercent?.trim() != '' && (
                                <text
                                    x="100%"
                                    y="100%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fill="#4D4D4D"
                                    fontSize="10px"
                                    fontFamily="FordF1Regular"
                                >
                                    {props.progressPercent} %
                                </text>
                            )}
                    </svg>
                </div>
            </div>
            {props.loaderMessage && (
                <div className="osb-loader-message">{props.loaderMessage}</div>
            )}
            <div
                className="fds-activity-indicator__notification"
                aria-live="polite"
                aria-relevant="additions"
            ></div>
        </div>
    );
};
