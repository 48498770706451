import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';

export interface ReservationOrderStatus {
    id: string;
    state: string;
    model: string;
    make: string;
    year: string;
    purchaseDate: string;
    vin: string;
}
export interface OrderStatusResponse {
    orders: ReservationOrderStatus[];
}
export default class ReservationOrderStatusService {
    async request(countryCode: string): Promise<boolean> {
        const config = {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            params: {
                countryCode,
            },
        };
        const url = DSL_BASE_URL + DSL_API_PATH.ORDER_STATUS;
        const response = HttpService.get<OrderStatusResponse>(
            url,
            false,
            config
        );
        response.catch(error => console.error(error));
        return response.then(response => response?.data?.orders?.length > 0);
    }
}
