import {
    CampaignState,
    CampaignStateCollection,
} from '../../models/osb-model/osb-campaign-state';
import { useOSBStep } from './use-osb-step';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import {
    CampaignValidationInfo,
    StorePayload,
    StoreState,
} from '../../models/osb-model/osb-store-details';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
export const getInitialCampaignState = (): CampaignStateCollection => {
    return {
        campaignStates: [],
    };
};

export const getVoucherDetailsFromStore = (
    campaignStates: CampaignState[] | undefined
) => {
    return campaignStates && campaignStates.length > 0
        ? {
              voucherName: campaignStates[0].name,
              voucherKey: campaignStates[0].key,
              voucherDescription: campaignStates[0].description,
              potentialSavings: campaignStates[0].potentialSavings,
          }
        : {
              voucherName: null,
              voucherKey: null,
              voucherDescription: null,
              potentialSavings: 0,
          };
};

const configureCampaignStepStore = () => {
    const actions = {
        SET_CAMPAIGN_STATES_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as CampaignValidationInfo)
                    .campaignStateCollection,
                ...(payload as CampaignStateCollection),
            };

            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_CAMPAIGN_STATE_STEP_KEY,
                newState,
                localStorageExpiry
            );

            return { campaignStateCollection: newState };
        },
    };
    initializeStore(actions, {
        campaignStateCollection:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_CAMPAIGN_STATE_STEP_KEY
            ) || getInitialCampaignState(),
    });
};

configureCampaignStepStore();
export const useCampaignStatesStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalCampaignStates = (
        campaignStateCollection: CampaignStateCollection,
        shouldReload = true
    ) => {
        dispatch(
            'SET_CAMPAIGN_STATES_STEP',
            campaignStateCollection,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };
    const resetCampaignStatesStep = (shouldReload = true) => {
        dispatch(
            'SET_CAMPAIGN_STATES_STEP',
            getInitialCampaignState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const getCampaignVoucherInfo = () => {
        const store = (globalState as CampaignValidationInfo)
            .campaignStateCollection;
        return getVoucherDetailsFromStore(store.campaignStates);
    };
    return {
        osbCampaignStates: (globalState as CampaignValidationInfo)
            .campaignStateCollection,
        setOSBCampaignStates: setGlobalCampaignStates,
        resetCampaignStatesStep: resetCampaignStatesStep,
        getCampaignVoucherInfo: getCampaignVoucherInfo,
    };
};
