import HttpService from '../../http-service/http-service';
import {
    OsbContentResponse,
    ContentObject,
    ContentElements,
} from '../../../models/osb-model/osb-content-details';
import OsbUtilService from '../osb-util-service/osb-util-service';

export default class OsbContentService {
    private async doGet(
        brand: string,
        region: string,
        language: string,
        modelName: string
    ): Promise<OsbContentResponse> {
        const countryCode =
            OsbUtilService.getAppConfiguration().countryName === 'Mothersite'
                ? 'mothersite'
                : region;
        const url = `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/${brand}/${countryCode}/${language}/content-fragments/common/owners-osb/${modelName}.global-owner-model.json`;
        const response = await HttpService.get(url, true);
        return response.data;
    }

    public async getOsbContentModel(
        brand: string,
        region: string,
        language: string,
        modelName: string
    ): Promise<OsbContentResponse> {
        const responseData = await this.doGet(
            brand,
            region,
            language,
            modelName
        );

        return responseData;
    }

    public async getOsbContentObject(
        brand: string,
        region: string,
        language: string,
        modelName: string
    ): Promise<ContentObject> {
        const responseData = await this.doGet(
            brand,
            region,
            language,
            modelName
        );

        return responseData.elements.reduce(
            (acc: ContentObject, currObj: ContentElements): ContentObject => {
                return {
                    ...acc,
                    [currObj.name]: currObj.value,
                };
            },
            {}
        );
    }
}
