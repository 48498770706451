import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';

export const getEuMapAccordionContent = (
    syncGeneration: string,
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined,
    mapCards: SyncMapsCard[] | undefined,
    syncMapContent: SyncMapStatus | null,
    mapsOption: string,
    isSyncOta: boolean,
    isVersion34: boolean
) => {
    return {
        syncGeneration,
        hasSync:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.hasSync,
        cards: mapCards || [],
        heading: syncMapContent?.mapsHeading,
        title: isSyncOta
            ? syncMapContent?.mapsAutomaticUpdates
            : syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                  ?.hasFirmwareUpdate
            ? syncMapContent?.updateSyncFirst
            : syncMapContent?.mapsUpdateAvailable,
        subtitle: syncMapContent?.mapsUpdateAvailableDescription,
        upToDate: syncMapContent?.mapsIsUpToDate,
        radioButtons: isVersion34,
        hasUpdate:
            isSyncOta ||
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.hasFirmwareUpdate ||
            syncMapsUpdates?.syncMapUpdateResponse?.mapUpdateResponse.hasUpdate,
        notAvailable: syncMapContent?.notAvailableLabel,
        accordionType: 'maps',
        disabled:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                .hasFirmwareUpdate,
        downloadUrl:
            syncMapsUpdates?.syncMapUpdateResponse?.mapUpdateResponse
                .downloadUrl,
        downloadUrlError:
            syncGeneration === '3' &&
            syncMapsUpdates?.syncMapUpdateResponse?.mapUpdateResponse
                ?.hasUpdate &&
            !syncMapsUpdates?.syncMapUpdateResponse?.mapUpdateResponse
                .downloadUrl,
        selectedOption: mapsOption,
        syncMapContent: syncMapContent,
        version: isVersion34,
    };
};
