import { useContent } from '../../../../hooks/use-server-content';

export interface PreferredDealerContentProps {
    headline: string;
    scheduleServiceCtaLabel: string;
    directionCtaLabel: string;
    changeDealerCtaLabel: string;
    changeDealerUrl: string;
    openScheduleServiceInNewTab: boolean;
    openDirectionInNewTab: boolean;
    openChangeDealerInNewTab: boolean;
}

export const usePreferredDealerContent = (): PreferredDealerContentProps => {
    const [content, getValueByName] = useContent(
        'vehicles/vehicle-health-reports',
        'preferred-dealer'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as PreferredDealerContentProps;
};
export default usePreferredDealerContent;
