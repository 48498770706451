import React from 'react';
import { useTrailerChecklistExperienceContent } from '../../components/sections/trailer-checklist/hooks/use-trailer-checklist-experience-content';
import { TrailerCheckListDetail } from '../../components/sections/trailer-checklist/trailer-checklist-detail/trailer-checklist-detail';
import { TrailerType } from '../../models/experiencefragments/trailer-checklist';

interface Props {
    trailerType: TrailerType;
}

export const TrailerChecklistDetailHandler = (props: Props) => {
    const trailerChecklistContent = useTrailerChecklistExperienceContent();
    const isContentAvailable =
        trailerChecklistContent?.trailerChecklist &&
        !!Object.keys(trailerChecklistContent?.trailerChecklist).length;

    const getTrailerChecklistDetail = () => {
        if (isContentAvailable === false) {
            return undefined;
        }
        if (props.trailerType === TrailerType.CONVENTIONAL) {
            return trailerChecklistContent?.trailerChecklist
                ?.conventionalTrailer;
        }
        if (props.trailerType === TrailerType.GOOSENECK) {
            return trailerChecklistContent?.trailerChecklist?.gooseneckTrailer;
        }
        if (props.trailerType === TrailerType.FIFTHWHEEL) {
            return trailerChecklistContent?.trailerChecklist?.fifthWheelTrailer;
        }
    };

    const trailerChecklistDetail = getTrailerChecklistDetail();

    return (
        <>
            {trailerChecklistDetail && (
                <TrailerCheckListDetail
                    pageType={props.trailerType}
                    trailerChecklistDetail={trailerChecklistDetail}
                    serviceOffersSection={
                        trailerChecklistContent?.serviceOffersSection
                    }
                />
            )}
        </>
    );
};

export default TrailerChecklistDetailHandler;
