import React, { useEffect, useState } from 'react';
import './light-cancel-booking-confirmation.lincoln.scss';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../../../services/service-handler';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import { useHttp, useOSBStep } from '../../../../../hooks/owners-osb';
import { FordAccount } from '../../ford-account/ford-account';
import { CANCEL_BOOKING_CONFIRMATION_KEYS } from '../../osb-constant';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';

interface Props {
    mainService: string;
    dealerName: string;
    appointmentDate: string;
    appointmentTime: string;
}

export const LightCancelBookingConfirmation = (props: Props) => {
    const { httpState, dispatch } = useHttp();
    const [
        cancelBookingConfirmationStepContent,
        setCancelBookingConfirmationStepContent,
    ] = useState<OsbContentResponse>();
    const { osbStep } = useOSBStep();

    function getCancelBookingConfirmationContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-cancel-booking-confirmation'
        )
            .then(results => {
                setCancelBookingConfirmationStepContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
                console.log('unable to load content from AEM', error);
            });
    }

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getCancelBookingConfirmationContent();
    }, []);
    return (
        <div className="light-cancel-booking-confirmation-component-container">
            {httpState.isLoading ? (
                <OsbLoader osbEmptyContentHeight={true} />
            ) : (
                <>
                    <div className="cbc-head">
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    cancelBookingConfirmationStepContent?.elements.find(
                                        e =>
                                            e.name ===
                                            CANCEL_BOOKING_CONFIRMATION_KEYS.BOOKING_CANCELLED_HEADING
                                    )?.value + ''.toString() ?? '',
                            }}
                        />
                    </div>

                    <div className="cbc-text">
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    cancelBookingConfirmationStepContent?.elements.find(
                                        e =>
                                            e.name ===
                                            CANCEL_BOOKING_CONFIRMATION_KEYS.BOOKING_CANCELLED_TEXT
                                    )?.value + ''.toString() ?? '',
                            }}
                        />
                    </div>

                    <div className="cbc-box-content">
                        <div
                            className="body-content"
                            dangerouslySetInnerHTML={{
                                __html:
                                    cancelBookingConfirmationStepContent?.elements
                                        .find(
                                            e =>
                                                e.name ===
                                                CANCEL_BOOKING_CONFIRMATION_KEYS.CANCELLED_BOOKING_SUMMARY
                                        )
                                        ?.value.toString()
                                        .replace(
                                            '##services##',
                                            props.mainService
                                        )
                                        .replace(
                                            '##dealer_name##',
                                            '<b>' + props.dealerName + '</b>'
                                        )
                                        .replace(
                                            '##booking_date##',
                                            '<b>' +
                                                props.appointmentDate +
                                                '</b>'
                                        )
                                        .replace(
                                            '##booking_time##',
                                            '<b>' +
                                                props.appointmentTime +
                                                '</b>'
                                        ) ?? '',
                            }}
                        />
                    </div>
                    <div
                        className={`cbc-footer-content-container ${
                            osbStep.isAuthenticatedFlow
                                ? 'auth-flow-container'
                                : ''
                        }`}
                    >
                        <div
                            className={
                                osbStep.isAuthenticatedFlow
                                    ? 'cbc-footer-rebook-auth-flow'
                                    : 'cbc-footer-rebook'
                            }
                        >
                            <div className="sub-head">
                                {cancelBookingConfirmationStepContent?.elements.find(
                                    e =>
                                        e.name ===
                                        CANCEL_BOOKING_CONFIRMATION_KEYS.REBOOK_TITLE
                                )?.value + ''.toString()}
                            </div>
                            <div className="rebook-text">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            cancelBookingConfirmationStepContent?.elements.find(
                                                e =>
                                                    e.name ===
                                                    CANCEL_BOOKING_CONFIRMATION_KEYS.REBOOK_TEXT
                                            )?.value + ''.toString() ?? '',
                                    }}
                                />
                            </div>
                        </div>
                        {!osbStep.isAuthenticatedFlow && (
                            <div className="cbc-footer-ford-account">
                                <FordAccount></FordAccount>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
