import { ConnectedVehicleBarFragment } from '../../../models/experiencefragments/connected-vehicle-bar';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import ConnectedVehicleChargeLevel from '../../connected-vehicle/connected-vehicle-charge-level/connected-vehicle-charge-level';
import ConnectedVehicleTirePressure from '../../connected-vehicle/connected-vehicle-tire-pressure/connected-vehicle-tire-pressure';
import ConnectedVehicleOilLife from '../../connected-vehicle/connected-vehicle-oil-life/connected-vehicle-oil-life';
import ConnectedVehicleFuelLevel from '../../connected-vehicle/connected-vehicle-fuel-level/connected-vehicle-fuel-level';
import ConnectedVehicleCurrentMileage from '../../connected-vehicle/connected-vehicle-current-mileage/connected-vehicle-current-mileage';
import './connected-vehicle-bar.scss';
import React, { useContext, useEffect, useState } from 'react';
import ServerContext from '../../../contexts/serverContext';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import { PageType } from '../view-template/page-type';
import VehicleTypeService, {
    VehicleType,
} from '../../../services/vehicle-type-service/vehicle-type-service';
import { BRAND, CONNECTED_VEHICLE_CARD_TYPE_V2 } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';

interface Props {
    vehicleAttributes: VehicleAttributes;
    pageType: PageType;
    connectedVehicleInfo: any;
}

export const ConnectedVehicleBar = (props: Props) => {
    let tilesToRender;
    const [vehicleTileAemContent] = useExperienceContent<
        ConnectedVehicleBarFragment
    >('vehicles', 'connected-vehicle-bar', 'connected_vehicle_ba');
    const vehicleTilesArray = [];
    const [authenticationState] = useAuthentication();
    const [vehicleType, setVehicleType] = useState<VehicleType>();
    const isBev = props.vehicleAttributes?.engineType === 'BEV';
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    useEffect(() => {
        if (
            authenticationState === AuthenticationState.Authenticated &&
            props.pageType === 'DASHBOARD' &&
            props.vehicleAttributes.vin
        ) {
            new VehicleTypeService()
                .request(
                    props.vehicleAttributes.vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                )
                .then(typeResponse => {
                    typeResponse && setVehicleType(typeResponse);
                });
        }
    }, [props.vehicleAttributes?.vin, authenticationState]);

    if (
        vehicleTileAemContent &&
        Object.keys(vehicleTileAemContent).length > 0
    ) {
        for (const type in vehicleTileAemContent) {
            if (Object.values(CONNECTED_VEHICLE_CARD_TYPE_V2).includes(type)) {
                vehicleTilesArray.push({
                    type,
                    ...(vehicleTileAemContent as { [key: string]: any })[type],
                });
            }
        }
    }

    if (vehicleTilesArray) {
        tilesToRender = vehicleTilesArray.map((tile: any, index: number) => {
            if (index > 4) {
                return;
            }
            if (tile.type === CONNECTED_VEHICLE_CARD_TYPE_V2.BEV_CHARGE_LEVEL) {
                return (
                    <ConnectedVehicleChargeLevel
                        key={tile.title}
                        vin={props.vehicleAttributes?.vin || ''}
                        {...tile}
                        connectedVehicleInfo={props.connectedVehicleInfo}
                        isBev={isBev}
                    />
                );
            }
            if (tile.type === CONNECTED_VEHICLE_CARD_TYPE_V2.TIRE_PRESSURE) {
                return (
                    <ConnectedVehicleTirePressure
                        key={tile.title}
                        vin={props.vehicleAttributes?.vin || ''}
                        {...tile}
                        connectedVehicleInfo={props.connectedVehicleInfo}
                        vehicleType={vehicleType}
                    />
                );
            }
            if (tile.type === CONNECTED_VEHICLE_CARD_TYPE_V2.OIL_LIFE) {
                return (
                    <ConnectedVehicleOilLife
                        key={tile.title}
                        vin={props.vehicleAttributes?.vin || ''}
                        {...tile}
                        connectedVehicleInfo={props.connectedVehicleInfo}
                        isBev={isBev}
                    />
                );
            }
            if (tile.type === CONNECTED_VEHICLE_CARD_TYPE_V2.FUEL_LEVEL) {
                return (
                    <ConnectedVehicleFuelLevel
                        key={tile.title}
                        vin={props.vehicleAttributes?.vin || ''}
                        {...tile}
                        connectedVehicleInfo={props.connectedVehicleInfo}
                        isBev={isBev}
                    />
                );
            }
            if (tile.type === CONNECTED_VEHICLE_CARD_TYPE_V2.CURRENT_MILEAGE) {
                return (
                    <ConnectedVehicleCurrentMileage
                        key={tile.title}
                        vin={props.vehicleAttributes?.vin || ''}
                        {...tile}
                        connectedVehicleInfo={props.connectedVehicleInfo}
                    />
                );
            }
        });
    } else {
        tilesToRender = null;
    }
    return (
        <>
            {props.connectedVehicleInfo &&
                vehicleTileAemContent &&
                !vehicleTileAemContent.hideVehicleBar && (
                    <div
                        className={`connected-vehicle-bar-grid ${!currentVersion &&
                            'connected-vehicle-bar-grid-preimagine'}`}
                    >
                        {tilesToRender}
                    </div>
                )}
        </>
    );
};
