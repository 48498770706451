import React from 'react';
import { PrimaryButton } from '../../../../common';
import './book-a-service.lincoln.scss';
import serverSideService from '../../../../../services/server-side-service/server-side-service';

interface Props {
    label: string;
    redirectUrl: string;
}

export const BookAServiceCTA = (props: Props) => {
    const goToBookAService = () => {
        if (serverSideService.isClientSide()) {
            window.location.href = props.redirectUrl;
        }
    };

    return (
        <div className="book-service-cta">
            <PrimaryButton
                fill="fill"
                color="dark"
                chevron={false}
                onClick={goToBookAService}
            >
                {props.label}
            </PrimaryButton>
        </div>
    );
};
