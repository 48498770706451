import React, { useEffect, useState } from 'react';
import oilLifeIcon from '../../../../../../assets/oillife-icon.svg';
import { VHACard } from '../../../components/vha-card/vha-card';
import { getVin } from '../../../../../utils/getVin-util/getVin-util';
import {
    getOilLifeDataFromAPI,
    getOilLifeStatus,
    getOilLifeDescription,
} from '../oillife/oilife';
import { useHistory } from 'react-router-dom';
import { findByAlias } from '../../../../../../routes';
export interface Props {
    oilLifeContent: any;
    handleVehicleStatus: (alertType: any) => void;
}

export const OilLifeAlert = (props: Props) => {
    const history = useHistory();
    const selectedVin = getVin();
    const [oilLifeData, setOilLifeData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            return await getOilLifeDataFromAPI(selectedVin);
        };

        fetchData()
            .then(oilLifeAPIData => {
                setOilLifeData(oilLifeAPIData?.oilLifeData);
                props.handleVehicleStatus(
                    getOilLifeStatus(oilLifeAPIData?.oilLifeData)
                );
            })
            .catch(error => {
                console.error(error.message);
            });
    }, []);

    const handleOilLifeCardClick = () => {
        const oilLifeDetailsUrl = findByAlias('OilLifeDetailsView');
        history.push(oilLifeDetailsUrl);
    };

    return (
        <>
            {oilLifeData && (
                <VHACard
                    icon={oilLifeIcon}
                    title={props?.oilLifeContent?.oilLifeAccordionLabel}
                    description={getOilLifeDescription(
                        oilLifeData,
                        props?.oilLifeContent
                    )}
                    cardState={getOilLifeStatus(oilLifeData)}
                    handleVHACardClick={handleOilLifeCardClick}
                />
            )}
        </>
    );
};
