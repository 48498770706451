import { OpenGraphMetaTagData } from './OpenGraphMetaTagData';
import { HTMLMetaTagData } from './HTMLMetaTagData';
import { GenericMetaTagData } from './GenericMetaTagData';
import { MetaTag } from './MetaTag';
import { OpenGraphMetaTag } from './OpenGraphMetaTag';
import { HTMLMetaTag } from './HTMLMetaTag';

export class MetaTagFactory {
    newTag(
        tagData: OpenGraphMetaTagData | HTMLMetaTagData | GenericMetaTagData
    ): MetaTag {
        let ogOrHtml;
        if ((tagData as GenericMetaTagData).fieldName) {
            const g = tagData as GenericMetaTagData;
            if (MetaTagFactory.isGenericOpenGraphData(g)) {
                ogOrHtml = { property: g.fieldName, content: g.fieldValue };
            } else {
                ogOrHtml = { name: g.fieldName, content: g.fieldValue };
            }
        } else {
            ogOrHtml = tagData;
        }

        if (MetaTagFactory.isOpenGraphData(ogOrHtml)) {
            const data = ogOrHtml as OpenGraphMetaTagData;
            return new OpenGraphMetaTag(data.property, data.content);
        }

        const data = ogOrHtml as HTMLMetaTagData;
        return new HTMLMetaTag(data.name, data.content);
    }

    private static isGenericOpenGraphData(g: GenericMetaTagData) {
        return (
            (g.fieldName && g.fieldName.startsWith('og')) ||
            g.fieldName.startsWith('fb')
        );
    }

    private static isOpenGraphData(
        tagData: OpenGraphMetaTagData | HTMLMetaTagData | object
    ) {
        return (tagData as OpenGraphMetaTagData).property;
    }
}
