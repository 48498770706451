import { useEffect, useState } from 'react';
import {
    FeatureCardsFragment,
    FeatureCardsService,
} from '../services/feature-cards/feature-card-article-service';
import { useExperienceContent } from '../../../../hooks/use-server-content';

export const useFeatureCards = (
    category: string,
    name: string,
    componentName: string,
    ymmServlet: boolean,
    vin?: string
): FeatureCardsFragment | null => {
    const [content, setContent] = useState<FeatureCardsFragment | null>(null);
    const [experienceContent] = useExperienceContent<FeatureCardsFragment>(
        category,
        name,
        componentName,
        undefined,
        ymmServlet
    );
    useEffect(() => {
        if (experienceContent) {
            setContent(
                new FeatureCardsService().getFeatureCards(experienceContent)
            );
        }
    }, [experienceContent, vin]);
    return content;
};
