import { useState, useEffect } from 'react';
import AuthenticationService from './../services/authentication-service/authentication-service';

export enum AuthenticationState {
    Pending,
    Authenticated,
    Unauthenticated,
}

export function useAuthentication(): [
    AuthenticationState,
    () => void,
    () => void
] {
    const [authenticationState, setAuthenticationState] = useState<
        AuthenticationState
    >(AuthenticationState.Pending);

    useEffect(() => {
        new AuthenticationService()
            .onIsAuthenticated()
            .then(isAuthenticated => {
                const state = isAuthenticated
                    ? AuthenticationState.Authenticated
                    : AuthenticationState.Unauthenticated;
                setAuthenticationState(state);
            });
    }, [setAuthenticationState]);

    const login = () => {
        new AuthenticationService().login();
    };
    const logout = () => {
        new AuthenticationService().logout();
    };

    return [authenticationState, login, logout];
}
