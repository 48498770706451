import React from 'react';
import { SearchContentProps } from './hooks/use-search-content';
import { ViewBanner } from '..';
import './search-bar.scss';
import SearchBarInput from './search-bar-input/search-bar-input';
import { SupportHeroTiles } from '../../../support/components/support-hero-tiles/support-hero-tiles';
import { LiveChatHelpCardsProps } from '../tirefinder/hooks/use-livechat-helpcard';

interface Props {
    searchContent: SearchContentProps;
    helpCardsContent: LiveChatHelpCardsProps;
}

export const LiveChatSearchBar = (props: Props) => {
    return (
        <>
            {props.searchContent && props.helpCardsContent && (
                <div className="search-bar" id="SH">
                    <ViewBanner
                        topComponent={
                            <SearchBarInput
                                searchContent={props.searchContent}
                            />
                        }
                        bottomComponent={
                            <SupportHeroTiles
                                supportHelpCardsContent={props.helpCardsContent}
                            />
                        }
                        backgroundImageDesktop={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.searchContent.searchBackgroundImageDesktop
                        }
                        backgroundImageMobile={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.searchContent.searchBackgroundImageMobile
                        }
                    />
                </div>
            )}
        </>
    );
};
