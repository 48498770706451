import React from 'react';
import './tab-nav.scss';
import { KEYBOARD_KEYS } from '../../../../../constants';

export const TabNav = (props: any) => {
    const handleKeyDown = (event: any, tab: any) => {
        if (event.key === KEYBOARD_KEYS.ENTER) {
            props.setSelected(tab);
        }
    };
    return (
        <div>
            <ul className="rebates-container">
                {props.tabs.map((tab: any) => {
                    const active = tab === props.selected ? 'active' : '';
                    return (
                        <li className="rebates-elements" key={tab}>
                            <a
                                className={'nav-link ' + active}
                                role="button"
                                tabIndex={0}
                                onClick={() => props.setSelected(tab)}
                                onKeyDown={e => handleKeyDown(e, tab)}
                            >
                                {tab}
                            </a>
                        </li>
                    );
                })}
            </ul>
            {props.children}
        </div>
    );
};
