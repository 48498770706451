import React, { ReactNode } from 'react';
import './fm-connected-vehicle.scss';
import { StringUtil } from '../../utils/string-util/string-util';
export type HeaderType = 'good' | 'moderate' | 'severe' | 'blue' | 'none';
import FMProgressBar from '../../common/fm-progress-bar/fm-progress-bar';

export interface Props {
    className?: string;
    header: string;
    children?: ReactNode;
    iconPath?: string;
    number?: any;
    showProgressBar?: boolean;
    progressBarPercentage?: any;
    progressBarColor?: any;
    progressBarHeight?: number;
    progressBarWidth?: number;
    progressBarAria?: string;
    progressBarIsGradient?: boolean;
}

const FMConnectedVehicle = (props: Props) => {
    const {
        showProgressBar = false,
        className,
        progressBarHeight = 16,
        progressBarWidth = 64,
    } = props;
    const title = ((str: string) => {
        return str
            ?.toLowerCase()
            ?.split(' ')
            ?.map((word: string) => StringUtil.capitalizeFirstLetter(word))
            .join(' ');
    })(props?.header);
    const fmConnectedBar = ['fm-connected-vehicle', className ? className : '']
        .join(' ')
        .trim();
    return (
        <div className={fmConnectedBar}>
            <header className="fm-connected-vehicle__header">
                {props?.iconPath && (
                    <img
                        className="fm-connected-vehicle__header__icon"
                        src={`${process.env.REACT_APP_AEM_BASE_URL}${props?.iconPath}`}
                        alt="presentation"
                    />
                )}
                <div className="fm-connected-vehicle__header__title">
                    {title}
                </div>
            </header>
            <div className="fm-connected-vehicle__body">
                <span>{props?.number}</span>
                {showProgressBar && (
                    <FMProgressBar
                        valuePercentage={props.progressBarPercentage}
                        color={props.progressBarColor}
                        height={progressBarHeight}
                        width={progressBarWidth}
                        ariaLabel={props.progressBarAria}
                        isGradient={props.progressBarIsGradient}
                    />
                )}
                {props?.children ? props?.children : <></>}
            </div>
        </div>
    );
};
export default FMConnectedVehicle;
