import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import React, { useState } from 'react';
import {
    AvailableDates,
    TimeRange,
} from '../../../../models/osb-model/dealer-calendar-details';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { DATE_TIME_STEP } from '../osb-constant';
import './calendar-lincoln.scss';
import { useCalendarStep } from '../../../../hooks/owners-osb';

const dateFns = require('date-fns');

interface Props {
    calendarStart: string;
    calendarEnd: string;
    availableDates: AvailableDates[];
    setSelectedDate?: (time: string) => void;
    setTimeslots?: (time: string[]) => void;
    message?: string;
    initSelectDate?: string;
    localeAsString?: string;
    isMobileView: boolean;
    setTimeRanges?: (time: TimeRange[]) => void;
    isMobileServiceSelected?: boolean;
}

export const Calendar = (props: Props) => {
    const localeAsString = props.localeAsString
        ? props.localeAsString
        : 'en-GB';
    const [currentDate, setCurrentDate] = useState(
        OsbUtilService.getUTCDate(
            props.initSelectDate && props.initSelectDate.length
                ? props.initSelectDate
                : props.calendarStart
        )
    );
    const [selectedDate, setSelectedDate] = useState(
        props.initSelectDate && props.initSelectDate.length > 0
            ? OsbUtilService.getUTCDate(props.initSelectDate)
            : OsbUtilService.getUTCDate(props.calendarStart)
    );
    const { osbCalendarStep } = useCalendarStep();
    const period = differenceInCalendarMonths(
        new Date(props.calendarEnd),
        new Date(props.calendarStart)
    );
    const initCurrentMonthState = () => {
        if (props.initSelectDate && props.initSelectDate.length > 0) {
            return differenceInCalendarMonths(
                new Date(props.initSelectDate),
                new Date()
            );
        } else if (props.calendarStart && props.calendarStart.length > 0) {
            return differenceInCalendarMonths(
                new Date(props.calendarStart),
                new Date()
            );
        }
        return 0;
    };
    const initPreviousMonthState = () => {
        if (props.initSelectDate && props.initSelectDate.length > 0) {
            return (
                new Date(props.initSelectDate).getMonth() -
                new Date().getMonth() -
                1
            );
        } else if (props.calendarStart && props.calendarStart.length > 0) {
            return (
                new Date(props.calendarStart).getMonth() -
                new Date().getMonth() -
                1
            );
        }
        return -1;
    };
    const [currentMonthState, setCurrentMonthState] = useState(
        initCurrentMonthState
    );
    const [previousMonthState, setPreviousMonthState] = useState(
        initPreviousMonthState
    );
    const nextMonth = () => {
        if (currentMonthState <= period) {
            setCurrentDate(dateFns.addMonths(currentDate, 1));
            setCurrentMonthState(currentMonthState + 1);
            setPreviousMonthState(previousMonthState - 1);
        }
    };
    const prevMonth = () => {
        if (previousMonthState <= period + 1) {
            setCurrentDate(dateFns.subMonths(currentDate, 1));
            setPreviousMonthState(previousMonthState + 1);
            setCurrentMonthState(currentMonthState - 1);
        }
    };
    function getTimeslotsFromAvailableDates(day: string) {
        return props.availableDates.filter(entry => entry.date === day);
    }
    function isNotValidDay(day: string) {
        if (getTimeslotsFromAvailableDates(day).length > 0) {
            return false;
        }
        return true;
    }

    const availableTimeslots = (selectedDay: Date) => {
        const selectedDaySlots = getTimeslotsFromAvailableDates(
            dateFns.format(selectedDay, 'yyyy-MM-dd').toString()
        );
        if (props.isMobileServiceSelected) {
            props.setTimeRanges &&
                props.setTimeRanges(
                    selectedDaySlots && selectedDaySlots[0]?.availableTimeRanges
                        ? selectedDaySlots[0].availableTimeRanges
                        : []
                );
        }

        props.setTimeslots &&
            props.setTimeslots(
                selectedDaySlots && selectedDaySlots[0]?.availableTimeSlots
                    ? selectedDaySlots[0].availableTimeSlots
                    : []
            );
        props.setSelectedDate &&
            props.setSelectedDate(
                dateFns.format(selectedDay, 'yyyy-MM-dd').toString()
            );
    };
    const onDateClick = (day: Date) => {
        // selected date is not in current month, then navigate to that month.
        if (!dateFns.isSameMonth(day, dateFns.startOfMonth(currentDate))) {
            if (currentDate.getMonth() < day.getMonth()) {
                nextMonth();
            } else {
                prevMonth();
            }
        }
        if (props.message === DATE_TIME_STEP.PICKUP_DATE) {
            setSelectedDate(day);
            availableTimeslots(day);
        }
        if (
            dateFns.format(day, 'yyyy-MM-dd') !== osbCalendarStep.selectedDate
        ) {
            setSelectedDate(day);
            availableTimeslots(day);
        }
    };

    const calendarHeader = () => {
        return currentDate.toLocaleString(localeAsString, {
            month: 'short',
            year: 'numeric',
        });
    };

    const header = () => {
        return (
            <div className="cal-header row flex-middle">
                <div className="column col-start">
                    {currentMonthState >= 1 && (
                        <div
                            className="icon"
                            data-testid="iconToPreviousMonth"
                            onClick={prevMonth}
                        >
                            &#10094;
                        </div>
                    )}
                </div>
                <div className="column col-center">
                    <span>{calendarHeader()}</span>
                </div>
                <div className="column col-end">
                    {currentMonthState < period && (
                        <div
                            className="icon"
                            data-testid="iconToNextMonth"
                            onClick={nextMonth}
                        >
                            &#10095;
                        </div>
                    )}
                </div>
            </div>
        );
    };
    const daysOfWeek = () => {
        const days = [];
        //startOfWeek function - the first argument is the current date (currentDate),
        //and the second argument is an options object that specifies additional parameters.
        //In this case, the options object specifies that the week starts on Monday (1 represents Monday in date-fns).
        //So, the startDate variable will be assigned the start date of the week that currentDate falls in,
        //with Monday as the first day of the week.
        const startDate = dateFns.startOfWeek(currentDate, {
            weekStartsOn: DATE_TIME_STEP.FIRST_DAY_OF_THE_WEEK,
        });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="column col-center" key={i}>
                    {dateFns
                        .addDays(startDate, i)
                        .toLocaleString(localeAsString, {
                            weekday: DATE_TIME_STEP.MOBILE_VIEW_NARROW,
                        })}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };
    const cells = () => {
        const monthStart = dateFns.startOfMonth(currentDate);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart, {
            weekStartsOn: DATE_TIME_STEP.FIRST_DAY_OF_THE_WEEK,
        });
        const endDate = dateFns.endOfWeek(monthEnd);
        const dateFormat = 'd';
        const rows = [];
        let days = [];
        let day = startDate;
        const newDate = new Date();
        const formattedCurrentDate = newDate.toLocaleDateString();

        const createCell = (day: Date) => {
            const formattedDate = dateFns.format(day, dateFormat);
            const formattedNewDate = day.toLocaleDateString();
            const cloneDay = day;
            const cellClass = `column cell ${
                formattedNewDate === formattedCurrentDate ? 'currentDay' : ''
            } ${
                isNotValidDay(dateFns.format(day, 'yyyy-MM-dd').toString())
                    ? 'disabled'
                    : dateFns.isSameDay(day, selectedDate)
                    ? 'selected'
                    : ''
            }`;
            const key = `cell-${formattedDate}`;
            return (
                <div
                    className={cellClass}
                    key={key}
                    data-testid={`${
                        !isNotValidDay(
                            dateFns.format(day, 'yyyy-MM-dd').toString()
                        )
                            ? `enabled ${formattedDate} ${calendarHeader()}`
                            : `disabled ${formattedDate} ${calendarHeader()}`
                    }`}
                    onClick={() => onDateClick(dateFns.toDate(cloneDay))}
                >
                    <span className="number">{formattedDate}</span>
                    <span className="bg">{formattedDate}</span>
                </div>
            );
        };

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                days.push(createCell(day));
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {' '}
                    {days}{' '}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };
    return (
        <div className="new-osb-calendar-container">
            <div className="calendar">
                <div>{header()}</div>
                <div>{daysOfWeek()}</div>
                <div>{cells()}</div>
            </div>
        </div>
    );
};

export default Calendar;
