import React, { useEffect, useState } from 'react';
import useSeoConfig from '../../hooks/use-seo-config';
import './service-price-calculator-view.scss';
import serverSideService from '../../services/server-side-service/server-side-service';
import ServicePriceCalculator from '../../components/sections/service-price-calculator/service-price-calculator';
import ServicePriceCalculatorWrapper from '../../components/sections/service-price-calculator/service-price-calculator-wrapper';
import { ContentElements } from '../../models/priceCalculator-content-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { useHttp } from '../../hooks/owners-osb';
import { getObjectFromAEMJson } from '../../components/sections/owners-osb/osb-utils';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../../components/sections/service-price-calculator/price-calculator-constant';
import GoogleMapService from '../../services/osb-service/google-map-service/google-map-service';

const ServicePriceCalculatorView = () => {
    //This hook is to add meta data title and description which is created in experience fragment in seo folder
    useSeoConfig('pc-metadata-title', 'price-calculator');
    const [priceCalculatorContent, setPriceCalculatorContent] = useState<
        ContentElements[]
    >([]);
    const [newDesign, setNewDesign] = useState<boolean>(false);
    const { dispatch } = useHttp();
    const fetchPriceCalculatorContent = async (modal: string) => {
        await ServiceHandler.PriceCalculatorContentService.getPriceCalculatorContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modal
        )
            .then(results => {
                const { elements } = results;
                setPriceCalculatorContent(elements);
                setNewDesign(
                    getObjectFromAEMJson(
                        PRICE_CALCULATOR_RESULT_CONSTANTS.ENABLE_NEW_LANDING_PAGE_DESIGN,
                        elements
                    )
                );
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };
    useEffect(() => {
        GoogleMapService.loadScript('google');
        fetchPriceCalculatorContent('price-calculator');
        const addOsbEuHeader = () => {
            const elementHeader = document.getElementById('euheaderId');
            if (elementHeader) {
                elementHeader.classList.add('osbEuHeader');
            }
        };
        const removeOsbEuHeader = () => {
            const osbEuHeader = document.getElementsByClassName('osbEuHeader');
            if (osbEuHeader.length > 0) {
                const elementHeader = document.getElementById('euheaderId');
                if (elementHeader) {
                    elementHeader.classList.remove('osbEuHeader');
                }
            }
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', addOsbEuHeader);
            window.addEventListener('popstate', removeOsbEuHeader);
            return () => {
                const elementHeader = document.getElementById('euheaderId');
                if (elementHeader) {
                    elementHeader.classList.remove('osbEuHeader');
                }
                window.removeEventListener('scroll', addOsbEuHeader);
                window.removeEventListener('popstate', removeOsbEuHeader);
            };
        }
    }, []);
    return (
        <React.Fragment>
            {priceCalculatorContent.length > 0 &&
                (newDesign ? (
                    <ServicePriceCalculatorWrapper
                        priceCalculatorContent={priceCalculatorContent}
                    />
                ) : (
                    <ServicePriceCalculator />
                ))}
        </React.Fragment>
    );
};
export default ServicePriceCalculatorView;
