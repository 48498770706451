import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
    ReactNode,
} from 'react';
import { Card } from '../../../card/card';
import PrimaryButton from '../../../../common/primary-button/primary-button';
import { SwipePoint, useSwipe } from '../../../../../hooks/use-swipe';

import './featured-articles-carousel.scss';
import { Heading } from '../../../../common/heading/heading';
import { FdsChevron } from '../../../../common/chevron/fds-chevron';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';
import { KEYBOARD_KEYS } from '../../../../../constants';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

interface Props {
    title: string;
    titleWithDisclaimer?: ReactNode;
    subtitle: string;
    subtitleWithDisclaimer?: ReactNode;
    role?: string;
    ariaLive?: string;
    ariaLabel?: string;
    paginationText?: string;
    rightButtonAriaLabel?: string;
    leftButtonAriaLabel?: string;
    cards: FMCardProps[];
    analyticsClickEventName?: string;
}

export const FeaturedArticlesCarousel = (props: Props) => {
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
    const cardRef = useRef<HTMLDivElement>(null);
    const carouselContainerRef = useRef<HTMLDivElement>(null);
    const carouselControlsRef = useRef<HTMLDivElement>(null);
    const carouselHeadingRef = useRef<HTMLDivElement>(null);
    const maxCardIndex = props.cards.length - 1;
    const maxCards = props.cards.length;
    const sectionId = props.title?.replace(/ /g, '-').toLowerCase();
    const [singleCardDisplay, setSingleCardDisplay] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 980px)').matches
            : false
    );
    const [tripleCardDisplay, setTripleCardDisplay] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(min-width: 1320px)').matches
            : false
    );

    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX, setSwipeOffsetX]
    );

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (let cardIndex = 0; cardIndex <= maxCardIndex; cardIndex++) {
            const cardPosX =
                cardIndex *
                (cardRef.current?.getBoundingClientRect().width || 0);
            if (
                Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
            ) {
                closestCardIndex = cardIndex;
                closestCardX = cardPosX;
            }
        }
        setCurrentCardIndex(closestCardIndex);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        cardRef,
        swipeOffsetX,
        setSwipeStartX,
        setCurrentCardIndex,
        maxCardIndex,
        cardsPositionX,
    ]);

    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint) => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );

    const containerRef = useSwipe(
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );

    const updateCardPositions = useCallback(() => {
        setSingleCardDisplay(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 980px)').matches
                : false
        );
        setTripleCardDisplay(
            ServerSideService.isClientSide()
                ? window.matchMedia('(min-width: 1320px)').matches
                : false
        );
        setCardsPositionX(
            -currentCardIndex *
                (cardRef.current?.getBoundingClientRect().width || 0)
        );
    }, [cardRef, currentCardIndex, setCardsPositionX]);

    useEffect(() => {
        if (ServerSideService.isClientSide()) {
            window.addEventListener('resize', updateCardPositions);
            return () => {
                window.removeEventListener('resize', updateCardPositions);
            };
        }
    }, [updateCardPositions]);

    useLayoutEffect(() => {
        updateCardPositions();
    }, [currentCardIndex, updateCardPositions]);

    function moveToNextCard() {
        if (currentCardIndex < maxCardIndex) {
            setCurrentCardIndex(currentCardIndex + 1);
        }
    }

    function moveToPreviousCard() {
        if (currentCardIndex > 0) {
            setCurrentCardIndex(currentCardIndex - 1);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.shiftKey && event.key === KEYBOARD_KEYS.TAB) {
            moveToPreviousCard();
        } else if (event.key === KEYBOARD_KEYS.TAB) {
            moveToNextCard();
        }
    };

    return (
        <article
            className="featured-articles-carousel-container"
            data-testid="featured-articles-carousel-id"
            id={sectionId}
            ref={carouselContainerRef}
        >
            <div
                className="featured-articles-carousel-heading"
                ref={carouselHeadingRef}
            >
                {props.title && (
                    <Heading
                        type="h2"
                        className="featured-articles-carousel-title"
                    >
                        {props.titleWithDisclaimer || props.title}
                    </Heading>
                )}
                {props.subtitle && (
                    <p className="featured-articles-carousel-subtitle">
                        {props.subtitleWithDisclaimer || props.subtitle}
                    </p>
                )}
            </div>
            <section
                id={`Carousel-${sectionId}`}
                className="featured-articles-carousel"
                ref={containerRef}
            >
                <div
                    className="featured-articles-carousel-cards"
                    style={{
                        transform: `translateX(${cardsPositionX +
                            swipeOffsetX}px)`,
                        transition: swipeStartX
                            ? 'none'
                            : 'transform 300ms linear',
                    }}
                >
                    {props.cards.map((card, index) => {
                        return (
                            <div
                                ref={cardRef}
                                key={index}
                                id={`C-${sectionId}` + (index + 1)}
                            >
                                <Card
                                    headerTitle={props.title}
                                    data={card}
                                    handleKeyDown={handleKeyDown}
                                    analyticsClickEventName={
                                        props.analyticsClickEventName
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </section>
            <section
                className="featured-articles-carousel-controls"
                ref={carouselControlsRef}
            >
                <PrimaryButton
                    className={`featured-articles-carousel-button ${
                        currentCardIndex === 0 ? 'disabled' : ''
                    }`}
                    color="dark"
                    fill="fill"
                    chevron={false}
                    onClick={moveToPreviousCard}
                    ariaLabel={props.leftButtonAriaLabel || 'Previous'}
                >
                    <FdsChevron type="unfilled" direction="left" />
                </PrimaryButton>
                <div className="featured-articles-carousel-indicator">
                    {singleCardDisplay && (
                        <span
                            aria-live={(props.ariaLive as any) || 'polite'}
                            role={props.role || 'alert'}
                            aria-label={props.ariaLabel || 'current card'}
                        >
                            {`${currentCardIndex + 1} ${props.paginationText ||
                                'of'} ${maxCards}`}
                        </span>
                    )}
                    {!singleCardDisplay && !tripleCardDisplay && (
                        <span
                            aria-live={(props.ariaLive as any) || 'polite'}
                            role={props.role || 'alert'}
                            aria-label={props.ariaLabel || 'current card'}
                        >
                            {`${currentCardIndex + 1} -
                            ${
                                currentCardIndex + 2 < maxCards
                                    ? currentCardIndex + 2
                                    : maxCards
                            }
                            ${props.paginationText || 'of'} ${maxCards}`}
                        </span>
                    )}
                    {tripleCardDisplay && (
                        <span
                            aria-live={(props.ariaLive as any) || 'polite'}
                            role={props.role || 'alert'}
                            aria-label={props.ariaLabel || 'current card'}
                        >
                            {`${currentCardIndex + 1} -
                            ${
                                currentCardIndex + 3 < maxCards
                                    ? currentCardIndex + 3
                                    : maxCards
                            }
                            ${props.paginationText || 'of'} ${maxCards}`}
                        </span>
                    )}
                </div>
                <PrimaryButton
                    className={`featured-articles-carousel-button ${
                        currentCardIndex === maxCardIndex ? 'disabled' : ''
                    }`}
                    color="dark"
                    fill="fill"
                    chevron={false}
                    onClick={moveToNextCard}
                    ariaLabel={props.rightButtonAriaLabel || 'Next'}
                >
                    <FdsChevron type="unfilled" direction="right" />
                </PrimaryButton>
            </section>
        </article>
    );
};
