import { VehicleData } from '../../../services/vehicle-type-service/vehicle-type-service';
import { StringUtil } from '../string-util/string-util';
export class VehicleUtil {
    public getVehicleName(vehicleData: any, isRegionNA: boolean): string {
        if (isRegionNA === true) {
            return new StringUtil().concatenate(
                ' ',
                vehicleData?.year,
                vehicleData?.make,
                vehicleData?.model
            );
        }
        return new StringUtil().concatenate(
            ' ',
            vehicleData?.make,
            vehicleData?.model,
            vehicleData?.year
        );
    }
    public getVehicleObject(
        vehicleData: VehicleData | undefined,
        vin: string
    ): any {
        return {
            year: vehicleData?.year,
            make: vehicleData?.make,
            model: vehicleData?.model,
            vin,
            warrantyStartDate: vehicleData?.warrantyStartDate,
        };
    }
    public getGarageVehicleName(vehicleData: any): string {
        return vehicleData.nickName
            ? vehicleData.nickName
            : new StringUtil().concatenate(
                  ' ',
                  vehicleData.modelYear,
                  vehicleData.vehicleMake,
                  vehicleData.modelName
              );
    }
}
