import React, { useContext } from 'react';
import './countdown.scss';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import useAemVehicleModelData from '../../../../hooks/use-aem-vehicle-model-data';
import ServerContext from '../../../../contexts/serverContext';
import { useHowToVideoContent } from '../../../../hooks/dyf/use-how-to-video-content/use-how-to-video-content';
import { SecondaryButton } from '../../../common';

interface PropsCount {
    vehicleAttributes: VehicleAttributes;
}

export const CountDown = (props: PropsCount) => {
    const year = props.vehicleAttributes.year.toString();
    const model = props.vehicleAttributes.model;
    const make = props.vehicleAttributes.make;
    const serverContext = useContext(ServerContext);
    const baseRoot = serverContext.root;
    const { currentLanguageRegionCode, currentRegionCode, brand } = useContext(
        ServerContext
    );
    const howToVideoContent = useHowToVideoContent();
    const modelSeoKey = useAemVehicleModelData(
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        year,
        model,
        'DISPLAY_MODEL_NAME'
    )?.seoKey;
    const livestreamCta = `vehicle/${modelSeoKey}/${year}/discover-your-ford/livestream`;

    return (
        <>
            {howToVideoContent && (
                <div className="time" data-testid="countdown-component">
                    <div className="mobile-inline">
                        <div className="time-text">
                            <b className="upper-case">
                                {year} {make} {model}{' '}
                                {howToVideoContent?.livestreamBannerYmmText}
                            </b>
                        </div>
                    </div>
                    <SecondaryButton
                        link={`${baseRoot}${livestreamCta}`}
                        aria-label={
                            howToVideoContent?.livestreamBannerCtaAriaLabel
                        }
                        className="countdown-banner-cta"
                    >
                        {howToVideoContent?.livestreamBannerCtaText}
                    </SecondaryButton>
                </div>
            )}
        </>
    );
};
