import React from 'react';
import './osb-card-lincoln.scss';
import parse from 'html-react-parser';
import { FMButton } from '@own/fds-react';
interface Props {
    title?: string;
    description?: string;
    ctaLabel?: string;
    onClick?: (event: any) => void;
    index?: number;
}
export default function OSBCard(props: Props) {
    return (
        <div className="osb-card" key={props.index}>
            <div className="osb-card-title">{props.title}</div>
            <div className="osb-card-description">
                {props.description && parse(props.description)}
            </div>
            <div className="osb-card-cta">
                <FMButton
                    type="primary"
                    label={props.ctaLabel}
                    theme="light"
                    onClick={props.onClick}
                    aria-label="service-card-list-cta"
                    aria-labelledby="select a service cta"
                    role="link"
                />
            </div>
        </div>
    );
}
