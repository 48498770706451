import powerTrainIcon from '../../../../../assets/powertrain-icon.svg';
import brakesSuspenseIcon from '../../../../../assets/brakes-sus-icon.svg';
import fluidsFiltersIcon from '../../../../../assets/fluids-filters-icon.svg';
import restrainIcon from '../../../../../assets/restraints-icon.svg';
import downloadCopyIcon from '../../../../../assets/download-copy-icon.png';
import { BookAServiceCTA } from '../book-a-service/book-a-service';
import { isMobileView, scrollToTop } from '../../vha-utils';
import { Accordion, PrimaryButton } from '../../../../common';
import './diagnostics-details.lincoln.scss';
import React, { useEffect, useState } from 'react';
import {
    getDiagnosticAlertsData,
    getDiagnosticAlertsList,
    getDiagnosticCardState,
    getDiagnosticsHeadlineColor,
    getDiagnosticsStatusColor,
    getDiagnosticsDescription,
} from '../vha-alert-list/diagnostic-alerts/diagnostic-alert-utils';
import { getVin } from '../../../../utils/getVin-util/getVin-util';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';
export interface Props {
    content: any;
    categoryId: any;
}
export const DiagnosticsDetails = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { diagnosticsDetails } = props.content;
    const diagnosticsFaqLabel = diagnosticsDetails?.diagnosticsDetailsFAQLabel;
    const diagnosticsFaqDesc =
        diagnosticsDetails?.diagnosticsDetailsFAQDescription;
    const diagnosticsDetailsLabel = diagnosticsDetails?.diagnosticsDetailsLabel;
    const diagnosticsWhatIsHappening = diagnosticsDetails?.whatIsHappeningLabel;
    const diagnosticsWhatShouldIdo = diagnosticsDetails?.whatShouldIDoLabel;
    const bookServiceCTALabel = diagnosticsDetails?.bookServiceCTALabel;
    const bookServiceCTALink = diagnosticsDetails?.bookServiceCTALink;
    const isDownloadCopy = diagnosticsDetails?.enableDownloadCopy;
    const [diagnosticAPIDetails, setDiagnosticAPIDetails] = useState<any>();
    const [alertState, setAlertState] = useState<any>();
    const selectedVin = getVin();

    const triggerFluidsAndFiltersLoadAnalytics = () => {
        fireEvents(
            ['vehicle-health-fluids'],
            'vehicle-health-fluids',
            undefined,
            false
        );
    };

    const triggerPowertrainLoadAnalytics = () => {
        fireEvents(
            ['vehicle-health-powertrain'],
            'vehicle-health-powertrain',
            undefined,
            false
        );
    };
    const triggerBrakesLoadAnalytics = () => {
        fireEvents(
            ['vehicle-health-brakes-suspension'],
            'vehicle-health-brakes-suspension',
            undefined,
            false
        );
    };
    const triggerRestraintsLoadAnalytics = () => {
        fireEvents(
            ['vehicle-health-restraints'],
            'vehicle-health-restraints',
            undefined,
            false
        );
    };
    useEffect(() => {
        scrollToTop();

        if (props.categoryId === '0') {
            triggerPowertrainLoadAnalytics();
        } else if (props.categoryId === '1') {
            triggerBrakesLoadAnalytics();
        } else if (props.categoryId === '2') {
            triggerFluidsAndFiltersLoadAnalytics();
        } else if (props.categoryId === '3') {
            triggerRestraintsLoadAnalytics();
        }

        const fetchData = async () => {
            return await getDiagnosticAlertsData(selectedVin);
        };

        fetchData()
            .then(apidata => {
                if (apidata) {
                    const diagnosticsAlertList: any = getDiagnosticAlertsList(
                        apidata
                    );
                    diagnosticsAlertList.forEach((alert: any) => {
                        if (alert.categoryId == props.categoryId) {
                            setDiagnosticAPIDetails(alert);
                        }
                    });
                    setAlertState(
                        getDiagnosticCardState(
                            diagnosticsAlertList,
                            Number(props.categoryId)
                        )
                    );
                }
            })
            .catch(error => {
                console.error(error.message);
            });
    }, []);
    const getDiagnosticsIcon = () => {
        const { categoryId } = props;
        if (categoryId === '0') {
            return powerTrainIcon;
        } else if (categoryId === '1') {
            return brakesSuspenseIcon;
        } else if (categoryId === '2') {
            return fluidsFiltersIcon;
        } else if (categoryId === '3') {
            return restrainIcon;
        }
        return '';
    };

    const goToBookAService = () => {
        if (serverSideService.isClientSide()) {
            window.location.href = bookServiceCTALink;
        }
    };

    const accordionHeadline = (accordionContent: any) => {
        return (
            <>
                <div
                    className={getDiagnosticsHeadlineColor(
                        accordionContent.severity
                    )}
                >
                    {accordionContent.headline}
                </div>
            </>
        );
    };
    const panelDescription = (panelContent: any) => {
        return (
            <>
                <div className={'whatHappening'}>
                    {diagnosticsWhatIsHappening}
                </div>
                <div>{panelContent.whatIsHappening}</div>
                <div className={'whatShouldIdo'}>
                    {diagnosticsWhatShouldIdo}
                </div>
                <div>{panelContent.whatShouldIdo}</div>
            </>
        );
    };
    const getAccordionText = () => {
        return (
            <>
                <div
                    className={
                        alertState !== 'NORMAL'
                            ? 'diagnostics-accordion-wrapper'
                            : ''
                    }
                >
                    {alertState !== 'NORMAL' &&
                        diagnosticAPIDetails?.diagnosticsDetails?.map(
                            (operation: any, key: number) => (
                                <Accordion
                                    key={operation.headline}
                                    header={accordionHeadline(operation)}
                                    panel={panelDescription(operation)}
                                    index={key.toString()}
                                    expandMultiplePanels={false}
                                    className=""
                                />
                            )
                        )}
                </div>
            </>
        );
    };
    const getCategoryDesc = () => {
        return (
            <>
                <div>
                    <div className={'category-heading'}>
                        {diagnosticsFaqLabel}
                    </div>
                    <hr />
                    <div
                        className="category-desc"
                        dangerouslySetInnerHTML={{
                            __html: diagnosticsFaqDesc,
                        }}
                    />
                </div>
            </>
        );
    };

    const printDiagnosticDetailPage = () => {
        if (serverSideService.isClientSide()) {
            window.print();
        }
    };

    const downloadACopy = () => {
        return (
            <div
                className="diagnostics-details-download-copy-wrapper"
                onClick={printDiagnosticDetailPage}
            >
                <img
                    src={downloadCopyIcon}
                    alt="Download copy icon"
                    className="diagnostics-details-download-copy-icon"
                    aria-label="diagnostics-details-download-copy-icon"
                />
            </div>
        );
    };

    return (
        <>
            <div className="diagnostics-details-wrapper">
                <div className="diagnostics-details-header">
                    {diagnosticsDetailsLabel}
                    {isDownloadCopy && downloadACopy()}
                </div>
                <div className="diagnostics-content-wrapper">
                    <div className="diagnostics-icon-desc">
                        <div className="diagnostics-icon-holder">
                            <img
                                className="diagnostics-icon"
                                alt="diagnostics Icon"
                                src={getDiagnosticsIcon()}
                            />
                        </div>
                    </div>
                    <div className="diagnostics-status-wrapper">
                        <div
                            className={`status-text ${getDiagnosticsStatusColor(
                                alertState
                            )}`}
                        >
                            {getDiagnosticsDescription(
                                alertState,
                                diagnosticsDetails,
                                diagnosticAPIDetails
                            )}
                        </div>
                    </div>
                    {!isMobileView() && (
                        <div className="diagnostics-book-service">
                            <PrimaryButton
                                fill="fill"
                                color="dark"
                                chevron={false}
                                onClick={goToBookAService}
                                ariaLabel={bookServiceCTALabel}
                            >
                                {bookServiceCTALabel}
                            </PrimaryButton>
                        </div>
                    )}
                </div>
                {diagnosticAPIDetails && getAccordionText()}
                <div className={'diagnostics-category-wrapper'}>
                    {getCategoryDesc()}
                </div>
            </div>
            {isMobileView() && (
                <BookAServiceCTA
                    label={bookServiceCTALabel}
                    redirectUrl={bookServiceCTALink}
                />
            )}
        </>
    );
};
