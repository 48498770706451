import React, { useState, useEffect, useCallback, useRef } from 'react';
import { KeyValueItem } from '../../../../../models/experiencefragments/category-view';
import { FloatingPanel } from '../../../../common/floating-panel/floating-panel';
import lincolnClose from '../../../../../assets/lincoln-icons/white-close.svg';
import close from '../../../../../assets/close.svg';
import './language-bar.scss';
import { Modal } from '../../../../common/modal/modal';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';

interface Props {
    languages: KeyValueItem[];
}

export const LanguageBar = (props: Props) => {
    const { brand } = new AppConfigurationService();
    const [languageArrayDivider, setLanguageArrayDivider] = useState<number>(
        props.languages.length
    );

    const languageBarContainer = useRef<HTMLUListElement>(null);

    const [hideSimpleModal, setHideSimpleModal] = useState<boolean>(true);

    const lastFocusedButton = useRef<HTMLButtonElement>();
    const lastFocusTooltipItemIndex = useRef<number>();
    const buttonRefs = useRef<{ ref: HTMLButtonElement; key: string }[]>([]);
    buttonRefs.current = [];

    const tooltipFirstItemRef = useRef<HTMLButtonElement>();
    const tooltipLastItemRef = useRef<HTMLButtonElement>();
    const [modalContent, setModalContent] = useState<KeyValueItem>();
    const [panelOpen, setPanelOpen] = useState(false);

    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 500px)').matches
            : false
    );
    const handleWindowResize = useCallback(() => {
        setPanelOpen(false);
        const languageBarRight = languageBarContainer.current?.getBoundingClientRect()
            .right;
        if (languageBarRight) {
            let divider: number | undefined;
            if (buttonRefs.current.length > 0) {
                buttonRefs.current.forEach((item, index) => {
                    if (
                        !divider &&
                        item.ref.getBoundingClientRect().right >
                            languageBarRight
                    ) {
                        divider = index;
                    }
                });
            }
            if (divider) {
                setLanguageArrayDivider(divider);
            } else {
                setLanguageArrayDivider(props.languages.length);
            }
        }
    }, [setLanguageArrayDivider]);
    const openButton = useRef<HTMLButtonElement>();

    const tooltipArray =
        props.languages.length > languageArrayDivider
            ? props.languages.slice(
                  languageArrayDivider,
                  props.languages.length
              )
            : [];

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 500px)').matches
                : false
        );
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    const openModal = (element: HTMLButtonElement, item: KeyValueItem) => {
        setModalContent(item);
        lastFocusedButton.current = element;
        setHideSimpleModal(false);
    };

    const closeSimpleModal = () => {
        setModalContent(undefined);
        if (lastFocusTooltipItemIndex.current === undefined) {
            lastFocusedButton.current?.focus();
        }
        setHideSimpleModal(true);
    };

    const onToolTipClick = () => {
        setPanelOpen(!panelOpen);
        if (!panelOpen) {
            setTimeout(() => {
                tooltipFirstItemRef.current?.focus();
            }, 0);
        }
    };

    const isArabicLanguage = (input: string) =>
        input.match('[\u0621-\u064A]+') != null;

    return (
        <article className="language-bar">
            <div className="language-bar-list-wrapper">
                <ul className="language-bar-list" ref={languageBarContainer}>
                    {props.languages.map((item, index) => (
                        <li
                            key={item.itemKey}
                            style={{
                                visibility:
                                    index < languageArrayDivider
                                        ? 'visible'
                                        : 'hidden',
                            }}
                        >
                            <button
                                ref={ref => {
                                    if (ref) {
                                        buttonRefs.current.push({
                                            ref,
                                            key: item.itemKey,
                                        });
                                    }
                                }}
                                className="language-bar-list-button"
                                lang={item.lang}
                                onClick={event =>
                                    openModal(event.currentTarget, item)
                                }
                                tabIndex={
                                    index >= languageArrayDivider ? -1 : 0
                                }
                            >
                                {item.itemKey}
                            </button>
                        </li>
                    ))}
                </ul>
                {tooltipArray.length > 0 && (
                    <button
                        className="language-bar__ellipses"
                        ref={ref => {
                            if (ref) openButton.current = ref;
                        }}
                        onClick={onToolTipClick}
                    >
                        &#8942;
                    </button>
                )}
                {tooltipArray.length > 0 && (
                    <>
                        {openButton.current && (
                            <FloatingPanel
                                parent={openButton.current}
                                hidden={!panelOpen}
                                align="center"
                                disableFullWidthOnMobile={true}
                                onClose={() => {
                                    openButton.current?.focus();
                                    setPanelOpen(false);
                                }}
                            >
                                {isMobile && (
                                    <img
                                        className="language-bar__tooltip-dismiss-icon"
                                        src={
                                            brand === 'lincoln'
                                                ? lincolnClose
                                                : close
                                        }
                                        alt=""
                                        onClick={onToolTipClick}
                                    />
                                )}
                                <ul className="language-bar-tooltip-list">
                                    {tooltipArray.map((item, index) => (
                                        <li key={`${item.itemKey}`}>
                                            <button
                                                className="language-bar-list-button-ellipses"
                                                ref={ref => {
                                                    if (ref) {
                                                        if (index === 0) {
                                                            tooltipFirstItemRef.current = ref;
                                                        } else if (
                                                            index ===
                                                            tooltipArray.length -
                                                                1
                                                        ) {
                                                            tooltipLastItemRef.current = ref;
                                                        }
                                                        if (
                                                            lastFocusTooltipItemIndex.current !==
                                                                undefined &&
                                                            lastFocusTooltipItemIndex.current ===
                                                                index &&
                                                            hideSimpleModal
                                                        ) {
                                                            setTimeout(() => {
                                                                ref.focus();
                                                            }, 0);
                                                            lastFocusTooltipItemIndex.current = undefined;
                                                        }
                                                    }
                                                }}
                                                onClick={event => {
                                                    lastFocusTooltipItemIndex.current = index;
                                                    lastFocusedButton.current =
                                                        event.currentTarget;
                                                    openModal(
                                                        event.currentTarget,
                                                        item
                                                    );
                                                }}
                                                onKeyDown={event => {
                                                    if (
                                                        index === 0 &&
                                                        event.key === 'Tab' &&
                                                        event.shiftKey
                                                    ) {
                                                        event.preventDefault();
                                                        tooltipLastItemRef.current?.focus();
                                                    } else if (
                                                        index ===
                                                            tooltipArray.length -
                                                                1 &&
                                                        event.key === 'Tab' &&
                                                        !event.shiftKey
                                                    ) {
                                                        event.preventDefault();
                                                        tooltipFirstItemRef.current?.focus();
                                                    }
                                                }}
                                            >
                                                {item.itemKey}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </FloatingPanel>
                        )}
                    </>
                )}
            </div>
            {modalContent && !hideSimpleModal && (
                <Modal
                    name="language-bar-modal"
                    onClose={closeSimpleModal}
                    isVisible={!hideSimpleModal}
                >
                    <div
                        className={
                            isArabicLanguage(modalContent?.itemKey)
                                ? 'arabic-text'
                                : undefined
                        }
                        lang={modalContent?.lang}
                        dangerouslySetInnerHTML={{
                            __html: modalContent?.itemValue,
                        }}
                    />
                </Modal>
            )}
        </article>
    );
};
