import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from '../../../../common';
import Tab from '../../../../common/tabs/tab';
import Tabs from '../../../../common/tabs/tabs';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import ProfileService from '../../../../../services/profile-service/profile-service';
import { AddVehicle } from '../../../add-vehicle/add-vehicle';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';
import { PageType } from '../../../view-template/page-type';
import { findByAlias } from '../../../../../routes';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import useUserProfile from '../../../../../hooks/use-user-profile';
import './vehicle-tabs.scss';
import { RedirectData } from '../../../../../support/components/FMALogin/FMALogin';

interface Props {
    redirectToDashboard?: boolean;
    onTabClick?: any;
    onChange?: any;
    vehiclesData?: VehicleAttributes[];
    selectedIndex?: number;
    dropDownOnly?: boolean;
    onSelectedVehicleChange?: (vehicleAttributes: VehicleAttributes) => void;
    page: PageType;
    dropDownPlaceholder?: string;
    redirectData?: RedirectData;
}

export const VehicleTabs = (props: Props) => {
    const [selectedIndex, setSelectedTabIndex] = useState<number>(
        props.selectedIndex ? props.selectedIndex : 0
    );
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const tabLimit = 3;
    const mobileDevice = ServerSideService.isClientSide()
        ? window.matchMedia('(max-width: 500px)').matches
        : false;
    const history = useHistory();
    const [vehicleTabsWidth, setVehicleTabsWidth] = useState<number>(
        ServerSideService.isClientSide() ? window.innerWidth : 0
    );

    const handleWindowResize = () => {
        setVehicleTabsWidth(
            ServerSideService.isClientSide() ? window.innerWidth : 0
        );
    };

    const profileService = new ProfileService();
    const profile = useUserProfile();
    const [fireEvents] = useAnalytics();

    useEffect(() => {
        if (ServerSideService.isClientSide()) {
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
    }, [vehicleTabsWidth]);

    const goToDashboard = useCallback(
        (index: number) => {
            history.push(findByAlias('VehicleDashboardView'), {
                selectedIndex: index,
            });
        },
        [history]
    );

    const [selectedTitle, setSelectedTitle] = useState<string>('');
    const buildVehicleTitle = (data: VehicleAttributes) => {
        if (!data) {
            return '';
        }
        return data.nickName && data.nickName.trim() !== ''
            ? data.nickName.trim()
            : `${data.year} ${data.make} ${data.model}`;
    };

    useEffect(() => {
        if (profile) {
            if (profile.vehicles.length > 0) {
                const vehicles = [...profile.vehicles]
                    .sort(
                        (vehicle1, vehicle2) =>
                            parseInt(vehicle2.modelYear, 10) -
                            parseInt(vehicle1.modelYear, 10)
                    )
                    .map(vehicleDetail => {
                        return {
                            year: parseInt(vehicleDetail.modelYear, 10),
                            make: vehicleDetail.make,
                            model: vehicleDetail.modelName,
                            vin: vehicleDetail.vin,
                            ownerState: vehicleDetail.ownerState,
                            registrationNo: vehicleDetail.licenseplate,
                            nickName: vehicleDetail.nickName,
                        } as VehicleAttributes;
                    });
                setVehiclesData(vehicles);
            }
        }
    }, [profile]);

    useEffect(() => {
        if (profile && vehiclesData.length > 0) {
            let [match, matchIndex] = [vehiclesData[0], 0];
            const selectedVin = profileService.getUserPreferences(
                profile.profile.email
            )?.lastSelectedVin;
            if (selectedVin) {
                match =
                    vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                    vehiclesData[0];
                matchIndex = vehiclesData.findIndex(
                    vehicle => vehicle.vin === selectedVin
                );
                !match && (match = vehiclesData[0]);
                matchIndex === -1 && (matchIndex = 0);
            }
            if (
                !vehiclesData[selectedIndex].vin &&
                matchIndex !== selectedIndex
            ) {
                match = vehiclesData[selectedIndex];
                matchIndex = selectedIndex;
            }

            props.onSelectedVehicleChange &&
                props.onSelectedVehicleChange(vehiclesData[matchIndex]);

            setSelectedTabIndex(matchIndex);
            setSelectedTitle(buildVehicleTitle(match));
        }
    }, [vehiclesData, selectedIndex]);

    const storeUserPreference = (
        vehiclesData: VehicleAttributes[],
        index: number
    ) => {
        profile &&
            profileService.persistVehicleSelection(
                profile.profile.email,
                vehiclesData[index].vin
            );
    };

    const selectVehicle = (title: string, index: number) => {
        if (props.onChange) {
            props.onChange(title, index, vehiclesData[index]);
        }
        storeUserPreference(vehiclesData, index);
        setSelectedTabIndex(index);
        setSelectedTitle(title);
        if (props.redirectData?.id !== 'sync-maps') {
            fireEvents(
                'change-vehicle-onclick-event',
                undefined,
                undefined,
                false
            );
        }
        if (props.redirectToDashboard) {
            goToDashboard(index);
        }
    };
    const noVehiclesinGarage =
        vehiclesData.length > 0 ? '' : 'noVehiclesinGarage';
    const darkTab = props.page === 'DASHBOARD' ? '-dark' : '';
    const buttonColor = props.page === 'DASHBOARD' ? 'dark' : 'light';
    return (
        <>
            {profile && (
                <div className="vehicle-selector">
                    {props.dropDownOnly ||
                    vehiclesData.length > tabLimit ||
                    (vehiclesData.length > 0 && mobileDevice) ? (
                        <div className="vehicle-selector-header">
                            <Dropdown
                                label={props.dropDownPlaceholder || ''}
                                value={selectedTitle}
                                noLabel={true}
                                showLabel={false}
                                onChange={selectVehicle}
                                className={`vehicle-tabs-dropdown${darkTab} ${noVehiclesinGarage}`}
                                options={vehiclesData?.map(vehicleAttributes =>
                                    buildVehicleTitle(vehicleAttributes)
                                )}
                            />
                            {!props.dropDownOnly && (
                                <AddVehicle
                                    buttonColor={buttonColor}
                                    className={
                                        props.page === 'DASHBOARD'
                                            ? ''
                                            : ' add-vehicle-container-light'
                                    }
                                    buttonFill="outline"
                                    addButtonPlusSign={true}
                                    page={props.page}
                                />
                            )}
                        </div>
                    ) : (
                        <Tabs
                            className={darkTab}
                            onChange={selectVehicle}
                            activeTabIndex={selectedIndex}
                            activeTabTitle={selectedTitle}
                            secondaryChild={
                                tabLimit <= 3 &&
                                vehiclesData.length > 0 && (
                                    <div className="add-vehicle-container-tabs">
                                        <AddVehicle
                                            buttonColor={buttonColor}
                                            buttonFill="outline"
                                            page={props.page}
                                        />
                                    </div>
                                )
                            }
                        >
                            {vehiclesData?.map((vehicleAttributes, index) => (
                                <Tab
                                    onTabClick={props.onTabClick}
                                    key={index}
                                    title={buildVehicleTitle(vehicleAttributes)}
                                />
                            ))}
                        </Tabs>
                    )}
                </div>
            )}
        </>
    );
};
