import {
    API_REQUEST_SOURCE_TYPE,
    SERVICE_DELIVERY_TYPE,
} from '../../../components/sections/owners-osb/osb-constant';
import { transformServiceResponse } from '../../../components/sections/owners-osb/osb-service-adapters/dealer-services-adapter';
import {
    DealerServices,
    DealerServicesArray,
} from '../../../models/osb-model/osb-dealerservice-info';
import {
    DealerServicesRequest,
    DealerServicesResponse,
} from '../../../models/osb-model/osb-service-step';
import { VehicleData } from '../../../models/osb-model/osb-vehicle-step';
import { OSBAPIResponse } from '../service-orchestration/service-adapter';
import {
    ErrorResponse,
    transformErrorResponse,
} from '../../../components/sections/owners-osb/osb-utils';
import { DSLAPIService } from '../service-orchestration/service-dsl';
import { DSL_API_PATH } from '../../../constants';
import { Bookable } from '../../../hooks/owners-osb/use-all-osb-state';
import { OSBStep } from '../../../models/osb-model/osb-step';
import { ExtractValueType } from '../../../components/sections/owners-osb/osb-bookable-utils';

export type DealerServiceAPIResponse = {
    value: {
        dealerServices: DealerServices;
    };
};

export class OSBDealerServicesClient {
    public getServicesList(args: {
        dealerCode: string;
        vehicleData: VehicleData;
        vouchers?: string[];
        bookingReferenceNumber?: string;
        serviceDeliveryType?: string;
        requestSourceType?: string;
        bookable: Bookable;
        DSLAPIVersion: ExtractValueType<OSBStep, 'DSLAPIVersion'>;
    }) {
        if (args.bookable && args.DSLAPIVersion === 'V5') {
            return this.getByPostServiceList(args.bookable);
        }
        const dealerServiceRequest: DealerServicesRequest = {
            vehicle: args.vehicleData,
            delivery:
                args.serviceDeliveryType || SERVICE_DELIVERY_TYPE.WORKSHOP,
            source: args.requestSourceType || API_REQUEST_SOURCE_TYPE.WEB,
        };

        if (args.vouchers && args.vouchers.length > 0) {
            dealerServiceRequest.voucherCodes = args.vouchers.join(',');
        }
        if (args.bookingReferenceNumber) {
            dealerServiceRequest.bookingReferenceNumber =
                args.bookingReferenceNumber;
        }

        return new Promise<OSBAPIResponse<DealerServicesArray>>(
            (resolve, reject) => {
                const transformedResponse: OSBAPIResponse<DealerServicesArray> = {
                    value: {} as DealerServicesArray,
                    responseError: {} as ErrorResponse,
                };
                new DSLAPIService()
                    .callServiceAPIOnDealerCode<
                        DealerServicesRequest,
                        DealerServicesResponse
                    >(
                        dealerServiceRequest,
                        args.dealerCode,
                        DSL_API_PATH.OSB_DEALER_SERVICES
                    )
                    .then(response => {
                        try {
                            transformedResponse.value = transformServiceResponse(
                                response
                            );
                            resolve(transformedResponse);
                        } catch (err) {
                            transformedResponse.responseError.errorMessage =
                                'DEALER_SERVICES_CLIENT_TRANSFORMATION_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    })
                    .catch(_error => {
                        transformedResponse.responseError = transformErrorResponse(
                            _error
                        );
                        reject(transformedResponse.responseError);
                        console.error(
                            'Rejected the promise - ',
                            transformedResponse.responseError
                        );
                    });
            }
        );
    }

    public getByPostServiceList(bookable: Bookable) {
        return new Promise<OSBAPIResponse<DealerServicesArray>>(
            (resolve, reject) => {
                const transformedResponse: OSBAPIResponse<DealerServicesArray> = {
                    value: {} as DealerServicesArray,
                    responseError: {} as ErrorResponse,
                };
                new DSLAPIService()
                    .postServiceAPIOnDealerCode<
                        Bookable,
                        DealerServicesResponse
                    >(
                        bookable,
                        bookable.dealerCode,
                        DSL_API_PATH.OSB_DEALER_SERVICESV5
                    )
                    .then(response => {
                        try {
                            transformedResponse.value = transformServiceResponse(
                                response
                            );
                            resolve(transformedResponse);
                        } catch (err) {
                            console.log(err);
                            transformedResponse.responseError.errorMessage =
                                'DEALER_SERVICES_CLIENT_TRANSFORMATION_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    })
                    .catch(_error => {
                        transformedResponse.responseError = transformErrorResponse(
                            _error
                        );
                        reject(transformedResponse.responseError);
                        console.error(
                            'Rejected the promise - ',
                            transformedResponse.responseError
                        );
                    });
            }
        );
    }
}
