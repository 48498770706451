import ServiceHandler from '../../../../../../services/service-handler';
import { replacePlaceholderByValue } from '../../../../../utils/placeholder-util/replace-placeholder-by-value';
import {
    CardState,
    TireLeak,
} from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';

export const getSlowTireLeakState = (tireLeakData: any) => {
    if (tireLeakData.featureType === 'TSL' && tireLeakData.shouldShow) {
        if (tireLeakData.urgency === 'H' || tireLeakData.urgency === 'U') {
            return CardState.ALERT;
        } else if (tireLeakData.urgency === 'M') {
            return CardState.WARNING;
        } else {
            return CardState.NORMAL;
        }
    } else {
        return CardState.NORMAL;
    }
};

export const getTireLeakDescription = (
    tireLeakData: any,
    tireLeakContent: TireLeak
) => {
    if (tireLeakData.featureType === 'TSL' && tireLeakData.shouldShow) {
        if (tireLeakData.urgency === 'H' || tireLeakData.urgency === 'U') {
            return tireLeakContent.tireLeakAlertDescription;
        } else if (tireLeakData.urgency === 'M') {
            return tireLeakContent.tireLeakWarningDescription;
        } else {
            return tireLeakContent.tireLeakSuccessDescription;
        }
    }
};
export const getVehicleStatus = (userSelectedVin: any) => {
    return ServiceHandler.ConnectedVehicleService.getVehicleStatus(
        userSelectedVin
    )
        .then(vehicleStatusData => {
            if (vehicleStatusData) {
                return vehicleStatusData;
            }
        })
        .catch(error => {
            console.error(error);
        });
};
const findWheeler = (tpms: any) => {
    let tireCount = 0;
    if (tpms?.leftFrontTirePressure) {
        tireCount += 1;
    }
    if (tpms?.rightFrontTirePressure) {
        tireCount += 1;
    }
    if (tpms?.outerLeftRearTirePressure) {
        tireCount += 1;
    }
    if (tpms?.outerRightRearTirePressure) {
        tireCount += 1;
    }
    if (tpms?.innerLeftRearTirePressure) {
        tireCount += 1;
    }
    if (tpms?.innerRightRearTirePressure) {
        tireCount += 1;
    }
    return tireCount;
};
export const getSlowTireLeakData = (
    vehicleStatusData: any,
    oilLifeData: any
) => {
    const vehicleData = vehicleStatusData?.vehiclestatus?.TPMS;
    return {
        tireWithSlowLeak: oilLifeData?.featureData?.tireWithSlowLeak,
        shouldShow: oilLifeData?.shouldShow,
        urgency: oilLifeData?.urgency,
        eventTime: oilLifeData?.eventTime,
        wheelers: findWheeler(vehicleData),
        leftFrontTirePressure: vehicleData?.leftFrontTirePressure?.value,
        rightFrontTirePressure: vehicleData?.rightFrontTirePressure?.value,
        outerLeftRearTirePressure:
            vehicleData?.outerLeftRearTirePressure?.value,
        outerRightRearTirePressure:
            vehicleData?.outerRightRearTirePressure?.value,
        innerLeftRearTirePressure:
            vehicleData?.innerLeftRearTirePressure?.value,
        innerRightRearTirePressure:
            vehicleData?.innerRightRearTirePressure?.value,
        recommendedFrontTirePressure:
            vehicleData?.recommendedFrontTirePressure?.value,
        recommendedRearTirePressure:
            vehicleData?.recommendedRearTirePressure?.value,
    };
};
export const getSlowTireLeakStatusColor = (alertState: any) => {
    if (alertState === 'WARNING') {
        return 'warning-color';
    } else if (alertState === 'ALERT') {
        return 'alert-color';
    }
};
export const getTLSStatus = (TLSData: any) => {
    if (TLSData?.urgency === 'M') {
        return CardState.WARNING;
    } else if (TLSData?.urgency === 'H' || TLSData?.urgency === 'U') {
        return CardState.ALERT;
    } else {
        return CardState.NORMAL;
    }
};
export const getTLSDescription = (
    alertState: any,
    slowTireLeakData: any,
    slowTireLeakDetails: any
) => {
    if (alertState === 'NORMAL') {
        return slowTireLeakDetails?.slowTireLeakSuccessDescription;
    } else if (alertState === 'WARNING') {
        return replacePlaceholderByValue(
            slowTireLeakDetails?.slowTireLeakWarningDescription,
            {
                warningCount: slowTireLeakData.tireWithSlowLeak.length,
            }
        );
    } else if (alertState === 'ALERT') {
        return replacePlaceholderByValue(
            slowTireLeakDetails?.slowTireLeakAlertDescription,
            {
                alertCount: slowTireLeakData?.tireWithSlowLeak.length,
            }
        );
    }
};
const getTimeConversion = (eventTime: any) => {
    const splittedHour = eventTime.split(':')[0];
    const splittedMins = eventTime.split(':')[1];
    if (splittedHour >= 12) {
        return (Number(splittedHour) % 12) + ':' + splittedMins;
    }
    return splittedHour + ':' + splittedMins;
};
const getTimeUnit = (eventTime: any, slowTireLeakDetails: any) => {
    if (
        eventTime?.split(' ')[1].split(':')[0] >= 12 &&
        eventTime?.split(' ')[1].split(':')[1] > 0
    ) {
        return slowTireLeakDetails?.pmLabel;
    } else {
        return slowTireLeakDetails?.amLabel;
    }
};
export const getLastDetected = (eventTime: any, slowTireLeakDetails: any) => {
    return replacePlaceholderByValue(
        slowTireLeakDetails?.lastDetectedDataTime,
        {
            date: eventTime?.split(' ')[0],
            time: getTimeConversion(eventTime?.split(' ')[1]),
            timeUnit: getTimeUnit(eventTime, slowTireLeakDetails),
        }
    );
};
