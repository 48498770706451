import React, { ReactNode } from 'react';
import Popup from 'reactjs-popup';
import { OSBSecondaryButton } from '../osb-secondary-button/osb-secondary-button';
import './osb-video-image-overlay.scss';

interface Props {
    isOpen: boolean;
    backButtonText?: string;
    closeOnDocumentClick?: boolean;
    hideVideoOverlay: () => void;
    hasCloseButton?: boolean;
    videoImageTitle?: string;
    children: ReactNode;
    isVideo?: boolean;
}

export const OSBVideoImageOverlay: React.FC<Props> = ({
    isOpen,
    backButtonText = '',
    closeOnDocumentClick = false,
    children,
    hideVideoOverlay,
    hasCloseButton = false,
    videoImageTitle = '',
    isVideo = false,
}) => {
    return (
        <Popup
            className="osb-video-image-overlay"
            modal={true}
            closeOnDocumentClick={closeOnDocumentClick}
            open={isOpen}
        >
            <div
                className={`osb-video-image-content-container ${
                    isVideo ? 'osb-add-video-style' : ''
                }`}
            >
                <div className="overlay-header">
                    {backButtonText && (
                        <OSBSecondaryButton
                            dataTestId="BackLinkFromOSBVideoImageOverlay"
                            direction="left"
                            type="filled"
                            text={backButtonText}
                            onClickHandler={hideVideoOverlay}
                        />
                    )}
                    {hasCloseButton && (
                        <div className="overlay-button-tray">
                            <ul>
                                <li
                                    data-testid="CloseImageOverlayPopupButton"
                                    className="close"
                                    onClick={hideVideoOverlay}
                                >
                                    &times;
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                {children}
                {videoImageTitle && (
                    <div className="osb-video-image-title">
                        {' '}
                        {videoImageTitle}
                    </div>
                )}
            </div>
        </Popup>
    );
};
