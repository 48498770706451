import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';
import HttpService from '../../http-service/http-service';
import OsbUtilService from '../osb-util-service/osb-util-service';
import { replaceTextKeyword } from '../../../components/sections/owners-osb/osb-utils';

export default class OsbRetrieveAccessCodeService {
    async request(email: string) {
        const appConfig = OsbUtilService.getAppConfiguration();
        const url = `${DSL_BASE_URL}${DSL_API_PATH.OSB_RETRIEVE_ACCESS_CODE}`;

        const endpointUrl = replaceTextKeyword(url, {
            ':locale': appConfig.languageRegionCode,
        });
        const headers = {
            identifier: email,
        };

        return HttpService.get(endpointUrl, false, { headers }).then(
            (response: any) => {
                return response.data;
            }
        );
    }
}
