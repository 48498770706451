import React from 'react';
import './preferred-service-centre-skeleton.scss';

const PreferredServiceCentreSkeleton = () => {
    return (
        <div
            className="osb-v3-preferred-service-centre-wrapper skeleton"
            data-testid="preferred-service-centre-skeleton"
        >
            <div className="osb-v3-preferred-service-centre-heading skeleton-placeholder"></div>
            <div className="osb-v3-preferred-service-centre">
                <div className="osb-v3-preferred-title skeleton-placeholder"></div>
                <div className="osb-v3-preferred-centre-name skeleton-placeholder"></div>
                <div className="osb-v3-preferred-button-container">
                    <div className="osb-v3-preferred-continue-button skeleton-button"></div>
                    <div className="osb-v3-preferred-search-button skeleton-button"></div>
                </div>
            </div>
        </div>
    );
};

export default PreferredServiceCentreSkeleton;
