import React, { ReactNode } from 'react';
import './vehicle-checklist.scss';
import { SecondaryButton } from '../../common';
import { useHistory } from 'react-router';
import { useAnalytics } from '../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { VehicleChecklistOnClickCtaInfo } from '../../../services/shortcode-service/shortcode-service';
import { AEM_DAM_PATH, BRAND } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { FMButton } from '@own/fds-react';
import { URLUtil } from '../../utils/url-util/url-util';

export type HeaderType = 'good' | 'moderate' | 'severe' | 'blue' | 'none';
export interface Props {
    className?: string;
    header: string;
    type: string;
    headerType?: HeaderType;
    children?: ReactNode;
    description: string | ReactNode;
    ctaTitle: string;
    ctaPath: string;
    iconPath: string;
    onCtaHrefClick?: (e: React.MouseEvent) => void;
    ctaAriaLabel: string;
    ctaTargetBlank: boolean;
}
const VehicleChecklist = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const cardType = props.type;
    const vehicleChecklistOnClickCtaInfo: VehicleChecklistOnClickCtaInfo = {
        cardType,
    };
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    const history = useHistory();
    const isRelativeCta = URLUtil.isRelativeUrl(props.ctaPath);
    async function handleEvents(e: React.MouseEvent) {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            props.ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('vehicle-checklist-onclick-event', undefined, {
                vehicleChecklistOnClickCtaInfo,
            });
        }
        if (props.onCtaHrefClick) props.onCtaHrefClick(e);
        else if (isRelativeCta) {
            history.push(props.ctaPath);
        }
    }

    const getIcon = (): string => {
        if (props.iconPath.includes(AEM_DAM_PATH))
            return process.env.REACT_APP_AEM_BASE_URL + props.iconPath;
        return props.iconPath;
    };

    function getHref() {
        return isRelativeCta && props.ctaPath.startsWith('/')
            ? props.ctaPath.substring(1)
            : props.ctaPath;
    }

    return (
        <>
            {currentVersion ? (
                <div
                    className={['vehicle-checklist', props.className]
                        .join(' ')
                        .trim()}
                >
                    <div className={'vehicle-checklist-text-container'}>
                        <div className={'vehicle-checklist-heading'}>
                            {props.header}
                            {props.iconPath && (
                                <img
                                    className={[
                                        'vehicle-checklist-icon',
                                        props.className,
                                        `${props.headerType || 'none'}`,
                                    ]
                                        .join(' ')
                                        .trim()}
                                    src={getIcon()}
                                    alt=""
                                />
                            )}
                        </div>
                        {props?.description && (
                            <div className={'vehicle-checklist-description'}>
                                {props.description}
                            </div>
                        )}
                        {props.children}
                    </div>
                    <SecondaryButton
                        className={'vehicle-checklist-cta'}
                        link={
                            isRelativeCta && props.ctaPath.startsWith('/')
                                ? props.ctaPath.substring(1)
                                : props.ctaPath
                        }
                        linkTarget={
                            !isRelativeCta && props.ctaTargetBlank
                                ? '_blank'
                                : ''
                        }
                        onClick={(e: React.MouseEvent) => {
                            handleEvents(e);
                        }}
                        aria-label={props.ctaAriaLabel || props.ctaTitle}
                        preventCtaDefault={isRelativeCta || undefined}
                    >
                        {props.ctaTitle}
                    </SecondaryButton>
                </div>
            ) : (
                <div
                    className={[
                        'vehicle-checklist',
                        'vehicle-checklist-preimagine',
                        props.className,
                    ]
                        .join(' ')
                        .trim()}
                >
                    <div
                        className={
                            'vehicle-checklist-preimagine-text-container'
                        }
                    >
                        <div className={'vehicle-checklist-preimagine-heading'}>
                            {props.iconPath && (
                                <img
                                    className={[
                                        'vehicle-checklist-preimagine-icon',
                                        props.className,
                                        `${props.headerType || 'none'}`,
                                    ]
                                        .join(' ')
                                        .trim()}
                                    src={getIcon()}
                                    alt=""
                                />
                            )}
                            <span
                                className={
                                    'vehicle-checklist-preimagine-header'
                                }
                            >
                                {' '}
                                {props.header}{' '}
                            </span>
                        </div>
                        {props?.description && (
                            <div
                                className={
                                    'vehicle-checklist-preimagine-description'
                                }
                            >
                                {props.description}
                            </div>
                        )}
                        {props.children}
                    </div>
                    {props.ctaTitle && (
                        <FMButton
                            type={'tertiary'}
                            label={props.ctaTitle}
                            href={getHref()}
                            chevron={'right'}
                            target={
                                !isRelativeCta && props.ctaTargetBlank
                                    ? '_blank'
                                    : ''
                            }
                            onClick={(e: React.MouseEvent) => {
                                handleEvents(e);
                            }}
                            ariaLabel={props.ctaAriaLabel || props.ctaTitle}
                            preventDefault={isRelativeCta || undefined}
                        ></FMButton>
                    )}
                </div>
            )}
        </>
    );
};
export default VehicleChecklist;
