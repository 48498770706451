/* This component is for CCPA Banner display for US market.
Content should not be placed other than in US market.
*/
import React from 'react';
import './ccpa-banner.scss';
import { Link } from '../../common';
import { useCcpaBannerContent } from './hook/use-ccpa-banner';
export const CcpaBanner = () => {
    const ccpaContent = useCcpaBannerContent();
    return (
        <>
            {ccpaContent && (
                <div className="ccpa">
                    <h3>{ccpaContent.headerTitle}</h3>
                    <div>
                        <span
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: ccpaContent.content,
                            }}
                        />
                        <p className="ccpa-button">
                            <Link
                                className="link"
                                href={ccpaContent.ctaLink}
                                target={ccpaContent.ctaTarget}
                                aria-label={ccpaContent.ctaAriaLabel}
                            >
                                {ccpaContent.ctaText}
                            </Link>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};
