import React, { useEffect, useState } from 'react';
import './light-retrieve-booking-summary-lincoln.scss';
import { Modal } from '../../../../common/modal/modal';
import ServiceHandler from '../../../../../services/service-handler';
import {
    SERVICE_FLOW,
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
    ACTIVITY_INDICATOR_KEYS,
} from '../../osb-constant';
import {
    usePersonalDetailStep,
    useCalendarStep,
    useServiceStep,
    useDealerStep,
    useVehicleStep,
    useRetrieveBookingStep,
    useReviewBookingStep,
    useLightDeliveryOptionServiceStep,
    useHttp,
    useStepProgressBar,
    useOSBStep,
    useServiceLocationStep,
    useOsbImageUpload,
    usePageRefresh,
    useViewport,
} from '../../../../../hooks/owners-osb';
import { FdsChevron } from '../../../../common/chevron/fds-chevron';
import { LightRetrieveBookingDetails } from '../../retrieve-booking-details/light-journey/light-retrieve-booking-details';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import { OSBSecondaryButton } from '../../common/osb-secondary-button/osb-secondary-button';
import parse from 'html-react-parser';
import {
    OsbPathBookingCancelStep,
    OsbPathBookingConfirmationStep,
    OsbPathBookingRetrieveStep,
    OsbPathBookingRetrieveSummaryStep,
} from '../../osb-controller';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import {
    triggerRetrieveBookingCancellationAnalytics,
    triggerRetrieveBookingLogoutAnalytics,
    triggerReviewAmendLoadAnalytics,
    triggerReviewBookingLoadAnalytics,
    triggerRetrieveBookingSummaryAnalytics,
} from '../../analytics/osb-analytics';
import { AcceptedFileInfo } from '../../common/osb-file-uploader/osb-file-uploader';
import {
    buildNavigationUrl,
    removeAllClientStorage,
    setRouteToPersist,
    getGoMainHeaderHeight,
} from '../../osb-utils';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import {
    BookingAuthorization,
    CancelBookingRequest,
} from '../../../../../models/osb-model/osb-retrieve-booking';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';
import { FMButton } from '@own/fds-react';

interface Props {
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}

export const LightRetrieveBookingSummary = (props: Props) => {
    const { resetStepProgressBar } = useStepProgressBar();
    const history = useHistory();
    const {
        osbPersonalDetail,
        resetPersonalDetailStep,
    } = usePersonalDetailStep();
    const { resetCalendarStep } = useCalendarStep();
    const { osbDealerStep, resetDealerStep } = useDealerStep();
    const { osbServiceStep, resetServiceStep } = useServiceStep();
    const { osbVehicleStep, resetVehicleStep } = useVehicleStep();
    const { osbImageUploadStep } = useOsbImageUpload();
    const { osbReviewBooking } = useReviewBookingStep();
    const [error, setError] = useState('');
    const [amendServiceError, setAmendServiceError] = useState(false);
    const bookable = useAllOSBState();
    const errorDiv = error ? <div className="error">{error}</div> : '';
    const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
    const [
        isAmendConfirmationPopupVisible,
        setIsAmendConfirmationPopupVisible,
    ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [
        isCancelBookingModalVisible,
        setIsCancelBookingModalVisible,
    ] = useState(false);
    const [addLogoutModal] = ['add-logout-modal'];
    const [amendConfirmationModal] = ['amend-confirmation-modal'];
    usePageRefresh();
    const [bookingRefNo, setBookingRefNo] = useState('');
    const [accessCode, setAccessCode] = useState('');

    const { osbRetrieveBookingStep } = useRetrieveBookingStep();
    const [fireEvents] = useAnalytics();
    const { osbStep } = useOSBStep();
    const [isDisableButtonActions, setIsDisableButtonActions] = useState(false);
    const {
        osbLightDeliveryServiceStep,
        resetLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const [
        retrieveSummarBookingContent,
        setRetrieveSummarBookingContent,
    ] = useState<OsbContentResponse>();
    const { httpState, dispatch } = useHttp();
    const {
        osbServiceLocationDetail,
        resetServiceLocationStep,
    } = useServiceLocationStep();
    const { isMobileView } = useViewport();
    const [progressPercentToDisplay, setProgressPercentToDisplay] = useState<
        string
    >(ACTIVITY_INDICATOR_KEYS.DEFAULT_PROGRESS_PERCENT);

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    const initBookingDetails = () => {
        const bookingDetails = osbRetrieveBookingStep.retrieveBookingDetails;
        if (bookingDetails.bookings) {
            setBookingRefNo(bookingDetails.bookings[0].bookingReferenceNumber);
            setAccessCode(bookingDetails.bookings[0].accesscode);
        }
    };
    const clearStoreData = () => {
        resetVehicleStep({ clearAll: true });
        resetDealerStep();
        resetServiceStep();
        resetLightDeliveryServiceStep();
        resetServiceLocationStep();
        resetCalendarStep();
        resetPersonalDetailStep();
        resetStepProgressBar();
    };

    function getRetrieveBookingSummaryContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-retrieve-booking'
        )
            .then(results => {
                setRetrieveSummarBookingContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    }

    useEffect(() => {
        setRouteToPersist(
            OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
            OsbPathBookingRetrieveSummaryStep(),
            osbStep.localStorageExpiry
        );
        initBookingDetails();
        dispatch({ type: 'REQUEST' });
        triggerReviewBookingLoadAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents,
            LIGHT_JOURNEY_ANALYTICS.AMEND_FLOW_CONTENT
        );
        getRetrieveBookingSummaryContent();
        triggerRetrieveBookingSummaryAnalytics(
            osbDealerStep.selectedDealerProfile.dealerCode,
            osbServiceStep.totalPriceForAnalytics || '0.00',
            [
                ...osbServiceStep.selectedServices,
                ...osbLightDeliveryServiceStep.selectedServices,
                ...osbServiceLocationDetail.selectedOtherOptions,
            ],
            osbServiceStep.voucherCode,
            osbVehicleStep,
            osbReviewBooking.bookingReferenceNumber,
            osbStep,
            fireEvents
        );
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, []);

    const goToRetrieveBookingSummaryPage = () => {
        setIsLogoutModalVisible(false);
        setIsCancelBookingModalVisible(false);
        history.push(
            buildNavigationUrl(
                OsbPathBookingRetrieveSummaryStep(),
                osbStep.UrlQueryParams
            )
        );
    };
    const goToCancelBookingPage = () => {
        setLoading(false);
        history.push(
            buildNavigationUrl(
                OsbPathBookingCancelStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    const goToRetrieveBookingPage = () => {
        triggerRetrieveBookingLogoutAnalytics(
            osbVehicleStep,
            osbStep,
            fireEvents
        );
        setIsLogoutModalVisible(false);
        clearStoreData();
        history.push(
            buildNavigationUrl(
                OsbPathBookingRetrieveStep(),
                osbStep.UrlQueryParams
            )
        );
    };
    const onShowLogoutModal = () => {
        setIsLogoutModalVisible(true);
    };
    const onShowCancelBookingModal = () => {
        setIsCancelBookingModalVisible(true);
    };
    const onCloseCancelBooking = () => {
        setIsCancelBookingModalVisible(false);
    };
    const onCloseLogout = () => {
        setIsLogoutModalVisible(false);
    };

    const showAmendConfirmationPopup = () => {
        setIsAmendConfirmationPopupVisible(true);
    };

    const closeAmendConfirmationPopup = () => {
        setIsAmendConfirmationPopupVisible(false);
    };

    const goToBookingConfirmationPage = () => {
        history.push(
            buildNavigationUrl(
                OsbPathBookingConfirmationStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    const imageUpload = async (
        accessCode: string,
        bookingReferenceNumber: string,
        email: string,
        uploadedImages: AcceptedFileInfo[]
    ) => {
        const bookingFileType = SERVICE_FLOW.IMAGE_UPLOAD_TYPE;
        await Promise.all(
            uploadedImages.map(uploadedImage => {
                return ServiceHandler.OsbImageUploadService.uploadImage(
                    accessCode,
                    email.toLowerCase(),
                    bookingReferenceNumber,
                    bookingFileType,
                    uploadedImage,
                    undefined
                )
                    .then()
                    .catch();
            })
        );
    };

    const invokeAmendBooking = async () => {
        closeAmendConfirmationPopup();
        setIsDisableButtonActions(true);
        setLoading(true);

        if (
            osbImageUploadStep.uploadedImage &&
            osbImageUploadStep.uploadedImage.filter(
                imageData => imageData.fileType === 'NEW'
            ).length > 0
        ) {
            await imageUpload(
                osbReviewBooking.accessCode,
                osbReviewBooking.bookingReferenceNumber,
                osbPersonalDetail.email,
                osbImageUploadStep.uploadedImage.filter(
                    imageData => imageData.fileType === 'NEW'
                )
            );
        }

        function fetchProgressPercentForAmendBooking(progressPercent: number) {
            setProgressPercentToDisplay(progressPercent.toString());
        }

        const { dealerCode, ...booingWithoutDealerCode } = bookable;

        triggerReviewAmendLoadAnalytics(osbVehicleStep, osbStep, fireEvents);
        ServiceHandler.OSBAmendBooking.amendServiceBooking({
            dealerCode,
            bookingRefNo: bookable.bookingReferenceNumber,
            serviceBookingRequest: booingWithoutDealerCode,
            isMobileServiceSelected:
                osbDealerStep.hasMobileService &&
                osbServiceStep.isMobileServiceSelected,
            source: osbStep.source,
            callback:
                osbImageUploadStep.uploadedImage &&
                osbImageUploadStep.uploadedImage.length > 0
                    ? undefined
                    : fetchProgressPercentForAmendBooking,
        })
            .then(results => {
                if (results.value.accessCode) {
                    removeAllClientStorage();
                    setAmendServiceError(false);
                    setLoading(false);
                    goToBookingConfirmationPage();
                }
            })
            .catch((error: any) => {
                setIsDisableButtonActions(false);
                setLoading(false);
                if (error.response) {
                    const errorMessage = error.response.data.error.message;
                    if (errorMessage !== '') {
                        setAmendServiceError(true);
                    }
                }
            });
    };

    const cancelBooking = () => {
        setIsDisableButtonActions(true);
        setIsCancelBookingModalVisible(false);
        setLoading(true);

        const bookingAuthorization: BookingAuthorization = {
            identifier: osbPersonalDetail.email,
            accessCode: accessCode,
        };
        const cancelBookingRequest: CancelBookingRequest = {
            bookingAuthorization: bookingAuthorization,
        };
        ServiceHandler.OSBRetrieveBooking.cancelServiceBooking(
            cancelBookingRequest,
            osbDealerStep.selectedDealerProfile.dealerCode,
            bookingRefNo
        )

            .then(() => {
                removeAllClientStorage();
                setError('');
                goToCancelBookingPage();
                triggerRetrieveBookingCancellationAnalytics(
                    osbVehicleStep,
                    osbStep,
                    fireEvents
                );
            })
            .catch((error: any) => {
                setIsDisableButtonActions(false);
                if (error?.errorMessage) {
                    setError(error.errorMessage);
                }
                setLoading(false);
            });
    };
    return (
        <div className="light-retrive-booking-component-container">
            {loading && (
                <OsbLoader
                    osbLoaderMessage={` ${
                        osbImageUploadStep.uploadedImage &&
                        osbImageUploadStep.uploadedImage?.length > 0
                            ? parse(
                                  retrieveSummarBookingContent?.elements
                                      .find(
                                          e =>
                                              e.name ===
                                              'loaderMessageForImageUpload'
                                      )
                                      ?.value?.toString() ?? ''
                              )
                            : parse(
                                  retrieveSummarBookingContent?.elements
                                      .find(e => e.name === 'loaderMessage')
                                      ?.value?.toString() ?? ''
                              )
                    }`}
                    progressPercent={progressPercentToDisplay}
                />
            )}

            {(osbStep.isWebViewJourney || !isMobileView) &&
                !osbStep.isRetrieveFlow && (
                    <div className="light-retrive-booking-back">
                        <OSBSecondaryButton
                            type="filled"
                            direction="left"
                            text={
                                !httpState.isLoading
                                    ? retrieveSummarBookingContent?.elements.find(
                                          e => e.name === 'backButton'
                                      )?.value + ''.toString()
                                    : ''
                            }
                            onClickHandler={goToRetrieveBookingPage}
                        />
                    </div>
                )}
            {errorDiv}
            {amendServiceError && (
                <div className="error">
                    {parse(
                        retrieveSummarBookingContent?.elements
                            .find(e => e.name === 'serviceDown')
                            ?.value.toString() ?? ''
                    )}
                </div>
            )}

            <div className="light-retrieve-booking-title">
                {!httpState.isLoading
                    ? retrieveSummarBookingContent?.elements.find(
                          e => e.name === 'retrieve_booking_summary'
                      )?.value + ''.toString()
                    : ''}
            </div>
            <div></div>

            <LightRetrieveBookingDetails
                loadVoucherAlertMessage={loadVoucherAlertMessage}
            />

            <div className="light-retrieve-amend-booking-button">
                <FMButton
                    role="link"
                    type="primary"
                    label={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'amendBooking'
                              )?.value + ''.toString()
                            : ''
                    }
                    disabled={isDisableButtonActions}
                    onClick={showAmendConfirmationPopup}
                />
                <Modal
                    name={amendConfirmationModal}
                    role="dialog"
                    aria-label="amend"
                    onClose={closeAmendConfirmationPopup}
                    primaryBtnLabel={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e =>
                                      e.name ===
                                      'amendConfirmationPrimaryButtonLabel'
                              )?.value + ''.toString()
                            : ''
                    }
                    secondaryBtnLabel={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e =>
                                      e.name ===
                                      'amendConfirmationSecondaryButtonLabel'
                              )?.value + ''.toString()
                            : ''
                    }
                    onPrimaryBtnClick={invokeAmendBooking}
                    onSecondaryBtnClick={closeAmendConfirmationPopup}
                    isVisible={isAmendConfirmationPopupVisible}
                    osbEnabled={true}
                >
                    <div className="model-heading">
                        {!httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'amendBooking'
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div
                        className="modal-body"
                        dangerouslySetInnerHTML={{
                            __html: !httpState.isLoading
                                ? retrieveSummarBookingContent?.elements.find(
                                      e => e.name === 'amendConfirmationMsg'
                                  )?.value + ''.toString()
                                : '',
                        }}
                    />
                </Modal>
            </div>

            <div className="light-retrieve-cancel-button">
                <FMButton
                    type="secondary"
                    theme="dark"
                    role="link"
                    label={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'cancelBooking'
                              )?.value + ''.toString()
                            : ''
                    }
                    disabled={isDisableButtonActions}
                    onClick={onShowCancelBookingModal}
                />
                <Modal
                    name="cancel-booking-modal"
                    role="dialog"
                    aria-label="Cancel Booking"
                    onClose={onCloseCancelBooking}
                    primaryBtnLabel={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'cancelBooking'
                              )?.value + ''.toString()
                            : ''
                    }
                    secondaryBtnLabel={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'backButton'
                              )?.value + ''.toString()
                            : ''
                    }
                    onPrimaryBtnClick={cancelBooking}
                    onSecondaryBtnClick={goToRetrieveBookingSummaryPage}
                    isVisible={isCancelBookingModalVisible}
                    osbEnabled={true}
                >
                    <div className="model-heading">
                        {!httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'cancelBooking'
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div
                        className="modal-body"
                        dangerouslySetInnerHTML={{
                            __html: !httpState.isLoading
                                ? retrieveSummarBookingContent?.elements.find(
                                      e => e.name === 'cancelBookingConfirm'
                                  )?.value + ''.toString()
                                : '',
                        }}
                    />
                </Modal>
            </div>

            <div className="light-retrieve-logout-button">
                <div
                    data-testid="RetrieveLogoutLink"
                    className="light-retrive-logout-button-label"
                    onClick={() => onShowLogoutModal()}
                >
                    {!httpState.isLoading
                        ? retrieveSummarBookingContent?.elements.find(
                              e => e.name === 'logout'
                          )?.value + ''.toString()
                        : ''}
                    &nbsp;
                </div>

                <div onClick={() => onShowLogoutModal()}>
                    <FdsChevron type="unfilled" direction={'right'} />
                    &nbsp;
                </div>

                <Modal
                    name={addLogoutModal}
                    role="dialog"
                    aria-label="logout"
                    onClose={onCloseLogout}
                    primaryBtnLabel={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'logout'
                              )?.value + ''.toString()
                            : ''
                    }
                    secondaryBtnLabel={
                        !httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'backButton'
                              )?.value + ''.toString()
                            : ''
                    }
                    onPrimaryBtnClick={goToRetrieveBookingPage}
                    onSecondaryBtnClick={goToRetrieveBookingSummaryPage}
                    isVisible={isLogoutModalVisible}
                    osbEnabled={true}
                >
                    <div className="model-heading">
                        {!httpState.isLoading
                            ? retrieveSummarBookingContent?.elements.find(
                                  e => e.name === 'logout'
                              )?.value + ''.toString()
                            : ''}
                    </div>
                    <div
                        className="modal-body"
                        dangerouslySetInnerHTML={{
                            __html: !httpState.isLoading
                                ? retrieveSummarBookingContent?.elements.find(
                                      e => e.name === 'logoutConfirmMsg'
                                  )?.value + ''.toString()
                                : '',
                        }}
                    />
                </Modal>
            </div>
        </div>
    );
};
