export class RegionError extends Error {
    public year: number;
    public make: string;
    public model: string;

    constructor(year: number, make: string, model: string) {
        super();

        this.year = year;
        this.make = make;
        this.model = model;

        this.name = 'RegionError';
        this.message = `The vehicle (${this.year} ${this.make} ${this.model}) is not currently supported in this region.`;
    }
}
