import React from 'react';
import { HelpRouterFragment } from '../../../models/experiencefragments/help-router';
import ServiceHandler from '../../../services/service-handler';
import { SOPHUS_CHAT_COUNTRIES } from '../../../constants';
interface Props {
    chatVisible: boolean;
    helpRouterContent: HelpRouterFragment;
}
const HelpRouterInnerLinks = (props: Props) => {
    const appConfigurationService = ServiceHandler.AppConfigurationService.getAppConfiguration();
    const { chatVisible, helpRouterContent } = props;

    return (
        <>
            <div className={'help-router-sticky-cta-inner'}>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            helpRouterContent?.mainMenu.menuItem.item1
                                .item1CtaUrlPath
                        }
                        aria-label={
                            helpRouterContent?.mainMenu.menuItem.item1
                                .item1AriaLabel
                        }
                    >
                        {helpRouterContent?.mainMenu.menuItem.item1
                            .item1IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${helpRouterContent?.mainMenu.menuItem.item1.item1IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            helpRouterContent?.mainMenu.menuItem.item1
                                .item1CtaTitle
                        }
                    </a>
                </div>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            helpRouterContent?.mainMenu.menuItem.item2
                                .item2CtaUrlPath
                        }
                        aria-label={
                            helpRouterContent?.mainMenu.menuItem.item2
                                .item2AriaLabel
                        }
                    >
                        {helpRouterContent?.mainMenu.menuItem.item2
                            .item2IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${helpRouterContent?.mainMenu.menuItem.item2.item2IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            helpRouterContent?.mainMenu.menuItem.item2
                                .item2CtaTitle
                        }
                    </a>
                </div>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            helpRouterContent?.mainMenu.menuItem.item3
                                .item3CtaUrlPath
                        }
                        aria-label={
                            helpRouterContent?.mainMenu.menuItem.item1
                                .item1AriaLabel
                        }
                    >
                        {helpRouterContent?.mainMenu.menuItem.item3
                            .item3IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${helpRouterContent?.mainMenu.menuItem.item3.item3IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            helpRouterContent?.mainMenu.menuItem.item3
                                .item3CtaTitle
                        }
                    </a>
                </div>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            helpRouterContent?.mainMenu.menuItem.item4
                                .item4CtaUrlPath
                        }
                        aria-label={
                            helpRouterContent?.mainMenu.menuItem.item4
                                .item4AriaLabel
                        }
                    >
                        {helpRouterContent?.mainMenu.menuItem.item4
                            .item4IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${helpRouterContent?.mainMenu.menuItem.item4.item4IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            helpRouterContent?.mainMenu.menuItem.item4
                                .item4CtaTitle
                        }
                    </a>
                </div>
                {Object.keys(SOPHUS_CHAT_COUNTRIES).includes(
                    appConfigurationService.countryCode
                ) ? (
                    <div id="S3LY_RE9WTF8yMDIz"></div>
                ) : (
                    <div
                        id={helpRouterContent?.divTagID?.divTag}
                        className={`live chat ${
                            chatVisible ? 'visually-hidden' : ''
                        }`}
                    />
                )}
            </div>
        </>
    );
};
export default HelpRouterInnerLinks;
