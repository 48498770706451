import { SyncMapsCard, SyncMapStatus } from '../use-syncs-maps-status-updates';

export const getCTRCards = (
    syncMapContent: SyncMapStatus | null,
    syncOption: string,
    desktopOrLarger: boolean
): SyncMapsCard[] | undefined => {
    const isNotMobileCard = (desktopOrLarger: boolean, card: SyncMapsCard) =>
        desktopOrLarger ? !card.isMobileCard : card.isMobileCard;

    return (
        syncMapContent?.ctrCards &&
        syncMapContent?.ctrCards
            .filter(card => card.syncMapsCardType === syncOption)
            .filter(card =>
                card.syncMapsCardType === 'usb'
                    ? isNotMobileCard(desktopOrLarger, card)
                    : true
            )
    );
};
