import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';
import HttpService from '../../http-service/http-service';
import { UserVehicleList } from '../../../models/profile-with-vehicles';

export default class OsbUserVehicleService {
    async request(): Promise<UserVehicleList> {
        const userVehicleUrl = DSL_BASE_URL + DSL_API_PATH.ADD_VEHICLE;
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        return HttpService.get<UserVehicleList>(userVehicleUrl, true, {
            headers,
        }).then(response => {
            return response.data;
        });
    }
}
