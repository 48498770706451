import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import serverSideService from '../../../services/server-side-service/server-side-service';

import './how-to-banner.scss';

export interface BannerBottom {
    component: ReactNode;
    marginTop: number | string;
}

interface Props {
    topComponent?: ReactNode;
    bottomComponent?: ReactNode;
    topMasthead?: ReactNode;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
    vehicleAttributes?: VehicleAttributes;
}

export const HowToBanner = (props: Props) => {
    const TABLET_BREAKPOINT = 768;
    const DEFAULT_HEIGHT_VALUE = 0;
    const topDivRef = useRef<HTMLDivElement>(null);
    const bottomDivRef = useRef<HTMLDivElement>(null);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [topDivHeight, setTopDivHeight] = useState(DEFAULT_HEIGHT_VALUE);
    const [bottomDivHeight, setBottomDivHeight] = useState(
        DEFAULT_HEIGHT_VALUE
    );

    useEffect(() => {
        let movementTimer: NodeJS.Timeout | null = null;

        const setDivHeights = () => {
            const bottomDivHeightValue =
                bottomDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setBottomDivHeight(bottomDivHeightValue / 2);

            const topDivHeightValue =
                topDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setTopDivHeight(topDivHeightValue);
        };

        const setBackgroundImageBasedOnScreenSize = () => {
            if (serverSideService.isClientSide()) {
                const isMobile = window.innerWidth <= TABLET_BREAKPOINT;
                setBackgroundImage(
                    isMobile
                        ? props.backgroundImageMobile
                        : props.backgroundImageDesktop
                );
            }
        };

        const configureLayout = () => {
            setDivHeights();
            setBackgroundImageBasedOnScreenSize();
        };

        const handleResize = () => {
            if (movementTimer) clearTimeout(movementTimer);
            movementTimer = setTimeout(configureLayout, 10);
        };

        configureLayout();

        if (serverSideService.isClientSide()) {
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                if (movementTimer) clearTimeout(movementTimer);
            };
        }
    }, [props.backgroundImageMobile, props.backgroundImageDesktop]);

    return (
        <div className="how-to-banner">
            {props.topComponent && (
                <div
                    className="how-to-top"
                    style={{
                        minHeight: `${topDivHeight + bottomDivHeight}px`,
                        backgroundImage: `url('${backgroundImage}')`,
                    }}
                >
                    <div className="how-to-cta" ref={topDivRef}>
                        {props.topComponent}
                    </div>
                </div>
            )}
            {props.bottomComponent && (
                <div className="how-to-bottom-container">
                    <div className="how-to-bottom" ref={bottomDivRef}>
                        {props.bottomComponent}
                    </div>
                </div>
            )}
        </div>
    );
};
