export class URLUtil {
    public static isRelativeUrl(path: string) {
        return (
            path && !path.startsWith('http://') && !path.startsWith('https://')
        );
    }
    public static omitEmptyParams(url: string) {
        if (this.isRelativeUrl(url)) {
            return url;
        }
        const urlObject = new URL(url);
        if (!urlObject.search) {
            return url;
        }
        const params = new URLSearchParams(urlObject.search);
        const emptyParamKeys: string[] = [];
        params.forEach((value, key) => {
            if (value === '' || value === undefined) {
                emptyParamKeys.push(key);
            }
        });
        emptyParamKeys.map((i: string) => params.delete(i));

        urlObject.search = params.toString();

        return urlObject.toString();
    }
}
