import ServiceHandler from '../../../services/service-handler';

export class ExternalSiteNotableUtil {
    private currentRegionCode =
        ServiceHandler.AppConfigurationService.currentRegionCode;
    private regionToDisplayModal = ['ca'];

    public regionUsesModal = (): boolean => {
        return this.getRegionToDisplayModal().includes(
            this.getCurrentRegionCode()
        );
    };

    public getCurrentRegionCode = () => {
        return this.currentRegionCode;
    };

    public getRegionToDisplayModal = () => {
        return this.regionToDisplayModal;
    };

    public hasExternalLink = (link?: string): boolean => {
        if (link && link !== undefined && link.startsWith('http')) {
            const url = new URL(link);
            return (
                this.extractRootDomain(window.location.hostname) !==
                this.extractRootDomain(url.hostname)
            );
        }
        return false;
    };

    private extractRootDomain = (domain: string) => {
        const splitArr = domain.split('.');
        const arrLen = splitArr.length;

        if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            if (
                splitArr[arrLen - 2].length == 2 &&
                splitArr[arrLen - 1].length == 2
            ) {
                domain = splitArr[arrLen - 3] + '.' + domain;
            }
        }
        return domain;
    };

    public enableNotableOption = (link?: string): boolean => {
        if (this.regionUsesModal() && this.hasExternalLink(link)) {
            return true;
        }
        if (this.regionUsesModal() && !this.hasExternalLink(link)) {
            return false;
        }
        return false;
    };

    public getParentByTag(element: any, tagName: string) {
        let parent = element;

        while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
            parent = parent.parentNode;
        }

        return parent;
    }
}

export default ExternalSiteNotableUtil;
