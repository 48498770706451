import httpAdapter from 'axios/lib/adapters/http';
import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';
import HttpService from '../../http-service/http-service';
import OsbUtilService from '../osb-util-service/osb-util-service';
import { AcceptedFileInfo } from '../../../components/sections/owners-osb/common/osb-file-uploader/osb-file-uploader';

export default class OsbImageUploadService {
    async uploadImage(
        accessCode: string,
        email: string,
        bookingReferenceNumber: string,
        bookingFileType: string,
        attachment: AcceptedFileInfo,
        callback:
            | { (progressPercent: number): void; (arg0: number): void }
            | undefined
    ): Promise<any> {
        const url =
            `${DSL_BASE_URL + DSL_API_PATH.OSB_IMAGE_UPLAOD}` +
            `/${
                OsbUtilService.getAppConfiguration().languageRegionCode
            }/bookings` +
            `/${bookingReferenceNumber}/attachments`;

        const inputData = {
            accessCode,
            email,
            bookingFileType,
            attachment,
        };
        return HttpService.post<any>(url, inputData, {
            adapter: httpAdapter,
            proxy: false,
            httpAgent: OsbUtilService.getProxyAgent(),
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent >= 100) {
                    percent = 99;
                }
                if (percent > 10) {
                    callback && callback(percent);
                }
            },
        }).then(response => {
            return response.data;
        });
    }

    async getOsbBookigImages(
        email: string,
        accessCode: string,
        bookingReferenceNumber: string
    ): Promise<any> {
        const url =
            `${DSL_BASE_URL + DSL_API_PATH.OSB_IMAGE_UPLAOD}` +
            `/${
                OsbUtilService.getAppConfiguration().languageRegionCode
            }/bookings` +
            `/${bookingReferenceNumber}/attachments`;

        const headers = {
            bookingAuthorization: JSON.stringify({
                identifier: email,
                accessCode: accessCode,
            }),
        };

        return HttpService.get<any>(url, false, {
            adapter: httpAdapter,
            proxy: false,
            httpAgent: OsbUtilService.getProxyAgent(),
            headers,
        }).then(response => {
            return response.data;
        });
    }

    async getOsbBookigImage(
        accessCode: string,
        email: string,
        bookingReferenceNumber: string,
        fileStoreId: string
    ): Promise<any> {
        const url =
            `${DSL_BASE_URL + DSL_API_PATH.OSB_IMAGE_UPLAOD}` +
            `/${
                OsbUtilService.getAppConfiguration().languageRegionCode
            }/bookings` +
            `/${bookingReferenceNumber}/attachments` +
            `/${fileStoreId}/`;

        const headers = {
            bookingAuthorization: JSON.stringify({
                identifier: email,
                accessCode: accessCode,
            }),
        };

        return HttpService.get<any>(url, false, {
            adapter: httpAdapter,
            proxy: false,
            httpAgent: OsbUtilService.getProxyAgent(),
            headers,
        }).then(response => {
            return response.data;
        });
    }
}
