import React, { ReactNode } from 'react';
import './notification-item.scss';
import { SecondaryButton } from '../../../common';

interface Props {
    headText: string;
    subText?: string;
    iconPath: string;
    buttonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
}

export interface NotificationItemButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    link?: string;
    linkTarget?: string;
    children: ReactNode;
    className?: string;
    noChevron?: boolean;
    ariaLabel?: string;
}

export const NotificationItem = (props: Props) => {
    return (
        <div className="notification-item-wrapper">
            <div className="icon-item">
                <img src={props.iconPath} alt="" />
            </div>
            <div className="details-item">
                <div className="details-message">{props.headText}</div>
                <div className="details-subtext">{props.subText}</div>
            </div>
            {props.buttonProps && (
                <div className="button-item">
                    <SecondaryButton
                        link={props.buttonProps.link}
                        linkTarget={props.buttonProps.linkTarget || '_blank'}
                        aria-label={props.buttonProps['aria-label']}
                        onClick={props.buttonProps.onClick}
                    >
                        {props.buttonProps.children}
                    </SecondaryButton>
                </div>
            )}
        </div>
    );
};
