import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../../../common';
import './light-retrieve-access-code-lincoln.scss';
import errorWarning from '../../../../../assets/error-warning.svg';
import ServiceHandler from '../../../../../services/service-handler';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import { useHttp, useOSBStep } from '../../../../../hooks/owners-osb';
import { OsbPathBookingRetrieveStep } from '../../osb-controller';
import {
    RETRIEVE_ACCESS_CODE_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
} from '../../osb-constant';
import {
    getObjectFromAEMJson,
    buildNavigationUrl,
    getGoMainHeaderHeight,
} from '../../osb-utils';
import {
    triggerRetrieveAccessCodeAnalytics,
    triggerRetrieveAccessCodeErrorAnalytics,
} from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import serverSideService from '../../../../../services/server-side-service/server-side-service';

export const LightRetrieveAccessCode = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [retrieveAccessCodeJson, setRetrieveAccessCodeJson] = useState<any>();
    const [isEmailLabelShow, setIsEmailLabelShow] = useState<boolean>(false);
    const { httpState, dispatch } = useHttp();
    const [fireEvents] = useAnalytics();
    const { osbStep } = useOSBStep();
    const history = useHistory();
    if (serverSideService.isClientSide()) {
        window.scrollTo({
            top: getGoMainHeaderHeight() - 2,
            behavior: 'smooth',
        });
    }

    function getRetrieveAccessCodeContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-retrieve-access-code'
        )
            .then(results => {
                setRetrieveAccessCodeJson(results.elements);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.error.message;
                    dispatch({ type: 'ERROR', error: errorMessage });
                }
            });
    }

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getRetrieveAccessCodeContent();
        triggerRetrieveAccessCodeAnalytics(
            fireEvents,
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE_LOAD
        );
    }, []);

    const handleEmailValidation = (email: string) => {
        if (
            email.length <= 0 ||
            (email.length > 0 &&
                !email.match(
                    getObjectFromAEMJson(
                        RETRIEVE_ACCESS_CODE_KEYS.EMAIL_REGEX,
                        retrieveAccessCodeJson
                    )
                ))
        ) {
            setEmailErrorMsg(
                getObjectFromAEMJson(
                    RETRIEVE_ACCESS_CODE_KEYS.EMAIL_INVALID_ERROR,
                    retrieveAccessCodeJson
                )
            );
            const inputValue = email.length <= 0 ? 'no entry' : 'invalid entry';
            triggerRetrieveAccessCodeErrorAnalytics(fireEvents, inputValue);
            return false;
        } else {
            setEmailErrorMsg('');
            return true;
        }
    };

    const onEmailChange = (e: any) => {
        setEmail(e.target.value);
        handleEmailValidation(e.target.value);
    };

    const emailLabelShow = () => {
        setIsEmailLabelShow(true);
    };

    const emailLabelHide = () => {
        setIsEmailLabelShow(false);
    };

    const goToRetrieveBookingStep = () => {
        const isValidEmail = handleEmailValidation(email);
        if (isValidEmail) {
            setLoading(true);
            ServiceHandler.OsbRetrieveAccessCodeService.request(email)
                .then(results => {
                    setLoading(false);
                    setError('');
                    if (results.emailNotificationsSent) {
                        history.push(
                            buildNavigationUrl(
                                OsbPathBookingRetrieveStep(),
                                osbStep.UrlQueryParams,
                                'isNotified: results.emailNotificationsSent'
                            )
                        );
                        if ((window as any).digitaldata?.page?.errorCodes) {
                            delete (window as any).digitaldata?.page
                                ?.errorCodes;
                        }
                        triggerRetrieveAccessCodeAnalytics(
                            fireEvents,
                            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE_COMPLETED
                        );
                    } else {
                        const serviceError = getObjectFromAEMJson(
                            RETRIEVE_ACCESS_CODE_KEYS.RETRIEVE_ACCESS_CODE_SERVICE_ERROR,
                            retrieveAccessCodeJson
                        );
                        setError(serviceError);
                        triggerRetrieveAccessCodeErrorAnalytics(
                            fireEvents,
                            'api error'
                        );
                    }
                })
                .catch((error: any) => {
                    setLoading(false);
                    if (error.response) {
                        const errorMessage = error.response.data.error.message;
                        setError(errorMessage);
                        triggerRetrieveAccessCodeErrorAnalytics(
                            fireEvents,
                            'api error'
                        );
                    }
                });
        }
    };

    const displayRetrieveAccessCodeError = () => {
        let accessCodeError = '';
        if (error) {
            accessCodeError = error;
        } else if (httpState.error) {
            accessCodeError = httpState.error;
        }
        return accessCodeError.length > 0 ? (
            <div className="error">{accessCodeError}</div>
        ) : (
            ''
        );
    };

    const navigateToRetrieveBookingStep = () => {
        history.push(
            buildNavigationUrl(
                OsbPathBookingRetrieveStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    return (
        <div className="retrieve-access-code-container">
            {loading && <OsbLoader />}
            {httpState.isLoading ? (
                <OsbLoader />
            ) : (
                <>
                    <div className="retrieve-access-code-header">
                        {getObjectFromAEMJson(
                            RETRIEVE_ACCESS_CODE_KEYS.RETRIEVE_ACCESS_CODE_HEADER,
                            retrieveAccessCodeJson
                        )}
                    </div>
                    {displayRetrieveAccessCodeError()}
                    <div className="retrieve-access-code-sub-header">
                        {getObjectFromAEMJson(
                            RETRIEVE_ACCESS_CODE_KEYS.RETRIEVE_ACCESS_CODE_SUB_HEADER,
                            retrieveAccessCodeJson
                        )}
                    </div>
                    <div className="email-address-link-container">
                        <div
                            className="email-address-container"
                            data-testid="enterBookingEmailAddress"
                        >
                            <div className="email-address-label">
                                {(isEmailLabelShow || email.length > 0) && (
                                    <div>
                                        {getObjectFromAEMJson(
                                            RETRIEVE_ACCESS_CODE_KEYS.EMAIL_TEXTBOX_LABEL,
                                            retrieveAccessCodeJson
                                        )}
                                    </div>
                                )}

                                {emailErrorMsg && (
                                    <img
                                        className="rac-email-error-icon"
                                        id="rac-email-error-icon"
                                        src={errorWarning}
                                    />
                                )}
                            </div>
                            <input
                                id="emailAddress"
                                aria-errormessage="rac-email-error-icon"
                                type="text"
                                className={`rac-email-address ${
                                    emailErrorMsg
                                        ? 'rac-email-address-error'
                                        : ''
                                }`}
                                placeholder={getObjectFromAEMJson(
                                    RETRIEVE_ACCESS_CODE_KEYS.EMAIL_TEXTBOX_PLACEHOLDER,
                                    retrieveAccessCodeJson
                                )}
                                aria-label="emailAddress"
                                aria-labelledby="emailAddress"
                                name="emailAddress"
                                value={email}
                                onChange={e => onEmailChange(e)}
                                onBlur={emailLabelHide}
                                onFocus={emailLabelShow}
                            />
                            {emailErrorMsg && (
                                <div className="rac-email-invalid">
                                    {emailErrorMsg}
                                </div>
                            )}
                        </div>
                        <div
                            data-testid="BackToRetrieveBookingStep"
                            className="remember-access-code-link"
                            onClick={navigateToRetrieveBookingStep}
                        >
                            {getObjectFromAEMJson(
                                RETRIEVE_ACCESS_CODE_KEYS.REMEMBER_MY_ACCESS_CODE_LINK,
                                retrieveAccessCodeJson
                            )}
                        </div>
                    </div>
                    <div className="retrieve-access-code-button">
                        <PrimaryButton
                            dataTestId="RetrieveAccessCodeButton"
                            color="dark"
                            fill="fill"
                            chevron={false}
                            onClick={goToRetrieveBookingStep}
                            ariaLabel="retrieve-access-code-button"
                        >
                            {getObjectFromAEMJson(
                                RETRIEVE_ACCESS_CODE_KEYS.RETRIEVE_ACCESS_CODE_BUTTON,
                                retrieveAccessCodeJson
                            )}
                        </PrimaryButton>
                    </div>
                </>
            )}
        </div>
    );
};
