import React, { useState, useEffect } from 'react';
import { SERVICE_FLOW } from '../osb-constant';
import './comments-lincoln.scss';
import errorWarning from '../../../../assets/error-warning.svg';
import parse from 'html-react-parser';
import { getObjectFromAEMJson } from '../osb-utils';

interface Props {
    commentInStore?: string;
    setCommentInStore: (payload: { [key: string]: string }) => void;
    serviceAemContent?: any;
    isRequiredComments?: boolean;
    emptyCommentErrorMsg?: string;
}

export const OsbComments = (props: Props) => {
    const [commentCharLeft, setCommentCharLeft] = useState(500);
    const handleChange = (event: any) => {
        const charCount = event.target.value.length;
        setCommentCharLeft(SERVICE_FLOW.COMMENTS_MAX_LENGTH - charCount);
        props.setCommentInStore({ comment: event.target.value });
    };

    useEffect(() => {
        if (props.commentInStore) {
            const commentLength = props.commentInStore?.length || 0;
            setCommentCharLeft(
                SERVICE_FLOW.COMMENTS_MAX_LENGTH - commentLength
            );
        } else {
            setCommentCharLeft(SERVICE_FLOW.COMMENTS_MAX_LENGTH);
        }
    }, [props.commentInStore]);

    return (
        <div className="comment-container">
            <div className="comments-section-title">
                {parse(
                    getObjectFromAEMJson(
                        'commentsSectionTitle',
                        props.serviceAemContent
                    )
                )}
            </div>
            <div className="comments-body">
                <div className="comments-header">
                    {parse(
                        getObjectFromAEMJson(
                            'commentsBoxLabel',
                            props.serviceAemContent
                        )
                    )}{' '}
                </div>
            </div>
            <div className="comment-input">
                <div>
                    {props.isRequiredComments &&
                    props.commentInStore?.length === 0 ? (
                        <img
                            className="osb-error-icon"
                            src={errorWarning}
                            alt="error icon"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div>
                    {props.commentInStore?.length !== 0}
                    <textarea
                        className={`comment-input textarea ${
                            props.isRequiredComments &&
                            props.commentInStore?.length === 0
                                ? 'comment-empty-error'
                                : ''
                        }`}
                        data-testid="OsbCommentsInputfield"
                        aria-label="comments"
                        id={'osb-comment-field'}
                        name={'comments'}
                        placeholder={getObjectFromAEMJson(
                            'placeHolderLabel',
                            props.serviceAemContent
                        )}
                        onChange={handleChange}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        value={props.commentInStore}
                    />
                    {props.isRequiredComments &&
                    props.commentInStore?.length === 0 ? (
                        <div className="empty-commments-error">
                            {props.emptyCommentErrorMsg}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="comments-remaining-char">
                {parse(
                    getObjectFromAEMJson(
                        'remainingCharactersMessageLabel',
                        props.serviceAemContent
                    )
                )}{' '}
                {commentCharLeft}
            </div>
        </div>
    );
};
