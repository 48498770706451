import { useContent } from '../../../../hooks/use-server-content';

export interface MaintenanceScheduleContentProps {
    maintenanceScheduleHeadline: string;
    maintenanceScheduleDescription: string;
    maintenanceScheduleAlternateDescription: string;
    maintenanceScheduleIOLMDescription: string;
    maintenanceCTALabel: string;
    maintenanceCTALink: string;
    maintenanceScheduleCTAAnalyticsContext: string;
    maintenanceScheduleErrorMessage: string;
    ctaLabel: string;
    ctaHref: string;
    ctaAriaLabel: string;
    ctaTarget: string;
    ctaAnalyticsContext: string;
    maintenanceScheduleCarouselHeadline: string;
    maintenanceScheduleCarouselToggle: string;
    toggleSubheadline: string;
    maintenanceScheduleSupplementaryDescription: string;
    serviceTimeIntervalSingleYear: string;
    serviceTimeIntervalYears: string;
    serviceTimeIntervalMonths: string;
    miLabelForSubHeadline: string;
    kmLabelForSubHeadline: string;
    odometerLabel: string;
    odometerTooltipHeader: string;
    odometerTooltipContent: string;
    openInNewtabArialabel: string;
    noMileageAlternativeCarouselToggle: string;
    noMileageAlternativeToggleSubheadline: string;
    detailsLabel: string;
}
export const useMaintenanceScheduleContent = (): MaintenanceScheduleContentProps => {
    const [content, getValueByName] = useContent(
        'common',
        'maintenance-schedule'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as MaintenanceScheduleContentProps;
};
