import {
    AemVideoQuietCardDetails,
    VideoQuietCardDetails,
} from '../../../models/experiencefragments/category-view';
import {
    SamReImagineCategoryViewContent,
    SamReImagineCategoryViewContentReturn,
    SamReImagineCategoryViewFragment,
} from '../../../models/experiencefragments/sam-reimagine-category-view';
import {
    DisclaimersService,
    DisclaimerContent,
    ProcessedField,
} from '../../../services/disclaimers-service/disclaimers-service';

export function useSamReImagineCategoryViewContent(
    categoryViewFragment: SamReImagineCategoryViewFragment
): SamReImagineCategoryViewContentReturn | undefined {
    const content: SamReImagineCategoryViewContent = {};
    let disclaimers: DisclaimerContent[] = [];

    if (categoryViewFragment) {
        if (
            categoryViewFragment.masthead &&
            !categoryViewFragment.masthead.hide
        ) {
            const [
                processedArticle,
                processedDisclaimers,
            ] = DisclaimersService.processArticle(
                categoryViewFragment.masthead,
                disclaimers
            );
            content.masthead = processedArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }
        if (
            categoryViewFragment.infoQuietCards &&
            !categoryViewFragment.infoQuietCards.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.infoQuietCards.articles,
                disclaimers
            );
            content.infoQuietCards = {
                ...categoryViewFragment.infoQuietCards,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.articlesTrio &&
            !categoryViewFragment.articlesTrio.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.articlesTrio.articles,
                disclaimers
            );
            content.articlesTrio = {
                ...categoryViewFragment.articlesTrio,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        } else {
            delete categoryViewFragment.articlesTrio;
        }

        if (
            categoryViewFragment.quietCards &&
            !categoryViewFragment.quietCards.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.quietCards.articles,
                disclaimers
            );
            content.quietCards = {
                ...categoryViewFragment.quietCards,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }
        if (
            categoryViewFragment.quietCardsOne &&
            !categoryViewFragment.quietCardsOne.hide
        ) {
            const [
                processedArticles,
                processedDisclaimers,
            ] = DisclaimersService.processArticles(
                categoryViewFragment.quietCardsOne.articles,
                disclaimers
            );
            content.quietCardsOne = {
                ...categoryViewFragment.quietCardsOne,
                articles: processedArticles,
            };
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.videoQuietCard &&
            !categoryViewFragment.videoQuietCard.hide &&
            categoryViewFragment.videoQuietCard.videoQuietCardDetails
        ) {
            const [
                processedVideoQuietCardDetails,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers<
                AemVideoQuietCardDetails,
                VideoQuietCardDetails,
                keyof AemVideoQuietCardDetails
            >(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                categoryViewFragment.videoQuietCard.videoQuietCardDetails,
                (item, fields) => {
                    return {
                        ...item,
                        title: fields[0]?.node,
                        description: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.videoQuietCard = {
                videoQuietCardDetails: processedVideoQuietCardDetails,
            };
        }

        if (
            categoryViewFragment.componentBox5050 &&
            !categoryViewFragment.componentBox5050.hide
        ) {
            const [
                processedArticle,
                processedDisclaimers,
            ] = DisclaimersService.processArticle(
                categoryViewFragment.componentBox5050,
                disclaimers
            );
            content.componentBox5050 = processedArticle;
            disclaimers = disclaimers.concat(processedDisclaimers);
        }

        if (
            categoryViewFragment.articleCarousel &&
            !categoryViewFragment.articleCarousel.hide
        ) {
            content.articleCarousel = categoryViewFragment.articleCarousel;
        }

        if (
            categoryViewFragment.faqs &&
            !categoryViewFragment.faqs?.hide &&
            categoryViewFragment.faqs?.questions
        ) {
            let savedFields: (ProcessedField | null)[] = [];
            const [
                ,
                processedDisclaimers,
            ] = DisclaimersService.processItemWithDisclaimers(
                ['title', 'subtitle'],
                'disclaimer',
                disclaimers,
                categoryViewFragment.faqs,
                (_item, fields) => {
                    savedFields = fields;
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            {
                const [
                    processedQuestions,
                    processedDisclaimers,
                ] = DisclaimersService.processItemsWithDisclaimers(
                    ['question', 'text'],
                    'disclaimer',
                    disclaimers,
                    categoryViewFragment.faqs.questions,
                    (_item, fields) => {
                        return {
                            question: fields[0]?.node,
                            text: fields[1]?.node,
                        };
                    }
                );
                disclaimers = disclaimers.concat(processedDisclaimers);
                content.faqs = {
                    title: savedFields[0]?.node,
                    subtitle: savedFields[1]?.node,
                    questions: processedQuestions,
                };
            }
        }

        return {
            disclaimers,
            content: content,
        };
    }
}
