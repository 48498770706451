import React from 'react';

import './preimagine-5050-banner.scss';
import { FMCardFiftyFifty } from '@own/fds-react';
import { NewAnalyticsService } from '../../../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { CategoryOnClickCtaInfo } from '../../../../../../services/shortcode-service/shortcode-service';
import { Article } from '../../../../../../models/experiencefragments/category-view';
import { transformRelativeUrlValidateRoot } from '../../../../../utils/router-util/router-util';

interface Props {
    className?: string;
    content: Article;
    categoryType?: string;
    categoryPath?: string;
    root?: string;
}

const PreImagine5050Banner = (props: Props) => {
    const { className, content, categoryType, categoryPath, root } = props;

    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
        ctaName: content.ctaLabel,
        categoryType: categoryType,
        categoryPath: categoryPath,
        ctaUrl: content.ctaPath,
    };

    const [fireEvents] = useAnalytics();

    const eventClick = async () => {
        if (categoryType == 'category' || categoryType == 'subcategory') {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                content.ctaPath
            );
            if (referralExit) {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            } else {
                fireEvents('category-onclick-event', undefined, {
                    categoryOnClickCtaInfo,
                });
            }
        }
    };

    return (
        <article
            className={`preimagine-5050-banner ${className ? className : ''}`}
        >
            <FMCardFiftyFifty
                {...{
                    title: content.title,
                    headline: content.headline,
                    description: content.description,
                    mediaSide: 'left',
                    media: {
                        src: `${process.env.REACT_APP_AEM_BASE_URL}${content.mobileImagePath}`,
                        alt: content.imageAltText,
                    },
                    button: {
                        type: 'secondary',
                        label: content.ctaLabel,
                        href: transformRelativeUrlValidateRoot(
                            content.ctaPath ?? '',
                            root ?? ''
                        ),
                        disabled: false,
                        onClick: eventClick,
                        target: content.targetBlank ? '_blank' : '',
                    },
                }}
            />
        </article>
    );
};

export default PreImagine5050Banner;
