import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import tickMark from '../../../../../../assets/owners-osb/tick-mark.svg';
import closeMark from '../../../../../../assets/owners-osb/close-mark.svg';
import './dealer-capabilities.scss';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { getObjectFromAEMJson } from '../../../osb-utils';
import {
    DEALER_STEP_KEYS,
    ELECTRIC_SERVICE_KEY,
    SERVICE_DEPARTMENT_CV_KEY,
    SERVICE_DEPARTMENT_PV_KEY,
} from '../../../osb-constant';
interface Props {
    dealer: DealerProfileInfo;
    dealerStepMoreContent: JsonFromAEMResponse[];
}

function DealerCapabilities(props: Props) {
    const { dealer, dealerStepMoreContent } = props;

    const getDealerCapability = (
        labelKey: string,
        capabilityKey: keyof DealerProfileInfo
    ) => {
        const label = getObjectFromAEMJson(labelKey, dealerStepMoreContent);
        const dealerCapabilitiesListArr: string[] = getObjectFromAEMJson(
            DEALER_STEP_KEYS.DEALER_CAPABILITIES_LIST,
            dealerStepMoreContent
        )?.split(',');
        const isDealerCapabilityConfigured = dealerCapabilitiesListArr?.includes(
            capabilityKey
        );
        const capability = dealer[capabilityKey];

        const icon = capability ? (
            <img src={tickMark} alt="tick" className="dealer-capability-icon" />
        ) : (
            <img
                src={closeMark}
                alt="close"
                className="dealer-capability-icon"
            />
        );

        return (
            isDealerCapabilityConfigured &&
            label && (
                <div className="dealer-capabilities-row">
                    <div className="dealer-capabilities-img-col">{icon}</div>
                    <div className="dealer-capabilities-text-col">
                        <p className="dealer-capability-text">{label}</p>
                    </div>
                </div>
            )
        );
    };

    return (
        <div className="osb-dealer-capabilities-container">
            {getDealerCapability(
                DEALER_STEP_KEYS.PASSANGER_SERVICE_VEHICLE_LABEL,
                SERVICE_DEPARTMENT_PV_KEY
            )}
            {getDealerCapability(
                DEALER_STEP_KEYS.COMMERCIAL_SERVICE_VEHICLE_LABEL,
                SERVICE_DEPARTMENT_CV_KEY
            )}
            {getDealerCapability(
                DEALER_STEP_KEYS.ELECTRIC_SERVICE_VEHICLE_LABEL,
                ELECTRIC_SERVICE_KEY
            )}
        </div>
    );
}

export default DealerCapabilities;
