import React, { useContext } from 'react';
import './quick-links-common.scss';
import { QuickLinksContent } from '../../../models/experiencefragments/quick-links';
import SecondaryButton from '../../common/secondary-button/secondary-button';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useAnalytics } from '../../../hooks/use-analytics';
import { transformRelativeUrlValidateRoot } from '../../utils/router-util/router-util';
import ServerContext from '../../../contexts/serverContext';
import { FMListItem } from '@own/fds-react';
import { BRAND } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { ctaHref, handleListItemEvent } from '../quick-links-util';

const QuickLinksCommon = (props: QuickLinksContent & VehicleAttributes) => {
    const [fireEvents] = useAnalytics();
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    const context = useContext(ServerContext);

    return currentVersion ? (
        <div className="quick-links-card">
            <img
                className="icon"
                alt=""
                src={process.env.REACT_APP_AEM_BASE_URL + props.iconPath}
            />
            <SecondaryButton
                className={'quick-links-cta'}
                link={transformRelativeUrlValidateRoot(
                    ctaHref(props),
                    context.root
                )}
                aria-label={props.ctaAriaLabel}
                noChevron={true}
                onClick={() => handleListItemEvent(props, fireEvents)}
            >
                {props?.tileTitle?.toUpperCase()}
            </SecondaryButton>
        </div>
    ) : (
        <FMListItem
            title={props?.tileTitle || ''}
            imgPath={process.env.REACT_APP_AEM_BASE_URL + props.iconPath}
            type={'no-description'}
            ctaPath={transformRelativeUrlValidateRoot(
                ctaHref(props),
                context.root
            )}
            showChevron={false}
            description={''}
            onClick={() => handleListItemEvent(props, fireEvents)}
        />
    );
};
export default QuickLinksCommon;
