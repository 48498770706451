import React, { useEffect, useState } from 'react';
import '../slow-tire-leak/slow-tire-leak-details.lincoln.scss';
import { isMobileView, scrollToTop } from '../../vha-utils';
import { getOilLifeDataFromAPI } from '../vha-alert-list/oillife/oilife';
import { TireLeakStatus } from '../../../../common/tire-leak-status/tire-leak-status';
import {
    getVehicleStatus,
    getSlowTireLeakData,
    getSlowTireLeakStatusColor,
    getTLSStatus,
    getTLSDescription,
    getLastDetected,
} from '../vha-alert-list/slow-tire-leak/slow-tire-leak-utils';
import { getVin } from '../../../../utils/getVin-util/getVin-util';
import { Accordion, PrimaryButton } from '../../../../common';
import { BookAServiceCTA } from '../book-a-service/book-a-service';
import serverSideService from '../../../../../services/server-side-service/server-side-service';
import { useSlowTireLeakDetailsContent } from '../../hook/use-slow-tire-leak-details-content';
import downloadCopyIcon from '../../../../../assets/download-copy-icon.png';

interface FaqProps {
    answer: string;
}

const FaqAnswer = (props: FaqProps) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: props.answer,
            }}
        />
    );
};

export const SlowTireLeakDetails = () => {
    const selectedVin = getVin();
    const slowTireLeakDetailsContent: any = useSlowTireLeakDetailsContent();
    const SlowTireLeakDetails = slowTireLeakDetailsContent?.slowTireLeak;
    const slowTireLeakFaqList = SlowTireLeakDetails?.slowTireLeakFaqList;
    const slowTireLeakLabel = SlowTireLeakDetails?.slowTireLeakLabel;
    const lastDetectedLabel = SlowTireLeakDetails?.lastDetectedLabel;
    const frontTiresLabel = SlowTireLeakDetails?.frontTiresLabel;
    const rearTiresLabel = SlowTireLeakDetails?.rearTiresLabel;
    const tirePressureLabel = SlowTireLeakDetails?.tirePressureLabel;
    const coldPressureLabel = SlowTireLeakDetails?.coldPressureLabel;
    const bookServiceCTALabel = SlowTireLeakDetails?.bookServiceCTALabel;
    const bookServiceCTALink = SlowTireLeakDetails?.bookServiceCTALink;
    const isDownloadCopy = SlowTireLeakDetails?.enableDownloadCopy;

    const [oilLifeData, setOilLifeData] = useState<any>(null);
    const [vehicleStatusData, setVehicleStatusData] = useState<any>(null);
    const [slowTireLeakData, setSlowTireLeakData] = useState<any>(null);

    useEffect(() => {
        if (vehicleStatusData && oilLifeData) {
            setSlowTireLeakData(
                getSlowTireLeakData(vehicleStatusData, oilLifeData)
            );
        }
    }, [vehicleStatusData, oilLifeData]);
    useEffect(() => {
        scrollToTop();
        const fetchOilLifeData = async () => {
            return await getOilLifeDataFromAPI(selectedVin);
        };

        const fetchVehicleStatus = async () => {
            return await getVehicleStatus(selectedVin);
        };
        fetchOilLifeData()
            .then(prognosticsData => {
                if (prognosticsData?.tireLeakData) {
                    setOilLifeData(prognosticsData.tireLeakData);
                }
            })
            .catch(error => {
                console.error(
                    'Oil Life Data fetch error ======',
                    error.message
                );
            });

        fetchVehicleStatus()
            .then(vehicleData => {
                if (vehicleData) {
                    setVehicleStatusData(vehicleData);
                }
            })
            .catch(error => {
                console.error(
                    'Vehicle Status Data fetch error ======',
                    error.message
                );
            });
    }, []);
    const goToBookAService = () => {
        if (serverSideService.isClientSide()) {
            window.location.href = bookServiceCTALink;
        }
    };

    const getAccordionText = () => {
        return (
            <>
                {slowTireLeakFaqList?.map((operation: any, key: number) => (
                    <Accordion
                        key={operation.faqItemQuestion}
                        header={operation.faqItemQuestion}
                        panel={FaqAnswer(operation.faqItemAnswer)}
                        index={key.toString()}
                        expandMultiplePanels={false}
                        className={''}
                    />
                ))}
            </>
        );
    };

    const printSlowTireLeakDetailPage = () => {
        if (serverSideService.isClientSide()) {
            window.print();
        }
    };

    const downloadACopy = () => {
        return (
            <div
                className="stl-details-download-copy-wrapper"
                onClick={printSlowTireLeakDetailPage}
            >
                <img
                    src={downloadCopyIcon}
                    alt="Download copy icon"
                    className="stl-details-download-copy-icon"
                    aria-label="stl-details-download-copy-icon"
                />
            </div>
        );
    };

    return (
        slowTireLeakData && (
            <>
                <div className="slow-tire-leak-details-wrapper">
                    <div className="slow-tire-leak-header">
                        {slowTireLeakLabel}
                        {isDownloadCopy && downloadACopy()}
                    </div>
                    <div className="slow-tire-leak-content-wrapper">
                        <div className="slow-tire-leak-tire-status">
                            <TireLeakStatus tireStatus={slowTireLeakData} />
                        </div>
                        {isMobileView() && (
                            <div className={'slow-tire-leak-do-not-use'}>
                                {tirePressureLabel}
                            </div>
                        )}
                        <div className="slow-tire-leak-data-wrapper">
                            <div className="recommened-label">
                                {coldPressureLabel}
                            </div>
                            <div className="recommened-data">
                                <div className="front-tyre-wrapper">
                                    <div className="front-tyre">
                                        {
                                            slowTireLeakData.recommendedFrontTirePressure
                                        }
                                    </div>
                                    <div className="front-tyre-label">
                                        {frontTiresLabel}
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="rear-tyre-wrapper">
                                    <div className="rear-tyre">
                                        {
                                            slowTireLeakData.recommendedRearTirePressure
                                        }
                                    </div>
                                    <div className="rear-tyre-label">
                                        {rearTiresLabel}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isMobileView() && (
                            <div className="slow-tire-leak-book-service">
                                <PrimaryButton
                                    fill="fill"
                                    color="dark"
                                    chevron={false}
                                    onClick={goToBookAService}
                                    ariaLabel="Book a Service"
                                >
                                    {bookServiceCTALabel}
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                    {!isMobileView() && (
                        <div className={'slow-tire-leak-do-not-use'}>
                            {tirePressureLabel}
                        </div>
                    )}
                    <div className="slow-tire-leak-status-wrapper">
                        <div
                            className={`status-text ${getSlowTireLeakStatusColor(
                                getTLSStatus(slowTireLeakData)
                            )}`}
                        >
                            {getTLSDescription(
                                getTLSStatus(slowTireLeakData),
                                slowTireLeakData,
                                SlowTireLeakDetails
                            )}
                        </div>
                    </div>
                    <div className="last-detected">
                        <span className={'last-detected-label'}>
                            {lastDetectedLabel}
                        </span>{' '}
                        <span className={'last-detected-date'}>
                            {getLastDetected(
                                slowTireLeakData.eventTime,
                                SlowTireLeakDetails
                            )}
                            .
                        </span>
                    </div>
                    {getAccordionText()}
                </div>
                {isMobileView() && (
                    <BookAServiceCTA
                        label={bookServiceCTALabel}
                        redirectUrl={bookServiceCTALink}
                    />
                )}
            </>
        )
    );
};
