import React from 'react';
import { TrailerCheckList } from '../../components/sections';
import { useTrailerChecklistExperienceContent } from '../../components/sections/trailer-checklist/hooks/use-trailer-checklist-experience-content';

export function TrailerChecklistView() {
    const trailerChecklistContent = useTrailerChecklistExperienceContent();
    const isContentAvailable =
        trailerChecklistContent?.trailerChecklist?.landing &&
        !!Object.keys(trailerChecklistContent?.trailerChecklist?.landing)
            .length;

    return (
        <>
            {isContentAvailable && (
                <TrailerCheckList
                    page="Trailer Checklist"
                    trailerChecklistLanding={
                        trailerChecklistContent?.trailerChecklist?.landing
                    }
                    serviceOffersSection={
                        trailerChecklistContent?.serviceOffersSection
                    }
                />
            )}
        </>
    );
}
export default TrailerChecklistView;
