import { useContent } from '../../../../hooks/use-server-content';

export interface VehicleDashboardContentProps {
    hide: boolean;
    breadcrumb: string;
}
export const useVehicleDashboardContent = (): VehicleDashboardContentProps | null => {
    const [content, getValueByName] = useContent('common', 'vehicle-dashboard');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as VehicleDashboardContentProps;
};
