import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AutoComplete from '@own/accessible-autocomplete/react';
import './ute-basic-info.ford.scss';
import { InputField } from '../../../../../common';
import { formField } from '../formField';
import { UteFooter } from '../ute-create-trailer-footer/ute-create-trailer-footer';
import { replacePlaceholderByValue } from '../../../../../utils/placeholder-util/replace-placeholder-by-value';
import { UTE_PAGE_TITLE, UTE_ROUTES } from '../../types';
import { uteProfileService } from '../../../../../../services/ute-service/ute-profile-service';
import { UteContext } from '../../../../../../views/ultimate-towing-experience-view/context/ute-context';
interface Props {
    profileContent: any;
    formContent: any;
    trailerList: any;
    handleModalCancel: () => void;
    handleBreadCrumb: (
        currentPage: string,
        pageTitle: string,
        isPopup: boolean
    ) => void;
    serverContext: any;
}

export const UteCreateBasicInfo = (props: Props) => {
    const {
        errorMessage,
        existProfileNameErrorMessage,
        addNewTrailerLabel,
        addStepLabel,
        trailerNickNameLabel,
        trailerManufacturerLabel,
        trailerMakeLabel,
        trailerModelLabel,
        trailerYearLabel,
    } = props.formContent;
    const { pageTitle } = props.profileContent;
    const history = useHistory();
    const selectedVin =
        props.serverContext.webviewData?.vin ||
        window.sessionStorage.getItem('selectedVin');
    const { uteContextFormData, setUteContextFormData } = useContext(
        UteContext
    );
    const inputField = formField(props.formContent);
    const [errors, setErrors] = useState<any>({
        name: '',
    });

    const [trailerPayloadData, setTrailerPayloadData] = useState<any>({});
    const [nextBtnDisable, setNextBtnDisable] = useState<boolean>(true);

    const [autoCompleteID, setAutoCompleteID] = useState<string>('');
    let autoCompletePayload: any;
    let autocompleteName: string;
    const handleNextButton = () => {
        for (const [key, value] of Object.entries(uteContextFormData)) {
            if (value !== '') {
                if (errors[key] !== '' && errors[key] !== undefined) {
                    setNextBtnDisable(true);
                    break;
                } else {
                    if (
                        !uteContextFormData.errors.mfgName &&
                        !uteContextFormData.errors.mfgMake &&
                        !uteContextFormData.errors.mfgModel &&
                        !uteContextFormData.errors.mfgYear
                    ) {
                        setNextBtnDisable(false);
                    } else {
                        setNextBtnDisable(true);
                    }
                }
            } else {
                setNextBtnDisable(true);
                break;
            }
        }

        setUteContextFormData(uteContextFormData);
    };

    const emptyUteFinalFormSetup = (redirectFlag: boolean) => {
        uteContextFormData.trailerList['name'] = uteContextFormData.name;
        if (redirectFlag) {
            history.push({
                pathname: UTE_ROUTES.FINAL_FORM,
            });
        }
    };
    const autocompleteUpdate = (
        autocompleteName: string,
        trailerProfileResponse: any,
        redirectFlag: boolean
    ) => {
        if (autocompleteName === 'mfgPopulatedData') {
            if (trailerProfileResponse.trailerList) {
                trailerProfileResponse.trailerList[0]['name'] =
                    uteContextFormData.name;
                uteContextFormData.trailerList = Object.assign(
                    {},
                    {
                        ...uteContextFormData.trailerList,
                        ...trailerProfileResponse.trailerList[0],
                    }
                );
                if (redirectFlag) {
                    history.push({
                        pathname: UTE_ROUTES.FINAL_FORM,
                    });
                }
            } else {
                emptyUteFinalFormSetup(redirectFlag);
            }
        } else {
            setUteContextFormData({
                ...uteContextFormData,
                [autocompleteName]: trailerProfileResponse,
            });
        }
    };
    const getTrailerProfileFromAPI = (
        autocompleteName: string,
        trailerProfilePayload: object,
        redirectFlag: boolean
    ) => {
        new uteProfileService()
            .profileSearch(trailerProfilePayload)
            .then((response: any) => {
                autocompleteUpdate(autocompleteName, response, redirectFlag);
            })
            .catch(() => {
                emptyUteFinalFormSetup(redirectFlag);
            });
    };
    const isExistProfileName = (e: any) => {
        e.target.name === 'name' &&
            props.trailerList?.trailerList.forEach(
                (profileObj: { name: string }) => {
                    if (
                        profileObj.name.toLowerCase() ===
                        e.target.value.toLowerCase()
                    ) {
                        const existProfileNameErrMsg = replacePlaceholderByValue(
                            existProfileNameErrorMessage,
                            {
                                TrailerProfileName: e.target.value,
                            }
                        );
                        setErrors({
                            ...errors,
                            [e.target.name]: `${existProfileNameErrMsg}`,
                        });
                    }
                }
            );
    };

    const onChange = (e: any) => {
        const { value, name } = e.target;
        if (e.type === 'blur') {
            const obj = inputField[0];
            if (value.match(obj?.pattern)) {
                setErrors({ ...errors, [name]: '' });
                setUteContextFormData({ ...uteContextFormData, [name]: value });
            } else {
                setErrors({
                    ...errors,
                    [name]: `${errorMessage}`,
                });
                setUteContextFormData({ ...uteContextFormData, [name]: value });
            }
            if (value === '') {
                setErrors({
                    ...errors,
                    [name]: `${errorMessage}`,
                });
            }
            isExistProfileName(e);
        }
        if (e.type === 'change') {
            setUteContextFormData({ ...uteContextFormData, [name]: value });
            setErrors({ ...errors, [name]: '' });
        }
    };

    function getTrailerProfilePayload(autoCompleteString: string) {
        let autoCompleteData;
        if (autoCompleteID === 'mfgName') {
            autoCompleteData = uteContextFormData.mfgName;
            autoCompletePayload = { mfgName: autoCompleteString };
            autocompleteName = 'mfgMake';
        } else if (autoCompleteID === 'mfgMake') {
            autoCompleteData = uteContextFormData.mfgMake;
            autoCompletePayload = {
                mfgName: trailerPayloadData['mfgName'],
                mfgMake: autoCompleteString,
            };
            autocompleteName = 'mfgModel';
        } else if (autoCompleteID === 'mfgModel') {
            autoCompleteData = uteContextFormData.mfgModel;
            autoCompletePayload = {
                mfgName: trailerPayloadData['mfgName'],
                mfgMake: trailerPayloadData['mfgMake'],
                mfgModel: autoCompleteString,
            };
            autocompleteName = 'mfgYear';
        } else if (autoCompleteID === 'mfgYear') {
            autoCompleteData = uteContextFormData.mfgYear;
            autoCompletePayload = {
                mfgName: trailerPayloadData['mfgName'],
                mfgMake: trailerPayloadData['mfgMake'],
                mfgModel: trailerPayloadData['mfgModel'],
                mfgYear: autoCompleteString,
            };
            autocompleteName = 'mfgPopulatedData';
        }
        return {
            autoCompleteData: autoCompleteData,
            autoCompletePayload: autoCompletePayload,
            autocompleteName: autocompleteName,
        };
    }

    const autoCompleteSelection = (searchTerm: string) => {
        const {
            autoCompletePayload,
            autocompleteName,
        } = getTrailerProfilePayload(searchTerm);
        if (searchTerm) {
            uteContextFormData.trailerList[autoCompleteID] = searchTerm;
            setTrailerPayloadData(
                Object.assign(trailerPayloadData, autoCompletePayload)
            );
            getTrailerProfileFromAPI(
                autocompleteName,
                autoCompletePayload,
                false
            );
        }
    };

    function autoCompleteSuggestion(query: string, populateResults: Function) {
        const {
            autoCompleteData,
            autoCompletePayload,
            autocompleteName,
        } = getTrailerProfilePayload(query);

        const autoCompleteLabel = document?.querySelector('.' + autoCompleteID);
        autoCompleteLabel?.classList?.remove('hide');
        uteContextFormData.trailerList[autoCompleteID] = query;

        const commonRegex = /^([a-zA-Z0-9]){0,20}$/g;
        const mfgYearRegex = /^([0-9]){0,4}$/g;
        const getId: any = document.getElementById('error-' + autoCompleteID);
        const autoId =
            autoCompleteID === 'mfgName' ||
            autoCompleteID === 'mfgMake' ||
            autoCompleteID === 'mfgModel';

        const errorMsg = (getId: any) => {
            getId.style.display = 'none';
            getId.parentElement.classList.remove('error-wrapper');
            uteContextFormData['errors'][autoCompleteID] = false;
        };

        const successMsg = (getId: any) => {
            getId.style.display = 'block';
            getId.parentElement.classList.add('error-wrapper');
            uteContextFormData['errors'][autoCompleteID] = true;
        };

        if (autoCompleteID === 'mfgYear') {
            if (mfgYearRegex.test(query)) {
                errorMsg(getId);
            } else {
                successMsg(getId);
            }
        }

        if (autoId) {
            if (commonRegex.test(query)) {
                errorMsg(getId);
            } else {
                successMsg(getId);
            }
        }

        setTrailerPayloadData(
            Object.assign(trailerPayloadData, autoCompletePayload)
        );
        getTrailerProfileFromAPI(autocompleteName, autoCompletePayload, false);

        const filteredResults = autoCompleteData[autoCompleteID]?.filter(
            (result: string) =>
                result.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
        populateResults(filteredResults);
    }

    const getAutoCompleteID = (event: any) => {
        setAutoCompleteID(event.target.id);
    };
    const handleEmptyElement = () => {
        ['mfgName', 'mfgMake', 'mfgModel', 'mfgYear'].map(elementId => {
            const inputEle: any = document.querySelector('#' + elementId);
            const InputLabel: any = document.querySelector('.' + elementId);
            if (inputEle?.value === '') {
                uteContextFormData.trailerList[elementId] = '';
            } else {
                InputLabel?.classList.remove('hide');
            }
        });
    };

    const handleContinueClick = () => {
        window.removeEventListener('click', getAutoCompleteID);
        window.removeEventListener('keypress', getAutoCompleteID);
        handleEmptyElement();
        const continueBtnPayload = {
            mfgName: uteContextFormData.trailerList['mfgName'],
            mfgMake: uteContextFormData.trailerList['mfgMake'],
            mfgModel: uteContextFormData.trailerList['mfgModel'],
            mfgYear: uteContextFormData.trailerList['mfgYear'],
        };
        getTrailerProfileFromAPI('mfgPopulatedData', continueBtnPayload, true);
    };

    const handleBackClick = () => {
        history.push(UTE_ROUTES.HOME);
    };

    useEffect(() => {
        handleNextButton();
    });

    useEffect(() => {
        if (!selectedVin) {
            history.push(UTE_ROUTES.HOME);
        }
        window.addEventListener('click', getAutoCompleteID);
        window.addEventListener('keypress', getAutoCompleteID);
        props.handleBreadCrumb(addNewTrailerLabel, pageTitle, true);
        handleEmptyElement();
        getTrailerProfileFromAPI('mfgName', {}, false);
    }, [pageTitle, addNewTrailerLabel]);
    return (
        <>
            <div className="ute-create-trailer-profile">
                <div className="ute-heading-section">
                    <h1 className="ute-header">
                        {addNewTrailerLabel}
                        <span>{addStepLabel}</span>
                    </h1>
                </div>
                <div className="ute-basic-page">
                    <div
                        key={`${inputField[0]}`}
                        className={`ute-trailer-nickname-field ute-input-container`}
                    >
                        <InputField
                            className="create-form-textField"
                            label={trailerNickNameLabel}
                            ariaLabel={trailerNickNameLabel}
                            name={'name'}
                            key={1}
                            value={uteContextFormData['name']}
                            fdsStyle={true}
                            errorMessage={errors['name']}
                            onChange={onChange}
                            onBlur={onChange}
                        />
                    </div>
                </div>
                <div className={'autocomplete-section'}>
                    <div className={'name-auto-complete'}>
                        <div className={'mfgName hide'}>
                            {trailerManufacturerLabel}
                        </div>
                        <AutoComplete
                            id={'mfgName'}
                            name={'mfgName'}
                            source={autoCompleteSuggestion}
                            placeholder={trailerManufacturerLabel}
                            onConfirm={autoCompleteSelection}
                            defaultValue={
                                uteContextFormData.trailerList['mfgName']
                            }
                        ></AutoComplete>
                        <div
                            className="error-message error-hide"
                            id="error-mfgName"
                        >
                            {errorMessage}
                        </div>
                    </div>
                    <div className={'make-auto-complete'}>
                        <div className={'mfgMake hide'}>{trailerMakeLabel}</div>
                        <AutoComplete
                            id={'mfgMake'}
                            name={'mfgMake'}
                            source={autoCompleteSuggestion}
                            placeholder={trailerMakeLabel}
                            onConfirm={autoCompleteSelection}
                            defaultValue={
                                uteContextFormData.trailerList['mfgMake']
                            }
                        ></AutoComplete>
                        <div
                            className="error-message error-hide"
                            id="error-mfgMake"
                        >
                            {errorMessage}
                        </div>
                    </div>
                    <div className={'model-auto-complete'}>
                        <div className={'mfgModel hide'}>
                            {trailerModelLabel}
                        </div>
                        <AutoComplete
                            id={'mfgModel'}
                            name={'mfgModel'}
                            source={autoCompleteSuggestion}
                            placeholder={trailerModelLabel}
                            onConfirm={autoCompleteSelection}
                            defaultValue={
                                uteContextFormData.trailerList['mfgModel']
                            }
                        />
                        <div
                            className="error-message error-hide"
                            id="error-mfgModel"
                        >
                            {errorMessage}
                        </div>
                    </div>
                    <div className={'year-auto-complete'}>
                        <div className={'mfgYear hide'}>{trailerYearLabel}</div>
                        <AutoComplete
                            id={'mfgYear'}
                            name={'mfgYear'}
                            source={autoCompleteSuggestion}
                            placeholder={trailerYearLabel}
                            onConfirm={autoCompleteSelection}
                            defaultValue={
                                uteContextFormData.trailerList['mfgYear']
                            }
                        ></AutoComplete>
                        <div
                            className="error-message error-hide"
                            id="error-mfgYear"
                        >
                            {errorMessage}
                        </div>
                    </div>
                </div>
                <UteFooter
                    title={UTE_PAGE_TITLE.BASIC_INFO}
                    handleDisable={nextBtnDisable}
                    isLoading={() => null}
                    handleModalCancel={props.handleModalCancel}
                    handleBasicContinueClick={handleContinueClick}
                    handleBasicBackClick={handleBackClick}
                />
            </div>
        </>
    );
};
