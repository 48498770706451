import React, { useEffect } from 'react';
import { FMButton } from '@own/fds-react';
import PreferredDealerService, {
    PreferredDealerResponse,
} from '../../../services/preferred-dealer/preferred-dealer';
import { useFMPreferredDealer } from './hook/use-fm-preferred-dealer';
import { transformRelativeUrlValidateRoot } from '../../utils/router-util/router-util';

import './fm-preferred-dealer.scss';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
interface Props {
    profile: any;
    root: string;
    vehicleAttributes: VehicleAttributes;
    fmaRegion: string;
}

const FMPreferredDealer = (props: Props) => {
    const [
        preferredDealer,
        setPrereferredDealer,
    ] = React.useState<PreferredDealerResponse | null>(null);

    const content = useFMPreferredDealer(
        'vehicle-data',
        getVehicleDataSelector('fm-preferred-dealer', props?.vehicleAttributes),
        true
    );

    const {
        iconPath,
        ctaLabel,
        ctaAriaLabel,
        ctaTargetBlank,
        ctaPath,
        gxpCtaPath,
    } = content || {};

    const isNullOrEmpty = (str: string | null | undefined): boolean => {
        return str == null || str.trim() === '';
    };
    const preferredDealerResult = preferredDealer?.dealerResultList?.[0];
    const { dealerName, phone, cupid } = preferredDealerResult ?? {};
    const dealerPath = preferredDealerResult?.url || ctaPath;
    const url =
        preferredDealerResult?.smarttprovider?.toLocaleLowerCase() === 'x' &&
        !isNullOrEmpty(gxpCtaPath)
            ? `${gxpCtaPath}?dealerCupid=${cupid}`
            : dealerPath;

    const showPreferredDealer = !isNullOrEmpty(url);
    const showNameAndPhone =
        !isNullOrEmpty(phone) && !isNullOrEmpty(dealerName);
    const target = ctaTargetBlank ? '_blank' : '_self';

    useEffect(() => {
        const service = new PreferredDealerService();
        const dealerId = service.getPreferredDealer(
            props.vehicleAttributes.vin!,
            props?.profile
        );
        if (!isNullOrEmpty(dealerId)) {
            service.request(dealerId!).then(response => {
                setPrereferredDealer(response);
            });
        }
        return () => setPrereferredDealer(null);
    }, [props?.vehicleAttributes?.vin, props?.profile]);

    return (
        <>
            {content && showPreferredDealer && (
                <article className="fm-preferred-dealer-wrapper">
                    {showNameAndPhone && (
                        <section className="fm-preferred-dealer">
                            <img
                                className="fm-preferred-dealer__icon"
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${iconPath}`}
                                role="presentation"
                                alt=""
                            />
                            <p className="fm-preferred-dealer__name">
                                {dealerName}
                            </p>
                            <p className="fm-preferred-dealer__phone">
                                {phone}
                            </p>
                        </section>
                    )}
                    <FMButton
                        className="fm-preferred-dealer-cta"
                        type="primary"
                        label={ctaLabel}
                        ariaLabel={ctaAriaLabel}
                        target={target}
                        href={transformRelativeUrlValidateRoot(
                            url!,
                            props.root
                        )}
                    />
                </article>
            )}
        </>
    );
};
export default FMPreferredDealer;
