import React from 'react';
import { ProfileWithVehiclesResponse } from '../models/profile-with-vehicles';

export interface ClientContextInterface {
    profile?: ProfileWithVehiclesResponse;
    setProfile: (profile: ProfileWithVehiclesResponse) => void;
}

export const ClientContextDefault: ClientContextInterface = {
    setProfile: (profile: ProfileWithVehiclesResponse) =>
        (ClientContextDefault.profile = profile),
};

const ClientContext = React.createContext<ClientContextInterface>(
    ClientContextDefault
);

export default ClientContext;
