import { useContext } from 'react';
import ServerContext from '../../../contexts/serverContext';
import ServiceHandler from '../../../services/service-handler';

export const getVin = () => {
    const serverContext = useContext(ServerContext);

    const userSelectedVin =
        typeof window === 'undefined'
            ? serverContext.profile?.vin
            : window.sessionStorage.getItem('selectedVin');
    return userSelectedVin;
};

export const getIsBevVehicle = async (selectedVin: any) => {
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    let isBev;
    await ServiceHandler.VehicleTypeService.request(
        selectedVin,
        currentLanguageRegionCode,
        current3LetterCountryCode?.toUpperCase()
    )
        .then(
            VehicleEngineType =>
                (isBev =
                    VehicleEngineType?.vehicleData?.engineType === 'BEV' ||
                    VehicleEngineType?.isBev)
        )
        .catch(error => {
            isBev = false;
            console.error(error);
        });

    return isBev;
};
