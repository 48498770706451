import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';
import { AxiosResponse } from 'axios';
export interface VhrLanding {
    collectionDate: string;
    vhrSheduleList: ScheduleList[];
    vhrStatus: VHRStatus;
    warningsTotal: number;
}

export interface ScheduleList {
    Frequency: string;
    LastPublished: string;
    LastUpdated: string;
    NextScheduled: string;
    Vin: string;
}

export enum VHRStatus {
    ELIGIBLE = 'ELIGIBLE',
    ELIGIBLE_WITH_UPGRADE = 'ELIGIBLE_WITH_UPGRADE',
    AVAILABLE = 'AVAILABLE',
    NOTRUN = 'NOTRUN',
    EXPIRED = 'EXPIRED',
    INELIGIBLE = 'INELIGIBLE',
}

export default class VhrService {
    public request(vin: string): Promise<VhrLanding | null> {
        const vhrLandingUrl = DSL_BASE_URL + DSL_API_PATH.VHR_LANDING;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin: vin,
        };
        const response = HttpService.get<VhrLanding>(vhrLandingUrl, true, {
            headers,
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<VhrLanding>
    ): VhrLanding | null {
        if (response.data) {
            return response.data;
        }
        return null;
    }
}
