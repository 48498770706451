import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import { Video, VideoCategoriesResponse } from './popular-video-service.types';
import HttpService from '../http-service/http-service';

export default class PopularVideoService {
    async getPopularVideos(make: string, languageRegionCode: string) {
        const url =
            DSL_BASE_URL +
            DSL_API_PATH.POPULAR_VIDEOS +
            `?make=${make}&languageRegionCode=${languageRegionCode} `;
        const response = HttpService.get<Video[]>(url, true, {
            headers: HttpService.getAppIdRequestHeaders(),
        });
        response.catch(err => {
            console.error(err.message);
            return Promise.resolve([]);
        });
        return response.then(axiosResponse => axiosResponse.data);
    }

    async getPopularVideosByViewCount(
        make: string,
        languageRegionCode: string,
        model?: string,
        year?: number
    ) {
        const url =
            DSL_BASE_URL +
            DSL_API_PATH.POPULAR_VIDEOS_BY_VIEW_COUNT +
            `?make=${make}&languageRegionCode=${languageRegionCode}` +
            (model === undefined ? '' : `&model=${model}`) +
            (year === undefined ? '' : `&year=${year}`);

        return await HttpService.get<Video[]>(url, true, {
            headers: HttpService.getAppIdRequestHeaders(),
        })
            .then(({ data }) => data)
            .catch(e => {
                console.error('error - ', e.message);
                return [];
            });
    }

    async getPopularVideosByYMM(
        make: string,
        languageRegionCode: string,
        model?: string,
        year?: number
    ) {
        const url =
            DSL_BASE_URL +
            DSL_API_PATH.POPULAR_VIDEOS_BY_YMM +
            `?make=${make}&languageRegionCode=${languageRegionCode}&model=${model}&year=${year}`;

        return await HttpService.get<Video[]>(url, true, {
            headers: HttpService.getAppIdRequestHeaders(),
        })
            .then(({ data }) => data)
            .catch(e => {
                console.error('error - ', e.message);
                return [];
            });
    }

    async getVideosByCategories(
        year: string,
        make: string,
        model: string,
        languageRegionCode: string
    ): Promise<VideoCategoriesResponse> {
        return await HttpService.get<VideoCategoriesResponse>(
            DSL_BASE_URL +
                DSL_API_PATH.VIDEOS_BY_CATEGORIES +
                `?year=${year}&make=${make}&model=${model}&languageRegionCode=${languageRegionCode}`,
            true
        )
            .then(({ data }) => data)
            .catch(e => {
                console.error(
                    'getVideosByCategories error - ',
                    e.message,
                    'Model name ',
                    model,
                    'year -  ',
                    year,
                    'languageRegionCode - ',
                    languageRegionCode
                );
                return {};
            });
    }

    async getVideosByCategory(
        category: string,
        year: string,
        make: string,
        model: string,
        languageRegionCode: string
    ) {
        return await HttpService.get<Video[]>(
            encodeURI(
                DSL_BASE_URL +
                    DSL_API_PATH.VIDEOS_BY_CATEGORY +
                    `?category=${category}&year=${year}&make=${make}&model=${model}&languageRegionCode=${languageRegionCode}`
            ),
            true,
            {
                headers: HttpService.getAppIdRequestHeaders(),
            }
        )
            .then(({ data }) => data)
            .catch(e => {
                console.error('getVideosByCategory error - ', e.message || '');
                return [];
            });
    }

    static yearTrim = (videoYears: string, year: string) => {
        const yearList = videoYears?.split(/[,|]/);
        if (yearList) {
            const trimmedYearList = yearList.map(element => element.trim());
            if (year.trim().length > 0 && trimmedYearList.indexOf(year) > -1) {
                return year;
            }
            return trimmedYearList[0];
        }
    };

    static modelTrim = (videoModels: string, model: string) => {
        const modelList = videoModels?.split(/[,|]/);
        if (modelList) {
            const trimmedModelList = modelList.map(element => element.trim());
            if (
                model.trim().length > 0 &&
                trimmedModelList.indexOf(model.toLowerCase()) > -1
            ) {
                return model;
            }
            return trimmedModelList[0];
        }
    };
}

export const popularVideos = new PopularVideoService();
