import {
    useContent,
    useExperienceContent,
} from '../../../../../hooks/use-server-content';
import { BaseWarrantyFragment } from '../../../../../models/experiencefragments/basewarranty';

export interface WarrantyDetailsContentProps {
    warrantyDetailsChevronText: string;
    warrantyDetailsHeader: string;
    warrantyDetailsSubText: string;
    espDetailsSubText: string;
    warrantyDetailsUnAuthenticatedText: string;
    espHeadline: string;
    flatRateHeadLine: string;
    espCTA: string;
    warrantyExpiredText: string;
    activeWarrantyWithOdometerReading: string;
    activeWarrantyWithoutOdometerReading: string;
    activeWarrantyWithoutMileage: string;
    activeWarrantyWithFlatRateReading: string;
    expiredWarrantyText: string;
    warrantyStartDate: string;
    warrantyStatusText: string;
    odometerLabel: string;
    uomMilesLabel: string;
    uomKmLabel: string;
    activeExtendedWarrantyContent: string;
    expiredExtendedWarrantyContent: string;
    flatRateContent: string;
    warrantyCTALabel: string;
    warrantyCTALink: string;
    warrantyCTAAnalyticsContext: string;
    espCTALabel: string;
    espCTALink: string;
    espCTAAnalyticsContext: string;
    warrantyGuideLabel: string;
    odometerTooltipHeader: string;
    odometerTooltipContent: string;
    warrantyServiceErrorMessage: string;
    WarrantyUnavailableErrorMessage: string;
    startandEndDateErrorMessage: string;
    startandEndDateErrorCTALabel: string;
    startandEndDateErrorCTALink: string;
    contentForInvalidWarranty: string;
    openInNewtabArialabel: string;
    baseWarrantyExpiredContentEU: string;
    extendedWarrantyExpiredContentEU: string;
    warrantyTermsAndConditions: string;
    enableStartDateAndAccordion: boolean;
}

export interface WarrantyElement {
    warrantyType: WarrantyType;
    warrantyInformation: WarrantyType;
}

export interface WarrantyType {
    value: string;
    title: string;
}

export const useWarrantyDetailsContent = (): WarrantyDetailsContentProps => {
    const [content, getValueByName] = useContent('common', 'warranty-details');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as WarrantyDetailsContentProps;
};

export const useBaseWarrantyContent = (): WarrantyElement[] => {
    const [warrantyContent] = useExperienceContent<BaseWarrantyFragment>(
        'common',
        'base-warranty',
        'contentfragmentlist'
    );
    const contentFragments: any = [];
    if (warrantyContent && Array.isArray(warrantyContent.items)) {
        warrantyContent.items.forEach(warrantyItem => {
            const contentFragment: WarrantyElement = warrantyItem.elements;
            contentFragments.push(contentFragment);
        });
    }
    return contentFragments as WarrantyElement[];
};
