import { useContent } from '../../../../hooks/use-server-content';
import { BannerType } from '../banner-type';
import { ExperienceType } from '../experience-type';

export interface NotificationBannerProps {
    hide: boolean;
    type: string;
    bannerDismissible: boolean;
    dismissAriaLabel: string;
    bannerText: string;
    showChevron: boolean;
    eventName?: string;
    shortCode?: {};
    contentId?: string;
}

export const useNotificationBannerContent = (
    category: ExperienceType,
    name: BannerType
): NotificationBannerProps | null => {
    const [content, getValueByName] = useContent(category, name);
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.bannerText) {
            contentFragment = null;
        }
    }

    return contentFragment as NotificationBannerProps;
};
