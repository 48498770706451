import { CardState } from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';
import ServiceHandler from '../../../../../../services/service-handler';

export const getSyncUpdateDataFromAPI = (userSelectedVin: any) => {
    return ServiceHandler.SyncMapService.request(userSelectedVin)
        .then(result => {
            if (result) return result;
        })
        .catch(error => {
            console.error(error.message);
        });
};

export const getSyncUpdateCardState = (syncUpdateAvailable: boolean) => {
    if (syncUpdateAvailable) {
        return CardState.WARNING;
    } else {
        return CardState.NORMAL;
    }
};

export const getSyncUpdateDescription = (
    syncUpdateApiData: any,
    syncUpdateContent: any
) => {
    const syncUpdateCount = syncUpdateApiData?.syncUpdateAvailable ? 1 : 0;
    if (syncUpdateApiData) {
        if (
            !syncUpdateApiData?.vehicleSyncEquipped &&
            !syncUpdateApiData?.syncUpdateAvailable
        ) {
            {
                return syncUpdateContent?.noSyncUpdatesText;
            }
        } else if (syncUpdateApiData?.syncUpdateAvailable) {
            {
                return `${syncUpdateCount} ${syncUpdateContent?.syncUpdatesText}`;
            }
        } else {
            {
                return syncUpdateContent?.noSyncUpdatesText;
            }
        }
    }
};
