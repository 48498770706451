import React, { useState, useEffect } from 'react';
import { PrimaryButton, Dropdown } from '../../../../common';
import './vehicle-from-garage.scss';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import ServiceHandler from '../../../../../services/service-handler';
import errorWarning from '../../../../../assets/error-warning.svg';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { VehicleUtil } from '../../../../utils/vehicle-util/vehicle-util';

interface Props {
    chooseYourVehicleFormHeadline: string;
    yourVehicleFieldLabel: string;
    chooseYourVehicleCta: string;
    vehiclesData: VehicleAttributes[];
    maintenanceScheduleNotFoundErrorMessage: string;
    showErrorPage: (isError: boolean) => void;
    goToMaintenanceScheduleViewDetails: (action: string) => void;
}

export const VehicleFromGarage = (props: Props) => {
    const {
        chooseYourVehicleFormHeadline,
        yourVehicleFieldLabel,
        chooseYourVehicleCta,
        vehiclesData,
        maintenanceScheduleNotFoundErrorMessage,
    } = props;
    const [selectedVinFromGarage, setSelectedVinFromGarage] = useState<
        string
    >();
    const garageOptions: any = [];
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [fireEvents] = useAnalytics();
    const vehicleUtil = new VehicleUtil();

    useEffect(() => {
        if (vehiclesData && Array.isArray(vehiclesData) && vehiclesData[0])
            setSelectedVinFromGarage(vehiclesData[0].vin);
    }, []);

    const triggerMaintenanceScheduleCTAOnClickAnalyticsEvent = () => {
        fireEvents(
            ['maintenance-schedule-landing-cta-onclick-event'],
            undefined,
            {
                maintenanceScheduleCtaContext: 'your vehicle',
            },
            false
        );
    };

    const chooseGarageChange = (
        label: string,
        id: number,
        value: string | undefined
    ) => {
        setSelectedVinFromGarage(value);
        setErrorMessage('');
    };

    const chooseGarage = () => {
        vehiclesData.forEach(vehicle => {
            garageOptions.push({
                label: vehicleUtil.getGarageVehicleName(vehicle),
                value: vehicle.vin,
            });
        });
        return garageOptions;
    };

    const onGarageSeeYourMaintenanceScheduleClick = () => {
        if (selectedVinFromGarage)
            ServiceHandler.MaintenanceScheduleService.getMaintenanceSchedule(
                selectedVinFromGarage
            )
                .then(() => {
                    setErrorMessage('');
                    window.sessionStorage.removeItem(
                        'maintenance-schedule-mileage'
                    );
                    triggerMaintenanceScheduleCTAOnClickAnalyticsEvent();
                    setTimeout(() => {
                        props.goToMaintenanceScheduleViewDetails(
                            selectedVinFromGarage
                        );
                    }, 1000);
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        setErrorMessage(
                            maintenanceScheduleNotFoundErrorMessage
                        );
                    } else {
                        props.showErrorPage(true);
                    }
                });
    };

    return (
        <div className="garage-section">
            <div className="heading-with-icon">
                <h4>{chooseYourVehicleFormHeadline}</h4>
            </div>
            <label className="dropdown-label">{yourVehicleFieldLabel}</label>
            {errorMessage && (
                <img className="error-icon" src={errorWarning} alt="" />
            )}
            <Dropdown
                id="garage-dropdown"
                label={''}
                noLabel={true}
                onChange={(e, index, value) =>
                    chooseGarageChange(e, index, value)
                }
                options={chooseGarage()}
                value={
                    garageOptions.find(
                        (opt: any) => opt.value === selectedVinFromGarage
                    )?.label || ''
                }
                className={`garage-dropdown-section ${
                    errorMessage ? 'dropdown-error' : ''
                }`}
            />
            {errorMessage && (
                <p className="error-message">
                    {maintenanceScheduleNotFoundErrorMessage}
                </p>
            )}
            <PrimaryButton
                className="continue-button"
                color={'dark'}
                fill={'fill'}
                chevron={true}
                onClick={onGarageSeeYourMaintenanceScheduleClick}
                ariaLabel={chooseYourVehicleCta}
            >
                {chooseYourVehicleCta}
            </PrimaryButton>
        </div>
    );
};
