import { useEffect, useState } from 'react';
import useUserProfile from '../../../../hooks/use-user-profile';
import PreferredDealerService, {
    PreferredDealerResponse,
} from '../../../../services/preferred-dealer/preferred-dealer';

export const usePreferredDealer = (vin: string): any => {
    const profile: any = useUserProfile();
    const [preferredDealerResponse, setPreferredDealerResponse] = useState<
        PreferredDealerResponse
    >();
    const preferredDealerService = new PreferredDealerService();

    useEffect(() => {
        if (vin && profile) {
            const dealerId = preferredDealerService.getPreferredDealer(
                vin,
                profile
            );
            if (dealerId)
                preferredDealerService
                    .request(dealerId)
                    .then((data: PreferredDealerResponse | null) => {
                        if (
                            data &&
                            data.dealerResultList &&
                            data.dealerResultList.length &&
                            data.dealerResultList[0].smarttsubset &&
                            ['A', 'R', 'X'].includes(
                                data.dealerResultList[0].smarttprovider
                            )
                        ) {
                            const transformedDealerResponse = data;
                            if (data.dealerResultList[0].url)
                                transformedDealerResponse.dealerResultList[0].scheduleServiceUrl = `${data.dealerResultList[0].url}/service-appointment/`;
                            setPreferredDealerResponse(
                                transformedDealerResponse
                            );
                        }
                    })
                    .catch(() => {
                        // handle API error
                    });
        }
    }, [profile, vin]);

    return preferredDealerResponse;
};
