import { useMemo } from 'react';
import { useContent } from '../../use-server-content';
import { mockHowToVideoContent } from '../__mocks__/how-to-video-aem-content.mock';

export interface HowToVideoContentProps {
    hide: boolean;
    htvBannerTitle: string;
    // htVBannerDescription: string;
    htvBannerUnauthDescription: string;
    htvBannerAuthDescription: string;
    htvBackgroundImageDesktop: string;
    htvBackgroundImageMobile: string;
    htvQuickTipImageDesktop: string;
    htvQuickTipImageMobile: string;
    htvElectricVehiclesImageDesktop: string;
    htvElectricVehiclesImageMobile: string;
    htvFordPassImageDesktop: string;
    htvFordPassImageMobile: string;
    htvFordServicesImageDesktop: string;
    htvFordServicesImageMobile: string;
    htvFordTechnologyImageDesktop: string;
    htvFordTechnologyImageMobile: string;
    htvKeysAndLocksImageDesktop: string;
    htvKeysAndLocksImageMobile: string;
    htvOilChangeImageDesktop: string;
    htvOilChangeImageMobile: string;
    htvOwnerResourcesImageDesktop: string;
    htvOwnerResourcesImageMobile: string;
    htvRecallsImageDesktop: string;
    htvRecallsImageMobile: string;
    htvSyncImageDesktop: string;
    htvSyncImageMobile: string;
    htvTiresAndWheelsImageDesktop: string;
    htvTiresAndWheelsImageMobile: string;
    htvWarrantyImageDesktop: string;
    htvWarrantyImageMobile: string;
    htvMoreVehicleTopicsImageDesktop: string;
    htvMoreVehicleTopicsImageMobile: string;
    // htvCategoryVideosQuickTipImageDesktop: string; // TODO: this does not appear on response
    // htvCategoryVideosQuickTipImageMobile: string; // TODO: this does not appear on response
    searchByVehicleTitle: string;
    yearTitle: string;
    modelTitle: string;
    searchPrimaryButton: string;
    searchPrimaryButtonAriaLabel: string;
    viewAllModelsSecondaryButton: string;
    viewAllModelsSecondaryButtonAriaLabel: string;
    popularHowToVideosTitle: string;
    moreDyfVideosTitle: string;
    htvLibrarylandingPageTitle: string;
    htvLibraryBannerDescription: string;
    searchAnotherVehicleSecondaryButton: string;
    searchAnotherVehicleSecondaryButtonAriaLabel: string;
    viewAllVideosSecondaryButton: string;
    viewAllVideosSecondaryButtonAriaLabel: string;
    viewMoreVideos: string;
    viewAllHtvTitle: string;
    videoLibraryPageSubTitle: string;
    htvLibraryErrorDescription: string;
    videoPageErrorDescription: string;
    lincolnServicesImageDesktop: string | null;
    lincolnServicesImageMobile: string | null;
    lincolnTechnologyImageDesktop: string | null;
    lincolnTechnologyImageMobile: string | null;
    lincolnWayAppImageDesktop: string | null;
    lincolnWayAppImageMobile: string | null;
    howToVideosBreadcrumbSupport: string;
    howToVideosBreadcrumbHowToVideos: string;
    livestreamBannerYmmText: string;
    livestreamBannerCtaText: string;
    livestreamBannerCtaAriaLabel: string;
    dyfCategoryTitle?: string;
    dyfCategoryDescription?: string;
}

export const useHowToVideoContent = (): HowToVideoContentProps | null => {
    const [content, getValueByName] = useContent('dyf', 'how-to-videos');

    const contentFragment = useMemo(() => {
        let fragment: any = null;
        if (content) {
            fragment = {};
            content?.elements.forEach(element => {
                fragment[`${element.name}`] = getValueByName(`${element.name}`);
            });

            if (fragment.hide || !fragment.htvBannerTitle) {
                fragment = null;
            }
            return fragment;
        } else {
            return mockHowToVideoContent;
        }
    }, [content]);

    return contentFragment as HowToVideoContentProps;
};
