import React from 'react';

interface Props {
    text: string;
}

export const BetaWrapper = (props: Props) => {
    if (props.text) {
        const noBetaText = props.text.indexOf('beta') === -1;
        if (noBetaText) {
            return <span>{props.text}</span>;
        }
    }
    return (
        <>
            {props.text && (
                <span className="beta-wrapper">
                    {props.text.split('beta')[0]}{' '}
                    <sup className="beta-superscript">beta</sup>
                </span>
            )}
        </>
    );
};
