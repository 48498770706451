import React from 'react';
import './checkbox.scss';

interface Props {
    placeHolder?: string;
    className?: string;
    label: string;
    onChange?: (e: any) => void;
    onClick?: (e: any) => void;
    onKeyDown?: (e: any) => void;
    ariaLabel: string;
    name: string;
    value?: string;
    testId?: string;
    target?: string;
    checked?: boolean;
    ariadescribedBy?: string;
    disabled?: boolean;
}

const Checkbox = (props: Props) => (
    <div className="check-box-spacing">
        <input
            id={props.name + '-field'}
            data-testid="checkbox-text"
            type="checkbox"
            className={`checkbox ${props.className || ''}`}
            placeholder={props.placeHolder}
            aria-label={props.ariaLabel}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
            onKeyDown={props.onKeyDown}
            checked={props.checked}
            aria-describedby={props.ariadescribedBy}
            disabled={props.disabled || false}
        />
        <label htmlFor={props.name + '-field'} className="checkbox-label">
            {props.label}
        </label>
    </div>
);

export default Checkbox;
