/* This component is for footer Change Language option implementation.
 Please don't use this other than in Footer.
 */
import React, { useState } from 'react';

import ServiceHandler from '../../../services/service-handler';
import './footer.scss';
import {
    AppConfigurationService,
    SupportedLanguage,
    SupportedLanguageOverride,
} from '../../../services/app-configuration-service/app-configuration-service';
import Link from '../../common/link/link';
import DOMPurify from 'dompurify';

interface Props {
    className?: string;
    tabIndex: number;
    langText: string;
    dialogTitle: string;
}

export const ChangeLanguageToggle = (props: Props) => {
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
    const openDropdown = () => {
        setPanelIsOpen(!panelIsOpen);
    };
    const languageGlobe = './icons/language-globe.svg';
    const appConfigurationService = ServiceHandler.AppConfigurationService;
    const {
        currentLanguage,
        currentDomain,
        currentRoot,
    } = appConfigurationService;
    const currentAppConfiguration = new AppConfigurationService().getAppConfiguration();
    const [languages] = useState<SupportedLanguage[]>(
        appConfigurationService
            .getSupportedLanguages()
            .filter(l => l.domain !== currentDomain)
    );

    function getUrlForLanguage(language: SupportedLanguage): string {
        const curPathName = DOMPurify.sanitize(window.location.pathname);
        const currentPathWithoutBase =
            curPathName.indexOf(currentRoot) === 0
                ? curPathName.substr(currentRoot.length)
                : '';
        const url = `${window.location.protocol}//${language.domain}${window
            .location.port && ':' + window.location.port}${
            language.root
        }${currentPathWithoutBase}`;
        return url;
    }

    const languageList = () => {
        if (
            appConfigurationService.getAppConfiguration().environment !==
            'fallback'
        ) {
            return languages.map((language: SupportedLanguage) => (
                <li className="list-lang-item" key={language.domain}>
                    <Link
                        className="primary-button"
                        href={`${getUrlForLanguage(language)}`}
                    >
                        <span className="button-label">{language.name}</span>
                    </Link>
                </li>
            ));
        } else {
            return appConfigurationService
                .getLanguageOverrides()
                .map((language: SupportedLanguageOverride) => (
                    <li
                        className="list-lang-item"
                        key={`nodomain-${language.languageRegionCode}`}
                    >
                        <button
                            className="primary-button"
                            onClick={() => {
                                (window as any).languageOverride(
                                    language.languageName,
                                    language.langScript,
                                    language.languageRegionCode,
                                    language.countryCode,
                                    language.threeLetterCountryCode
                                );
                                window.location.reload();
                            }}
                        >
                            <span className="button-label">
                                {language.languageName}
                            </span>
                        </button>
                    </li>
                ));
        }
    };

    return (
        <>
            {currentAppConfiguration.fmaRegion !== 'eu' && (
                <div className="language-toggle-container">
                    <button
                        className="toggle-button"
                        tabIndex={props.tabIndex}
                        onClick={() => {
                            setPanelIsOpen(!panelIsOpen);
                        }}
                    >
                        <img src={languageGlobe} alt="" />
                        <span className="toggle-text-footer">
                            {props.langText}
                        </span>
                    </button>
                    {panelIsOpen && (
                        <div className="language-panel-footer button-separated-list-container">
                            <div>{props.dialogTitle.toUpperCase()}</div>
                            <div onClick={openDropdown} className="close">
                                &times;
                            </div>
                            <ul className="list-wrapper">
                                <li
                                    className="list-lang-item"
                                    key={currentDomain}
                                >
                                    <div className="primary-button disabled">
                                        <span className="button-label">
                                            {currentLanguage}
                                        </span>
                                    </div>
                                </li>
                                {languageList()}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
