import { useContent } from '../../../../hooks/use-server-content';

export interface CheckRebateStatusProps {
    emailNotFound: string;
    trackingNumberNotFound: string;
    questionsPartThreeText: string;
    rebateRequestedStatus: string;
    inValidTrackingNumberMsg: string;
    rebateRequestedTrackingNumber: string;
    trackingNumberErrorMsg: string;
    emailText: string;
    emailIdErrorMsg: string;
    inValidemailMsg: string;
    questionsPartFourNumber: string;
    rebateRequestedDate: string;
    checkRebatesTitle: string;
    rebateRequestedDealer: string;
    trackingNumberText: string;
    questionsPartTwoEmail: string;
    formButtonText: string;
    forgotTrackingNumber: string;
    rebateRequestedHeader: string;
    rebateRequestedVin: string;
    rebateRequestedServiceDate: string;
    questionsPartOneText: string;
    rebateRequestedAnotherStatus: string;
}

export const useCheckRebateStatusContent = (): CheckRebateStatusProps | null => {
    const [content, getValueByName] = useContent(
        'redeem-rebates',
        'check-rebate-status'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as CheckRebateStatusProps;
};
