import { useContent } from '../../../../../hooks/use-server-content';

export interface ConfirmationContentProps {
    title: string;
    rebateStatusText: string;
    dateOfServiceText: string;
    vinText: string;
    dealerText: string;
    submissionDateText: string;
    trackingText: string;
    doneButtonText: string;
}

export const useConfirmationContent = (): ConfirmationContentProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates/request-form-steps',
        'confirmation'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ConfirmationContentProps;
};
