import React from 'react';
import './ford-account-info.scss';
import { FMButton } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import parse from 'html-react-parser';
import AuthenticationService from '../../../../../services/authentication-service/authentication-service';
import { useViewport } from '../../../../../hooks/owners-osb';
const FordAccountInfo = () => {
    const { isMobileView } = useViewport();
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'vehicle-step') || {};
    const authService = new AuthenticationService();
    const handleSignInClick = () => {
        authService.login();
    };
    return (
        <div
            className="osb-v3-ford-account-login-container"
            data-testId="ford-account-login"
        >
            {isMobileView && (
                <div className="login-btn" data-testid="ford-account-login-cta">
                    <FMButton
                        label={osbContentData?.loginOrRegisterButtonLabel}
                        type="primary"
                        onClick={handleSignInClick}
                    />
                </div>
            )}

            <div
                className="ford-account-section-title"
                data-testid="ford-account-title"
            >
                {osbContentData?.fordAccountLabel?.toString()}
            </div>
            <div
                className="ford-account-section-desc"
                data-testid="ford-account-desc"
            >
                {parse((osbContentData?.fordAccountDesc || '').toString())}
            </div>

            <div className="osb-dealer-login-container">
                <div className="delaer-login-section">
                    <div
                        className="login-instruction"
                        data-testid="ford-account-login-desc"
                    >
                        {parse(
                            (
                                osbContentData?.loginOrRegiserDescription || ''
                            ).toString()
                        )}
                    </div>
                    {!isMobileView && (
                        <div
                            className="login-btn"
                            data-testid="ford-account-login-cta"
                        >
                            <FMButton
                                label={
                                    osbContentData?.loginOrRegisterButtonLabel
                                }
                                type="primary"
                                onClick={handleSignInClick}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FordAccountInfo;
