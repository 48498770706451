import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import './popover.scss';
import { KEYBOARD_KEYS } from '../../../constants';

interface Props {
    content: ReactNode;
    handleClose: (event: React.MouseEvent) => void;
    heading: string;
    subheading?: string;
    footer?: ReactNode;
    focusElement?: string;
    name: string;
    tooltipPosition?: string;
    mask?: boolean;
    popoverPosition: {
        left: number;
        top: number;
    };
}

const Popover = (props: Props) => {
    const {
        handleClose,
        popoverPosition,
        name,
        tooltipPosition,
        mask,
        heading,
        subheading,
        content,
        footer,
        focusElement,
    } = props;
    const [showPopover, setPopoverState] = useState<boolean>(true);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const el = document.createElement('div');
    const rootElement = document.getElementById('root');
    el.className = `popover popover-${name} popover-${tooltipPosition}-arrow ${
        mask ? '' : 'no-mask'
    }`;

    const hidePopover = (e: any) => {
        e.preventDefault();
        el.classList.add('hide');
        setPopoverState(false);
        handleClose(e);
    };

    const setFocusBack = () => {
        setTimeout(() => {
            if (focusElement) {
                const focusedElement = document.querySelectorAll(
                    focusElement
                )[0] as HTMLElement;
                focusedElement.setAttribute('tabIndex', '0');
                focusedElement.focus();
            }
        }, 500);
    };

    const handlePopoverClose = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            hidePopover(event);
            setFocusBack();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (
            event.key === KEYBOARD_KEYS.ENTER ||
            event.key === KEYBOARD_KEYS.SPACE
        ) {
            hidePopover(event);
            setFocusBack();
        }
    };

    const handleTabKey = (event: any) => {
        if (event.key === KEYBOARD_KEYS.TAB && event.shiftKey) {
            const contentElement = document.getElementsByClassName(
                `popover-content ${name}`
            )[0] as HTMLElement;
            contentElement.focus();
        } else if (event.key === KEYBOARD_KEYS.TAB) {
            const closeElement = document.getElementsByClassName(
                'popover-close'
            )[0] as HTMLElement;
            closeElement.focus();
        }
    };
    const handleTabEventForLink = () => {
        const paraElem = document.querySelectorAll('.popover-rte p');
        const anchorElem = document.querySelectorAll('.popover-rte a');
        if (paraElem && paraElem.length > 0) {
            for (let i = 0; i < paraElem.length; i++) {
                const paraElement = paraElem[i] as HTMLElement;
                paraElement.setAttribute('tabIndex', '0');
                const anchorElement = anchorElem[i] as HTMLElement;
                anchorElement.setAttribute('tabIndex', '-1');
                const anchorHref: string | null = anchorElement.getAttribute(
                    'href'
                );
                if (anchorHref) {
                    paraElement.onkeypress = function(event) {
                        if (event.key === KEYBOARD_KEYS.ENTER) {
                            window.open(anchorHref);
                        }
                    };
                }
            }
        }
    };
    const handleOutsideClick = (event: any, wrapperRef: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            hidePopover(event);
        }
    };

    useEffect(() => {
        rootElement?.firstElementChild?.append(el);
        document.body.classList.add('no-scroll');
        setTimeout(() => {
            document.addEventListener('click', e =>
                handleOutsideClick(e, wrapperRef)
            );
        });
        const closeElement = document.getElementsByClassName(
            'popover-close'
        )[0] as HTMLElement;
        closeElement && closeElement.focus();
        handleTabEventForLink();
        return () => {
            document.body.classList.remove('no-scroll');
            rootElement?.getElementsByClassName('popover')[0].remove();
            document.removeEventListener('click', e =>
                handleOutsideClick(e, wrapperRef)
            );
        };
    }, []);

    const element = (
        <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="FDSIconRightHeader"
            aria-describedby="FDSIconRightContent"
            ref={wrapperRef}
            className="popover-container"
            data-testid="popover-container"
            onKeyDown={e => handlePopoverClose(e)}
            style={{
                position: 'absolute',
                left: `${popoverPosition.left}px`,
                top: `${popoverPosition.top}px`,
            }}
        >
            <div className="popover-wrapper" aria-modal="true">
                <div onKeyUp={e => handleTabKey(e)} tabIndex={0}></div>
                <img
                    className="popover-close"
                    data-testid="popover-close"
                    alt=""
                    tabIndex={0}
                    aria-label="close tooltip"
                    onClick={e => hidePopover(e)}
                    onKeyDown={e => handleKeyDown(e)}
                />
                <h2 id="FDSIconRightHeader" className="popover-heading">
                    {heading}
                </h2>
                {props.subheading && (
                    <p className="popover-subheading">{subheading}</p>
                )}
                <div
                    id="FDSIconRightContent"
                    className={`popover-content ${name}`}
                    tabIndex={0}
                >
                    {content}
                </div>
                <div className="popover-footer">{footer}</div>
                <div
                    className="popover-bottom"
                    tabIndex={0}
                    onKeyUp={e => handleTabKey(e)}
                ></div>
            </div>
        </div>
    );

    return showPopover ? createPortal(element, el) : null;
};

export default Popover;
