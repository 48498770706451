import React from 'react';
import { Link } from '../../../../common';
import { FdsChevron } from '../../../../common/chevron/fds-chevron';
import { useOfferCategory } from '../../hooks/use-offer-category';
import ServerSideService from '../../../../../../src/services/server-side-service/server-side-service';
interface Props {
    categoryName: string;
}
export const CaoOptionalCta = (props: Props) => {
    const categoryContent = useOfferCategory();
    const isMobile = ServerSideService.isClientSide()
        ? window?.innerWidth <= 991
        : false;

    console.log('categoryName----', props.categoryName);
    return (
        <div>
            {categoryContent && (
                <div>
                    <Link
                        className="cta-inline first-cta"
                        href={categoryContent.cta1Path}
                        target={categoryContent.cta1TargetBlank ? '_blank' : ''}
                        aria-label={categoryContent.cta1AriaLabel}
                        onKeyDown={() => {
                            if (!isMobile) {
                                document
                                    .querySelectorAll(
                                        `.print-button, .tooltip__button , .view-offer-button, .rewards-card a`
                                    )
                                    .forEach(value => {
                                        value.setAttribute('tabIndex', '-1');
                                    });
                            }
                        }}
                    >
                        {categoryContent.cta1Label}
                        <div className="chevron">
                            <FdsChevron type="filled" direction="right" />
                        </div>
                    </Link>
                    <br />
                    <Link
                        className="cta-inline"
                        href={categoryContent.cta2Path}
                        target={categoryContent.cta2TargetBlank ? '_blank' : ''}
                        aria-label={categoryContent.cta2AriaLabel}
                    >
                        {categoryContent.cta2Label}
                        <div className="chevron">
                            <FdsChevron type="filled" direction="right" />
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
};
CaoOptionalCta.displayName = 'CaoOptionalCta';
