import { ContentElements } from '../../../models/osb-model/osb-content-details';
import OsbUtilService from '../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../services/service-handler';

export const callContentService = async (modelName: string) => {
    return await ServiceHandler.OsbV3ContentService.getOsbContentModel(
        OsbUtilService.getAppConfiguration().brand,
        OsbUtilService.getAppConfiguration().countryCode,
        OsbUtilService.getAppConfiguration().languageRegionCode,
        modelName
    );
};

export const getObjectFromAEMJson = (
    key: string,
    data: any[] | ContentElements[]
) => {
    if (data) {
        const item = data.filter(part => {
            return part.name === key;
        });
        if (
            item &&
            item.length > 0 &&
            Object.prototype.hasOwnProperty.call(item[0], 'value')
        ) {
            return item[0].value === null ? '' : item[0].value;
        }
    }
    return '';
};

export const formatRegVinNumber = (
    regVin: string,
    allowSpecialCharacters: boolean
) => {
    if (!allowSpecialCharacters) {
        return regVin
            .replace(/ /g, '')
            .replace(/-/g, '')
            .toUpperCase();
    }
    return regVin.toUpperCase();
};
