import React from 'react';
import './vehicle-selector-wrapper.ford.scss';
import ModelYearDropdown from '../model-year-dropdown/model-year-dropdown';
import VehicleSelectorVinInput from '../vehicle-selector-vin-input/vehicle-selector-vin-input';
import { useDyfVehicleSelectorContent } from '../../../../views/onboarding-vehicle-selector-view/hooks/use-dyf-vehicle-selector-content';

export const VehicleSelectorWrapper = () => {
    const aemContent = useDyfVehicleSelectorContent();
    return (
        <div className={'vehicle-selector-wrapper'}>
            <div className={'onboarding-vehicle-selector-container'}>
                <div className={'onboarding-vehicle-selector-header'}>
                    <div className={'onboarding-vehicle-selector-title'}>
                        <span>{aemContent.vehicleSelectorTitle}</span>
                    </div>
                    <div className="onboarding-vehicle-selector-subcopy">
                        <span>{aemContent.vehicleSelectorDescription}</span>
                    </div>
                </div>
                <div className={'onboarding-vehicle-selector-content'}>
                    <div className={'onboarding-vin-container'}>
                        <VehicleSelectorVinInput
                            vinHeaderLabel={aemContent.vinLabelText}
                            ariaLabel={aemContent.vinLabelText}
                            whereDoIFindVinLabel={
                                aemContent.whereDoIFindVinText
                            }
                            whereDoIFindVinLink={aemContent.whereDoIFindVinLink}
                            vinInputErrorMessage={
                                aemContent.vinInputErrorMessage
                            }
                            whereDoIFindVinCta={aemContent.whereDoIFindVinText}
                            submitLabel={aemContent.searchButtonText}
                            submitButtonAriaLabel={
                                aemContent.searchButtonAriaLabel
                            }
                        />
                    </div>
                    <div className={'onboarding-selector-divider'}>
                        <span>{aemContent.orText}</span>
                    </div>
                    <div className={'onboarding-ymm-container'}>
                        <ModelYearDropdown
                            yearDropdownLabel={aemContent.yearLabelText}
                            modelDropdownLabel={aemContent.modelLabelText}
                            submitLabel={aemContent.searchButtonText}
                            submitButtonAriaLabel={
                                aemContent.searchButtonAriaLabel
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
