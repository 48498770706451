import React from 'react';
import { PrimaryButton, SecondaryButton } from '../../../common';
import { RedirectData } from '../../../../support/components/FMALogin/FMALogin';

interface Props {
    redirectData?: RedirectData;
    submitLabel?: string | any;
    findMyVinLink?: string;
    findMyVinLabel: string | undefined;
    dFlex: boolean | undefined;
    errorMessage: string | undefined;
    validateVin: (vin: string) => void;
    vin: string;
    inputIsntFullVin: () => boolean;
}
export const VinSelectorButtons = (props: Props) => {
    const errorMessageClass = props.errorMessage ? 'd-flex-error' : 'd-flex';
    const vehicleSelectorDFlexErrorClass = props.dFlex ? errorMessageClass : '';

    return (
        <>
            {props.findMyVinLink && props.findMyVinLabel && (
                <SecondaryButton
                    noChevron={true}
                    linkTarget={'_blank'}
                    link={props.findMyVinLink}
                    className={'find-my-vin-link'}
                >
                    {props.findMyVinLabel}
                </SecondaryButton>
            )}
            <PrimaryButton
                className={
                    props.redirectData?.id === 'sync-maps' &&
                    props.submitLabel?.length >= 19
                        ? `vehicle-selector-button-sync-mobile ${vehicleSelectorDFlexErrorClass}`
                        : `vehicle-selector-button ${vehicleSelectorDFlexErrorClass}`
                }
                color="dark"
                fill="fill"
                ariaLabel={props.submitLabel}
                chevron={true}
                dataTestId="vin-submit-button"
                onClick={() => {
                    props.validateVin(props.vin);
                }}
                disabled={props.inputIsntFullVin()}
            >
                {props.submitLabel}
            </PrimaryButton>
        </>
    );
};
