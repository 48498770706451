import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

export interface SubscriptionsReponse {
    paidSubscriptions: number;
    paidSubscriptionsExpiring: number;
    freeSubscriptions: number;
    freeSubscriptionsExpiring: number;
    error?: string;
}

class SubscriptionsService {
    public async getSubscriptions(vin: string): Promise<SubscriptionsReponse> {
        const {
            currentLanguageRegionCode: languageName,
            current3LetterCountryCode: countryCode,
        } = new AppConfigurationService();
        const languageSplit = languageName?.split('-');
        const language = `${
            languageSplit[0]
        }-${languageSplit[1]?.toUpperCase()}`;
        const url = `${DSL_BASE_URL}${
            DSL_API_PATH.SUBSCRIPTIONS
        }?language=${language}&countryCode=${countryCode?.toUpperCase()}`;
        const axiosResponse = HttpService.get<SubscriptionsReponse>(url, true, {
            headers: {
                ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
                vin,
            },
        });
        axiosResponse.catch(err => console.error('Subscriptions error: ', err));
        return axiosResponse.then(response => response.data);
    }
}

export default SubscriptionsService;
