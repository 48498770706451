import React, { useCallback, useEffect, useState } from 'react';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import './eu-login.scss';
import { SignIn } from '../../../../models/experiencefragments/header';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import useUserProfile from '../../../../hooks/use-user-profile';

const userLoggedOut = './icons/user-profile-loggedout-outline.svg';
const userLoggedIn = './icons/user-profile-loggedin-outline.svg';

interface Props {
    content: SignIn;
}

export const EuLogin = (props: Props) => {
    const authService = new AuthenticationService();
    const profile = useUserProfile();

    const determineUserIcon = useCallback(() => {
        let icon = userLoggedOut;
        if (serverSideService.isClientSide() && profile) icon = userLoggedIn;
        return icon;
    }, [profile]);

    const [userIcon, setUserIcon] = useState<string>(determineUserIcon());

    useEffect(() => {
        if (profile) {
            setUserIcon(determineUserIcon());
        }
    }, [profile, determineUserIcon]);

    const toggleDropwdownAndOverlay = () => {
        document
            .getElementsByClassName('eulogin-dropdown')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('eulogin-button')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('main-overlay')[0]
            .classList.toggle('open');
    };

    if (serverSideService.isClientSide()) {
        window.onresize = () => {
            setUserIcon(determineUserIcon());
        };
        document.body.onclick = (e: MouseEvent) => {
            const eventSource = e.target as HTMLElement;
            const loginDropdown = document.getElementsByClassName(
                'eulogin-dropdown'
            )[0];
            if (
                !eventSource.closest('.eulogin-container') &&
                loginDropdown &&
                loginDropdown.classList.contains('active')
            )
                toggleDropwdownAndOverlay();
        };
    }

    const loginText = () => {
        return profile ? '' : props.content.signInTitle;
    };

    const toggleDisplayOrLogin = () => {
        if (profile) {
            toggleDropwdownAndOverlay();
        } else authService.login();
    };

    return (
        <div className="eulogin-container">
            <div>
                <button
                    className="eulogin-button"
                    onClick={toggleDisplayOrLogin}
                >
                    <img
                        src={userIcon}
                        className={`eu-profile-icon ${
                            userIcon === userLoggedIn ? '' : 'logout'
                        }`}
                        alt="user-profile"
                    ></img>
                    <i className="icon linc1638-lw-icons-38px-c16-expanded-06 hide-me" />
                    <span className="eulogin-text">{loginText()}</span>
                </button>
            </div>
            {profile &&
                Array.isArray(props.content.signInAuthItems) &&
                props.content.signInAuthItems.length != 0 &&
                props.content.signInAuthItems[0] && (
                    <div
                        data-testid="eulogin-dropdown"
                        className="eulogin-dropdown"
                    >
                        <ul className="eulogin-dropdown-list">
                            <li className="eulogin-dropdown-list-item">
                                <a href={props.content.signInAuthItems[0].url}>
                                    {props.content.signInAuthItems[0].title?.replace(
                                        '[firstname]',
                                        profile.profile.firstName
                                    )}
                                </a>
                            </li>
                            <li className="eulogin-dropdown-list-item">
                                <button onClick={authService.logout}>
                                    {props.content.signOutTitle}
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
        </div>
    );
};
