import React from 'react';
import { TPMS } from '../../../models/connected-vehicle';
import { TirePressureContent } from '../../../models/experiencefragments/connected-vehicle-bar';

import './fm-connected-tire-pressure.scss';
interface Props {
    tpms: TPMS;
    isTpmsAvaialble: boolean;
}

const FMConnectedTirePressure = (props: Props & TirePressureContent) => {
    const {
        tpms,
        isTpmsAvaialble,
        rearWheelText: rear,
        frontWheelText: front,
        leftWheelText: left,
        rightWheelText: right,
        title,
        icon,
    } = props;

    const rowClassName = (status?: string) => {
        if (status) {
            if (status.toLowerCase() === 'alert') return 'fm-threshold';
        }
        return '';
    };

    const ceilValue = (number?: string) => {
        if (tpms && number && !Number.isNaN(Number(number))) {
            const pressureUnits =
                String(tpms.uomPressure).toLocaleLowerCase() === 'kpa'
                    ? 'kPa'
                    : String(tpms.uomPressure).toLocaleLowerCase();
            if (pressureUnits?.toLocaleLowerCase() === 'bar')
                return (
                    Math.round((Number(number) + Number.EPSILON) * 100) / 100
                );
            return Math.ceil(Number(number));
        }
        return number;
    };

    const parseClassName = (className?: string) => {
        return className !== '' ? { className } : {};
    };

    const isValidTPMS =
        tpms?.leftFrontTirePressure?.value != null &&
        tpms?.rightFrontTirePressure?.value != null &&
        tpms?.outerLeftRearTirePressure?.value != null &&
        tpms?.outerRightRearTirePressure?.value != null &&
        tpms?.leftFrontTireStatus?.value != null &&
        tpms?.rightFrontTireStatus?.value != null;

    const leftFrontClass = rowClassName(
        tpms?.leftFrontTireStatus?.value?.toString()
    );
    const rightFrontClass = rowClassName(
        tpms?.rightFrontTireStatus?.value?.toString()
    );
    const outerLeftRearClass = rowClassName(
        tpms?.outerLeftRearTireStatus?.value?.toString()
    );
    const outerRightRearClass = rowClassName(
        tpms?.outerRightRearTireStatus?.value?.toString()
    );

    return (
        <>
            {isTpmsAvaialble && isValidTPMS && (
                <div className="fm-connected-tire-pressure">
                    <header className="fm-connected-tire-pressure__header">
                        <img
                            className="fm-connected-tire-pressure__header__icon"
                            src={`${process.env.REACT_APP_AEM_BASE_URL}${icon}`}
                            role="presentation"
                        />
                        <div className="fm-connected-tire-pressure__header__title">
                            {title}
                        </div>
                    </header>
                    <table className="fm-connected-tire-pressure__table">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="fm-connected-tire-pressure__table__frontire">
                                <td className="fm-connected-tire-pressure__table__cell">
                                    <span>{front}</span>
                                </td>
                                <td className="fm-connected-tire-pressure__table__cell">
                                    <span {...parseClassName(leftFrontClass)}>
                                        {`${left} ${ceilValue(
                                            tpms?.leftFrontTirePressure?.value
                                        )}`}
                                    </span>
                                </td>
                                <td className="fm-connected-tire-pressure__table__cell">
                                    <span {...parseClassName(rightFrontClass)}>
                                        {`${right} ${ceilValue(
                                            tpms?.rightFrontTirePressure?.value
                                        )}`}
                                    </span>
                                </td>
                            </tr>
                            <tr className="fm-connected-tire-pressure__table__reartire">
                                <td className="fm-connected-tire-pressure__table__cell">
                                    <span>{rear}</span>
                                </td>
                                <td className="fm-connected-tire-pressure__table__cell">
                                    <span
                                        {...parseClassName(outerLeftRearClass)}
                                    >
                                        {`${left} ${ceilValue(
                                            tpms?.outerLeftRearTirePressure
                                                ?.value
                                        )}`}
                                    </span>
                                </td>
                                <td className="fm-connected-tire-pressure__table__cell">
                                    <span
                                        {...parseClassName(outerRightRearClass)}
                                    >
                                        {`${right} ${ceilValue(
                                            tpms?.outerRightRearTirePressure
                                                ?.value
                                        )}`}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default FMConnectedTirePressure;
