import { useContent } from '../../../../hooks/use-server-content';

export interface GdprBannerProps {
    hide: boolean;
    fordLogoImagePath: string;
    cwaButtonLabel: string;
    cwaButtonAriaLabel: string;
    defaultStrictlyNecessaryCookie: string;
    defaultPerformanceCookie: string;
    defaultFunctionalCookie: string;
    defaultTargetingCookie: string;
    gdprBannerDescription?: string;
    manageCookiesButtonLabel?: string;
    manageButtonOnClickURL?: string;
    manageButtonAriaLabel?: string;
    manageButtonTarget?: string;
    agreeButtonLabel?: string;
    agreeButtonAriaLabel?: string;
    manageCookieSettingsPageDisplayTextPart1?: string;
    manageCookieSettingsPageDisplayTextPart2?: string;
    manageCookieSettingsPageHrefText?: string;
    manageCookieSettingsPageHrefURL?: string;
    manageCookieSettingsPageHrefAriaLabel?: string;
    declineCookiesButtonLabel: string;
    declineCookiesButtonAriaLabel: string;
    acceptCookiesButtonLabel: string;
    acceptCookiesButtonAriaLabel: string;
    paragraphOne: string;
    paragraphTwo: string;
    paragraphThree: string;
    websitePrivacyDisplayTextPart1?: string;
    websitePrivacyDisplayTextPart2?: string;
    websitePrivacyHrefText?: string;
    websitePrivacyHrefURL?: string;
    websitePrivacyHrefAriaLabel?: string;
    websitePrivacyHref2Text?: string;
    websitePrivacyHref2URL?: string;
    websitePrivacyHref2AriaLabel?: string;
}

export const useGdprBannerContent = (): GdprBannerProps | null => {
    const [content, getValueByName] = useContent('common', 'gdpr-banner');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as GdprBannerProps;
};
