import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

interface RewardsApiResponse {
    coupons: CouponTile[];
}

interface CouponTile {
    couponId: string;
    offer: string;
    imageURLXLarge: string;
    disclaimer: string;
    commodityDescription: string;
    expiryDate: string;
    subCopy: string;
    displayPriority: string;
}

export class CouponsService {
    private static appConfig = new AppConfigurationService();

    request(brand = 'ford') {
        let brandCode = 'F';

        if (['lincoln', 'Lincoln'].includes(brand)) {
            brandCode = 'L';
        }

        const params = {
            brandCode,
        };
        const rewardsUrl = DSL_BASE_URL + DSL_API_PATH.COUPONS_API_CALL;
        const response = HttpService.get<RewardsApiResponse>(rewardsUrl, true, {
            params,
        });
        return response.then(response => {
            response.data.coupons.sort(function(d1, d2) {
                const priority1 = parseInt(d1.displayPriority);
                const priority2 = parseInt(d2.displayPriority);
                if (priority1 < priority2) {
                    return -1;
                } else if (priority1 == priority2) {
                    return 0;
                } else {
                    return 1;
                }
            });
            return response.data;
        });
    }
}
