import { OSBContentData } from '../../../models/osb-model/osb-content-data';
import { useContent } from '../../use-server-content';
import { Elements } from '../../../services/content-service/content-service.interface';

export const useOSBContentFragment = (
    categoryName: string,
    aemPath: string
): OSBContentData => {
    const [content, getValueByName] = useContent(categoryName, aemPath);
    const osbContentData = content?.elements?.reduce(
        (acc: OSBContentData, element: Elements): OSBContentData => {
            return {
                ...acc,
                [element.name as string]: getValueByName(
                    element.name as string
                ),
            };
        },
        {}
    );
    return osbContentData as OSBContentData;
};
