import React, { useEffect, useState } from 'react';
import serviceRemindersIcon from '../../../../../../assets/service-reminders-icon.svg';
import { VHACard } from '../../../components/vha-card/vha-card';
import { getVin } from '../../../../../utils/getVin-util/getVin-util';
import { getOilLifeDataFromAPI } from '../oillife/oilife';
import { ServiceReminder } from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';
import {
    getServiceRemindersState,
    getServiceRemindersText,
} from './service-reminders-utils';
import { useHistory } from 'react-router-dom';
import { findByAlias } from '../../../../../../routes';

interface Props {
    serviceRemindersContent: ServiceReminder;
    handleVehicleStatus: (alertType: any) => void;
}
export const ServiceReminders = (prop: Props) => {
    const history = useHistory();
    const selectedVin = getVin();
    const [serviceRemindersData, setServiceRemindersData] = useState<any>(null);

    useEffect(() => {
        getOilLifeDataFromAPI(selectedVin)
            .then(prognosticsData => {
                if (prognosticsData?.serviceRemindersData) {
                    setServiceRemindersData(
                        prognosticsData.serviceRemindersData
                    );
                    prop.handleVehicleStatus(
                        getServiceRemindersState(
                            prognosticsData.serviceRemindersData
                        )
                    );
                }
            })
            .catch(error => {
                console.error(error.message);
            });
    }, []);

    const handleServiceReminderCardClick = () => {
        const srdetailsPageUrl = findByAlias('ServiceRemindersDetailsView');
        history.push(srdetailsPageUrl);
    };

    return (
        <>
            {serviceRemindersData && (
                <VHACard
                    icon={serviceRemindersIcon}
                    title={
                        prop.serviceRemindersContent
                            ?.serviceRemindersAccordionLabel
                    }
                    description={getServiceRemindersText(
                        serviceRemindersData,
                        prop.serviceRemindersContent
                    )}
                    cardState={getServiceRemindersState(serviceRemindersData)}
                    handleVHACardClick={handleServiceReminderCardClick}
                />
            )}
        </>
    );
};
