import { useEffect, useState } from 'react';
import ServerSideService from '../../../services/server-side-service/server-side-service';

export const ViewportUtil = () => {
    const [isMobileView, setMobileView] = useState<boolean>(
        ServerSideService.isClientSide() ? window.innerWidth <= 768 : false
    );

    const onResize = () => {
        setMobileView(() => window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return {
        isMobileView,
    };
};
