import { useExperienceContent } from '../../../../hooks/use-server-content';
import { ContainerFragment } from '../../../../models/service-maintenance-models/vehicle-health-alerts';
import { DiagnosticsDetailsPage } from '../../../../models/experiencefragments/diagnostics-details';

export const useDiagnosticsDetailsContent = (
    categoryName: string
): DiagnosticsDetailsPage => {
    const ITEMS = ':items';
    const [containerContent] = useExperienceContent<ContainerFragment>(
        'vehicles',
        categoryName,
        'container'
    );
    return containerContent?.[ITEMS] as DiagnosticsDetailsPage;
};
