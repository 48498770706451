import React, { useEffect, useState } from 'react';
import { VHACard } from '../../../components/vha-card/vha-card';
import powertrainIcon from '../../../../../../assets/powertrain-icon.svg';
import brakesIcon from '../../../../../../assets/brakes-sus-icon.svg';
import fluidsIcon from '../../../../../../assets/fluids-filters-icon.svg';
import restraintsIcon from '../../../../../../assets/restraints-icon.svg';
import {
    getIsBevVehicle,
    getVin,
} from '../../../../../utils/getVin-util/getVin-util';
import {
    DiagnosticAlerts,
    getAllDiagnosticAlertState,
    getDiagnosticAlertsData,
    getDiagnosticAlertsList,
    getDiagnosticCardMessage,
    getDiagnosticCardState,
} from './diagnostic-alert-utils';
import { useHistory } from 'react-router-dom';
import { findByAlias } from '../../../../../../routes';

interface Props {
    aemContent: any;
    handleVehicleStatus: (alertType: any) => void;
}

export const DiagnosticAlertCards = (props: Props) => {
    const history = useHistory();
    const [diagnosticAlertList, setDiagnosticAlertList] = useState<
        DiagnosticAlerts[]
    >([]);
    const [isBev, setIsBev] = useState<boolean | undefined>(false);
    const selectedVin = getVin();

    useEffect(() => {
        const fetchData = async () => {
            return await getDiagnosticAlertsData(selectedVin);
        };

        fetchData()
            .then(apidata => {
                if (apidata) {
                    const diagnosticAlertList: DiagnosticAlerts[] = getDiagnosticAlertsList(
                        apidata
                    );
                    if (diagnosticAlertList && diagnosticAlertList.length > 0) {
                        setDiagnosticAlertList(diagnosticAlertList);
                    }

                    //To get the consolidated state of all 4 cards
                    props.handleVehicleStatus(
                        getAllDiagnosticAlertState(diagnosticAlertList)
                    );
                }
            })
            .catch(error => {
                console.error(error.message);
            });
    }, []);

    useEffect(() => {
        if (selectedVin) {
            const getIsBevValue = async () => {
                const isBev = await getIsBevVehicle(selectedVin);
                setIsBev(isBev);
            };
            getIsBevValue();
        }
    }, [selectedVin]);

    const handleDiagnosticCardClick = (categoryId: string) => {
        const redirectUrl = findByAlias('DiagnosticsDetailsView');
        history.push({
            pathname: redirectUrl[categoryId],
            search: `?categoryId=${categoryId}`,
        });
    };

    return (
        <>
            {diagnosticAlertList && diagnosticAlertList.length > 0 && (
                <>
                    {props.aemContent.powerTrain?.enablePowerTrainAlerts &&
                        !isBev &&
                        isBev !== undefined && (
                            <VHACard
                                icon={powertrainIcon}
                                title={
                                    props.aemContent.powerTrain
                                        ?.powerTrainAccordionLabel
                                }
                                description={getDiagnosticCardMessage(
                                    diagnosticAlertList,
                                    0,
                                    props.aemContent.powerTrain?.powerTrainText,
                                    props.aemContent.powerTrain
                                        ?.noPowerTrainText
                                )}
                                cardState={getDiagnosticCardState(
                                    diagnosticAlertList,
                                    0
                                )}
                                handleVHACardClick={() =>
                                    handleDiagnosticCardClick('0')
                                }
                            />
                        )}
                    {props.aemContent.brakesAndSuspensions
                        ?.enableBrakesAndSuspensionsAlerts && (
                        <VHACard
                            icon={brakesIcon}
                            title={
                                props.aemContent.brakesAndSuspensions
                                    .brakesAndSuspensionsAccordionLabel
                            }
                            description={getDiagnosticCardMessage(
                                diagnosticAlertList,
                                1,
                                props.aemContent.brakesAndSuspensions
                                    ?.brakesAndSuspensionsText,
                                props.aemContent.brakesAndSuspensions
                                    ?.noBrakesAndSuspensionsText
                            )}
                            cardState={getDiagnosticCardState(
                                diagnosticAlertList,
                                1
                            )}
                            handleVHACardClick={() =>
                                handleDiagnosticCardClick('1')
                            }
                        />
                    )}
                    {props.aemContent.fluidsAndFilters
                        ?.enableFluidsAndFiltersAlerts && (
                        <VHACard
                            icon={fluidsIcon}
                            title={
                                props.aemContent.fluidsAndFilters
                                    .fluidsAndFiltersAccordionLabel
                            }
                            description={getDiagnosticCardMessage(
                                diagnosticAlertList,
                                2,
                                props.aemContent.fluidsAndFilters
                                    ?.fluidsAndFiltersText,
                                props.aemContent.fluidsAndFilters
                                    ?.noFluidsAndFiltersText
                            )}
                            cardState={getDiagnosticCardState(
                                diagnosticAlertList,
                                2
                            )}
                            handleVHACardClick={() =>
                                handleDiagnosticCardClick('2')
                            }
                        />
                    )}
                    {props.aemContent.restraintsAndDriverAssistance
                        ?.enableRestraintsAndDriverAssistanceAlerts && (
                        <VHACard
                            icon={restraintsIcon}
                            title={
                                props.aemContent.restraintsAndDriverAssistance
                                    .restraintsAndDriverAssistanceLabel
                            }
                            description={getDiagnosticCardMessage(
                                diagnosticAlertList,
                                3,
                                props.aemContent.restraintsAndDriverAssistance
                                    ?.restraintsAndDriverAssistanceText,
                                props.aemContent.restraintsAndDriverAssistance
                                    ?.noRestraintsAndDriverAssistanceText
                            )}
                            cardState={getDiagnosticCardState(
                                diagnosticAlertList,
                                3
                            )}
                            handleVHACardClick={() =>
                                handleDiagnosticCardClick('3')
                            }
                        />
                    )}
                </>
            )}
        </>
    );
};
