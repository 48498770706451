import {
    RetrieveBookingStep,
    RetrieveBookingStepAction,
} from '../../models/osb-model/osb-retrieve-booking-step';

export const retrieveBookingStepReducer = (
    state: RetrieveBookingStep,
    action: RetrieveBookingStepAction
): RetrieveBookingStep => {
    switch (action.type) {
        case 'SET':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
