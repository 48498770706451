import React from 'react';
import FMActionItem from '../../common/fm-action-item/fm-action-item';
import { AuthenticationState } from '../../../hooks/use-authentication';
import { ActionItemProps } from '../../../models/experiencefragments/action-item';

interface Props {
    authenticationState: AuthenticationState;
    login: any;
}

const FMActionItemLogin = (props: Props & ActionItemProps) => {
    const {
        login,
        content,
        requiredLabel,
        recommendedLabel,
        authenticationState,
    } = props;

    const onClick = () => {
        login();
    };
    const showActionItem =
        authenticationState === AuthenticationState.Unauthenticated;
    return (
        <>
            {showActionItem && (
                <FMActionItem
                    content={content}
                    requiredLabel={requiredLabel}
                    recommendedLabel={recommendedLabel}
                    onClick={onClick}
                />
            )}
        </>
    );
};

export default FMActionItemLogin;
