import {
    DealerStep,
    DealerStepAction,
} from '../../models/osb-model/osb-dealer-step';

export const dealerStepReducer = (
    state: DealerStep,
    action: DealerStepAction
): DealerStep => {
    switch (action.type) {
        case 'SET':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
