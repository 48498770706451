import { useExperienceContent } from '../../../../hooks/use-server-content';
import { TrailerChecklistPage } from '../../../../models/experiencefragments/trailer-checklist';
import { ContainerFragment } from '../../../../models/service-maintenance-models/vehicle-health-alerts';

export const useTrailerChecklistExperienceContent = (): TrailerChecklistPage => {
    const ITEMS = ':items';
    const [containerContent] = useExperienceContent<ContainerFragment>(
        'vehicles',
        'trailer-checklist',
        'container'
    );
    return containerContent?.[ITEMS] as TrailerChecklistPage;
};
