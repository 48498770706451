import React, { useEffect, useState } from 'react';
import recallIcon from '../../../../../../assets/recall-icon.svg';
import { VHACard } from '../../../components/vha-card/vha-card';
import { getVin } from '../../../../../utils/getVin-util/getVin-util';
import {
    getRecallDataFromAPI,
    getRecallStatus,
    getRecallDescription,
} from '../recalls/recall';
export interface Props {
    recallContent: any;
    handleVehicleStatus: (alertType: any) => void;
}
export const RecallsAlert = (props: Props) => {
    const selectedVin = getVin();
    const [recallData, setRecallData] = useState<any>(null);

    useEffect(() => {
        getRecallDataFromAPI(selectedVin).then(recallAPIData => {
            setRecallData(recallAPIData);
            props.handleVehicleStatus(getRecallStatus(recallAPIData));
        });
    }, []);

    return (
        <>
            {recallData && (
                <VHACard
                    icon={recallIcon}
                    title={props?.recallContent?.recallAccordionLabel}
                    description={getRecallDescription(
                        recallData,
                        props?.recallContent
                    )}
                    cardState={getRecallStatus(recallData)}
                    redirectUrl={props?.recallContent?.recallDetailsPath}
                />
            )}
        </>
    );
};
