import { useState } from 'react';
import { SubCategoryViewFragment } from '../../models/experiencefragments/category-view';
import { SubCategoryView } from '../sub-category-view/sub-category-view';
import {
    useSubCategoryViewContent,
    UseSubCategoryViewContentReturn,
} from '../sub-category-view/hooks/use-sub-category-view-content';
import { ExperienceFragmentModel } from '../../services/content-service/content-service.interface';
import { useLocation } from 'react-router';
import useSeoConfig from '../../hooks/use-seo-config';
import xss from 'xss';
import { removeHyphen } from '../category-view/category-view-handler';
import {
    SamReImagineCategoryViewContentReturn,
    SamReImagineCategoryViewFragment,
} from '../../models/experiencefragments/sam-reimagine-category-view';
import { SamReImagineCategoryView } from './sam-reimagine-category-view';
import { ViewportUtil } from '../../components/utils/viewport-util/viewport-util';
import EllipsesIcon from '../../assets/ellipses-icon.png';

interface Props {
    useParams: () => string[];
    useExperienceContent: <T extends ExperienceFragmentModel>(
        category: string,
        name: string,
        componentName: string,
        brandOverride?: string,
        ymmServlet?: boolean
    ) => [T | null, boolean];
    useCategoryViewContent: (
        categoryViewFragment: SamReImagineCategoryViewFragment
    ) => SamReImagineCategoryViewContentReturn | undefined;
    useSubCategoryViewContent: (
        subCategoryViewFragment: SubCategoryViewFragment
    ) => UseSubCategoryViewContentReturn | undefined;
}

export const SamReImagineCategoryHandler = (props: Props) => {
    // Start - SEO Metadata
    let route = xss(useLocation().pathname, {
        whiteList: {}, // empty, means filter out all tags
        stripIgnoreTag: true, // filter out all HTML not in the whitelist
        stripIgnoreTagBody: true, // filter out all HTML body not in the whitelist
    });
    if (route.endsWith('/')) route = route.substring(0, route.length - 1);
    const routeParts = route.split('/');
    useSeoConfig(routeParts[routeParts.length - 1], routeParts.join('/'));
    // End - SEO Metadata

    const path = routeParts.slice(routeParts.indexOf('category') + 1).join('/');
    //const path = routeParts[routeParts.length - 1]
    const cleanPath = path.endsWith('/') ? path.slice(0, -1) : path;
    const categoryId = cleanPath.split('/').pop();
    const [categoryViewFragment] = props.useExperienceContent<
        SamReImagineCategoryViewFragment | SubCategoryViewFragment
    >(`category/${cleanPath}`, `${categoryId}`, '', undefined, false);
    const categoryName = cleanPath.split('/');
    const linkSeparator = '/';

    const { isMobileView } = ViewportUtil();
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

    const getBreadCrumbItem = (index: number, categoryName?: string[]) => {
        const breadCrumbItems = categoryViewFragment?.breadcrumbs?.breadcrumbText?.split(
            '|'
        );
        if (breadCrumbItems && breadCrumbItems?.length >= 1) {
            if (categoryName && categoryName.length === breadCrumbItems.length)
                return breadCrumbItems?.at(index)?.trim();
        }
        return undefined;
    };

    const handleClick = (index: number) => {
        window.location.href = `category/${
            cleanPath.split(categoryName[index + 1])[0]
        }`;
    };

    const handleShowMoreInfo = () => {
        setShowMoreInfo(true);
    };

    const CategoryBreadcrumbs = () => {
        return (
            <>
                {categoryName.map((item, index) => {
                    if (index < categoryName.length - 1) {
                        return (
                            <>
                                <span
                                    onClick={() => handleClick(index)}
                                    className="breadcrumb-link-ellipses-expanded"
                                    aria-label={
                                        getBreadCrumbItem(
                                            index,
                                            categoryName
                                        ) || removeHyphen(categoryName[index])
                                    }
                                >
                                    {getBreadCrumbItem(index, categoryName) ||
                                        removeHyphen(categoryName[index])}
                                </span>
                                <span className="back-slash">{` ${linkSeparator} `}</span>
                            </>
                        );
                    }
                })}
            </>
        );
    };

    const getMobileDevice = () => {
        return (
            <>
                {!showMoreInfo ? (
                    <>
                        <span
                            onClick={handleShowMoreInfo}
                            className="ellipses-icon"
                        >
                            <img src={EllipsesIcon} />
                        </span>
                        <span className="back-slash">{'/'}</span>
                    </>
                ) : (
                    CategoryBreadcrumbs()
                )}
            </>
        );
    };

    return (
        <>
            {categoryId &&
                categoryViewFragment &&
                categoryViewFragment[':type'] && (
                    <>
                        {categoryViewFragment[':type'].endsWith(
                            'v1/collision-page-structure'
                        ) && (
                            <>
                                <SamReImagineCategoryView
                                    path={cleanPath}
                                    categoryId={
                                        getBreadCrumbItem(-1, categoryName) ||
                                        removeHyphen(categoryId)
                                    }
                                    categoryViewFragment={categoryViewFragment}
                                    useCategoryViewContent={
                                        props.useCategoryViewContent
                                    }
                                    breadcrumbs={
                                        !isMobileView
                                            ? CategoryBreadcrumbs
                                            : getMobileDevice
                                    }
                                    pageName={categoryId}
                                    isShowMoreInfo={showMoreInfo}
                                />
                            </>
                        )}
                        {categoryViewFragment[':type'].endsWith(
                            'v1/sub-category-page-structure'
                        ) && (
                            <>
                                <SubCategoryView
                                    path={cleanPath}
                                    subCategoryId={
                                        getBreadCrumbItem(-1, categoryName) ||
                                        removeHyphen(categoryId)
                                    }
                                    subCategoryViewFragment={
                                        categoryViewFragment as SubCategoryViewFragment
                                    }
                                    useSubCategoryViewContent={
                                        useSubCategoryViewContent
                                    }
                                    breadcrumbs={CategoryBreadcrumbs}
                                />
                            </>
                        )}
                    </>
                )}
        </>
    );
};

export default SamReImagineCategoryHandler;
