import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';
import { AxiosResponse } from 'axios';

export interface VhrReportData {
    coupons: Coupon[];
    dealerInspection: DealerInspection;
    oilStatus: string;
    oilPercent: string;
    prognosticsOilCapable: string;
    oilLife: OilLife;
    collectionDate: string;
    scheduledMaintenance: ScheduledMaintenance;
    vehicleSystems: VehicleSystems[];
}

interface Coupon {
    code: string;
    dealerName: string;
    disclaimer: string;
    imageURL: string;
    offer: string;
    paCode: string;
    expirationDate: ExpirationDate;
}

interface ExpirationDate {
    year: number;
    dayOfMonth: number;
    monthOfYear: number;
    hourOfMonth: number;
    hourOfDay: number;
    secondOfMinute: number;
    weekOfWeekyear: number;
    millisOfSecond: number;
    millis: number;
    secondOfDay: number;
    millisOfDay: number;
    minuteOfDay: number;
    weekyear: number;
    yearOfCentury: number;
    yearOfEra: number;
    centuryOfEra: number;
    era: number;
    dayOfWeek: number;
    dayOfYear: number;
    beforeNow: boolean;
    afterNow: boolean;
    equalNow: boolean;
    zone: Zone;
}

interface Zone {
    fixed: boolean;
    id: string;
}

interface DealerInspection {
    paDealerCode: string;
    repairDate: string;
    repairOrderId: string;
    results: string;
}

interface OilLife {
    iolm: string;
    status: string;
    urgency: string;
    estimatedDate: EstimatedDate;
    estimatedDistance: EstimatedDistance;
    expandableSection: ExpandableSection;
}

interface EstimatedDate {
    month: string;
    date: string;
    year: string;
}

interface EstimatedDistance {
    miles: string;
    kms: string;
}

interface ExpandableSection {
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
}

interface ScheduledMaintenance {
    maintenanceMileageRecommendation: string;
    maintenanceOperations: any;
    nextMaintenanceMileage: number;
    vehicleMileage: number;
}

interface VehicleSystems {
    description: string;
    id: string;
    status: string;
    symbolId: string;
    warnings: Warnings[];
}

interface Warnings {
    customerAction: string;
    description: string;
    name: string;
    severity: number;
    symptom: string;
}
export default class VhrReportService {
    request(vin: string): Promise<VhrReportData | null> {
        const vhrReportUrl = DSL_BASE_URL + DSL_API_PATH.VHR_REPORT;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin: vin,
        };
        const response = HttpService.get<VhrReportData>(vhrReportUrl, true, {
            headers,
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<VhrReportData>
    ): VhrReportData | null {
        if (response.data) {
            return response.data;
        }
        return null;
    }
}
