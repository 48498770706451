import React from 'react';
import { Modal } from '../../../common/modal/modal';
import vinNumberWindowVehicle from '../../../../assets/owners-osb/icons-vin-number-window-vehicle.svg';
import vinNumberDocumentation from '../../../../assets/owners-osb/icons-vin-number-documentation.svg';
import vinNumberWindowVehicleFront from '../../../../assets/owners-osb/icons-vin-number-window-vehicle-front.svg';
import { OSB_AEM_PROPERTY_KEYS } from '../../owners-osb/osb-constant';
import regPlateNumber from '../../../../assets/owners-osb/icons-reg-plate-number.svg';
import parse from 'html-react-parser';
import './find-my-vin-popup.scss';
import { getObjectFromAEMJson } from '../../owners-osb/osb-utils';

interface Props {
    onModalClose?: () => void;
    showFindMyVinPopup?: boolean;
    aemContent?: any;
}
const FindMyVinPopup = (props: Props) => {
    return (
        <div>
            <Modal
                name="vin-reg-modal-popup"
                role="dialog"
                aria-label="Vin and Reg information"
                onClose={props.onModalClose}
                isVisible={props.showFindMyVinPopup}
            >
                <div className="vin-content">
                    <h3 className="vin-info-title">
                        {getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.MODAL_FIND_YOUR_VIN_TITLE,
                            props.aemContent
                        )}
                    </h3>

                    <div className="vin-info-body-content">
                        <div>
                            {getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.MODAL_FIND_YOUR_VIN_SUBTITLE,
                                props.aemContent
                            )}
                        </div>
                        <div>
                            <ul className="vin-info-list">
                                <li>
                                    <img
                                        className=""
                                        src={vinNumberWindowVehicle}
                                    />
                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_DRIVER_SIDE_DOORFRAME,
                                            props.aemContent
                                        )
                                    )}
                                </li>
                                <li>
                                    <img
                                        className=""
                                        src={vinNumberDocumentation}
                                    />
                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_YOUR_VEHICLE_DOCUMENTATION_DESCRIPTION,
                                            props.aemContent
                                        )
                                    )}
                                </li>
                            </ul>
                            <ul className="vin-info-list">
                                <li>
                                    <img
                                        className=""
                                        src={vinNumberWindowVehicleFront}
                                    />
                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_VISIBLE_THROUGH_WINDSHIELD_DESCRIPTION,
                                            props.aemContent
                                        )
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.MODAL_REGISTRATION_NUMBER_DESCRIPTION,
                    props.aemContent
                ) !== '' && (
                    <>
                        <p className="modal-line" />
                        <div className="reg-container">
                            <h3 className="vin-info-title">
                                {getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.MODAL_FIND_YOUR_REGISTRATION_NUMBER_LABEL,
                                    props.aemContent
                                )}
                            </h3>

                            <div className="reg-content">
                                <img className="" src={regPlateNumber} />
                                {parse(
                                    getObjectFromAEMJson(
                                        OSB_AEM_PROPERTY_KEYS.MODAL_REGISTRATION_NUMBER_DESCRIPTION,
                                        props.aemContent
                                    )
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default FindMyVinPopup;
