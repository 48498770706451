import React, { useState } from 'react';
import './retrieve-booking-summary-view.scss';
import { LightRetrieveBookingSummary } from '../../../components/sections/owners-osb/retrieve-booking-summary/light-journey/light-retrieve-booking-summary';
import { OsbAlertMessagePopup } from '../../../components/sections/owners-osb/common/osb-alert-message-popup/osb-alert-message-popup';

export function RetrieveBookingSummaryView() {
    const [voucherAlertMessage, setVoucherAlertMessage] = useState<string>('');
    const [voucherAlertSuccess, setVoucherAlertSuccess] = useState<boolean>(
        false
    );

    // added this method to handle OsbAlertMessage from light vehicle section to OsbAlertMessage component
    const osbAlertMessagePopup = (message: string, success: boolean) => {
        setVoucherAlertMessage(message);
        setVoucherAlertSuccess(success);
    };

    const closeOsbAlertPopup = () => {
        setVoucherAlertMessage('');
    };
    return (
        <>
            {voucherAlertMessage && (
                <OsbAlertMessagePopup
                    alertMessage={voucherAlertMessage}
                    alertSuccess={voucherAlertSuccess}
                    closeOsbAlertPopup={closeOsbAlertPopup}
                />
            )}
            <div className="retrieve-booking-summary">
                <LightRetrieveBookingSummary
                    osbAlertMessagePopup={osbAlertMessagePopup}
                />
            </div>
        </>
    );
}
