import React, { useEffect } from 'react';
import './dealer-tile.scss';
import { PrimaryButton } from '../../../common';
import { Accordion } from '@own/fds-react';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import { FormatAddress } from '../../owners-osb/common/osb-common-components/osb-common-components';
import { getObjectFromAEMJson } from '../../owners-osb/osb-utils';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../price-calculator-constant';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { OSB_SERVICE_PRICE_TYPE } from '../../owners-osb/osb-constant';
import { useVehicleStep } from '../../../../hooks/owners-osb';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import { useDealerService } from '../../../../hooks/owners-osb/use-dealer-service';
import { ServiceInfo } from '../../../../models/osb-model/osb-dealerservice-info';
import { InfoIconToolTip } from '../../../common/info-icon-tooltip/info-icon-tooltip';

interface Props {
    index: number;
    label: string;
    dealer: DealerProfileInfo;
    isPreferredDealer: boolean;
    priceCalculatorContent: ContentElements[];
    priceCalculatorResultContent: ContentElements[];
    serviceApiError?: (error: string) => void;
    goToServicesStep: (dealer: DealerProfileInfo) => void;
}

const DealerTile = (props: Props) => {
    const { osbVehicleStep } = useVehicleStep();
    const {
        httpState,
        mainServices,
        deliveryServices,
        loadDealerService,
    } = useDealerService();
    function getDistanceValueAndMilesLabel() {
        const resultConstants = {
            distanceUnit:
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_UNIT,
                    props.priceCalculatorResultContent
                )?.toLowerCase() ?? '',
            milesLabel:
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_FROM_YOU,
                    props.priceCalculatorResultContent
                ) ?? '',
        };

        const distanceValue =
            resultConstants.distanceUnit === 'miles'
                ? OsbUtilService.getDistanceInMiles(
                      Number(props.dealer?.distance)
                  )
                : OsbUtilService.getDistanceInKm(
                      Number(props.dealer?.distance)
                  );

        return `${distanceValue} ${resultConstants.milesLabel}` ?? '';
    }
    const getFormattedPrice = (price: string) => {
        const {
            PRICE_POR_LABEL,
            PRICE_FREE_LABEL,
            PRICE_PRECISION,
            PRICE_SEPARATOR,
            PRICE_PREFIX,
            PRICE_POSTFIX,
        } = PRICE_CALCULATOR_RESULT_CONSTANTS;
        const { priceCalculatorResultContent } = props;
        const numPrice = Number(price);

        if (
            numPrice === OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
            numPrice === OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
        ) {
            return getObjectFromAEMJson(
                PRICE_POR_LABEL,
                priceCalculatorResultContent
            );
        }

        if (numPrice === OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE) {
            return getObjectFromAEMJson(
                PRICE_FREE_LABEL,
                priceCalculatorResultContent
            );
        }

        const precision = getObjectFromAEMJson(
            PRICE_PRECISION,
            priceCalculatorResultContent
        );
        const separator = getObjectFromAEMJson(
            PRICE_SEPARATOR,
            priceCalculatorResultContent
        );
        const priceWithPrecision = OsbUtilService.addPrecision(
            price,
            precision
        );
        const priceWithSeparators = OsbUtilService.addSeparators(
            priceWithPrecision,
            separator
        );

        return `${getObjectFromAEMJson(
            PRICE_PREFIX,
            priceCalculatorResultContent
        )}${OsbUtilService.addDecimalSeparator(
            priceWithSeparators,
            separator
        )}${getObjectFromAEMJson(PRICE_POSTFIX, priceCalculatorResultContent)}`;
    };

    const renderDeliveryServices = (deliveryServices: ServiceInfo[]) => {
        return deliveryServices.map(service => {
            return (
                <div className="dealer-tile-service" key={service.serviceId}>
                    <div className="dealer-tile-service-info">
                        <span className="dealer-tile-service-name">
                            {service.name}
                        </span>
                        {service.description && (
                            <span id={`${service.serviceId}-tooltip`}>
                                <InfoIconToolTip
                                    osbServiceTooltip={true}
                                    tooltipInfoIconClass={'dark'}
                                    tooltipContent={service.description}
                                />
                            </span>
                        )}
                    </div>
                    <span className="dealer-tile-service-price">
                        {getFormattedPrice(
                            service.priceAfterDiscount.toString()
                        )}
                    </span>
                </div>
            );
        });
    };

    useEffect(() => {
        const { dealer, priceCalculatorResultContent } = props;
        const { vehicleDetails } = osbVehicleStep;
        if (dealer && vehicleDetails.vin && vehicleDetails.mileageInKm) {
            loadDealerService(dealer, priceCalculatorResultContent);
        }
    }, [props.dealer, osbVehicleStep.vehicleDetails]);

    return (
        <React.Fragment>
            {props.isPreferredDealer && (
                <div className="dealer-tile-label">{props.label}</div>
            )}
            <div className="dealer-tile-details">
                <div className="dealer-tile-name">
                    {props.index + 1}. {props.dealer.dealerName}
                </div>
                <div className="dealer-tile-address-container">
                    <div className="address-container">
                        <div className="dealer-tile-address-text">
                            <FormatAddress
                                addressData={props.dealer}
                                addressOrder={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_RESULT_CONSTANTS.DEALER_ADDRESS_ORDER,
                                    props.priceCalculatorContent
                                )}
                                isDisplaySingleRow={true}
                            />
                        </div>
                        {props.dealer.distance > 0 && (
                            <div className="dealer-tile-distance">
                                <span>
                                    {getObjectFromAEMJson(
                                        PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_LABEL,
                                        props.priceCalculatorContent
                                    )}
                                    :
                                </span>{' '}
                                {getDistanceValueAndMilesLabel()}
                            </div>
                        )}
                    </div>
                    <div className="dealer-tile-button-container">
                        <PrimaryButton
                            role="link"
                            color={'dark'}
                            fill={'fill'}
                            chevron={false}
                            aria-label="select-dealer-cta"
                            aria-labelledby="select dealer cta"
                            dataTestId={`SelectDealer-${props.dealer.dealerCode}`}
                            onClick={() => props.goToServicesStep(props.dealer)}
                        >
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.BOOK_NOW_BUTTON,
                                props.priceCalculatorResultContent
                            )}
                        </PrimaryButton>
                    </div>
                </div>

                {httpState.isLoading ? (
                    <div className="dealer-tile-activity-indicator">
                        <ActivityIndicator />
                    </div>
                ) : (
                    <>
                        {mainServices.map(service => (
                            <div
                                key={service.serviceId}
                                className="dealer-tile-services"
                            >
                                <div
                                    className="dealer-tile-service"
                                    key={service.serviceId}
                                >
                                    <div className="dealer-tile-service-info">
                                        {service.name}
                                        {service.description && (
                                            <span
                                                id={
                                                    service.serviceId +
                                                    '-tooltip'
                                                }
                                            >
                                                <InfoIconToolTip
                                                    osbServiceTooltip={true}
                                                    tooltipInfoIconClass={
                                                        'dark'
                                                    }
                                                    tooltipContent={
                                                        service.description
                                                    }
                                                />
                                            </span>
                                        )}
                                    </div>
                                    <div className="dealer-tile-service-price">
                                        {getFormattedPrice(
                                            service.priceAfterDiscount.toString()
                                        )}
                                    </div>
                                </div>
                                <hr className="service-separator" />
                            </div>
                        ))}

                        <div className="dealer-tile-convenience-option">
                            <Accordion
                                panels={[
                                    {
                                        body: renderDeliveryServices(
                                            deliveryServices
                                        ),
                                        header: getObjectFromAEMJson(
                                            PRICE_CALCULATOR_RESULT_CONSTANTS.CONVENIENT_OPTION_LABEL,
                                            props.priceCalculatorContent
                                        ),
                                        index: 0,
                                    },
                                ]}
                                theme="dark-on-light"
                            />
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );
};

export default DealerTile;
