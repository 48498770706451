import { HttpsProxyAgent } from 'https-proxy-agent';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import axios from 'axios';
import { RedeemFormData } from '../../components/sections/redeem-rebates/redeem-rebates-steps/redeem-rebates-steps';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
const httpsAgent = new HttpsProxyAgent(
    process.env.http_proxy || 'http://internet.ford.com:83'
);

export interface RebatesSubmitResponse {
    offerRequests: OfferRequest[];
    selectedRebate: string;
}
export interface OfferRequest {
    rebateOfferID: number;
    rebateName: string;
    rebateTrackingNumber: string;
    rebateStatusCode: number;
    rebateStatus: string;
}

const RebateConfirmationService = async (redeemFormData: RedeemFormData) => {
    const { brand } = new AppConfigurationService();
    const selectedDate: any = redeemFormData.serviceDate;
    const getServiceDate = (separator = '-') => {
        const newDate = new Date(selectedDate);
        const date = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear();

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date < 10 ? `0${date}` : `${date}`}`;
    };
    let rebateObj: any[] = [];
    const rebateObjData: any[] = [];
    const url = DSL_BASE_URL + DSL_API_PATH.REBATE_OFFERS;

    redeemFormData.rebateData?.forEach(grp => {
        if (grp.value != '') {
            if (grp.isPointsClicked) {
                rebateObjData.push(`{
                                "rebateCreditCardNumber": "",
                                "rebateOfferID": ${grp.rebateOfferID},
                                "rebateOARNumber": "",
                                "rebateRewardsPointsAccountNumber": "${redeemFormData.finalizeDetails?.memberId}"
                              }`);
            } else {
                rebateObjData.push(`{
                            "rebateCreditCardNumber": "",
                            "rebateOfferID": ${grp.rebateOfferID},
                            "rebateOARNumber": "",
                            "rebateRewardsPointsAccountNumber": ""
                          }`);
            }
        }
        rebateObj = rebateObjData;
    });
    const bodyRequest = `{
                "offerRequest": {
                  "address1": "${
                      redeemFormData.finalizeDetails?.streetAddress
                  }",
                  "address2": "",
                  "city": "${redeemFormData.finalizeDetails?.city}",
                  "dealerCode": "${
                      redeemFormData.selectedDealerDetails?.dealerCode
                  }",
                  "emailAddress": "${redeemFormData.finalizeDetails?.email}",
                  "firstName": "${redeemFormData.finalizeDetails?.firstName}",
                  "lastName": "${redeemFormData.finalizeDetails?.lastName}",
                  "marketingOptin": "${
                      redeemFormData.finalizeDetails?.isEmailCommunications
                          ? 'Y'
                          : 'N'
                  }",
                  "rebateRequest": {
                    "rebateRequest": 
                        [${rebateObj}]
                  },
                  "serviceDate": "${getServiceDate()}",
                  "state": "${redeemFormData.finalizeDetails?.stateDropdown}",
                  "vin": "${redeemFormData.vin}",
                  "website": "${brand === 'ford' ? 'O' : 'L'}",
                  "zip": "${redeemFormData.finalizeDetails?.zipCode}"
                },
                "rebates": [${rebateObj}]
              }`;
    const apiResponse = axios
        .post<RebatesSubmitResponse>(url, bodyRequest, {
            headers: {
                'Content-type': 'application/json',
            },
            httpsAgent,
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
    return apiResponse;
};

export { RebateConfirmationService };
