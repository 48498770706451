import React from 'react';
import './info-box.scss';

interface InfoBoxProps {
    title: string;
    infoText?: string;
    iconUrl: string;
    backgroundColor: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({
    title,
    infoText,
    iconUrl,
    backgroundColor,
}) => {
    return (
        <div
            className="osb-v3-info-box"
            style={{ background: backgroundColor }}
        >
            <div className="osb-v3-info-box__icon">
                <img src={iconUrl} alt="Notification Icon" />
            </div>
            <div>
                <div className="osb-v3-info-box__title">{title}</div>
                {infoText && (
                    <p className="osb-v3-info-box__text">{infoText}</p>
                )}
            </div>
        </div>
    );
};

export default InfoBox;
