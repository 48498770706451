import React, { useEffect, ReactNode, useRef, useState } from 'react';
import './tirefinder-banner.scss';

export interface BannerBottom {
    component: ReactNode;
    marginTop: number | string;
}

interface Props {
    topComponent?: ReactNode;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
}

export const TireFinderBanner = (props: Props) => {
    const TABLET_BREAKPOINT = 768;
    const DEFAULT_HEIGHT_VALUE = 0;
    const topDivRef = useRef<HTMLDivElement>(null);
    const bottomDivRef = useRef<HTMLDivElement>(null);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [topDivHeight, setTopDivHeight] = useState(DEFAULT_HEIGHT_VALUE);
    const [bottomDivHeight, setBottomDivHeight] = useState(
        DEFAULT_HEIGHT_VALUE
    );

    useEffect(() => {
        let movementTimer: NodeJS.Timeout | null = null;

        const configureLayout = () => {
            const bottomDivHeightValue =
                bottomDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setBottomDivHeight(bottomDivHeightValue / 2);

            const topDivHeightValue =
                topDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setTopDivHeight(topDivHeightValue);

            const isMobile = window.innerWidth <= TABLET_BREAKPOINT;
            if (isMobile) setBackgroundImage(props.backgroundImageMobile);
            else setBackgroundImage(props.backgroundImageDesktop);
        };

        configureLayout();

        window.addEventListener('resize', () => {
            if (movementTimer) clearTimeout(movementTimer);
            movementTimer = setTimeout(configureLayout, 10);
        });

        return () => {
            window.removeEventListener('resize', () => {
                if (movementTimer) clearTimeout(movementTimer);
            });
        };
    }, [props.backgroundImageMobile, props.backgroundImageDesktop]);

    return (
        <div className="tire-finder-banner">
            {props.topComponent && (
                <div
                    className="tire-finder-banner-top"
                    style={{
                        minHeight: `${topDivHeight + bottomDivHeight}px`,
                        backgroundImage: `url('${backgroundImage}')`,
                    }}
                >
                    <div className="tire-finder-banner-cta" ref={topDivRef}>
                        {props.topComponent}
                    </div>
                </div>
            )}
        </div>
    );
};
