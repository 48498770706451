import { useContent } from '../../../../hooks/use-server-content';

export interface MaintenanceScheduleContentProps {
    maintenanceSchedulePageHeadline: string;
    maintenanceScheduleDescription: string;
    enterYourVehicleDetailFormHeadline: string;
    vinOrRegInputFieldLabel: string;
    mileageInputFieldLabel: string;
    enterYourVehicleDetailsCTALabel: string;
    orSeparatorText: string;
    maintenanceScheduleNotFoundErrorMessage: string;
    maintenanceSchedulePath: string;
    enterYourVehicleDetailsTooltipHeadline: string;
    enterYourVehicleDetailsTooltipContent: string;
    enterYourVehicleDetailsTooltipAriaLabel: string;
    chooseYourVehicleFormHeadline: string;
    yourVehicleFieldLabel: string;
    chooseYourVehicleCta: string;
}
export const useMaintenanceScheduleLandingContent = (): MaintenanceScheduleContentProps => {
    const [content, getValueByName] = useContent(
        'common',
        'maintenance-schedule-landing'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as MaintenanceScheduleContentProps;
};
