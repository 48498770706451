import { useEffect, useState } from 'react';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import ServiceHandler from '../../../../services/service-handler';
import { LoyaltyPointsServiceResponse } from '../../../../services/loyalty-points-service/loyalty-points-service.interface';

export const useLoyaltyPointsDataForTile = (): LoyaltyPointsServiceResponse | null => {
    const [
        loyaltyPointsDataForTile,
        setLoyaltyPointsDataForTile,
    ] = useState<LoyaltyPointsServiceResponse | null>(null);
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        new AuthenticationService()
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (!loading) {
                    setLoading(true);
                    if (isAuthenticated) {
                        ServiceHandler.LoyaltyPointsService.request().then(
                            points => {
                                setLoyaltyPointsDataForTile(points);
                            }
                        );
                    } else {
                        setLoyaltyPointsDataForTile(null);
                    }
                    setLoading(false);
                }
            });
    }, []);

    return loyaltyPointsDataForTile;
};
