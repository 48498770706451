import { OSBAPIResponse } from '../service-orchestration/service-adapter';
import {
    ErrorResponse,
    transformErrorResponse,
} from '../../../components/sections/owners-osb/osb-utils';
import { DSLAPIService } from '../service-orchestration/service-dsl';
import { DSL_API_PATH } from '../../../constants';
import { transformRetrieveBookingResponse } from '../../../components/sections/owners-osb/osb-service-adapters/retrieve-booking-adapter';
import { transformCancelBookingResponse } from '../../../components/sections/owners-osb/osb-service-adapters/cancel-booking-adapter';
import { Bookings } from '../../../models/osb-model/osb-retrieve-booking-step';
import {
    RetrieveBookingResponse,
    CancelBookingRequest,
    CancelBookingResponse,
    CancelBookingOSBResponse,
    BookingAuthorization,
} from '../../../models/osb-model/osb-retrieve-booking';

export class OSBRetrieveBookingClient {
    public retrieveServiceBooking(
        retrieveBookingRequest: BookingAuthorization
    ) {
        return new Promise<OSBAPIResponse<Bookings>>((resolve, reject) => {
            const transformedResponse: OSBAPIResponse<Bookings> = {
                value: {} as Bookings,
                responseError: {} as ErrorResponse,
            };
            new DSLAPIService()
                .postOSBAPI<BookingAuthorization, RetrieveBookingResponse>(
                    retrieveBookingRequest,
                    DSL_API_PATH.DSL_OSB_RETRIEVE_BOOKINGV5
                )
                .then(response => {
                    try {
                        transformedResponse.value = transformRetrieveBookingResponse(
                            response
                        );
                        resolve(transformedResponse);
                    } catch (err) {
                        transformedResponse.responseError.errorMessage =
                            'RETRIEVE_BOOKING_CLIENT_TRANSFORMATION_ERROR: ' +
                            (err as Error)?.message;
                        reject(transformedResponse.responseError);
                    }
                })
                .catch(_error => {
                    transformedResponse.responseError = transformErrorResponse(
                        _error
                    );
                    reject(transformedResponse.responseError);
                    console.error(
                        'Rejected the promise - ',
                        transformedResponse.responseError
                    );
                });
        });
    }

    //cancel Booking
    public cancelServiceBooking(
        cancelBookingRequest: CancelBookingRequest,
        dealerId: string,
        bookingId: string
    ) {
        return new Promise<OSBAPIResponse<CancelBookingOSBResponse>>(
            (resolve, reject) => {
                const transformedResponse: OSBAPIResponse<CancelBookingOSBResponse> = {
                    value: {} as CancelBookingOSBResponse,
                    responseError: {} as ErrorResponse,
                };
                new DSLAPIService()
                    .deleteServiceAPI<
                        CancelBookingRequest,
                        CancelBookingResponse
                    >(
                        cancelBookingRequest,
                        dealerId,
                        bookingId,
                        DSL_API_PATH.DSL_OSB_CANCEL_BOOKING
                    )
                    .then(response => {
                        try {
                            transformedResponse.value = transformCancelBookingResponse(
                                response
                            );
                            resolve(transformedResponse);
                        } catch (err) {
                            transformedResponse.responseError.errorMessage =
                                'CANCEL_BOOKING_CLIENT_TRANSFORMATION_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    })
                    .catch(_error => {
                        transformedResponse.responseError = transformErrorResponse(
                            _error
                        );
                        reject(transformedResponse.responseError);
                        console.error(
                            'Rejected the promise - ',
                            transformedResponse.responseError
                        );
                    });
            }
        );
    }
}
