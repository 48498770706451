import { AppEnvironmentConfiguration } from '../app-configuration';

export const appConfigurationMothersite: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'Mothersite',
            countryCode: 'mothersite',
            threeLetterCountryCode: 'gbr',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Czech Republic',
            countryCode: 'cz',
            threeLetterCountryCode: 'cze',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Czech',
                    langScript: 'LATN',
                    languageRegionCode: 'cs-cz',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
    ],
};
