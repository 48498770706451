export const PRICE_CALCULATOR_CONSTANTS = {
    PC_Top_Banner_Text: 'pcTopBannerText',
    PC_Bottom_Banner_Text: 'pcBottomBannerText',
    Book_A_Service_CTA: 'bookAServiceCTA',
    Find_A_Dealer_CTA: 'findADealerCTA',
    Vin_Reg_Validation_Message: 'vinRegValidationMessage',
    Invalid_Vin_Error_Message: 'invalidVinErrorMessage',
    Invalid_Reg_Error_Message: 'invalidRegErrorMessage',
    Mileage_Validation_Message: 'mileageValidationMessage',
    PC_Review_Header: 'pcReviewHeader',
    Vin_RegNo_Label: 'vinRegNoLabel',
    Vin_RegNo_PlaceHolder: 'vinRegNoPlaceHolder',
    Where_To_Find_My_Vin: 'whereToFindMyVin',
    Mileage_Label: 'mileageLabel',
    Mileage_PlaceHolder: 'mileagePlaceHolder',
    My_Location_Label: 'myLocationLabel',
    My_Location_PlaceHolder: 'myLocationPlaceHolder',
    Use_My_Location_Label: 'useMyLocationLabel',
    Get_A_Quote_Btn_Label: 'getAQuoteBtnLabel',
    Location_Position_Unavailable_Label: 'locationPositionUnavailableLabel',
    Location_Permission_Denied_Label: 'locationPermissionDeniedLabel',
    Location_Timeout_Label: 'locationTimeoutLabel',
    Location_Unknown_Label: 'locationUnknownLabel',
    Vin_RegNo_Pattern: 'vinRegNoPattern',
    Osb_Home_Redirect_Url: 'osbHomeRedirectUrl',
    Location_Validation_Message: 'locationValidationMessage',
    PC_SUBHEADER: 'pcSubHeader',
    Please_Select_PlaceHolder: 'pleaseSelectPlaceHolder',
    Enter_NewVin_Label: 'enterNewVinLabel',
    Or_Choose_Your_VehicleLink: 'orChooseYourVehicleLink',
    Choose_Your_Vehicle_Label: 'chooseYourVehicleLabel',
    Geo_Country_Code: 'geoCountryCode',
    Search_Hint_Label: 'searchHintLabel',
    Autocomplete_Search_String_Length: 'autocompleteSearchStringLength',
    OSB_THREE_LETTER_COUNTRY_CODE: 'osbThreeLetterCountryCode',
    FIND_A_DEALER_LINK: 'findADealerLink',
    LOCATION_FIELD_DISABLED_MESSAGE: 'locationFieldDisabledMessage',
    FIND_DEALERS_BY_LOCATION_BUTTON_LABEL: 'findDealersByLocationButtonLabel',
};
export const PRICE_CALCULATOR_RESULT_CONSTANTS = {
    RECOMMENDEC_LABEL: 'recommended_Vehicle_Label',
    FILTER_LABEL: 'filterLabel',
    FILTER_HEADING: 'filterHeading',
    FILTER_RESET: 'filterReset',
    FILTER_AVAILABLE_SERVICES_HEADING: 'availableServiceAccordion',
    FILTER_DELIVERY_SERVICES_HEADING: 'deliveryServicesAcc',
    FILTER_REGULAR_SERVICES_HEADING: 'regularServicesAcc',
    FILTER_APPLY_BUTTON: 'applyButton',
    FILTER_ERROR_MSG: 'filterError',
    COMMON_SERVICE_API_ERROR: 'serviceError',
    DEFAULT_DEALER_COUNT: 'defaultDealerCount',
    COUNT_DEALER_OF: 'dealerCountOfLabel',
    MAX_DEALER_COUNT: 'maxDealerLoadCount',
    NUMBER_OF_CHAR_STRIP_FROM_DEALERID: 'numberOfLeadingCharToStrip',
    VIEW_MORE: 'loadMoreLabel',
    POLYGON: 'polygon',
    DISTANCE_UNIT: 'unitLabel',
    DISTANCE_FROM_YOU: 'distanceFromYou',
    DEALER_ADDRESS_ORDER: 'dealerAddressOrder',
    SEE_QUOTE_BUTTON: 'seeQuote',
    BOOK_NOW_BUTTON: 'bookNowLabel',
    OSB_HOME_PAGE_STEP: 'osbHomePageStep',
    PRICE_PRECISION: 'precision',
    PRICE_SEPARATOR: 'separators',
    PRICE_PREFIX: 'pricePrefix',
    PRICE_POSTFIX: 'pricePostFix',
    PRICE_POR_LABEL: 'porLabel',
    PRICE_FREE_LABEL: 'freeLabel',
    GET_SERVICES_MAX_RETRY_LABEL: 'getECATServicesMaxRetriesLabel',
    GET_SERVICES_RETRY_DELAY_IN_SECONDS_LABEL:
        'getECATServicesRetryDelayInSecondsLabel',
    SEARCH_DISTANCE: 'searchDistance',
    LOADER_MSG: 'loaderMsg',
    SERVICES_MAX_RETRY_COUNT: 1,
    SCHEDULED_SERVICE: '55555',
    ENABLE_NEW_LANDING_PAGE_DESIGN: 'enableNewLandingPage',
    DISTANCE_LABEL: 'distanceTitle',
    PREFERRED_DEALER_LABEL: 'preferredDealerCaption',
    MAP_VIEW_LABEL: 'mapViewLabel',
    LIST_VIEW_LABEL: 'listViewLabel',
    CONVENIENT_OPTION_LABEL: 'convenientOptionsLabel',
};

export const PRICE_CALCULATOR_ANALYTICS = {
    EVENT_PRICE_CALCULATOR_DEALER_SEARCH_START:
        'price-calculator-dealer-search-start',
    EVENT_PRICE_CALCULATOR_DEALER_SEARCH_RESULT:
        'price-calculator-dealer-search-result',
    TARGET_TRIGGER_VIEW: 'target-trigger-view',
    EVENT_PRICE_CALCULATOR_GET_QUOTE: 'price-calculator-get-quote',
    EVENT_PRICE_CALCULATOR_LOCATION_SIGNIN: 'price-calculator-location-signin',
    EVENT_PRICE_CALCULATOR_USER_INPUT: 'price-calculator-user-input',
    VIN_LABEL: 'vin',
    MILEAGE_LABEL: 'mileage',
    LOCATION_LABEL: 'location',
    USE_MY_LOCATION_LABEL: 'use my location',
    FIND_MY_VIN: 'find my vin',
    LOGIN_CTA_LABEL: 'logincta',
    EVENT_PRICE_CALCULATOR_INTERNAL_CTA_CLICK: 'price-calculator-cta-onclick',
    EVENT_PRICE_CALCULATOR_FILTER_CLICK: 'price-calculator-filter-onclick',
    BOOKNOW_LABEL: 'book now',
    LOADMORE_LABEL: 'load more',
    SEEPRICE_LABEL: 'see price',
};
