import React, { useCallback, useEffect, useState } from 'react';
import { Heading } from '../../../../components/common/heading/heading';
import './smash-card.scss';
import ServerSideService from '../../../../services/server-side-service/server-side-service';
import { Article } from '../../../../models/experiencefragments/category-view';
import PrimaryButton from '../../../../components/common/primary-button/primary-button';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { NewAnalyticsService } from '../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../hooks/use-analytics';

export const SmashCard = (props: Article) => {
    const [buttonFillStyleClass, setButtonFillStyleClass] = useState<
        'outline' | 'fill'
    >('outline');
    const [fireEvents] = useAnalytics();
    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 500px)').matches
            : false
    );
    const handleWindowResize = useCallback(() => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 500px)').matches
                : false
        );
    }, [setMobile]);
    useEffect(() => {
        const { brand } = new AppConfigurationService();
        if (brand === BRAND.lincoln.LONG_NAME) {
            setButtonFillStyleClass('fill');
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    const eventClick = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            props.ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('smash-5050-click-event', undefined, {});
        }
    };

    return (
        <section className="smash-card" data-testid="smash-card-id">
            <div
                className="smash-card__image"
                style={{
                    backgroundImage: `url(${
                        process.env.REACT_APP_AEM_BASE_URL
                    }${
                        isMobile
                            ? props.mobileImagePath
                            : props.desktopImagePath
                    })`,
                }}
            >
                <span
                    role="img"
                    role-img-alt={props.imageAltText}
                    aria-label={props.imageAltText}
                />
            </div>
            <div className="smash-card__body">
                {props.title && (
                    <Heading type="h2" className="smash-card__body-title">
                        {props.title}
                    </Heading>
                )}
                {props.headline && (
                    <Heading type="h2" className="smash-card__body-headline">
                        {props.headline}
                    </Heading>
                )}

                <div className="smash-card__body-description">
                    {props.description}
                </div>
                {props.ctaLabel && (
                    <PrimaryButton
                        className="smash-card__body-cta"
                        color="dark"
                        fill={buttonFillStyleClass}
                        chevron={false}
                        href={props.ctaPath}
                        ariaLabel={props.ctaAriaLabel}
                        target={props.targetBlank ? '_blank' : ''}
                        onClick={() => eventClick()}
                    >
                        {props.ctaLabel}
                    </PrimaryButton>
                )}
            </div>
        </section>
    );
};
