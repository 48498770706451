import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import { LoyaltyPointsServiceResponse } from './loyalty-points-service.interface';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

export default class LoyaltyPointsService {
    async request(): Promise<LoyaltyPointsServiceResponse | null> {
        const {
            current3LetterCountryCode,
            currentLanguageRegionCode,
        } = new AppConfigurationService();
        const url = DSL_BASE_URL + DSL_API_PATH.AGGREGATE_POINTS;
        const params = {
            programCountry: current3LetterCountryCode.toUpperCase(),
            languageCode: currentLanguageRegionCode,
        };
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        const response = HttpService.get<LoyaltyPointsServiceResponse>(
            url,
            false,
            { params, headers }
        );
        return response.then(this.parsePointsResponse);
    }

    private parsePointsResponse(
        response: AxiosResponse<LoyaltyPointsServiceResponse>
    ): LoyaltyPointsServiceResponse | null {
        if (response.data) {
            return response.data;
        }
        return null;
    }
}
