import React from 'react';
import { InfoIconToolTip } from '../info-icon-tooltip/info-icon-tooltip';

import './skinny-banner.scss';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    bannerDesktop: string;
    bannerTablet: string;
    bannerMobile: string;
    isDarkBackground?: boolean;
    imageAlt: string;
    url: string;
    target: string;
    tooltipHeader?: string;
    tooltipCloseButtonAriaLabel: string;
    tooltipContent: string;
}
const SkinnyBanner = (props: Props) => {
    const {
        bannerDesktop,
        bannerTablet,
        bannerMobile,
        imageAlt,
        url,
        target,
        tooltipHeader,
        tooltipCloseButtonAriaLabel,
        tooltipContent,
        isDarkBackground,
    } = props;

    const desktop = `${process.env.REACT_APP_AEM_BASE_URL}${bannerDesktop}`;
    const tablet = `${process.env.REACT_APP_AEM_BASE_URL}${bannerTablet}`;
    const mobile = `${process.env.REACT_APP_AEM_BASE_URL}${bannerMobile}`;

    const imageBackgroundClass = isDarkBackground ? 'light' : 'dark';
    const srcset = `${desktop}, ${tablet}, ${mobile}`;

    const [fireEvents] = useAnalytics();

    const eventClick = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            props.url
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('skinny-banner-click-event', undefined, {
                ctaType: ctaAnalytic,
            });
        }
    };
    const splitUrl = props.url.split('/').filter(slug => slug && slug !== '');
    let ctaAnalytic = splitUrl?.pop();
    ctaAnalytic = ctaAnalytic?.replaceAll('-', ' ');

    return (
        <section className="skinny-banner-section" role="banner">
            <a
                href={url}
                target={target}
                rel="noreferrer"
                className="skinny-banner-link"
                data-testid="skinny-banner-link"
                onClick={eventClick}
            >
                <picture>
                    <source
                        srcSet={desktop}
                        media="(min-width: 1024px)"
                    ></source>
                    <source srcSet={tablet} media="(min-width: 768px)"></source>
                    <source srcSet={mobile}></source>
                    <img
                        src={mobile}
                        srcSet={srcset}
                        alt={imageAlt}
                        className="skinny-banner-image"
                    />
                </picture>
            </a>
            <div className="skinny-banner-tooltip">
                <InfoIconToolTip
                    tooltipInfoIconClass={imageBackgroundClass}
                    tooltipHeader={tooltipHeader || ''}
                    tooltipContent={tooltipContent}
                    customShowTooltipAriaLabel={tooltipCloseButtonAriaLabel}
                />
            </div>
        </section>
    );
};

export default SkinnyBanner;
