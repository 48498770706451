import { useContext } from 'react';
import { ProfileWithVehiclesResponse } from '../models/profile-with-vehicles';
import ClientContext, {
    ClientContextInterface,
} from '../contexts/clientContext';

const useUserProfile = (): ProfileWithVehiclesResponse | undefined => {
    const { profile } = useContext<ClientContextInterface>(ClientContext);
    return profile;
};

export default useUserProfile;
