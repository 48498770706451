import React from 'react';
import './redeem-rebates-options.scss';

interface Props {
    onwebtitle: string;
    onwebdescription: string;
    onmailtitle: string;
    onmaildescription: string;
    onwebimage: string;
    onmailimage: string;
}

export const RedeemRebatesOptions = (props: Props) => {
    return (
        <div className="redeem-rebates-online-info">
            <div className="redeem-rebates">
                <div className="redeem-rebates-row">
                    <div className="redeem-rebates-container">
                        <div className="redeem-content">
                            <h2 className="site-header">{props.onwebtitle}</h2>
                            <img
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    props.onwebimage
                                }
                                alt={props.onwebtitle}
                                className="center"
                            />
                            <p className="content-redeem">
                                {props.onwebdescription}
                            </p>
                        </div>
                    </div>
                    <div className="redeem-rebates-container">
                        <div className="redeem-content">
                            <h2 className="site-header">{props.onmailtitle}</h2>
                            <img
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    props.onmailimage
                                }
                                alt={props.onmailtitle}
                                className="center"
                            />
                            <p className="content-redeem">
                                {props.onmaildescription}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
