import React, { useContext } from 'react';
import { findByAlias, findByAliasV1, findByPathV1 } from '../../../routes';
import { OsbMainWrapperView, PageNotFoundView } from '../../../views';
import { useParams } from 'react-router-dom';
import ServerContext from '../../../contexts/serverContext';
import { VehicleStepView } from '../../../views/osb-v3-view/vehicle-step-view/vehicle-step-view';
import { ServiceCentreStepView } from '../../../views/osb-v3-view/service-centre-step-view/service-centre-step-view';
import { OsbV3BookingConfirmationView } from '../../../views/osb-v3-view/booking-confirmation-view/booking-confirmation-view';
import { CancelConfirmStepView } from '../../../views/osb-v3-view/cancel-confirm-step-view/cancel-amend-confirmation';
import { ContactDetailsStepView } from '../../../views/osb-v3-view/contact-details-step-view/contact-details-step-view';

export const OsbPathStartStep = (): string => {
    return findByAlias('OsbStartView');
};
export const OsbPathVehicleStep = (): string => {
    return findByAlias('VehicleStepView');
};
export const OsbPathServiceCentreStep = (): string => {
    return findByAlias('ServiceCentreStepView');
};
export const OsbBookingConfirmationView = (): string => {
    return findByAlias('OsbV3BookingConfirmationView');
};

export const OsbPathCancelConfirmStep = (): string => {
    return findByAlias('CancelConfirmStepView');
};

export const OsbPathContactDetailsStep = (): string => {
    return findByAlias('ContactDetailsStepView');
};
const osbViewSelector = (componentName: string | undefined) => {
    switch (componentName) {
        case 'OsbStartView':
            return <></>;
        case 'VehicleStepView':
            return <VehicleStepView />;
        case 'ServiceCentreStepView':
            return <ServiceCentreStepView />;
        case 'BookingConfirmationView':
            return <OsbV3BookingConfirmationView />;
        case 'CancelConfirmStepView':
            return <CancelConfirmStepView />;
        case 'ContactDetailsStepView':
            return <ContactDetailsStepView />;
        default:
            return <PageNotFoundView />;
    }
};
export const OsbViewWrapper: React.FunctionComponent = (): React.JSX.Element => {
    const context = useContext(ServerContext);
    const { view: viewPath } = useParams<{ view: string }>();

    let basePath: string;
    const b = findByAliasV1(
        'OsbViewWrapper',
        context.domain,
        context.brand,
        context.currentLanguageRegionCode
    );

    if (b !== undefined) {
        if (typeof b == 'string') {
            basePath = b;
        } else {
            basePath = b[0];
        }
    } else basePath = 'OsbStartView'; // this should never happen
    let component;
    console.log('viewPath', viewPath);
    if (viewPath) {
        component = findByPathV1(
            basePath + '/' + viewPath,
            context.domain,
            context.brand,
            context.currentLanguageRegionCode
        );
    } else {
        component = 'OsbStartView';
    }
    return (
        <OsbMainWrapperView>{osbViewSelector(component)}</OsbMainWrapperView>
    );
};
export default OsbViewWrapper;
