import React, { useState, useEffect } from 'react';
import VehicleInfo from '../vehicle-info/vehicle-info';
import VehicleInfoSkeleton from '../vehicle-info-skeleton/vehicle-info-skeleton';
import { VehicleDetail } from '../../../../../models/profile-with-vehicles';
import ProfileService from '../../../../../services/profile-service/profile-service';
import './vehicle-list.scss';
import { FMButton, FMModal } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import ErrorDisplay from '../../common/error-display/error-display';
import VehicleSearchForm from '../vehicle-search-form/vehicle-search-form';
import FordAccountVehicle from '../ford-account-vehicle/ford-account-vehicle';

const VehicleList: React.FC = () => {
    const [vehicles, setVehicles] = useState<VehicleDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'vehicle-step-extension') || {};

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [showVehicleInfo, setShowVehicleInfo] = useState(false);
    const [vehicleInfo, setVehicleInfo] = useState<any>(null);
    const [vehicleImageUrl, setVehicleImageUrl] = useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        const fetchVehicles = async () => {
            const profileService = new ProfileService();
            try {
                const { vehicles } = await profileService.request();
                setVehicles(vehicles);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch vehicles from profile');
                setLoading(false);
            }
        };

        fetchVehicles();
    }, []);

    const togglePopup = () => {
        console.log('togglePopup called');
        setIsPopupVisible(prev => !prev);
    };

    const handleVehicleInfoSuccess = (info: any, imageUrl: string) => {
        setVehicleInfo(info);
        setVehicleImageUrl(imageUrl);
        setShowVehicleInfo(true);
    };
    const Popup = () => (
        <FMModal
            data-testid="modal"
            name={'vehicle-search-popup'}
            isVisible={isPopupVisible}
            onClose={togglePopup}
        >
            <VehicleSearchForm
                onContinue={togglePopup}
                onVehicleInfoSuccess={handleVehicleInfoSuccess}
            />
        </FMModal>
    );

    const renderContent = () => {
        if (loading) {
            return (
                <div className="osb-v3-vehicle-info-skeleton-wrapper">
                    <VehicleInfoSkeleton />
                    <VehicleInfoSkeleton />
                </div>
            );
        }

        if (error) {
            return (
                <div className="osb-v3-vehicle-info-error-display">
                    <ErrorDisplay
                        title={`${osbContentData?.errorMessageTitle}`}
                        errorMessage={error}
                        suggestion={`${osbContentData?.errorMessageSuggestion}`}
                    />
                </div>
            );
        }

        return (
            <div className="osb-v3-vehicle-info-scroll-container">
                {vehicles.map(vehicle => (
                    <VehicleInfo key={vehicle.vin} vehicleDetail={vehicle} />
                ))}
            </div>
        );
    };
    return (
        <>
            <div className="osb-v3-vehicle-info-heading">
                {osbContentData?.vehicleInformationLabel}
            </div>
            {renderContent()}
            <div className="osb-v3-add-vehicle-row">
                <span className="vehicle-label">
                    {osbContentData?.vehicleNotListedLabel}
                </span>
                <FMButton
                    data-testid="closeButton"
                    className="add-vehicle-button"
                    theme="light"
                    type="primary"
                    label={osbContentData?.addAVehicleButtonLabel}
                    onClick={togglePopup}
                />
            </div>
            {isPopupVisible && <Popup />}

            {/* Modal for displaying FordAccountVehicle */}
            <FMModal
                name="osb-v3-vehicle-detail-info"
                isVisible={showVehicleInfo}
                onClose={() => setShowVehicleInfo(false)}
            >
                {vehicleInfo && (
                    <FordAccountVehicle
                        vehicleInfo={vehicleInfo}
                        vehicleImageUrl={vehicleImageUrl || ''}
                    />
                )}
            </FMModal>
        </>
    );
};

export default VehicleList;
