import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
export interface trailerResponse {
    mfgName?: Array<string>;
    mfgMake?: Array<string>;
    mfgModel?: Array<string>;
    mfgYear?: Array<string>;
}
export class uteProfileService {
    profileSearch(request: any) {
        const params = {
            ...request,
        };
        const qs = '?' + new URLSearchParams(params).toString();
        const url = DSL_BASE_URL + DSL_API_PATH.UTE_PROFILE_SEARCH + qs;
        const response = HttpService.get<trailerResponse>(url, true, {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
        }).catch(e => {
            throw e;
        });

        return response.then(response => {
            return response.data;
        });
    }
}
