import { AxiosRequestConfig } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import HttpService from '../http-service/http-service';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class CheckStatusService {
    public async forgotTrackingNumber(email: string): Promise<any> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url = DSL_BASE_URL + DSL_API_PATH.EMAIL_FORGOT_TRACKING_NUMBER;
        const body = { email, authenticated: false } as any;
        const request = HttpService.post<any>(url, body, config);

        request.catch(error =>
            console.log(`ami email-tracking-info error ${error?.message}`)
        );
        return request.then(response => {
            return response.data;
        });
    }
    public async checkRebatesStatus(
        email: string,
        trackingNumber: string
    ): Promise<any> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url = DSL_BASE_URL + DSL_API_PATH.AMI_CHECK_STATUS;
        const body = {
            email,
            trackingNumber,
        };

        const request = HttpService.post<any>(url, body, config);
        request.catch(error =>
            console.log(`ami check rebates status error ${error?.message}`)
        );
        return request.then(response => {
            return response.data;
        });
    }
}
