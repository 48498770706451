import { useContent } from '../../../../hooks/use-server-content';

export interface ServiceHistoryVehInfoContentProps {
    ctaLabel: string;
    ctaHref: string;
    ctaAriaLabel: string;
    ctaTarget: string;
    odometerLabel: string;
    milesLabel: string;
    kilometersLabel: string;
    nextServiceDueInformation: string;
    nextServiceDueInformationLookAHeadNoMileage: string;
    nextServiceDueInformationLookBackPastDue: string;
    odometerTooltipHeader: string;
    odometerTooltipContent: string;
    nextServiceDueTooltip: string;
    serviceHistoryUpToDateNotification: string;
    yourNextServiceIsDueTooltipAlt: string;
    serviceUpToDateIconAlt: string;
}

export const useServiceHistoryVehInfoContent = (): ServiceHistoryVehInfoContentProps => {
    const [content, getValueByName] = useContent(
        'common',
        'service-history-vehicle-information'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ServiceHistoryVehInfoContentProps;
};
