import React from 'react';
import { Video } from '../../../../../common/video/video';
import './fds-video-content.scss';

export interface Props {
    videoId: string;
    videoAccountId: string;
    videoDataPlayer: string;
}
export const FdsVideoContent = (props: Props) => {
    return (
        <div className="fds-video-content-main">
            <div className="fds-video-content">
                <Video
                    videoId={props.videoId.trim()}
                    accountId={props.videoAccountId.trim()}
                    dataPlayer={props.videoDataPlayer.trim()}
                ></Video>
            </div>
        </div>
    );
};
