import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '../../../card/card';
import './feature-trio.scss';
import { SecondaryButton } from '../../../../common';
import { Heading } from '../../../../common/heading/heading';
import { Articles } from '../../../../../models/experiencefragments/category-view';
import { PaginationCX } from '../../../../common/pagination-cx/pagination-cx';
import { usePaginationCxContent } from '../../../../common/pagination-cx/hooks/use-pagination-cx';
import { PageType } from '../../../view-template/page-type';
import { TrioCarousel } from './trio-carousel';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

interface Props {
    title?: string;
    subtitle?: string;
    openInNewTabAriaLabel?: string;
    cards: FMCardProps[];
    articles?: Articles;
    dynamicCardId?: string;
    analyticsEventName?: string;
    pagination?: Boolean;
    page?: PageType;
}

export const FeatureTrio = (props: Props) => {
    const sectionId = props.title?.replace(/ /g, '-').toLowerCase();
    const paginationCxContent = usePaginationCxContent();
    let titleType2 = false;
    let cards: FMCardProps[] = [];
    if (props.articles) {
        titleType2 = true;
    }
    if (props.cards === undefined) {
        const tempProps = { cards: [] };
        props = { ...props, ...tempProps };
    }
    if (props.cards?.length === 0 && props.articles) {
        cards = props.articles?.articles?.map(article => {
            return {
                title: article.plainTitle
                    ? article.plainTitle
                    : 'Authoring Error',
                titleWithDisclaimer: article.title,
                description: article.plainDescription
                    ? article.plainDescription
                    : 'Authoring Error',
                descriptionWithDisclaimer: article.description,
                imageSrc: article.desktopImagePath,
                imageAlt: article.imageAltText,
                ctaHref: article.ctaPath,
                ctaLabel: article.ctaLabel ? article.ctaLabel : 'Author CTA',
                ctaAriaLabel: article.ctaAriaLabel,
                ctaType: 'primary',
                ctaTheme: 'light',
            } as FMCardProps;
        });
    } else if (props.cards) {
        cards = props.cards.map(card => {
            return {
                ...card,
                ctaType: 'primary',
                ctaTheme: 'light',
            } as FMCardProps;
        });
    }

    const featureTrioCards = cards.map((card, index) => {
        return (
            <div
                className="card-wrap"
                key={index}
                id={
                    (props?.dynamicCardId ? props.dynamicCardId : 'T') +
                    (index + 1)
                }
            >
                <Card
                    key={index}
                    data={card}
                    analyticsClickEventName={props.analyticsEventName}
                    openInNewTabAriaLabel={props.openInNewTabAriaLabel}
                />{' '}
            </div>
        );
    });

    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 500px)').matches
            : false
    );
    const handleWindowResize = useCallback(() => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 500px)').matches
                : false
        );
    }, [setMobile]);
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    const isSIPageInMobile = () => {
        return (
            isMobile &&
            props.page &&
            ['VIN', 'YMM', 'Landing', 'DASHBOARD'].includes(props?.page)
        );
    };

    const getClassNames = () => {
        const featureTrioClasses = ['feature-trio'];
        if (isSIPageInMobile())
            featureTrioClasses.push('trio-carousel-padding');
        return featureTrioClasses.join(' ');
    };

    return (
        <article
            className={getClassNames()}
            data-testid="feature-trio-id"
            id={sectionId}
        >
            {!titleType2 && props.title && (
                <Heading
                    type="h2"
                    className={`feature-trio-title ${
                        props.pagination ? 'scroll-into-view' : ''
                    }`}
                >
                    {props?.title}
                </Heading>
            )}
            {!titleType2 && props.subtitle && (
                <p className="feature-trio-subtitle">{props.subtitle}</p>
            )}
            {titleType2 && (
                <div className="feature-trio-heading-two">
                    <h2 className="feature-trio-cards__title-two">
                        {props.articles?.sectionHeading}
                    </h2>
                    {props.articles?.cta?.title && props.articles?.cta?.url && (
                        <SecondaryButton
                            linkTarget={
                                props.articles?.cta?.targetBlank ? '_blank' : ''
                            }
                            link={props.articles?.cta?.url}
                            noChevron={false}
                            aria-label={props.articles.cta?.ariaLabel}
                            dataTestId="explore-all-link"
                        >
                            {props.articles?.cta?.title}
                        </SecondaryButton>
                    )}
                </div>
            )}
            {titleType2 && <hr className="feature-trio-cards__divider" />}
            {paginationCxContent && props.pagination ? (
                <PaginationCX
                    data={featureTrioCards}
                    desktopItemsPerPage={
                        paginationCxContent.numberOfItemsPerPageInDesktop
                    }
                    mobileItemsPerPage={
                        paginationCxContent.numberOfItemsPerPageInMobile
                    }
                    totalItems={props.cards.length}
                />
            ) : (
                <>
                    {isSIPageInMobile() ? (
                        <TrioCarousel
                            cards={cards}
                            dynamicCardId={props?.dynamicCardId}
                            analyticsEventName={props.analyticsEventName}
                            openInNewTabAriaLabel={props.openInNewTabAriaLabel}
                        />
                    ) : (
                        <section className="feature-trio-cards">
                            {featureTrioCards}
                        </section>
                    )}
                </>
            )}
        </article>
    );
};
