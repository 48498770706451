import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { FdsChevron } from '../../../../common/chevron/fds-chevron';
import printIcon from '../../../../../assets/print-icon.svg';
import {
    RewardsApiResponse,
    subcategoryTitle,
    catagoryNameForURL,
} from '../../constants/route-constants';
import { dropdown } from '../../coupons';
import { CaoOptionalCta } from '../cao-optional-cta/cao-optional-cta';
import { CardDescription } from '../cao-common/card-description';
import { focusOnCouponsDetail } from '../coupons-detail/coupons-detail';
import parse from 'html-react-parser';
import { displayOfferUrl } from './display-offer-url';

export let printcardParse: any;
export const DisplayCoupons = (
    categoryFilter: string,
    categoryTitle: string,
    categoryIcon: string,
    position: number,
    rewardsContent: RewardsApiResponse | undefined
) => {
    const [fireEvents] = useAnalytics();
    let subcategory: string;
    const noCoupons = rewardsContent?.coupons.filter(
        coupon =>
            coupon.commodityDescription
                .split(':')
                .slice(1)
                .join(':')
                .indexOf(categoryFilter) !== -1
    );
    let title: any;
    const [printcard, setprintcard] = useState<ReactNode>();

    const printPage = (index: any) => {
        const id = `rewards${index}`;

        const printcard: any = document.getElementById(id)?.innerHTML
            ? document.getElementById(id)?.innerHTML
            : 'error';
        printcardParse = parse(printcard);
        setprintcard(printcardParse);
        setTimeout(() => {
            window.print();
        }, 10);
    };

    return (
        <div className="category-heading">
            {noCoupons?.length === 0 && (
                <> SORRY, THERE ARE NO OFFERS FOR THIS SELECTION</>
            )}
            <div
                className={`${
                    dropdown ? 'nodisplay' : 'selected-item-display'
                }`}
            >
                <img
                    className="selected-category-icon"
                    src={
                        (process.env.REACT_APP_AEM_BASE_URL as string) +
                        categoryIcon
                    }
                    alt={`${categoryTitle} `}
                />
                <p className="dropdown-selected mobile-only">
                    {categoryTitle + ' '}
                </p>
            </div>
            <hr className="rewards-hr" aria-hidden="true" />
            <div className="rewards-cta-mobile">
                <CaoOptionalCta categoryName={categoryTitle} />
            </div>
            <div className="no-display">{printcard}</div>
            {noCoupons?.length != 0 &&
                noCoupons?.map((couponDetails, index) => {
                    const commodityDescriptionElement = couponDetails.commodityDescription.split(
                        ':'
                    )[1];
                    subcategory = commodityDescriptionElement;
                    const categoryURL = catagoryNameForURL(subcategory, title);
                    title = subcategoryTitle(subcategory, title);

                    return (
                        <div
                            id={`rewards${index}`}
                            key={couponDetails.couponId}
                        >
                            <div className="rewards-card">
                                <div className="card-image-div">
                                    <img
                                        className="card-image"
                                        src={couponDetails.imageURLXLarge}
                                        alt={couponDetails.commodityDescription}
                                    ></img>
                                </div>
                                <div className="card-body">
                                    <button
                                        className={`print-button${index} print-button`}
                                        onClick={() => {
                                            fireEvents(
                                                'coupons-detail-cta-onclick-event',
                                                undefined,
                                                {
                                                    couponsCategoryName: title.toLowerCase(),
                                                },
                                                false
                                            );
                                            printPage(index);
                                        }}
                                        aria-label={`"Print ${couponDetails.offer}"`}
                                    >
                                        <img
                                            src={printIcon}
                                            alt=""
                                            className="close-icon"
                                        />
                                    </button>

                                    <div className="title">{title}</div>
                                    <h3 className="card-description">
                                        <CardDescription {...couponDetails} />
                                    </h3>
                                    <div className="sub-headline">
                                        {parse(couponDetails.subCopy)}
                                    </div>
                                    {couponDetails.code &&
                                        !couponDetails.code.includes('E') && (
                                            <div className="expiry">
                                                EXPIRES{' '}
                                                {couponDetails.expiryDate}
                                            </div>
                                        )}
                                    <Link
                                        className={`button${index} view-offer-button`}
                                        to={displayOfferUrl(
                                            couponDetails.couponId,
                                            couponDetails.offer,
                                            categoryURL
                                        )}
                                        onClick={() => {
                                            setTimeout(() => {
                                                focusOnCouponsDetail();
                                            }, 100);
                                        }}
                                        aria-label={`Show more on ${couponDetails.offer}`}
                                        onKeyDown={event => {
                                            if (
                                                event.key === 'Tab' &&
                                                noCoupons.length - 1 === index
                                            ) {
                                                const currentElement = document.querySelector(
                                                    `.desktop${position}`
                                                ) as HTMLAnchorElement;
                                                currentElement?.focus();
                                            }
                                        }}
                                    >
                                        View Offer
                                        <div className="chevron">
                                            <FdsChevron
                                                type="filled"
                                                direction="right"
                                            />
                                        </div>
                                    </Link>
                                    <div className="rewards-printonly">
                                        <br />
                                        {parse(couponDetails.disclaimer)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
