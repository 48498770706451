import { findByAlias } from '../../../routes';
import serverSideService from '../../../services/server-side-service/server-side-service';
import siteMapService from '../../services/site-map-service/site-map-service';
import { RECALLS } from '../../support-constants';
import { NavLink } from 'react-router-dom';

export const getRecallsLandingLink = (
    languageRegionCode: string,
    brand: string,
    isImgTakataRoute = false
): string => {
    const recallsComponent =
        RECALLS.TAKATA_RECALLS_AVAILABLE.includes(
            languageRegionCode.toLowerCase()
        ) && brand.toLowerCase() === 'ford'
            ? 'TakataRecallsPage'
            : 'RecallsPage';

    return siteMapService.getPathFromRouterConfigs(
        languageRegionCode,
        brand,
        recallsComponent,
        [],
        isImgTakataRoute
    );
};
export const isYmmAvailable = (lngRegCode: string, brand: string): boolean => {
    let hasYmm = false;
    RECALLS.YMM_AVAILABLE.forEach(couple => {
        if (
            brand?.toLowerCase() === couple.brand &&
            lngRegCode?.toLowerCase() === couple.lngRegCode
        )
            hasYmm = true;
    });
    return hasYmm;
};

export const isIMGTakataCampaignIdPresent = (
    lngRegCode: string,
    responseCampaignID: string
): boolean => {
    return RECALLS.IMG_TAKATA_RECALLS_AVAILABLE.some(
        takata =>
            lngRegCode?.toLowerCase() === takata.lngRegCode &&
            takata.campaignIDs.includes(responseCampaignID.toLowerCase())
    );
};

export const isIMGTakataRoute = (): boolean => {
    if (serverSideService.isClientSide()) {
        return window.location.pathname?.includes(RECALLS.IMG_TAKATA_ROUTE);
    } else {
        return false;
    }
};

export const findByAliasForRecalls = (
    alias: string,
    isIMGTakataRoute: boolean
): any => {
    if (findByAlias(alias) instanceof Array && !isIMGTakataRoute) {
        return findByAlias(alias)[0];
    }
    if (findByAlias(alias) instanceof Array && isIMGTakataRoute) {
        return findByAlias(alias)[1];
    }
    return findByAlias(alias);
};

export const RecallPageBreadCrumb = (title: string, href: string) => {
    return (
        <>
            <NavLink
                className="recalls-breadcrumb-link"
                to={href}
                onClick={() => {
                    sessionStorage.removeItem('QUERY_VIN');
                    localStorage.removeItem('USER_VIN');
                }}
            >
                <span>{title}</span>
            </NavLink>
            <span className="back-slash">{'>'}</span>
        </>
    );
};
