import { createClient } from 'redis';
import serverSideService from '../server-side-service/server-side-service';
export class RedisCacheService {
    private static redisClient: any;
    static async getRedisClient() {
        if (serverSideService.isServerSide()) {
            if (RedisCacheService.redisClient?.isOpen)
                return RedisCacheService.redisClient;
            if (process.env.REACT_APP_PLATFORM === 'gcp') {
                RedisCacheService.redisClient = createClient({
                    password: process.env.REACT_APP_REDIS_CERT_PWD,
                    socket: {
                        host: process.env.REACT_APP_REDIS_HOST,
                        port: process.env.REACT_APP_REDIS_PORT,
                        tls: true,
                        ca: [process.env.REACT_APP_REDIS_CA_CERT],
                        reconnectStrategy: function(retries: number) {
                            return retries > 20
                                ? new Error('Too many retries.')
                                : retries * 500;
                        },
                    },
                });
            } else {
                RedisCacheService.redisClient = createClient({
                    socket: {
                        reconnectStrategy: function(retries: number) {
                            return retries > 20
                                ? new Error('Too many retries.')
                                : retries * 500;
                        },
                    },
                    port: process.env.REACT_APP_REDIS_PORT,
                });
            }
            RedisCacheService.redisClient.on('error', () => {
                console.info('Error connecting to Redis');
            });
            RedisCacheService.redisClient.on('ready', () => {
                console.info('Redis Client is ready!');
            });
            RedisCacheService.redisClient.on('reconnecting', () => {
                console.info('Connection lost! Reconnecting to Redis...');
            });
            await RedisCacheService.redisClient.connect();
            return RedisCacheService.redisClient;
        }
    }
}
