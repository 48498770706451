import { Config } from './components/utils/router-util/router-util';
import { ROUTE_REGEX } from './constants';

export const configs: Config[] = [
    {
        brand: 'ford',
        locale: 'de-de',
        routes: [
            {
                path: '/sync-karten-software-updates',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/fahrzeug/vin/nicht-gefunden',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/fahrzeugzustand',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/fahrzeug/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-fahrzeug/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/fahrzeug-ueberblick',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-fahrzeug-ueberblick',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/ihren-ford-entdecken/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/ihren-ford-entdecken/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/digitaler-service-nachweis',
                componentName: 'ServiceHistoryView',
            },
            {
                path: ['/garantie', '/garantie/', '/garantie/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/wartungsintervalle/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/wartungsintervalle',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/online-service-buchung',
                    '/online-service-buchung/',
                    '/online-service-buchung/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/online-service-buchung',
                componentName: 'OsbStartView',
            },
            {
                path: '/online-service-buchung/buchung-pin',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/online-service-buchung/buchung-stornieren',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/online-service-buchung/buchung-bestätigen',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/online-service-buchung/buchung-übersicht',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/online-service-buchung/buchung-laden',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/online-service-buchung/buchung-zusammenfassung',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/online-service-buchung/termin',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/online-service-buchung/händler',
                componentName: 'OsbDealerView',
            },
            {
                path: '/online-service-buchung/übergabe',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/online-service-buchung/kontakt-information',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/online-service-buchung/leistungen',
                componentName: 'OsbServicesView',
            },
            {
                path: '/online-service-buchung/fahrzeuge',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/berechnen-sie-ihren-servicepreis',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/fahrzeuge/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-fahrzeuge/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/anleitungen/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/anleitungen/suche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/anleitungen/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/anleitungen/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/anleitungen/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rückrufe/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rückrufe/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rueckruf-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/betriebsanleitungen/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/fahrzeug/:model+/:year/betriebsanleitungen/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/betriebsanleitungen/uebersicht/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/betriebsanleitungen-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'de-at',
        routes: [
            {
                path: '/sync-karten-software-updates',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/fahrzeug/vin/nicht-gefunden',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/fahrzeug/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-fahrzeug/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/fahrzeugzustand',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/fahrzeugzustand/bremsen-achsaufhaengung',
                    '/fahrzeugzustand/fluessigkeiten-filter',
                    '/fahrzeugzustand/sicherheit-fahrerassistenz',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/fahrzeug-ueberblick',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-fahrzeug-ueberblick',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/ihren-ford-entdecken/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/ihren-ford-entdecken/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/digitaler-service-nachweis',
                componentName: 'ServiceHistoryView',
            },
            {
                path: ['/garantie', '/garantie/', '/garantie/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/wartungsintervalle/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/wartungsintervalle',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/online-service-buchung',
                    '/online-service-buchung/',
                    '/online-service-buchung/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/online-service-buchung',
                componentName: 'OsbStartView',
            },
            {
                path: '/online-service-buchung/buchung-pin',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/online-service-buchung/buchung-stornieren',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/online-service-buchung/buchung-bestätigen',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/online-service-buchung/buchung-übersicht',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/online-service-buchung/buchung-laden',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/online-service-buchung/buchung-zusammenfassung',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/online-service-buchung/termin',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/online-service-buchung/händler',
                componentName: 'OsbDealerView',
            },
            {
                path: '/online-service-buchung/übergabe',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/online-service-buchung/kontakt-information',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/online-service-buchung/leistungen',
                componentName: 'OsbServicesView',
            },
            {
                path: '/online-service-buchung/fahrzeuge',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/berechnen-sie-ihren-servicepreis',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/fahrzeuge/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-fahrzeuge/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/anleitung/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/anleitung/suche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/anleitung/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/anleitung/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/anleitung/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rueckrufe/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rueckrufe/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rueckrufe-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/benutzerhandbucher/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/fahrzeug/:model+/:year/benutzerhandbucher/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/benutzerhandbucher/sitemap-benutzerhandbucher/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/benutzerhandbucher-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'de-ch',
        routes: [
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/fahrzeug/vin/nicht-gefunden',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/fahrzeug/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-fahrzeug/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/fahrzeugzustand',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/fahrzeug-ueberblick',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-fahrzeug-ueberblick',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/ihren-ford-entdecken/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/ihren-ford-entdecken/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/digitaler-service-nachweis',
                componentName: 'ServiceHistoryView',
            },
            {
                path: ['/garantie', '/garantie/', '/garantie/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/wartungsintervalle/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/wartungsintervalle',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/online-service-buchung',
                    '/online-service-buchung/',
                    '/online-service-buchung/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/online-service-buchung',
                componentName: 'OsbStartView',
            },
            {
                path: '/online-service-buchung/buchung-pin',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/online-service-buchung/buchung-stornieren',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/online-service-buchung/buchung-bestätigen',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/online-service-buchung/buchung-übersicht',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/online-service-buchung/buchung-laden',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/online-service-buchung/buchung-zusammenfassung',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/online-service-buchung/termin',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/online-service-buchung/händler',
                componentName: 'OsbDealerView',
            },
            {
                path: '/online-service-buchung/übergabe',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/online-service-buchung/kontakt-information',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/online-service-buchung/leistungen',
                componentName: 'OsbServicesView',
            },
            {
                path: '/online-service-buchung/fahrzeuge',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/berechnen-sie-ihren-servicepreis',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/fahrzeuge/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-fahrzeuge/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/anleitung/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/anleitung/suche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/anleitung/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/anleitung/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/anleitung/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rückrufe/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rückrufe/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rückruf-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/bedienungsanleitungen/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/fahrzeug/:model+/:year/bedienungsanleitungen/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/bedienungsanleitungen/eigentumerhandbuch-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/bedienungsanleitungen-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'ko-kr',
        routes: [
            {
                path: ['/booking', '/booking/', '/booking/:view'],
                componentName: 'OsbWrapper',
            },
            {
                path: '/booking',
                componentName: 'OsbStartView',
            },
            {
                path: '/booking/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/booking/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/booking/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/booking/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/booking/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/booking/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/booking/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/booking/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/booking/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/booking/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/booking/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/booking/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/calculate-your-service-price',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'lincoln',
        locale: 'ko-kr',
        routes: [
            {
                path: ['/booking', '/booking/', '/booking/:view'],
                componentName: 'OsbWrapper',
            },
            {
                path: '/booking',
                componentName: 'OsbStartView',
            },
            {
                path: '/booking/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/booking/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/booking/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/booking/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/booking/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/booking/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/booking/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/booking/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/booking/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/booking/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/booking/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/booking/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: ['en-ca', 'fr-ca'],
        routes: [
            {
                path: '/towing-calculator',
                componentName: 'CITTView',
            },
            {
                path: '/towing-calculator/configure-payload',
                componentName: 'CITTConfigurePayloadView',
            },
            {
                path: '/fordpass',
                componentName: 'DeviceAwareNaEuView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },

            {
                path: [
                    '/service-maintenance/vehicle-health-reports/:vin',
                    '/service-maintenance/vehicle-health-reports',
                ],
                componentName: 'VehicleHealthReportView',
            },
            {
                path: [
                    '/rewards',
                    '/fordpass/fordpass-rewards',
                    '/lincoln-way-app/lincoln-access-rewards',
                ],
                componentName: 'RewardsViewHandler',
            },
            {
                path: '/recalls-details/:vinOrModel?/:year?/',
                componentName: 'RecallsDetailsPage',
            },

            {
                path: '/recalls/',
                componentName: 'TakataRecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/vehicle/:model/:year/discover-your-ford/livestream/',
                componentName: 'LiveStreamView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: [
                    `/vehicle/vin/:vin/discover-your-ford/`,
                    `/vehicle/:model/:year/discover-your-ford/`,
                ],
                componentName: 'DyfLandingView',
            },
            {
                path: [
                    `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/discover-your-ford/:title/overview/`,
                    `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/discover-your-ford/:categoryName/:title/overview/`,
                ],
                componentName: 'DyfYmmFeatureArticleOverview',
            },
            {
                path: [
                    `/vehicle/vin/:vin(${ROUTE_REGEX['vinGeneric']})/discover-your-ford/:title/overview/`,
                    `/vehicle/vin/:vin(${ROUTE_REGEX['vinGeneric']})/discover-your-ford/:categoryName/:title/overview/`,
                ],
                componentName: 'DyfVinFeatureArticleOverview',
            },
            {
                path: [
                    `/vehicle/vin/:vin(${ROUTE_REGEX['vinGeneric']})/discover-your-ford/:title/related-articles/`,
                    `/vehicle/vin/:vin(${ROUTE_REGEX['vinGeneric']})/discover-your-ford/:categoryName/:title/related-articles/`,
                ],
                componentName: 'DyfRelatedArticlesView',
            },
            {
                path: '/maintenance-schedule/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/maintenance-schedule',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap/',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: [
                    '/service-maintenance/tire-finder/',
                    '/service-maintenance/tire-finder/search-results.html',
                    '/service-maintenance/tire-finder/ecommerce.html',
                ],
                componentName: 'TireFinderView',
            },
            {
                path: '/service-information',
                componentName: 'TSBView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: ['en-us'],
        routes: [
            {
                path: '/collision-assistance',
                componentName: 'CollisionAssistanceView',
            },
            {
                path: '/towing-demystified',
                componentName: 'TowingDemystifiedView',
            },
            {
                path: '/towing-demystified-resources',
                componentName: 'TowingDemystifiedResourcesView',
            },
            {
                path: '/towing-dictionary',
                componentName: 'TowingDictionaryView',
            },
            {
                path: '/towing-demystified-calculator',
                componentName: 'TowingDemystifiedCalculatorView',
            },
            {
                path: '/towing-calculator',
                componentName: 'CITTView',
            },
            {
                path: '/towing-calculator/configure-payload',
                componentName: 'CITTConfigurePayloadView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '/category/fordpass/trailer-profiles/early-access',
                componentName: 'TowingExperienceView',
            },
            {
                path: '/category/service-maintenance/old-collision-assistance*',
                componentName: 'SamCategoryHandler',
            },
            {
                path: '/category/service-maintenance/collision-assistance*',
                componentName: 'SamReImagineCategoryHandler',
            },
            {
                path: '/fordpass',
                componentName: 'DeviceAwareNaEuView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: ['/vehicle-health', '/vehicle-health/:vin'],
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/vehicle-details-preimagine',
                componentName: 'PreImagineVehicleVinView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },

            {
                path: [
                    '/service-maintenance/vehicle-health-reports/:vin',
                    '/service-maintenance/vehicle-health-reports',
                ],
                componentName: 'VehicleHealthReportView',
            },
            {
                path: [
                    '/rewards',
                    '/fordpass/fordpass-rewards',
                    '/lincoln-way-app/lincoln-access-rewards',
                ],
                componentName: 'RewardsViewHandler',
            },
            {
                path: [
                    '/service-maintenance/tire-finder/',
                    '/service-maintenance/tire-finder/search-results.html',
                    '/service-maintenance/tire-finder/ecommerce.html',
                ],
                componentName: 'TireFinderView',
            },

            {
                path: '/recalls-details/:vinOrModel?/:year?/',
                componentName: 'RecallsDetailsPage',
            },

            {
                path: '/campaign-details/:id',
                componentName: 'RecallsCampaignDetailsPage',
            },
            {
                path: '/test-scanner',
                componentName: 'TestScannerPage',
            },
            {
                path: '/service-maintenance/service-rebates/redeem-rebates/',
                componentName: 'RedeemRebatesView',
            },
            {
                path: '/service-maintenance/coupons-offers-rebates/offers/',
                componentName: 'CouponsDetailView',
            },
            {
                path: '/service-maintenance/coupons-offers-rebates/',
                componentName: 'CouponsView',
            },
            {
                path: '/recalls/',
                componentName: 'TakataRecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'TakataRecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals-details/intelligent-search/',
                componentName: 'OwnerManualAiChat',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/vehicle/:model/:year/discover-your-ford/livestream/',
                componentName: 'LiveStreamView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/pro-trailer-backup-assist/',
                componentName: 'ProTrailerBackupAssist',
            },
            {
                path: ['/discover-your-ford/scheduling'],
                componentName: 'GuidedVirtualTour',
            },
            {
                path: ['/discover-your-ford/scheduling/new-appointment'],
                componentName: 'GVTCreateAppointment',
            },
            {
                path: ['/discover-your-ford/scheduling/edit'],
                componentName: 'GVTEditAppointment',
            },
            {
                path: ['/discover-your-ford/scheduling/appointment-details'],
                componentName: 'GVTAppointmentDetails',
            },
            {
                path: '/discover-your-ford/scheduling/cancelled-appointment',
                componentName: 'CancelledAppointmentView',
            },
            {
                path: '/discover-your-ford/scheduling/cancel',
                componentName: 'GvtCancelConfirmation',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: [
                    `/vehicle/vin/:vin/discover-your-ford/`,
                    `/vehicle/:model/:year/discover-your-ford/`,
                ],
                componentName: 'DyfLandingView',
            },
            {
                path: [
                    `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/discover-your-ford/:title/overview/`,
                    `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/discover-your-ford/:categoryName/:title/overview/`,
                ],
                componentName: 'DyfYmmFeatureArticleOverview',
            },
            {
                path: [
                    `/vehicle/vin/:vin(${ROUTE_REGEX['vinGeneric']})/discover-your-ford/:title/overview/`,
                    `/vehicle/vin/:vin(${ROUTE_REGEX['vinGeneric']})/discover-your-ford/:categoryName/:title/overview/`,
                ],
                componentName: 'DyfVinFeatureArticleOverview',
            },
            {
                path: [
                    '/vehicle/vin/:vin/discover-your-ford/:title/related-articles/',
                    '/vehicle/vin/:vin/discover-your-ford/:categoryName/:title/related-articles/',
                ],
                componentName: 'DyfRelatedArticlesView',
            },
            {
                path: ['/discover-your-ford/vehicle-selector/'],
                componentName: 'OnboardingVehicleSelectorView',
            },
            {
                path: '/maintenance-schedule',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap/',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/schedule-service',
                componentName: 'GxpCustomUIView',
            },
            {
                path: '/yfa-optin',
                componentName: 'YFAOptInView',
            },
            {
                path: '/yfa-success',
                componentName: 'YFAConfirmationView',
            },
            {
                path: '/yfa-enrolled',
                componentName: 'YFAEnrolledView',
            },
            {
                path: '/yfa-invited',
                componentName: 'YfaInvitedView',
            },
            {
                path: '/yfa-error',
                componentName: 'YfaErrorView',
            },
            {
                path: '/yfa-terms',
                componentName: 'YfaTermsConditions',
            },
            {
                path: '/service-information',
                componentName: 'TSBView',
            },
            {
                path: '/bluecruise',
                componentName: 'BlueCruisePage',
            },
            {
                path: '/software-update-release-notes-history',
                componentName: 'HistoricalReleaseNotes',
            },
        ],
    },
    {
        brand: 'lincoln',
        locale: 'en-us',
        routes: [
            {
                path: '/lincoln-way',
                componentName: 'DeviceAwareNaEuView',
            },
            {
                path: '/service-information',
                componentName: 'TSBView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/service-maintenance/vehicle-health-reports/:vin',
                    '/service-maintenance/vehicle-health-reports',
                ],
                componentName: 'VehicleHealthReportView',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: [
                    '/rewards',
                    '/fordpass/fordpass-rewards',
                    '/lincoln-way-app/lincoln-access-rewards',
                ],
                componentName: 'RewardsViewHandler',
            },
            {
                path: [
                    '/service-maintenance/tire-finder/',
                    '/service-maintenance/tire-finder/search-results.html',
                    '/service-maintenance/tire-finder/ecommerce.html',
                ],
                componentName: 'TireFinderView',
            },
            {
                path: '/recalls-details/:vinOrModel?/:year?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/service-maintenance/service-rebates/redeem-rebates/',
                componentName: 'RedeemRebatesView',
            },
            {
                path: '/service-maintenance/coupons-offers-rebates/offers/',
                componentName: 'CouponsDetailView',
            },
            {
                path: '/service-maintenance/coupons-offers-rebates/',
                componentName: 'CouponsView',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/campaign-details/:id',
                componentName: 'RecallsCampaignDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePage',
            },
            {
                path: '/home-preimagine',
                componentName: 'HomePreimagine',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: [
                    `/vehicle/vin/:vin/discover-your-lincoln/`,
                    `/vehicle/:model/:year/discover-your-lincoln/`,
                ],
                componentName: 'DyfLandingView',
            },
            {
                path: [
                    '/vehicle/vin/:vin/discover-your-lincoln/:title/overview/',
                    '/vehicle/vin/:vin/discover-your-lincoln/:categoryName/:title/overview/',
                ],
                componentName: 'DyfFeatureArticleOverview',
            },
            {
                path: [
                    '/vehicle/vin/:vin/discover-your-lincoln/:title/related-articles/',
                    '/vehicle/vin/:vin/discover-your-lincoln/:categoryName/:title/related-articles/',
                ],
                componentName: 'DyfRelatedArticlesView',
            },
            {
                path: '/maintenance-schedule',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap/',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: [
                    '/book-a-service',
                    '/book-a-service/',
                    '/book-a-service/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/book-a-service',
                componentName: 'OsbStartView',
            },
            {
                path: '/book-a-service/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/book-a-service/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/book-a-service/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/book-a-service/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/book-a-service/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/book-a-service/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/book-a-service/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/book-a-service/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/book-a-service/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/book-a-service/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/book-a-service/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/schedule-service',
                componentName: 'GxpCustomUIView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
            {
                path: '/lincoln-calm',
                componentName: 'LincolnCalmView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'fr-ch',
        routes: [
            {
                path: '/mises-a-jour-cartes-sync',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicule/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicule/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicule/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/tableau-de-bord-vehicule',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-tableau-de-bord-vehicule',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/verification-historique-entretien',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/etat-du-vehicule',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/verification-de-garantie',
                    '/verification-de-garantie/',
                    '/verification-de-garantie/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verification-de-garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verification-intervalles-entretien/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verification-intervalles-entretien',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/reservez-un-entretien-en-ligne',
                    '/reservez-un-entretien-en-ligne/',
                    '/reservez-un-entretien-en-ligne/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/reservez-un-entretien-en-ligne',
                componentName: 'OsbStartView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/récupération-code-accès',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/confirmation-annulation-réservation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/confirmation-réservation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/résumé-récupération-réservation',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/étape-récupération-réservation',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/révision-réservation',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/étape-jourheure',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/étape-agence',
                componentName: 'OsbDealerView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/étape-livraison',
                componentName: 'OsbDeliveryView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/étape-détails-personnels',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/étape-service',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calculez-votre-prix-de-service',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/voiture/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-voiture/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/instructions/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/instructions/recherche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/instructions/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/instructions/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/instructions/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rappels/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rappels/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rappels-de-produit-informations-detaillees/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuels-du-proprietaire/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicule/:model+/:year/manuels-du-proprietaire/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path:
                    '/manuels-du-proprietaire/manuels-du-proprietaire-plan-du-site/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manuels-du-proprietaire-detaillees/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-es',
        routes: [
            {
                path: '/actualizaciones-mapas-sync',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehiculo/bastidor/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehiculo/bastidor/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehiculo/bastidor/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/estado-del-vehiculo',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/panel-de-control-vehiculo',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-panel-de-control-vehiculo',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/explorar-mi-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/explorar-mi-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/comprobacion-historial-mantenimiento',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/comprobacion-garantia',
                    '/comprobacion-garantia/',
                    '/comprobacion-garantia/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/comprobacion-garantia',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/comprobacion-intervalos-mantenimiento/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/comprobacion-intervalos-mantenimiento',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/pide-tu-cita',
                    '/pide-tu-cita/',
                    '/pide-tu-cita/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/pide-tu-cita',
                componentName: 'OsbStartView',
            },
            {
                path: '/pide-tu-cita/reintentar-codigo-acceso',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/pide-tu-cita/cancelar-confirmación-reserva',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/pide-tu-cita/confirmación-reserva',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/pide-tu-cita/reintentar-resumen-reserva',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/pide-tu-cita/reintentar-reserva',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/pide-tu-cita/revisión-reserva',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/pide-tu-cita/fecha-hora',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/pide-tu-cita/concesion',
                componentName: 'OsbDealerView',
            },
            {
                path: '/pide-tu-cita/entrega',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/pide-tu-cita/detalles-personales',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/pide-tu-cita/servicios',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calcula-el-precio-del-servicio',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/coches/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-coches/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/guias/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/guias/buscando/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/guias/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/guias/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/guias/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/llamadas-a-revisión/',
                componentName: 'RecallsPage',
            },
            {
                path: '/llamadas-a-revisión/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/revision-detalles/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuales-del-usuario/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehículo/:model+/:year/manuales-del-usuario/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path:
                    '/manuales-del-usuario/mapa-del-sitio-de-los-manuales-del-usuario/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manuales-del-usuario-detalles/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'fr-fr',
        routes: [
            {
                path: '/mises-a-jour-cartes-sync',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicule/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicule/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicule/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/tableau-de-bord-vehicule',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-tableau-de-bord-vehicule',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/verification-historique-entretien',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/sante-du-vehicule',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/verification-de-garantie',
                    '/verification-de-garantie/',
                    '/verification-de-garantie/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verification-de-garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verification-intervalles-entretien/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verification-intervalles-entretien',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/reservez-un-entretien-en-ligne',
                    '/reservez-un-entretien-en-ligne/',
                    '/reservez-un-entretien-en-ligne/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/reservez-un-entretien-en-ligne',
                componentName: 'OsbStartView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/recuperation-code-acces',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/annulation-reservation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/confirmation-de-reservation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/consulter-modifier-reservation',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/etape-consulter-reservation',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path:
                    '/reservez-un-entretien-en-ligne/consulter-confirmer-reservation',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/etape-date-heure',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/etape-concessionnaire',
                componentName: 'OsbDealerView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/etape-livraison',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/etape-coordonnees',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/reservez-un-entretien-en-ligne/etape-service',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calculez-votre-prix-de-service',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/voiture/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-voiture/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/tutoriel/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/tutoriel/recherche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/tutoriel/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/tutoriel/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/tutoriel/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rappels/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rappels/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rappels-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuels-proprietaire/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicule/:model+/:year/manuels-proprietaire/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuels-proprietaire/manuel-proprietaire-plan-site/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manuels-proprietaire-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'it-it',
        routes: [
            {
                path: '/aggiornamenti-mappe-sync',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/veicolo/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/veicolo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-veicolo/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/dashboarveicolo',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-dashboarveicolo',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/scopri-la-tua-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/scopri-la-tua-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/verifica-libretto-di-manutenzione',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/stato-del-veicolo',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/verifica-garanzia',
                    '/verifica-garanzia/',
                    '/verifica-garanzia/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verifica-garanzia',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verifica-intervalli-di-manutenzione/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verifica-intervalli-di-manutenzione',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/prenota-un-servizio-online',
                    '/prenota-un-servizio-online/',
                    '/prenota-un-servizio-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/prenota-un-servizio-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/prenota-un-servizio-online/recupera-codice-accesso',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path:
                    '/prenota-un-servizio-online/conferma-prenotazione-cancellata',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/prenota-un-servizio-online/conferma-prenotazione',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/prenota-un-servizio-online/recupera-prenotazione-sommario',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/prenota-un-servizio-online/recupera-prenotazione',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/prenota-un-servizio-online/revisione-prenotazione',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-data-orario',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-centro-ford-service',
                componentName: 'OsbDealerView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-accettazione',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/prenota-un-servizio-online/dati-personali',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-servizi',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calcola-il-tuo-preventivo',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/auto/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-auto/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/come-fare/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/come-fare/cerca/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/come-fare/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/come-fare/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/come-fare/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/campagne-di-richiamo/',
                componentName: 'RecallsPage',
            },
            {
                path: '/campagne-di-richiamo/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/dettagli-richiamo/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuali-utente/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/veicolo/:model+/:year/manuali-utente/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuali-utente/mappa-del-sito-manuali-utente/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/dettagli-manuali-utente/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'pt-pt',
        routes: [
            {
                path: '/atualizacoes-mapas-sync',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/veiculo/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/veiculo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-veiculo/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/painel-do-veiculo',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-painel-do-veiculo',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/explorar-o-seu-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/explorar-o-seu-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/verificacao-do-historico-de-revisoes',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/estado-do-veiculo',
                componentName: 'VehicleHealthView',
            },
            {
                path: [
                    '/verificacao-da-garantia',
                    '/verificacao-da-garantia/',
                    '/verificacao-da-garantia/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verificacao-da-garantia',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verificacao-dos-intervalos-de-revisoes/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verificacao-dos-intervalos-de-revisoes',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/marcar-uma-revisao-online',
                    '/marcar-uma-revisao-online/',
                    '/marcar-uma-revisao-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/marcar-uma-revisao-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/marcar-uma-revisao-online/encontrar-codigo-reserva',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/marcar-uma-revisao-online/cancelar-confrimaáo-reserva',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/marcar-uma-revisao-online/confrimaçao-reserva',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/marcar-uma-revisao-online/encontrar-resumo-reserva',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/marcar-uma-revisao-online/encontrar-reserva',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/marcar-uma-revisao-online/revisao-reserva',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/marcar-uma-revisao-online/data-hora',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/marcar-uma-revisao-online/concessionario',
                componentName: 'OsbDealerView',
            },
            {
                path: '/marcar-uma-revisao-online/entrega',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/marcar-uma-revisao-online/informaçao-pessoal',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/marcar-uma-revisao-online/serviços',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calcular-o-preço-do-serviço',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/carros/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-carros/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/como/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/como/pesquisa/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/como/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/como/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/como/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/chamadas-à-oficina/',
                componentName: 'RecallsPage',
            },
            {
                path: '/chamadas-à-oficina/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/detalhes-lembretes/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuais-proprietario/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/veiculo/:model+/:year/manuais-proprietario/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuais-proprietario/proprietario-manuais-mapadosite/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/detalhes-manuais-proprietario/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-ie',
        routes: [
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/warranty-check',
                    '/warranty-check/',
                    '/warranty-check/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty-check',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/service-intervals-check/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/service-intervals-check',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/book-a-service',
                    '/book-a-service/',
                    '/book-a-service/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/book-a-service',
                componentName: 'OsbStartView',
            },
            {
                path: '/book-a-service/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/book-a-service/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/book-a-service/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/book-a-service/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/book-a-service/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/book-a-service/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/book-a-service/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/book-a-service/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/book-a-service/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/book-a-service/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/book-a-service/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calculate-your-service-price',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/cars/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-cars/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'no-no',
        routes: [
            {
                path: '/sync-kart-oppdateringer',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/bil/understellsnummer/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/bil/understellsnummer/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-bil/understellsnummer/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/bil-kontrollpanel',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-bil-kontrollpanel',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/utforsk-din-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/utforsk-din-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/servicehistorikk-sjekk',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/bilens-helsetilstand',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: ['/garantisjekk', '/garantisjekk/', '/garantisjekk/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garantisjekk',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/service-intervallsjekk/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/service-intervallsjekk',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/bestill-service-paa-nett',
                    '/bestill-service-paa-nett/',
                    '/bestill-service-paa-nett/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/bestill-service-paa-nett',
                componentName: 'OsbStartView',
            },
            {
                path: '/bestill-service-paa-nett/hent-bestillingskode',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/bestill-service-paa-nett/kansellering',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/bestill-service-paa-nett/bekreftelse',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/bestill-service-paa-nett/hent-oversikt',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/bestill-service-paa-nett/oversikt-booking',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/bestill-service-paa-nett/kontroll',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/bestill-service-paa-nett/dato-og-tid-valg',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/bestill-service-paa-nett/forhandler-valg',
                componentName: 'OsbDealerView',
            },
            {
                path: '/bestill-service-paa-nett/leverings-valg',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/bestill-service-paa-nett/kontaktinformasjon',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/bestill-service-paa-nett/service-valg',
                componentName: 'OsbServicesView',
            },
            {
                path: '/bestill-service-paa-nett/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/sjekk-servicepriser',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/biler/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-biler/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/slik-gjoer-du-det/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/slik-gjoer-du-det/soek/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/slik-gjoer-du-det/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/slik-gjoer-du-det/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/slik-gjoer-du-det/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/tilbakekallinger/',
                componentName: 'RecallsPage',
            },
            {
                path: '/tilbakekallinger/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/tilbakekallingsdetaljer/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/instruksjonsbok/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/bil/:model+/:year/instruksjonsbok/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/instruksjonsbok/instruksjonsbok-nettstedskart/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/instruksjonsbokdetaljer/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'pl-pl',
        routes: [
            {
                path: '/aktualizacje-map-i-sync',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/pojazd/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/pojazd/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-pojazd/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/stan-pojazdu',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/deska-rozdzielcza-pojazdu',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-deska-rozdzielcza-pojazdu',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/poznaj-swojego-forda/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/poznaj-swojego-forda/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/kontrola-historii-serwisowania',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/kontrola-gwarancji',
                    '/kontrola-gwarancji/',
                    '/kontrola-gwarancji/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/kontrola-gwarancji',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/kontrola-okresu-serwisowania/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/kontrola-okresu-serwisowania',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/zarezerwuj-serwis-online',
                    '/zarezerwuj-serwis-online/',
                    '/zarezerwuj-serwis-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/zarezerwuj-serwis-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/zarezerwuj-serwis-online/kod-dostepu',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/zarezerwuj-serwis-online/anuluj-rezerwacje',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/zarezerwuj-serwis-online/potwierdzenie-rezerwacji',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/zarezerwuj-serwis-online/pobierz-podsumowanie',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path:
                    '/zarezerwuj-serwis-online/pobierz-potwierdzenie-rezerwacji',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/zarezerwuj-serwis-online/podsumowanie-rezerwacji',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/zarezerwuj-serwis-online/wybierz-termin',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/zarezerwuj-serwis-online/znajdz-dealera',
                componentName: 'OsbDealerView',
            },
            {
                path: '/zarezerwuj-serwis-online/dostarczenie-pojazdu',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/zarezerwuj-serwis-online/podaj-swoje-dane',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/zarezerwuj-serwis-online/wybierz-usluge',
                componentName: 'OsbServicesView',
            },
            {
                path: '/zarezerwuj-serwis-online/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/przelicz-cene-serwisu',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/samochody/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-samochody/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/instrukcje-wideo/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/instrukcje-wideo/szukaj/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/instrukcje-wideo/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/instrukcje-wideo/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/instrukcje-wideo/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/akcje-serwisowe/',
                componentName: 'RecallsPage',
            },
            {
                path: '/akcje-serwisowe/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/szczegóły-wycofania/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/podreczniki-uzytkownika/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/pojazd/:model+/:year/podreczniki-uzytkownika/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path:
                    '/podreczniki-uzytkownika/podreczniki-uzytkownika-mapa-strony/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/szczegóły-podreczniki-uzytkownika/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'el-gr',
        routes: [
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/service-history-check',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/warranty-check',
                    '/warranty-check/',
                    '/warranty-check/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty-check',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/service-intervals-check/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/service-intervals-check',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/book-a-service-online',
                    '/book-a-service-online/',
                    '/book-a-service-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/book-a-service-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/book-a-service-online/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/book-a-service-online/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/book-a-service-online/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/book-a-service-online/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/book-a-service-online/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/book-a-service-online/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/book-a-service-online/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/book-a-service-online/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/book-a-service-online/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/book-a-service-online/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/book-a-service-online/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/book-a-service-online/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/calculate-your-service-price',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/cars/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-cars/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'fi-fi',
        routes: [
            {
                path: '/sync-maps-paivitykset',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/ajoneuvo/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/ajoneuvo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-ajoneuvo/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/ajoneuvon-kojelauta',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-ajoneuvon-kojelauta',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/tutustu-fordiisi/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/tutustu-fordiisi/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/palveluhistorian-tarkistus',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/ajoneuvon-kunto',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/takuun-tarkistus',
                    '/takuun-tarkistus/',
                    '/takuun-tarkistus/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/takuun-tarkistus',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/huoltovalin-tarkistus/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/huoltovalin-tarkistus',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/varaa-aika-netissa',
                    '/varaa-aika-netissa/',
                    '/varaa-aika-netissa/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/varaa-aika-netissa',
                componentName: 'OsbStartView',
            },
            {
                path: '/varaa-aika-netissa/palauta-paasykoodi',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/varaa-aika-netissa/peruuta-varaukseni',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/varaa-aika-netissa/varauksen-hyvaksynta',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/varaa-aika-netissa/palauta-varauksen-yhteenveto',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/varaa-aika-netissa/palauta-varaus-vaihe',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/varaa-aika-netissa/tarkista-varaus',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/varaa-aika-netissa/ajankohta-vaihe',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/varaa-aika-netissa/jalleenmyyja-vaihe',
                componentName: 'OsbDealerView',
            },
            {
                path: '/varaa-aika-netissa/toimitus-vaihe',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/varaa-aika-netissa/henkilotiedot-vaihe',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/varaa-aika-netissa/palvelut-vaihe',
                componentName: 'OsbServicesView',
            },
            {
                path: '/varaa-aika-netissa/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/laske-huollon-hinta',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/autot/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-autot/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/ohjeet/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/ohjeet/haku/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/ohjeet/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/ohjeet/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/ohjeet/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/takaisinkutsut/',
                componentName: 'RecallsPage',
            },
            {
                path: '/takaisinkutsut/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/takaisinkutsut-yksityiskohdat/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/omistaja-kayttoohjekirjat/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/ajoneuvo/:model+/:year/omistaja-kayttoohjekirjat/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path:
                    '/omistaja-kayttoohjekirjat/omistaja-kayttoohjekirjat-sivukartta/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/omistaja-kayttoohjekirjat-yksityiskohdat/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'ro-ro',
        routes: [
            {
                path: '/sincronizare-harti-actualizari',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicul/serie-de-sasiu/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicul/serie-de-sasiu/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicul/serie-de-sasiu/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/panou-de-bord',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-panou-de-bord',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/verificare-istoric-revizii',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: [
                    '/verificare-garantie',
                    '/verificare-garantie/',
                    '/verificare-garantie/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verificare-garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verificare-intervale-revizii/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verificare-intervale-revizii',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/programare-service-online',
                    '/programare-service-online/',
                    '/programare-service-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/programare-service-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/programare-service-online/recuperare-cod-de-acces',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path:
                    '/programare-service-online/anulare-confirmare-programare',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/programare-service-online/confirmare-programare',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/programare-service-online/anulare-programare-sumar',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/programare-service-online/anulare-programare',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/programare-service-online/verificare-programare',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/programare-service-online/selectare-data-si-ora',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/programare-service-online/selectare-reprezentanta',
                componentName: 'OsbDealerView',
            },
            {
                path: '/programare-service-online/selectare-livrare',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/programare-service-online/date-personale',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/programare-service-online/selectare-serviciu',
                componentName: 'OsbServicesView',
            },
            {
                path: '/programare-service-online/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/calculator-operatiuni-service',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/autovehicule/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-autovehicule/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/cum-sa/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/cum-sa/cautare/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/cum-sa/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/cum-sa/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/cum-sa/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rechemări/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rechemări/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/detalii-rechemari/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuale-proprietar/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicul/:model+/:year/manuale-proprietar/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuale-proprietar/hartasite-manuale-proprietar/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/detalii-manuale-proprietar/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'fr-be',
        routes: [
            {
                path: '/mises-a-jour-sync-maps',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicule/numero-de-chassis/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicule/numero-de-chassis/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicule/numero-de-chassis/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/etat-du-vehicule',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/tableau-de-bord-vehicule',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-tableau-de-bord-vehicule',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/decouvrir-votre-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/decouvrir-votre-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/verification-historique-entretiens',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/verification-garantie',
                    '/verification-garantie/',
                    '/verification-garantie/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verification-garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verification-intervalles-entretiens/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verification-intervalles-entretiens',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/reserver-un-entretien-en-ligne',
                    '/reserver-un-entretien-en-ligne/',
                    '/reserver-un-entretien-en-ligne/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/reserver-un-entretien-en-ligne',
                componentName: 'OsbStartView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/recuperation-code-acces',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/annulation-reservation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path:
                    '/reserver-un-entretien-en-ligne/confirmation-de-reservation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/reserver-un-entretien-en-ligne/consulter-modifier-reservation',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path:
                    '/reserver-un-entretien-en-ligne/etape-consulter-reservation',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path:
                    '/reserver-un-entretien-en-ligne/consulter-confirmer-reservation',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/etape-date-heure',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/etape-distributeur',
                componentName: 'OsbDealerView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/etape-livraison',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/etape-coordonnees',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/etape-service',
                componentName: 'OsbServicesView',
            },
            {
                path: '/reserver-un-entretien-en-ligne/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/calculez-votre-prix-de-service',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/voitures/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-voitures/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/instructions/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/instructions/recherche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/instructions/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/instructions/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/instructions/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rappels/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rappels/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rappels-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuels-proprietaire/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicule/:model+/:year/manuels-proprietaire/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuels-proprietaire/manuel-proprietaire-plan-site/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manuels-proprietaire-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'nl-be',
        routes: [
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/voertuig/chassisnummer/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/voertuig/chassisnummer/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-voertuig/chassisnummer/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/voertuiggezondheid',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/voertuig-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-voertuig-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/verken-uw-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/verken-uw-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/onderhougeschiedenis-controle',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/garantie-controle',
                    '/garantie-controle/',
                    '/garantie-controle/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garantie-controle',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/onderhouintervals-controle/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/onderhouintervals-controle',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/boek-een-onderhoudonline',
                    '/boek-een-onderhoudonline/',
                    '/boek-een-onderhoudonline/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/boek-een-onderhoudonline',
                componentName: 'OsbStartView',
            },
            {
                path: '/boek-een-onderhoudonline/opzoeken-toegangscode',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/boek-een-onderhoudonline/annuleren-reservering',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/boek-een-onderhoudonline/bevestiging-reservering',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/boek-een-onderhoudonline/bekijken-reservering-overzicht',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/boek-een-onderhoudonline/bekijken-reservering-stap',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/boek-een-onderhoudonline/nakijken-reservering',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/boek-een-onderhoudonline/datum-uur-stap',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/boek-een-onderhoudonline/verdeler-stap',
                componentName: 'OsbDealerView',
            },
            {
                path: '/boek-een-onderhoudonline/levering-stap',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/boek-een-onderhoudonline/persoonlijke-gegevens-stap',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/boek-een-onderhoudonline/service-stap',
                componentName: 'OsbServicesView',
            },
            {
                path: '/boek-een-onderhoudonline/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/bereken-uw-service-prijs',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/personenwagens/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-personenwagens/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/hoe/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/hoe/zoek/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/hoe/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/hoe/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/hoe/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/terugroepacties/',
                componentName: 'RecallsPage',
            },
            {
                path: '/terugroepacties/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/terugroepacties-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/gebruikershandleidingen/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/voertuig/:model+/:year/gebruikershandleidingen/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path:
                    '/gebruikershandleidingen/gebruikershandleidingen-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/gebruikershandleidingen-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'da-dk',
        routes: [
            {
                path: '/sync-kort-opdateringer',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/bil/chassisnummer/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/bil/chassisnummer/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-bil/chassisnummer/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/din-bils-status',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/bil-instrumentbraet',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-bil-instrumentbraet',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/udforsk-din-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/udforsk-din-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/service-historik-kontrol',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/garanti-kontrol',
                    '/garanti-kontrol/',
                    '/garanti-kontrol/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garanti-kontrol',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/service-interval-kontrol/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/service-interval-kontrol',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/book-service-online',
                    '/book-service-online/',
                    '/book-service-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/book-service-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/book-service-online/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/book-service-online/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/book-service-online/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/book-service-online/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/book-service-online/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/book-service-online/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/book-service-online/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/book-service-online/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/book-service-online/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/book-service-online/location-step',
                componentName: 'OsbServiceLocationView',
            },
            {
                path: '/book-service-online/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/book-service-online/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calculate-your-service-price',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/biler/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-biler/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/goer-det-selv/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/goer-det-selv/soeg/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/goer-det-selv/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/goer-det-selv/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/goer-det-selv/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/tilbagekaldelser/',
                componentName: 'RecallsPage',
            },
            {
                path: '/tilbagekaldelser/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/tilbagekaldelser-detaljer/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/instruktionsboeger/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/bil/:model+/:year/instruktionsboeger/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/instruktionsboeger/instruktionsboeger-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/instruktionsboeger-detaljer/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'hu-hu',
        routes: [
            {
                path: '/sync-terkep-frissitesek',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/jarmu/alvazszam/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/jarmu/alvazszam/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-jarmu/alvazszam/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/jarmu-kezelofelulet',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-jarmu-kezelofelulet',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/fedezze-fel-fordjat/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/fedezze-fel-fordjat/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/szerviz-tortenet-ellenorzes',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/garancia-ellenorzes',
                    '/garancia-ellenorzes/',
                    '/garancia-ellenorzes/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garancia-ellenorzes',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/karbantartasi-utemterv/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/karbantartasi-utemterv',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/online-szerviz-bejelentkezes',
                    '/online-szerviz-bejelentkezes/',
                    '/online-szerviz-bejelentkezes/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/online-szerviz-bejelentkezes',
                componentName: 'OsbStartView',
            },
            {
                path:
                    '/online-szerviz-bejelentkezes/hozzaferesi-kod-lekerdezes',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path:
                    '/online-szerviz-bejelentkezes/foglalas-jovahagyas-torles',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/online-szerviz-bejelentkezes/foglalas-visszaigazolas',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/online-szerviz-bejelentkezes/foglalas-lekerdezes-osszefoglalo',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/online-szerviz-bejelentkezes/foglalas-lekerdezes-lepes',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/online-szerviz-bejelentkezes/foglalas-attekintese',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/online-szerviz-bejelentkezes/idopont-valasztas',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/online-szerviz-bejelentkezes/markaszerviz-valasztas',
                componentName: 'OsbDealerView',
            },
            {
                path:
                    '/online-szerviz-bejelentkezes/kenyelmi-szolgáltatas-valasztas',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/online-szerviz-bejelentkezes/szemelyes-adatok',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/online-szerviz-bejelentkezes/szolgaltatas-valasztas',
                componentName: 'OsbServicesView',
            },
            {
                path: '/online-szerviz-bejelentkezes/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/szervizdij-kalkulator',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/modellek/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-modellek/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/bemutato-videok/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/használati-utasítás/keresés/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/használati-utasítás/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/használati-utasítás/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/használati-utasítás/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/visszahívások/',
                componentName: 'RecallsPage',
            },
            {
                path: '/visszahívások/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/visszahivas-reszletek/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/felhasznaloi-kezikonyv/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/jarmu/:model+/:year/felhasznaloi-kezikonyv/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path:
                    '/felhasznaloi-kezikonyv/felhasznaloi-kezikonyv-oldalterkep/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/felhasznaloi-kezikonyv-reszletek/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'cs-cz',
        routes: [
            {
                path: '/sync-mapy-aktualizace',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vozidlo/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vozidlo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vozidlo/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/stav-vozidla',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/prehled',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-prehled',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/poznejte-svuj-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/poznejte-svuj-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/kontrola-servisni-historie',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/kontrola-zaruky',
                    '/kontrola-zaruky/',
                    '/kontrola-zaruky/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/kontrola-zaruky',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/kontrola-servisnich-intervalu/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/kontrola-servisnich-intervalu',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/rezervovat-servis-online',
                    '/rezervovat-servis-online/',
                    '/rezervovat-servis-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/rezervovat-servis-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/rezervovat-servis-online/zobrazit-pristupovy-kod',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/rezervovat-servis-online/zrusit-potvrzenou-rezervaci',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/rezervovat-servis-online/potvrzeni-rezervace',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/rezervovat-servis-online/zobrazit-shrnuti-rezervace',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/rezervovat-servis-online/zobrazit-rezervaci',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/rezervovat-servis-online/kontrola-rezervace',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/rezervovat-servis-online/datum-a-cas-krok',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/rezervovat-servis-online/dealer-krok',
                componentName: 'OsbDealerView',
            },
            {
                path: '/rezervovat-servis-online/doruceni-krok',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/rezervovat-servis-online/osobni-udaje-krok',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/rezervovat-servis-online/servis-krok',
                componentName: 'OsbServicesView',
            },
            {
                path: '/rezervovat-servis-online/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/spocitat-cenu-servisu',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/automobily/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-automobily/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/napoveda/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/napoveda/vyhledat/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/napoveda/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/napoveda/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/napoveda/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/svolávací-akce/',
                componentName: 'RecallsPage',
            },
            {
                path: '/svolávací-akce/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/podrobnosti-svolávacích-akcí/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/navody-k-obsluze/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vozidlo/:model+/:year/navody-k-obsluze/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/navody-k-obsluze/mapa-stranek-navodu-k-obsluze/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/podrobnosti-navody-k-obsluze/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'nl-nl',
        routes: [
            {
                path: '/sync-en-maps-software-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/voertuig/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/voertuig/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-voertuig/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/voertuigstatus',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/auto-dashboarondersteuning',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-auto-dashboarondersteuning',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/ontdek-jouw-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/ontdek-jouw-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/onderhoudsgeschiedenis',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/garantie-controle',
                    '/garantie-controle/',
                    '/garantie-controle/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/garantie-controle',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/onderhoudsschema/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/onderhoudsschema',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/boek-een-onderhoudonline',
                    '/boek-een-onderhoudonline/',
                    '/boek-een-onderhoudonline/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/boek-een-onderhoudonline',
                componentName: 'OsbStartView',
            },
            {
                path: '/boek-een-onderhoudonline/opzoeken-toegangscode',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/boek-een-onderhoudonline/annuleren-reservering',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/boek-een-onderhoudonline/bevestiging-reservering',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/boek-een-onderhoudonline/bekijken-reservering-overzicht',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/boek-een-onderhoudonline/bekijken-reservering-stap',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/boek-een-onderhoudonline/nakijken-reservering',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/boek-een-onderhoudonline/datum-uur-stap',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/boek-een-onderhoudonline/fordservicepartner',
                componentName: 'OsbDealerView',
            },
            {
                path: '/boek-een-onderhoudonline/levering-stap',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/boek-een-onderhoudonline/persoonlijke-gegevens-stap',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/boek-een-onderhoudonline/service-stap',
                componentName: 'OsbServicesView',
            },
            {
                path: '/boek-een-onderhoudonline/vehicle-step',
                componentName: 'OsbVehicleView',
            },
            {
                path: '/bereken-uw-service-prijs',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/autos/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-autos/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/hoe/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/hoe/zoek/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/hoe/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/hoe/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/hoe/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/terugroepingen/',
                componentName: 'RecallsPage',
            },
            {
                path: '/terugroepingen/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/terugroepacties-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/instructieboekjes/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/voertuig/:model+/:year/instructieboekjes/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/instructieboekjes/instructieboekjes-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/instructieboekjes-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'fr-lu',
        routes: [
            {
                path: '/mises-a-jour-sync-maps',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicule/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicule/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicule/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/etat-du-vehicule',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/tableau-de-bord-vehicule',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-tableau-de-bord-vehicule',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/verification-historique-entretien',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/verification-de-garantie',
                    '/verification-de-garantie/',
                    '/verification-de-garantie/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verification-de-garantie',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verification-intervalles-entretien/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verification-intervalles-entretien',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/voiture/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-voiture/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/instructions/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/instructions/recherche/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/instructions/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/instructions/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/instructions/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/rappels/',
                componentName: 'RecallsPage',
            },
            {
                path: '/rappels/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/rappels-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuels-proprietaire/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicule/:model+/:year/manuels-proprietaire/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuels-proprietaire/manuel-proprietaire-plan-site/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manuels-proprietaire-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'it-ch',
        routes: [
            {
                path: '/aggiornamento-di-sync-e-maps',

                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/veicolo/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/stato-del-veicolo',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/veicolo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-veicolo/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/dashboarveicolo',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-dashboarveicolo',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/scopri-la-tua-ford/create-checklist',
                componentName: 'CreatChecklistRoute',
            },
            {
                path: '/scopri-la-tua-ford/send-to-dealer',
                componentName: 'SendToDealerRoute',
            },
            {
                path: '/verifica-libretto-di-manutenzione',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/verifica-garanzia',
                    '/verifica-garanzia/',
                    '/verifica-garanzia/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/verifica-garanzia',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/verifica-intervalli-di-manutenzione/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/verifica-intervalli-di-manutenzione',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/prenota-un-servizio-online',
                    '/prenota-un-servizio-online/',
                    '/prenota-un-servizio-online/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/prenota-un-servizio-online',
                componentName: 'OsbStartView',
            },
            {
                path: '/prenota-un-servizio-online/recupera-codice-accesso',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path:
                    '/prenota-un-servizio-online/conferma-prenotazione-cancellata',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/prenota-un-servizio-online/conferma-prenotazione',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path:
                    '/prenota-un-servizio-online/recupera-prenotazione-sommario',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/prenota-un-servizio-online/recupera-prenotazione',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/prenota-un-servizio-online/revisione-prenotazione',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-data-orario',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-centro-ford-service',
                componentName: 'OsbDealerView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-accettazione',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/prenota-un-servizio-online/dati-personali',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/prenota-un-servizio-online/scelta-servizi',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calcola-il-tuo-preventivo',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/auto/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-auto/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/istruzioni/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/istruzioni/cerca/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/istruzioni/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/istruzioni/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/istruzioni/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/controlli-di-sicurezza/',
                componentName: 'RecallsPage',
            },
            {
                path: '/controlli-di-sicurezza/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/dettagli-richiami/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manuali-utente/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/veicolo/:model+/:year/manuali-utente/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manuali-utente/mappa-del-sito-manuali-utente/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/dettagli-manuali-utente/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-gb',
        routes: [
            {
                path: '/collision-assistance',
                componentName: 'CollisionAssistanceView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/service-history-check',
                componentName: 'ServiceHistoryView',
            },
            {
                path: [
                    '/warranty-check',
                    '/warranty-check/',
                    '/warranty-check/:vin',
                ],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty-check',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: [
                    '/rewards',
                    '/fordpass/fordpass-rewards',
                    '/lincoln-way-app/lincoln-access-rewards',
                ],
                componentName: 'RewardsViewHandler',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/coming-soon/',
                componentName: 'ComingSoonPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: [
                    '/vehicle/:model/:year/discover-your-ford/livestream',
                    '/vehicle/vin/:vin/discover-your-ford/livestream',
                ],
                componentName: 'LiveStreamView',
            },
            {
                path: ['/fordpass', '/lincoln-way'],
                componentName: 'DeviceAwareNaEuView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: '/service-intervals-check/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/service-intervals-check',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: [
                    '/book-a-service',
                    '/book-a-service/',
                    '/book-a-service/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/book-a-service',
                componentName: 'OsbStartView',
            },
            {
                path: '/book-a-service/retrieve-access-code',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/book-a-service/cancel-booking-confirmation',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/book-a-service/booking-confirmation',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/book-a-service/retrieve-booking-summary',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/book-a-service/retrieve-booking-step',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/book-a-service/review-booking',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/book-a-service/datetime-step',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/book-a-service/dealer-step',
                componentName: 'OsbDealerView',
            },
            {
                path: '/book-a-service/delivery-step',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/book-a-service/location-step',
                componentName: 'OsbServiceLocationView',
            },
            {
                path: '/book-a-service/personal-details-step',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/book-a-service/services-step',
                componentName: 'OsbServicesView',
            },
            {
                path: '/calculate-your-service-price',
                componentName: 'ServicePriceCalculatorView',
            },
            {
                path: '/cars/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-cars/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/:howtos/error-page',
                componentName: 'RoadBlockPage',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/service-information',
                componentName: 'TSBView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
            {
                path: '/service-information',
                componentName: 'TSBView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-au',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: [
                    '/recalls-details/:vin?/',
                    '/takata-airbag-recalls-details/:vin?/',
                ],
                componentName: 'RecallsDetailsPage',
            },
            {
                path: ['/recalls/', '/takata-airbag-recalls/'],
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/discover-your-ford',
                componentName: 'CxHomePage',
            },
            {
                path: '/discover-your-ford/vehicle/:model/:year',
                componentName: 'CxHandler',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/ford-rides',
                componentName: 'FordRidesView',
            },
            {
                path: '/communication-preferences',
                componentName: 'PreferenceCentreView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'th-th',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/discover-your-ford',
                componentName: 'CxHomePage',
            },
            {
                path: '/discover-your-ford/vehicle/:model/:year',
                componentName: 'CxHandler',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/extended-service-business',
                componentName: 'ExtendedServiceBusinessRoute',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-th',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/extended-service-business',
                componentName: 'ExtendedServiceBusinessRoute',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-cl',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-co',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/maintenance-schedule/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/maintenance-schedule',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-nz',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/discover-your-ford',
                componentName: 'CxHomePage',
            },
            {
                path: '/discover-your-ford/vehicle/:model/:year',
                componentName: 'CxHandler',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/ford-rides',
                componentName: 'FordRidesView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-mx',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehiculo/vin/no-encontrado',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehiculo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehiculo/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehiculo/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehiculo/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: [
                    '/cita-de-servicio',
                    '/cita-de-servicio/',
                    '/cita-de-servicio/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/cita-de-servicio',
                componentName: 'OsbStartView',
            },
            {
                path: '/cita-de-servicio/reintentar-codigo-acceso',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/cita-de-servicio/cancelar-confirmación-reserva',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/cita-de-servicio/confirmación-reserva',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/cita-de-servicio/reintentar-resumen-reserva',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/cita-de-servicio/reintentar-reserva',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/cita-de-servicio/revisión-reserva',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/cita-de-servicio/fecha-hora',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/cita-de-servicio/distribuidor',
                componentName: 'OsbDealerView',
            },
            {
                path: '/cita-de-servicio/entrega',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/cita-de-servicio/Ubicación-paso',
                componentName: 'OsbServiceLocationView',
            },
            {
                path: '/cita-de-servicio/detalles-personales',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/cita-de-servicio/servicios',
                componentName: 'OsbServicesView',
            },
            {
                path: '/salud-del-vehiculo',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/salud-del-vehiculo/vida-del-aceite',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/salud-del-vehiculo/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: [
                    '/salud-del-vehiculo/tren-motriz',
                    '/salud-del-vehiculo/frenos-y-suspension',
                    '/salud-del-vehiculo/fluidos-y-filtros',
                    '/salud-del-vehiculo/retenciones-y-asistencias-de-manejo',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/llamado-revision/',
                componentName: 'RecallsPage',
            },
            {
                path: '/llamado-revision/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/llamado-revision-detalle/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manual-propietario/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehiculo/:model+/:year/manual-propietario/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manual-propietario/manual-propietario-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manual-propietario-detalle/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/actualizacion-mapas-sync',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/categoria/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: [
            'en-ag',
            'en-aw',
            'en-bs',
            'en-bb',
            'en-bm',
            'en-ky',
            'en-cw',
            'en-dm',
            'en-gd',
            'en-ht',
            'en-jm',
            'en-lc',
            'en-mf',
            'en-sr',
            'en-tt',
        ],
        routes: [
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-do',
        routes: [
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'lincoln',
        locale: 'es-mx',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/vehiculo/vin/no-encontrado',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehiculo/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/vehiculo/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePage',
            },
            {
                path: [
                    '/cita-de-servicio',
                    '/cita-de-servicio/',
                    '/cita-de-servicio/:view',
                ],
                componentName: 'OsbWrapper',
            },
            {
                path: '/cita-de-servicio',
                componentName: 'OsbStartView',
            },
            {
                path: '/cita-de-servicio/reintentar-codigo-acceso',
                componentName: 'OsbBookingAccessCodeView',
            },
            {
                path: '/cita-de-servicio/cancelar-confirmación-reserva',
                componentName: 'OsbBookingCancelView',
            },
            {
                path: '/cita-de-servicio/confirmación-reserva',
                componentName: 'OsbBookingConfirmationView',
            },
            {
                path: '/cita-de-servicio/reintentar-resumen-reserva',
                componentName: 'OsbBookingRetrieveSummaryView',
            },
            {
                path: '/cita-de-servicio/reintentar-reserva',
                componentName: 'OsbBookingRetrieveView',
            },
            {
                path: '/cita-de-servicio/revisión-reserva',
                componentName: 'OsbBookingReviewView',
            },
            {
                path: '/cita-de-servicio/fecha-hora',
                componentName: 'OsbCalenderView',
            },
            {
                path: '/cita-de-servicio/concesión',
                componentName: 'OsbDealerView',
            },
            {
                path: '/cita-de-servicio/entrega',
                componentName: 'OsbDeliveryView',
            },
            {
                path: '/cita-de-servicio/detalles-personales',
                componentName: 'OsbPersonalDetailsView',
            },
            {
                path: '/cita-de-servicio/servicios',
                componentName: 'OsbServicesView',
            },
            {
                path: '/llamado-revision/',
                componentName: 'RecallsPage',
            },
            {
                path: '/llamado-revision/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/llamado-revision-detalle/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/manual-propietario/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehiculo/:model+/:year/manual-propietario/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/manual-propietario/manual-propietario-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/manual-propietario-detalle/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/actualizacion-mapas-sync',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'pt-br',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-ar',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'es-pe',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'sv-se',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/service-history-check',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/service-intervals-check/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/service-intervals-check',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: ['es-pr', 'en-pr'],
        routes: [
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'lincoln',
        locale: ['en-pr', 'es-pr'],
        routes: [
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePage',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },

    {
        brand: 'ford',
        locale: 'es-ve',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-za',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/discover-your-ford',
                componentName: 'CxHomePage',
            },
            {
                path: '/discover-your-ford/vehicle/:model/:year',
                componentName: 'CxHandler',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/extended-service-business',
                componentName: 'ExtendedServiceBusinessRoute',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },
            {
                path: '/ford-rides',
                componentName: 'FordRidesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: ['es-bz', 'es-cr', 'es-sv', 'es-gt', 'es-hn', 'es-ni', 'es-pa'],
        routes: [
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/fm-vehicle/vin/:vin',
                componentName: 'FMVehicleVinView',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/fm-vehicle/:model/:year',
                componentName: 'FMVehicleYmmView',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'vi-vn',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },
            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/discover-your-ford',
                componentName: 'CxHomePage',
            },
            {
                path: '/discover-your-ford/vehicle/:model/:year',
                componentName: 'CxHandler',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'en-ph',
        routes: [
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: '/fm-vehicle-dashboard',
                componentName: 'FMVehicleDashboardView',
            },
            {
                path: [
                    '/recalls-details/:vin?/',
                    '/takata-airbag-recalls-details/:vin?/',
                ],
                componentName: 'RecallsDetailsPage',
            },
            {
                path: ['/recalls/', '/takata-airbag-recalls/'],
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePreimagine',
            },
            {
                path: '/fm-home-page',
                componentName: 'FMHomePage',
            },
            {
                path: '/discover-your-ford',
                componentName: 'CxHomePage',
            },
            {
                path: '/discover-your-ford/vehicle/:model/:year',
                componentName: 'CxHandler',
            },
            {
                path: '/how-tos/sitemap',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
        ],
    },
    {
        brand: 'lincoln',
        locale: ['en-ca', 'fr-ca'],
        routes: [
            {
                path: '/service-information',
                componentName: 'TSBView',
            },
            {
                path: '/fordpass',
                componentName: 'DeviceAwareNaEuView',
            },
            {
                path: '/vehicle/vin/not-found',
                componentName: 'VehicleNotFoundView',
            },
            {
                path: '/vehicle-health',
                componentName: 'VehicleHealthView',
            },
            {
                path: '/vehicle-health/oil-life-details',
                componentName: 'OilLifeDetailsView',
            },
            {
                path: '/vehicle-health/service-reminder',
                componentName: 'ServiceRemindersDetailsView',
            },
            {
                path: '/vehicle-health/slow-tire-leak-details',
                componentName: 'SlowTireLeakDetailsView',
            },
            {
                path: [
                    '/vehicle-health/powertrain',
                    '/vehicle-health/brakes-suspensions',
                    '/vehicle-health/fluids-filters',
                    '/vehicle-health/restraints-driver-assistance',
                ],
                componentName: 'DiagnosticsDetailsView',
            },
            {
                path: '/vehicle/vin/:vin',
                componentName: 'VehicleVinView',
            },
            {
                path: '/vehicle-dashboard',
                componentName: 'VehicleDashboardView',
            },
            {
                path: ['/warranty', '/warranty/', '/warranty/:vin'],
                componentName: 'WarrantyWrapper',
            },
            {
                path: '/warranty',
                componentName: 'WarrantyInformationViewRoute',
            },
            {
                path: '/service-history',
                componentName: 'ServiceHistoryView',
            },

            {
                path: [
                    '/service-maintenance/vehicle-health-reports/:vin',
                    '/service-maintenance/vehicle-health-reports',
                ],
                componentName: 'VehicleHealthReportView',
            },
            {
                path: [
                    '/rewards',
                    '/fordpass/fordpass-rewards',
                    '/lincoln-way-app/lincoln-access-rewards',
                ],
                componentName: 'RewardsViewHandler',
            },
            {
                path: '/recalls-details/:vin?/',
                componentName: 'RecallsDetailsPage',
            },

            {
                path: '/recalls/',
                componentName: 'RecallsPage',
            },
            {
                path: '/recalls/:vin/',
                componentName: 'RecallsWithVin',
            },
            {
                path: '/owner-manuals/',
                componentName: 'OwnerManualPage',
            },
            {
                path: '/vehicle/:model+/:year/owner-manuals/',
                componentName: 'OwnerManualDetailsPage',
            },
            {
                path: '/owner-manuals/owner-manuals-sitemap/',
                componentName: 'OwnerManualSitemapPage',
            },
            {
                path: '/owner-manuals-details/',
                componentName: 'OwnerManualWithVin',
            },
            {
                path: '/',
                componentName: 'HomePage',
            },
            {
                path: '/how-to-videos/',
                componentName: 'HowToVideoView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/`,
                componentName: 'HTVVideoLibraryView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/:videoId(${ROUTE_REGEX['videoIdOrName']})/`,
                componentName: 'HTVVideoDetailView',
            },
            {
                path: `/vehicle/:model(${ROUTE_REGEX['vehicleName']})/:year(${ROUTE_REGEX['year']})/how-to-videos/video-library/:categoryName(${ROUTE_REGEX['categoryName']})/`,
                componentName: 'HTVCategoryLibrary',
            },
            {
                path: '/how-to-videos/video-sitemap/',
                componentName: 'SiteMapView',
            },
            {
                path: [
                    `/vehicle/vin/:vin/discover-your-lincoln/`,
                    `/vehicle/:model/:year/discover-your-lincoln/`,
                ],
                componentName: 'DyfLandingView',
            },
            {
                path: [
                    `/vehicle/vin/:vin/discover-your-lincoln/:title/overview/`,
                    `/vehicle/vin/:vin/discover-your-lincoln/:categoryName/:title/overview/`,
                ],
                componentName: 'DyfFeatureArticleOverview',
            },
            {
                path: [
                    '/vehicle/vin/:vin/discover-your-lincoln/:title/related-articles/',
                    '/vehicle/vin/:vin/discover-your-lincoln/:categoryName/:title/related-articles/',
                ],
                componentName: 'DyfRelatedArticlesView',
            },
            {
                path: '/maintenance-schedule/:vin',
                componentName: 'MaintenanceScheduleView',
            },
            {
                path: '/maintenance-schedule',
                componentName: 'MaintenanceScheduleLandingView',
            },
            {
                path: '/category/*',
                componentName: 'CategoryHandler',
            },
            {
                path: '/vehicle/:model/:year',
                componentName: 'VehicleYmmView',
            },
            {
                path: '/sitemap/',
                componentName: 'HtmlSiteMap',
            },
            {
                path: '/how-tos/sitemap/',
                componentName: 'AstuteSiteMap',
            },
            {
                path: '/how-tos/search/:searchterm',
                componentName: 'AstuteSearch',
            },
            {
                path: '/how-tos/:category',
                componentName: 'CategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory',
                componentName: 'SubCategoryPageView',
            },
            {
                path: '/how-tos/:category/:subcategory/:topic',
                componentName: 'AstuteTopic',
            },
            {
                path: '/sync-maps-updates',
                componentName: 'SyncMapsUpdatesView',
            },
            {
                path: '*',
                componentName: 'PageNotFoundView',
            },
            {
                path: '/category/fordpass/checklist-to-connect-trailer',
                componentName: 'TrailerChecklistView',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-conventional-trailer-checklist',
                componentName: 'TrailerChecklistConventionalTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-gooseneck-trailer-checklist',
                componentName: 'TrailerChecklistGooseneckTrailer',
            },
            {
                path:
                    '/category/fordpass/checklist-to-connect-trailer/how-to-connect-a-fifth-wheel-trailer-checklist',
                componentName: 'TrailerChecklistFifthWheelTrailer',
            },
            {
                path: [
                    '/service-maintenance/tire-finder/',
                    '/service-maintenance/tire-finder/search-results.html',
                    '/service-maintenance/tire-finder/ecommerce.html',
                ],
                componentName: 'TireFinderView',
            },
        ],
    },
];
