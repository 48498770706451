import React, { useState, useReducer, useEffect } from 'react';
import {
    useStepProgressBar,
    useHttp,
    useViewport,
    useServiceStep,
    useServiceLocationStep,
    getInitialServiceLocationState,
    useOSBStep,
    usePageRefresh,
    useLightDeliveryOptionServiceStep,
    useVehicleStep,
} from '../../../../hooks/owners-osb';
import { AutoComplete, Dropdown, PrimaryButton } from '../../../common';
import { OsbComments } from '../comments/comments';
import { OSBSecondaryButton } from '../common/osb-secondary-button/osb-secondary-button';
import {
    LIGHT_JOURNEY_ANALYTICS,
    OSB_CLIENT_STORAGE_KEYS,
    OSB_SERVICE_TYPE,
    SERVICE_FLOW,
    SERVICE_LOCATION_STEP_KEYS,
    STEP_PROGRESS_BAR_KEYS,
} from '../osb-constant';
import {
    OsbPathCalenderStep,
    OsbPathServiceLocationStep,
    OsbPathServicesStep,
} from '../osb-controller';
import './service-location-lincoln.scss';
import { serviceLocationReducer } from '../../../../reducers/osb-reducers';
import { ServiceLocationDetails } from '../../../../models/osb-model/osb-service-location';
import { LightBookingSummary } from '../light-booking-summary/light-booking-summary';
import BookingSummaryAccordion from '../light-booking-summary/booking-summary-accordion/booking-summary-accordion';
import {
    getObjectFromAEMJson,
    buildNavigationUrl,
    setServiceUniqueId,
    setRouteToPersist,
    sortOrderComparer,
    getClientStorageValue,
    isConvenienceOptionTypePresent,
    frameServiceData,
    isServiceFoundInSelectedList,
    getGoMainHeaderHeight,
} from '../osb-utils';
import GoogleMapService from '../../../../services/osb-service/google-map-service/google-map-service';
import {
    DealerServices,
    ServiceData,
    ServiceInfo,
} from '../../../../models/osb-model/osb-dealerservice-info';
import { OsbServiceRenderer } from '../common/osb-service-renderer/osb-service-renderer';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../common/osb-loader/osb-loader';
import errorWarning from '../../../../assets/error-warning.svg';
import parse from 'html-react-parser';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { triggerBookServiceGlobalCTAOnClickAnalytics } from '../analytics/osb-analytics';
import { useAnalytics } from '../../../../hooks/use-analytics';

interface Props {
    backButtonTrigger?: () => void;
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}

export const ServiceLocation = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { osbVehicleStep } = useVehicleStep();
    const appConfiguration = new AppConfigurationService().getAppConfiguration();
    const {
        osbServiceLocationDetail,
        callContentService,
        setOSBServiceLocationStep,
        setOSBServiceLocationStepPayload,
    } = useServiceLocationStep();
    const {
        osbStepProgressBar,
        updateProgressBarStatus,
    } = useStepProgressBar();
    const { isMobileView } = useViewport();
    const { httpState, dispatch } = useHttp();
    const { osbServiceStep } = useServiceStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();
    const [streetNameError, setStreetNameError] = useState('');
    const [houseNumberError, setHouseNumberError] = useState('');
    const [postCodeError, setPostCodeError] = useState('');
    const [cityError, setCityError] = useState('');
    const [parkingTypeError, setParkingTypeError] = useState('');
    const [serviceLocationDetail, setServiceLocationDetail] = useReducer(
        serviceLocationReducer,
        getInitialServiceLocationState()
    );
    const history = useHistory();
    const [listOfNoTouchServices, setListOfNoTouchServices] = useState<
        ServiceInfo[]
    >();

    const [isLocationRefreshed, setIsLocationRefreshed] = useState(false);
    const [serviceLocationContent, setServiceLocationContent] = useState<any>();
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const isPickUpAndDeliverySelected =
        isConvenienceOptionTypePresent(
            SERVICE_FLOW.PICKUP_AND_DELIVERY_CONVENIENCE_OPTION_TYPE,
            osbLightDeliveryServiceStep.selectedServices
        ) || false;
    const [enableContinueButton, setEnableContinueButton] = useState<boolean>(
        false
    );
    const [isStreetNameLabelShow, setIsStreetNameLabelShow] = useState<boolean>(
        false
    );
    const [isHouseNumberLabelShow, setIsHouseNumberLabelShow] = useState<
        boolean
    >(false);
    const [isCityLabelShow, setIsCityLabelShow] = useState<boolean>(false);
    let postcode = '';
    usePageRefresh();

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.osbAlertMessagePopup(message, success);
    };

    const setLocationOptionServices = (dealerServices: DealerServices) => {
        if (dealerServices) {
            const updatedServiceList = setServiceUniqueId(dealerServices);
            const locationOptionServices = updatedServiceList.filter(
                service =>
                    service.additionalInfo?.serviceType?.toUpperCase() ===
                    OSB_SERVICE_TYPE.DELIVERY.toUpperCase()
            );

            if (locationOptionServices) {
                setListOfNoTouchServices(
                    [...locationOptionServices].sort(sortOrderComparer)
                );
            }
        }
    };

    const getServiceLocationContent = async () => {
        dispatch({ type: 'REQUEST' });
        await callContentService('osb-service-location')
            .then(results => {
                if (results?.elements) {
                    setServiceLocationContent(results.elements);
                    dispatch({ type: 'RESPONSE' });
                }
            })
            .catch((error: any) => {
                dispatch({ type: 'ERROR', error: error.message });
            });
    };

    const setComponentPayload = (
        payload:
            | { [key: string]: string | ServiceData[] }
            | ServiceLocationDetails
    ): void => {
        setServiceLocationDetail({
            type: 'SET',
            payload: payload,
            localStorageExpiry: osbStep.localStorageExpiry,
        });
    };

    // To keep the deeplinked services selected for MSV flow
    const preselectDeeplinkedServices = (extraServices: ServiceInfo[]) => {
        if (
            osbStep.preSelectedServices &&
            osbStep.preSelectedServices.length > 0
        ) {
            const preSelectedServices = osbStep.preSelectedServices.split(',');
            const preSelectedDeliveryServices: ServiceData[] = [];
            extraServices.forEach(service => {
                if (
                    preSelectedServices.includes(
                        service.serviceId.toString()
                    ) &&
                    service?.additionalInfo?.serviceType ==
                        OSB_SERVICE_TYPE.DELIVERY &&
                    !isServiceFoundInSelectedList(
                        service,
                        osbServiceLocationDetail.selectedOtherOptions
                    )
                ) {
                    const serviceData = frameServiceData(
                        service?.additionalInfo?.serviceType,
                        OSB_SERVICE_TYPE.DELIVERY,
                        service
                    );
                    preSelectedDeliveryServices.push(serviceData);
                }
            });
            if (preSelectedDeliveryServices.length > 0) {
                setOSBServiceLocationStepPayload({
                    selectedOtherOptions: [
                        ...osbServiceLocationDetail.selectedOtherOptions,
                        ...preSelectedDeliveryServices,
                    ],
                });
            }
        }
    };

    useEffect(() => {
        if (serverSideService.isClientSide() && !httpState.isLoading) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, [history.location.pathname, httpState.isLoading]);

    useEffect(() => {
        if (osbServiceStep.isMobileServiceSelected) {
            updateProgressBarStatus(
                STEP_PROGRESS_BAR_KEYS.LOCATION,
                false,
                true
            );
            const stepDetails = osbStepProgressBar.progressBarDetails.find(
                step =>
                    step.key === STEP_PROGRESS_BAR_KEYS.LOCATION &&
                    step.isEditable
            );
            if (stepDetails || osbStep.isRetrieveFlow) {
                setRouteToPersist(
                    OSB_CLIENT_STORAGE_KEYS.OSB_PERSISTED_ROUTE_KEY,
                    OsbPathServiceLocationStep(),
                    osbStep.localStorageExpiry
                );
                if (serverSideService.isClientSide()) {
                    window.scrollTo({
                        top: getGoMainHeaderHeight() - 2,
                        behavior: 'smooth',
                    });
                }
                setComponentPayload(
                    getClientStorageValue(
                        OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_LOCATION_STEP_KEY
                    ) || osbServiceLocationDetail
                );
                getServiceLocationContent();
            }
            preselectDeeplinkedServices(
                osbServiceStep?.dealerServiceInfo?.dealerServices
                    ?.additionalServices
            );
        } else if (isPickUpAndDeliverySelected) {
            if (serverSideService.isClientSide()) {
                window.scrollTo({
                    top: getGoMainHeaderHeight() - 2,
                    behavior: 'smooth',
                });
            }
            setComponentPayload(osbServiceLocationDetail);
            getServiceLocationContent();
        }
    }, []);

    useEffect(() => {
        setLocationOptionServices(
            osbServiceStep.dealerServiceInfo.dealerServices
        );
    }, [isLocationRefreshed]);

    useEffect(() => {
        if (serviceLocationContent) {
            const isValid = enableNextStepContinuation();
            setEnableContinueButton(isValid);
        }
    }, [
        serviceLocationDetail.streetName,
        serviceLocationDetail.houseNumber,
        serviceLocationDetail.town,
        serviceLocationDetail.postalCode,
        serviceLocationDetail.parkingType,
        serviceLocationContent,
    ]);

    const gotoPreviousStep = () => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.BACK_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        if (osbServiceStep.isMobileServiceSelected) {
            updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.LOCATION, false);
            history.push(
                buildNavigationUrl(
                    OsbPathServicesStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else if (isPickUpAndDeliverySelected) {
            props.backButtonTrigger && props.backButtonTrigger();
        }
    };

    const streetNameLabelShow = () => {
        setIsStreetNameLabelShow(true);
    };

    const streetNameLabelHide = () => {
        setIsStreetNameLabelShow(false);
    };

    const houseNumberLabelShow = () => {
        setIsHouseNumberLabelShow(true);
    };

    const houseNumberLabelHide = () => {
        setIsHouseNumberLabelShow(false);
    };

    const cityLabelShow = () => {
        setIsCityLabelShow(true);
    };

    const cityLabelHide = () => {
        setIsCityLabelShow(false);
    };

    const handleStreetNameValidation = (value: string) => {
        if (
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.STREET_NAME_MANDATORY,
                serviceLocationContent
            ) &&
            value.length <= 0
        ) {
            setStreetNameError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.STREET_NAME_ERROR,
                    serviceLocationContent
                )
            );
            return false;
        } else {
            setStreetNameError('');
            return true;
        }
    };

    const handleHouseNumberValidation = (value: string) => {
        if (
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.HOUSE_NUMBER_MANDATORY,
                serviceLocationContent
            ) &&
            value.length <= 0
        ) {
            setHouseNumberError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.HOUSE_NUMBER_ERROR,
                    serviceLocationContent
                )
            );
            return false;
        } else {
            setHouseNumberError('');
            return true;
        }
    };

    const handlePostcodeValidation = (value: string) => {
        if (
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.POSTCODE_MANDATORY,
                serviceLocationContent
            ) &&
            value.length <= 0
        ) {
            setPostCodeError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.POSTCODE_ERROR,
                    serviceLocationContent
                )
            );
            return false;
        } else {
            setPostCodeError('');
            return true;
        }
    };

    const handleCityValidation = (value: string) => {
        if (
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.CITY_MANDATORY,
                serviceLocationContent
            ) &&
            value.length <= 0
        ) {
            setCityError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.CITY_ERROR,
                    serviceLocationContent
                )
            );
            return false;
        } else {
            setCityError('');
            return true;
        }
    };

    const handleParkingTypeValidation = (parkingType: string) => {
        if (
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.PARKING_TYPE_MANDATORY,
                serviceLocationContent
            ) &&
            parkingType.length <= 0
        ) {
            setParkingTypeError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.PARKING_TYPE_ERROR,
                    serviceLocationContent
                )
            );
            return false;
        } else {
            setParkingTypeError('');
            return true;
        }
    };

    const onStreetNameChange = (e: any) => {
        setComponentPayload({ [e.target.name]: e.target.value });
        handleStreetNameValidation(e.target.value);
    };
    const onHouseNumberChange = (e: any) => {
        setComponentPayload({ [e.target.name]: e.target.value });
        handleHouseNumberValidation(e.target.value);
    };

    // find city from post code.
    const getCity = (description: string) => {
        // The function first checks if the length of description is less than or equal to 0.
        // If so, it returns an empty string. Otherwise, it continues with the rest of the function.
        if (description.length <= 0 || !postcode) {
            return description;
        }
        // Split the description into an array of elements, removing whitespace.
        const descriptionArray = description
            .split(',')
            .map(element => element.trim());
        const poCode = `${postcode}`;
        // Find the element containing the postcode.
        const cityWithPostcode = descriptionArray.find(desc =>
            desc.includes(poCode)
        );
        // If found, extract the city name.
        if (cityWithPostcode) {
            const city = cityWithPostcode.replace(postcode, '').trim();
            // If the city is empty or matches the country name, try to get the preceding element.
            if (
                city.length === 0 ||
                city.toLocaleLowerCase() ===
                    appConfiguration.countryName.toLocaleUpperCase()
            ) {
                const poCodeIndex = descriptionArray.indexOf(cityWithPostcode);
                if (poCodeIndex > 0) {
                    return descriptionArray[poCodeIndex - 1];
                }
            } else {
                return city;
            }
        }
        // If no city with postcode is found, return the original description.
        return description;
    };

    function getExactDescriptionFromPredictions(
        predictions: google.maps.places.AutocompletePrediction[]
    ): string {
        if (predictions.length <= 0) {
            return '';
        }
        let desc = '';
        predictions.forEach(item => {
            item?.terms?.forEach(sub => {
                if (sub.value === postcode) {
                    desc = item.description;
                }
            });
        });
        return desc;
    }
    const setPostcodeSuggestions = function(
        predictions: google.maps.places.AutocompletePrediction[] | null
    ) {
        if (predictions && predictions?.length > 0) {
            const city = getCity(
                getExactDescriptionFromPredictions(predictions)
            );
            setComponentPayload({ town: city });
            setCityError('');
        }
    };
    const resetOnPostcodeRemoval = (postalCode: string) => {
        if (postalCode.length <= 0) {
            setComponentPayload({ town: '', postalCode: '' });
            setPostCodeError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.POSTCODE_ERROR,
                    serviceLocationContent
                )
            );
            setCityError(
                getObjectFromAEMJson(
                    SERVICE_LOCATION_STEP_KEYS.CITY_ERROR,
                    serviceLocationContent
                )
            );
        } else {
            setPostCodeError('');
        }
    };
    const getCityForPostCode = (postalCode: string) => {
        if (postalCode.length > 0) {
            postcode = postalCode;
            setPostCodeError('');
        }
        setComponentPayload({ postalCode: postalCode });
        GoogleMapService.getPlacePredictions(
            postalCode,
            setPostcodeSuggestions,
            osbStep.geoCountryCode,
            true
        );
    };

    const onCityChange = (e: any) => {
        setComponentPayload({ town: e.target.value });
        handleCityValidation(e.target.value);
    };

    const onParkingTypeChange = (parkingType: string) => {
        setComponentPayload({ parkingType: parkingType || '' });
        handleParkingTypeValidation(parkingType);
    };

    const validateServiceLocationFields = () => {
        const hasStreetName = handleStreetNameValidation(
            serviceLocationDetail.streetName
        );
        const hasHouseNumber = handleHouseNumberValidation(
            serviceLocationDetail.houseNumber
        );
        const hasPostcode = handlePostcodeValidation(
            serviceLocationDetail.postalCode
        );
        const hasCity = handleCityValidation(serviceLocationDetail.town);
        const hasParkingType = handleParkingTypeValidation(
            serviceLocationDetail.parkingType
        );

        if (
            hasStreetName &&
            hasHouseNumber &&
            hasPostcode &&
            hasCity &&
            hasParkingType
        ) {
            return true;
        } else {
            return false;
        }
    };

    const enableNextStepContinuation = () => {
        const hasStreetName =
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.STREET_NAME_MANDATORY,
                serviceLocationContent
            ) && serviceLocationDetail.streetName.length > 0;
        const hasHouseNumber =
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.HOUSE_NUMBER_MANDATORY,
                serviceLocationContent
            ) && serviceLocationDetail.houseNumber.length > 0;

        const hasPostcode =
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.POSTCODE_MANDATORY,
                serviceLocationContent
            ) && serviceLocationDetail.postalCode.length > 0;

        const hasCity =
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.CITY_MANDATORY,
                serviceLocationContent
            ) && serviceLocationDetail.town.length > 0;

        const hasParkingType =
            getObjectFromAEMJson(
                SERVICE_LOCATION_STEP_KEYS.PARKING_TYPE_MANDATORY,
                serviceLocationContent
            ) && serviceLocationDetail.parkingType.length > 0;
        return (
            hasStreetName &&
            hasHouseNumber &&
            hasPostcode &&
            hasCity &&
            hasParkingType
        );
    };

    const goToNextStep = async () => {
        const isValid = validateServiceLocationFields();
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.CONTINUE_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        if (isValid) {
            serviceLocationDetail.street = serviceLocationDetail.streetName;
            setOSBServiceLocationStep(serviceLocationDetail);
            if (osbServiceStep.isMobileServiceSelected) {
                updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.LOCATION, true);
            } else if (isPickUpAndDeliverySelected) {
                updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DELIVERY, true);
            }
            if (osbStep.isRetrieveFlow) {
                updateProgressBarStatus(
                    STEP_PROGRESS_BAR_KEYS.DATETIME,
                    false,
                    true
                );
            }
            history.push(
                buildNavigationUrl(
                    OsbPathCalenderStep(),
                    osbStep.UrlQueryParams
                )
            );
        } else if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    };

    const handleSummaryRefresh = () => {
        setIsLocationRefreshed(!isLocationRefreshed);
    };

    const addService = (serviceType: string, service: any) => {
        const serviceData: ServiceData = {
            id: service.id,
            serviceType: serviceType,
            serviceName: service.name,
            serviceCode: service.serviceId.toString(),
            priceAfterDiscount: service.priceAfterDiscount,
            price: service.price,
            type: service.type,
            subType: service.subType
                ? service.subType
                : service.additionalInfo.subType,
            serviceOption: 'Location Other Option',
            brightCoveAccountId:
                service?.additionalInfo?.brightCoveAccountId || '',
            brightCoveVideoId: service?.additionalInfo?.brightCoveVideoId || '',
            brightCovePlayerId:
                service?.additionalInfo?.brightCovePlayerId || '',
        };
        const selectedServices = [
            ...osbServiceLocationDetail.selectedOtherOptions,
            serviceData,
        ];
        setComponentPayload({ selectedOtherOptions: selectedServices });
        setOSBServiceLocationStepPayload({
            selectedOtherOptions: selectedServices,
        });
    };

    const removeService = (service: any) => {
        let selectedServices: ServiceData[] =
            osbServiceLocationDetail.selectedOtherOptions;
        selectedServices = selectedServices.filter(
            item => item.serviceCode !== service.serviceId.toString()
        );
        setComponentPayload({ selectedOtherOptions: selectedServices });
        setOSBServiceLocationStepPayload({
            selectedOtherOptions: selectedServices,
        });

        if (
            osbStep.preSelectedServices &&
            osbStep.preSelectedServices.length > 0
        ) {
            const preSelectedServices = osbStep.preSelectedServices.split(',');
            setOSBStepPayload({
                preSelectedServices: preSelectedServices
                    .filter(item => item !== service.serviceId.toString())
                    .join(','),
            });
        }
    };

    const hasServiceSelected = (serviceId: number) => {
        if (!osbServiceLocationDetail.selectedOtherOptions) {
            return false;
        }
        const selectedService = osbServiceLocationDetail.selectedOtherOptions?.find(
            item => item.serviceCode === serviceId.toString()
        );
        return !!selectedService;
    };

    const handleServiceSelection = (e: boolean, service: any) => {
        const serviceType = OSB_SERVICE_TYPE.DELIVERY;

        if (e) {
            const existingService = osbServiceLocationDetail.selectedOtherOptions?.find(
                serviceItem =>
                    serviceItem.serviceCode === service.serviceId.toString()
            );
            if (!existingService) {
                addService(serviceType, service);
            }
        } else {
            removeService(service);
        }
    };

    return (
        <>
            {httpState.isLoading ? (
                <OsbLoader />
            ) : (
                <>
                    <div className="osb-service-location-container">
                        <div className="service-location">
                            {renderBackButton()}
                            {renderServiceLocationTitleAndText()}
                            {renderPickupLocationTitleAndText()}
                            <div className="location-form">
                                {renderStreetNameInput()}
                                {renderHouseNumberInput()}
                                {renderPostCodeInput()}
                                {renderCityInput()}
                                {renderParkingTypeDropdown()}
                            </div>
                            {renderComments()}
                            {renderOtherOptions()}
                            {renderContinueButton()}
                            {renderPleaseNoteText()}
                            {renderPickupAndDeliveryDisclaimer()}
                        </div>
                        {!isMobileView && (
                            <div className="osb-light-journey-booking-summary">
                                <LightBookingSummary
                                    stepName={STEP_PROGRESS_BAR_KEYS.LOCATION}
                                    refreshBookingSummary={handleSummaryRefresh}
                                    showVoucherLink={true}
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {isMobileView && (
                        <div className="osb-mobile-booking-summary">
                            <div className="view-summary-accordion">
                                <BookingSummaryAccordion
                                    index="0"
                                    className=""
                                    expandMultiplePanels={true}
                                    refreshBookingSummary={handleSummaryRefresh}
                                    stepName={STEP_PROGRESS_BAR_KEYS.LOCATION}
                                    showVoucherLink={true}
                                    loadVoucherAlertMessage={
                                        loadVoucherAlertMessage
                                    }
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
    function renderBackButton() {
        return (
            (osbStep.isWebViewJourney || !isMobileView) &&
            !osbStep.isRetrieveFlow && (
                <OSBSecondaryButton
                    type="svg"
                    direction="left"
                    text={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.BACK_LABEL,
                        serviceLocationContent
                    )}
                    onClickHandler={gotoPreviousStep}
                />
            )
        );
    }
    function renderServiceLocationTitleAndText() {
        return (
            osbServiceStep.isMobileServiceSelected && (
                <>
                    <div className="service-location-title">
                        {getObjectFromAEMJson(
                            SERVICE_LOCATION_STEP_KEYS.SERVICE_LOCATION_TITLE,
                            serviceLocationContent
                        )}
                    </div>
                    <div className="service-location-text">
                        {parse(
                            getObjectFromAEMJson(
                                SERVICE_LOCATION_STEP_KEYS.SERVICE_LOCATION_TEXT,
                                serviceLocationContent
                            )
                        )}
                    </div>
                </>
            )
        );
    }
    function renderPickupLocationTitleAndText() {
        return (
            isPickUpAndDeliverySelected && (
                <>
                    <div className="pick-up-location-title">
                        {getObjectFromAEMJson(
                            SERVICE_LOCATION_STEP_KEYS.PICKUP_SERVICE_LOCATION_TITLE,
                            serviceLocationContent
                        )}
                    </div>
                    <div className="pick-up-location-text">
                        {parse(
                            getObjectFromAEMJson(
                                SERVICE_LOCATION_STEP_KEYS.PICKUP_SERVICE_LOCATION_TEXT,
                                serviceLocationContent
                            )
                        )}
                    </div>
                </>
            )
        );
    }
    function renderStreetNameInput() {
        return (
            <div className="street-name">
                <label className="input-label">
                    {(serviceLocationDetail?.streetName.length > 0 ||
                        isStreetNameLabelShow) && (
                        <span>
                            {getObjectFromAEMJson(
                                SERVICE_LOCATION_STEP_KEYS.STREET_NAME_LABEL,
                                serviceLocationContent
                            )}
                        </span>
                    )}
                    {streetNameError ? (
                        <img className="osb-error-icon" src={errorWarning} />
                    ) : (
                        <span></span>
                    )}
                </label>
                <input
                    id="streetName"
                    data-testid="streetNameTestId"
                    type="text"
                    className={`input-field${streetNameError ? '-error' : ''}`}
                    placeholder={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.STREET_NAME_LABEL,
                        serviceLocationContent
                    )}
                    aria-label="streetName"
                    aria-labelledby="streetName"
                    name="streetName"
                    value={serviceLocationDetail.streetName}
                    onChange={e => onStreetNameChange(e)}
                    onBlur={streetNameLabelHide}
                    onFocus={streetNameLabelShow}
                />
                {streetNameError ? (
                    <div className="osb-error-message">{streetNameError}</div>
                ) : (
                    <span></span>
                )}
            </div>
        );
    }

    function renderHouseNumberInput() {
        return (
            <div className="house-number">
                <label className="input-label">
                    {(serviceLocationDetail?.houseNumber.length > 0 ||
                        isHouseNumberLabelShow) && (
                        <span>
                            {getObjectFromAEMJson(
                                SERVICE_LOCATION_STEP_KEYS.HOUSE_NUMBER_LABEL,
                                serviceLocationContent
                            )}
                        </span>
                    )}
                    {houseNumberError ? (
                        <img className="osb-error-icon" src={errorWarning} />
                    ) : (
                        <span></span>
                    )}
                </label>
                <input
                    id="houseNumber"
                    data-testid="houseNumberTestId"
                    type="text"
                    className={`input-field${houseNumberError ? '-error' : ''}`}
                    placeholder={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.HOUSE_NUMBER_LABEL,
                        serviceLocationContent
                    )}
                    aria-label="houseNumber"
                    aria-labelledby="houseNumber"
                    name="houseNumber"
                    value={serviceLocationDetail.houseNumber}
                    onChange={e => onHouseNumberChange(e)}
                    onBlur={houseNumberLabelHide}
                    onFocus={houseNumberLabelShow}
                />
                {houseNumberError ? (
                    <div className="osb-error-message">{houseNumberError}</div>
                ) : (
                    <span></span>
                )}
            </div>
        );
    }

    function renderPostCodeInput() {
        return (
            <div className="post-code">
                <AutoComplete
                    name="selectPostCode"
                    id="selectPostCode"
                    text={serviceLocationDetail.postalCode}
                    google={true}
                    hasPostcodeSearch={true}
                    setInputText={e => getCityForPostCode(e)}
                    resetOnPostcodeRemoval={e => resetOnPostcodeRemoval(e)}
                    placeHolderText={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.POSTCODE_LABEL,
                        serviceLocationContent
                    )}
                    message={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.SEARCH_HINT_LABEL,
                        serviceLocationContent
                    )}
                    error={postCodeError}
                    searchStringLength={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.POSTCODE_AUTOCOMPLETE_SEARCH_STRING_LENGTH,
                        serviceLocationContent
                    )}
                />
                <div className="osb-input-text-error">{postCodeError}</div>
            </div>
        );
    }

    function renderCityInput() {
        return (
            <div className="city">
                <label className="input-label">
                    {(serviceLocationDetail?.town.length > 0 ||
                        isCityLabelShow) && (
                        <span>
                            {getObjectFromAEMJson(
                                SERVICE_LOCATION_STEP_KEYS.CITY_LABEL,
                                serviceLocationContent
                            )}
                        </span>
                    )}
                    {cityError ? (
                        <img className="osb-error-icon" src={errorWarning} />
                    ) : (
                        <span></span>
                    )}
                </label>
                <input
                    id="city"
                    data-testid="cityTestId"
                    type="text"
                    className={`input-field${houseNumberError ? '-error' : ''}`}
                    placeholder={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.CITY_LABEL,
                        serviceLocationContent
                    )}
                    aria-label="city"
                    aria-labelledby="city"
                    name="city"
                    value={serviceLocationDetail.town}
                    onChange={e => onCityChange(e)}
                    onBlur={cityLabelHide}
                    onFocus={cityLabelShow}
                />
                {cityError ? (
                    <div className="osb-error-message">{cityError}</div>
                ) : (
                    <span></span>
                )}
            </div>
        );
    }

    function renderParkingTypeDropdown() {
        return (
            <div className="parking-type">
                <Dropdown
                    label={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.PARKING_TYPE_LABEL,
                        serviceLocationContent
                    )}
                    options={getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.PARKING_TYPE_OPTIONS,
                        serviceLocationContent
                    )}
                    value={serviceLocationDetail.parkingType}
                    onChange={e => onParkingTypeChange(e)}
                    errorMessage={parkingTypeError}
                />
            </div>
        );
    }
    function renderComments() {
        return (
            <div className="service-location-comments">
                <OsbComments
                    commentInStore={serviceLocationDetail.comment}
                    setCommentInStore={setComponentPayload}
                    serviceAemContent={serviceLocationContent}
                />
            </div>
        );
    }

    function renderOtherOptions() {
        return (
            osbServiceStep.isMobileServiceSelected &&
            listOfNoTouchServices &&
            listOfNoTouchServices.length > 0 && (
                <div className="sl-other-options">
                    <div className="other-options-title">
                        {getObjectFromAEMJson(
                            SERVICE_LOCATION_STEP_KEYS.OTHER_OPTIONS_TITLE,
                            serviceLocationContent
                        )}
                    </div>
                    <div
                        className={`other-option-service-list  brand-${osbStep.brandName}`}
                    >
                        <OsbServiceRenderer
                            handleServiceSelection={handleServiceSelection}
                            servicesList={listOfNoTouchServices}
                            hasServiceSelected={hasServiceSelected}
                        />
                    </div>
                </div>
            )
        );
    }

    function renderContinueButton() {
        return (
            <div className="sl-continue-button">
                <PrimaryButton
                    role="link"
                    color={'dark'}
                    fill={'fill'}
                    chevron={false}
                    onClick={goToNextStep}
                    disabled={!enableContinueButton}
                >
                    {getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.CONTINUE_BUTTON,
                        serviceLocationContent
                    )}
                </PrimaryButton>
            </div>
        );
    }

    function renderPleaseNoteText() {
        return (
            osbServiceStep.isMobileServiceSelected && (
                <div className="please-note-text">
                    {getObjectFromAEMJson(
                        SERVICE_LOCATION_STEP_KEYS.PLEASE_NOTE_TEXT,
                        serviceLocationContent
                    )}
                </div>
            )
        );
    }

    function renderPickupAndDeliveryDisclaimer() {
        return (
            isPickUpAndDeliverySelected && (
                <div className="pickup-and-delivery-disclaimer-text">
                    {parse(
                        getObjectFromAEMJson(
                            SERVICE_LOCATION_STEP_KEYS.PICKUP_AND_DELIVERY_DISCLAIMER_TEXT,
                            serviceLocationContent
                        )
                    )}
                </div>
            )
        );
    }
};
