import React, { useState } from 'react';
import './vehicle-search-form.scss';
import { FMButton, FMDropdown, FMInput, FMModal } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import parse from 'html-react-parser';
import { useOSBStep, useVehicleStep } from '../../../../../hooks/owners-osb';
import { VehicleData } from '../../../../../models/osb-model/osb-vehicle-step';
import ServiceHandler from '../../../../../services/service-handler';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import { formatRegVinNumber } from '../../osb-v3-utils';
import { PC_DEFAULT_DEALER_CODE } from '../../../../../constants';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import VehicleImageService from '../../../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import { PageType } from '../../../view-template/page-type';
import { VehicleInfo } from '../../../../../models/osb-model/osb-vehicle-lookup';
import { buildNavigationUrl } from '../../../owners-osb/osb-utils';
import { OsbPathServiceCentreStep } from '../../osb-view-controller';
import { useHistory } from 'react-router-dom';
import VinInformation from './vin-information';

const InfoIconL = './icons/info-l.svg';

interface VehicleSearchFormProps {
    onContinue?: () => void;
    onVehicleInfoSuccess?: (info: VehicleInfo, imageUrl: string) => void;
}

const VehicleSearchForm: React.FC<VehicleSearchFormProps> = ({
    onContinue,
    onVehicleInfoSuccess,
}) => {
    const vehicleStepContent =
        useOSBContentFragment('common/osb-v3', 'vehicle-step') || {};
    let { brand } = new AppConfigurationService();
    brand = brand === 'demo' ? 'ford' : brand;
    const history = useHistory();
    const { osbStep } = useOSBStep();
    const searchParams = new URLSearchParams(window.location.search);
    const isAuth = searchParams.get('isAuth') === 'true';
    const page = 'OSBVehicleStep' as PageType;
    const { osbVehicleStep, setOSBVehicleStepPayload } = useVehicleStep();
    const bookable = useAllOSBState();
    const [vinRegErrorMsg, setVinRegErrorMsg] = useState<string>();
    const [mileageError, setMileageError] = useState<string>();
    const [isVinRegModalVisible, setIsVinRegModalVisible] = useState(false);
    const [serviceError, setServiceError] = useState('');
    const [isManualMileageEntry, setIsManualMileageEntry] = useState<boolean>(
        false
    );
    const [vehicleImageUrl, setVehicleImageUrl] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const isThisVIN = (vinRegNo: string) => {
        return vinRegNo.length >= 10;
    };

    const isValidVin = (regVin: string, vinRegDataPattern: string) => {
        if (!regVin) {
            return false;
        }
        const vinRegistrationRegExp =
            vinRegDataPattern?.trim() ||
            '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,9}[a-zA-Z0-9])$';
        const vinRegex = new RegExp(vinRegistrationRegExp);
        return vinRegex.test(regVin);
    };

    const validateVinRegNo = (vinRegNo: string) => {
        if (vinRegNo === '') {
            setVinRegErrorMsg(
                vehicleStepContent?.vinregistrationValidationMessage?.toString()
            );
            return false;
        } else {
            if (isThisVIN(vinRegNo)) {
                //VIN
                const isVinValid = isValidVin(
                    vinRegNo,
                    '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,7}[a-zA-Z0-9])$'
                );
                if (!isVinValid) {
                    setVinRegErrorMsg(
                        vehicleStepContent?.invalidVinErrorMessage?.toString()
                    );
                    return false;
                } else {
                    setVinRegErrorMsg('');
                    return true;
                }
            } else {
                // REG NO
                const isRegNoValid = isValidVin(
                    vinRegNo,
                    '^([a-zA-Z0-9]{17}|[a-zA-Z0-9][a-zA-Z0-9 -]{1,7}[a-zA-Z0-9])$'
                );
                if (!isRegNoValid) {
                    setVinRegErrorMsg(
                        vehicleStepContent?.invalidRegistrationErrorMessage?.toString()
                    );
                    return false;
                } else {
                    setVinRegErrorMsg('');
                    return true;
                }
            }
        }
    };

    const onVinRegChange = (e: any) => {
        validateVinRegNo(e.target.value);
        setOSBVehicleStepPayload({ vinRegNo: e.target.value });
    };

    const isValidMileage = (mileage: string) => {
        const correctMileage = mileage.replace(/[^0-9 .,']+$/, '');
        const sanitisedMileage = correctMileage
            ? correctMileage.replace(/[ .,']/g, '')
            : '';
        const finalMileage = sanitisedMileage.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ''
        );
        setOSBVehicleStepPayload({ vinMileage: finalMileage });
        if (finalMileage === '') {
            setMileageError(
                vehicleStepContent?.mileageValidationMessage?.toString()
            );
            return false;
        }
        return true;
    };

    const onMileageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const isMileageValid = isValidMileage(e.target.value);
        if (!isMileageValid) {
            setMileageError(
                vehicleStepContent?.mileageValidationMessage?.toString()
            );
        } else {
            setMileageError('');
        }
    };

    const onCloseVinRegInfo = () => {
        setIsVinRegModalVisible(false);
    };

    const renderHowToFindYourVin = () => {
        setIsVinRegModalVisible(true);
    };

    const fetchVehicleImage = async (
        vehicleAttributes: VehicleAttributes
    ): Promise<string> => {
        try {
            return (
                (await new VehicleImageService().getVehicleImage(
                    vehicleAttributes,
                    page
                )) || '#'
            );
        } catch (error) {
            console.error('Failed to fetch vehicle image:', error);
            return '#';
        }
    };

    const prepareVehicleInfo = (vehicleInfo: VehicleInfo) => {
        osbVehicleStep.vehicleDetails = {
            modelName: vehicleInfo?.modelName || '',
            engine: vehicleInfo?.engine || '',
            transmission: vehicleInfo?.transmission || '',
            fuelType: vehicleInfo?.fuelType || '',
            color: vehicleInfo?.color || '',
            registrationNumber: vehicleInfo?.registrationNumber || '',
            vin: vehicleInfo?.vin || '',
            buildDate: vehicleInfo?.buildDate || '',
            vehicleImageURL: vehicleImageUrl,
        };
    };

    const getVehicleData = async () => {
        setLoading(true); // Start loading
        setServiceError('');
        if (
            osbVehicleStep.vinRegNo !== '' &&
            osbVehicleStep.vinMileage !== ''
        ) {
            let vehicleData: VehicleData;
            if (osbVehicleStep.vinRegNo.length <= 10) {
                vehicleData = {
                    registrationNumber: formatRegVinNumber(
                        osbVehicleStep.vinRegNo,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.vinMileage,
                };
            } else {
                vehicleData = {
                    vin: formatRegVinNumber(
                        osbVehicleStep.vinRegNo,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.vinMileage,
                };
            }

            await ServiceHandler.OsbVehicleLookUp.postVehicleInfo({
                vehicleData: vehicleData,
                prognosticsData: osbVehicleStep.vehiclePrognosticData,
                dealerCode: PC_DEFAULT_DEALER_CODE,
                bookable: bookable,
            })
                .then(async result => {
                    const imageUrl = await fetchVehicleImage({
                        model: result.value.modelName,
                        make: brand,
                        vin: result.value.vin,
                        year: parseInt(result.value.buildDate.substring(0, 4)),
                    });
                    setVehicleImageUrl(imageUrl);
                    prepareVehicleInfo(result.value);

                    if (isAuth) {
                        if (onVehicleInfoSuccess) {
                            onVehicleInfoSuccess(result.value, imageUrl || '#');
                        }
                        if (onContinue) {
                            onContinue();
                        }
                    } else {
                        history.push(
                            buildNavigationUrl(
                                OsbPathServiceCentreStep(),
                                osbStep.UrlQueryParams
                            )
                        );
                    }
                    setLoading(false);
                })
                .catch((error: any) => {
                    if (error) {
                        console.log('error', error);
                        const errorMessage = error.errorCode;
                        if (errorMessage === 'OSB_VIN_EXISTS') {
                            setServiceError('ACTIVE_BOOKING');
                        } else if (
                            errorMessage === 'OSB_NO_DATAFOUND' ||
                            errorMessage === 'OSB_VEHICLE_DETAILS_NOT_FOUND' ||
                            errorMessage === 'VEHICLE_NOT_FOUND'
                        ) {
                            if (osbVehicleStep.vinRegNo.length <= 10) {
                                setServiceError('REG_NOT_FOUND');
                            } else {
                                setServiceError('VIN_NOT_FOUND');
                            }
                        } else {
                            setServiceError('SERVICE_ERROR');
                        }
                    }
                    setLoading(false);
                });
        } else {
            if (!osbVehicleStep.vinRegNo?.trim()) {
                validateVinRegNo('');
            }
            if (!osbVehicleStep.vinMileage?.trim()) {
                setMileageError(
                    vehicleStepContent?.mileageValidationMessage?.toString()
                );
            }
            setLoading(false);
        }
    };

    function renderErrorMessages() {
        return (
            <>
                {serviceError && serviceError === 'ACTIVE_BOOKING' && (
                    <div className="error-message">
                        {vehicleStepContent?.activeBookingContent}
                    </div>
                )}
                {serviceError &&
                    ((serviceError === 'REG_NOT_FOUND' && (
                        <div className="error-message">
                            {vehicleStepContent?.regVehicleNotFoundMessage}
                        </div>
                    )) ||
                        (serviceError === 'VIN_NOT_FOUND' && (
                            <div className="error-message">
                                {vehicleStepContent?.vinVehicleNotFoundMessage}
                            </div>
                        )))}
                {serviceError && serviceError === 'SERVICE_ERROR' && (
                    <div className="error-message">
                        {vehicleStepContent?.vehicleServiceErrorMessage}
                    </div>
                )}
                {serviceError && serviceError === 'INTERNAL_ERROR_MESSAGE' && (
                    <div className="error-message">
                        {vehicleStepContent?.internalErrorMessage}
                    </div>
                )}
            </>
        );
    }

    const noRefCheck = (e: any) => {
        if (e.target.value === 'Manual Mileage Entry') {
            setIsManualMileageEntry(true);
        } else {
            setIsManualMileageEntry(false);
            setOSBVehicleStepPayload({ vinMileage: e.target.value });
        }
    };

    return (
        <>
            <div
                className="osb-v3-vehicle-search-container"
                data-testId="vehicle-search-section"
            >
                {serviceError !== '' && renderErrorMessages()}
                <div
                    className="vehicle-search-section-title"
                    data-testid="vehicle-search-title"
                >
                    {vehicleStepContent?.vehicleSectionTitle}
                </div>
                <div
                    className="vehicle-search-section-desc"
                    data-testid="vehicle-search-deac"
                >
                    {parse(
                        (
                            vehicleStepContent?.vehicleSectionDescLabel || ''
                        ).toString()
                    )}
                </div>

                <div className="vehicle-search-input-section">
                    <div className="vehicle-vin-reg-input-section">
                        <div className="vin-input-label">
                            <div className="osb-v3-reg-num-or-vin-label">
                                {vehicleStepContent?.vinregistrationLabel}
                            </div>
                            <div
                                data-testid="VinAndRegInfoPopup"
                                className="vin-reg-info-icon"
                                onClick={() => renderHowToFindYourVin()}
                                tabIndex={0}
                                role="button"
                            >
                                <img src={InfoIconL} alt="Info" />
                            </div>
                        </div>

                        <div className="osb-v3-reg-vin-input-wrapper">
                            <FMInput
                                dataTestId="vin-reg-input"
                                type="text"
                                onChange={e => onVinRegChange(e)}
                                value={osbVehicleStep.vinRegNo}
                                state={vinRegErrorMsg ? 'error' : 'standard'}
                                label="VIN/Registration Number"
                                disabled={loading}
                            />
                        </div>
                        {vinRegErrorMsg ? (
                            <div className="osb-error-message">
                                {vinRegErrorMsg}
                            </div>
                        ) : (
                            <div className="vin-reg-input-placeholder">
                                {vehicleStepContent?.vinRegistrationPlaceholder}
                            </div>
                        )}
                    </div>
                    <div className="vehicle-mileage-input-section">
                        <div className="osb-v3-vehicle-mileage-label">
                            {vehicleStepContent?.mileageLabel}
                        </div>
                        {isManualMileageEntry ? (
                            <>
                                <div className="osb-v3-vehicle-mileage-wrapper">
                                    <FMInput
                                        dataTestId="mileage-input"
                                        type="text"
                                        onChange={e => onMileageChange(e)}
                                        value={osbVehicleStep.vinMileage}
                                        state={
                                            mileageError ? 'error' : 'standard'
                                        }
                                        label="Mileage"
                                        disabled={loading}
                                        ariaLabel="mileage-input"
                                    />
                                </div>
                                {mileageError ? (
                                    <div className="osb-error-message">
                                        {mileageError}
                                    </div>
                                ) : (
                                    <div className="mileage-input-placeholder">
                                        {vehicleStepContent?.mileagePlaceholder}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div>
                                <FMDropdown
                                    dataTestId="mileage-dropdown"
                                    onChange={e => noRefCheck(e)}
                                    options={
                                        Array.isArray(
                                            vehicleStepContent?.mileageRangeOption
                                        )
                                            ? vehicleStepContent.mileageRangeOption
                                            : []
                                    }
                                    state="standard"
                                    ariaLabel={'choose a mileage'}
                                    disabled={loading}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="cta-control-section">
                    <div className="continue-btn">
                        <FMButton
                            data-testid="continue-btn"
                            href=""
                            label={vehicleStepContent?.continueButtonLabel}
                            type="primary"
                            theme="light"
                            onClick={() => getVehicleData()}
                            disabled={loading}
                        />
                    </div>
                    <div className="do-not-know-details-btn">
                        <FMButton
                            href=""
                            label={vehicleStepContent?.iDontKnowDetailsLabel}
                            type="secondary"
                            theme="light"
                            disabled={loading}
                        />
                    </div>
                </div>
                {loading && isAuth && (
                    <div className="loading-indicator">
                        <div className="spinner"></div>
                        {vehicleStepContent?.loadingIndicatorLabel}
                    </div>
                )}
            </div>

            <FMModal
                name="osb-v3-vin-reg-modal-popup"
                onClose={onCloseVinRegInfo}
                isVisible={isVinRegModalVisible}
            >
                <VinInformation />
            </FMModal>
        </>
    );
};

export default VehicleSearchForm;
