import React from 'react';
import './feature-content-b.scss';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { PrimaryButton } from '../../../../common';
import { Heading } from '../../../../common/heading/heading';
import localStorageWrapper from '../../../../utils/local-storage-wrapper/localStorageWrapper';
import { replacePlaceholderByValue } from '../../../../utils/placeholder-util/replace-placeholder-by-value';

interface Props {
    backgroundImage: string;
    backgroundImageMobile?: string;
    backgroundImageAlt: string;
    title: string;
    subtitle: string;
    description: string;
    ctaLink: string;
    ctaLinkTarget: string;
    ctaAriaLabel: string;
    ctaLabel: string;
}

export const FeatureContentB = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const eventClick = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaHrefUrl
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (ctaAnalytic) {
            fireEvents('feature-content-b-click-event', undefined, {
                ctaType: ctaAnalytic,
            });
        }
    };
    let ctaHrefUrl = props.ctaLink;
    const splitUrl = ctaHrefUrl?.split('/').filter(slug => slug && slug !== '');
    let ctaAnalytic = splitUrl?.pop();
    ctaAnalytic = ctaAnalytic?.replaceAll('-', ' ');
    const vinPlaceholderRegex = /##(\w+)##/g;
    const isVin: RegExpExecArray | null = vinPlaceholderRegex.exec(
        props.ctaLink
    );
    const userVin = localStorageWrapper.getItem('USER_VIN');
    if (isVin && isVin[1].toLowerCase() === 'vin' && userVin) {
        ctaHrefUrl = replacePlaceholderByValue(props.ctaLink, {
            [isVin[1]]: userVin,
        });
    }

    return (
        <section
            className="feature-content-b"
            data-testid="feature-content-b-id"
            style={{
                backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${props.backgroundImage})`,
            }}
        >
            <span role="img" aria-label={props.backgroundImageAlt}></span>
            <div className="feature-content-b-body">
                {props.title && (
                    <Heading type="h2" className="feature-content-b-title">
                        {props.title}
                    </Heading>
                )}
                <p className="feature-content-b-subtitle">{props.subtitle}</p>

                <div className="feature-content-b-description">
                    {props.description}
                </div>
                <PrimaryButton
                    className="feature-content-b-link"
                    href={ctaHrefUrl}
                    onClick={eventClick}
                    target={props.ctaLinkTarget}
                    ariaLabel={props.ctaAriaLabel}
                    fill="fill"
                    color="light"
                    chevron={true}
                >
                    {props.ctaLabel}
                </PrimaryButton>
            </div>
        </section>
    );
};
