import { URLUtil } from '../utils/url-util/url-util';
import { NewAnalyticsService } from '../../services/new-analytics-service/new-analytics-service';
import { QuickLinksContent } from '../../models/experiencefragments/quick-links';
import { VehicleAttributes } from '../../models/vehicle-attributes';

export const ctaHref = (
    props: VehicleAttributes & QuickLinksContent
): string => {
    const replacer = {
        '%vin': props.vin || '',
        '%year': props?.year?.toString() || '',
        '%make': props.make?.toLowerCase() || '',
        '%model':
            props.seoKey?.toLowerCase() ||
            props?.model
                ?.replace(/[^a-zA-Z0-9]/g, ' ')
                .trim()
                .replace(/\s+/g, '-')
                .toLowerCase() ||
            '',
    };
    if (props.dynamicCTAPath) {
        const url = props.dynamicCTAPath.replace(
            /%vin|%year|%make|%model/gi,
            matched => {
                return replacer[matched as keyof typeof replacer];
            }
        );
        return URLUtil.omitEmptyParams(url);
    }
    return props.staticCTAPath;
};

export const handleListItemEvent = async (
    props: VehicleAttributes & QuickLinksContent,
    fireEvents: Function
) => {
    const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
        ctaHref(props)
    );
    if (referralExit) {
        NewAnalyticsService.fireReferralExitsEvent(fireEvents, referralExit);
    } else {
        fireEvents('quicklinks-onclick-event', undefined, {
            tileOnClickCtaInfo: { analyticsName: props.analyticsName },
        });
    }
};
