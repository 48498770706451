import React, { useContext } from 'react';
import { findByAlias, findByAliasV1, findByPathV1 } from '../../../routes';
import {
    DealerStepView,
    ServicesStepView,
    DeliveryOptionsView,
    DateTimeStepView,
    PersonalDetailsStepView,
    ReviewBookingView,
    BookingConfirmationView,
    RetrieveBookingStepView,
    RetrieveBookingSummaryView,
    CancelBookingConfirmationView,
    RetrieveAccessCodeView,
    MainWrapperView,
    PageNotFoundView,
} from '../../../views';
import { useParams } from 'react-router-dom';
import { ServiceLocationView } from '../../../views/owners-osb-view/service-location-view/service-location-view';
import ServerContext from '../../../contexts/serverContext';

export const OsbPathBookingAccessCodeStep = (): string => {
    return findByAlias('OsbBookingAccessCodeView');
};
export const OsbPathBookingCancelStep = (): string => {
    return findByAlias('OsbBookingCancelView');
};
export const OsbPathBookingConfirmationStep = (): string => {
    return findByAlias('OsbBookingConfirmationView');
};
export const OsbPathStartStep = (): string => {
    return findByAlias('OsbStartView');
};
export const OsbPathDealerStep = (): string => {
    return findByAlias('OsbDealerView');
};
export const OsbPathVehicleStep = (): string => {
    return findByAlias('OsbVehicleView');
};
export const OsbPathDeliveryStep = (): string => {
    return findByAlias('OsbDeliveryView');
};
export const OsbPathPersonalDetailsStep = (): string => {
    return findByAlias('OsbPersonalDetailsView');
};
export const OsbPathCalenderStep = (): string => {
    return findByAlias('OsbCalenderView');
};
export const OsbPathBookingRetrieveSummaryStep = (): string => {
    return findByAlias('OsbBookingRetrieveSummaryView');
};
export const OsbPathServicesStep = (): string => {
    return findByAlias('OsbServicesView');
};
export const OsbPathBookingRetrieveStep = (): string => {
    return findByAlias('OsbBookingRetrieveView');
};
export const OsbPathBookingReviewStep = (): string => {
    return findByAlias('OsbBookingReviewView');
};
export const OsbPathServiceLocationStep = (): string => {
    return findByAlias('OsbServiceLocationView');
};

const osbViewSelector = (componentName: string | undefined) => {
    switch (componentName) {
        case 'OsbStartView':
            return <></>;
        case 'OsbDealerView':
            return <DealerStepView />;
        case 'OsbServicesView':
            return <ServicesStepView />;
        case 'OsbDeliveryView':
            return <DeliveryOptionsView />;
        case 'OsbCalenderView':
            return <DateTimeStepView />;
        case 'OsbPersonalDetailsView':
            return <PersonalDetailsStepView />;
        case 'OsbBookingReviewView':
            return <ReviewBookingView />;
        case 'OsbBookingConfirmationView':
            return <BookingConfirmationView />;
        case 'OsbBookingRetrieveView':
            return <RetrieveBookingStepView />;
        case 'OsbBookingRetrieveSummaryView':
            return <RetrieveBookingSummaryView />;
        case 'OsbBookingCancelView':
            return <CancelBookingConfirmationView />;
        case 'OsbBookingAccessCodeView':
            return <RetrieveAccessCodeView />;
        case 'OsbServiceLocationView':
            return <ServiceLocationView />;
        default:
            return <PageNotFoundView />;
    }
};
export const OsbWrapper: React.FunctionComponent = (): JSX.Element => {
    const context = useContext(ServerContext);
    const { view: viewPath } = useParams<{ view: string }>();

    let basePath: string;
    const b = findByAliasV1(
        'OsbWrapper',
        context.domain,
        context.brand,
        context.currentLanguageRegionCode
    );

    if (b !== undefined) {
        if (typeof b == 'string') {
            basePath = b;
        } else {
            basePath = b[0];
        }
    } else basePath = 'OsbStartView'; // this should never happen
    let component;
    if (viewPath) {
        component = findByPathV1(
            basePath + '/' + viewPath,
            context.domain,
            context.brand,
            context.currentLanguageRegionCode
        );
    } else {
        component = 'OsbStartView';
    }
    return <MainWrapperView>{osbViewSelector(component)}</MainWrapperView>;
};
export default OsbWrapper;
