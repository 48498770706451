import React, { useEffect, useRef, useState } from 'react';
import useUserProfile from '../../../../../hooks/use-user-profile';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import { Link, PrimaryButton, SecondaryButton } from '../../../../common';
import stateOptions from '../../../../common/states/state';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useFinalizeDetailsContent } from '../../hooks/redeem-rebates-steps/use-finalize-details-content';
import { useAnalytics } from '../../../../../hooks/use-analytics';

import './finalize-details.scss';

interface Props {
    setCurrentStep(stepNumber: number): void;
    setFormData(data: RedeemFormData): void;
    redeemFormData: RedeemFormData;
}
interface FinalizeDetailsProps {
    name: string;
    placeholderText: string;
    value?: string;
    onInput: (event: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    isTouched: boolean;
    validate?: any;
}

interface FinalizeDetailsSelectProps {
    name: string;
    placeholderText: string;
    value: string;
    onChange: any;
    onBlur: any;
    isTouched: boolean;
    validate?: any;
    options: string[];
    errorMessage: string;
}

const InputField = (props: FinalizeDetailsProps) => {
    const errorMessage = props.isTouched ? props.validate()[props.name] : '';
    return (
        <div className="form__div">
            <input
                data-testid={`${props.name}-number`}
                name={props.name}
                placeholder=" "
                id={`${props.name}-id`}
                className="form__input"
                value={props.value}
                onInput={props.onInput}
                onBlur={props.onBlur}
                aria-label={`Enter ${props.placeholderText} ${errorMessage}`}
            />
            <label htmlFor={`${props.name}-id`} className="form__label">
                {props.placeholderText}
            </label>
            {errorMessage && (
                <div>
                    <span className="form__error">{errorMessage}</span>
                </div>
            )}
        </div>
    );
};

const SelectField = (props: FinalizeDetailsSelectProps) => {
    const error = props.isTouched ? props.validate()[props.name] : '';
    return (
        <div className="finalize-row-elements">
            <select
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                className="state-dropdown"
                onBlur={props.onBlur}
                aria-label={props.errorMessage}
            >
                <option value="State" disabled>
                    {props.placeholderText}
                </option>
                {props.options.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            {error && (
                <div>
                    <span className="form__error">{error}</span>
                </div>
            )}
        </div>
    );
};
export const FinalizeDetails = (props: Props) => {
    const TABLET_BREAKPOINT = 768;
    const profile = useUserProfile();
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const [fireEvents] = useAnalytics();
    const rebatesStepName = {
        rebatesStepName: 'finalize details',
    };
    let memberId = '';
    let email = '';
    useAnalytics(['redeem-rebates-generic'], undefined, rebatesStepName);

    const memberIdAnalytics = () => {
        fireEvents(
            'rr-finalize-details-cta-onclick-event',
            undefined,
            { ctaType: 'find member id' },
            false
        );
    };
    const downloadAnalytics = (ctaTypeParameter: any) => {
        fireEvents(
            'rr-finalize-details-cta-onclick-event',
            undefined,
            { ctaType: ctaTypeParameter },
            false
        );
    };
    const [buttonState, setButtonState] = useState('');

    const [mailRebates, setMailRebates] = useState(true);

    const [size, setSize] = useState(window.innerWidth);

    const isPointsClicked: boolean[] = [];

    const isRebateOfferName: any[] = [];

    props.redeemFormData.rebateData?.map(detail => {
        isPointsClicked.push(detail.isPointsClicked);
        isRebateOfferName.push(detail.rebateOfferName);
    });

    const [country, setCountry] = useState({
        currentCountryCode: '',
        currentLanguageRegionCode: '',
        brand: '',
    });

    const finalizeDetailsContent = useFinalizeDetailsContent();

    useEffect(() => {
        const {
            currentCountryCode,
            currentLanguageRegionCode,
            brand,
        } = new AppConfigurationService();
        setCountry({
            ...country,
            currentCountryCode,
            currentLanguageRegionCode,
            brand,
        });
    }, []);

    if (profile?.profile?.memberId) {
        memberId = profile.profile.memberId.toString();
    } else if (isPointsClicked.includes(true)) {
        memberId = props.redeemFormData.retainedInputMemberId;
    }

    if (profile?.profile?.email) {
        email = profile.profile.email;
    } else if (props.redeemFormData.finalizeDetails?.email) {
        email = props.redeemFormData.finalizeDetails.email;
    }

    const defaultDetails = profile
        ? profile.profile
        : props.redeemFormData.finalizeDetails;

    const [finalizeDetails, setfinalizeDetails] = useState({
        memberId: memberId,
        firstName: defaultDetails?.firstName,
        lastName: defaultDetails?.lastName,
        streetAddress: profile
            ? profile.profile.address1
            : props.redeemFormData.finalizeDetails?.streetAddress,
        city: defaultDetails?.city,
        stateDropdown: profile
            ? profile.profile.state
            : props.redeemFormData.finalizeDetails?.stateDropdown,
        zipCode: profile
            ? profile.profile.zip
            : props.redeemFormData.finalizeDetails?.zipCode,
        email: email,
        confirmEmail: '',
        isEmailCommunications:
            props.redeemFormData.finalizeDetails?.isEmailCommunications ||
            false,
    });

    const [isTouched, setTouched] = useState({
        memberId: false,
        firstName: false,
        lastName: false,
        streetAddress: false,
        city: false,
        stateDropdown: false,
        zipCode: false,
        email: false,
        confirmEmail: false,
        isEmailCommunications: false,
    });

    useEffect(() => {
        if (profile && props.redeemFormData.finalizeDetails?.firstName !== '') {
            setfinalizeDetails({
                memberId: props.redeemFormData.finalizeDetails?.memberId,
                firstName: props.redeemFormData.finalizeDetails?.firstName,
                lastName: props.redeemFormData.finalizeDetails?.lastName,
                streetAddress:
                    props.redeemFormData.finalizeDetails?.streetAddress,
                city: props.redeemFormData.finalizeDetails?.city,
                stateDropdown:
                    props.redeemFormData.finalizeDetails?.stateDropdown,
                zipCode: props.redeemFormData.finalizeDetails?.zipCode,
                email: props.redeemFormData.finalizeDetails?.email || '',
                confirmEmail: '',
                isEmailCommunications:
                    props.redeemFormData.finalizeDetails
                        ?.isEmailCommunications || false,
            });
        }
        const handleResize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleInputChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setfinalizeDetails({
            ...finalizeDetails,
            [name]: value,
        });
    };

    const finalizeDetailsInputClickAnalytics = () => {
        fireEvents(
            ['finalize-details-input-onclick-event'],
            'finalize-details-input-onclick-event',
            undefined,
            false
        );
    };

    const onTouch = (event: any) => {
        finalizeDetailsInputClickAnalytics();
        const name = event.target.name;
        setTouched({
            ...isTouched,
            [name]: true,
        });
    };

    const validateField = (field: any, condition: any, errorMessage: any) => {
        if (condition) {
            return errorMessage;
        } else {
            return '';
        }
    };

    const validate = () => {
        const {
            memberId,
            firstName,
            lastName,
            streetAddress,
            city,
            stateDropdown,
            zipCode,
            email,
            confirmEmail,
        } = finalizeDetails;
        const errors: { [key: string]: any } = {
            memberId: validateField(
                memberId,
                !memberId || !/^\d{10}$/i.test(memberId.toString()),
                finalizeDetailsContent.memberIdErrorMessage
            ),
            firstName: validateField(
                firstName,
                !firstName,
                finalizeDetailsContent.firstNameErrorMessage
            ),
            lastName: validateField(
                lastName,
                !lastName,
                finalizeDetailsContent.lastNameErrorMessage
            ),
            streetAddress: validateField(
                streetAddress,
                !streetAddress,
                finalizeDetailsContent.streetAddressErrorMessage
            ),
            city: validateField(
                city,
                !city,
                finalizeDetailsContent.cityErrorMessage
            ),
            stateDropdown: validateField(
                stateDropdown,
                stateDropdown === 'State',
                finalizeDetailsContent.stateErrorMessage
            ),
            zipCode: validateField(
                zipCode,
                !zipCode || !/^\d{5}$/i.test(zipCode),
                finalizeDetailsContent.zipCodeErrorMessage
            ),
            email: validateField(
                email,
                !email ||
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email),
                finalizeDetailsContent.emailErrorMessage
            ),
            confirmEmail: validateField(
                confirmEmail,
                !confirmEmail ||
                    email.toLowerCase() != confirmEmail.toLowerCase(),
                finalizeDetailsContent.reEnterEmailErrorMessage
            ),
            selectState: '',
            isValid: false,
            success: [],
        };

        Object.keys(errors).forEach(key => {
            if (key !== 'isValid' && key !== 'success' && errors[key] === '') {
                errors.success.push(key);
            }
        });

        const requiredSuccessCount =
            profile || isPointsClicked.includes(true) ? 10 : 9;
        errors.isValid = errors.success.length === requiredSuccessCount;

        return errors;
    };

    const continueCTAClickAnalytics = () => {
        //Continue CTA click analytics
        fireEvents(
            ['finalize-details-continue-onclick-event'],
            'finalize-details-continue-onclick-event',
            undefined,
            false
        );
    };

    const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        let inputMemberId: any = '';
        event.preventDefault();
        if (buttonState === 'submit') {
            continueCTAClickAnalytics();
            if (
                !profile &&
                finalizeDetails.memberId &&
                isPointsClicked.includes(true)
            ) {
                inputMemberId = finalizeDetails.memberId;
            } else {
                inputMemberId = props.redeemFormData.retainedInputMemberId;
            }
            props.setFormData({
                ...props.redeemFormData,
                finalizeDetails: {
                    ...finalizeDetails,
                },
                retainedInputMemberId: inputMemberId,
                showMemberId: isPointsClicked.includes(true),
            });
            props.setCurrentStep(5);
        }
    };

    return (
        <form className="form-finalize" onSubmit={handelSubmit}>
            <div className="finalize-download">
                <p className="finalize-form-text">
                    {finalizeDetailsContent.finalizeDetailsDescription}
                </p>
                {mailRebates ? (
                    <p className="finalize-download-text-link">
                        {finalizeDetailsContent.wantToMailText}{' '}
                        <a
                            className="finalize-download-link"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                setMailRebates(false);
                                downloadAnalytics('download rebates forms');
                            }}
                        >
                            {finalizeDetailsContent.downloadLinkText}
                        </a>
                    </p>
                ) : (
                    <>
                        <div className="finalize-Mail-Rebate-Forms">
                            <h3 className="finalize-download-header">
                                {finalizeDetailsContent.mailInTitle}
                            </h3>
                            <p className="finalize-download-text">
                                {finalizeDetailsContent.mailInDescription}
                            </p>
                            {props.redeemFormData.rebateData?.map(
                                (detail, index) => {
                                    if (detail.rebateOfferName) {
                                        return (
                                            <>
                                                <div
                                                    className="finalize-download-form"
                                                    key={detail.key}
                                                >
                                                    <p>
                                                        <strong>
                                                            {detail.key}
                                                        </strong>
                                                    </p>
                                                    <Link
                                                        aria-label={
                                                            detail.key +
                                                            '' +
                                                            finalizeDetailsContent.mailInDownloadLinkText
                                                        }
                                                        className="finalize-form-link"
                                                        onClick={() => {
                                                            downloadAnalytics(
                                                                'download mail-in form'
                                                            );
                                                        }}
                                                        rel="noopener noreferrer"
                                                        href={
                                                            process.env
                                                                .REACT_APP_AEM_BASE_URL +
                                                            `/content/dam/global-owner/${country.brand}/${country.currentCountryCode}/${country.currentLanguageRegionCode}/documents/redeem-rebates/${detail.pdf}`
                                                        }
                                                        target="_blank"
                                                    >
                                                        {
                                                            finalizeDetailsContent.mailInDownloadLinkText
                                                        }
                                                    </Link>
                                                </div>
                                                {!isRebateOfferName.includes(
                                                    ''
                                                ) && (
                                                    <hr
                                                        className={`line ${
                                                            props.redeemFormData
                                                                .rebateData &&
                                                            props.redeemFormData
                                                                .rebateData
                                                                .length -
                                                                1 ==
                                                                index
                                                                ? 'hide'
                                                                : ''
                                                        }`}
                                                    />
                                                )}
                                            </>
                                        );
                                    }
                                }
                            )}
                        </div>
                    </>
                )}
            </div>
            {isPointsClicked.includes(true) && (
                <>
                    <hr />
                    <div className="finalize-member-rebates-row">
                        {profile ? (
                            <div className="finalize-signin-memberid">
                                <p>
                                    <span className="finalize-signin-memberid-text">
                                        {
                                            finalizeDetailsContent.memberIdPlaceholderText
                                        }
                                        :
                                    </span>{' '}
                                    <span className="finalize-signin-memberid-id">
                                        {finalizeDetails.memberId}
                                    </span>
                                </p>
                            </div>
                        ) : (
                            <>
                                <div className="row-elements">
                                    <div className="finalize-memberid-text">
                                        {finalizeDetailsContent.memberIdTitle}
                                    </div>
                                    {!profile && size < TABLET_BREAKPOINT && (
                                        <div className="sign-middle-button">
                                            <SecondaryButton
                                                link={
                                                    finalizeDetailsContent.registerSignInUrl ||
                                                    '#$userSignIn'
                                                }
                                            >
                                                {
                                                    finalizeDetailsContent.registerSignInButtonText
                                                }
                                            </SecondaryButton>
                                        </div>
                                    )}
                                    <InputField
                                        name="memberId"
                                        placeholderText={
                                            finalizeDetailsContent.memberIdPlaceholderText
                                        }
                                        value={finalizeDetails.memberId}
                                        onInput={handleInputChange}
                                        onBlur={onTouch}
                                        isTouched={isTouched.memberId}
                                        validate={validate}
                                    />
                                    <div className="find-member-id">
                                        <Link
                                            className="find-member-id-text"
                                            onClick={memberIdAnalytics}
                                            tabIndex={0}
                                            href={
                                                finalizeDetailsContent.memberIdHelpLink
                                            }
                                            target="_blank"
                                        >
                                            {
                                                finalizeDetailsContent.memberIdHelpLinkText
                                            }
                                        </Link>
                                    </div>
                                </div>
                                {!profile && size >= TABLET_BREAKPOINT && (
                                    <div className="finalize-member-signin">
                                        <div className="finalize-member-button">
                                            <SecondaryButton
                                                link={
                                                    finalizeDetailsContent.registerSignInUrl ||
                                                    '#$userSignIn'
                                                }
                                            >
                                                {
                                                    finalizeDetailsContent.registerSignInButtonText
                                                }
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            <hr />
            <div className="finalize-member-rebates-row-info">
                <fieldset>
                    <p className="finalize-member-contact-info">
                        {finalizeDetailsContent.addressTitle}
                    </p>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <InputField
                                name="firstName"
                                placeholderText={
                                    finalizeDetailsContent.firstNamePlaceholderText
                                }
                                value={finalizeDetails.firstName}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                                isTouched={isTouched.firstName}
                                validate={validate}
                            />
                        </div>
                        <div className="finalize-row-elements">
                            <InputField
                                name="lastName"
                                placeholderText={
                                    finalizeDetailsContent.lastNamePlaceholderText
                                }
                                value={finalizeDetails.lastName}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                                isTouched={isTouched.lastName}
                                validate={validate}
                            />
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <InputField
                                name="streetAddress"
                                placeholderText={
                                    finalizeDetailsContent.streetAddressPlaceholderText
                                }
                                value={finalizeDetails.streetAddress}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                                isTouched={isTouched.streetAddress}
                                validate={validate}
                            />
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <InputField
                                name="city"
                                placeholderText={
                                    finalizeDetailsContent.cityPlaceholderText
                                }
                                value={finalizeDetails.city}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                                isTouched={isTouched.city}
                                validate={validate}
                            />
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <SelectField
                            name="stateDropdown"
                            value={finalizeDetails.stateDropdown || ''}
                            onChange={handleInputChange}
                            onBlur={onTouch}
                            isTouched={isTouched.stateDropdown}
                            validate={validate}
                            options={stateOptions}
                            placeholderText={
                                finalizeDetailsContent.statePlaceholderText
                            }
                            errorMessage={
                                finalizeDetailsContent.stateErrorMessage
                            }
                        />
                        <div className="finalize-row-elements">
                            <InputField
                                name="zipCode"
                                placeholderText={
                                    finalizeDetailsContent.zipCodePlaceholderText
                                }
                                value={finalizeDetails.zipCode}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                                isTouched={isTouched.zipCode}
                                validate={validate}
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <p className="finalize-member-contact-info">
                        {finalizeDetailsContent.emailTitle}
                    </p>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <InputField
                                name="email"
                                placeholderText={
                                    finalizeDetailsContent.emailPlaceholderText
                                }
                                value={finalizeDetails.email}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                                isTouched={isTouched.email}
                                validate={validate}
                            />
                        </div>
                    </div>
                    <div className="finalize-member-rebates-row-input">
                        <div className="finalize-row-elements">
                            <InputField
                                name="confirmEmail"
                                value={finalizeDetails.confirmEmail}
                                placeholderText={
                                    finalizeDetailsContent.reEnterEmailPlaceholderText
                                }
                                isTouched={isTouched.confirmEmail}
                                validate={validate}
                                onInput={handleInputChange}
                                onBlur={onTouch}
                            />
                        </div>
                    </div>
                    <div className="finalize-member-rebates-checkbox">
                        <div className="finalize-checkbox">
                            <input
                                name="isEmailCommunications"
                                type="checkbox"
                                id="finalize-checkbox"
                                checked={finalizeDetails.isEmailCommunications}
                                onChange={handleInputChange}
                                className="checkbox-finalize"
                            />
                        </div>
                        <label
                            className="finalize-checkbox-label"
                            htmlFor="finalize-checkbox"
                        >
                            {finalizeDetailsContent.mailCheckboxText}
                        </label>
                    </div>
                    <div className="finalize-checkbox-email">
                        <p className="checkbox-email">
                            {finalizeDetailsContent.noteText}
                        </p>
                    </div>
                </fieldset>
            </div>
            <div className="finalize-response-button">
                <PrimaryButton
                    ref={primaryButtonRef}
                    chevron={true}
                    color="dark"
                    fill="fill"
                    disabled={!validate().isValid}
                    onClick={() => setButtonState('submit')}
                >
                    {finalizeDetailsContent.continueButtonText}
                </PrimaryButton>
            </div>
        </form>
    );
};
