import React, { useEffect, useState } from 'react';
import { findByAlias } from '../../../routes';
import QuickLinksCommon from '../quick-links-common/quick-links-common';
import { QuickLinksContent } from '../../../models/experiencefragments/quick-links';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import useUserProfile from '../../../hooks/use-user-profile';
import { getSmartProviderStatus } from '../../../views/service-cx-view/service-cx';
import { ProfileWithVehiclesResponse } from '../../../models/profile-with-vehicles';

const QuickLinksServiceSchedule = (
    props: VehicleAttributes & QuickLinksContent
) => {
    const profile = useUserProfile();
    const [dealerCheck, setDealerCheck] = useState<boolean>(false);
    const serviceCXUrl = process.env.REACT_APP_SERVICE_CX_URL;

    async function isSmartProvider(profile: ProfileWithVehiclesResponse) {
        const smartProviderStatus = await getSmartProviderStatus(
            profile,
            props.vin
        );
        setDealerCheck(smartProviderStatus === 'X');
    }

    useEffect(() => {
        if (profile) {
            isSmartProvider(profile);
        }
    }, [profile]);

    const ctaVinHref = () => {
        if (dealerCheck && serviceCXUrl) {
            return findByAlias('GxpCustomUIView');
        } else if (props.vin && props.dynamicCTAPath) {
            return props.dynamicCTAPath.replace('%vin', props.vin);
        } else {
            return props.staticCTAPath;
        }
    };

    return (
        <QuickLinksCommon
            {...props}
            staticCTAPath={ctaVinHref()}
            dynamicCTAPath={ctaVinHref()}
        />
    );
};
export default QuickLinksServiceSchedule;
