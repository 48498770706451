import React from 'react';
import '../service-history.scss';
import './service-download-history.scss';
import './service-download-history-lincoln.scss';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { ServiceRecord } from '../../../../models/service-history';
import DateTimeUtil from '../../../utils/date-time-util/date-time-util';
import checkMark from '../../../../assets/check-mark.svg';
import { BrandUtil } from '../../../utils/brand-util/brand-util';
interface Props {
    vehicleAttributes: VehicleAttributes;
    records: ServiceRecord[];
    euRegion: boolean;
    naRegion: boolean;
    imgRegion: boolean;
    serviceHistoryContent: any;
}
export const ServiceDownloadHistory = (props: Props) => {
    const {
        records,
        euRegion,
        naRegion,
        imgRegion,
        vehicleAttributes,
        serviceHistoryContent,
    } = props;
    const { uomSpeed } = vehicleAttributes;
    const formattedDate = (RegionDate: string) => {
        if (naRegion || imgRegion) {
            return new DateTimeUtil().formatDate(RegionDate)?.currentRegionDate;
        }
        if (euRegion) {
            const modifiedDateString = RegionDate.split(/\//)
                .reverse()
                .join('/');
            return new DateTimeUtil().formatDate(modifiedDateString)
                ?.currentRegionDate;
        }
    };
    const { brandName } = new BrandUtil();
    const serviceHeaderLogo = (
        serviceHistoryItem: ServiceRecord,
        showApprovedText = false,
        footer = false
    ) => {
        let logoDisplay = '';
        let footerDisplay = '';
        const { dealer } = serviceHistoryItem.serviceLocation;
        if (
            (euRegion && dealer?.authorised === false) ||
            ((naRegion || imgRegion) &&
                serviceHistoryItem.serviceCategory === 'owner')
        ) {
            logoDisplay = 'hide';
        }
        if (footer) {
            footerDisplay = '-footer';
        }
        return (
            <div className="print-row">
                <div className="print-col">
                    <div>
                        <p className={`pdf-ford-col${footerDisplay}`}>
                            <img
                                className={`pdf-ford-col-logo${footerDisplay} ${logoDisplay}`}
                                alt="ford logo"
                            />
                            {showApprovedText && (
                                <span
                                    className={`pdf-ford-col-txt${footerDisplay} ${logoDisplay}`}
                                >
                                    {serviceHistoryContent.approvedText}
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const serviceHeader = (serviceHistoryItem: ServiceRecord) => {
        const { servicesPerformed } = serviceHistoryItem;
        let operations = '';
        if (naRegion || imgRegion) {
            if (servicesPerformed && servicesPerformed.operations) {
                const operationsData = servicesPerformed.operations;
                operationsData.forEach(operation => {
                    operations += operation['desc'] + ', ';
                });

                operations = operations.slice(0, -2);
                return operations;
            }
        }
        if (euRegion) {
            if (servicesPerformed) {
                return uomSpeed === 'KPH'
                    ? serviceHistoryItem.servicesPerformed.scheduled.kmsDesc
                    : serviceHistoryItem.servicesPerformed.scheduled.milesDesc;
            }
        }
        return '';
    };
    const printRecord = (
        serviceHistoryItem: ServiceRecord,
        pageNumber: number,
        index: number
    ) => {
        const { vin, make, model, year } = props.vehicleAttributes;
        const {
            serviceLocation,
            servicesPerformed,
            serviceAmount,
            vehicleMileage,
            serviceDate,
            notes,
            vehicleMileageUnit,
            inspections,
        } = serviceHistoryItem;
        const { dealer } = serviceLocation;
        const {
            scheduled,
            bodyAndPaintCheckDesc,
            operations,
            otherRelatedItems,
        } = servicesPerformed;
        return (
            <div className="print-page" key={index}>
                <div className="print-main-page">
                    <div className="print-header">
                        <img
                            alt={`${brandName}-logo`}
                            className="print-header-ford-logo"
                        />
                    </div>
                    <div
                        className="service-history-accordion-title-details-left service-download-history-title"
                        dangerouslySetInnerHTML={{
                            __html: serviceHistoryContent.serviceHistoryTitle,
                        }}
                    ></div>
                    <div className="service-download-history-header">
                        <span
                            className="service-download-history-accordion-title-details-left"
                            data-testid="accordion-header"
                        >
                            {serviceDate && (
                                <>
                                    <p className="service-download-history-accordion-details-item">
                                        {serviceDate}
                                    </p>
                                </>
                            )}
                        </span>
                        <span className="service-download-history-accordion-title-details-medium">
                            <p className="service-download-history-accordion-details-header">
                                {serviceHeader(serviceHistoryItem)}
                            </p>
                        </span>
                        {naRegion || imgRegion ? (
                            <span className="service-download-history-accordion-title-details-right">
                                <p className="service-download-history-accordion-details-header">
                                    {serviceHistoryItem.serviceCategory ===
                                    'owner'
                                        ? serviceHistoryContent.ownersEntryLabel
                                        : serviceHistoryContent.dealerServiceLabel}
                                </p>
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="service-history-print">
                        <div className="print-row">
                            <div className="print-col">
                                <label>{serviceHistoryContent.vehicle}</label>
                                <div>{`${make} ${model} (${year})`}</div>

                                <div className="service-download-history-vin">
                                    <span>
                                        {serviceHistoryContent.vinLabel}: {vin}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="print-row">
                            <div className="print-col">
                                <label>
                                    {serviceHistoryContent.serviceDate}
                                </label>
                                <span>{formattedDate(serviceDate)}</span>
                            </div>
                        </div>
                        <div className="print-row">
                            <div className="print-col">
                                <label>
                                    {serviceHistoryContent.mileageLabel}
                                </label>
                                <span>
                                    {new Intl.NumberFormat().format(
                                        vehicleMileage
                                    )}{' '}
                                    {vehicleMileageUnit}
                                </span>
                            </div>
                        </div>

                        {dealer && (
                            <>
                                <div className="print-row">
                                    <div className="print-col">
                                        <label>
                                            {
                                                serviceHistoryContent.locationNameLabel
                                            }
                                        </label>
                                        <div>
                                            {`${dealer.name}`}
                                            {(dealer.address ||
                                                dealer.countryCode) &&
                                                ','}
                                        </div>
                                        <div>
                                            {dealer.address &&
                                                `${dealer.address},`}
                                            {dealer.countryCode}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {serviceHeaderLogo(serviceHistoryItem, true)}
                        <div className="print-divider"> </div>
                        {euRegion && (
                            <>
                                {(scheduled.kmsDesc || scheduled.milesDesc) && (
                                    <>
                                        <div className="print-row">
                                            <div className="print-col">
                                                <label>
                                                    {
                                                        serviceHistoryContent.scheduledServiceCompleted
                                                    }
                                                </label>
                                                <span>
                                                    {uomSpeed === 'KPH'
                                                        ? scheduled.kmsDesc
                                                        : scheduled.milesDesc}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {bodyAndPaintCheckDesc && (
                            <div className="print-row">
                                <div className="print-col">
                                    <label>
                                        {
                                            serviceHistoryContent.bodyAndPaintCheckComplete
                                        }
                                    </label>
                                    <span>{bodyAndPaintCheckDesc}</span>
                                </div>
                            </div>
                        )}
                        {(naRegion || imgRegion) && (
                            <>
                                {operations?.length ? (
                                    <>
                                        <div className="print-row">
                                            <div className="print-col">
                                                <label>
                                                    {
                                                        serviceHistoryContent.servicesPerformedLabel
                                                    }
                                                </label>
                                                <div className="service-download-history-item operations">
                                                    {operations.map(
                                                        (operation, index) => (
                                                            <span
                                                                key={
                                                                    operation.id
                                                                }
                                                            >
                                                                {index !== 0 &&
                                                                    ', '}
                                                                {operation.desc}
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                {inspections && inspections.length ? (
                                    <>
                                        <div className="print-row">
                                            <div className="print-col">
                                                <label>
                                                    {
                                                        serviceHistoryContent.inspectionsPerformedLabel
                                                    }
                                                </label>
                                                <div className="service-download-history-item inspections">
                                                    {inspections.map(
                                                        inspection => (
                                                            <span
                                                                key={inspection}
                                                            >
                                                                {inspection}
                                                            </span>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                {notes && (
                                    <>
                                        <div className="print-row">
                                            <div className="print-col">
                                                <label>
                                                    {
                                                        serviceHistoryContent.notesLabel
                                                    }
                                                </label>
                                                <div>{notes}</div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="print-row">
                                    <div className="print-col">
                                        <label>
                                            {
                                                serviceHistoryContent.totalCostLabel
                                            }
                                        </label>
                                        <div className="service-download-history-item">
                                            ${serviceAmount}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {euRegion && (
                            <div className="print-row">
                                <div className="print-col">
                                    <label>
                                        {
                                            serviceHistoryContent.otherRelatedItems
                                        }
                                    </label>
                                    <span>
                                        <ul className="check-list">
                                            {otherRelatedItems.map(
                                                otherItems => (
                                                    <li key={otherItems.id}>
                                                        <img
                                                            src={checkMark}
                                                            alt="Check"
                                                            className="check-img"
                                                        ></img>
                                                        {otherItems.desc}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="print-row">
                            <div className="print-col">
                                {records && records?.length > 0 && (
                                    <div className="print-page-number">
                                        {
                                            serviceHistoryContent.downloadHistoryPageLabel
                                        }{' '}
                                        {pageNumber}{' '}
                                        {
                                            serviceHistoryContent.downloadHistoryOfLabel
                                        }{' '}
                                        {records.length}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="print-footer">
                    {serviceHeaderLogo(serviceHistoryItem, true, true)}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="print-only print-service-records">
                {records?.map((userService, index) =>
                    printRecord(
                        userService,
                        records.indexOf(userService) + 1,
                        index
                    )
                )}
            </div>
        </>
    );
};
