import { useContent } from '../../../../../hooks/use-server-content';

export interface VinRegLookupContentProps {
    vinRegLookup: boolean;
    invalidVINorRegnoErrorMessage: string;
    complementaryWFOErrorMessage: string;
    vehicleNotFoundErrorMessage: string;
}
export const useVinRegLookupContent = (): VinRegLookupContentProps => {
    const [content, getValueByName] = useContent('common', 'vin-reg-lookup');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as VinRegLookupContentProps;
};
