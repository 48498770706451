import { OSBAPIResponse } from '../service-orchestration/service-adapter';
import {
    ErrorResponse,
    transformErrorResponse,
} from '../../../components/sections/owners-osb/osb-utils';
import { DSLAPIService } from '../service-orchestration/service-dsl';
import { DSL_API_PATH } from '../../../constants';
import { ServiceBookingResponse } from '../../../models/osb-model/osb-review-booking';
import { transformServiceResponse } from '../../../components/sections/owners-osb/osb-service-adapters/service-booking-adapter';
import { BookingCredentials } from '../../../models/osb-model/osb-booking-credentials';
import { Bookable } from '../../../hooks/owners-osb/use-all-osb-state';
import { ExtractValueType } from '../../../components/sections/owners-osb/osb-bookable-utils';
import { OSBStep } from '../../../models/osb-model/osb-step';

export class OSBServiceBookingClient {
    public createServiceBooking(args: {
        serviceBookingRequest: Bookable;
        callback?: {
            (progressPercent: number): void;
            (arg0: number): void;
        };
        DSLAPIVersion?: ExtractValueType<OSBStep, 'DSLAPIVersion'>;
    }) {
        const { serviceBookingRequest, DSLAPIVersion = 'V1' } = args;
        const {
            dealerCode,
            ...bookableWithoutDealerCode
        } = serviceBookingRequest; // FIXME: API doesnt work currently with dealer code in paylaod.
        return new Promise<OSBAPIResponse<BookingCredentials>>(
            (resolve, reject) => {
                const transformedResponse: OSBAPIResponse<BookingCredentials> = {
                    value: {} as BookingCredentials,
                    responseError: {} as ErrorResponse,
                };
                new DSLAPIService()
                    .postServiceAPIOnDealerCode<
                        Omit<Bookable, 'dealerCode'>,
                        ServiceBookingResponse
                    >(
                        bookableWithoutDealerCode,
                        dealerCode,
                        DSLAPIVersion === 'V5'
                            ? DSL_API_PATH.DSL_OSB_CREATE_BOOKINGV5
                            : DSL_API_PATH.DSL_OSB_CREATE_BOOKING,
                        args.callback
                    )
                    .then(response => {
                        try {
                            transformedResponse.value = transformServiceResponse(
                                response
                            );
                            resolve(transformedResponse);
                        } catch (err) {
                            transformedResponse.responseError.errorMessage =
                                'SERVICE_BOOKING_CLIENT_TRANSFORMATION_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    })
                    .catch(_error => {
                        transformedResponse.responseError = transformErrorResponse(
                            _error
                        );
                        reject(transformedResponse.responseError);
                        console.error(
                            'Rejected the promise - ',
                            transformedResponse.responseError
                        );
                    });
            }
        );
    }
}
