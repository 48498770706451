import React from 'react';
import { PrimaryButton, Accordion } from '../../../common';
import { NotificationItemButtonProps } from '../notification-item/notification-item';
import './detailed-notification-item.scss';

export interface AccordionItem {
    accordionHeader: string;
    description: React.JSX.Element | string;
}
export interface ListItem {
    description: string;
}
export interface Props {
    headText?: string;
    subText?: string;
    iconPath?: string;
    detailedIconPath?: string;
    buttonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
    secondaryButtonProps?: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement>;
    notificationDetails?: ListItem[];
    notificationAccordionDetails?: AccordionItem[];
    notificationName?: string;
    category?: string;
}

export const DetailedNotificationItem = (props: Props) => {
    const notificationDetail = (idx: number, item: ListItem) => {
        return (
            <>
                <hr className="notifications-item-divider" />
                <div className="list-item" key={idx}>
                    <div className="list-icon">
                        <img src={props.detailedIconPath} alt="" />
                    </div>
                    <div className="list-text">{item?.description}</div>
                </div>
            </>
        );
    };
    const getAccordionHeader = (idx: number, item: AccordionItem) => {
        return (
            <>
                <div className="list-item" key={idx}>
                    <div className="list-icon">
                        <img src={props.detailedIconPath} alt="" />
                    </div>
                    <div className="list-text">{item?.accordionHeader}</div>
                </div>
            </>
        );
    };
    return (
        <div className="detailed-notification-item-wrapper">
            <div className="detailed-notification-item-header">
                <div className="icon-item">
                    <img src={props?.iconPath} alt="" />
                </div>
                <div className="details-item">
                    <div className="details-message">{props.headText}</div>
                    <div className="details-subtext">{props.subText}</div>
                </div>
            </div>

            {props && props.notificationDetails && (
                <div className="details-list">
                    {props.notificationDetails.map((item, idx) =>
                        notificationDetail(idx, item)
                    )}
                </div>
            )}

            {props && props.notificationAccordionDetails && (
                <>
                    <div className="details-list">
                        {props.notificationAccordionDetails.map((item, key) => (
                            <Accordion
                                key={key}
                                header={getAccordionHeader(key, item)}
                                panel={item.description}
                                className={'notification-accordion'}
                                index={key.toString()}
                                expandMultiplePanels={true}
                                cta={item.accordionHeader}
                                category={props.category}
                            />
                        ))}
                    </div>
                </>
            )}

            {props.buttonProps && (
                <div className="button-wrapper">
                    <PrimaryButton
                        href={props.buttonProps.link}
                        ariaLabel={props.buttonProps.ariaLabel}
                        target={props.buttonProps.linkTarget ? '_blank' : ''}
                        color="dark"
                        fill="fill"
                        chevron={true}
                        onClick={props.buttonProps.onClick}
                    >
                        {props.buttonProps.children}
                    </PrimaryButton>
                    {props.secondaryButtonProps &&
                        props.secondaryButtonProps.children && (
                            <PrimaryButton
                                className={'secondary-button-cta'}
                                href={props.secondaryButtonProps.link}
                                ariaLabel={props.secondaryButtonProps.ariaLabel}
                                target={
                                    props.secondaryButtonProps.linkTarget
                                        ? '_blank'
                                        : ''
                                }
                                color="light"
                                fill="fill"
                                chevron={true}
                                onClick={props.secondaryButtonProps.onClick}
                            >
                                {props.secondaryButtonProps.children}
                            </PrimaryButton>
                        )}
                </div>
            )}
        </div>
    );
};
