import React, { ReactNode } from 'react';
import { PageType } from '../../sections/view-template/page-type';
import { SecondaryButton } from '../../common';

import './smart-tile.scss';
import { SmartTileContent } from '../../../models/experiencefragments/smarttiles';
import { useAnalytics } from '../../../hooks/use-analytics';
import { TileOnClickCtaInfo } from '../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useHistory } from 'react-router';

export type HeaderType = 'good' | 'moderate' | 'severe' | 'blue' | 'none';

export interface SmartTileProps extends SmartTileContent {
    header: string;
    ctaHref: string;
    ctaTitle: string;
    headerType?: HeaderType;
    children?: ReactNode;
    className?: string;
    pageType?: PageType;
    analyticEventTitle?: string;
    displayModal?: () => void;
    onCtaHrefClick?: (e: React.MouseEvent) => void;
    displaySecondaryButton?: boolean;
    analyticsEventName?: string;
    isChecklistSaved?: boolean;
    isHeroRow?: boolean;
}

const SmartTile = (props: SmartTileProps) => {
    const [fireEvents] = useAnalytics();
    const tileName = props?.header;
    const ctaName = props?.ctaTitle;
    const secondaryButton =
        props.displaySecondaryButton === false
            ? props.displaySecondaryButton
            : true;
    const tileOnClickCtaInfo: TileOnClickCtaInfo = {
        tileName,
        ctaName,
    };
    const history = useHistory();
    const isRelativeCta = () => {
        return (
            props.ctaHref &&
            !props.ctaHref.startsWith('http://') &&
            !props.ctaHref.startsWith('https://')
        );
    };

    async function handleEvents(e: React.MouseEvent) {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            props.ctaHref
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents(
                'tiles-onclick-event',
                undefined,
                { tileOnClickCtaInfo },
                false
            );
            setTimeout(() => {
                props.analyticsEventName &&
                    fireEvents(
                        props.analyticsEventName,
                        undefined,
                        {
                            smashHomeClick: { infoGridServiceName: tileName },
                        },
                        false
                    );
            });
        }

        props.displayModal && props.displayModal();
        if (props.onCtaHrefClick) props.onCtaHrefClick(e);
        else if (isRelativeCta()) {
            history.push(props.ctaHref);
        }
    }

    return (
        <div className={['smart-tile', props.className].join(' ').trim()}>
            <div className={'information-grid-header-wrapper'}>
                {!props.isHeroRow && (
                    <h3
                        className={[
                            'smart-tile-header',
                            ` ${props.headerType || 'none'}`,
                        ]
                            .join(' ')
                            .trim()}
                    >
                        {props.header}
                    </h3>
                )}
                {props.isHeroRow && (
                    <h2
                        className={[
                            'smart-tile-header',
                            ` ${props.headerType || 'none'}`,
                        ]
                            .join(' ')
                            .trim()}
                    >
                        {props.header}
                    </h2>
                )}
            </div>
            {props.children}
            {secondaryButton && (
                <SecondaryButton
                    className="smart-tile-button"
                    link={
                        isRelativeCta() && props.ctaHref.startsWith('/')
                            ? props.ctaHref.substring(1)
                            : props.ctaHref
                    }
                    linkTarget={
                        !isRelativeCta() && props.ctaTargetBlank ? '_blank' : ''
                    }
                    onClick={(e: React.MouseEvent) => {
                        handleEvents(e);
                    }}
                    aria-label={
                        props.ctaAriaLabel ? props.ctaAriaLabel : props.ctaTitle
                    }
                    preventCtaDefault={isRelativeCta() || undefined}
                >
                    {props.ctaTitle}
                </SecondaryButton>
            )}
        </div>
    );
};

export default SmartTile;
