import React from 'react';
import VehicleChecklist, {
    HeaderType,
} from '../vehicle-checklist/vehicle-checklist';
import { MMOTAStatusResponse } from '../../../services/mmota-status-service/mmota-status.interface';
import { DSL_MMOTA_STATUS } from '../vehicle-checklist-mmota/mmota-util';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import './vehicle-checklist-software-update.scss';

interface Props {
    upToDateStatus: string;
    upToDateIconPath: string;
    successStatus: string;
    errorStatus: string;
    errorIconPath: string;
    pendingStatus: string;
    pendingIconPath: string;
    retryStatus: string;
    retryIconPath: string;
    inProgressStatus: string;
    inProgressIconPath: string;
    reminderStatus: string;
    reminderIconPath: string;
    settingsOffStatus: string;
    disabledStatus: string;
    unavailableStatus: string;
    otaApiStatus: MMOTAStatusResponse;
    displayModal: () => void;
}
const VehicleChecklistSoftwareUpdate = (
    props: Props & VehicleChecklistContent & VehicleAttributes
) => {
    const otaStatus = props.otaApiStatus;
    let currentStatus = otaStatus?.summary?.alertDescription;
    if (!currentStatus) {
        currentStatus = otaStatus?.status?.errorDescription;
    }
    const isAccessUnavailable = otaStatus?.error?.message === 'UserDisallowed';

    const ctaHref = (): string | null => null;

    let headerType: HeaderType = 'none';
    let icon = props.upToDateIconPath;
    let statusLabel = props.upToDateStatus;

    if (isAccessUnavailable) {
        headerType = 'severe';
        icon = props.errorIconPath;
        statusLabel = props.unavailableStatus;
    } else if (currentStatus === DSL_MMOTA_STATUS.upToDate) {
        icon = props.upToDateIconPath;
        statusLabel = props.upToDateStatus;
    } else if (currentStatus === DSL_MMOTA_STATUS.updateSuccessful) {
        headerType = 'good';
        icon = props.upToDateIconPath;
        statusLabel = props.successStatus;
    } else if (currentStatus === DSL_MMOTA_STATUS.updateWillRetry) {
        headerType = 'moderate';
        icon = props.retryIconPath;
        statusLabel = props.retryStatus;
    } else if (currentStatus === DSL_MMOTA_STATUS.updateInProgress) {
        headerType = 'moderate';
        icon = props.inProgressIconPath;
        statusLabel = props.inProgressStatus;
    } else if (currentStatus === DSL_MMOTA_STATUS.updateReminder) {
        headerType = 'moderate';
        icon = props.reminderIconPath;
        statusLabel = props.reminderStatus;
    } else if (currentStatus === DSL_MMOTA_STATUS.updatePending) {
        headerType = 'moderate';
        icon = props.pendingIconPath;
        statusLabel = props.pendingStatus;
    } else if (
        currentStatus ===
            DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended ||
        currentStatus === DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain ||
        currentStatus === DSL_MMOTA_STATUS.vehicleInoperable
    ) {
        headerType = 'severe';
        icon = props.errorIconPath;
        statusLabel = props.errorStatus;
    } else if (otaStatus?.ccsStatus?.ccsVehicleData?.toLowerCase() !== 'on') {
        headerType = 'severe';
        icon = props.errorIconPath;
        statusLabel = props.disabledStatus;
    } else if (otaStatus?.tappsData?.asuSettingsState !== 'ON') {
        headerType = 'severe';
        icon = props.errorIconPath;
        statusLabel = props.settingsOffStatus;
    }

    return (
        <>
            {otaStatus && (
                <VehicleChecklist
                    headerType={headerType}
                    type={props.cardTypes}
                    header={props.title}
                    ctaTitle={props.ctaTitle}
                    description={statusLabel}
                    iconPath={icon}
                    ctaPath={ctaHref()!}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                    onCtaHrefClick={props.displayModal}
                />
            )}
        </>
    );
};

export default VehicleChecklistSoftwareUpdate;
