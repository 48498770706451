import ServiceHandler from '../../../../../../services/service-handler';
import { CardState } from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';
import { replacePlaceholderByValue } from '../../../../../utils/placeholder-util/replace-placeholder-by-value';
export const getRecallDataFromAPI = (userSelectedVin: any) => {
    return ServiceHandler.RecallsService.request(userSelectedVin)
        .then(result => {
            return result;
        })
        .catch(() => {
            return null;
        });
};
const isNonEmptyArray = (element: any) =>
    Array.isArray(element) && element.length > 0;

const getRecallCount = (recallData: any) => {
    let recallCount = 0;
    if (isNonEmptyArray(recallData?.nhtsa)) {
        recallCount += recallData?.nhtsa?.length;
    }
    if (isNonEmptyArray(recallData?.recalls)) {
        recallCount += recallData?.recalls?.length;
    }
    return recallCount;
};
export const getRecallStatus = (recallData: any) => {
    const alertCount = getRecallCount(recallData);
    if (alertCount > 0) {
        return CardState.ALERT;
    } else {
        return CardState.NORMAL;
    }
};
export const getRecallDescription = (recallData: any, recallContent: any) => {
    const alertCount: any = getRecallCount(recallData);
    if (alertCount > 0) {
        const recallsMsg = recallContent?.recallText || '';
        return replacePlaceholderByValue(recallsMsg, {
            recallsCount: alertCount,
        });
    } else {
        return recallContent?.noRecallText;
    }
};
