import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDealerStep, useHttp, useOSBStep } from '../../../hooks/owners-osb';
import OsbUtilService from '../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../services/service-handler';
import { OSBSecondaryButton } from '../owners-osb/common/osb-secondary-button/osb-secondary-button';
import OSBBanner from '../owners-osb/osb-banner/osb-banner';
import './service-price-calculator.scss';
import { PRICE_CALCULATOR_CONSTANTS } from './price-calculator-constant';
import { OsbLoader } from '../../common/osb-loader/osb-loader';
import { getObjectFromAEMJson } from '../owners-osb/osb-utils';
import ReviewServicePrice from './review-service-price/review-service-price';
import {
    DealerDetails,
    DealerProfileInfo,
} from '../../../models/osb-model/osb-dealer-info';
import errorWarning from '../../../assets/error-warning.svg';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { triggerDealerSearchLoadAnalytics } from './analytics/price-calculator-analytics';
import { useAnalytics } from '../../../hooks/use-analytics';

const ServicePriceCalculator = () => {
    const { setOSBStepPayload } = useOSBStep();
    const { setOSBDealerStepPayload } = useDealerStep();
    const history = useHistory();
    const bookAService = () => {
        const bookAserviceUrl = getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.Osb_Home_Redirect_Url,
            priceCalculatorContent
        );
        history.push(bookAserviceUrl);
    };

    const findADealer = () => {
        const findADealerLink = getObjectFromAEMJson(
            PRICE_CALCULATOR_CONSTANTS.FIND_A_DEALER_LINK,
            priceCalculatorContent
        );
        if (serverSideService.isClientSide() && findADealerLink) {
            window.location.href = findADealerLink;
        }
    };
    const [osbThreeLetterCountryCode, setOsbThreeLetterCountryCode] = useState(
        ''
    );
    const [findMyVinContent, setFindMyVinContent] = useState<any>();
    const [priceCalculatorContent, setPriceCalculatorContent] = useState<any>();
    const [allOsbDealers, setAllOsbDealers] = useState<DealerProfileInfo[]>([]);
    const [errorMsgDealer, setErrorMsgDealer] = useState('');
    const [fireEvents] = useAnalytics();
    const { httpState, dispatch } = useHttp();

    const buildDealerDataListWithType = async (data: DealerDetails[]) => {
        const dealerProfileInfo: DealerProfileInfo[] = [];
        data.forEach((item: DealerDetails) => {
            if (item.dealerProfile) {
                item.dealerProfile.dealerCode =
                    item.dealerProfile.dealerCode.split('~').length > 1
                        ? item.dealerProfile.dealerCode.split('~')[0]
                        : item.dealerProfile.dealerCode;
                item.dealerProfile.dealerType =
                    item.dealerProfile.dealerCode.split('~').length > 1
                        ? item.dealerProfile.dealerCode.split('~')[1]
                        : '';
                dealerProfileInfo.push(item.dealerProfile);
            }
        });
        setAllOsbDealers(dealerProfileInfo);
        // setOSBDealerStepPayload({
        //     allOSBDealers: dealerProfileInfo,
        // });
        setOSBStepPayload({ allOSBDealers: dealerProfileInfo });
    };

    const marketDealers = async () => {
        await ServiceHandler.OsbDslDealerDetailsService.getDealerDetails()
            .then(results => {
                setOSBDealerStepPayload({
                    filterDeliveryServices:
                        results.value.filterDeliveryServices || [],
                    filterDidServices: results.value.filterDidServices || [],
                    filterRegularServices:
                        results.value.filterRegularServices || [],
                });
                buildDealerDataListWithType(results.value.dealers);

                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.error.message;
                    setErrorMsgDealer('SERVICE_ERROR');
                    dispatch({
                        type: 'ERROR',
                        error: errorMessage,
                    });
                }
            });
    };

    useEffect(() => {
        triggerDealerSearchLoadAnalytics(fireEvents);
        dispatch({ type: 'REQUEST' });
        fetchVehicleStepContent('vehicle-step-more-elements');
        fetchPriceCalculatorContent('price-calculator');
    }, []);

    //fetch AEM content
    const fetchVehicleStepContent = async (modal: string) => {
        await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modal
        )
            .then(results => {
                setFindMyVinContent(results.elements);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({
                    type: 'ERROR',
                    error: error.message,
                });
            });
    };
    const fetchPriceCalculatorContent = async (modal: string) => {
        await ServiceHandler.PriceCalculatorContentService.getPriceCalculatorContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modal
        )
            .then((results: { elements: any[] }) => {
                const geoCountryCodeAEM = results?.elements.find(
                    (e: any) =>
                        e.name === PRICE_CALCULATOR_CONSTANTS.Geo_Country_Code
                )?.value;
                setOSBStepPayload({
                    geoCountryCode: geoCountryCodeAEM
                        ? geoCountryCodeAEM
                        : OsbUtilService.getAppConfiguration().countryCode,
                });
                setPriceCalculatorContent(results.elements);

                const threeLetterCountryCodeFromAEM = results?.elements.find(
                    (e: any) =>
                        e.name ===
                        PRICE_CALCULATOR_CONSTANTS.OSB_THREE_LETTER_COUNTRY_CODE
                )?.value;
                const osbThreeLetterCountryCode: string = threeLetterCountryCodeFromAEM
                    ? threeLetterCountryCodeFromAEM.trim().toUpperCase()
                    : OsbUtilService.getAppConfiguration().threeLetterCountryCode.toUpperCase();
                setOsbThreeLetterCountryCode(osbThreeLetterCountryCode);
                marketDealers();
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                dispatch({
                    type: 'ERROR',
                    error: error.message,
                });
            });
    };
    return (
        <>
            {httpState.isLoading ? (
                <OsbLoader />
            ) : (
                <>
                    <div className="service-pc-container">
                        <div className="pc-top-banner">
                            <OSBBanner
                                applicationName={getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.PC_Top_Banner_Text,
                                    priceCalculatorContent
                                )}
                            />
                        </div>

                        {errorMsgDealer.length > 0 && (
                            <div className="service-error">
                                <span>
                                    <img
                                        className="osb-error-icon"
                                        src={errorWarning}
                                        alt="error icon"
                                    />{' '}
                                    &nbsp;
                                    {errorMsgDealer}
                                </span>
                            </div>
                        )}

                        <div>
                            <ReviewServicePrice
                                findMyVinContent={findMyVinContent}
                                priceCalculatorContent={priceCalculatorContent}
                                allOsbDealers={allOsbDealers}
                                osbThreeLetterCountryCode={
                                    osbThreeLetterCountryCode
                                }
                            />
                        </div>
                        <div className="pc-bottom-banner">
                            <div className="pc-bottom-banner-text">
                                {getObjectFromAEMJson(
                                    PRICE_CALCULATOR_CONSTANTS.PC_Bottom_Banner_Text,
                                    priceCalculatorContent
                                )}
                            </div>
                            <div className="pc-button-wrapper">
                                <div className="book-a-service">
                                    <OSBSecondaryButton
                                        direction="right"
                                        type="filled"
                                        text={getObjectFromAEMJson(
                                            PRICE_CALCULATOR_CONSTANTS.Book_A_Service_CTA,
                                            priceCalculatorContent
                                        )}
                                        onClickHandler={() => bookAService()}
                                    />
                                </div>
                                <div className="find-a-dealer">
                                    <OSBSecondaryButton
                                        direction="right"
                                        type="filled"
                                        text={getObjectFromAEMJson(
                                            PRICE_CALCULATOR_CONSTANTS.Find_A_Dealer_CTA,
                                            priceCalculatorContent
                                        )}
                                        onClickHandler={() => findADealer()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ServicePriceCalculator;
