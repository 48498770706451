import { useState, useEffect } from 'react';
import YfaService from '../services/yfa-service/yfa-service';
import { AuthenticationState } from './use-authentication';
import { VehicleChecklistFragment } from '../models/experiencefragments/vehicle-checklist';
import { VehicleAttributes } from '../models/vehicle-attributes';

const useYfaEligibility = (
    vehicleChecklistContent: VehicleChecklistFragment | null,
    vehicleAttributes: VehicleAttributes,
    authenticationState: AuthenticationState
) => {
    const [isYfaEligible, setYfaEligible] = useState<boolean>(false);
    const isYfaEligibleModel =
        vehicleChecklistContent?.yfaEligibleModels?.some(item =>
            vehicleAttributes?.model?.includes(item.modelAlias)
        ) && vehicleAttributes?.engineType === 'BEV';

    useEffect(() => {
        if (
            authenticationState === AuthenticationState.Authenticated &&
            isYfaEligibleModel &&
            vehicleAttributes.vin
        ) {
            new YfaService()
                .isEligible(vehicleAttributes.vin)
                .then(response => setYfaEligible(response?.eligible));
        }
    }, [vehicleAttributes.vin, authenticationState, isYfaEligibleModel]);

    return isYfaEligible;
};

export default useYfaEligibility;
