import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, InputField } from '../../../../../common';
import { finalFormInput, finalFormDropDowns } from '../formField';
import './ute-final.ford.scss';
import { UTE_PAGE_TITLE, UTE_ROUTES } from '../../types';
import { UteFooter } from '../ute-create-trailer-footer/ute-create-trailer-footer';
import { ActivityIndicator } from '../../../../../common/activity-indicator/activity-indicator';
import { UteContext } from '../../../../../../views/ultimate-towing-experience-view/context/ute-context';
import { useHistory } from 'react-router-dom';
import {
    DSL_API_PATH,
    DSL_BASE_URL,
    SESSION_KEY_API_CACHE,
} from '../../../../../../constants';
import { CacheService } from '../../../../../../services/cache-service/cache-service';
import { UtePostService } from '../../../../../../services/ute-service/ute-services';
import { getVin } from '../../../../../utils/getVin-util/getVin-util';
interface Props {
    formContent: any;
    handleModalCancel: () => void;
}
export const UteFinalForm = (props: Props) => {
    const [contentFlag, setContentFlag] = useState<boolean>(true);
    const {
        addNewTrailerLabel,
        addStepTwoLabel,
        apiErrorMsg,
        errorMessage,
    } = props.formContent;
    const inputField = finalFormInput(props.formContent);
    const dropDowns = finalFormDropDowns(props.formContent);
    const { uteContextFormData, setUteContextFormData } = useContext(
        UteContext
    );
    const smartNavPattern = `^(0|[1-9]\\d*)(\\.\\d+)?$`;
    const history = useHistory();
    const userSelectedVin = getVin();
    const [errors, setErrors] = useState<{ [key: string]: string }>({
        type: '',
        brakeType: '',
        smartNavLength: '',
        smartNavWidth: '',
        smartNavHeight: '',
        smartNavWeight: '',
        distance: '',
    });
    const [completeDisable, setCompleteDisable] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(false);
    const [errorMsg, setErrroMsg] = useState<string>('');
    useEffect(() => {
        if (errorMessage !== undefined && contentFlag) {
            validateInputValues();
            setContentFlag(false);
        }
    }, [errorMessage]);
    const isLoading = (state: boolean, errorMsg: string) => {
        setLoader(state);
        setErrroMsg(errorMsg);
    };

    const handleCompleteButton = () => {
        for (const [key, value] of Object.entries(
            uteContextFormData.trailerList
        )) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                if (value !== '') {
                    if (errors[key] !== '') {
                        setCompleteDisable(true);
                        break;
                    } else {
                        setCompleteDisable(false);
                    }
                } else {
                    setCompleteDisable(true);
                    break;
                }
            }
        }
    };
    const validateInputValues = () => {
        for (const [key] of Object.entries(uteContextFormData.trailerList)) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                if (uteContextFormData?.trailerList[key] !== '') {
                    const values = uteContextFormData?.trailerList[key].split(
                        ' '
                    )[0];
                    if (values.match(smartNavPattern)) {
                        errors[key] = '';
                    } else {
                        errors[key] = errorMessage;
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (uteContextFormData.name == '') {
            history.push(UTE_ROUTES.BASIC_INFO);
        }
        handleCompleteButton();
    });

    /** handle dropdown change */
    const handleDropDownChange = (
        selectedOption: string,
        index: number,
        value: string | undefined,
        dropDownName: string,
        options: any
    ) => {
        const optionValue = options.find(
            (item: any) => item.label === selectedOption
        );
        const selectedValue = optionValue.value;
        setUteContextFormData({
            ...uteContextFormData,
            trailerList: {
                ...uteContextFormData.trailerList,
                [dropDownName]: selectedValue,
            },
        });
    };

    /* Input fields onChange */
    const onChange = (e: any, unit: string) => {
        const value = e.target.value;
        if (e.type === 'blur') {
            const obj = inputField.find(
                (obj: { name: string }) => obj.name === e.target.name
            );

            if (value.match(obj?.pattern)) {
                setErrors({ ...errors, [e.target.name]: '' });
                setUteContextFormData({
                    ...uteContextFormData,
                    trailerList: {
                        ...uteContextFormData.trailerList,
                        [e.target.name]: value + ' ' + unit,
                    },
                });
            } else {
                setErrors({ ...errors, [e.target.name]: `${errorMessage}` });
                setUteContextFormData({
                    ...uteContextFormData,
                    trailerList: {
                        ...uteContextFormData.trailerList,
                        [e.target.name]: value,
                    },
                });
                if (value === '') {
                    setErrors({
                        ...errors,
                        [e.target.name]: `${errorMessage}`,
                    });
                }
            }
        }
        if (e.type === 'change') {
            setUteContextFormData({
                ...uteContextFormData,
                trailerList: {
                    ...uteContextFormData.trailerList,
                    [e.target.name]: value,
                },
            });
        }
        if (e.type === 'focus') {
            const valueOnFocus = e.target.value;
            const valueOnFocusReplaced = valueOnFocus.replace(unit, '');
            setUteContextFormData({
                ...uteContextFormData,
                trailerList: {
                    ...uteContextFormData.trailerList,
                    [e.target.name]: valueOnFocusReplaced.trim(),
                },
            });

            if (valueOnFocusReplaced.trim() <= 0) {
                setUteContextFormData({
                    ...uteContextFormData,
                    trailerList: {
                        ...uteContextFormData.trailerList,
                        [e.target.name]: '',
                    },
                });
            }
        }
    };

    const removeUnits = () => {
        const stringRegex = /[a-z,A-Z]+/g;
        const postData = { ...uteContextFormData.trailerList };
        postData['trailerFrontalArea'] = Number(postData['trailerFrontalArea']);
        postData['trailerCouplerAxle'] = '';
        postData['brakeEffort'] = '1';
        postData['brakeGain'] = '5';
        postData['trailerCouplerAxle'] = '';
        postData['smartNavLength'] =
            postData['smartNavLength'].replace(stringRegex, '').trim() || '';
        postData['smartNavWeight'] =
            postData['smartNavWeight'].replace(stringRegex, '').trim() || '';
        postData['smartNavHeight'] =
            postData['smartNavHeight'].replace(stringRegex, '').trim() || '';
        postData['smartNavWidth'] =
            postData['smartNavWidth'].replace(stringRegex, '').trim() || '';
        postData['distance'] =
            postData['distance'].replace(stringRegex, '').trim() || '';
        return postData;
    };
    const handleCompleteClick = () => {
        const cachedURL = `${DSL_BASE_URL +
            DSL_API_PATH.UTE_POST_SERVICES +
            '?vin=' +
            userSelectedVin}`;
        new CacheService().evictFromCache(SESSION_KEY_API_CACHE, cachedURL);
        isLoading(true, '');
        const updatedContextData = removeUnits();
        UtePostService(userSelectedVin, updatedContextData)
            .then(function(response) {
                if (response) {
                    history.push({
                        pathname: UTE_ROUTES.HOME,
                        state: {
                            data: true,
                        },
                    });
                    isLoading(false, '');
                    return response;
                }
            })
            .catch((error: any) => {
                console.error(error);
                isLoading(false, apiErrorMsg);
            });
    };

    const handleBackClick = () => {
        history.push({
            pathname: UTE_ROUTES.BASIC_INFO,
        });
    };

    return loader === true ? (
        <ActivityIndicator className="full-height" />
    ) : (
        <div className="ute-final-form">
            <div className="ute-heading-section">
                <h1 className="ute-header">
                    {addNewTrailerLabel}
                    <span>{addStepTwoLabel}</span>
                </h1>
            </div>

            <div className="dropdown-input-wrapper">
                {dropDowns?.map(input => (
                    <div
                        key={input.id}
                        className={`ute-dropdown-${input.id} ute-dropdown`}
                    >
                        <Dropdown
                            key={input.id}
                            label={input.label}
                            noLabel={true}
                            className={input.className}
                            options={input.options}
                            value={
                                input.options.find(
                                    opt =>
                                        opt.value ==
                                        uteContextFormData.trailerList[
                                            input.className
                                        ]
                                )?.label || ''
                            }
                            onChange={(e, index, value) =>
                                handleDropDownChange(
                                    e,
                                    index,
                                    value,
                                    input.className,
                                    input.options
                                )
                            }
                        />
                    </div>
                ))}

                {inputField?.map(input => (
                    <div
                        key={input.id}
                        className={`ute-input-container-${input.id} ute-input-container`}
                    >
                        <InputField
                            className="trailer-form-textField"
                            label={input.label}
                            ariaLabel={input.label}
                            name={input.name}
                            key={input.id}
                            value={uteContextFormData.trailerList[input.name]}
                            fdsStyle={true}
                            maxLength={input.maxLength}
                            errorMessage={errors[input.name]}
                            onChange={e => onChange(e, input.unit)}
                            onBlur={e => onChange(e, input.unit)}
                            onFocus={e => onChange(e, input.unit)}
                        />

                        <div className="input-desc">{input.description}</div>
                    </div>
                ))}
            </div>
            <UteFooter
                title={UTE_PAGE_TITLE.FINAL_FORM}
                handleDisable={completeDisable}
                isLoading={isLoading}
                handleModalCancel={props.handleModalCancel}
                handleFinalCompleteClick={handleCompleteClick}
                handleFinalBackClick={handleBackClick}
            />
            {errorMsg != '' ? (
                <div className="apiErrorMsg">{errorMsg}</div>
            ) : (
                ''
            )}
        </div>
    );
};
