import React from 'react';
import { PrimaryButton, SecondaryButton } from '../../common';
import './login-register.scss';

interface Props {
    contentLabels: {
        loginOrRegisterFormHeading: string;
        loginOrRegisterFormDescription: string;
        signinButtonLabel: string;
        registerButtonLabel: string;
    };
    loginClick: (action: string) => void;
}

export const LoginSection = (props: Props) => {
    const {
        loginOrRegisterFormHeading,
        loginOrRegisterFormDescription,
        signinButtonLabel,
        registerButtonLabel,
    } = props.contentLabels;

    const onSignInClick = (action: string) => {
        props.loginClick(action);
    };

    return (
        <div className="login-section">
            <h2>{loginOrRegisterFormHeading}</h2>
            <p className="login-desc">{loginOrRegisterFormDescription}</p>
            <div className={'login-btn-wrap'}>
                <PrimaryButton
                    className="signin-button"
                    role="link"
                    color="dark"
                    fill="fill"
                    chevron={true}
                    onClick={() => onSignInClick('signin')}
                >
                    {signinButtonLabel}
                </PrimaryButton>
                <SecondaryButton
                    className="register-button"
                    onClick={() => onSignInClick('register')}
                >
                    {registerButtonLabel}
                </SecondaryButton>
            </div>
        </div>
    );
};
