import React from 'react';
import { PrimaryButton } from '../../../../../common';
import { UTE_PAGE_TITLE } from '../../types';
import './ute-create-trailer-footer.ford.scss';
import {
    UltimateTowingExperienceContentProps,
    UteFormElementsProps,
} from '../../../../../../models/service-maintenance-models/ute';
import { useContentFragment } from '../../../../../utils/aem-content-utils/use-content-fragment';

interface Props {
    title: string;
    handleDisable: boolean;
    isLoading: (state: boolean, errorMsg: string) => void;
    handleModalCancel: () => void;
    handleBasicContinueClick?: () => void;
    handleBasicBackClick?: () => void;
    handleFinalCompleteClick?: () => void;
    handleFinalBackClick?: () => void;
}

export const UteFooter = (props: Props) => {
    const profileContent: UltimateTowingExperienceContentProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/profile'
    );
    const formContent: UteFormElementsProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/ute-form-elements'
    );
    const {
        saveAndContinueCTA,
        cancelCta,
        completeCta,
        goBackCta,
    } = formContent;
    const { faqDescriptionWithCta } = profileContent;
    const handleClickContinue = (title: string) => {
        if (title === UTE_PAGE_TITLE.BASIC_INFO) {
            if (props.handleBasicContinueClick)
                props.handleBasicContinueClick();
        } else if (title === UTE_PAGE_TITLE.FINAL_FORM) {
            if (props.handleFinalCompleteClick)
                props.handleFinalCompleteClick();
        }
    };

    const handleClickGoBack = (title: string) => {
        if (title === UTE_PAGE_TITLE.BASIC_INFO) {
            if (props.handleBasicBackClick) props.handleBasicBackClick();
        } else if (title === UTE_PAGE_TITLE.FINAL_FORM) {
            if (props.handleFinalBackClick) props.handleFinalBackClick();
        }
    };

    const saveAndContinueButton = () => (
        <PrimaryButton
            className="ute-save-button"
            ariaLabel={saveAndContinueCTA}
            target=""
            color="dark"
            disabled={props.handleDisable}
            fill="fill"
            chevron={true}
            onClick={() => handleClickContinue(props.title)}
        >
            {saveAndContinueCTA}
        </PrimaryButton>
    );
    const completeButton = () => (
        <PrimaryButton
            className="ute-complete-button"
            ariaLabel={completeCta}
            target=""
            color="dark"
            disabled={props.handleDisable}
            fill="fill"
            chevron={false}
            onClick={() => handleClickContinue(props.title)}
        >
            {completeCta}
        </PrimaryButton>
    );
    const goBackButton = () => (
        <PrimaryButton
            className="ute-goBack-button"
            ariaLabel={'go-Back-button'}
            target=""
            color="dark"
            fill="outline"
            chevron={true}
            chevronDirection="left"
            onClick={() => handleClickGoBack(props.title)}
        >
            {goBackCta}
        </PrimaryButton>
    );

    const handleModalCancel = () => {
        props.handleModalCancel();
    };
    const cancelButton = () => {
        return (
            <button
                className="ute-cancel-button"
                aria-label={cancelCta}
                onClick={() => handleModalCancel()}
            >
                {cancelCta}
            </button>
        );
    };
    return (
        <section className="ute-footer-section">
            <>
                <div className="ute-trailer-footer">
                    <div className="faq_wrap" data-testid={'faq'}>
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: faqDescriptionWithCta,
                            }}
                        ></div>
                    </div>
                    <div
                        className={
                            props.title === UTE_PAGE_TITLE.FINAL_FORM
                                ? 'ute-cta-button-reverse'
                                : 'ute-cta-button'
                        }
                    >
                        {cancelButton()}
                        <div
                            className={
                                props.title === UTE_PAGE_TITLE.FINAL_FORM
                                    ? 'ute-cta-button-reverse_wrap'
                                    : ''
                            }
                        >
                            {props.title === UTE_PAGE_TITLE.BASIC_INFO
                                ? saveAndContinueButton()
                                : ''}
                            {props.title === UTE_PAGE_TITLE.FINAL_FORM
                                ? goBackButton()
                                : ''}
                            {props.title === UTE_PAGE_TITLE.FINAL_FORM
                                ? completeButton()
                                : ''}
                        </div>
                    </div>
                </div>
            </>
        </section>
    );
};
