import React, { useState, Fragment } from 'react';
import './upcoming-livestream.scss';
import { LivestreamLessons, ScheduleItemsInterface } from '../gvt-interfaces';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { ModelNotSupportedProps } from '../../../../views/live-stream-view/hook/use-live-stream-view';
import { LivestreamUpcomingSchedule } from './livestream-upcoming-schedule/livestream-upcoming-schedule';
import { InfoIconToolTip } from '../../../common/info-icon-tooltip/info-icon-tooltip';

interface Props {
    profile?: ProfileWithVehiclesResponse;
    vehicleAttributes?: VehicleAttributes;
    i18nAEM?: LivestreamLessons;
    modelNotSupportedContent?: ModelNotSupportedProps | null;
    eligibleLivestreamVehicles?: string;
}

const UpcomingLivestreamHeader = ({
    props,
    liveStreami18nAEM,
}: {
    props: Props;
    liveStreami18nAEM: any;
}) => {
    const renderTitle = () => {
        if (props.vehicleAttributes && props.modelNotSupportedContent) {
            return props.modelNotSupportedContent.upcomingLivestreamTitle;
        }
        if (props.i18nAEM) {
            return liveStreami18nAEM?.title;
        }
        return props.modelNotSupportedContent?.livestreamTitle;
    };

    const renderDescription = () => {
        if (
            !props.vehicleAttributes &&
            props.modelNotSupportedContent &&
            props.eligibleLivestreamVehicles
        ) {
            return (
                <div className="upcoming-schedule-description-tooltip">
                    <div className="upcoming-schedule-description-wrapper">
                        {props.vehicleAttributes &&
                        props.modelNotSupportedContent
                            ? props.modelNotSupportedContent
                                  .upcomingLivestreamDescription
                            : props.i18nAEM
                            ? liveStreami18nAEM?.title
                            : props.modelNotSupportedContent
                                  ?.livestreamDescription}
                        <InfoIconToolTip
                            tooltipInfoIconClass={'dark'}
                            tooltipHeader={
                                props.modelNotSupportedContent.tooltipHeader
                            }
                            tooltipContent={props.eligibleLivestreamVehicles}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className="upcoming-schedule-description">
                {props.vehicleAttributes && props.modelNotSupportedContent
                    ? props.modelNotSupportedContent
                          .upcomingLivestreamDescription
                    : props.i18nAEM
                    ? liveStreami18nAEM?.title
                    : props.modelNotSupportedContent?.livestreamDescription}
            </div>
        );
    };

    return (
        <div className="upcoming-livestream-header-container">
            <div className="upcoming-schedule-subtitle">
                {props.modelNotSupportedContent
                    ? ''
                    : liveStreami18nAEM?.subtitle}
            </div>
            <div className="upcoming-schedule-title">{renderTitle()}</div>
            {renderDescription()}
        </div>
    );
};

const LivestreamScheduleContainer = ({
    props,
    scheduleItemList,
    liveStreami18nAEM,
}: {
    props: Props;
    scheduleItemList: any[];
    liveStreami18nAEM: any;
}) => {
    if (props.vehicleAttributes && scheduleItemList.length > 0) {
        return (
            <LivestreamUpcomingSchedule
                scheduleItems={scheduleItemList}
                modelNotSupportedContent={props.modelNotSupportedContent}
                vehicleAttributes={props.vehicleAttributes}
            />
        );
    }

    if (!props.vehicleAttributes && !props.i18nAEM) {
        return (
            <LivestreamUpcomingSchedule
                scheduleItems={scheduleItemList.slice(0, 3)}
                modelNotSupportedContent={props.modelNotSupportedContent}
            />
        );
    }

    return (
        <LivestreamUpcomingSchedule
            scheduleItems={scheduleItemList.slice(0, 3)}
            liveStreami18nAEM={liveStreami18nAEM}
        />
    );
};

export const UpcomingLivestream = (props: Props) => {
    const [scheduleItemList] = useState<ScheduleItemsInterface[]>([]);
    let liveStreami18nAEM;
    if (props.i18nAEM) {
        liveStreami18nAEM = props.profile
            ? props.i18nAEM?.authorized
            : props.i18nAEM?.unauthorized;
    }

    return (
        <Fragment>
            <div className="upcoming-livestream-wrapper">
                <UpcomingLivestreamHeader
                    props={props}
                    liveStreami18nAEM={liveStreami18nAEM}
                />
                <LivestreamScheduleContainer
                    props={props}
                    scheduleItemList={scheduleItemList}
                    liveStreami18nAEM={liveStreami18nAEM}
                />
            </div>
        </Fragment>
    );
};

export default UpcomingLivestream;
