import React, { useState, useEffect, useContext, useRef } from 'react';
import './warranty-details.scss';
import {
    useWarrantyDetailsContent,
    useBaseWarrantyContent,
} from './hooks/use-warranty-details-content';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import ServiceHandler from '../../../../services/service-handler';
import { replacePlaceholderByValue } from '../../../utils/placeholder-util/replace-placeholder-by-value';
import warrantyExpiredIcon from '../../../../assets/notification-error.svg';
import successCheckmark from '../../../../assets/success-checkmark.svg';
import { Accordion, PrimaryButton, SecondaryButton } from '../../../common';
import downloadIcon from '../../../../assets/download-icon-solid.svg';
import ServerContext from '../../../../contexts/serverContext';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import Popover from '../../../common/popover/popover';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import { SupportTrio } from '../../../common/support-trio/support-trio';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { ErrorMessage } from '../../error-message/error-message';
import DateTimeUtil from '../../../utils/date-time-util/date-time-util';
import { VehicleUtil } from '../../../utils/vehicle-util/vehicle-util';
import { BrandUtil } from '../../../utils/brand-util/brand-util';
import { BreadcrumbWrapperReimagine } from '../../../../components/common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';
import { useLocation } from 'react-router';
import { ViewTemplate } from '../../view-template/view-template';
import { PageType } from '../../view-template/page-type';
import isWebviewCheck from '../../../utils/webviewCheck/webviewCheck';
import WarrantyService from '../../../../services/warranty-service/warranty-service';
import { IMG_COUNTRIES_WITH_WARRANTY_STATUS } from '../../../../constants';
import VehicleTypeService from '../../../../services/vehicle-type-service/vehicle-type-service';
import AuthenticationService from '../../../../services/authentication-service/authentication-service';
import { ViewportUtil } from '../../../utils/viewport-util/viewport-util';

interface Props {
    vinNumber: string;
    isAuthenticated?: string;
    loginClick: (action: string) => void;
    uomDistance: number;
    page: PageType;
}

interface BreadCrumbTitle {
    breadCrumbCurrentRoot?: string;
    breadCrumbRoute?: string;
    breadCrumbTitle?: string;
}

const WarrantyDetailsBreadCrumbTitle = (props: BreadCrumbTitle) => {
    const handleClick = () => {
        sessionStorage.removeItem('selectedVin');
        window.location.href = `${props?.breadCrumbCurrentRoot}${props?.breadCrumbRoute}`;
    };

    return (
        <>
            <span
                className="breadcrumb-link-ellipses-expanded"
                aria-label={`Click to goto ${props?.breadCrumbTitle} page`}
                onClick={handleClick}
            >
                <span>{props?.breadCrumbTitle}</span>
            </span>
            <span className="back-slash">{'/'}</span>
        </>
    );
};

export const WarrantyDetails = (props: Props) => {
    const [AuthenticatedUser, setAuthenticatedUser] = useState('');
    const authenticationService = new AuthenticationService();
    const warrantyDetailsContent = useWarrantyDetailsContent();
    const baseWarrantyContent = useBaseWarrantyContent();
    const { isLincoln } = new BrandUtil();
    const isContentAvailable = !!Object.keys(warrantyDetailsContent).length;
    const [warrantyResponse, setWarrantyResponse] = useState<any>();
    const mounted = useRef<any>();
    const {
        warrantyDetailsChevronText,
        warrantyDetailsHeader,
        warrantyDetailsSubText,
        espDetailsSubText,
        warrantyDetailsUnAuthenticatedText,
        activeWarrantyWithOdometerReading,
        activeWarrantyWithFlatRateReading,
        activeWarrantyWithoutOdometerReading,
        activeWarrantyWithoutMileage,
        warrantyStartDate,
        warrantyStatusText,
        odometerLabel,
        uomMilesLabel,
        uomKmLabel,
        odometerTooltipHeader,
        odometerTooltipContent,
        activeExtendedWarrantyContent,
        expiredExtendedWarrantyContent,
        flatRateContent,
        warrantyCTALabel,
        warrantyCTALink,
        warrantyCTAAnalyticsContext,
        espCTALabel,
        espCTALink,
        espCTAAnalyticsContext,
        warrantyGuideLabel,
        contentForInvalidWarranty,
        openInNewtabArialabel,
        espHeadline,
        flatRateHeadLine,
        warrantyTermsAndConditions,
        enableStartDateAndAccordion,
    } = warrantyDetailsContent;
    const getMileageUnit = (
        uomDistance: number,
        unitForMiles: string,
        unitForKm: string
    ) => (uomDistance === 1 ? unitForMiles : unitForKm);

    const mileageUnitNA = getMileageUnit(props.uomDistance, 'miles', 'km');
    const mileageUnitEU = getMileageUnit(props.uomDistance, 'mi', 'km');
    const mileageUnitIMG = getMileageUnit(props.uomDistance, 'mi', 'km');
    const countryCodeNA = 'NA';
    const countryCodeEU = 'EU';
    const expiredText = 'EXPIRED';
    const BASE_WARRANTY_TYPE = 0;
    const serverContext = useContext(ServerContext);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;
    const appConfig = new AppConfigurationService();
    const isRegionNA = appConfig.isRegionNA();
    const isRegionEU = appConfig.isRegionEU();
    const isRegionAPA = appConfig.isRegionAPA();
    const isCountryZAF = appConfig.isCountryZAF();
    const isWebview = isWebviewCheck();
    const isEsbView = props.page === 'Extended Service Business';
    const [warrantyPeriod, setWarrantyPeriod] = useState<string>();
    const [warrantyOdometer, setwarrantyOdometer] = useState<string>();

    const hideDisclosureContent =
        serverContext.webview === 'true' && isRegionEU;
    const currentRegionCode = appConfig.currentRegionCode;
    const [region] = [
        serverContext ? serverContext.currentRegionCode : currentRegionCode,
    ];
    const headerText = isEsbView ? espHeadline : warrantyDetailsHeader;
    const vehicleUtil = new VehicleUtil();
    const [vehicleName, setVehicleName] = useState<any>();
    const [warrantyNetstore, setWarrantyNetstore] = useState<any>();
    const [showPopover, setPopover] = useState<boolean>(false);
    const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 });
    const { isMobileView } = ViewportUtil();
    const [showError, setShowError] = useState<boolean>(false);
    const [isApiCompleted, setApiCompleted] = useState<boolean>(false);
    const [fireEvents] = useAnalytics();
    const triggerLoadAnalytics = (selectedVehicleData: any) => {
        if (isEsbView) {
            fireEvents(
                [
                    'target-trigger-view',
                    'extended-service-business-information',
                ],
                'extended-service-business-information',
                { ymm: Promise.resolve(selectedVehicleData) }
            );
        } else {
            fireEvents(
                ['target-trigger-view', 'warranty-information'],
                'warranty-information',
                { ymm: Promise.resolve(selectedVehicleData) }
            );
        }
    };
    const [vehiclesData, setVehiclesData] = useState<
        VehicleAttributes | undefined
    >(undefined);
    const route = useLocation().pathname;
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const warrantyDetailsBreadCrumb = warrantyDetailsHeader?.toLowerCase();
    const warrantyDetailsBreadCrumbTitle = warrantyDetailsBreadCrumb?.replace(
        /(?<=^|\s)\w/g,
        letter => letter.toUpperCase()
    );
    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    const padZero = (num: number) => (num > 9 ? num : '0' + num);

    const getWarrantyEndDate = (dateString: string, yearDuration = 0) => {
        if (dateString) {
            const date = new Date(dateString);
            if (date) {
                const month = padZero(date.getMonth() + 1);
                const day = padZero(date.getDate());
                const year = date.getFullYear() + yearDuration;
                return `${month}/${day}/${year}`;
            }
        }
        return '';
    };

    const getBaseCoverages = (
        baseCoverages: any,
        warrantyStartDate: any,
        warrantyEndDate: any
    ) => {
        const baseActiveCoverages: any[] = [];
        const baseExpiredCoverages: any[] = [];

        baseCoverages &&
            baseCoverages.forEach((coverage: any) => {
                const endDate = warrantyStartDate
                    ? getWarrantyEndDate(
                          warrantyStartDate,
                          Number(coverage.yearDuration || coverage.years)
                      )
                    : warrantyEndDate;
                if (new Date(endDate) >= new Date()) {
                    baseActiveCoverages.push({
                        ...coverage,
                        coverageType: 'base',
                    });
                } else {
                    baseExpiredCoverages.push({
                        ...coverage,
                        coverageType: 'base',
                    });
                }
            });

        return { baseActiveCoverages, baseExpiredCoverages };
    };

    const pushCoverage = (
        coverage: any,
        isExpired: boolean,
        activeCoverages: any[],
        expiredCoverages: any[]
    ) => {
        const targetCoverages = isExpired ? expiredCoverages : activeCoverages;
        targetCoverages.push({
            ...coverage,
            coverageType: 'extended',
        });
    };

    const sortExpiredCoverages = (expiredCoverages: any[]) => {
        expiredCoverages.sort((a, b) => {
            const aFormattedExpiredDate = new Date(a.expirationDate);
            const bFormattedExpiredDate = new Date(b.expirationDate);

            if (aFormattedExpiredDate > bFormattedExpiredDate) {
                return -1;
            }
            if (aFormattedExpiredDate < bFormattedExpiredDate) {
                return 1;
            }
            return 0;
        });
    };

    const getExtendedCoverages = (
        esp: any,
        isEUCoverages: boolean,
        isIMGESBContracts: boolean,
        contracts: any
    ) => {
        const extendedActiveCoverages: any[] = [];
        const extendedExpiredCoverages: any[] = [];

        if (!isEUCoverages && esp?.coverages) {
            esp.coverages.forEach((coverage: any) => {
                pushCoverage(
                    coverage,
                    coverage.type === expiredText,
                    extendedActiveCoverages,
                    extendedExpiredCoverages
                );
            });
            sortExpiredCoverages(extendedExpiredCoverages);
        }

        if (isEUCoverages && esp.contractType) {
            pushCoverage(
                esp,
                new Date(esp.contractExpirationDate) < new Date(),
                extendedActiveCoverages,
                extendedExpiredCoverages
            );
        }

        if (isIMGESBContracts) {
            contracts.forEach((contract: any) => {
                pushCoverage(
                    contract,
                    new Date(contract.contractExpirationDate) < new Date(),
                    extendedActiveCoverages,
                    extendedExpiredCoverages
                );
            });
        }

        return { extendedActiveCoverages, extendedExpiredCoverages };
    };

    const sortCoverages = (warrantyResult: any, warrantyNetStore: any = {}) => {
        const {
            coverages,
            esp,
            warrantyStartDate,
            warrantyEndDate,
            contracts,
        } = warrantyResult;
        const isIMGESBContracts = warrantyResult?.contracts?.length > 0;
        const isEUCoverages = warrantyResult.url && !warrantyResult.coverages;
        const netStoreValues = warrantyNetStore.values?.[0]?.operations;
        const baseCoverages = isEUCoverages ? netStoreValues : coverages;

        const { baseActiveCoverages, baseExpiredCoverages } = getBaseCoverages(
            baseCoverages,
            warrantyStartDate,
            warrantyEndDate
        );
        const {
            extendedActiveCoverages,
            extendedExpiredCoverages,
        } = getExtendedCoverages(
            esp,
            isEUCoverages,
            isIMGESBContracts,
            contracts
        );

        const allCoverages: any = [
            ...baseActiveCoverages,
            ...extendedActiveCoverages,
            ...baseExpiredCoverages,
            extendedExpiredCoverages[0],
        ].filter(coverage => coverage);

        return warrantyNetStore.values
            ? {
                  ...warrantyNetStore,
                  allCoverages,
              }
            : {
                  ...warrantyResult,
                  allCoverages,
              };
    };

    const getWarrantyNetstore = (warrantyResult: any) => {
        if (warrantyResult?.url && !warrantyResult.coverages)
            ServiceHandler.WarrantyService.getWarrantyNetstore(
                warrantyResult.url
            )
                .then(warrantyNetstore => {
                    setWarrantyNetstore(
                        sortCoverages(warrantyResult, warrantyNetstore)
                    );
                    setShowError(false);
                })
                .catch(() => {
                    setShowError(true);
                });
    };

    const triggerErrorAnalytics = () => {
        if (vehiclesData) {
            if (isEsbView) {
                fireEvents(
                    [
                        'target-trigger-view',
                        'extended-service-business-information-error',
                    ],
                    'extended-service-business-information-error',
                    { ymm: Promise.resolve(vehiclesData) }
                );
            } else {
                fireEvents(
                    ['target-trigger-view', 'warranty-information-error'],
                    'warranty-information-error',
                    { ymm: Promise.resolve(vehiclesData) }
                );
            }
        } else {
            if (isEsbView) {
                fireEvents(
                    [
                        'target-trigger-view',
                        'extended-service-business-information-error',
                    ],
                    'extended-service-business-information-error'
                );
            } else {
                fireEvents(
                    ['target-trigger-view', 'warranty-information-error'],
                    'warranty-information-error'
                );
            }
        }
    };

    const getVinVehicleResponse = () => {
        new VehicleTypeService()
            .request(
                props.vinNumber,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            )
            .then(response => {
                const vehicleDataObject = vehicleUtil.getVehicleObject(
                    response.vehicleData,
                    props.vinNumber
                );
                triggerLoadAnalytics(vehicleDataObject);
                setVehiclesData(vehicleDataObject);
                setVehicleName(
                    vehicleUtil.getVehicleName(response.vehicleData, isRegionNA)
                );
                if (isCountryZAF && !isEsbView) {
                    setTimeout(() => {
                        setApiCompleted(true);
                    }, 1500);
                }
                setShowError(false);
            })
            .catch(() => {
                setApiCompleted(true);
                setShowError(true);
            });
    };

    const getWarrantyDetails = () => {
        if (props.vinNumber && AuthenticatedUser && !warrantyResponse) {
            ServiceHandler.WarrantyService.getWarrantyService(
                props.vinNumber,
                AuthenticatedUser === 'loggedIn'
            )
                .then(warrantyResult => {
                    setWarrantyResponse(sortCoverages(warrantyResult));
                    getWarrantyNetstore(warrantyResult);
                    setTimeout(() => {
                        setApiCompleted(true);
                    }, 1500);
                    setShowError(false);
                })
                .catch(() => {
                    setApiCompleted(true);
                    setShowError(true);
                });
        }
    };

    const getImgWarrantyDetails = () => {
        if (props.vinNumber) {
            new WarrantyService()
                .getImgWarrantyService(props.vinNumber)
                .then(response => {
                    if (response) {
                        const warrantyStartDate =
                            response.value?.baseWarranty?.warrantyStartDate;
                        const warrantyEndDate =
                            response.value?.baseWarranty?.warrantyEndDate;
                        const warrantyEndOdometer =
                            response.value?.baseWarranty?.warrantyEndOdometer;
                        if (
                            warrantyStartDate &&
                            warrantyEndDate &&
                            warrantyEndOdometer
                        ) {
                            const warrantyPeriod =
                                new Date(warrantyEndDate).getFullYear() -
                                new Date(warrantyStartDate).getFullYear();
                            setWarrantyPeriod(warrantyPeriod.toString());
                            setwarrantyOdometer(warrantyEndOdometer);
                        }
                        if (isEsbView) {
                            setWarrantyResponse(sortCoverages(response.value));
                        }
                    }
                    setTimeout(() => {
                        setApiCompleted(true);
                    }, 1500);
                    setShowError(false);
                })
                .catch(() => {
                    setApiCompleted(true);
                    setShowError(true);
                });
        }
    };
    const getESBDetailsForZAF = () => {
        if (props.vinNumber && !warrantyResponse) {
            ServiceHandler.ZafEsbService.getEsbForZAF(props.vinNumber)
                .then(warrantyResult => {
                    setWarrantyResponse(sortCoverages(warrantyResult));
                    setTimeout(() => {
                        setApiCompleted(true);
                    }, 1500);
                    setShowError(false);
                })
                .catch(() => {
                    setApiCompleted(true);
                    setShowError(true);
                });
        }
    };

    const attachOnClickEvent = () => {
        const registerElement: any = document.querySelector(
            "a[href='#register']"
        );
        const loginElement: any = document.querySelector("a[href='#login']");
        if (registerElement) {
            registerElement.addEventListener('click', function(event: any) {
                event.preventDefault();
                props.loginClick(registerElement.getAttribute('href'));
            });
        }

        if (loginElement) {
            loginElement.addEventListener('click', function(event: any) {
                event.preventDefault();
                props.loginClick(loginElement.getAttribute('href'));
            });
        }
    };
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            attachOnClickEvent();
        }
    });
    useEffect(() => {
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setAuthenticatedUser('loggedIn');
                } else {
                    setAuthenticatedUser('notLoggedIn');
                }
            });
    }, []);
    useEffect(() => {
        scrollPageToTop();
        if (isEsbView && isCountryZAF) {
            getESBDetailsForZAF();
        } else if (isRegionAPA && !isCountryZAF) {
            getImgWarrantyDetails();
        }
        if (isRegionEU || isRegionNA) {
            getWarrantyDetails();
        }
        getVinVehicleResponse();
    }, [AuthenticatedUser]);

    useEffect(() => {
        if (showError) triggerErrorAnalytics();
    }, [showError]);

    useEffect(() => {
        if (mounted.current) {
            const espCTA = document.querySelector('.warranty-esp-cta a[href]');
            espCTA &&
                espCTA.setAttribute(
                    'aria-label',
                    `${espCTA.innerHTML} ${openInNewtabArialabel || ''}`
                );
        }
    });

    const renderWarrantyUnavailable = () => (
        <div
            className={'accordion-text warranty-unavailable'}
            dangerouslySetInnerHTML={{ __html: contentForInvalidWarranty }}
        />
    );
    const renderWarrantyExpired = () => (
        <>
            <img
                src={warrantyExpiredIcon}
                className={`warranty-status-icon expired`}
                alt="warranty-status-icon"
            />
            <span className={'accordion-text'}>
                {warrantyDetailsContent.warrantyExpiredText}
            </span>
        </>
    );
    const isFlatRateContentAvailable = () => {
        return (
            activeWarrantyWithFlatRateReading &&
            flatRateContent &&
            flatRateHeadLine
        );
    };
    const isDeFlrContract = () => {
        return (
            warrantyResponse?.esp?.contractType === 'DE-FLR' &&
            isFlatRateContentAvailable()
        );
    };
    const getActiveWarrantyWithoutOdometerReading = () => {
        return isDeFlrContract()
            ? activeWarrantyWithFlatRateReading
            : activeWarrantyWithoutOdometerReading;
    };
    const renderActiveWarrantyStatus = (
        odometer: any,
        mileageTypeFromAPI: string,
        mileageUnitPreferenceByUser: string,
        mileageDuration: any,
        endDate: string
    ) => {
        let mileageUnitDisplayLabel = '';
        mileageTypeFromAPI = mileageTypeFromAPI?.toLowerCase();
        // Instead of if else statements
        switch (mileageTypeFromAPI) {
            case 'miles':
                mileageUnitDisplayLabel = uomMilesLabel;
                break;
            case 'mi':
                mileageUnitDisplayLabel = uomMilesLabel;
                break;
            case 'km':
                mileageUnitDisplayLabel = uomKmLabel;
                break;
        }
        const warrantyEndDate = endDate
            ? new DateTimeUtil().formatDateByRegion(endDate)
            : null;
        if (!warrantyEndDate) {
            return <>{renderWarrantyUnavailable()}</>;
        } else if (
            checkConnectedVehicle() &&
            mileageTypeFromAPI === mileageUnitPreferenceByUser &&
            !isNaN(mileageDuration) &&
            activeWarrantyWithOdometerReading &&
            Number(mileageDuration) > 0
        ) {
            return (
                <>
                    <img
                        src={successCheckmark}
                        className={`warranty-status-icon`}
                        alt="warranty-status-icon"
                    />
                    <span className={'accordion-text'}>
                        {replacePlaceholderByValue(
                            activeWarrantyWithOdometerReading,
                            {
                                expirationDate: warrantyEndDate,
                                expirationMiles: mileageDuration,
                                mileageType: mileageUnitDisplayLabel,
                                milesLeft: `${Number(mileageDuration) -
                                    Number(odometer.value)}`,
                            }
                        )}
                    </span>
                </>
            );
        } else if (
            activeWarrantyWithoutMileage &&
            (isNaN(mileageDuration) || Number(mileageDuration) <= 0)
        ) {
            return (
                <>
                    {odometer?.value ? (
                        <img
                            src={successCheckmark}
                            className={`warranty-status-icon`}
                            alt="warranty-status-icon"
                        />
                    ) : (
                        <span className={'warranty-icon-placeholder'} />
                    )}
                    <span className={'accordion-text'}>
                        {replacePlaceholderByValue(
                            activeWarrantyWithoutMileage,
                            {
                                expirationDate: warrantyEndDate,
                            }
                        )}
                    </span>
                </>
            );
        } else {
            return (
                <>
                    {odometer?.value ? (
                        <img
                            src={successCheckmark}
                            className={`warranty-status-icon`}
                            alt="warranty-status-icon"
                        />
                    ) : (
                        <span className={'warranty-icon-placeholder'} />
                    )}
                    <span className={'accordion-text'}>
                        {replacePlaceholderByValue(
                            getActiveWarrantyWithoutOdometerReading(),
                            {
                                expirationDate: warrantyEndDate,
                                expirationMiles: mileageDuration,
                                mileageType: mileageUnitDisplayLabel,
                            }
                        )}
                    </span>
                </>
            );
        }
    };

    const renderBaseWarrantyStatus = (coverage: any) => {
        const {
            odometer,
            warrantyStartDate,
            warrantyEndDate,
        } = warrantyResponse;
        const endDate = warrantyStartDate
            ? getWarrantyEndDate(
                  warrantyStartDate,
                  Number(coverage.yearDuration)
              )
            : getWarrantyEndDate(warrantyEndDate);
        if (!endDate) {
            return <>{renderWarrantyUnavailable()}</>;
        }
        const today = new Date();
        const expiryDate = new Date(endDate);
        const isExpired = expiryDate < today;
        if (isExpired) {
            return <>{renderWarrantyExpired()}</>;
        } else {
            return (
                <>
                    {renderActiveWarrantyStatus(
                        odometer,
                        coverage.mileageType,
                        mileageUnitNA,
                        coverage.mileageDuration,
                        endDate
                    )}
                </>
            );
        }
    };
    const renderIMGWarrantyStatus = (coverage: any) => {
        const { odometer } = warrantyResponse;
        const endDate = coverage.contractExpirationDate;
        const mileageUnit = coverage.mileageUnit ? coverage.mileageUnit : 'km';
        if (!endDate) {
            return <>{renderWarrantyUnavailable()}</>;
        }
        const today = new Date();
        const expiryDate = new Date(endDate);
        const isExpired = expiryDate < today;
        if (isExpired) {
            return <>{renderWarrantyExpired()}</>;
        } else {
            return (
                <>
                    {renderActiveWarrantyStatus(
                        odometer,
                        mileageUnit,
                        mileageUnitIMG,
                        coverage.contractExpirationDistanceMeasure,
                        endDate
                    )}
                </>
            );
        }
    };
    const renderIMGHeaderContent = (coverages: any) => {
        return (
            <>
                <div className="warranty-header-content accordion-item1">
                    <div className="coverage-type">
                        <span className={'accordion-heading'}>
                            {coverages.contractProductName}
                        </span>
                        <span className={'accordion-subtext-img'}>
                            {coverages.contractProductDescription}
                        </span>
                    </div>
                </div>
                <div className={'accordion-item2 warranty-status-wrap'}>
                    {renderIMGWarrantyStatus(coverages)}
                </div>
            </>
        );
    };
    const renderHeaderContent = (coverages: any) => {
        return (
            <>
                <div className="warranty-header-content accordion-item1">
                    <div className="coverage-type">
                        <span className={'accordion-heading'}>
                            {coverages.type}
                        </span>
                        <span className={'accordion-subtext'}>
                            {coverages.description}
                        </span>
                    </div>
                </div>
                <div className={'accordion-item2 warranty-status-wrap'}>
                    {renderBaseWarrantyStatus(coverages)}
                </div>
            </>
        );
    };
    const renderNAWarrantyStatus = (refObj: any) => {
        const { odometer } = warrantyResponse;
        return (
            <>
                {renderActiveWarrantyStatus(
                    odometer,
                    refObj.mileageType,
                    mileageUnitNA,
                    refObj.expirationMiles,
                    refObj.expirationDate
                )}
            </>
        );
    };

    const renderEUWarrantyStatus = (refObj: any) => {
        const { odometer } = warrantyResponse;
        if (refObj.contractType) {
            return (
                <>
                    {' '}
                    {renderActiveWarrantyStatus(
                        odometer,
                        refObj.mileageUnit,
                        mileageUnitEU,
                        refObj.contractExpirationDistanceMeasure,
                        getWarrantyEndDate(refObj.contractExpirationDate)
                    )}
                </>
            );
        }
    };

    const renderMiddleColumn = (refObj: any, country: string) => {
        const isExpired =
            (country === countryCodeNA && refObj.type === expiredText) ||
            (country === countryCodeEU &&
                refObj.contractExpirationDate &&
                new Date() > new Date(refObj.contractExpirationDate));
        return isExpired ? (
            warrantyDetailsContent.warrantyExpiredText &&
                renderWarrantyExpired()
        ) : (
            <>
                {country === countryCodeNA
                    ? renderNAWarrantyStatus(refObj)
                    : renderEUWarrantyStatus(refObj)}
            </>
        );
    };

    const espEuAccordionDetails = (
        refObj: any,
        country: string,
        details: any
    ) => {
        return (
            <>
                <span className="warranty-details-accordion-details accordion-base-warranty-status">
                    {renderMiddleColumn(refObj, country)}
                </span>
                <span
                    className="warranty-details-accordion-details"
                    dangerouslySetInnerHTML={{
                        __html: details === '*' ? '' : details,
                    }}
                ></span>
            </>
        );
    };

    const accordionDetails = (coverages: any) => {
        const additionalInformation = coverages.additionalInformation?.replace(
            '<h4>',
            ''
        );

        return (
            <>
                <span className="warranty-details-accordion-details">
                    {coverages.description}
                </span>

                <span className="warranty-details-accordion-details accordion-base-warranty-status">
                    {renderBaseWarrantyStatus(coverages)}
                </span>
                {coverages.additionalInformation !== '*' &&
                coverages.additionalInformation !== '' ? (
                    <span
                        className="warranty-details-accordion-details"
                        dangerouslySetInnerHTML={{
                            __html:
                                coverages.additionalInformation === '*'
                                    ? ''
                                    : additionalInformation,
                        }}
                    ></span>
                ) : null}
            </>
        );
    };
    const getActiveWarrantContent = () => {
        return isDeFlrContract()
            ? flatRateContent
            : activeExtendedWarrantyContent;
    };
    const espAccordionDetails = (
        refObj: any,
        country: string,
        expirationDate: any
    ) => {
        const isExpired = new Date() > new Date(expirationDate);
        const accordionDetails = isExpired
            ? expiredExtendedWarrantyContent
            : getActiveWarrantContent();
        return (
            <>
                <span className="warranty-details-accordion-details accordion-base-warranty-status">
                    {renderMiddleColumn(refObj, country)}
                </span>
                {accordionDetails !== '*' && accordionDetails !== '' ? (
                    <span
                        className="warranty-details-accordion-details"
                        dangerouslySetInnerHTML={{
                            __html: accordionDetails,
                        }}
                    ></span>
                ) : null}
            </>
        );
    };
    const getWarrantyHeadLine = () => {
        return isDeFlrContract() ? flatRateHeadLine : espHeadline;
    };
    const espHeader = (refObj: any, country: string) => {
        return (
            <>
                <div className="warranty-esp accordion-item1">
                    <span className="warranty-esp-header accordion-heading">
                        {getWarrantyHeadLine()}
                    </span>
                </div>
                <div className={'accordion-item2 warranty-status-wrap'}>
                    {renderMiddleColumn(refObj, country)}
                </div>
            </>
        );
    };

    const renderEspTerms = () => {
        return (
            <section>
                <div
                    className="warranty-terms-and-conditions"
                    dangerouslySetInnerHTML={{
                        __html: activeExtendedWarrantyContent,
                    }}
                ></div>
            </section>
        );
    };

    const renderEspCta = () => {
        return (
            <section
                className={'warranty-esp-cta accordion-text'}
                dangerouslySetInnerHTML={{
                    __html: warrantyDetailsContent.espCTA,
                }}
            ></section>
        );
    };

    const getBaseWarrantyInformation = (currentType: string): string => {
        let result = '';
        if (Array.isArray(baseWarrantyContent)) {
            baseWarrantyContent.forEach(warrantyFragment => {
                result =
                    warrantyFragment.warrantyType.value ===
                    currentType.toString()
                        ? warrantyFragment.warrantyInformation.value
                        : result;
            });
        }
        return result;
    };

    const renderEUHeader = (operation: any) => {
        const {
            warrantyEndDate,
            warrantyStartDate,
            odometer,
        } = warrantyResponse;
        const endDate = warrantyStartDate
            ? getWarrantyEndDate(warrantyStartDate, Number(operation.years))
            : getWarrantyEndDate(warrantyEndDate);
        const today = new Date();
        const expireDate = new Date(endDate);
        const isExpired = expireDate < today;
        return (
            <>
                <div className={'eu-base-warranty-header accordion-item1'}>
                    <span className={'eu-heading accordion-heading'}>
                        {operation.heading}
                    </span>
                </div>
                <div className={'accordion-item2 warranty-status-wrap'}>
                    {!isExpired ? (
                        <>
                            {' '}
                            {renderActiveWarrantyStatus(
                                odometer,
                                warrantyNetstore.mileageUnit,
                                mileageUnitEU,
                                operation.distance,
                                endDate
                            )}
                        </>
                    ) : (
                        <>{renderWarrantyExpired()}</>
                    )}
                </div>
            </>
        );
    };
    const euAccordionDetails = (operation: any, details: any) => {
        const {
            warrantyEndDate,
            warrantyStartDate,
            odometer,
        } = warrantyResponse;
        const endDate = warrantyStartDate
            ? getWarrantyEndDate(warrantyStartDate, Number(operation.years))
            : getWarrantyEndDate(warrantyEndDate);
        const today = new Date();
        const expireDate = new Date(endDate);
        const isExpired = expireDate < today;
        return (
            <>
                <span className="warranty-details-accordion-details accordion-base-warranty-status">
                    {!isExpired ? (
                        <>
                            {' '}
                            {renderActiveWarrantyStatus(
                                odometer,
                                warrantyNetstore.mileageUnit,
                                mileageUnitEU,
                                operation.distance,
                                endDate
                            )}
                        </>
                    ) : (
                        <>{renderWarrantyExpired()}</>
                    )}
                </span>
                {details !== '*' && details !== '' ? (
                    <span
                        className={'warranty-details-accordion-details'}
                        dangerouslySetInnerHTML={{
                            __html: details === '*' ? '' : details,
                        }}
                    ></span>
                ) : null}
            </>
        );
    };

    const renderEUCoverages = () => {
        return warrantyNetstore.allCoverages.map(
            (coverage: any, key: number) => {
                if (
                    coverage.coverageType === 'base' &&
                    coverage.warrantyType === BASE_WARRANTY_TYPE
                ) {
                    return (
                        <Accordion
                            key={coverage.heading}
                            header={renderEUHeader(coverage)}
                            panel={euAccordionDetails(
                                coverage,
                                getBaseWarrantyInformation(
                                    coverage.warrantyType
                                )
                            )}
                            className={'warranty-quick-guide'}
                            index={key.toString()}
                            expandMultiplePanels={true}
                            chevronText={warrantyDetailsChevronText}
                            accordionName={'warranty-details-panel'}
                        />
                    );
                } else if (coverage.coverageType === 'extended') {
                    return (
                        <Accordion
                            key={warrantyResponse.esp}
                            header={espHeader(
                                warrantyResponse.esp,
                                countryCodeEU
                            )}
                            panel={espAccordionDetails(
                                warrantyResponse.esp,
                                countryCodeEU,
                                warrantyResponse.esp.contractExpirationDate
                            )}
                            className={'warranty-quick-guide'}
                            index={key.toString()}
                            expandMultiplePanels={true}
                            chevronText={warrantyDetailsChevronText}
                            accordionName={'warranty-details-panel'}
                        />
                    );
                }
            }
        );
    };

    const getFormattedDate = (date: string) => {
        if (date) {
            const dateObject = new DateTimeUtil().convertISOToDate(date);
            return new DateTimeUtil().formatDateByRegion(dateObject);
        }
        return '';
    };
    const getCTALinkWithVin = (ctaLink: string) => {
        if (props.vinNumber && ctaLink) {
            return ctaLink.replace(':vin', props.vinNumber);
        }
        return ctaLink;
    };
    const triggerWarrantyCTAOnClickAnalyticsEvent = (ctaContext: string) => {
        if (ctaContext != null) {
            if (isEsbView) {
                fireEvents(
                    ['extended-service-business-information-cta-onclick-event'],
                    undefined,
                    {
                        fieldName: ctaContext,
                    },
                    false
                );
            } else {
                fireEvents(
                    ['warranty-information-cta-onclick-event'],
                    undefined,
                    {
                        fieldName: ctaContext,
                    },
                    false
                );
            }
        }
    };
    const dealerLocator = () => {
        if (isEsbView) {
            window.open(getCTALinkWithVin(espCTALink));
            triggerWarrantyCTAOnClickAnalyticsEvent(espCTAAnalyticsContext);
        } else {
            window.open(getCTALinkWithVin(warrantyCTALink));
            triggerWarrantyCTAOnClickAnalyticsEvent(
                warrantyCTAAnalyticsContext
            );
        }
    };
    const setUnit = () => {
        return props.uomDistance === 1 ? uomMilesLabel : uomKmLabel;
    };
    const checkConnectedVehicle = () => {
        return warrantyResponse?.odometer?.value > 0;
    };

    const checkVehicleDetails = () =>
        (warrantyStartDate && warrantyResponse?.warrantyStartDate) ||
        checkConnectedVehicle();

    const setPageScrollTo = (rect: DOMRect) => {
        if (!isMobileView && rect.top < 120) {
            window.scrollBy(0, -150);
        } else if (!isMobileView && rect.top > 450 && rect.top >= 160) {
            window.scrollBy(0, 150);
        }
    };
    const setPopoverPositionState = (rect: DOMRect) => {
        let left, top;

        if (!isMobileView) {
            left = rect.x - 120;
            top = rect.y - (isLincoln ? 190 : 220);
        } else {
            left = 0;
            top = 0;
        }

        const newPosition = {
            left: left,
            top: top,
        };

        setPopoverPosition(newPosition);
    };
    const togglePopover = (e: any, popoverState: boolean) => {
        if (e && e.currentTarget && e.currentTarget.getBoundingClientRect) {
            let rect = e.currentTarget.getBoundingClientRect();
            setPageScrollTo(rect);
            setPopover(popoverState);
            rect = e.currentTarget.getBoundingClientRect();
            setPopoverPositionState(rect);
            e.preventDefault();
        } else {
            setPopover(popoverState);
        }
        e.preventDefault();
    };
    const renderPopover = () => {
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: odometerTooltipContent,
                    }}
                />
            </div>
        );
        return (
            <Popover
                name="warranty-odometer"
                tooltipPosition="bottom"
                mask={false}
                handleClose={e => togglePopover(e, false)}
                popoverPosition={popoverPosition}
                heading={odometerTooltipHeader}
                content={popoverContent}
            />
        );
    };

    const renderNACoverages = () => {
        return warrantyResponse?.allCoverages.map(
            (coverage: any, key: number) => {
                if (coverage.coverageType === 'base') {
                    return (
                        <Accordion
                            key={coverage.type}
                            header={renderHeaderContent(coverage)}
                            panel={accordionDetails(coverage)}
                            className={'warranty-quick-guide'}
                            index={key.toString()}
                            expandMultiplePanels={true}
                            chevronText={warrantyDetailsChevronText}
                            accordionName={'warranty-details-panel'}
                        />
                    );
                } else if (coverage.coverageType === 'extended') {
                    return (
                        <Accordion
                            header={espHeader(coverage, countryCodeNA)}
                            panel={espEuAccordionDetails(
                                coverage,
                                countryCodeNA,
                                coverage.type === 'EXPIRED'
                                    ? expiredExtendedWarrantyContent
                                    : activeExtendedWarrantyContent
                            )}
                            index={key.toString()}
                            key={coverage.type}
                            className={'warranty-quick-guide'}
                            expandMultiplePanels={true}
                            chevronText={warrantyDetailsChevronText}
                            accordionName={'warranty-details-panel'}
                        />
                    );
                }
            }
        );
    };

    const renderIMGCoverages = () => {
        return warrantyResponse?.allCoverages.map(
            (coverage: any, key: number) => {
                if (coverage.coverageType === 'extended') {
                    return (
                        <Accordion
                            key={coverage.contractProductName}
                            header={renderIMGHeaderContent(coverage)}
                            panel={<></>}
                            className={'warranty-quick-guide'}
                            index={key.toString()}
                            expandMultiplePanels={true}
                            chevronText={warrantyDetailsChevronText}
                            accordionName={'warranty-details-panel'}
                        />
                    );
                }
            }
        );
    };
    const triggerDownloadGuideAnalytics = () => {
        fireEvents(
            'warranty-information-download',
            undefined,
            undefined,
            false
        );
    };

    const warrantyNotFoundText = () => {
        triggerErrorAnalytics();
        return (
            <ErrorMessage
                errorMessage={
                    warrantyDetailsContent.WarrantyUnavailableErrorMessage
                }
            />
        );
    };

    const warrantyGuideDownload = (warrantyResponse: any) => {
        const downloadWarranty = warrantyResponse?.warrantyGuide?.link;
        return (
            <>
                {region == 'us' && downloadWarranty !== null && (
                    <PrimaryButton
                        color={'light'}
                        fill={'fill'}
                        chevron={false}
                        onClick={triggerDownloadGuideAnalytics}
                        className={' no-shadow-transparent '}
                        href={downloadWarranty}
                        target="_blank"
                        ariaLabel={`${warrantyGuideLabel} ${openInNewtabArialabel ||
                            ''}`}
                    >
                        <div className="warranty-vehicle-download-button-format">
                            <img
                                className="warranty-vehicle-download-icon"
                                src={downloadIcon}
                                alt={warrantyGuideLabel}
                            />
                            {warrantyGuideLabel}
                        </div>
                    </PrimaryButton>
                )}
            </>
        );
    };
    const isWarrantyDateNull = () => {
        return (
            warrantyResponse &&
            warrantyResponse.warrantyStartDate === null &&
            warrantyResponse.warrantyEndDate === null
        );
    };
    const renderDateErrorMessage = () => {
        return (
            <section className={'warranty-start-date-error-msg'}>
                <ErrorMessage
                    errorMessage={
                        warrantyDetailsContent.startandEndDateErrorMessage
                    }
                />
                <SecondaryButton
                    link={warrantyDetailsContent.startandEndDateErrorCTALink}
                    noChevron={false}
                    aria-label={
                        warrantyDetailsContent.startandEndDateErrorCTALabel
                    }
                >
                    {warrantyDetailsContent.startandEndDateErrorCTALabel}
                </SecondaryButton>
            </section>
        );
    };
    const renderWarrantyAccordions = () => {
        return (
            <section className="warranty-details-accordion">
                {warrantyResponse?.coverages && (
                    <>
                        {renderNACoverages()}
                        {warrantyDetailsContent.espCTA &&
                            !isWebview &&
                            renderEspCta()}
                    </>
                )}
                {warrantyResponse?.contracts && (
                    <>
                        {renderIMGCoverages()}
                        {renderEspTerms()}
                        {warrantyDetailsContent.espCTA &&
                            !isWebview &&
                            renderEspCta()}
                    </>
                )}
                {warrantyNetstore?.allCoverages && (
                    <>
                        {renderEUCoverages()}
                        {warrantyDetailsContent.espCTA &&
                            !isWebview &&
                            renderEspCta()}
                    </>
                )}
            </section>
        );
    };

    const showWarrantyEUExpiryInfoSection = () => {
        return (
            !isWarrantyDateNull() &&
            warrantyDetailsContent?.baseWarrantyExpiredContentEU &&
            warrantyDetailsContent?.extendedWarrantyExpiredContentEU
        );
    };

    const warrantyEUCoveragesAvailable = () => {
        return warrantyNetstore?.allCoverages?.length > 0;
    };

    const warrantyNACoveragesAvailable = () => {
        return warrantyResponse?.coverages?.length > 0;
    };

    const warrantyIMGContractsAvailable = () => {
        return warrantyResponse?.contracts?.length > 0;
    };
    const warrantyZAFStartDateAvailableFromVinLookup = () => {
        return isCountryZAF && !isEsbView && vehiclesData?.warrantyStartDate;
    };

    const getWarrantyStartDate = () => {
        if ((isRegionEU || isRegionNA) && warrantyResponse) {
            return getFormattedDate(warrantyResponse.warrantyStartDate);
        }
        if (isCountryZAF && vehiclesData?.warrantyStartDate) {
            return getFormattedDate(vehiclesData.warrantyStartDate);
        }
    };

    const getBaseExpiryDate = (coverage: any, today: Date) => {
        let baseExpiryDate = '';
        let baseWarrantyActive = true;
        if (
            coverage.coverageType === 'base' &&
            coverage.warrantyType === BASE_WARRANTY_TYPE
        ) {
            baseExpiryDate = warrantyResponse?.warrantyStartDate
                ? getWarrantyEndDate(
                      warrantyResponse.warrantyStartDate,
                      Number(coverage.years)
                  )
                : getWarrantyEndDate(warrantyResponse?.warrantyEndDate);
            const expireDate = new Date(baseExpiryDate);
            baseWarrantyActive = expireDate > today;
        }
        return { baseExpiryDate, baseWarrantyActive };
    };

    const getExtendedExpiryDate = (coverage: any, today: Date) => {
        let extendedExpiryDate = '';
        let extendedWarrantyActive = true;
        if (coverage.coverageType === 'extended') {
            extendedExpiryDate = coverage?.contractExpirationDate;
            extendedWarrantyActive =
                !!extendedExpiryDate && today < new Date(extendedExpiryDate);
        }
        return { extendedExpiryDate, extendedWarrantyActive };
    };

    const getContentAndDisplayExpiryDate = (
        baseWarrantyActive: boolean,
        extendedWarrantyActive: boolean,
        baseExpiryDate: string,
        extendedExpiryDate: string
    ) => {
        let content = null;
        let displayExpiryDate = '';
        if (!baseWarrantyActive && !extendedWarrantyActive) {
            content = warrantyDetailsContent.extendedWarrantyExpiredContentEU;
            displayExpiryDate = extendedExpiryDate
                ? new DateTimeUtil().formatDateByRegion(extendedExpiryDate)
                : '';
        } else if (!baseWarrantyActive && !extendedExpiryDate) {
            content = warrantyDetailsContent.baseWarrantyExpiredContentEU;
            displayExpiryDate = baseExpiryDate
                ? new DateTimeUtil().formatDateByRegion(baseExpiryDate)
                : '';
        }
        return { content, displayExpiryDate };
    };

    const renderWarrantyEUExpiryInformationSection = () => {
        if (
            !showWarrantyEUExpiryInfoSection() ||
            !warrantyEUCoveragesAvailable()
        ) {
            return null;
        }

        const today = new Date();
        let baseExpiryDate = '';
        let extendedExpiryDate = '';
        let baseWarrantyActive = true;
        let extendedWarrantyActive = true;

        warrantyNetstore.allCoverages.forEach((coverage: any) => {
            const baseResults = getBaseExpiryDate(coverage, today);
            baseExpiryDate = baseResults.baseExpiryDate;
            baseWarrantyActive = baseResults.baseWarrantyActive;

            const extendedResults = getExtendedExpiryDate(coverage, today);
            extendedExpiryDate = extendedResults.extendedExpiryDate;
            extendedWarrantyActive = extendedResults.extendedWarrantyActive;
        });

        const { content, displayExpiryDate } = getContentAndDisplayExpiryDate(
            baseWarrantyActive,
            extendedWarrantyActive,
            baseExpiryDate,
            extendedExpiryDate
        );

        return content ? (
            <div
                className={'warranty-expiry-info-content accordion-text'}
                dangerouslySetInnerHTML={{
                    __html: replacePlaceholderByValue(content, {
                        expirationDate: displayExpiryDate,
                    }),
                }}
            ></div>
        ) : null;
    };

    const renderWarrantyDetails = () => {
        const renderVehicleDetails = () => (
            <section className="warranty-vehicle-details">
                <div className="warranty-vehicle-details-wrapper">
                    <div className="warranty-vehicle-details-left">
                        <div className="warranty-vehicle-details-name">
                            <h2
                                className={`${!checkVehicleDetails() &&
                                    'name-only'}`}
                            >
                                {vehicleName?.toUpperCase()}
                            </h2>
                        </div>
                        {enableStartDateAndAccordion && (
                            <div className="warranty-vehicle-details-info">
                                {warrantyStartDate &&
                                    getWarrantyStartDate() &&
                                    !isEsbView && (
                                        <section
                                            className={
                                                'warranty-vehicle-start-date'
                                            }
                                        >
                                            <span
                                                className={
                                                    'warranty-start-date-label'
                                                }
                                            >
                                                {warrantyStartDate}:
                                            </span>
                                            <span
                                                className={
                                                    'warranty-start-date'
                                                }
                                            >
                                                {getWarrantyStartDate()}
                                            </span>
                                        </section>
                                    )}
                                {warrantyStatusText &&
                                    IMG_COUNTRIES_WITH_WARRANTY_STATUS.includes(
                                        appConfig.currentCountryCode
                                    ) &&
                                    !isEsbView && (
                                        <section
                                            className={
                                                'warranty-vehicle-status'
                                            }
                                        >
                                            <span
                                                className={
                                                    'warranty-status-label'
                                                }
                                            >
                                                {replacePlaceholderByValue(
                                                    warrantyStatusText,
                                                    {
                                                        warrantyPeriod: warrantyPeriod,
                                                        warrantyOdometer: warrantyOdometer,
                                                    }
                                                )}
                                            </span>
                                        </section>
                                    )}
                                {checkConnectedVehicle() && (
                                    <section
                                        className={'warranty-vehicle-odometer'}
                                    >
                                        <span
                                            className={
                                                'warranty-odometer-label'
                                            }
                                        >
                                            {odometerLabel}:
                                        </span>
                                        <span className={'warranty-odometer'}>
                                            {warrantyResponse.odometer.value}{' '}
                                            {setUnit()}
                                        </span>
                                        {showPopover && renderPopover()}
                                        <span className="info-wrapper">
                                            <img
                                                className="odometer-info-icon info-icon"
                                                aria-label={
                                                    'icon' +
                                                    odometerLabel +
                                                    'tooltip'
                                                }
                                                role="button"
                                                alt=""
                                                onClick={(e: any) =>
                                                    togglePopover(e, true)
                                                }
                                                onKeyUp={(e: any) =>
                                                    togglePopover(e, true)
                                                }
                                                tabIndex={0}
                                            />
                                        </span>
                                    </section>
                                )}
                            </div>
                        )}
                    </div>
                    {!isWebview && (
                        <div className="warranty-vehicle-title-right">
                            {warrantyGuideDownload(warrantyResponse)}
                            {isEsbView && espCTALabel && espCTALink && (
                                <PrimaryButton
                                    className="find-dealer-button"
                                    role="link"
                                    color="dark"
                                    chevron={false}
                                    fill="fill"
                                    onClick={dealerLocator}
                                    newTabAriaLabel={openInNewtabArialabel}
                                >
                                    {espCTALabel}
                                </PrimaryButton>
                            )}
                            {!isEsbView && (
                                <PrimaryButton
                                    className="find-dealer-button"
                                    role="link"
                                    color="dark"
                                    chevron={false}
                                    fill="fill"
                                    onClick={dealerLocator}
                                    newTabAriaLabel={openInNewtabArialabel}
                                >
                                    {warrantyCTALabel}
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
            </section>
        );

        const renderTermsAndConditions = () => (
            <section>
                <div
                    className="warranty-terms-and-conditions"
                    dangerouslySetInnerHTML={{
                        __html: warrantyTermsAndConditions,
                    }}
                ></div>
            </section>
        );
        const renderActivityIndicator = () => <ActivityIndicator />;

        return (
            <>
                <div
                    className="warranty-details-page-subtext"
                    dangerouslySetInnerHTML={{
                        __html: isEsbView
                            ? espDetailsSubText
                            : warrantyDetailsSubText,
                    }}
                ></div>
                {AuthenticatedUser === 'loggedIn' && (
                    <div className="warranty-details-padding"></div>
                )}
                {AuthenticatedUser === 'notLoggedIn' && (
                    <section className="warranty-details-page-auth">
                        <div
                            className="warranty-details-page-auth-text"
                            dangerouslySetInnerHTML={{
                                __html: warrantyDetailsUnAuthenticatedText,
                            }}
                        ></div>
                    </section>
                )}
                {renderVehicleDetails()}
                {warrantyTermsAndConditions &&
                    !isEsbView &&
                    renderTermsAndConditions()}
                {warrantyResponse?.url &&
                    !warrantyNetstore &&
                    renderActivityIndicator()}
                {isWarrantyDateNull() ? (
                    renderDateErrorMessage()
                ) : (
                    <>
                        {renderWarrantyEUExpiryInformationSection()}
                        {enableStartDateAndAccordion &&
                            renderWarrantyAccordions()}
                    </>
                )}
            </>
        );
    };

    const renderContent = () => {
        if (
            warrantyEUCoveragesAvailable() ||
            warrantyNACoveragesAvailable() ||
            warrantyZAFStartDateAvailableFromVinLookup() ||
            warrantyIMGContractsAvailable() ||
            (warrantyPeriod && warrantyOdometer)
        ) {
            return renderWarrantyDetails();
        } else if (isApiCompleted) {
            return warrantyNotFoundText();
        } else {
            return <ActivityIndicator className="full-height" />;
        }
    };
    return (
        <ViewTemplate
            page={props.page}
            className="trailer-checklist-view"
            hideLinksList={true}
            hideSearchBar={isWebview}
            showDisclosureContent={!hideDisclosureContent}
        >
            {isContentAvailable ? (
                <>
                    {!isWebview && !isRegionAPA && (
                        <BreadcrumbWrapperReimagine
                            currentPage={vehicleName}
                            hashId="support"
                            hideFirstBreadcrumb={isWebview}
                            title={
                                <WarrantyDetailsBreadCrumbTitle
                                    breadCrumbCurrentRoot={currentRoot}
                                    breadCrumbRoute={route}
                                    breadCrumbTitle={
                                        warrantyDetailsBreadCrumbTitle
                                    }
                                />
                            }
                        />
                    )}
                    <section className="warranty-details-page">
                        <section className="warranty-details-page-header">
                            <div className="warranty-details-page-title">
                                <h1>{headerText}</h1>
                            </div>
                        </section>
                        {showError ? (
                            <ErrorMessage
                                errorMessage={
                                    warrantyDetailsContent.warrantyServiceErrorMessage
                                }
                            />
                        ) : (
                            <>{renderContent()}</>
                        )}
                    </section>
                    {!isWebview && (
                        <SupportTrio
                            folder="common"
                            experienceFragmentName="support-trio"
                        />
                    )}
                </>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </ViewTemplate>
    );
};
