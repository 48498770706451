import React from 'react';
import './search-service-centre.scss';
import DealerSearchDropdown from '../../dealer-search-dropdown/dealer-search-dropdown';
import { useDealerStep } from '../../../../../hooks/owners-osb';
import { FMButton } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import { useHistory } from 'react-router-dom';

interface SearchServiceCentreProps {
    onPreferredDealerClick: () => void;
    hasPreferredDealer: boolean;
}

const SearchServiceCentre: React.FC<SearchServiceCentreProps> = ({
    onPreferredDealerClick,
    hasPreferredDealer,
}) => {
    const { osbDealerStep } = useDealerStep();
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'service-centre-step') || {};
    const history = useHistory();
    return (
        <div
            data-testid="search-service-centre-wrapper"
            className="osb-v3-search-service-centre-wrapper"
        >
            <div
                data-testid="search-service-centre-heading"
                className="osb-v3-search-service-centre-heading"
            >
                {osbContentData?.fordServiceCentreTitle}
            </div>
            <div
                data-testid="search-service-centre-container"
                className="osb-v3-search-service-centre"
            >
                <FMButton
                    data-testid="search-service-back-button"
                    chevron="left"
                    className="osb-v3-search-service-back-button"
                    onClick={() => history.goBack()}
                    label={osbContentData?.backToPreviousStepLabel}
                    type="tertiary"
                    aria-level={123}
                />

                <div data-testid="search-title" className="osb-v3-search-title">
                    {osbContentData?.searchByLocationOrServiceCentreTitle}
                </div>
                <div data-testid="search-input" className="osb-v3-search-input">
                    <DealerSearchDropdown
                        data-testid="dealer-search-dropdown"
                        onSelect={(selectedValue: string): void => {
                            console.log('Selected Value - ', selectedValue);
                        }}
                        placeholder={`${osbContentData?.enterTownOrDealerNamePlaceholder}`}
                        locationLabel={`${osbContentData?.locationDropdownCheckboxLabel}`}
                        serviceCentreLabel={`${osbContentData?.serviceCentreDropdownCheckboxLabel}`}
                        selectedValue={osbDealerStep.selectedDealerName}
                    />
                </div>
                <FMButton
                    data-testid="use-my-location-button"
                    className="osb-v3-use-my-location-link"
                    label={osbContentData?.useMyLocationLinkText}
                    type="tertiary"
                />
                <div
                    data-testid="button-container"
                    className="osb-v3-search-service-centre-button-container"
                >
                    <FMButton
                        data-testid="continue-button"
                        className="osb-v3-search-service-centre-continue-button"
                        label={osbContentData?.continueButtonDealerSearchLabel}
                        theme="light"
                        type="primary"
                        onClick={() => {}} // eslint-disable-line
                    />
                    <FMButton
                        data-testid="preferred-dealer-button"
                        className="osb-v3-search-service-centre-button"
                        onClick={onPreferredDealerClick}
                        label={
                            osbContentData?.useMyPreferredServiceCentreButtonLabel
                        }
                        theme="light"
                        type="secondary"
                        disabled={!hasPreferredDealer}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchServiceCentre;
