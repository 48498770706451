import React, { ReactNode, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { InvalidStatusResponse } from '../../../../../models/invalid-status-response';
import { SuccessStatusResponse } from '../../../../../models/success-status-response';
import {
    Link,
    PrimaryButton,
    SecondaryButton,
} from '../../../../../components/common';
import { ActivityIndicator } from '../../../../../components/common/activity-indicator/activity-indicator';
import { useCheckRebateStatusContent } from '../../hooks/use-check-rebate-status-content';
import { useAnalytics } from '../../../../../hooks/use-analytics';

import './check-rebate-status.scss';
import ServiceHandler from '../../../../../services/service-handler';
import { validate } from './check-rebate-status-utils';
import { RebateDetails } from './rebate-details';

interface Props {
    click: () => void;
}
interface FormInputProps {
    testId: string;
    id: string;
    placeholder: string;
    className: string;
    ariaLabel: string;
    value: string;
    onInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: () => void;
    label: string;
    validate?: any;
}

interface ValidationMessageProps {
    message?: boolean;
    validate?: any;
}

interface ConditionalSecondaryButtonProps {
    size: number;
    click: () => void;
    buttonText: string;
    TABLET_BREAKPOINT: number;
    fireEvents: any;
}

const FormInput = (props: FormInputProps) => (
    <div className="form__div">
        <input
            data-testid={props.testId}
            placeholder={props.placeholder}
            id={props.id}
            className={`form__input${props.className}`}
            aria-label={props.ariaLabel ? props.validate : ''}
            value={props.value}
            onInput={props.onInput}
            onBlur={props.onBlur}
        />
        <label htmlFor={props.id} className={`form__label${props.className}`}>
            {props.label}
        </label>
    </div>
);

const ValidationMessage = (props: ValidationMessageProps) => {
    const trackingNumberMessage = props.message ? props.validate : undefined;
    return (
        <>
            {trackingNumberMessage && (
                <div>
                    <span className="form__error">{trackingNumberMessage}</span>
                </div>
            )}
        </>
    );
};

const ConditionalSecondaryButton = (props: ConditionalSecondaryButtonProps) => (
    <>
        {(props.size > props.TABLET_BREAKPOINT ||
            props.size <= props.TABLET_BREAKPOINT) && (
            <SecondaryButton
                type={'button'}
                onClick={() => {
                    if (
                        props.size <= props.TABLET_BREAKPOINT &&
                        props.size < 350
                    ) {
                        window.scrollTo({
                            top: 2300,
                            behavior: 'smooth',
                        });
                    } else if (
                        props.size <= props.TABLET_BREAKPOINT &&
                        props.size < 500
                    ) {
                        window.scrollTo({
                            top: 1900,
                            behavior: 'smooth',
                        });
                    }
                    props.click();
                    props.fireEvents(
                        'rr-check-status-cta-onclick-event',
                        undefined,
                        {
                            ctaType: 'forgot your tracking number',
                        },
                        false
                    );
                }}
                noChevron={true}
            >
                {props.buttonText}
            </SecondaryButton>
        )}
    </>
);

export const CheckRebateStatus = (props: Props) => {
    const rebatesStepName = {
        rebatesStepName: 'check status',
    };
    const [fireEvents] = useAnalytics();
    useAnalytics(['redeem-rebates-status-generic'], undefined, rebatesStepName);
    const TABLET_BREAKPOINT = 768;
    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const [invalidApi, setInvalidApi] = useState<InvalidStatusResponse>();
    const [apiResponse, setApiResponse] = useState<SuccessStatusResponse>();
    const [loading, setLoading] = useState(false);
    const [printcard, setprintcard] = useState<ReactNode | null>();
    const [size, setSize] = useState(window.innerWidth);

    const checkRebatresScroll = () => {
        if (size < 350) {
            window.scrollTo({
                top: 2300,
                behavior: 'smooth',
            });
        } else if (size < 500) {
            window.scrollTo({
                top: 1900,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({
                top: 1150,
                behavior: 'smooth',
            });
        }
    };

    const rebatesStatus = useCheckRebateStatusContent();

    useEffect(() => {
        const handleResize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [data, setData] = useState({
        email: '',
        trackingNumber: '',
    });

    const [isTouched, setTouched] = useState({
        email: false,
        trackingNumber: false,
    });
    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            email: event.target.value,
        });
    };

    const onTrackingNumberChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setData({
            ...data,
            trackingNumber: event.target.value,
        });
    };

    const handleRebatesPrintClick = (index: number) => {
        setprintcard(null);
        printPage(index);
        fireEvents(
            'rr-confirm-status-cta-onclick-event',
            undefined,
            undefined,
            false
        );
    };

    const handleRebateResponseClick = () => {
        checkRebatresScroll();
        setTouched({
            ...isTouched,
            email: false,
            trackingNumber: false,
        });
        setData({
            ...data,
            email: '',
            trackingNumber: '',
        });
        setInvalidApi(undefined);
        setApiResponse(undefined);
    };

    const printPage = (index: any) => {
        const id = `rebates${index}`;

        const printcard: any = document.getElementById(id)?.innerHTML
            ? document.getElementById(id)?.innerHTML
            : 'error';
        const printcardParse: any = parse(printcard);
        setprintcard(printcardParse);
        setTimeout(() => {
            window.print();
        }, 10);
    };

    function isValid(arr: any[]) {
        return arr.some(o => o.errorID);
    }

    const getUser = () => {
        setLoading(true);
        ServiceHandler.CheckStatusService.checkRebatesStatus(
            data.email,
            data.trackingNumber
        )
            .then(response => {
                isValid(
                    response.submitStatusResponse.submitStatusRequestResult
                        .anyType
                )
                    ? setInvalidApi(response)
                    : setApiResponse(response);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        getUser();
    };

    useEffect(() => {
        const headingElement = document.getElementById('heading');
        if (headingElement) {
            headingElement.scrollIntoView();
            window.scrollTo(0, window.scrollY - 84);
        }
        if (apiResponse) {
            fireEvents('redeem-rebates-status-generic', undefined, {
                rebatesStepName: 'confirm status',
            });
        }
    }, [apiResponse]);

    return (
        <>
            {apiResponse ? (
                <>
                    <RebateDetails
                        rebatesStatus={rebatesStatus}
                        apiResponse={apiResponse}
                        printCard={printcard}
                        data={data}
                        primaryButtonRef={primaryButtonRef}
                        handleRebatesPrintClick={handleRebatesPrintClick}
                        handleRebateResponseClick={handleRebateResponseClick}
                    />
                </>
            ) : (
                rebatesStatus && (
                    <div className="check-rebates-form">
                        <div className="check-rebate-status">
                            <h2 className="form__title">
                                {rebatesStatus.checkRebatesTitle}
                            </h2>
                            <p className="form-text">
                                {rebatesStatus.questionsPartOneText}{' '}
                                <Link
                                    className="form-link"
                                    href={
                                        'mailto:' +
                                        rebatesStatus.questionsPartTwoEmail
                                    }
                                    onClick={() => {
                                        fireEvents(
                                            'rr-check-status-cta-onclick-event',
                                            undefined,
                                            {
                                                ctaType: 'email',
                                            },
                                            false
                                        );
                                    }}
                                >
                                    {rebatesStatus.questionsPartTwoEmail}
                                </Link>{' '}
                                {rebatesStatus.questionsPartThreeText}{' '}
                                {rebatesStatus.questionsPartFourNumber}
                            </p>
                        </div>
                        <div className="l-form">
                            {loading ? (
                                <ActivityIndicator />
                            ) : (
                                <>
                                    <form
                                        data-testId="form"
                                        onSubmit={handelSubmit}
                                        className="form_elements"
                                    >
                                        <div className="service-rebates-row">
                                            <div className="row-elements">
                                                <FormInput
                                                    testId="tracking-number"
                                                    id="tracking-number"
                                                    placeholder=" "
                                                    className={
                                                        invalidApi
                                                            ? ' invalid'
                                                            : ''
                                                    }
                                                    ariaLabel={`Enter ${rebatesStatus.trackingNumberText} ${isTouched.trackingNumber}`}
                                                    value={data.trackingNumber}
                                                    onInput={
                                                        onTrackingNumberChange
                                                    }
                                                    onBlur={() =>
                                                        setTouched({
                                                            ...isTouched,
                                                            trackingNumber: true,
                                                        })
                                                    }
                                                    label={
                                                        rebatesStatus.trackingNumberText
                                                    }
                                                    validate={
                                                        validate(
                                                            data,
                                                            rebatesStatus
                                                        ).emailRequired
                                                    }
                                                />
                                                <ValidationMessage
                                                    message={
                                                        isTouched.trackingNumber
                                                    }
                                                    validate={
                                                        validate(
                                                            data,
                                                            rebatesStatus
                                                        ).trackingNumberRequired
                                                    }
                                                />
                                                <ConditionalSecondaryButton
                                                    size={size}
                                                    click={props.click}
                                                    buttonText={
                                                        rebatesStatus.forgotTrackingNumber
                                                    }
                                                    TABLET_BREAKPOINT={
                                                        TABLET_BREAKPOINT
                                                    }
                                                    fireEvents={fireEvents}
                                                />
                                            </div>
                                            <div className="row-elements">
                                                <FormInput
                                                    testId="Email"
                                                    id="email-id"
                                                    placeholder=" "
                                                    className={
                                                        invalidApi
                                                            ? ' invalid'
                                                            : ''
                                                    }
                                                    ariaLabel={`Enter ${rebatesStatus.emailText} ${isTouched.email}`}
                                                    value={data.email}
                                                    onInput={onEmailChange}
                                                    onBlur={() =>
                                                        setTouched({
                                                            ...isTouched,
                                                            email: true,
                                                        })
                                                    }
                                                    label={
                                                        rebatesStatus.emailText
                                                    }
                                                    validate={
                                                        validate(
                                                            data,
                                                            rebatesStatus
                                                        ).emailRequired
                                                    }
                                                />
                                                <ValidationMessage
                                                    message={isTouched.email}
                                                    validate={
                                                        validate(
                                                            data,
                                                            rebatesStatus
                                                        ).emailRequired
                                                    }
                                                />
                                                <ConditionalSecondaryButton
                                                    size={size}
                                                    click={props.click}
                                                    buttonText={
                                                        rebatesStatus.forgotTrackingNumber
                                                    }
                                                    TABLET_BREAKPOINT={
                                                        TABLET_BREAKPOINT
                                                    }
                                                    fireEvents={fireEvents}
                                                />
                                            </div>
                                            <div className="row-button">
                                                <PrimaryButton
                                                    ref={primaryButtonRef}
                                                    color="dark"
                                                    fill="fill"
                                                    type={'submit'}
                                                    disabled={
                                                        !validate(
                                                            data,
                                                            rebatesStatus
                                                        ).allValid
                                                    }
                                                    chevron={true}
                                                    onClick={() => {
                                                        setInvalidApi(
                                                            undefined
                                                        );
                                                        setApiResponse(
                                                            undefined
                                                        );
                                                    }}
                                                >
                                                    {
                                                        rebatesStatus.formButtonText
                                                    }
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                        {invalidApi?.submitStatusResponse
                                            .submitStatusRequestResult
                                            .anyType[0].message ? (
                                            <p
                                                className="form__error form__warning"
                                                tabIndex={0}
                                            >
                                                {rebatesStatus.emailNotFound}
                                            </p>
                                        ) : null}
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                )
            )}
        </>
    );
};
