import React, { useContext, useEffect, useState } from 'react';
import {
    getInitialVehicleDetails,
    useDealerStep,
    useHttp,
    useOSBStep,
    useServiceStep,
    useVehicleStep,
    useLightDeliveryOptionServiceStep,
    useServiceLocationStep,
    useStepProgressBar,
    useViewport,
    useCampaignStatesStep,
} from '../../../../../hooks/owners-osb';
import {
    VehicleInfo,
    UserVehicleList,
} from '../../../../../models/osb-model/osb-vehicle-lookup';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../../../services/service-handler';
import { Dropdown, PrimaryButton } from '../../../../common';
import errorWarning from '../../../../../assets/error-warning.svg';
import { PageType } from '../../../view-template/page-type';
import {
    getObjectFromAEMJson,
    getVehicleData,
    getVehicleHealthAlerts,
    frameServiceData,
    setServiceUniqueId,
    getRefreshedServicesData,
    getRefreshedOtherOptionServicesData,
    isValidVin,
    formatRegVinNumber,
    processDeliveryStepProgressbarDisplay,
    addMileageSeparators,
    isThisVIN,
} from '../../osb-utils';
import parse from 'html-react-parser';
import './light-vehicle-finder-lincoln.scss';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import VehicleImageService from '../../../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import { Modal } from '../../../../common/modal/modal';
import vinNumberWindowVehicle from '../../../../../assets/owners-osb/icons-vin-number-window-vehicle.svg';
import vinNumberDocumentation from '../../../../../assets/owners-osb/icons-vin-number-documentation.svg';
import vinNumberWindowVehicleFront from '../../../../../assets/owners-osb/icons-vin-number-window-vehicle-front.svg';
import regPlateNumber from '../../../../../assets/owners-osb/icons-reg-plate-number.svg';
import { VehicleData } from '../../../../../models/osb-model/osb-vehicle-step';
import {
    OSB_AEM_PROPERTY_KEYS,
    SERVICE_FLOW,
    OSB_SERVICE_TYPE,
    SERVICE_DELIVERY_TYPE,
} from '../../osb-constant';
import {
    triggerVehicleActiveBookingExistsAnalytics,
    triggerVehicleInfoAnalytics,
    triggerVehicleNotFoundAnalytics,
    triggerVehicleRecallFoundAnalytics,
    triggerVehicleReviewAnalytics,
    triggerAuthJourneyVehicleManualEntryAnalytics,
    triggerAuthJourneySaveVinAnalytics,
    triggerVinMileageInfoTooltipClickAnalytics,
    triggerVehicleChangeRemoveCTAClickAnalytics,
} from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { VehicleRecallInfo } from '../../../../../models/osb-model/vehicle-recall-info';
import { LogService } from '../../../../../services/log-service/log-service';
import ProfileService from '../../../../../services/profile-service/profile-service';
import { ServiceData } from '../../../../../models/osb-model/osb-dealerservice-info';
import { OsbLoader } from '../../../../common/osb-loader/osb-loader';
import Popup from 'reactjs-popup';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';
import ServerContext from '../../../../../contexts/serverContext';
import { getInitialPrognosticsDetails } from '../../../../../hooks/owners-osb/use-vehicle-step';
import { PrognosticsDataV2 } from '../../../../../models/vehicle-prognostics';
import { FMButton } from '@own/fds-react';
interface Option {
    label: string;
    value: string;
}
interface Props {
    updateVINDealerServices: () => void;
    voucherCode: string;
    maxServiceRetryCount: number;
    shouldValidateVehicleData: boolean;
    updateVehicleSectionMandatoryState?: () => void;
    servicesRetryIntervalInSeconds: number;
    loadSpecificServiceComponent: (isVehicleLoaded: boolean) => void;
    osbAlertMessagePopup: (alertMessage: string, alertSuccess: boolean) => void;
}
const SCHEDULED_SERVICE = '55555';
export const VehicleFinder = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { osbStep, setOSBStepPayload } = useOSBStep();
    const [isVinRegModalVisible, setIsVinRegModalVisible] = useState(false);
    const [vehicleStepContent, setVehicleStepContent] = useState<any>();
    const [vehicleStepMoreContent, setVehicleStepMoreContent] = useState<any>();
    const { httpState, dispatch } = useHttp();
    const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo>(
        getInitialVehicleDetails()
    );
    const [vehiclePrognosticInfo] = useState<PrognosticsDataV2>(
        getInitialPrognosticsDetails() || ''
    );
    const [fireEvents] = useAnalytics();
    const [vinRegErrorMsg, setVinRegErrorMsg] = useState<string>();
    const [mileageError, setMileageError] = useState<string>();
    const [serviceError, setServiceError] = useState('');
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const { osbDealerStep, setOSBDealerStepPayload } = useDealerStep();
    const { isMobileView } = useViewport();
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const InfoIconL = './icons/info-l.svg';

    const {
        osbLightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();

    const {
        osbServiceLocationDetail,
        setOSBServiceLocationStepPayload,
    } = useServiceLocationStep();

    const {
        osbVehicleStep,
        setOSBVehicleStep,
        setOSBVehicleStepPayload,
    } = useVehicleStep();
    const { resetVehicleStep } = useVehicleStep();
    const { manageOsbStepVisibility } = useStepProgressBar();
    const page = 'OSBVehicleStep' as PageType;
    let { brand } = new AppConfigurationService();
    brand = brand === 'demo' ? 'ford' : brand;
    const [vehiclePicUrl, setVehiclePicUrl] = useState<string>();
    const [isVinRegNoLabelShow, setIsVinRegNoLabelShow] = useState<boolean>(
        false
    );
    const [isMileageLabelShow, setisMileageLabelShow] = useState<boolean>(
        false
    );

    const [isMandatorySection, setIsMandatorySection] = useState(false);
    const profileService = new ProfileService();

    const [isManualVinRegEntry, setIsManualVinRegEntry] = useState<boolean>(
        false
    );
    const [progressPercentToDisplay, setProgressPercentToDisplay] = useState<
        string
    >('');
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = useState(false);

    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    const closeModal = () => {
        setOpen(false);
    };

    const bookable = useAllOSBState();

    // If user had outstanding recall action, we need to pre select Recall Service with green box,
    //open the extra services accordion and update comments section with recall info.
    const getVehicleRecall = async (vin: string) => {
        if (vin !== '') {
            await ServiceHandler.OsbVehicleRecallService.request(
                vin,
                osbStep.osbThreeLetterCountryCode
            )
                .then(results => {
                    setOSBVehicleStepPayload({ vehicleRecallService: results });
                    const vehicleRecallInfo: VehicleRecallInfo = results;
                    if (
                        !OsbUtilService.isEmptyObject(vehicleRecallInfo) &&
                        vehicleRecallInfo.recalls &&
                        vehicleRecallInfo.recalls.length > 0
                    ) {
                        triggerVehicleRecallFoundAnalytics(
                            vehicleInfo?.modelName,
                            vehicleInfo?.buildDate,
                            osbServiceStep.voucherCode,
                            fireEvents
                        );
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        type: 'ERROR',
                        error: error.message,
                    });
                });
        }
    };
    const getVinsPrognosticsRecords = async (vin: string) => {
        if (osbStep.isAuthenticatedFlow) {
            if (vin !== '') {
                let selectedConnectedVehicle = false;
                //call vehicle type service to check if the vehicle is connected or not
                await ServiceHandler.VehicleTypeService.request(
                    vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                ).then(async response => {
                    if (response) {
                        selectedConnectedVehicle =
                            response?.isConnected ?? false;
                        if (selectedConnectedVehicle) {
                            //migrated to call Prognostics latest API
                            ServiceHandler.VehiclePrognosticsService.getActivePrognosticsV2(
                                vin
                            )
                                .then(result => {
                                    if (result?.prognosticsData) {
                                        setOSBVehicleStepPayload({
                                            oilLifeData:
                                                result?.prognosticsData[0],
                                            vehiclePrognosticData:
                                                result?.prognosticsData[0],
                                        });
                                    }
                                })
                                .catch(() => {
                                    setOSBVehicleStepPayload({
                                        vehiclePrognosticData: vehiclePrognosticInfo,
                                    });
                                    LogService.log(
                                        'Authentication',
                                        'Received error response from prognostics service'
                                    );
                                });
                        }
                    }
                });
            }
        }
    };
    const getVehicleVHAAlerts = async (vin: string) => {
        if (osbStep.isAuthenticatedFlow) {
            if (vin !== '') {
                let selectedConnectedVehicle = false;
                //call vehicle type service to check if the vehicle is connected or not
                await ServiceHandler.VehicleTypeService.request(
                    vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                ).then(async response => {
                    if (response) {
                        selectedConnectedVehicle =
                            response?.isConnected ?? false;
                        if (selectedConnectedVehicle) {
                            await getVehicleHealthAlerts(vin)
                                .then(response => {
                                    if (response) {
                                        if (response?.error?.message) {
                                            LogService.log(
                                                'Authentication',
                                                `Received error response from vha service: ${response.error.message}`
                                            );
                                        } else {
                                            setOSBVehicleStepPayload({
                                                VhaResponse:
                                                    response
                                                        .vhaAlertResponseList[0],
                                            });
                                        }
                                    }
                                })
                                .catch((error: any) => {
                                    dispatch({
                                        type: 'ERROR',
                                        error: error.message,
                                    });
                                });
                        }
                    }
                });
            }
        }
    };
    //fetch AEM content for vehicle step
    const fetchVehicleStepContent = async (modal: string) => {
        await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modal
        )
            .then(results => {
                if (modal === 'vehicle-step') {
                    setVehicleStepContent(results.elements);
                } else if (modal === 'vehicle-step-more-elements') {
                    // setting vehicle accordion open/close based on authoring.

                    setVehicleStepMoreContent(results.elements);
                    if (
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.IS_MANDATORY_VEHICLE_SECTION,
                            results.elements
                        )
                    ) {
                        props.updateVehicleSectionMandatoryState?.();
                        setIsMandatorySection(true);
                    }

                    if (
                        !osbStep.isPcFlow &&
                        (!osbStep.isVINDeepLinked ||
                            !osbStep.isMileageDeepLinked)
                    ) {
                        dispatch({ type: 'RESPONSE' });
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: 'ERROR',
                    error: error.message,
                });
            });
    };
    // fetch dealer services from backend REST service
    const fetchDealerServices = async (
        vehicleData: VehicleData,
        callfrom: string
    ) => {
        if (vehicleData) {
            const selectedDealerId =
                osbDealerStep.selectedDealerProfile.dealerCode;
            let retries = props.maxServiceRetryCount || 1;
            const retryInterval =
                (props.servicesRetryIntervalInSeconds ||
                    SERVICE_FLOW.RETRY_INTERVAL) * 1000;
            let isValidResponse = false;
            let retryOnceWithoutVoucher = true;
            let dealerServiceInfo: any = null;
            const serviceCallCount = retries;
            let voucherCode = props.voucherCode;
            let progressCounter = Math.floor(100 / retries);

            while (retries > 0 && !isValidResponse) {
                if (serviceCallCount !== retries) {
                    await new Promise(r => setTimeout(r, retryInterval));
                }
                progressCounter *= 2;
                if (progressCounter > 99) {
                    progressCounter = 99;
                }
                setProgressPercentToDisplay(progressCounter.toString());
                await ServiceHandler.OsbDealerServices.getServicesList({
                    dealerCode: selectedDealerId,
                    vehicleData: vehicleData,
                    vouchers: voucherCode ? [voucherCode] : [],
                    serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                        ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                        : SERVICE_DELIVERY_TYPE.WORKSHOP,
                    requestSourceType: osbStep.source,
                    bookable: bookable,
                    DSLAPIVersion: osbStep.DSLAPIVersion,
                })
                    .then((results: any) => {
                        setServiceError('');
                        if (results) {
                            const mainServices =
                                results?.value?.dealerServices?.mainServices;
                            if (
                                mainServices &&
                                mainServices.length > 0 &&
                                vehicleData.vin
                            ) {
                                const scheduledServices = mainServices.filter(
                                    (service: any) => {
                                        return (
                                            service.serviceId.toString() ===
                                            SCHEDULED_SERVICE
                                        );
                                    }
                                );
                                isValidResponse =
                                    scheduledServices?.length === 0;
                            }
                            dealerServiceInfo = results.value;
                            if (
                                !osbStep.isRetrieveFlow &&
                                !osbServiceStep.isMobileServiceSelected
                            ) {
                                const isDealerHasDeliveryServices = processDeliveryStepProgressbarDisplay(
                                    results.value,
                                    manageOsbStepVisibility
                                );
                                setOSBDealerStepPayload({
                                    isDealerHasDeliveryServices: isDealerHasDeliveryServices,
                                });
                            }
                            if (
                                Array.isArray(
                                    results.value.dealerServices?.campaignStates
                                )
                            ) {
                                setOSBCampaignStates({
                                    campaignStates: [
                                        ...results.value.dealerServices
                                            .campaignStates,
                                    ],
                                });
                            }
                        }
                    })
                    .catch((error: any) => {
                        setIsError(true);
                        dispatch({
                            type: 'ERROR',
                            error: error.errorMessage,
                        });

                        if (error) {
                            setServiceError('INTERNAL_ERROR_MESSAGE');
                            setIsError(true);
                            setIsLoading(false);
                        }

                        if (
                            retryOnceWithoutVoucher &&
                            (osbStep.isVoucherDeepLinked ||
                                osbServiceStep.voucherCode)
                        ) {
                            setOSBServiceStepPayload({
                                voucherCode: '',
                            });
                            voucherCode = '';
                            setOSBStepPayload({ isValidVoucher: false });
                            retryOnceWithoutVoucher = false;
                            retries = retries + 1;
                            isValidResponse = false;
                        } else {
                            retries = 0;
                        }
                    });

                if (
                    !vehicleData.vin &&
                    vehicleData.modelName === osbVehicleStep.defaultModelName
                ) {
                    isValidResponse = true;
                }

                if (!isValidResponse) {
                    retries = retries - 1;
                }
                if (isValidResponse || retries === 0) {
                    setProgressPercentToDisplay('99');
                    if (dealerServiceInfo) {
                        const newUpdatedServiceArray = setServiceUniqueId(
                            dealerServiceInfo.dealerServices
                        );
                        const services = getRefreshedServicesData(
                            newUpdatedServiceArray,
                            osbServiceStep.selectedServices,
                            vehicleData.vin ? true : false
                        );
                        const selectedServiceList: ServiceData[] = [];
                        if (services) {
                            let serviceData;
                            services.forEach((service: any) => {
                                if (
                                    service.additionalInfo?.serviceType !==
                                    OSB_SERVICE_TYPE.SERVICES
                                ) {
                                    serviceData = frameServiceData(
                                        OSB_SERVICE_TYPE.EXTRA_SERVICES,
                                        SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
                                        service
                                    );
                                } else {
                                    serviceData = frameServiceData(
                                        OSB_SERVICE_TYPE.SERVICES,
                                        SERVICE_FLOW.CARD_TYPE_SPECIFIC_SERVICE,
                                        service
                                    );
                                }
                                selectedServiceList.push(serviceData);
                            });
                        }
                        setOSBServiceStepPayload({
                            dealerServiceInfo: dealerServiceInfo,
                            selectedServices: selectedServiceList,
                        });

                        const deliverySelectedServiceList: ServiceData[] = [];
                        const deliveryServices = getRefreshedOtherOptionServicesData(
                            newUpdatedServiceArray,
                            osbLightDeliveryServiceStep.selectedServices
                        );
                        if (deliveryServices) {
                            deliveryServices.forEach((service: any) => {
                                const serviceData = frameServiceData(
                                    OSB_SERVICE_TYPE.DELIVERY,
                                    'Delivery Other Option',
                                    service
                                );
                                deliverySelectedServiceList.push(serviceData);
                            });
                        }
                        setOSBLightDeliveryServiceStep({
                            selectedServices: deliverySelectedServiceList,
                        });

                        if (osbDealerStep.hasMobileService) {
                            const deliverySelectedServiceList: ServiceData[] = [];
                            const mobileDeliveryServices = getRefreshedOtherOptionServicesData(
                                newUpdatedServiceArray,
                                osbServiceLocationDetail.selectedOtherOptions
                            );
                            if (mobileDeliveryServices) {
                                mobileDeliveryServices.forEach(
                                    (service: any) => {
                                        const serviceData = frameServiceData(
                                            OSB_SERVICE_TYPE.DELIVERY,
                                            'Location Other Option',
                                            service
                                        );
                                        deliverySelectedServiceList.push(
                                            serviceData
                                        );
                                    }
                                );
                            }
                            setOSBServiceLocationStepPayload({
                                selectedOtherOptions: deliverySelectedServiceList,
                            });
                        }

                        props.updateVINDealerServices();
                        setIsLoading(false);
                        if (
                            callfrom ===
                                OSB_AEM_PROPERTY_KEYS.SUBMIT_VEHICLE_CALL_TYPE &&
                            osbStep.isAuthenticatedFlow &&
                            isManualVinRegEntry
                        ) {
                            setOpen(true);
                        }
                    }
                }
            }
        }
    };

    //vehicle image service for fetching vehicle image
    const fetchVehicleImage = (vehicleAttributes: VehicleAttributes) => {
        new VehicleImageService()
            .getVehicleImage(vehicleAttributes, page)
            .then(vehicleImageUrl => {
                setVehiclePicUrl(
                    vehicleImageUrl !== null ? vehicleImageUrl : '#'
                );
            })
            .catch();
    };
    const isValidMileage = (mileage: string) => {
        const correctMileage = mileage.replace(/[^0-9 .,']+$/, '');
        const sanitisedMileage = correctMileage
            ? correctMileage.replace(/[ .,']/g, '')
            : '';
        const finalMileage = sanitisedMileage.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ''
        );
        setOSBVehicleStepPayload({ vinMileage: finalMileage });
        if (finalMileage === '') {
            return false;
        }
        return true;
    };

    const validateVinRegNo = (vinRegNo: string) => {
        if (vinRegNo === '') {
            setVinRegErrorMsg(
                getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.VIN_REG_VALIDATION_MESSAGE,
                    vehicleStepContent
                )
            );
            return false;
        } else {
            if (isThisVIN(vinRegNo)) {
                //VIN
                const isVinValid = isValidVin(
                    vinRegNo,
                    osbVehicleStep.vinRegDataPattern
                );
                if (!isVinValid) {
                    setVinRegErrorMsg(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.INVALID_VIN_ERROR_MESSAGE,
                            vehicleStepContent
                        )
                    );
                    return false;
                } else {
                    setVinRegErrorMsg('');
                    return true;
                }
            } else {
                // REG NO
                const isRegNoValid = isValidVin(
                    vinRegNo,
                    osbVehicleStep.vinRegDataPattern
                );
                if (!isRegNoValid) {
                    setVinRegErrorMsg(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.INVALID_REG_ERROR_MESSAGE,
                            vehicleStepContent
                        )
                    );
                    return false;
                } else {
                    setVinRegErrorMsg('');
                    return true;
                }
            }
        }
    };
    const confirmVehicle = (vehicleInfo: VehicleInfo, callfrom: string) => {
        setIsLoading(true);
        setServiceError('');
        osbVehicleStep.vehicleDetails = {
            modelName: vehicleInfo?.modelName || '',
            engine: vehicleInfo?.engine || '',
            transmission: vehicleInfo?.transmission || '',
            fuelType: vehicleInfo?.fuelType || '',
            color: vehicleInfo?.color || '',
            registrationNumber: vehicleInfo?.registrationNumber || '',
            vin: vehicleInfo?.vin || '',
            buildDate: vehicleInfo?.buildDate || '',
            vehicleImageURL: vehiclePicUrl,
        };
        triggerVehicleReviewAnalytics(
            vehicleInfo?.modelName,
            vehicleInfo?.buildDate,
            osbServiceStep.voucherCode,
            fireEvents
        );

        getVehicleRecall(vehicleInfo?.vin || '');
        getVehicleVHAAlerts(vehicleInfo?.vin || '');
        const vehicleDataInfo = getVehicleData(osbVehicleStep);
        vehicleDataInfo.vin = vehicleInfo?.vin || '';
        vehicleDataInfo.registrationNumber = '';

        setOSBVehicleStep({ ...osbVehicleStep });
        // TODO: very bad hack to mutate state values but the refactor was costly to introduce useEffect to make it reactive.
        bookable.vehicle = vehicleDataInfo;
        fetchDealerServices(vehicleDataInfo, callfrom);
    };

    const deleteAnalyticField = (deleteField: any, type: string) => {
        delete (window as any).digitalData?.[type]?.[deleteField];
    };

    const getVehicle = async (callfrom: string) => {
        if ((window as any).digitaldata?.page?.errorTracking) {
            deleteAnalyticField('errorTracking', 'page');
        }
        if ((window as any).digitaldata?.page?.errorCodes) {
            deleteAnalyticField('errorCodes', 'page');
        }
        setServiceError('');
        if (
            osbVehicleStep.vinRegNo !== '' &&
            osbVehicleStep.vinMileage !== ''
        ) {
            dispatch({ type: 'REQUEST' });
            let vehicleData: VehicleData;
            if (osbVehicleStep.vinRegNo.length <= 10) {
                vehicleData = {
                    registrationNumber: formatRegVinNumber(
                        osbVehicleStep.vinRegNo,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.vinMileage,
                };
            } else {
                vehicleData = {
                    vin: formatRegVinNumber(
                        osbVehicleStep.vinRegNo,
                        osbVehicleStep.allowSpecialCharacters
                    ),
                    mileage: osbVehicleStep.vinMileage,
                };
            }
            await ServiceHandler.OsbVehicleLookUp.postVehicleInfo({
                vehicleData: vehicleData,
                prognosticsData: osbVehicleStep.vehiclePrognosticData,
                dealerCode: osbDealerStep.selectedDealerProfile.dealerCode,
                bookable: bookable,
            })
                .then(result => {
                    fetchVehicleImage({
                        model: result.value.modelName,
                        make: brand,
                        vin: result.value.vin,
                        year: parseInt(result.value.buildDate.substring(0, 4)),
                    });
                    setVehicleInfo(result.value);
                    triggerVehicleInfoAnalytics(
                        result.value.modelName,
                        result.value.buildDate.substring(0, 4),
                        osbVehicleStep.vinMileage,
                        osbServiceStep.voucherCode,
                        fireEvents
                    );
                    confirmVehicle(result.value, callfrom);
                    dispatch({ type: 'RESPONSE' });
                })
                .catch((error: any) => {
                    setIsError(true);
                    dispatch({
                        type: 'ERROR',
                        error: error.errorMessage,
                    });

                    if (error) {
                        const errorMessage = error.errorCode;
                        if (errorMessage === 'OSB_VIN_EXISTS') {
                            setServiceError('ACTIVE_BOOKING');
                            triggerVehicleActiveBookingExistsAnalytics(
                                osbServiceStep.voucherCode,
                                fireEvents
                            );
                        } else if (
                            errorMessage === 'OSB_NO_DATAFOUND' ||
                            errorMessage === 'OSB_VEHICLE_DETAILS_NOT_FOUND' ||
                            errorMessage === 'VEHICLE_NOT_FOUND'
                        ) {
                            if (osbVehicleStep.vinRegNo.length <= 10) {
                                setServiceError('REG_NOT_FOUND');
                            } else {
                                setServiceError('VIN_NOT_FOUND');
                            }
                            triggerVehicleNotFoundAnalytics(
                                osbServiceStep.voucherCode,
                                fireEvents
                            );
                        } else {
                            setServiceError('SERVICE_ERROR');
                        }
                    }
                });
        } else {
            if (!osbVehicleStep.vinRegNo?.trim()) {
                validateVinRegNo('');
            }
            if (!osbVehicleStep.vinMileage?.trim()) {
                const errorMsg = getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.MILEAGE_VALIDATION_MESSAGE,
                    vehicleStepContent
                );
                setMileageError(errorMsg);
            }
        }
    };

    const userListDropdown = () => {
        const vehiclesList: UserVehicleList[] | undefined =
            osbServiceStep.userVehicles;
        const VehicleNameList: Option[] = [];
        if (vehiclesList !== undefined) {
            vehiclesList.forEach((item: UserVehicleList) => {
                const VehicleModelAndYear =
                    item.modelName + ' ' + item.modelYear;
                const vehicleName = item.nickName
                    ? item.nickName
                    : VehicleModelAndYear;
                const option: Option = {
                    label: vehicleName,
                    value: item.vin,
                };
                VehicleNameList.push(option);
            });
        }
        const manualEntryFieldLabel = getObjectFromAEMJson(
            OSB_AEM_PROPERTY_KEYS.VIN_OR_REG_ENTRY_FIELD_LABEL,
            vehicleStepMoreContent
        );
        if (manualEntryFieldLabel !== '' && manualEntryFieldLabel !== null) {
            const defaultOption: Option = {
                label: manualEntryFieldLabel,
                value: 'manualEntry',
            };

            VehicleNameList.push(defaultOption);
        }

        return VehicleNameList;
    };

    const onUserVinChange = (label: any, index: any, value: any) => {
        if (value === 'manualEntry') {
            setIsManualVinRegEntry(true);
            setOSBVehicleStepPayload({
                vehiclePrognosticData: getInitialPrognosticsDetails(),
            });
            setOSBVehicleStepPayload({ vinRegNo: '' });
            setOSBVehicleStepPayload({ vinMileage: '' });
            triggerAuthJourneyVehicleManualEntryAnalytics(
                osbVehicleStep,
                fireEvents
            );
        } else {
            setIsManualVinRegEntry(false);
            setOSBVehicleStepPayload({
                vehiclePrognosticData: getInitialPrognosticsDetails(),
            });
            setOSBVehicleStepPayload({ vinRegNo: value });
            setOSBVehicleStepPayload({ vehicleLabel: label });
            getVinsPrognosticsRecords(value || '');
        }
    };

    const vinRegLabelShow = () => {
        setIsVinRegNoLabelShow(true);
    };

    const vinRegLabelHide = () => {
        setIsVinRegNoLabelShow(false);
    };

    const mileageLabelShow = () => {
        setisMileageLabelShow(true);
    };

    const mileageLabelHide = () => {
        setisMileageLabelShow(false);
    };
    const onVinRegChange = (e: any) => {
        setServiceError('');
        validateVinRegNo(e.target.value);
        setOSBVehicleStepPayload({ vinRegNo: e.target.value });
    };
    const onMileageChange = (e: any) => {
        const isMileageValid = isValidMileage(e.target.value);
        if (!isMileageValid) {
            const errorMsg = getObjectFromAEMJson(
                OSB_AEM_PROPERTY_KEYS.MILEAGE_VALIDATION_MESSAGE,
                vehicleStepContent
            );
            setMileageError(errorMsg);
        } else {
            setMileageError('');
        }
    };
    const renderHowToFindYourVin = () => {
        setIsVinRegModalVisible(true);
    };
    const clearVehicleInfo = () => {
        if (osbStep.isPcFlow) {
            setOSBStepPayload({ preSelectedServices: '' });
        }
        setVehicleInfo(getInitialVehicleDetails());
        const vehicleVin = osbVehicleStep.vinRegNo;
        const vehicleLabel = osbVehicleStep.vehicleLabel;
        // TODO: vey bad hack of mutating state value. cant refactor as the cost is high to make reactive style of workflow.
        bookable.vehicle = {
            modelName: osbVehicleStep.defaultModelName,
            buildYear: osbVehicleStep.defaultBuildYear,
            mileage: osbVehicleStep.defaultMileage,
        };
        resetVehicleStep();
        setServiceError('');
        setVehiclePicUrl('');
        fetchDealerServices(
            {
                modelName: osbVehicleStep.defaultModelName,
                buildYear: osbVehicleStep.defaultBuildYear,
                mileage: osbVehicleStep.defaultMileage,
            },
            OSB_AEM_PROPERTY_KEYS.OLOAD_VEHICLE_CALL_TYPE
        );
        setOSBServiceStepPayload({
            comment: '',
        });

        if (osbStep.isAuthenticatedFlow && osbServiceStep.userVehicles) {
            setOSBVehicleStepPayload({
                vehicleLabel: vehicleLabel,
                vinRegNo: vehicleVin,
            });
            getVinsPrognosticsRecords(vehicleVin);
            setIsManualVinRegEntry(false);
        }
        triggerVehicleChangeRemoveCTAClickAnalytics(
            osbVehicleStep.vehicleDetails.modelName,
            osbVehicleStep.vehicleDetails.buildDate.substring(0, 4),
            fireEvents
        );
    };
    const initializeVehicleInfoFromStore = () => {
        setVehiclePicUrl(osbVehicleStep.vehicleDetails.vehicleImageURL);
        setVehicleInfo(osbVehicleStep.vehicleDetails);
    };

    useEffect(() => {
        if (osbStep.isAuthenticatedFlow && osbVehicleStep.vinRegNo) {
            getVinsPrognosticsRecords(osbVehicleStep.vinRegNo);
        }
        if (isMandatorySection) {
            if (!osbVehicleStep.vinRegNo) {
                validateVinRegNo(osbVehicleStep.vinRegNo);
            } else if (!osbVehicleStep.vinMileage) {
                const isMileageValid = isValidMileage(
                    osbVehicleStep.vinMileage
                );

                if (!isMileageValid) {
                    const errorMsg = getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.MILEAGE_VALIDATION_MESSAGE,
                        vehicleStepContent
                    );
                    setMileageError(errorMsg);
                }
            } else {
                setServiceError('VEHICLE_DATA_NOT_CONFIRMED');
            }
        }
    }, [props.shouldValidateVehicleData]);

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        fetchVehicleStepContent(OSB_AEM_PROPERTY_KEYS.VEHICLE_STEP);
        fetchVehicleStepContent(
            OSB_AEM_PROPERTY_KEYS.VEHICLE_STEP_MORE_ELEMENTS
        );
        initializeVehicleInfoFromStore();
        if (
            osbVehicleStep.vinRegNo !== '' &&
            osbVehicleStep.vinMileage !== ''
        ) {
            setIsManualVinRegEntry(true);
            getVehicle(OSB_AEM_PROPERTY_KEYS.OLOAD_VEHICLE_CALL_TYPE);
        }
    }, []);
    useEffect(() => {
        // Specific Service component wait till All content load from Vehicle Finder
        if (!httpState.isLoading) {
            props.loadSpecificServiceComponent(true);
        }
    }, [httpState.isLoading]);

    const addVehicleToFordAccount = async (vehicleInfo: VehicleInfo) => {
        triggerAuthJourneySaveVinAnalytics(fireEvents);

        if (vehicleInfo && vehicleInfo?.vin) {
            dispatch({ type: 'REQUEST' });
            const nickname: string =
                vehicleInfo?.modelName +
                ' ' +
                parseInt(vehicleInfo?.buildDate?.substring(0, 4));

            await profileService
                .addVehicle(vehicleInfo?.vin, nickname)
                .then(result => {
                    if (result.status !== 200 || result.error) {
                        props.osbAlertMessagePopup(
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.ADD_VEHICLE_TO_ACCOUNT_ERROR_MESSAGE,
                                vehicleStepMoreContent
                            ),
                            false
                        );
                    } else if (result.status === 200) {
                        props.osbAlertMessagePopup('success', true);
                    }
                    setOpen(false);
                    dispatch({ type: 'RESPONSE' });
                })
                .catch((error: any) => {
                    setOpen(false);
                    props.osbAlertMessagePopup(
                        getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.ADD_VEHICLE_TO_ACCOUNT_ERROR_MESSAGE,
                            vehicleStepMoreContent
                        ),
                        false
                    );
                    dispatch({
                        type: 'ERROR',
                        error: error.message,
                    });
                });
        } else {
            setOpen(false);
        }
    };

    const handleChooseYourVehicleClick = () => {
        setIsManualVinRegEntry(false);
        setOSBVehicleStepPayload({
            vinMileage: '',
        });
        userListDropdown();
    };

    function renderErrorMessages() {
        return (
            <>
                {serviceError && serviceError === 'ACTIVE_BOOKING' && (
                    <div className="error-message">
                        {parse(
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.ACTIVE_BOOKING_RTE_CONTENT,
                                vehicleStepContent
                            )
                        )}
                    </div>
                )}
                {serviceError &&
                    ((serviceError === 'REG_NOT_FOUND' && (
                        <div className="error-message">
                            {parse(
                                getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.VEHICLE_NOT_FOUND_MESSAGE,
                                    vehicleStepContent
                                )
                            )}
                        </div>
                    )) ||
                        (serviceError === 'VIN_NOT_FOUND' && (
                            <div className="error-message">
                                {parse(
                                    getObjectFromAEMJson(
                                        OSB_AEM_PROPERTY_KEYS.VIN_VEHICLE_NOT_FOUND_MESSAGE,
                                        vehicleStepMoreContent
                                    )
                                )}
                            </div>
                        )) ||
                        (serviceError === 'VEHICLE_DATA_NOT_CONFIRMED' && (
                            <div className="error-message">
                                {parse(
                                    getObjectFromAEMJson(
                                        OSB_AEM_PROPERTY_KEYS.MANDATORY_VEHICLE_SECTION_ERROR_MESSAGE,
                                        vehicleStepMoreContent
                                    )
                                )}
                            </div>
                        )))}
                {serviceError && serviceError === 'SERVICE_ERROR' && (
                    <div className="error-message">
                        {parse(
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.VEHICLE_SERVICE_ERROR_MESSAGE,
                                vehicleStepContent
                            )
                        )}
                    </div>
                )}
                {serviceError && serviceError === 'INTERNAL_ERROR_MESSAGE' && (
                    <div className="error-message">
                        {osbStep.internalErrorMessage}
                    </div>
                )}
            </>
        );
    }

    function renderVinDropdown() {
        return (
            <>
                {osbServiceStep.userVehicles !== undefined &&
                osbServiceStep.userVehicles.length > 0 &&
                !isManualVinRegEntry ? (
                    <>
                        <label className="input-label">
                            {getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.CHOOSE_YOUR_VEHICLE_DROPDOWN_LABEL,
                                vehicleStepMoreContent
                            )}
                        </label>
                        <Dropdown
                            dataTestId="Select-Vin"
                            showLabel={false}
                            label={
                                osbVehicleStep.vehicleLabel !== ''
                                    ? osbVehicleStep.vehicleLabel
                                    : ''
                            }
                            options={userListDropdown()}
                            value={osbVehicleStep.vehicleLabel}
                            className="osb-dropdown-input"
                            onChange={onUserVinChange}
                        />
                    </>
                ) : (
                    <div className="osb-input-field-vin">
                        <label className="input-label">
                            {(osbVehicleStep.vinRegNo.length > 0 ||
                                isVinRegNoLabelShow) && (
                                <span>
                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.VIN_REGISTRATION_LABEL,
                                            vehicleStepContent
                                        )
                                    )}
                                </span>
                            )}
                            {vinRegErrorMsg && (
                                <img
                                    className="osb-error-icon"
                                    src={errorWarning}
                                    alt="Error icon"
                                />
                            )}
                        </label>
                        <div className="vin-input-container">
                            <input
                                id="vinRegInput"
                                data-testid="VinORegistrationInputField"
                                type="text"
                                className={`input-field${
                                    vinRegErrorMsg ? '-error' : ''
                                } vin-mobile`}
                                placeholder={getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.VIN_REGISTRATION_LABEL,
                                    vehicleStepContent
                                )}
                                aria-label="vinRegInput"
                                aria-labelledby="vinRegInput"
                                name="vinRegInput"
                                value={osbVehicleStep.vinRegNo.toUpperCase()}
                                onChange={e => onVinRegChange(e)}
                                onBlur={vinRegLabelHide}
                                onFocus={vinRegLabelShow}
                            />
                            <div
                                data-testid="VinAndRegInfoPopup"
                                className="vin-reg-info-icon"
                                onClick={() => {
                                    triggerVinMileageInfoTooltipClickAnalytics(
                                        'vin',
                                        fireEvents
                                    );
                                    renderHowToFindYourVin();
                                }}
                            >
                                <img src={InfoIconL} alt="Info" />
                            </div>
                        </div>
                        {vinRegErrorMsg ? (
                            <div className="error-message">
                                {vinRegErrorMsg}
                            </div>
                        ) : (
                            <div className=""></div>
                        )}
                    </div>
                )}
            </>
        );
    }

    function renderHowToFinVinSection() {
        return (
            <>
                {osbStep.isAuthenticatedFlow && isManualVinRegEntry && (
                    <div className="how-to-find-your-vin">
                        <div onClick={handleChooseYourVehicleClick}>
                            <span className="how-to-find-your-vin-label">
                                {getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.CHOOSE_YOUR_VEHICLE_LABEL,
                                    vehicleStepMoreContent
                                )}
                            </span>
                        </div>
                    </div>
                )}
            </>
        );
    }

    function renderVehicleMileage() {
        return (
            <div className="osb-input-field-mileage">
                <label className="input-label">
                    {(isMileageLabelShow ||
                        osbVehicleStep.vinMileage.length > 0) && (
                        <span>
                            {parse(
                                getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.MILEAGE_LABEL,
                                    vehicleStepContent
                                )
                            )}
                        </span>
                    )}
                    {mileageError ? (
                        <img
                            className="osb-error-icon"
                            src={errorWarning}
                            alt="Error icon"
                        />
                    ) : (
                        <div></div>
                    )}
                </label>

                <input
                    id="mileage"
                    data-testid="MileageInputField"
                    type="text"
                    className={`input-field${mileageError ? '-error' : ''} `}
                    placeholder={getObjectFromAEMJson(
                        OSB_AEM_PROPERTY_KEYS.MILEAGE_LABEL,
                        vehicleStepContent
                    )}
                    aria-label="mileage"
                    aria-labelledby="mileage"
                    name="mileage"
                    value={osbVehicleStep.vinMileage}
                    onChange={e => onMileageChange(e)}
                    onBlur={mileageLabelHide}
                    onFocus={mileageLabelShow}
                />
                {mileageError ? (
                    <div className="error-message">{mileageError}</div>
                ) : (
                    <div className=""></div>
                )}
            </div>
        );
    }

    const renderVehicleDetails = () => {
        let logicToRender = null;
        if (
            OsbUtilService.isEmptyObject(vehicleInfo) ||
            vehicleInfo?.vin === '' ||
            isError
        ) {
            logicToRender = (
                <>
                    {renderErrorMessages()}
                    {renderVinDropdown()}
                    {renderHowToFinVinSection()}
                    {renderVehicleMileage()}
                    <div className="bottom-text">
                        {parse(
                            getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.BOTTOM_TEXT,
                                vehicleStepMoreContent
                            )
                        )}
                    </div>
                    <div className="vehicle-detail-submit-cta">
                        <FMButton
                            type="primary"
                            label={getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.SUBMIT_BUTTON_LABEL,
                                vehicleStepMoreContent
                            )}
                            onClick={() =>
                                getVehicle(
                                    OSB_AEM_PROPERTY_KEYS.SUBMIT_VEHICLE_CALL_TYPE
                                )
                            }
                            theme="light"
                            aria-label="service-card-list-cta"
                            aria-labelledby="select a service cta"
                        />
                    </div>
                </>
            );
        } else {
            logicToRender = (
                <>
                    <div className="vehicle-model-container">
                        <div className="vehicle-detail-section">
                            <div className="vehicle-detail-modal">
                                {vehicleInfo?.buildDate.substring(0, 4)}{' '}
                                {vehicleInfo?.modelName}
                            </div>
                            <div className="vehicle-miles">
                                {vehicleInfo?.mileageInMiles &&
                                    addMileageSeparators(
                                        vehicleInfo?.mileageInMiles,
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MILEAGE_SEPARATOR_LABEL,
                                            vehicleStepMoreContent
                                        )
                                    )}{' '}
                                {getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.VEHICLE_DISTANCE_LABEL,
                                    vehicleStepMoreContent
                                )}
                            </div>

                            {vehicleInfo?.vin !== '' && !isMobileView ? (
                                <>
                                    <div className="vehicle-detail-your-vehicle">
                                        <div className="confirmBox">
                                            <div className="not-your-vehicle-label">
                                                {getObjectFromAEMJson(
                                                    OSB_AEM_PROPERTY_KEYS.MODAL_NOT_YOUR_VEHICLE,
                                                    vehicleStepMoreContent
                                                )}
                                            </div>
                                            <div className="change-or-remove-link">
                                                <a onClick={clearVehicleInfo}>
                                                    {getObjectFromAEMJson(
                                                        OSB_AEM_PROPERTY_KEYS.MODAL_CHANGE_OR_REMOVE_LABEL,
                                                        vehicleStepMoreContent
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="blank-space"></div>
                            )}
                        </div>

                        {vehiclePicUrl && vehiclePicUrl !== '#' && (
                            <div className="vehicle-image-container">
                                <div className="vehicle-detail-image">
                                    <img
                                        className="osb-vehicle-image"
                                        src={vehiclePicUrl}
                                        alt=""
                                        crossOrigin="anonymous"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {vehicleInfo?.vin !== '' && isMobileView ? (
                        <>
                            <div className="vehicle-detail-your-vehicle">
                                <div className="confirmBox">
                                    <div className="not-your-vehicle-label">
                                        {getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_NOT_YOUR_VEHICLE,
                                            vehicleStepMoreContent
                                        )}
                                    </div>
                                    <div className="change-or-remove-link">
                                        <a onClick={clearVehicleInfo}>
                                            {getObjectFromAEMJson(
                                                OSB_AEM_PROPERTY_KEYS.MODAL_CHANGE_OR_REMOVE_LABEL,
                                                vehicleStepMoreContent
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="blank-space"></div>
                    )}

                    {
                        <>
                            <div>
                                <Popup
                                    className={`brand-${osbStep.brandName} add-vehicle-popup`}
                                    modal={true}
                                    closeOnDocumentClick={false}
                                    open={open}
                                >
                                    <div
                                        className={`brand-${osbStep.brandName} modal-vehicle-add`}
                                    >
                                        <a
                                            className="close-button"
                                            onClick={closeModal}
                                        >
                                            &times;
                                        </a>

                                        <div className="vehicle-content-header">
                                            {getObjectFromAEMJson(
                                                OSB_AEM_PROPERTY_KEYS.ADD_VEHICLE_POPUP_HEADER_LABEL,
                                                vehicleStepMoreContent
                                            )}
                                        </div>
                                        <div className="add-vehicle-modal-body">
                                            {parse(
                                                getObjectFromAEMJson(
                                                    OSB_AEM_PROPERTY_KEYS.ADD_VEHICLE_POPUP_BODY_LABEL,
                                                    vehicleStepMoreContent
                                                )
                                            )}
                                        </div>
                                        <div className="add-vehicle-button">
                                            <PrimaryButton
                                                className="primary-button"
                                                role="link"
                                                color={'dark'}
                                                fill={'fill'}
                                                chevron={false}
                                                onClick={() =>
                                                    addVehicleToFordAccount(
                                                        vehicleInfo
                                                    )
                                                }
                                            >
                                                {getObjectFromAEMJson(
                                                    OSB_AEM_PROPERTY_KEYS.ADD_VEHICLE_POPUP_BUTTON,
                                                    vehicleStepMoreContent
                                                )}
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </Popup>
                            </div>
                        </>
                    }
                </>
            );
        }
        return (
            <div className="vehicle-details-container">
                {isLoading && (
                    <OsbLoader
                        osbLoaderMessage={getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.VEHICLE_LOADER_MESSAGE,
                            vehicleStepMoreContent
                        )}
                        progressPercent={progressPercentToDisplay}
                    />
                )}
                {httpState.isLoading ? <OsbLoader /> : logicToRender}
            </div>
        );
    };
    const onCloseVinRegInfo = () => {
        setIsVinRegModalVisible(false);
    };

    return (
        <div className="vehicle-detail-container">
            {httpState.isLoading ? <OsbLoader /> : renderVehicleDetails()}
            <Modal
                name="vin-reg-modal-popup"
                role="dialog"
                aria-label="Vin and Reg information"
                onClose={onCloseVinRegInfo}
                isVisible={isVinRegModalVisible}
            >
                <div className="vin-content">
                    <h3 className="vin-info-title">
                        {getObjectFromAEMJson(
                            OSB_AEM_PROPERTY_KEYS.MODAL_FIND_YOUR_VIN_TITLE,
                            vehicleStepMoreContent
                        )}
                    </h3>

                    <div className="vin-info-body-content">
                        <div className="vin-subtitle">
                            {getObjectFromAEMJson(
                                OSB_AEM_PROPERTY_KEYS.MODAL_FIND_YOUR_VIN_SUBTITLE,
                                vehicleStepMoreContent
                            )}
                        </div>
                        <div className="vin-details">
                            <ul className="vin-info-list">
                                <li>
                                    <img
                                        className=""
                                        src={vinNumberWindowVehicle}
                                    />

                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_DRIVER_SIDE_DOORFRAME,
                                            vehicleStepMoreContent
                                        )
                                    )}
                                </li>
                                <li>
                                    <img
                                        className=""
                                        src={vinNumberDocumentation}
                                    />

                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_YOUR_VEHICLE_DOCUMENTATION_DESCRIPTION,
                                            vehicleStepMoreContent
                                        )
                                    )}
                                </li>
                            </ul>
                            <ul className="vin-info-list">
                                <li>
                                    <img
                                        className=""
                                        src={vinNumberWindowVehicleFront}
                                    />

                                    {parse(
                                        getObjectFromAEMJson(
                                            OSB_AEM_PROPERTY_KEYS.MODAL_VISIBLE_THROUGH_WINDSHIELD_DESCRIPTION,
                                            vehicleStepMoreContent
                                        )
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {getObjectFromAEMJson(
                    OSB_AEM_PROPERTY_KEYS.MODAL_REGISTRATION_NUMBER_DESCRIPTION,
                    vehicleStepMoreContent
                ) !== '' && (
                    <>
                        <p className="modal-line" />
                        <div className="reg-container">
                            <h3 className="vin-info-title">
                                {getObjectFromAEMJson(
                                    OSB_AEM_PROPERTY_KEYS.MODAL_FIND_YOUR_REGISTRATION_NUMBER_LABEL,
                                    vehicleStepMoreContent
                                )}
                            </h3>

                            <div className="reg-content">
                                <img className="" src={regPlateNumber} />
                                {parse(
                                    getObjectFromAEMJson(
                                        OSB_AEM_PROPERTY_KEYS.MODAL_REGISTRATION_NUMBER_DESCRIPTION,
                                        vehicleStepMoreContent
                                    )
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};
