import React from 'react';
import Link from '../../../common/link/link';

interface Props {
    href: string;
    ariaLabel: string;
    content: string;
    class?: string;
}

const MmotaModalLink = (props: Props) => {
    const { href, ariaLabel, content } = props;
    return (
        <p>
            <Link
                className={['mmota-modal-link', props.class].join(' ').trim()}
                href={href}
                aria-label={ariaLabel}
                rel="noopener noreferrer"
                target="_blank"
            >
                {content}
            </Link>
        </p>
    );
};
export default MmotaModalLink;
