import { useEffect, useState } from 'react';
import { LocalizationBreadcrumbs } from '../models/experiencefragments/localization-breadcrumbs';
import { removeHyphen } from '../views/category-view/category-view-handler';
import { useExperienceContent } from './use-server-content';

export function useLocalizationBreadcrumbs(linkTitle = ''): string {
    const [localBreadcrumb, setLocalBreadcrumb] = useState(linkTitle);
    const [experienceContent] = useExperienceContent<LocalizationBreadcrumbs>(
        'common',
        'localize-breadcrumbs',
        'localize_breadcrumbs'
    );
    let localBreadcrumbTitle = removeHyphen(linkTitle);
    const breadcrumbLocalValue =
        experienceContent &&
        experienceContent?.properties?.find(
            item =>
                removeHyphen(item.propertyDescriptor).toLowerCase() ===
                removeHyphen(linkTitle).toLowerCase()
        );
    if (breadcrumbLocalValue) {
        localBreadcrumbTitle = breadcrumbLocalValue.value;
    }
    useEffect(() => {
        setLocalBreadcrumb(localBreadcrumbTitle);
    }, [experienceContent, linkTitle]);
    return localBreadcrumb;
}
