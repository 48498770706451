import React, { useEffect, useRef } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { COOKIE_CONFIGURATION, GDPR_COOKIE } from '../../../constants';
import { useGdprBannerContent } from './hooks/use-gdpr-banner-content';
import PrimaryButton from './../../common/primary-button/primary-button';
import { useAnalytics } from '../../../hooks/use-analytics';
import { scriptService } from '../../../services/script-service/script-service';
import './gdpr-banner.scss';
import { GdprModalButtonType } from '../../../services/shortcode-service/shortcode-service';
import ServiceHandler from '../../../services/service-handler';
export const GdprBanner = () => {
    const { currentDomain } = ServiceHandler.AppConfigurationService;
    const [fireEvents] = useAnalytics();
    const bannerRef = useRef<HTMLDivElement>(null);
    const [, setCookie] = useCookies([GDPR_COOKIE, 'cookie-configuration']);
    const cookieDomain = currentDomain.substring(
        currentDomain.indexOf('.'),
        currentDomain.length
    );
    const cookie_options = {
        path: '/',
        domain: cookieDomain,
    };
    const gdprBannerContent = useGdprBannerContent();
    const bannerDisplay = (show: boolean) => {
        const gdprBanner = document.querySelector(
            '.gdpr-visibility-on'
        ) as HTMLDivElement;
        if (gdprBanner)
            show
                ? gdprBanner.classList.remove('hide-me')
                : gdprBanner.classList.add('hide-me');
    };
    const handleCookie = (decision?: string): void => {
        const timestampNow = Date.now();
        const timestampExpire = new Date();
        timestampExpire.setDate(new Date().getDate() + 30);
        setCookie(GDPR_COOKIE, timestampNow, cookie_options);
        let userAcceptanceStatus: GdprModalButtonType = 'Decline';
        let cookieConfiguration: string;
        if (decision === 'accept') {
            userAcceptanceStatus = 'Accept';
            cookieConfiguration = JSON.stringify({
                expire: timestampExpire.getTime(),
                timestamp: timestampNow,
                Domain: currentDomain,
                value: {
                    functionality: true,
                    performance: true,
                    'strictly necessary': true,
                    targeting: true,
                },
            });
        } else {
            cookieConfiguration = JSON.stringify({
                expire: timestampExpire.getTime(),
                timestamp: timestampNow,
                Domain: currentDomain,
                value: {
                    functionality: false,
                    performance: false,
                    'strictly necessary': true,
                    targeting: false,
                },
            });
        }
        localStorage.setItem(COOKIE_CONFIGURATION, cookieConfiguration);
        setCookie(COOKIE_CONFIGURATION, cookieConfiguration, cookie_options);
        fireEvents(
            'moduleInternalCtaClick',
            undefined,
            { gdprModalButtonPressed: Promise.resolve(userAcceptanceStatus) },
            false
        );
        scriptService.loadDynatraceTags();
        scriptService.setMouseflowScript();
        scriptService.loadCafexScript();
        bannerDisplay(false);
        document.body.classList.remove('gdpr-open');
    };
    const keyDownHandler = (e: any) => {
        const focusableElements = bannerRef.current!.querySelectorAll(
            'button, [href]'
        );
        const firstFocusableElement = focusableElements[0] as HTMLElement;
        const lastFocusableElement = focusableElements[
            focusableElements.length - 1
        ] as HTMLElement;
        const isTabPressed = (e: any) => e.key === 'Tab' || e.keyCode === 9;
        if (!isTabPressed || e.key === ' ') {
            return;
        }
        if (isTabPressed(e)) {
            if (
                !e.shiftKey &&
                document.activeElement === lastFocusableElement
            ) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
            if (
                e.shiftKey &&
                document.activeElement === firstFocusableElement
            ) {
                e.preventDefault();
                lastFocusableElement.focus();
            }
        }
    };
    useEffect(() => {
        if (!new Cookies().get(GDPR_COOKIE)) {
            bannerDisplay(true);
            document.body.classList.add('gdpr-open');
        } else {
            document.body.classList.remove('gdpr-open');
        }
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler, false);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, false);
        };
    }, []);
    return (
        <div>
            {gdprBannerContent && (
                <div
                    className="gdpr-visibility-on hide-me"
                    data-testid="gdpr-banner"
                >
                    <div
                        className="overlay"
                        onClick={() => bannerDisplay(false)}
                    />
                    <div className="gdpr-container" ref={bannerRef}>
                        <div className="brand-logo">
                            <img
                                className="logo"
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    gdprBannerContent.fordLogoImagePath
                                }
                                alt=""
                            />
                        </div>
                        <div className="gdpr-banner-content">
                            <div className="gdpr-banner-text">
                                <div
                                    className="gdpr-banner__paragraph--one"
                                    dangerouslySetInnerHTML={{
                                        __html: gdprBannerContent.paragraphOne,
                                    }}
                                />
                            </div>
                            <div className="gdpr-buttons__container">
                                <PrimaryButton
                                    dataTestId="accept-cookies-button"
                                    className="gdpr-button-dark"
                                    color="dark"
                                    fill="fill"
                                    chevron={false}
                                    ariaLabel={
                                        gdprBannerContent.acceptCookiesButtonAriaLabel
                                    }
                                    onClick={() => handleCookie('accept')}
                                >
                                    {gdprBannerContent.acceptCookiesButtonLabel}
                                </PrimaryButton>
                                <PrimaryButton
                                    dataTestId="decline-cookies-button"
                                    className="light"
                                    color="light"
                                    fill="outline"
                                    chevron={false}
                                    ariaLabel={
                                        gdprBannerContent.declineCookiesButtonAriaLabel
                                    }
                                    onClick={() => handleCookie()}
                                >
                                    {
                                        gdprBannerContent.declineCookiesButtonLabel
                                    }
                                </PrimaryButton>
                            </div>
                            <div className="gdpr-banner-text">
                                <div
                                    className="gdpr-banner__paragraph--two"
                                    dangerouslySetInnerHTML={{
                                        __html: gdprBannerContent.paragraphTwo,
                                    }}
                                ></div>
                            </div>
                            <div className="gdpr-banner-text">
                                <div
                                    className="gdpr-banner__paragraph--three"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            gdprBannerContent.paragraphThree,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default GdprBanner;
