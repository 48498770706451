import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { findByAlias } from '../../../../routes';
import { FMButton, FMDropdown } from '@own/fds-react';
import './model-year-dropdown.scss';
import { useDyfEligibleYears } from '../../../../hooks/dyf/use-dyf-eligible-years/use-dyf-eligible-years';
import { useAllDyfEligibleModels } from '../../../../hooks/use-all-dyf-eligible-models';
import { EligibleModelData } from '../../../../hooks/dyf/use-dyf-eligible-model/use-dyf-eligible-model';

interface Props {
    yearDropdownLabel: string;
    modelDropdownLabel: string;
    submitLabel: string;
    submitButtonAriaLabel?: string;
    onYmmSubmit?: (modelAlias: string, modelYear: string) => void;
}

const ModelYearDropdown = (props: Props) => {
    const [selectedYear, setSelectedYear] = useState<string>('');
    const [
        selectedModel,
        setSelectedModel,
    ] = useState<EligibleModelData | null>(null);

    const [modelOptions, setModelOptions] = useState<EligibleModelData[]>([]);

    const { data: yearOptions } = useDyfEligibleYears();

    const { models } = useAllDyfEligibleModels();

    const history = useHistory();

    const handleYearDropdownChange = (e: any) => {
        setSelectedYear(e.target.value);
        setSelectedModel(null);
        setModelOptions(models.filter(m => m.modelYear == e.target.value));
    };

    const handleModelDropdownChange = (e: any) => {
        const foundModel = modelOptions.find(
            m => m.modelAlias == e.target.value
        );
        foundModel && setSelectedModel(foundModel);
    };

    const handleYmmSubmit = () => {
        if (selectedYear && selectedModel) {
            history.push(
                findByAlias('DyfLandingView')[1]
                    .replace(':year', selectedYear)
                    .replace(':model', selectedModel?.modelAlias)
            );
            props.onYmmSubmit &&
                props.onYmmSubmit(
                    selectedModel.modelAlias,
                    selectedModel.modelYear
                );
        }
    };

    return (
        <div className="onboarding-ymm-vin-container">
            <div className="onboarding-ymm-vin-body">
                <div className="onboarding-ymm-container-dropdown">
                    <FMDropdown
                        header={props.yearDropdownLabel}
                        options={yearOptions.flatMap(y => `${y}`)}
                        onChange={handleYearDropdownChange}
                        data-testid="onboarding-year-dropdown"
                        ariaLabel={props.yearDropdownLabel}
                    />
                    <FMDropdown
                        ariaLabel={props.modelDropdownLabel}
                        header={props.modelDropdownLabel}
                        disabled={!selectedYear}
                        options={modelOptions.flatMap(m => ({
                            value: m.modelAlias,
                            label: m.modelDisplayName,
                        }))}
                        onChange={handleModelDropdownChange}
                        data-testid="onboarding-model-dropdown"
                    />
                </div>
                <FMButton
                    type={'primary'}
                    chevron={'right'}
                    label={props.submitLabel}
                    disabled={!selectedModel}
                    onClick={handleYmmSubmit}
                    data-testid={'onboarding-ymm--selector-submit-button'}
                    ariaLabel={props.submitButtonAriaLabel || props.submitLabel}
                ></FMButton>
            </div>
        </div>
    );
};

export default ModelYearDropdown;
