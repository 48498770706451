import serverSideService from '../server-side-service/server-side-service';

class ConsentService {
    private readonly consentKey = 'OptanonConsent';

    private static getAllCookies() {
        return document.cookie.split(';').reduce((map: any, obj: any) => {
            const cookieString = obj.trim();
            const firstEqual = cookieString.indexOf('=');
            const key = cookieString.substring(0, firstEqual);
            map[key] = cookieString.substring(firstEqual + 1);
            return map;
        }, []);
    }

    private static getCookie(name: string) {
        const cookies = ConsentService.getAllCookies();
        const cookieObj = cookies[name];
        if (cookieObj) {
            return cookieObj.split('&').reduce((map: any, obj: string) => {
                const equal = obj.indexOf('=');
                const key = obj.substring(0, equal);
                map[key] = decodeURIComponent(obj.substring(equal + 1));
                return map;
            }, []);
        }
    }

    private static allowedGroupConsent(
        groupConsents: string,
        groupId: string
    ): boolean {
        // an example of groupConsents is 1:1,6:1,3:1,2:1,4:1
        let allowedConsent = true;
        if (groupConsents && groupConsents.length > 0) {
            const groups = groupConsents.split(',');
            const group = groups.filter(function(group: string) {
                const id = group.split(':')[0];
                return id === groupId;
            })[0];
            if (typeof group !== 'undefined' && group.split(':')[1] === '0') {
                allowedConsent = false;
            }
        }
        return allowedConsent;
    }

    public nonEssentialScriptsAllowed(): boolean {
        const consents = ConsentService.getCookie(this.consentKey);
        if (consents) {
            return ConsentService.allowedGroupConsent(consents['groups'], '6');
        }
        return true;
    }
    private static getGDPRCookie(name: string) {
        const cookies = ConsentService.getAllCookies();
        const cookieObj = cookies[name];
        if (cookieObj) {
            return cookieObj.split('&').reduce((map: any, obj: string) => {
                const equal = obj.indexOf('=');
                const key = obj.substring(0, equal);
                map[key] = decodeURIComponent(obj.substring(equal + 1));
                return map[key];
            }, []);
        }
    }
    public getGDPRCookieValue() {
        const gdprConsents =
            serverSideService.isClientSide() &&
            ConsentService.getGDPRCookie('cookie-configuration');
        if (gdprConsents) {
            const gdprValue = JSON.stringify({
                value: JSON.parse(gdprConsents).value,
            });
            localStorage.setItem('cookie-configuration', gdprValue);
            return JSON.parse(gdprConsents).value;
        }
    }
}

export const consentService = new ConsentService();
