import React from 'react';
import { GoBackButton } from '../../components/sections/go-back-button/go-back-button';
import { useErrorsContent } from '../page-not-found-view/hooks/use-errors-content';
import './road-block-view.scss';

export const RoadBlockPage = () => {
    const errorsContent = useErrorsContent();
    let roadblockDescriptionBefore;
    let roadblockDescriptionAfter;
    let roadblockCTAText;
    const transformDescription = () => {
        if (errorsContent?.roadblockDescription) {
            const splitDescription = errorsContent.roadblockDescription.split(
                '%*',
                3
            );
            roadblockDescriptionBefore = splitDescription[0];
            roadblockCTAText = splitDescription[1];
            roadblockDescriptionAfter = splitDescription[2];
        }
    };
    transformDescription();
    return (
        <div className="roadblock-container">
            <div className="content">
                <span className="content-title">
                    {errorsContent?.roadblockTitle}
                </span>
                <span className="content-subtitle">
                    {roadblockDescriptionBefore}
                    <a href={errorsContent?.supportCTA}>{roadblockCTAText}</a>
                    {roadblockDescriptionAfter}
                </span>
                <GoBackButton goBackLabel={'Back'} />
            </div>
        </div>
    );
};
export default RoadBlockPage;
