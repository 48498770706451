import React from 'react';
import { Link, PrimaryButton } from '../../../../common';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { VideoQuietCard } from '../../../../../models/experiencefragments/category-view';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import { FdsVideoContent } from './fds-video-content/fds-video-content';

import './category-video-quiet-card.scss';

interface Props extends VideoQuietCard {
    class?: string;
}

export const CategoryVideoQuietCard = (props: Props) => {
    const [fireEvents] = useAnalytics();
    return (
        <div
            className={`video-quiet-card-main ${
                props.class ? props.class : ''
            }`}
        >
            <div className="video-quiet-card-content">
                <h2 className="video-quiet-card-title">
                    {props.videoQuietCardDetails?.title}
                </h2>
                <div>
                    <p className="video-quiet-card-description">
                        {props.videoQuietCardDetails?.description}
                    </p>
                </div>
                {props.videoQuietCardDetails?.disclaimer && (
                    <div className="video-quiet-card-disclaimer">
                        {props.videoQuietCardDetails.disclaimer}
                    </div>
                )}
                <div className="mobile-app-cta">
                    {props.videoQuietCardDetails?.appleCta && (
                        <Link
                            href={props.videoQuietCardDetails.appleCta}
                            target="_blank"
                            aria-label={
                                props.videoQuietCardDetails.appleAriaLabel
                            }
                            className="apple-app-cta"
                        >
                            <img
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    props.videoQuietCardDetails.appleIconPath
                                }
                                alt={props.videoQuietCardDetails.appleAlt}
                            />
                        </Link>
                    )}
                    {props.videoQuietCardDetails?.googleCta && (
                        <Link
                            href={props.videoQuietCardDetails.googleCta}
                            target="_blank"
                            aria-label={
                                props.videoQuietCardDetails.googleAriaLabel
                            }
                        >
                            <img
                                className="google-app-cta"
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    props.videoQuietCardDetails.googleIconPath
                                }
                                alt={props.videoQuietCardDetails.googleAlt}
                            />
                        </Link>
                    )}
                </div>
                {props.videoQuietCardDetails?.showButtonCTA && (
                    <PrimaryButton
                        fill="fill"
                        color="dark"
                        chevron={true}
                        className="cta-optional__primary-button"
                        href={props.videoQuietCardDetails.buttonCta}
                        onClick={() =>
                            props.videoQuietCardDetails.buttonCta &&
                            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                props.videoQuietCardDetails.buttonCta,
                                fireEvents
                            )
                        }
                        target={
                            props.videoQuietCardDetails.buttonCtaTarget
                                ? props.videoQuietCardDetails.buttonCtaTarget
                                : '_blank'
                        }
                        ariaLabel={props.videoQuietCardDetails.buttonAriaLabel}
                    >
                        {props.videoQuietCardDetails.buttonTitle}
                    </PrimaryButton>
                )}
            </div>
            <div className="image-or-video">
                {props.videoQuietCardDetails?.imageOrVideo === 'Video' &&
                    props.videoQuietCardDetails.videoId &&
                    props.videoQuietCardDetails.videoAccountId &&
                    props.videoQuietCardDetails.videoDataPlayer && (
                        <FdsVideoContent
                            videoId={props.videoQuietCardDetails.videoId}
                            videoAccountId={
                                props.videoQuietCardDetails.videoAccountId
                            }
                            videoDataPlayer={
                                props.videoQuietCardDetails.videoDataPlayer
                            }
                        />
                    )}
                {props.videoQuietCardDetails?.imageOrVideo === 'Image' &&
                    props.videoQuietCardDetails.image && (
                        <img
                            src={
                                process.env.REACT_APP_AEM_BASE_URL +
                                props.videoQuietCardDetails.image
                            }
                            alt={props.videoQuietCardDetails.imageAltText}
                        />
                    )}
            </div>
        </div>
    );
};
