import React from 'react';

interface Props {
    largeDesktopImagePath: string;
    desktopImagePath: string;
    tabletImagePath: string;
    mobileImagePath: string;
    imageAltText: string;
    testId?: string;
}

export const Picture = (props: Props) => {
    return (
        <picture>
            <source
                srcSet={
                    process.env.REACT_APP_AEM_BASE_URL +
                    props.largeDesktopImagePath
                }
                media="(min-width: 1200px)"
            />
            <source
                srcSet={
                    process.env.REACT_APP_AEM_BASE_URL + props.desktopImagePath
                }
                media="(min-width: 993px) and (max-width:1199px )"
            />
            <source
                srcSet={
                    process.env.REACT_APP_AEM_BASE_URL + props.tabletImagePath
                }
                media="(min-width: 768px) and (max-width:992px )"
            />
            <img
                data-testid={props.testId}
                alt={props.imageAltText}
                src={process.env.REACT_APP_AEM_BASE_URL + props.mobileImagePath}
            />
        </picture>
    );
};
