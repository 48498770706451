import React, { useState } from 'react';
import { useAnalytics } from '../../../hooks/use-analytics';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { PlusMinus } from '../../../support/components/plus-minus/plus-minus';
import { FdsChevron } from '../chevron/fds-chevron';

import './simple-accordion.scss';

interface Props {
    id: string;
    className?: string;
    headerName?: string;
    header: React.ReactNode;
    children: React.ReactNode;
    startOpen?: boolean;
    accordionToggleEvent?: string;
    cta?: string;
    category?: string;
}
export const SimpleAccordion = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const [open, setOpen] = useState(props.startOpen || false);
    const toggleActive = (toggle: boolean) => {
        fireEvents(
            props.accordionToggleEvent
                ? props.accordionToggleEvent
                : 'notifications-section-onclick-event',
            undefined,
            {
                notificationCategory: props.category,
                notificationCtaName: props.cta,
            }
        );
        setOpen(toggle);
    };
    const { brand } = new AppConfigurationService();
    return (
        <div id={props.id} className={['accordion', props.className].join(' ')}>
            <button
                aria-label={`${props.headerName} Click to open or close`}
                aria-expanded={open}
                className={'accordion-title'}
                onClick={() => toggleActive(!open)}
            >
                {props.header}
                {brand.toLowerCase() === 'lincoln' ? (
                    <PlusMinus isOpen={open} />
                ) : (
                    <FdsChevron
                        direction={open ? 'up' : 'down'}
                        type="unfilled"
                    />
                )}
            </button>
            {open && <div aria-labelledby={props.id}>{props.children}</div>}
            <div className="accordion-divider" />
        </div>
    );
};
