import React from 'react';

import './fds-chevron.scss';

type Direction = 'up' | 'down' | 'left' | 'right';
type ChevronType = 'filled' | 'unfilled' | 'svg';

interface Props {
    direction: Direction;
    type: ChevronType;
    className?: string;
    chevronText?: string;
    focusClicked?: string;
    ariaLabelledBy?: string;
    enableAriaExpandedAttribute?: boolean;
}

export const FdsChevron = (props: Props) => {
    return (
        <div className="fds-chevron-wrap" aria-hidden={false}>
            {props.chevronText && (
                <span className="fds-chevron-text">{props.chevronText}</span>
            )}
            <div
                className={`${props.className || ''} fds-chevron ${
                    props.direction
                }`}
            >
                {props.type === 'filled' && <span className="fds-dot"></span>}
                {props.focusClicked === 'focusTab' && (
                    <span
                        className={`fds-arrow ${props.type}`}
                        tabIndex={0}
                        aria-label={
                            props.ariaLabelledBy && props.ariaLabelledBy !== ''
                                ? props.ariaLabelledBy
                                : 'chevron Clicked'
                        }
                        aria-labelledby={props.ariaLabelledBy}
                        aria-expanded={
                            props.enableAriaExpandedAttribute
                                ? props.direction === 'up'
                                : undefined
                        }
                        role="button"
                    ></span>
                )}
                {props.focusClicked !== 'focusTab' && (
                    <span className={`fds-arrow ${props.type}`}></span>
                )}
            </div>
        </div>
    );
};
