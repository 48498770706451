import { useContent } from '../../../../hooks/use-server-content';

export interface LoginOrRegisterContentProps {
    loginOrRegisterFormHeading: string;
    loginOrRegisterFormDescription: string;
    signinButtonLabel: string;
    registerButtonLabel: string;
}
export const useLoginOrRegisterContent = (): LoginOrRegisterContentProps => {
    const [content, getValueByName] = useContent('common', 'login-register');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as LoginOrRegisterContentProps;
};
