import React, { ReactNode } from 'react';
import { Question } from '../../../../../models/experiencefragments/category-view';
import { Accordion, FMAccordion } from '@own/fds-react';
import './faqs-accordion.scss';
import { PageType } from '../../../view-template/page-type';
import CategoryViewUtil from '../../../../../views/category-view/category-view-util';

export interface Props {
    title: ReactNode;
    subtitle: ReactNode;
    questions: Question[];
    onPanelClickEvent?: (panelTitle: string) => void;
    handlePanelClick?: (panelTitle: string) => void;
    pageType?: PageType;
}

export const FaqsAccordion = (props: Props) => {
    const currentVersion = CategoryViewUtil.currentVersion;
    const isCatOrSubCatPage = !['Subcategory', 'Category'].includes(
        props?.pageType ?? ''
    );

    return (
        <>
            {isCatOrSubCatPage || currentVersion ? (
                <article className="faqs-accordion-container">
                    {props.title && (
                        <h2 className="faqs-accordion-title">{props.title}</h2>
                    )}
                    {props.subtitle && (
                        <h3 className="faqs-accordion-subtitle">
                            {props.subtitle}
                        </h3>
                    )}
                    <section className="faqs-accordion-wrapper">
                        <Accordion
                            theme="light-on-light"
                            panels={props.questions.map((faq, index) => {
                                return {
                                    index: index,
                                    header: faq.question,
                                    body: faq.text,
                                };
                            })}
                            onPanelClick={(
                                panelTitle: string,
                                isOpen: boolean
                            ) => {
                                if (
                                    isOpen &&
                                    props.onPanelClickEvent &&
                                    panelTitle
                                ) {
                                    props.onPanelClickEvent(
                                        panelTitle
                                            .toLowerCase()
                                            .replace(/[^a-zA-Z ]/g, '')
                                    );
                                }
                            }}
                        />
                    </section>
                </article>
            ) : (
                <article className="preimagine-faqs-accordion-container">
                    <FMAccordion
                        title={props?.title}
                        panels={props.questions.map(faq => {
                            return {
                                title: faq.question,
                                panel: faq.text,
                            };
                        })}
                        handleClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                            const panelTitle = e.currentTarget?.querySelector(
                                '.fm-accordion-panel__header-title'
                            );
                            const title = panelTitle?.textContent ?? '';
                            const panelDetails = document.querySelector(
                                '.fm-accordion-panel__details'
                            );
                            const isOpen = panelDetails
                                ? panelDetails.hasAttribute('open')
                                : false;
                            if (
                                isOpen &&
                                props?.handlePanelClick &&
                                panelTitle
                            ) {
                                props?.handlePanelClick(
                                    title
                                        .toLowerCase()
                                        .replace(/[^a-zA-Z ]/g, '')
                                );
                            }
                        }}
                    />
                </article>
            )}
        </>
    );
};
