import { AemArticles } from './category-view';

export interface TrailerChecklistPage {
    trailerChecklist: TrailerChecklist;
    serviceOffersSection: AemArticles;
}

export interface TrailerChecklistLandingProps {
    trailerChecklistHeadline: string;
    trailerChecklistDescription: string;
    conventionalTrailerCTALabel: string;
    fifthWheelTrailerCTALabel: string;
    gooseneckTrailerCTALabel: string;
}

export interface TrailerChecklist {
    landing: TrailerChecklistLandingProps;
    conventionalTrailer: TrailerChecklistDetail;
    gooseneckTrailer: TrailerChecklistDetail;
    fifthWheelTrailer: TrailerChecklistDetail;
}

export interface TrailerChecklistDetail {
    pageTitle: string;
    description: string;
    accordionMarkCompleteCTA: string;
    accordionList: AccordionContent[];
    lightCheckAccordionTitle: string;
    lightCheckDescription: string;
    lightCheckSubDescription: string;
    lightCheckAccordionImagePath: string;
    resetCTA: string;
}

export interface AccordionContent {
    accordionItemTitle: string;
    imagePath: string;
}

export enum TrailerType {
    CONVENTIONAL = 'TrailerChecklistConventionalTrailer',
    GOOSENECK = 'TrailerChecklistGooseneckTrailer',
    FIFTHWHEEL = 'TrailerChecklistFifthWheelTrailer',
}
