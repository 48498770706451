import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Chevron, Link } from '../../../common';
import { SubnavMobile } from '../subnav/subnav';
import {
    Category,
    HeaderFragment,
} from '../../../../models/experiencefragments/header';
import { LanguageToggle } from '../../language-toggle/language-toggle';
import './sidebar-menu.scss';
import { SearchInputGroup } from '../../search/search-input-group/search-input-group';
import { useExperienceContent } from '../../../../hooks/use-server-content';

interface SidebarMenuProps {
    isOpen: boolean;
    className: string;
    toggleMenu: React.MouseEventHandler<HTMLElement>;
}

const SidebarMenu = (props: SidebarMenuProps) => {
    const [experienceCont] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );

    const [activeSubNavCategory, setActiveSubNavCategory] = useState<
        Category[]
    >([]);
    const [activeSubnav, setActiveSubnav] = useState('');
    const animationProps = useSpring({
        left: props.isOpen ? '0%' : '-100%',
    });

    const isTabbable = () => (props.isOpen ? 0 : -1);
    const hideElementClass: string = activeSubnav ? 'hide-me' : '';

    const openSubnav = (newSubnav: string, drawersContent: Category[]) => {
        return <SubnavMobile title={newSubnav} drawers={drawersContent} />;
    };
    const generateArrayMapKey = (title: string, index: number) =>
        `${title}-${index}`;
    function chooseClassForNavMenuButton(navToCheckAgainst: string) {
        if (activeSubnav === '') {
            return '';
        }

        return activeSubnav === navToCheckAgainst
            ? 'selected-sidebar-menu-item'
            : 'unselected-sidebar-menu-item';
    }

    return (
        <>
            {experienceCont && (
                <animated.div
                    id="sidebar-menu"
                    className={`sidebar-container ${props.className} ${
                        activeSubnav ? 'grey-border' : ''
                    }`}
                    data-testid="sidebar-menu"
                    style={animationProps}
                >
                    <div
                        className={`sidebar-menu-container  ${
                            activeSubnav ? 'sidebar-minimized' : ''
                        }`}
                    >
                        <ul tabIndex={props.isOpen ? 0 : -1}>
                            {!activeSubnav &&
                                experienceCont['main-nav-right'].search &&
                                experienceCont['main-nav-right'].search.url && (
                                    <li className="blue-search-bar">
                                        <SearchInputGroup
                                            content={
                                                experienceCont['main-nav-right']
                                                    .search
                                            }
                                        />
                                    </li>
                                )}
                            {activeSubnav && (
                                <li
                                    className={chooseClassForNavMenuButton(
                                        'backChevron'
                                    )}
                                >
                                    <button
                                        onClick={() => setActiveSubnav('')}
                                        className="sidebar-menu-item"
                                        tabIndex={isTabbable()}
                                        aria-label="back"
                                    >
                                        <Chevron direction="left" />
                                    </button>
                                </li>
                            )}
                            {experienceCont['main-nav-left'].map(
                                (navsub, index) => {
                                    if (!navsub.categories && navsub.noFlyout) {
                                        return (
                                            <li
                                                key={generateArrayMapKey(
                                                    navsub.title,
                                                    index
                                                )}
                                                className={chooseClassForNavMenuButton(
                                                    navsub.title
                                                )}
                                            >
                                                <nav
                                                    aria-label={navsub.title}
                                                    key={generateArrayMapKey(
                                                        navsub.title,
                                                        index
                                                    )}
                                                >
                                                    <Link
                                                        href={navsub.url}
                                                        target={
                                                            navsub.targetBlank
                                                                ? '_blank'
                                                                : '_self'
                                                        }
                                                        className="sidebar-menu-item"
                                                        aria-label={
                                                            navsub.ariaLabel
                                                        }
                                                    >
                                                        {navsub.iconPath && (
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_AEM_BASE_URL +
                                                                    navsub.iconPath
                                                                }
                                                                alt={
                                                                    navsub.ariaLabel
                                                                }
                                                                className="sidebar-menu-item-icon"
                                                            />
                                                        )}
                                                        <span
                                                            className={`sidebar-menu-item-text ${hideElementClass}`}
                                                        >
                                                            {navsub.title}
                                                        </span>
                                                        <Chevron
                                                            className={
                                                                hideElementClass
                                                            }
                                                            direction="right"
                                                        />
                                                    </Link>
                                                </nav>
                                            </li>
                                        );
                                    } else if (
                                        navsub.categories &&
                                        !navsub.noFlyout
                                    ) {
                                        return (
                                            <li
                                                key={generateArrayMapKey(
                                                    navsub.title,
                                                    index
                                                )}
                                                className={chooseClassForNavMenuButton(
                                                    navsub.title
                                                )}
                                            >
                                                <nav aria-label={navsub.title}>
                                                    <button
                                                        onClick={() => {
                                                            setActiveSubnav(
                                                                navsub.title
                                                            );
                                                            setActiveSubNavCategory(
                                                                navsub.categories
                                                            );
                                                        }}
                                                        className="sidebar-menu-item"
                                                        aria-label={
                                                            navsub.ariaLabel
                                                        }
                                                    >
                                                        {navsub.iconPath && (
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_AEM_BASE_URL +
                                                                    navsub.iconPath
                                                                }
                                                                alt={
                                                                    navsub.ariaLabel
                                                                }
                                                                className="sidebar-menu-item-icon"
                                                            />
                                                        )}
                                                        <span
                                                            className={`sidebar-menu-item-text ${hideElementClass}`}
                                                        >
                                                            {navsub.title}
                                                        </span>
                                                        <Chevron
                                                            className={
                                                                hideElementClass
                                                            }
                                                            direction="right"
                                                        />
                                                    </button>
                                                </nav>
                                            </li>
                                        );
                                    }
                                }
                            )}
                            {experienceCont['main-nav-right'].utilityLinks !==
                                null &&
                                experienceCont[
                                    'main-nav-right'
                                ].utilityLinks.map((nav, index) => {
                                    return (
                                        <li
                                            key={generateArrayMapKey(
                                                nav.title,
                                                index
                                            )}
                                            className={chooseClassForNavMenuButton(
                                                nav.title
                                            )}
                                        >
                                            <Link
                                                href={nav.url}
                                                target={
                                                    nav.targetBlank
                                                        ? '_blank'
                                                        : '_self'
                                                }
                                                aria-label="Dealership Locations"
                                                className="sidebar-menu-item"
                                            >
                                                {nav.icon && (
                                                    <img
                                                        src={
                                                            process.env
                                                                .REACT_APP_AEM_BASE_URL +
                                                            nav.icon
                                                        }
                                                        alt={nav.ariaLabel}
                                                        className="sidebar-menu-item-icon"
                                                    ></img>
                                                )}
                                                <span
                                                    className={`sidebar-menu-item-text ${hideElementClass}`}
                                                >
                                                    {nav.title}
                                                </span>
                                                <Chevron
                                                    className={hideElementClass}
                                                    direction="right"
                                                />
                                            </Link>
                                        </li>
                                    );
                                })}
                            {experienceCont['main-nav-right'].language
                                .languageItems && (
                                <li
                                    className={`${
                                        activeSubnav ? 'hide-me' : ''
                                    }`}
                                >
                                    <div className="mobile-language-container">
                                        <LanguageToggle tabIndex={0} />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>

                    {openSubnav(activeSubnav, activeSubNavCategory)}
                </animated.div>
            )}
        </>
    );
};

export default SidebarMenu;
