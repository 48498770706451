import { useEffect, useContext } from 'react';
import { ViewportContext } from '../../contexts/owners-osb';
import { useOSBStep } from './use-osb-step';

export const useViewport = () => {
    const viewportContext = useContext(ViewportContext);
    const { osbStep, setOSBStepPayload } = useOSBStep();

    useEffect(() => {
        if (viewportContext.viewportWidth <= 768) {
            setOSBStepPayload({ isMobileView: true });
        } else {
            setOSBStepPayload({ isMobileView: false });
        }
    }, [viewportContext.viewportWidth]);

    return {
        isMobileView: osbStep.isMobileView,
    };
};
