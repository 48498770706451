import React, { ReactElement, ReactNode } from 'react';
import Tab from './tab';
import './tabs.scss';

interface Props {
    children: ReactElement<typeof Tab>[] | ReactElement<typeof Tab>;
    onChange?: (title: string, index: number) => void;
    activeTabIndex: number;
    activeTabTitle?: string;
    className?: string;
    secondaryChild?: ReactNode;
}

const Tabs = (props: Props) => {
    const handleTabClick = (tabIndex: number, tabTitle: string) => {
        if (props.onChange) {
            props.onChange(tabTitle, tabIndex);
        }
    };

    const renderChildrenWithTabsAsProps = () => {
        return React.Children.map(
            props.children,
            (child: React.ReactElement<any>, index) => {
                return React.cloneElement(child, {
                    onClick: handleTabClick,
                    tabIndex: index,
                    isActive: index === props.activeTabIndex,
                    children: child.props.children,
                    className: props.className ? props.className : ' default',
                });
            }
        );
    };

    return (
        <div className="tabs">
            <div className="tabs-nav" role="tablist">
                {renderChildrenWithTabsAsProps()}
                {props.secondaryChild}
            </div>
        </div>
    );
};

export default Tabs;
