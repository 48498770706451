import React, { useEffect, useReducer, useState } from 'react';
import { PrimaryButton } from '../../../../../common';
import parse from 'html-react-parser';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import './light-dealer-details-lincoln.scss';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { FdsChevron } from '../../../../../common/chevron/fds-chevron';
import { OsbMap } from '../osb-map';
import { LightDealerSearch } from '../light-dealer-search/light-dealer-search';
import { DealerMoreInfo } from '../light-dealer-more-info/dealer-more-info';
import { LightDealerFilterOptions } from '../light-dealer-filter-options/light-dealer-filter-options';
import {
    DEALER_STEP_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    STEP_PROGRESS_BAR_KEYS,
} from '../../../osb-constant';
import {
    getInitialDealerState,
    useCalendarStep,
    useDealerStep,
    useLightDeliveryOptionServiceStep,
    useOSBStep,
    useServiceStep,
    useStepProgressBar,
    useVehicleStep,
    useViewport,
    useServiceLocationStep,
} from '../../../../../../hooks/owners-osb';
import { dealerStepReducer } from '../../../../../../reducers/osb-reducers';
import { DealerStep } from '../../../../../../models/osb-model/osb-dealer-step';
import {
    getObjectFromAEMJson,
    getVehicleAttributesForAnalytics,
    buildNavigationUrl,
    hasMobileService,
    getGoMainHeaderHeight,
} from '../../../osb-utils';
import { OSBSecondaryButton } from '../../../common/osb-secondary-button/osb-secondary-button';
import OsbUtilService from '../../../../../../services/osb-service/osb-util-service/osb-util-service';
import { OSBPayload } from '../../../../../../services/shortcode-service/shortcode-service';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { OsbPathServicesStep } from '../../../osb-controller';
import filterIcon from '../../../../../../assets/owners-osb/dealer-filter.png';
import { OSBUseLocation } from '../../../common/osb-use-location/osb-use-location';
import { useHistory } from 'react-router-dom';
import { OsbLoader } from '../../../../../common/osb-loader/osb-loader';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import { FormatAddress } from '../../../common/osb-common-components/osb-common-components';
import { triggerBookServiceGlobalCTAOnClickAnalytics } from '../../../analytics/osb-analytics';
import tickMark from '../../../../../../assets/owners-osb/success_tick_mark.svg';
import closeMark from '../../../../../../assets/owners-osb/red-close-mark.svg';

interface Props {
    errorMsgDealer: string;
    errorMsgDealerDetails: string;
    fetchSelectedLocation: (location: string, serachType?: string) => void;
    selectedLocation: string;
    selectedDealerName?: string;
    locationErrorMsgDetails: string;
    fetchChosenLocation: (input: string) => void;
    showCurrentLocation: () => void;
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    dealerStepSpecialServicesContent: JsonFromAEMResponse[];
    dealerProfiles: DealerProfileInfo[];
    isViewMoreButtonVisible: boolean;
    showMaxDealers: () => void;
    goToLightDealerSearch: () => void;
    searchResultsCount: string;
    loading?: boolean;
    autocompleteSearchStringLength?: number;
    refreshDealerDetailsList?: (dealerProfiles: DealerProfileInfo[]) => void;
    isDealerFilterOptionsEnabled: boolean;
    clearErrorMessage?: () => void;
    fetchErrorCode?: (errorCode: string) => void;
    progressPercentToDisplay?: string;
    isUseLocationClicked: boolean;
    triggerSearchTypeChanged: () => void;
}

export const DealerDetailsLightJourney = (props: Props) => {
    const {
        updateProgressBarStatus,
        resetStepProgressBar,
    } = useStepProgressBar();
    const [fireEvents] = useAnalytics();
    const { isMobileView } = useViewport();
    const [defaultFilter, setDefaultFilter] = useState<boolean>(true);
    const [dealers] = useState<DealerProfileInfo[]>(props.dealerProfiles);
    const [isDealerMoreInfoView, setIsDealerMoreInfoView] = useState<boolean>(
        false
    );
    const [isDealerIndexValue, setIsDealerIndexValue] = useState<number>(0);
    const [moreInfoDealerProfile, setMoreInfoDealerProfile] = useState<
        DealerProfileInfo
    >(props.dealerProfiles[0]);
    const history = useHistory();
    const { resetServiceLocationStep } = useServiceLocationStep();
    const { resetCalendarStep } = useCalendarStep();
    const { setOSBStepPayload } = useOSBStep();
    const {
        osbServiceStep,
        resetServiceStep,
        setOSBServiceStepPayload,
    } = useServiceStep();

    const { osbVehicleStep } = useVehicleStep();
    const { osbStep, invalidateAuthentication } = useOSBStep();
    const {
        resetLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const { osbDealerStep, setOSBDealerStep } = useDealerStep();
    const [dealerStep, setDealerStep] = useReducer(
        dealerStepReducer,
        getInitialDealerState()
    );
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);
    const [
        enableDealerFilterForMarket,
        setEnableDealerFilterForMarket,
    ] = useState<boolean>(false);
    const triggerDealerSearchResultsInitiateFilterAnalytics = () => {
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_BOOKING_DEALER_SEARCH_RESULTS_INITIATE_FILTERS,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
            {
                ymm: Promise.resolve(
                    getVehicleAttributesForAnalytics(osbVehicleStep)
                ),
            },
            false
        );
    };

    const toggleLightDealerFilterOptions = () => {
        triggerDealerSearchResultsInitiateFilterAnalytics();
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };
    const [top, setTop] = useState<number>(0);
    const setComponentPayload = (
        payload:
            | {
                  [key: string]:
                      | string
                      | DealerProfileInfo
                      | boolean
                      | number
                      | []
                      | {};
              }
            | DealerStep
    ): void => {
        setDealerStep({ type: 'SET', payload: payload });
    };

    const enableDealerFiltersForMarket = () => {
        if (osbDealerStep.searchType === DEALER_STEP_KEYS.LOCATION) {
            const dealerFilterDIDServicesMapping =
                osbDealerStep.filterDidServices;
            const dealerFilterDeliveryServices =
                osbDealerStep.filterDeliveryServices;
            const dealerFilterRegularServices =
                osbDealerStep.filterRegularServices;
            if (
                dealerFilterDIDServicesMapping ||
                dealerFilterDeliveryServices ||
                dealerFilterRegularServices
            ) {
                setEnableDealerFilterForMarket(true);
            }
        } else {
            setEnableDealerFilterForMarket(false);
        }
    };

    const dealerMoreInfoDetail = (
        dealerProfile: DealerProfileInfo,
        index: number
    ) => {
        triggerBookServiceGlobalCTAOnClickAnalytics(
            LIGHT_JOURNEY_ANALYTICS.MORE_INFO_BTN_CTA_NAME,
            fireEvents,
            osbVehicleStep
        );
        setIsDealerMoreInfoView(true);
        setMoreInfoDealerProfile(dealerProfile);
        setIsDealerIndexValue(index);
        if (serverSideService.isClientSide()) {
            window.scrollTo(getGoMainHeaderHeight() - 2, 0);
        }
    };
    const triggerSelectDealerCtaAnalytics = () => {
        const osbSelectDealer: OSBPayload = {
            dealerID: dealerStep.selectedDealerProfile.dealerCode,
            searchDepth: dealerStep.searchDepth,
        };
        fireEvents(
            LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DEALER_CTA,
            LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
            {
                ymm: Promise.resolve(
                    getVehicleAttributesForAnalytics(osbVehicleStep)
                ),
                osbPayload: osbSelectDealer,
            },
            false
        );
    };

    const goToLightDealerDetail = () => {
        invalidateAuthentication();
        setIsDealerMoreInfoView(false);
    };
    const goToServicesStep = async (
        dealerProfile: DealerProfileInfo,
        index: number
    ) => {
        if (
            osbDealerStep?.selectedDealerProfile?.dealerCode &&
            osbDealerStep?.selectedDealerProfile.dealerCode !==
                dealerProfile.dealerCode
        ) {
            // Kept this code as discussed with AGADLE
            // to keep deeplinked voucher code in store.
            if (osbStep.isVoucherDeepLinked) {
                const tempVoucher = osbServiceStep.voucherCode;
                resetServiceStep();
                setOSBServiceStepPayload({ voucherCode: tempVoucher });
            } else {
                resetServiceStep();
            }
            resetLightDeliveryServiceStep();
            resetServiceLocationStep();
            resetCalendarStep();
            resetStepProgressBar();
        }

        if (isMobileView && serverSideService.isClientSide()) {
            window.scrollTo(getGoMainHeaderHeight() - 2, 0);
        }

        dealerStep.selectedDealerProfile = dealerProfile;
        dealerStep.hasMobileService = hasMobileService(
            dealerProfile.delivery || []
        );
        dealerStep.selectedLocation =
            props.selectedLocation && props.selectedLocation.trim().length > 0
                ? props.selectedLocation
                : '';
        dealerStep.selectedDealerName =
            dealerProfile.dealerName &&
            dealerProfile.dealerName.trim().length > 0
                ? dealerProfile.dealerName
                : '';
        dealerStep.searchDepth = osbStep.isDealerDeepLinked
            ? DEALER_STEP_KEYS.SEARCH_DEPTH_FOR_DEALER_DEEPLINK
            : String(index + 1) + ':' + props.searchResultsCount;
        // updating current dealer profiles to store
        dealerStep.dealerProfiles = props.dealerProfiles;
        dealerStep.filteredDealerProfiles = props.dealerProfiles;
        dealerStep.searchType = osbDealerStep.searchType;
        dealerStep.osbEnabledDealerFromBing =
            osbDealerStep.osbEnabledDealerFromBing;

        dealerStep.isDealerFilterOptionsEnabled =
            props.isDealerFilterOptionsEnabled;
        setOSBDealerStep(dealerStep, false);
        updateProgressBarStatus(STEP_PROGRESS_BAR_KEYS.DEALER, true);
        triggerSelectDealerCtaAnalytics();
        invalidateAuthentication();
        history.push(
            buildNavigationUrl(OsbPathServicesStep(), osbStep.UrlQueryParams)
        );
    };

    const dealerList = props.dealerProfiles.map((dealerProfile, index) => {
        return (
            <div
                key={dealerProfile.dealerCode}
                className="dealer-items-container"
                id={`dealerScrollView-${index}`}
            >
                <div className="sequence-number">{index + 1}</div>
                <div className="dealer-info">
                    <div className="dealer-name">
                        {dealerProfile.dealerName &&
                            dealerProfile.dealerName !== '' &&
                            dealerProfile.dealerName}
                    </div>
                    <div className="dealer-address">
                        <FormatAddress
                            addressData={dealerProfile}
                            addressOrder={osbStep.dealerAddressOrder}
                            isDisplaySingleRow={true}
                        />
                    </div>
                    {dealerProfile.distance > 0 && (
                        <div className="distance-miles">
                            <div className="distance-value">
                                {getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.DISTANCE_UNIT,
                                    props.dealerStepMoreContent
                                ).toLowerCase() === 'miles'
                                    ? OsbUtilService.getDistanceInMiles(
                                          Number(dealerProfile.distance)
                                      )
                                    : OsbUtilService.getDistanceInKm(
                                          Number(dealerProfile.distance)
                                      )}
                            </div>
                            <div className="miles-label">
                                {getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.LIGHT_JOURNEY_DISTANCE_LABEL,
                                    props.dealerStepMoreContent
                                )}
                            </div>
                        </div>
                    )}
                    <div className="dealer-vehicle-services">
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.PASSANGER_SERVICE_VEHICLE_LABEL,
                            props.dealerStepMoreContent
                        ) && (
                            <div className="service-name">
                                <img
                                    src={`${
                                        dealerProfile.serviceDepartmentPV
                                            ? tickMark
                                            : closeMark
                                    }`}
                                    className="icon"
                                    alt={''}
                                />
                                <p>
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.PASSANGER_SERVICE_VEHICLE_LABEL,
                                        props.dealerStepMoreContent
                                    )}
                                </p>
                            </div>
                        )}
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.COMMERCIAL_SERVICE_VEHICLE_LABEL,
                            props.dealerStepMoreContent
                        ) && (
                            <div className="service-name">
                                <img
                                    src={`${
                                        dealerProfile.serviceDepartmentCV
                                            ? tickMark
                                            : closeMark
                                    }`}
                                    className="icon"
                                    alt={''}
                                />
                                <p>
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.COMMERCIAL_SERVICE_VEHICLE_LABEL,
                                        props.dealerStepMoreContent
                                    )}
                                </p>
                            </div>
                        )}
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.ELECTRIC_SERVICE_VEHICLE_LABEL,
                            props.dealerStepMoreContent
                        ) && (
                            <div className="service-name">
                                <img
                                    src={`${
                                        dealerProfile.electricService
                                            ? tickMark
                                            : closeMark
                                    }`}
                                    className="icon"
                                    alt={''}
                                />
                                <p>
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.ELECTRIC_SERVICE_VEHICLE_LABEL,
                                        props.dealerStepMoreContent
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                    {dealerProfile.specialServices &&
                        dealerProfile.specialServices.length > 0 && (
                            <div className="special-service-provided">
                                <div className="special-service-provided-title">
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.SPECIAL_SERVICES_LIST_TITLE,
                                        props.dealerStepSpecialServicesContent
                                    )}
                                </div>
                                <ul className="service-provided-list">
                                    {dealerProfile.specialServices &&
                                        dealerProfile.specialServices.map(
                                            (item: string, key: any) => (
                                                <li
                                                    className="service-provided-item"
                                                    id={`item-${key}`}
                                                    key={item}
                                                >
                                                    {item}
                                                </li>
                                            )
                                        )}
                                </ul>
                            </div>
                        )}

                    {dealerProfile.additionalInfo
                        ?.earliestAvailableTimeslot && (
                        <div className="earliest-available-slot">
                            {getObjectFromAEMJson(
                                DEALER_STEP_KEYS.LIGHT_JOURNEY_EARLIEST_AVAILABLE_SLOT,
                                props.dealerStepMoreContent
                            )}{' '}
                            {
                                dealerProfile.additionalInfo
                                    ?.earliestAvailableTimeslot
                            }
                        </div>
                    )}
                </div>
                <div className="select-button-container">
                    <PrimaryButton
                        role="link"
                        color={'dark'}
                        fill={'fill'}
                        chevron={false}
                        aria-label="select-dealer-cta"
                        aria-labelledby="select dealer cta"
                        dataTestId={`SelectDealer-${dealerProfile.dealerCode}`}
                        onClick={() => goToServicesStep(dealerProfile, index)}
                    >
                        {getObjectFromAEMJson(
                            DEALER_STEP_KEYS.LIGHT_JOURNEY_SELECT_DEALER_BUTTON,
                            props.dealerStepMoreContent
                        )}
                    </PrimaryButton>
                    <div
                        className="more-info-button-section"
                        data-testid={`MoreInfo-${dealerProfile.dealerCode}`}
                    >
                        <OSBSecondaryButton
                            type="filled"
                            direction="right"
                            text={getObjectFromAEMJson(
                                DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_MOREINFO_BUTTON,
                                props.dealerStepMoreContent
                            )}
                            onClickHandler={() =>
                                dealerMoreInfoDetail(dealerProfile, index)
                            }
                        />
                    </div>
                </div>
                {props.dealerProfiles.length > 1 && (
                    <div className="hr-line-style"></div>
                )}
            </div>
        );
    });

    // Added this useeffect for mobile view to change to filter icon position depends on scroll bar.
    const listenToScroll = () => {
        const windowScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (windowScroll > 900) {
            setDefaultFilter(false);
        } else {
            setDefaultFilter(true);
        }
    };

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', listenToScroll);
            return () => window.removeEventListener('scroll', listenToScroll);
        }
    });

    useEffect(() => {
        setOSBStepPayload({ displayProgressBar: true });
        setComponentPayload(osbDealerStep);
    }, [osbDealerStep.selectedFilterServices]);

    useEffect(() => {
        // filter bar enabled for multiple dealer profiles
        enableDealerFiltersForMarket();
    }, [props.dealerProfiles]);

    useEffect(() => {
        const handleTop = () => {
            const progressBarTop =
                document.getElementById('step-progress-bar-container')
                    ?.clientHeight || 0;
            const bannerTop =
                document.getElementById('osbBannerId')?.clientHeight || 0;
            const browserTop =
                document.getElementById('osbBannerId')?.getBoundingClientRect()
                    .top || 0;
            setTop(browserTop + bannerTop + progressBarTop);
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleTop);
            window.addEventListener('resize', handleTop);
            return () => {
                window.removeEventListener('scroll', handleTop);
                window.removeEventListener('resize', handleTop);
            };
        }
    }, []);

    return (
        <>
            {!isDealerMoreInfoView && (
                <>
                    <div className="osb-light-dealer-search-section-wrapper">
                        {!isMobileView && (
                            <div className="row-display">
                                <div className="column-display">
                                    <div className="back-button-column">
                                        {(osbStep.isWebViewJourney ||
                                            !isMobileView) && (
                                            <OSBSecondaryButton
                                                dataTestId="BackToDealerHomePage"
                                                type="filled"
                                                direction="left"
                                                text={getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.BACK_BUTTON_LABEL,
                                                    props.dealerStepMoreContent
                                                )}
                                                onClickHandler={
                                                    props.goToLightDealerSearch
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="select-a-dealer-title-column">
                                        {parse(
                                            getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.SELECT_A_DEALER,
                                                props.dealerStepContent
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="column-display">
                                    <div className="dealer-search-column">
                                        <div className="dealer-search-section">
                                            <LightDealerSearch
                                                errorMsgDealer={
                                                    props.errorMsgDealer
                                                }
                                                errorMsgDealerDetails={
                                                    props.errorMsgDealerDetails
                                                }
                                                fetchSelectedLocation={
                                                    props.fetchSelectedLocation
                                                }
                                                selectedLocation={
                                                    props.selectedLocation
                                                }
                                                selectedDealerName={
                                                    props.selectedDealerName
                                                }
                                                locationErrorMsgDetails={
                                                    props.locationErrorMsgDetails
                                                }
                                                fetchChosenLocation={
                                                    props.fetchChosenLocation
                                                }
                                                showCurrentLocation={
                                                    props.showCurrentLocation
                                                }
                                                dealerStepContent={
                                                    props.dealerStepContent
                                                }
                                                dealerStepMoreContent={
                                                    props.dealerStepMoreContent
                                                }
                                                dealerCount={
                                                    props.dealerProfiles
                                                        ? props.dealerProfiles
                                                              .length
                                                        : 0
                                                }
                                                autocompleteSearchStringLength={
                                                    props.autocompleteSearchStringLength
                                                }
                                                clearErrorMessage={
                                                    props.clearErrorMessage
                                                }
                                                fetchErrorCode={
                                                    props.fetchErrorCode
                                                }
                                                hideTitle={true}
                                                isUseLocationLinkClicked={
                                                    props.isUseLocationClicked
                                                }
                                                triggerSearchTypeChanged={
                                                    props.triggerSearchTypeChanged
                                                }
                                                includeUseMyLocationLink={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isMobileView && (
                            <div className="search-a-dealer-mobile-container">
                                <div className="search-a-dealer-mobile-title">
                                    {parse(
                                        getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.SELECT_A_DEALER,
                                            props.dealerStepContent
                                        )
                                    )}
                                </div>
                                <div className="search-a-dealer-mobile">
                                    <LightDealerSearch
                                        errorMsgDealer={props.errorMsgDealer}
                                        errorMsgDealerDetails={
                                            props.errorMsgDealerDetails
                                        }
                                        fetchSelectedLocation={
                                            props.fetchSelectedLocation
                                        }
                                        selectedLocation={
                                            props.selectedLocation
                                        }
                                        selectedDealerName={
                                            props.selectedDealerName
                                        }
                                        locationErrorMsgDetails={
                                            props.locationErrorMsgDetails
                                        }
                                        fetchChosenLocation={
                                            props.fetchChosenLocation
                                        }
                                        showCurrentLocation={
                                            props.showCurrentLocation
                                        }
                                        dealerStepContent={
                                            props.dealerStepContent
                                        }
                                        dealerStepMoreContent={
                                            props.dealerStepMoreContent
                                        }
                                        dealerCount={
                                            props.dealerProfiles
                                                ? props.dealerProfiles.length
                                                : 0
                                        }
                                        autocompleteSearchStringLength={
                                            props.autocompleteSearchStringLength
                                        }
                                        clearErrorMessage={
                                            props.clearErrorMessage
                                        }
                                        fetchErrorCode={props.fetchErrorCode}
                                        hideTitle={true}
                                        isUseLocationLinkClicked={
                                            props.isUseLocationClicked
                                        }
                                        triggerSearchTypeChanged={
                                            props.triggerSearchTypeChanged
                                        }
                                    />
                                    <div className="search-a-dealer-mobile-use-location">
                                        <OSBUseLocation
                                            showCurrentLocation={
                                                props.showCurrentLocation
                                            }
                                            useLocationLabel={getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.USE_MY_LOCATION_LABEL,
                                                props.dealerStepMoreContent
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="light-journey-dealer-details-container">
                        <OsbMap dealers={props.dealerProfiles} />
                        {props.loading ? (
                            <OsbLoader
                                osbLoaderMessage={getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.DEALER_LOADER_MESSAGE,
                                    props.dealerStepMoreContent
                                )}
                                progressPercent={props.progressPercentToDisplay}
                            />
                        ) : (
                            <></>
                        )}
                        {!isMobileView &&
                            enableDealerFilterForMarket &&
                            props.isDealerFilterOptionsEnabled &&
                            !showDealerFilterOptions && (
                                <div className="dealer-filter-banner-container">
                                    <div className="select-a-dealer">
                                        <div
                                            className="filters"
                                            onClick={
                                                toggleLightDealerFilterOptions
                                            }
                                        >
                                            <img
                                                src={filterIcon}
                                                className="filter-icon"
                                                alt="dealer-filter-icon"
                                            />
                                            <div className="filter-label">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.DEALER_FILTER_LABEL,
                                                    props.dealerStepMoreContent
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        <>
                            <div
                                className={`dealer-list-container ${
                                    !(
                                        enableDealerFilterForMarket &&
                                        props.isDealerFilterOptionsEnabled
                                    )
                                        ? 'dealer-list-container-without-filter'
                                        : ''
                                }`}
                            >
                                {defaultFilter &&
                                    isMobileView &&
                                    enableDealerFilterForMarket &&
                                    props.isDealerFilterOptionsEnabled && (
                                        <div
                                            className="filters"
                                            onClick={
                                                toggleLightDealerFilterOptions
                                            }
                                        >
                                            <img
                                                src={filterIcon}
                                                className="filter-icon"
                                                alt="dealer-filter-icon"
                                            />
                                            <div className="filter-label">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.DEALER_FILTER_LABEL,
                                                    props.dealerStepMoreContent
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {!defaultFilter &&
                                    isMobileView &&
                                    enableDealerFilterForMarket &&
                                    props.isDealerFilterOptionsEnabled && (
                                        <div
                                            className="top-sticky-filters"
                                            style={{ top }}
                                            onClick={
                                                toggleLightDealerFilterOptions
                                            }
                                        >
                                            <img
                                                src={filterIcon}
                                                className="filter-icon"
                                                alt="dealer-filter-icon"
                                            />
                                            <div className="filter-label">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.DEALER_FILTER_LABEL,
                                                    props.dealerStepMoreContent
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {isMobileView && showDealerFilterOptions && (
                                    <div className="dealer-filter-options-modal">
                                        <LightDealerFilterOptions
                                            toggleLightDealerFilterOptions={
                                                toggleLightDealerFilterOptions
                                            }
                                            dealerStepMoreContent={
                                                props.dealerStepMoreContent
                                            }
                                            refreshDealerDetailsList={
                                                props.refreshDealerDetailsList
                                            }
                                        />
                                    </div>
                                )}
                                {dealerList}
                                {props.isViewMoreButtonVisible && (
                                    <div className="lj-view-more-button-container">
                                        <div
                                            className="lj-view-more"
                                            onClick={props.showMaxDealers}
                                        >
                                            <div>
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_VIEWMORE_BUTTON,
                                                    props.dealerStepMoreContent
                                                )}
                                            </div>
                                            <FdsChevron
                                                direction={'down'}
                                                type="filled"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        {!isMobileView && showDealerFilterOptions && (
                            <div className="dealer-filter-options-container">
                                <LightDealerFilterOptions
                                    toggleLightDealerFilterOptions={
                                        toggleLightDealerFilterOptions
                                    }
                                    dealerStepMoreContent={
                                        props.dealerStepMoreContent
                                    }
                                    refreshDealerDetailsList={
                                        props.refreshDealerDetailsList
                                    }
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
            {isDealerMoreInfoView && (
                <div>
                    <DealerMoreInfo
                        dealerStepContent={props.dealerStepContent}
                        dealerStepMoreContent={props.dealerStepMoreContent}
                        dealerProfile={moreInfoDealerProfile}
                        dealers={dealers}
                        selectedLocation={props.selectedLocation}
                        goToLightDealerDetail={goToLightDealerDetail}
                        setIsDealerIndexValue={isDealerIndexValue}
                        goToServicesStep={goToServicesStep}
                        dealerStepSpecialServicesContent={
                            props.dealerStepSpecialServicesContent
                        }
                        searchResultsCount={props.searchResultsCount}
                    />
                </div>
            )}
        </>
    );
};
