import { CardState } from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';
import ServiceHandler from '../../../../../../services/service-handler';
import { replacePlaceholderByValue } from '../../../../../utils/placeholder-util/replace-placeholder-by-value';

interface DiagnosticAlert {
    categoryId: number;
    severity: number;
    headline: string;
    whatIsHappening: string;
    whatShouldIdo: string;
}
export interface DiagnosticAlerts {
    categoryId: number;
    severities: number[];
    alertCount: number;
    warningCount: number;
    diagnosticsDetails: DiagnosticDetails[];
}
export interface DiagnosticDetails {
    headline: string;
    severity: number;
    whatIsHappening: string;
    whatShouldIdo: string;
}
export const getDiagnosticAlertsData = (selectedVin: any) => {
    return ServiceHandler.VhaService.request(selectedVin)
        .then(response => {
            if (response) {
                return response;
            }
        })
        .catch(error => {
            console.error(error);
        });
};

export const getDiagnosticAlertsList = (response: any) => {
    // diagnosticAlertsData will hold severities merged grouped by categoryId - Final data
    const diagnosticAlertsData: DiagnosticAlerts[] = [
        {
            categoryId: 0,
            severities: [],
            alertCount: 0,
            warningCount: 0,
            diagnosticsDetails: [],
        },
        {
            categoryId: 1,
            severities: [],
            alertCount: 0,
            warningCount: 0,
            diagnosticsDetails: [],
        },
        {
            categoryId: 2,
            severities: [],
            alertCount: 0,
            warningCount: 0,
            diagnosticsDetails: [],
        },
        {
            categoryId: 3,
            severities: [],
            alertCount: 0,
            warningCount: 0,
            diagnosticsDetails: [],
        },
    ];

    // diagnosticAlertList is a list of categoryId and its corresponsing severity
    const diagnosticAlertList: DiagnosticAlert[] = [];
    response.vhaAlertResponseList[0]?.activeAlerts?.forEach((item: any) => {
        const category: DiagnosticAlert = {
            categoryId: item.metadata?.categoryId,
            severity: item.metadata?.severity,
            headline: item.headline,
            whatIsHappening: item.body.whatIsHappening,
            whatShouldIdo: item.body.whatShouldIdo,
        };
        diagnosticAlertList.push(category);
    });

    // Logic for merging severity group by categoryId
    diagnosticAlertsData.forEach(item => {
        diagnosticAlertList.forEach(alert => {
            if (item.categoryId === alert.categoryId) {
                item.severities.push(alert.severity);
                item.diagnosticsDetails.push({
                    headline: alert.headline,
                    severity: alert.severity,
                    whatIsHappening: alert.whatIsHappening,
                    whatShouldIdo: alert.whatShouldIdo,
                });
                //get warning and alert count
                if (alert.severity === 2 || alert.severity === 3) {
                    item.alertCount += 1;
                } else if (alert.severity === 1) {
                    item.warningCount += 1;
                }
            }
        });
    });

    return diagnosticAlertsData;
};
const getCardStateBySeverity = (severities: number[]) => {
    if (severities.indexOf(2) > -1 || severities.indexOf(3) > -1) {
        return CardState.ALERT;
    } else if (severities.indexOf(1) > -1) {
        return CardState.WARNING;
    } else {
        return CardState.NORMAL;
    }
};
export const getDiagnosticsHeadlineColor = (severity: number) => {
    if (severity >= 2) {
        return 'alert-color';
    } else if (severity == 1) {
        return 'warning-color';
    }
};
export const getDiagnosticsStatusColor = (alertState: any) => {
    if (alertState === 'WARNING') {
        return 'warning-color';
    } else if (alertState === 'ALERT') {
        return 'alert-color';
    }
};
export const getDiagnosticsDescription = (
    alertState: any,
    diagnosticsDetails: any,
    diagnosticAPIDetails: any
) => {
    if (alertState === 'NORMAL') {
        return diagnosticsDetails?.diagnosticsDetailsSuccessDescription;
    } else if (alertState === 'WARNING') {
        return replacePlaceholderByValue(
            diagnosticsDetails.diagnosticsDetailsWarningDescription,
            {
                warningCount: diagnosticAPIDetails?.warningCount.toString(),
            }
        );
    } else if (alertState === 'ALERT') {
        if (diagnosticAPIDetails?.warningCount === 0) {
            return replacePlaceholderByValue(
                diagnosticsDetails.diagnosticsDetailsOnlyAlertDescription,
                {
                    alertCount: diagnosticAPIDetails?.alertCount?.toString(),
                }
            );
        } else {
            return replacePlaceholderByValue(
                diagnosticsDetails.diagnosticsDetailsAlertDescription,
                {
                    alertCount: diagnosticAPIDetails?.alertCount?.toString(),
                    warningCount: diagnosticAPIDetails?.warningCount?.toString(),
                }
            );
        }
    }
};
export const getDiagnosticCardState = (
    diagnosticAlertList: DiagnosticAlerts[],
    categoryId: number
) => {
    let cardState = '';
    if (diagnosticAlertList.length > 0) {
        diagnosticAlertList.forEach(item => {
            if (item.categoryId === categoryId) {
                cardState = getCardStateBySeverity(item.severities);
            }
        });
    }
    return cardState;
};

export const getDiagnosticCardMessage = (
    diagnosticAlertList: DiagnosticAlerts[],
    categoryId: number,
    errorMessage: string,
    successMessage: string
) => {
    const diagnosticAlert = diagnosticAlertList.find(
        item => item.categoryId === categoryId
    );

    if (diagnosticAlert) {
        if (
            diagnosticAlert.warningCount > 0 ||
            diagnosticAlert.alertCount > 0
        ) {
            return replacePlaceholderByValue(errorMessage, {
                alertCount: diagnosticAlert.alertCount.toString(),
                warningCount: diagnosticAlert.warningCount.toString(),
            });
        } else {
            return successMessage;
        }
    } else {
        return successMessage;
    }
};

export const getAllDiagnosticAlertState = (
    diagnosticAlertList: DiagnosticAlerts[]
) => {
    //Consolidating severities of all categories
    let severityArray: number[] = [];
    if (diagnosticAlertList.length > 0) {
        diagnosticAlertList.forEach(item => {
            severityArray = [...severityArray, ...item.severities];
        });
    }

    if (severityArray.indexOf(2) > -1 || severityArray.indexOf(3) > -1) {
        return CardState.ALERT;
    } else if (severityArray.indexOf(1) > -1) {
        return CardState.WARNING;
    } else {
        return CardState.NORMAL;
    }
};
