import ServerSideService from '../server-side-service/server-side-service';
import { RedisCacheService } from './redis-service';

export interface CacheEntry {
    key: string;
    data: any;
    expires?: number;
}

export enum STORE_TYPE {
    SESSION,
    LOCAL,
}

const CACHING_ENABLED = true;
const CACHE_EVICT_IN_MILLIS = 1 * 60 * 60 * 1000; // hours * minutes * seconds * millis

export class CacheService {
    private store: Storage | undefined;
    DEFAULT_STORE: STORE_TYPE = STORE_TYPE.SESSION;

    constructor(type?: STORE_TYPE) {
        if (ServerSideService.isClientSide()) {
            type && (this.DEFAULT_STORE = type);
            this.store =
                this.DEFAULT_STORE === STORE_TYPE.SESSION
                    ? sessionStorage
                    : localStorage;
        }
    }

    private getOrCreateCache(cacheName: string) {
        if (this.store) {
            if (!this.store.getItem(cacheName)) {
                this.store.setItem(cacheName, JSON.stringify({}));
            }

            const apiCache = this.store.getItem(cacheName);
            return apiCache ? JSON.parse(apiCache) : {};
        }
    }

    public putInCache(
        cacheName: string,
        cacheEntry: CacheEntry,
        ttl?: number
    ): void {
        if (this.store && CACHING_ENABLED) {
            const { key, data } = cacheEntry;
            const created = new Date().getTime();
            const expires = cacheEntry.expires
                ? cacheEntry.expires
                : new Date().getTime() + (ttl || CACHE_EVICT_IN_MILLIS);
            const cache = this.getOrCreateCache(cacheName);
            cache[key] = {
                data,
                created,
                expires,
            };
            this.store.setItem(cacheName, JSON.stringify(cache));
        }
    }

    public getFromCache(
        cacheName: string,
        key: string,
        ttl?: number
    ): any | undefined {
        if (this.store) {
            const cacheEntry = this.getOrCreateCache(cacheName)[key];
            if (cacheEntry) {
                const epochInPast =
                    new Date().getTime() - (ttl || CACHE_EVICT_IN_MILLIS);
                if (cacheEntry.expires > epochInPast) return cacheEntry.data;
                else {
                    this.evictFromCache(cacheName, key);
                    return undefined;
                }
            } else {
                return undefined;
            }
        }
    }

    public evictFromCache(cacheName: string, key: string): void {
        if (this.store) {
            const cache = this.getOrCreateCache(cacheName);
            delete cache[key];
            this.store.setItem(cacheName, JSON.stringify(cache));
            console.debug(`cache entry for ${key} evicted`);
        }
    }

    public async getFromRedis(key: string) {
        const redisClient = await RedisCacheService.getRedisClient();
        if (redisClient) {
            return redisClient
                .get(key)
                ?.then((result: string) => {
                    return JSON.parse(result);
                })
                .catch((e: any) => console.debug(e.message));
        }
    }

    public async putInRedis(key: string, value: any, ttl: 3600 | number) {
        const redisClient = await RedisCacheService.getRedisClient();
        if (redisClient && value) {
            redisClient.del(key)?.then((reply: any) => console.debug(reply));
            return redisClient
                .set(key, JSON.stringify(value), 'EX', ttl)
                .then(() =>
                    console.debug(
                        'key:: ' +
                            key +
                            ' cached in redis ' +
                            new Date().getTime()
                    )
                )
                .catch((e: any) => {
                    console.debug(e.message);
                    return e.message;
                });
        }
    }
    public async deleteFromRedis(key: string): Promise<void> {
        const redisClient = await RedisCacheService.getRedisClient();
        if (redisClient) {
            redisClient.del(key)?.catch((e: any) => {
                console.error(e.message);
            });
            console.debug('deleted key:: ' + key + ' from redis');
        }
    }
}
const cacheService = new CacheService();
export default cacheService;
