import React, { useState } from 'react';
import { FdsChevron } from '../chevron/fds-chevron';
import SupportFdsWrapper from '../../../support/components/support-fds-wrapper/support-fds-wrapper';
import { Breadcrumbs } from '../../sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';

interface Props {
    currentPage: string;
    hashId: string;
    type?: string;
    className?: string;
    title?: React.ReactNode;
    hideFirstBreadcrumb?: boolean;
}

export const BreadcrumbWrapper = (props: Props) => {
    const { currentPage, hashId, className, type, hideFirstBreadcrumb } = props;
    const [breadcrumbToggle, setBreadCrumbToggle] = useState(false);
    const isEU = new AppConfigurationService().isRegionEU();
    return (
        <>
            <SupportFdsWrapper className={className}>
                <div
                    className={`breadcrumbs-wrapper breadcrumbAccordion ${breadcrumbToggle &&
                        'expanded'} ${isEU ? 'eu-header' : ''}`}
                >
                    <Breadcrumbs
                        currentPage={currentPage}
                        hashId={hashId}
                        type={type}
                        onClick={() => sessionStorage.removeItem('selectedVin')}
                        hideFirstBreadcrumb={hideFirstBreadcrumb}
                    >
                        {props?.title}
                    </Breadcrumbs>

                    <div onClick={() => setBreadCrumbToggle(!breadcrumbToggle)}>
                        <FdsChevron
                            direction={breadcrumbToggle ? 'up' : 'down'}
                            type={'unfilled'}
                        ></FdsChevron>
                    </div>
                </div>
            </SupportFdsWrapper>
        </>
    );
};
