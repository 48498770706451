import React from 'react';
import SmartTile, { SmartTileProps } from './../smart-tile/smart-tile';

import './smart-tile-icon.scss';
import { InfoIconToolTip } from '../../common/info-icon-tooltip/info-icon-tooltip';

interface Props {
    header: string;
    ctaHref: string;
    ctaTitle: string;
    iconPath: string;
    staticSubCopy: string;
}

const SmartTileIcon = (props: Props & SmartTileProps) => {
    return (
        <SmartTile
            {...props}
            header={props.header || props.staticTitle}
            ctaHref={props.ctaHref || props.staticCTAPath}
            ctaTitle={props.ctaTitle || props.staticCTATitle}
            className="smart-tile-icon"
        >
            <span>
                <img
                    className="icon"
                    alt=""
                    src={process.env.REACT_APP_AEM_BASE_URL + props.iconPath}
                />
            </span>
            <div className="description">
                {props.staticSubCopy}
                {props.toolTipMsg && (
                    <InfoIconToolTip
                        tooltipContent={props.toolTipMsg}
                        customShowTooltipAriaLabel={props.showTooltipAriaLabel}
                        customDismissTooltipAriaLabel={
                            props.dismissTooltipAriaLabel
                        }
                        tooltipInfoIconClass={'smart-tile__tool-tip'}
                    />
                )}
            </div>
        </SmartTile>
    );
};

export default SmartTileIcon;
