import { useState } from 'react';
import { DealerProfileInfo } from '../../models/osb-model/osb-dealer-info';
import { ServiceInfo } from '../../models/osb-model/osb-dealerservice-info';
import { ContentElements } from '../../models/priceCalculator-content-details';
import { useHttp } from './use-http';
import { useOSBStep } from './use-osb-step';
import { useVehicleStep } from './use-vehicle-step';
import { getObjectFromAEMJson } from '../../components/sections/owners-osb/osb-utils';
import {
    API_REQUEST_SOURCE_TYPE,
    OSB_SERVICE_TYPE,
    SERVICE_DELIVERY_TYPE,
    SERVICE_FLOW,
} from '../../components/sections/owners-osb/osb-constant';
import { VehicleData } from '../../models/osb-model/osb-vehicle-step';
import ServiceHandler from '../../services/service-handler';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../../components/sections/service-price-calculator/price-calculator-constant';
import { useAllOSBState } from './use-all-osb-state';

export const useDealerService = () => {
    const { httpState, dispatch } = useHttp();
    const { osbVehicleStep } = useVehicleStep();
    const { osbStep } = useOSBStep();
    const [mainServices, setMainServices] = useState<ServiceInfo[]>([]);
    const [deliveryServices, setDeliveryServices] = useState<ServiceInfo[]>([]);
    const bookable = useAllOSBState();
    const vehicleData: VehicleData = {
        vin: osbVehicleStep.vehicleDetails.vin,
        mileage: osbVehicleStep.vehicleDetails.mileageInKm || '',
    };
    const loadDealerService = async (
        dealer: DealerProfileInfo,
        priceCalculatorResultContent: ContentElements[]
    ) => {
        dispatch({ type: 'REQUEST' });
        const maxRetryCount =
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.GET_SERVICES_MAX_RETRY_LABEL,
                priceCalculatorResultContent
            ) || PRICE_CALCULATOR_RESULT_CONSTANTS.SERVICES_MAX_RETRY_COUNT;
        const retryDelay =
            getObjectFromAEMJson(
                PRICE_CALCULATOR_RESULT_CONSTANTS.GET_SERVICES_RETRY_DELAY_IN_SECONDS_LABEL,
                priceCalculatorResultContent
            ) || SERVICE_FLOW.RETRY_INTERVAL;
        const retryInterval = retryDelay * 1000;
        let isValidResponse = false;
        for (
            let retryCount = maxRetryCount;
            retryCount > 0 && !isValidResponse;
            retryCount--
        ) {
            if (retryCount !== maxRetryCount) {
                await new Promise(r => setTimeout(r, retryInterval));
            }
            bookable.dealerCode = dealer.dealerCode;
            bookable.source = osbStep.source || API_REQUEST_SOURCE_TYPE.WEB;
            await ServiceHandler.OsbDealerServices.getServicesList({
                dealerCode: dealer.dealerCode,
                vehicleData: vehicleData,
                serviceDeliveryType: SERVICE_DELIVERY_TYPE.WORKSHOP,
                requestSourceType:
                    osbStep.source || API_REQUEST_SOURCE_TYPE.WEB,
                bookable: bookable,
                DSLAPIVersion: osbStep.DSLAPIVersion,
            })
                .then(result => {
                    const mainServices = result.value.dealerServices.mainServices.filter(
                        service =>
                            service.additionalInfo?.serviceType?.toUpperCase() ===
                                OSB_SERVICE_TYPE.SERVICES.toUpperCase() &&
                            service.serviceId.toString() !==
                                SERVICE_FLOW.GA_SERVICE_ID
                    );
                    isValidResponse = !mainServices.some(
                        service =>
                            service.serviceId.toString() ===
                            PRICE_CALCULATOR_RESULT_CONSTANTS.SCHEDULED_SERVICE
                    );
                    if (isValidResponse || retryCount === 1) {
                        setMainServices(mainServices);
                        setDeliveryServices(
                            result.value.dealerServices.additionalServices.filter(
                                service =>
                                    service.additionalInfo?.serviceType?.toUpperCase() ===
                                    OSB_SERVICE_TYPE.DELIVERY.toUpperCase()
                            )
                        );
                        dispatch({ type: 'RESPONSE' });
                    }
                })
                .catch((error: any) => {
                    if (retryCount === 1) {
                        dispatch({
                            type: 'ERROR',
                            error: error.message,
                        });
                        dispatch({
                            type: 'ERROR',
                            error: getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.COMMON_SERVICE_API_ERROR,
                                priceCalculatorResultContent
                            ),
                        });
                    }
                });
        }
    };
    return { httpState, mainServices, deliveryServices, loadDealerService };
};
