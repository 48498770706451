import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { useContext, useEffect, useState } from 'react';
import { HeaderType } from '../../smart-tiles/smart-tile/smart-tile';
import RecallsService, {
    RecallsApiResponse,
} from '../../../services/recalls-service/recalls-service';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import warningIcon from '../../../assets/warning-icon-red.svg';
import { findByAlias } from '../../../routes';
import ServerContext from '../../../contexts/serverContext';
import { isYmmAvailable } from '../../../support/pages/recalls-details/recalls-util';
interface Props {
    recallDataWithVin: RecallsApiResponse;
}
export const VehicleChecklistRecallsCard = (
    props: Props & VehicleChecklistContent & VehicleAttributes
) => {
    const [recallDataForTile, setRecallDataForTile] = useState<
        RecallsApiResponse
    >();
    if (serverSideService.isClientSide() && props.vin) {
        localStorage.setItem('USER_VIN', props.vin);
    }
    const serverContext = useContext(ServerContext);

    const isYmmLaunched = isYmmAvailable(
        serverContext.currentLanguageRegionCode,
        serverContext.brand
    );
    useEffect(() => {
        if (props.vin) setRecallDataForTile(props.recallDataWithVin);
    }, [props.vin]);
    useEffect(() => {
        const modelValue = props.seoKey ? props.seoKey : props.model;
        if (
            isYmmLaunched &&
            !props.vin &&
            (props.seoKey || props.model) &&
            props.year
        )
            new RecallsService()
                .requestYmm(modelValue, props.year.toString())
                .then(response => {
                    if (response) {
                        setRecallDataForTile(response);
                    }
                });
    }, [props.seoKey || props.model]);

    if (recallDataForTile) {
        const recall =
            recallDataForTile?.recalls.length + recallDataForTile?.nhtsa.length;
        const ctaHref = (): string => {
            if (props.vin && props.dynamicCtaPath) {
                return props.dynamicCtaPath;
            } else if ((props.seoKey || props.model) && props.year) {
                return findByAlias('RecallsDetailsPage')
                    .replace(':vinOrModel?', props.seoKey || props.model)
                    .replace(':year?', `${props.year}`);
            }
            return props.staticCtaPath;
        };
        const dynamicText = (): {
            type: HeaderType;
            icon: string;
            description: string;
        } => {
            if (recall === 0) {
                return {
                    type: 'none',
                    icon: props.iconPath,
                    description: props.staticDescription,
                };
            } else
                return {
                    type: 'severe',
                    icon: warningIcon,
                    description: props.dynamicDescription,
                };
        };
        return (
            <>
                <VehicleChecklist
                    headerType={dynamicText().type}
                    type={props.cardTypes}
                    header={props.title}
                    ctaTitle={props.ctaTitle}
                    description={dynamicText().description}
                    iconPath={dynamicText().icon}
                    ctaPath={ctaHref()}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                />
            </>
        );
    } else {
        return (
            <VehicleChecklist
                header={props.title}
                type={props.cardTypes}
                ctaTitle={props.ctaTitle}
                description={props.staticDescription}
                iconPath={props.iconPath}
                ctaPath={props.staticCtaPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
            />
        );
    }
};
export default VehicleChecklistRecallsCard;
