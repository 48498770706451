export const validate = (data: any, rebatesStatus: any) => {
    const errors = {
        trackingNumberRequired: '',
        emailRequired: '',
        isEmailValid: false,
        isTrackingNumberValid: false,
        allValid: false,
    };

    if (!data.trackingNumber) {
        errors.trackingNumberRequired =
            rebatesStatus?.trackingNumberErrorMsg || '';
    } else if (!/^[A-Z0-9]{8}$/i.test(data.trackingNumber)) {
        errors.trackingNumberRequired =
            rebatesStatus?.inValidTrackingNumberMsg || '';
    } else {
        errors.isTrackingNumberValid = true;
    }

    if (!data.email) {
        errors.emailRequired = rebatesStatus?.emailIdErrorMsg || '';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.emailRequired = rebatesStatus?.inValidemailMsg || '';
    } else {
        errors.isEmailValid = true;
    }

    if (errors.isTrackingNumberValid && errors.isEmailValid) {
        errors.allValid = true;
    }

    return errors;
};
