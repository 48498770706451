import React, { useState } from 'react';
import './input-with-placeholder.scss';
import errorWarning from '../../../../assets/error-warning.svg';
interface Props {
    placeholder: string;
    error?: string;
    formattedValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
function InputWithPlaceholder(props: Props) {
    const [value, setValue] = useState('');
    const [showPlaceholder, setShowPlaceholder] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (event.target.value !== '') {
            setShowPlaceholder(true);
        } else {
            setShowPlaceholder(false);
        }
        props.onChange && props.onChange(event);
    };

    return (
        <div className="spc-input-container">
            {showPlaceholder && (
                <div className="spc-input-placeholder">{props.placeholder}</div>
            )}
            <input
                type="text"
                className="spc-input-text"
                placeholder={props.placeholder}
                value={props.formattedValue || value}
                onChange={handleChange}
            />
            {props.error && (
                <React.Fragment>
                    <img
                        src={errorWarning}
                        className="spc-input-icon"
                        alt="icon"
                    />
                    <div className="spc-input-error">{props.error}</div>
                </React.Fragment>
            )}
        </div>
    );
}

export default InputWithPlaceholder;
