import React, { useEffect, useRef } from 'react';
import './osb-brightcove-video-player.scss';
import {
    triggerVideoPlayAnalytics,
    triggerVideoPercentage,
    triggerVideoComplete,
} from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';

interface Props {
    accountId: string;
    videoId: string;
    playerId: string;
    videoName: string;
}
export const OSBBrightcoveVideoPlayer = (props: Props) => {
    const brightcoveVideoId = useRef<HTMLVideoElement>(null);
    const [fireEvents] = useAnalytics();
    useEffect(() => {
        const script = document.createElement('script');

        script.src = `https://players.brightcove.net/${props.accountId}/${props.playerId}_default/index.min.js`;
        script.defer = true;

        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        const getSegmentViewed = (percentage: number) => {
            switch (percentage) {
                case 25:
                    return { segmentViewed: '1:M:0-25', videoPercentage: '25' };
                case 50:
                    return {
                        segmentViewed: '2:M:25-50',
                        videoPercentage: '50',
                    };
                case 75:
                    return {
                        segmentViewed: '3:M:50-75',
                        videoPercentage: '75',
                    };
                default:
                    return { segmentViewed: '', videoPercentage: '' };
            }
        };
        const checkVideoProgress = (videoPlayPercentage: number) => {
            const roundedPercentage = Math.round(videoPlayPercentage);
            if (roundedPercentage === 1) {
                triggerVideoPlayAnalytics(props.videoName, fireEvents);
            }

            const { segmentViewed, videoPercentage } = getSegmentViewed(
                roundedPercentage
            );
            if (segmentViewed && videoPercentage) {
                triggerVideoPercentage(
                    props.videoName,
                    videoPercentage,
                    segmentViewed,
                    fireEvents
                );
            }

            if (roundedPercentage >= 99) {
                triggerVideoComplete(props.videoName, fireEvents);
            }
        };
        const intervalId = setInterval(() => {
            if (!brightcoveVideoId.current?.paused) {
                const videoDuration = brightcoveVideoId.current?.duration || 0;
                const currentVideoTime =
                    brightcoveVideoId.current?.currentTime || 0;
                const videoPlayPercentage =
                    videoDuration && (currentVideoTime / videoDuration) * 100;

                checkVideoProgress(videoPlayPercentage);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <div className="osb-video-container">
                <video
                    ref={brightcoveVideoId}
                    id={`videoId-${props.videoId}`}
                    data-video-id={props.videoId}
                    data-account={props.accountId}
                    data-player={props.playerId}
                    data-embed="default"
                    className={`video-js video-player`}
                    controls
                ></video>
            </div>
        </>
    );
};
