import {
    DealerArray,
    DealerDetails,
    DealerProfileInfo,
    DslDealerDetails,
    OsbDealerDetailsResponse,
} from '../../../../models/osb-model/osb-dealer-info';

export const transformDealerDetailsResponse = (
    dealerDetailsResponse: OsbDealerDetailsResponse
): DealerArray => {
    const dealers: DealerDetails[] = [];

    for (const dealer of dealerDetailsResponse.dealers) {
        dealers.push(transformDealer(dealer));
    }
    return {
        dealers: dealers,
        filterDeliveryServices: dealerDetailsResponse.filterDeliveryServices
            ? JSON.parse(dealerDetailsResponse.filterDeliveryServices)
            : [],
        filterDidServices: dealerDetailsResponse.filterDidServices
            ? JSON.parse(dealerDetailsResponse.filterDidServices)
            : [],
        filterRegularServices: dealerDetailsResponse.filterRegularServices
            ? JSON.parse(dealerDetailsResponse.filterRegularServices)
            : [],
    };
};

const transformDealer = (dealer: DslDealerDetails): DealerDetails => {
    const dealerProfile = extractDealerProfile(dealer);
    return {
        dealerName: dealer.name,
        dealerCode: dealer.dealerCode,
        dealerType: '',
        dealerProfile: dealerProfile,
    };
};

const extractDealerProfile = (dealer: DslDealerDetails): DealerProfileInfo => {
    const earliestAvailableTimeslot =
        dealer.dealerAppointments && dealer.dealerAppointments.length > 0
            ? dealer.dealerAppointments[0].nextAppointmentDate
            : '';
    return {
        dealerCode: dealer.dealerCode,
        dealerGroup: dealer.dealerGroup ? dealer.dealerGroup : '',
        dealerName: dealer.name,
        street: dealer.street,
        town: dealer.town,
        postalCode: dealer.postalCode,
        openingHours: dealer.openingHours,
        customerRating: 0,
        customerRespondentsService: 0,
        distance: 0,
        delivery: dealer.delivery,
        primaryPhone: dealer.phone,
        location: {
            lat: 0,
            lng: 0,
        },
        dealerType: '',
        specialServices: [],
        additionalInfo: {
            earliestAvailableTimeslot: earliestAvailableTimeslot,
            dealerFilterServiceAvailability:
                dealer.filterServiceAvailability ?? [],
            nextAppointmentDateAm:
                dealer.dealerAppointments?.[0]?.nextAppointmentDateAm,
            nextAppointmentDatePm:
                dealer.dealerAppointments?.[0]?.nextAppointmentDatePm,
            nextAppointmentDateWeekend:
                dealer.dealerAppointments?.[0]?.nextAppointmentDateWeekend,
        },
    };
};
