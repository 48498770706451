import React, { useState, useEffect } from 'react';
import './retrieve-booking-view.scss';
import { OsbContentResponse } from '../../../models/osb-model/osb-content-details';
import ServiceHandler from '../../../services/service-handler';
import OsbUtilService from '../../../services/osb-service/osb-util-service/osb-util-service';
import { useHttp } from '../../../hooks/owners-osb';
import { LightRetrieveBooking } from '../../../components/sections/owners-osb/retrieve-booking/light-journey/light-retrieve-booking';
import { OsbLoader } from '../../../components/common/osb-loader/osb-loader';

export function RetrieveBookingStepView() {
    const { httpState, dispatch } = useHttp();
    const [retrieveBookingContent, setRetrieveBookingContent] = useState<
        OsbContentResponse
    >();
    const [osbAppMoreContent, setOsbAppMoreContent] = useState<any>();

    const setError = (error: any) => {
        if (error.response) {
            const errorMessage = error.response.data.error.message;
            dispatch({ type: 'ERROR', error: errorMessage });
        }
    };
    function getRetrieveBookingContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-retrieve-booking'
        )
            .then(results => {
                setRetrieveBookingContent(results);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                setError(error);
            });
    }

    const getOsbAppMoreElementsContent = () => {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-app-more-elements'
        )
            .then(results => {
                setOsbAppMoreContent(results.elements);
                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: any) => {
                setError(error);
            });
    };

    useEffect(() => {
        dispatch({ type: 'REQUEST' });
        getRetrieveBookingContent();
        getOsbAppMoreElementsContent();
    }, []);

    return (
        <div className="retrieve-booking-step-view-container">
            {httpState.isLoading ? (
                <OsbLoader osbEmptyContentHeight={true} />
            ) : (
                <>
                    <LightRetrieveBooking
                        retrieveBookingContent={retrieveBookingContent}
                        osbAppMoreContent={osbAppMoreContent}
                    />
                </>
            )}
        </div>
    );
}
