import React from 'react';
import { useLoyaltyPointsDataForTile } from '../../sections/vehicle-portal/hooks/use-loyalty-points-data-for-tile';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';

const VehicleChecklistFPR = (props: VehicleChecklistContent) => {
    const fprDataForTile = useLoyaltyPointsDataForTile();
    const { brand } = new AppConfigurationService();

    if (fprDataForTile) {
        const points =
            brand === 'ford'
                ? fprDataForTile.ford?.points?.toLocaleString()
                : fprDataForTile.lincoln?.points?.toLocaleString();
        return (
            <VehicleChecklist
                ctaAriaLabel={props.ctaAriaLabel}
                ctaPath={props.dynamicCtaPath}
                ctaTargetBlank={props.ctaTargetBlank}
                ctaTitle={props.ctaTitle}
                description={points + ' ' + props.dynamicDescription}
                header={props.title}
                type={props.cardTypes}
                iconPath={props.iconPath}
            />
        );
    } else {
        return (
            <VehicleChecklist
                ctaAriaLabel={props.ctaAriaLabel}
                ctaPath={props.staticCtaPath}
                ctaTargetBlank={props.ctaTargetBlank}
                ctaTitle={props.ctaTitle}
                description={props.staticDescription}
                header={props.title}
                type={props.cardTypes}
                iconPath={props.iconPath}
            />
        );
    }
};

export default VehicleChecklistFPR;
