import { useReducer } from 'react';

interface HttpState {
    isLoading: boolean;
    isError: boolean;
    error: string;
}

const httpReducer = (currentState: HttpState, action: any): HttpState => {
    switch (action.type) {
        case 'REQUEST':
            return {
                ...currentState,
                isLoading: true,
                isError: false,
                error: '',
            };
        case 'RESPONSE':
            return {
                ...currentState,
                isLoading: false,
                isError: false,
                error: '',
            };
        case 'ERROR':
            return {
                ...currentState,
                isLoading: false,
                isError: true,
                error: action.error,
            };
        default:
            throw new Error(
                'The provided action type not matched with any of httpReducer'
            );
    }
};

export const useHttp = () => {
    const [httpState, dispatch] = useReducer(httpReducer, {
        isLoading: true,
        isError: false,
        error: '',
    });

    return { httpState: httpState, dispatch: dispatch };
};
