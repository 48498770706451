import React from 'react';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { findByAlias } from '../../../routes';
import { useOwnerManualContent } from '../../../support/pages/owner-manual/hook/use-owner-manual-content';
import serverSideService from '../../../services/server-side-service/server-side-service';
import QuickLinksCommon from '../quick-links-common/quick-links-common';
import { QuickLinksContent } from '../../../models/experiencefragments/quick-links';

const QuickLinksOwnerManuals = (
    props: VehicleAttributes & QuickLinksContent
) => {
    useOwnerManualContent();
    localStorage.removeItem('USER_VIN');
    sessionStorage.removeItem('QUERY_VIN');
    if (serverSideService.isClientSide() && props.vin) {
        localStorage.setItem('USER_VIN', props.vin);
    }
    let manualsCta: string;

    if (props.vin) {
        manualsCta = props.dynamicCTAPath;
    } else if (props.seoKey || props.model) {
        manualsCta = findByAlias('OwnerManualDetailsPage')
            .replace(':model+', props.seoKey || props.model)
            .replace(':year', `${props.year}`);
    } else {
        manualsCta = props.staticCTAPath;
    }

    return (
        <QuickLinksCommon
            ctaAriaLabel={props.ctaAriaLabel}
            analyticsName={props.analyticsName}
            ctaTargetBlank={props.ctaTargetBlank}
            dynamicCTAPath={manualsCta}
            iconPath={props.iconPath}
            make={props.make}
            model={props.model}
            staticCTAPath={manualsCta}
            tileTitle={props.tileTitle}
            type={props.type}
            year={props.year}
        />
    );
};

export default QuickLinksOwnerManuals;
