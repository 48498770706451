import React from 'react';
import { FMButton } from '@own/fds-react';
import {
    DSL_MMOTA_STATUS,
    MMOTAUtil,
    SETTINGS_DISABLED,
    USER_DISALLOWED,
} from '../../vehicle-checklist/vehicle-checklist-mmota/mmota-util';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import { MMOTAStatusResponse } from '../../../services/mmota-status-service/mmota-status.interface';
import FMDrawer from '../../common/fm-drawer/fm-drawer';
import './fm-action-software-update-drawer.scss';

interface Props {
    onClose: () => void;
    mmotaData?: MMOTAStatusResponse;
    isVisible: boolean;
}
export const getUpdateDisplayTime = (input?: string): string =>
    input ? input : '45';

export const getCurrentStatus = (
    alertDescription?: string
): string | undefined => alertDescription;

export const getSettingsStatus = (
    currentStatus: string | undefined,
    isCcsDisabled: boolean,
    isAsuDisabled: boolean
): string =>
    !currentStatus && (isCcsDisabled || isAsuDisabled)
        ? SETTINGS_DISABLED
        : (currentStatus as string);

const FMActionItemSoftwareUpdateDrawer = (props: Props) => {
    const {
        summary,
        mmotaAlertsDetails,
        tappsData,
        ccsStatus,
        releaseNotes,
        error,
        status,
    } = props.mmotaData || {};
    const mmotaUtil = new MMOTAUtil();
    const dateTimeUtil = new DateTimeUtil();
    const mmotaStatusDrawerContent = mmotaUtil.mmotaStatusModalContent;

    const mmotaAlertsDetail = mmotaAlertsDetails?.[0];
    const currentStatus = getCurrentStatus(
        summary?.alertDescription || status?.errorDescription
    );
    const { date, time } = dateTimeUtil.parseDateTime(
        mmotaAlertsDetail?.dateTimeStamp || '',
        true
    );
    const updateDisplayTime = getUpdateDisplayTime(
        mmotaAlertsDetail?.updateDisplayTime?.toString() || '45'
    );
    const scheduleTypeMap: { [key: string]: string } = {
        none:
            mmotaStatusDrawerContent?.recurringScheduleNotSetText ?? 'Not Set',
        recurring: mmotaUtil.getParsedRecurringDateTime(
            tappsData || null,
            mmotaStatusDrawerContent?.showInMeridiemTime
        ),
    };
    const parsedRecurringDateTime =
        scheduleTypeMap[tappsData?.scheduleType?.toLowerCase() || ''];
    const headerDescription = mmotaStatusDrawerContent?.updateReminderStateDesc?.replace(
        '%date%',
        mmotaUtil.getParsedRecurringDateTime(
            tappsData || null,
            mmotaStatusDrawerContent?.showInMeridiemTime
        )
    );
    const updateNotesDescription =
        releaseNotes ?? mmotaStatusDrawerContent?.releaseNotesDesc ?? '';

    const statusMap: {
        [key: string]: {
            header: string | undefined;
            description: string | undefined;
            releaseNotesAvailable: boolean;
        };
    } = {
        [USER_DISALLOWED]: {
            header: mmotaStatusDrawerContent?.accessUnavailableStateTitle,
            description: mmotaStatusDrawerContent?.accessUnavailableStateDesc,
            releaseNotesAvailable: false,
        },
        [DSL_MMOTA_STATUS.upToDate]: {
            header: mmotaStatusDrawerContent?.upToDateStatusTitle,
            description: mmotaStatusDrawerContent?.upToDateStatusDesc,
            releaseNotesAvailable: false,
        },
        [DSL_MMOTA_STATUS.updateSuccessful]: {
            header: mmotaStatusDrawerContent?.successStatusTitle,
            description: mmotaStatusDrawerContent?.successStatusDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateWillRetry]: {
            header: mmotaStatusDrawerContent?.retryStateTitle,
            description: mmotaStatusDrawerContent?.retryStateDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateInProgress]: {
            header: mmotaStatusDrawerContent?.inProgressStateTitle,
            description: mmotaStatusDrawerContent?.inProgressStateDesc?.replace(
                '%time%',
                updateDisplayTime
            ),
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateReminder]: {
            header: mmotaStatusDrawerContent?.updateReminderStateTitle,
            description: headerDescription?.replace(
                '%time%',
                updateDisplayTime
            ),
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updatePending]: {
            header: mmotaStatusDrawerContent?.updatePendingStateTitle,
            description: mmotaStatusDrawerContent?.updatePendingStateDesc?.replace(
                '%date%',
                [date, mmotaStatusDrawerContent?.atTranslationText, time]
                    .join(' ')
                    .trim()
            ),
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended]: {
            header: mmotaStatusDrawerContent?.updateUnsuccessfulStateTitle,
            description: mmotaStatusDrawerContent?.updateUnsuccessfulStateDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain]: {
            header: mmotaStatusDrawerContent?.updateUnsuccessfulStateTitle,
            description: mmotaStatusDrawerContent?.updateUnsuccessfulStateDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.vehicleInoperable]: {
            header: mmotaStatusDrawerContent?.inoperableStateTitle,
            description: mmotaStatusDrawerContent?.inoperableStateDesc,
            releaseNotesAvailable: true,
        },
        [SETTINGS_DISABLED]: {
            header: mmotaStatusDrawerContent?.settingsDisabledStateTitle,
            description: mmotaStatusDrawerContent?.settingsDisabledStateDesc,
            releaseNotesAvailable: false,
        },
    };
    const isCcsDisabled = ccsStatus?.ccsVehicleData?.toLowerCase() !== 'on';
    const isAsuDisabled = tappsData?.asuSettingsState?.toLowerCase() !== 'on';

    const settingsStatus = getSettingsStatus(
        currentStatus,
        isCcsDisabled,
        isAsuDisabled
    );
    const isAccessAvailable = error?.message !== USER_DISALLOWED;
    const statusKey = isAccessAvailable ? settingsStatus : USER_DISALLOWED;
    const statusInfo = statusMap[statusKey];
    const { header, description = '', releaseNotesAvailable } = statusInfo;

    const settingsState = isAsuDisabled
        ? mmotaStatusDrawerContent?.automaticUpdatesOffText ?? 'Off'
        : mmotaStatusDrawerContent?.automaticUpdatesOnText ?? 'On';

    const displayHowToChangeOrSetContent =
        currentStatus &&
        [
            DSL_MMOTA_STATUS.upToDate,
            DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended,
            DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain,
            DSL_MMOTA_STATUS.updateReminder,
        ].includes(currentStatus);
    return (
        <FMDrawer
            className={'software-updates-drawer'}
            showDraw={props.isVisible}
            onClose={props.onClose}
        >
            <h3 className={'software-updates-title'}>{header}</h3>
            <div
                className={'software-updates-description'}
                data-testid="software-updates-description"
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
            {mmotaStatusDrawerContent && (
                <>
                    {currentStatus === DSL_MMOTA_STATUS.updatePending && (
                        <div className={'fm-update-pending-btn-wrapper'}>
                            <FMButton
                                className={'inline-first'}
                                href={
                                    mmotaStatusDrawerContent.connectToWifiLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusDrawerContent.connectToWifiLinkText
                                }
                                label={
                                    mmotaStatusDrawerContent.connectToWifiLinkText
                                }
                                type={'tertiary'}
                            />

                            <FMButton
                                className={'inline-middle'}
                                href={
                                    mmotaStatusDrawerContent.acceptUpdateLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusDrawerContent.acceptUpdateLinkText
                                }
                                label={
                                    mmotaStatusDrawerContent.acceptUpdateLinkText
                                }
                                type={'tertiary'}
                            />

                            <FMButton
                                className={'inline-last'}
                                href={
                                    mmotaStatusDrawerContent.setScheduleLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusDrawerContent.setScheduleLinkText
                                }
                                label={
                                    mmotaStatusDrawerContent.setScheduleLinkText
                                }
                                type={'tertiary'}
                            />
                        </div>
                    )}
                    {!isAccessAvailable && (
                        <FMButton
                            href={
                                mmotaStatusDrawerContent.howToActivateLinkPath
                            }
                            ariaLabel={
                                mmotaStatusDrawerContent.howToActivateLinkText
                            }
                            label={
                                mmotaStatusDrawerContent.howToActivateLinkText
                            }
                            type={'tertiary'}
                        />
                    )}
                    <div className={'fm-mmota-button-wrapper'}>
                        {displayHowToChangeOrSetContent && (
                            <FMButton
                                href={
                                    mmotaStatusDrawerContent.howToChangeOrSetLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusDrawerContent.howToChangeOrSetLinkText
                                }
                                label={
                                    mmotaStatusDrawerContent.howToChangeOrSetLinkText
                                }
                                type={'tertiary'}
                            />
                        )}
                        {currentStatus === DSL_MMOTA_STATUS.updateWillRetry && (
                            <FMButton
                                href={
                                    mmotaStatusDrawerContent.howToChangeOrSetLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusDrawerContent.howToChangeLinkText
                                }
                                label={
                                    mmotaStatusDrawerContent.howToChangeLinkText
                                }
                                type={'tertiary'}
                            />
                        )}
                        {isCcsDisabled &&
                            error?.message !== USER_DISALLOWED && (
                                <FMButton
                                    href={
                                        mmotaStatusDrawerContent.howToChangeSettingsLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusDrawerContent.howToChangeOTASettingsLinkText
                                    }
                                    label={
                                        mmotaStatusDrawerContent.howToChangeOTASettingsLinkText
                                    }
                                    type={'tertiary'}
                                />
                            )}
                        {isCcsDisabled &&
                            error?.message !== USER_DISALLOWED && (
                                <FMButton
                                    href={
                                        mmotaStatusDrawerContent.howToChangeSettingsLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusDrawerContent.howToChangeSettingsLinkText
                                    }
                                    label={
                                        mmotaStatusDrawerContent.howToChangeSettingsLinkText
                                    }
                                    type={'tertiary'}
                                />
                            )}
                        <FMButton
                            href={mmotaStatusDrawerContent.getSupportLinkPath}
                            ariaLabel={
                                mmotaStatusDrawerContent.getSupportLinkText
                            }
                            label={mmotaStatusDrawerContent.getSupportLinkText}
                            type={'tertiary'}
                        />
                    </div>
                    {currentStatus !== DSL_MMOTA_STATUS.updateInProgress && (
                        <hr />
                    )}
                    {settingsState &&
                        currentStatus !== DSL_MMOTA_STATUS.updateInProgress && (
                            <p className={'settings-not-updateinprogress'}>
                                {`${mmotaStatusDrawerContent.otaSettingsText}
                                ${settingsState}`}
                            </p>
                        )}
                    {parsedRecurringDateTime &&
                        currentStatus !== DSL_MMOTA_STATUS.updateInProgress && (
                            <p className={'date-not-updateinprogress'}>
                                {`${mmotaStatusDrawerContent.recurringScheduleText}
                                ${parsedRecurringDateTime}`}
                            </p>
                        )}
                    {
                        <>
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updateSuccessful &&
                                date &&
                                time && (
                                    <p className="top-padding">
                                        {
                                            mmotaStatusDrawerContent.lastSuccessfulText
                                        }
                                        {date}
                                    </p>
                                )}

                            {releaseNotesAvailable && (
                                <div className={'fm-update-notes-wrapper'}>
                                    <hr className="mmota-updates__header-hr" />
                                    <h3 className="mmota-updates__header">
                                        {
                                            mmotaStatusDrawerContent.updateNotesTitle
                                        }
                                    </h3>
                                    <div
                                        className="mmota-updates__notes"
                                        dangerouslySetInnerHTML={{
                                            __html: updateNotesDescription,
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    }
                </>
            )}
        </FMDrawer>
    );
};

export default FMActionItemSoftwareUpdateDrawer;
