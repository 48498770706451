import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';
import { HttpsProxyAgent } from 'https-proxy-agent';
import axios from 'axios';
import { UTEFormData } from '../../components/sections/ultimate-towing-experience/components/types';
const httpsAgent = new HttpsProxyAgent(
    process.env.http_proxy || 'http://internet.ford.com:83'
);

const UtePostService = async (vin: string, ute: UTEFormData) => {
    const UtePostUrl =
        DSL_BASE_URL + DSL_API_PATH.UTE_POST_SERVICES + '?vin=' + vin;
    const apiResponse = axios
        .post<UTEFormData>(UtePostUrl, ute, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            },
            httpsAgent,
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
    return apiResponse;
};
export { UtePostService };
