import React, { useContext } from 'react';
import { Link } from '../../common';
import './footer.scss';
import { ChangeLanguageToggle } from './change-lang';
import { FooterFragment } from '../../../models/experiencefragments/footer';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { HeaderFragment } from '../../../models/experiencefragments/header';
import {
    useExperienceContent,
    useContent,
} from '../../../hooks/use-server-content';
import { CcpaBanner } from '../ccpa-banner/ccpa-banner';
import { EU_COUNTRIES } from '../../../constants';
import ServerContext from '../../../contexts/serverContext';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../hooks/use-analytics';

const Footer = () => {
    const [fireEvents] = useAnalytics();
    const serverContext = useContext(ServerContext);
    const [experienceContent] = useExperienceContent<FooterFragment>(
        'common',
        'header-footer',
        'footer'
    );
    const { currentRegionCode } = new AppConfigurationService();
    const [country] = [
        serverContext ? serverContext.currentRegionCode : currentRegionCode,
    ];

    const [headerContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );
    const [, getValueByName] = useContent('common', 'app-information');

    return (
        experienceContent &&
        headerContent && (
            <footer className="footer">
                {(country === 'us' || country === 'mx') && <CcpaBanner />}
                {experienceContent.primarySection.primaryLinks && (
                    <div className="primary-section pipe-separated-list-container">
                        <ul className="skinny">
                            {experienceContent.primarySection.primaryLinks.map(
                                (link, index) => {
                                    const maplength =
                                        experienceContent.primarySection
                                            .primaryLinks.length;
                                    return (
                                        <li key={index}>
                                            <span
                                                className={`${
                                                    index === 0
                                                        ? 'primary-first-item'
                                                        : ''
                                                }
                                        ${
                                            index === maplength - 1
                                                ? 'primary-last-item'
                                                : 'content-label'
                                        }`}
                                            ></span>
                                            <Link
                                                key={index}
                                                href={link.url}
                                                onClick={() =>
                                                    NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                                        link.url,
                                                        fireEvents
                                                    )
                                                }
                                                className={`${
                                                    index === maplength - 1
                                                        ? 'sec-link-last'
                                                        : 'sec-link-color'
                                                }`}
                                                target={
                                                    link.targetBlank
                                                        ? '_blank'
                                                        : '_self'
                                                }
                                            >
                                                {link.title}
                                            </Link>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>
                )}
                {!EU_COUNTRIES.includes(country) &&
                    experienceContent.changeLanguage.title && (
                        <div className="change-language-section footer-change-lang-block">
                            <span className="desktop">
                                <ChangeLanguageToggle
                                    tabIndex={0}
                                    langText={
                                        experienceContent.changeLanguage.title
                                    }
                                    dialogTitle={
                                        experienceContent.changeLanguage
                                            .dialogTitle
                                    }
                                />
                            </span>
                        </div>
                    )}
                <div className="tertiary-first-title">
                    © {getValueByName('copyrightStatement')}
                </div>
                <div className="tertiary-section links">
                    {experienceContent.tertiarySection.tertiaryLinks &&
                        experienceContent.tertiarySection.tertiaryLinks.map(
                            (link, index) => {
                                return (
                                    <Link
                                        key={index}
                                        href={link.url}
                                        onClick={() =>
                                            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                                link.url,
                                                fireEvents
                                            )
                                        }
                                        className={`${
                                            link?.url?.indexOf(
                                                'cookieSettings'
                                            ) > 0
                                                ? 'optanon-toggle-display link'
                                                : 'link'
                                        }`}
                                        target={
                                            link.targetBlank
                                                ? '_blank'
                                                : '_self'
                                        }
                                    >
                                        {link.title}
                                    </Link>
                                );
                            }
                        )}
                    <a
                        tabIndex={0}
                        href={headerContent.logo.url}
                        onClick={() =>
                            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                headerContent.logo.url,
                                fireEvents
                            )
                        }
                        target={
                            headerContent.logo.targetBlank ? '_blank' : '_self'
                        }
                        className="logo-link"
                        aria-label={headerContent.logo.ariaLabel}
                        rel="noopener noreferrer"
                    >
                        <span
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${headerContent.logo.logoPath})`,
                            }}
                            className={'ford-logo'}
                        />
                    </a>
                </div>
            </footer>
        )
    );
};
export default Footer;
