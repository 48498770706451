import React, { useState, useEffect, useContext } from 'react';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import {
    MaintenanceScheduleLanding,
    MaintenanceScheduleDetails,
} from '../../components/sections';
import useSeoConfig from '../../hooks/use-seo-config';
import ServerContext from '../../contexts/serverContext';
import useUserProfile from '../../hooks/use-user-profile';
import serverSideService from '../../services/server-side-service/server-side-service';

export const MaintenanceScheduleLandingView = () => {
    const [authenticatedState, setAuthenticatedUser] = useState('');
    const authenticationService = new AuthenticationService();
    const serverContext = useContext(ServerContext);
    const userSelectedVin =
        typeof window === 'undefined'
            ? serverContext.profile?.vin
            : window.sessionStorage.getItem('selectedVin');
    const currentVin = serverContext.webviewData?.vin
        ? serverContext.webviewData?.vin
        : userSelectedVin;

    const [selectedVin, setSelectedVin] = useState<string>(currentVin);
    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    const profile = useUserProfile();
    const [uomDistance, setUomDistance] = useState<number>(0);
    const pageName = currentVin
        ? 'maintenance-schedule-details'
        : 'maintenance-schedule-landing';
    useSeoConfig(pageName, 'maintenance-schedule');

    useEffect(() => {
        scrollPageToTop();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setAuthenticatedUser('loggedIn');
                } else {
                    setAuthenticatedUser('notLoggedIn');
                }
            });
    }, []);
    useEffect(() => {
        if (profile?.profile) {
            setUomDistance(profile.profile?.uomDistance);
        }
    }, [profile]);

    const loginHandler = (action: string) => {
        if (action === 'signin') {
            authenticationService.login();
        } else if (action === 'register') {
            authenticationService.register();
        }
    };

    const goToMaintenanceScheduleViewDetails = (vin: string) => {
        if (serverSideService.isClientSide()) {
            sessionStorage.setItem('selectedVin', vin);
            sessionStorage.setItem('retainedVIN', vin);
        }
        setSelectedVin(vin);
    };

    return (
        <>
            {selectedVin ? (
                <MaintenanceScheduleDetails
                    vin={selectedVin}
                    uomDistance={uomDistance}
                    page={'Maintenance Schedule Details'}
                    isAuthenticated={authenticatedState}
                />
            ) : (
                <MaintenanceScheduleLanding
                    isAuthenticated={authenticatedState}
                    loginClick={loginHandler}
                    goToMaintenanceScheduleViewDetails={
                        goToMaintenanceScheduleViewDetails
                    }
                    page={'Maintenance Schedule'}
                />
            )}
        </>
    );
};
export default MaintenanceScheduleLandingView;
