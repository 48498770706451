import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import PrimaryButton from '../../common/primary-button/primary-button';
import { Modal } from '../../common/modal/modal';
import ProfileService from '../../../services/profile-service/profile-service';
import { useHistory } from 'react-router-dom';
import { CacheService } from '../../../services/cache-service/cache-service';
import {
    DSL_API_PATH,
    DSL_BASE_URL,
    KEYBOARD_KEYS,
    SESSION_KEY_API_CACHE,
} from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { useAddVehicleContent } from './hook/use-add-vehicle';
import { findByAlias } from '../../../routes';
import ServerContext from '../../../contexts/serverContext';
import { PageType } from '../view-template/page-type';
import { useAnalytics } from '../../../hooks/use-analytics';
import { InfoIconToolTip } from '../../common/info-icon-tooltip/info-icon-tooltip';

import './add-vehicle.scss';
import { notify } from '../../../services/slack-message-service/SlackMessageService';
import VinInput from '../../common/vin-input/vin-input';
import { useVinSelectorContent } from '../vehicle-selector-fds/vin-selector/hook/use-vin-selector';
import useUserProfile from '../../../hooks/use-user-profile';
import ServiceHandler from '../../../services/service-handler';
import { filterXSS } from 'xss';
import { SecondaryButton } from '../../common';
import ServerSideService from '../../../services/server-side-service/server-side-service';

interface Props {
    buttonColor: 'light' | 'dark';
    buttonFill: 'fill' | 'outline';
    className?: string;
    addButtonPlusSign?: boolean;
    isSupport?: boolean;
    vin?: string;
    page: PageType;
    showAddModalOnly?: boolean;
    closeAddModal?: () => void;
    addVehicleClickEventName?: string;
    addVehicleSubmitEventName?: string;
    isTabs?: boolean;
}

export const AddVehicle = (props: Props) => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const { currentRoot } = new AppConfigurationService();
    const profileService = new ProfileService();
    const addVehicleContent = useAddVehicleContent();
    const vinSelectorContent = useVinSelectorContent();
    const addVehicleButtonRef = useRef<HTMLButtonElement>(null);
    const history = useHistory();
    const VEHICLE_DASHBOARD = findByAlias('VehicleDashboardView');
    const [isAddVehicleVisible, setIsAddVehicleVisible] = useState(false);
    const serverContext = useContext(ServerContext);
    const [fireEvents] = useAnalytics();
    const { showAddModalOnly, closeAddModal } = props;
    const modalName = showAddModalOnly
        ? 'add-vehicle-smart-tile'
        : 'add-vehicle-modal';
    const baseRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const [vin, setVin] = useState(props.vin || '');
    const inputFieldSubmitBtnref = useRef<HTMLButtonElement>(null);
    const [nickName, setNickName] = useState('');
    const nicknameRef = useRef<HTMLInputElement>(null);
    const profile = useUserProfile();
    const addPluSign = props.addButtonPlusSign ? ' + ' : '';
    useEffect(() => {
        if (isAddVehicleVisible && nickName && nicknameRef.current)
            nicknameRef.current.focus();
    }, [nickName, isAddVehicleVisible]);

    const onCloseAddVehicle = () => {
        fireEvents(
            'dyf-gvt-landing-add-vehicle-popup-cancel-cta-onclick',
            undefined,
            undefined,
            false
        );
        setIsAddVehicleVisible(false);
        closeAddModal && closeAddModal();
        if (!showAddModalOnly && addVehicleButtonRef) {
            addVehicleButtonRef.current?.focus();
        }
    };

    const toggleUpdateBtnState = (state: string) => {
        const updateButton = document.querySelector(
            '.modal-footer .primary-button'
        ) as HTMLButtonElement;
        if (state === 'disabled') {
            updateButton.disabled = true;
            updateButton.classList.add('disabled');
        } else {
            updateButton.disabled = false;
            updateButton.classList.remove('disabled');
        }
    };

    const onEscapeKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const isEscapeClicked = event.key === KEYBOARD_KEYS.ESC;
        if (isEscapeClicked) {
            onCloseAddVehicle();
        }
    };

    const addVehicle = async (vin: string) => {
        fireEvents(
            props.addVehicleSubmitEventName
                ? props.addVehicleSubmitEventName
                : 'add-vehicle-submit-event',
            undefined,
            undefined,
            false
        );
        let apiError = false;
        setErrorMsg(undefined);
        const response = await profileService.addVehicle(vin, nickName);
        if (response.status !== 200 || response.error) {
            apiError = true;
            !response.error &&
                notify(
                    `${addVehicleContent?.incorrectVin} message: ${response.message} for vin: ${vin} for api path: ${response.config.url}`
                );
            setErrorMsg(
                response.error
                    ? addVehicleContent?.duplicateVinMessage
                    : addVehicleContent?.incorrectVin
            );
        } else if (addVehicleContent?.bannerMessage) {
            const vehicleName =
                response.vehicle.nickName ||
                response.vehicle.modelYear + response.vehicle.modelName;
            const addedVehicleBannerMessage = addVehicleContent.bannerMessage.replace(
                '$Vehicle_Name',
                vehicleName
            );
            sessionStorage.setItem(
                'addedVehicleBannerData',
                JSON.stringify({
                    bannerMessage: addedVehicleBannerMessage,
                    dismissAriaLabel: addVehicleContent.dismissAriaLabel,
                })
            );
        }
        if (!apiError) {
            profileService.persistVehicleSelection(profile?.profile.email, vin);
            setIsAddVehicleVisible(false);
            const {
                current3LetterCountryCode,
                currentLanguageRegionCode,
            } = ServiceHandler.AppConfigurationService;
            const cachedURL = `${DSL_BASE_URL +
                DSL_API_PATH.PROFILE_WITH_VEHICLES}?country=${current3LetterCountryCode.toUpperCase()}&locale=${currentLanguageRegionCode}`;
            new CacheService().evictFromCache(SESSION_KEY_API_CACHE, cachedURL);
            if (
                window.location.pathname ===
                currentRoot.concat(VEHICLE_DASHBOARD.substring(1))
            ) {
                window.location.reload();
            } else if (props.page === 'OnboardingWithVin') {
                window.location.reload();
            } else if (props.page === 'OnboardingNoVin') {
                history.push(
                    (findByAlias('OnboardingView')[1] as string).replace(
                        ':vin',
                        vin
                    )
                );
                window.location.reload();
            } else if (props.page === 'SupportRecallsPage') {
                window.location.href = `${baseRoot}${findByAlias(
                    'RecallsPage'
                ).concat(`?vin=${vin}`)}`;
            } else if (props.page === 'Owner Manuals') {
                window.location.href = `${baseRoot}${findByAlias(
                    'OwnerManualPage'
                )}`;
            } else if (props.page === 'Owner Manuals Details') {
                window.location.href = `${baseRoot}${findByAlias(
                    'OwnerManualWithVin'
                )}`;
            } else {
                profileService.request().then(profile => {
                    serverContext.profile = profile;
                    history.push(VEHICLE_DASHBOARD.concat(`?vin=${vin}`));
                });
            }
        }
    };

    const onUpdate = () => {
        inputFieldSubmitBtnref.current?.click();
    };

    const onShowAddVehicle = () => {
        fireEvents(
            props.addVehicleClickEventName
                ? props.addVehicleClickEventName
                : 'add-vehicle-onclick-event',
            undefined,
            undefined,
            false
        );
        setVin(props.vin || '');
        setNickName('');
        toggleUpdateBtnState('active');
        setIsAddVehicleVisible(true);
        setErrorMsg(undefined);
    };

    const AddVehicleContent = () => {
        return (
            <>
                {addVehicleContent && (
                    <div className="text-center">
                        <h2 className="add-vehicle-header">
                            {addVehicleContent.addVehicleLabel.toUpperCase()}
                        </h2>
                        <div className="add-vehicle-description">
                            <span>
                                {addVehicleContent.addVehicleDescription}
                            </span>
                        </div>
                        {addVehicleContent.addVehicleVinHeader && (
                            <div className="add-vehicle-vin-header">
                                <h3>{addVehicleContent.addVehicleVinHeader}</h3>
                                {!addVehicleContent?.hideTooltip && (
                                    <InfoIconToolTip
                                        tooltipInfoIconClass={'dark'}
                                        tooltipHeader={
                                            addVehicleContent?.tooltipHeader
                                        }
                                        tooltipContent={
                                            addVehicleContent?.tooltipContent
                                        }
                                    />
                                )}
                            </div>
                        )}
                        <div className="user-input-container">
                            {vinSelectorContent && (
                                <VinInput
                                    ref={inputFieldSubmitBtnref}
                                    name="add-vehicle"
                                    vinSelectorContent={vinSelectorContent}
                                    vin={vin}
                                    setParentVin={setVin}
                                    errorMessage={errorMsg}
                                    setParentError={setErrorMsg}
                                    onSubmit={addVehicle}
                                    focusOnLoad={true}
                                    placeholder={
                                        addVehicleContent?.enterVinPlaceholder
                                    }
                                />
                            )}
                        </div>
                        <div
                            className="user-input-container"
                            style={{
                                marginTop: '1rem',
                            }}
                        >
                            <label
                                htmlFor="add-vehicle-nickname"
                                className="user-input-label"
                            >
                                {addVehicleContent.nickname}
                            </label>
                            <input
                                ref={nicknameRef}
                                id="add-vehicle-nickname"
                                className="user-input"
                                value={nickName}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setNickName(
                                        ServerSideService.isClientSide()
                                            ? filterXSS(e.currentTarget.value)
                                            : ''
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        addVehicleContent && (
            <div
                onKeyDown={onEscapeKeyPress}
                className={[
                    'add-vehicle-container',
                    'add-vehicle-container-hidden',
                    `${props.className ? props.className : ''}`,
                ]
                    .join(' ')
                    .trim()}
            >
                {!showAddModalOnly && !props.isTabs ? (
                    <PrimaryButton
                        color={props.buttonColor}
                        fill={props.buttonFill}
                        chevron={false}
                        onClick={() => onShowAddVehicle()}
                        ariaLabel={'Add Vehicle'}
                        ref={addVehicleButtonRef}
                    >
                        {!props.isSupport && window.innerWidth < 500
                            ? ` + `
                            : `${addPluSign} ${addVehicleContent.addVehicleButton}`}
                    </PrimaryButton>
                ) : (
                    <SecondaryButton onClick={() => onShowAddVehicle()}>
                        Add New Vehicle
                    </SecondaryButton>
                )}
                <Modal
                    name={modalName}
                    role="dialog"
                    aria-label="add a vehicle"
                    onClose={onCloseAddVehicle}
                    primaryBtnLabel={addVehicleContent.btnUpdate}
                    secondaryBtnLabel={addVehicleContent.btnCancel}
                    onPrimaryBtnClick={onUpdate}
                    onSecondaryBtnClick={onCloseAddVehicle}
                    isVisible={showAddModalOnly ? true : isAddVehicleVisible}
                >
                    <AddVehicleContent />
                </Modal>
            </div>
        )
    );
};
