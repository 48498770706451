import React, { useEffect, useState, useRef } from 'react';
import { ChooseRebates } from '../../../../../services/rebate-choose-method-service/choose-method-service';
import ServiceHandler from '../../../../../services/service-handler';
import { useChooseMethodContent } from '../../hooks/redeem-rebates-steps/use-choose-method-content';
import { PrimaryButton } from '../../../../common';
import { RebatesDisclaimerFragment } from '../../../../../models/experiencefragments/rebates-disclaimer';
import { useExperienceContent } from '../../../../../hooks/use-server-content';
import { ActivityIndicator } from '../../../../common/activity-indicator/activity-indicator';
import { RedeemFormData } from '../redeem-rebates-steps';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';

import './choose-method.scss';

interface Props {
    redeemFormData: RedeemFormData;
    setFormData(data: RedeemFormData): void;
    setCurrentStep(stepNumber: number): void;
}
interface OfferDisplayDataProps {
    grouporder: any;
    offers: any;
    thereAreRewardPointOffers: boolean;
    selectedRebateData: any;
    chooseMethodContent: any;
    disclaimerSet: any;
    handleClick: any;
    handleClear: any;
    disclaimerContent: any;
    getDiscText: any;
    DOLLAR: any;
}

const OfferDisplayData = ({
    grouporder,
    offers,
    thereAreRewardPointOffers,
    selectedRebateData,
    chooseMethodContent,
    disclaimerSet,
    handleClick,
    handleClear,
    disclaimerContent,
    getDiscText,
    DOLLAR,
}: OfferDisplayDataProps) => {
    const transformeddisplayoffers: any[] = [];
    return (
        <div>
            {grouporder.forEach((groupTitle: string) => {
                const copyOfNonCreditIndOffers = offers;
                const groupRewardsPointsOffers = copyOfNonCreditIndOffers.filter(
                    (i: {
                        rebateGroupTitle: string;
                        rebateRewardsPointsOffer: string;
                    }) =>
                        i.rebateGroupTitle === groupTitle &&
                        i.rebateRewardsPointsOffer === 'Y'
                );

                if (groupRewardsPointsOffers.length > 0) {
                    thereAreRewardPointOffers = true;
                }
                transformeddisplayoffers.push({
                    rebateGroupTitle: groupTitle,
                    rewardPointsOffers: thereAreRewardPointOffers,
                    nonCreditIndividualOffers: offers.filter(
                        (i: { rebateGroupTitle: string }) =>
                            i.rebateGroupTitle === groupTitle
                    ),
                });
                selectedRebateData.push({
                    key: groupTitle,
                    value: '',
                    pdf: '',
                    disclaimerFile: '',
                    rebatePointsOrAmount: '',
                    rebateOfferName: '',
                    isPointsClicked: false,
                    rebateOfferID: 0,
                });
            })}
            {transformeddisplayoffers.map((offers, index) => {
                return (
                    <section
                        key={offers.rebateGroupTitle}
                        className={`section${index}`}
                    >
                        <div className="title-button">
                            <p className="group-titles">
                                {offers.rebateGroupTitle}
                            </p>

                            <div className="clear-button">
                                <PrimaryButton
                                    color="dark"
                                    fill="outline"
                                    chevron={false}
                                    ariaLabel={
                                        chooseMethodContent.clearButtonText
                                    }
                                    onClick={handleClear(
                                        offers.rebateGroupTitle,
                                        index
                                    )}
                                >
                                    {chooseMethodContent.clearButtonText}
                                </PrimaryButton>
                            </div>
                        </div>
                        {disclaimerSet.clear()}

                        <div className="choose-rebates-table">
                            <div className="choose-rebates-table-column">
                                <h3 className="ford-pass-rewards-point">
                                    {chooseMethodContent.rewardPointsSubtitle}
                                </h3>

                                {offers.nonCreditIndividualOffers.map(
                                    (offer: {
                                        rebateGroupTitle: any;
                                        rewardsPointsAmount: string;
                                        rebateName: string;
                                        rebateOfferPDFFile: string;
                                        rebateOfferDisclaimerFile: string;
                                        rebateOfferID: number;
                                    }) => {
                                        disclaimerSet.add(
                                            offer.rebateOfferDisclaimerFile
                                        );
                                        if (
                                            offer.rebateGroupTitle ===
                                            offers.rebateGroupTitle
                                        ) {
                                            return (
                                                <div
                                                    key={offer.rebateOfferID}
                                                    className={`individual-rebate-offers ${offer.rebateGroupTitle}`}
                                                >
                                                    <label className="debit-card-radio-button-label">
                                                        <input
                                                            type="radio"
                                                            name={`rebates${index}`}
                                                            value={
                                                                offer.rewardsPointsAmount +
                                                                ' ' +
                                                                offer.rebateName
                                                            }
                                                            onChange={handleClick(
                                                                offer.rebateGroupTitle,
                                                                offer.rebateOfferPDFFile,
                                                                offer.rebateOfferDisclaimerFile,
                                                                offer.rewardsPointsAmount,
                                                                offer.rebateName,
                                                                true,
                                                                offer.rebateOfferID
                                                            )}
                                                        />
                                                        <span className="rebate-names">
                                                            {offer.rewardsPointsAmount +
                                                                ' ' +
                                                                offer.rebateName}{' '}
                                                        </span>
                                                    </label>
                                                </div>
                                            );
                                        }
                                    }
                                )}
                            </div>
                            <div className="choose-rebates-table-column">
                                <h3 className="ford-pass-rewards-point">
                                    {chooseMethodContent.debitCardSubtitle}
                                </h3>
                                {offers.nonCreditIndividualOffers.map(
                                    (offer: {
                                        rebateGroupTitle: any;
                                        debitCardAmount: string;
                                        rebateName: string;
                                        rebateOfferPDFFile: string;
                                        rebateOfferDisclaimerFile: string;
                                        rebateOfferID: number;
                                    }) => {
                                        if (
                                            offer.rebateGroupTitle ===
                                            offers.rebateGroupTitle
                                        ) {
                                            return (
                                                <>
                                                    <div className="individual-rebate-offers">
                                                        <label className="debit-card-radio-button-label">
                                                            <input
                                                                type="radio"
                                                                name={`rebates${index}`}
                                                                value={
                                                                    DOLLAR +
                                                                    ' ' +
                                                                    offer.debitCardAmount +
                                                                    '.00' +
                                                                    ' ' +
                                                                    offer.rebateName
                                                                }
                                                                onChange={handleClick(
                                                                    offer.rebateGroupTitle,
                                                                    offer.rebateOfferPDFFile,
                                                                    offer.rebateOfferDisclaimerFile,
                                                                    offer.debitCardAmount.toString(),
                                                                    offer.rebateName,
                                                                    false,
                                                                    offer.rebateOfferID
                                                                )}
                                                            />
                                                            <span className="rebate-names">
                                                                {DOLLAR +
                                                                    ' ' +
                                                                    offer.debitCardAmount +
                                                                    '.00' +
                                                                    ' ' +
                                                                    offer.rebateName}{' '}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </>
                                            );
                                        }
                                    }
                                )}
                            </div>
                        </div>
                        {disclaimerContent && disclaimerSet && (
                            <div>
                                <small className="legal-regular">
                                    <div
                                        className="disclaimer-text"
                                        dangerouslySetInnerHTML={{
                                            __html: getDiscText(
                                                disclaimerContent,
                                                disclaimerSet
                                            ),
                                        }}
                                    ></div>
                                </small>
                            </div>
                        )}
                    </section>
                );
            })}
        </div>
    );
};

export const ChooseMethod = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const rebatesStepName = {
        rebatesStepName: 'choose method',
    };
    useAnalytics(['redeem-rebates-generic'], undefined, rebatesStepName);
    const DOLLAR = '$';
    const selectedDate: any = props.redeemFormData.serviceDate;
    const date = new Date(selectedDate).toLocaleDateString('zh-Hans-CN');
    const dateFormatUS = new Date(selectedDate).toLocaleDateString('en-US');
    const { brand } = new AppConfigurationService();
    const creditDealer: string = props.redeemFormData.selectedDealerDetails
        ?.creditDealer
        ? 'Y'
        : 'N';
    const oarDealer: string = props.redeemFormData.selectedDealerDetails
        ?.oarDealer
        ? 'Y'
        : 'N';

    const primaryButtonRef = useRef<HTMLButtonElement>(null);
    const chooseMethodContent = useChooseMethodContent();
    const [disclaimerContent] = useExperienceContent<RebatesDisclaimerFragment>(
        'rebates',
        'RebatesDisclaimers',
        'rebates'
    );

    const [
        rebateOfferServiceProviders,
        setRebateOfferServiceProviders,
    ] = useState<ChooseRebates>();

    const group1: any = new Set(
        rebateOfferServiceProviders?.nonCreditIndividualOffers.map(
            item => item.rebateGroupTitle
        )
    );
    const group2: any = new Set(
        rebateOfferServiceProviders?.fordCreditIndividualOffers.map(
            item => item.rebateGroupTitle
        )
    );
    const group3: any = new Set(
        rebateOfferServiceProviders?.lincolnCreditIndividualOffers.map(
            item => item.rebateGroupTitle
        )
    );
    const group4: any = new Set(
        rebateOfferServiceProviders?.quicklaneIndividualOffers.map(
            item => item.rebateGroupTitle
        )
    );
    const disclaimerSet: any = new Set();
    const noncreditoffers: any =
        rebateOfferServiceProviders?.nonCreditIndividualOffers;
    const fordcreditoffers: any =
        rebateOfferServiceProviders?.fordCreditIndividualOffers;
    const lincolncreditoffers: any =
        rebateOfferServiceProviders?.lincolnCreditIndividualOffers;
    const quicklaneOffers: any =
        rebateOfferServiceProviders?.quicklaneIndividualOffers;
    const thereAreRewardPointOffers = false;
    const selectedRebateData: any[] = [];
    const disclaimercount: any = {};
    let disctext = '<div>';
    const [noRebate, setNoRebate] = useState(false);
    const [loading, setLoading] = useState(false);
    const startAgain = () => {
        props.setCurrentStep(1);
    };
    const handleClick = (
        title: string,
        pdf: string,
        disclaimerFile: string,
        rebateValue: string,
        rebateName: string,
        ispointsselected: boolean,
        offerID: number
    ) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // Payment method click analytics
        fireEvents(
            ['choose-method-cta-onclick-event'],
            'choose-method-cta-onclick-event',
            {
                chooseMethodCTAContext: 'radiopaymentoptions',
            },
            false
        );
        selectedRebateData.forEach(grp => {
            if (grp.key === title) {
                grp.key = title;
                grp.value = event.target.value;
                grp.pdf = pdf;
                grp.disclaimerFile = disclaimerFile;
                grp.rebatePointsOrAmount = rebateValue;
                grp.rebateOfferName = rebateName;
                grp.isPointsClicked = ispointsselected;
                grp.rebateOfferID = offerID;
            }
        });
    };
    const handleClear = (grptitle: string, inx: number) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.preventDefault();
        const selector = `.section${inx}`;
        const divElement = event.target.closest(selector);
        const radioInput = divElement?.querySelectorAll('input');
        if (radioInput) {
            radioInput.forEach(i => {
                i.checked = false;
            });
        }
        selectedRebateData.forEach(grp => {
            if (grp.key === grptitle) {
                grp.key = grptitle;
                grp.value = '';
                grp.pdf = '';
                grp.disclaimerFile = '';
                grp.rebatePointsOrAmount = '';
                grp.rebateOfferName = '';
                grp.isPointsClicked = false;
                grp.rebateOfferID = 0;
            }
        });
    };
    let rebateDataValue = 0;
    const handleContinue = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Continue CTA click analytics
        fireEvents(
            ['choose-method-cta-onclick-event'],
            'choose-method-cta-onclick-event',
            {
                chooseMethodCTAContext: 'continue',
            },
            false
        );

        selectedRebateData.forEach(grp => {
            if (grp.value !== '') {
                rebateDataValue = 1;
            }
        });
        if (rebateDataValue === 0) {
            setNoRebate(true);
        } else {
            setNoRebate(false);
            props.setFormData({
                ...props.redeemFormData,
                rebateData: selectedRebateData,
            });
            props.setCurrentStep(4);
        }
    };

    useEffect(() => {
        const errorDiv = document.getElementById('error');
        if (errorDiv) {
            errorDiv.scrollIntoView();
            window.scrollTo(0, window.scrollY - 84);
        }
    }, [noRebate]);

    useEffect(() => {
        setLoading(true);
        ServiceHandler.ChooseMethodService.getRebateOfferService(
            props.redeemFormData.selectedDealerDetails?.dealerCode,
            date,
            props.redeemFormData.vin,
            oarDealer,
            creditDealer,
            brand === 'ford' ? 'O' : 'L'
        )
            .then(offerserviceProvider => {
                setRebateOfferServiceProviders(offerserviceProvider);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const getDiscText = (
        disclaimerContent: RebatesDisclaimerFragment,
        disclaimerSet: any
    ) => {
        disctext = ' ';
        disclaimerSet.forEach((disclaimer: string) => {
            {
                disclaimerContent.categorySection.categoryList.forEach(disc => {
                    if (disc.disclaimerTitle === disclaimer) {
                        if (disclaimercount[disclaimer] != 1) {
                            disctext = disctext.concat(disc.disclaimerDesc);
                        }
                        disclaimercount[disclaimer] = 1;
                    }
                });
            }
            disctext = disctext.concat('</div>');
        });
        return disctext;
    };

    if (loading === true) {
        return <ActivityIndicator className="full-height" />;
    }
    return (
        chooseMethodContent && (
            <>
                <form className="choose_rebates_form" onSubmit={handleContinue}>
                    <div className="rebate-offers-page">
                        <div className="choose-method-page-description">
                            {chooseMethodContent.chooseMethodDescription}
                        </div>
                        <div className="choose-method-tip-text">
                            {chooseMethodContent.tipText}
                        </div>
                        <div className="choose-method-dealer-info-text">
                            {chooseMethodContent.dealerInformationText}
                            <span className="text-bold">
                                <b>
                                    {' '}
                                    {
                                        props.redeemFormData
                                            .selectedDealerDetails?.dealerName
                                    }{' '}
                                </b>
                            </span>
                            on
                            <span className="text-bold">
                                <b> {dateFormatUS}</b>
                            </span>{' '}
                            for your{' '}
                            <span className="text-bold">
                                <b>
                                    {' '}
                                    {props.redeemFormData.year}{' '}
                                    {props.redeemFormData.make}{' '}
                                    {props.redeemFormData.model}
                                    {'.'}
                                </b>
                            </span>
                        </div>
                        {noRebate && (
                            <div
                                id="error"
                                className="no-rebates-error-message"
                            >
                                {chooseMethodContent.chooseMethodErrorMessage}
                            </div>
                        )}
                        {group1.size > 0 ||
                        group2.size > 0 ||
                        group3.size > 0 ||
                        group4.size > 0 ? (
                            <>
                                {group1.size > 0 &&
                                    OfferDisplayData({
                                        grouporder: group1,
                                        offers: noncreditoffers,
                                        thereAreRewardPointOffers,
                                        selectedRebateData,
                                        chooseMethodContent,
                                        disclaimerSet,
                                        handleClick,
                                        handleClear,
                                        disclaimerContent,
                                        getDiscText,
                                        DOLLAR: DOLLAR,
                                    })}
                                {group2.size > 0 &&
                                    OfferDisplayData({
                                        grouporder: group2,
                                        offers: fordcreditoffers,
                                        thereAreRewardPointOffers,
                                        selectedRebateData,
                                        chooseMethodContent,
                                        disclaimerSet,
                                        handleClick,
                                        handleClear,
                                        disclaimerContent,
                                        getDiscText,
                                        DOLLAR: DOLLAR,
                                    })}
                                {group3.size > 0 &&
                                    OfferDisplayData({
                                        grouporder: group3,
                                        offers: lincolncreditoffers,
                                        thereAreRewardPointOffers,
                                        selectedRebateData,
                                        chooseMethodContent,
                                        disclaimerSet,
                                        handleClick,
                                        handleClear,
                                        disclaimerContent,
                                        getDiscText,
                                        DOLLAR: DOLLAR,
                                    })}
                                {group4.size > 0 &&
                                    OfferDisplayData({
                                        grouporder: group4,
                                        offers: quicklaneOffers,
                                        thereAreRewardPointOffers,
                                        selectedRebateData,
                                        chooseMethodContent,
                                        disclaimerSet,
                                        handleClick,
                                        handleClear,
                                        disclaimerContent,
                                        getDiscText,
                                        DOLLAR: DOLLAR,
                                    })}
                                <div className="continue-button">
                                    <PrimaryButton
                                        ref={primaryButtonRef}
                                        color="dark"
                                        fill="fill"
                                        chevron={true}
                                        ariaLabel={
                                            chooseMethodContent.continueButtonText
                                        }
                                    >
                                        {chooseMethodContent.continueButtonText}
                                    </PrimaryButton>
                                </div>
                            </>
                        ) : (
                            <div className="error-display">
                                <p className="no-available-offers-error">
                                    {chooseMethodContent.noRebatesErrorMessage}
                                </p>
                                <div className="search-over-button">
                                    <PrimaryButton
                                        color={'dark'}
                                        fill={'outline'}
                                        chevron={brand === 'lincoln'}
                                        ariaLabel={
                                            chooseMethodContent.searchAgainButtonText ||
                                            'Go back and Search Again'
                                        }
                                        onClick={startAgain}
                                    >
                                        {chooseMethodContent.searchAgainButtonText ||
                                            'Search Again'}
                                    </PrimaryButton>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </>
        )
    );
};
