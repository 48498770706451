import {
    API_REQUEST_SOURCE_TYPE,
    SERVICE_DELIVERY_TYPE,
} from '../../../components/sections/owners-osb/osb-constant';
import { transformServiceResponse } from '../../../components/sections/owners-osb/osb-service-adapters/dealer-calendar-adapter';
import { VehicleData } from '../../../models/osb-model/osb-vehicle-step';
import { OSBAPIResponse } from '../service-orchestration/service-adapter';
import {
    ErrorResponse,
    transformErrorResponse,
} from '../../../components/sections/owners-osb/osb-utils';
import { DSLAPIService } from '../service-orchestration/service-dsl';
import {
    CalendarAvailabilityRequest,
    CalendarAvailabilityResponse,
} from '../../../models/osb-model/osb-calendar-step';
import { DealerCalendarDetails } from '../../../models/osb-model/dealer-calendar-details';
import { DSL_API_PATH } from '../../../constants';
import { Bookable } from '../../../hooks/owners-osb/use-all-osb-state';
import { OSBStep } from '../../../models/osb-model/osb-step';
import { ExtractValueType } from '../../../components/sections/owners-osb/osb-bookable-utils';

export class OSBDealerCalendarAvailabilityClient {
    public getCalendarInfo(args: {
        dealerCode: string;
        vehicleData: VehicleData;
        selectedServices?: string[];
        startDateTime?: string;
        endDateTime?: string;
        serviceDeliveryType?: string;
        requestSourceType?: string;
        bookable?: Bookable;
        DSLAPIVersion?: ExtractValueType<OSBStep, 'DSLAPIVersion'>;
    }) {
        if (args.bookable && args.DSLAPIVersion === 'V5') {
            return this.getByPostCalendarInfo(
                args.bookable,
                args.serviceDeliveryType
            );
        }

        const calendarAvailabilityRequest: CalendarAvailabilityRequest = {
            vehicle: args.vehicleData,
            delivery:
                args.serviceDeliveryType || SERVICE_DELIVERY_TYPE.WORKSHOP,
            source: args.requestSourceType || API_REQUEST_SOURCE_TYPE.WEB,
        };

        if (args.selectedServices && args.selectedServices.length > 0) {
            calendarAvailabilityRequest.selectedServices = args.selectedServices.join(
                ','
            );
        }

        if (args.startDateTime) {
            calendarAvailabilityRequest.startDateTime = args.startDateTime;
        }

        if (args.endDateTime) {
            calendarAvailabilityRequest.endDateTime = args.endDateTime;
        }

        return new Promise<OSBAPIResponse<DealerCalendarDetails>>(
            (resolve, reject) => {
                const transformedResponse: OSBAPIResponse<DealerCalendarDetails> = {
                    value: {} as DealerCalendarDetails,
                    responseError: {} as ErrorResponse,
                };
                new DSLAPIService()
                    .callServiceAPIOnDealerCode<
                        CalendarAvailabilityRequest,
                        CalendarAvailabilityResponse
                    >(
                        calendarAvailabilityRequest,
                        args.dealerCode,
                        DSL_API_PATH.OSB_CALENDAR_AVAILABILITY
                    )
                    .then(response => {
                        try {
                            transformedResponse.value = transformServiceResponse(
                                response,
                                args.serviceDeliveryType
                            );
                            resolve(transformedResponse);
                        } catch (err) {
                            transformedResponse.responseError.errorMessage =
                                'DEALER_CALENDAR_AVAILABILITY_CLIENT_TRANSFORMATION_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    })
                    .catch(_error => {
                        transformedResponse.responseError = transformErrorResponse(
                            _error
                        );
                        reject(transformedResponse.responseError);
                        console.error(
                            'Rejected the promise - ',
                            transformedResponse.responseError
                        );
                    });
            }
        );
    }

    public getByPostCalendarInfo(
        bookable: Bookable,
        serviceDeliveryType: string | undefined
    ) {
        const { dealerCode, ...bookableWithoutDealerCode } = bookable; // FIXME: API doesnt work currently with dealer code in paylaod.
        return new Promise<OSBAPIResponse<DealerCalendarDetails>>(
            (resolve, reject) => {
                const transformedResponse: OSBAPIResponse<DealerCalendarDetails> = {
                    value: {} as DealerCalendarDetails,
                    responseError: {} as ErrorResponse,
                };
                new DSLAPIService()
                    .postServiceAPIOnDealerCode<
                        Omit<Bookable, 'dealerCode'>,
                        CalendarAvailabilityResponse
                    >(
                        bookableWithoutDealerCode,
                        dealerCode,
                        DSL_API_PATH.OSB_CALENDAR_AVAILABILITYV5
                    )
                    .then(response => {
                        try {
                            transformedResponse.value = transformServiceResponse(
                                response,
                                serviceDeliveryType
                            );
                            resolve(transformedResponse);
                        } catch (err) {
                            transformedResponse.responseError.errorMessage =
                                'DEALER_CALENDAR_AVAILABILITY_CLIENT_TRANSFORMATION_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    })
                    .catch(_error => {
                        transformedResponse.responseError = transformErrorResponse(
                            _error
                        );
                        reject(transformedResponse.responseError);
                        console.error(
                            'Rejected the promise - ',
                            transformedResponse.responseError
                        );
                    });
            }
        );
    }
}
