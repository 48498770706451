import { useContent } from '../../../../hooks/use-server-content';

export interface VehicleHealthReportContentProps {
    headline: string;
    downloadLabel: string;
    scheduleReportLabel: string;
    backButtonLabel: string;
    backButtonTarget: string;
    reportCaption: string;
    reportDisclaimer: string;
    oilLifeRemainingHeadline: string;
    oilLifeStatusLabel: string;
    oilLifeEstimatedDateLabel: string;
    oilLifeEstimatedDistanceLabel: string;
    milesUnitLabel: string;
    kmUnitLabel: string;
    vehicleInformationStatus: string;
    vehicleMileage: string;
    vehicleNotFound: string;
    singularIssuesFound: string;
    pluralIssuesFound: string;
    reportStatus: string;
    reportAvailability: string;
    engineAndTransmissionAccordionHeadline: string;
    brakesAndSuspensionAccordionHeadline: string;
    restraintsAndDriverAssistanceAccordionHeadline: string;
    checkFluidsAndFilterAccordionHeadline: string;
    customerActionSubHeadline: string;
    warningSymptomSubHeadline: string;
    warningDescSubHeadline: string;
    eligibleSubheadline: string;
    eligibleDescription: string;
    eligibleWithUpgradeSubheadline: string;
    eligibleWithUpgradeDescription: string;
    expiredSubheadline: string;
    expiredDescription: string;
    ineligibleSubheadline: string;
    ineligibleDescription: string;
    vhrPageDisclaimer: string;
    goodIconAltText: string;
    warningIconAltText: string;
    vhrPageCTALabel: string;
    vhrPageCTAHref: string;
    vhrPageCTATargetBlank: boolean;
}
export const useVehicleHealthReportContent = (): VehicleHealthReportContentProps => {
    const [content, getValueByName] = useContent(
        'vehicles/vehicle-health-reports',
        'vehicle-health-report'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as VehicleHealthReportContentProps;
};
export default useVehicleHealthReportContent;
