import { useContent } from '../../../../hooks/use-server-content';

export interface OffersCouponContentProps {
    desktopImagePath: string;
    mobileImagePath: string;
    headline: string;
    subHeadline: string;
    description: string;
    ctaLabel: string;
    ctaAriaLabel: string;
    ctaHref: string;
}
export const useOffersCouponContent = (): OffersCouponContentProps => {
    const [content, getValueByName] = useContent(
        'vehicles/vehicle-health-reports',
        'offers-coupons'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as OffersCouponContentProps;
};
export default useOffersCouponContent;
