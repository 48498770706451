import React from 'react';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import {
    VhrLanding,
    VHRStatus,
} from '../../../services/vhr-service/vhr-service';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
interface Props {
    issueText: string;
    issuesText: string;
    noIssuesText: string;
    goodIconPath: string;
    warningIconPath: string;
    vhrData: VhrLanding;
}
const VehicleChecklistVhr = (
    props: Props & VehicleAttributes & VehicleChecklistContent
) => {
    let icon;
    let issueText;
    const isVhrAvailable = props.vhrData?.vhrStatus === VHRStatus.AVAILABLE;
    const warningTotals = props.vhrData?.warningsTotal || 0;

    if (warningTotals === 0) {
        icon = props.goodIconPath;
        issueText = props.noIssuesText;
    } else if (warningTotals === 1) {
        icon = props.warningIconPath;
        issueText = warningTotals + ' ' + props.issueText;
    } else {
        icon = props.warningIconPath;
        issueText = warningTotals + ' ' + props.issuesText;
    }

    const ctaVinHref = (): string => {
        if (props.vin && props.dynamicCtaPath) {
            return props?.dynamicCtaPath
                ?.replace('%vin', props.vin)
                ?.replace(':vin', props.vin);
        }
        return props.staticCtaPath;
    };
    return (
        <>
            {isVhrAvailable && (
                <VehicleChecklist
                    description={issueText}
                    type={props.cardTypes}
                    header={props.title}
                    ctaTitle={props.ctaTitle}
                    ctaPath={ctaVinHref()}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                    iconPath={icon}
                />
            )}
        </>
    );
};

export default VehicleChecklistVhr;
