import { useContent } from '../../../hooks/use-server-content';

export interface SyncVehicleSelectorProps {
    hideContent: boolean;
    title: string;
    subtitle: string;
    description: string;
    vehicleVinLabel: string;
    tooltipTitle: string;
    tooltipCloseButtonAriaLabel: string;
    tooltipContent: string;
    vehicleVinPlaceholder: string;
    ctaVinLabel: string;
    ctaVinAriaLabel: string;
    ctaVinHref: string;
    labelSeparator: string;
}

export const useSyncVehicleSelectorContent = (): SyncVehicleSelectorProps | null => {
    const [content, getValueByName] = useContent(
        'sync',
        'sync-vehicle-selector'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hideContent || !contentFragment.title) {
            contentFragment = null;
        }
    }

    return contentFragment as SyncVehicleSelectorProps;
};
