import React, { useContext } from 'react';
import {
    Coupons,
    CouponsDetail,
    ViewTemplate,
} from '../../components/sections';
import { useLocation } from 'react-router-dom';
import ServerContext from '../../../src/contexts/serverContext';
import ServerSideService from '../../../src/services/server-side-service/server-side-service';

export const CouponsView = () => {
    const location = useLocation();
    const page: any = 'Coupons and Offers';
    const context = useContext(ServerContext);
    const couponId = ServerSideService.isServerSide()
        ? context.urlParams.couponId
        : new URLSearchParams(location?.search).get('couponId');
    return (
        <ViewTemplate page={page} hideSearchBar={true} hideLinksList={true}>
            {couponId ? <CouponsDetail /> : <Coupons />}
        </ViewTemplate>
    );
};
export default CouponsView;
