import React from 'react';
import { BatteryFillLevel } from '../../../models/connected-vehicle';
import FMConnectedVehicle from '../fm-connected-vehicle/fm-connected-vehicle';
import { ChargeLevelContent } from '../../../models/experiencefragments/connected-vehicle-bar';

interface Props {
    batteryFillLevel: BatteryFillLevel;
}

const FMConnectedBatteryCharge = (props: Props & ChargeLevelContent) => {
    const chargeLevel = props.batteryFillLevel?.value;
    const color = chargeLevel > 25 ? 'green' : 'red';
    return (
        <>
            {chargeLevel != null && (
                <FMConnectedVehicle
                    className="fm-battery-charge"
                    header={props.title}
                    iconPath={props.fullIconPath}
                    number={`${Math.round(chargeLevel)}%`}
                    progressBarPercentage={chargeLevel}
                    progressBarColor={color}
                    progressBarAria={'battery-charge-percent'}
                    showProgressBar={true}
                />
            )}
        </>
    );
};

export default FMConnectedBatteryCharge;
