import React from 'react';
import './ute-list-data.scss';

export interface UteListItem {
    label: string;
    value: string;
}

type Style = 'default' | 'success' | 'error' | 'configured';

export interface listOption {
    style?: Style;
    class?: string;
}

export const UteListData = (props: {
    //Do we need the list to be in array format or it will be object - karthik
    items: UteListItem[];
    options?: listOption;
    editCTA: string;
}) => {
    const { items, options } = props;
    return (
        <section
            className={`ute-data-list ${options?.class || ''}`}
            data-testid="ute-data-list"
        >
            {items?.map((element: UteListItem, index: number) => {
                return (
                    <div className={`row ${options?.style || ''}`} key={index}>
                        <span className="key"> {element.label}</span>
                        <span className="value">{element.value}</span>
                    </div>
                );
            })}
        </section>
    );
};
