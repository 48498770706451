import React from 'react';
import { BreadcrumbWrapperReimagine } from '../../../../common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';

interface CouponDetailBreadcrumbProps {
    PrimarySubcategory: string;
    currentPage: string;
    isWebview: boolean;
    currentRoot: string;
}

interface CouponDetailLandingBreadCrumbProps {
    PrimarySubcategory: string;
    currentRoot: string;
}

const CouponDetailLandingBreadCrumb: React.FC<CouponDetailLandingBreadCrumbProps> = ({
    PrimarySubcategory,
    currentRoot,
}) => {
    const handleClick = () => {
        window.location.href = `${currentRoot}service-maintenance/coupons-offers-rebates/`;
    };

    return (
        <>
            {PrimarySubcategory != 'all-savings' && (
                <>
                    <span
                        onClick={handleClick}
                        className="breadcrumb-link-ellipses-expanded"
                        aria-label={`Click to goto all offers, rebates and coupons page`}
                    >
                        All Offers, Rebates & Coupons
                    </span>
                    <span className="back-slash">{`/`}</span>
                </>
            )}
        </>
    );
};

const CouponDetailBreadcrumb: React.FC<CouponDetailBreadcrumbProps> = ({
    PrimarySubcategory,
    currentPage,
    isWebview,
    currentRoot,
}) => {
    return (
        <div>
            {isWebview ? (
                ''
            ) : (
                <BreadcrumbWrapperReimagine
                    currentPage={currentPage}
                    hashId="support"
                    hideFirstBreadcrumb={isWebview}
                    title={
                        isWebview ? (
                            ''
                        ) : (
                            <CouponDetailLandingBreadCrumb
                                PrimarySubcategory={PrimarySubcategory}
                                currentRoot={currentRoot}
                            />
                        )
                    }
                />
            )}
        </div>
    );
};

export default CouponDetailBreadcrumb;
