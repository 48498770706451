import React, { useEffect, useState } from 'react';
import './preferred-dealer.scss';
import { usePreferredDealer } from '../../vehicle-health-report/hooks/use-preferred-dealer';
import phoneIcon from '../../../../assets/call.svg';
import locationIcon from '../../../../assets/location.svg';
import ServerSideService from '../../../../services/server-side-service/server-side-service';

interface Props {
    headline: string;
    subheadline: string;
}

export const PreferredDealer = (props: Props) => {
    const userSelectedVin = ServerSideService.isClientSide()
        ? filterXSS(window?.sessionStorage?.getItem('selectedVin') || '')
        : '';
    const preferredDealerData = usePreferredDealer(userSelectedVin)
        ?.dealerResultList[0];
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);

    const handleResize = () => setIsMobile(window.innerWidth <= 780);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <section className="preferred-dealer-section">
                <div className="dealer-content-headline">
                    <div className="title">{props.headline}</div>
                    <div
                        className="subtitle"
                        dangerouslySetInnerHTML={{
                            __html: props.subheadline,
                        }}
                    />
                </div>
                <div className="dealer-information">
                    <div className={'dealer-rating'}>
                        <div className="rating">
                            {preferredDealerData?.dealerName}
                        </div>
                    </div>
                    <div className={'dealer-details-wrapper'}>
                        <div className={'dealer-address'}>
                            <span className={'space'}></span>
                            <span className={'details'}>
                                <img
                                    className={'icon'}
                                    src={locationIcon}
                                ></img>
                                <span className={'wrapper'}>
                                    {preferredDealerData?.streetAddress}
                                    <br />
                                    {preferredDealerData?.city},{' '}
                                    {preferredDealerData?.state}
                                    <br />
                                </span>
                            </span>
                        </div>
                        <div className={'dealer-phone-number'}>
                            <span className={'space'}></span>
                            <span className={'details'}>
                                <img className={'icon'} src={phoneIcon}></img>
                                <span className={'wrapper'}>
                                    {isMobile ? (
                                        <a
                                            href={`tel:${preferredDealerData?.phone}`}
                                        >
                                            {preferredDealerData?.phone}
                                        </a>
                                    ) : (
                                        <p>{preferredDealerData?.phone}</p>
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className={'dealer-buttons'}></div>
                    </div>
                </div>
                <div className="dealer-location-map"></div>
            </section>
        </>
    );
};
