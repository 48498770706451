import { useContent } from '../../../../hooks/use-server-content';

export declare type FAB_VARIANT = 'chat' | 'scrollToTop' | 'none';
export declare type SCROLL_POSITION = 'quarter' | 'half' | 'threeByForth';
export interface FabContentProps {
    hide: boolean;
    fabVariant: FAB_VARIANT;
    label: string;
    ariaLabel: string;
    showAfterScrollingPage: boolean;
    timeOut: number;
    scrolledPosition: SCROLL_POSITION;
}
export const useFabContent = (
    category = 'common',
    name = 'scroll-fab'
): FabContentProps => {
    const [content, getValueByName] = useContent(category, name);
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as FabContentProps;
};
