import React, { useEffect, useState } from 'react';
import { useHomeContent } from '../../../../views/home-page/hooks/use-home-content';
import './model-not-support.scss';
import { useLiveStreamViewContent } from '../../../../views/live-stream-view/hook/use-live-stream-view';
import UpcomingLivestream from '../../gvt/upcoming-livestream/upcoming-livestream';
import { useAllDyfEligibleModels } from '../../../../hooks/use-all-dyf-eligible-models';

export const ModelNotSupport = () => {
    const homeContent = useHomeContent();

    const [
        eligibleLivestreamVehicles,
        setEligibleLivestreamVehicles,
    ] = useState<string>('');
    const modelNotSupportedContent = useLiveStreamViewContent();

    const { hasLoaded, models } = useAllDyfEligibleModels();

    useEffect(() => {
        if (hasLoaded) {
            models.map(item => {
                if (item.eligibleLivestream) {
                    setEligibleLivestreamVehicles(
                        prev =>
                            prev +
                            `${item.modelYear} ${item.modelDisplayName} <br>`
                    );
                }
            });
        }
    }, [hasLoaded]);

    return (
        <>
            {homeContent &&
                modelNotSupportedContent &&
                eligibleLivestreamVehicles && (
                    <div className="model-not-supported-wrapper">
                        <UpcomingLivestream
                            modelNotSupportedContent={modelNotSupportedContent}
                            eligibleLivestreamVehicles={
                                eligibleLivestreamVehicles
                            }
                        />
                    </div>
                )}
        </>
    );
};
