import React from 'react';
import { Vehiclestatus } from '../../../models/connected-vehicle';
import { FuelLevelContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import FMConnectedVehicle from '../fm-connected-vehicle/fm-connected-vehicle';

interface Props {
    connectedVehicleInfo: Vehiclestatus;
}

const FMConnectedFuelLevel = (props: Props & FuelLevelContent) => {
    const { title, fullIconPath } = props;
    const { fuelLevel, distanceToEmpty } =
        props?.connectedVehicleInfo?.fuel || {};
    const uomDistance = props?.connectedVehicleInfo?.uomDistance;
    const color = fuelLevel > 25 ? 'green' : 'red';
    const distanceUnit = uomDistance === 1 ? ' MI' : ' KM';
    const distance =
        Math.round(distanceToEmpty).toLocaleString() +
        (distanceToEmpty?.toLocaleString() === '0' ? '' : distanceUnit);
    const isRender = distanceToEmpty && fuelLevel > 0 && distance;
    return (
        <>
            {isRender && (
                <FMConnectedVehicle
                    className="fm-current-fuel-level"
                    header={title}
                    iconPath={fullIconPath}
                    number={distance}
                    showProgressBar={true}
                    progressBarPercentage={fuelLevel.toFixed(2)}
                    progressBarColor={color}
                    progressBarAria={`${fuelLevel.toFixed(2)}%`}
                />
            )}
        </>
    );
};
export default FMConnectedFuelLevel;
