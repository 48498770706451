import { useExperienceContent } from '../../../../hooks/use-server-content';
import { ExperienceFragmentModel } from '../../../../services/content-service/content-service.interface';
import { ReactNode } from 'react';
interface Categories {
    category: string;
    activeCategorySideIcon: string;
    inactiveCategorySideIcon: string;
    categoryDesktopIcon: string;
}
export interface CategoriesProps extends ExperienceFragmentModel {
    hideBrowseByTopic: boolean;
    title: string;
    titleWithDisclaimer: ReactNode;
    subTitle: string;
    subTitleWithDisclaimer: ReactNode;
    disclaimer: string;
    categories: Categories[];
    prevLabel: string;
    nextLabel: string;
}
export const useBbtContent = (): CategoriesProps | null => {
    const [content] = useExperienceContent<CategoriesProps>(
        'landing',
        'browse-by-topic',
        'browsebytopic'
    );
    return content;
};
