import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';
import { VehicleRecallInfo } from '../../../models/osb-model/vehicle-recall-info';
import HttpService from '../../http-service/http-service';
import ServiceHandler from '../../service-handler';
import OsbUtilService from '../osb-util-service/osb-util-service';

function castToVehicleRecallInfo(results: any): VehicleRecallInfo {
    const vehicleRecallInfo: VehicleRecallInfo = {
        applicationName: results.applicationName,
        country: results.country,
        fsa: results.fsa,
        recalls: results.recalls,
        recallsCount: results.recallsCount,
        totalFsaCount: results.totalFsaCount,
        vehicleDetails: {
            make: results.vehicleDetails.make,
            model: results.vehicleDetails.model,
            vin: results.vehicleDetails.vin,
            year: results.vehicleDetails.year,
        },
    };
    return vehicleRecallInfo;
}
export default class OsbVehicleRecallService {
    async request(
        vin: string,
        osbThreeLetterCountryCode: string
    ): Promise<VehicleRecallInfo> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const currentLangScript =
            ServiceHandler.AppConfigurationService.currentLangScript;
        const headers = {
            vin: vin,
        };
        const params = {
            country: osbThreeLetterCountryCode,
            langscript: currentLangScript,
            language: appConfig.languageRegionCode.split('-')[0].toUpperCase(),
            region:
                appConfig.countryCode === 'mothersite'
                    ? 'gb'.toUpperCase()
                    : appConfig.countryCode.toUpperCase(),
        };
        const url = DSL_BASE_URL + DSL_API_PATH.RECALLS_VIN;

        return HttpService.get<VehicleRecallInfo>(url, true, {
            headers,
            params,
        })
            .then(response => {
                if (response.data) {
                    return castToVehicleRecallInfo(response.data);
                }
            })
            .catch(error => {
                return error;
            });
    }
}
