import React, { useCallback, useEffect, useState } from 'react';
import { Heading } from '../../../common/heading/heading';
import ServerSideService from '../../../../services/server-side-service/server-side-service';
import { Article } from '../../../../models/experiencefragments/category-view';
import PrimaryButton from '../../../common/primary-button/primary-button';
import { BrandUtil } from '../../../utils/brand-util/brand-util';

export const VehicleSmashCard = (props: Article) => {
    const [buttonFillStyleClass, setButtonFillStyleClass] = useState<
        'outline' | 'fill'
    >('outline');
    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 500px)').matches
            : false
    );
    const handleWindowResize = useCallback(() => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 500px)').matches
                : false
        );
    }, [setMobile]);
    useEffect(() => {
        const { isLincoln } = new BrandUtil();
        if (isLincoln) {
            setButtonFillStyleClass('fill');
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);
    return (
        <section className="smash-card" data-testid="smash-card-id">
            <div
                className="smash-card__image"
                style={{
                    backgroundImage: `url(${
                        process.env.REACT_APP_AEM_BASE_URL
                    }${
                        isMobile
                            ? props.mobileImagePath
                            : props.desktopImagePath
                    })`,
                }}
            >
                <span
                    role="img"
                    role-img-alt={props.imageAltText}
                    aria-label={props.imageAltText}
                />
            </div>
            <div className="smash-card__body">
                {props.title && (
                    <Heading type="h2" className="smash-card__body-title">
                        {props.title}
                    </Heading>
                )}
                {props.headline && (
                    <Heading type="h2" className="smash-card__body-headline">
                        {props.headline}
                    </Heading>
                )}

                <div className="smash-card__body-description">
                    {props.description}
                </div>
                {props.ctaLabel && (
                    <PrimaryButton
                        className="smash-card__body-cta"
                        color="dark"
                        fill={buttonFillStyleClass}
                        chevron={false}
                        href={props.ctaPath}
                        ariaLabel={props.ctaAriaLabel}
                        target={props.targetBlank ? '_blank' : ''}
                    >
                        {props.ctaLabel}
                    </PrimaryButton>
                )}
                {props.disclaimer && (
                    <div className="smash-card-disclaimer">
                        <p>{props.disclaimer}</p>
                    </div>
                )}
            </div>
        </section>
    );
};
