import React, { useEffect, useRef, useState } from 'react';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import { OsbMap } from '../../owners-osb/dealer-step/light-journey/osb-map';
import filterIcon from '../../../../assets/owners-osb/dealer-filter.png';
import listIcon from '../../../../assets/owners-osb/list_24.svg';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import {
    getObjectFromAEMJson,
    isPreferredDealer,
} from '../../owners-osb/osb-utils';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../price-calculator-constant';
import './service-price-calculator-mobile-map-dealer-details.scss';
import PrimaryButton from '../../../common/primary-button/primary-button';
import { FormatAddress } from '../../owners-osb/common/osb-common-components/osb-common-components';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import PcServiceRenderer from './pc-service-renderer';
import { DealerFilterOptions } from '../dealer-filter-options/dealer-filter-options';
import { useVehicleStep } from '../../../../hooks/owners-osb/use-vehicle-step';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import { useOSBStep } from '../../../../hooks/owners-osb/use-osb-step';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { triggerDealerSearchResultAnalytics } from '../analytics/price-calculator-analytics';
interface Props {
    dealers: DealerProfileInfo[];
    profile?: ProfileWithVehiclesResponse;
    priceCalculatorContent: ContentElements[];
    priceCalculatorResultContent: ContentElements[];
    preferredDealerIds: string[];
    isViewMoreButton: boolean;
    showMaxDealers: () => void;
    onListLinkClick: () => void;
    refreshDealerList?: (dealerProfiles: DealerProfileInfo[]) => void;
    goToServicesStep: (dealer: DealerProfileInfo) => void;
}

const ServicePriceCalculatorMobileMapDealerDetails = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { osbVehicleStep } = useVehicleStep();
    const { osbStep } = useOSBStep();
    const [landingTileDealerCode, setLandingTileDealerCode] = useState<string>(
        ''
    );
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);
    const mapRef = useRef<HTMLDivElement>(null);
    const [isMapFixed, setIsMapFixed] = useState(false);

    const toggleLightDealerFilterOptions = () => {
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const element = event.target as HTMLDivElement;
        const currentTile = Math.ceil(element.scrollLeft / element.offsetWidth);
        const dealerCode = props.dealers[currentTile].dealerCode;
        setLandingTileDealerCode(dealerCode);
    };

    function getDistanceValueAndMilesLabel(dealer: any) {
        const resultConstants = {
            distanceUnit:
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_UNIT,
                    props.priceCalculatorResultContent
                )?.toLowerCase() ?? '',
            milesLabel:
                getObjectFromAEMJson(
                    PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_FROM_YOU,
                    props.priceCalculatorResultContent
                ) ?? '',
        };

        const distanceValue =
            resultConstants.distanceUnit === 'miles'
                ? OsbUtilService.getDistanceInMiles(Number(dealer?.distance))
                : OsbUtilService.getDistanceInKm(Number(dealer?.distance));

        return `${distanceValue} ${resultConstants.milesLabel}` ?? '';
    }

    const handleListLinkClick = () => {
        props.onListLinkClick();
    };

    useEffect(() => {
        const vehicleInfoContainer = document.querySelector(
            '.spc-dealer-details-mobile-map-wrapper'
        );
        if (vehicleInfoContainer) {
            const topValue = vehicleInfoContainer.getBoundingClientRect().top;
            if (serverSideService.isClientSide()) {
                window.scrollTo({ top: topValue, behavior: 'smooth' });
            }
        }
    }, [osbVehicleStep.vehicleDetails]);

    useEffect(() => {
        const handleHorizontalScroll = () => {
            if (mapRef.current) {
                const { top } = mapRef.current.getBoundingClientRect();
                if (top <= 77) {
                    setIsMapFixed(true);
                } else {
                    setIsMapFixed(false);
                }
            }
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleHorizontalScroll);
            return () =>
                window.removeEventListener('scroll', handleHorizontalScroll);
        }
        triggerDealerSearchResultAnalytics(fireEvents);
    }, []);

    return (
        <div
            className="spc-dealer-details-mobile-map-wrapper"
            ref={mapRef}
            id="mobile-map"
        >
            <div className={`map-actions ${isMapFixed ? 'fixed' : ''}`}>
                {props.dealers.length > 1 && (
                    <button
                        className="filter-link"
                        onClick={toggleLightDealerFilterOptions}
                    >
                        <img src={filterIcon} alt="dealer-filter-icon" />
                        <span className="filters-text">
                            {' '}
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.FILTER_LABEL,
                                props.priceCalculatorResultContent
                            )}
                        </span>
                    </button>
                )}

                {props.dealers.length > 1 && (
                    <button className="list-link" onClick={handleListLinkClick}>
                        <img src={listIcon} alt="dealer-filter-icon" />
                        <span className="list-text">
                            {' '}
                            {getObjectFromAEMJson(
                                PRICE_CALCULATOR_RESULT_CONSTANTS.LIST_VIEW_LABEL,
                                props.priceCalculatorContent
                            )}
                        </span>
                    </button>
                )}
            </div>
            <OsbMap
                dealers={props.dealers}
                fullscreenControl={false}
                zoomControlPosition="RIGHT_TOP"
                selectedDealerCode={landingTileDealerCode}
                upShiftDegree={0.03}
            />

            {showDealerFilterOptions && (
                <div className="spc-desktop-map-filter-component">
                    <DealerFilterOptions
                        toggleLightDealerFilterOptions={
                            toggleLightDealerFilterOptions
                        }
                        priceCalculatorResultContent={
                            props.priceCalculatorResultContent
                        }
                        refreshDealerDetailsList={props.refreshDealerList}
                    />
                </div>
            )}

            {!showDealerFilterOptions && (
                <>
                    <div className="mobile-container">
                        <div
                            className="dealer-tiles-container"
                            onScroll={handleScroll}
                        >
                            {props.dealers.map((dealer, index) => (
                                <div
                                    key={index}
                                    data-dealercode={dealer.dealerCode}
                                    className="dealer-info-box-wrapper"
                                >
                                    {isPreferredDealer(
                                        osbVehicleStep.vehicleDetails?.vin,
                                        props.profile?.vehicles || [],
                                        dealer,
                                        osbStep.numberOfLeadingCharactersToStrip
                                    ) && (
                                        <div className="preferred-dealer-label">
                                            {getObjectFromAEMJson(
                                                PRICE_CALCULATOR_RESULT_CONSTANTS.PREFERRED_DEALER_LABEL,
                                                props.priceCalculatorContent
                                            )}
                                        </div>
                                    )}
                                    <div className="map-view-dealer-detail-section">
                                        <div
                                            className={`dealer-details-section dealer-${index}`}
                                        >
                                            <div className="dealer-info">
                                                <div className="dealer-name">
                                                    {index + 1}.&nbsp;&nbsp;
                                                    {dealer?.dealerName}
                                                </div>
                                                <div className="dealer-address-container">
                                                    <div className="address-container">
                                                        <div className="dealer-tile-address-text">
                                                            <FormatAddress
                                                                addressData={
                                                                    dealer
                                                                }
                                                                addressOrder={getObjectFromAEMJson(
                                                                    PRICE_CALCULATOR_RESULT_CONSTANTS.DEALER_ADDRESS_ORDER,
                                                                    props.priceCalculatorContent
                                                                )}
                                                                isDisplaySingleRow={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                        {dealer.distance >
                                                            0 && (
                                                            <div className="dealer-distance">
                                                                <span>
                                                                    {getObjectFromAEMJson(
                                                                        PRICE_CALCULATOR_RESULT_CONSTANTS.DISTANCE_LABEL,
                                                                        props.priceCalculatorContent
                                                                    )}
                                                                    :
                                                                </span>{' '}
                                                                {getDistanceValueAndMilesLabel(
                                                                    dealer
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dealer-service-section">
                                                <PcServiceRenderer
                                                    dealer={dealer}
                                                    priceCalculatorResultContent={
                                                        props.priceCalculatorResultContent
                                                    }
                                                    priceCalculatorContent={
                                                        props.priceCalculatorContent
                                                    }
                                                />
                                            </div>

                                            <div className="dealer-cta-container">
                                                <PrimaryButton
                                                    role="link"
                                                    color={'dark'}
                                                    fill={'fill'}
                                                    chevron={false}
                                                    aria-label="select-dealer-cta"
                                                    aria-labelledby="select dealer cta"
                                                    dataTestId={`SelectDealer-${dealer.dealerCode}`}
                                                    onClick={() =>
                                                        props.goToServicesStep(
                                                            dealer
                                                        )
                                                    }
                                                >
                                                    {getObjectFromAEMJson(
                                                        PRICE_CALCULATOR_RESULT_CONSTANTS.BOOK_NOW_BUTTON,
                                                        props.priceCalculatorResultContent
                                                    )}
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {props.isViewMoreButton && (
                        <div className="view-more-container-mobile">
                            <div
                                className="view-more-button-mobile"
                                onClick={props.showMaxDealers}
                            >
                                <div className="view-more-text-mobile">
                                    {getObjectFromAEMJson(
                                        PRICE_CALCULATOR_RESULT_CONSTANTS.VIEW_MORE,
                                        props.priceCalculatorResultContent
                                    )}
                                </div>
                                <FdsChevron direction={'down'} type="filled" />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ServicePriceCalculatorMobileMapDealerDetails;
