import React, { useCallback, useEffect, useState } from 'react';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import useUserProfile from '../../../../../hooks/use-user-profile';
import './vehicle-selector.scss';
import { useHistory } from 'react-router-dom';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../../../hooks/use-authentication';
import ProfileService from '../../../../../services/profile-service/profile-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { goToMyAccount } from '../../../../../views/vehicle-dashboard-view/vehicle-dashboard-view';
import { useVehiclePageContent } from '../../hooks/use-vehicle-page-content';
import { VehicleSelector as VehicleMenu } from '@own/fds-react';
import { BUTTONS } from '@own/fds-react/dist/types/vehicleSelector.types';
import { findByAlias } from '../../../../../routes';

interface Props {
    onTabClick?: any;
    onChange?: any;
    onSelectedVehicleChange?: (vehicleAttributes: VehicleAttributes) => void;
    dropDownPlaceholder?: string;
    currentSearchVehicle?: VehicleAttributes;
    disableDashboardRedirect?: boolean;
}
const VEHICLE_BUTTONS: BUTTONS = {
    ADD: 'Add Vehicle',
    CHANGE: 'Change Vehicle',
};

export const VehicleSelector = (props: Props) => {
    const profileService = new ProfileService();
    const profile = useUserProfile();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const [authenticationState] = useAuthentication();
    const vehiclePageContent = useVehiclePageContent();
    const [currentVehicle, setCurrentVehicle] = useState<
        VehicleAttributes | undefined
    >(props.currentSearchVehicle);

    const profileSelection = () => {
        if (profile && vehiclesData.length > 0) {
            let match: VehicleAttributes = vehiclesData[0];
            const selectedVin = profileService.getUserPreferences(
                profile.profile.email
            )?.lastSelectedVin;
            if (selectedVin) {
                match =
                    vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                    vehiclesData[0];
            }
            props.onSelectedVehicleChange &&
                props.onSelectedVehicleChange(match);
            setCurrentVehicle(match);
        } else if (profile && currentVehicle && vehiclesData.length === 0) {
            setCurrentVehicle(currentVehicle);
        }
    };

    useEffect(() => {
        profileSelection();
    }, [vehiclesData]);

    useEffect(() => {
        if (profile) {
            if (profile.vehicles.length > 0) {
                const vehicles = [...profile.vehicles]
                    .sort(
                        (vehicle1, vehicle2) =>
                            parseInt(vehicle2.modelYear, 10) -
                            parseInt(vehicle1.modelYear, 10)
                    )
                    .map(vehicleDetail => {
                        return {
                            year: parseInt(vehicleDetail.modelYear, 10),
                            make: vehicleDetail.make,
                            model: vehicleDetail.modelName,
                            vin: vehicleDetail.vin,
                            ownerState: vehicleDetail.ownerState,
                            registrationNo: vehicleDetail.licenseplate,
                            nickName: vehicleDetail.nickName,
                            warrantyStartDate: vehicleDetail.warrantyStartDate,
                        } as VehicleAttributes;
                    });
                setVehiclesData(vehicles);
            }
        }
    }, [profile]);

    const storeUserPreference = (vehicle: VehicleAttributes) => {
        profile &&
            profile.vehicles.length > 0 &&
            profileService.persistVehicleSelection(
                profile.profile.email,
                vehicle.vin
            );
    };

    const history = useHistory();
    const [fireEvents] = useAnalytics();
    const goToDashboard = useCallback(
        (index: number) => {
            history.push(findByAlias('VehicleDashboardView'), {
                selectedIndex: index,
            });
        },
        [history]
    );
    const selectVehicle = (
        label: string,
        index: number,
        value: string | undefined
    ) => {
        if (value === VEHICLE_BUTTONS.CHANGE) {
            history.push('/');
        } else if (value === VEHICLE_BUTTONS.ADD) {
            goToMyAccount(vehiclePageContent);
        } else {
            if (props.onChange) {
                props.onChange(label, index, value, vehiclesData[index]);
            }
            fireEvents(
                'change-vehicle-onclick-event',
                undefined,
                undefined,
                false
            );
            if (authenticationState === AuthenticationState.Authenticated) {
                storeUserPreference(vehiclesData[index]);
                setCurrentVehicle(vehiclesData[index]);
                if (!props.disableDashboardRedirect) goToDashboard(index);
            }
        }
    };

    return (
        <VehicleMenu
            value={currentVehicle}
            vehiclesData={vehiclesData}
            content={{
                addVehicleCtaLabel:
                    vehiclePageContent?.addVehicleCtaLabel || 'Add Vehicle',
                changeVehicleButtonLabel:
                    vehiclePageContent?.changeVehicleButtonLabel ||
                    'Change Vehicle',
                vinLabel: vehiclePageContent?.vinLabel || 'VIN:',
            }}
            handleMenuItemClick={(
                e: string,
                i: number,
                value: string | undefined
            ) => selectVehicle(e, i, value)}
        />
    );
};
