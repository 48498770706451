import React, { useContext, useEffect, useState } from 'react';
import './vehicle-info.scss';
import { FMButton, FMInput } from '@own/fds-react';
import { VehicleAttributes } from '../../../../../models/vehicle-attributes';
import VehicleImageService from '../../../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import { PageType } from '../../../view-template/page-type';
import { ReactComponent as DefaultCarImage } from '../../../../../../src/assets/osb-v3/default-vehicle.svg';
import { AppConfigurationService } from '../../../../../services/app-configuration-service/app-configuration-service';
import ServiceHandler from '../../../../../services/service-handler';
import ServerContext from '../../../../../contexts/serverContext';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import { useHistory } from 'react-router-dom';
import { buildNavigationUrl } from '../../../owners-osb/osb-utils';
import { OsbPathServiceCentreStep } from '../../osb-view-controller';
import { useOSBStep, useVehicleStep } from '../../../../../hooks/owners-osb';
import {
    VehicleDetail,
    VehicleDetailWithMileage,
} from '../../../../../models/profile-with-vehicles';

function VehicleInfo({
    vehicleDetail,
}: Readonly<{ vehicleDetail: VehicleDetail }>) {
    const { modelName, modelYear, licenseplate, vin } = vehicleDetail;
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'vehicle-step-extension') || {};
    const [vehicleImageUrl, setVehicleImageUrl] = useState<string | null>(null);
    const [imageError, setImageError] = useState(false);
    const [mileage, setMileage] = useState<string>('');
    const [mileageError, setMileageError] = useState<string | null>(null);
    const { setOSBVehicleStepPayload } = useVehicleStep();
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    const { osbStep } = useOSBStep();
    const history = useHistory();

    useEffect(() => {
        if (!vin || !modelYear) {
            return;
        }

        const fetchVehicleImage = async () => {
            const page = 'OSBVehicleStep' as PageType;
            const { brand } = new AppConfigurationService();
            const vehicleAttributes: VehicleAttributes = {
                vin: vin,
                year: parseInt(modelYear, 10),
                make: brand,
                model: modelName ?? '',
            };

            const vehicleImageService = new VehicleImageService();

            try {
                const imageUrl = await vehicleImageService.getVehicleImage(
                    vehicleAttributes,
                    page
                );
                setVehicleImageUrl(imageUrl);
            } catch (error) {
                console.error('Error fetching vehicle image:', error);
                setImageError(true);
            }
        };

        fetchVehicleImage();
    }, [vin, modelYear]);

    useEffect(() => {
        if (!vin || !modelYear) {
            return;
        }

        const fetchVehicleData = async () => {
            try {
                const vehicleTypeResponse = await ServiceHandler.VehicleTypeService.request(
                    vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                );

                if (vehicleTypeResponse?.isConnected) {
                    const vehicleStatus = await ServiceHandler.ConnectedVehicleService.getVehicleStatus(
                        vin,
                        parseInt(modelYear, 10)
                    );

                    const fetchedMileage = Number(
                        vehicleStatus?.vehiclestatus.odometer.value
                    );
                    setMileage(fetchedMileage ? fetchedMileage.toString() : '');
                }
            } catch (error) {
                console.error('Error fetching vehicle data:', error);
            }
        };

        fetchVehicleData();
    }, [vin, modelYear]);

    const onMileageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value.replace(/,/g, '');
        if (/^\d*$/.test(inputValue)) {
            const formattedValue = inputValue.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
            );
            setMileage(formattedValue);
            setMileageError(null);
        } else {
            setMileageError(osbContentData?.mileageErrorMessage as string);
        }
    };

    const onContinueClick = () => {
        const vehicleDetailWithMileage: VehicleDetailWithMileage = {
            ...vehicleDetail,
            mileage: mileage,
        };
        setOSBVehicleStepPayload({ vehicleDetail: vehicleDetailWithMileage });
        history.push(
            buildNavigationUrl(
                OsbPathServiceCentreStep(),
                osbStep.UrlQueryParams
            )
        );
    };

    const isMileageValid = mileage !== '';

    return (
        <div className="osb-v3-vehicle-info">
            <div className="osb-v3-vehicle-info-image">
                {vehicleImageUrl && !imageError ? (
                    <img
                        src={vehicleImageUrl}
                        alt="Vehicle"
                        crossOrigin="anonymous"
                        onError={() => setImageError(true)}
                    />
                ) : (
                    <DefaultCarImage
                        data-testid="default-car-image"
                        aria-label="Default Vehicle Image"
                    />
                )}
            </div>

            <div className="osb-v3-vehicle-info-details">
                {modelName && (
                    <div className="osb-v3-vehicle-info-item">
                        <div className="osb-v3-vehicle-name-label">
                            {osbContentData?.vehicleModelLabel}
                        </div>
                        <div className="osb-v3-vehicle-name-value">
                            {modelName}
                        </div>
                    </div>
                )}

                {licenseplate && (
                    <div className="osb-v3-vehicle-info-item">
                        <div className="osb-v3-registration-number-label">
                            {osbContentData?.vehicleRegistrationNumberLabel}
                        </div>
                        <div className="osb-v3-registration-number-value">
                            {licenseplate}
                        </div>
                    </div>
                )}

                {vin && (
                    <div className="osb-v3-vehicle-info-item">
                        <div className="osb-v3-vin-label">
                            {osbContentData?.vehicleIdentificationNumberLabel}
                        </div>
                        <div className="osb-v3-vin-value">{vin}</div>
                    </div>
                )}

                <div className="osb-v3-vehicle-info-item">
                    <div className="osb-v3-mileage-label">
                        {osbContentData?.currentMileageLabel}
                    </div>
                    <FMInput
                        type="text"
                        value={mileage}
                        onChange={onMileageChange}
                        required={true}
                    />
                    {mileageError && (
                        <div className="osb-v3-vehicle-info-mileage-error-message">
                            {mileageError}
                        </div>
                    )}
                </div>

                <div className="osb-v3-vehicle-info-continue-button">
                    <FMButton
                        href=""
                        label={osbContentData?.continueButtonLabel}
                        disabled={!isMileageValid}
                        theme="light"
                        type="primary"
                        onClick={onContinueClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default VehicleInfo;
