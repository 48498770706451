import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import {
    RidesQueryParams,
    UnAssignedRidesResponse,
    AssignedRidesResponse,
    SaveAssignedRidesEntryData,
    BusinessRideResponse,
    ReducerInitialState,
} from '../../models/ford-rides-trips-service-model';
import { AxiosRequestConfig } from 'axios';
import ServiceHandler from '../service-handler';
import { RideType } from '../../views/ford-rides-view/ford-rides-view';

type exportType = 'pdf' | 'excel';
export default class FordRidesTripsService {
    async getUnassignedRides(
        unassignedQueryParams?: RidesQueryParams,
        vin?: any
    ) {
        const unassignedRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS}/cat/unassigned`;
        return await HttpService.get<UnAssignedRidesResponse>(
            unassignedRidesUrl,
            false,
            {
                params: {
                    ...unassignedQueryParams,
                    country: (
                        unassignedQueryParams?.country ??
                        ServiceHandler.AppConfigurationService
                            .current3LetterCountryCode
                    )?.toUpperCase(),
                },
                headers: {
                    'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                        'Consumer-Key'
                    ],
                    'Auth-Token':
                        HttpService.getAppIdAndAuthTokenRequestHeaders()[
                            'Auth-Token'
                        ] ?? '',
                    Vin: vin,
                },
            }
        ).then(({ data }) => data);
    }

    async getAssignedRides(assignedQueryParams?: RidesQueryParams, vin?: any) {
        const assignedRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS}/adfs/assigned`;
        return await HttpService.get<AssignedRidesResponse>(
            assignedRidesUrl,
            false,
            {
                params: {
                    ...assignedQueryParams,
                    country: (
                        assignedQueryParams?.country ??
                        ServiceHandler.AppConfigurationService
                            .current3LetterCountryCode
                    )?.toUpperCase(),
                    userGuid: assignedQueryParams?.userGuid,
                },
                headers: {
                    'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                        'Consumer-Key'
                    ],
                    Vin: vin,
                },
            }
        ).then(({ data }) => data);
    }

    async saveAssignedRides(
        saveRidesData?: ReducerInitialState,
        config?: AxiosRequestConfig
    ) {
        const saveRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS}/adfs/save`;
        return await HttpService.post<BusinessRideResponse>(
            saveRidesUrl,
            {
                ...(saveRidesData as SaveAssignedRidesEntryData),
                tripCountry:
                    saveRidesData?.tripCountry ??
                    ServiceHandler.AppConfigurationService.current3LetterCountryCode.toUpperCase(),
                tripGuid: saveRidesData?.tripGuid,
            },
            {
                headers: { 'Content-Type': 'application/json' },
                ...config,
            }
        ).then(({ data }) => data);
    }

    async exportRides(
        exportType: exportType,
        rideType: RideType[],
        queryParams?: RidesQueryParams,
        vin?: any
    ) {
        const isPDF: boolean = exportType?.trim()?.toLowerCase() === 'pdf';
        const exportPath = isPDF ? '/adfs/export/pdf' : '/adfs/export/excel';
        const responseType = isPDF
            ? 'application/pdf'
            : 'application/octet-stream';
        const filename = isPDF
            ? `Ford_Vehicle_Rides.pdf`
            : `Ford_Vehicle_Rides.xlsx`;
        const exportRidesUrl = `${DSL_BASE_URL +
            DSL_API_PATH.FORD_RIDES_TRIPS +
            exportPath}`;

        try {
            const countryCode =
                queryParams?.country ??
                ServiceHandler.AppConfigurationService
                    .current3LetterCountryCode;
            const safeCountryCode =
                typeof countryCode === 'string'
                    ? countryCode.toUpperCase()
                    : null;
            const tripTypeParams = rideType
                .map(type => `tripType=${encodeURIComponent(type)}`)
                .join('&');
            const urlWithParams = `${exportRidesUrl}?${tripTypeParams}`;
            const response = await HttpService.get(urlWithParams, false, {
                params: {
                    ...queryParams,
                    country: safeCountryCode,
                    userGuid: queryParams?.userGuid,
                },

                headers: {
                    'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                        'Consumer-Key'
                    ],
                    Vin: vin,
                },
                responseType: 'blob',
            });
            if (!response) {
                console.error(`Export failed with status: ${response}`);
                throw new Error(`Export failed with status: ${response}`);
            }
            const blob = new Blob([response.data], { type: responseType });
            const url = URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = url;
            tempLink.setAttribute('download', filename);
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading Attachment:', error);
        }
    }
}
