import React from 'react';
import { CouponTile } from '../../constants/route-constants';

export const CardDescription = (couponDetails: CouponTile) => {
    const descriptionTooltip = couponDetails.offer.split(' ');

    return (
        <>
            {descriptionTooltip.map((value: string) => {
                if (value.includes('*') || value == '**' || value == '*') {
                    return <>{value}</>;
                } else if (value) {
                    return <>{' ' + value + ' '}</>;
                }
            })}
        </>
    );
};
