import { useContent } from '../../../../hooks/use-server-content';

export interface ServieHistoryContentProps {
    region: string;
    serviceHistoryTitle: string;
    downloadHistoryLabel: string;
    addRecordLabel: string;
    addRecordEnabled: boolean;
    noServiceRecordsText: string;
    noPriorRecordsFound: string;
    ownersEntryLabel: string;
    dealerServiceLabel: string;
    loadMoreLabel: string;
    loadLessLabel: string;
    mileageLabel: string;
    mileageUnitLabel: string;
    locationNameLabel: string;
    totalCostLabel: string;
    totalCostUnitLabel: string;
    serviceDate: string;
    roNumberLabel: string;
    nextServiceDateLabel: string;
    entryLastModifiedDate: string;
    scheduledServiceCompleted: string;
    bodyAndPaintCheckComplete: string;
    otherRelatedItems: string;
    nextServiceDueLabel: string;
    nextServiceDueText: string;
    nextServiceDateDueLabel: string;
    servicesPerformedLabel: string;
    inspectionsPerformedLabel: string;
    notesLabel: string;
    editLabel: string;
    editButtonLink: string;
    deleteLabel: string;
    deleteButtonLink: string;
    approvedText: string;
    yourRecordsLabel: string;
    priorRecordsLabel: string;
    vehicle: string;
    vinLabel: string;
    limitedViewLabel: string;
    serviceFailureErrorMessage: string;
    limitedViewTooltipHeading: string;
    limitedViewTooltipContent: string;
    limitedViewTooltipRte: string;
    deleteConfirmationHeading: string;
    deleteConfirmationContent: string;
    deleteConfirmationCancelCTALabel: string;
    deleteConfirmationCTALabel: string;
    deleteSuccessMessage: string;
    deleteFailureMessage: string;
    ownerEditableMessage: string;
    ownerNonEditableMessage: string;
    downloadHistoryPageLabel: string;
    downloadHistoryOfLabel: string;
    openInNewtabArialabel: string;
    showLimitedView: boolean;
}
export const useServiceHistoryContent = (): ServieHistoryContentProps => {
    const [content, getValueByName] = useContent('common', 'service-history');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ServieHistoryContentProps;
};
