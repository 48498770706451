import React from 'react';

interface Props {
    relatedTopicsContent: string;
}

export const RelatedTopicsSection = (props: Props) => (
    <section className="warranty-related-topics">
        <div
            className="warranty-information-title"
            dangerouslySetInnerHTML={{
                __html: props.relatedTopicsContent,
            }}
        ></div>
    </section>
);
