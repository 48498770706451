import { OsbMap } from '../osb-map';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import './dealer-details-mobile-map.scss';
import DealerInfoTile from './dealer-info-tile';
import filterIcon from '../../../../../../assets/owners-osb/dealer-filter-icon.svg';
import listIcon from '../../../../../../assets/owners-osb/list-view.svg';

import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { useEffect, useRef, useState } from 'react';
import { getObjectFromAEMJson } from '../../../osb-utils';
import { DEALER_STEP_KEYS } from '../../../osb-constant';
import { FdsChevron } from '../../../../../common/chevron/fds-chevron';
import { LightDealerFilterOptions } from '../light-dealer-filter-options/light-dealer-filter-options';

interface Props {
    selectedLocation: string;
    dealers: DealerProfileInfo[];
    preferredDealerIds: string[];
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    dealerStepSpecialServicesContent: JsonFromAEMResponse[];
    showViewMoreButton: boolean;
    searchResultsCount: string;
    showMaxDealers: () => void;
    onListLinkClick: () => void;
    handleSelectDealer: (
        dealerProfile: DealerProfileInfo,
        index: number
    ) => void;
    handleDealerDetails: (dealer: DealerProfileInfo, index: number) => void;
    refreshDealerDetailsList?: (dealerProfiles: DealerProfileInfo[]) => void;
    isDealerFilterOptionsEnabled: boolean;
}
function DealerDetailsMobileMap(props: Props) {
    const {
        dealers,
        preferredDealerIds,
        dealerStepContent,
        dealerStepMoreContent,
        showViewMoreButton,
        showMaxDealers,
        onListLinkClick,
        handleSelectDealer,
        handleDealerDetails,
    } = props;
    const mapRef = useRef<HTMLDivElement>(null);
    const [isMapFixed, setIsMapFixed] = useState(false);
    const [showMore, setShowMore] = useState(showViewMoreButton);
    const handleViewMoreClick = () => {
        setShowMore(false);
        showMaxDealers();
    };

    const [landingTileDealerCode, setLandingTileDealerCode] = useState<string>(
        ''
    );

    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);
    const handleDealerDetailsClick = (
        dealer: DealerProfileInfo,
        index: number
    ) => {
        handleDealerDetails(dealer, index);
    };
    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const element = event.target as HTMLDivElement;
        const currentTile = Math.ceil(element.scrollLeft / element.offsetWidth);
        const dealerCode = props.dealers[currentTile].dealerCode;
        setLandingTileDealerCode(dealerCode);
    };
    const handleListLinkClick = () => {
        onListLinkClick();
    };

    const toggleLightDealerFilterOptions = () => {
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };
    useEffect(() => {
        const handleHorizontalScroll = () => {
            if (mapRef.current) {
                const { top } = mapRef.current.getBoundingClientRect();

                if (top <= 77) {
                    setIsMapFixed(true);
                } else {
                    setIsMapFixed(false);
                }
            }
        };
        window.addEventListener('scroll', handleHorizontalScroll);
        return () =>
            window.removeEventListener('scroll', handleHorizontalScroll);
    }, []);
    return (
        <div
            className="osb-dealer-details-mobile-map-wrapper"
            ref={mapRef}
            id="mobile-map"
        >
            <>
                <div className={`map-actions ${isMapFixed ? 'fixed' : ''}`}>
                    {dealers.length > 1 &&
                        props.isDealerFilterOptionsEnabled &&
                        !showDealerFilterOptions && (
                            <button
                                className="filter-link"
                                onClick={toggleLightDealerFilterOptions}
                            >
                                <img
                                    src={filterIcon}
                                    alt="dealer-filter-icon"
                                />
                                <span className="filters-text">
                                    {' '}
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.DEALER_FILTER_LABEL,
                                        dealerStepMoreContent
                                    )}
                                </span>
                            </button>
                        )}

                    {dealers.length > 1 && (
                        <button
                            className="list-link"
                            onClick={handleListLinkClick}
                        >
                            <img src={listIcon} alt="dealer-filter-icon" />
                            <span className="list-text">
                                {' '}
                                {getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.LIST_VIEW_LABEL,
                                    dealerStepContent
                                )}
                            </span>
                        </button>
                    )}
                </div>
                <OsbMap
                    dealers={dealers}
                    fullscreenControl={false}
                    zoomControlPosition="RIGHT_TOP"
                    selectedDealerCode={landingTileDealerCode}
                    upShiftDegree={0.03}
                    preferredDealerIds={preferredDealerIds}
                />
            </>
            {!showDealerFilterOptions && (
                <>
                    <div className="mobile-container">
                        <div
                            className="dealer-tiles-container"
                            onScroll={handleScroll}
                        >
                            {dealers.map((dealer, index) => (
                                <div
                                    key={dealer.dealerCode}
                                    data-dealercode={dealer.dealerCode}
                                    className="dealer-info-box-wrapper"
                                >
                                    {preferredDealerIds.includes(
                                        dealer.dealerCode
                                    ) &&
                                        getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.PREFERRED_DEALER_CAPTION,
                                            dealerStepContent
                                        ) && (
                                            <div className="preferred-dealer-label">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.PREFERRED_DEALER_CAPTION,
                                                    dealerStepContent
                                                )}
                                            </div>
                                        )}
                                    <DealerInfoTile
                                        dealer={dealer}
                                        index={index}
                                        dealerStepContent={dealerStepContent}
                                        dealerStepMoreContent={
                                            dealerStepMoreContent
                                        }
                                        onSelectDealer={(
                                            dealer: DealerProfileInfo,
                                            index: number
                                        ) => handleSelectDealer(dealer, index)}
                                        onSelectDealerDetails={(
                                            dealer: DealerProfileInfo,
                                            index: number
                                        ) =>
                                            handleDealerDetailsClick(
                                                dealer,
                                                index
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {showMore && (
                        <div className="view-more-container-mobile">
                            <div
                                className="view-more-button-mobile"
                                onClick={handleViewMoreClick}
                            >
                                <div className="view-more-text-mobile">
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_VIEWMORE_BUTTON,
                                        dealerStepMoreContent
                                    )}
                                </div>
                                <FdsChevron direction={'down'} type="filled" />
                            </div>
                        </div>
                    )}
                </>
            )}
            {showDealerFilterOptions && (
                <div className="dealer-filter-options-container">
                    <LightDealerFilterOptions
                        toggleLightDealerFilterOptions={
                            toggleLightDealerFilterOptions
                        }
                        dealerStepMoreContent={props.dealerStepMoreContent}
                        refreshDealerDetailsList={
                            props.refreshDealerDetailsList
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default DealerDetailsMobileMap;
