import React from 'react';
import { FMButton } from '@own/fds-react';
import { ActionItemProps } from '../../../models/experiencefragments/action-item';

import './fm-action-item.scss';
import { useAnalytics } from '../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { URLUtil } from '../../utils/url-util/url-util';
import { useHistory } from 'react-router';

const FMActionItem = (props: ActionItemProps) => {
    const {
        content,
        requiredLabel,
        required = false,
        recommendedLabel,
        onClick,
    } = props;
    const isRelativeCta = URLUtil.isRelativeUrl(content.ctaPath);
    const history = useHistory();
    const [fireEvents] = useAnalytics();

    const href =
        isRelativeCta && content.ctaPath.startsWith('/')
            ? content.ctaPath.substring(1)
            : content.ctaPath;

    const fireAnalytics = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            content.ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('action-item-onclick-event', undefined, {
                actionItemType: content.type,
            });
        }
    };

    const handleClick = async () => {
        await fireAnalytics();
        if (onClick) {
            onClick();
        } else if (isRelativeCta) {
            history.push(content.ctaPath);
        }
    };

    return (
        <article className="fm-action-items-card">
            <p className="fm-action-items-card__label">
                <span
                    className={
                        required
                            ? 'fm-action-items-card__label__required'
                            : 'fm-action-items-card__label__recommended'
                    }
                >
                    {required ? requiredLabel : recommendedLabel}
                </span>
            </p>
            <p className="fm-action-items-card__title">{content.title}</p>
            <p className="fm-action-items-card__description">
                {content.subtitle}
            </p>
            <FMButton
                type={'primary'}
                label={content.ctaTitle}
                className="fm-action-items-card__cta"
                onClick={handleClick}
                {...(content.ctaPath ? { href } : {})}
                {...(content?.ctaTargetBlank
                    ? { target: '_blank' }
                    : { target: '_self' })}
            />
        </article>
    );
};

export default FMActionItem;
