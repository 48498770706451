import React from 'react';
import './vehicle-step-view.scss';
import { OsbLandingPage } from '../../../components/sections/osb-v3/vehicle-step/vehicle-step';

export function VehicleStepView() {
    return (
        <>
            <div className="osb-v3-vehicle-step-view-container">
                <OsbLandingPage />
            </div>
        </>
    );
}
