import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ScrollToTop from '../support/components/scroll-to-top/scroll-to-top';

interface Props {
    children: ReactNode;
}

const Layout = (props: RouteComponentProps & Props) => {
    return (
        <main className="app">
            {props.children}
            <ScrollToTop />
        </main>
    );
};

export default withRouter(Layout);
