import { useCalendarStep, useOSBStep } from '../../../../../hooks/owners-osb';
import './light-pickup-service-calendar-ford.scss';
import { DATE_TIME_STEP } from '../../osb-constant';
import { ContentElements } from '../../../../../models/osb-model/osb-content-details';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import {
    getGoMainHeaderHeight,
    getObjectFromAEMJson,
    getPickupDeliveryDateAndTimeSlots,
} from '../../osb-utils';
import Calendar from '../calendar';
import { AvailableDates } from '../../../../../models/osb-model/dealer-calendar-details';
import { useEffect, useState } from 'react';
import { TimeslotPicker } from '../timeslot-picker';
import serverSideService from '../../../../../services/server-side-service/server-side-service';

interface Props {
    dateTimeContent?: ContentElements[];
    calendarStartDate: string;
    calendarEndDate: string;
    availableDatesWithTimeslots: AvailableDates[];
    editTrigger?: () => void;
    selectedPickupDateValue?: (date: string) => void;
    selectedPickupTimeslotValue?: (timeslot: string) => void;
}
export const LightPickupCalendar = (props: Props) => {
    const { osbCalendarStep } = useCalendarStep();
    const { osbStep } = useOSBStep();
    const [pickupDate, setPickupDate] = useState<string>(
        osbCalendarStep.selectedPickupDate.length > 0
            ? osbCalendarStep.selectedPickupDate
            : ''
    );
    const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
    const [isShowTimeslot, setIsShowTimeslot] = useState<boolean>(
        osbCalendarStep.selectedPickupTime?.length > 0
    );
    const [defaultTime, setDefaultTime] = useState<string>(
        osbCalendarStep.selectedPickupTime?.length > 0
            ? osbCalendarStep.selectedPickupTime
            : osbCalendarStep.selectedTime || ''
    );
    const [defaultDate, setDefaultDate] = useState<string>(
        osbCalendarStep.selectedPickupDate.length > 0
            ? osbCalendarStep.selectedPickupDate
            : osbCalendarStep.selectedDate || ''
    );
    const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);
    const appConfig = OsbUtilService.getAppConfiguration();
    const [pickupDatesWithTimeslots, setPickupDatesWithTimeslots] = useState<
        AvailableDates[]
    >([]);
    const gotoServiceAppointment = () => {
        props.editTrigger && props.editTrigger();
    };

    const getServiceAppointment = () => {
        return osbCalendarStep.selectedDate
            ? OsbUtilService.constructDisplayDate(
                  osbCalendarStep.selectedDate,
                  getObjectFromAEMJson(
                      DATE_TIME_STEP.DATE_FORMAT_CALENDAR,
                      props.dateTimeContent || []
                  )
              ) +
                  ' ' +
                  getObjectFromAEMJson(
                      DATE_TIME_STEP.AT_SIGN,
                      props.dateTimeContent || []
                  ).trim() +
                  ' ' +
                  OsbUtilService.getTimeBasedOnAuthoring(
                      osbCalendarStep.selectedTime,
                      osbStep.twelveHrsTimeFormat,
                      osbStep.amTimeLabel,
                      osbStep.pmTimeLabel,
                      osbStep.timeSuffixLabel
                  )
            : '';
    };
    const framePickupDate = () => {
        if (pickupDate.length > 0) {
            return (
                '(' +
                OsbUtilService.constructDisplayDate(
                    pickupDate,
                    getObjectFromAEMJson(
                        DATE_TIME_STEP.DATE_FORMAT_CALENDAR,
                        props.dateTimeContent || []
                    )
                ) +
                ')'
            );
        }
        return '';
    };
    const fetchSelectedDate = (selectedDate: string) => {
        setDefaultDate(selectedDate);
        setIsShowCalendar(false);
        setIsShowTimeslot(true);
        osbCalendarStep.selectedPickupDate = selectedDate;
        setPickupDate(selectedDate);
        props.selectedPickupDateValue &&
            props.selectedPickupDateValue(selectedDate);
    };
    const fetchSelectedTimeslots = (selectedTimeslot: string[]) => {
        setDefaultTime(
            selectedTimeslot.length > 0 ? selectedTimeslot[0]?.slice(0, -3) : ''
        );
        osbCalendarStep.selectedPickupTime = selectedTimeslot[0]?.slice(0, -3);
        setAvailableTimeSlots(selectedTimeslot.map(str => str.slice(0, -3)));
        if (selectedTimeslot.length > 0) {
            props.selectedPickupTimeslotValue &&
                props.selectedPickupTimeslotValue(
                    selectedTimeslot[0]?.slice(0, -3)
                );
        }
    };
    const onpickupDateChange = (value: any) => {
        setPickupDate(value);
        setIsShowCalendar(true);
    };
    const onpickupDateClick = () => {
        setIsShowCalendar(true);
        setIsShowTimeslot(false);
    };
    const fetchSelectedTime = (selectTime: string) => {
        setDefaultTime(selectTime);
        osbCalendarStep.selectedPickupTime = selectTime;
        props.selectedPickupTimeslotValue &&
            props.selectedPickupTimeslotValue(selectTime);
    };
    const findAvailableTimeSlots = (
        pickupAndDeliverydatesAndTime: AvailableDates[]
    ) => {
        return pickupAndDeliverydatesAndTime.length > 0
            ? pickupAndDeliverydatesAndTime
                  .find(obj => {
                      return obj.date === osbCalendarStep.selectedPickupDate;
                  })
                  ?.availableTimeSlots?.map(str => str.slice(0, -3)) || []
            : [];
    };
    useEffect(() => {
        //get initialTimeSlotIntervalInSeconds  from AEM JSON using the key DATE_TIME_STEP.INITIAL_TIMESLOT_INTERVAL_IN_SECONDS,
        //and then converts the retrieved value to a number.
        const initialTimeSlotIntervalInSeconds = Number(
            getObjectFromAEMJson(
                DATE_TIME_STEP.INITIAL_TIMESLOT_INTERVAL_IN_SECONDS,
                props.dateTimeContent || []
            )
        );

        const pickupAndDeliverydatesAndTime = getPickupDeliveryDateAndTimeSlots(
            osbCalendarStep.selectedDate,
            `${osbCalendarStep.selectedTime}:00`,
            initialTimeSlotIntervalInSeconds,
            props.availableDatesWithTimeslots
        );
        // for calendar
        setPickupDatesWithTimeslots(pickupAndDeliverydatesAndTime);
        // for timeslot picker
        setAvailableTimeSlots(
            findAvailableTimeSlots(pickupAndDeliverydatesAndTime)
        );
        if (serverSideService.isClientSide()) {
            window.scrollTo({
                top: getGoMainHeaderHeight() - 2,
                behavior: 'smooth',
            });
        }
    }, []);
    return (
        <div className="osb-pickup-service-calendar-section-container">
            <div className="pickup-service-calendar-title">
                {getObjectFromAEMJson(
                    DATE_TIME_STEP.PICKUP_SELECT_DATE_AND_TIME_TITLE,
                    props.dateTimeContent || []
                )}
            </div>

            <div className="pickup-service-calendar-appointment-container">
                <div className="pickup-service-calendar-appointment-header">
                    <div className="pickup-service-calendar-appointment-title">
                        {getObjectFromAEMJson(
                            DATE_TIME_STEP.PICKUP_SERVICE_APPOINTMENT_TITLE,
                            props.dateTimeContent || []
                        )}
                    </div>
                    <div
                        className="pickup-service-calendar-appointment-edit-link"
                        onClick={gotoServiceAppointment}
                    >
                        {getObjectFromAEMJson(
                            DATE_TIME_STEP.PICKUP_EDIT_LINK_LABEL,
                            props.dateTimeContent || []
                        )}
                    </div>
                </div>
                <div className="pickup-service-calendar-appointment-date-time">
                    {getServiceAppointment()}
                </div>
            </div>

            <div className="pickup-service-calendar-time-and-date-title">
                {getObjectFromAEMJson(
                    DATE_TIME_STEP.PICKUP_TIME_AND_DATE_TITLE,
                    props.dateTimeContent || []
                )}
            </div>
            <div
                className="pickup-service-calendar-time-and-date-text"
                dangerouslySetInnerHTML={{
                    __html: getObjectFromAEMJson(
                        DATE_TIME_STEP.PICKUP_TIME_AND_DATE_TEXT,
                        props.dateTimeContent || []
                    ),
                }}
            />
            <div className="pickup-date-text-container">
                <div className="input-textbox-label">
                    {pickupDate.length > 0 && (
                        <div>
                            {getObjectFromAEMJson(
                                DATE_TIME_STEP.PICKUP_DATE_PLACE_HOLDER_TEXT,
                                props.dateTimeContent || []
                            )}
                        </div>
                    )}
                </div>
                <input
                    id="pickupDateText"
                    type="text"
                    className="pickup-input-text"
                    placeholder={getObjectFromAEMJson(
                        DATE_TIME_STEP.PICKUP_DATE_PLACE_HOLDER_TEXT,
                        props.dateTimeContent || []
                    )}
                    aria-label="emailAddress"
                    aria-labelledby="pickupInputText"
                    name="pickupDateText"
                    value={framePickupDate()}
                    onChange={e => onpickupDateChange(e.target.value)}
                    onClick={onpickupDateClick}
                />
                {isShowCalendar && (
                    <Calendar
                        calendarStart={props.calendarStartDate}
                        calendarEnd={osbCalendarStep.selectedDate}
                        setSelectedDate={fetchSelectedDate}
                        setTimeslots={fetchSelectedTimeslots}
                        message={DATE_TIME_STEP.PICKUP_DATE}
                        availableDates={pickupDatesWithTimeslots}
                        initSelectDate={defaultDate}
                        localeAsString={appConfig.languageRegionCode}
                        isMobileView={false}
                    />
                )}
                {isShowTimeslot && (
                    <>
                        <div className="pickup-service-timeslot-title">
                            {getObjectFromAEMJson(
                                DATE_TIME_STEP.PICKUP_AVAILABLE_TIMES_TITLE,
                                props.dateTimeContent || []
                            )}
                        </div>
                        <div className="pickup-service-timeslot-component">
                            <TimeslotPicker
                                data={availableTimeSlots}
                                setSelectedTime={fetchSelectedTime}
                                selectedDate={defaultDate}
                                initSelectTime={defaultTime}
                                localeAsString={appConfig.languageRegionCode}
                                isMobileView={false}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
