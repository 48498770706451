import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import {
    useDealerStep,
    useHttp,
    useOSBStep,
    useServiceStep,
    useVehicleStep,
} from '../../../../hooks/owners-osb';
import './campaign-validation-ford.scss';
import {
    DEFAULT_VOUCHER_ERROR_SORT_ORDER,
    OSB_LIGHTT_VOUCHER_KEYS,
    SERVICE_DELIVERY_TYPE,
} from '../osb-constant';
import {
    ErrorResponse,
    getLightVehicleData,
    getObjectFromAEMJson,
} from '../osb-utils';
import { useCampaignStatesStep } from '../../../../hooks/owners-osb/use-campaign-states-step';
import { ContentElements } from '../../../../models/osb-model/osb-content-details';
import { findByPath } from '../../../../routes';
import ServiceHandler from '../../../../services/service-handler';
import { useAllOSBState } from '../../../../hooks/owners-osb/use-all-osb-state';
import { VehicleData } from '../../../../models/osb-model/osb-vehicle-step';
import { useLocation } from 'react-router-dom';
import { InfoIconToolTip } from '../../../common/info-icon-tooltip/info-icon-tooltip';
import {
    CampaignState,
    Validation,
    ValidationValues,
} from '../../../../models/osb-model/osb-campaign-state';
import redWarning from '../../../../assets/owners-osb/warning-icon-red.svg';
import yellowWarning from '../../../../assets/owners-osb/warning-yellow.png';

const replacePlaceholders = (message: string, values: ValidationValues) => {
    const valueList = Object.keys(values);
    if (valueList.length > 0) {
        message = valueList.reduce((acc: string, key: string) => {
            return acc.replace(`<${key}>`, values[key] as string);
        }, message);
    }
    return message;
};
const formatErrorKey = (key: string) => `invalid${key}Error`;

const generateErrorMessageHTML = (messages: string[]) => {
    const html = messages.map(message => `<li>${message}</li>`).join('');
    return `<ul>${html}</ul>`;
};

const formatInvalidMessageContent = (invalidMessages: string[]) => {
    if (invalidMessages.length > 0) {
        return generateErrorMessageHTML(invalidMessages);
    } else {
        return '';
    }
};

export const CampaignValidationMessageWithTooltip = (props: {
    invalidMessages: string[];
    unknownMessage: string;
}) => {
    const { unknownMessage, invalidMessages } = props;

    switch (true) {
        // WHEN INVALID_MESSAGEs === 0 AND UNKNOWN_MESSAGE EXIST THEN,
        // SHOW the priority error message as the UNKNOWN_MESSAGE.
        case unknownMessage && invalidMessages.length === 0:
            return (
                <>
                    <img
                        src={yellowWarning}
                        alt=""
                        className="campaign-validation-error-image"
                        data-testid="Yellow-warning-icon"
                    />{' '}
                    {unknownMessage}
                </>
            );

        // WHEN INVALID_MESSAGEs === 1 AND UNKNOWN_MESSAGE DOESNT EXIST THEN,
        // SHOW the priority error message as the only INVALID MESSAGE.
        case !unknownMessage && invalidMessages.length === 1:
            return (
                <>
                    <img
                        src={redWarning}
                        alt=""
                        className="campaign-validation-error-image"
                        data-testid="Red-error-icon"
                    />{' '}
                    {invalidMessages[0]}
                </>
            );
        // WHEN INVALID_MESSAGEs > 1 AND UNKNOWN_MESSAGE DOESNT EXIST THEN
        // SHOW INVALID_MESSAGEs[0] as the priority error message and rest of INVALID_MESSAGEs in tooltip.
        case !unknownMessage && invalidMessages.length > 1: {
            const restErrorMessages = invalidMessages.slice(1);
            return (
                <div data-testid="Red-error-icon">
                    <InfoIconToolTip
                        osbServiceTooltip={true}
                        redWarningIcon={true}
                        tooltipInfoIconClass={'dark'}
                        tooltipContent={formatInvalidMessageContent(
                            restErrorMessages
                        )}
                    />{' '}
                    {invalidMessages[0]}
                </div>
            );
        }
        // WHEN INVALID_MESSAGEs > 0 AND UNKNOWN_MESSAGE EXIST THEN,
        // SHOW the priority error message as UNKNOWN_MESSAGE  with list of INVALID_MESSAGEs in tooltip.
        case unknownMessage && invalidMessages.length > 0: {
            return (
                <div data-testid="Yellow-warning-icon">
                    <InfoIconToolTip
                        osbServiceTooltip={true}
                        yellowWarningIcon={true}
                        tooltipInfoIconClass={'dark'}
                        tooltipContent={formatInvalidMessageContent(
                            invalidMessages
                        )}
                    />{' '}
                    {unknownMessage}
                </div>
            );
        }
        // WHEN INVALID_MESSAGEs = 0 AND UNKNOWN_MESSAGE DOESNT EXIST THEN
        // DONT SHOW any message.
        default:
            return <></>;
    }
};

export const CampaignValidation = () => {
    const { osbCampaignStates, setOSBCampaignStates } = useCampaignStatesStep();
    const { httpState, dispatch } = useHttp();
    const { osbDealerStep, callContentService } = useDealerStep();
    const [voucherContent, setVoucherContent] = useState<ContentElements[]>();
    const bookable = useAllOSBState();
    const { osbStep } = useOSBStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbServiceStep } = useServiceStep();

    const errorMessages = useMemo(() => {
        return osbCampaignStates?.campaignStates.reduce(
            (acc: Validation[], campaignState: CampaignState): Validation[] => {
                return [...acc, ...campaignState.validations];
            },
            []
        );
    }, [osbCampaignStates?.campaignStates, voucherContent]);

    const sortedErrorInvalidMessages = useMemo(() => {
        const aemSortOrderText =
            voucherContent &&
            getObjectFromAEMJson(
                OSB_LIGHTT_VOUCHER_KEYS.ERROR_MESSAGE_SORT_ORDER,
                voucherContent
            );
        const aemSortOrder = aemSortOrderText
            ? aemSortOrderText.split(',')
            : [];
        const sortOrderArray =
            aemSortOrder.length > 0
                ? aemSortOrder
                : DEFAULT_VOUCHER_ERROR_SORT_ORDER;

        return errorMessages
            .filter(validation => validation.state === 'INVALID')
            .sort((a, b) => {
                return (
                    sortOrderArray.indexOf(formatErrorKey(a.type)) -
                    sortOrderArray.indexOf(formatErrorKey(b.type))
                );
            })
            .map(validation => {
                //We discovered an issue during our discussion with the team and TK regarding a mismatch between the AEM key and the backend response key
                //specifically for 'Services.' As a result, we implemented a workaround to address this issue. The code below is hardcoded due to this.
                const t =
                    validation.type === 'Services'
                        ? 'ServiceSpecific'
                        : validation.type;
                const key = formatErrorKey(t);

                const message =
                    voucherContent && getObjectFromAEMJson(key, voucherContent);
                if (message) {
                    return replacePlaceholders(message, validation.values);
                }
                return key; // This is intentional to highlight if no content is configured then display key
            });
    }, [errorMessages]);

    const errorUnknownMessage: string = useMemo(() => {
        const unknownErrors = errorMessages.filter(
            validation => validation.state === 'UNKNOWN'
        );
        if (unknownErrors.length > 0) {
            if (voucherContent) {
                return getObjectFromAEMJson(
                    OSB_LIGHTT_VOUCHER_KEYS.UNKNOWN_COMMON_ERROR,
                    voucherContent
                );
            } else {
                return `unknown`; // This is intentional to highlight if no content is configured then display key
            }
        }

        return '';
    }, [errorMessages]);

    const location = useLocation();
    const view = findByPath(location.pathname);
    useEffect(() => {
        if (
            osbCampaignStates?.campaignStates &&
            osbCampaignStates?.campaignStates.length > 0 &&
            [
                'OsbDeliveryView',
                'OsbBookingReviewView',
                'OsbServiceLocationView',
            ].includes(view)
        ) {
            const selectedDealerCode =
                osbDealerStep.selectedDealerProfile.dealerCode || '';
            const vehicleData = getLightVehicleData(osbVehicleStep);
            if (vehicleData) {
                loadServiceData(
                    osbCampaignStates?.campaignStates[0].key,
                    vehicleData,
                    selectedDealerCode
                );
            }
        }
    }, [view]);

    const loadServiceData = async (
        voucherCode: string,
        vehicleData: VehicleData,
        selectedDealerCode: string
    ) => {
        // TODO: Hack to avoid the race condition of updating global state and making a call to api service.
        if (!voucherCode) {
            bookable.campaignSelections = [];
        }
        await ServiceHandler.OsbDealerServices.getServicesList({
            dealerCode: selectedDealerCode,
            vehicleData: vehicleData,
            vouchers: voucherCode ? [voucherCode] : [],
            serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            requestSourceType: osbStep.source,
            bookable: bookable,
            DSLAPIVersion: osbStep.DSLAPIVersion,
        })
            .then(results => {
                if (
                    Array.isArray(results.value.dealerServices?.campaignStates)
                ) {
                    setOSBCampaignStates({
                        campaignStates: [
                            ...results.value.dealerServices.campaignStates,
                        ],
                    });
                }

                dispatch({ type: 'RESPONSE' });
            })
            .catch((error: ErrorResponse) => {
                console.error(error);
            });
    };

    useEffect(() => {
        // loads voucher content from AEM
        const loadVoucherContent = async () => {
            dispatch({ type: 'REQUEST' });
            await callContentService(
                OSB_LIGHTT_VOUCHER_KEYS.VOUCHER_FRAGMENT_NAME
            )
                .then(results => {
                    setVoucherContent(results?.elements);
                    dispatch({ type: 'RESPONSE' });
                })
                .catch((error: any) => {
                    if (error.response) {
                        const errorMessage =
                            error.response?.data?.error?.message;
                        dispatch({ type: 'ERROR', error: errorMessage });
                    }
                });
        };
        loadVoucherContent();
    }, []);

    return (
        <>
            {httpState.isLoading ? (
                <ActivityIndicator />
            ) : sortedErrorInvalidMessages.length > 0 || errorUnknownMessage ? (
                <div className="campaign-validation-container">
                    <CampaignValidationMessageWithTooltip
                        invalidMessages={sortedErrorInvalidMessages}
                        unknownMessage={errorUnknownMessage}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default CampaignValidation;
