import React, { useEffect, useState } from 'react';
import './drag-fileUpload-popup-lincoln.scss';
import Popup from 'reactjs-popup';
import { SERVICES_STEP_KEYS } from '../../osb-constant';
import { getObjectFromAEMJson } from '../../osb-utils';

interface Props {
    setStatus: (status: boolean) => void;
    openPopupData: boolean;
    serviceAemContent?: any;
}

export const FileUploadPopup = (props: Props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.openPopupData);
    }, []);

    return (
        <div>
            <Popup className="modal-file-drop" modal={true} open={open}>
                <div className="popup-body-content-light">
                    <div className="body-content-text">
                        {getObjectFromAEMJson(
                            SERVICES_STEP_KEYS.DROP_FILE_POPUP_LABEL,
                            props.serviceAemContent
                        )}
                    </div>
                </div>
            </Popup>
        </div>
    );
};
