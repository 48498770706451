import ServerSideService from '../services/server-side-service/server-side-service';

interface ServerDataProps {
    setData: (data: any) => void;
    key: string;
    context?: any;
}

export const SetServerData = (props: ServerDataProps) => {
    if (ServerSideService.isClientSide()) {
        props.setData(props.context.data[props.key]);
        delete props.context.data[props.key];
    }
};
