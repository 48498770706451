import { useContent } from '../../../../hooks/use-server-content';

export interface AddVehicleProps {
    hide: boolean;
    addVehicleButton: string;
    addVehicleLabel: string;
    addVehicleDescription: string;
    addVehicleVinHeader?: string;
    vin: string;
    enterVinPlaceholder?: string;
    nickname: string;
    btnUpdate: string;
    btnCancel: string;
    incorrectVin: string;
    reEnter: string;
    hideTooltip: boolean;
    tooltipHeader?: string;
    tooltipContent: string;
    bannerMessage?: string;
    dismissAriaLabel?: string;
    duplicateVinMessage?: string;
}
export const useAddVehicleContent = (): AddVehicleProps | null => {
    const [content, getValueByName] = useContent('common', 'add-vehicle');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (
            contentFragment.tooltipHeader == null &&
            contentFragment.tooltipContent == null
        ) {
            contentFragment.hideTooltip = true;
        }
        if (contentFragment.hide || !contentFragment.addVehicleButton) {
            contentFragment = null;
        }
    }
    return contentFragment as AddVehicleProps;
};
