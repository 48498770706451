import React from 'react';
import { MapTile } from '../../../services/sync-service/sync-map-service';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import VehicleChecklist, {
    HeaderType,
} from '../vehicle-checklist/vehicle-checklist';

interface Props {
    dynamicDescriptionUpToDate?: string;
    dynamicDescriptionUpdateAvailable?: string;
    tileTitle: string;
    ctaText: string;
    ctaPath: string;
    syncMapData: MapTile;
}
const VehicleChecklistSyncMap = (
    props: Props & VehicleAttributes & VehicleChecklistContent
) => {
    const { syncMapData } = props;
    if (serverSideService.isClientSide() && props.vin) {
        localStorage.setItem('USER_VIN', props.vin);
    }
    if (syncMapData) {
        const dynamicText = (): {
            type: HeaderType;
            subCopy?: string;
        } => {
            if (!syncMapData?.mapUpToDate && !syncMapData?.mapUpdateAvailable) {
                return {
                    type: 'none',
                    subCopy: props.staticDescription,
                };
            } else if (syncMapData?.mapUpdateAvailable) {
                return {
                    type: 'severe',
                    subCopy: props.dynamicDescriptionUpdateAvailable,
                };
            } else {
                return {
                    type: 'none',
                    subCopy: props.dynamicDescriptionUpToDate,
                };
            }
        };

        return (
            <VehicleChecklist
                type={''}
                {...props}
                header={props.tileTitle}
                headerType={dynamicText().type}
                ctaTitle={props.ctaTitle || props.ctaText}
                ctaPath={props.ctaPath}
                description={dynamicText().subCopy || ''}
            />
        );
    } else {
        return (
            <VehicleChecklist
                type={''}
                header={props.tileTitle}
                description={props.staticDescription}
                {...props}
            />
        );
    }
};

export default VehicleChecklistSyncMap;
