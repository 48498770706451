import React, { useEffect, useState } from 'react';
import './htv-video-component.scss';
import { useAnalytics } from '../../../../hooks/use-analytics';
import useBrightcoveScriptBuilder from '../../../../hooks/owners-osb/use-brightcove-script';

interface ModelProps {
    videoId: string;
    account: string;
    className?: string;
    description?: string;
    videoTitle?: string;
}

export const VideoContent = (props: ModelProps) => {
    const [videoPlayer, setVideoPlayer] = useState<string>();
    const [videoCompletion, setVideoCompletion] = useState<boolean>(false);
    const { src, playerSource } = useBrightcoveScriptBuilder(props.account);
    const [fireEvents] = useAnalytics();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.defer = true;
        document.body.appendChild(script);
        setVideoPlayer(playerSource);
    }, [props.videoId, src, playerSource]);

    const handleClick = () => {
        const videoOnClickCtaInfo = {
            videoName: props.videoTitle?.toLowerCase(),
        };

        fireEvents(
            'dyf-new-htv-click-video',
            undefined,
            videoOnClickCtaInfo,
            false
        );
    };
    const handleVideoProgress = (
        event: React.SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        const watchTime = Math.floor(
            (event.currentTarget.currentTime / event.currentTarget.duration) *
                100
        );
        if (watchTime > 74 && !videoCompletion) {
            const videoOnClickCtaInfo = {
                videoName: props.videoTitle?.toLowerCase(),
            };
            fireEvents(
                'dyf-new-htv-video-complete',
                undefined,
                videoOnClickCtaInfo,
                false
            );

            setVideoCompletion(true);
        }
    };

    return (
        <div
            className={'htv-video-container'}
            onClick={() => handleClick()}
            data-testId={`video-${props.videoId}`}
        >
            <video
                id={`videoId-${props.videoId}`}
                data-video-id={props.videoId}
                data-account={props.account}
                data-player={videoPlayer}
                data-embed="default"
                className={`video-js vjs-fluid video-player ${props.className &&
                    props.className}`}
                controls
                onTimeUpdate={handleVideoProgress}
            />
            <div className="video-desc-container">
                <p>{props.description}</p>
            </div>
        </div>
    );
};
