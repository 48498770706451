import { useContent } from '../../../../hooks/use-server-content';

export interface OfferCategoryProps {
    image1: string;
    name1: string;
    image2: string;
    name2: string;
    image3: string;
    name3: string;
    image4: string;
    name4: string;
    image5: string;
    name5: string;
    image6: string;
    name6: string;
    cta1Label: string;
    cta1Path: string;
    cta1AriaLabel: string;
    cta1TargetBlank: string;
    cta2Label: string;
    cta2Path: string;
    cta2AriaLabel: string;
    cta2TargetBlank: string;
}

export const useOfferCategory = (): OfferCategoryProps | null => {
    const [content, getValueByName] = useContent(
        'common',
        'categorydetails-offer'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as OfferCategoryProps;
};
