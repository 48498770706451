import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import './ultimate-towing-experience-view.scss';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { UteProvider } from './context/ute-context';
import { UTE_ROUTES } from '../../components/sections/ultimate-towing-experience/components/types';
import { UteVehicleWrapper } from '../../components/sections/ultimate-towing-experience/components/ute-vehicle-wrapper/ute-vehicle-wrapper';
import { UteCreateBasicInfo } from '../../components/sections/ultimate-towing-experience/components/ute-Forms/ute-basic-info/ute-basic-info';
import { UteFinalForm } from '../../components/sections/ultimate-towing-experience/components/ute-Forms/ute-final/ute-final';
import { UteFaqs } from '../../components/sections/ultimate-towing-experience/components/ute-faqs/ute-faqs';
import { UteViewTrailerProfile } from '../../components/sections/ultimate-towing-experience/components/ute-view-trailer-profile/ute-view-trailer-profile';
import { Modal } from '../../components/common/modal/modal';
import { useContentFragment } from '../../components/utils/aem-content-utils/use-content-fragment';
import {
    UltimateTowingExperienceContentProps,
    UteFormElementsProps,
} from '../../models/service-maintenance-models/ute';
import { ViewTemplate } from '../../components/sections';
import { BreadcrumbWrapper } from '../../components/common/breadcrumb-wrapper/breadcrumb-wrapper';
import ServerContext from '../../contexts/serverContext';

export interface Props {
    isModalOpen: boolean;
}
export function TowingExperienceView(props: Props) {
    const history = useHistory();
    const serverContext = useContext(ServerContext);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const authenticationService = new AuthenticationService();
    const formContent: UteFormElementsProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/ute-form-elements'
    );
    const profileContent: UltimateTowingExperienceContentProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/profile'
    );
    const {
        modalCancelBtn,
        modalContent,
        modalContinueBtn,
        modalTitle,
    } = formContent;
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<string>('');
    const [pageTitle, setPageTitle] = useState<string>('');
    const [isPopup, setIspopup] = useState<boolean>(true);
    const handleBreadCrumb = (
        currentPage: string,
        pageTitle: string,
        popup: boolean
    ) => {
        setCurrentPage(currentPage);
        setPageTitle(pageTitle);
        setIspopup(popup);
    };
    const [
        uteExistingTrailerProfile,
        setUteExistingTrailerProfile,
    ] = useState();
    const userSelectedVin =
        typeof window != 'undefined' &&
        window.sessionStorage.getItem('selectedVin');

    const uteExistingTrailerProfiles = (uteProfileData: any) => {
        setUteExistingTrailerProfile(uteProfileData);
    };
    const handleCancelBtn = () => {
        history.push(UTE_ROUTES.HOME);
        setIsModalVisible(false);
    };
    const handleModal = () => {
        if (isPopup) {
            setIsModalVisible(true);
        } else {
            handleCancelBtn();
        }
    };
    useEffect(() => {
        if (props.isModalOpen) setIsModalVisible(true);
    }, [props.isModalOpen]);

    useEffect(() => {
        if (userSelectedVin) {
            setIsAuth(true);
        } else {
            authenticationService.onIsAuthenticated().then(authenticated => {
                !authenticated
                    ? authenticationService.login()
                    : setIsAuth(true);
            });
        }
    }, []);
    const Breadcrumb = () => {
        return (
            <>
                <a
                    className="breadcrumb-link ute-breadcrumb"
                    aria-label={'towingAriaLabel'}
                    onClick={() => handleModal()}
                >
                    <span aria-current={true} tabIndex={0}>
                        {pageTitle}
                    </span>
                </a>
                <span className="back-slash">{'>'}</span>
            </>
        );
    };
    const routes = [
        {
            path: UTE_ROUTES.HOME,
            exact: true,
            component: (
                <UteVehicleWrapper
                    profileContent={profileContent}
                    handleBreadCrumb={handleBreadCrumb}
                    uteExistingTrailerProfiles={uteExistingTrailerProfiles}
                />
            ),
        },
        {
            path: UTE_ROUTES.BASIC_INFO,
            exact: true,
            component: (
                <UteCreateBasicInfo
                    profileContent={profileContent}
                    formContent={formContent}
                    handleModalCancel={handleModal}
                    trailerList={uteExistingTrailerProfile}
                    handleBreadCrumb={handleBreadCrumb}
                    serverContext={serverContext}
                />
            ),
        },
        {
            path: UTE_ROUTES.FINAL_FORM,
            exact: true,
            component: (
                <UteFinalForm
                    formContent={formContent}
                    handleModalCancel={handleModal}
                />
            ),
        },
        {
            path: UTE_ROUTES.FAQS,
            exact: true,
            component: <UteFaqs profileContent={profileContent} />,
        },
        {
            path: UTE_ROUTES.VIEW_TRAILOR_PROFILE,
            exact: true,
            component: (
                <UteViewTrailerProfile
                    profileContent={profileContent}
                    handleBreadCrumb={handleBreadCrumb}
                />
            ),
        },
    ];
    return (
        <ViewTemplate
            seoConfig={{
                pageTitle: 'Ultimate Towing Experience',
                applicationName: '',
            }}
            page={'Ultimate Towing Experience'}
            className="ultimate-towing-experience-view"
            hideLinksList={true}
            hideSearchBar={serverContext.webview === 'true'}
            showDisclosureContent={
                serverContext.webview === 'true' ? false : true
            }
        >
            <BreadcrumbWrapper
                currentPage={currentPage}
                hashId="support"
                title={
                    pageTitle != '' &&
                    currentPage !== pageTitle && <Breadcrumb />
                }
            />
            <div className="ute-view">
                {isAuth ? (
                    <>
                        <section className="ute-page">
                            <UteProvider>
                                <Switch>
                                    {routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                        >
                                            <>{route.component}</>
                                        </Route>
                                    ))}
                                </Switch>
                            </UteProvider>
                            <Modal
                                name="ute-modal-alert"
                                role="dialog"
                                aria-label="ute-modal"
                                primaryBtnLabel={modalCancelBtn}
                                secondaryBtnLabel={modalContinueBtn}
                                preventClose={true}
                                onPrimaryBtnClick={handleCancelBtn}
                                isVisible={isModalVisible}
                                onSecondaryBtnClick={() =>
                                    setIsModalVisible(false)
                                }
                            >
                                <div className="modalTitle">{modalTitle}</div>
                                <div
                                    className="modalDesc"
                                    dangerouslySetInnerHTML={{
                                        __html: modalContent,
                                    }}
                                />
                            </Modal>
                        </section>
                    </>
                ) : (
                    <ActivityIndicator className="full-height" />
                )}
            </div>
        </ViewTemplate>
    );
}
export default TowingExperienceView;
