import ServiceHandler from '../../../../../../services/service-handler';
import { CardState } from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';
import { replacePlaceholderByValue } from '../../../../../utils/placeholder-util/replace-placeholder-by-value';
export const getOilLifeDataFromAPI = (userSelectedVin: any) => {
    return ServiceHandler.VehiclePrognosticsService.getActivePrognosticsV2(
        userSelectedVin
    )
        .then(result => {
            const prognosticsData = result?.prognosticsData;
            const tempPrognostics = {
                oilLifeData: null,
                serviceRemindersData: null,
                tireLeakData: null,
            };
            prognosticsData?.forEach((reminder: any) => {
                if (reminder.featureType === 'OL') {
                    tempPrognostics.oilLifeData = reminder;
                }
                if (reminder.featureType === 'SM') {
                    tempPrognostics.serviceRemindersData = reminder;
                }
                if (reminder.featureType === 'TSL') {
                    tempPrognostics.tireLeakData = reminder;
                }
            });
            return tempPrognostics;
        })
        .catch(error => {
            console.error(error);
            return null;
        });
};

const getOLCount = (oilLifeData: any) => {
    if (
        oilLifeData?.featureData.iolm >= 0 &&
        oilLifeData?.featureData.iolm <= 5 &&
        (oilLifeData?.urgency === 'M' ||
            oilLifeData?.urgency === 'H' ||
            oilLifeData?.urgency === 'U')
    ) {
        return 1;
    } else {
        return 0;
    }
};
export const getOilLifeStatus = (oilLifeData: any) => {
    if (
        oilLifeData?.featureData.iolm > 1 &&
        oilLifeData?.featureData.iolm <= 5 &&
        oilLifeData?.urgency === 'M'
    ) {
        return CardState.WARNING;
    } else if (
        oilLifeData?.featureData.iolm >= 0 &&
        oilLifeData?.featureData.iolm <= 1 &&
        (oilLifeData?.urgency === 'H' || oilLifeData?.urgency === 'U')
    ) {
        return CardState.ALERT;
    } else {
        return CardState.NORMAL;
    }
};
export const getOilLifeDescription = (
    oilLifeData: any,
    oilLifeContent: any
) => {
    const alertCount: any = getOLCount(oilLifeData);
    if (alertCount > 0) {
        const oilLifeMsg = oilLifeContent.iolmLabel || '';
        return replacePlaceholderByValue(oilLifeMsg, {
            iolm: oilLifeData.featureData.iolm,
        });
    } else {
        return oilLifeContent.oilLifeLabel;
    }
};
