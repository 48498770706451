interface Props {
    breadcrumbUrl: string;
    breadcrumbTitle: string;
}

export const TireFinderBreadCrumb = (props: Props) => {
    const handleClick = () => {
        window.location.href = props.breadcrumbUrl;
    };

    return (
        <>
            <span
                className="breadcrumb-link-ellipses-expanded"
                onClick={handleClick}
            >
                <span>{props.breadcrumbTitle}</span>
            </span>
            <span className="back-slash">{'/'}</span>
        </>
    );
};
