import { useOSBStep } from '../../../../../../hooks/owners-osb';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import OsbUtilService from '../../../../../../services/osb-service/osb-util-service/osb-util-service';
import { FormatAddress } from '../../../common/osb-common-components/osb-common-components';
import { DEALER_STEP_KEYS } from '../../../osb-constant';
import { getObjectFromAEMJson } from '../../../osb-utils';
import DealerCapabilities from './dealer-capabilities';
import './dealer-details-mobile-list-ford.scss';
import filterIcon from '../../../../../../assets/owners-osb/dealer-filter-icon.svg';
import React, { useEffect, useRef, useState } from 'react';
import { PrimaryButton } from '../../../../../common';
import { OSBSecondaryButton } from '../../../common/osb-secondary-button/osb-secondary-button';
import { FdsChevron } from '../../../../../common/chevron/fds-chevron';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import { LightDealerFilterOptions } from '../light-dealer-filter-options/light-dealer-filter-options';

interface Props {
    dealers: DealerProfileInfo[];
    preferredDealerIds: string[];
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    showViewMoreButton: boolean;
    showMaxDealers: () => void;
    onMapsLinkClick: () => void;
    handleSelectDealer: (
        dealerProfile: DealerProfileInfo,
        index: number
    ) => void;
    handleDealerDetails: (dealer: DealerProfileInfo, index: number) => void;
    refreshDealerDetailsList?: (dealerProfiles: DealerProfileInfo[]) => void;
    isDealerFilterOptionsEnabled: boolean;
}
const DealerDetailsMobileList = (props: Props) => {
    const {
        dealers,
        preferredDealerIds,
        dealerStepContent,
        dealerStepMoreContent,
        showViewMoreButton,
        showMaxDealers,
        onMapsLinkClick,
        handleSelectDealer,
        handleDealerDetails,
    } = props;
    const [showMore, setShowMore] = useState(showViewMoreButton);
    const listRef = useRef<HTMLDivElement>(null);
    const [isListFixed, setIsListFixed] = useState(false);
    const { osbStep } = useOSBStep();
    const handleViewMoreClick = () => {
        setShowMore(false);
        showMaxDealers();
    };
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);

    function getDealerDistance(dealer: DealerProfileInfo) {
        const distanceUnit = getObjectFromAEMJson(
            DEALER_STEP_KEYS.DISTANCE_UNIT,
            dealerStepMoreContent
        ).toLowerCase();
        const distance =
            distanceUnit === 'miles'
                ? OsbUtilService.getDistanceInMiles(Number(dealer.distance))
                : OsbUtilService.getDistanceInKm(Number(dealer.distance));
        return distance;
    }
    const handleListLinkClick = () => {
        onMapsLinkClick();
    };
    const toggleLightDealerFilterOptions = () => {
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { top } = listRef.current.getBoundingClientRect();
                if (top <= 77) {
                    setIsListFixed(true);
                } else {
                    setIsListFixed(false);
                }
            }
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div
            className="osb-dealer-details-mobile-list"
            ref={listRef}
            id="mobile-list"
        >
            <div>
                <div className={`list-actions ${isListFixed ? 'fixed' : ''}`}>
                    {dealers.length > 1 &&
                        props.isDealerFilterOptionsEnabled &&
                        !showDealerFilterOptions && (
                            <button
                                className="filter-link"
                                onClick={toggleLightDealerFilterOptions}
                            >
                                <img
                                    src={filterIcon}
                                    alt="dealer-filter-icon"
                                />
                                <span className="filters-text">
                                    {' '}
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.DEALER_FILTER_LABEL,
                                        dealerStepMoreContent
                                    )}
                                </span>
                            </button>
                        )}

                    {dealers.length > 1 && (
                        <button
                            className="list-link"
                            onClick={handleListLinkClick}
                        >
                            <FdsChevron direction={'left'} type="unfilled" />
                            <span className="list-text">
                                {getObjectFromAEMJson(
                                    DEALER_STEP_KEYS.MAP_VIEW_LABEL,
                                    dealerStepContent
                                )}
                            </span>
                        </button>
                    )}
                </div>
                {!showDealerFilterOptions && (
                    <>
                        {dealers.map((dealer, index) => (
                            <React.Fragment key={dealer.dealerCode}>
                                <div
                                    key={dealer.dealerCode}
                                    className={`dealer-container ${
                                        preferredDealerIds.includes(
                                            dealer.dealerCode
                                        )
                                            ? 'preferred-dealer-container'
                                            : ''
                                    }`}
                                >
                                    {preferredDealerIds.includes(
                                        dealer.dealerCode
                                    ) &&
                                        getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.PREFERRED_DEALER_CAPTION,
                                            dealerStepContent
                                        ) && (
                                            <div className="preferred-dealer-label">
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.PREFERRED_DEALER_CAPTION,
                                                    dealerStepContent
                                                )}
                                            </div>
                                        )}
                                    <div className="dealer-number">
                                        {index + 1}
                                    </div>
                                    <div className="dealer-info">
                                        <div className="dealer-name">
                                            {dealer.dealerName}
                                        </div>
                                        <div className="dealer-address">
                                            <FormatAddress
                                                addressData={dealer}
                                                addressOrder={
                                                    osbStep.dealerAddressOrder
                                                }
                                                isDisplaySingleRow={true}
                                            />
                                        </div>
                                        {dealer?.distance > 0 && (
                                            <div className="dealer-distance-label">
                                                <span className="distance-label-text">
                                                    {getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.DISTANCE_TITLE,
                                                        dealerStepContent
                                                    )}
                                                </span>
                                                <span className="distance-value">
                                                    {getDealerDistance(dealer)}{' '}
                                                    {getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_DISTANCE_LABEL,
                                                        dealerStepMoreContent
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                        {dealer?.additionalInfo
                                            ?.earliestAvailableTimeslot && (
                                            <div className="dealer-earliest-available-slot">
                                                <span className="earliest-slot-label-text">
                                                    {getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_EARLIEST_AVAILABLE_SLOT,
                                                        props.dealerStepMoreContent
                                                    )}
                                                </span>
                                                <span className="dealer-earliest-slot-value">
                                                    {
                                                        dealer.additionalInfo
                                                            ?.earliestAvailableTimeslot
                                                    }
                                                </span>
                                            </div>
                                        )}
                                        {getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.ENABLE_DEALER_CAPABILITIES,
                                            dealerStepMoreContent
                                        ) === true && (
                                            <DealerCapabilities
                                                dealer={dealer}
                                                dealerStepMoreContent={
                                                    dealerStepMoreContent
                                                }
                                            />
                                        )}

                                        <div className="dealer-list-actions">
                                            <PrimaryButton
                                                role="link"
                                                color={'dark'}
                                                fill={'fill'}
                                                chevron={false}
                                                aria-label="select-dealer-cta"
                                                aria-labelledby="select dealer cta"
                                                dataTestId={`SelectDealer-${dealer.dealerCode}`}
                                                className="select-dealer-button"
                                                onClick={() =>
                                                    handleSelectDealer(
                                                        dealer,
                                                        index
                                                    )
                                                }
                                            >
                                                {getObjectFromAEMJson(
                                                    DEALER_STEP_KEYS.LIGHT_JOURNEY_SELECT_DEALER_BUTTON,
                                                    dealerStepMoreContent
                                                )}
                                            </PrimaryButton>
                                            <div className="details-link">
                                                <OSBSecondaryButton
                                                    type="unfilled"
                                                    direction="right"
                                                    text={getObjectFromAEMJson(
                                                        DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_MOREINFO_BUTTON,
                                                        props.dealerStepMoreContent
                                                    )}
                                                    onClickHandler={() =>
                                                        handleDealerDetails(
                                                            dealer,
                                                            index
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!preferredDealerIds.includes(
                                    dealer.dealerCode
                                ) && <hr className="separator" />}
                            </React.Fragment>
                        ))}
                        {showMore && (
                            <div className="view-more-container-mobile">
                                <div
                                    className="view-more-button-mobile"
                                    onClick={handleViewMoreClick}
                                >
                                    <div className="view-more-text-mobile">
                                        {getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_VIEWMORE_BUTTON,
                                            dealerStepMoreContent
                                        )}
                                    </div>
                                    <FdsChevron
                                        direction={'down'}
                                        type="filled"
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {showDealerFilterOptions && (
                    <div className="dealer-filter-options-container">
                        <LightDealerFilterOptions
                            toggleLightDealerFilterOptions={
                                toggleLightDealerFilterOptions
                            }
                            dealerStepMoreContent={props.dealerStepMoreContent}
                            refreshDealerDetailsList={
                                props.refreshDealerDetailsList
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DealerDetailsMobileList;
