import React, { useEffect, useReducer, useState } from 'react';
import './contact-details-form.scss';
import { FMButton, FMDropdown, FMInput } from '@own/fds-react';
import CountryDialCodePicker, {
    CountryDialCodePickerContent,
    CountryDialCodePickerOptions,
} from '../../../../common/country-dial-code-picker/country-dial-code-picker';
import {
    getInitialPersonalDetailState,
    useOSBStep,
    usePersonalDetailStep,
} from '../../../../../hooks/owners-osb';
import { personalDetailReducer } from '../../../../../reducers/osb-reducers';
import { PersonalDetail } from '../../../../../models/osb-model/osb-personal-details';
import { useCountryDialCodePicker } from '../../../../../hooks/use-country-dial-code-picker';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import InfoBox from '../../common/info-box/info-box';
import PrivacyText from '../../common/privacy-text/privacy-text';
//import { useQuery } from '../../../../../hooks/use-query';
import NotificationIcon from './../../../../../assets/osb-v3/notification-icons.svg';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';

interface ContactDetailsFormProps {
    onContinueClick: () => void;
}

type Errors = {
    firstName?: string;
    lastName?: string;
    contactNumber?: string;
    email?: string;
};

const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
    onContinueClick,
}) => {
    const contactDetailsContent =
        useOSBContentFragment('common/osb-v3', 'contact-details-step') || {};

    const countryDialCodeContent = useCountryDialCodePicker();
    const { osbStep } = useOSBStep();
    const [
        defaultMarketCodeToDisplay,
        setDefaultMarketCodeToDisplay,
    ] = useState<string>('');
    const [countryCodeData, setCountryCodeData] = useState<
        CountryDialCodePickerContent
    >();
    const appConfig = OsbUtilService.getAppConfiguration();
    const { osbPersonalDetail, setOSBPersonalDetail } = usePersonalDetailStep();
    const [personalDetail, setPersonalDetail] = useReducer(
        personalDetailReducer,
        getInitialPersonalDetailState()
    );
    //const query = useQuery();
    //const deliveryServiceId = query.get('deliveryService');
    const deepLinkParams = new URLSearchParams(window.location.search);
    const deliveryServiceId = deepLinkParams.get('deliveryService') || '10';
    let deliveryServiceOptions = [];

    try {
        if (Array.isArray(contactDetailsContent?.deliveryServiceOptions)) {
            deliveryServiceOptions = contactDetailsContent.deliveryServiceOptions
                .map(option => {
                    try {
                        return JSON.parse(option);
                    } catch (error) {
                        console.error('Failed to parse option:', option, error);
                        return null;
                    }
                })
                .filter(option => option !== null);
        }
    } catch (error) {
        console.error('Failed to process deliveryServiceOptions:', error);
    }

    const deliveryServiceInfo = deliveryServiceOptions.find(
        service => service.id.toString() === deliveryServiceId
    );

    const setComponentPayload = (
        payload:
            | { [key: string]: string | boolean | CountryDialCodePickerOptions }
            | PersonalDetail
    ): void => {
        setPersonalDetail({
            type: 'SET',
            payload: payload,
            localStorageExpiry: osbStep.localStorageExpiry,
        });
    };
    const [errors, setErrors] = useState<Errors>({});

    const titleOptions = Array.isArray(contactDetailsContent?.titles)
        ? contactDetailsContent?.titles.map(title => ({
              label: title,
              value: title,
          }))
        : [];

    const isContinueDisabled = () => {
        return (
            !personalDetail.firstName.trim() ||
            !personalDetail.lastName.trim() ||
            !personalDetail.contactNumber.trim() ||
            !personalDetail.email.trim() ||
            Object.values(errors).some(error => error)
        );
    };

    function validateField(fieldName: string, value: string): string {
        let error = '';

        switch (fieldName) {
            case 'firstName':
                if (!value.trim()) {
                    error = contactDetailsContent?.firstNameRequiredFieldAlertMessage as string;
                }
                break;
            case 'lastName':
                if (!value.trim()) {
                    error = contactDetailsContent?.lastNameRequiredFieldAlertMessage as string;
                }
                break;
            case 'contactNumber':
                if (!value.trim()) {
                    error = contactDetailsContent?.contactNumberRequiredFieldAlertMessage as string;
                } else if (!isValidContactNumber(value)) {
                    error = contactDetailsContent?.contactNumberInvalidFieldAlertMessage as string;
                }
                break;
            case 'email':
                if (!value.trim()) {
                    error = contactDetailsContent?.emailRequiredFieldAlertMessage as string;
                } else if (!isValidEmail(value)) {
                    error = contactDetailsContent?.emailInvalidFieldAlertMessage as string;
                }
                break;
            default:
                break;
        }

        return error;
    }

    function isValidEmail(email: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function isValidContactNumber(contactNumber: string): boolean {
        const pattern = contactDetailsContent?.contactNumberRegexForValidation as string;
        if (contactNumber.length <= 0 || !contactNumber.match(pattern)) {
            return false;
        } else {
            return true;
        }
    }
    const handleContinueClick = () => {
        const newErrors: Errors = {
            firstName: validateField('firstName', personalDetail.firstName),
            lastName: validateField('lastName', personalDetail.lastName),
            contactNumber: validateField(
                'contactNumber',
                personalDetail.contactNumber
            ),
            email: validateField('email', personalDetail.email),
        };

        setErrors(newErrors);

        if (Object.values(newErrors).every(error => !error)) {
            setOSBPersonalDetail(personalDetail, false);
            onContinueClick();
        }
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setComponentPayload({
            title: event.target.value,
        });
    };

    useEffect(() => {
        if (countryDialCodeContent?.dropdownOptions) {
            const contactNumber = osbPersonalDetail.contactNumber;
            const countryCode =
                contactNumber.length > 0
                    ? getCountryCodeFromContactNumber(contactNumber)
                    : '';

            setDefaultMarketCodeToDisplay(countryCode || appConfig.countryCode);

            setCountryCodeData(countryDialCodeContent);
        }

        if (osbPersonalDetail) {
            setPersonalDetail({
                type: 'SET',
                payload: osbPersonalDetail,
            });
        }
    }, [countryDialCodeContent, osbPersonalDetail]);

    function handelSelectedCountry(
        countrySelected: CountryDialCodePickerOptions | undefined,
        initialLoad: boolean
    ) {
        if (countrySelected) {
            let contactNumber = countrySelected.countryDialCode;
            if (initialLoad && personalDetail.contactNumber.length > 0) {
                contactNumber = personalDetail.contactNumber.startsWith(
                    countrySelected.countryDialCode
                )
                    ? personalDetail.contactNumber
                    : `${countrySelected.countryDialCode}${personalDetail.contactNumber}`;
            }

            setComponentPayload({
                contactNumber,
                countryDialCodeObject: countrySelected,
            });
        }
    }

    function getCountryCodeFromContactNumber(contactNumber: string) {
        if (!countryDialCodeContent?.dropdownOptions) {
            return '';
        }

        const foundItem = countryDialCodeContent.dropdownOptions.find(item =>
            contactNumber.startsWith(item.countryDialCode)
        );

        return foundItem ? foundItem.countryCode : '';
    }

    return (
        <div data-testid="contact-form" className="osb-v3-contact-details-form">
            <div className="osb-v3-contact-details-form__input">
                <div className="osb-v3-contact-details-form__title">
                    {contactDetailsContent?.yourContactDetailsTitle}
                </div>
                <label className="osb-v3-contact-details-form__label">
                    {contactDetailsContent?.titleLabel}
                </label>
                <FMDropdown
                    data-testid="title-dropdown"
                    onChange={handleTitleChange}
                    options={titleOptions}
                    value={personalDetail.title}
                    state="standard"
                    ariaLabel="Select your title"
                />
                <label
                    className="osb-v3-contact-details-form__label"
                    data-testid="first-name"
                >
                    {contactDetailsContent?.firstNameLabel}
                </label>
                <FMInput
                    ariaLabel="first-name-input"
                    name="first-name-input"
                    type="text"
                    className=""
                    value={personalDetail.firstName}
                    role="textbox"
                    onChange={e => {
                        const value = e.target.value.trimStart();
                        setComponentPayload({ firstName: value });
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            firstName: validateField('firstName', value),
                        }));
                    }}
                    required={true}
                />
                {errors.firstName && (
                    <div
                        data-testid="first-name-error"
                        style={{ color: 'red' }}
                    >
                        {errors.firstName}
                    </div>
                )}

                <label
                    className="osb-v3-contact-details-form__label"
                    data-testid="last-name"
                >
                    {contactDetailsContent?.lastNameLabel}
                </label>
                <FMInput
                    ariaLabel="last-name-input"
                    name="last-name-input"
                    type="text"
                    value={personalDetail.lastName}
                    onChange={e => {
                        const value = e.target.value.trimStart();
                        setComponentPayload({ lastName: value });
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            lastName: validateField('lastName', value),
                        }));
                    }}
                    required={true}
                />
                {errors.lastName && (
                    <div data-testid="last-name-error" style={{ color: 'red' }}>
                        {errors.lastName}
                    </div>
                )}

                <div className="osb-v3-contact-details-form__row">
                    <div className="osb-v3-contact-details-form__column">
                        <label
                            className="osb-v3-contact-details-form__label"
                            data-testid="country-code"
                        >
                            {contactDetailsContent?.countryCodeLabel}
                        </label>
                        <CountryDialCodePicker
                            data-testid="country-code-picker"
                            countryDialCodeContent={countryCodeData}
                            displayDefaultMarketCode={
                                defaultMarketCodeToDisplay
                            }
                            selectedCountry={handelSelectedCountry}
                        />
                    </div>
                    <div className="osb-v3-contact-details-form__column">
                        <label
                            className="osb-v3-contact-details-form__label"
                            data-testid="contact-number"
                        >
                            {contactDetailsContent?.contactNumberLabel}
                        </label>
                        <div className="osb-v3-contact-number-input">
                            <FMInput
                                ariaLabel="contact-number-input"
                                name="contact-number-input"
                                type="text"
                                value={personalDetail.contactNumber}
                                onChange={e => {
                                    const value = e.target.value.trimStart();
                                    setComponentPayload({
                                        contactNumber: value,
                                    });
                                    setErrors(prevErrors => ({
                                        ...prevErrors,
                                        contactNumber: validateField(
                                            'contactNumber',
                                            value
                                        ),
                                    }));
                                }}
                                required={true}
                            />
                        </div>
                        {errors.contactNumber && (
                            <div
                                data-testid="contact-number-error"
                                style={{ color: 'red' }}
                            >
                                {errors.contactNumber}
                            </div>
                        )}
                    </div>
                </div>

                <label
                    className="osb-v3-contact-details-form__label"
                    data-testid="contact-email"
                >
                    {contactDetailsContent?.emailLabel}
                </label>
                <FMInput
                    ariaLabel="email-input"
                    name="email-input"
                    type="text"
                    value={personalDetail.email}
                    onChange={e => {
                        const value = e.target.value.trimStart();
                        setComponentPayload({ email: value });
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            email: validateField('email', value),
                        }));
                    }}
                    required={true}
                />
                {errors.email && (
                    <div data-testid="email-error" style={{ color: 'red' }}>
                        {errors.email}
                    </div>
                )}
            </div>
            {deliveryServiceInfo && (
                <div
                    data-testid="delivery-service-info"
                    className="osb-v3-contact-details-courtesy-car-info-box"
                >
                    <InfoBox
                        title={deliveryServiceInfo.title}
                        iconUrl={NotificationIcon}
                        backgroundColor="#e4f1ff"
                        infoText={deliveryServiceInfo.description}
                    />
                </div>
            )}
            <div className="osb-v3-privacy-policy">
                <PrivacyText
                    fullPrivacyText={
                        contactDetailsContent?.privacyText as string
                    }
                    linkText={contactDetailsContent?.privacyLinkText as string}
                    privacyUrl={contactDetailsContent?.privacyLinkUrl as string}
                />
            </div>

            <div className="osb-v3-contact-details-form__separator"></div>
            <div className="osb-v3-contact-details-form-continue-button">
                <FMButton
                    ariaLabel="continue-button"
                    data-testid="continue-button"
                    label={contactDetailsContent?.continueButtonLabel}
                    theme="light"
                    type="primary"
                    onClick={handleContinueClick}
                    disabled={isContinueDisabled()}
                />
            </div>
        </div>
    );
};

export default ContactDetailsForm;
