import { classes, attributes } from './segmentedControl.const';
import { KEYBOARD_KEYS } from '../../../constants';

export default class FDSSegmentedControl {
    initMoveTimers: NodeJS.Timeout[] = [];
    segmentedControlWindowResizeDelay = 500;
    segmentedControlWindowResizeThrottled = false;
    windowWidth = window.innerWidth;
    buttonListFlexContainerElement: HTMLElement | undefined;
    buttonListElement: HTMLElement | undefined;
    sliderTextElement: HTMLSpanElement | undefined;
    sliderElement: HTMLElement | undefined;
    sliderStyles: HTMLElement | undefined;
    sliderPadding = 0;
    sliderBorder = 0;
    buttonListDividers: any;
    totalExtraWidth: any;
    mobileBreakpoint: any;

    // Initialize selectors

    isMobile = false;
    isTouch = false;
    fdsElement: any;
    pressedButtonElement: any;
    buttonElements: NodeListOf<HTMLElement>;
    sliderWidth = 0;
    sliderLeft = 0;
    viewportCenter = 0;
    activePanelElement: any;

    constructor(fdsElement: HTMLElement) {
        this.fdsElement = fdsElement;
        this.buttonElements = this.fdsElement.querySelectorAll(
            `.${classes.BUTTON}`
        );

        if (this.buttonElements.length > 0) {
            this.buttonListFlexContainerElement = this.fdsElement.querySelector(
                `.${classes.BUTTON_LIST_FLEX_CONTAINER}`
            ) as HTMLElement;
            this.buttonListElement = this.fdsElement.querySelector(
                `.${classes.BUTTON_LIST}`
            ) as HTMLElement;
            this.sliderTextElement = this.fdsElement.querySelector(
                `.${classes.SLIDER_TEXT}`
            ) as HTMLSpanElement;
            const sliderElement = this.fdsElement.querySelector(
                `.${classes.SLIDER}`
            );
            if (sliderElement) {
                const sliderStyles = window.getComputedStyle(sliderElement);
                this.sliderPadding =
                    parseInt(
                        sliderStyles.getPropertyValue('padding-left'),
                        10
                    ) * 2;
                this.sliderBorder =
                    parseInt(
                        sliderStyles.getPropertyValue('border-left-width'),
                        10
                    ) * 2;
            }
            const buttonListDividers = this.buttonElements.length - 1;
            this.totalExtraWidth =
                this.sliderPadding + this.sliderBorder + buttonListDividers;
            this.mobileBreakpoint = '768px';

            this.initPosition();

            window.addEventListener('resize', () => {
                if (
                    !this.segmentedControlWindowResizeThrottled &&
                    window.innerWidth !== this.windowWidth
                ) {
                    this.initMoveTimers.forEach(timer => {
                        clearTimeout(timer);
                        this.initMoveTimers.splice(
                            this.initMoveTimers.indexOf(timer),
                            1
                        );
                    });
                    this.segmentedControlWindowResizeThrottled = true;

                    // When browser is resized
                    this.resetStyles();
                    this.initPosition();
                    this.windowWidth = window.innerWidth;
                    const resizeTimer = setTimeout(() => {
                        this.segmentedControlWindowResizeThrottled = false;
                    }, this.segmentedControlWindowResizeDelay);
                    clearTimeout(resizeTimer);
                }
            });
        }
    }

    resetStyles() {
        const sliderElement = document.querySelector(
            `.${classes.SLIDER}`
        ) as HTMLElement;
        this.isMobile = false;
        this.isTouch = false;
        if (this.buttonListFlexContainerElement)
            this.buttonListFlexContainerElement.style.width = '';
        if (sliderElement) {
            sliderElement.style.width = '';
            sliderElement.style.margin = '';
            sliderElement.style.left = '';
        }
        if (this.buttonListElement) {
            this.buttonListElement.style.left = '';
        }

        this.fdsElement.classList.remove(classes.MOBILE);
    }

    initPosition() {
        if ('ontouchstart' in document.documentElement) {
            this.isTouch = true;
        }

        this.buttonElements.forEach(buttonElement => {
            if (
                buttonElement.getAttribute(attributes.ARIA_PRESSED) === 'true'
            ) {
                this.pressedButtonElement = buttonElement;
                const moveTimer = setTimeout(() => {
                    this.moveSlider(buttonElement);
                }, 50);
                this.activatePanel(buttonElement);
                this.initMoveTimers.push(moveTimer);
            }

            // Add Event Listeners
            buttonElement.addEventListener('click', event =>
                this.onClick(event)
            );
            buttonElement.addEventListener('blur', event => this.onBlur(event));
            buttonElement.addEventListener('keyup', event =>
                this.onKeyup(event)
            );
        });
    }

    setSliderWidth(buttonTextElement: HTMLElement) {
        const sliderElement = document.querySelector(
            `.${classes.SLIDER}`
        ) as HTMLElement;
        const buttonTextStyles = window.getComputedStyle(buttonTextElement);
        const buttonTextWidth = parseInt(
            buttonTextStyles.getPropertyValue('width'),
            10
        );
        this.sliderWidth = buttonTextWidth + this.totalExtraWidth;

        sliderElement.style.width = `${this.sliderWidth}px`;
    }

    setMobileSliderStartPosition(buttonElement: HTMLElement) {
        const sliderElement = document.querySelector(
            `.${classes.SLIDER}`
        ) as HTMLElement;
        const buttonTextElement = buttonElement.querySelector(
            `.${classes.BUTTON_TEXT}`
        ) as HTMLElement;

        this.setSliderWidth(buttonTextElement);
        sliderElement.style.left = 'auto';

        const sliderRect = sliderElement.getBoundingClientRect();
        this.sliderLeft = sliderRect.left;
        this.viewportCenter = window.innerWidth / 2;
        sliderElement.style.left = `${this.viewportCenter - this.sliderLeft}px`;
    }

    moveButtonList(buttonElement: HTMLElement) {
        const sliderElement = document.querySelector(
            `.${classes.SLIDER}`
        ) as HTMLElement;
        const buttonListElement = document.querySelector(
            `.${classes.BUTTON_LIST}`
        ) as HTMLElement;
        const buttonTextElement = buttonElement.querySelector(
            `.${classes.BUTTON_TEXT}`
        ) as HTMLSpanElement;
        const buttonTextRect = buttonTextElement.getBoundingClientRect();
        const buttonTextWidth = buttonTextRect.width;
        const buttonTextLeft = buttonTextRect.left;
        const buttonListRect = buttonListElement.getBoundingClientRect();
        const buttonListLeft = buttonListRect.left;

        this.setSliderWidth(buttonTextElement);

        // center slider
        sliderElement.style.marginLeft = `-${this.sliderWidth / 2}px`;

        if (this.viewportCenter < buttonTextLeft) {
            buttonListElement.style.left = `${buttonListLeft -
                (buttonTextLeft - this.viewportCenter) -
                buttonTextWidth / 2 -
                this.sliderLeft}px`;
        } else {
            buttonListElement.style.left = `${buttonListLeft +
                (this.viewportCenter - buttonTextLeft) -
                buttonTextWidth / 2 -
                this.sliderLeft}px`;
        }

        if (this.sliderTextElement) {
            this.sliderTextElement.innerText = buttonTextElement.innerText;
        }

        this.updatePressedButton(buttonElement);
    }

    moveSlider(buttonElement: HTMLElement) {
        const sliderElement = document.querySelector(
            `.${classes.SLIDER}`
        ) as HTMLElement;
        const buttonListElement = document.querySelector(
            `.${classes.BUTTON_LIST}`
        );
        const buttonListRect = buttonListElement?.getBoundingClientRect();
        let buttonListLeft = buttonListRect?.left;
        if (!buttonListLeft) buttonListLeft = 0;
        const buttonTextElement = buttonElement.querySelector(
            `.${classes.BUTTON_TEXT}`
        ) as HTMLElement;
        const buttonTextRect = buttonTextElement.getBoundingClientRect();
        const buttonTextLeft = buttonTextRect.left;
        if (sliderElement) {
            const sliderStyles = window.getComputedStyle(sliderElement);
            const sliderPadding = parseInt(
                sliderStyles.getPropertyValue('padding-left'),
                10
            );
            const slideWidth = `${buttonTextLeft -
                buttonListLeft -
                sliderPadding}`;
            this.setSliderWidth(buttonTextElement);
            sliderElement.style.left = `${slideWidth}px`;
        }

        if (this.sliderTextElement) {
            this.sliderTextElement.innerText = buttonTextElement.innerText;
        }
        this.updatePressedButton(buttonElement);
    }

    activatePanel(buttonElement: HTMLElement) {
        const panelId = buttonElement.getAttribute(
            attributes.ARIA_CONTROLS
        ) as string;
        const panelElement = document.getElementById(panelId);
        if (this.activePanelElement) {
            this.activePanelElement.classList.remove(classes.PANEL_SHOW);
        }
        panelElement?.classList.add(classes.PANEL_SHOW);
        this.activePanelElement = panelElement;
    }

    updatePressedButton(buttonElement: HTMLElement) {
        this.pressedButtonElement?.setAttribute(
            attributes.ARIA_PRESSED,
            'false'
        );
        this.pressedButtonElement = buttonElement;
        this.pressedButtonElement?.setAttribute(
            attributes.ARIA_PRESSED,
            'true'
        );
    }

    /**
     * Event Handlers
     */

    onClick(event: any) {
        const buttonElement = event.target.closest(`.${classes.BUTTON}`);

        if (this.isMobile) {
            this.moveButtonList(buttonElement);
        } else {
            this.moveSlider(buttonElement);
        }

        this.activatePanel(buttonElement);
    }

    onBlur(event: any) {
        const buttonElement = event.target.closest(`.${classes.BUTTON}`);

        buttonElement.classList.remove(classes.FOCUSED);
    }

    onKeyup(event: any) {
        const buttonElement = event.target.closest(`.${classes.BUTTON}`);

        if (event.keyCode === KEYBOARD_KEYS.TAB) {
            buttonElement.classList.add(classes.FOCUSED);
        }
    }
}
