import React, { ReactNode, useContext, useEffect, useState } from 'react';
import QuickLinksCommon from '../../quicklinks/quick-links-common/quick-links-common';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useExperienceContent } from '../../../hooks/use-server-content';
import {
    QuickLinksContent,
    QuickLinksFragment,
    QuickLinksMapping,
} from '../../../models/experiencefragments/quick-links';
import './quick-links-grid.scss';
import VehicleTypeService from '../../../services/vehicle-type-service/vehicle-type-service';
import QuickLinksOwnerManuals from '../../quicklinks/quick-links-owner-manuals/quick-links-owner-manuals';
import { BRAND, QUICK_LINKS_CARD_TYPE } from '../../../constants';
import { SecondaryButton } from '../../common';
import searchPlusIcon from '../../../assets/search-plus.svg';
import { ViewportUtil } from '../../utils/viewport-util/viewport-util';
import QuickLinksScheduleService from '../../quicklinks/quick-links-schedule-service/quick-links-schedule-service';
import { PageType } from '../view-template/page-type';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import ServerContext from '../../../contexts/serverContext';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { FMButton } from '@own/fds-react';

interface Props {
    vehicleAttributes: VehicleAttributes;
    page: PageType;
}

export const QuickLinksGrid = (props: Props) => {
    const [canITowThis, setCanITowThis] = useState<boolean>(false);
    const [isWifiCapable, setIsWifiCapable] = useState<boolean>(false);
    const [quickLinksContent] = useExperienceContent<QuickLinksFragment>(
        'vehicles',
        getVehicleDataSelector('quicklinks', props.vehicleAttributes),
        'quicklinks',
        undefined,
        true
    );
    const { isMobileView } = ViewportUtil();
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;

    const [quicklinksMapping] = useExperienceContent<QuickLinksMapping>(
        'vehicles',
        'quicklinks-mapping',
        'quicklinks_mapping'
    );
    const { currentLanguageRegionCode, current3LetterCountryCode } = useContext(
        ServerContext
    );
    useEffect(() => {
        if (props.vehicleAttributes && props.vehicleAttributes.vin) {
            new VehicleTypeService()
                .request(
                    props.vehicleAttributes.vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                )
                .then(response => {
                    setCanITowThis(
                        response.vehicleData?.VINLookupCanITow?.toLowerCase() ===
                            'true'
                    );
                    response.isWifiCapable &&
                        props.page === 'DASHBOARD' &&
                        setIsWifiCapable(response.isWifiCapable);
                })
                .catch(error => {
                    console.error(
                        'Failed to Fetch Vehicle Type Response ',
                        error
                    );
                });
        }
    }, [props.vehicleAttributes?.vin]);
    const filterCITT = (card: QuickLinksContent) => {
        if (!canITowThis) return card.type !== QUICK_LINKS_CARD_TYPE.CITT;
        return card;
    };

    const filterWIFI = (card: QuickLinksContent) => {
        if (!isWifiCapable) return card.type !== QUICK_LINKS_CARD_TYPE.WIFI;
        return card;
    };

    const filterAuthOnlyBasedLinks = (
        linksContent: QuickLinksContent,
        pageType: PageType
    ) => {
        if (pageType === 'DASHBOARD') return true;
        else return !linksContent?.showOnlyInAuth;
    };

    const filters = [filterCITT, filterWIFI];

    const [showAllEnabled, setShowAllEnabled] = useState(false);

    const [initialCount, setInitialCount] = useState<number>(
        quickLinksContent?.noOfCards || 8
    );
    const [totalCount, setTotalCount] = useState<number>(
        quickLinksContent?.quickLinks.length || 0
    );

    const cardsToShow = () => {
        return totalCount < initialCount ? totalCount : initialCount;
    };

    const [index, setIndex] = useState<number>(cardsToShow());
    useEffect(() => {
        const isCurrentVehicleYearCittEligible = (eligibleYears: string[]) => {
            return eligibleYears
                .map(Number)
                .includes(props.vehicleAttributes.year);
        };

        if (
            props.vehicleAttributes &&
            props.vehicleAttributes.seoKey &&
            props.page === 'YMM' &&
            quicklinksMapping !== null
        ) {
            const currentVehicle = quicklinksMapping?.eligibleModelsCitt.filter(
                model => model.model === props.vehicleAttributes.seoKey
            )[0];
            if (currentVehicle !== undefined) {
                const eligibleYears = currentVehicle.years.split(',');
                if (isCurrentVehicleYearCittEligible(eligibleYears)) {
                    setCanITowThis(true);
                }
            }
        }
    }, [quicklinksMapping]);
    useEffect(() => {
        if (
            quickLinksContent &&
            quickLinksContent.noOfCards > 0 &&
            quickLinksContent.quickLinks.length > 0
        ) {
            setInitialCount(quickLinksContent.noOfCards);
            setTotalCount(quickLinksContent.quickLinks.length);
        } else if (
            quickLinksContent &&
            quickLinksContent.noOfCards < 0 &&
            quickLinksContent.quickLinks.length > 0
        ) {
            setInitialCount(quickLinksContent.quickLinks.length);
            setTotalCount(quickLinksContent.quickLinks.length);
        } else if (
            quickLinksContent &&
            quickLinksContent.noOfCards == 0 &&
            quickLinksContent.quickLinks.length > 0
        ) {
            setInitialCount(8);
            setTotalCount(quickLinksContent.quickLinks.length);
        }
    }, [quickLinksContent]);

    useEffect(() => {
        setIndex(cardsToShow());
        if (initialCount < 0) {
            setShowAllEnabled(false);
        } else if (initialCount < totalCount) {
            setShowAllEnabled(true);
        }
    }, [totalCount, initialCount]);
    const getFilteredCards = (): QuickLinksContent[] => {
        let result: QuickLinksContent[] = [];
        if (quickLinksContent) {
            result = quickLinksContent.quickLinks
                ?.filter(card => {
                    return filters.every(fn => fn(card));
                })
                .filter(tile => {
                    return filterAuthOnlyBasedLinks(tile, props.page);
                });
        }
        return result;
    };

    const handleShowAll = () => {
        let newIndex = index + initialCount;
        if (newIndex > totalCount) {
            newIndex = totalCount;
        }
        const focusNewIndex = `article${index}`;
        setTimeout(() => {
            document.getElementById(focusNewIndex)?.focus();
        }, 100);
        setIndex(newIndex);
        setShowAllEnabled(false);
    };

    // for dynamic quick links consider cardsToRender and filters array
    const cardsToRender = (card: any): ReactNode => {
        if (card.type === QUICK_LINKS_CARD_TYPE.OWNERS_MANUAL) {
            return (
                <QuickLinksOwnerManuals
                    {...props.vehicleAttributes}
                    {...card}
                />
            );
        } else if (card.type === QUICK_LINKS_CARD_TYPE.SCHEDULE_SERVICE) {
            return (
                <QuickLinksScheduleService
                    {...props.vehicleAttributes}
                    {...card}
                />
            );
        } else {
            return <QuickLinksCommon {...props.vehicleAttributes} {...card} />;
        }
    };

    return (
        <>
            {quickLinksContent && (
                <div className={'quick-links-grid-wrapper'}>
                    <div
                        className={`quick-links-header ${!currentVersion &&
                            'preimagine-quick-links-header'}`}
                    >
                        {quickLinksContent?.quicklinksTitle}
                    </div>
                    {currentVersion && <hr />}
                    {quickLinksContent?.quicklinksSubCopy &&
                        !currentVersion && (
                            <div className={'quick-links-subcopy'}>
                                {quickLinksContent?.quicklinksSubCopy}
                            </div>
                        )}
                    <div
                        className={'quick-links-card-wrapper'}
                        style={
                            currentVersion
                                ? { gap: '10px' }
                                : { marginTop: '28px', gap: '16px' }
                        }
                    >
                        {isMobileView &&
                            getFilteredCards()
                                .slice(0, index)
                                .map(card => cardsToRender(card))}
                        {!isMobileView &&
                            getFilteredCards().map(card => cardsToRender(card))}
                    </div>
                    {isMobileView &&
                        showAllEnabled &&
                        (currentVersion ? (
                            <div className="quick-links-show-all">
                                <SecondaryButton
                                    onClick={handleShowAll}
                                    className="show-all-button"
                                    noChevron={true}
                                >
                                    {quickLinksContent?.showallCTAText}
                                    <img
                                        alt=""
                                        src={searchPlusIcon}
                                        className="plus-circle"
                                    />
                                </SecondaryButton>
                            </div>
                        ) : (
                            <div className="preimagine-quick-links-show-all">
                                <FMButton
                                    onClick={handleShowAll}
                                    type={'primary'}
                                    label={quickLinksContent?.showallCTAText}
                                />
                            </div>
                        ))}
                </div>
            )}
        </>
    );
};
