import React, { useEffect, useState } from 'react';
import { NotificationItemButtonProps } from '../notification-item/notification-item';
import notificationError from '../../../../assets/notification-error.svg';
import notificationSuccess from '../../../../assets/success-checkmark.svg';
import { ServiceReminderContent } from '../../../../models/experiencefragments/vehicle-notifications';
import {
    DetailedNotificationItem,
    AccordionItem,
} from '../detailed-notification-item/detailed-notification-item';
import useUserProfile from '../../../../hooks/use-user-profile';
import getNumberFormatByRegion from '../../../utils/number-format-utill/number-format-mileage';
import { replacePlaceholderByValue } from '../../../utils/placeholder-util/replace-placeholder-by-value';
import DateTimeUtil from '../../../utils/date-time-util/date-time-util';
import { NextService } from '../../../../services/vehicle-service-history/vehicle-service-history';
import { LookUpType } from '../vehicle-notifications';
import { PrognosticsDataV2 } from '../../../../models/vehicle-prognostics';
import { useAnalytics } from '../../../../hooks/use-analytics';
import ServiceHandler from '../../../../services/service-handler';
import successIconSvg from '../../../../assets/check-mark.svg';
import errorWarningSvg from '../../../../assets/error-warning.svg';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { PreImagineDetailedNotificationItem } from '../detailed-notification-item/pre-imagine-detailed-notification-item';

interface Props {
    serviceReminderDetails: ServiceRemindersNotificationObject | null;
    content: ServiceReminderContent;
    vin?: string;
}
export interface ServiceRemindersNotificationObject {
    nextServiceItems: NextService | null;
    lookUpType: LookUpType;
    srCount: number;
    olCount: number;
    smCount: number;
    prognosticsData: PrognosticsDataV2;
    scheduleMaintenanceData: PrognosticsDataV2;
}

export const ServiceRemindersNotification = (props: Props) => {
    const { serviceReminderDetails, vin } = props;
    const nextServiceItems = serviceReminderDetails?.nextServiceItems;
    const prognosticsData = serviceReminderDetails?.prognosticsData;
    const scheduleMaintenanceData =
        serviceReminderDetails?.scheduleMaintenanceData;
    const dateTimeUtil = new DateTimeUtil();
    const {
        ctaUrl,
        targetBlank,
        ctaTitle,
        milesLabel,
        kmLabel,
        nextIntervalLabel,
        nextServiceDueInformation,
        nextServiceDueInformationLookAHeadNoMileage,
        nextServiceDueInformationLookBackPastDue,
        maintenanceReminderAccordionLabel,
        headlineSingle,
        headlinePlural,
        description,
        oilLifeAccordionLabel,
        oilLifeRemaining,
        estimatedMilesRemaining,
        estimatedServiceDueDate,
        scheduledMaintenanceAccordionLabel,
        alreadyDone,
        confirmCTALabel,
        cancelCTALabel,
        confirmSuccessMessage,
        confirmErrorMessage,
        noServiceRemindersHeadline,
    } = props.content;
    const profile = useUserProfile();
    const [uomDistance, setUomDistance] = useState<number>(0);
    const [alreadyDoneClicked, setAlreadyDoneClicked] = useState<boolean>();
    const [confirmResponse, setConfirmResponse] = useState<string>('');
    const MILEAGE_OVERRUN_PLACEHOLDER = '{MILEAGE_OVERRUN}';
    const kmOverrun = props?.content?.kmsOverrun || 800;
    const milesOverrun = props?.content?.milesOverrun || 500;
    const [fireEvents] = useAnalytics();
    const selectedVin: any = sessionStorage.getItem('selectedVin');

    useEffect(() => {
        if (profile) {
            if (profile.profile) {
                setUomDistance(profile.profile.uomDistance);
            }
        }
    }, [profile]);

    const isSMNotificationDismissed = () => {
        const serviceReminderString: any = sessionStorage.getItem(
            'serviceReminderDismissed'
        );
        const smParsed = JSON.parse(serviceReminderString);
        let serviceReminderJson: Map<string, [string]> = new Map<
            string,
            [string]
        >();
        if (smParsed && Object.entries(smParsed).length > 0) {
            serviceReminderJson = new Map(smParsed);
        }
        if (serviceReminderJson?.has(selectedVin)) {
            const array = serviceReminderJson.get(selectedVin);
            return (
                Array.isArray(array) &&
                array.length >= 0 &&
                array.indexOf('SM') >= 0
            );
        }
        return false;
    };

    const getSubText = () => {
        const mcPara1 = prognosticsData && prognosticsData.dtsMessage?.para1;
        const smSubtext =
            scheduleMaintenanceData &&
            scheduleMaintenanceData.dtsMessage?.para1;

        if (
            serviceReminderDetails?.olCount === 0 &&
            serviceReminderDetails?.smCount === 1
        ) {
            return isSMNotificationDismissed() ? '' : smSubtext;
        }
        if (!mcPara1) {
            return description;
        }
        if (mcPara1.indexOf(MILEAGE_OVERRUN_PLACEHOLDER) === -1) {
            return mcPara1;
        }
        let mileageOverrunText = null;
        if (uomDistance == 1) {
            mileageOverrunText = `${milesOverrun} ${milesLabel}`;
        } else {
            mileageOverrunText = `${kmOverrun} ${kmLabel}`;
        }
        return mcPara1.replace(MILEAGE_OVERRUN_PLACEHOLDER, mileageOverrunText);
    };

    const ctaHref = (): string | undefined => {
        if (ctaUrl?.includes(':vin') && vin) {
            return ctaUrl.replace(':vin', vin);
        }
        return ctaUrl;
    };

    const setFocusPrimaryButton = () => {
        setTimeout(() => {
            const primaryButton = document.querySelector(
                '.service-reminder-notification button.primary-button:first-child'
            ) as HTMLElement;
            primaryButton?.focus();
        }, 10);
    };

    const triggerOnClickAnalytics = () => {
        fireEvents(
            ['notifications-cta-onclick-event'],
            undefined,
            {
                notificationCategory: 'service-reminder',
                notificationCtaName: ctaTitle?.toLowerCase(),
            },
            false
        );
    };

    const setSMNotificationDismissed = () => {
        const serviceReminderString: any = sessionStorage.getItem(
            'serviceReminderDismissed'
        );
        const smParsed = JSON.parse(serviceReminderString);
        let serviceReminderJson: Map<string, [string]> = new Map<
            string,
            [string]
        >();
        if (smParsed && Object.entries(smParsed).length > 0) {
            serviceReminderJson = new Map(smParsed);
        }
        if (serviceReminderJson?.has(selectedVin)) {
            serviceReminderJson.get(selectedVin)?.push('SM');
        } else {
            serviceReminderJson.set(selectedVin, ['SM']);
        }
        sessionStorage.setItem(
            'serviceReminderDismissed',
            JSON.stringify(Array.from(serviceReminderJson.entries()))
        );
    };

    const resetPrognostics = () => {
        ServiceHandler.VehiclePrognosticsService.resetPrognosticsByVin(
            selectedVin
        )
            .then(result => {
                if (result?.statusCode === 2000) {
                    setSMNotificationDismissed();
                    setConfirmResponse('successful');
                } else {
                    setConfirmResponse('error');
                }
                setAlreadyDoneClicked(false);
            })
            .catch(() => {
                setConfirmResponse('error');
                setAlreadyDoneClicked(false);
            });
    };

    const alreadyButtonOnClick = () => {
        setConfirmResponse('');
        setAlreadyDoneClicked(true);
        setFocusPrimaryButton();
        triggerOnClickAnalytics();
    };

    const confirmButtonOnClick = () => {
        resetPrognostics();
        triggerOnClickAnalytics();
    };

    const cancelButtonOnClick = () => {
        setAlreadyDoneClicked(false);
        setConfirmResponse('');
        triggerOnClickAnalytics();
    };

    const primaryButtonProps: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement> = {
        link: ctaHref(),
        linkTarget: targetBlank ? '_blank' : '_self',
        ariaLabel: ctaTitle,
        children: <>{ctaTitle}</>,
        onClick: () => triggerOnClickAnalytics(),
    };

    const alreadyDoneButtonProps: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement> = {
        ariaLabel: alreadyDone,
        children: <>{alreadyDone}</>,
        onClick: () => alreadyButtonOnClick(),
    };

    const confirmButtonProps: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement> = {
        ariaLabel: confirmCTALabel,
        children: <>{confirmCTALabel}</>,
        onClick: () => confirmButtonOnClick(),
    };

    const cancelButtonProps: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement> = {
        ariaLabel: cancelCTALabel,
        children: <>{cancelCTALabel}</>,
        onClick: () => cancelButtonOnClick(),
    };

    const getFormattedDate = () => {
        const dueDate = nextServiceItems?.dueDate;
        if (dueDate) {
            const dateParts = dueDate.split('/');
            const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
            return dateTimeUtil.formatDateByRegion(formattedDate);
        }
    };
    const getDistanceLabel = () => (uomDistance === 1 ? milesLabel : kmLabel);

    const createNextServiceDueMessage = () => {
        if (nextServiceItems?.desc) return nextServiceItems?.desc;
        let result = '';
        const nextServiceDueInfo = nextServiceDueInformation || '';
        const nextServiceDueInfoLookAHeadNoMileage =
            nextServiceDueInformationLookAHeadNoMileage || '';
        const nextServiceDueInfoLookBackPastDue =
            nextServiceDueInformationLookBackPastDue || '';

        if (
            nextServiceItems?.mileageInMiles ||
            nextServiceItems?.mileageInKilometres
        ) {
            if (serviceReminderDetails?.lookUpType === LookUpType.LOOK_AHEAD) {
                result = replacePlaceholderByValue(nextServiceDueInfo, {
                    nextServiceDueDate: getFormattedDate(),
                    nextServiceDueMileage: getNumberFormatByRegion(
                        uomDistance === 1
                            ? nextServiceItems?.mileageInMiles
                            : nextServiceItems?.mileageInKilometres
                    ),
                    nextServiceMileageUnit: getDistanceLabel(),
                });
            }
            if (serviceReminderDetails?.lookUpType === LookUpType.LOOK_BACK) {
                result = replacePlaceholderByValue(
                    nextServiceDueInfoLookBackPastDue,
                    {
                        nextServiceDueDate: getFormattedDate(),
                    }
                );
            }
        } else if (
            serviceReminderDetails?.lookUpType === LookUpType.LOOK_AHEAD
        ) {
            result = replacePlaceholderByValue(
                nextServiceDueInfoLookAHeadNoMileage,
                {
                    nextServiceDueDate: getFormattedDate(),
                }
            );
        }
        return result;
    };

    const getReminderData = (reminderType: string) => {
        return reminderType === 'OL'
            ? prognosticsData
            : scheduleMaintenanceData;
    };

    const getRemainingDistance = (reminderData: any): string => {
        if (reminderData) {
            return uomDistance === 1
                ? reminderData.featureData?.remainingMiles
                : reminderData.featureData?.remainingKMs;
        }
        return '0';
    };

    const getNextIntervalRemainingDistance = (reminderData: any): string => {
        if (reminderData) {
            return uomDistance === 1
                ? reminderData.featureData?.nextIntervalMiles
                : reminderData.featureData?.nextIntervalKMs;
        }
        return '0';
    };

    const renderEstimatedDueDate = (reminderType: string) => {
        let dateString = '';
        const reminderData =
            reminderType === 'OL' ? prognosticsData : scheduleMaintenanceData;
        if (reminderData) {
            const dateData = reminderData.featureData?.estimatedDate;
            const { confidenceLevel } = reminderData;
            let withDay = true;
            if (
                confidenceLevel &&
                confidenceLevel > 1 &&
                confidenceLevel <= 3
            ) {
                dateString = `${dateData.year}-${dateData.month}`;
                withDay = false;
            } else {
                dateString = `${dateData.year}-${dateData.month}-${dateData.day}`;
            }
            return dateTimeUtil.formatDateByRegion(dateString, withDay);
        }
        return '';
    };

    const getOilLifeAccordionDescription = () => {
        return (
            <div>
                {' '}
                <p>
                    {oilLifeRemaining}:{' '}
                    {prognosticsData && prognosticsData.featureData?.iolm}%
                </p>
                {prognosticsData && prognosticsData.confidenceLevel <= 3 && (
                    <>
                        <p>
                            {' '}
                            {estimatedServiceDueDate}:{' '}
                            {renderEstimatedDueDate('OL')}
                        </p>
                        <p>
                            {estimatedMilesRemaining}:{' '}
                            {getNumberFormatByRegion(
                                getRemainingDistance(getReminderData('OL'))
                            )}{' '}
                            {getDistanceLabel()}
                        </p>
                    </>
                )}
            </div>
        );
    };

    const getScheduleMaintenanceAccordionDescription = () => {
        const confidenceLevel = scheduleMaintenanceData?.confidenceLevel || 0;
        return (
            <>
                {confidenceLevel >= 1 && confidenceLevel <= 3 && (
                    <div>
                        {nextIntervalLabel &&
                            scheduleMaintenanceData?.featureData
                                ?.nextIntervalMiles &&
                            scheduleMaintenanceData?.featureData
                                ?.nextIntervalKMs && (
                                <p>
                                    {nextIntervalLabel}:{' '}
                                    {getNumberFormatByRegion(
                                        getNextIntervalRemainingDistance(
                                            getReminderData('SM')
                                        )
                                    )}{' '}
                                    {getDistanceLabel()}
                                </p>
                            )}
                        <p>
                            {estimatedMilesRemaining}:{' '}
                            {getNumberFormatByRegion(
                                getRemainingDistance(getReminderData('SM'))
                            )}{' '}
                            {getDistanceLabel()}
                        </p>
                        <p>
                            {' '}
                            {estimatedServiceDueDate}:{' '}
                            {renderEstimatedDueDate('SM')}
                        </p>
                    </div>
                )}
                {confirmResponse && (
                    <div className={'save-messages-container'}>
                        <img
                            className={confirmResponse}
                            src={
                                confirmResponse === 'error'
                                    ? errorWarningSvg
                                    : successIconSvg
                            }
                        />
                        <span className={confirmResponse}>
                            {confirmResponse === 'error'
                                ? confirmErrorMessage
                                : confirmSuccessMessage}
                        </span>
                    </div>
                )}
            </>
        );
    };

    const getDetails = () => {
        const listItems: AccordionItem[] = [];
        const accordionTitle = maintenanceReminderAccordionLabel || '';
        const nextServiceDueMsg = createNextServiceDueMessage();
        if (serviceReminderDetails?.olCount && prognosticsData) {
            listItems.push({
                accordionHeader: oilLifeAccordionLabel,
                description: getOilLifeAccordionDescription(),
            });
        } else if (serviceReminderDetails?.smCount && scheduleMaintenanceData) {
            listItems.push({
                accordionHeader: scheduledMaintenanceAccordionLabel,
                description: getScheduleMaintenanceAccordionDescription(),
            });
        }
        if (serviceReminderDetails?.srCount)
            listItems.push({
                accordionHeader: accordionTitle,
                description: nextServiceDueMsg,
            });
        return listItems;
    };
    const getReminderCount = () => {
        let count = 0;
        if (serviceReminderDetails) count = serviceReminderDetails.srCount;
        if (serviceReminderDetails?.olCount) {
            count = count + serviceReminderDetails?.olCount;
        } else if (
            serviceReminderDetails?.smCount &&
            !isSMNotificationDismissed()
        ) {
            count = count + serviceReminderDetails?.smCount;
        }
        return count;
    };

    const showNoServiceRemindersHeadline = (count: number) => {
        return (
            count === 0 &&
            isSMNotificationDismissed() &&
            noServiceRemindersHeadline
        );
    };

    const getHeadText = (): string => {
        const count = getReminderCount();
        if (showNoServiceRemindersHeadline(count)) {
            return noServiceRemindersHeadline?.toUpperCase();
        }
        return (
            count +
            ' ' +
            (count === 1
                ? headlineSingle?.toUpperCase()
                : headlinePlural?.toUpperCase())
        );
    };

    const getIconPath = (): string => {
        return getReminderCount() > 0 ? notificationError : notificationSuccess;
    };

    const getDetailedIconPath = (): string => {
        return getReminderCount() > 0 ? errorWarningSvg : successIconSvg;
    };

    const secondaryButton = () => {
        if (
            isSMNotificationDismissed() ||
            confirmResponse === 'successful' ||
            (!!serviceReminderDetails?.smCount &&
                serviceReminderDetails?.smCount <= 0)
        ) {
            return undefined;
        }
        return alreadyDoneClicked ? cancelButtonProps : alreadyDoneButtonProps;
    };

    const primaryButton = () => {
        if (isSMNotificationDismissed() || confirmResponse === 'successful') {
            return undefined;
        }
        return alreadyDoneClicked ? confirmButtonProps : primaryButtonProps;
    };
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    return (
        <>
            {currentVersion ? (
                <DetailedNotificationItem
                    notificationAccordionDetails={getDetails()}
                    headText={getHeadText()}
                    subText={getSubText()}
                    iconPath={getIconPath()}
                    detailedIconPath={getDetailedIconPath()}
                    buttonProps={primaryButton()}
                    secondaryButtonProps={secondaryButton()}
                    notificationName={'SERVICE_REMINDER'}
                    category={getHeadText()}
                />
            ) : (
                <PreImagineDetailedNotificationItem
                    notificationAccordionDetails={getDetails()}
                    headText={getHeadText()}
                    subText={getSubText()}
                    iconPath={getIconPath()}
                    detailedIconPath={getDetailedIconPath()}
                    buttonProps={primaryButton()}
                    secondaryButtonProps={secondaryButton()}
                    notificationName={'SERVICE_REMINDER'}
                    category={getHeadText()}
                />
            )}
        </>
    );
};
