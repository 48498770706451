import { OsbMap } from '../osb-map';
import { DealerProfileInfo } from '../../../../../../models/osb-model/osb-dealer-info';
import './dealer-details-desktop.scss';
import DealerInfoTile from './dealer-info-tile';
import filterIcon from '../../../../../../assets/owners-osb/dealer-filter-icon.svg';
import { JsonFromAEMResponse } from '../../../../../../models/osb-model/osb-content-details';
import { getObjectFromAEMJson } from '../../../osb-utils';
import { DEALER_STEP_KEYS } from '../../../osb-constant';
import { FdsChevron } from '../../../../../common/chevron/fds-chevron';
import { useRef, useState } from 'react';
import serverSideService from '../../../../../../services/server-side-service/server-side-service';
import { LightDealerFilterOptions } from '../light-dealer-filter-options/light-dealer-filter-options';

interface Props {
    dealers: DealerProfileInfo[];
    preferredDealerIds: string[];
    dealerStepContent: JsonFromAEMResponse[];
    dealerStepMoreContent: JsonFromAEMResponse[];
    showViewMoreButton: boolean;
    showMaxDealers: () => void;
    handleSelectDealer: (
        dealerProfile: DealerProfileInfo,
        index: number
    ) => void;
    handleDealerDetails: (dealer: DealerProfileInfo, index: number) => void;
    refreshDealerDetailsList?: (dealerProfiles: DealerProfileInfo[]) => void;
    isDealerFilterOptionsEnabled: boolean;
}

export default function DealerDetailsDesktop(props: Props) {
    const {
        dealers,
        preferredDealerIds,
        dealerStepContent,
        dealerStepMoreContent,
        showViewMoreButton,
        showMaxDealers,
        handleSelectDealer,
        handleDealerDetails,
    } = props;
    const divRef = useRef<HTMLDivElement>(null);
    const handleDealerClick = (dealerCode: string) => {
        scrollToDealer(dealerCode);
    };
    const [showDealerFilterOptions, setShowDealerFilterOptions] = useState<
        boolean
    >(false);

    const toggleLightDealerFilterOptions = () => {
        setShowDealerFilterOptions(!showDealerFilterOptions);
    };

    const scrollToDealer = (dealerCode: string) => {
        const dealerCodeElement = divRef?.current?.querySelector(
            `[data-dealercode="${dealerCode}"]`
        );
        if (dealerCodeElement) {
            dealerCodeElement.scrollIntoView({
                block: 'center',
                inline: 'center',
            });
            if (serverSideService.isClientSide()) {
                const rect = dealerCodeElement.getBoundingClientRect();
                const top = rect.top;
                window.scrollTo({ top, behavior: 'smooth' });
            }
        }
    };
    return (
        <div className="osb-dealer-details-desktop-wrapper" id="desktop-map">
            <OsbMap
                dealers={dealers}
                fullscreenControlPosition="LEFT_TOP"
                zoomControlPosition="LEFT_BOTTOM"
                leftShiftDegree={-0.2}
                newUIDesign={true}
                onDealerClick={handleDealerClick}
                preferredDealerIds={preferredDealerIds}
            />

            <div
                className={`search-list-container ${
                    dealers.length === 1 ? 'margin-top' : ''
                }`}
                ref={divRef}
            >
                {dealers.length > 1 &&
                    props.isDealerFilterOptionsEnabled &&
                    !showDealerFilterOptions && (
                        <div className="filter-container">
                            <button
                                className="filter-button"
                                onClick={toggleLightDealerFilterOptions}
                            >
                                <img
                                    src={filterIcon}
                                    alt="dealer-filter-icon"
                                />
                                <span className="filters-text">
                                    {' '}
                                    {getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.DEALER_FILTER_LABEL,
                                        dealerStepMoreContent
                                    )}
                                </span>
                            </button>
                        </div>
                    )}
                {!showDealerFilterOptions && (
                    <>
                        {dealers.map((dealer, index) => (
                            <div
                                data-testid="search-list-container"
                                key={dealer.dealerCode}
                                data-dealercode={dealer.dealerCode}
                                className="dealer-info-box-wrapper"
                            >
                                {preferredDealerIds.includes(
                                    dealer.dealerCode
                                ) &&
                                    getObjectFromAEMJson(
                                        DEALER_STEP_KEYS.PREFERRED_DEALER_CAPTION,
                                        dealerStepContent
                                    ) && (
                                        <div className="preferred-dealer-label">
                                            {getObjectFromAEMJson(
                                                DEALER_STEP_KEYS.PREFERRED_DEALER_CAPTION,
                                                dealerStepContent
                                            )}
                                        </div>
                                    )}
                                <DealerInfoTile
                                    dealer={dealer}
                                    index={index}
                                    dealerStepContent={dealerStepContent}
                                    dealerStepMoreContent={
                                        dealerStepMoreContent
                                    }
                                    onSelectDealer={(
                                        dealer: DealerProfileInfo,
                                        index: number
                                    ) => handleSelectDealer(dealer, index)}
                                    onSelectDealerDetails={(
                                        dealer: DealerProfileInfo,
                                        index: number
                                    ) => handleDealerDetails(dealer, index)}
                                />
                            </div>
                        ))}
                        {showViewMoreButton && (
                            <div className="view-more-container">
                                <div
                                    className="view-more-button"
                                    onClick={showMaxDealers}
                                >
                                    <div className="view-more-text">
                                        {getObjectFromAEMJson(
                                            DEALER_STEP_KEYS.LIGHT_JOURNEY_DEALER_VIEWMORE_BUTTON,
                                            dealerStepMoreContent
                                        )}
                                    </div>
                                    <FdsChevron
                                        direction={'down'}
                                        type="filled"
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {showDealerFilterOptions && (
                    <div className="dealer-filter-options-container">
                        <LightDealerFilterOptions
                            toggleLightDealerFilterOptions={
                                toggleLightDealerFilterOptions
                            }
                            dealerStepMoreContent={props.dealerStepMoreContent}
                            refreshDealerDetailsList={
                                props.refreshDealerDetailsList
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
