import { useContext, useEffect, useState } from 'react';
import ServerContext from '../../../../../contexts/serverContext';
import ServiceHandler from '../../../../../services/service-handler';

export const formField = (aemContent: any) => {
    const inputField = [
        {
            id: 1,
            name: 'name',
            label: aemContent.trailerNickNameLabel,
            pattern: `^([a-zA-Z0-9$!%*#?& ]){0,20}$`,
            maxLength: 20,
        },
    ];

    return inputField;
};

/* Final Page Form Fields - Form 2 */
export const finalFormInput = (aemContent: any) => {
    const smartNavPattern = `^(0|[1-9]\\d*)(\\.\\d+)?$`;
    const inputField = [
        {
            id: 1,
            name: 'smartNavLength',
            label: aemContent.lengthLabel,
            pattern: smartNavPattern,
            description: aemContent.lengthDesc,
            unit: aemContent.lengthUnit,
        },
        {
            id: 2,
            name: 'smartNavWidth',
            label: aemContent.widthLabel,
            pattern: smartNavPattern,
            description: aemContent.widthDesc,
            unit: aemContent.lengthUnit,
        },
        {
            id: 3,
            name: 'smartNavHeight',
            label: aemContent.heightLabel,
            pattern: smartNavPattern,
            description: aemContent.heightDesc,
            unit: aemContent.lengthUnit,
        },
        {
            id: 4,
            name: 'smartNavWeight',
            label: aemContent.weightLabel,
            pattern: smartNavPattern,
            maxLength: 5,
            description: aemContent.weightDesc,
            unit: aemContent.weightUnit,
        },
        {
            id: 5,
            name: 'distance',
            label: aemContent.mileageLabel,
            pattern: smartNavPattern,
            maxLength: 8,
            description: aemContent.mileageDesc,
            unit: aemContent.mileageUnit,
        },
    ];
    return inputField;
};

/* Form 2 dropdown fields*/
export const finalFormDropDowns = (aemContent: any) => {
    const [bevState, setBevState] = useState<boolean>(false);
    const serverContext = useContext(ServerContext);
    const userSelectedVin =
        typeof window === 'undefined'
            ? serverContext.profile?.vin
            : window.sessionStorage.getItem('selectedVin');

    const getIsBevVehicle = (selectedVin: any) => {
        ServiceHandler.VehicleEngineTypeService.request(selectedVin)
            .then(VehicleEngineType => {
                setBevState(VehicleEngineType.isBev === true);
            })
            .catch(() => {
                setBevState(false);
            });
    };

    useEffect(() => {
        if (userSelectedVin) {
            getIsBevVehicle(userSelectedVin);
        }
    }, [userSelectedVin]);

    const dropDownFields = [
        {
            id: 1,
            className: 'type',
            label: aemContent.trailerType,
            options:
                bevState === true
                    ? [{ label: aemContent.conventionalLabel, value: '1' }]
                    : [
                          { label: aemContent.conventionalLabel, value: '1' },
                          { label: aemContent.fifthWheelLabel, value: '2' },
                          { label: aemContent.gooseNeckLabel, value: '3' },
                      ],
        },
        {
            id: 2,
            className: 'brakeType',
            label: aemContent.brakeType,
            options: [
                { label: aemContent.noneLabel, value: '0' },
                { label: aemContent.electricLabel, value: '1' },
                { label: aemContent.hydraulicLabel, value: '2' },
            ],
        },
    ];
    return dropDownFields;
};
