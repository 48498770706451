import React from 'react';
import './service-centre-step-view.scss';
import ServiceCentreHandler from '../../../components/sections/osb-v3/service-centre-step/service-centre-handler/service-centre-handler';

export function ServiceCentreStepView() {
    return (
        <div className="osb-v3-service-centre-step-view-container">
            <ServiceCentreHandler />
        </div>
    );
}
