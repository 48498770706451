import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';

export class BrandUtil {
    private LINCOLN = 'lincoln';
    private LINCOLN_DEMO = 'lincolndemo';
    private FORD = 'ford';
    private FORD_DEMO = 'demo';

    brandName: string;
    isLincoln: boolean;
    isFord: boolean;

    constructor() {
        const { brand } = new AppConfigurationService();
        this.isLincoln = this.getIsLincoln(brand);
        this.isFord = this.getIsFord(brand);
        this.brandName = this.getBrandName(brand);
    }

    getIsFord(brand: string) {
        return brand === this.FORD_DEMO || brand === this.FORD;
    }

    getIsLincoln(brand: string) {
        return brand === this.LINCOLN_DEMO || brand === this.LINCOLN;
    }

    getBrandName(brand: string) {
        if (this.getIsFord(brand)) {
            return this.FORD;
        }
        if (this.getIsLincoln(brand)) {
            return this.LINCOLN;
        }
        return brand;
    }

    getFormattedBrandName() {
        return this.brandName.charAt(0).toUpperCase() + this.brandName.slice(1);
    }
}
