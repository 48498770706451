export const displayOfferUrl = (
    couponId: any,
    couponOffer: any,
    categoryName: string
) => {
    couponOffer = couponOffer.replace(/,/g, '');
    let offer = couponOffer;

    if (offer.split('$')[1]) {
        const beforeDollar = offer.split('$')[0];
        const afterDollar = offer.split('$')[1];
        const dollar =
            afterDollar.split(' ')[0] === 1 ? '-dollar-' : '-dollars-';
        offer =
            beforeDollar +
            afterDollar.split(' ')[0] +
            dollar +
            afterDollar.split(afterDollar.split(' ')[0])[1];
    }

    if (couponOffer.split('$')[2]) {
        const afterSecondDollar = couponOffer.split('$')[2];
        const seconddollar =
            afterSecondDollar.split(' ')[0] === 1 ? '-dollar-' : '-dollars-';
        offer =
            offer +
            afterSecondDollar.split(' ')[0] +
            seconddollar +
            afterSecondDollar.split(afterSecondDollar.split(' ')[0])[1];
    }

    offer = offer.replace(/\W+/g, '-').toLowerCase();
    if (offer.slice(-1) === '-') {
        offer = offer.substr(0, offer.length - 1);
    }

    return `/service-maintenance/coupons-offers-rebates/${categoryName}/?couponId=${couponId.toLowerCase()}&name=${offer}`;
};
