import { useContent } from '../../hooks/use-server-content';

export interface VehicleNotFoundViewFragment {
    breadcrumbLabel: string;
    vinLabel: string;
    regNoLabel: string;
    vehicleNotFoundDescription: string;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
}

const useVehicleNotFoundViewContent = (): VehicleNotFoundViewFragment => {
    let contentFragment: any = null;
    const [content, getValueByName] = useContent('common', 'vehicle-not-found');
    if (content) {
        contentFragment = {};
        content?.elements.forEach(element => {
            if (element.name && element.value)
                contentFragment[element.name] = getValueByName(element.name);
        });
    }
    return contentFragment as VehicleNotFoundViewFragment;
};

export default useVehicleNotFoundViewContent;
