import { DealerDetails } from '../../../models/osb-model/osb-dealer-info';
import OsbUtilService from '../osb-util-service/osb-util-service';
import GoogleMapService from './google-map-service';

export default class DealerSearchService {
    static bingDealerSearchByLocation = (
        conf: any,
        radius: number,
        maxResult: number,
        callback: any,
        polygon: string
    ) => {
        if (conf) {
            if (conf.length > 0) {
                const matchParameters = { CountryCode: '' };
                matchParameters.CountryCode = OsbUtilService.getAppConfiguration().threeLetterCountryCode;
                GoogleMapService.searchDealersByDistance(
                    conf[0],
                    radius,
                    maxResult,
                    (dealers: any) => {
                        callback(dealers);
                    },
                    matchParameters,
                    polygon
                );
            }
        }
    };

    static bingDealerByName = (
        maxResult: number,
        callback: any,
        polygon: string,
        dealerCode: string,
        osbTwoLetterCountryCode: string
    ) => {
        const matchParameters = {
            CountryCode: '',
            DealerID: osbTwoLetterCountryCode + dealerCode,
        };
        matchParameters.CountryCode = OsbUtilService.getAppConfiguration().threeLetterCountryCode;
        GoogleMapService.searchDealersByProperties(
            maxResult,
            (dealers: any) => {
                callback(dealers);
            },
            matchParameters
        );
    };

    static getIdFromBing = (
        item: { DealerID: string },
        numberOfLeadingCharToStrip: number
    ) => {
        return (
            item.DealerID &&
            (numberOfLeadingCharToStrip > 0
                ? item.DealerID.substring(numberOfLeadingCharToStrip).trim()
                : item.DealerID.trim())
        );
    };

    static filterOnOsbDealerIds = (
        allOsbDealers: DealerDetails[] | any,
        bingDealers: any,
        numberOfLeadingCharToStrip: number
    ) => {
        let _osbEnabledDealerFromBing: any = [];
        allOsbDealers &&
            allOsbDealers.forEach((dealer: { dealerCode: any }) => {
                const dealerCode = dealer.dealerCode.trim();
                _osbEnabledDealerFromBing.push(
                    bingDealers.find(
                        (d: { DealerID: string }) =>
                            DealerSearchService.getIdFromBing(
                                d,
                                numberOfLeadingCharToStrip
                            ) === dealerCode
                    )
                );
            });
        // remove undefined objects from the array.
        _osbEnabledDealerFromBing = _osbEnabledDealerFromBing.filter(function(
            element: undefined
        ) {
            return element !== undefined;
        });
        return _osbEnabledDealerFromBing;
    };
}
