export const KEYS = {
    LAST_MESSAGE: 'chatbot-session',
    CONVERSATION_ID: 'chatbot-conversation',
    TAB_REGISTRY: 'chatbot-tabs',
};
export const ERROR_TYPES = {
    SELF_CLOSING: 'self-closing',
    ACTIONABLE: 'actionable',
};
export const MESSAGE_TYPES = {
    TEXT: 0,
    QUICK_REPLIES: 2,
    CARDS: 5,
    KNOWLEDGE_BASE: 1000,
};
export const TAB_TIMEOUT = 30 * 1_000;
export const TAB_CHECK_INTERVAL = 10 * 1_000;
