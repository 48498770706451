import { BreadcrumbWrapperReimagine } from '../../components/common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';
import { ViewTemplate } from '../../components/sections';
import { OilLifeDetails } from '../../components/sections/vehicle-health/components/oil-life/oil-life-details';
import React from 'react';
import { useOilLifeDetailsContent } from '../../components/sections/vehicle-health/hook/use-oil-life-details-content';
import { useVehicleHealthPageComponents } from '../../components/sections/vehicle-health/components/vehicle-health-page-components';

export const OilLifeDetailsView = () => {
    const oilLifeDetailsContent: any = useOilLifeDetailsContent();
    const [vehicleHealthComponent] = useVehicleHealthPageComponents();
    const currentRoute = window.location.pathname;
    const VhaAlertBreadCrumb = () => {
        const vhaUrl = currentRoute.endsWith('/')
            ? currentRoute.substring(0, currentRoute.length - 1)
            : currentRoute;

        const handleClick = () => {
            window.location.href = `${vhaUrl.substr(
                0,
                vhaUrl.lastIndexOf('/')
            )}`;
        };

        return (
            <>
                <span
                    className="breadcrumb-link-ellipses-expanded"
                    onClick={handleClick}
                >
                    <span>{vehicleHealthComponent?.vehicleHealthTitle}</span>
                </span>
                <span className="back-slash">{'/'}</span>
            </>
        );
    };
    return (
        <>
            {oilLifeDetailsContent && (
                <ViewTemplate
                    page={
                        oilLifeDetailsContent?.oilLifeDetails?.oilLifeBreadcrum
                    }
                >
                    <BreadcrumbWrapperReimagine
                        currentPage={
                            oilLifeDetailsContent.oilLifeDetails
                                .oilLifeBreadcrum
                        }
                        hashId="support"
                        title={<VhaAlertBreadCrumb />}
                    />
                    <OilLifeDetails />
                </ViewTemplate>
            )}
        </>
    );
};

export default OilLifeDetailsView;
