import React from 'react';
import { DisclaimerContent } from '../../../services/disclaimers-service/disclaimers-service';

import './disclaimer-list.scss';

interface Props {
    disclaimers: DisclaimerContent[];
}

export function DisclaimerList(props: Props) {
    return (
        <div className="disclaimer-list-container">
            <ol className="disclaimer-list">
                {props.disclaimers.map(disclaimer => (
                    <li
                        className="disclaimer-list-item"
                        key={`${disclaimer.symbol} ${disclaimer.content}`}
                    >
                        <sup>{disclaimer.symbol}</sup>
                        {disclaimer.content}
                    </li>
                ))}
            </ol>
        </div>
    );
}
