import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';

export const getVwAccordionContent = (
    syncOption: string,
    vehicleInfo: VehicleAttributes | undefined,
    experienceContent: SyncMapStatus | null,
    syncGeneration: string,
    vwCards: SyncMapsCard[] | undefined,
    syncMapContent: SyncMapStatus | null,
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined,
    supportCommonContent: any,
    releaseDate: string
) => {
    return {
        selectedOption: syncOption,
        hasVw:
            vehicleInfo?.citivanVin &&
            !experienceContent?.vwHide &&
            experienceContent?.vwHeading !== undefined,
        hasUpdate: true,
        syncGeneration,
        cards: vwCards || [],
        heading: syncMapContent?.vwHeading,
        title: syncMapContent?.vwMapsUpdateAvailable,
        subtitle:
            syncMapContent?.vwUpdateAvailableDescription +
            ' ' +
            supportCommonContent?.releaseDateLabel +
            ' ' +
            releaseDate,
        radioButtons: false,
        upToDate: syncMapContent?.vwMapsUpToDate,
        syncMapContent: syncMapContent,
        downloadUrl: syncMapsUpdates?.vwMapsUpdateResponse?.downloadUrl,
        downloadUrlError: syncMapsUpdates?.vwMapsUpdateResponse?.downloadUrl
            ? false
            : true,
        notAvailable: syncMapContent?.vwMapsNotAvailable,
        accordionType: 'vw',
        releaseDate: releaseDate,
        eulaURL:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.eulaURL,
        error: '',
    };
};
