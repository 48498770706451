import React, { useEffect, useState } from 'react';
import { HeaderType } from '../../smart-tiles/smart-tile/smart-tile';
import RecallsService, {
    RecallsApiResponse,
} from '../../../services/recalls-service/recalls-service';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import serverSideService from '../../../services/server-side-service/server-side-service';

interface Props {
    noProgramsText?: string;
    vin?: string;
    programText: string;
    programsText: string;
}

const VehicleChecklistCSP = (
    props: Props & VehicleAttributes & VehicleChecklistContent
) => {
    const [cspDataForTile, setCspDataForTile] = useState<RecallsApiResponse>();

    if (serverSideService.isClientSide() && props.vin) {
        localStorage.setItem('USER_VIN', props.vin);
    }
    useEffect(() => {
        if (props.vin)
            new RecallsService().request(props.vin).then(response => {
                if (response) {
                    setCspDataForTile(response);
                }
            });
    }, [props.vin]);

    if (cspDataForTile) {
        const csp = cspDataForTile?.cspCount;
        const ctaHref = (): string => {
            if (props.vin && props.dynamicCtaPath) {
                return props.dynamicCtaPath.replace('%vin', props.vin);
            }
            return props.staticCtaPath;
        };
        const dynamicText = (): {
            type: HeaderType;
            description?: string;
        } => {
            if (csp > 1) {
                return {
                    type: 'severe',
                    description: csp + ' ' + props.programsText,
                };
            }
            if (csp === 1)
                return {
                    type: 'moderate',
                    description: csp + ' ' + props.programText,
                };
            else {
                return {
                    type: 'none',
                    description: props.noProgramsText,
                };
            }
        };

        return (
            <VehicleChecklist
                type={''}
                {...props}
                header={props.title || ''}
                description={
                    dynamicText().description || props.staticDescription
                }
                ctaPath={ctaHref()}
                ctaTitle={props.ctaTitle}
                iconPath={props.iconPath}
            />
        );
    } else {
        return (
            <VehicleChecklist
                description={props.staticDescription}
                type={props.cardTypes}
                header={props.title}
                ctaTitle={props.ctaTitle}
                ctaPath={props.staticCtaPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
                iconPath={props.iconPath}
            />
        );
    }
};

export default VehicleChecklistCSP;
