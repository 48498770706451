import { VehicleData } from '../../../models/osb-model/osb-vehicle-step';
import { OSBAPIResponse } from '../service-orchestration/service-adapter';
import {
    ErrorResponse,
    transformErrorResponse,
} from '../../../components/sections/owners-osb/osb-utils';
import { DSLAPIService } from '../service-orchestration/service-dsl';
import { DSL_API_PATH, PC_DEFAULT_DEALER_CODE } from '../../../constants';
import {
    OsbVehiclePostRequest,
    VehicleInfo,
} from '../../../models/osb-model/osb-vehicle-lookup';
import { PrognosticsDataV2 } from '../../../models/vehicle-prognostics';
import { SERVICES_STEP_KEYS } from '../../../components/sections/owners-osb/osb-constant';
import { Bookable } from '../../../hooks/owners-osb/use-all-osb-state';

export class OSBVehicleLookUpClient {
    public getVehicleInfo(args: {
        vehicleData: VehicleData;
        delivery: string;
        source: string;
    }) {
        const osbVehiclePostRequest: OsbVehiclePostRequest = {
            vehicle: args.vehicleData,
            delivery: args.delivery,
            source: args.source,
        };
        return new Promise<OSBAPIResponse<VehicleInfo>>((resolve, reject) => {
            const transformedResponse: OSBAPIResponse<VehicleInfo> = {
                value: {} as VehicleInfo,
                responseError: {} as ErrorResponse,
            };
            new DSLAPIService()
                .postCallServiceAPI<OsbVehiclePostRequest, VehicleInfo>(
                    osbVehiclePostRequest,
                    PC_DEFAULT_DEALER_CODE,
                    DSL_API_PATH.OSB_VEHICLE_LOOKUP_POST
                )
                .then(response => {
                    try {
                        transformedResponse.value = response;
                        resolve(transformedResponse);
                    } catch (err) {
                        if (err !== '') {
                            transformedResponse.responseError.errorMessage =
                                'VEHICLE_LOOKUP_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    }
                })
                .catch(_error => {
                    transformedResponse.responseError = transformErrorResponse(
                        _error
                    );
                    reject(transformedResponse.responseError);
                });
        });
    }
    public postVehicleInfo(args: {
        vehicleData: VehicleData;
        prognosticsData?: PrognosticsDataV2;
        dealerCode: string;
        bookable: Bookable;
    }) {
        const osbVehicleRequest: OsbVehiclePostRequest = {
            vehicle: args.vehicleData,
            source: args.bookable.source,
            delivery: args.bookable.delivery,
        };
        if (
            args.prognosticsData?.featureType ===
            SERVICES_STEP_KEYS.SM_FEATURE_TYPE
        ) {
            osbVehicleRequest.prognosticsData = args.prognosticsData;
        }
        return new Promise<OSBAPIResponse<VehicleInfo>>((resolve, reject) => {
            const transformedResponse: OSBAPIResponse<VehicleInfo> = {
                value: {} as VehicleInfo,
                responseError: {} as ErrorResponse,
            };
            new DSLAPIService()
                .postCallServiceAPI<OsbVehiclePostRequest, VehicleInfo>(
                    osbVehicleRequest,
                    args.dealerCode,
                    DSL_API_PATH.OSB_VEHICLE_LOOKUP_POST
                )
                .then((response: VehicleInfo) => {
                    try {
                        transformedResponse.value = response;
                        resolve(transformedResponse);
                    } catch (err) {
                        if (err !== '') {
                            transformedResponse.responseError.errorMessage =
                                'VEHICLE_LOOKUP_ERROR: ' +
                                (err as Error)?.message;
                            reject(transformedResponse.responseError);
                        }
                    }
                })
                .catch(_error => {
                    transformedResponse.responseError = transformErrorResponse(
                        _error
                    );
                    reject(transformedResponse.responseError);
                    console.error(
                        'Rejected the promise - ',
                        transformedResponse.responseError
                    );
                });
        });
    }
}
