import { useExperienceContent } from '../../../../hooks/use-server-content';
import { OilLifeDetailsPage } from '../../../../models/experiencefragments/oil-life-details';
import { ContainerFragment } from '../../../../models/service-maintenance-models/vehicle-health-alerts';

export const useOilLifeDetailsContent = (): OilLifeDetailsPage => {
    const ITEMS = ':items';
    const [containerContent] = useExperienceContent<ContainerFragment>(
        'vehicles',
        'oil-life-details',
        'container'
    );
    return containerContent?.[ITEMS] as OilLifeDetailsPage;
};
