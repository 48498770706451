import { initializeStore, useStore } from '../../store/osb-store/global-store';
import {
    StoreState,
    OsbImageServiceState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import { useCallback } from 'react';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { AcceptedFileInfo } from '../../components/sections/owners-osb/common/osb-file-uploader/osb-file-uploader';
import { OsbImageService } from '../../models/osb-model/osb-image-upload';
import { useOSBStep } from './use-osb-step';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';

export const getInitialOsbImageServiceState = (): OsbImageService => {
    return {
        osbImageContent: '',
        uploadedImage: [],
    };
};
//Configure and initialize service step state in global store
const configureServiceStepStore = () => {
    const actions = {
        //Action to set the manual service step details to global store
        SET_IMAGE_SERVICE_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as OsbImageServiceState).osbImageUpload,
                ...(payload as OsbImageService),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_IMAGE_UPLOAD_KEY,
                newState,
                localStorageExpiry
            );
            return { osbImageUpload: newState };
        },

        SET_IMAGE_SERVICE_STEP_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as OsbImageServiceState).osbImageUpload,
                ...(payload as {
                    [key: string]: string | AcceptedFileInfo[];
                }),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_IMAGE_UPLOAD_KEY,
                newState,
                localStorageExpiry
            );
            return { osbImageUpload: newState };
        },
    };
    initializeStore(actions, {
        osbImageUpload:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_IMAGE_UPLOAD_KEY
            ) || getInitialOsbImageServiceState(),
    });
};

configureServiceStepStore();

// custom hook for manual service step to handle the actions and updating payload in global store
export const useOsbImageUpload = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalOSBImageServiceStep = (
        osbImageUpload: OsbImageService,
        shouldReload = true
    ) => {
        dispatch(
            'SET_IMAGE_SERVICE_STEP',
            osbImageUpload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const setOSBImageServiceStepPayload = (
        payload: {
            [key: string]: string | AcceptedFileInfo[];
        },
        shouldReload = true
    ) => {
        dispatch(
            'SET_IMAGE_SERVICE_STEP_PAYLOAD',
            payload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetOSBImageServiceStep = (shouldReload = true) => {
        dispatch(
            'SET_IMAGE_SERVICE_STEP',
            getInitialOsbImageServiceState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);
    const getObject = (key: string, data: any[]) => {
        if (data) {
            const item = data.filter(part => {
                return part.name === key;
            });
            if (
                item &&
                item.length > 0 &&
                Object.prototype.hasOwnProperty.call(item[0], 'value')
            ) {
                return item[0].value;
            }
        }
        return '';
    };
    return {
        osbImageUploadStep: (globalState as OsbImageServiceState)
            .osbImageUpload,
        setOSBImageServiceStep: setGlobalOSBImageServiceStep,
        setOSBImageServiceStepPayload: setOSBImageServiceStepPayload,
        resetOSBImageServiceStep: resetOSBImageServiceStep,
        callContentService: callContentService,
        getObject: getObject,
    };
};
