import {
    CardState,
    ServiceReminder,
} from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';

export const getServiceRemindersState = (serviceRemindersData: any) => {
    if (serviceRemindersData.featureType === 'SM') {
        if (
            serviceRemindersData.urgency === 'H' ||
            serviceRemindersData.urgency === 'U'
        ) {
            return CardState.ALERT;
        } else if (serviceRemindersData.urgency === 'M') {
            return CardState.WARNING;
        } else {
            return CardState.NORMAL;
        }
    } else {
        return CardState.NORMAL;
    }
};

export const getServiceRemindersText = (
    serviceRemindersData: any,
    serviceRemindersContent: ServiceReminder
) => {
    if (serviceRemindersData.featureType === 'SM') {
        if (serviceRemindersData.urgency === 'U') {
            return (
                serviceRemindersContent?.serviceRemindersText +
                ' ' +
                (serviceRemindersData.featureData.estimatedDate.day || '') +
                '/' +
                (serviceRemindersData.featureData.estimatedDate.month || '') +
                '/' +
                (serviceRemindersData.featureData.estimatedDate.year || '')
            );
        } else {
            return (
                serviceRemindersContent?.noServiceRemindersText +
                ' ' +
                (serviceRemindersData.featureData.estimatedDate.month || '') +
                '/' +
                (serviceRemindersData.featureData.estimatedDate.year || '')
            );
        }
    } else {
        return serviceRemindersContent?.noServiceRemindersText;
    }
};
