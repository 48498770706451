import { OSBPayload } from '../../../../services/shortcode-service/shortcode-service';
import { DealerStep } from '../../../../models/osb-model/osb-dealer-step';
import { OSBStep } from '../../../../models/osb-model/osb-step';
import { VehicleStep } from '../../../../models/osb-model/osb-vehicle-step';
import {
    DEALER_STEP_KEYS,
    LIGHT_JOURNEY_ANALYTICS,
    SERVICE_FLOW,
} from '../osb-constant';
import { getBrand, getVehicleAttributesForAnalytics } from '../osb-utils';
import { PersonalDetailErrorField } from '../../../../models/osb-model/osb-personal-details';

export const clearDigitalData = () => {
    (window as any).digitaldata = {};
};

export const clearErrorCodes = () => {
    if ((window as any).digitaldata?.page?.errorTracking) {
        delete (window as any).digitaldata?.page?.errorTracking;
    }
    if ((window as any).digitaldata?.page?.errorCodes) {
        delete (window as any).digitaldata?.page?.errorCodes;
    }
    if ((window as any).digitaldata?.onclick?.errorCodes) {
        delete (window as any).digitaldata?.onclick?.errorCodes;
    }
};

export const getMileage = (osbVehicleStep: VehicleStep) => {
    if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.vin &&
        osbVehicleStep.vehicleDetails.vin.length > 0
    ) {
        return osbVehicleStep.vinMileage;
    } else if (
        osbVehicleStep.vehicleDetails.modelName &&
        osbVehicleStep.vehicleDetails.modelName.length > 0 &&
        osbVehicleStep.vehicleDetails.registrationNumber &&
        osbVehicleStep.vehicleDetails.registrationNumber.length > 0
    ) {
        return osbVehicleStep.vinMileage;
    } else {
        return osbVehicleStep.manualMileage;
    }
};

export const getDeepLinkType = (osbStep: OSBStep) => {
    if (osbStep.isDealerDeepLinked && osbStep.isVINDeepLinked) {
        return LIGHT_JOURNEY_ANALYTICS.VIN_AND_DEALER_DEEP_LINKED;
    } else if (osbStep.isDealerDeepLinked) {
        return LIGHT_JOURNEY_ANALYTICS.DEALER_DEEP_LINKED;
    } else if (osbStep.isVINDeepLinked) {
        return LIGHT_JOURNEY_ANALYTICS.VIN_DEEP_LINKED;
    } else {
        return LIGHT_JOURNEY_ANALYTICS.NO_DEEP_LINKING;
    }
};

export const triggerDealerSearchLoadAnalytics = (
    osbStep: OSBStep,
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const osbDealerSearchStartPageLoad: OSBPayload = {
        deepLinkType: getDeepLinkType(osbStep),
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DEALER_SEARCH_START,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbDealerSearchStartPageLoad,
        },
        false
    );
};

export const triggerDealerSearchResultsAnalytics = (
    osbDealerStep: DealerStep,
    osbStep: OSBStep,
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const osbDealerSearchResults: OSBPayload = {
        userLocationSearchTerm: osbDealerStep.selectedLocation
            ? osbDealerStep.selectedLocation
            : ' ',
        searchLocationType:
            osbDealerStep.searchLocationType === 'geolocation'
                ? 'current location'
                : 'manual location',
        searchType:
            osbDealerStep.searchType === DEALER_STEP_KEYS.LOCATION
                ? DEALER_STEP_KEYS.LOCATION
                : DEALER_STEP_KEYS.DEALER_NAME,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DEALER_RESULTS_MAP,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbDealerSearchResults,
        },
        false
    );
};

export const triggerSelectDealerCtaAnalytics = (
    dealerCode: string,
    searchDepth: string,
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    const osbSelectDealer: OSBPayload = {
        dealerID: dealerCode,
        searchDepth: searchDepth,
    };

    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DEALER_CTA,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerSelectPreferredDealerAnalytics = (
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_PREFERRED_DEALER,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
        },
        false
    );
};

export const triggerDealerSearchResultsInitiateFilterAnalytics = (
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_BOOKING_DEALER_SEARCH_RESULTS_INITIATE_FILTERS,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
        },
        false
    );
};

export const triggerSelectServiceLoadAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbSelectServicePageLoad: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        searchDepth: osbDealerStep.searchDepth,
        deepLinkType: getDeepLinkType(osbStep),
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_SERVICE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectServicePageLoad,
        },
        false
    );
};

export const triggerDeliveryOptionsLoadAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbDeliveryOptionsPageLoad: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        searchDepth: osbDealerStep.searchDepth,
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DELIVERY_OPTION,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbDeliveryOptionsPageLoad,
        },
        false
    );
};

export const triggerSelectServiceCardAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    cardType: string
) => {
    clearErrorCodes();
    let cardName = '';
    if (cardType === SERVICE_FLOW.GENERAL_APPOINTMENT_SERVICE_KEY) {
        cardName = LIGHT_JOURNEY_ANALYTICS.GENERAL_APPOINTMENT_CARD_TYPE;
    } else if (cardType === SERVICE_FLOW.SPECIFIC_SERVICE_KEY) {
        cardName = LIGHT_JOURNEY_ANALYTICS.SPECIFIC_SERVICE_CARD_TYPE;
    } else if (cardType === SERVICE_FLOW.MOBILE_SERVICE_KEY) {
        cardName = LIGHT_JOURNEY_ANALYTICS.MOBILE_SERVICE_CARD_TYPE;
    } else if (cardType === SERVICE_FLOW.DEALER_SERVICE_KEY) {
        cardName = LIGHT_JOURNEY_ANALYTICS.DEALER_SERVICE_CARD_TYPE;
    }
    const osbSelectServiceCard: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        cardType: cardName,
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_SERVICE_CARD,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectServiceCard,
        },
        false
    );
};

export const triggerSpecificServiceLoadAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const osbSpecificServicePageLoad: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        searchDepth: osbDealerStep.searchDepth,
        deepLinkType: getDeepLinkType(osbStep),
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_SERVICE_SPECIFIC_SERVICE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSpecificServicePageLoad,
        },
        false
    );
};

export const triggerSpecificServicePanelExpansionsAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    panelType: string
) => {
    clearErrorCodes();
    const osbSpecificServicePanelExpansions: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        panelType: panelType,
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SPECIFIC_SERVICE_PANEL_EXPANSIONS,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSpecificServicePanelExpansions,
        },
        false
    );
};

export const triggerApplyVoucherCtaAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    voucherCode: string,
    applyRemoveVoucher: string
) => {
    clearErrorCodes();
    const osbSelectDealer: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        bookingVoucherCode: voucherCode,
        applyRemoveVoucher: applyRemoveVoucher,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_APPLY_VOUCHER_CTA,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerApplyVoucherConfirmCtaAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    voucherCode: string
) => {
    clearErrorCodes();
    const osbSelectDealer: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_APPLY_CONFIRM_VOUCHER_CTA,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerInvalidVoucherCtaAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    voucherCode: string
) => {
    clearErrorCodes();
    const osbSelectDealer: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_INVALID_VOUCHER,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerErrorVoucherCtaAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    voucherCode: string
) => {
    clearErrorCodes();
    const osbSelectDealer: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_ERROR_VOUCHER,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerRemoveVoucherCtaAnalytics = (
    osbDealerStep: DealerStep,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    voucherCode: string,
    applyRemoveVoucher: string
) => {
    clearErrorCodes();
    const osbSelectDealer: OSBPayload = {
        dealerID: osbDealerStep.selectedDealerProfile.dealerCode,
        bookingVoucherCode: voucherCode,
        applyRemoveVoucher: applyRemoveVoucher,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_REMOVE_VOUCHER_CTA,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

const formBookingProductString = (selectedServices: any) => {
    const productList: string[] = [];
    selectedServices.length > 0 &&
        selectedServices.forEach((service: any) => {
            let servicePrice = service.price;

            if (service.price > service.priceAfterDiscount) {
                servicePrice = service.priceAfterDiscount;
            }
            const productString = `service booking;${service.serviceName.toLowerCase()};1;${servicePrice}`;
            productList.push(productString);
        });
    return productList.join(',');
};
export const triggerVehicleInfoAnalytics = (
    modelName: string,
    modelYear: string,
    InputMileage: string,
    storeVoucherCode: string,
    fireEvents: Function
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const vehicle = {
        year: Number(modelYear),
        model: modelName,
        make: getBrand(),
    };
    const osbVehiclePageLoad: OSBPayload = {
        mileage: InputMileage,
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_VEHICLE_INFO,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(vehicle),
            osbPayload: osbVehiclePageLoad,
        },
        false
    );
};
export const triggerVehicleReviewAnalytics = (
    modelName: string,
    modelYear: string,
    storeVoucherCode: string,
    fireEvents: Function
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const vehicle = {
        year: Number(modelYear),
        model: modelName,
        make: getBrand(),
    };
    const osbVehicleReviewPageLoad: OSBPayload = {
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_VEHICLE_REVIEW,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(vehicle),
            osbPayload: osbVehicleReviewPageLoad,
        },
        false
    );
};
export const triggerVehicleNotFoundAnalytics = (
    storeVoucherCode: string,
    fireEvents: Function
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const osbVehicleNotFoundPageLoad: OSBPayload = {
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_VEHICLE_NOT_FOUND,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbVehicleNotFoundPageLoad,
        },
        false
    );
};
export const triggerVehicleRecallFoundAnalytics = (
    modelName: string,
    modelYear: string,
    storeVoucherCode: string,
    fireEvents: Function
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const vehicle = {
        year: Number(modelYear),
        model: modelName,
        make: getBrand(),
    };
    const osbRecallPageLoad: OSBPayload = {
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_VEHICLE_RECALL_FOUND,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(vehicle),
            osbPayload: osbRecallPageLoad,
        },
        false
    );
};
export const triggerVehicleActiveBookingExistsAnalytics = (
    storeVoucherCode: string,
    fireEvents: Function
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const osbActiveBookingPageLoad: OSBPayload = {
        bookingVoucherCode: voucherCode,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_VEHICLE_ACTIVE_BOOKING_EXISTS,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbActiveBookingPageLoad,
        },
        false
    );
};
const getServiceTypeForAnalytics = (selectedServices: any) => {
    let services = [];
    services = selectedServices.filter((item: { subType: string }) => {
        return (
            item.subType === SERVICE_FLOW.SERVICE_SUBTYPE_MOT ||
            item.subType === SERVICE_FLOW.SERVICE_SUBTYPE_REPAIR ||
            item.subType === SERVICE_FLOW.SUBTYPE_VALUE_SERVICE
        );
    });
    return services.length > 0 ? 'motorcraft' : 'classic';
};

export const triggerDateTimeLoadAnalytics = (
    totalPrice: string,
    selectedServices: any,
    storeVoucherCode: string,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const bookingProductString = formBookingProductString(selectedServices);
    const serviceType = getServiceTypeForAnalytics(selectedServices);
    const osbDateTimePageLoad: OSBPayload = {
        serviceType: serviceType,
        bookingValue: totalPrice,
        bookingProductString: bookingProductString,
        bookingVoucherCode: voucherCode,
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DATE_TIME,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbDateTimePageLoad,
        },
        false
    );
};
export const triggerDateTimeLoadErrorAnalytics = (
    totalPrice: string,
    selectedServices: any,
    storeVoucherCode: string,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const bookingProductString = formBookingProductString(selectedServices);
    const serviceType = getServiceTypeForAnalytics(selectedServices);
    const osbDateTimePageLoad: OSBPayload = {
        serviceType: serviceType,
        bookingValue: totalPrice,
        bookingProductString: bookingProductString,
        bookingVoucherCode: voucherCode,
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DATE_TIME_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbDateTimePageLoad,
        },
        false
    );
};
export const triggerReviewAmendLoadAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_AMEND_SERVICE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
        },
        false
    );
};

export const triggerBookingCreateCompleteAnalytics = (
    selectedServices: any,
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string,
    bookingID: string
) => {
    clearErrorCodes();
    const serviceType = getServiceTypeForAnalytics(selectedServices);
    const osbCreateBookingPageLoad: OSBPayload = {
        serviceType: serviceType,
        reviewAmendField: reviewAmendField,
        bookingID: bookingID,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_BOOKING_COMPLETE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbCreateBookingPageLoad,
        },
        false
    );
};
export const triggerPrintBookingAnalytics = (
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const osbPrintBookingPageLoad: OSBPayload = {};
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_PRINT_BOOKING,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbPrintBookingPageLoad,
        },
        false
    );
};

export const triggerRetrieveBookingAnalytics = (fireEvents: Function) => {
    clearErrorCodes();
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_BOOKING_RETRIEVE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {},
        false
    );
};
export const triggerReviewBookingLoadAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendReviewBookingSummary: OSBPayload = {
        reviewAmendField: reviewAmendField,
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_BOOKING_REVIEW,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendReviewBookingSummary,
        },
        false
    );
};

export const triggerRetrieveBookingErrorAnalytics = (fireEvents: Function) => {
    clearErrorCodes();
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_BOOKING_RETRIEVE_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {},
        false
    );
};
export const triggerReviewBookingErrorAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const osbReviewBookingError: OSBPayload = {
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_REVIEW_BOOKING_SUMMARY_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbReviewBookingError,
        },
        false
    );
};

export const triggerRetrieveBookingSummaryAnalytics = (
    dealerID: string,
    totalPrice: string,
    selectedServices: any,
    storeVoucherCode: string,
    osbVehicleStep: VehicleStep,
    bookingID: string,
    osbStep: OSBStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const voucherCode = storeVoucherCode !== '' ? storeVoucherCode : ' ';
    const bookingProductString = formBookingProductString(selectedServices);
    const serviceType = getServiceTypeForAnalytics(selectedServices);
    const mileage = getMileage(osbVehicleStep);
    const osbRetrieveSummaryPageLoad: OSBPayload = {
        dealerID: dealerID,
        serviceType: serviceType,
        bookingValue: totalPrice,
        bookingProductString: bookingProductString,
        bookingVoucherCode: voucherCode,
        mileage: mileage,
        bookingID: bookingID,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_BOOKING_RETRIEVE_REVIEW_SUMMARY,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbRetrieveSummaryPageLoad,
        },
        false
    );
};
export const triggerAmendReviewBookingSummaryAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendReviewBookingSummary: OSBPayload = {
        reviewAmendField: reviewAmendField,
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_AMEND_REVIEW_BOOKING_SUMMARY,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendReviewBookingSummary,
        },
        false
    );
};

export const triggerAmendReviewServiceErrorAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendReviewBookingSummary: OSBPayload = {
        reviewAmendField: reviewAmendField,
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_AMEND_SERVICE_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendReviewBookingSummary,
        },
        false
    );
};

export const triggerAmendReviewDeliveryServiceErrorAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendReviewBookingSummary: OSBPayload = {
        reviewAmendField: reviewAmendField,
        pTemplate: LIGHT_JOURNEY_ANALYTICS.PTEMPLATE_VALUE,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_AMEND_DELIVERY_SERVICE_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendReviewBookingSummary,
        },
        false
    );
};

export const triggerDealerContactDetailsAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbContactDetail: OSBPayload = {
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CONTACT_DETAILS,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbContactDetail,
        },
        false
    );
};

const formInvalidEntriesString = (
    invalidFields: PersonalDetailErrorField[]
) => {
    const analyticSettings: string[] = [];
    for (const key in invalidFields) {
        const value = invalidFields[key];
        analyticSettings.push(`contact details:${value.field}:${value.entry}`);
    }
    const value = analyticSettings.join(',');
    return value;
};

export const triggerContactDetailsErrorAnalytics = (
    invalidFormFieldsForAnalytic: PersonalDetailErrorField[],
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const invalidFields = formInvalidEntriesString(
        invalidFormFieldsForAnalytic
    );
    const osbContactDetailsErrrorInfo: OSBPayload = {
        errorDescription: invalidFields,
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CONTACT_DETAILS_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbContactDetailsErrrorInfo,
        },
        false
    );
};

export const triggerRetrieveAmendContactDetailsLoadAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendRetrieveContactDetails: OSBPayload = {
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CONTACT_DETAILS,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendRetrieveContactDetails,
        },
        false
    );
};

export const triggerRetrieveAmendContactDetailsErrorAnalytics = (
    invalidFormFieldsForAnalytic: PersonalDetailErrorField[],
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const invalidFields = formInvalidEntriesString(
        invalidFormFieldsForAnalytic
    );
    const osbContactDetailsErrrorInfo: OSBPayload = {
        errorDescription: invalidFields,
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CONTACT_DETAILS_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbContactDetailsErrrorInfo,
        },
        false
    );
};

export const triggerRetrieveAmendDateAndTImeLoadAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendRetrieveContactDetails: OSBPayload = {
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DATE_TIME,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendRetrieveContactDetails,
        },
        false
    );
};

export const triggerRetrieveDateTimeAmendErrorAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string
) => {
    clearErrorCodes();
    const osbAmendRetrieveContactDetails: OSBPayload = {
        reviewAmendField: reviewAmendField,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_SELECT_DATE_TIME,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbAmendRetrieveContactDetails,
        },
        false
    );
};

export const triggerRetrieveBookingCancellationAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_RETRIEVE_SERVICE_BOOKING_CANCELLATION,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
        },
        false
    );
};

export const triggerRetrieveAccessCodeAnalytics = (
    fireEvents: Function,
    accessCodeStatus: string
) => {
    clearErrorCodes();
    const osbRetrieveAccessCodeInfo: OSBPayload = {
        accessCodeStatus: accessCodeStatus,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbRetrieveAccessCodeInfo,
        },
        false
    );
};

export const triggerRetrieveAccessCodeErrorAnalytics = (
    fireEvents: Function,
    errorDescription: string
) => {
    clearErrorCodes();
    const osbRetrieveAccessCodeInfo: OSBPayload = {
        errorDescription: errorDescription,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_RETRIEVE_ACCESS_CODE_ERROR,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbRetrieveAccessCodeInfo,
        },
        false
    );
};

export const triggerBookingSummaryAndProgressBarAmendAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function,
    reviewAmendField: string,
    contentSubType: string
) => {
    clearErrorCodes();
    const osbBookingSummaryAndProgressBarAmendInfo: OSBPayload = {
        reviewAmendField: reviewAmendField,
        contentSubType: contentSubType,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_BOOKING_SUMMARY_AND_PROGRESS_BAR_AMEND,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbBookingSummaryAndProgressBarAmendInfo,
        },
        false
    );
};

export const triggerRetrieveBookingLogoutAnalytics = (
    osbVehicleStep: VehicleStep,
    osbStep: OSBStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    const osbRetrieveBookingLogout: OSBPayload = {};
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_RETRIEVE_SERVICE_BOOKING_LOGOUT,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbRetrieveBookingLogout,
        },
        false
    );
};

export const triggerSelectDealerFilterApplyAnalytics = (
    selectedFiltersAnalytics: string,
    fireEvents: Function
) => {
    const osbSelectDealer: OSBPayload = {
        selectedDealerFilters: selectedFiltersAnalytics,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_BOOKING_DEALER_SEARCH_RESULTS_FILTERS_REFINE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerAuthJourneyVehicleManualEntryAnalytics = (
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    clearErrorCodes();
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_AUTH_JOURNEY_VEHICLE_MANUAL_ENTRY,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
        },
        false
    );
};
export const triggerVideoPlayAnalytics = (
    videoName: string,
    fireEvents: Function
) => {
    delete (window as any).digitaldata?.video;
    const osbSelectDealer: OSBPayload = {
        videoName: videoName,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CLEAR_COMMUNICATION_VIDEOS_START,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerAuthJourneySaveVinAnalytics = (fireEvents: Function) => {
    clearErrorCodes();
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_AUTH_JOURNEY_SAVE_VIN,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {},
        false
    );
};
export const triggerVideoPercentage = (
    videoName: string,
    videoPercentage: any,
    segmentViewed: any,
    fireEvents: Function
) => {
    const osbSelectDealer: OSBPayload = {
        videoName: videoName,
        videoPercentage: videoPercentage,
        segmentViewed: segmentViewed,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CLEAR_COMMUNICATION_VIDEOS_SEGMENT,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerVideoComplete = (
    videoName: string,
    fireEvents: Function
) => {
    const osbSelectDealer: OSBPayload = {
        videoName: videoName,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_CLEAR_COMMUNICATION_VIDEOS_COMPLETE,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerImageUploadAnalytics = (
    fileStatus: string,
    osbVehicleStep: VehicleStep,
    fireEvents: Function
) => {
    const osbSelectDealer: OSBPayload = {
        fileStatus: fileStatus,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_IMAGE_UPLOAD,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                getVehicleAttributesForAnalytics(osbVehicleStep)
            ),
            osbPayload: osbSelectDealer,
        },
        false
    );
};

export const triggerVinMileageInfoTooltipClickAnalytics = (
    vinMileage: string,
    fireEvents: Function
) => {
    const vinMileageObject: OSBPayload = {
        vinMileage: vinMileage,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_VIN_MILEAGE_TOOLTIP_ONCLICK,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: vinMileageObject,
        },
        false
    );
};

export const triggerServiceInfoTooltipClickAnalytics = (
    serviceName: string,
    nameplateData: string,
    fireEvents: Function
) => {
    const serviceInfoObject: OSBPayload = {
        serviceName: serviceName || ' ',
        nameplateData: nameplateData || ' ',
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_SERVICE_INFO_TOOLTIP_ONCLICK,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            osbPayload: serviceInfoObject,
        },
        false
    );
};

export const triggerVehicleChangeRemoveCTAClickAnalytics = (
    modelName: string,
    modelYear: string,
    fireEvents: Function
) => {
    const vehicle = {
        year: Number(modelYear),
        model: modelName || ' ',
        make: modelName ? getBrand() : ' ',
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_VEHICLE_CHANGE_REMOVE_CTA_ONCLICK,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(vehicle),
        },
        false
    );
};

export const triggerStickyViewSummaryLinkOnClickMobileAnalytics = (
    modelName: string,
    modelYear: string,
    fireEvents: Function
) => {
    const vehicle = {
        year: Number(modelYear),
        model: modelName || ' ',
        make: modelName ? getBrand() : ' ',
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_STICKY_VIEW_SUMMARY_LINK_MOBILE_ONCLICK,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(vehicle),
        },
        false
    );
};

export const triggerBookServiceGlobalCTAOnClickAnalytics = (
    ctaName: string,
    fireEvents: Function,
    osbVehicleStep?: VehicleStep
): void => {
    const osbPayload: OSBPayload = {
        ctaName,
    };
    fireEvents(
        LIGHT_JOURNEY_ANALYTICS.EVENT_BOOK_SERVICE_GLOBAL_CTA_ONCLICK,
        LIGHT_JOURNEY_ANALYTICS.TARGET_TRIGGER_VIEW,
        {
            ymm: Promise.resolve(
                osbVehicleStep
                    ? getVehicleAttributesForAnalytics(osbVehicleStep)
                    : {
                          year: Number(' '),
                          model: ' ',
                          make: ' ',
                      }
            ),
            osbPayload: osbPayload,
        },
        false
    );
};
