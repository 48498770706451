import { COLORS } from '../../../constants';
import './fm-progress-bar.scss';

interface FMProgressBarProps {
    valuePercentage: number;
    color: string;
    height: number;
    width: number;
    ariaLabel?: string;
    isGradient?: boolean;
}

const getFillerColor = (color?: string) => {
    if (color === 'red') {
        return COLORS.FM_RED_1000;
    }
    return COLORS.FM_GREEN_1000;
};

const FMProgressBar = (props: FMProgressBarProps) => {
    const { valuePercentage: value } = props;
    const fillerColor = getFillerColor(props.color);
    const filler = props.isGradient
        ? `linear-gradient(90deg, ${fillerColor} 70%, ${COLORS.FM_WHITE} 100%)`
        : fillerColor;

    return (
        <>
            <style>
                {`
                .fm-progress-bar .filler::-webkit-progress-value {
                    background-color: ${filler};
                }
                .fm-progress-bar .filler::-moz-progress-bar {
                    background-color: ${filler};
                }
            `}
            </style>
            <section className="fm-progress-bar">
                <div
                    className="progress-container"
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={value}
                    style={{
                        width: `${props.width}` + 'px',
                        height: `${props.height}` + 'px',
                    }}
                    aria-label={props.ariaLabel}
                >
                    <div
                        className="bar-container"
                        style={{
                            height: `${props.height}` + 'px',
                        }}
                    >
                        <div
                            className="filler"
                            style={{
                                width: `${value}%`,
                                background: filler,
                            }}
                        >
                            {props.isGradient ? (
                                <div
                                    style={{
                                        width: `${(100 / value) * 100}%`,
                                        background: filler,
                                    }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default FMProgressBar;
