import React from 'react';
import {
    CategoryViewFragment,
    SubCategoryViewFragment,
} from '../../models/experiencefragments/category-view';
import { SubCategoryView } from '../sub-category-view/sub-category-view';
import { CategoryView } from './category-view';
import {
    useSubCategoryViewContent,
    UseSubCategoryViewContentReturn,
} from '../sub-category-view/hooks/use-sub-category-view-content';
import { UseCategoryViewContentReturn } from './hooks/use-category-view-content';
import { ExperienceFragmentModel } from '../../services/content-service/content-service.interface';
import { Link } from '../../components/common';
import { useLocation } from 'react-router';
import useSeoConfig from '../../hooks/use-seo-config';
import xss from 'xss';
import { StringUtil } from '../../components/utils/string-util/string-util';
import CategoryViewUtil from './category-view-util';
import PageNotFoundView from '../page-not-found-view/page-not-found-view';

interface Props {
    useParams: () => string[];
    useExperienceContent: <T extends ExperienceFragmentModel>(
        category: string,
        name: string,
        componentName: string,
        brandOverride?: string,
        ymmServlet?: boolean
    ) => [T | null, boolean];
    useCategoryViewContent: (
        categoryViewFragment: CategoryViewFragment
    ) => UseCategoryViewContentReturn | undefined;
    useSubCategoryViewContent: (
        subCategoryViewFragment: SubCategoryViewFragment
    ) => UseSubCategoryViewContentReturn | undefined;
}

export function removeHyphen(string: any) {
    return string
        .split('-')
        .map((word: string) => StringUtil.capitalizeFirstLetter(word))
        .join(' ')
        .trim();
}

export const CategoryHandler = (props: Props) => {
    const path = xss(props.useParams()[0], {
        whiteList: {}, // empty, means filter out all tags
        stripIgnoreTag: true, // filter out all HTML not in the whitelist
        stripIgnoreTagBody: true, // filter out all HTML body not in the whitelist
    });
    const cleanPath = path.endsWith('/')
        ? path.substr(0, path.length - 1)
        : path;
    const categoryId = cleanPath.split('/').pop();
    const [categoryViewFragment] = props.useExperienceContent<
        CategoryViewFragment | SubCategoryViewFragment
    >(`category/${cleanPath}`, `${categoryId}`, '', undefined, false);
    const categoryName = cleanPath.split('/');
    const currentVersion = CategoryViewUtil.currentVersion;
    // Start - SEO Metadata
    let route = xss(useLocation().pathname, {
        whiteList: {}, // empty, means filter out all tags
        stripIgnoreTag: true, // filter out all HTML not in the whitelist
        stripIgnoreTagBody: true, // filter out all HTML body not in the whitelist
    });
    if (route.endsWith('/')) route = route.substring(0, route.length - 1);
    const routeParts = route.split('/');
    useSeoConfig(routeParts[routeParts.length - 1], routeParts.join('/'));
    // End - SEO Metadata

    const getBreadCrumbItem = (index: number, categoryName?: string[]) => {
        const breadCrumbItems = categoryViewFragment?.breadcrumbs?.breadcrumbText?.split(
            '|'
        );
        if (breadCrumbItems && breadCrumbItems?.length >= 1) {
            if (categoryName && categoryName.length === breadCrumbItems.length)
                return breadCrumbItems?.at(index)?.trim();
        }
        return undefined;
    };
    const currentCategoryView = () => {
        if (categoryViewFragment && categoryViewFragment[':type']) {
            if (
                categoryViewFragment[':type'].endsWith(
                    'v1/category-page-structure'
                )
            ) {
                return 'category-view';
            }
            if (
                categoryViewFragment[':type']?.endsWith(
                    'v1/sub-category-page-structure'
                )
            ) {
                return 'sub-category-view';
            }
        }
    };

    const CategoryBreadcrumbs = () => {
        return (
            <>
                {categoryName.map((item, index) => {
                    if (index < categoryName.length - 1) {
                        return (
                            <>
                                <Link
                                    href={`category/${
                                        cleanPath.split(
                                            categoryName[index + 1]
                                        )[0]
                                    }`}
                                    className="breadcrumb-link"
                                    aria-label={
                                        getBreadCrumbItem(
                                            index,
                                            categoryName
                                        ) || removeHyphen(categoryName[index])
                                    }
                                >
                                    {getBreadCrumbItem(index, categoryName) ||
                                        removeHyphen(categoryName[index])}
                                </Link>
                                {!currentVersion ? (
                                    <span className="back-slash">{`/`}</span>
                                ) : (
                                    <span className="back-slash">{`>`}</span>
                                )}
                            </>
                        );
                    }
                })}
            </>
        );
    };

    let invalidPath = false;
    if (
        !categoryViewFragment ||
        categoryViewFragment?.[':type'] === undefined
    ) {
        invalidPath = true;
    }

    return (
        <>
            {invalidPath && (
                <>
                    <PageNotFoundView />
                </>
            )}
            {!invalidPath &&
                categoryId &&
                categoryViewFragment &&
                categoryViewFragment[':type'] && (
                    <>
                        {currentCategoryView() === 'category-view' && (
                            <>
                                <CategoryView
                                    path={cleanPath}
                                    categoryId={
                                        getBreadCrumbItem(-1, categoryName) ||
                                        removeHyphen(categoryId)
                                    }
                                    categoryViewFragment={categoryViewFragment}
                                    useCategoryViewContent={
                                        props.useCategoryViewContent
                                    }
                                    breadcrumbs={CategoryBreadcrumbs}
                                />
                            </>
                        )}
                        {currentCategoryView() === 'sub-category-view' && (
                            <>
                                <SubCategoryView
                                    path={cleanPath}
                                    subCategoryId={
                                        getBreadCrumbItem(-1, categoryName) ||
                                        removeHyphen(categoryId)
                                    }
                                    subCategoryViewFragment={
                                        categoryViewFragment as SubCategoryViewFragment
                                    }
                                    useSubCategoryViewContent={
                                        useSubCategoryViewContent
                                    }
                                    breadcrumbs={CategoryBreadcrumbs}
                                />
                            </>
                        )}
                    </>
                )}
        </>
    );
};

export default CategoryHandler;
