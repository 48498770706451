import React, { useContext } from 'react';
import { Breadcrumbs, SearchBar } from '../../components/sections';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../page-not-found-view/hooks/use-help-cards-content';
import { CategoryViewMasthead } from '../../components/sections/category/categories/category-view-masthead/category-view-masthead';
import { PreImagineCategoryMasthead } from '../../components/sections/category/categories/preimagine/category-masthead/preimagine-category-masthead';
import { ComponentBox5050 } from '../../components/sections/category/categories/component-box-5050/component-box-5050';
import { FaqsAccordion } from '../../components/sections/category/categories/faqs-accordion/faqs-accordion';
import { Category5050Carousel } from '../../components/sections/category/categories/category-5050-carousel/category-5050-carousel';
import { CategoryQuietCards } from '../../components/sections/category/categories/quiet-cards/category-quiet-cards';
import { CategoryVideoQuietCard } from '../../components/sections/category/categories/category-video-quiet-card/category-video-quiet-card';
import { useAnalytics } from '../../hooks/use-analytics';
import { UseCategoryViewContentReturn } from './hooks/use-category-view-content';
import { DisclaimerList } from '../../components/common/disclaimer-list/disclaimer-list';
import { CategoryViewFragment } from '../../models/experiencefragments/category-view';
import { useSkinnyBannerContent } from '../../components/common/skinny-banner/hook/use-skinny-banner';
import DisclosuresLinkSyndicationAware from '../../components/sections/disclosures-link/disclosures-link-syndication-aware';
import SkinnyBanner from '../../components/common/skinny-banner/skinny-banner';
import { CategoryOnClickCtaInfo } from '../../services/shortcode-service/shortcode-service';
import { CategoryPopularArticlesTrio } from '../../components/sections/category/categories/category-popular-articles-trio/category-popular-articles-trio';
import PreImagineQuietCards from '../../components/sections/category/categories/preimagine/quiet-cards/preimagine-quiet-cards';
import PreImagine5050Banner from '../../components/sections/category/categories/preimagine/preimagine-5050-banner/preimagine-5050-banner';
import { PreImagineEscapeHatch } from '../../components/sections/pre-imagine-components/pre-imagine-escape-hatch/pre-imagine-escape-hatch';
import { PreImagineCategoryVideoQuietCard } from '../../components/sections/category/categories/preimagine/preimagine-category-video-quiet-card/preimagine-category-video-quiet-card';
import ServerContext from '../../contexts/serverContext';

import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { FMCard, FMCarousel } from '@own/fds-react';
import CategoryViewUtil from './category-view-util';

import './category-view.scss';

interface Props {
    path: string;
    categoryId: string;
    categoryViewFragment: CategoryViewFragment;
    breadcrumbs: any;
    useCategoryViewContent: (
        categoryViewFragment: CategoryViewFragment
    ) => UseCategoryViewContentReturn | undefined;
}

export const CategoryView = (props: Props) => {
    const { path, categoryViewFragment } = props;
    const context = useContext(ServerContext);
    const currentVersion = CategoryViewUtil.currentVersion;
    useAnalytics('owner-category', undefined, {
        categoryId: path,
    });

    const [fireEvents] = useAnalytics();
    const categoryFaqPanelClick = async (panelTitle: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            categoryPath: props.path,
            panelTitle,
        };
        fireEvents('faq-onclick-event', undefined, {
            categoryOnClickCtaInfo,
        });
    };

    const { disclaimers, content } =
        props.useCategoryViewContent(categoryViewFragment) || {};
    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();
    const skinnyBannerContent = useSkinnyBannerContent(
        `category/${path}`,
        'skinny-banner',
        false
    );
    return (
        <>
            {content && (
                <div
                    className={[
                        'category-view',
                        currentVersion ? '' : 'preimagine',
                    ]
                        .join(' ')
                        .trim()}
                >
                    {!props.categoryViewFragment.breadcrumbs
                        ?.hideBreadcrumbs && (
                        <Breadcrumbs
                            currentPage={props.categoryId}
                            type="common"
                            crumbSeparator={`${currentVersion ? '>' : '/'}`}
                        >
                            <props.breadcrumbs />
                        </Breadcrumbs>
                    )}
                    {content.masthead && currentVersion && (
                        <CategoryViewMasthead
                            {...content.masthead}
                            eventName={'category-onclick-event'}
                            categoryPath={path}
                        />
                    )}
                    {content.masthead && !currentVersion && (
                        <PreImagineCategoryMasthead
                            {...content.masthead}
                            eventName={'category-onclick-event'}
                            categoryPath={path}
                            root={context?.root ?? ''}
                        />
                    )}
                    {content?.videoQuietCard && currentVersion && (
                        <CategoryVideoQuietCard {...content.videoQuietCard} />
                    )}
                    {content?.videoQuietCard && !currentVersion && (
                        <PreImagineCategoryVideoQuietCard
                            {...content.videoQuietCard}
                            root={context?.root ?? ''}
                        />
                    )}
                    {skinnyBannerContent && (
                        <SkinnyBanner {...skinnyBannerContent} />
                    )}
                    {content.fiftyFiftyCarousel && currentVersion && (
                        <Category5050Carousel
                            {...content.fiftyFiftyCarousel}
                            categoryType={'category'}
                            categoryPath={path}
                        />
                    )}
                    {content.fiftyFiftyCarousel && !currentVersion && (
                        <>
                            <div className="fm-category-fifty-fifty-carousel-header dark">
                                {content.fiftyFiftyCarousel.sectionHeading && (
                                    <h2 className="fm-category-fifty-fifty-carousel__title">
                                        {
                                            content.fiftyFiftyCarousel
                                                .sectionHeading
                                        }
                                    </h2>
                                )}
                                {content.fiftyFiftyCarousel.sectionSubCopy && (
                                    <div className="fm-category-fifty-fifty-carousel__subtitle">
                                        {
                                            content.fiftyFiftyCarousel
                                                .sectionSubCopy
                                        }
                                    </div>
                                )}
                            </div>
                            <FMCarousel
                                items={CategoryViewUtil.MapArticlesToFMCardProps(
                                    content.fiftyFiftyCarousel.articles,
                                    context?.root
                                )}
                                instanceName="category-fifty-fifty-carousel"
                                isDualMode={true}
                                carouselTheme="dark"
                                render={(item: FMCardProps) => (
                                    <FMCard
                                        {...item}
                                        cardTheme="dark"
                                        ctaOnClick={CategoryViewUtil.HandleFMCardClick(
                                            item,
                                            'category-onclick-event',
                                            fireEvents,
                                            false
                                        )}
                                    />
                                )}
                            />
                        </>
                    )}
                    {content.quietCards && currentVersion && (
                        <CategoryQuietCards
                            articles={content.quietCards.articles}
                            categoryType={'category'}
                            categoryPath={path}
                        />
                    )}
                    {content.quietCards && !currentVersion && (
                        <PreImagineQuietCards
                            articles={content.quietCards.articles}
                            categoryType={'category'}
                            categoryPath={path}
                            root={context?.root}
                        />
                    )}
                    {content.componentBox5050 && currentVersion && (
                        <ComponentBox5050
                            content={content.componentBox5050}
                            {...content.componentBox5050}
                            categoryType={'category'}
                            categoryPath={path}
                        />
                    )}
                    {content.componentBox5050 && !currentVersion && (
                        <PreImagine5050Banner
                            content={content.componentBox5050}
                            categoryType={'category'}
                            categoryPath={path}
                            root={context?.root}
                        />
                    )}
                    {content.articlesTrio && currentVersion && (
                        <CategoryPopularArticlesTrio
                            {...content.articlesTrio}
                            categoryPath={path}
                            categoryType={'category'}
                        />
                    )}
                    {content.articlesTrio && !currentVersion && (
                        <>
                            <div className="fm-category-articles-trio-header">
                                {content.articlesTrio.sectionHeading && (
                                    <h2 className="fm-category-articles-trio__title">
                                        {content.articlesTrio.sectionHeading}
                                    </h2>
                                )}
                                {content.articlesTrio.sectionSubCopy && (
                                    <div className="fm-category-articles-trio__subtitle">
                                        {content.articlesTrio.sectionSubCopy}
                                    </div>
                                )}
                            </div>
                            <FMCarousel
                                instanceName="category-article-trio-carousel"
                                isUniMode={true}
                                items={CategoryViewUtil.MapArticlesToFMCardProps(
                                    content.articlesTrio?.articles ?? [],
                                    context?.root ?? ''
                                )}
                                render={(item: FMCardProps) => (
                                    <FMCard
                                        {...item}
                                        ctaOnClick={CategoryViewUtil.HandleFMCardClick(
                                            item,
                                            'category-onclick-event',
                                            fireEvents,
                                            false
                                        )}
                                        cta2OnClick={CategoryViewUtil.HandleFMCardClick(
                                            item,
                                            'category-onclick-event',
                                            fireEvents,
                                            false
                                        )}
                                    />
                                )}
                            />
                        </>
                    )}
                    {content.faqs && (
                        <FaqsAccordion
                            {...content.faqs}
                            onPanelClickEvent={categoryFaqPanelClick}
                            handlePanelClick={categoryFaqPanelClick}
                            pageType="Category"
                        />
                    )}
                    {disclaimers && (
                        <DisclaimerList
                            disclaimers={disclaimers}
                        ></DisclaimerList>
                    )}
                    {searchContent && helpCardsContent && currentVersion && (
                        <SearchBar
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                    {searchContent && helpCardsContent && !currentVersion && (
                        <PreImagineEscapeHatch
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                    <DisclosuresLinkSyndicationAware />
                </div>
            )}
        </>
    );
};
