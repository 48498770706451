import React from 'react';
import './osb-overlay-image.scss';

interface Props {
    fileContent: string;
    fileName: string;
}

export const OSBOverlayImage = (props: Props) => {
    return (
        <img
            src={props.fileContent}
            alt={props.fileName}
            className="osb-overlay-image"
        />
    );
};
