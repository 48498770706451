import React from 'react';
import Link from '../../common/link/link';

import './article.scss';
import { FeatureLinkListCategory } from '../../../models/experiencefragments/featurelinkslist';
import { useAnalytics } from '../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';

interface Props {
    articleCategory: FeatureLinkListCategory;
    index: number;
}

export const Article = (props: Props) => {
    const [fireEvents] = useAnalytics();
    return (
        <section className="article" id={'LM' + props.index}>
            <h3 className="article-header">
                {props.articleCategory.categoryTitle}
            </h3>
            <section className="article-body">
                <ul className={'article-list-body'} aria-labelledby="IDref">
                    {props.articleCategory.categoryItems.map((item, index) => (
                        <li className={'article-list'} key={index}>
                            <Link
                                className="article-details"
                                href={item.url}
                                onClick={() =>
                                    NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                        item.url,
                                        fireEvents
                                    )
                                }
                                aria-label={item.ariaLabel}
                                rel="noopener noreferrer"
                                target={item.targetBlank ? '_blank' : ''}
                                id={'LM' + props.index + '-' + (index + 1)}
                            >
                                {item.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </section>
        </section>
    );
};
