import React, { ReactNode } from 'react';
import './details-banner.scss';
interface Props {
    title?: string | ReactNode;
    description?: string;
}
export const DetailsBanner = (props: Props) => {
    return (
        <div className="how-to-top-text">
            <h1 className="how-to-title">{props.title ? props.title : ''}</h1>
            <div className="how-to-description">
                <div className="description-text">
                    {props.description ? props.description : ''}
                </div>
            </div>
        </div>
    );
};
