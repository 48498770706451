import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

export interface SoftwareVersionDetail {
    softwareVersionInHeader: string;
    softwareNotesPerVersionList: SoftwareNotesPerVersion[];
}

export interface HistoricalReleaseNotesContent {
    releaseNotesGroupHeader: string;
    releaseNotesGroupText: string;
    releaseNotesFooter: string;
}

export interface SoftwareNotesPerVersion {
    releaseNotesManifestHeader: string;
    historicalReleaseNotesContentList: HistoricalReleaseNotesContent[];
    releaseNotesManifestDisclaimer: string;
    releaseDate: string;
}

export interface HistoricalReleaseNotesRequest {
    vin: string;
    locale: string;
}

export interface HistoricalReleaseNotesResponse {
    softwareVersionDetailList?: SoftwareVersionDetail[];
}

export class HistoricalReleaseNotesService {
    private static appConfig = new AppConfigurationService();

    async getReleaseNotesForVehicle(
        vin: string
    ): Promise<HistoricalReleaseNotesResponse> {
        const historicalReleaseNotesRequest: HistoricalReleaseNotesRequest = {
            vin: vin,
            locale:
                HistoricalReleaseNotesService.appConfig
                    .currentLanguageRegionCode,
        };
        const releaseNotesUrl =
            DSL_BASE_URL + DSL_API_PATH.HISTORICAL_RELEASE_NOTES;

        return await HttpService.post<any>(
            releaseNotesUrl,
            historicalReleaseNotesRequest,
            {}
        )
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error('Error in getReleaseNotesForVehicle', error);
            });
    }
}

export default HistoricalReleaseNotesService;
