import React from 'react';
import { OtaContent } from '../../../../models/experiencefragments/vehicle-notifications';
import { MMOTAStatusResponse } from '../../../../services/mmota-status-service/mmota-status.interface';
import { PrimaryButton } from '../../../common';
import { SimpleAccordion } from '../../../common/simple-accordion/simple-accordion';
import {
    DSL_MMOTA_STATUS,
    MMOTAUtil,
} from '../../../vehicle-checklist/vehicle-checklist-mmota/mmota-util';
import DateTimeUtil, {
    ParsedDateTimeFormat,
} from '../../../utils/date-time-util/date-time-util';
import { NotificationItem } from '../notification-item/notification-item';
import { useAnalytics } from '../../../../hooks/use-analytics';
import './software-update-notifications.scss';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { FMAccordion, FMButton } from '@own/fds-react';
import { PreImagineNotificationItem } from '../notification-item/pre-imagine-notification-item';
interface Props {
    content: OtaContent;
    mmotaData: MMOTAStatusResponse;
}

export const SoftwareUpdatesNotifications = (props: Props) => {
    const {
        summary,
        mmotaAlertsDetails,
        tappsData,
        ccsStatus,
        releaseNotes,
        error,
    } = props.mmotaData;
    const mmotaUtil = new MMOTAUtil();
    const dateTimeUtil = new DateTimeUtil();
    let currentStatus = summary?.alertDescription;
    if (!currentStatus) {
        currentStatus = props.mmotaData?.status?.errorDescription;
    }
    const [fireEvents] = useAnalytics();

    const getIconPath = (iconPath: string): string => {
        if (!iconPath) return '';
        return process.env.REACT_APP_AEM_BASE_URL + iconPath;
    };

    const isAccessUnavailable = error?.message === 'UserDisallowed';

    let title,
        subText,
        status,
        iconPath,
        ariaLabel,
        accordionOneTitle,
        accordionOneDescription,
        accordionTwoTitle,
        accordionTwoDescription;

    const updateText = releaseNotes;

    function isUpdateTextValid() {
        return updateText !== undefined || updateText !== '';
    }

    if (isUpdateTextValid()) {
        accordionTwoDescription = updateText;
    }
    let parsedLatestDateTime: ParsedDateTimeFormat | null;

    function getMmotaAlertsDetail() {
        return mmotaAlertsDetails && mmotaAlertsDetails[0];
    }

    const mmotaAlertsDetail = getMmotaAlertsDetail();
    if (mmotaAlertsDetail?.dateTimeStamp) {
        parsedLatestDateTime = dateTimeUtil.parseDateTime(
            mmotaAlertsDetail?.dateTimeStamp
        );
    }

    const ParseDescription = (description: string) => {
        if (description) {
            let formattedTime;
            const reccuringtimeOfDay = tappsData?.dayOfWeekAndTime;
            if (reccuringtimeOfDay) {
                formattedTime = mmotaUtil.getParsedRecurringDateTime(
                    tappsData,
                    true
                );
            }

            return description
                .replace(
                    '[date of update success]',
                    parsedLatestDateTime?.date + ''
                )
                .replace(
                    '[time of update success]',
                    parsedLatestDateTime?.time
                        .split(' ')
                        .slice(0, 2)
                        .join(' ') + ''
                )
                .replace('[recurringSchedule]', formattedTime + '');
        }
    };

    function setPropsContentValues() {
        if (isAccessUnavailable) {
            title = props.content.accessUnavailableStateTitle;
            subText = props.content.accessUnavailableStateSubtext;
            status = props.content.accessUnavailableStateStatus;
            iconPath = props.content.accessUnavailableStateIconPath;
            ariaLabel = props.content.accessUnavailableStateIconAriaLabel;
            accordionOneTitle =
                props.content.accessUnavailableStateAccordionOneTitle;
            accordionOneDescription =
                props.content.accessUnavailableStateAccordionOneDescription;
            accordionTwoTitle =
                props.content.accessUnavailableStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.accessUnavailableStateAccordionTwoDescription;
        } else if (currentStatus === DSL_MMOTA_STATUS.upToDate) {
            title = props.content.upToDateStateTitle;
            subText = props.content.upToDateStateSubtext;
            status = props.content.upToDateStateStatus;
            iconPath = props.content.upToDateStateIconPath;
            ariaLabel = props.content.upToDateStateIconAriaLabel;
            accordionOneTitle = props.content.upToDateStateAccordionOneTitle;
            accordionOneDescription = ParseDescription(
                props.content.upToDateStateAccordionOneDescription
            );
            accordionTwoTitle = props.content.upToDateStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.upToDateStateAccordionTwoDescription;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateSuccessful) {
            title = props.content.successStateTitle;
            subText = props.content.successStateSubtext;
            status = props.content.successStateStatus;
            iconPath = props.content.successStateIconPath;
            ariaLabel = props.content.successStateIconAriaLabel;
            accordionOneTitle = props.content.successStateAccordionOneTitle;
            accordionOneDescription = ParseDescription(
                props.content.successStateAccordionOneDescription
            );
            accordionTwoTitle = props.content.successStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.successStateAccordionTwoDescription;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateWillRetry) {
            title = props.content.retryStateTitle;
            subText = props.content.retryStateSubtext;
            status = props.content.retryStateStatus;
            iconPath = props.content.retryStateIconPath;
            ariaLabel = props.content.retryStateIconAriaLabel;
            accordionOneTitle = props.content.retryStateAccordionOneDescription;
            accordionOneDescription = ParseDescription(
                props.content.retryStateAccordionOneDescription
            );
            accordionTwoTitle = props.content.retryStateAccordionTwoTitle;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateInProgress) {
            title = props.content.inProgressStateTitle;
            subText = props.content.inProgressStateSubtext;
            status = props.content.inProgressStateStatus;
            iconPath = props.content.inProgressStateIconPath;
            ariaLabel = props.content.inProgressStateIconAriaLabel;
            accordionOneTitle = props.content.inProgressStateAccordionOneTitle;
            accordionOneDescription =
                props.content.inProgressStateAccordionOneDescription;
            accordionTwoTitle = props.content.inProgressStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.inProgressStateAccordionTwoDescription;
        } else if (currentStatus === DSL_MMOTA_STATUS.updateReminder) {
            title = props.content.reminderStateTitle;
            subText = props.content.reminderStateSubtext;
            status = props.content.reminderStateStatus;
            iconPath = props.content.reminderStateIconPath;
            ariaLabel = props.content.reminderStateIconAriaLabel;
            accordionOneTitle = props.content.reminderStateAccordionOneTitle;
            accordionOneDescription = ParseDescription(
                props.content.reminderStateAccordionOneDescription
            );
            accordionTwoTitle = props.content.reminderStateAccordionTwoTitle;
        } else if (currentStatus === DSL_MMOTA_STATUS.updatePending) {
            title = props.content.pendingStateTitle;
            subText = props.content.pendingStateSubtext;
            status = props.content.pendingStateStatus;
            iconPath = props.content.pendingStateIconPath;
            ariaLabel = props.content.pendingStateIconAriaLabel;
            accordionOneTitle = props.content.pendingStateAccordionOneTitle;
            accordionOneDescription = ParseDescription(
                props.content.pendingStateAccordionOneDescription
            );
            accordionTwoTitle = props.content.pendingStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.pendingStateAccordionTwoDescription;
        } else if (currentStatus === DSL_MMOTA_STATUS.vehicleInoperable) {
            title = props.content.inoperableStateTitle;
            subText = props.content.inoperableStateSubtext;
            status = props.content.inoperableStateStatus;
            iconPath = props.content.inoperableStateIconPath;
            ariaLabel = props.content.inoperableStateIconAriaLabel;
            accordionOneTitle = props.content.inoperableStateAccordionOneTitle;
            accordionOneDescription = ParseDescription(
                props.content.inoperableStateAccordionOneDescription
            );
            accordionTwoTitle = props.content.inoperableStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.inoperableStateAccordionTwoDescription;
        } else if (
            ccsStatus?.ccsVehicleData?.toLowerCase() !== 'on' ||
            tappsData?.asuSettingsState === 'OFF'
        ) {
            title = props.content.settingsDisabledStateTitle;
            subText = props.content.settingsDisabledStateSubtext;
            status = props.content.settingsDisabledStateStatus;
            iconPath = props.content.settingsDisabledStateIconPath;
            ariaLabel = props.content.settingsDisabledStateIconAriaLabel;
            accordionOneTitle =
                props.content.settingsDisabledStateAccordionOneTitle;
            accordionOneDescription =
                props.content.settingsDisabledStateAccordionOneDescription;
            accordionTwoTitle =
                props.content.settingsDisabledStateAccordionTwoTitle;
            accordionTwoDescription =
                props.content.settingsDisabledStateAccordionTwoDescription;
        }
    }

    if (props.content) {
        setPropsContentValues();
    }

    function unsuccessfulStatusCheck() {
        return (
            currentStatus ===
                DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended ||
            currentStatus === DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain
        );
    }

    if (unsuccessfulStatusCheck()) {
        return null;
    }
    const triggerOnClickAnalytics = () => {
        fireEvents(
            ['notifications-cta-onclick-event'],
            undefined,
            {
                notificationCategory: 'ota',
                notificationCtaName: props.content.ctaTitle.toLowerCase(),
            },
            false
        );
    };
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;

    function getTarget() {
        return props.content.targetBlank ? '_blank' : '_self';
    }

    return (
        <>
            {title &&
            subText &&
            status &&
            iconPath &&
            ariaLabel &&
            accordionOneTitle &&
            accordionOneDescription &&
            accordionTwoTitle &&
            accordionTwoDescription &&
            currentVersion ? (
                <div className="software-updates-container">
                    <NotificationItem
                        headText={title}
                        subText={subText}
                        iconPath={getIconPath(iconPath)}
                    ></NotificationItem>
                    <div className="software-updates-accordion-container">
                        <SimpleAccordion
                            id="software-updates-accordion-1"
                            header={
                                <div className="software-updates-headline">
                                    <div className="software-updates-headline__title">
                                        {accordionOneTitle}
                                    </div>
                                </div>
                            }
                            category={title}
                            cta={accordionOneTitle}
                        >
                            <div className="software-updates-body">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: accordionOneDescription,
                                    }}
                                />
                            </div>
                        </SimpleAccordion>
                        <SimpleAccordion
                            id="software-updates-accordion-2"
                            header={
                                <div className="software-updates-headline">
                                    <div className="software-updates-headline__title">
                                        {accordionTwoTitle}
                                    </div>
                                </div>
                            }
                            category={title}
                            cta={accordionTwoTitle}
                        >
                            <div className="software-updates-body">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: accordionTwoDescription,
                                    }}
                                />
                            </div>
                        </SimpleAccordion>
                        <hr className="notifications-divider" />
                    </div>
                    {props.content.ctaAriaLabel &&
                        props.content.ctaTitle &&
                        props.content.ctaUrl && (
                            <div className="software-updates-cta">
                                <PrimaryButton
                                    color="dark"
                                    fill="fill"
                                    chevron={true}
                                    ariaLabel={props.content.ctaAriaLabel}
                                    href={props.content.ctaUrl}
                                    target={getTarget()}
                                    onClick={triggerOnClickAnalytics}
                                >
                                    {props.content.ctaTitle}
                                </PrimaryButton>
                            </div>
                        )}
                </div>
            ) : (
                title &&
                subText &&
                status &&
                iconPath &&
                ariaLabel &&
                accordionOneTitle &&
                accordionOneDescription &&
                accordionTwoTitle &&
                accordionTwoDescription && (
                    <>
                        <hr className="notifications-divider" />
                        <div className="software-updates-container">
                            <PreImagineNotificationItem
                                headText={title}
                                subText={subText}
                                iconPath={getIconPath(iconPath)}
                            ></PreImagineNotificationItem>
                            <div className="software-updates-accordion-container">
                                <FMAccordion
                                    panels={[
                                        {
                                            title: accordionOneTitle,
                                            panel: `<div className="software-updates-body">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: accordionOneDescription,
                                            }}
                                        />
                                    </div>`,
                                        },
                                        {
                                            title: accordionTwoTitle,
                                            subtitle: '',
                                            panel: (
                                                <div className="software-updates-body">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: accordionTwoDescription,
                                                        }}
                                                    />
                                                </div>
                                            ),
                                        },
                                    ]}
                                ></FMAccordion>
                            </div>
                            {props.content.ctaAriaLabel &&
                                props.content.ctaTitle &&
                                props.content.ctaUrl && (
                                    <div className="software-updates-cta preimagine-software-updates-cta">
                                        <FMButton
                                            type={'tertiary'}
                                            label={props.content.ctaTitle}
                                            chevron={'right'}
                                            ariaLabel={
                                                props.content.ctaAriaLabel
                                            }
                                            href={props.content.ctaUrl}
                                            target={getTarget()}
                                            onClick={triggerOnClickAnalytics}
                                        ></FMButton>
                                    </div>
                                )}
                        </div>
                    </>
                )
            )}
        </>
    );
};
