import { VhaServiceResponse } from './vha-service.interface';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import { AxiosResponse } from 'axios';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

export default class VhaService {
    async request(vin: string): Promise<VhaServiceResponse | undefined> {
        const vhaUrl =
            DSL_BASE_URL +
            DSL_API_PATH.VHA +
            `?language=${
                new AppConfigurationService().currentLanguageRegionCode
            }`;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin: vin,
        };
        const response = HttpService.get<VhaServiceResponse>(vhaUrl, true, {
            headers,
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<VhaServiceResponse>
    ): VhaServiceResponse | undefined {
        if (response.data) {
            return response.data;
        }
    }
}
