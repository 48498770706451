import React, { useContext, useEffect, useState } from 'react';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import ServerContext from '../../../contexts/serverContext';
import { useHistory } from 'react-router-dom';
import { findByAlias } from '../../../routes';

const VehicleChecklistDyf = (
    props: VehicleChecklistContent & VehicleAttributes
) => {
    const context = useContext(ServerContext);
    const [ctaPath, setCtaPath] = useState('');
    const history = useHistory();
    const showTrimPage =
        ['us', 'ca'].includes(context.currentRegionCode) && !props.vin;
    useEffect(() => {
        const dyfRoutes: any[] = findByAlias('DyfLandingView');
        console.log({ dyfRoutes });
        if (
            showTrimPage &&
            dyfRoutes !== undefined &&
            dyfRoutes?.length !== 0
        ) {
            setCtaPath(
                dyfRoutes[1]
                    .replace(':year', props.year.toString())
                    .replace(':model', props.seoKey)
            );
        } else if (
            props.vin &&
            dyfRoutes !== undefined &&
            dyfRoutes?.length !== 0
        ) {
            setCtaPath(dyfRoutes[0].replace(':vin', props.vin));
        } else setCtaPath(props.staticCtaPath);
    }, [props.vin, props.year, props.seoKey]);
    return (
        <>
            <VehicleChecklist
                header={props.title}
                ctaTitle={props.ctaTitle}
                description={props.dynamicDescription}
                type={props.cardTypes}
                iconPath={props.iconPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
                ctaPath={showTrimPage ? '' : ctaPath}
                onCtaHrefClick={(e: React.MouseEvent) => {
                    if (ctaPath && (showTrimPage || props.vin)) {
                        e.preventDefault();
                        history.push(ctaPath);
                    }
                }}
            />
        </>
    );
};
export default VehicleChecklistDyf;
