import { replaceTextKeyword } from '../../../components/sections/owners-osb/osb-utils';
import OsbUtilService from '../osb-util-service/osb-util-service';
import OsbServiceAdapter from './service-adapter';
import { OSBAPIService } from './service-contract';

export class DSLAPIService implements OSBAPIService {
    callServiceAPI<P, R>(
        requestParams: P,
        serviceUrl: string,
        isCacheable?: boolean
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
        });

        return new OsbServiceAdapter().get<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestParams,
            isCacheable: isCacheable || false,
        });
    }

    deleteServiceAPI<P, R>(
        requestParams: P,
        dealerId: string,
        bookingId: string,
        serviceUrl: string
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
            ':dealerId': dealerId,
            ':bookingId': bookingId,
        });

        return new OsbServiceAdapter().delete<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestParams,
        });
    }

    postOSBAPI<P, R>(
        requestBody: P,
        serviceUrl: string,
        callback?: {
            (progressPercent: number): void;
            (arg0: number): void;
        }
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
        });

        return new OsbServiceAdapter().post<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestBody,
            callback: callback,
        });
    }

    postServiceAPIOnDealerCode<P, R>(
        requestParams: P,
        dealerCode: string,
        serviceUrl: string,
        callback?: {
            (progressPercent: number): void;
            (arg0: number): void;
        }
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
            ':dealerCode': dealerCode,
        });

        return new OsbServiceAdapter().post<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestParams,
            callback: callback,
        });
    }

    putServiceAPIOnDealerCodeBookingId<P, R>(
        requestParams: P,
        dealerCode: string,
        bookingId: string,
        serviceUrl: string,
        callback?: {
            (progressPercent: number): void;
            (arg0: number): void;
        }
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
            ':dealerCode': dealerCode,
            ':bookingId': bookingId,
        });

        return new OsbServiceAdapter().put<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestParams,
            callback: callback,
        });
    }

    callServiceAPIOnDealerCode<P, R>(
        requestParams: P,
        dealerCode: string,
        serviceUrl: string
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
            ':dealercode': dealerCode,
        });

        return new OsbServiceAdapter().get<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestParams,
        });
    }

    postCallServiceAPI<P, R>(
        requestParams: P,
        dealerCode: string,
        serviceUrl: string
    ): Promise<R> {
        const appConfig = OsbUtilService.getAppConfiguration();
        const endpointUrl = replaceTextKeyword(serviceUrl, {
            ':locale': appConfig.languageRegionCode,
            ':dealerCode': dealerCode,
        });

        return new OsbServiceAdapter().post<P, R>({
            endpointUrl: endpointUrl,
            requestParameters: requestParams,
        });
    }
}
