import React from 'react';

import './support-hero-tiles.scss';
import { HeroTile } from '../../../components/sections/hero-tiles/hero-tile/hero-tile';
import { SupportHelpCardsProps } from '../../../views/page-not-found-view/hooks/use-support-help-cards-content';

interface Props {
    supportHelpCardsContent: SupportHelpCardsProps;
}

export const SupportHeroTiles = (props: Props) => {
    return (
        props.supportHelpCardsContent && (
            <div className="support-hero-tiles-container">
                <div className="tile-grid">
                    <div id="support-escape-hatch-card-1">
                        <HeroTile
                            title={props.supportHelpCardsContent.firstCardTitle}
                            link={props.supportHelpCardsContent.firstCardUrl}
                            imageUrl={
                                props.supportHelpCardsContent.firstCardIcon &&
                                process.env.REACT_APP_AEM_BASE_URL +
                                    props.supportHelpCardsContent.firstCardIcon
                            }
                            linkTarget={
                                props.supportHelpCardsContent.firstCardTarget
                            }
                            ariaLabel={
                                props.supportHelpCardsContent.firstCardAriaLabel
                            }
                        />
                    </div>
                    <div id="support-escape-hatch-card-2">
                        <HeroTile
                            title={
                                props.supportHelpCardsContent.secondCardTitle
                            }
                            link={props.supportHelpCardsContent.secondCardUrl}
                            imageUrl={
                                props.supportHelpCardsContent.secondCardIcon &&
                                process.env.REACT_APP_AEM_BASE_URL +
                                    props.supportHelpCardsContent.secondCardIcon
                            }
                            linkTarget={
                                props.supportHelpCardsContent.secondCardTarget
                            }
                            ariaLabel={
                                props.supportHelpCardsContent
                                    .secondCardAriaLabel
                            }
                        />
                    </div>
                    <div id="support-escape-hatch-card-3">
                        <HeroTile
                            title={props.supportHelpCardsContent.thirdCardTitle}
                            link={props.supportHelpCardsContent.thirdCardUrl}
                            imageUrl={
                                props.supportHelpCardsContent.thirdCardIcon &&
                                process.env.REACT_APP_AEM_BASE_URL +
                                    props.supportHelpCardsContent.thirdCardIcon
                            }
                            linkTarget={
                                props.supportHelpCardsContent.thirdCardTarget
                            }
                            ariaLabel={
                                props.supportHelpCardsContent.thirdCardAriaLabel
                            }
                        />
                    </div>
                </div>
            </div>
        )
    );
};
