import React from 'react';
import {
    NotificationItem,
    NotificationItemButtonProps,
} from '../notification-item/notification-item';
import { NotificationsFragment } from '../../../../models/experiencefragments/vehicle-notifications';
import { useAnalytics } from '../../../../hooks/use-analytics';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { PreImagineNotificationItem } from '../notification-item/pre-imagine-notification-item';

interface Props {
    syncUpdates: SyncUpdatesNotificationObject;
    content: NotificationsFragment | null;
    vin?: string;
}

export interface SyncUpdatesNotificationObject {
    lastUpdatedDate: string;
    count: number;
}
export const SyncUpdatesNotification = (props: Props) => {
    const { syncUpdates, content, vin } = props;
    const ctaHref = (): string | undefined => {
        if (content?.sync?.ctaUrl?.includes('%vin') && vin) {
            return content.sync.ctaUrl.replace('%vin', vin);
        }
        return content?.sync?.ctaUrl;
    };
    const [fireEvents] = useAnalytics();
    const triggerOnClickAnalytics = () => {
        fireEvents(
            ['notifications-cta-onclick-event'],
            undefined,
            {
                notificationCategory: 'sync',
                notificationCtaName: content?.sync?.ctaTitle.toLowerCase(),
            },
            false
        );
    };
    const buttonProps: NotificationItemButtonProps &
        React.HTMLProps<HTMLAnchorElement> = {
        link: ctaHref(),
        linkTarget: content?.sync?.targetBlank ? '_blank' : '_self',
        children: <>{content?.sync?.ctaTitle}</>,
        onClick: () => triggerOnClickAnalytics(),
    };
    const getHeadText = (): string => {
        return syncUpdates.count + ' ' + content?.sync?.title?.toUpperCase();
    };
    const getSubText = (): string => {
        return (
            content?.sync?.description?.replace(
                '%date',
                syncUpdates?.lastUpdatedDate
            ) || ''
        );
    };
    const getIconPath = (): string => {
        if (!content?.sync?.iconPath) return '';
        return process.env.REACT_APP_AEM_BASE_URL + content?.sync?.iconPath;
    };
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    return (
        <>
            {currentVersion ? (
                <NotificationItem
                    headText={getHeadText()}
                    subText={getSubText()}
                    iconPath={getIconPath()}
                    buttonProps={buttonProps}
                />
            ) : (
                <PreImagineNotificationItem
                    headText={getHeadText()}
                    subText={getSubText()}
                    iconPath={getIconPath()}
                    buttonProps={buttonProps}
                />
            )}
        </>
    );
};
