import { ServerSideService } from './../server-side-service/server-side-service';

export class MouseflowService {
    public static setVariable(key: string, value: string) {
        if (new ServerSideService().isClientSide()) {
            if ((window as any)?._mfq?.push) {
                (window as any)._mfq.push(['setVariable', key, value]);
            } else {
                setTimeout(() => {
                    MouseflowService.setVariable(key, value);
                }, 200);
            }
        }
    }
}
