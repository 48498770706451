import React, { ReactNode } from 'react';

interface Props {
    title: string;
    className?: string;
    isActive?: boolean;
    tabIndex?: number;
    onClick?: (index: number | undefined, title: string) => void;
    onTabClick: (e: any) => void;
    iconClassName?: string;
    children?: ReactNode;
    onKeyDownHandler?: (e: any) => void;
}

const Tab = (props: Props) => {
    const onClickTab = () => {
        if (props.onClick) props.onClick(props.tabIndex, props.title);
    };

    return (
        <div
            className={`tab-container${props.className}`}
            onClick={props.onTabClick}
        >
            <button
                className={`tab${props.isActive ? ` tab-active` : ''}`}
                onClick={onClickTab}
                onKeyDown={props.onKeyDownHandler}
                value={props.tabIndex}
                role="tab"
                id={`service-tabs-${props.tabIndex}`}
                tabIndex={props.isActive ? 0 : -1}
                aria-selected={props.isActive ? 'true' : 'false'}
            >
                <div
                    className={`tab-icon ${
                        props.iconClassName ? props.className : ''
                    }`}
                />
                {props.title}
            </button>
            {props.isActive && props.children}
        </div>
    );
};
export default Tab;
