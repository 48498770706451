import React, { useEffect, useState } from 'react';
import { SESSION_KEY_API_CACHE } from '../../../constants';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import { Modal } from '../../common/modal/modal';
import { useTermsAndConditionsModalContent } from './hook/use-terms-and-conditions-modal-content';
import { TermsAndConditionsService } from './services/terms-and-conditions-service/terms-and-conditions-service';
import './terms-and-conditions-modal.scss';

function TermsAndConditionsModal() {
    const modalName = 'terms-and-conditions';
    const [authenticationState, , logout] = useAuthentication();
    const termsAndConditionsModalContent = useTermsAndConditionsModalContent();
    const [acceptanceStatus, setAcceptanceStatus] = useState<boolean>(true);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const displayModal = () => {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        setIsModalVisible(true);
    };
    const closeModal = () => {
        document.body.style.overflow = '';
        document.body.style.position = '';
        setIsModalVisible(false);
    };

    const acceptTermsAndConditions = () => {
        const service: TermsAndConditionsService = new TermsAndConditionsService();
        service.setAcceptance().then((allLLIDsAccepted: boolean) => {
            closeModal();
            if (allLLIDsAccepted) {
                sessionStorage.removeItem(SESSION_KEY_API_CACHE);
                window.location.reload();
            } else {
                new AuthenticationService().logout();
            }
        });
    };

    const rejectTermsAndConditions = () => {
        logout();
    };

    useEffect(() => {
        if (
            termsAndConditionsModalContent &&
            authenticationState === AuthenticationState.Authenticated
        ) {
            const service: TermsAndConditionsService = new TermsAndConditionsService();
            service.getAcceptanceStatus().then(status => {
                setAcceptanceStatus(status);
            });
        }
    }, [termsAndConditionsModalContent, authenticationState]);

    useEffect(() => {
        if (!acceptanceStatus) {
            displayModal();
            setTimeout(() => {
                const modalBody = document.querySelector(
                    `div.${modalName} .modal-body`
                );
                modalBody && (modalBody.scrollTop = 0);
            });
        }
    }, [acceptanceStatus]);

    return (
        <>
            {termsAndConditionsModalContent && !acceptanceStatus && (
                <Modal
                    name={modalName}
                    primaryBtnLabel={
                        termsAndConditionsModalContent?.acceptTerms
                    }
                    secondaryBtnLabel={
                        termsAndConditionsModalContent?.declineTerms
                    }
                    modalNote={termsAndConditionsModalContent?.modalNote}
                    isVisible={isModalVisible}
                    onPrimaryBtnClick={acceptTermsAndConditions}
                    onSecondaryBtnClick={rejectTermsAndConditions}
                    preventClose={true}
                >
                    {termsAndConditionsModalContent?.text1 && (
                        <>
                            {termsAndConditionsModalContent.label1 && (
                                <div className="tac-header">
                                    {termsAndConditionsModalContent.label1}
                                </div>
                            )}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        termsAndConditionsModalContent?.text1 ||
                                        '',
                                }}
                            />
                        </>
                    )}
                    {termsAndConditionsModalContent?.text2 && (
                        <>
                            <hr></hr>
                            {termsAndConditionsModalContent.label2 && (
                                <div className="tac-header">
                                    {termsAndConditionsModalContent.label2}
                                </div>
                            )}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        termsAndConditionsModalContent?.text2 ||
                                        '',
                                }}
                            />
                        </>
                    )}
                    {termsAndConditionsModalContent?.text3 && (
                        <>
                            <hr></hr>
                            {termsAndConditionsModalContent.label3 && (
                                <div className="tac-header">
                                    {termsAndConditionsModalContent.label3}
                                </div>
                            )}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        termsAndConditionsModalContent?.text3 ||
                                        '',
                                }}
                            />
                        </>
                    )}
                </Modal>
            )}
        </>
    );
}
export default TermsAndConditionsModal;
