import React, { useContext } from 'react';
import PageNotFoundView from '../page-not-found-view/page-not-found-view';
import RewardsView from './rewards-view';
import { useServerData } from '../../hooks/use-server-data';
import { useLocation } from 'react-router-dom';
import FprUrlValidationService from '../../services/fpr-url-validation-service/fpr-url-validation-service';
import ServerContext from '../../contexts/serverContext';

const validateUrl = async () => {
    const route = useLocation().pathname;

    const serverContext = useContext(ServerContext);
    console.log('sc brand: ', serverContext.brand);

    const apiResponse = await new FprUrlValidationService().validateFordPassUrl(
        serverContext.brand,
        serverContext.currentLanguageRegionCode,
        route
    );

    return apiResponse?.isValidUrl ? apiResponse.isValidUrl : false;
};

export const RewardsViewHandler = () => {
    const apiResponse = useServerData('validateFprUrl', validateUrl)[0];
    const isValidUrl = apiResponse?.data;
    console.info('isValidUrl?-', isValidUrl);
    if (!isValidUrl) {
        return <PageNotFoundView />;
    } else {
        return <RewardsView />;
    }
};

export default RewardsViewHandler;
