import React, { useEffect, useState, useCallback, useContext } from 'react';
import { VehiclePortal } from '../../components/sections';
import ProfileService from '../../services/profile-service/profile-service';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import { useHistory, useLocation } from 'react-router-dom';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import { useAnalytics } from '../../hooks/use-analytics';

import './vehicle-dashboard-view.scss';
import { useShortcodeProvider } from '../../hooks/use-shortcode-provider';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import useUserProfile from '../../hooks/use-user-profile';
import useSeoConfig from '../../hooks/use-seo-config';
import { ServerSideService } from '../../services/server-side-service/server-side-service';
import { VehicleSelector } from '../../components/sections/vehicle-portal/components/vehicle-selector/vehicle-selector';
import {
    useVehiclePageContent,
    VehiclePageContentProps,
} from '../../components/sections/vehicle-portal/hooks/use-vehicle-page-content';
import {
    BRAND,
    GA_COUNTRIES_BRAND_SPECIFIC,
    GA_ZERO_CAR_SCENARIO_COUNTRIES,
    NEW_VEHICLE_DASHBOARD_COUNTRIES,
} from '../../constants';
import { getAemVehicleModelData } from '../../hooks/use-aem-vehicle-model-data';
import serverContext from '../../contexts/serverContext';
import { VehicleYmm } from '../../models/vehicle-ymm';
import FMVehiclePortal from '../../components/sections/fm-vehicle-portal/fm-vehicle-portal';

export const goToMyAccount = (
    vehiclePageContent: VehiclePageContentProps | null
) => {
    if (vehiclePageContent && vehiclePageContent?.addVehicleCtaUrl) {
        (window as any).location = vehiclePageContent?.addVehicleCtaUrl;
    }
};

export const VehicleDashboardView = () => {
    const [ymmPromise, ymmResolve] = useShortcodeProvider<VehicleAttributes>();
    const vehiclePageContent = useVehiclePageContent();
    const [fireEvents] = useAnalytics(
        ['target-trigger-view', 'owner-profile'],
        'target-owner-profile',
        { ymm: ymmPromise }
    );
    const [
        vehicleAttributes,
        setVehicleAttributes,
    ] = useState<VehicleAttributes | null>(null);

    const history = useHistory();
    const goToHomePage = useCallback(() => {
        history.push('/');
    }, [history]);
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const authenticationService = new AuthenticationService();
    const { brand, currentRegionCode, currentLanguageRegionCode } = useContext(
        serverContext
    );
    const vin =
        new URLSearchParams(useLocation().search).get('vin') || undefined;
    const profileService = new ProfileService();
    const profile = useUserProfile();

    useSeoConfig('vehicle-dashboard', undefined, [
        {
            fieldName: 'ymm',
            fieldValue: vehicleAttributes
                ? `${vehicleAttributes.year} ${vehicleAttributes.make} ${vehicleAttributes.model}`
                : '',
        },
        {
            fieldName: 'year',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.year}` : '',
        },
        {
            fieldName: 'make',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.make}` : '',
        },
        {
            fieldName: 'model',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.model}` : '',
        },
    ]);

    const serverService = new ServerSideService();
    if (serverService.isClientSide()) {
        sessionStorage.removeItem('vinLookupResponse');
        sessionStorage.setItem('calledOnce', 'false');
        sessionStorage.setItem('feature-color', '');
    }

    useEffect(() => {
        const processZeroCarScenario = () => {
            if (
                GA_ZERO_CAR_SCENARIO_COUNTRIES.includes(currentRegionCode) ||
                GA_COUNTRIES_BRAND_SPECIFIC.includes(
                    `${brand}_${currentLanguageRegionCode}`
                )
            ) {
                if (
                    vehiclePageContent !== null &&
                    Object.keys(vehiclePageContent).length !== 0
                ) {
                    goToMyAccount(vehiclePageContent);
                }
            } else {
                goToHomePage();
            }
        };

        if (profile) {
            profileService.persistVehicleSelection(profile.profile.email, vin);
            if (profile.vehicles.length > 0) {
                setVehiclesData(
                    [...profile.vehicles]
                        .sort(
                            (vehicle1, vehicle2) =>
                                parseInt(vehicle2.modelYear, 10) -
                                parseInt(vehicle1.modelYear, 10)
                        )
                        .map(vehicle => {
                            return {
                                year: parseInt(vehicle.modelYear, 10),
                                make: vehicle.make,
                                model: vehicle.modelName?.trim(),
                                vin: vehicle.vin,
                                ownerState: vehicle.ownerState,
                                registrationNo: vehicle.licenseplate,
                                fuelType: vehicle.fuelType,
                                engineType: vehicle.engineType,
                                warrantyStartDate: vehicle.warrantyStartDate,
                            };
                        })
                );
            } else {
                processZeroCarScenario();
            }
        } else {
            authenticationService.onIsAuthenticated().then(authenticated => {
                !authenticated && authenticationService.login();
            });
        }
    }, [profile, goToHomePage]);
    useEffect(() => {
        if (profile && vehiclesData.length > 0) {
            let [match] = [vehiclesData[0]];
            const selectedVin = profileService.getUserPreferences(
                profile.profile.email
            )?.lastSelectedVin;
            if (selectedVin) {
                match =
                    vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                    vehiclesData[0];
                !match && (match = vehiclesData[0]);
            }
            getAemVehicleModelData(
                brand,
                currentRegionCode,
                currentLanguageRegionCode,
                match.year,
                match.model,
                'PTS_MARKET_NAME'
            ).then((data: VehicleYmm | undefined) => {
                if (data !== undefined) {
                    setVehicleAttributes({ ...match, seoKey: data.seoKey });
                } else {
                    setVehicleAttributes(match);
                }
            });
            ymmResolve(match);
        }
    }, [vehiclesData]);

    const handleOnClick = (title: string, index: number) => {
        getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            vehiclesData[index].year,
            vehiclesData[index].model,
            'PTS_MARKET_NAME'
        ).then((data: VehicleYmm | undefined) => {
            if (data !== undefined) {
                setVehicleAttributes({
                    ...vehiclesData[index],
                    seoKey: data.seoKey,
                });
            } else {
                setVehicleAttributes(vehiclesData[index]);
            }
        });
        fireEvents(
            ['target-trigger-view', 'owner-profile'],
            'target-owner-profile',
            { ymm: Promise.resolve(vehiclesData[index]) }
        );
        sessionStorage.setItem('calledOnce', 'false');
        sessionStorage.setItem('feature-color', '');
    };
    return (
        <>
            {vehicleAttributes ? (
                <>
                    {brand === BRAND.ford.LONG_NAME &&
                    NEW_VEHICLE_DASHBOARD_COUNTRIES.includes(
                        currentRegionCode
                    ) ? (
                        <FMVehiclePortal
                            vehicleAttributes={vehicleAttributes}
                            page="DASHBOARD"
                            onChange={handleOnClick}
                        />
                    ) : (
                        <VehiclePortal
                            vehicleAttributes={vehicleAttributes}
                            page="DASHBOARD"
                            topMasthead={
                                <VehicleSelector onChange={handleOnClick} />
                            }
                        />
                    )}
                </>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};
export default VehicleDashboardView;
