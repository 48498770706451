import React from 'react';
import FMActionItem from '../../common/fm-action-item/fm-action-item';
import { ActionItemProps } from '../../../models/experiencefragments/action-item';

const FMActionItemSyncMap = (props: ActionItemProps) => {
    const { content, requiredLabel, recommendedLabel } = props;

    return (
        <FMActionItem
            content={content}
            required={true}
            requiredLabel={requiredLabel}
            recommendedLabel={recommendedLabel}
        />
    );
};

export default FMActionItemSyncMap;
