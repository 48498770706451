import React, { useEffect, useState } from 'react';
import './cancel-amend-confirmation.scss';
import { FMButton } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import { useViewport } from '../../../../hooks/owners-osb';

export const CancelAmendConfirmation: React.FC = () => {
    const { isMobileView } = useViewport();
    const [isMobile, setIsMobile] = useState(isMobileView);

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 390);
            };

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    const searchParams = new URLSearchParams(window.location.search);
    const isCancel = searchParams.get('isCancel') === 'true';
    const osbContentData =
        useOSBContentFragment(
            'common/osb-v3',
            'canceled-amended-booking-view'
        ) || [];
    return (
        <div className="osb-v3-cancel-amend-confirmation-component">
            <div className="osb-v3-cancel-amend-heading-wrapper">
                <div className="osb-v3-cancel-amend-message-title-wrapper">
                    <h2 className="osb-v3-cancel-amend-message-title">
                        {isCancel
                            ? osbContentData?.cancelBookingHeadingConfirmationLabel
                            : osbContentData?.amendbookingHeadingConfirmationLabel}
                    </h2>
                </div>
                <div className="osb-v3-cancel-amend-message-divider" />
                <div className="osb-v3-cancel-amend-subtitle-wrapper">
                    <p className="osb-v3-sercice-centre-confirmation-text">
                        {osbContentData?.serviceCentreNotifiedLabel}
                    </p>
                    <p className="osb-v3-cancel-amend-location-time-message">
                        {
                            osbContentData?.bookingCancelationConfirmationDescriptionLabel
                        }
                    </p>
                </div>
            </div>
            <div className="osb-v3-log-in-or-create-booking-option-section">
                <div className="osb-v3-log-in-account-option-wrapper">
                    <FMButton
                        className="osb-v3-log-in-account-option-button"
                        type="primary"
                        label={osbContentData?.loginOrRegisterButtonLabel}
                        onClick={() => {
                            console.log('login or register button clicked');
                        }}
                    />
                    <div className="osb-v3-registration-prompt-wrapper">
                        <h3 className="osb-v3-registration-prompt-heading">
                            {osbContentData?.whyRegisterHeadingPromptLabel}
                        </h3>
                        <ul className="osb-v3-registration-prompt-list">
                            {Array.isArray(
                                osbContentData?.registrationInsentivePromptLabels
                            ) &&
                                osbContentData?.registrationInsentivePromptLabels.map(
                                    (element, index) => (
                                        <li
                                            key={`${element}-${index}`}
                                            className="osb-v3-registration-prompt-list-item"
                                        >
                                            {element}
                                        </li>
                                    )
                                )}
                        </ul>
                    </div>
                </div>
                <div className="osb-v3-log-in-or-create-booking-option-divider">
                    <div className="osb-v3-log-in-or-create-booking-option-divider-line"></div>
                    <p className="osb-v3-log-in-or-create-booking-option-divider-text">
                        {osbContentData?.dividerLabel}
                    </p>
                    <div className="osb-v3-log-in-or-create-booking-option-divider-line"></div>
                </div>
                <div className="osb-v3-create-booking-option-wrapper">
                    <h3>{osbContentData?.rebookAppointmentHeadingLabel}</h3>
                    <p>
                        {isCancel || isMobile
                            ? osbContentData?.rebookCanceledBookingMessageLabel
                            : osbContentData?.rebookAmendedBookingMessageLabel}
                    </p>
                    <div className="osb-v3-log-in-account-option-wrapper">
                        <FMButton
                            type="primary"
                            label={
                                osbContentData?.bookAnotherServiceButtonLabel
                            }
                            onClick={() => {
                                console.log(
                                    'book another service button clicked'
                                );
                            }}
                            className="osb-v3-book-another-service-button, osb-v3-log-in-account-option-button"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CancelAmendConfirmation;
