import React, { ReactNode } from 'react';
import './connected-vehicle.scss';
import { StringUtil } from '../../utils/string-util/string-util';
export type HeaderType = 'good' | 'moderate' | 'severe' | 'blue' | 'none';
export interface Props {
    className?: string;
    header: string;
    headerType?: HeaderType;
    children?: ReactNode;
    iconPath?: string;
    number?: any;
}
const ConnectedVehicle = (props: Props) => {
    const transformToTitleCase = (str: string) => {
        return str
            ?.toLowerCase()
            ?.split(' ')
            ?.map((word: string) => StringUtil.capitalizeFirstLetter(word))
            .join(' ');
    };

    return (
        <div
            className={['connected-vehicle', props.className].join(' ').trim()}
        >
            <div className={'connected-vehicle-heading'}>
                {transformToTitleCase(props.header)}
            </div>
            <div
                className={[
                    'connected-vehicle-bar',
                    ` ${props.headerType || 'none'}`,
                ]
                    .join(' ')
                    .trim()}
            />
            {props.iconPath && (
                <img
                    className={['icon', props.className].join(' ').trim()}
                    src={process.env.REACT_APP_AEM_BASE_URL + props.iconPath}
                    alt={''}
                />
            )}
            {props.number && (
                <div className={['number', props.className].join(' ').trim()}>
                    {props.number}
                </div>
            )}
            {props.children}
        </div>
    );
};
export default ConnectedVehicle;
