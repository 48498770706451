import { useContent } from '../../../../hooks/use-server-content';

export interface FeatureContentAProps {
    hide: boolean;
    imageHero: string;
    imageAlt: string;
    heading: string;
    bodyCopy: string;
    ctaText: string;
    ctaAriaLabel: string;
    ctaLink: string;
    ctaTarget: string;
}
export const useFeatureContentA = (
    category: string,
    name: string,
    ymmServlet: boolean
): FeatureContentAProps | null => {
    const [content, getValueByName] = useContent(
        category,
        name,
        undefined,
        ymmServlet
    );
    let contentFragment: any = {};
    if (content) {
        content?.elements.forEach(element => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.heading) {
            contentFragment = null;
        }
    }
    return contentFragment as FeatureContentAProps;
};
