import { useContent } from '../../../../../hooks/use-server-content';

export interface ReviewAndSubmitContentProps {
    defaultImage: string;
    vehicleText: string;
    vinText: string;
    serviceDateText: string;
    dealerText: string;
    rebateText: string;
    nameText: string;
    emailText: string;
    memberIdText: string;
    rebateMailingAddressText: string;
    editButtonText: string;
    submitButtonText: string;
}

export const useReviewAndSubmitContent = (): ReviewAndSubmitContentProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates/request-form-steps',
        'review-and-submit'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ReviewAndSubmitContentProps;
};
