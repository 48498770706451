import React from 'react';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { SubscriptionsReponse } from '../../../services/subscriptions-service/subscriptions-service';
import { URLUtil } from '../../utils/url-util/url-util';

interface Props {
    cardTypes: string;
    vehicleType?: string;
    title: string;
    iconPath: string;
    staticDescription: string;
    dynamicDescription: string;
    ctaTitle: string;
    staticCtaPath: string;
    dynamicCtaPath: string;
    ctaAriaLabel: string;
    ctaTargetBlank: boolean;
    paidSubscriptions: string;
    paidSubscriptionsExpiring: string;
    freeSubscriptions: string;
    freeSubscriptionsExpiring: string;
    vin: string;
    data: SubscriptionsReponse;
}

const VehicleCheckListSubscriptions = (props: Props) => {
    const {
        paidSubscriptions,
        paidSubscriptionsExpiring,
        freeSubscriptions,
        freeSubscriptionsExpiring,
    } = props.data || {};

    const hasSubscriptions =
        paidSubscriptions > 0 ||
        paidSubscriptionsExpiring > 0 ||
        freeSubscriptions > 0 ||
        freeSubscriptionsExpiring > 0;

    const FormatPlural = (input: string, count: number) => {
        if (count === 1) return input.replace(/ *\([^)]*\)/g, '');
        return input.replace(/["'()]/g, '');
    };

    const DynamicDescription = () => {
        return (
            <>
                {paidSubscriptions > 0 && (
                    <p>
                        {paidSubscriptions}{' '}
                        {FormatPlural(
                            props.paidSubscriptions,
                            paidSubscriptions
                        )}
                    </p>
                )}
                {paidSubscriptionsExpiring > 0 && (
                    <p>
                        {paidSubscriptionsExpiring}{' '}
                        {FormatPlural(
                            props.paidSubscriptionsExpiring,
                            paidSubscriptionsExpiring
                        )}
                    </p>
                )}
                {freeSubscriptions > 0 && (
                    <p>
                        {freeSubscriptions}{' '}
                        {FormatPlural(
                            props.freeSubscriptions,
                            freeSubscriptions
                        )}
                    </p>
                )}
                {freeSubscriptionsExpiring > 0 && (
                    <p>
                        {freeSubscriptionsExpiring}{' '}
                        {FormatPlural(
                            props.freeSubscriptionsExpiring,
                            freeSubscriptionsExpiring
                        )}
                    </p>
                )}
            </>
        );
    };

    const subscriptionsCta = (): string => {
        return URLUtil.omitEmptyParams(
            props.staticCtaPath.replace('%vin', props.vin || '')
        );
    };

    return (
        <>
            <VehicleChecklist
                header={props.title}
                type={props.cardTypes}
                description={
                    hasSubscriptions ? (
                        <DynamicDescription />
                    ) : (
                        props.staticDescription
                    )
                }
                ctaTitle={props.ctaTitle}
                ctaPath={subscriptionsCta()}
                iconPath={props.iconPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
            />
        </>
    );
};

export default VehicleCheckListSubscriptions;
