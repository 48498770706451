import React, { CSSProperties, ReactNode, useContext } from 'react';
import { FdsChevron } from '../chevron/fds-chevron';
import PropTypes from 'prop-types';
import './primary-button.scss';
import ServerContext from '../../../contexts/serverContext';
import { transformRelativeUrlValidateRoot } from '../../utils/router-util/router-util';
import PrimaryButtonChevron from './hook/primary-button-chevron';

export type PrimaryButtonColor = 'light' | 'dark';
export type PrimaryButtonFill = 'fill' | 'outline';
export type Direction = 'up' | 'down' | 'left' | 'right';

interface Props {
    color: PrimaryButtonColor;
    fill: PrimaryButtonFill;
    chevron: boolean;
    href?: string;
    target?: string;
    ariaLabel?: string;
    role?: string;
    onClick?: (event: any) => void;
    onKeyDown?: (event: any) => void;
    onMouseDown?: (event: any) => void;
    className?: string;
    style?: CSSProperties;
    children?: ReactNode;
    ref?: React.RefObject<HTMLButtonElement>;
    disabled?: boolean;
    chevronFill?: boolean;
    dataTestId?: string;
    type?: any;
    preventCtaDefault?: boolean;
    chevronDirection?: Direction;
    newTabAriaLabel?: string;
}

const PrimaryButton = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLButtonElement>) => {
        const context = useContext(ServerContext);
        return (
            <>
                {props.href ? (
                    <a
                        data-testid={props.dataTestId || ''}
                        className={[
                            'primary-button',
                            `color-${props.color}`,
                            `color-${props.fill}`,
                            `${props.className ? props.className : ''}`,
                        ]
                            .join(' ')
                            .trim()}
                        onClick={e => {
                            props.preventCtaDefault && e.preventDefault();
                            if (props.onClick) props.onClick(e);
                        }}
                        onKeyDown={props.onKeyDown}
                        style={props.style}
                        aria-label={props.ariaLabel}
                        href={transformRelativeUrlValidateRoot(
                            props.href,
                            context.root
                        )}
                        target={props.target || ''}
                    >
                        <span
                            className={`button-label ${
                                props.chevron ? '' : 'no-chevron'
                            }`}
                        >
                            {props.children}
                        </span>
                        {props.chevron && (
                            <FdsChevron
                                type={props.chevronFill ? 'filled' : 'unfilled'}
                                direction="right"
                            />
                        )}
                    </a>
                ) : (
                    <PrimaryButtonChevron
                        color={props.color}
                        fill={props.fill}
                        chevron={props.chevron}
                        ariaLabel={props.ariaLabel}
                        role={props.role}
                        onClick={props.onClick}
                        onKeyDown={props.onKeyDown}
                        onMouseDown={props.onMouseDown}
                        className={props.className}
                        style={props.style}
                        ref={ref}
                        disabled={props.disabled}
                        chevronFill={props.chevronFill}
                        dataTestId={props.dataTestId}
                        type={props.type}
                        chevronDirection={props.chevronDirection}
                        newTabAriaLabel={props.newTabAriaLabel}
                    >
                        {props.children}
                    </PrimaryButtonChevron>
                )}
            </>
        );
    }
);

PrimaryButton.propTypes = {
    style: PropTypes.object,
};

PrimaryButton.displayName = 'PrimaryButton';
export default PrimaryButton;
