import React from 'react';
import { BreadcrumbWrapperReimagine } from '../../../common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';

interface MaintenanceScheduleBreadcrumbProps {
    breadcrumbTitle: string;
    maintenanceLandingPath: string;
    currentRoot: string;
    vehicleName: string;
    isWebview: boolean;
}

interface MaintanenceScheduleLandingBreadCrumbProps {
    breadcrumbText: string;
    currentRoot: string;
    maintenanceLandingPath: string;
}

const MaintanenceScheduleLandingBreadCrumb: React.FC<MaintanenceScheduleLandingBreadCrumbProps> = ({
    breadcrumbText,
    currentRoot,
    maintenanceLandingPath,
}) => {
    const handleClick = () => {
        sessionStorage.removeItem('selectedVin');
        window.location.href = `${currentRoot}${maintenanceLandingPath}`;
    };

    return (
        <>
            <span
                className="breadcrumb-link-ellipses-expanded"
                aria-label={`Click to goto ${breadcrumbText} page`}
                onClick={handleClick}
            >
                <span>{breadcrumbText}</span>
            </span>
            <span className="back-slash">{'/'}</span>
        </>
    );
};

const MaintenanceScheduleBreadcrumb: React.FC<MaintenanceScheduleBreadcrumbProps> = ({
    breadcrumbTitle,
    maintenanceLandingPath,
    currentRoot,
    vehicleName,
    isWebview,
}) => {
    const breadcrumbText = breadcrumbTitle
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ');

    return (
        <div>
            {isWebview ? (
                ''
            ) : (
                <BreadcrumbWrapperReimagine
                    currentPage={vehicleName}
                    hashId="support"
                    hideFirstBreadcrumb={isWebview}
                    title={
                        isWebview ? (
                            ''
                        ) : (
                            <MaintanenceScheduleLandingBreadCrumb
                                breadcrumbText={breadcrumbText}
                                currentRoot={currentRoot}
                                maintenanceLandingPath={maintenanceLandingPath}
                            />
                        )
                    }
                />
            )}
        </div>
    );
};

export default MaintenanceScheduleBreadcrumb;
