import React, { useEffect, useReducer, useState } from 'react';
import './pickup-and-delivery-address-form.scss';
import { Checkbox, FMButton, FMDropdown, FMInput } from '@own/fds-react';
import PrivacyText from '../../common/privacy-text/privacy-text';
import InfoBox from '../../common/info-box/info-box';
import NotificationIcon from './../../../../../assets/osb-v3/green-checkmark.svg';
import {
    getInitialServiceLocationState,
    useOSBStep,
    usePersonalDetailStep,
} from '../../../../../hooks/owners-osb';
//import { useLocation } from 'react-router-dom';
import ProfileService from '../../../../../services/profile-service/profile-service';
import { ServiceData } from '../../../../../models/osb-model/osb-dealerservice-info';
import { ServiceLocationDetails } from '../../../../../models/osb-model/osb-service-location';
import { serviceLocationReducer } from '../../../../../reducers/osb-reducers';
import CommentInput from '../../common/comment-input/comment-input';
import GoogleMapService from '../../../../../services/osb-service/google-map-service/google-map-service';
import DealerSearchDropdown from '../../dealer-search-dropdown/dealer-search-dropdown';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';

interface PickupAndDeliveryAddressFormProps {
    onEditClick: () => void;
}

const PickupAndDeliveryAddressForm: React.FC<PickupAndDeliveryAddressFormProps> = ({
    onEditClick,
}) => {
    const pickupAndDeliveryAddressContent =
        useOSBContentFragment('common/osb-v3', 'pickup-and-delivery-address') ||
        {};
    console.log(
        'pickupAndDeliveryAddressContent - ',
        JSON.stringify(pickupAndDeliveryAddressContent)
    );

    const { osbPersonalDetail } = usePersonalDetailStep();
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    //const isAuth = searchParams.get('isAuth') === 'true';
    const deepLinkParams = new URLSearchParams(window.location.search);
    const isAuth = deepLinkParams.get('isAuth') === 'true';
    const [useProfileForAddress, setUseProfileForAddress] = useState(false);
    const initialServiceLocationState = {
        address1: '',
        address2: '',
        address3: '',
        town: '',
        postalCode: '',
        parkingType: '',
        comment: '',
    };

    const ERROR_MESSAGES = {
        address1:
            pickupAndDeliveryAddressContent?.addressLine1RequiredFieldAlertMessage,
        postalCode:
            pickupAndDeliveryAddressContent?.postcodeIsRequiredFieldAlertMessage,
        town:
            pickupAndDeliveryAddressContent?.townOrCityIsRequiredFieldAlertMessage,
        parkingType:
            pickupAndDeliveryAddressContent?.parkingTypeIsRequiredFieldAlertMessage,
    };

    const [errors, setErrors] = useState({
        address1: ERROR_MESSAGES.address1,
        postalCode: ERROR_MESSAGES.postalCode,
        town: ERROR_MESSAGES.town,
        parkingType: ERROR_MESSAGES.parkingType,
    });
    const [touched, setTouched] = useState({
        address1: false,
        postalCode: false,
        town: false,
        parkingType: false,
    });

    const parkingOptions = Array.isArray(
        pickupAndDeliveryAddressContent?.parkingTypes
    )
        ? pickupAndDeliveryAddressContent?.parkingTypes.map(parkingType => ({
              label: parkingType,
              value: parkingType,
          }))
        : [];
    const handleCheckboxChange = async () => {
        setUseProfileForAddress(prevState => !prevState);
        if (!useProfileForAddress) {
            const profileService = new ProfileService();
            try {
                const { profile } = await profileService.request();

                setComponentPayload({
                    address1: profile.address1,
                    address2: '',
                    address3: '',
                    town: profile.city,
                    postalCode: profile.zip,
                });
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        } else {
            setComponentPayload({
                address1: '',
                address2: '',
                address3: '',
                town: '',
                postalCode: '',
            });
        }
    };
    const { osbStep } = useOSBStep();
    const [serviceLocationDetail, setServiceLocationDetail] = useReducer(
        serviceLocationReducer,
        getInitialServiceLocationState()
    );
    const setComponentPayload = (
        payload:
            | { [key: string]: string | ServiceData[] }
            | ServiceLocationDetails
    ): void => {
        setServiceLocationDetail({
            type: 'SET',
            payload: payload,
            localStorageExpiry: osbStep.localStorageExpiry,
        });
    };
    const handleClearFields = () => {
        setComponentPayload(initialServiceLocationState);
        setErrors({
            address1: '',
            postalCode: '',
            town: '',
            parkingType: '',
        });
        setTouched({
            address1: false,
            postalCode: false,
            town: false,
            parkingType: false,
        });
        setUseProfileForAddress(false);
    };
    const handleCommentChange = (comment: string) => {
        const value = comment.trim();
        setComponentPayload({ comment: value });
    };

    const handleParkingTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const selectedParkingType = event.target.value;
        setComponentPayload({ parkingType: selectedParkingType });
        setErrors(prev => ({
            ...prev,
            parkingType: selectedParkingType ? '' : ERROR_MESSAGES.parkingType,
        }));
        handleTouch('parkingType');
    };

    const handlePostcodeChange = (postalCode: string) => {
        setComponentPayload({ postalCode });

        if (postalCode.length >= 3) {
            GoogleMapService.getCityFromPostalCode(postalCode).then(city => {
                if (city) {
                    setComponentPayload({ town: city });
                    setErrors(prev => ({ ...prev, town: '' }));
                } else {
                    console.log('City not found.');
                }
            });
        }

        setErrors(prev => ({
            ...prev,
            postalCode: postalCode ? '' : ERROR_MESSAGES.postalCode,
        }));
    };

    const handleContinueClick = () => {
        console.log('submit');
    };

    const validateField = (
        fieldName: keyof typeof ERROR_MESSAGES,
        value: string
    ) => {
        let errorMessage = '';
        if (!value?.trim()) {
            errorMessage = ERROR_MESSAGES[fieldName] as string;
        }
        setErrors(prev => ({ ...prev, [fieldName]: errorMessage }));
    };
    const handleTouch = (fieldName: string) => {
        setTouched(prevTouched => ({
            ...prevTouched,
            [fieldName]: true,
        }));
    };
    useEffect(() => {
        validateField('address1', serviceLocationDetail.address1);
        validateField('postalCode', serviceLocationDetail.postalCode);
        validateField('town', serviceLocationDetail.town);
        validateField('parkingType', serviceLocationDetail.parkingType);
    }, [serviceLocationDetail]);

    useEffect(() => {
        GoogleMapService.loadScript('google');
    }, []);

    const isFormValid = Object.values(errors).every(error => error === '');

    return (
        <div
            data-testid="pickup-form"
            className="osb-v3-pickup-and-delivery-contact-details-form"
        >
            <div className="osb-v3-pickup-and-delivery-header">
                <span className="osb-v3-pickup-and-delivery-contact-label">
                    {pickupAndDeliveryAddressContent?.yourContactDetailsTitle}
                </span>
                <a
                    href="#"
                    className="osb-v3-pickup-and-delivery-edit-link"
                    onClick={e => {
                        e.preventDefault();
                        onEditClick();
                    }}
                >
                    {pickupAndDeliveryAddressContent?.editLinkLabel}
                </a>
            </div>
            <div className="osb-v3-pickup-and-delivery-contact-info">
                {(osbPersonalDetail?.firstName ||
                    osbPersonalDetail?.lastName) && (
                    <div className="osb-v3-pickup-and-delivery-name">
                        {osbPersonalDetail?.title
                            ? `${osbPersonalDetail?.title} `
                            : ''}
                        {osbPersonalDetail?.firstName
                            ? `${osbPersonalDetail?.firstName} `
                            : ''}
                        {osbPersonalDetail?.lastName || ''}
                    </div>
                )}
                {osbPersonalDetail?.contactNumber && (
                    <div className="osb-v3-pickup-and-delivery-phone">
                        {osbPersonalDetail?.contactNumber}
                    </div>
                )}
                {osbPersonalDetail?.email && (
                    <div className="osb-v3-pickup-and-delivery-email">
                        {osbPersonalDetail?.email}
                    </div>
                )}
            </div>

            <div className="osb-v3-pickup-and-delivery-divider"></div>
            <div className="osb-v3-pickup-delivery-title">
                {
                    pickupAndDeliveryAddressContent?.yourPickupAndDeliveryAddressLabel
                }
            </div>
            <div className="osb-v3-courtesy-car-info-box-title">
                <InfoBox
                    title={
                        pickupAndDeliveryAddressContent?.yourCourtesyCarWillBeDeliveredHereText as string
                    }
                    iconUrl={NotificationIcon}
                    backgroundColor="#E5F3E6"
                />
            </div>

            {isAuth && (
                <div className="osb-v3-pickup-delivery-checkbox">
                    <Checkbox
                        checkboxArray={[
                            {
                                ariaLabel: 'use-profile-checkbox',
                                itemName: pickupAndDeliveryAddressContent?.useMyContactDetailsForPickupAndDeliveryCheckboxLabel as string,
                                name: 'checkbox-item-1',
                                value: 'item-1',
                                checked: useProfileForAddress,
                                onClick: handleCheckboxChange,
                            },
                        ]}
                        direction="standard"
                    />
                </div>
            )}

            <div className="osb-v3-pickup-and-delivery-address-fields">
                <div className="osb-v3-pickup-and-delivery-address1">
                    <label htmlFor="address1">
                        {pickupAndDeliveryAddressContent?.addressLine1Label}
                    </label>
                    <FMInput
                        dataTestId="address1-input"
                        id="address1"
                        type="text"
                        ariaLabel="Address line 1"
                        value={serviceLocationDetail.address1}
                        onChange={e => {
                            const value = e.target.value;
                            setComponentPayload({ address1: value });
                            validateField('address1', value);
                            handleTouch('address1');
                        }}
                        required={true}
                    />
                    {touched.address1 && errors.address1 && (
                        <div
                            data-testid="address1-error"
                            className="osb-v3-Pnd-error-message"
                        >
                            {errors.address1}
                        </div>
                    )}
                </div>
                <div className="osb-v3-pickup-and-delivery-address2">
                    <label htmlFor="address2">
                        {' '}
                        {pickupAndDeliveryAddressContent?.addressLine2Label}
                    </label>
                    <FMInput
                        dataTestId="address2-input"
                        id="address2"
                        type="text"
                        ariaLabel="Address line 2"
                        value={serviceLocationDetail.address2}
                        onChange={e => {
                            const value = e.target.value.trimStart();
                            setComponentPayload({ address2: value });
                        }}
                        required={true}
                    />
                </div>
                <div className="osb-v3-pickup-and-delivery-address3">
                    <label htmlFor="address3">
                        {' '}
                        {pickupAndDeliveryAddressContent?.addressLine3Label}
                    </label>
                    <FMInput
                        dataTestId="address3-input"
                        id="address3"
                        type="text"
                        ariaLabel="Address line 3"
                        value={serviceLocationDetail.address3}
                        onChange={e => {
                            const value = e.target.value.trimStart();
                            setComponentPayload({ address3: value });
                        }}
                        required={true}
                    />
                </div>
                <div className="osb-v3-pickup-delivery-postcode">
                    <label htmlFor="postcode">
                        {pickupAndDeliveryAddressContent?.postcodeLabel}
                    </label>
                    <DealerSearchDropdown
                        dataTestId="postcode-input"
                        onSelect={(selectedValue: string): void => {
                            handlePostcodeChange(selectedValue);
                        }}
                        onInputChange={(inputValue: string): void => {
                            validateField('postalCode', inputValue.trim());
                            handleTouch('postalCode');
                        }}
                        selectedValue={serviceLocationDetail.postalCode}
                        showFilter={false}
                        hasPostcodeSearch={true}
                    />
                    {touched.postalCode && errors.postalCode && (
                        <div
                            data-testid="postcode-error"
                            className="osb-v3-Pnd-error-message"
                        >
                            {errors.postalCode}
                        </div>
                    )}
                </div>
                <div className="osb-v3-pickup-and-delivery-town">
                    <label htmlFor="townCity">
                        {pickupAndDeliveryAddressContent?.townOrCityLabel}
                    </label>
                    <FMInput
                        dataTestId="town-input"
                        id="townCity"
                        type="text"
                        ariaLabel="Town or city"
                        value={serviceLocationDetail.town}
                        onChange={e => {
                            const value = e.target.value.trimStart();
                            setComponentPayload({ town: value });
                            validateField('town', value);
                            handleTouch('town');
                        }}
                        required={true}
                    />
                    {touched.town && errors.town && (
                        <div
                            data-testid="town-error"
                            className="osb-v3-Pnd-error-message"
                        >
                            {errors.town}
                        </div>
                    )}
                </div>
                <div className="osb-v3-pickup-and-delivery-parking-type">
                    <label htmlFor="parkingType">
                        {pickupAndDeliveryAddressContent?.parkingTypeLabel}
                    </label>
                    <FMDropdown
                        dataTestId="parking-type-dropdown"
                        id="parkingType"
                        onChange={handleParkingTypeChange}
                        options={parkingOptions}
                        value={serviceLocationDetail.parkingType}
                        state="standard"
                        ariaLabel="Select your parking type"
                    />
                    {touched.parkingType && errors.parkingType && (
                        <div
                            data-testid="parking-type-error"
                            className="osb-v3-Pnd-error-message"
                        >
                            {errors.parkingType}
                        </div>
                    )}
                </div>
                <div className="osb-v3-pickup-delivery-comment">
                    <label>
                        {pickupAndDeliveryAddressContent?.leaveACommentLabel}
                    </label>
                    <CommentInput
                        maxChars={500}
                        boxHeight={100}
                        charsRemainingText="Characters remaining"
                        initialComment={serviceLocationDetail.comment}
                        onCommentChange={handleCommentChange}
                    />
                </div>
                <a
                    href="#"
                    className="osb-v3-pickup-and-delivery-search-link"
                    onClick={e => {
                        e.preventDefault();
                        handleClearFields();
                    }}
                >
                    {
                        pickupAndDeliveryAddressContent?.searchForADifferentAddressLinkLabel
                    }
                </a>
            </div>
            <div className="osb-v3-Pnd-privacy-policy">
                <PrivacyText
                    fullPrivacyText={
                        pickupAndDeliveryAddressContent?.privacyText as string
                    }
                    linkText={
                        pickupAndDeliveryAddressContent?.privacyLinkText as string
                    }
                    privacyUrl={
                        pickupAndDeliveryAddressContent?.privacyLinkUrl as string
                    }
                />
            </div>
            <div className="osb-v3-pickup-and-delivery-divider"></div>
            <FMButton
                data-testid="submit-button"
                label={
                    pickupAndDeliveryAddressContent?.submitButtonLabel as string
                }
                theme="light"
                type="primary"
                onClick={handleContinueClick}
                disabled={!isFormValid}
            />
        </div>
    );
};

export default PickupAndDeliveryAddressForm;
