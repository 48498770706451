import React from 'react';
import './cao-banner.scss';
import { useCaoContent } from '../../hooks/use-cao-banner-details';
import { useLocation } from 'react-router-dom';
interface Props {
    heading?: string[];
}

export const CaoBanner = (props: Props) => {
    const location = useLocation();
    const CaoContent = useCaoContent();
    function getDynamicHeader() {
        const dynamicParam = location.pathname.split('/');
        if (dynamicParam[dynamicParam.length - 1] == 'coupons-offers-rebates') {
            return CaoContent?.bannerTitle;
        } else if (dynamicParam[dynamicParam.length - 1] == '') {
            if (
                dynamicParam[dynamicParam.length - 2] ==
                'coupons-offers-rebates'
            ) {
                return CaoContent?.bannerTitle;
            } else if (
                dynamicParam[dynamicParam.length - 2] !=
                'coupons-offers-rebates'
            ) {
                let dynamicHeader = dynamicParam[dynamicParam.length - 2];
                if (props.heading?.includes(dynamicHeader)) {
                    dynamicHeader = dynamicHeader?.split('-').join(' ');
                    return dynamicHeader;
                } else {
                    return CaoContent?.bannerTitle;
                }
            }
        } else {
            let dynamicHeader = dynamicParam[dynamicParam.length - 1];
            if (props.heading?.includes(dynamicHeader)) {
                dynamicHeader = dynamicHeader.split('-').join(' ');
                return dynamicHeader;
            } else {
                return CaoContent?.bannerTitle;
            }
        }
    }
    return (
        <div className="cao-text">
            <h1 className="cao-title">
                {getDynamicHeader() || `${CaoContent?.bannerTitle}`}
            </h1>
            <div className="cao-description">
                <div className="cao-description-text">
                    {CaoContent?.bannerDescription}
                </div>
            </div>
        </div>
    );
};
