import React from 'react';
import {
    NumberStatus,
    UomDistance,
    Vehiclestatus,
} from '../../../models/connected-vehicle';
import { CurrentMileageContent } from '../../../models/experiencefragments/connected-vehicle-bar';
import FMConnectedVehicle from '../fm-connected-vehicle/fm-connected-vehicle';
interface Props {
    connectedVehicleInfo: Vehiclestatus;
}

const FMConnectedVehicleMileage = (props: Props & CurrentMileageContent) => {
    const odometer: NumberStatus = props.connectedVehicleInfo.odometer;
    const uomDistance: UomDistance = props.connectedVehicleInfo.uomDistance;
    const nf = new Intl.NumberFormat();
    const odometerReading = () => {
        let odometerReading = '';
        if (odometer) {
            odometerReading = nf.format(odometer.value);
            if (odometerReading !== '0') {
                const uom = uomDistance === UomDistance.Miles ? ' MI' : ' KM';
                odometerReading += uom;
            }
        }
        return odometerReading;
    };
    return (
        <>
            {odometer && (
                <FMConnectedVehicle
                    className="fm-current-mileage"
                    header={props.title}
                    iconPath={props.icon}
                    number={odometerReading()}
                />
            )}
        </>
    );
};
export default FMConnectedVehicleMileage;
