import React, { useEffect } from 'react';
import { DealerProfileInfo } from '../../../../models/osb-model/osb-dealer-info';
import { ContentElements } from '../../../../models/priceCalculator-content-details';
import { getObjectFromAEMJson } from '../../owners-osb/osb-utils';
import { PRICE_CALCULATOR_RESULT_CONSTANTS } from '../price-calculator-constant';
import './pc-service-renderer-ford.scss';
import OsbUtilService from '../../../../services/osb-service/osb-util-service/osb-util-service';
import { useDealerService } from '../../../../hooks/owners-osb/use-dealer-service';
import { OSB_SERVICE_PRICE_TYPE } from '../../owners-osb/osb-constant';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import { Accordion } from '@own/fds-react';
import { useVehicleStep } from '../../../../hooks/owners-osb/use-vehicle-step';
import { InfoIconToolTip } from '../../../common/info-icon-tooltip/info-icon-tooltip';
import { ServiceInfo } from '../../../../models/osb-model/osb-dealerservice-info';
interface DealerServicesProps {
    dealer: DealerProfileInfo;
    priceCalculatorResultContent: ContentElements[];
    priceCalculatorContent: ContentElements[];
}
const PcServiceRenderer = (props: DealerServicesProps) => {
    const {
        httpState,
        mainServices,
        deliveryServices,
        loadDealerService,
    } = useDealerService();
    const { osbVehicleStep } = useVehicleStep();

    const getFormattedPrice = (price: string) => {
        const {
            PRICE_POR_LABEL,
            PRICE_FREE_LABEL,
            PRICE_PRECISION,
            PRICE_SEPARATOR,
            PRICE_PREFIX,
            PRICE_POSTFIX,
        } = PRICE_CALCULATOR_RESULT_CONSTANTS;
        const { priceCalculatorResultContent } = props;
        const numPrice = Number(price);

        if (
            numPrice === OSB_SERVICE_PRICE_TYPE.POR_SERVICE_PRICE ||
            numPrice === OSB_SERVICE_PRICE_TYPE.EMPTY_SERVICE_PRICE
        ) {
            return getObjectFromAEMJson(
                PRICE_POR_LABEL,
                priceCalculatorResultContent
            );
        }

        if (numPrice === OSB_SERVICE_PRICE_TYPE.FREE_SERVICE_PRICE) {
            return getObjectFromAEMJson(
                PRICE_FREE_LABEL,
                priceCalculatorResultContent
            );
        }

        const precision = getObjectFromAEMJson(
            PRICE_PRECISION,
            priceCalculatorResultContent
        );
        const separator = getObjectFromAEMJson(
            PRICE_SEPARATOR,
            priceCalculatorResultContent
        );
        const priceWithPrecision = OsbUtilService.addPrecision(
            price,
            precision
        );
        const priceWithSeparators = OsbUtilService.addSeparators(
            priceWithPrecision,
            separator
        );

        return `${getObjectFromAEMJson(
            PRICE_PREFIX,
            priceCalculatorResultContent
        )}${OsbUtilService.addDecimalSeparator(
            priceWithSeparators,
            separator
        )}${getObjectFromAEMJson(PRICE_POSTFIX, priceCalculatorResultContent)}`;
    };

    const renderDeliveryServices = (deliveryServices: ServiceInfo[]) => {
        return deliveryServices.map(service => {
            return (
                <div className="dealer-tile-service" key={service.serviceId}>
                    <div className="dealer-tile-service-info">
                        <span className="dealer-tile-service-name">
                            {service.name}
                        </span>
                        {service.description && (
                            <span id={`${service.serviceId}-tooltip`}>
                                <InfoIconToolTip
                                    osbServiceTooltip={true}
                                    tooltipInfoIconClass={'dark'}
                                    tooltipContent={service.description}
                                />
                            </span>
                        )}
                    </div>
                    <span className="dealer-tile-service-price">
                        {getFormattedPrice(
                            service.priceAfterDiscount.toString()
                        )}
                    </span>
                </div>
            );
        });
    };

    useEffect(() => {
        const { dealer, priceCalculatorResultContent } = props;
        const { vehicleDetails } = osbVehicleStep;
        if (dealer && vehicleDetails.vin && vehicleDetails.mileageInKm) {
            loadDealerService(dealer, priceCalculatorResultContent);
        }
    }, [props.dealer, osbVehicleStep.vehicleDetails]);

    return (
        <>
            {httpState.isLoading ? (
                <div className="dealer-tile-activity-indicator">
                    <ActivityIndicator />
                </div>
            ) : (
                <>
                    {mainServices.map(service => (
                        <div className="dealer-service-container">
                            <hr className="service-separator" />
                            <div
                                key={service.serviceId}
                                className="dealer-services"
                            >
                                <div className="dealer-service-info">
                                    <div className="service-name">
                                        {service.name}
                                        {service.description && (
                                            <span
                                                id={
                                                    service.serviceId +
                                                    '-tooltip'
                                                }
                                            >
                                                <InfoIconToolTip
                                                    osbServiceTooltip={true}
                                                    tooltipInfoIconClass={
                                                        'dark'
                                                    }
                                                    tooltipContent={
                                                        service.description
                                                    }
                                                />
                                            </span>
                                        )}
                                    </div>
                                    <div className="service-price">
                                        {getFormattedPrice(
                                            service.priceAfterDiscount.toString()
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="dealer-convenience-option">
                        <Accordion
                            panels={[
                                {
                                    body: renderDeliveryServices(
                                        deliveryServices
                                    ),
                                    header: getObjectFromAEMJson(
                                        PRICE_CALCULATOR_RESULT_CONSTANTS.CONVENIENT_OPTION_LABEL,
                                        props.priceCalculatorContent
                                    ),
                                    index: 0,
                                },
                            ]}
                            theme="dark-on-light"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default PcServiceRenderer;
