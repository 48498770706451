import React from 'react';
import { CouponsDetail, ViewTemplate } from '../../components/sections';

export const CouponsDetailView = () => {
    const page: any = 'Coupons Detail';

    return (
        <>
            <ViewTemplate page={page} hideSearchBar={true} hideLinksList={true}>
                <CouponsDetail />
            </ViewTemplate>
        </>
    );
};
export default CouponsDetailView;
