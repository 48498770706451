import React from 'react';
import './sub-category-view-masthead.scss';
import { Article } from '../../../../../models/experiencefragments/category-view';
import { Heading } from '../../../../common/heading/heading';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { CategoryOnClickCtaInfo } from '../../../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import { FMButton } from '@own/fds-react';

interface Props extends Article {
    class?: string;
    categoryId?: string;
    eventName?: string;
    categoryPath?: string;
}

export const SubCategoryViewMasthead = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const eventClick = async (ctaLabel: string, ctaPath: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            ctaName: ctaLabel,
            categoryPath: props.categoryPath,
            ctaUrl: ctaPath,
        };
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (props.eventName) {
            fireEvents(props.eventName, undefined, {
                categoryOnClickCtaInfo,
            });
        }
    };

    return (
        <div className="sub-category-view-masthead-container">
            <section
                className={`sub-category-view-masthead ${
                    props.class ? props.class : ''
                }`}
            >
                <div className="sub-category-view-masthead__wrapper">
                    {(props.headline || props.categoryId) && (
                        <h1 className="sub-category-view-masthead__category-headline">
                            {props.headline || props.categoryId}
                        </h1>
                    )}
                    {props.title && (
                        <Heading
                            type={
                                props.headline || props.categoryId ? 'h2' : 'h1'
                            }
                            className="sub-category-view-masthead__category-title"
                        >
                            {props.title}
                        </Heading>
                    )}
                    <div>
                        <p className="sub-category-view-masthead__description">
                            {props.description}
                        </p>
                    </div>
                    {props.ctaPath && (
                        <div className="sub-category-view-masthead__cta">
                            <FMButton
                                type={'primary'}
                                label={props.ctaLabel}
                                href={props.ctaPath}
                                chevron={'right'}
                                theme={'light'}
                                onClick={() =>
                                    eventClick(props.ctaLabel, props.ctaPath)
                                }
                                target={props.targetBlank ? '_blank' : ''}
                            />
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};
