import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VehiclePortal } from '../../components/sections';
import { RegionError } from '../../errors/region-error';
import { VehicleAttributes } from '../../models/vehicle-attributes';
import { useAnalytics } from '../../hooks/use-analytics';
import { useShortcodeProvider } from '../../hooks/use-shortcode-provider';
import { findByAlias } from '../../routes';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { useServerData } from '../../hooks/use-server-data';
import useSeoConfig from '../../hooks/use-seo-config';
import { VehicleSelector } from '../../components/sections/vehicle-portal/components/vehicle-selector/vehicle-selector';
import serverContext from '../../contexts/serverContext';
import { getAemVehicleModelData } from '../../hooks/use-aem-vehicle-model-data';
import VehicleTypeService from '../../services/vehicle-type-service/vehicle-type-service';
import FMVehiclePortal from '../../components/sections/fm-vehicle-portal/fm-vehicle-portal';
import { BRAND, NEW_VEHICLE_DASHBOARD_COUNTRIES } from '../../constants';

export const VehicleVinView = () => {
    const [ymmPromise, ymmResolve] = useShortcodeProvider<VehicleAttributes>();
    useAnalytics(
        ['target-trigger-view', 'owner-veh-info'],
        'target-owner-veh-info',
        { ymm: ymmPromise }
    );
    const { vin } = useParams<{ vin: string }>();
    const history = useHistory();
    const [
        vehicleAttributes,
        setVehicleAttributes,
    ] = useState<VehicleAttributes | null>(null);
    const [seoVehicleAttributes, setSeoVehicleAttributes] = useState<
        VehicleAttributes
    >();

    const [serverData] = useServerData(
        'vehicleVinData',
        () => {
            return new VehicleTypeService().request(
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            );
        },
        [vin]
    );

    if (serverData && !seoVehicleAttributes) {
        const vehicleData = serverData.data?.vehicleData;
        if (vehicleData) {
            const vehicleAttributes = {
                year: Number(vehicleData?.year),
                model: vehicleData?.model,
                make: vehicleData?.make,
            };
            setSeoVehicleAttributes(vehicleAttributes);
        }
    }

    useSeoConfig('vehicle-portal', undefined, [
        {
            fieldName: 'ymm',
            fieldValue: seoVehicleAttributes
                ? `${seoVehicleAttributes?.year} ${seoVehicleAttributes?.make} ${seoVehicleAttributes?.model}`
                : '',
        },
        {
            fieldName: 'year',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.year}` : '',
        },
        {
            fieldName: 'make',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.make}` : '',
        },
        {
            fieldName: 'model',
            fieldValue: vehicleAttributes ? `${vehicleAttributes.model}` : '',
        },
    ]);

    const goToPageNotFound = useCallback(
        (error: Error) => {
            const vinNotFoundPath = findByAlias('VehicleNotFoundView');
            let goToPath = `${vinNotFoundPath}?vin=${vin}`;

            if (error instanceof RegionError) {
                goToPath = `${vinNotFoundPath}?year=${error.year}&make=${error.make}&model=${error.model}`;
            }

            console.error(error.name + ': ' + error.message);
            history.push(goToPath);
        },
        [history, vin]
    );
    const {
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = useContext(serverContext);

    const parsedVehicleAttribute = vehicleAttributes?.modelYear
        ? vehicleAttributes
        : ({
              ...vehicleAttributes,
              nickName: vehicleAttributes?.model,
          } as VehicleAttributes);

    useEffect(() => {
        const updateVehicleAttributes = async () => {
            try {
                const decodedVin = await new VehicleTypeService().request(
                    vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                );
                const vehicleData = decodedVin.vehicleData;
                const aemVehicleModelData = await getAemVehicleModelData(
                    brand,
                    currentRegionCode,
                    currentLanguageRegionCode,
                    Number(vehicleData?.year),
                    vehicleData?.model || '',
                    'PTS_MARKET_NAME'
                );
                if (decodedVin || aemVehicleModelData) {
                    const { year, model, make, vin, fuelType } =
                        vehicleData ?? {};
                    const vehicleAttributes = {
                        year: Number(year),
                        model,
                        make,
                        vin,
                        ownerState: 1,
                        registrationNo:
                            vin && vin.length < 17 ? vin : undefined,
                        fuelType,
                        seoKey: aemVehicleModelData?.seoKey,
                    } as VehicleAttributes;
                    sessionStorage.setItem(
                        'vehicleAttributes',
                        JSON.stringify(vehicleAttributes)
                    );
                    setVehicleAttributes(vehicleAttributes);
                    ymmResolve(vehicleAttributes);
                }
            } catch (error) {
                if (error instanceof Error) goToPageNotFound(error);
            }
        };
        updateVehicleAttributes().then();
    }, [goToPageNotFound, vin]);
    return (
        <>
            {vehicleAttributes ? (
                <>
                    {brand === BRAND.ford.LONG_NAME &&
                    NEW_VEHICLE_DASHBOARD_COUNTRIES.includes(
                        currentRegionCode
                    ) ? (
                        <FMVehiclePortal
                            vehicleAttributes={vehicleAttributes}
                            page="VIN"
                        />
                    ) : (
                        <VehiclePortal
                            vehicleAttributes={vehicleAttributes}
                            page="VIN"
                            topMasthead={
                                <VehicleSelector
                                    currentSearchVehicle={
                                        parsedVehicleAttribute
                                    }
                                />
                            }
                        />
                    )}
                </>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};
export default VehicleVinView;
