import { CouponsService } from '../../../../services/coupons-service/coupons-service';

export const CouponApiData = async (brand: string | undefined) => {
    const requestCouponService = new CouponsService();
    const generateCoupons = await requestCouponService
        .request(brand)
        .then(couponDetails => {
            return couponDetails;
        });
    return generateCoupons;
};
