import React from 'react';
import './osb-use-location-lincoln.scss';
import navigationIcon from '../../../../../assets/navigation.svg';

interface Props {
    showCurrentLocation: () => void;
    useLocationLabel: string;
}

export const OSBUseLocation = (props: Props) => {
    return (
        <div className="osb-use-location">
            <button
                data-testid="UseMyLocationLink"
                onClick={props.showCurrentLocation}
            >
                <img src={navigationIcon} alt="navigationIcon" />
                <div>{props.useLocationLabel}</div>
            </button>
        </div>
    );
};
