import React from 'react';
import { Breadcrumbs, SearchBar } from '../../components/sections';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../page-not-found-view/hooks/use-help-cards-content';
import './sub-category-view.scss';

import { CategoryViewMasthead } from '../../components/sections/category/categories/category-view-masthead/category-view-masthead';
import { ArticleContainer } from '../../components/sections/category/sub-categories/article-container/article-container';
import { SubCategoryViewFragment } from '../../models/experiencefragments/category-view';
import { SimpleCards } from '../../components/sections/category/sub-categories/simple-cards/simple-cards';
import { useAnalytics } from '../../hooks/use-analytics';
import { FaqsAccordion } from '../../components/sections/category/categories/faqs-accordion/faqs-accordion';
import { UseSubCategoryViewContentReturn } from './hooks/use-sub-category-view-content';
import { DisclaimerList } from '../../components/common/disclaimer-list/disclaimer-list';
import { LanguageBar } from '../../components/sections/category/sub-categories/language-bar/language-bar';
import DisclosuresLinkSyndicationAware from '../../components/sections/disclosures-link/disclosures-link-syndication-aware';
import { useLocalizationBreadcrumbs } from '../../hooks/use-localization-breadcrumbs';
import { Category5050Carousel } from '../../components/sections/category/categories/category-5050-carousel/category-5050-carousel';
import { useSkinnyBannerContent } from '../../components/common/skinny-banner/hook/use-skinny-banner';
import SkinnyBanner from '../../components/common/skinny-banner/skinny-banner';
import { CategoryOnClickCtaInfo } from '../../services/shortcode-service/shortcode-service';
import { SubCategoryViewMasthead } from '../../components/sections/category/sub-categories/sub-category-view-masthead/sub-category-view-masthead';
import { PreImagineEscapeHatch } from '../../components/sections/pre-imagine-components/pre-imagine-escape-hatch/pre-imagine-escape-hatch';
import CategoryViewUtil from '../category-view/category-view-util';

interface Props {
    path: string;
    subCategoryViewFragment: SubCategoryViewFragment;
    useSubCategoryViewContent: (
        subCategoryViewFragment: SubCategoryViewFragment
    ) => UseSubCategoryViewContentReturn | undefined;

    breadcrumbs: any;
    subCategoryId: string;
}

export const SubCategoryView = (props: Props) => {
    const categoryId = props.path.split('/')[0];
    const currentVersion = CategoryViewUtil.currentVersion;
    const subCategoryId =
        props.path.length > categoryId.length
            ? props.path.substring(categoryId.length + 1, props.path.length)
            : undefined;

    useAnalytics('owner-subcategory', undefined, {
        categoryId: categoryId,
        subcategoryId: subCategoryId,
    });
    const [fireEvents] = useAnalytics();
    const subCategoryFaqPanelClick = async (panelTitle: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            categoryPath: props.path,
            panelTitle,
        };
        fireEvents('faq-onclick-event', undefined, {
            categoryOnClickCtaInfo,
        });
    };

    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();

    const { disclaimers, content } =
        props.useSubCategoryViewContent(props.subCategoryViewFragment) || {};

    const skinnyBannerContent = useSkinnyBannerContent(
        `category/${props.path}`,
        'skinny-banner',
        false
    );
    return (
        <>
            {content && (
                <div
                    className={`sub-category-view ${
                        !currentVersion ? 'preimagine' : ''
                    }`}
                >
                    {!props.subCategoryViewFragment.breadcrumbs
                        ?.hideBreadcrumbs && (
                        <Breadcrumbs
                            currentPage={useLocalizationBreadcrumbs(
                                props.subCategoryId
                            )}
                            type="common"
                            crumbSeparator={`${!currentVersion ? '/' : '>'}`}
                        >
                            <props.breadcrumbs />
                        </Breadcrumbs>
                    )}
                    {content.masthead && (
                        <>
                            {currentVersion ? (
                                <CategoryViewMasthead
                                    {...content.masthead}
                                    eventName={'category-onclick-event'}
                                    categoryPath={props.path}
                                />
                            ) : (
                                <SubCategoryViewMasthead
                                    {...content.masthead}
                                    eventName={'category-onclick-event'}
                                    categoryPath={props.path}
                                />
                            )}
                        </>
                    )}
                    {content.keyValueItemsBar && (
                        <LanguageBar
                            languages={content.keyValueItemsBar.keyValueItems}
                        />
                    )}
                    <div className="sub-category-content">
                        <div className="sub-category-component-wrapper">
                            {content.simpleCards && (
                                <SimpleCards
                                    cards={content.simpleCards.simpleCards}
                                    {...content.simpleCards}
                                    currentVersion={currentVersion}
                                    categoryPath={props.path}
                                    categoryType={'subcategory'}
                                />
                            )}
                            {skinnyBannerContent && (
                                <SkinnyBanner {...skinnyBannerContent} />
                            )}
                            <ArticleContainer
                                {...content}
                                categoryPath={props.path}
                                eventName={'category-onclick-event'}
                                currentVersion={currentVersion}
                            />
                        </div>
                    </div>

                    {content.fiftyFiftyCarousel && (
                        <Category5050Carousel
                            {...content.fiftyFiftyCarousel}
                            categoryPath={props.path}
                        />
                    )}
                    {content.faqs && (
                        <FaqsAccordion
                            {...content.faqs}
                            onPanelClickEvent={subCategoryFaqPanelClick}
                            handlePanelClick={subCategoryFaqPanelClick}
                            pageType={'Subcategory'}
                        />
                    )}
                    {disclaimers && (
                        <DisclaimerList disclaimers={disclaimers} />
                    )}
                    {searchContent && helpCardsContent && (
                        <>
                            {currentVersion ? (
                                <SearchBar
                                    searchContent={searchContent}
                                    helpCardsContent={helpCardsContent}
                                />
                            ) : (
                                <PreImagineEscapeHatch
                                    searchContent={searchContent}
                                    helpCardsContent={helpCardsContent}
                                />
                            )}
                        </>
                    )}
                    <DisclosuresLinkSyndicationAware />
                </div>
            )}
        </>
    );
};
