import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import { replacePlaceholderByValue } from '../../../utils/placeholder-util/replace-placeholder-by-value';
import { throttle } from '../../../utils/throttle-util/throttle';
import './maintenance-schedule-carousel.ford.scss';
import './maintenance-schedule-carousel.lincoln.scss';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import { Accordion } from '../../../common';
import getNumberFormatByRegion from '../../../utils/number-format-utill/number-format-mileage';
import ServerContext from '../../../../contexts/serverContext';
import selectedVin from '../../../utils/selectedVinUtil/getSelectedVin';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import { getIsBevVehicle } from '../../../utils/getVin-util/getVin-util';
import { useMaintenanceScheduleContent } from '../hooks/use-maintenance-schedule-content';
import CarouselToggleBody from './maintenance-schedule-carousel-body';

interface Props {
    carouselHeading: string;
    toggleText: string;
    toggleSubheadline: string;
    maintenanceResponse: any;
    isNARegion: boolean;
    supplementaryDescription: string;
    monthUnit: string;
    singleYearUnit: string;
    yearUnit: string;
    miLabelForSubHeadline: string;
    kmLabelForSubHeadline: string;
    vehicleStatus: any;
    uomDistance: number;
    openInNewtabArialabel: string;
    noMileageToggleText: string;
    noMileageToggleSubheadline: string;
}
interface CarouselToggleHeaderProps {
    activeSlide: number;
    maintenanceResponse: any[];
    leftChevronClicked: (e: React.KeyboardEvent) => void;
    getPreviousSlideDisabledClassName: () => string;
    goToPreviousSlide: () => void;
    renderSlider: () => React.ReactNode;
    rightChevronClicked: (e: React.KeyboardEvent) => void;
    getNextSlideDisabledClassName: () => string;
    goToNextSlide: () => void;
    contentRef: React.RefObject<HTMLDivElement>;
}

const CarouselToggleHeader: React.FC<CarouselToggleHeaderProps> = ({
    activeSlide,
    maintenanceResponse,
    leftChevronClicked,
    getPreviousSlideDisabledClassName,
    goToPreviousSlide,
    renderSlider,
    rightChevronClicked,
    getNextSlideDisabledClassName,
    goToNextSlide,
    contentRef,
}) => {
    return (
        <div className="carousel-header-wrapper">
            <div className="dash-content-slider" ref={contentRef}>
                <span
                    aria-label="Go to previous schedule"
                    role="button"
                    tabIndex={activeSlide === 0 ? -1 : 0}
                    onKeyDown={e => leftChevronClicked(e)}
                    className={`dash-slider-btn previous ${getPreviousSlideDisabledClassName()}`}
                    id="prevSlide"
                    onClick={goToPreviousSlide}
                >
                    <FdsChevron type={'unfilled'} direction={'left'} />
                </span>
                {renderSlider()}
                <span
                    aria-label="Go to next schedule"
                    role="button"
                    tabIndex={
                        activeSlide === maintenanceResponse.length - 1 ? -1 : 0
                    }
                    onKeyDown={e => rightChevronClicked(e)}
                    className={`dash-slider-btn next ${getNextSlideDisabledClassName()}`}
                    id="nextSlide"
                    onClick={goToNextSlide}
                >
                    <FdsChevron type={'unfilled'} direction={'right'} />
                </span>
            </div>
        </div>
    );
};

export const MaintenanceScheduleCarousel = (props: Props) => {
    const maintenanceScheduleContent = useMaintenanceScheduleContent();

    const contentRef = useRef<HTMLDivElement>(null);
    const [showLoading, setLoadingStatus] = useState<boolean>(false);
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [slideWidth, setSlideWidth] = useState<number>(() => 0);
    const [wrapperWidth, setWrapperWidth] = useState<number>(() => 0);
    const [isBev, setIsBev] = useState<boolean>(false);
    const mileageFromLandingPage = serverSideService.isClientSide()
        ? window.sessionStorage.getItem('maintenance-schedule-mileage')
        : false;
    const mounted = useRef<any>();

    const { detailsLabel } = maintenanceScheduleContent;

    const {
        maintenanceResponse,
        carouselHeading,
        toggleText,
        toggleSubheadline,
        isNARegion,
        supplementaryDescription,
        monthUnit,
        singleYearUnit,
        yearUnit,
        miLabelForSubHeadline,
        kmLabelForSubHeadline,
        vehicleStatus,
        uomDistance,
        openInNewtabArialabel,
        noMileageToggleText,
        noMileageToggleSubheadline,
    } = props;

    const serverContext = useContext(ServerContext);

    const getVisibleCountByDevice = () => {
        const TABLET_BREAKPOINT = 768;
        const DESKTOP_BREAKPOINT = 992;
        const XL_DESKTOP_BREAKPOINT = 1700;
        if (window.innerWidth <= TABLET_BREAKPOINT) {
            return 2;
        } else if (window.innerWidth <= DESKTOP_BREAKPOINT) {
            return 4;
        } else if (window.innerWidth <= XL_DESKTOP_BREAKPOINT) {
            return 6;
        }
        return 7;
    };

    const calculateSlideWidth = () => {
        const visibleSlideCount = getVisibleCountByDevice();

        setTimeout(() => {
            if (contentRef && contentRef.current) {
                const itemCount = maintenanceResponse.length;
                const containerWidth = contentRef.current?.offsetWidth;
                const slideWidth = containerWidth / visibleSlideCount;
                const updatedWrapperWidth = itemCount
                    ? slideWidth * itemCount
                    : wrapperWidth;
                setSlideWidth(slideWidth);
                setWrapperWidth(updatedWrapperWidth);
            }
        }, 100);
    };

    const onResize = throttle(calculateSlideWidth, 200);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        }
        wrapperWidth === 0 && calculateSlideWidth();
    });

    useEffect(() => {
        setStartingSlide();
        const selectedVinVal = selectedVin(serverContext);
        if (selectedVinVal) {
            const getIsBevValue = async () => {
                const isBev = await getIsBevVehicle(selectedVinVal);
                if (isBev) {
                    setIsBev(isBev);
                    goToSlide(0);
                } else {
                    setStartingSlide();
                }
            };
            getIsBevValue();
        }
    }, []);

    const updateActiveSlide = (index: number) => {
        setActiveSlide(index);
        setLoadingStatus(true);
        setTimeout(() => {
            setLoadingStatus(false);
        }, 300);
    };

    const goToSlide = (index: number) => {
        updateActiveSlide(index);
    };

    const isSameDistanceUnit = () => {
        const mileageUnit = maintenanceResponse[0].mileageUnit;
        return (
            (uomDistance === 1 && mileageUnit === 'mi') ||
            (uomDistance === 2 && mileageUnit === 'km')
        );
    };

    const calculateStartingSlide = (mileage: number) => {
        if (Array.isArray(maintenanceResponse) && maintenanceResponse[0]) {
            let selectedSlide = maintenanceResponse.length - 1;
            for (let i = 0; i < maintenanceResponse.length; i++) {
                if (+maintenanceResponse[i].mileage >= mileage) {
                    selectedSlide = i;
                    break;
                }
            }
            goToSlide(selectedSlide);
        }
    };

    const setStartingSlide = () => {
        if (mileageFromLandingPage) {
            calculateStartingSlide(+mileageFromLandingPage);
        } else if (
            vehicleStatus &&
            !vehicleStatus.error &&
            vehicleStatus.vehiclestatus &&
            vehicleStatus.vehiclestatus.odometer &&
            Array.isArray(maintenanceResponse) &&
            maintenanceResponse[0] &&
            isSameDistanceUnit()
        ) {
            calculateStartingSlide(vehicleStatus.vehiclestatus.odometer.value);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        if (mounted.current) {
            const dealerCTA = document.querySelector(
                '.supplementary-description a[href]'
            );
            dealerCTA &&
                dealerCTA.setAttribute(
                    'aria-label',
                    `${dealerCTA.innerHTML} ${openInNewtabArialabel || ''}`
                );
        }
    });

    const getLeftSlideCount = () => {
        const totalSlideCount = maintenanceResponse.length;
        const visibleCount = getVisibleCountByDevice();

        if (totalSlideCount - activeSlide <= visibleCount) {
            return totalSlideCount - visibleCount;
        }

        return activeSlide;
    };

    const getWrapperStyles = () => {
        const width = wrapperWidth;
        const leftSlideCount = getLeftSlideCount();
        const marginLeft = -leftSlideCount * slideWidth;

        return {
            marginLeft,
            width,
        };
    };

    const goToNextSlide = () => {
        const totalSlideCount = maintenanceResponse.length - 1;
        if (activeSlide !== totalSlideCount) {
            updateActiveSlide(activeSlide + 1);
            setTimeout(() => {
                const nextSlideFocus = document.getElementById('nextSlide');
                nextSlideFocus?.focus();
            }, 300);
        }
    };

    const goToPreviousSlide = () => {
        if (activeSlide !== 0) {
            updateActiveSlide(activeSlide - 1);
            setTimeout(() => {
                const prevSlideFocus = document.getElementById('prevSlide');
                prevSlideFocus?.focus();
            }, 300);
        }
    };

    const getActiveSlideClassName = (index: any) => {
        return activeSlide === index ? 'active' : '';
    };

    const getNextSlideDisabledClassName = () => {
        const totalSlideCount = maintenanceResponse.length - 1;
        return activeSlide === totalSlideCount ? 'disabled' : '';
    };

    const getPreviousSlideDisabledClassName = () => {
        return activeSlide === 0 ? 'disabled' : '';
    };

    const leftChevronClicked = (event: any) => {
        if (event.keyCode == 13) {
            goToPreviousSlide();
        }
    };

    const rightChevronClicked = (event: any) => {
        if (event.keyCode == 13) {
            goToNextSlide();
        }
    };

    const getAuthoredServiceIntervalUnit = (intervalUnitFromAPI: string) => {
        if (intervalUnitFromAPI === undefined) {
            return 'years';
        }
        if (monthUnit && intervalUnitFromAPI.toLowerCase() === 'months') {
            return monthUnit;
        }
        if (yearUnit && intervalUnitFromAPI.toLowerCase() === 'years') {
            return yearUnit;
        }
        if (singleYearUnit && intervalUnitFromAPI.toLowerCase() === 'year') {
            return singleYearUnit;
        }
        return intervalUnitFromAPI;
    };

    const keyDownHandler = (event: any, index: number) => {
        if (event.keyCode === 39 || event.keyCode === 37) {
            let nextIndex = 0;
            const currentElement = document.getElementById(
                `schedule-tab-${index}`
            );
            currentElement?.setAttribute('tabindex', '-1');
            if (event.keyCode === 39) {
                nextIndex = index + 1;
                if (index === maintenanceResponse.length - 1) {
                    nextIndex = 0;
                }
            } else if (event.keyCode === 37) {
                nextIndex = index - 1;
                if (index === 0) {
                    nextIndex = maintenanceResponse.length - 1;
                }
            }
            updateActiveSlide(nextIndex);
            const nextElement = document.getElementById(
                `schedule-tab-${nextIndex}`
            );
            nextElement?.setAttribute('tabindex', '0');
        }
    };

    const renderSliderListItem = (item: any, index: number) => {
        const data =
            (isBev || item.mileage === '0') && noMileageToggleText
                ? noMileageToggleText
                : toggleText;
        const placeHolderObject = {
            mileage: getNumberFormatByRegion(item.mileage),
            mileageUnit: item.mileageUnit,
            serviceTimeInterval: item.serviceTimeInterval,
            serviceTimeIntervalUnit: getAuthoredServiceIntervalUnit(
                item.serviceTimeIntervalUnit
            ),
        };

        if (slideWidth) {
            return (
                <button
                    key={index}
                    tabIndex={index === activeSlide ? 0 : -1}
                    role="tab"
                    aria-controls={`schedule-panel-${index}`}
                    id={`schedule-tab-${index}`}
                    aria-selected={index === activeSlide}
                    aria-hidden={index !== activeSlide}
                    onKeyDown={e => keyDownHandler(e, index)}
                    className={`dash-content-slider-list-item ${getActiveSlideClassName(
                        index
                    )}`}
                    onClick={() => goToSlide(index)}
                    style={{ width: slideWidth }}
                >
                    <i
                        className={
                            activeSlide === index
                                ? 'odometer-icon'
                                : 'odometer-dis-icon'
                        }
                    />
                    <p className="odometer-text">
                        {replacePlaceholderByValue(data, placeHolderObject)
                            .replaceAll(/^0\s+(mi|km)\s+(or)\s/g, '')
                            .replaceAll(/or 0 years/g, '')}
                    </p>
                </button>
            );
        }
        return '';
    };

    const renderNADescription = (currentMaintenanceData: any) => {
        const { operations } = currentMaintenanceData;
        const accordionData: any =
            Array.isArray(operations) && operations.length
                ? formatAccordionData(operations, 'NA')
                : [];
        const operationsData = accordionData[0]?.accordionDescription;
        // remove the duplicate description from the operations
        const operation = operationsData?.filter(
            (operationObj: any, index: number) => {
                return operationsData.indexOf(operationObj) === index;
            }
        );
        return (
            <section className="toggle-description">
                <ul className="description-wrapper">
                    {operation?.length
                        ? operation.map((description: any) => (
                              <li
                                  className="maintenance-list"
                                  key={description}
                              >
                                  <span>{description}</span>
                              </li>
                          ))
                        : null}
                </ul>
                {serverContext.webview !== 'true' && (
                    <div
                        className="supplementary-description"
                        dangerouslySetInnerHTML={{
                            __html: supplementaryDescription,
                        }}
                    />
                )}
            </section>
        );
    };

    const renderAccordionHeader = (maintenanceData: any) => (
        <div className="maintenance-accordion-header">
            <h2>{maintenanceData.accordionHeading}</h2>
        </div>
    );

    const renderAccordionBody = (maintenanceData: any) => (
        <div className="maintenance-accordion-body">
            <ul>
                {maintenanceData.accordionDescription.map(
                    (description: string) => (
                        <li key={description}>{description}</li>
                    )
                )}
            </ul>
        </div>
    );

    const formatAccordionData = (operations: any, region: string): any => {
        const formattedData: {
            accordionHeading: string;
            accordionDescription: string[];
        }[] = [];
        type operation = { heading: string };
        const uniqueHeadings: string[] = Array.from(
            new Set(operations.map((operation: operation) => operation.heading))
        );
        uniqueHeadings.forEach(heading => {
            const descriptions: string[] = [];
            const specificHeadingData = operations.filter(
                (operation: operation) => operation.heading === heading
            );
            specificHeadingData.forEach(
                (headingData: { description: string; subHeading: string }) => {
                    const separator =
                        headingData.subHeading && headingData.description
                            ? ' -- '
                            : ' ';
                    const description = `${headingData.subHeading ||
                        ''}${separator}${headingData.description}`;
                    if (region === 'NA') {
                        descriptions.push(headingData.description);
                    } else {
                        descriptions.push(description);
                    }
                }
            );
            formattedData.push({
                accordionHeading: heading,
                accordionDescription: descriptions,
            });
        });
        return formattedData;
    };

    const renderEUDescription = (currentMaintenanceData: any) => {
        const { operations } = currentMaintenanceData;
        const accordionData =
            Array.isArray(operations) && operations.length
                ? formatAccordionData(operations, 'EU')
                : [];
        return (
            <section className="toggle-description eu-region">
                {accordionData.length
                    ? accordionData.map((operation: any, key: number) => (
                          <Accordion
                              key={operation.accordionHeading}
                              header={renderAccordionHeader(operation)}
                              panel={renderAccordionBody(operation)}
                              className={'maintenance-accordion'}
                              index={key.toString()}
                              expandMultiplePanels={true}
                              chevronText={detailsLabel}
                          />
                      ))
                    : null}
            </section>
        );
    };

    const renderSlider = () => {
        return (
            <div className="dash-content-slider-container">
                <div
                    className="dash-content-slider-wrapper"
                    style={getWrapperStyles()}
                    role="tablist"
                >
                    {maintenanceResponse.length
                        ? maintenanceResponse.map((item: any, index: number) =>
                              renderSliderListItem(item, index)
                          )
                        : null}
                </div>
            </div>
        );
    };

    const getAuthoredMileageUnits = (mileageUnit: string) => {
        if (mileageUnit?.toLowerCase() === 'km') {
            return kmLabelForSubHeadline ? kmLabelForSubHeadline : mileageUnit;
        } else if (mileageUnit?.toLowerCase() === 'mi') {
            return miLabelForSubHeadline ? miLabelForSubHeadline : mileageUnit;
        }
        return mileageUnit;
    };
    const currentMaintenanceToggleData = maintenanceResponse[activeSlide];
    const {
        mileageUnit,
        serviceTimeInterval,
        serviceTimeIntervalUnit,
    } = currentMaintenanceToggleData;
    const mileage = getNumberFormatByRegion(
        currentMaintenanceToggleData.mileage
    );
    const togglemileageUnit = getAuthoredMileageUnits(mileageUnit);
    const toggleserviceTimeIntervalUnit = getAuthoredServiceIntervalUnit(
        serviceTimeIntervalUnit
    );
    const togglerenderDescription = isNARegion
        ? renderNADescription(currentMaintenanceToggleData)
        : renderEUDescription(currentMaintenanceToggleData);

    const handlers = useSwipeable({
        onSwipedLeft: goToNextSlide,
        onSwipedRight: goToNextSlide,
    });

    return (
        <div className={'maintenance-carousel'}>
            <h2 className={'carousel-heading'}>{carouselHeading}</h2>
            {maintenanceResponse.length ? (
                <>
                    <CarouselToggleHeader
                        activeSlide={activeSlide}
                        maintenanceResponse={maintenanceResponse}
                        leftChevronClicked={leftChevronClicked}
                        getPreviousSlideDisabledClassName={
                            getPreviousSlideDisabledClassName
                        }
                        goToPreviousSlide={goToPreviousSlide}
                        renderSlider={renderSlider}
                        rightChevronClicked={rightChevronClicked}
                        getNextSlideDisabledClassName={
                            getNextSlideDisabledClassName
                        }
                        goToNextSlide={goToNextSlide}
                        contentRef={contentRef}
                    />
                    {!showLoading ? (
                        <div {...handlers}>
                            <CarouselToggleBody
                                activeSlide={activeSlide}
                                isBev={isBev}
                                noMileageToggleSubheadline={
                                    noMileageToggleSubheadline
                                }
                                toggleSubheadline={toggleSubheadline}
                                toggleMileage={mileage}
                                toggleMileageUnit={togglemileageUnit}
                                toggleServiceTimeInterval={serviceTimeInterval}
                                toggleserviceTimeIntervalUnit={
                                    toggleserviceTimeIntervalUnit
                                }
                                toggleDescription={() =>
                                    togglerenderDescription
                                }
                            />
                        </div>
                    ) : (
                        <ActivityIndicator />
                    )}
                </>
            ) : null}
        </div>
    );
};
