import React from 'react';
import { PrimaryButton } from '../../common';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../hooks/use-analytics';
import { Heading } from '../../common/heading/heading';
import { useHistory } from 'react-router-dom';
import './card.scss';
import { replacePlaceholderByValue } from '../../utils/placeholder-util/replace-placeholder-by-value';
import localStorageWrapper from '../../utils/local-storage-wrapper/localStorageWrapper';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

interface Props {
    data: FMCardProps;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
    headerTitle?: string;
    analyticsClickEventName?: string;
    openInNewTabAriaLabel?: string;
}

export const Card = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const history = useHistory();
    let ctaHrefUrl = props.data.ctaHref;
    const isRelativeCta = () => {
        return (
            props.data.ctaHref &&
            !props.data.ctaHref.startsWith('http://') &&
            !props.data.ctaHref.startsWith('https://')
        );
    };
    const vinPlaceholderRegex = /##(\w+)##/g;
    const isVin: RegExpExecArray | null = vinPlaceholderRegex.exec(
        props.data.ctaHref || ''
    );
    const userVin = localStorageWrapper.getItem('USER_VIN');
    const ctaTarget =
        props.data.ctaTarget === undefined ? '_blank' : props.data.ctaTarget;
    const target = isRelativeCta() ? '' : ctaTarget;

    if (isVin && isVin[1].toLowerCase() === 'vin' && userVin) {
        ctaHrefUrl = replacePlaceholderByValue(props.data.ctaHref || '', {
            [isVin[1]]: userVin,
        });
    }

    const getAriaLabel = () => {
        if (
            props.openInNewTabAriaLabel &&
            !isRelativeCta() &&
            (props.data.ctaTarget === undefined ||
                props.data.ctaTarget === '_blank')
        ) {
            return `${props.data.ctaAriaLabel} ${props.openInNewTabAriaLabel ||
                ''}`;
        } else if (
            !isRelativeCta() &&
            (props.data.ctaTarget === undefined ||
                props.data.ctaTarget === '_blank' ||
                props.data.ctaTarget === '_self')
        ) {
            return props.data.ctaAriaLabel;
        }
        return props.data.ctaLabel || props.data.title;
    };
    return (
        <div className="card" data-testid="card-id">
            {props.data.hoverTextHeading || props.data.hoverTextQuestions ? (
                <div className="card-header-container">
                    <img
                        id="card-header-id"
                        className="card-header"
                        src={`${process.env.REACT_APP_AEM_BASE_URL}${props.data.imageSrc}`}
                        alt={`${props.data.imageAlt}`}
                        aria-hidden={
                            props.data.cardImageAriaHidden ? true : false
                        }
                    />
                    <div className="card-header-hover" id="card-header-id-1">
                        <div className="card-header-hover-text">
                            <div className="card-header-text-head">
                                {props.data.hoverTextHeading}
                            </div>

                            <div
                                className="card-header-text-body"
                                dangerouslySetInnerHTML={{
                                    __html: props.data.hoverTextQuestions || '',
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <img
                    className="card-header"
                    src={`${process.env.REACT_APP_AEM_BASE_URL}${props.data.imageSrc}`}
                    alt={`${props.data.imageAlt}`}
                    aria-hidden={props.data.cardImageAriaHidden ? true : false}
                />
            )}
            <div className="card-body">
                {props.data.title && (
                    <Heading
                        type={props?.headerTitle ? 'h3' : 'h2'}
                        className="card-title"
                    >
                        {props.data.titleWithDisclaimer || props.data.title}
                    </Heading>
                )}
                <div className="card-description">
                    {props.data.descriptionWithDisclaimer ||
                        props.data.description}
                </div>
                <div className="button-position">
                    <PrimaryButton
                        fill="fill"
                        color="dark"
                        chevron={true}
                        href={ctaHrefUrl}
                        onClick={() => {
                            props.analyticsClickEventName &&
                                fireEvents(
                                    props.analyticsClickEventName,
                                    undefined,
                                    {
                                        smashHomeClick: {
                                            cardName: props.data.title,
                                        },
                                    },
                                    false
                                );
                            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                ctaHrefUrl || '',
                                fireEvents
                            );
                            if (isRelativeCta()) {
                                history.push(ctaHrefUrl || '');
                            }
                        }}
                        onKeyDown={props.handleKeyDown}
                        target={target}
                        ariaLabel={getAriaLabel()}
                        preventCtaDefault={isRelativeCta() || undefined}
                    >
                        {props.data.ctaLabel}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
