import { useContent } from '../../../../hooks/use-server-content';

export interface RecallsSearchTermsListProps {
    termList: string[];
}

export const useRecallsSearchTermsContent = (): RecallsSearchTermsListProps | null => {
    const [content, getValueByName] = useContent(
        'emplifi',
        'recalls-search-terms'
    );
    let contentFragment: any = {};

    content?.elements?.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (!contentFragment.termList) {
            contentFragment = null;
        }
    }

    return contentFragment as RecallsSearchTermsListProps;
};
