import React, { useEffect, useState, useRef } from 'react';
import './autocomplete-lincoln.scss';
import GoogleMapService from '../../../services/osb-service/google-map-service/google-map-service';
import errorWarning from '../../../assets/error-warning.svg';
import { FdsChevron } from '../chevron/fds-chevron';
import { useOSBStep } from '../../../hooks/owners-osb';
import { DealerDetails } from '../../../models/osb-model/osb-dealer-info';
import { DEALER_STEP_KEYS } from '../../sections/owners-osb/osb-constant';

interface DataType {
    label: string;
    value: string;
}
interface Props {
    name?: string;
    id?: string;
    text?: string;
    data?: DataType[];
    placeHolderText?: string;
    setCode?: (code: string, searchType?: string) => void;
    setInputText?: (input: string) => void;
    message?: string;
    ref?: HTMLInputElement;
    google?: boolean;
    isDropDownOpen?: boolean;
    searchStringLength?: number;
    error?: string;
    clearErrorMessage?: () => void;
    hasPostcodeSearch?: boolean;
    resetOnPostcodeRemoval?: (postcode: string) => void;
    setErrorCode?: (errorCode: string) => void;
    fetchAutoCompleteInput?: (input: string) => void;
    fetchSuggestions?: (input: DataType[]) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    errorClass?: string;
    searchType?: string;
    dealerList?: DealerDetails[] | [];
    showChevron?: boolean;
    geoCountryCode?: string;
    hideLabel?: boolean;
}

const INVALID_INPUT_ERROR_CODE = 'ERR001';
const AutoComplete = (props: Props) => {
    const [suggestions, setSuggestions] = useState<DataType[]>([]);
    const { osbStep } = useOSBStep();
    const [text, setText] = useState<string>(props.text ? props.text : '');
    const [showLabel, setShowLabel] = useState<boolean>(false);
    const textInput = React.useRef<HTMLInputElement>(props.ref || null);
    const firstUpdate = useRef(true);
    const { showChevron = true, hideLabel = false } = props;
    const searchStrLength =
        props.searchStringLength === undefined ? 2 : props.searchStringLength;

    const setGoogleSuggestions = function(
        predictions: google.maps.places.AutocompletePrediction[] | null
    ) {
        const suggestions: DataType[] = [];
        predictions &&
            predictions.map(v => {
                suggestions.push({
                    label: props.hasPostcodeSearch
                        ? v.structured_formatting.main_text
                        : v.description,
                    value: props.hasPostcodeSearch
                        ? v.structured_formatting.main_text
                        : v.description,
                });
            });
        setSuggestions(suggestions);
    };
    function suggestionSeleced(value: any) {
        props.setCode && props.setCode(value.value, props.searchType);
        setText(value.label);
        setSuggestions([]);
        props.setInputText && props.setInputText(value.label);
        props.fetchAutoCompleteInput &&
            props.fetchAutoCompleteInput(value.label);
    }
    function handleClick() {
        if (textInput.current) {
            const value = textInput.current.value;
            let item = [];
            if (props.google === true) {
                item = suggestions;
            } else {
                item = props.data ? props.data : [];
            }
            let data: DataType[] = [];
            if (value.length > 0) {
                data = [...item]
                    .sort()
                    .filter(v =>
                        v.label.toUpperCase().includes(value.toUpperCase())
                    );
            }
            if (data.length > 0) {
                suggestionSeleced(data[0]);
                props.setErrorCode?.('');
            } else {
                props.setErrorCode?.(INVALID_INPUT_ERROR_CODE);
            }
        }
    }
    function renderSuggestions() {
        if (suggestions && suggestions.length === 0) {
            return null;
        }
        return (
            <ul className="ulList">
                <span>{props.message}</span>
                {suggestions &&
                    suggestions.map((item: DataType) => (
                        <li
                            onClick={() => suggestionSeleced(item)}
                            key={item.value}
                            data-testid={`Select${item.value}`}
                        >
                            {item.label}
                        </li>
                    ))}
            </ul>
        );
    }

    useEffect(() => {
        if (props.isDropDownOpen && props.google !== true) {
            setSuggestions(props.data || []);
            renderSuggestions();
        }
    }, [props.data]);

    useEffect(() => {
        setText(props.text ? props.text : '');
    }, [props.text]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setText('');
        setSuggestions([]);
    }, [props.searchType]);

    useEffect(() => {
        props.fetchSuggestions && props.fetchSuggestions(suggestions);
    }, [suggestions]);

    function escapeString(unsafe: string) {
        return unsafe
            .replace(/\\/g, '\\\\')
            .replace(/\[/g, '\\[')
            .replace(/\]/g, '\\]')
            .replace(/\(/g, '\\(')
            .replace(/\)/g, '\\)');
    }
    function autocompleteMatch(dealerName: string, searchString: string) {
        searchString = searchString.toLowerCase().replace(/\s+/g, ' ');
        dealerName = dealerName.toLowerCase().replace(/\s+/g, ' ');
        return dealerName.indexOf(searchString);
    }

    const onTextChanged = (e: { target: { value: any } }) => {
        const value = e.target.value;
        props.resetOnPostcodeRemoval?.(value);
        props.clearErrorMessage?.();
        props.fetchAutoCompleteInput && props.fetchAutoCompleteInput(value);
        if (value && value?.length > 0) {
            setShowLabel(true);
        } else {
            setShowLabel(false);
        }
        let suggestions: DataType[] = [];
        if (
            props.searchType === DEALER_STEP_KEYS.DEALER_NAME &&
            value.length > searchStrLength
        ) {
            const dealerMatches = [];
            let dealerMatch;
            let dealerName;
            const dealerList = props.dealerList ? props.dealerList : [];
            for (const element of dealerList) {
                dealerName = element.dealerName;

                if (autocompleteMatch(dealerName, value) !== -1) {
                    dealerMatch = {
                        label: element.dealerName,
                        value: element.dealerCode,
                    };

                    if (autocompleteMatch(dealerName, value) === 0) {
                        dealerMatches.splice(0, 0, dealerMatch);
                    } else {
                        dealerMatches.push(dealerMatch);
                    }
                }
            }
            setSuggestions(dealerMatches);
            setText(value);
        } else {
            if (props.google === true && value.length > searchStrLength) {
                GoogleMapService.getPlacePredictions(
                    value,
                    setGoogleSuggestions,
                    props.geoCountryCode || osbStep.geoCountryCode,
                    props.hasPostcodeSearch ? props.hasPostcodeSearch : false
                );
            } else {
                const item = props.data ? props.data : [];
                if (value.length > searchStrLength) {
                    const regex = new RegExp(`${escapeString(value)}`, 'i');
                    suggestions = [...item]
                        .sort()
                        .filter(v => regex.test(v.label));
                }
            }
            setSuggestions(suggestions);
            setText(value);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleClick();
        } else {
            props.setErrorCode?.('');
        }
    };
    const handleOnFocus = () => {
        props.onFocus?.();
    };
    const handleOnBlur = () => {
        props.onBlur?.();
    };

    return (
        <div className="auto-complete-container">
            <div className="auto-complete-label-container">
                {!hideLabel ||
                    (props.error && (
                        <div className="auto-complete-label">
                            {(showLabel || props.text) && props.placeHolderText}
                        </div>
                    ))}
                {props.error && (
                    <img className="osb-error-icon" src={errorWarning} />
                )}
            </div>
            <div
                data-testid="AutoCompletedSection"
                className={`autoCompleteText ${
                    props.errorClass ? props.errorClass : ''
                } `}
            >
                <form
                    autoComplete="off"
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <label htmlFor="search-input">
                        <input
                            type="text"
                            name={props.name}
                            id={props.id}
                            ref={textInput}
                            value={text}
                            onChange={onTextChanged}
                            onKeyDown={handleKeyDown}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            placeholder={props.placeHolderText}
                            data-testid="EnterYourLocation"
                        />
                        <button
                            type="button"
                            aria-label="choose you location"
                            onClick={handleClick}
                        >
                            {showChevron && (
                                <FdsChevron type="unfilled" direction="right" />
                            )}
                        </button>
                        {!props.error && <div>{renderSuggestions()}</div>}
                    </label>
                </form>
            </div>
        </div>
    );
};
export default AutoComplete;
