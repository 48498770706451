import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { LightDeliveryServiceStep } from '../../models/osb-model/osb-light-delivery-service-step';
import {
    StoreState,
    StorePayload,
    LightDeliveryServiceStepState,
} from '../../models/osb-model/osb-store-details';
import { useCallback } from 'react';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { useOSBStep } from './use-osb-step';

export const getInitialLightDeliveryServiceState = (): LightDeliveryServiceStep => {
    return {
        selectedServices: [],
    };
};

//Configure and initialize service step state in global store
const configureLightDeliveryServiceStepStore = () => {
    const actions = {
        //Action to set the manual service step details to global store
        SET_LIGHT_DELIVERY_SERVICE_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as LightDeliveryServiceStepState)
                    .lightDeliveryServiceStep,
                ...(payload as LightDeliveryServiceStep),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_DELIVERY_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { lightDeliveryServiceStep: newState };
        },
    };
    initializeStore(actions, {
        lightDeliveryServiceStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_DELIVERY_STEP_KEY
            ) || getInitialLightDeliveryServiceState(),
    });
};

configureLightDeliveryServiceStepStore();

// custom hook for manual service step to handle the actions and updating payload in global store
export const useLightDeliveryOptionServiceStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalLightDeliveryServiceStep = (
        lightDeliveryServiceStep: LightDeliveryServiceStep,
        shouldReload = true
    ) => {
        dispatch(
            'SET_LIGHT_DELIVERY_SERVICE_STEP',
            lightDeliveryServiceStep,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetLightDeliveryServiceStep = (shouldReload = true) => {
        dispatch(
            'SET_LIGHT_DELIVERY_SERVICE_STEP',
            getInitialLightDeliveryServiceState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);
    const getObject = (key: string, data: any[]) => {
        if (data) {
            const item = data.filter(part => {
                return part.name === key;
            });
            if (
                item &&
                item.length > 0 &&
                Object.prototype.hasOwnProperty.call(item[0], 'value')
            ) {
                return item[0].value;
            }
        }
        return '';
    };
    return {
        osbLightDeliveryServiceStep: (globalState as LightDeliveryServiceStepState)
            .lightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep: setGlobalLightDeliveryServiceStep,
        resetLightDeliveryServiceStep: resetLightDeliveryServiceStep,
        callContentService: callContentService,
        getObject: getObject,
    };
};
