import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { ServiceStep } from '../../models/osb-model/osb-service-step';
import {
    StoreState,
    ServiceStepState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import { useCallback } from 'react';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import {
    DealerServices,
    DealerServicesArray,
    ServiceData,
} from '../../models/osb-model/osb-dealerservice-info';
import { AcceptedFileInfo } from '../../components/sections/owners-osb/common/osb-file-uploader/osb-file-uploader';
import { UserVehicleList } from '../../models/osb-model/osb-vehicle-lookup';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';
import { useOSBStep } from './use-osb-step';

export const getInitialServiceState = (): ServiceStep => {
    return {
        voucherCode: '',
        voucherDesc: '',
        totalPrice: '00.00',
        totalDiscountPrice: '0',
        comment: '',
        recallServiceDescription: [],
        vhaServiceDescription: [],
        oilLifeServiceReminderDescription: '',
        selectedServices: [],
        dealerServiceInfo: {
            dealerServices: {
                oldServices: [],
                additionalServices: [],
                serviceVouchers: [],
                mainServices: [],
            },
        },
        totalPriceForAnalytics: '0.00',
        uploadedImage: [],
        userVehicles: [],
        isMobileServiceSelected: false,
        isGASkipped: false,
        otherServiceDesc: '',
        isOtherServiceSelected: false,
    };
};
//Configure and initialize service step state in global store
const configureServiceStepStore = () => {
    const actions = {
        //Action to set the manual service step details to global store
        SET_SERVICE_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as ServiceStepState).serviceStep,
                ...(payload as ServiceStep),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { serviceStep: newState };
        },

        SET_SERVICE_STEP_PAYLOAD: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as ServiceStepState).serviceStep,
                ...(payload as {
                    [key: string]:
                        | string
                        | ServiceData[]
                        | boolean
                        | AcceptedFileInfo[]
                        | DealerServices;
                }),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { serviceStep: newState };
        },
    };
    initializeStore(actions, {
        serviceStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_SERVICE_STEP_KEY
            ) || getInitialServiceState(),
    });
};

configureServiceStepStore();

// custom hook for manual service step to handle the actions and updating payload in global store
export const useServiceStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalServiceStep = (
        serviceStep: ServiceStep,
        shouldReload = true
    ) => {
        dispatch(
            'SET_SERVICE_STEP',
            serviceStep,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const setGlobalServiceStepPayload = (
        payload: {
            [key: string]:
                | string
                | string[]
                | boolean
                | ServiceData[]
                | AcceptedFileInfo[]
                | DealerServices
                | DealerServicesArray
                | UserVehicleList
                | {}
                | [];
        },
        shouldReload = true
    ) => {
        dispatch(
            'SET_SERVICE_STEP_PAYLOAD',
            payload,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetServiceStep = (shouldReload = true) => {
        dispatch(
            'SET_SERVICE_STEP',
            getInitialServiceState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);
    const getObject = (key: string, data: any[]) => {
        if (data) {
            const item = data.filter(part => {
                return part.name === key;
            });
            if (
                item &&
                item.length > 0 &&
                Object.prototype.hasOwnProperty.call(item[0], 'value')
            ) {
                return item[0].value;
            }
        }
        return '';
    };
    return {
        osbServiceStep: (globalState as ServiceStepState).serviceStep,
        setOSBServiceStep: setGlobalServiceStep,
        setOSBServiceStepPayload: setGlobalServiceStepPayload,
        resetServiceStep: resetServiceStep,
        callContentService: callContentService,
        getObject: getObject,
    };
};
