import { useContent } from '../../../../hooks/use-server-content';

export interface WarrantyInformationContentProps {
    title: string;
    warrantyHeader: string;
    warrantyQuickGuideHeading: string;
    warrantyQuickGuideReferences: any;
    warrantyQuickGuideSubtext: string;
    relatedTopics: string;
    vinInputHeader: string;
    vinInputLabel: string;
    vinInputPlaceholder: string;
    vinInputDividerText: string;
    chooseVehicleGarageHeadLine: string;
    vehicleSelectorYearDropdownLabel: string;
    vehicleSelectorVinSubtitle: string;
    vehicleSelectorVinInput: string;
    vehicleSelectorModelDropdownLabel: string;
    vehicleSelectorDisclaimerLabel: string;
    vehicleSelectorModelSubtitle: string;
    vehicleSelectorDividerText: string;
    loginSwitchLabel: string;
    loggedInSwitchLabel: string;
    vehicleSwitchLabel: string;
    vinTooltipHeader: string;
    vinTooltipAriaLabel: string;
    vinTooltipContent: string;
    seeYourWarrantyCtaLabel: string;
    loginHeading: string;
    loginDescription: string;
    signinButtonText: string;
    invalidVinValidationMessage: string;
    invalidVinOptionalValidationMessage: string;
    vehicleVinTooltipHeader: string;
    vehicleVinTooltipContent: string;
    registerLinkCtaLabel: string;
    warrantyNotFoundErrorMessage: string;
    registrationLookup: boolean;
    regNumberValidationMessage: string;
}
export interface WarrantyQuickGuideContentProps {
    elements: any;
}
export const useWarrantyInformationContent = (): WarrantyInformationContentProps => {
    const [content, getValueByName] = useContent(
        'common',
        'warranty-information'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as WarrantyInformationContentProps;
};
export const useWarrantyQuickGuideContent = (
    warrantyQuickGuideReferences: any
): WarrantyQuickGuideContentProps => {
    let warrantyGuideObj = '';
    if (warrantyQuickGuideReferences) {
        warrantyQuickGuideReferences.map((refURL: any) => {
            warrantyGuideObj +=
                refURL.split('/')[refURL.split('/').length - 1] + '|';
        });
    }
    const [content] = useContent('common', warrantyGuideObj);
    const contentFragment: any = [];
    content?.elements.forEach(element => {
        contentFragment.push(element);
    });
    return contentFragment;
};
