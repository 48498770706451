import React, { ReactNode } from 'react';
import { Replacer } from 'react-element-replace';

export interface StringReplacerRule {
    replace: string;
    replaceWith: ReactNode;
}

interface Props {
    children: ReactNode;
    rules: StringReplacerRule[];
}

export const StringReplacer = (props: Props) => {
    const { rules, children } = props;
    const splitty = (
        item: string,
        replace: StringReplacerRule[],
        currentReplaceIndex = 0
    ): ReactNode => {
        const currentReplace = replace[currentReplaceIndex];
        if (currentReplace) {
            const existsAt = item.indexOf(currentReplace.replace);
            if (existsAt >= 0) {
                const pre = item.substring(0, existsAt);
                const post = item.substring(
                    existsAt + currentReplace.replace.length
                );
                return (
                    <>
                        {splitty(pre, replace, currentReplaceIndex)}
                        {currentReplace.replaceWith}
                        {splitty(post, replace, currentReplaceIndex)}
                    </>
                );
            } else {
                return splitty(item, replace, currentReplaceIndex + 1);
            }
        } else {
            return item;
        }
    };
    return (
        <Replacer
            matchLiteral={item =>
                typeof item === 'string' &&
                rules.filter(r => item.indexOf(r.replace) >= 0).length > 0
            }
            replace={item => {
                return splitty(item, rules);
            }}
        >
            {children}
        </Replacer>
    );
};
