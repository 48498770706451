export interface ServiceHistoryResponse {
    dealerServices: ServiceRecord[];
    userServices: ServiceRecord[];
    ownerFlag: boolean;
    serviceUpToDate: boolean;
}
export interface ServiceRecord {
    serviceMileage: any;
    createdOn: string;
    currentOwnershipPeriod: string;
    serviceId: string;
    vehicleMileage: number;
    editable: boolean;
    vehicleMileageUnit: string;
    serviceDate: string;
    serviceAmount: number;
    serviceDescription: string;
    servicesPerformed: ServicesPerformed;
    serviceLocation: ServiceLocation;
    serviceOperations: ServiceOperations;
    provider?: ServiceProviders[];
    date?: Date;
    serviceCategory?: string;
    notes?: string;
    inspections?: Array<string>;
    nextService?: NextService;
}
export interface NextService {
    desc: string;
    milesDesc: string;
    kmsDesc: string;
    dueDate: string;
    mileageInMiles: number;
    mileageInKilometres: number;
}
export interface ReactCategory {
    code: string;
    desc: string;
}
export interface ServicesPerformed {
    otherRelatedItems: OtherItems[];
    bodyAndPaintCheckDesc: string;
    scheduled: SevicePerformedSCheduled;
    operations: ServicePerformedOperations[];
    inspections: ServicePerformedInspections[];
}
export interface SevicePerformedSCheduled {
    kmsDesc: string;
    milesDesc: string;
}
export interface OtherItems {
    id: string;
    desc: string;
}
export interface ServicePerformedOperations {
    id: string;
    desc: string;
}
export interface ServicePerformedInspections {
    id: string;
    desc: string;
}
export interface ServiceLocation {
    serviceProvider: ServiceProvider;
    dealer: Dealer;
    selfService: boolean;
    otherServiceLocation: string;
}
export interface Dealer {
    code: string;
    address: string;
    name: string;
    authorised: boolean;
    dealerCode?: string;
    dealerName?: string;
    dealerPACode?: string;
    countryCode: string;
}
export interface ServiceProvider {
    serviceProviderCode: string;
    serviceProviderName: string;
}
export interface ServiceProviders {
    serviceProvider?: ServiceProvider[];
}
export interface ServiceOperations {
    reactCategory: ReactCategory[];
}

export interface AddVehicleServiceHistoryResponse {
    userGUID: string;
    vin: string;
    userService: ServiceRecord;
}

export interface DeleteVehicleServiceHistoryResponse {
    successful: boolean;
}

export interface ServiceCodes {
    code: string;
    value: string;
}

export interface ServiceProviderType {
    serviceProvider: ServiceCodes;
}

export class ServiceHistory {
    providerType?: string;
    legalLanguageId?: string;
    servicesPerformed: string[];
    emissionInspection?: boolean;
    safetyInspection?: boolean;
    vin?: string;
    mileage?: number | string;
    date?: string;
    cost?: string;
    notes?: string;
    provider?: ServiceProviderType;
    id?: string;

    constructor() {
        this.servicesPerformed = [];
    }

    setProviderType(providerType: string) {
        this.providerType = providerType;
    }

    setLegalLanguageId(legalLanguageId: string) {
        this.legalLanguageId = legalLanguageId;
    }

    setEmissionInspection(emissionInspection: boolean) {
        this.emissionInspection = emissionInspection;
    }

    setSafetyInspection(safetyInspection: boolean) {
        this.safetyInspection = safetyInspection;
    }

    setServicePerformed(servicePerformed: [string]) {
        this.servicesPerformed = servicePerformed;
    }

    setVin(vin: string) {
        this.vin = vin;
    }

    setMileage(mileage: number | string) {
        this.mileage = mileage;
    }

    setDate(date: string) {
        this.date = date;
    }

    setCost(cost: string) {
        this.cost = cost;
    }

    setNotes(notes: string) {
        this.notes = notes;
    }

    setProvider(provider: ServiceProviderType) {
        this.provider = {
            serviceProvider: {
                code:
                    provider.serviceProvider.code &&
                    provider.serviceProvider.code.length > 0
                        ? provider.serviceProvider.code
                        : 'unknown',
                value:
                    provider.serviceProvider.value &&
                    provider.serviceProvider.value.length > 0
                        ? provider.serviceProvider.value
                        : 'unknown',
            },
        };
    }

    setId(id: string) {
        this.id = id;
    }
}
