import React from 'react';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    href: string | undefined;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    target?: string;
    preventdefault?: string;
    role?: string;
}

const Link = (props: Props & React.HTMLProps<HTMLAnchorElement>) => {
    const [fireEvents] = useAnalytics();

    return (
        <a
            {...props}
            aria-label={props['aria-label']}
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent) => {
                if (props.onClick) {
                    if (props.preventdefault && props.preventdefault === 'true')
                        e.preventDefault();
                    props.onClick(e);
                }
                if (props.href) {
                    NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                        props.href,
                        fireEvents
                    );
                }
            }}
            role={props.role}
            {...(props.className ? { className: props.className } : '')}
        >
            {props.children}
        </a>
    );
};

export default Link;
