export default function useBrightcoveScriptBuilder(account: string) {
    const brandname: string = (window as any).SERVER_CONTEXT.brand;

    const fordPlayerSourceMap = new Map<string, string>();
    fordPlayerSourceMap.set('4095259350001', 'SyeO7cSQJf');
    fordPlayerSourceMap.set('2379864788001', 'B1eFuwPXkG');
    fordPlayerSourceMap.set('2270581146001', 'rkZxOrQ1M');
    fordPlayerSourceMap.set('4095259375001', 'S1zpVBX1G');
    fordPlayerSourceMap.set('4082198820001', 'S1NnUBm1z');
    fordPlayerSourceMap.set('4095259376001', 'rJPzjSmyG');
    fordPlayerSourceMap.set('4095259380001', 'rJ3XVuQJG');
    fordPlayerSourceMap.set('4095259377001', 'ByHDNPQyz');
    fordPlayerSourceMap.set('4095259378001', 'r1mnTrQJf');

    const lincolnPlayerSourceMap = new Map<string, string>();
    lincolnPlayerSourceMap.set('4095259350001', 'PGSW3FAYU');
    lincolnPlayerSourceMap.set('2379864788001', 'H1T3fN51G');
    lincolnPlayerSourceMap.set('4103911217001', 'SJWKuuXJM');

    const fordPlayerId = fordPlayerSourceMap.get(account);
    const lincolnPlayerId = lincolnPlayerSourceMap.get(account);

    return {
        src: `https://players.brightcove.net/${account}/${
            brandname === 'ford' ? fordPlayerId : lincolnPlayerId
        }_default/index.min.js`,
        playerSource: brandname === 'ford' ? fordPlayerId : lincolnPlayerId,
    };
}
