import React, {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import './eu-help-router.scss';
import helpicon from '../../../assets/help-icon.svg';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { HelpRouterFragment } from '../../../models/experiencefragments/help-router';
import { useCookies } from 'react-cookie';

import { KEYS, TAB_CHECK_INTERVAL, MESSAGE_TYPES } from './emplifi-wrapper';
import EuHelpRouterPopularLinks from './eu-help-router-popular-links';
import HelpRouterInnerLinks from './eu-help-router-inner-links';

export const PERSISTENCE_MESSAGE_TYPES = {
    NEW_MESSAGE: 'new-message',
    FEEDBACK_RECEIVED: 'feedback-received',
};
import {
    FILTER_CHAT_HISTORY,
    SET_CONVERSATION_STARTED,
    UPDATE_CHAT_HISTORY,
} from './actionTypes';
import {
    chatbotReducer as reducer,
    chatbotInitState as initState,
} from './reducers';
import ChatbotInput from './chatbot-input/chatbot-input';
import getUuid from '../../utils/uuid-util/uuid';
import { KEYBOARD_KEYS } from '../../../constants';
import tabRegistry from './tabRegistry';
import {
    EUHelpRouterCookie,
    EuHelpRouterFeedbackReceiverMessageType,
    EuHelpRouterHandleMessage,
    EuHelpRouterIsOpen,
    EuHelpRouterProcessFeedbackMessage,
    handleAnalogClickOpen,
} from './utils/eu-help-router-handle-message';
import siteMapService from '../../../support/services/site-map-service/site-map-service';
import ServerContext from '../../../contexts/serverContext';
import { SUPPORT_HUB_REGEX } from '../../../support/support-constants';
import xss from 'xss';
interface Props {
    className?: string;
    onAnalogClick?: () => void;
    onVAClick?: () => void;
    message?: any;
}
const EuHelpRouter = (props: Props) => {
    const [state, dispatch] = useReducer(reducer, initState);
    const [isVisible, setIsVisible] = useState(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [cookies, setCookie] = useCookies(['sticky-popup-displayed']);
    const [helpRouterContent] = useExperienceContent<HelpRouterFragment>(
        'common',
        'help-router',
        'helprouter'
    );
    const chatHistoryRef = useRef(state.chatHistory);
    const [tabId] = useState(tabRegistry.generateId());
    const serverContext = useContext(ServerContext);
    const { currentLanguageRegionCode } = serverContext;
    const isMessageTypeNewMessage = (message: { type: string }) =>
        message?.type === PERSISTENCE_MESSAGE_TYPES.NEW_MESSAGE;

    const persistentMessageType = (message: {
        key?: any;
        newValue?: any;
        payload?: any;
    }) => {
        dispatch({
            type: UPDATE_CHAT_HISTORY,
            payload: { ...message.payload, id: getUuid() },
        });
    };

    const handleMessage = (event: { newValue: string }) => {
        const message = JSON.parse(event.newValue);
        EuHelpRouterHandleMessage({
            isMessageTypeNewMessage,
            message,
            persistentMessageType,
        });
    };

    const processFeedbackReceivedMessage = async (message: {
        type: string;
    }) => {
        EuHelpRouterProcessFeedbackMessage({ message });
    };

    const feedbackReceiverMessageType = async (event: { newValue: string }) => {
        const message = JSON.parse(event.newValue);
        const payload = await processFeedbackReceivedMessage(message);
        EuHelpRouterFeedbackReceiverMessageType({ payload, dispatch });
    };
    useEffect(() => {
        const lastMessageKey = (event: { key: string }) =>
            event.key !== KEYS.LAST_MESSAGE;

        const handlePayload = () => {
            dispatch({
                type: FILTER_CHAT_HISTORY,
                payload: shouldBePersisted,
            });
            dispatch({ type: SET_CONVERSATION_STARTED });
        };
        const handleTabSynchronization = async (event: {
            key: any;
            newValue: any;
        }) => {
            lastMessageKey(event);
            handleMessage(event);
            await feedbackReceiverMessageType(event);
            handlePayload();
        };

        tabRegistry.removeOldTabs();

        window.addEventListener('storage', handleTabSynchronization);
        tabRegistry.saveTab(tabId);

        const chatbotCheckTimer = setInterval(
            () => tabRegistry.update(tabId),
            TAB_CHECK_INTERVAL
        );

        const cleanup = () => {
            clearInterval(chatbotCheckTimer);
            tabRegistry.removeTab(tabId);
        };

        window.addEventListener('beforeunload', cleanup);

        return cleanup;
    }, [tabId]);

    useEffect(() => {
        chatHistoryRef.current = state.chatHistory;
    }, [state.chatHistory]);

    const shouldBePersisted = (
        msg: { type: number },
        i: number,
        allMessages: string | any[]
    ) =>
        msg.type !== MESSAGE_TYPES.QUICK_REPLIES ||
        i === allMessages.length - 1;

    const onInitialSubmit = useCallback((message: any) => {
        const sanitizedSearchTerm = message.replace(
            SUPPORT_HUB_REGEX.INVALID_SEARCH_CHARS,
            ''
        );

        const slugifiedSearchTerm = xss(sanitizedSearchTerm, {
            whiteList: {},
            stripIgnoreTag: true,
            stripIgnoreTagBody: ['script'],
        }).replace(SUPPORT_HUB_REGEX.SPACES, '-');

        window.open(
            serverContext.root
                .substring(0, serverContext.root.length - 1)
                .concat(
                    siteMapService.getPathFromRouterConfigs(
                        currentLanguageRegionCode,
                        serverContext.brand,
                        'AstuteSearch',
                        [slugifiedSearchTerm]
                    )
                ),
            '_blank'
        );
    }, []);

    const handleAnalogClick = (e: any) => {
        e.stopPropagation();
        const closedBackground = document.getElementsByClassName(
            'background'
        )[0];
        const closedRouter = document.getElementsByClassName(
            'help-router-container'
        )[0];

        const { onAnalogClick } = props;

        handleAnalogClickOpen({
            isOpen,
            onAnalogClick,
            closedRouter,
            setIsOpen,
            closedBackground,
        });
    };

    const onEscape = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            handleAnalogClick(event);
        }
    };
    useEffect(() => {
        EuHelpRouterIsOpen({ isOpen, onEscape });
    }, [isOpen]);
    const handleClick = () => {
        setIsVisible(false);
    };
    useEffect(() => {
        setIsVisible(false);
        setTimeout(() => {
            setIsVisible(true);
        }, 3000);
        setTimeout(() => {
            setIsVisible(false);
        }, 7000);
        EUHelpRouterCookie({ cookies, setCookie, setIsVisible });
    }, []);
    return helpRouterContent && !helpRouterContent.hide ? (
        <div className={'help-router'}>
            {isVisible &&
                cookies['sticky-popup-displayed'] != 1 &&
                helpRouterContent?.helpAnalog?.popUpMessageText && (
                    <div
                        className="help-router-starting-pop-up"
                        aria-label={
                            helpRouterContent?.helpAnalog?.popUpMessageAriaLabel
                        }
                        aria-live={'polite'}
                    >
                        {helpRouterContent?.helpAnalog?.popUpMessageText}
                        <button
                            className="closeButton"
                            onClick={() => {
                                handleClick();
                            }}
                        >
                            <img
                                className="close-icon"
                                src={'icons/f-close.svg'}
                                alt=""
                            />
                        </button>
                    </div>
                )}
            <button
                className={'help-router-analog-cta'}
                onClick={handleAnalogClick}
                aria-label={helpRouterContent?.helpAnalog?.ariaLabel}
            >
                <img src={helpicon} alt="" className="help-icon"></img>
                <p>{helpRouterContent?.helpAnalog?.helpText}</p>
            </button>
            <div aria-hidden={!isOpen} className={'help-router-container'}>
                <div className={'help-router-title'}>
                    <p>{helpRouterContent?.mainMenu?.howToHelp}</p>
                    <button
                        className={'help-router-cta-close'}
                        onClick={handleAnalogClick}
                    ></button>
                </div>
                <div className={'help-router-container-inner'}>
                    <>
                        <p className={'chatbot-header'}>
                            {
                                helpRouterContent?.mainMenu
                                    ?.virtualAssistantHeader
                            }
                        </p>
                        <ChatbotInput
                            onSubmit={onInitialSubmit}
                            dispatch={dispatch}
                            placeholder={
                                helpRouterContent?.mainMenu?.placeholderText
                            }
                            conversationStarted={state.conversationStarted}
                        />
                    </>
                    {!state.chatVisible && (
                        <EuHelpRouterPopularLinks
                            helpRouterContent={helpRouterContent}
                        />
                    )}
                </div>
                <HelpRouterInnerLinks
                    chatVisible={state.chatVisible}
                    helpRouterContent={helpRouterContent}
                />
            </div>

            <div className={'background'} onClick={handleAnalogClick} />
        </div>
    ) : (
        <></>
    );
};
export default EuHelpRouter;
