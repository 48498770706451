import {
    CouponSeoAttributes,
    subcategoryTitle,
} from '../../constants/route-constants';
import { SUPPORT_HUB_REGEX } from '../../../../../support/support-constants';

const PAGE_TITLE_MAX_LENGTH = 100;
const META_DESC_MAX_LENGTH = 250;
const ELLIPSIS_LENGTH = 3;
let title: any;
let value: string;
const flag = true;

function stripHtmlTags(html: string) {
    return html
        .replace(SUPPORT_HUB_REGEX.ALL_MARKUP_TAGS, '')
        .replace(SUPPORT_HUB_REGEX.FIND_ALL_TABS_NEW_LINE, '')
        .replace(SUPPORT_HUB_REGEX.NON_BREAKING_SPACE, ' ')
        .replace('•', '')
        .replace(/•/g, ',')
        .replace(/\*/g, '')
        .replace(/\.$/g, '')
        .trim();
}

function truncateToLastWord(text: string, limit: number) {
    return text.substring(0, (text + ' ').lastIndexOf(' ', limit));
}

function capitalizeEachWord(text: string) {
    return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function getConfiguredMetaContent(context: any, key: string): string {
    try {
        const seoConfig =
            context.content[context.brand][context.currentRegionCode][
                context.currentLanguageRegionCode
            ]['seo/coupon']['coupon-details']['seoconfig'];
        return seoConfig[key];
    } catch (e) {
        return '';
    }
}

function preparePageTitle(coupon: CouponSeoAttributes) {
    const { offer, categoryName, pageTitle } = coupon;
    const catName = subcategoryTitle(categoryName, title, value, flag);
    const sanitizedCategory = catName[0];
    const sanitizedOffer = capitalizeEachWord(stripHtmlTags(offer));
    [title, value] = subcategoryTitle(categoryName, title, value, flag);
    const staticText = pageTitle
        ? pageTitle.match(/\[page-title\|(.*?)\]/gm)
        : '';
    if (staticText && staticText.length > 0) {
        const editedText = staticText[0];
        const titleText = editedText.substring(1, editedText.length - 1);
        coupon.staticTitlePlaceholder = titleText;

        const staticTitleText1 = staticText[0].split('|')[1];
        coupon.staticTitleText = staticTitleText1.slice(0, -1);
        const pipeText = ' | ';

        const truncatedOffer =
            sanitizedOffer.length > 100
                ? `${truncateToLastWord(
                      sanitizedOffer,
                      PAGE_TITLE_MAX_LENGTH - ELLIPSIS_LENGTH
                  )}...`
                : sanitizedOffer;

        const categoryName =
            sanitizedCategory.trim() !== ''
                ? `${pipeText}${sanitizedCategory}`
                : '';
        let staticTitleText = '';

        if (
            sanitizedOffer.length +
                categoryName.length +
                coupon.staticTitleText.length <=
            PAGE_TITLE_MAX_LENGTH
        ) {
            staticTitleText = `${pipeText}${coupon.staticTitleText}`;
        }

        return {
            ...coupon,
            offer: truncatedOffer,
            categoryName,
            staticTitleText,
        };
    } else {
        console.log('No matching page-title shortcode found.');
    }

    return coupon;
}

function prepareMetaDescription(coupon: CouponSeoAttributes) {
    const { subCopy, offer, disclaimer, expiryDate, metaDescription } = coupon;
    const sanitizedSubCopy = stripHtmlTags(subCopy || offer);
    const sanitizedDisclaimer = stripHtmlTags(disclaimer);
    const META_DESC_DEFAULT_TEXT_LENGTH = metaDescription
        ? metaDescription.replace(/(\[\w*?\])/g, '').length
        : 0;
    const META_DESC_AVAILABLE_TEXT_LENGTH =
        META_DESC_MAX_LENGTH -
        META_DESC_DEFAULT_TEXT_LENGTH -
        expiryDate.length;

    switch (true) {
        case META_DESC_AVAILABLE_TEXT_LENGTH <= 0:
            return {
                ...coupon,
                subCopy: '',
                disclaimer: '',
            };

        case sanitizedSubCopy.length + META_DESC_DEFAULT_TEXT_LENGTH >
            META_DESC_MAX_LENGTH:
            return {
                ...coupon,
                subCopy: truncateToLastWord(
                    sanitizedSubCopy,
                    META_DESC_AVAILABLE_TEXT_LENGTH
                ),
                disclaimer: '',
            };

        case sanitizedDisclaimer.length +
            sanitizedSubCopy.length +
            META_DESC_DEFAULT_TEXT_LENGTH >
            META_DESC_MAX_LENGTH:
            return {
                ...coupon,
                subCopy: sanitizedSubCopy,
                disclaimer: truncateToLastWord(
                    sanitizedDisclaimer,
                    META_DESC_AVAILABLE_TEXT_LENGTH - sanitizedSubCopy.length
                ),
            };

        default:
            return {
                ...coupon,
                sanitizedSubCopy,
                sanitizedDisclaimer,
            };
    }
}

function prepareSeoAttributes(coupon: CouponSeoAttributes) {
    let seoCouponAttributes: CouponSeoAttributes = preparePageTitle(coupon);
    seoCouponAttributes = prepareMetaDescription(seoCouponAttributes);
    return seoCouponAttributes;
}

export { getConfiguredMetaContent, prepareSeoAttributes };
