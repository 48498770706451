import React from 'react';
import { PrimaryButton } from '../../../../../common';
import './light-osb-home-retrieve-booking-lincoln.scss';
import { OsbPathBookingRetrieveStep } from '../../../osb-controller';
import { buildNavigationUrl } from '../../../osb-utils';
import { useHistory } from 'react-router-dom';
import { OSBStep } from '../../../../../../models/osb-model/osb-step';
import { triggerBookServiceGlobalCTAOnClickAnalytics } from '../../../analytics/osb-analytics';
import { LIGHT_JOURNEY_ANALYTICS } from '../../../osb-constant';
import { useAnalytics } from '../../../../../../hooks/use-analytics';

interface Props {
    osbStep: OSBStep;
}

export const LightOsbHomeRetrieveBooking = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const history = useHistory();
    return (
        <div className="osb-home-retrieve-booking-container">
            <div className="osb-home-retrieve-booking-header">
                {props.osbStep.osbHomeRetrieveBookingHeader}
            </div>
            <div className="osb-home-retrieve-booking-copy-text">
                {props.osbStep.osbHomeRetrieveBookingCopyText}
            </div>
            <div className="osb-home-retrieve-booking-button-container">
                <PrimaryButton
                    data-testid="RetrieveBookingCTA"
                    role="link"
                    color={'dark'}
                    fill={'fill'}
                    chevron={false}
                    onClick={() => {
                        triggerBookServiceGlobalCTAOnClickAnalytics(
                            LIGHT_JOURNEY_ANALYTICS.RETRIEVE_BOOKING_BTN_CTA_NAME,
                            fireEvents
                        );
                        history.push(
                            buildNavigationUrl(OsbPathBookingRetrieveStep(), '')
                        );
                    }}
                >
                    {props.osbStep.osbHomeRetrieveBookingButtonLabel}
                </PrimaryButton>
            </div>
        </div>
    );
};
