import { replacePlaceholderByValue } from '../../../utils/placeholder-util/replace-placeholder-by-value';

interface CarouselToggleBodyProps {
    activeSlide: number;
    isBev: boolean;
    noMileageToggleSubheadline: string;
    toggleSubheadline: string;
    toggleMileage: string;
    toggleMileageUnit: string;
    toggleServiceTimeInterval: string;
    toggleserviceTimeIntervalUnit: string;
    toggleDescription: () => React.ReactNode;
}

const CarouselToggleBody: React.FC<CarouselToggleBodyProps> = ({
    activeSlide,
    isBev,
    noMileageToggleSubheadline,
    toggleSubheadline,
    toggleMileage,
    toggleMileageUnit,
    toggleServiceTimeInterval,
    toggleserviceTimeIntervalUnit,
    toggleDescription,
}) => {
    const data =
        (isBev || toggleMileage === '0') && noMileageToggleSubheadline
            ? noMileageToggleSubheadline
            : toggleSubheadline;
    const placeHolderObject = {
        mileage: toggleMileage,
        mileageUnit: toggleMileageUnit,
        serviceTimeInterval: toggleServiceTimeInterval,
        serviceTimeIntervalUnit: toggleserviceTimeIntervalUnit,
    };
    return (
        <div
            className="carousel-body"
            role="tabpanel"
            tabIndex={0}
            id={`schedule-panel-${activeSlide}`}
            aria-labelledby={`schedule-tab-${activeSlide}`}
        >
            <h3 className="toggle-subheading">
                {replacePlaceholderByValue(data, placeHolderObject)
                    .toLowerCase()
                    .replaceAll(/\s0\s+(mi|km)\s+(or)/g, '')
                    .replaceAll(/or 0 years/g, '')}
            </h3>
            {toggleDescription()}
        </div>
    );
};
export default CarouselToggleBody;
