import { VHACard } from '../../vha-card/vha-card';
import slowTireLeakIcon from '../../../../../../assets/slow-tire-leak.svg';
import { useEffect, useState } from 'react';
import { getOilLifeDataFromAPI } from '../oillife/oilife';
import { getVin } from '../../../../../utils/getVin-util/getVin-util';
import {
    getSlowTireLeakState,
    getTireLeakDescription,
} from './slow-tire-leak-utils';
import { TireLeak } from '../../../../../../models/service-maintenance-models/vehicle-health-alerts';
import { findByAlias } from '../../../../../../routes';
import { useHistory } from 'react-router-dom';

interface Props {
    tireLeakContent: TireLeak;
    handleVehicleStatus: (alertType: any) => void;
}

export const SlowTireLeak = (props: Props) => {
    const history = useHistory();
    const selectedVin = getVin();
    const [tireLeakApiData, setTireLeakApiData] = useState<any>(null);
    useEffect(() => {
        getOilLifeDataFromAPI(selectedVin)
            .then(prognosticsData => {
                if (prognosticsData?.tireLeakData) {
                    setTireLeakApiData(prognosticsData.tireLeakData);
                    props.handleVehicleStatus(
                        getSlowTireLeakState(prognosticsData.tireLeakData)
                    );
                }
            })
            .catch(error => {
                console.error(error.message);
            });
    }, []);
    const handleSlowTireLeakCardClick = () => {
        const slowTireLeakDetailsUrl = findByAlias('SlowTireLeakDetailsView');
        history.push(slowTireLeakDetailsUrl);
    };
    return (
        <>
            {tireLeakApiData && tireLeakApiData.featureType === 'TSL' && (
                <VHACard
                    icon={slowTireLeakIcon}
                    title={props.tireLeakContent.tireLeakAccordionLabel}
                    description={
                        getTireLeakDescription(
                            tireLeakApiData,
                            props.tireLeakContent
                        ) || ''
                    }
                    cardState={getSlowTireLeakState(tireLeakApiData)}
                    handleVHACardClick={handleSlowTireLeakCardClick}
                />
            )}
        </>
    );
};
