import React from 'react';
import { FdsChevron } from '../chevron/fds-chevron';
import './accordion.ford.scss';
import './accordian.lincoln.scss';
import { useAnalytics } from '../../../hooks/use-analytics';
import { KEYBOARD_KEYS } from '../../../constants';

interface Props {
    header: React.ReactNode;
    panel: React.ReactNode;
    index: string;
    className: string;
    borderBottom?: boolean;
    expandMultiplePanels?: boolean;
    chevronText?: string;
    expandPanel?: boolean;
    expandAnalytics?: Function;
    accordionType?: string;
    accordionToggleEvent?: string;
    cta?: string;
    category?: string;
    accordionName?: string;
    useHTag?: boolean;
    origin?: string;
    handleAccordionToggle?: (isExpanded: boolean) => void;
    fdsChevronType?: boolean;
}

const Accordion = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const updateAccordionState = (
        element: Element,
        expanded: boolean,
        areaExpanded: string,
        removeIcon: string,
        addIcon: string
    ) => {
        props.handleAccordionToggle?.(expanded);
        element.querySelector('.fds-chevron')?.classList.remove(removeIcon);
        element.querySelector('.fds-chevron')?.classList.add(addIcon);
        const panelEle = element.querySelector('.panel') as HTMLElement;
        if (expanded) {
            panelEle?.classList.add('expanded');
            if (props.expandMultiplePanels) {
                element
                    .querySelector('.accordion-title')
                    ?.classList.add('header-color');
            }
            setTimeout(() => {
                element
                    .querySelector('.fds-chevron .fds-arrow')
                    ?.setAttribute('aria-expanded', 'true');
            }, 200);
        } else {
            panelEle?.classList.remove('expanded');
            if (props.expandMultiplePanels) {
                element
                    .querySelector('.accordion-title')
                    ?.classList.remove('header-color');
            }
            setTimeout(() => {
                element
                    .querySelector('.fds-chevron .fds-arrow')
                    ?.setAttribute('aria-expanded', 'false');
            }, 200);
        }
    };

    const toggleActive = (index: string) => {
        if (props.origin === 'dyf-gvt-faq' && props.header) {
            fireEvents('dyf-gvt-faq-accordion-onclick-event', undefined, {
                question: props.header.toString().toLowerCase(),
            });
        } else if (props.origin === 'sync-maps-faq' && props.header) {
            const selfHelpFaq = props.header?.toString() || 'no question';
            fireEvents('sync-software-faq', undefined, { selfHelpFaq }, false);
        } else {
            fireEvents(
                props.accordionToggleEvent
                    ? props.accordionToggleEvent
                    : 'notifications-section-onclick-event',
                undefined,
                {
                    notificationCategory: props.category,
                    notificationCtaName: props.cta,
                }
            );
        }
        const elementId = '#accordion-' + props.className + '-' + index;
        const activeEle = document.querySelector(elementId);
        if (!activeEle) {
            return;
        }
        if (activeEle.querySelector('.panel')?.classList.contains('expanded')) {
            updateAccordionState(activeEle, false, 'false', 'up', 'down');
        } else if (props.expandMultiplePanels === true) {
            updateAccordionState(activeEle, true, 'true', 'down', 'up');
        } else {
            if (props.expandAnalytics && props.accordionType)
                props.expandAnalytics(props.accordionType);
            document.querySelectorAll('.accordion-section')?.forEach(value => {
                updateAccordionState(value, false, 'false', 'up', 'down');
            });
            updateAccordionState(activeEle, true, 'true', 'down', 'up');
        }
    };

    const keyBoardEnter = (event: any, index: string) => {
        if (
            event.key === KEYBOARD_KEYS.ENTER ||
            event.key === KEYBOARD_KEYS.SPACE
        ) {
            toggleActive(index);
            event.key === KEYBOARD_KEYS.SPACE &&
                (document.body.style.overflow = 'hidden');
        }
    };

    const keyUp = (event: any) => {
        event.key === KEYBOARD_KEYS.SPACE &&
            (document.body.style.overflow = '');
    };

    const accordionPanelName = () => {
        if (props.accordionName) {
            return `${props.accordionName}-${props.className}-${props.index} `;
        }
        return `accordion-panels-${props.className}-${props.index}`;
    };

    return (
        <>
            <div
                className={`accordion-section ${props.className}`}
                id={`accordion-${props.className}-${props.index}`}
            >
                <div
                    className={`accordion-title ${
                        props.borderBottom ? 'border-bottom' : ''
                    }`}
                    onClick={() => toggleActive(props.index)}
                    onKeyDown={e => keyBoardEnter(e, props.index)}
                    onKeyUp={e => keyUp(e)}
                >
                    {props.useHTag && props.header ? (
                        <h3
                            className="accordion-title__header accordion-title__heading"
                            id={`accordion-span-${props.className}-${props.index}`}
                        >
                            {props.header}
                        </h3>
                    ) : (
                        <span
                            className="accordion-title__header accordion-title__heading"
                            id={`accordion-span-${props.className}-${props.index}`}
                        >
                            {props.header}
                        </span>
                    )}
                    {props.chevronText && (
                        <span>
                            <FdsChevron
                                direction={props.expandPanel ? 'up' : 'down'}
                                type={
                                    props.fdsChevronType ? 'filled' : 'unfilled'
                                }
                                chevronText={props.chevronText}
                                focusClicked="focusTab"
                                ariaLabelledBy={
                                    props.className && props.index
                                        ? `accordion-span-${props.className}-${props.index}`
                                        : ''
                                }
                                enableAriaExpandedAttribute={true}
                            />
                        </span>
                    )}
                </div>
                <div
                    id={accordionPanelName()}
                    className={props.expandPanel ? 'panel expanded' : 'panel'}
                    aria-hidden="false"
                >
                    {props.panel}
                </div>
                <div className="accordion-divider" />
            </div>
        </>
    );
};
export default Accordion;
