export interface ContainerFragment {
    ':items': any;
    ':itemsOrder': string[];
    ':type': string;
}

export interface OilLife {
    enableOilLifeAlerts: boolean;
    oilLifeAccordionLabel: string;
    iolmLabel: string;
    oilLifeLabel: string;
    oilLifeRemainingLabel: string;
    estimatedDuedateLabel: string;
    estimatedDistanceRemainingLabel: string;
}

export interface Recall {
    enableRecallAlerts: boolean;
    recallAccordionLabel: string;
    noRecallText: string;
    recallText: string;
    vehicleHealthRecallDescription: string;
}

export interface ServiceReminder {
    serviceRemindersAccordionLabel: string;
    enableServiceRemindersAlerts: boolean;
    serviceRemindersText: string;
    noServiceRemindersText: string;
}

export interface SyncUpdates {
    enableSyncUpdatesAlerts: boolean;
    syncUpdatesAccordionLabel: string;
    syncUpdatesText: string;
    noSyncUpdatesText: string;
}

export interface Powertrain {
    powerTrainAccordionLabel: string;
    enablePowerTrainAlerts: boolean;
    powerTrainText: string;
    noPowerTrainText: string;
}

export interface BrakesSuspension {
    brakesAndSuspensionsAccordionLabel: string;
    enableBrakesAndSuspensionsAlerts: boolean;
    brakesAndSuspensionsText: string;
    noBrakesAndSuspensionsText: string;
}

export interface FluidsAndFilters {
    fluidsAndFiltersAccordionLabel: string;
    enableFluidsAndFiltersAlerts: boolean;
    fluidsAndFiltersText: string;
    noFluidsAndFiltersText: string;
}

export interface RestraintsDriverAssistance {
    restraintsAndDriverAssistanceLabel: string;
    enableRestraintsAndDriverAssistanceAlerts: boolean;
    restraintsAndDriverAssistanceText: string;
    noRestraintsAndDriverAssistanceText: string;
}

export interface TireLeak {
    tireLeakAccordionLabel: string;
    enableTireLeakAlerts: boolean;
    tireLeakSuccessDescription: string;
    tireLeakWarningDescription: string;
    tireLeakAlertDescription: string;
}

export interface VehicleHealth {
    syncUpdates: SyncUpdates;
    vehicleHealthTitle: string;
    vehicleHealthDescription: string;
    lastUpdatedLabel: string;
    vehicleHealthCTALabel: string;
    vehicleHealthCTALink: string;
    backButtonCTALabel: string;
    backButtonOverrideURL: string;
    downloadButtonCTALabel: string;
    oilLife: OilLife;
    recall: Recall;
    serviceReminders: ServiceReminder;
    powerTrain: Powertrain;
    brakesAndSuspensions: BrakesSuspension;
    fluidsAndFilters: FluidsAndFilters;
    restraintsAndDriverAssistance: RestraintsDriverAssistance;
    tireLeak: TireLeak;
    singularLabel: string;
    pluralLabel: string;
    goodHealthIconAltText: string;
    warningHealthIconAltText: string;
    kmLabel: string;
    kmsOverrun: string;
    milesLabel: string;
    milesOverrun: string;
    is12HourClock: boolean;
}

export enum CardState {
    NORMAL = 'NORMAL',
    ALERT = 'ALERT',
    WARNING = 'WARNING',
}
export interface ScheduleReportContentProps {
    scheduleReportHeading: string;
    scheduleReportSubheading: string;
    scheduleReportContentOne: string;
    scheduleReportContentTwo: string;
    scheduleReportEmailLabel: string;
    toggleLabel: string;
    toggleOnLabel: string;
    toggleOffLabel: string;
    frequencySemiAnnually: string;
    frequencyAnnually: string;
    frequencyMonthly: string;
    frequencyQuarterly: string;
    frequencyLabel: string;
    emailAdditionalInfoHeader: string;
    emailAdditionalInfoContent: string;
    saveButtonLabel: string;
    saveSuccessfulMessage: string;
    saveErrorMessage: string;
}
