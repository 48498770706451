import {
    AdditionalInfo,
    DealerServices,
    DealerServicesArray,
    ServiceInfo,
    ServiceVouchersInfo,
} from '../../../../models/osb-model/osb-dealerservice-info';
import {
    DealerServicesResponse,
    ServiceDetail,
    ServicePriceDetail,
} from '../../../../models/osb-model/osb-service-step';
import {
    MEDIA_TYPE,
    MEDIA_VIEWER_TYPE,
    SERVICE_DELIVERY_TYPE,
    SERVICE_GROUP_TYPE,
} from '../osb-constant';

/* Extract service code out of service id */
const extractServiceCode = (serviceId: string) => {
    const splitArray = serviceId.split(':');
    return splitArray && splitArray.length > 1 ? Number(splitArray[1]) : 0;
};

/* Extract service price out of price collection*/
const extractServicePriceDetail = (servicePrices: ServicePriceDetail[]) => {
    const servicePriceDetail = servicePrices?.find(
        p =>
            p.priceType.toLowerCase() ===
                SERVICE_DELIVERY_TYPE.WORKSHOP.toLowerCase() ||
            SERVICE_DELIVERY_TYPE.MOBILESERVICE.toLowerCase()
    );
    return {
        price: servicePriceDetail?.price.basePrice || 0,
        priceAfterDiscount: servicePriceDetail?.price.priceAfterDiscount || 0,
    };
};

const getServiceAdditionalInfo = (service: ServiceDetail): AdditionalInfo => {
    const serviceMedia = service.media?.find(
        m =>
            m.mediaType.toLowerCase() === MEDIA_TYPE.VIDEO.toLowerCase() &&
            m.viewerType.toLowerCase() ===
                MEDIA_VIEWER_TYPE.BRIGHTCOVE.toLowerCase()
    );
    return {
        offer: service.offer,
        subType: service.subType,
        serviceType: service.serviceType,
        sortOrder: service.sortOrder?.toString() || '0',
        brightCoveAccountId: serviceMedia?.payload.brightCoveAccountId,
        brightCoveVideoId: serviceMedia?.payload.brightCoveVideoId,
        brightCovePlayerId: serviceMedia?.payload.brightCovePlayerId,
        brightCoveVideoName: serviceMedia?.payload.brightCoveVideoName,
        preSelect: service.preSelect,
        priceDisclaimerText: service.disclaimer?.priceDisclaimerText || '',
        priceDisclaimerSymbol: service.disclaimer?.priceDisclaimerSymbol || '',
        serviceDisclaimer: service.disclaimer?.serviceDisclaimer || '',
    };
};

const transformService = (service: ServiceDetail): ServiceInfo => {
    const servicePriceInfo = extractServicePriceDetail(service.prices);
    return {
        id: service.id,
        serviceId: extractServiceCode(service.id),
        name: service.name,
        price: servicePriceInfo.price,
        priceAfterDiscount: servicePriceInfo.priceAfterDiscount,
        additionalInfo: getServiceAdditionalInfo(service),
        description: service.description,
        isChecked: service.selected,
        subType: service.subType,
        offer: service.offer,
        type: service.type,
        convenienceOptionTypes: service.convenienceOptionTypes,
        intervalServiceNamePrefix:
            service.intervalServiceNamePrefix || service.name,
        intervalServiceNameSuffix: service.intervalServiceNameSuffix || '',
    };
};
export const transformServiceResponse = (
    serviceAPIResponse: DealerServicesResponse
): DealerServicesArray => {
    const oldServices: ServiceInfo[] = [];
    const additionalServices: ServiceInfo[] = [];
    const mainServices: ServiceInfo[] = [];
    const serviceVoucherDetails: ServiceVouchersInfo[] = [];

    for (const service of serviceAPIResponse.services) {
        const transformedService = transformService(service);
        if (service.type.toUpperCase() === SERVICE_GROUP_TYPE.MAIN) {
            mainServices.push(transformedService);
        } else if (
            service.type.toUpperCase() === SERVICE_GROUP_TYPE.ADDITIONAL
        ) {
            additionalServices.push(transformedService);
        } else {
            oldServices.push(transformedService);
        }
    }
    // TODO: check with Tristan whether this information is no longer available in V5
    // for (const campaign of serviceAPIResponse.campaigns) {
    //     serviceVoucherDetails.push({
    //         description: campaign.voucherCodeDescription,
    //         amount: campaign.voucherAmount,
    //         code: campaign.voucherCode,
    //         percentage: campaign.voucherPercentage,
    //     });
    // }

    const dealerServices: DealerServices = {
        oldServices: oldServices,
        additionalServices: additionalServices,
        mainServices: mainServices,
        serviceVouchers: serviceVoucherDetails,
        campaignStates: serviceAPIResponse?.campaignStates,
    };

    return { dealerServices: dealerServices };
};
