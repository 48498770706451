import React, { useEffect, useState } from 'react';
import alertBlueIcon from '../../../../../../assets/alert-blue-icon.svg';
import { SyncTile } from '../../../../../../services/sync-service/sync-map-service';
import { getVin } from '../../../../../utils/getVin-util/getVin-util';
import { VHACard } from '../../vha-card/vha-card';
import {
    getSyncUpdateCardState,
    getSyncUpdateDataFromAPI,
    getSyncUpdateDescription,
} from './vha-sync-update';

interface Props {
    syncUpdateContent: any;
    handleVehicleStatus: (alertType: any) => void;
}

const SyncAndUpdates = (props: Props) => {
    const { syncUpdateContent } = props;
    const [syncUpdateApiData, setsyncUpdateApiData] = useState<SyncTile>();
    const userSelectedVin = getVin();

    useEffect(() => {
        getSyncUpdateDataFromAPI(userSelectedVin).then(response => {
            if (response?.syncTile) {
                setsyncUpdateApiData(response.syncTile);
                props.handleVehicleStatus(
                    getSyncUpdateCardState(
                        response.syncTile?.syncUpdateAvailable
                    )
                );
            } else {
                props.handleVehicleStatus(getSyncUpdateCardState(false));
            }
        });
    }, [userSelectedVin]);

    return (
        <>
            {syncUpdateApiData && (
                <VHACard
                    icon={alertBlueIcon}
                    title={syncUpdateContent?.syncUpdatesAccordionLabel}
                    description={getSyncUpdateDescription(
                        syncUpdateApiData,
                        syncUpdateContent
                    )}
                    cardState={getSyncUpdateCardState(
                        syncUpdateApiData?.syncUpdateAvailable
                    )}
                    redirectUrl={syncUpdateContent?.syncUpdatePath}
                />
            )}
        </>
    );
};

export default SyncAndUpdates;
