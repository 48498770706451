import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';

export interface EsbResponse {
    requestStatus: string;
    error: {
        statusCode: number;
    };
    serviceStatus: {
        status: string;
    };
    contracts: [
        {
            contractState: string;
            contractProductName: string;
            contractProductDescription: string;
            contractExpirationDate: string;
            contractExpirationDistanceMeasure: string;
        }
    ];
}

export default class ZafEsbService {
    private parseResponse(response: AxiosResponse<any>): any {
        if (response.data) {
            return response.data;
        }
        return null;
    }

    public async getEsbForZAF(vin: string): Promise<EsbResponse | null> {
        const url = DSL_BASE_URL + DSL_API_PATH.EXTENDED_SERVICE_BUSINESS;
        const response = HttpService.get<EsbResponse>(url, true, {
            headers: {
                'Consumer-Key': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                    'Consumer-Key'
                ],
                'Auth-Token': HttpService.getAppIdAndAuthTokenRequestHeaders()[
                    'Auth-Token'
                ],
                vin: vin,
            },
        });
        return response.then(this.parseResponse);
    }
}
