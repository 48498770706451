import React from 'react';
import './service-and-offers.scss';
import { FeatureTrio } from '../../vehicle-portal/components/feature-trio/feature-trio';
import { Category5050Carousel } from '../../category/categories/category-5050-carousel/category-5050-carousel';
import { ViewportUtil } from '../../../utils/viewport-util/viewport-util';
import { AemArticles } from '../../../../models/experiencefragments/category-view';

interface Props {
    serviceOffersSection: AemArticles;
}

export const ServiceAndOffers = (props: Props) => {
    const { isMobileView } = ViewportUtil();
    const articlesObj: any = {};
    articlesObj.articles = { ...props?.serviceOffersSection };

    return (
        <>
            {props?.serviceOffersSection && (
                <div className="service-and-offers-section">
                    {isMobileView ? (
                        <Category5050Carousel
                            {...props?.serviceOffersSection}
                        />
                    ) : (
                        <FeatureTrio {...articlesObj} />
                    )}
                </div>
            )}
        </>
    );
};
