import React from 'react';

const ServerContext = React.createContext<any>({
    content: {},
    data: {},
    documentTitle: '',
    currentRegionCode: '',
    currentLanguageRegionCode: '',
    current3LetterCountryCode: '',
    brand: '',
    metaDescription: '',
    hrefLangs: '',
    profile: null,
    domain: '',
    root: '',
    canonical: '',
    applicationId: '',
    metaTags: [],
    noIndex: false,
    webview: 'false',
    webviewData: null,
    recallsType: '',
    pageNotFound: false,
});

export default ServerContext;
