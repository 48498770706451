import React, { useEffect } from 'react';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { LiveStreamDetails } from '../live-stream-builder';
import './upcoming-event.scss';

interface Props {
    livestream: LiveStreamDetails;
}
export const UpcomingEvent = (props: Props) => {
    useEffect(() => {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        const script = document.createElement('script');

        script.src = props.livestream.src;

        script.defer = true;

        script.setAttribute(
            'data-language-code',
            appConfiguration.languageRegionCode
        );
        script.setAttribute(
            'data-program-country',
            appConfiguration.threeLetterCountryCode
        );

        document.body.appendChild(script);
    }, [props.livestream]);

    return (
        <div className="upcoming-event-wapper">
            <div>
                {' '}
                <h1 className="event-heading">
                    DISCOVER YOUR FORD: LIVESTREAM
                </h1>
            </div>

            <div id={props.livestream.id}></div>
        </div>
    );
};
