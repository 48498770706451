import React from 'react';
import { EU_COUNTRIES } from '../../../../constants';
import { Category } from '../../../../models/experiencefragments/header';
import { Link } from '../../../common';
import './global-mx-eu-footer-accordion.scss';

interface Props {
    footerNavigation: Category;
    country: any;
}

const GlobalMXandEUFooterAccordion = (props: Props) => {
    return (
        <div className="footer-list">
            <h4
                className={`${
                    EU_COUNTRIES.includes(props.country) === true
                        ? 'eu-footer-category-title'
                        : 'category-title'
                }`}
            >
                {props.footerNavigation.categoryTitle}
            </h4>
            <ul
                className={`${
                    EU_COUNTRIES.includes(props.country) === true &&
                    props.footerNavigation.categoryItems.length < 10
                        ? 'eu-footer-category-items'
                        : 'category-items'
                }
                                                ${
                                                    EU_COUNTRIES.includes(
                                                        props.country
                                                    ) === true &&
                                                    props.footerNavigation
                                                        .categoryItems.length >
                                                        10
                                                        ? 'eu-footer-category-items-splitcolumn'
                                                        : 'category-items'
                                                }`}
            >
                {props.footerNavigation.categoryItems.map((item, index) => {
                    return (
                        <li key={index} className="category-item">
                            <Link
                                href={item.url}
                                className="category-item-link"
                                target={item.targetBlank ? '_blank' : '_self'}
                            >
                                {item.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default GlobalMXandEUFooterAccordion;
