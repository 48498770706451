import React from 'react';
import './go-back-button.scss';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import { FdsChevron } from '../../common/chevron/fds-chevron';
import { Link } from 'react-router-dom';
import { sanitizeUrl } from '../../utils/dom-util';

interface Props {
    goBackLabel: string;
    goBackUrl?: string;
    backToDashboardLabel?: string;
}

export const GoBackButton = (props: Props) => {
    const authService = new AuthenticationService();
    let isDashboardLinkSet = false;

    const getBackToFordLink = () => {
        if (authService.getCatBundle() && props.backToDashboardLabel) {
            const linkFromSession = sessionStorage.getItem(
                'dashboardOriginUrl'
            );
            if (linkFromSession) {
                isDashboardLinkSet = true;
                return linkFromSession;
            }
        }
        return props.goBackUrl ? props.goBackUrl : 'javascript:history.back()';
    };

    const getBackToFordTitle = () => {
        return authService.getCatBundle() &&
            props.backToDashboardLabel &&
            isDashboardLinkSet
            ? props.backToDashboardLabel
            : props.goBackLabel;
    };

    return (
        <div className="back-to-ford-account-link secondary-button">
            <Link
                role="link"
                className="secondary-button-link"
                to={sanitizeUrl(getBackToFordLink())}
                aria-label={`${getBackToFordTitle()}`}
            >
                <FdsChevron type="filled" direction="left" />
                <span className="secondary-button-text">
                    {`${getBackToFordTitle()}`}
                </span>
            </Link>
        </div>
    );
};
