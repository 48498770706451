import { useContent } from '../../../../hooks/use-server-content';

export interface VehiclePreferenceContentProps {
    vehPrefTitle: string;
    vehPrefTitle1Heading: string;
    vehPrefTitle1IconPath: string;
    vehPrefTitle1Description: string;
    vehPrefTitle1CTALabel: string;
    vehPrefTitle1CTALink: string;
    vehPrefTitle2Heading: string;
    vehPrefTitle2IconPath: string;
    vehPrefTitle2Description: string;
    vehPrefTitle2CTALabel: string;
    vehPrefTitle2CTALink: string;
    vehPrefTitle3Heading: string;
    vehPrefTitle3IconPath: string;
    vehPrefTitle3Description: string;
    vehPrefTitle3CTALabel: string;
    vehPrefTitle3CTALink: string;
}
export const useVehiclePreferenceContent = (): VehiclePreferenceContentProps => {
    const [content, getValueByName] = useContent(
        'vehicles/vehicle-health-reports',
        'vehicle-preference'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as VehiclePreferenceContentProps;
};
export default useVehiclePreferenceContent;
