import './tire-leak-status.lincoln.scss';

interface Props {
    tireStatus: TyreLeakData;
}

interface TyreLeakData {
    tireWithSlowLeak: string[];
    shouldShow: Boolean;
    urgency: string;
    wheelers: number;
    leftFrontTirePressure: number;
    rightFrontTirePressure: number;
    outerLeftRearTirePressure: number;
    outerRightRearTirePressure: number;
    innerLeftRearTirePressure: number;
    innerRightRearTirePressure: number;
    recommendedFrontTirePressure: number;
    recommendedRearTirePressure: number;
}

export const TireLeakStatus = (props: Props) => {
    const isSixWheeler = props.tireStatus.wheelers == 6 ? true : false;

    const isTireLeaked = (tirePosition: string) => {
        if (
            props.tireStatus.tireWithSlowLeak != undefined &&
            props.tireStatus.tireWithSlowLeak.includes(tirePosition)
        ) {
            if (
                props.tireStatus.urgency === 'H' ||
                props.tireStatus.urgency === 'U'
            ) {
                return 'tls_alert';
            } else if (props.tireStatus.urgency === 'M') {
                return 'tls_warning';
            }
        } else {
            return '';
        }
    };

    return (
        <div className="tls-icon-wrapper">
            <div className="tls_text_container">
                <div className={`${isTireLeaked('LF')}`}>
                    {props.tireStatus.leftFrontTirePressure}
                </div>
                <div>
                    {isSixWheeler && (
                        <>
                            <span id="ilr" className={`${isTireLeaked('ILR')}`}>
                                {props.tireStatus.innerLeftRearTirePressure}
                            </span>
                            <span> | </span>
                        </>
                    )}
                    <span id="olr" className={`${isTireLeaked('OLR')}`}>
                        {props.tireStatus.outerLeftRearTirePressure}
                    </span>
                </div>
            </div>
            <div>
                {isSixWheeler ? (
                    <>
                        <svg
                            width="84"
                            height="72"
                            viewBox="0 0 84 72"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="12"
                                y="12"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('LF')}`}
                            />
                            <rect
                                x="64"
                                y="12"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('RF')}`}
                            />
                            <rect
                                x="12"
                                y="46"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('OLR')}`}
                            />
                            <rect
                                x="64"
                                y="46"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('ORR')}`}
                            />
                            <rect
                                x="74"
                                y="46"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('IRR')}`}
                            />
                            <rect
                                x="2"
                                y="46"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('ILR')}`}
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M42.0005 8C46.6671 8 51.3336 9.16667 55.5557 11.2667C56.6668 11.9667 57.5557 13.3667 57.5557 15V26.6667H62V31.3333H57.5557V58.8667C57.5557 60.7333 56.0002 62.6 54.4446 62.8333C52.8891 63.0667 46.4448 64 42.0005 64C37.5562 64 31.5563 63.3 29.5564 62.8333C27.5565 62.3667 26.4454 60.9667 26.4454 58.8667V31.3333H22.001V26.6667H26.4454V15C26.4454 13.6 27.3342 12.2 28.4453 11.5C32.6674 9.16667 37.334 8 42.0005 8ZM42.0005 12.6667C38.0006 12.6667 34.2229 13.6 30.8897 15.2333V58.1667C34.4452 58.8667 38.2228 59.1 42.0005 59.1C45.7782 59.1 49.5559 58.8667 53.1113 58.1667V15.2333C49.7781 13.6 46.0004 12.6667 42.0005 12.6667ZM47.1115 47.6667C48.0004 47.6667 48.667 48.3667 48.667 49.3V53.2667L48.5626 53.3223C48.0548 53.5825 45.716 54.6667 42.0005 54.6667C38.2851 54.6667 35.9463 53.5825 35.4385 53.3223L35.3457 53.2732L35.334 49.3C35.334 48.3667 36.0007 47.6667 37.1118 47.6667H47.1115ZM42.0005 24.3333C45.716 24.3333 48.0548 25.4175 48.5626 25.6777L48.6553 25.7268L48.667 29.7C48.667 30.6333 48.0004 31.3333 46.8893 31.3333H36.8895C36.0007 31.3333 35.334 30.6333 35.334 29.7V25.7333L35.4385 25.6777C35.9463 25.4175 38.2851 24.3333 42.0005 24.3333Z"
                                fill="#102B4E"
                            />
                        </svg>
                    </>
                ) : (
                    <>
                        <svg
                            width="66"
                            height="72"
                            viewBox="0 0 66 72"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="2"
                                y="12"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('LF')}`}
                            />
                            <rect
                                x="56"
                                y="12"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('RF')}`}
                            />
                            <rect
                                x="2"
                                y="46"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('OLR')}`}
                            />
                            <rect
                                x="56"
                                y="46"
                                width="8"
                                height="16"
                                rx="2"
                                className={`tls_tire ${isTireLeaked('ORR')}`}
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="m33,2c4.67,0 9.33,1.17 13.56,3.27c1.11,0.7 2,2.1 2,3.73l0,11.67l4.44,0l0,4.66l-4.44,0l0,27.54c0,1.86 -1.56,3.73 -3.12,3.96c-1.55,0.24 -8,1.17 -12.44,1.17c-4.44,0 -10.44,-0.7 -12.44,-1.17c-2,-0.46 -3.11,-1.86 -3.11,-3.96l0,-27.54l-4.45,0l0,-4.66l4.45,0l0,-11.67c0,-1.4 0.88,-2.8 2,-3.5c4.22,-2.33 8.88,-3.5 13.55,-3.5zm0,4.67c-4,0 -7.78,0.93 -11.11,2.56l0,42.94c3.56,0.7 7.33,0.93 11.11,0.93c3.78,0 7.56,-0.23 11.11,-0.93l0,-42.94c-3.33,-1.63 -7.11,-2.56 -11.11,-2.56zm5.11,35c0.89,0 1.56,0.7 1.56,1.63l0,3.97l-0.11,0.05c-0.51,0.26 -2.84,1.35 -6.56,1.35c-3.71,0 -6.05,-1.09 -6.56,-1.35l-0.09,-0.05l-0.02,-3.97c0,-0.93 0.67,-1.63 1.78,-1.63l10,0zm-5.11,-23.34c3.72,0 6.05,1.09 6.56,1.35l0.1,0.05l0.01,3.97c0,0.93 -0.67,1.63 -1.78,1.63l-10,0c-0.89,0 -1.56,-0.7 -1.56,-1.63l0,-3.97l0.11,-0.05c0.51,-0.26 2.85,-1.35 6.56,-1.35z"
                                fill="#102B4E"
                            />
                        </svg>
                    </>
                )}
            </div>
            <div className="tls_text_container tls_text_container_right">
                <div className={`${isTireLeaked('RF')}`}>
                    {props.tireStatus.rightFrontTirePressure}
                </div>
                <div>
                    {isSixWheeler && (
                        <>
                            <span id="orr" className={`${isTireLeaked('IRR')}`}>
                                {props.tireStatus.innerRightRearTirePressure}
                            </span>
                            <span> | </span>
                        </>
                    )}
                    <span id="irr" className={`${isTireLeaked('ORR')}`}>
                        {props.tireStatus.outerRightRearTirePressure}
                    </span>
                </div>
            </div>
        </div>
    );
};
