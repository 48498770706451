import React, { useContext, useEffect, useRef, useState } from 'react';
import { FloatingActionButton } from '@own/fds-react';
import { matchPath, useLocation } from 'react-router-dom';
import { BrandUtil } from '../../../components/utils/brand-util/brand-util';
import { mergeRoutes } from '../../../components/utils/router-util/router-util';
import ServerContext from '../../../contexts/serverContext';
import { configs } from '../../../router-configuration';
import { DEFAULT_REGISTER, RouteDescription } from '../../../routes';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import CheckDeviceType, {
    DeviceType,
} from '../../../services/check-device-type/check-device-type';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import { useFabContent } from './hooks/use-fab-content';
import './scroll-to-top.scss';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    pageOffSetY?: number;
    timeOut?: number;
    onClickButton?: Function;
    cleanUpRef?: Function;
    otherElementRef?: any;
    aemCategory?: string;
    aemCF?: string;
    showAfterScrollingPage?: boolean;
}

const callAll = (...fns: any[]) => (...args: any) =>
    fns.forEach(fn => fn && fn(...args));

const ScrollToTop = ({
    onClickButton,
    cleanUpRef,
    otherElementRef = null,
    ...props
}: Props) => {
    const [showButton, setShowButton] = useState(false);
    const [buttonPosition, setButtonPosition] = useState(40);
    const [mouseHover, setMouseHover] = useState(false);
    const [documentOffSetY, setDocumentOffSetY] = useState(3000);
    const [device, setDevice] = useState<DeviceType>();
    const oldScrollY = useRef<number>(0);
    const fabContent = useFabContent(props.aemCategory, props.aemCF);
    const [fireEvents] = useAnalytics();
    const [presentRoute, setPresentRoute] = useState('');
    const floatingButtonRef = useRef<HTMLButtonElement>(null);
    const {
        currentDomain,
        currentLanguageRegionCode,
        brand,
    } = new AppConfigurationService();

    let routeAtPresent = '';
    const location = useLocation();
    const brandUtil = new BrandUtil();
    const serverContext = useContext(ServerContext);
    const effectiveDomain = serverContext.domain || currentDomain;
    const brandName = brandUtil.getBrandName(serverContext.brand || brand);
    const languageRegionCode =
        serverContext.currentLanguageRegionCode || currentLanguageRegionCode;
    const mergedRegister = mergeRoutes(
        DEFAULT_REGISTER,
        configs,
        effectiveDomain,
        brandName,
        languageRegionCode
    );
    mergedRegister.forEach((routeDescription: RouteDescription) => {
        const match = matchPath(location.pathname, routeDescription.path);
        if (routeDescription.path !== '*' && match?.isExact) {
            routeAtPresent = routeDescription.alias;
        }
    });

    useEffect(() => {
        setPresentRoute(routeAtPresent);
    }, [routeAtPresent]);

    useEffect(() => {
        const calculateDocumentOffsetY = () => {
            let offset = 0;
            const { scrolledPosition } = fabContent;
            const windowHeight = Math.max(
                document.body.clientHeight,
                window.innerHeight
            );
            switch (scrolledPosition) {
                case 'half':
                    offset = Math.floor(windowHeight / 2);
                    break;
                case 'threeByForth':
                    offset = Math.floor(windowHeight * 0.75);
                    break;
                default:
                    offset = Math.floor(windowHeight / 4);
                    break;
            }
            return offset;
        };
        if (props.pageOffSetY) {
            setDocumentOffSetY(props.pageOffSetY);
        } else {
            const documentOffSetY = calculateDocumentOffsetY();
            setDocumentOffSetY(documentOffSetY);
        }
    }, [fabContent.scrolledPosition, props.pageOffSetY]);

    const showAfterScrollingPage =
        props.showAfterScrollingPage ?? fabContent.showAfterScrollingPage;
    const timeOut = props.timeOut ?? fabContent.timeOut;

    useEffect(() => {
        setDevice(CheckDeviceType());
    }, []);

    const scrollToTop = () => {
        fireEvents(
            'scroll-to-top-cta',
            undefined,
            { pageNameAlias: presentRoute },
            false
        );
        if (
            otherElementRef &&
            otherElementRef.current &&
            otherElementRef.current.getBoundingClientRect().top < window.scrollY
        ) {
            if ('scrollBehavior' in document.documentElement.style) {
                otherElementRef.current.scrollIntoView({
                    behavior: 'smooth',
                });
            } else {
                otherElementRef.current.scrollIntoView(true);
            }
            cleanUpRef && cleanUpRef();
        } else {
            window.scrollTo({
                top: 0,
                behavior:
                    'scrollBehavior' in document.documentElement.style
                        ? 'smooth'
                        : 'auto',
            });
            cleanUpRef && cleanUpRef();
        }
    };

    const onButtonClick = callAll(scrollToTop, onClickButton);

    const onMouseHover = (value: boolean) => {
        setMouseHover(value);
    };

    const handleWindowScroll = () => {
        if (ServerSideService.isClientSide()) {
            if (window.scrollY > documentOffSetY) {
                if (
                    oldScrollY.current &&
                    oldScrollY.current < window.scrollY &&
                    !showAfterScrollingPage
                ) {
                    setShowButton(false);
                } else {
                    setShowButton(true);
                }
            } else {
                setShowButton(false);
            }
            oldScrollY.current = window.scrollY;
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (parseInt(timeOut as any)) {
            timer = setTimeout(() => {
                if (showButton && !mouseHover) {
                    setShowButton(false);
                }
            }, timeOut);
        }
        return () => clearTimeout(timer);
    }, [showButton, mouseHover]);

    useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll);
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, [documentOffSetY]);

    const validElements = useRef<Element[]>([]);

    let allElementsHeight: any[] = [];
    const getAllElementsHeight = (element: any) => {
        if (element.hasChildNodes()) {
            const children = element.childNodes;
            children.forEach((child: any) => {
                if (child.nodeType === 1) {
                    const height = child.offsetHeight;
                    if (
                        height !== undefined &&
                        !allElementsHeight.includes(height)
                    ) {
                        allElementsHeight.push(height);
                    }
                    getAllElementsHeight(child);
                }
            });
        }
        setButtonPosition(
            allElementsHeight.length > 0
                ? Math.max(...(allElementsHeight as number[])) + 40
                : 40
        );
    };

    const getParentElement = (element: Element) => {
        const root = document.getElementById('root');
        let reqNode: Element | null = null;
        let elementNode: Element | null = element;
        while (
            elementNode &&
            elementNode.tagName &&
            elementNode.tagName.toLowerCase() !== 'body'
        ) {
            if (elementNode?.tagName?.toLowerCase() !== 'html') {
                if (
                    elementNode?.tagName.toLowerCase() === 'header' ||
                    (elementNode?.classList.contains('help-router-container') &&
                        elementNode?.classList.contains('open') &&
                        root?.contains(element))
                ) {
                    setShowButton(false);
                    reqNode = null;
                    break;
                } else if (
                    root?.contains(element) ||
                    elementNode?.tagName.toLowerCase() === 'footer' ||
                    elementNode?.id === 'global-ux' ||
                    elementNode?.id === 'root' ||
                    elementNode?.classList.contains('scroll-top')
                ) {
                    reqNode = null;
                    break;
                }
            } else {
                break;
            }
            reqNode = elementNode;
            elementNode = elementNode.parentNode as Element;
        }
        return reqNode;
    };

    function getPointCoordinates(scrollTopRect?: DOMRect) {
        const x = scrollTopRect
            ? scrollTopRect.left + scrollTopRect.width / 2
            : 0;
        const y = scrollTopRect
            ? scrollTopRect.top + scrollTopRect.height * 0.9
            : 0;
        return { x, y };
    }

    useEffect(() => {
        const body = document.querySelector('body');
        const getPointParentElement = () => {
            allElementsHeight = [];
            const scrollTopRect = floatingButtonRef.current?.getBoundingClientRect();
            const { x, y } = getPointCoordinates(scrollTopRect);
            const elements = document?.elementsFromPoint(x, y);
            elements.forEach(element => {
                let unique = true;
                const reqNode = getParentElement(element);
                if (validElements.current.length > 0 && reqNode) {
                    validElements.current.forEach(element => {
                        unique = !element.isEqualNode(reqNode);
                    });
                }
                if (unique) {
                    reqNode && validElements.current.push(reqNode);
                }
            });
            validElements.current.forEach(elementConsidered => {
                getAllElementsHeight(elementConsidered);
            });
        };
        const getObserver = (mutations: MutationRecord[]) => {
            for (const mutation of mutations) {
                if (
                    ['attributes', 'childList', 'characterData'].includes(
                        mutation.type
                    ) &&
                    getParentElement(mutation.target as Element)
                ) {
                    getPointParentElement();
                }
            }
        };
        const observer = new MutationObserver(getObserver);
        const options = {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
        };
        body && observer.observe(body, options);
        return () => {
            observer?.disconnect();
        };
    }, []);

    function getMouseHoverValue() {
        if (device === DeviceType.UNDETERMINED) {
            return {
                onMouseOut: () => onMouseHover(false),
                onMouseOver: () => onMouseHover(true),
            };
        }
        return {};
    }

    function getCustomButtonClass() {
        return device === DeviceType.UNDETERMINED && fabContent.label
            ? ''
            : ' no-expand';
    }

    if (fabContent?.hide) {
        return <></>;
    }

    return (
        <div
            className="scroll-top"
            data-hidden={!showButton}
            style={{
                bottom: `${buttonPosition}px`,
            }}
        >
            <FloatingActionButton
                ref={floatingButtonRef}
                buttonCustomClass={getCustomButtonClass()}
                {...getMouseHoverValue()}
                variant={fabContent.fabVariant || 'scrollToTop'}
                label={fabContent.label}
                aria-label={fabContent.ariaLabel}
                onClick={onButtonClick}
                {...props}
            />
        </div>
    );
};

export default ScrollToTop;
