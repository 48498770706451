import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';
import HttpService from '../../http-service/http-service';

interface UpdateUserResponse {
    httpStatus: string;
    status: string;
    requestStatus: string;
    error: {
        message: string;
        statusCode: string;
        statusContext: string;
    };
    lastRequested: string;
    version: string;
    statusCode: string | null;
    message: string | null;
}

interface UpdateUserRequest {
    title: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export default class FordAccountService {
    public async updateUserPersonalInfo(
        userDetails: UpdateUserRequest
    ): Promise<UpdateUserResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.UPDATE_USER_INFO;
        return HttpService.put<any>(url, userDetails, {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
        }).then(response => {
            return response.data;
        });
    }
}
