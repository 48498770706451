import React, { useEffect, useState } from 'react';
import { InputField, PrimaryButton } from '../../../../common';
import { VinRegLookup } from '../vin-reg-lookup/vin-reg-lookup';
import getNumberFormatByRegion from '../../../../utils/number-format-utill/number-format-mileage';
import './enter-your-vin-section.scss';
import Popover from '../../../../common/popover/popover';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';
import { KEYBOARD_KEYS } from '../../../../../constants';

interface Props {
    contentLabels: {
        enterYourVehicleDetailFormHeadline: string;
        vinOrRegInputFieldLabel: string;
        mileageInputFieldLabel: string;
        enterYourVehicleDetailsCTALabel: string;
        maintenanceScheduleNotFoundErrorMessage: string;
        maintenanceSchedulePath: string;
        enterYourVehicleDetailsTooltipHeadline: string;
        enterYourVehicleDetailsTooltipContent: string;
        enterYourVehicleDetailsTooltipAriaLabel: string;
    };
    showErrorPage: (isError: boolean) => void;
    goToMaintenanceScheduleViewDetails: (action: string) => void;
}

export const EnterYourVinSection = (props: Props) => {
    const [mileage, setMileage] = useState<string>('');
    const [showPopover, setPopover] = useState<boolean>(false);
    const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 });

    const [isMobileView, setMobileView] = useState<boolean>(
        ServerSideService.isClientSide() ? window.innerWidth <= 768 : false
    );
    const [isCtaClicked, setIsCtaClicked] = useState<boolean>(false);
    const {
        enterYourVehicleDetailFormHeadline,
        vinOrRegInputFieldLabel,
        mileageInputFieldLabel,
        enterYourVehicleDetailsCTALabel,
        maintenanceScheduleNotFoundErrorMessage,
        maintenanceSchedulePath,
        enterYourVehicleDetailsTooltipHeadline,
        enterYourVehicleDetailsTooltipContent,
        enterYourVehicleDetailsTooltipAriaLabel,
    } = props.contentLabels;
    const [fireEvents] = useAnalytics();

    const onResize = () => {
        setMobileView(() => window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const triggerMaintenanceScheduleVinOrRegCTAOnClickEvent = () => {
        fireEvents(
            ['maintenance-schedule-landing-cta-onclick-event'],
            undefined,
            {
                maintenanceScheduleCtaContext: 'vin or reg',
            },
            false
        );
    };

    const updateMileageInput = (e: any) => {
        const mileageValue = e.target.value;
        if (
            e.type === 'change' &&
            (mileageValue === '' || mileageValue.match(/^\d+$/))
        ) {
            setMileage(mileageValue);
        } else if (e.type === 'blur' && mileageValue) {
            setMileage(
                getNumberFormatByRegion(mileageValue.replace(/\D/g, ''))
            );
        } else if (e.type === 'focus' && mileageValue) {
            setMileage(mileageValue.replace(/\D/g, ''));
        }
    };

    const saveUserMileage = () => {
        window.sessionStorage.removeItem('maintenance-schedule-mileage');
        if (mileage) {
            const mileageValue = mileage.replace(/\D/g, '');
            window.sessionStorage[
                'maintenance-schedule-mileage'
            ] = +mileageValue;
        }
    };

    const goToNextPage = (vin: string) => {
        if (maintenanceSchedulePath && vin && isCtaClicked) {
            saveUserMileage();
            triggerMaintenanceScheduleVinOrRegCTAOnClickEvent();
            props.goToMaintenanceScheduleViewDetails(vin);
        }
    };

    const enterYourVehicleCTAClick = () => {
        setIsCtaClicked(true);
    };

    const resetIsCtaClicked = () => {
        setIsCtaClicked(false);
    };

    const setPageScrollTo = (rect: DOMRect) => {
        if (!isMobileView && rect.top < 120) {
            window.scrollBy(0, -150);
        } else if (!isMobileView && rect.top > 450 && rect.top >= 160) {
            window.scrollBy(0, 150);
        }
    };

    const getToolTipPosition = () => {
        let position = 'right';
        const popoverTriggerEle = document.getElementsByClassName(
            'enter-your-vin-info-wrapper'
        )[0];
        if (popoverTriggerEle) {
            const rect = popoverTriggerEle.getBoundingClientRect();
            if (rect.x + 350 <= window.innerWidth) {
                position = 'left';
            }
        }
        return position;
    };

    const setPopoverPositionState = (rect: DOMRect) => {
        const newPosition =
            getToolTipPosition() == 'left'
                ? {
                      left: !isMobileView ? rect.x + 36 : 0,
                      top: !isMobileView ? rect.y - 130 : 0,
                  }
                : {
                      left: !isMobileView ? rect.x - 310 : 0,
                      top: !isMobileView ? rect.y - 130 : 0,
                  };
        setPopoverPosition(newPosition);
    };

    const togglePopover = (e: any, popoverState: boolean) => {
        if (e && e.currentTarget && e.currentTarget.getBoundingClientRect) {
            let rect = e.currentTarget.getBoundingClientRect();
            setPageScrollTo(rect);
            setPopover(popoverState);
            rect = e.currentTarget.getBoundingClientRect();
            setPopoverPositionState(rect);
            e.preventDefault();
        } else {
            setPopover(popoverState);
        }
        e.preventDefault();
    };

    const togglePopoverClicked = (event: any, value: boolean) => {
        if (
            event.key === KEYBOARD_KEYS.ENTER ||
            event.key === KEYBOARD_KEYS.SPACE
        ) {
            togglePopover(event, value);
        }
    };
    const renderPopover = () => {
        const popoverContent = (
            <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: enterYourVehicleDetailsTooltipContent,
                    }}
                />
            </div>
        );

        return (
            <Popover
                name="enter-your-vin-tooltip"
                tooltipPosition={getToolTipPosition()}
                mask={false}
                handleClose={e => togglePopover(e, false)}
                popoverPosition={popoverPosition}
                heading={enterYourVehicleDetailsTooltipHeadline}
                content={popoverContent}
                focusElement={'.enter-your-vin-info-icon'}
            />
        );
    };

    const renderPopoverInfoIcon = () => {
        const isTooltipDataAvailable =
            enterYourVehicleDetailsTooltipHeadline &&
            enterYourVehicleDetailsTooltipContent;
        return (
            <>
                {showPopover && renderPopover()}
                {isTooltipDataAvailable ? (
                    <div className="enter-your-vin-info-wrapper">
                        <img
                            className="enter-your-vin-info-icon"
                            aria-label={
                                'icon ' +
                                enterYourVehicleDetailsTooltipAriaLabel +
                                ' tooltip'
                            }
                            alt=""
                            role="button"
                            tabIndex={0}
                            onClick={(e: any) => togglePopover(e, true)}
                            onKeyUp={(e: any) => togglePopoverClicked(e, true)}
                        />
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    };

    return (
        <div className="enter-your-vin-section">
            <div className="heading-with-icon">
                <h2>{enterYourVehicleDetailFormHeadline}</h2>
                {renderPopoverInfoIcon()}
            </div>
            <VinRegLookup
                vinOrRegInputFieldLabel={vinOrRegInputFieldLabel}
                name="maintenance-schedule-vin"
                customErrorMessage={maintenanceScheduleNotFoundErrorMessage}
                goToNextPage={goToNextPage}
                showErrorPage={props.showErrorPage}
                isCtaClicked={isCtaClicked}
                resetIsCtaClicked={resetIsCtaClicked}
            />
            <InputField
                label={mileageInputFieldLabel}
                ariaLabel={mileageInputFieldLabel}
                name="mileage"
                className="maintenance-schedule-mileage mileage-textField"
                onChange={updateMileageInput}
                onBlur={updateMileageInput}
                onFocus={updateMileageInput}
                value={mileage}
                fdsStyle={true}
            />
            <div className="enter-your-vehicle-cta-wrapper">
                <PrimaryButton
                    className="enter-your-vehicle-cta"
                    color={'dark'}
                    fill={'fill'}
                    chevron={true}
                    onClick={enterYourVehicleCTAClick}
                    ariaLabel={enterYourVehicleDetailsCTALabel}
                >
                    {enterYourVehicleDetailsCTALabel}
                </PrimaryButton>
            </div>
        </div>
    );
};
