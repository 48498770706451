import React, { useState, useEffect, useMemo } from 'react';
import './booking-summary-accordion-lincoln.scss';
import { LightBookingSummary } from '../../light-booking-summary/light-booking-summary';
import { OsbContentResponse } from '../../../../../models/osb-model/osb-content-details';
import OsbUtilService from '../../../../../services/osb-service/osb-util-service/osb-util-service';
import ServiceHandler from '../../../../../services/service-handler';
import {
    BOOKING_SUMMARY_CONTAINER_KEYS,
    OSB_LIGHTT_VOUCHER_KEYS,
    SERVICE_DELIVERY_TYPE,
} from '../../osb-constant';
import { LightJourneyVouchers } from '../../services-step/light-journey/light-vouchers/light-vouchers';
import {
    useDealerStep,
    useHttp,
    useOSBStep,
    useServiceStep,
    useVehicleStep,
    useLightDeliveryOptionServiceStep,
    useServiceLocationStep,
} from '../../../../../hooks/owners-osb';
import {
    getLightVehicleData,
    getVoucherAppliedSelectedDeliveryOptions,
    getVoucherAppliedSelectedLocationOptions,
    getVoucherAppliedSelectedServices,
    ErrorResponse,
    calculateTotalPriceSummary,
    getActualPrice,
} from '../../osb-utils';
import { VehicleData } from '../../../../../models/osb-model/osb-vehicle-step';
import { DealerServices } from '../../../../../models/osb-model/osb-dealerservice-info';
import { triggerStickyViewSummaryLinkOnClickMobileAnalytics } from '../../analytics/osb-analytics';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { useCampaignStatesStep } from '../../../../../hooks/owners-osb/use-campaign-states-step';
import { useAllOSBState } from '../../../../../hooks/owners-osb/use-all-osb-state';

interface Props {
    index: string;
    className: string;
    borderBottom?: boolean;
    expandMultiplePanels?: boolean;
    chevronText?: string;
    refreshBookingSummary?: () => void;
    stepName?: string;
    showVoucherLink: boolean;
    loadVoucherAlertMessage: (
        alertMessage: string,
        alertSuccess: boolean
    ) => void;
}

const BookingSummaryAccordion = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const {
        osbServiceLocationDetail,
        setOSBServiceLocationStepPayload,
    } = useServiceLocationStep();
    const { osbServiceStep, setOSBServiceStepPayload } = useServiceStep();
    const {
        osbLightDeliveryServiceStep,
        setOSBLightDeliveryServiceStep,
    } = useLightDeliveryOptionServiceStep();
    const [reviewBookingContent, setReviewBookingContent] = useState<
        OsbContentResponse
    >();
    const { setOSBCampaignStates } = useCampaignStatesStep();
    const { dispatch } = useHttp();
    const { osbDealerStep } = useDealerStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbStep } = useOSBStep();
    const [isServiceTotalRefreshed, setIsServiceTotalRefreshed] = useState(
        false
    );
    const bookable = useAllOSBState();
    const [contentLoading, setContentLoading] = useState(false);

    const { osbCampaignStates } = useCampaignStatesStep();
    const potentialSavingAmount = useMemo(() => {
        return osbCampaignStates.campaignStates.reduce((total, item) => {
            if (item.state === 'VALID') {
                total += item.potentialSavings;
            }
            return total;
        }, 0);
    }, [osbCampaignStates]);

    const updateAccordionState = (
        element: Element,
        expanded: boolean,
        areaExpanded: string,
        removeIcon: string,
        addIcon: string
    ) => {
        element
            .querySelector('.accordion-title')
            ?.setAttribute('aria-expanded', areaExpanded);
        element.querySelector('.fds-chevron')?.classList.remove(removeIcon);
        element.querySelector('.fds-chevron')?.classList.add(addIcon);
        if (expanded) {
            element.querySelector('.panel')?.classList.add('expanded');
            if (props.expandMultiplePanels) {
                element
                    .querySelector('.accordion-title')
                    ?.classList.add('header-color');
            }
        } else {
            element.querySelector('.panel')?.classList.remove('expanded');
            if (props.expandMultiplePanels) {
                element
                    .querySelector('.accordion-title')
                    ?.classList.remove('header-color');
            }
        }
    };

    const [openModel, setOpenModel] = useState(false);

    const setStatus = (status: boolean) => {
        setOpenModel(status);
    };

    const loadVoucherAlertMessage = (message: string, success: boolean) => {
        props.loadVoucherAlertMessage(message, success);
    };

    const openModelData = () => {
        setOpenModel(true);
        const elementId = '#accordion-' + props.className + '-' + props.index;
        const activeEle = document.querySelector(elementId);
        if (!activeEle) {
            return;
        }
        if (activeEle.querySelector('.panel')?.classList.contains('expanded')) {
            activeEle.querySelector('.panel')?.classList.remove('expanded');
        }
    };
    const loadServiceData = async (
        voucherCode: string,
        vehicleData: VehicleData,
        selectedDealerCode: string
    ) => {
        // TODO: Hack to avoid the race condition of updating global state and making a call to api service.
        if (!voucherCode) {
            bookable.campaignSelections = [];
        }
        await ServiceHandler.OsbDealerServices.getServicesList({
            dealerCode: selectedDealerCode,
            vehicleData: vehicleData,
            vouchers: voucherCode ? [voucherCode] : [],
            serviceDeliveryType: osbServiceStep.isMobileServiceSelected
                ? SERVICE_DELIVERY_TYPE.MOBILESERVICE
                : SERVICE_DELIVERY_TYPE.WORKSHOP,
            requestSourceType: osbStep.source,
            bookable: bookable,
            DSLAPIVersion: osbStep.DSLAPIVersion,
        })
            .then(results => {
                setOSBServiceStepPayload({
                    dealerServiceInfo: results.value,
                    voucherDesc:
                        results.value.dealerServices.serviceVouchers.length > 0
                            ? results.value.dealerServices.serviceVouchers[0]
                                  .description
                            : '',
                });
                if (
                    Array.isArray(results.value.dealerServices?.campaignStates)
                ) {
                    setOSBCampaignStates({
                        campaignStates: [
                            ...results.value.dealerServices.campaignStates,
                        ],
                    });
                }
                dispatch({ type: 'RESPONSE' });
                props.refreshBookingSummary?.();
            })
            .catch((error: ErrorResponse) => {
                if (error?.errorMessage) {
                    const errorMessage = error.errorMessage;
                    dispatch({ type: 'ERROR', errorMessage });
                } else {
                    const errorMessage = osbStep.internalErrorMessage;
                    dispatch({ type: 'ERROR', errorMessage });
                }
            });
    };

    const setAppliedVoucher = async (voucherCode: string) => {
        const vehicleData = getLightVehicleData(osbVehicleStep);
        const selectedDealerCode =
            osbDealerStep.selectedDealerProfile.dealerCode || '';
        if (vehicleData) {
            await loadServiceData(voucherCode, vehicleData, selectedDealerCode);
        }
    };

    const refreshServicesData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedServices = getVoucherAppliedSelectedServices(
            dealerServices,
            osbServiceStep.selectedServices,
            isVoucherApplied
        );

        setOSBServiceStepPayload({
            selectedServices: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedServices
            ),
        });
    };

    const refreshDeliveryData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedDeliveryOptions = getVoucherAppliedSelectedDeliveryOptions(
            dealerServices,
            osbLightDeliveryServiceStep.selectedServices,
            isVoucherApplied
        );

        setOSBLightDeliveryServiceStep({
            selectedServices: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedDeliveryOptions
            ),
        });
    };

    const refreshLocationData = (
        dealerServices: DealerServices,
        isVoucherApplied = false
    ) => {
        const voucherAppliedSelectedLocationOptions = getVoucherAppliedSelectedLocationOptions(
            dealerServices,
            osbServiceLocationDetail.selectedOtherOptions,
            isVoucherApplied
        );

        setOSBServiceLocationStepPayload({
            selectedOtherOptions: OsbUtilService.sortServicesByRank(
                voucherAppliedSelectedLocationOptions
            ),
        });
    };

    const removeVoucherDetails = async () => {
        setOSBServiceStepPayload({ voucherCode: '' });
        await setAppliedVoucher('');
        refreshServicesData(osbServiceStep.dealerServiceInfo.dealerServices);
        refreshDeliveryData(osbServiceStep.dealerServiceInfo.dealerServices);
        refreshLocationData(osbServiceStep.dealerServiceInfo.dealerServices);
        setOpenModel(false);
    };

    function getReviewBookingContent() {
        ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            'osb-review-booking'
        )
            .then(results => {
                setReviewBookingContent(results);
                setContentLoading(true);
            })
            .catch((error: any) => {
                console.log('unable to load content from AEM', error);
                setContentLoading(false);
            });
    }

    useEffect(() => {
        getReviewBookingContent();
    }, []);

    const toggleActive = (index: string) => {
        setOpenModel(false);
        const elementId = '#accordion-' + props.className + '-' + index;
        const activeEle = document.querySelector(elementId);
        if (!activeEle) {
            return;
        }

        if (activeEle.querySelector('.panel')?.classList.contains('expanded')) {
            updateAccordionState(activeEle, false, 'false', 'up', 'down');
        } else if (props.expandMultiplePanels === true) {
            triggerStickyViewSummaryLinkOnClickMobileAnalytics(
                osbVehicleStep.vehicleDetails.modelName,
                osbVehicleStep.vehicleDetails.buildDate.substring(0, 4),
                fireEvents
            );
            updateAccordionState(activeEle, true, 'true', 'down', 'up');
        } else {
            document.querySelectorAll('.accordion-section')?.forEach(value => {
                updateAccordionState(value, false, 'false', 'up', 'down');
            });
            updateAccordionState(activeEle, true, 'true', 'down', 'up');
        }
    };

    const refreshServiceTotal = () => {
        setIsServiceTotalRefreshed(!isServiceTotalRefreshed);
    };

    return (
        <div
            className={`accordion-section ${props.className}`}
            id={`accordion-${props.className}-${props.index}`}
        >
            <div
                aria-labelledby={`accordion-${props.className}-${props.index}`}
                className="panel"
            >
                <div className="osb-light-journey-mobile-booking-summary">
                    <p
                        className="close-icon"
                        onClick={() => toggleActive(props.index)}
                    >
                        &times;
                    </p>
                    <LightBookingSummary
                        refreshServiceTotal={refreshServiceTotal}
                        stepName={props.stepName}
                        showVoucherLink={true}
                        loadVoucherAlertMessage={loadVoucherAlertMessage}
                    />
                </div>
            </div>

            <div className="accordion-divider" />
            <div className="osb-mobile-summary-section">
                <div className="total-price">
                    {contentLoading
                        ? reviewBookingContent?.elements.find(
                              e =>
                                  e.name ===
                                  BOOKING_SUMMARY_CONTAINER_KEYS.TOTAL_COST_LABEL
                          )?.value + ''.toString()
                        : ''}
                    :
                    <>
                        <div className="totalPriceValue">
                            {calculateTotalPriceSummary(
                                osbStep,
                                osbServiceStep,
                                osbLightDeliveryServiceStep.selectedServices,
                                osbServiceLocationDetail.selectedOtherOptions,
                                potentialSavingAmount
                            )}
                        </div>
                        {potentialSavingAmount > 0 && (
                            <div className="discountedPrice">
                                {getActualPrice(
                                    potentialSavingAmount + '',
                                    osbServiceStep.totalPrice,
                                    osbStep
                                )}
                            </div>
                        )}
                    </>
                </div>
                <div className="summary-button-section">
                    <a
                        className="view-summary"
                        onClick={() => toggleActive(props.index)}
                    >
                        {contentLoading
                            ? reviewBookingContent?.elements.find(
                                  e =>
                                      e.name ===
                                      BOOKING_SUMMARY_CONTAINER_KEYS.VIEW_SUMMARY_LABEL
                              )?.value + ''.toString()
                            : ''}
                    </a>
                    {props.showVoucherLink ||
                    osbServiceStep.voucherCode !== '' ? (
                        <span>|</span>
                    ) : (
                        <span></span>
                    )}
                    {osbServiceStep.voucherCode !== '' ? (
                        <div
                            className="light-remove-voucher-link"
                            onClick={removeVoucherDetails}
                        >
                            {contentLoading
                                ? reviewBookingContent?.elements.find(
                                      e =>
                                          e.name ===
                                          OSB_LIGHTT_VOUCHER_KEYS.REMOVE_VOUCHER
                                  )?.value + ''.toString()
                                : ''}
                        </div>
                    ) : (
                        <div
                            className="add-voucher"
                            onClick={() => openModelData()}
                        >
                            {contentLoading && props.showVoucherLink
                                ? reviewBookingContent?.elements.find(
                                      e =>
                                          e.name ===
                                          BOOKING_SUMMARY_CONTAINER_KEYS.ADD_VOUCHER_LABEL
                                  )?.value + ''.toString()
                                : ''}
                            {openModel ? (
                                <div>
                                    <LightJourneyVouchers
                                        openPopupData={openModel}
                                        setStatus={setStatus}
                                        refreshBookingSummary={
                                            props.refreshBookingSummary
                                        }
                                        refreshServicesData={
                                            refreshServicesData
                                        }
                                        refreshDeliveryData={
                                            refreshDeliveryData
                                        }
                                        refreshLocationData={
                                            refreshLocationData
                                        }
                                        loadVoucherAlertMessage={
                                            loadVoucherAlertMessage
                                        }
                                    />
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default BookingSummaryAccordion;
