import { HttpsProxyAgent } from 'https-proxy-agent';
import { BRIGHTCOVE_API_PATH } from '../../constants';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import HttpService from '../http-service/http-service';

const httpsAgent = new HttpsProxyAgent(
    process.env.http_proxy || 'http://internet.ford.com:83'
);
export default class BrightCoveService {
    appConfig = new AppConfigurationService();

    async getVideoDetails(videoId: string, accountId: string) {
        const videoUrl =
            BRIGHTCOVE_API_PATH.BRIGHTCOVE_BASE_URL +
            `accounts/${accountId}/videos/${videoId}`;

        try {
            const { data } = await HttpService.get<any>(
                encodeURI(videoUrl),
                false,
                {
                    httpsAgent,
                    proxy: false,
                    headers: this.getRequestHeaders(accountId),
                }
            );
            return data;
        } catch (e) {
            console.error('getVideoDetails error - ', e);
            throw e;
        }
    }

    getRequestHeaders(accountId: string) {
        const accPolicyKeyMap = new Map<string, string>();
        accPolicyKeyMap.set(
            '4095259350001',
            'BCpkADawqM1d3a7tcVjzQqdfAoA5Vl5VyRKjICg-4dFpTEyZUprRsSXkEaot3ZF3siXXXzs2aA-N1PFv5KanxPGRqRpoi5aeFU4Oqp5rxls7qDOc4Ju6W9N8i22i4kKMqr6LT2acyM2Kd9iK'
        );
        accPolicyKeyMap.set(
            '2379864788001',
            'BCpkADawqM1rQr3Urh2z5hoSLv5N32pZ7nC3_GSxVVs_wvNgh2DajDHI85-7uDkLnG-fTb50NxSz-CctwLIv_lv_gWgj_4LPSU_v3zqSFrea5h03BV2lS_9Q2FZLRjZ32ENQ2ZSr6PMmPrek'
        );
        accPolicyKeyMap.set(
            '4095259385001',
            'BCpkADawqM0cwE97WjNh0cWLDG1OKHsMXRK7cy2VEi6qri9EikPKazM9dc7Lasza8lX5leRzSUi3rc0O5MaS661B_7ul18eVvfOUbDgk4XRCh4csSlvFCMDwo47GQP4dAvE6MUvV2E7aM7wN'
        );
        accPolicyKeyMap.set(
            '4095259376001',
            'BCpkADawqM2SDGiLErI0o6yRPumKkXdCw7cFcuCrviOBVUFjjOxaMS3dt3eclHMjoI-pyLdyS-DPjfEbHpib4Pwy7cHXNuLTPG20fHeN8GhD_L1CoFBzLJj98ouT6PyVO8ZuC4uv8TQG8CdZ'
        );
        accPolicyKeyMap.set(
            '4095259380001',
            'BCpkADawqM1MT8oJlvi6HzjzYYTtIX1RE9yns97JVamo853hIXCt85ZAgqy5pQ2wk3LO9nyRQ4k_sazfGGTMAju1xmVrk6Zcv9C8eSrWe01FPdAoJ4uafJL8z6vOkJH9bRp0I1I_m87jkGES'
        );
        accPolicyKeyMap.set(
            '4095259377001',
            'BCpkADawqM0-5hw9ysU-LXVrtea5KfjMibn_JuzJpf-B8BXbWr892fM0Gr1exIfp8o6Vb6PYjZC5Z8SJ0ZzYzoyTNNunncd4sH1DRc_FP467Ls-3NtMKNvR9vgp5F5kp0GZbBM3BudryXAPg'
        );
        accPolicyKeyMap.set(
            '4095259378001',
            'BCpkADawqM13-zgwd-Dr9jQlkeMFxfmhzKIrE9O8v6AeLRus8gx2d7kmXF2JNEgJy5G3bUcD7Fnu66fyFDrOr4h0RacNXm-eGPl0ecPMwXwkTA_d9zzzYXkCT57izkejDqh5aeiv9a_X5LK0'
        );
        accPolicyKeyMap.set(
            '4082198820001',
            'BCpkADawqM036px3GMPELHckp4YR-unUWvOQiie8yLA7pAiYP4PSJoxsTkERuhVFZTZyPXuGtzIhPDzskVF0n96bBCCLXmfpoCheZth-MzNOB5rGXrUDuW0EElcvzFvL8rWPtKe8fZhh0KGy'
        );
        accPolicyKeyMap.set(
            '4095259375001',
            'BCpkADawqM2b5hGY27rSSfMYk_qu3puiiOx_sDqQXVV1NLFtK09oKypjKZXDEkb4xGAiJrE8yGubMQuycpWUTeZ29ex196Eca8CYklI62ILzhf6yIS1VhHB4ZLO5Ze4NeMLhnpaVu6k1w2PM'
        );
        accPolicyKeyMap.set(
            '2270581146001',
            'BCpkADawqM1JAmDDhBpiM4mqtwbF_wghv7OPV9rJqj-mKYL9tHkWL5uV0X7xp99wt-RSFgXTyLhs2Fr6kXugHPsCilB1NJIbLW3hIInk0xbs-Q14cPIdcIQ9TwkkWDCudk-LUbCVjTjy4eOZ'
        );
        accPolicyKeyMap.set(
            '4103911217001',
            'BCpkADawqM11QBzRbKT9ygs4JODCJJchOKD6nTZbPVFyy_w6qRUPdQbux2d0PN_aH0-gvuO1mlz_hHCUNykKwxRLBM-nnYRfeQq4cliHZCvp6rbySPO_hoq8prMxVd700qpQZ2SBSCd4Pm93'
        );
        const policyKey = accPolicyKeyMap.get(accountId);
        return {
            Accept: `application/json;pk=${policyKey}`,
        };
    }
}
