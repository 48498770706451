import React from 'react';
import { Link } from '../../../../../common';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { VideoQuietCard } from '../../../../../../models/experiencefragments/category-view';
import { NewAnalyticsService } from '../../../../../../services/new-analytics-service/new-analytics-service';
import { PreImagineFdsVideoContent } from './preimagine-fds-video-content/preimagine-fds-video-content';

import './preimagine-category-video-quiet-card.scss';
import { FMButton } from '@own/fds-react';
import { transformRelativeUrlValidateRoot } from '../../../../../utils/router-util/router-util';
interface Props extends VideoQuietCard {
    class?: string;
    root?: string;
}

export const PreImagineCategoryVideoQuietCard = (props: Props) => {
    const {
        title,
        description,
        appleCta,
        appleAriaLabel,
        appleAlt,
        googleCta,
        googleAriaLabel,
        googleAlt,
        showButtonCTA,
        buttonCta,
        buttonTitle,
        buttonAriaLabel,
        buttonCtaTarget,
        imageOrVideo,
        videoId,
        videoAccountId,
        videoDataPlayer,
        imageAltText,
    } = props?.videoQuietCardDetails ?? {};
    const transformedCTA = transformRelativeUrlValidateRoot(
        buttonCta ?? '',
        props?.root ?? ''
    );
    const appleIconPath = `${process.env.REACT_APP_AEM_BASE_URL}${props?.videoQuietCardDetails?.appleIconPath}`;
    const googleIconPath = `${process.env.REACT_APP_AEM_BASE_URL}${props?.videoQuietCardDetails?.googleIconPath}`;
    const image = `${process.env.REACT_APP_AEM_BASE_URL}${props?.videoQuietCardDetails?.image}`;
    const videoQuietCardMain = [
        'preimagine-video-quiet-card',
        props?.class ? props?.class?.trim() : '',
    ]
        .join(' ')
        .trim();

    const [fireEvents] = useAnalytics();

    return (
        <article className={videoQuietCardMain}>
            <section className="preimagine-video-quiet-card__details">
                <h2 className="preimagine-video-quiet-card__title">{title}</h2>
                <section className="preimagine-video-quiet-card__description">
                    {description}
                </section>
                <section className="preimagine-video-quiet-card__mobile-app">
                    {appleCta && (
                        <Link
                            href={appleCta}
                            target="_blank"
                            aria-label={appleAriaLabel}
                        >
                            <img src={appleIconPath} alt={appleAlt} />
                        </Link>
                    )}
                    {googleCta && (
                        <Link
                            href={googleCta}
                            target="_blank"
                            aria-label={googleAriaLabel}
                        >
                            <img src={googleIconPath} alt={googleAlt} />
                        </Link>
                    )}
                </section>
                {showButtonCTA && (
                    <FMButton
                        className="preimagine-cta-optional__primary-button"
                        type={'primary'}
                        chevron={'right'}
                        theme={'light'}
                        href={transformedCTA}
                        label={buttonTitle}
                        ariaLabel={buttonAriaLabel}
                        onClick={() =>
                            buttonCta &&
                            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                buttonCta,
                                fireEvents
                            )
                        }
                        target={buttonCtaTarget ?? '_blank'}
                    />
                )}
            </section>
            <section className="preimagine-video-quiet-card__media">
                {imageOrVideo === 'Video' &&
                    videoId &&
                    videoAccountId &&
                    videoDataPlayer && (
                        <PreImagineFdsVideoContent
                            videoId={videoId}
                            videoAccountId={videoAccountId}
                            videoDataPlayer={videoDataPlayer}
                        />
                    )}
                {imageOrVideo === 'Image' && image && (
                    <img src={image} alt={imageAltText} />
                )}
            </section>
        </article>
    );
};
