import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { RetrieveBookingStep } from '../../models/osb-model/osb-retrieve-booking-step';
import {
    StoreState,
    RetrieveBookingStepState,
    StorePayload,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { useOSBStep } from './use-osb-step';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';

export const getInitialRetrieveBookingState = (): RetrieveBookingStep => {
    return {
        isRetrieveBookingStep: false,
        retrieveBookingDetails: {
            bookings: [
                {
                    appointmentDateTimeLocal: '',
                    dealer: {
                        street: '',
                        dealerName: '',
                        dealerCode: '',
                        district: '',
                        phone: '',
                        openingHours: [],
                        town: '',
                        country: '',
                        postalCode: '',
                        email: '',
                        vehicleDeliveryTypes: [],
                    },
                    bookingReferenceNumber: '',
                    comments: '',
                    source: '',
                    oldServices: [
                        {
                            serviceId: 0,
                            serviceCode: '0',
                            serviceType: '',
                            name: '',
                            serviceName: '',
                            price: 0.0,
                            priceAfterDiscount: 0.0,
                            additionalInfo: {
                                offer: '',
                                subType: '',
                            },
                            description: '',
                            isChecked: false,
                            type: 'VALUE',
                            length: 0,
                        },
                    ],
                    additionalServices: [
                        {
                            serviceId: 0,
                            serviceCode: '0',
                            serviceType: '',
                            name: '',
                            serviceName: '',
                            price: 0.0,
                            priceAfterDiscount: 0.0,
                            additionalInfo: {
                                offer: '',
                                subType: '',
                            },
                            description: '',
                            isChecked: false,
                            type: 'ADDITIONAL',
                            length: 0,
                        },
                    ],
                    vehicleDetails: {
                        bodyStyle: '',
                        buildDate: '',
                        color: '',
                        engine: '',
                        fuelType: '',
                        mileage: '',
                        modelName: '',
                        registrationNumber: '',
                        transmission: '',
                        transmissionType: '',
                        vehicleLineCode: '',
                        version: '',
                        vin: '',
                    },
                    mainService: {
                        serviceId: 0,
                        serviceCode: '0',
                        serviceType: '',
                        name: '',
                        serviceName: '',
                        price: 0.0,
                        priceAfterDiscount: 0.0,
                        additionalInfo: {
                            offer: '',
                            subType: '',
                        },
                        serviceOption: '',
                        description: '',
                        isChecked: false,
                        length: 0,
                        type: '',
                    },
                    vehicleLineDescription: '',
                    customer: {
                        lastName: '',
                        title: '',
                        fordId: '',
                        contactNumber: '',
                        firstName: '',
                        email: '',
                        phone: '',
                    },
                    accesscode: '',
                },
            ],
        },
    };
};
//Configure and initialize calendar step state in global store
const configureRetriveBookingStepStore = () => {
    const actions = {
        //Action to set the manual calendar step details to global store
        SET_RETRIEVE_BOOKING_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as RetrieveBookingStepState)
                    .retrieveBookingStep,
                ...(payload as RetrieveBookingStep),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_RETRIEVE_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { retrieveBookingStep: newState };
        },
    };
    initializeStore(actions, {
        retrieveBookingStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_RETRIEVE_STEP_KEY
            ) || getInitialRetrieveBookingState(),
    });
};

configureRetriveBookingStepStore();

// custom hook for manual calendar step to handle the actions and updating payload in global store
export const useRetrieveBookingStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalRetrieveBookingStep = (
        retrieveBookingStep: RetrieveBookingStep,
        shouldReload = true
    ) => {
        dispatch(
            'SET_RETRIEVE_BOOKING_STEP',
            retrieveBookingStep,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetRetrieveBookingStep = (shouldReload = true) => {
        dispatch(
            'SET_RETRIEVE_BOOKING_STEP',
            getInitialRetrieveBookingState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbRetrieveBookingStep: (globalState as RetrieveBookingStepState)
            .retrieveBookingStep,
        setOSBRetrieveBookingStep: setGlobalRetrieveBookingStep,
        resetRetrieveBookingStep: resetRetrieveBookingStep,
        callContentService: callContentService,
    };
};
