import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';

export interface VehicleEngineType {
    isBev?: boolean;
}

export default class VehicleEngineTypeService {
    async request(vin: string): Promise<VehicleEngineType> {
        const keyType = '?keys=engineType';
        const params = {
            vin,
        };
        const headers = HttpService.getAppIdRequestHeaders();
        const vehicleProfileUrl =
            DSL_BASE_URL + DSL_API_PATH.VEHICLE_PROFILE + keyType;
        const response = HttpService.get<VehicleEngineType>(
            vehicleProfileUrl,
            true,
            {
                params,
                headers,
            }
        );
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(
        response: AxiosResponse<VehicleEngineType>
    ): VehicleEngineType {
        if (response.data) {
            return response.data;
        } else {
            return {
                isBev: false,
            };
        }
    }
}
