export const classes = {
    COMPONENT: 'js-fds-segmented-control',
    BUTTON_LIST_FLEX_CONTAINER:
        'fds-segmented-control__button-list-flex-container',
    BUTTON_LIST: 'fds-segmented-control__button-list',
    BUTTON: 'fds-segmented-control__button',
    BUTTON_TEXT: 'fds-segmented-control__button-text',
    SLIDER: 'fds-segmented-control__slider',
    SLIDER_TEXT: 'fds-segmented-control__slider-text',
    PANEL_SHOW: 'fds-segmented-control__panel--show',
    FOCUSED: 'fds-segmented-control__button--focused',
    MOBILE: 'fds-segmented-control__mobile',
};

export const attributes = {
    ARIA_PRESSED: 'aria-pressed',
    ARIA_CONTROLS: 'aria-controls',
    TABINDEX: 'tabindex',
};

export const strings = {
    ICON_CLASS: 'fds-font--ford-icons',
};
