import { useState } from 'react';

export function useShortcodeProvider<T>(
    resolveWith?: T
): [Promise<T>, (value: T) => void, (reason: string) => void] {
    const [providerState, setProviderState] = useState<
        [Promise<T>, (value: T) => void, (reason: string) => void]
    >();
    if (providerState) {
        return providerState;
    } else {
        let resolve: (value: T) => void = () => {
            return;
        };
        let reject: (reason: string) => void = () => {
            return;
        };
        if (resolveWith) {
            setProviderState([Promise.resolve(resolveWith), resolve, reject]);
            return [Promise.resolve(resolveWith), resolve, reject];
        } else {
            setProviderState([
                new Promise((resolvePromise, rejectPromise) => {
                    resolve = resolvePromise;
                    reject = rejectPromise;
                }),
                resolve,
                reject,
            ]);
            return [
                new Promise((resolvePromise, rejectPromise) => {
                    resolve = resolvePromise;
                    reject = rejectPromise;
                }),
                resolve,
                reject,
            ];
        }
    }
}
