import React, { useContext } from 'react';
import { useExperienceContent } from '../../../../hooks/use-server-content';
import { GlobalMXandEUFooterFragment } from '../../../../models/experiencefragments/global-mx-eu-footer';
import { Link } from '../../../common';
import GlobalMXandEUFooterAccordion from './global-mx-eu-footer-accordion';
import { EU_COUNTRIES, BRAND } from '../../../../constants';
import ServerContext from '../../../../contexts/serverContext';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { HeaderFragment } from '../../../../models/experiencefragments/header';
import { NewAnalyticsService } from '../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { SubnavMobile } from '../../header/subnav/subnav';
import { FooterFragment } from '../../../../models/experiencefragments/footer';
import Footer from '../footer';
import FeedbackIcon from '../../../../assets/feedback.svg';
import './global-mx-eu-footer.scss';

const GlobalMXandEUFooter = () => {
    const MEDILLIA_CONST = 'KAMPYLE_ONSITE_SDK';
    const [globalFooterContent] = useExperienceContent<
        GlobalMXandEUFooterFragment
    >('common', 'header-footer', 'globalfooter');
    const [footerContent] = useExperienceContent<FooterFragment>(
        'common',
        'header-footer',
        'footer'
    );
    const [euHeaderContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );
    const serverContext = useContext(ServerContext);
    const appConfiguration = new AppConfigurationService();
    const { currentRegionCode } = appConfiguration;

    const [brand] = [
        serverContext ? serverContext.brand : BRAND.lincoln.LONG_NAME,
    ];
    const [country] = [
        serverContext ? serverContext.currentRegionCode : currentRegionCode,
    ];
    const [fireEvents] = useAnalytics();

    const medalliaCommentCardId = appConfiguration?.getAppConfiguration()
        ?.medalliaCommentCardId;

    const tertiaryLinks = globalFooterContent?.tertiarySection?.tertiaryLinks?.filter(
        link => !link?.url?.includes(MEDILLIA_CONST)
    );
    const medalliaLinks = globalFooterContent?.tertiarySection?.tertiaryLinks?.filter(
        link => link?.url?.includes(MEDILLIA_CONST)
    );

    return (
        <>
            {globalFooterContent ? (
                <footer
                    className={`${
                        EU_COUNTRIES.includes(country)
                            ? 'global-eu-footer'
                            : 'global-mx-footer'
                    }`}
                >
                    <div className="footer-list-wrapper">
                        <div className="footer-list-accordion">
                            {globalFooterContent &&
                                globalFooterContent.categories.map(
                                    (footerNavigation, index) => {
                                        return (
                                            <GlobalMXandEUFooterAccordion
                                                key={index}
                                                footerNavigation={
                                                    footerNavigation
                                                }
                                                country={country}
                                            />
                                        );
                                    }
                                )}
                        </div>
                        {globalFooterContent &&
                            !EU_COUNTRIES.includes(country) && (
                                <div className="footer-list-mobile">
                                    <SubnavMobile
                                        isHeader={false}
                                        drawers={globalFooterContent.categories}
                                    />
                                </div>
                            )}
                    </div>
                    {country === 'mx' &&
                        brand === 'lincoln' &&
                        !EU_COUNTRIES.includes(country) && (
                            <section className="footer-bar">
                                <div className="footer-bar-wrapper">
                                    <div className="social-wrapper">
                                        <ul className="social-lists">
                                            {globalFooterContent &&
                                                globalFooterContent.socialMediaSection.socialMediaLinks.map(
                                                    (link, index) => (
                                                        <li key={index}>
                                                            <Link
                                                                href={link.url}
                                                                className="social-media-link"
                                                                aria-label={
                                                                    link.ariaLabel
                                                                }
                                                                target={
                                                                    link.targetBlank
                                                                        ? '_blank'
                                                                        : '_self'
                                                                }
                                                            >
                                                                <img
                                                                    src={`${process.env.REACT_APP_AEM_BASE_URL}${link.logoPath}`}
                                                                    alt={
                                                                        link.ariaLabel
                                                                    }
                                                                />
                                                            </Link>
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                    <hr />
                                    <div className="footer-disclaimer">
                                        <ul className="disclaimer-lists">
                                            {tertiaryLinks &&
                                                tertiaryLinks.map(
                                                    (link, index) => (
                                                        <li key={index}>
                                                            {link.url ? (
                                                                <Link
                                                                    href={
                                                                        link.url
                                                                    }
                                                                    className="disclaimer-link"
                                                                    aria-label={
                                                                        link.ariaLabel
                                                                    }
                                                                    target={
                                                                        link.targetBlank
                                                                            ? '_blank'
                                                                            : '_self'
                                                                    }
                                                                >
                                                                    {link.title}
                                                                </Link>
                                                            ) : (
                                                                <p className="disclaimer-text">
                                                                    {link.title}
                                                                </p>
                                                            )}
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        )}
                    {EU_COUNTRIES.includes(country) && (
                        <section className="eu-new-footer-bar">
                            <div className="eu-box-footer-bar-wrapper">
                                <div className="eu-footer-social-wrapper">
                                    <ul className="eu-footer-social-lists">
                                        {globalFooterContent &&
                                            globalFooterContent.socialMediaSection.socialMediaLinks.map(
                                                (link, index) => (
                                                    <li key={index}>
                                                        <Link
                                                            href={link.url}
                                                            className="social-media-link"
                                                            aria-label={
                                                                link.ariaLabel
                                                            }
                                                            target={
                                                                link.targetBlank
                                                                    ? '_blank'
                                                                    : '_self'
                                                            }
                                                        >
                                                            <img
                                                                src={`${process.env.REACT_APP_AEM_BASE_URL}${link.logoPath}`}
                                                                alt={
                                                                    link.ariaLabel
                                                                }
                                                            />
                                                        </Link>
                                                    </li>
                                                )
                                            )}
                                    </ul>
                                </div>
                            </div>
                            <div className="eu-box-footer-secondary-links">
                                <div className="eu-footer-secondary-links">
                                    <ul className="eu-secondary-links-list">
                                        {medalliaLinks?.map(link => (
                                            <>
                                                <li key={link.title}>
                                                    <Link
                                                        href={undefined}
                                                        className="eu-tertiary-link"
                                                        aria-label={
                                                            link.ariaLabel
                                                        }
                                                        target={
                                                            link.targetBlank
                                                                ? '_blank'
                                                                : '_self'
                                                        }
                                                        onClick={() => {
                                                            const win = window as any;
                                                            medalliaCommentCardId &&
                                                                win.KAMPYLE_ONSITE_SDK.showForm(
                                                                    medalliaCommentCardId
                                                                );
                                                        }}
                                                    >
                                                        <span className="icon">
                                                            <img
                                                                src={
                                                                    FeedbackIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </span>
                                                        <span className="text">
                                                            {link.title}
                                                        </span>
                                                    </Link>
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="eu-box-footer-copyrights-and-links">
                                <div className="eu-footer-tertiary-links">
                                    <ul className="eu-tertiary-links-list">
                                        {tertiaryLinks?.map(link => (
                                            <li key={link.title}>
                                                {link.url ? (
                                                    <Link
                                                        href={link.url}
                                                        className="eu-tertiary-link"
                                                        aria-label={
                                                            link.ariaLabel
                                                        }
                                                        target={
                                                            link.targetBlank
                                                                ? '_blank'
                                                                : '_self'
                                                        }
                                                    >
                                                        {link.title}
                                                    </Link>
                                                ) : (
                                                    <p className="eu-footer-disclaimer-text">
                                                        {link.title}
                                                    </p>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {euHeaderContent && (
                                    <a
                                        tabIndex={0}
                                        href={euHeaderContent.logo.url}
                                        onClick={() =>
                                            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                                euHeaderContent.logo.url,
                                                fireEvents
                                            )
                                        }
                                        target={
                                            euHeaderContent.logo.targetBlank
                                                ? '_blank'
                                                : '_self'
                                        }
                                        className="eu-logo-link"
                                        aria-label={
                                            euHeaderContent.logo.ariaLabel
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            style={{
                                                backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${euHeaderContent.logo.logoPath})`,
                                            }}
                                            className={'eu-ford-logo'}
                                        />
                                    </a>
                                )}
                            </div>
                        </section>
                    )}
                </footer>
            ) : (
                footerContent && !globalFooterContent && <Footer />
            )}
        </>
    );
};

export default GlobalMXandEUFooter;
