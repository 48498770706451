import { useCallback } from 'react';
import { initializeStore, useStore } from '../../store/osb-store/global-store';
import { ReviewBooking } from '../../models/osb-model/osb-review-booking';
import {
    StoreState,
    StorePayload,
    ReviewBookingStepState,
} from '../../models/osb-model/osb-store-details';
import ServiceHandler from '../../services/service-handler';
import OsbUtilService from '../../services/osb-service/osb-util-service/osb-util-service';
import { useOSBStep } from './use-osb-step';
import {
    getClientStorageValue,
    setClientStorageValue,
} from '../../components/sections/owners-osb/osb-utils';
import { OSB_CLIENT_STORAGE_KEYS } from '../../components/sections/owners-osb/osb-constant';

export const getInitialReviewBookingState = (): ReviewBooking => {
    return {
        accessCode: '',
        bookingReferenceNumber: '',
        identifier: '',
    };
};
//Configure and initialize booking credentials step state in global store
const configureReviewBookingStepStore = () => {
    const actions = {
        //Action to set the booking credentials to global store
        SET_REVIEW_BOOKING_STEP: (
            currentState: StoreState,
            payload: StorePayload,
            localStorageExpiry: number
        ): StoreState => {
            const newState = {
                ...(currentState as ReviewBookingStepState).reviewBookingStep,
                ...(payload as ReviewBooking),
            };
            setClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_REVIEW_STEP_KEY,
                newState,
                localStorageExpiry
            );
            return { reviewBookingStep: newState };
        },
    };
    initializeStore(actions, {
        reviewBookingStep:
            getClientStorageValue(
                OSB_CLIENT_STORAGE_KEYS.OSB_REVIEW_STEP_KEY
            ) || getInitialReviewBookingState(),
    });
};

configureReviewBookingStepStore();

// custom hook for manual personal detail step to handle the actions and updating payload in global store
export const useReviewBookingStep = () => {
    const { globalState, dispatch } = useStore();
    const { osbStep } = useOSBStep();

    const setGlobalBookingCredentials = (
        bookingCredentials: ReviewBooking,
        shouldReload = true
    ) => {
        dispatch(
            'SET_REVIEW_BOOKING_STEP',
            bookingCredentials,
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const resetBookingCredentials = (shouldReload = true) => {
        dispatch(
            'SET_REVIEW_BOOKING_STEP',
            getInitialReviewBookingState(),
            shouldReload,
            osbStep.localStorageExpiry
        );
    };

    const callContentService = useCallback(async (modelName: string) => {
        return await ServiceHandler.OsbContentService.getOsbContentModel(
            OsbUtilService.getAppConfiguration().brand,
            OsbUtilService.getAppConfiguration().countryCode,
            OsbUtilService.getAppConfiguration().languageRegionCode,
            modelName
        );
    }, []);

    return {
        osbReviewBooking: (globalState as ReviewBookingStepState)
            .reviewBookingStep,
        setOSBReviewDetail: setGlobalBookingCredentials,
        resetReviewBookingStep: resetBookingCredentials,
        callContentService: callContentService,
    };
};
