import { SyncMapsCard, SyncMapStatus } from '../use-syncs-maps-status-updates';

export const getMapCards = (
    syncMapContent: SyncMapStatus | null,
    mapsOption: string,
    syncGeneration: string,
    desktopOrLarger: boolean,
    isVersion34: boolean
): SyncMapsCard[] | undefined => {
    const mapSyncGen = syncGeneration === '5' ? '4' : syncGeneration;
    const isMobileCardCondition = (card: SyncMapsCard) =>
        desktopOrLarger ? !card.isMobileCard : card.isMobileCard;
    return (
        syncMapContent?.mapsCards &&
        (isVersion34
            ? syncMapContent?.mapsCards
                  .filter(card => card.syncMapsCardType === mapsOption)
                  .filter(card =>
                      card.syncMapsCardType === 'usb'
                          ? isMobileCardCondition(card)
                          : card.syncMapsCardType === mapsOption
                  )
            : syncMapContent?.mapsCards.filter(
                  card => card.syncGeneration === mapSyncGen
              ))
    );
};
