import React from 'react';
import './site-search.scss';
import { SearchInputGroup } from './search-input-group/search-input-group';
import { Search } from '../../../models/experiencefragments/header';

import { AppConfigurationService } from '../../../services/app-configuration-service/app-configuration-service';

const closeIcon = './icons/close.svg';

interface Props {
    content: Search;
    isModalOpen?: Function;
}
export const SiteSearch = (props: Props) => {
    const { brand } = new AppConfigurationService();
    const currentAppConfiguration = new AppConfigurationService().getAppConfiguration();
    const toggleSearch = (show: boolean) => {
        const searchInputDiv = document.querySelector(
            '.input-container'
        ) as HTMLDivElement;
        if (searchInputDiv)
            show
                ? searchInputDiv.classList.remove('hide-me')
                : searchInputDiv.classList.add('hide-me');
    };

    return (
        <>
            {props.content.url && (
                <div className="site-search-container">
                    <button
                        onClick={() => {
                            toggleSearch(true);
                            props.isModalOpen && props.isModalOpen();
                        }}
                        data-testid="search-icon"
                        aria-label="Search Icon"
                    >
                        <span
                            className={`${
                                brand === 'lincoln' ? '' : 'search-icon'
                            } icon icon-search-glass-25px `}
                        ></span>
                        {currentAppConfiguration.fmaRegion === 'eu' && (
                            <span className="search-text">
                                {props.content.title}
                            </span>
                        )}
                    </button>
                    <div
                        className="input-container hide-me"
                        data-testid="input-container"
                    >
                        <div className="input">
                            <button
                                className="close"
                                onClick={() => {
                                    toggleSearch(false);
                                    props.isModalOpen && props.isModalOpen();
                                }}
                                data-testid="close"
                                aria-label="Close Search"
                            >
                                <img src={closeIcon} alt="close"></img>
                            </button>
                            <SearchInputGroup
                                content={props.content}
                            ></SearchInputGroup>
                        </div>
                        <div
                            className="overlay"
                            onClick={() => toggleSearch(false)}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
