import { useState, useEffect } from 'react';

export interface Breakpoints {
    mobile: boolean;
    tabletOrLarger: boolean;
    desktopOrLarger: boolean;
    xlDesktopOrLarger: boolean;
    xxlDesktopOrLarger: boolean;
}

export function useBreakpoints(): Breakpoints {
    const isServerSide = () => {
        return typeof window === 'undefined';
    };

    function calcBreakpoints(): Breakpoints {
        return {
            mobile: !isServerSide()
                ? !!window.matchMedia('(max-width: 767px)').matches
                : false,
            tabletOrLarger: !isServerSide()
                ? !!window.matchMedia('(min-width: 768px)').matches
                : false,
            desktopOrLarger: !isServerSide()
                ? !!window.matchMedia('(min-width: 992px)').matches
                : false,
            xlDesktopOrLarger: !isServerSide()
                ? !!window.matchMedia('(min-width: 1700px)').matches
                : false,
            xxlDesktopOrLarger: !isServerSide()
                ? !!window.matchMedia('(min-width: 3000px)').matches
                : false,
        };
    }

    const [breakpoints, setBreakpoints] = useState<Breakpoints>(
        calcBreakpoints()
    );

    const handleWindowResize = () => {
        setBreakpoints(calcBreakpoints());
    };

    useEffect(() => {
        if (!isServerSide()) {
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
    }, []);

    return breakpoints;
}
