import { useContent } from '../../../../hooks/use-server-content';
export interface ConfirmModalProps {
    title: string;
    body: string;
    primaryButtonLabel: string;
    secondaryButtonLabel: string;
    ariaLabel: string;
}
export const useConfirmModalContent = (): ConfirmModalProps | null => {
    const [content, getValueByName] = useContent('common', 'confirm-modal');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.title) {
            contentFragment = null;
        }
    }
    return contentFragment as ConfirmModalProps;
};
