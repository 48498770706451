import React from 'react';
import { useHistory } from 'react-router-dom';
import { SecondaryButton } from '../../../../common';
import { UTE_ROUTES } from '../types';
import './ute-faqs.ford.scss';
import { useContentFragment } from '../../../../utils/aem-content-utils/use-content-fragment';
import { FaqsContentProps } from '../../../../../models/service-maintenance-models/ute';
interface Props {
    profileContent: any;
}
export const UteFaqs = (props: Props) => {
    const uteFaqContent: FaqsContentProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/faqs'
    );
    const { pageTitle } = props.profileContent;
    const {
        faqTitle,
        comingSoonLabel,
        faqDescription,
        backCTA,
    } = uteFaqContent;
    const history = useHistory();
    return (
        <>
            <section className="ute-faq-wrapper-section">
                <section className="ute-faq-heading-section">
                    <h1 className="ute-header">{faqTitle}</h1>
                </section>
                <section className="ute-faq-details-section">
                    <div className="coming-soon">{comingSoonLabel}</div>
                    <div
                        className="description"
                        dangerouslySetInnerHTML={{
                            __html: faqDescription,
                        }}
                    ></div>
                    <div className="back-btn">
                        <SecondaryButton
                            className="faq-back-cta"
                            direction="left"
                            onClick={() => {
                                history.push({
                                    pathname: UTE_ROUTES.HOME,
                                    state: {
                                        pageTitle: pageTitle,
                                    },
                                });
                            }}
                        >
                            {backCTA}
                        </SecondaryButton>
                    </div>
                </section>
            </section>
        </>
    );
};
