import React from 'react';
import './booking-confirmation-view.scss';
import {
    useCalendarStep,
    useDealerStep,
    useLightDeliveryOptionServiceStep,
    useOSBStep,
    useReviewBookingStep,
    useServiceStep,
    useVehicleStep,
    useServiceLocationStep,
} from '../../../hooks/owners-osb';
import { LightBookingConfirmation } from '../../../components/sections/owners-osb/booking-confirmation/light-journey/light-booking-confirmation';

export function BookingConfirmationView() {
    const { osbServiceLocationDetail } = useServiceLocationStep();
    const { osbCalendarStep } = useCalendarStep();
    const { osbDealerStep } = useDealerStep();
    const { osbServiceStep } = useServiceStep();
    const { osbLightDeliveryServiceStep } = useLightDeliveryOptionServiceStep();
    const { osbStep } = useOSBStep();
    const { osbVehicleStep } = useVehicleStep();
    const { osbReviewBooking } = useReviewBookingStep();
    // Read below values from session storage in future
    const selectedDate = osbCalendarStep.selectedDate || '';
    const selectedTime = osbCalendarStep.selectedTime || '';
    const bookingNumber = osbReviewBooking.bookingReferenceNumber;
    const accessCode = osbReviewBooking.accessCode;

    const selectedServicesInfo = osbServiceStep.selectedServices;
    const selectedDeliveryServices =
        osbLightDeliveryServiceStep.selectedServices;
    const selectedLocationServices =
        osbServiceLocationDetail.selectedOtherOptions;
    const isDealerDeepLinked = osbStep.isDealerDeepLinked;

    const getDealerName = () => {
        return osbDealerStep.selectedDealerProfile.dealerName;
    };

    const getDealerAddress = () => {
        return (
            osbDealerStep.selectedDealerProfile.town +
            ' ' +
            osbDealerStep.selectedDealerProfile.street
        );
    };

    const getDealerPostalCode = () => {
        return osbDealerStep.selectedDealerProfile.postalCode;
    };
    const getSelectedServices = () => {
        return [
            ...selectedServicesInfo,
            ...selectedDeliveryServices,
            ...selectedLocationServices,
        ];
    };
    return (
        <>
            <div className="light-booking-confirmation-view-container">
                <LightBookingConfirmation
                    dealerName={getDealerName() || ''}
                    dealerAddress={getDealerAddress() || ''}
                    dealerPostalCode={getDealerPostalCode() || ''}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    bookingNumber={bookingNumber}
                    accessCode={accessCode}
                    isDealerDeepLinked={isDealerDeepLinked}
                    selectedServices={getSelectedServices() || ''}
                    osbStep={osbStep}
                    osbVehicleStep={osbVehicleStep}
                />
            </div>
        </>
    );
}
