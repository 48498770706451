import { useContent } from '../../../../hooks/use-server-content';

export interface TireFinderProps {
    hide: boolean;
    bannerTitle: string;
    bannerDescription: string;
    caoBackgroundImageDesktop: string;
    caoBackgroundImageMobile: string;
    breadcrumbTitle: string;
    breadcrumbUrl: string;
}
export const useTireFinderContent = (): TireFinderProps | null => {
    const [content, getValueByName] = useContent(
        'tirefinder',
        'tirefinder-banner'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as TireFinderProps;
};
