import React from 'react';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useSyncVehicleSelectorContent } from '../../../views/sync-maps-updates-view/hooks/use-sync-vehicle-selector';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { SyncMapStatus } from '../../../views/sync-maps-updates-view/hooks/use-syncs-maps-status-updates';

export interface Props {
    syncData: any;
    dynamicDescriptionUpToDate?: string;
    dynamicDescriptionUpdateAvailable?: string;
    tileTitle: string;
    ctaText: string;
    ctaPath: string;
}

const VehicleChecklistSync = (
    props: VehicleChecklistContent & VehicleAttributes & Props
) => {
    useSyncVehicleSelectorContent();
    const [experienceContent] = useExperienceContent<SyncMapStatus>(
        'sync',
        'sync-maps',
        'sync_maps',
        undefined,
        false
    );
    let statusText = props.staticDescription;

    const syncUpdateAvailable = !!(
        props.syncData?.syncUpdateAvailable &&
        experienceContent?.syncHeading !== undefined
    );
    if (props.syncData && props.syncData.showSyncTile) {
        if (!props.syncData?.vehicleSyncEquipped && !syncUpdateAvailable) {
            {
                statusText = props.dynamicDescriptionUpToDate as string;
            }
        } else if (props.syncData?.syncUpdateAvailable || syncUpdateAvailable) {
            {
                statusText = props.dynamicDescriptionUpdateAvailable as string;
            }
        } else {
            {
                statusText = props.dynamicDescriptionUpToDate as string;
            }
        }
    }

    return (
        <>
            <VehicleChecklist
                header={props.tileTitle}
                type={props.cardTypes}
                ctaTitle={props.ctaTitle || props.ctaText}
                description={statusText}
                iconPath={props.iconPath}
                ctaPath={props.ctaPath}
                ctaAriaLabel={props.ctaAriaLabel}
                ctaTargetBlank={props.ctaTargetBlank}
            />
        </>
    );
};
export default VehicleChecklistSync;
