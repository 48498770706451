import { AiChatFeedbackOption } from './pages/owner-manual-ai-chat/owner-manual-ai-chat';

export const SUPPORT_HUB_REGEX = {
    SPACES: /\s+/g,
    HYPHENS: /-+/g,
    DOUBLE_QUOTES: /["]/g,
    INVALID_SEARCH_CHARS: /[%<>"=\\]+/g,
    ALL_MARKUP_TAGS: /(<([^>]+)>)/g,
    FIND_FIRST_260_CHARS: /^(.{260}[^\s]*).*/,
    FIND_FIRST_110_CHARS: /^(.{110}[^\s]*).*/,
    FIND_ALL_TABS_NEW_LINE: /\n|\r|\t/g,
    NON_BREAKING_SPACE: /&nbsp;/g,
    FORWARD_SLASH: /\//g,
    PERIOD: /\./g,
    HREF_HASH: /href="#/g,
    COPYRIGHTS_TRADE_REGISTERED_MARKS: /(™|®|©|&|&trade;|&reg;|&copy;|&#8482;|&#174;|&#169;|&amp;)/g,
    INVALID_CHARS: /[*+,()'":?“ʺ]+/g,
    AMPERSAND: /&amp;/g,
    ALPHA_NUMERIC: /[^A-Za-z0-9]/g,
    VALID_VIN_REGEX: /^([a-zA-Z0-9]{17})$/,
    VALID_CAMPAIGN_ID_REGEX: /^([0-9]{2})([a-zA-Z]{1})([0-9]{2})$/,
};
export const SUPPORT_HUB_PREFIXES_NOT_FOR_SITEMAP = ['AB - ', 'AS - '];
export const SUPPORT_HUB_PREFIXES_NOT_FOR_BBT = ['AM - ', 'AS - '];
export const BRAND_ATTRIBUTES = {
    ford: {
        sitemap: ['AF - '],
        cache: ['AF - ', 'AS - ', 'AB - ', 'AM - '],
        touchpoint: '1007054935',
        searchUrl: {
            http:
                process.env.REACT_APP_ACTIVE_PROFILE &&
                ['dev', 'qa', 'test'].includes(
                    process.env.REACT_APP_ACTIVE_PROFILE
                )
                    ? 'http://use-fordservicelayer.emplifiknowledge.com/AskFordWebQA'
                    : 'https://use-fordservicelayer.emplifiknowledge.com/AskFordWeb',
            https:
                process.env.REACT_APP_ACTIVE_PROFILE &&
                ['dev', 'qa', 'test'].includes(
                    process.env.REACT_APP_ACTIVE_PROFILE
                )
                    ? 'https://use-fordservicelayer.emplifiknowledge.com/AskFordWebQA'
                    : 'https://use-fordservicelayer.emplifiknowledge.com/AskFordWeb',
        },
        bbt: ['AF - '],
        brandName: 'Ford ',
        excludeCatId: [4829],
        attributesUrl: 'https://topicsearch.emplifiknowledge.com/',
        attributesRequestHeader: {
            'X-AK-Auth': 'tx5m3f6rxsb3hcjl3904omd56vkibtcn',
        },
        SUPPORTED_MARKETS_RTE_SEARCH: [
            'en-ca',
            'fr-ca',
            'en-us',
            'en-gb',
            'en-ie',
            'es-es',
            'it-it',
            'de-de',
            'fr-fr',
            'pl-pl',
            'pt-pt',
            'de-at',
            'cs-cz',
            'hu-hu',
            'fr-be',
            'nl-be',
            'no-no',
            'nl-nl',
            'el-gr',
            'en-au',
            'en-za',
            'en-ph',
            'ro-ro',
            'fr-lu',
            'de-ch',
            'fr-ch',
            'it-ch',
            'en-nz',
            'th-th',
            'en-th',
            'vi-vn',
            'da-dk',
            'fi-fi',
        ],
        SUPPORTED_MARKETS_RTE_DISCOVERABILITY: [
            'en-us',
            'it-it',
            'es-es',
            'fr-fr',
            'en-gb',
            'de-de',
            'en-ca',
            'fr-ca',
            'en-au',
            'th-th',
            'en-th',
            'en-nz',
            'no-no',
            'nl-nl',
            'it-ch',
            'fr-ch',
            'de-ch',
            'de-at',
            'da-dk',
            'fr-be',
            'nl-be',
            'cs-cz',
            'fi-fi',
            'el-gr',
            'hu-hu',
            'pl-pl',
            'en-ie',
            'fr-lu',
            'ro-ro',
            'pt-pt',
            'en-za',
            'en-ph',
            'vi-vn',
        ],
    },
    lincoln: {
        sitemap: ['AL - '],
        cache: ['AL - ', 'AS - ', 'AB - ', 'AM - '],
        touchpoint: '1007054941',
        searchUrl: {
            http:
                process.env.REACT_APP_ACTIVE_PROFILE &&
                ['dev', 'qa', 'test'].includes(
                    process.env.REACT_APP_ACTIVE_PROFILE
                )
                    ? 'http://use-fordservicelayer.emplifiknowledge.com/AskLincolnWebQA'
                    : 'http://use-fordservicelayer.emplifiknowledge.com/AskLincolnWeb',
            https:
                process.env.REACT_APP_ACTIVE_PROFILE &&
                ['dev', 'qa', 'test'].includes(
                    process.env.REACT_APP_ACTIVE_PROFILE
                )
                    ? 'https://use-fordservicelayer.emplifiknowledge.com/AskLincolnWebQA'
                    : 'https://use-fordservicelayer.emplifiknowledge.com/AskLincolnWeb',
        },
        bbt: ['AL - '],
        brandName: 'Lincoln ',
        excludeCatId: [4843],
        attributesUrl: 'https://topicsearch.emplifiknowledge.com/',
        attributesRequestHeader: {
            'X-AK-Auth': 'tx5m3f6rxsb3hcjl3904omd56vkibtcn',
        },
        SUPPORTED_MARKETS_RTE_SEARCH: ['en-us', 'en-ca', 'fr-ca'],
        SUPPORTED_MARKETS_RTE_DISCOVERABILITY: ['en-us', 'en-ca', 'fr-ca'],
    },
};
export const DESCRIPTION_ELLIPSIS = '...';
export const SUPPORT_SITEMAPS = 'support-sitemaps';
export const TABLET_BREAKPOINT = 768;

export const ASTUTE = {
    HEADING_TAG: 'h1',
    BASE_URL: 'authoring.emplifiknowledge.com/RD7Authoring/topic-publish/kbs',
    BASE_URL_RTE:
        process.env.REACT_APP_ACTIVE_PROFILE &&
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ? 'https://api.pd01e.gcp.ford.com/dbi-kb/api/knowledge/v1/search'
            : 'https://api.qa01i.gcp.ford.com/dbi-kb/api/knowledge/v1/search',
    BASE_URL_RTE_DISCOVERABILITY:
        process.env.REACT_APP_ACTIVE_PROFILE &&
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ? 'https://api.pd01e.gcp.ford.com/dbi-kb/api/knowledge/v1/topic'
            : 'https://api.qa01i.gcp.ford.com/dbi-kb/api/knowledge/v1/topic',
    KB_ID: '5941',
    SITE_MAP_KEY_PREFIX: `Astute-Site-Map-${
        process.env.REACT_APP_ACTIVE_PROFILE
            ? process.env.REACT_APP_ACTIVE_PROFILE
            : 'dev'
    }-`,
    PRIVILEGED_AREA_WEB_ID: '1008011716',
    SUPPORTED_MARKETS:
        process.env.REACT_APP_ACTIVE_PROFILE &&
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ? [
                  'en-us',
                  'es-es',
                  'it-it',
                  'fr-fr',
                  'en-gb',
                  'de-de',
                  'en-ca',
                  'fr-ca',
                  'no-no',
                  'nl-nl',
                  'it-ch',
                  'fr-ch',
                  'de-ch',
                  'de-at',
                  'da-dk',
                  'fr-be',
                  'nl-be',
                  'cs-cz',
                  'fi-fi',
                  'el-gr',
                  'hu-hu',
                  'pl-pl',
                  'en-ie',
                  'fr-lu',
                  'ro-ro',
                  'pt-pt',
                  'en-au',
                  'en-nz',
                  'th-th',
                  'en-th',
                  'en-za',
                  'en-ph',
                  'vi-vn',
              ]
            : [
                  'en-us',
                  'it-it',
                  'es-es',
                  'fr-fr',
                  'en-gb',
                  'de-de',
                  'en-ca',
                  'fr-ca',
                  'en-au',
                  'th-th',
                  'en-th',
                  'en-nz',
                  'no-no',
                  'nl-nl',
                  'it-ch',
                  'fr-ch',
                  'de-ch',
                  'de-at',
                  'da-dk',
                  'fr-be',
                  'nl-be',
                  'cs-cz',
                  'fi-fi',
                  'el-gr',
                  'hu-hu',
                  'pl-pl',
                  'en-ie',
                  'fr-lu',
                  'ro-ro',
                  'pt-pt',
                  'en-za',
                  'en-ph',
                  'vi-vn',
              ],
    SUPPORTED_MARKETS_WITH_ENDING_SLASH: ['en-us', 'en-ca', 'fr-ca'],
    BRANDS: ['ford', 'lincoln'],
    SUPPORTED_MARKETS_FOR_COUPONS: ['en-us'],
    SUPPORTED_DYF_MARKETS: [
        'en-us',
        'pt-br',
        'es-ar',
        'es-cl',
        'es-ve',
        'es-pe',
        'es-co',
    ],
    SITE_MAP_CACHE_EXP_SEC: 432000,
    RE_INIT_SEARCH_SESSION_MILLS: 1800000,
    SITE_MAP_CHANGE_FREQ: 'daily',
    WHITELIST_XSS_MARKUP: ['iframe'],
    WHITELIST_XSS_ATTRIBUTES: [
        'style',
        'class',
        'align',
        'cellspacing',
        'cellpadding',
        'id',
        'start',
    ],
    SITEMAP_MAX_ITEMS: 500,
    PAGE_NOT_FOUND_URL: '/404',
    PAGINATION_ITEMS_PER_PAGE: 10,
    SLACK_ALERT_INVALID_URL: 'article-alerts',
    SKIP_SLUGIFY_LANGUAGES: ['th', 'vi'],
    DOMAINS_TO_SKIP_BBT_SEARCH: ['es.ford.com', 'es.lincoln.com'],
    CAMPAIGN_ID_SEARCH_PARAM: 'campaignId',
};
export const COMPONENTS_FOR_STATIC_SITEMAP = [
    'RecallsPage',
    'OwnerManualPage',
    'OwnerManualSitemapPage',
    'TireFinderView',
    'RedeemRebatesView',
    'AstuteSiteMap',
    'SiteMapView',
    'HowToVideoView',
    'GuidedVirtualTour',
    'BlueCruisePage',
];
export const RECALLS = {
    TAKATA_CAMPAIGN_IDS: ['19s01', '18s02', '17s42', '15s21', '21S12'],
    SLACK_ALERT_RECALLS_AEM_API: 'recalls-aem-api-alerts',
    DISCLAIMERS_ACCORDION_CONTENT: 'recall-disclaimers-section',
    IMPORTANT_INFO_ACCORDION: 'important-info-accordion',
    RECALLS_CATEGORY: 'recalls',
    VIDEO_CONTENT: 'video-content',
    CAMPAIGN_SEARCH_AVAILABLE: ['en-us'],
    TAKATA_RECALLS_AVAILABLE: ['en-us', 'en-ca', 'fr-ca'],
    IMG_TAKATA_ROUTE: 'takata-airbag-recalls',
    IMG_TAKATA_RECALLS_AVAILABLE: [
        { lngRegCode: 'en-au', campaignIDs: ['17s32', '19s53'] },
        { lngRegCode: 'en-ph', campaignIDs: ['15s21', '19s01', '19s06'] },
    ],
    YMM_AVAILABLE: [
        { brand: 'ford', lngRegCode: 'en-us' },
        { brand: 'ford', lngRegCode: 'en-ca' },
        { brand: 'ford', lngRegCode: 'fr-ca' },
        { brand: 'lincoln', lngRegCode: 'en-us' },
    ],
};
export const SYNC_MAPS = {
    SLACK_ALERT_SYNC_MAPS: 'sync-maps-alert',
    SLACK_ALERT_SYNC_MAPS_UPDATES: 'sync-maps-updates-alert',
    OPEN_TICKET_STATUS_VALUES: ['ASSIGNED', 'IN PROGRESS', 'NEW REQUEST'],
};
export const OWNER_MANUALS = {
    ENGLISH_WITH_DDMMYYY: ['en-ie'],
    SLACK_ALERT_OWNER_MANUALS_AEM_API: 'owner-manuals-aem-api-alerts',
    OM_SORTING_ORDER_BY_CATEGORY_ID: [
        112,
        111,
        266,
        267,
        306,
        311,
        262,
        210,
        110,
        318,
        272,
        127,
        128,
        172,
        123,
        88,
        135,
        136,
        137,
        138,
        204,
        264,
        132,
        312,
        263,
        95,
        101,
        133,
        120,
        121,
        122,
        126,
        141,
        114,
        115,
        177,
        317,
        265,
        316,
        211,
        89,
        117,
        118,
        131,
        178,
        246,
        100,
        150,
        103,
        104,
        105,
        106,
        102,
        130,
        171,
        129,
        86,
        124,
        116,
        119,
        134,
        108,
        109,
        113,
        94,
        98,
        107,
        87,
        85,
        90,
        91,
        92,
        96,
        97,
        99,
        93,
        125,
    ],
    AI_SEARCHABLE_VEHICLES:
        process.env.REACT_APP_ACTIVE_PROFILE &&
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ? []
            : [
                  {
                      brand: 'ford',
                      langReg: 'en-us',
                      model: 'f-150',
                      year: '2020',
                  },
                  {
                      brand: 'ford',
                      langReg: 'en-us',
                      model: 'f-150',
                      year: '2021',
                  },
                  {
                      brand: 'ford',
                      langReg: 'en-us',
                      model: 'f-150',
                      year: '2022',
                  },
                  {
                      brand: 'ford',
                      langReg: 'en-us',
                      model: 'f-150',
                      year: '2023',
                  },
                  {
                      brand: 'ford',
                      langReg: 'en-us',
                      model: 'f-150',
                      year: '2024',
                  },
              ],
    AI_FEEDBACK_USER_ID:
        process.env.REACT_APP_ACTIVE_PROFILE &&
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ? 'go_ui_prod'
            : 'go_ui_uat',
};

export const BLUECRUISE = {
    BLUECRUISE_MAY_BE_AVAILABLE_MODELS: ['mustang mach-e', 'f-150 lightning'],
    BLUECRUISE_PAGE_TITLE_WITH_ASTERISK: 'BlueCruise*',
    BLUECRUISE_PAGE_TITLE_WITHOUT_ASTERISK: ' BlueCruise',
};
export const COUNTRY_CODES_CENTRAL_AMERICA = {
    CENTRAL_AMERICA_MARKETS_SPANISH: [
        'blz',
        'cri',
        'slv',
        'gtm',
        'hnd',
        'nic',
        'pan',
    ],
};
export const COUNTRY_CODES_CARIBBEAN = {
    CARIBBEAN_MARKETS_ENGLISH: [
        'atg',
        'abw',
        'bhs',
        'brb',
        'bmu',
        'cym',
        'cuw',
        'dma',
        'grd',
        'hti',
        'jam',
        'lca',
        'maf',
        'sur',
        'tto',
    ],
    CARIBBEAN_MARKETS_SPANISH: ['dom'],
};
export const LANG_CODES_CENTRAL_AMERICA = {
    CENTRAL_AMERICA_MARKETS_SPANISH: [
        'es-bz',
        'es-cr',
        'es-sv',
        'es-gt',
        'es-hn',
        'es-ni',
        'es-pa',
    ],
};
export const LANG_CODES_CARIBBEAN = {
    CARIBBEAN_MARKETS_ENGLISH: [
        'en-ag',
        'en-aw',
        'en-bs',
        'en-bb',
        'en-bm',
        'en-ky',
        'en-cw',
        'en-dm',
        'en-gd',
        'en-ht',
        'en-jm',
        'en-lc',
        'en-mf',
        'en-sr',
        'en-tt',
    ],
    CARIBBEAN_MARKETS_SPANISH: ['es-do'],
};

export const OWNER_MANUALS_LATAM_SPANISH = ['es-ve'];

export const CITIVAN_ATTRIBUTES = {
    CITIVAN_CODE: 'VL0FJ',
    CITIVAN_DESCRIPTION: 'V761 CITY VAN',
};
export const pageTypesForH3 = [
    'sync-maps',
    'recalls',
    'owner-manual',
    'recalls-details',
];
export const THUMBS_UP_DOWN = {
    UP: 'up' as AiChatFeedbackOption,
    DOWN: 'down' as AiChatFeedbackOption,
};
export const CA_NO_PASTPRINTINGS = {
    regions: ['en-ca', 'fr-ca'],
    vehicles: ['transit', 'e-transit'],
};
export const XML_VIN_MISMATCH_MSG = `Upload Async halted. XML filename and VIN mismatch.`;
