import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import localStorageWrapper from '../../../utils/local-storage-wrapper/localStorageWrapper';
import { findByAlias } from '../../../../routes';
import { FMButton, FMInput } from '@own/fds-react';
import { getVinFromLocalStorageIfExists } from '../../vehicle-selector-fds/vin-selector/vin-selector';
import { useDyfEligibleModel } from '../../../../hooks/dyf/use-dyf-eligible-model/use-dyf-eligible-model';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import { INPUT_STATE } from '@own/fds-react/dist/types/Input.types';

interface Props {
    vinHeaderLabel: string;
    whereDoIFindVinCta: string;
    submitLabel: string;
    whereDoIFindVinLabel: string;
    whereDoIFindVinLink: string;
    ariaLabel: string;
    submitButtonAriaLabel: string;
    vinInputErrorMessage: string;
}

const VehicleSelectorVinInput = (props: Props) => {
    const history = useHistory();
    const [vin, setVin] = useState('');

    const [vinRegexValid, setVinRegexValid] = useState<boolean>(false);
    const [vinValid, setVinValid] = useState(false);

    const [hasInteracted, setHasInteracted] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const {
        executeQuery,
        data: eligibleModelData,
        loading: eligibleModelDataLoading,
    } = useDyfEligibleModel(null);

    const checkValidRegex = (inputVin: string) => {
        const vinRegex = /^[A-Z0-9]{17}$/;
        return vinRegex.test(inputVin.toUpperCase());
    };

    useEffect(() => {
        setVin(getVinFromLocalStorageIfExists());
    }, []);

    const handleChange = (value: string) => {
        if (!value.match(/^[A-Za-z0-9]{0,17}$/)) {
            return;
        }
        setVin(value);
    };
    useEffect(() => {
        if (vin.length >= 17) {
            setHasInteracted(true);
        }
        if (!checkValidRegex(vin)) {
            setVinRegexValid(false);
            setVinValid(false);
        } else {
            setLoading(true);
            setVinRegexValid(true);
            executeQuery({ vin });
        }
    }, [vin, hasInteracted]);

    useEffect(() => {
        if (loading) return setErrorMessage(null);
        if (hasInteracted) {
            if (!vinRegexValid) {
                return setErrorMessage(props.vinInputErrorMessage);
            } else if (!vinValid) {
                setErrorMessage(props.vinInputErrorMessage);
            } else {
                setErrorMessage(null);
            }
        }
        return () => setErrorMessage(null);
    }, [hasInteracted, vinValid, vinRegexValid, loading]);

    useEffect(() => {
        if (!eligibleModelDataLoading) {
            if (eligibleModelData && eligibleModelData.eligibleFeatureGuide) {
                setVinValid(true);
                setErrorMessage(null);
            } else {
                setVinValid(false);
            }
            setLoading(false);
        }
    }, [eligibleModelDataLoading, eligibleModelData, hasInteracted]);

    const handleSubmit = () => {
        localStorageWrapper.setItem('USER_VIN', vin);
        history.push(findByAlias('DyfLandingView')[0].replace(':vin', vin));
    };

    const onInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            vinValid && handleSubmit();
        }
    };
    const inputState = useMemo<INPUT_STATE>(() => {
        if (!hasInteracted) {
            return 'standard';
        } else {
            return vinValid ? 'success' : 'error';
        }
    }, [hasInteracted, vinValid]);

    return (
        <div className={'onboarding-vin-selector-container'}>
            <div className={'onboarding-vin-input-field-container'}>
                <FMInput
                    maxLength={17}
                    header={props.vinHeaderLabel}
                    label={props.vinHeaderLabel}
                    ariaLabel={props.ariaLabel}
                    name={'vin-field-selector'}
                    state={inputState}
                    type="text"
                    onBlur={() => setHasInteracted(true)}
                    value={vin}
                    onChange={e => {
                        handleChange(e.target.value);
                    }}
                    placeholder={''}
                    onKeyUp={onInputKeyPress}
                />
            </div>
            <div>
                {errorMessage && (
                    <div
                        className={'onboarding-vin-error-message'}
                        data-testid={'error-message'}
                    >
                        <span>{errorMessage}</span>
                    </div>
                )}
            </div>

            <div
                className="find-vin-cta"
                data-testid={'find-vin-cta-test-id'}
                aria-label={props.whereDoIFindVinLabel}
                onClick={() =>
                    (window.location.href = props.whereDoIFindVinLink)
                }
                dangerouslySetInnerHTML={{
                    __html: props.whereDoIFindVinLabel,
                }}
            />

            <div className="onboarding-search-button">
                <FMButton
                    type={'primary'}
                    chevron={'right'}
                    label={props.submitLabel}
                    ariaLabel={props.submitButtonAriaLabel}
                    data-testid={'vin-submit-button'}
                    disabled={!vinValid}
                    onClick={handleSubmit}
                />
                {loading && (
                    <div className="icon-holder">
                        <ActivityIndicator />
                    </div>
                )}
            </div>
        </div>
    );
};

export default VehicleSelectorVinInput;
