export interface LiveStreamDetails {
    model: string;
    id: string;
    src: string;
}

const livestreamList: LiveStreamDetails[] = [
    {
        model: 'bronco',
        id: 'broncoCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/bronco-combined-embed.min.js',
    },
    {
        model: 'bronco sport',
        id: 'broncoSportCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/bronco-sport-combined-embed.min.js',
    },
    {
        model: 'mustang mach-e',
        id: 'macheCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/mach-e-combined-embed.min.js',
    },
    {
        model: 'f-150',
        id: 'f150CombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/f150-combined-embed.min.js',
    },
    {
        model: 'f-150 lightning',
        id: 'f150lightningCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/f150-lightning-combined-embed.min.js',
    },
    {
        model: 'maverick',
        id: 'maverickCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/maverick-combined-embed.min.js',
    },
    // This is Dev Embedded for Maverick
    // {
    //     model: 'maverick',
    //     id: 'maverickCombinedEmbed',
    //     src:
    //         'https://dyf-embed-dev.s3.amazonaws.com/maverick-combined-embed.min.js',
    // },
    {
        model: 'explorer',
        id: 'explorerCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/explorer-combined-embed.min.js',
    },
    // This is Dev Embedded for Escape
    // {
    //     model: 'explorer',
    //     id: 'explorerCombinedEmbed',
    //     src:
    //         'https://dyf-embed-dev.s3.amazonaws.com/explorer-combined-embed.min.js',
    {
        model: 'escape',
        id: 'escapeCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/escape-combined-embed.min.js',
    },
    // This is Dev Embedded for Escape
    // {
    //     model: 'escape',
    //     id: 'escapeCombinedEmbed',
    //     src:
    //         'https://dyf-embed-dev.s3.amazonaws.com/escape-combined-embed.min.js',
    // },
    {
        model: 'ranger',
        id: 'rangerCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/ranger-combined-embed.min.js',
    },
    // This is Dev Embedded for Expedition
    // {
    //     model: 'expedition',
    //     id: 'expeditionCombinedEmbed',
    //     src:
    //         'https://dyf-embed-dev.s3.amazonaws.com/expedition-combined-embed.min.js',
    // },
    {
        model: 'expedition',
        id: 'expeditionCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/expedition-combined-embed.min.js',
    },
    {
        model: 'edge',
        id: 'edgeCombinedEmbed',
        src:
            'https://s3.amazonaws.com/discoveryourford.com/embed/edge-combined-embed.min.js',
    },
];

export function getLiveStreamDetails(model: string): LiveStreamDetails {
    return (
        livestreamList.filter(
            ele => ele.model === model.toLocaleLowerCase()
        )[0] || livestreamList[0]
    );
}
