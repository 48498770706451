import React from 'react';
import { QuietCard } from '../../../../common/quiet-card/quiet-card';
import { Article } from '../../../../../models/experiencefragments/category-view';

import './category-quiet-cards.scss';
import {
    PrimaryButtonColor,
    PrimaryButtonFill,
} from '../../../../common/primary-button/primary-button';

interface Props {
    className?: string;
    articles: Article[];
    firstCardImageContent?: boolean;
    buttonColor?: PrimaryButtonColor;
    buttonFill?: PrimaryButtonFill;
    eventNames?: string;
    categoryType?: string;
    categoryPath?: string;
}

export const CategoryQuietCards = (props: Props) => {
    const quietCards = props
        ? {
              ...props,
              articles: props?.articles.map(article => {
                  return {
                      ...article,
                      mobileImagePath: `${process.env.REACT_APP_AEM_BASE_URL}${article.mobileImagePath}`,
                      desktopImagePath: `${process.env.REACT_APP_AEM_BASE_URL}${article.desktopImagePath}`,
                  };
              }),
          }
        : null;

    const cardFormat = (index: number) => {
        if (props.firstCardImageContent) {
            return index % 2 === 0 ? 'image-content' : 'content-image';
        } else return index % 2 === 0 ? 'content-image' : 'image-content';
    };

    return (
        <div
            className={`category-quiet-cards ${
                props.className ? props.className : ''
            }`}
        >
            {quietCards?.articles.map((card, index) => {
                return (
                    <QuietCard
                        key={index}
                        subtitle={card.headline}
                        title={card.title}
                        body={card.description}
                        ctaUrl={card.ctaPath}
                        ctaLabel={card.ctaLabel}
                        ctaIconPath={card.ctaIconPath}
                        ctaAriaLabel={card.ctaAriaLabel}
                        targetBlank={card.targetBlank}
                        cta2Url={card.cta2Path}
                        cta2Label={card.cta2Label}
                        cta2IconPath={card.cta2IconPath}
                        cta2AriaLabel={card.cta2AriaLabel}
                        target2Blank={card.target2Blank}
                        mobileImageUrl={card.mobileImagePath}
                        desktopImageUrl={card.desktopImagePath}
                        format={cardFormat(index)}
                        buttonColor={props.buttonColor}
                        buttonFill={props.buttonFill}
                        eventNames={props.eventNames}
                        categoryType={props.categoryType}
                        categoryPath={props.categoryPath}
                        imageAltText={card.imageAltText}
                    />
                );
            })}
        </div>
    );
};
