import React, { useState } from 'react';
import { CheckRebateStatus } from './check-rebate-status/check-rebate-status';
import { ForgotTrackingNumber } from './forgot-tracking-number/forgot-tracking-number';
export const RedeemRebatesStatus = () => {
    const [forgot, isForgot] = useState(false);
    const DidForgot = () => {
        isForgot(!forgot);
    };
    return (
        <>
            {forgot ? (
                <ForgotTrackingNumber click={DidForgot} />
            ) : (
                <CheckRebateStatus click={DidForgot} />
            )}
        </>
    );
};
