import React, { ReactNode, useRef, useEffect, useState } from 'react';
import './banner.scss';

interface Props {
    topComponent?: ReactNode;
    bottomComponent?: ReactNode;
    topMasthead?: ReactNode;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
    componentStyle?: string;
}

export const ViewBanner = (props: Props) => {
    const TABLET_BREAKPOINT = 768;
    const DEFAULT_HEIGHT_VALUE = 0;
    const topDivRef = useRef<HTMLDivElement>(null);
    const bottomDivRef = useRef<HTMLDivElement>(null);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [topDivHeight, setTopDivHeight] = useState(DEFAULT_HEIGHT_VALUE);
    const [bottomDivHeight, setBottomDivHeight] = useState(
        DEFAULT_HEIGHT_VALUE
    );

    useEffect(() => {
        let movementTimer: NodeJS.Timeout | null = null;

        const configureLayout = () => {
            const bottomDivHeightValue =
                bottomDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setBottomDivHeight(bottomDivHeightValue / 2);

            const topDivHeightValue =
                topDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setTopDivHeight(topDivHeightValue);

            const isMobile = window.innerWidth <= TABLET_BREAKPOINT;
            if (isMobile) setBackgroundImage(props.backgroundImageMobile);
            else setBackgroundImage(props.backgroundImageDesktop);
        };

        configureLayout();

        window.addEventListener('resize', () => {
            if (movementTimer) clearTimeout(movementTimer);
            movementTimer = setTimeout(configureLayout, 10);
        });

        return () => {
            window.removeEventListener('resize', () => {
                if (movementTimer) clearTimeout(movementTimer);
            });
        };
    }, [props.backgroundImageMobile, props.backgroundImageDesktop]);

    return (
        <div className="banner">
            {props.topComponent && (
                <div
                    className={
                        props.componentStyle
                            ? props.componentStyle
                            : ['top', bottomDivHeight === 0 ? 'no-bottom' : '']
                                  .join(' ')
                                  .trim()
                    }
                    style={{
                        minHeight: `${topDivHeight + bottomDivHeight}px`,
                        backgroundImage: `url('${backgroundImage}')`,
                    }}
                >
                    {props.topMasthead && (
                        <div className="vehicle-tabs">{props.topMasthead}</div>
                    )}

                    <div className="cta" ref={topDivRef}>
                        {props.topComponent}
                    </div>
                </div>
            )}
            {props.bottomComponent && (
                <div className="bottom-container">
                    <div className="bottom" ref={bottomDivRef}>
                        {props.bottomComponent}
                    </div>
                </div>
            )}
        </div>
    );
};
