import React, { useEffect, useState } from 'react';
import { useRedeemRebateContent } from '../hooks/redeem-rebates-steps/use-redeem-rebate-content';
import { RedeemRebatesSteps } from '../redeem-rebates-steps/redeem-rebates-steps';
import { RedeemRebatesStatus } from '../redeem-rebates-status/redeem-rebates-status';
import { TabNav } from './tab-nav/tab-nav';
import { Tab } from './tab/tab';

import './redeem-rebates-tabs.scss';

export const RedeemRebatesTabs = () => {
    const [select, setSelect] = useState('');
    const setSelected = (tab: any) => {
        setSelect(tab);
    };

    useEffect(() => {
        setTimeout(() => {
            setSelect('redeem rebates');
        }, 50);
    }, []);

    const redeemrebateContent = useRedeemRebateContent();

    return (
        <div className="rebate-tabs">
            <TabNav
                tabs={['redeem rebates', 'check status']}
                selected={select}
                setSelected={setSelected}
            >
                <Tab isSelected={select === 'redeem rebates'}>
                    {redeemrebateContent && (
                        <div className="rebates-step1-page-form">
                            <div className="rebates-page-toptext">
                                <div className="rebates-page-title">
                                    <h3>
                                        {redeemrebateContent.rebatesPageTitle ||
                                            'Redeem Your Rebate(s)'}
                                    </h3>
                                </div>
                                <p className="rebates-form-text">
                                    {' '}
                                    {redeemrebateContent.rebatesFormDescriptionText ||
                                        'You can get your rebate or Points by simply submitting a few details using our convenient online submission form. Or, if you wish, you can download a printable rebate request form.'}
                                </p>
                            </div>
                        </div>
                    )}
                    <RedeemRebatesSteps />
                </Tab>
                <Tab isSelected={select == 'check status'}>
                    <RedeemRebatesStatus />
                </Tab>
            </TabNav>
        </div>
    );
};
