import React from 'react';
import { useRouterLocation } from '../../../../../hooks/use-router-location';
import { Link, SecondaryButton } from '../../../../common';

import './feature-content-a.scss';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';

interface Props {
    imageHero: string;
    imageAlt: string;
    heading: string;
    bodyCopy: string;
    ctaText: string;
    ctaAriaLabel: string;
    ctaLink: string;
    ctaTarget: string;
}

export const FeatureContentA = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const sectionId = props.heading?.replace(/ /g, '-').toLowerCase();
    const location = useRouterLocation();
    const curPathName = ServerSideService.isClientSide()
        ? filterXSS(location.pathname)
        : null;
    return (
        <>
            {props && (
                <section className="feature-content-a" id={sectionId}>
                    <div className="feature-content-a__content">
                        <div className="feature-content-a__content-wrapper">
                            <Link
                                className="section-heading-link"
                                href={`${curPathName}#${sectionId}`}
                            >
                                <h3 className="feature-content-a__heading">
                                    {props.heading}
                                </h3>
                            </Link>
                            <p className="feature-content-a__body-copy">
                                {props.bodyCopy}
                            </p>
                            <SecondaryButton
                                link={props.ctaLink}
                                onClick={() =>
                                    NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                                        props.ctaLink,
                                        fireEvents
                                    )
                                }
                                linkTarget={props.ctaTarget}
                                className="feature-content-a__cta"
                                aria-label={props.ctaAriaLabel}
                            >
                                {props.ctaText}
                            </SecondaryButton>
                        </div>
                    </div>
                    <div className="feature-content-a__image">
                        <img
                            src={`${process.env.REACT_APP_AEM_BASE_URL}${props.imageHero}`}
                            alt={props.imageAlt}
                        />
                    </div>
                </section>
            )}
        </>
    );
};
