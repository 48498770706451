import React from 'react';
import './support-trio.scss';
import { useFeatureCards } from '../../sections/vehicle-portal/hooks/use-feature-cards';
import { FeatureTrio } from '../../sections/vehicle-portal/components/feature-trio/feature-trio';

interface Props {
    folder: string;
    experienceFragmentName: string;
}

export const SupportTrio = (props: Props) => {
    const supportTrioContent = useFeatureCards(
        props.folder,
        props.experienceFragmentName,
        'featuretrio',
        false
    );

    return (
        <section className="support-section">
            {supportTrioContent && <FeatureTrio {...supportTrioContent} />}
        </section>
    );
};
