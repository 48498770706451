import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';
import ServiceHandler from '../service-handler';
import {
    VehiclePrognostics,
    VehicleResetPrognostics,
} from '../../models/vehicle-prognostics';
import { BrandUtil } from '../../components/utils/brand-util/brand-util';

export default class VehiclePrognosticsService {
    public async getActivePrognosticsByVin(
        vin: string
    ): Promise<VehiclePrognostics | null> {
        const {
            current3LetterCountryCode,
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        const baseUrl = DSL_BASE_URL + DSL_API_PATH.VEHICLE_PROGNOSTICS;
        const requestUrl = `${baseUrl}?countryCode=${current3LetterCountryCode.toUpperCase()}&languageCode=${currentLanguageRegionCode}&source=FORD`;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin: vin,
        };
        const response = HttpService.get<VehiclePrognostics>(requestUrl, true, {
            headers,
        });
        return response.then(response => {
            if (response.data) {
                return response.data;
            }
            return null;
        });
    }

    public async getActivePrognosticsV2(
        vin: string
    ): Promise<VehiclePrognostics | null> {
        const {
            current3LetterCountryCode,
            currentLanguageRegionCode,
            brand,
        } = ServiceHandler.AppConfigurationService;
        const baseUrl = DSL_BASE_URL + DSL_API_PATH.VEHICLE_PROGNOSTICS_V2;
        const brandName = new BrandUtil().getBrandName(brand);
        const requestUrl = `${baseUrl}?countryCode=${current3LetterCountryCode.toUpperCase()}&languageCode=${currentLanguageRegionCode}&brand=${brandName}`;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin: vin,
        };
        const response = HttpService.get<VehiclePrognostics>(requestUrl, true, {
            headers,
        });
        return response.then(response => {
            if (response.data) {
                return response.data;
            }
            return null;
        });
    }

    public async resetPrognosticsByVin(
        vin: string
    ): Promise<VehicleResetPrognostics | null> {
        const {
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;
        const brandUtil = new BrandUtil();
        const brandName = brandUtil.getFormattedBrandName();
        const baseUrl = DSL_BASE_URL + DSL_API_PATH.VEHICLE_PROGNOSTICS;
        const requestUrl = `${baseUrl}?countryCode=${current3LetterCountryCode.toUpperCase()}&featureType=SM&consumer=GlobalOwnerWeb&brand=${brandName}`;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin: vin,
        };
        const params = {
            countryCode: current3LetterCountryCode.toUpperCase(),
            featureType: 'SM',
            consumer: 'GlobalOwnerWeb',
            brand: brandName,
        };
        const response = HttpService.post<VehicleResetPrognostics>(
            requestUrl,
            params,
            {
                headers,
            }
        );
        return response.then(response => {
            if (response.data) {
                return response.data;
            }
            return null;
        });
    }
}
