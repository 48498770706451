import { ToolTip } from '../tooltip/tooltip';
import React from 'react';

import './info-icon-tooltip.scss';
import redWarning from '../../../assets/owners-osb/warning-icon-red.svg';
import yellowWarning from '../../../assets/owners-osb/warning-yellow.png';

interface Props {
    tooltipHeader?: string;
    tooltipContent: string;
    tooltipInfoIconClass?: string;
    osbServiceTooltip?: boolean;
    redWarningIcon?: boolean;
    yellowWarningIcon?: boolean;
    customShowTooltipAriaLabel?: string;
    customDismissTooltipAriaLabel?: string;
    uniqueId?: number;
    onClick?: () => void; // define a prop for click event
    dataTestId?: string;
}

export const InfoIconToolTip = (props: Props) => {
    const {
        osbServiceTooltip,
        redWarningIcon,
        yellowWarningIcon,
        tooltipInfoIconClass,
        customShowTooltipAriaLabel,
    } = props;
    const infoClass = tooltipInfoIconClass ? tooltipInfoIconClass : '';
    const tooltipclass = ['tooltip__infoIcon', infoClass].join(' ').trim();
    const InfoIconS = './icons/info-s.svg';
    return (
        <ToolTip
            {...props}
            className="info-icon-tooltip"
            onClick={props.onClick}
            tooltipSymbol={
                <>
                    {osbServiceTooltip ? (
                        <div
                            className={tooltipclass}
                            aria-label={customShowTooltipAriaLabel}
                        >
                            {redWarningIcon && (
                                <img
                                    src={redWarning}
                                    alt=""
                                    className="campaign-validation-error-image"
                                />
                            )}
                            {yellowWarningIcon && (
                                <img
                                    src={yellowWarning}
                                    alt=""
                                    className="campaign-validation-error-image"
                                />
                            )}
                            {!redWarningIcon && !yellowWarningIcon && (
                                <img src={InfoIconS} />
                            )}
                        </div>
                    ) : (
                        <sup className={tooltipclass}>&#9432;</sup>
                    )}
                </>
            }
        />
    );
};
