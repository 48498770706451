import { useExperienceContent } from '../../../../hooks/use-server-content';
import { ContainerFragment } from '../../../../models/service-maintenance-models/vehicle-health-alerts';
import { SlowTireLeakDetailsPage } from '../../../../models/experiencefragments/slow-tire-leak';

export const useSlowTireLeakDetailsContent = (): SlowTireLeakDetailsPage => {
    const ITEMS = ':items';
    const [containerContent] = useExperienceContent<ContainerFragment>(
        'vehicles',
        'slow-tire-leak',
        'container'
    );
    return containerContent?.[ITEMS] as SlowTireLeakDetailsPage;
};
