import React, { useContext, useState } from 'react';
import { SecondaryButton } from '../../../../common';
import {
    Articles,
    Article,
} from '../../../../../models/experiencefragments/category-view';
import { SubcategoryComponentBox5050 } from '../../../subcategory/subcategory-component-box-5050/subcategory-component-box-5050';

import './latest-articles.scss';
import { Picture } from '../../../../common/picture/picture';
import ServerContext from '../../../../../contexts/serverContext';
import { transformRelativeUrlValidateRoot } from '../../../../utils/router-util/router-util';
import searchPlusPreimagineIcon from '../../../../../assets/search-plus-preimagine.svg';
import searchPlusIcon from '../../../../../assets/search-plus.svg';
import { CategoryOnClickCtaInfo } from '../../../../../services/shortcode-service/shortcode-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import { FMCardFiftyFifty } from '@own/fds-react';

interface Props {
    latestArticlesContent?: Articles;
    componentBox5050Content?: Article;
    categoryPath?: string;
    eventName?: string;
    class?: string;
}

export const LatestArticles = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { latestArticlesContent } = props;
    const { componentBox5050Content } = props;
    const ONE = 1;
    const PER_PAGE = 6;
    const COMPONENT_BOX_POSITION = 2;
    const totalCount = latestArticlesContent?.articles?.length || 0;
    const [enableShowMore, setEnableShowMore] = useState(PER_PAGE < totalCount);
    const [index, setIndex] = useState(
        totalCount < PER_PAGE ? totalCount : PER_PAGE
    );
    const context = useContext(ServerContext);

    const eventClick = async (ctaUrl: string, ctaName: string | undefined) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            ctaName: ctaName,
            categoryPath: props.categoryPath,
            ctaUrl: ctaUrl,
        };
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaUrl
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (props?.eventName) {
            fireEvents(props.eventName, undefined, {
                categoryOnClickCtaInfo,
            });
        }
    };
    const showMore = () => {
        const newIndex =
            index + PER_PAGE > totalCount ? totalCount : index + PER_PAGE;
        const focusNewIndex = `article${index}`;
        setTimeout(() => {
            document.getElementById(focusNewIndex)?.focus();
        }, 100);
        setIndex(newIndex);
        setEnableShowMore(newIndex < totalCount);
    };

    const getComponentBox5050 = (index: number) => {
        let position = COMPONENT_BOX_POSITION;
        if (totalCount <= COMPONENT_BOX_POSITION) {
            position = totalCount - 1;
        }
        if (index === position && componentBox5050Content) {
            if (props.class === 'preimagine') {
                return (
                    <FMCardFiftyFifty
                        {...{
                            headline: componentBox5050Content.headline,
                            description: componentBox5050Content.description,
                            mediaSide: 'right',
                            media: {
                                src:
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    componentBox5050Content.desktopImagePath,
                                alt: componentBox5050Content.imageAltText,
                            },
                            button: {
                                type: 'secondary',
                                label: componentBox5050Content.ctaLabel,
                                href: componentBox5050Content.ctaPath,
                                disabled: false,
                                onClick: () =>
                                    eventClick(
                                        componentBox5050Content?.ctaPath,
                                        props.componentBox5050Content?.ctaLabel
                                    ),
                                target: componentBox5050Content.targetBlank
                                    ? '_blank'
                                    : '',
                            },
                        }}
                    />
                );
            } else {
                return (
                    <SubcategoryComponentBox5050
                        content={componentBox5050Content}
                        categoryPath={props.categoryPath}
                        eventName={props.eventName}
                    />
                );
            }
        } else return null;
    };

    return (
        <section
            className={`latest-articles ${props.class ? props.class : ''}`}
        >
            <h2 className="latest-articles-header">
                {latestArticlesContent?.sectionHeading}
            </h2>
            <hr className="latest-articles-divider" aria-hidden="true" />
            <div className="latest-articles-container">
                {latestArticlesContent?.articles
                    ?.slice(0, index)
                    .map((article, index) => {
                        return (
                            <>
                                <div key={index} className="latest-article">
                                    <div className="article-info">
                                        <a
                                            id={`article${index}`}
                                            className="title"
                                            rel="noopener noreferrer"
                                            target={
                                                article.targetBlank
                                                    ? '_blank'
                                                    : ''
                                            }
                                            href={transformRelativeUrlValidateRoot(
                                                article.ctaPath,
                                                context.root
                                            )}
                                            aria-label={article.ctaAriaLabel}
                                        >
                                            <h3
                                                onClick={() =>
                                                    eventClick(
                                                        article.ctaPath,
                                                        article.plainHeadline
                                                    )
                                                }
                                            >
                                                {article.headline}
                                            </h3>
                                        </a>
                                        <div className="description">
                                            {article.description}
                                        </div>
                                    </div>
                                    <div className="article-asset">
                                        <Picture
                                            largeDesktopImagePath={
                                                article.desktopImagePath
                                            }
                                            desktopImagePath={
                                                article.desktopImagePath
                                            }
                                            tabletImagePath={
                                                article.mobileImagePath
                                            }
                                            mobileImagePath={
                                                article.mobileImagePath
                                            }
                                            imageAltText={article.imageAltText}
                                        />
                                    </div>
                                </div>
                                {getComponentBox5050(index)}
                            </>
                        );
                    })}
            </div>
            <hr className="latest-articles-divider" />
            <div className="latest-articles-load-more">
                <div>
                    {enableShowMore && (
                        <SecondaryButton
                            onClick={showMore}
                            className="show-more"
                            noChevron={true}
                        >
                            {latestArticlesContent?.showMoreText}
                            <img
                                alt=""
                                src={
                                    props.class
                                        ? searchPlusPreimagineIcon
                                        : searchPlusIcon
                                }
                                className="plus-circle"
                            />
                        </SecondaryButton>
                    )}
                </div>
                <div className="page-count">
                    {latestArticlesContent?.displayingText} {ONE}-{index}{' '}
                    {latestArticlesContent?.ofText} {totalCount}
                </div>
            </div>
        </section>
    );
};
