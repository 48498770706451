import React, { useState, useEffect, useContext } from 'react';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import {
    WarrantyDetails,
    WarrantyInformation,
} from '../../components/sections';
import useUserProfile from '../../hooks/use-user-profile';
import useSeoConfig from '../../hooks/use-seo-config';
import ServerContext from '../../contexts/serverContext';
import { useWarrantyInformationContent } from '../../components/sections/warranty-information/hooks/use-warranty-information-content';

export function WarrantyInformationView() {
    const warrantyInformationContent = useWarrantyInformationContent();
    const authenticationService = new AuthenticationService();
    const profile = useUserProfile();
    const [uomDistance, setUomDistance] = useState<number>(0);
    const serverContext = useContext(ServerContext);
    const userSelectedVin =
        typeof window === 'undefined'
            ? serverContext.profile?.vin
            : window.sessionStorage.getItem('selectedVin');
    const [selectedVin, setSelectedVin] = useState<string>(
        serverContext?.webviewData?.vin || userSelectedVin
    );

    const pageName = selectedVin ? 'warranty-details' : 'warranty-information';
    useEffect(() => {
        if (profile && profile.vehicles && profile.vehicles.length === 1) {
            setUomDistance(profile.profile?.uomDistance);
            setSelectedVin(profile.vehicles[0].vin);
        } else if (profile) {
            setUomDistance(profile.profile?.uomDistance);
        }
    }, [profile]);

    const goToWarrantyDetails = (vin: string) => {
        sessionStorage.setItem('selectedVin', vin);
        setSelectedVin(vin);
    };

    const loginHandler = (action: string) => {
        if (action === 'signin') {
            authenticationService.login();
        } else if (action === 'register') {
            authenticationService.register();
        }
    };
    useSeoConfig(pageName, 'warranty');
    return (
        <>
            {selectedVin ? (
                <>
                    <WarrantyDetails
                        vinNumber={selectedVin}
                        loginClick={loginHandler}
                        uomDistance={uomDistance}
                        page={'Warranty Details'}
                    />
                </>
            ) : (
                <WarrantyInformation
                    goToWarrantyDetails={goToWarrantyDetails}
                    page={'Warranty Information'}
                    loginClick={loginHandler}
                    warrantyInformationContent={warrantyInformationContent}
                />
            )}
        </>
    );
}
export default WarrantyInformationView;
