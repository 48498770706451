import { useEffect, useState } from 'react';
import { CountryDialCodePickerContent } from '../components/common/country-dial-code-picker/country-dial-code-picker';
import { useExperienceContent } from './use-server-content';
export const useCountryDialCodePicker = (): CountryDialCodePickerContent | null => {
    const [content, setContent] = useState<CountryDialCodePickerContent | null>(
        null
    );

    const [experienceFragmentCountryDialCodePicker] = useExperienceContent<
        CountryDialCodePickerContent
    >('common', 'country-dial-code-list', 'dynamicPhoneNumberDropdown');

    useEffect(() => {
        if (experienceFragmentCountryDialCodePicker) {
            setContent(experienceFragmentCountryDialCodePicker);
        }
    }, [experienceFragmentCountryDialCodePicker]);

    return content;
};
