import ServerSideService from '../server-side-service/server-side-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { LogService } from '../log-service/log-service';
import { findByAlias } from '../../routes';

export default class AuthenticationService {
    private readonly AUTHENTICATED_EVENT = 'fma_authenticated';
    private readonly UNAUTHENTICATED_EVENT = 'fma_unauthenticated';
    private win: any;
    private handleAuthenticatedEvent: (() => void) | null = null;
    private handleUnauthenticatedEvent: (() => void) | null = null;

    private static isAuthenticatedPromise: Promise<boolean>;
    private fmaTimeout: any;

    constructor() {
        if (ServerSideService.isClientSide()) {
            this.win = window;
        }
        this.updateWebviewBundle();
        if (!AuthenticationService.isAuthenticatedPromise) {
            AuthenticationService.isAuthenticatedPromise = new Promise<boolean>(
                resolve => {
                    if (ServerSideService.isClientSide()) {
                        LogService.log(
                            'Authentication',
                            'Authentication is configured for this domain.'
                        );

                        if (
                            this.win?.SERVER_CONTEXT?.webview === 'true' &&
                            this.win?.SERVER_CONTEXT?.webviewData?.accessToken
                        ) {
                            this.win.webviewBundle = this.win.SERVER_CONTEXT.webviewData;
                            resolve(true);
                        } else if (
                            this.fmaIsConfigured() &&
                            this.fmaFinishedCheckingAuthentication()
                        ) {
                            const authenticationState = this.getFmaIsAuthenticated();
                            LogService.log(
                                'Authentication',
                                `Authentication check already completed, not setting event listeners. Authentication State: ${authenticationState}`
                            );
                            resolve(authenticationState);
                        } else {
                            LogService.log(
                                'Authentication',
                                'Authentication check not yet completed. Setting event listeners.'
                            );
                            this.addAuthenticationEventListeners(resolve);
                        }
                    } else {
                        LogService.log(
                            'Authentication',
                            'Authentication is not configured for this domain.'
                        );
                        resolve(false);
                    }
                }
            );
        }
    }

    private updateWebviewBundle() {
        if (ServerSideService.isClientSide()) {
            const deepLinkParams = new URLSearchParams(location.search);
            if (deepLinkParams.get('webview') === 'true') {
                this.win.webviewBundle = {
                    source:
                        this.win?.SERVER_CONTEXT?.webviewData?.source ||
                        'FordPass',
                    accessToken: this.win?.SERVER_CONTEXT?.webviewData
                        ?.accessToken,
                    dealerCode: this.win?.SERVER_CONTEXT?.webviewData
                        ?.dealerCode,
                    vin: this.win?.SERVER_CONTEXT?.webviewData?.vin,
                };
            }
        }
    }

    private fmaIsConfigured(): boolean {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        const fmaUrlConfigured = appConfiguration.fmaUrl.length > 0;
        const fmaRegionConfigured = appConfiguration.fmaRegion.length > 0;
        const apimUrlConfigured = appConfiguration.apimUrl.length > 0;
        return fmaUrlConfigured && fmaRegionConfigured && apimUrlConfigured;
    }

    private fmaFinishedCheckingAuthentication = (): boolean => {
        const isAuthenticated = this.win?.fma?.isAuthenticated;
        return isAuthenticated !== undefined;
    };

    private getFmaIsAuthenticated = (): boolean => {
        const isAuthenticated = this.win?.fma?.isAuthenticated;
        return isAuthenticated;
    };

    public getwebviewBundle = (): any | null => {
        return this.win?.webviewBundle;
    };

    public getCatBundle = (): any | null => {
        if (this.win?.webviewBundle)
            return {
                access_token: this.win?.webviewBundle.accessToken,
            };
        else return this.win?.fma?.CATBundle;
    };

    private addAuthenticationEventListeners = (
        resolve: (value: boolean | PromiseLike<boolean>) => void
    ): void => {
        document?.body?.addEventListener(
            this.AUTHENTICATED_EVENT,
            (this.handleAuthenticatedEvent = () => {
                LogService.log(
                    'Authentication',
                    '"Authenticated" event received.'
                );
                resolve(true);
                this.removeAuthenticationEventListeners();
            })
        );
        document?.body?.addEventListener(
            this.UNAUTHENTICATED_EVENT,
            (this.handleUnauthenticatedEvent = () => {
                LogService.log(
                    'Authentication',
                    '"Unauthenticated" event received.'
                );
                resolve(false);
                this.removeAuthenticationEventListeners();
            })
        );
        this.fmaTimeout = setTimeout(() => {
            LogService.log(
                'Authentication',
                'Authentication event listener has timed-out. Considering user unauthenticated.'
            );
            resolve(false);
            this.removeAuthenticationEventListeners();
        }, 30000);
    };

    private removeAuthenticationEventListeners = (): void => {
        LogService.log(
            'Authentication',
            'Removing authentication event listeners.'
        );
        if (this.handleAuthenticatedEvent)
            document.body.removeEventListener(
                this.AUTHENTICATED_EVENT,
                this.handleAuthenticatedEvent
            );
        if (this.handleUnauthenticatedEvent)
            document.body.removeEventListener(
                this.UNAUTHENTICATED_EVENT,
                this.handleUnauthenticatedEvent
            );
        if (this.fmaTimeout) {
            clearTimeout(this.fmaTimeout);
            this.fmaTimeout = undefined;
        }
    };

    public login = (): void => {
        if (!this.win?.fma.model.config.state) this.setFmaState();
        this.win?.fma && this.win.fma.login();
    };

    public register = (): void => {
        this.win?.fma && this.win.fma.register();
    };

    public logout = (): void => {
        sessionStorage.clear();
        if (this.win?.fma) {
            this.win.fma.logout();
            if (ServerSideService.isClientSide()) {
                sessionStorage.setItem('fmaAuthenticated', 'false');
            }
        }
    };

    public onIsAuthenticated = (): Promise<boolean> => {
        AuthenticationService.isAuthenticatedPromise.then(authenticated => {
            if (ServerSideService.isClientSide()) {
                sessionStorage.setItem('fmaAuthenticated', `${authenticated}`);
            }
        });
        return AuthenticationService.isAuthenticatedPromise;
    };

    public setFmaRedirectUrl(url: string): void {
        const origin = `${window.location.protocol}//${
            window.location.hostname
        }${new AppConfigurationService().currentRoot}`;
        if (this.win?.fma) {
            this.win.fma.model.config.redirectUrl = url;
            // set logout and origin URLs if missing(in case of syndicated header)
            !this.win.fma.model.config.logoutURL &&
                (this.win.fma.model.config.logoutURL = origin);
            !this.win.fma.model.config.originURL &&
                (this.win.fma.model.config.originURL = '');
        }
    }

    public setFmaState() {
        if (this.win?.fma) {
            const pathName = this.getPathName();
            const {
                domain,
                root,
                brand,
                currentLanguageRegionCode,
            } = this.win.SERVER_CONTEXT;
            if (domain?.length > 1) {
                this.win.fma.model.config.state = this.getAkamaiState(
                    pathName,
                    root
                );
            } else {
                this.win.fma.model.config.state = this.getOriginState(
                    pathName,
                    root,
                    brand,
                    currentLanguageRegionCode
                );
            }
        }
    }

    private getPathName(): string {
        let pathName = window.location.pathname + window.location.search;
        if (!pathName.endsWith('/') && !pathName.includes('?')) {
            pathName += '/';
        }
        return pathName;
    }

    private getAkamaiState(pathName: string, root: string): string {
        this.win.fma.model.config.redirectUrl = window.location.origin + root;
        let state = pathName.replace(root, '');
        if (state?.split('?')[0].length <= 1) {
            state = findByAlias('VehicleDashboardView');
        } else if (!state.startsWith('/')) {
            state = '/' + state;
        }
        return state;
    }

    private getOriginState(
        pathName: string,
        root: string,
        brand: string,
        currentLanguageRegionCode: string
    ): string {
        this.win.fma.model.config.redirectUrl = window.location.origin;
        let state = pathName;
        if (state?.split('?')[0] === root) {
            state = `/${brand}/${currentLanguageRegionCode}${findByAlias(
                'VehicleDashboardView'
            )}`;
        }
        return state + (state.includes('?') ? '&origin=yes' : '?origin=yes');
    }

    public getFmaState(): string | undefined {
        if (this.win?.fma) {
            return this.win?.fma.state;
        }
    }

    public static getIbmUrl(brand: string) {
        let ssoEnv = 'qa';
        if (
            process.env.REACT_APP_ACTIVE_PROFILE &&
            process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
        ) {
            ssoEnv = '';
        }
        return `https://sso${ssoEnv}.ci.${brand}.com`;
    }
}
