import React, { useEffect, useState } from 'react';
import { FdsChevron } from '../../common/chevron/fds-chevron';
import { useDisclosuresLinkContent } from './hook/use-disclosures-link-content';
import './disclosures-link.scss';

interface Props {
    class?: string;
    disclosuresType?: string;
}

export const DisclosuresLink = (props: Props) => {
    const disclosuresLinkContent = props.disclosuresType
        ? useDisclosuresLinkContent(props.disclosuresType)
        : useDisclosuresLinkContent();
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
    useEffect(() => {
        if (disclosuresLinkContent?.defaultStateOpen) {
            setPanelIsOpen(!panelIsOpen);
        }
    }, [disclosuresLinkContent?.defaultStateOpen]);

    return (
        disclosuresLinkContent && (
            <>
                <div className="disclosures-button-section">
                    <div className="disclosures-container">
                        <div className="disclosures-button">
                            <button
                                className="disclosures-button-link"
                                onClick={() => {
                                    setPanelIsOpen(!panelIsOpen);
                                }}
                                tabIndex={0}
                            >
                                {disclosuresLinkContent.ctaLinkText}
                                <FdsChevron
                                    type="unfilled"
                                    direction={panelIsOpen ? 'up' : 'down'}
                                />
                            </button>
                            {panelIsOpen && (
                                <div
                                    className={`disclosure-content ${
                                        props.class ? props.class : ''
                                    }`}
                                    tabIndex={0}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                disclosuresLinkContent.content,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    );
};
