import { useContent } from '../../../../hooks/use-server-content';

export interface GenericAppErrorContentProps {
    genericErrorMessage: string;
}

export const useGenericAppErrorContent = (): GenericAppErrorContentProps => {
    const [content, getValueByName] = useContent('common', 'generic-app-error');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as GenericAppErrorContentProps;
};
