import React from 'react';

import { HeroTile } from './hero-tile/hero-tile';

import './hero-tiles.scss';
import { HelpCardsProps } from '../../../views/page-not-found-view/hooks/use-help-cards-content';

interface Props {
    helpCardsContent: HelpCardsProps;
}

export const HeroTiles = (props: Props) => {
    return (
        props.helpCardsContent && (
            <div className="hero-tiles-container">
                <div className="tile-grid">
                    <div id="SH1">
                        <HeroTile
                            title={
                                props.helpCardsContent
                                    .myFordVehicleHelpCardTitle
                            }
                            link={
                                props.helpCardsContent.myFordVehicleHelpCardURL
                            }
                            imageUrl={
                                process.env.REACT_APP_AEM_BASE_URL +
                                props.helpCardsContent.myFordVehicleHelpCardIcon
                            }
                            linkTarget={
                                props.helpCardsContent
                                    .myFordVehicleHelpCardTarget
                            }
                            ariaLabel={
                                props.helpCardsContent
                                    .myFordVehicleHelpCardAriaLabel
                            }
                        />
                    </div>
                    <div id="SH2">
                        <HeroTile
                            title={
                                props.helpCardsContent.contactUsHelpCardTitle
                            }
                            link={props.helpCardsContent.contactUsHelpCardURL}
                            imageUrl={
                                process.env.REACT_APP_AEM_BASE_URL +
                                props.helpCardsContent.contactUsHelpCardIcon
                            }
                            linkTarget={
                                props.helpCardsContent.contactUsHelpCardTarget
                            }
                            ariaLabel={
                                props.helpCardsContent.contactUsHelpCarAriaLabel
                            }
                        />
                    </div>
                    <div id="SH3">
                        <HeroTile
                            title={
                                props.helpCardsContent.findVehicleHelpCardTitle
                            }
                            link={props.helpCardsContent.findVehicleHelpCardURL}
                            imageUrl={
                                process.env.REACT_APP_AEM_BASE_URL +
                                props.helpCardsContent.findVehicleHelpCardIcon
                            }
                            linkTarget={
                                props.helpCardsContent.findVehicleHelpCardTarget
                            }
                            eventName="global-find-a-dealer"
                            ariaLabel={
                                props.helpCardsContent
                                    .findVehicleHelpCardAriaLabel
                            }
                        />
                    </div>
                </div>
            </div>
        )
    );
};
