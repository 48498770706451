import React from 'react';
import { UpcomingEvent } from './components/upcoming-event';
import { VideoTiles } from './components/video-tiles/video-tiles';
import './live-stream.scss';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useAnalytics } from '../../../hooks/use-analytics';
import { useShortcodeProvider } from '../../../hooks/use-shortcode-provider';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import useSeoConfig from '../../../hooks/use-seo-config';
import { SmashCard } from '../../../views/home-page/components/smash-card/smash-card';
import { useDfyLivestream } from './hooks/use-dyf-livestream-view';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { useLiveStreamViewContent } from '../../../views/live-stream-view/hook/use-live-stream-view';
import { getLiveStreamDetails } from './live-stream-builder';
import { DyfOnboardingScheduleAemContent } from '../gvt/gvt-interfaces';
import UpcomingLivestream from '../gvt/upcoming-livestream/upcoming-livestream';
import { CategoryQuietCards } from '../category/categories/quiet-cards/category-quiet-cards';

interface Props {
    vehicleAttributes: VehicleAttributes;
}

export const LiveStream = (props: Props) => {
    const dyfComponentName = {
        dyfComponentName: 'livestream',
        ymm: useShortcodeProvider(props.vehicleAttributes)[0],
    };
    const modelNotSupportedContent = useLiveStreamViewContent();
    const smashCardGvt = useDfyLivestream(
        'vehicle-data',
        getVehicleDataSelector('smash-card', props.vehicleAttributes),
        'article',
        true
    );
    const dyfRecordedLivestream = useDfyLivestream(
        'vehicle-data',
        getVehicleDataSelector(
            'dyf-recorded-live-stream',
            props.vehicleAttributes
        ),
        'article',
        true
    );
    const [gvtSchedulingContent] = useExperienceContent<
        DyfOnboardingScheduleAemContent
    >(
        'vehicles',
        'dyf-onboarding-scheduling',
        'dyf_onboarding_sched',
        undefined,
        false
    );

    useAnalytics(['dyf-generic'], undefined, dyfComponentName);
    useSeoConfig('livestream', 'dyf', [
        {
            fieldName: 'ymm',
            fieldValue: `${props.vehicleAttributes.year} ${props.vehicleAttributes.make} ${props.vehicleAttributes.model}`,
        },
    ]);
    return (
        gvtSchedulingContent && (
            <div className="live-stream-view-wrapper">
                <UpcomingEvent
                    livestream={getLiveStreamDetails(
                        props.vehicleAttributes.model
                    )}
                />
                <div className="live-stream-content-wrapper">
                    <UpcomingLivestream
                        vehicleAttributes={props.vehicleAttributes}
                        modelNotSupportedContent={modelNotSupportedContent}
                    />
                    {dyfRecordedLivestream && (
                        <CategoryQuietCards
                            articles={[{ ...dyfRecordedLivestream }]}
                            buttonFill={'outline'}
                            eventNames={'dyf-recorded-live-stream-button'}
                        />
                    )}
                    {smashCardGvt && <SmashCard {...smashCardGvt} />}
                </div>
                <VideoTiles
                    vehicleAttributes={props.vehicleAttributes}
                    seoKey={props.vehicleAttributes.seoKey}
                    origin={'livestream'}
                />
            </div>
        )
    );
};
