import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './ute-vehicle-wrapper.ford.scss';
import { UTEVehicleSelector } from '../ute-vehicle-selector/ute-vehicle-selector';
import { UteLanding } from '../ute-landing/ute-landing';
import { useContentFragment } from '../../../../utils/aem-content-utils/use-content-fragment';
import { getVin } from '../../../../utils/getVin-util/getVin-util';
import { UTEVehicleSelectorContentProps } from '../../../../../models/service-maintenance-models/ute';
import { UteContext } from '../../../../../views/ultimate-towing-experience-view/context/ute-context';

export interface Props {
    profileContent: any;
    uteExistingTrailerProfiles: any;
    handleBreadCrumb: (
        currentPage: string,
        pageTitle: string,
        isPopup: boolean
    ) => void;
}
export const UteVehicleWrapper = (props: Props) => {
    const uteVehicleSelectorContent: UTEVehicleSelectorContentProps = useContentFragment(
        'vehicles',
        'ultimate-towing-experience/ute-vehicle-selector'
    );
    const location = useLocation<any>();
    const { setUteContextFormData } = useContext(UteContext);
    const [isSelectedVinValid, setIsSelectedVinValid] = useState<boolean>(true);
    const [isValidVinError, setisValidVinError] = useState<boolean>(false);
    const [successMessageFlag, setSuccessMessageFlag] = useState<boolean>(
        false
    );
    const userSelectedVin = getVin();
    const { pageTitle } = props.profileContent;
    const isValidVin = (state: boolean) => {
        setisValidVinError(!state);
        setIsSelectedVinValid(state);
    };
    const setFormEmpty = () => {
        setUteContextFormData({
            name: '',
            trailerList: {
                mfgName: '',
                mfgMake: '',
                mfgModel: '',
                mfgYear: '',
                smartNavLength: '',
                smartNavWidth: '',
                smartNavHeight: '',
                smartNavWeight: '',
                trailerCameraType: '0',
                trailerTpms: '0',
                trailerFrontalArea: '',
                trailerAxleCount: '0',
                trailerCouplerAxle: '',
                type: '',
                brakeType: '',
                distance: '',
            },
            success: false,
            errors: {
                mfgName: false,
                mfgMake: false,
                mfgModel: false,
                mfgYear: false,
            },
        });
    };
    useEffect(() => {
        const isValid = userSelectedVin != undefined && userSelectedVin != '';

        if (isValid) {
            setIsSelectedVinValid(true);
        } else {
            setIsSelectedVinValid(false);
        }
        props.handleBreadCrumb(pageTitle, '', true);
    }, [pageTitle]);
    useEffect(() => {
        setFormEmpty();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        if (location.state) {
            setSuccessMessageFlag(location.state.data);
        }
    }, [location]);

    return (
        <>
            {!isSelectedVinValid ? (
                <section className="ute-wrapper-section">
                    <section className="ute-heading-section">
                        <h1 className="ute-header">{pageTitle}</h1>
                    </section>
                    <div className="ute-content">
                        <div
                            className={
                                isValidVinError ? 'ute-desc-error' : 'ute-desc'
                            }
                        >
                            <p>{uteVehicleSelectorContent.uteDescription}</p>
                        </div>

                        <div>
                            <UTEVehicleSelector
                                contentLabels={uteVehicleSelectorContent}
                                isValidVin={isValidVin}
                            />
                        </div>
                    </div>
                </section>
            ) : (
                <UteLanding
                    uteExistingTrailerProfiles={
                        props.uteExistingTrailerProfiles
                    }
                    profileContent={props.profileContent}
                    successMessageFlag={successMessageFlag}
                />
            )}
        </>
    );
};
