export enum DeviceType {
    IOS = 'ios',
    ANDROID = 'android',
    UNDETERMINED = 'undetermined-device',
}

const CheckDeviceType = (): DeviceType => {
    if (!navigator || !navigator.userAgent) return DeviceType.UNDETERMINED;
    let deviceType: DeviceType = DeviceType.UNDETERMINED;
    if (/iphone|ipod|ipad|mini/i.test(navigator.userAgent.toLowerCase())) {
        deviceType = DeviceType.IOS;
    } else if (/android/i.test(navigator.userAgent.toLowerCase())) {
        deviceType = DeviceType.ANDROID;
    }
    return deviceType;
};

export default CheckDeviceType;
