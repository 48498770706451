import React from 'react';
import { FdsChevron } from '../../../../common/chevron/fds-chevron';
import './osb-secondary-button-lincoln.scss';

type ButtonDirection = 'up' | 'down' | 'left' | 'right';
type ButtonType = 'filled' | 'unfilled' | 'svg';

interface Props {
    onClickHandler: any;
    text: string;
    direction: ButtonDirection;
    type: ButtonType;
    dataTestId?: string;
}

const leftArrowIcon = './icons/left-arrow.svg';

export const OSBSecondaryButton = (props: Props) => {
    return (
        <div className="osb-secondary-button">
            <a onClick={props.onClickHandler} data-testid={props.dataTestId}>
                <ul>
                    {(props.direction === 'right' ||
                        props.direction === 'up' ||
                        props.direction === 'down') && <li>{props.text}</li>}
                    <li>
                        {props.type === 'svg' && props.direction === 'left' ? (
                            <div className="bttonIcon">
                                <img src={leftArrowIcon} alt="SVG Image" />
                            </div>
                        ) : (
                            <FdsChevron
                                type={props.type}
                                direction={props.direction}
                            />
                        )}
                    </li>
                    {props.direction === 'left' && <li>{props.text}</li>}
                </ul>
            </a>
        </div>
    );
};
