import { HttpsProxyAgent } from 'https-proxy-agent';
import { VehicleYmmGroup } from '../../models/vehicle-ymm';
import HttpService from '../http-service/http-service';

export interface AEMVehicle {
    id: string;
    ptsMarketName: string;
    ptsModelName?: string;
    seoKey: string;
    displayModelName: string;
    displayMode: boolean;
    source: string;
}
export const ALLOWED_COUNTRIES = ['us', 'ca'];
export const YEARS_TO_DISPLAY = 10;
export class VehicleListServiceAem {
    async getVehicles(
        brand: string,
        countryCode: string,
        languageRegionCode: string,
        returnFullVehicleList = false
    ): Promise<VehicleYmmGroup[]> {
        const vehiclesListByYear: VehicleYmmGroup[] = [];
        const httpsAgent = new HttpsProxyAgent(
            process.env.http_proxy || 'http://internet.ford.com:83'
        );
        const response = HttpService.get(
            process.env.REACT_APP_AEM_BASE_URL +
                `/content/experience-fragments/global-owner/${brand.toLowerCase()}/${countryCode}/${languageRegionCode}/vehicles/pts_vehicles/pts-vehicles/master.model.json`,
            true,
            { httpsAgent }
        );
        response.catch(err => {
            console.error(
                'Error retrieving vehicles list from AEM for ',
                countryCode,
                languageRegionCode
            );
            console.error(err);
            return Promise.resolve(vehiclesListByYear);
        });
        return response.then(axiosResponse => {
            let items = axiosResponse.data[':items'];
            if (
                !returnFullVehicleList &&
                ALLOWED_COUNTRIES.includes(countryCode)
            ) {
                const years: string[] = Object.keys(items);
                items = years
                    .filter((year: string) => {
                        return (
                            parseInt(year) >
                            new Date().getFullYear() - YEARS_TO_DISPLAY
                        );
                    })
                    .reduce((cur, key: string) => {
                        return Object.assign(cur, { [key]: items[key] });
                    }, {});
            }
            if (items) {
                for (const year in items) {
                    if (Object.prototype.hasOwnProperty.call(items, year)) {
                        const aemVehicles = items[year][
                            'vehicles'
                        ] as AEMVehicle[];
                        if (aemVehicles && aemVehicles.length > 0) {
                            vehiclesListByYear.push({
                                year: Number.parseInt(year),
                                vehicles: aemVehicles
                                    .filter(
                                        aemVehicle => aemVehicle.displayMode
                                    )
                                    .map(aemVehicle => {
                                        return {
                                            year: Number.parseInt(year),
                                            marketName:
                                                aemVehicle.ptsMarketName,
                                            model: aemVehicle.displayModelName,
                                            make: brand,
                                            seoKey: aemVehicle.seoKey,
                                            id: aemVehicle.id,
                                        };
                                    })
                                    .sort((a, b) =>
                                        a.seoKey.localeCompare(b.seoKey)
                                    ),
                            });
                        }
                    }
                }
            }
            return Promise.resolve(vehiclesListByYear);
        });
    }
}
