import React, { useEffect, useState } from 'react';
import './warranty-quick-guide-accordion.scss';
import './warranty-quick-guide-accordion.lincoln.scss';
import { Accordion } from '../../../common';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
interface Props {
    warrantyQuickGuideReferences: RefData[];
}
export interface RefData {
    name: string;
    elements: Elements[];
}
export interface Elements {
    value: any;
}
export const accordionDetails = (details: any) => {
    return (
        <span
            className={'warranty-guide-accordion-details'}
            dangerouslySetInnerHTML={{
                __html: details,
            }}
        ></span>
    );
};

export const WarrantyQuickGuideAccordion = (props: Props) => {
    const [
        warrantyQuickGuideReferences,
        setWarrantyQuickGuideReferences,
    ] = useState<RefData[]>();

    useEffect(() => {
        //To render accordions if all 7 CF content is available
        if (
            props.warrantyQuickGuideReferences &&
            Array.isArray(props.warrantyQuickGuideReferences) &&
            props.warrantyQuickGuideReferences.length === 7
        ) {
            setWarrantyQuickGuideReferences(props.warrantyQuickGuideReferences);
        }
    }, [props.warrantyQuickGuideReferences]);

    return (
        <section className="warranty-quick-guide-accordion">
            {warrantyQuickGuideReferences ? (
                warrantyQuickGuideReferences.map((refObj, key) => (
                    <Accordion
                        key={refObj.name}
                        header={refObj.elements[0].value}
                        panel={accordionDetails(refObj.elements[1].value)}
                        className={'warranty-quick-guide'}
                        index={key.toString()}
                        expandMultiplePanels={true}
                    />
                ))
            ) : (
                <ActivityIndicator />
            )}
        </section>
    );
};
