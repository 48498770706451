import { useContent } from '../../../../hooks/use-server-content';

export interface TireFinderCaptionProps {
    tireFinderCaption: string;
}

export const useTireFinderCaption = (): TireFinderCaptionProps => {
    const [content, getValueByName] = useContent('tirefinder', 'tire-caption');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as TireFinderCaptionProps;
};
