import { CardState } from '../../../../../models/service-maintenance-models/vehicle-health-alerts';
import { replacePlaceholderByValue } from '../../../../utils/placeholder-util/replace-placeholder-by-value';

export const getStatusTextStyle = (alertState: string) => {
    if (alertState === CardState.ALERT) {
        return 'alert-status-text';
    } else if (alertState === CardState.WARNING) {
        return 'warning-status-text';
    } else {
        return 'normal-status-text';
    }
};

export const getVehicleCondition = (
    alertState: string,
    vehicleName: string,
    alertDescription: any
) => {
    if (alertState === CardState.ALERT) {
        return replacePlaceholderByValue(
            alertDescription.vehicleHealthAlertDescription,
            {
                vehicleName: vehicleName,
            }
        );
    } else if (alertState === CardState.WARNING) {
        return replacePlaceholderByValue(
            alertDescription.vehicleHealthWarningDescription,
            {
                vehicleName: vehicleName,
            }
        );
    } else {
        return replacePlaceholderByValue(
            alertDescription.vehicleHealthSuccessDescription,
            {
                vehicleName: vehicleName,
            }
        );
    }
};

export const getVehicleHealthDescription = (
    alertState: string,
    alertStatusList: any,
    content: any
) => {
    if (alertState === CardState.ALERT) {
        return content.vehicleHealthAlertText;
    } else if (alertState === CardState.WARNING) {
        const warningCount = alertStatusList.filter(
            (status: string) => status === 'WARNING'
        ).length;
        return replacePlaceholderByValue(content.vehicleHealthWarningText, {
            warningCount: warningCount.toString(),
        });
    } else {
        return content.vehicleHealthSuccessText;
    }
};
