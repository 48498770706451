import { ProfileWithVehiclesResponse } from '../../models/profile-with-vehicles';
import PreferredDealerService from '../../services/preferred-dealer/preferred-dealer';

const getPreferredDealer = (
    profile: ProfileWithVehiclesResponse,
    vin: string | undefined
) => {
    if (profile && profile?.vehicles?.length > 0) {
        const vehicleData = profile.vehicles.find(
            (vehicle: any) => vehicle.vin === vin
        );
        if (vehicleData?.preferredDealer) {
            return vehicleData.preferredDealer;
        }
    }
    return '';
};

export const getSmartProviderStatus = (
    profile: ProfileWithVehiclesResponse,
    vin: string | undefined
) => {
    if (!vin) {
        return '';
    }
    const preferredDealer = getPreferredDealer(profile, vin);
    if (!preferredDealer) {
        return '';
    }
    return new PreferredDealerService()
        .request(preferredDealer)

        .then(response => {
            if (
                response?.dealerResultList &&
                response?.dealerResultList.length > 0
            ) {
                return response?.dealerResultList[0]?.smarttprovider;
            }
            return '';
        })
        .catch(error => {
            console?.log(
                'ServiceCX - Error retrieving dealer code: ' +
                    preferredDealer +
                    '. Error: ' +
                    error
            );
            return '';
        });
};
