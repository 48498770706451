import React, { useState, useContext } from 'react';

import { useExperienceContent } from '../../../../hooks/use-server-content';
import {
    Category,
    CategoryItem,
    HeaderFragment,
    MainNavLeft,
    UtilityLink,
} from '../../../../models/experiencefragments/header';
import { Link } from '../../../common';
import { LanguageToggle } from '../../language-toggle/language-toggle';
import './lincoln-na-header.scss';
import { Login } from '../login/login';
import { SearchLincoln } from '../search/search';
import Hamburger from 'hamburger-react';
import { BRAND } from '../../../../constants';
import ServerContext from '../../../../contexts/serverContext';

const LincolnNAHeader = () => {
    const serverContext = useContext(ServerContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [currentOpenedFlyout, setCurrentOpenedFlyout] = useState(-1);
    const lincolnBrand =
        serverContext.brand == BRAND.lincolndemo.LONG_NAME
            ? BRAND.lincolndemo.LONG_NAME
            : BRAND.lincoln.LONG_NAME;
    const [experienceContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header',
        lincolnBrand
    );

    const removeFlyoutOpenClass = () => {
        document
            .querySelectorAll(
                '.lincoln-na-header .main-navigation-items .main-nav-item'
            )
            ?.forEach(value => {
                value.classList.remove('flyout-open');
            });
    };
    const openFlyout = (
        flyoutItemIndex: number,
        currentEle?: HTMLButtonElement
    ) => {
        const headerEle = document.querySelector(
            '.lincoln-na-header'
        ) as HTMLDivElement;
        const containerElem = document.querySelector(
            '.lincoln-na-header .flyout-container'
        ) as HTMLDivElement;
        const selectedFlyoutItem = containerElem.querySelector(
            '#fgx-main-nav-flyout_item-' + flyoutItemIndex
        );
        const overLayEle = document.querySelector(
            '.lincoln-na-header-overlay'
        ) as HTMLDivElement;
        containerElem.querySelectorAll('.flyout-menu-item')?.forEach(value => {
            value.classList.add('hide-me');
        });

        if (currentEle) {
            removeFlyoutOpenClass();
            currentEle?.parentElement?.classList.add('flyout-open');
        }

        selectedFlyoutItem?.classList?.remove('hide-me');
        if (currentOpenedFlyout === flyoutItemIndex) {
            containerElem?.classList?.toggle('expanded');
            overLayEle?.classList?.toggle('open');
            headerEle?.classList?.toggle('flyout-opened');
        } else {
            containerElem?.classList?.add('expanded');
            overLayEle?.classList?.add('open');
            headerEle?.classList?.add('flyout-opened');
        }

        setCurrentOpenedFlyout(flyoutItemIndex);
    };

    function toggleMenu() {
        const containerElem = document.querySelector(
            '.lincoln-na-header .menu-items-container'
        ) as HTMLDivElement;
        if (currentOpenedFlyout !== -1) {
            openFlyout(currentOpenedFlyout);
            setCurrentOpenedFlyout(-1);
        }
        const bodyElem = document.querySelector('body') as HTMLBodyElement;
        bodyElem.classList.toggle('no-scroll-sm');
        containerElem?.classList?.toggle('expanded');
        removeFlyoutOpenClass();
        containerElem
            ?.querySelector('.main-nav-item')
            ?.classList.add('flyout-open');
        setMenuOpen(!menuOpen);
    }

    const closeFlyoutOverlay = () => {
        openFlyout(currentOpenedFlyout);
    };
    const backToPreviousNav = () => {
        openFlyout(currentOpenedFlyout);
        setCurrentOpenedFlyout(-1);
    };

    const getMainNavItemsRenderer = (
        mainNavLeft: MainNavLeft,
        index: number
    ) => {
        return (
            <li
                id={`main-nav-left-${index}`}
                className="main-nav-item"
                aria-label={mainNavLeft.title}
                key={index}
            >
                {mainNavLeft.noFlyout ? (
                    <Link
                        className="main-item padding"
                        href={mainNavLeft.url}
                        aria-label={mainNavLeft.ariaLabel}
                    >
                        <span className="main-item-button ">
                            {mainNavLeft.title}
                        </span>
                        <i
                            className="icon iconl-chevron-toggle hidden-xs"
                            aria-hidden="true"
                        />
                        <i
                            className="icon iconl-linc1638-lw-icons-38px-c16-expanded-35 visible-xs"
                            aria-hidden="true"
                        />
                    </Link>
                ) : (
                    <button
                        aria-label={mainNavLeft.ariaLabel}
                        onClick={event =>
                            openFlyout(index, event.currentTarget)
                        }
                        className="main-item"
                    >
                        <span className="main-item-button">
                            {mainNavLeft.title}
                        </span>
                        <i
                            className="icon iconl-chevron-toggle hidden-xs"
                            aria-hidden="true"
                        />
                        <i
                            className="icon iconl-linc1638-lw-icons-38px-c16-expanded-35 visible-xs"
                            aria-hidden="true"
                        />
                    </button>
                )}
            </li>
        );
    };

    const getUtilityLinksRenderer = (nav: UtilityLink, index: number) => {
        return (
            <li className="main-nav-item">
                <Link key={index} className="utility-item" href={nav.url}>
                    <i className="icon linc1638-lw-icons-38px-c16-expanded-07" />
                    <span className="link-text">{nav.title}</span>
                </Link>
            </li>
        );
    };

    const getThumbnailsSectionRenderer = (
        category: CategoryItem,
        categoryIndex: number
    ) => {
        return (
            <li key={`category-0-${categoryIndex}`} className="column-btn-item">
                <Link
                    href={category.url}
                    className={
                        category.thumbnailImagePath
                            ? 'enhanced-btn has-bg-image'
                            : 'enhanced-btn'
                    }
                    target={category.targetBlank ? '_blank' : '_self'}
                >
                    {category.thumbnailImagePath ? (
                        <div>
                            <div className="enhanced-btn-img-bg">
                                <img
                                    alt={category.ariaLabel}
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        category.thumbnailImagePath
                                    }
                                />
                            </div>
                            <div className="enhanced-btn-content">
                                <p className="btn-headline">{category.title}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="enhanced-btn-content">
                            {category.icon ? (
                                <i
                                    className={`icon enhanced-btn-icon fgx-nav-icon ${category.icon}`}
                                    aria-hidden="true"
                                />
                            ) : null}

                            <p className="btn-headline">{category.title}</p>
                        </div>
                    )}
                </Link>
            </li>
        );
    };

    const getFlyoutLinkColumnItemsRenderer = (
        category: Category,
        categoryIndex: number
    ) => {
        return (
            <div key={categoryIndex} className="column-item column-left">
                <h3 className="column-item-headline">
                    {category.categoryTitle}
                </h3>
                <ul className="column-link-list">
                    {category.categoryItems.map(
                        (categoryItem, categoryItemIndex) => {
                            return (
                                <li
                                    key={categoryItemIndex}
                                    className="column-link-item "
                                >
                                    <Link
                                        href={categoryItem.url}
                                        className="btn-icon-no-underline"
                                        target={
                                            categoryItem.targetBlank
                                                ? '_blank'
                                                : '_self'
                                        }
                                    >
                                        <span className="cta-text">
                                            {categoryItem.title}
                                        </span>
                                        <span
                                            className={`${
                                                categoryItem.targetBlank
                                                    ? 'icon icon-new-window-25px'
                                                    : 'icon icon-chevron-outline-25px'
                                            }
                                                `}
                                            aria-hidden="true"
                                        />
                                    </Link>
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
        );
    };

    const getFlyoutMenuItems = (mainNavLeft: MainNavLeft, index: number) => {
        if (!mainNavLeft.noFlyout)
            return (
                <div
                    key={index}
                    className="flyout-menu-item hide-me"
                    id={`fgx-main-nav-flyout_item-${index}`}
                >
                    <div className="flyout-menu-item-content">
                        <div className="heading-container">
                            <h2 className="flyout-menu-item-headline">
                                {mainNavLeft.categories[0].categoryTitle}
                            </h2>
                        </div>
                        <div className="column-wrap-container">
                            <div className="btn-columns-wrap">
                                <div className="column-item column-left">
                                    <ul className="column-btn-list">
                                        {mainNavLeft.categories[0].categoryItems.map(
                                            getThumbnailsSectionRenderer
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="link-columns-wrap">
                                {mainNavLeft.categories
                                    .filter((category, categoryIndex) => {
                                        return categoryIndex !== 0;
                                    })
                                    .map(getFlyoutLinkColumnItemsRenderer)}
                            </div>
                        </div>
                    </div>
                </div>
            );
    };

    return (
        <>
            {experienceContent && (
                <header>
                    <div
                        onClick={() => closeFlyoutOverlay()}
                        className="lincoln-na-header-overlay"
                    />
                    <div className="lincoln-na-header header-height">
                        <nav className="navigation-bar">
                            <div className="container">
                                <div className="logo">
                                    <Link
                                        tabIndex={0}
                                        href={experienceContent.logo.url}
                                        target={
                                            experienceContent.logo.targetBlank
                                                ? '_blank'
                                                : ''
                                        }
                                        className="logo-link"
                                        aria-label={
                                            experienceContent.logo.ariaLabel
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            className="lincoln-logo"
                                            style={{
                                                backgroundImage: `url('https://www.lincoln.com/cmslibs/content/dam/brand_lincoln/en_us/brand/common/LINC_Hz_K_R03.png')`,
                                            }}
                                        />
                                    </Link>
                                    <div data-testid="menu-toggle">
                                        <Hamburger
                                            direction="right"
                                            toggled={menuOpen}
                                            toggle={toggleMenu}
                                            size={35}
                                            color="#324047"
                                        />
                                    </div>
                                </div>
                                <div className="menu-items-container collapsed">
                                    <div className="menu-items-wrap">
                                        <div className="logo hidden-xs">
                                            <a
                                                tabIndex={0}
                                                href={
                                                    experienceContent.logo.url
                                                }
                                                target={
                                                    experienceContent.logo
                                                        .targetBlank
                                                        ? '_blank'
                                                        : ''
                                                }
                                                className="logo-link"
                                                aria-label={
                                                    experienceContent.logo
                                                        .ariaLabel
                                                }
                                                rel="noopener noreferrer"
                                            >
                                                <span
                                                    className="lincoln-logo"
                                                    style={{
                                                        backgroundImage: `url('https://www.lincoln.com/cmslibs/content/dam/brand_lincoln/en_us/brand/common/LINC_Hz_K_R03.png')`,
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <ul className="main-navigation-items navbar-content left-nav-items  ">
                                            {experienceContent[
                                                'main-nav-left'
                                            ].map(getMainNavItemsRenderer)}
                                        </ul>
                                        <ul className="main-navigation-items navbar-content utility-list">
                                            <li className="main-nav-item">
                                                <Login
                                                    content={
                                                        experienceContent[
                                                            'main-nav-right'
                                                        ].signIn
                                                    }
                                                />
                                            </li>
                                            {experienceContent[
                                                'main-nav-right'
                                            ].utilityLinks.map(
                                                getUtilityLinksRenderer
                                            )}
                                            <li className="main-nav-item search-item">
                                                <SearchLincoln
                                                    className="navbar-form"
                                                    content={
                                                        experienceContent[
                                                            'main-nav-right'
                                                        ].search
                                                    }
                                                />
                                            </li>
                                            <li className="main-nav-item language-item">
                                                <LanguageToggle tabIndex={0} />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flyout-container collapsed">
                                        <div className="flyout-header visible-xs">
                                            <button
                                                className="flyout-header-item flyout-back"
                                                aria-label="back"
                                                onClick={() =>
                                                    backToPreviousNav()
                                                }
                                            >
                                                <span
                                                    className="icon iconl-linc1638-lw-icons-38px-c16-expanded-35 back-arrow"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                            <button
                                                className="flyout-close flyout-header-item"
                                                aria-label="close"
                                                onClick={toggleMenu}
                                            >
                                                <span
                                                    className="icon iconl-linc1638-lw-icons-38px-c16-expanded-42"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>

                                        {experienceContent['main-nav-left'].map(
                                            getFlyoutMenuItems
                                        )}
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            )}
        </>
    );
};
export default LincolnNAHeader;
