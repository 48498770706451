import { FeatureCardsFragment } from '../../../components/sections/vehicle-portal/services/feature-cards/feature-card-article-service';
import {
    CategoryViewFragment,
    CategoryViewContent,
    AemVideoQuietCardDetails,
    VideoQuietCardDetails,
} from '../../../models/experiencefragments/category-view';
import {
    DisclaimersService,
    DisclaimerContent,
    ProcessedField,
} from './../../../services/disclaimers-service/disclaimers-service';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

export interface UseCategoryViewContentReturn {
    disclaimers: DisclaimerContent[];
    content: CategoryViewContent;
}

const processCardFields = (card: FMCardProps, fields: any) => {
    return {
        ...card,
        title: fields[0]?.plain,
        titleWithDisclaimer: fields[0]?.node,
        description: fields[1]?.plain,
        descriptionWithDisclaimer: fields[1]?.node,
    };
};

const getString = (processedFieldsString: string | undefined) => {
    return processedFieldsString || '';
};

const isVisible = (fragment: any) => {
    return fragment && !fragment.hide;
};

export function useCategoryViewContent(
    categoryViewFragment: CategoryViewFragment
): UseCategoryViewContentReturn | undefined {
    const content: CategoryViewContent = {};
    let disclaimers: DisclaimerContent[] = [];

    if (!categoryViewFragment) return;

    const featureTrioContent = (
        processedFeatureTrioContent: FeatureCardsFragment
    ): FeatureCardsFragment | undefined => {
        if (!processedFeatureTrioContent) return;
        const [
            processedFields,
            processedDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['title', 'subtitle'],
            'disclaimer',
            disclaimers,
            processedFeatureTrioContent
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedCards,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                processedFeatureTrioContent.cards,
                (card, fields) => processCardFields(card, fields)
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            return {
                ...processedFeatureTrioContent,
                title: getString(processedFields[0]?.plain),
                titleWithDisclaimer: processedFields[0]?.node,
                subtitle: getString(processedFields[1]?.plain),
                subtitleWithDisclaimer: processedFields[1]?.node,
                cards: processedCards,
            };
        }
    };

    const processSingleContent = (
        fragment: any,
        processFn: Function,
        contentKey: keyof CategoryViewContent
    ) => {
        if (!isVisible(fragment)) return;

        const [processedContent, processedDisclaimers] = processFn(
            fragment,
            disclaimers
        );
        content[contentKey] = processedContent;
        disclaimers = disclaimers.concat(processedDisclaimers);
    };

    const processMultipleContents = (
        fragment: any,
        processFn: Function,
        contentKey: keyof CategoryViewContent
    ) => {
        if (!isVisible(fragment)) return;

        const [processedContents, processedDisclaimers] = processFn(
            fragment.articles,
            disclaimers
        );
        content[contentKey] = {
            ...fragment,
            articles: processedContents,
        };
        disclaimers = disclaimers.concat(processedDisclaimers);
    };

    const processFeatureTrio = (
        fragment: any,
        processFn: Function,
        contentKey: keyof CategoryViewContent
    ) => {
        if (!isVisible(fragment)) return;
        content[contentKey] = processFn(fragment);
    };

    processSingleContent(
        categoryViewFragment.masthead,
        DisclaimersService.processArticle,
        'masthead'
    );

    processFeatureTrio(
        categoryViewFragment.featureTrioOne,
        featureTrioContent,
        'featureTrioOne'
    );

    if (
        isVisible(categoryViewFragment.videoQuietCard) &&
        categoryViewFragment.videoQuietCard?.videoQuietCardDetails
    ) {
        const [
            processedVideoQuietCardDetails,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers<
            AemVideoQuietCardDetails,
            VideoQuietCardDetails,
            keyof AemVideoQuietCardDetails
        >(
            ['title', 'description'],
            'disclaimer',
            disclaimers,
            categoryViewFragment.videoQuietCard.videoQuietCardDetails,
            (item, fields) => {
                return {
                    ...item,
                    title: fields[0]?.node,
                    description: fields[1]?.node,
                };
            }
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        content.videoQuietCard = {
            videoQuietCardDetails: processedVideoQuietCardDetails,
        };
    }

    processMultipleContents(
        categoryViewFragment.fiftyFiftyCarousel,
        DisclaimersService.processArticles,
        'fiftyFiftyCarousel'
    );

    processMultipleContents(
        categoryViewFragment.quietCards,
        DisclaimersService.processArticles,
        'quietCards'
    );

    processSingleContent(
        categoryViewFragment.componentBox5050,
        DisclaimersService.processArticle,
        'componentBox5050'
    );

    processFeatureTrio(
        categoryViewFragment.featureTrioTwo,
        featureTrioContent,
        'featureTrioTwo'
    );

    processFeatureTrio(
        categoryViewFragment.articlesCarousel,
        featureTrioContent,
        'articlesCarousel'
    );

    processMultipleContents(
        categoryViewFragment.articlesTrio,
        DisclaimersService.processArticles,
        'articlesTrio'
    );

    if (
        isVisible(categoryViewFragment.faqs) &&
        categoryViewFragment.faqs?.questions
    ) {
        let savedFields: (ProcessedField | null)[] = [];
        const [
            ,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'subtitle'],
            'disclaimer',
            disclaimers,
            categoryViewFragment.faqs,
            (_item, fields) => {
                savedFields = fields;
            }
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedQuestions,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['question', 'text'],
                'disclaimer',
                disclaimers,
                categoryViewFragment.faqs.questions,
                (_item, fields) => {
                    return {
                        question: fields[0]?.node,
                        text: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.faqs = {
                title: savedFields[0]?.node,
                subtitle: savedFields[1]?.node,
                questions: processedQuestions,
            };
        }
    }

    if (
        isVisible(categoryViewFragment.cxBbtAccordian) &&
        categoryViewFragment.cxBbtAccordian?.categoryArticles
    ) {
        let savedFields: (ProcessedField | null)[] = [];
        const [
            ,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'subtitle'],
            'disclaimer',
            disclaimers,
            categoryViewFragment.cxBbtAccordian,
            (_item, fields) => {
                savedFields = fields;
            }
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedAccordionData,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['categoryName', 'text'],
                'disclaimer',
                disclaimers,
                categoryViewFragment.cxBbtAccordian?.categoryArticles,
                (_item, fields) => {
                    return {
                        categoryName: fields[0]?.node,
                        text: fields[1]?.node,
                    };
                }
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            content.cxBbtAccordian = {
                title: savedFields[0]?.node,
                subtitle: savedFields[1]?.node,
                categoryArticles: processedAccordionData,
            };
        }
    }

    return {
        disclaimers,
        content: content,
    };
}
