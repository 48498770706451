import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { filterXSS } from 'xss';
export const useDyfQueryParams = () => {
    const {
        year: queryYear = '',
        model: queryModel = '',
        categoryName: queryCategory = '',
        title: queryTitle = '',
        videoId: queryVid = '',
        vin: queryVin = '',
    } = useParams<{
        vin?: string;
        year?: string;
        model?: string;
        categoryName?: string;
        videoId?: string;
        title?: string;
    }>();

    const params = useMemo(
        () => ({
            queryYear: filterXSS(queryYear),
            queryModel: filterXSS(queryModel),
            queryCategory: filterXSS(queryCategory),
            queryVideoId: filterXSS(queryVid),
            queryVin: filterXSS(queryVin),
            queryTitle: filterXSS(queryTitle),
        }),
        []
    );

    return { ...params };
};
