import React, { useEffect } from 'react';

export interface VideoDetails {
    videoTitle: string;
    videoId: string;
    videoDesc: string;
    videoThumbnail: string;
    categoryName?: string;
}
export enum HeadTags {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

export interface BrightcoveProps {
    accountId: string;
    videoId: string;
    videoDetails?: VideoDetails;
    dataPlayer?: string;
    categoryName?: string;
    make?: string;
    year?: string;
    model?: string;
    name?: string;
    origin?: string;
    headTag?: HeadTags;
    eventName?: string;
    thumbnail?: string;
}

export const Video = (props: BrightcoveProps) => {
    const dataPlayer = props.dataPlayer || 'SyeO7cSQJf';
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://players.brightcove.net/${props.accountId}/${dataPlayer}_default/index.min.js`;
        script.defer = true;
        document.body.appendChild(script);
    }, [props]);

    return (
        <div className="content-center">
            <video
                id={props.videoId}
                data-video-id={props.videoId}
                data-account={props.accountId}
                data-player={dataPlayer}
                data-embed="default"
                className="video-js model-video-js"
                controls
            ></video>
        </div>
    );
};
