import { NewAnalyticsService } from '../services/new-analytics-service/new-analytics-service';
import { useEffect, useState } from 'react';
import { ShortcodeProviders } from '../services/shortcode-service/shortcode-service';

async function fireEvents(
    eventNames: string | string[],
    targetViewName?: string,
    shortcodeProviders?: ShortcodeProviders,
    clearDigitaldata = true,
    skipFireTargetAnalytics = true
) {
    const analyticsService: NewAnalyticsService = new NewAnalyticsService(
        targetViewName,
        shortcodeProviders
    );
    if (typeof eventNames !== 'undefined') {
        analyticsService.fireEvents(
            eventNames,
            clearDigitaldata,
            skipFireTargetAnalytics
        );
    }
}

export function useAnalytics(
    onLoadEvents?: string | string[],
    targetViewName?: string,
    shortcodeProviders?: ShortcodeProviders,
    clearDigitaldata = true
): [
    (
        eventNames: string | string[],
        targetViewName?: string,
        shortcodeProviders?: ShortcodeProviders,
        clearDigitaldata?: boolean
    ) => void,
    boolean
] {
    const [onLoadFinished, setOnLoadFinished] = useState<boolean>(false);
    useEffect(() => {
        if (onLoadEvents) {
            fireEvents(
                onLoadEvents,
                targetViewName,
                shortcodeProviders,
                clearDigitaldata
            ).then(() => {
                setOnLoadFinished(true);
            });
        } else {
            setOnLoadFinished(true);
        }
    }, []);
    return [fireEvents, onLoadFinished];
}
