import React, { useState, useEffect } from 'react';
import AuthenticationService from '../../../services/authentication-service/authentication-service';
import { PrimaryButton, SecondaryButton } from '../../common';
import './login-button.scss';
import { PageType } from '../view-template/page-type';
import { HeaderFragment } from '../../../models/experiencefragments/header';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    page: PageType;
    color?: 'light' | 'dark';
    fill?: 'fill' | 'outline';
    customCtaTitle?: string;
    chevron?: boolean;
    dataTestId?: string;
    analyticsEventName?: string;
    onClick?: () => void;
}
export const LoginButton = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const [content] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );
    const authService = new AuthenticationService();
    const login = () => {
        props.analyticsEventName &&
            fireEvents(props.analyticsEventName, undefined, undefined, false);
        props.onClick && props.onClick();
        authService.login();
    };

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    useEffect(() => {
        authService.onIsAuthenticated().then(isAuthenticated => {
            setIsAuthenticated(isAuthenticated);
        });
        window.dispatchEvent(new Event('resize'));
    });

    return (
        <>
            {content &&
                content['main-nav-right'].signIn.signInCtaTitle &&
                !isAuthenticated && (
                    <div
                        data-testid={props?.dataTestId}
                        className={
                            props.page === 'Landing'
                                ? 'home-sign-in-container'
                                : 'second-signin-button'
                        }
                    >
                        {props.page === 'Landing' ||
                        props.page === 'How To Video' ? (
                            <PrimaryButton
                                onClick={() => {
                                    fireEvents(
                                        'register-login-onclick-event',
                                        undefined,
                                        undefined,
                                        false
                                    );
                                    login();
                                }}
                                role="link"
                                color={props.color ? props.color : 'light'}
                                fill={props.fill ? props.fill : 'fill'}
                                chevron={
                                    props.chevron !== undefined
                                        ? props.chevron
                                        : true
                                }
                            >
                                {props.customCtaTitle ||
                                    content['main-nav-right'].signIn
                                        .signInCtaTitle}
                            </PrimaryButton>
                        ) : (
                            <SecondaryButton
                                onClick={login}
                                noChevron={!props.chevron}
                            >
                                {props.customCtaTitle ||
                                    content['main-nav-right'].signIn
                                        .signInCtaTitle}
                            </SecondaryButton>
                        )}
                    </div>
                )}
            {content &&
                content['main-nav-right'].signIn.signInCtaTitle &&
                isAuthenticated && (
                    <div
                        data-testid={props?.dataTestId}
                        className={
                            props.page === 'Landing'
                                ? 'home-sign-in-container'
                                : 'signin-button-placeholder'
                        }
                    ></div>
                )}
        </>
    );
};
