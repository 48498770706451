import React, { ReactNode } from 'react';
import './service-price-input.scss';
import errorWarning from '../../../../assets/error-warning.svg';

interface Props {
    children: ReactNode;
    inputLabel?: string;
    inputError?: string;
    showLabel?: boolean;
}

const ServicePriceInput = (props: Props) => {
    return (
        <div className="pc-input-wrapper">
            <div className="pc-input-label">
                {props.showLabel && props.inputLabel}
                {props.inputError && (
                    <img className="osb-error-icon" src={errorWarning} />
                )}
            </div>
            <div>{props.children}</div>
            {props.inputError && (
                <div className="pc-input-error">{props.inputError}</div>
            )}
        </div>
    );
};

export default ServicePriceInput;
