import usePreferredDealerListContent from './hooks/use-preferred-dealer-list-content';

export default class PreferredDealerServiceXPsService {
    public isPreferredDealerOnList(dealerId: string) {
        const preferredDealerList = usePreferredDealerListContent()?.dealerIds;
        let isDealerFound = false;
        if (dealerId && Array.isArray(preferredDealerList)) {
            preferredDealerList.forEach(item => {
                if (item) {
                    const dealerIdTemp = item.replace(/[^a-zA-Z0-9]/g, '');
                    if (
                        dealerIdTemp.toLowerCase() ===
                        dealerId.trim().toLowerCase()
                    ) {
                        isDealerFound = true;
                    }
                }
            });
        }
        return isDealerFound;
    }
}
