import { ServerSideService } from '../server-side-service/server-side-service';
import { MetaTagFactory } from './seo-tags/MetaTagFactory';
import { GenericMetaTagData } from './seo-tags/GenericMetaTagData';

export interface SeoConfig {
    applicationName: string;
    pageTitle: string;
    metaDescription?: string;
}

export class SeoService {
    public set(seoConfig: SeoConfig, serverContext: any) {
        const domain = serverContext.domain;
        const titleParts: string[] = [];
        seoConfig.pageTitle && titleParts.push(seoConfig.pageTitle);
        if (!seoConfig.pageTitle) {
            seoConfig.applicationName &&
                titleParts.push(seoConfig.applicationName);
            domain && titleParts.push(domain);
        }
        const newTitle = titleParts.join(' | ');

        if (new ServerSideService().isClientSide()) {
            document.title = newTitle;
        }
        serverContext.documentTitle = newTitle;
        serverContext.metaDescription = seoConfig.metaDescription;
    }

    public createTags(res: GenericMetaTagData[]) {
        let metaTagsAsString = '';
        if (res === undefined || res.length <= 0) {
            return metaTagsAsString;
        }
        const metaTagFactory = new MetaTagFactory();
        res.forEach((item: GenericMetaTagData) => {
            const tag = metaTagFactory.newTag(item);
            metaTagsAsString += tag.render();
        });
        return metaTagsAsString;
    }
}

const seoService = new SeoService();
export default seoService;
