import { useContent } from '../../../../../hooks/use-server-content';

export interface VinSelectorProps {
    hide?: string;
    vinInputLabel: string;
    ariaLabel: string;

    invalidVin: string;
    vinNotFordLincolnMercury: string;
    noEmptyField: string;
    vinNotEnoughCharacters: string;
    vinWfoError: string;
}
export const useVinSelectorContent = (): VinSelectorProps | null => {
    const [content, getValueByName] = useContent('common', 'vin-form');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.invalidVin) {
            contentFragment = null;
        }
    }
    return contentFragment as VinSelectorProps;
};
