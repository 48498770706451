import { useContent } from '../../../hooks/use-server-content';
import { ReactNode } from 'react';

export interface HomeContentProps {
    hide: boolean;
    mastheadTitle: string;
    bannerTitle: string;
    bannerTitleWithDisclaimer?: ReactNode;
    bannerDescription: string;
    bannerDescriptionWithDisclaimer?: ReactNode;
    bannerDisclaimer: string;
    vehicleSelectorTitle: string;
    vehicleSelectorDescription: string;
    vehicleSelectorDescriptionWithDisclaimer?: ReactNode;
    vehicleSelectorDescAuthState?: string;
    vehicleSelectorDisclaimer: string;
    vehicleSelectorYearDropdownLabel: string;
    vehicleSelectorModelDropdownLabel: string;
    vehicleSelectorSubmitButtonLabel: string;
    disclaimerLabel: string;
    helperTextContent: string;
    hideVehicleSelector: boolean;
    vehicleSelectorVinInput: string;
    vehicleSelectorYearErrorMsg: string;
    vehicleSelectorModelErrorMsg: string;
    vehicleSelectorVinSubmitButtonLabel: string;
    vehicleSelectorModelSubmitButtonLabel: string;
    vehicleSelectorVinSubtitle: string;
    vehicleSelectorModelSubtitle: string;
    homeBackgroundImageDesktop: string;
    homeBackgroundImageMobile: string;
    hideBannerTooltip: boolean;
    bannerTooltipHeader?: string;
    bannerTooltipContent: string;
    vinHideTooltip: boolean;
    vinTooltipHeader?: string;
    vinTooltipContent: string;
    segmentedControlVinButtonLabel?: string;
    segmentedControlYearAndModelButtonLabel?: string;
    whereDoIFindVinCta?: string;
    userGreetingLine1?: string;
    userGreetingLine2?: string;
    userGreetingLine3?: string;
}
export const useHomeContent = (
    category = 'common'
): HomeContentProps | null => {
    const [content, getValueByName] = useContent(category, 'home');
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (
            contentFragment.vinTooltipHeader == null &&
            contentFragment.vinTooltipContent == null
        ) {
            contentFragment.vinHideTooltip = true;
        }
        if (
            contentFragment.bannerTooltipHeader == null &&
            contentFragment.bannerTooltipContent == null
        ) {
            contentFragment.hideBannerTooltip = true;
        }
        if (contentFragment.hide || !contentFragment.bannerTitle) {
            contentFragment = null;
        }
    }
    return contentFragment as HomeContentProps;
};
