import serverSideService from '../../../services/server-side-service/server-side-service';

export const isMobileView = () => {
    if (serverSideService.isClientSide()) {
        return window.matchMedia('only screen and (max-width: 768px)').matches;
    }
};

export const scrollToTop = () => {
    if (serverSideService.isClientSide()) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
};

export const getServiceDueDate = (serviceData: any) => {
    if (serviceData?.featureData?.estimatedDate) {
        const estimatedDate = serviceData.featureData.estimatedDate;
        const day = estimatedDate.day ? estimatedDate.day : '';
        const monthNumber = estimatedDate.month ? estimatedDate.month : '';
        const year = estimatedDate.year ? estimatedDate.year : '';
        const date =
            day !== ''
                ? new Date(year, monthNumber - 1, day)
                : new Date(year, monthNumber, day);
        // Using the browser's default locale.

        if (serviceData.urgency === 'U') {
            return (
                day +
                ' ' +
                date.toLocaleString([], { month: 'long' }).slice(0, 3) +
                ' ' +
                year
            );
        } else {
            return (
                date.toLocaleString([], { month: 'long' }).slice(0, 3) +
                ' ' +
                year
            );
        }
    }
};
