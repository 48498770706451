import React, { useContext } from 'react';
import { HelpCardsProps } from '../../../views/page-not-found-view/hooks/use-help-cards-content';
import { SearchContentProps } from './hooks/use-search-content';
import { HeroTiles, ViewBanner } from '../';
import './search-bar.scss';
import SearchBarInput from './search-bar-input/search-bar-input';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import ServerContext from '../../../contexts/serverContext';
import { ASTUTE } from '../../../support/support-constants';
interface Props {
    vehicleAttributes?: VehicleAttributes;
    searchContent: SearchContentProps;
    helpCardsContent: HelpCardsProps;
}

export const SearchBar = (props: Props) => {
    const serverContext = useContext(ServerContext);
    const hideSearchBar = ASTUTE.DOMAINS_TO_SKIP_BBT_SEARCH.includes(
        serverContext.domain
    );
    return (
        <>
            {props.searchContent && props.helpCardsContent && !hideSearchBar && (
                <div className="search-bar" id="SH">
                    <ViewBanner
                        topComponent={
                            <SearchBarInput
                                searchContent={props.searchContent}
                            />
                        }
                        bottomComponent={
                            <HeroTiles
                                helpCardsContent={props.helpCardsContent}
                            />
                        }
                        backgroundImageDesktop={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.searchContent.searchBackgroundImageDesktop
                        }
                        backgroundImageMobile={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.searchContent.searchBackgroundImageMobile
                        }
                    />
                </div>
            )}
        </>
    );
};
