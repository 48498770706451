import React from 'react';
import './preferred-service-centre.scss';
import { FMButton } from '@own/fds-react';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import { useHistory } from 'react-router-dom';

interface PreferredServiceCentreProps {
    preferredServiceCentre: string;
    onSearchDealerClick: () => void;
}

const PreferredServiceCentre: React.FC<PreferredServiceCentreProps> = ({
    preferredServiceCentre,
    onSearchDealerClick,
}) => {
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'service-centre-step') || {};

    const history = useHistory();
    return (
        <div
            className="osb-v3-preferred-service-centre-wrapper"
            data-testid="preferred-service-centre-wrapper"
        >
            <div
                className="osb-v3-preferred-service-centre-heading"
                data-testid="preferred-service-centre-heading"
            >
                {osbContentData?.preferredServiceCentreTitle}
            </div>
            <div
                className="osb-v3-preferred-service-centre"
                data-testid="preferred-service-centre"
            >
                <FMButton
                    className="osb-v3-preferred-back-button"
                    chevron="left"
                    onClick={() => history.goBack()}
                    label={osbContentData?.backToPreviousStepLabel}
                    type="tertiary"
                    data-testid="back-button"
                />

                <div
                    className="osb-v3-preferred-title"
                    data-testid="preferred-title"
                >
                    {osbContentData?.yourPreferredServiceCentreLabel}
                </div>
                <div
                    className="osb-v3-preferred-centre-name"
                    data-testid="preferred-centre-name"
                >
                    {preferredServiceCentre}
                </div>
                <div
                    className="osb-v3-preferred-button-container"
                    data-testid="button-container"
                >
                    <div
                        className="osb-v3-preferred-continue-button"
                        data-testid="continue-button-container"
                    >
                        <FMButton
                            label={
                                osbContentData?.continueButtonPreferredDealerLabel
                            }
                            theme="light"
                            type="primary"
                            data-testid="continue-button"
                        />
                    </div>
                    <div
                        className="osb-v3-preferred-search-button"
                        data-testid="search-button-container"
                    >
                        <FMButton
                            onClick={onSearchDealerClick}
                            label={
                                osbContentData?.searchDifferentServiceCentreLabel
                            }
                            type="secondary"
                            theme="light"
                            data-testid="search-button"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreferredServiceCentre;
