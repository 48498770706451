import { useContext, useEffect, useState } from 'react';
import { AemDyfEligibleModelResponse } from '../../../models/experiencefragments/dyf-eligible-model';
import ServerContext from '../../../contexts/serverContext';
import contentService from '../../../services/content-service/content-service';

const MODEL_YEAR_QUERY_END = 2050;
const MODEL_YEAR_QUERY_START = 2020;

type HookState<T> = {
    data: T;
    loading: boolean;
};

export const useDyfEligibleYears = (): HookState<number[]> => {
    const { brand, currentRegionCode, currentLanguageRegionCode } = useContext(
        ServerContext
    );

    const [elgYears, setElgYears] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchModels = async () => {
            const validModelYears: number[] = [];
            let shouldStop = false;
            let modelYearIndex = MODEL_YEAR_QUERY_START;
            while (!shouldStop) {
                if (modelYearIndex > MODEL_YEAR_QUERY_END) {
                    shouldStop = true;
                    return validModelYears;
                }
                const hasResult = await contentService
                    .getExperience<AemDyfEligibleModelResponse | null>(
                        `dyf/${modelYearIndex}`,
                        'dyf-eligible-models',
                        'dyf_eligible_models',
                        brand,
                        currentRegionCode,
                        currentLanguageRegionCode
                    )
                    .then(res => {
                        return res?.models != null;
                    });
                if (hasResult || modelYearIndex <= 2021) {
                    // the second argument is because canada has no models in 2020 so need to force the loop to continue
                    hasResult && validModelYears.push(modelYearIndex);
                    modelYearIndex++;
                } else {
                    shouldStop = true;
                }
            }

            return Promise.resolve(validModelYears);
        };
        fetchModels().then(res => {
            res.sort((a, b) => b - a);
            setElgYears(res);
            setLoading(false);
        });
    }, [brand, currentRegionCode, currentLanguageRegionCode]);

    return { data: elgYears, loading };
};
