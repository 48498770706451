import { useContent } from '../../../../hooks/use-server-content';

export interface ReviewContentProps {
    stepOneText: string;
    stepTwoText: string;
    stepThreeText: string;
    stepFourText: string;
    stepFiveText: string;
    stepSixText: string;
}

export const useRebatesTrackerContent = (): ReviewContentProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates',
        'redeem-rebates-tracker'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ReviewContentProps;
};
