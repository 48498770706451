import { MetaTag } from './MetaTag';

export abstract class AbstractMetaTag implements MetaTag {
    protected constructor(
        protected attributeName: string,
        protected attributeValue: string,
        protected content: string
    ) {}

    render(): string {
        return `<meta ${this.attributeName}="${this.attributeValue}" content="${this.content}">`;
    }
}
