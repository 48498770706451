import { useContent } from '../../../../hooks/use-server-content';

export interface FMPreferredDealerProps {
    iconPath: string;
    ctaLabel: string;
    ctaAriaLabel: string;
    ctaTargetBlank: boolean;
    ctaPath: string;
    gxpCtaPath: string;
}
export const useFMPreferredDealer = (
    category: string,
    name: string,
    ymmServlet?: boolean
): FMPreferredDealerProps | null => {
    const [content, getValueByName] = useContent(
        category,
        name,
        undefined,
        ymmServlet
    );
    const isNullOrEmpty = (str: string | null | undefined): boolean => {
        return str == null || str.trim() === '';
    };
    let contentFragment: any = {};
    content?.elements?.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (
            isNullOrEmpty(contentFragment?.gxpCtaPath) &&
            isNullOrEmpty(contentFragment?.ctaPath)
        ) {
            contentFragment = null;
        }
    }
    return contentFragment as FMPreferredDealerProps;
};
