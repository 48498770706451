import React from 'react';
import { ActivityIndicator } from '../activity-indicator/activity-indicator';
import './osb-loader.scss';

interface Props {
    osbEmptyContentHeight?: boolean;
    osbLoaderMessage?: string;
    progressPercent?: string;
}
export const OsbLoader = (props: Props) => {
    return (
        <>
            <div
                className={` ${
                    props.osbEmptyContentHeight
                        ? 'osb-empty-content-height'
                        : ''
                }`}
            ></div>
            <span className="osb-loading">
                <ActivityIndicator
                    className="full-height"
                    loaderMessage={
                        props.osbLoaderMessage ? props.osbLoaderMessage : ''
                    }
                    progressPercent={props.progressPercent}
                />
            </span>
        </>
    );
};
