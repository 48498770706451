import { useEffect, useState, useContext } from 'react';
import ReservationOrderStatusService from '../services/reservation-order-status/reservation-order-status';
import { AuthenticationState } from './use-authentication';
import ServerContext from '../contexts/serverContext';

const useOrderStatus = (
    vin: string | undefined,
    authenticationState: AuthenticationState
) => {
    const [orderStatus, setOrderStatus] = useState<boolean>(false);
    const serverContext = useContext(ServerContext);

    useEffect(() => {
        if (vin && authenticationState === AuthenticationState.Authenticated) {
            new ReservationOrderStatusService()
                .request(serverContext.current3LetterCountryCode)
                .then(response => setOrderStatus(response));
        }
    }, [vin, authenticationState, serverContext.current3LetterCountryCode]);

    return orderStatus;
};

export default useOrderStatus;
