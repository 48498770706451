import React, { useContext } from 'react';
import ServerContext from '../../../../../contexts/serverContext';
import { PopularLinksSectionFragment } from '../../../../../models/experiencefragments/category-view';
import { Link } from '../../../../common';
import { transformRelativeUrlValidateRoot } from '../../../../utils/router-util/router-util';
import './popular-links-section.scss';
import { CategoryOnClickCtaInfo } from '../../../../../services/shortcode-service/shortcode-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';

interface Props {
    content: PopularLinksSectionFragment;
    categoryPath?: string;
    categoryType?: string;
    ctaLabel?: string;
    ctaPath?: string;
}

export const PopularLinksSection = (props: Props) => {
    const context = useContext(ServerContext);
    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
        ctaName: props.ctaLabel,
        categoryType: props.categoryType,
        categoryPath: props.categoryPath,
        ctaUrl: props.ctaPath,
    };
    const [fireEvents] = useAnalytics();
    const eventClick = async (ctaUrl: string, ctaName: string) => {
        if (
            props.categoryType == 'category' ||
            props.categoryType == 'subcategory'
        ) {
            categoryOnClickCtaInfo.ctaUrl = ctaUrl;
            categoryOnClickCtaInfo.ctaName = ctaName;
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                ctaUrl
            );
            if (referralExit) {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            } else {
                fireEvents('category-onclick-event', undefined, {
                    categoryOnClickCtaInfo,
                });
            }
        }
    };
    return (
        <>
            {props.content && (
                <div className="popular-links-section">
                    {props.content.relatedCategoryLinksItems && (
                        <div className="related-categories">
                            <h2 className="related-categories__header">
                                {props.content.relatedCategoryLinksTitle}
                            </h2>
                            <hr />
                            {props.content.relatedCategoryLinksItems.map(
                                (relatedCategoryLinksItem, index) => (
                                    <h3
                                        key={`${relatedCategoryLinksItem.title}_${index}`}
                                        className="related-categories__item"
                                        onClick={() =>
                                            eventClick(
                                                `${relatedCategoryLinksItem.url}`,
                                                `${relatedCategoryLinksItem.title}`
                                            )
                                        }
                                    >
                                        <Link
                                            href={transformRelativeUrlValidateRoot(
                                                relatedCategoryLinksItem.url,
                                                context.root
                                            )}
                                            target={
                                                relatedCategoryLinksItem.targetBlank
                                                    ? '_blank'
                                                    : ''
                                            }
                                        >
                                            {relatedCategoryLinksItem.title}
                                        </Link>
                                    </h3>
                                )
                            )}
                        </div>
                    )}

                    {props.content.popularLinksItems && (
                        <div className="popular-in-rewards">
                            <h2 className="popular-in-rewards__header">
                                {props.content.popularLinksTitle}
                            </h2>
                            <hr />
                            {props.content.popularLinksItems.map(
                                (popularLinksItem, index) => (
                                    <h3
                                        className="popular-in-rewards__item"
                                        key={`${popularLinksItem.title}_${index}`}
                                    >
                                        <Link
                                            href={popularLinksItem.url}
                                            target={
                                                popularLinksItem.targetBlank
                                                    ? '_blank'
                                                    : ''
                                            }
                                        >
                                            {popularLinksItem.title}
                                        </Link>
                                    </h3>
                                )
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
