import React, { useState, useEffect } from 'react';
import { usePreferredDealerContent } from '../hooks/use-preferred-dealer-content';
import { SecondaryButton } from '../../../common';
import './preferred-dealer.scss';
import { useAnalytics } from '../../../../hooks/use-analytics';

interface Props {
    vinNumber: string;
    preferredDealerData: any;
}

const PreferredDealerVHR = (props: Props) => {
    const {
        headline,
        scheduleServiceCtaLabel,
        directionCtaLabel,
        changeDealerCtaLabel,
        changeDealerUrl,
        openScheduleServiceInNewTab,
        openDirectionInNewTab,
        openChangeDealerInNewTab,
    } = usePreferredDealerContent();
    const [fireEvents] = useAnalytics();
    const preferredDealerData = props.preferredDealerData;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);

    const handleResize = () => setIsMobile(window.innerWidth <= 780);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const triggerPrefereDealerAnaltics = (ctaContext: string) => {
        fireEvents(
            ['vhr-cta-onclick-event'],
            undefined,
            {
                fieldName: ctaContext?.toLowerCase(),
            },
            false
        );
    };
    return (
        <>
            {preferredDealerData && headline && (
                <section className="preferred-dealer-section">
                    <div className="category-quiet-card__content-wrap category-quiet-card__content-image__content-wrap">
                        <div className="category-quiet-card__content-wrap__subtitle">
                            {headline}
                        </div>
                        <div className="category-quiet-card__content-wrap__title">
                            {preferredDealerData.dealerName}
                        </div>
                        <div className="category-quiet-card__content-wrap__body">
                            {preferredDealerData.streetAddress}
                            <br />
                            {preferredDealerData.city},{' '}
                            {preferredDealerData.state}
                            <br />{' '}
                            {isMobile ? (
                                <a href={`tel:${preferredDealerData.phone}`}>
                                    {preferredDealerData.phone}
                                </a>
                            ) : (
                                <p>{preferredDealerData.phone}</p>
                            )}
                        </div>
                        {scheduleServiceCtaLabel &&
                            preferredDealerData.scheduleServiceUrl && (
                                <>
                                    <SecondaryButton
                                        onClick={() =>
                                            triggerPrefereDealerAnaltics(
                                                scheduleServiceCtaLabel
                                            )
                                        }
                                        linkTarget={
                                            openScheduleServiceInNewTab
                                                ? '_blank'
                                                : '_self'
                                        }
                                        link={
                                            preferredDealerData.smarttprovider ===
                                            'X'
                                                ? preferredDealerData.smarttsubset
                                                : preferredDealerData.scheduleServiceUrl
                                        }
                                    >
                                        {scheduleServiceCtaLabel}
                                    </SecondaryButton>
                                    <br />
                                </>
                            )}
                        {directionCtaLabel &&
                            preferredDealerData.directionpage && (
                                <>
                                    <SecondaryButton
                                        onClick={() =>
                                            triggerPrefereDealerAnaltics(
                                                directionCtaLabel
                                            )
                                        }
                                        linkTarget={
                                            openDirectionInNewTab
                                                ? '_blank'
                                                : '_self'
                                        }
                                        link={preferredDealerData.directionpage}
                                    >
                                        {directionCtaLabel}
                                    </SecondaryButton>
                                    <br />
                                </>
                            )}
                        {changeDealerCtaLabel && changeDealerUrl && (
                            <SecondaryButton
                                onClick={() =>
                                    triggerPrefereDealerAnaltics(
                                        changeDealerCtaLabel
                                    )
                                }
                                linkTarget={
                                    openChangeDealerInNewTab
                                        ? '_blank'
                                        : '_self'
                                }
                                link={changeDealerUrl}
                            >
                                {changeDealerCtaLabel}
                            </SecondaryButton>
                        )}
                    </div>
                </section>
            )}
        </>
    );
};

export default PreferredDealerVHR;
