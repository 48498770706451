import React, { useRef } from 'react';
import { Search } from '../../../../models/experiencefragments/header';
import { SiteSearch } from '../../search/site-search';

interface Props {
    content: Search;
    className?: string;
}

export const SearchLincoln = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <>
            {props.content.url && (
                <div className={`search ${props.className || ''}`}>
                    <div className="hidden-xs">
                        <SiteSearch content={props.content}></SiteSearch>
                    </div>

                    <form
                        className="mobile-search-form visible-xs"
                        name="mobileSearchForm"
                        method="GET"
                        action={props.content.url.replace(
                            'searchQuery',
                            inputRef.current ? inputRef.current.value : ''
                        )}
                    >
                        <div className="form-group">
                            <div className="form-wrapper">
                                <button
                                    aria-label="Search Icon"
                                    className="mobile-search-submit"
                                >
                                    <i className="icon icon-search-glass-25px" />
                                </button>
                                <input
                                    className="mobile-search-input search-input"
                                    name="f:searchInputString"
                                    type="search"
                                    aria-label="Search"
                                    placeholder={
                                        props.content.placeHolderTextForMobile
                                    }
                                    autoCapitalize="none"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    ref={inputRef}
                                />
                                <input type="hidden" name="f:search" value="" />
                                <input
                                    type="hidden"
                                    name="formID"
                                    value="globalSearchForm"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};
