import { useContent } from '../../../hooks/use-server-content';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';

export interface HelpCardsProps {
    hide: boolean;
    myFordVehicleHelpCardTitle: string;
    contactUsHelpCardTitle: string;
    findVehicleHelpCardTitle: string;
    myFordVehicleHelpCardURL: string;
    contactUsHelpCardURL: string;
    findVehicleHelpCardIcon: string;
    contactUsHelpCardIcon: string;
    findVehicleHelpCardURL: string;
    myFordVehicleHelpCardIcon: string;
    myFordVehicleHelpCardAriaLabel: string;
    contactUsHelpCarAriaLabel: string;
    findVehicleHelpCardAriaLabel: string;
    myFordVehicleHelpCardTarget: string;
    contactUsHelpCardTarget: string;
    findVehicleHelpCardTarget: string;
    escapeHatchTitle: string;
    escapeHatchDescription: string;
}
export const useHelpCardsContent = (
    vehiclesAttributes?: VehicleAttributes
): HelpCardsProps => {
    const [content, getValueByName] = useContent(
        'vehicle-data',
        getVehicleDataSelector('help-cards', vehiclesAttributes),
        undefined,
        true
    );
    let contentFragment: any = {};
    content?.elements?.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });

    if (contentFragment) {
        if (
            contentFragment.hide ||
            !contentFragment.myFordVehicleHelpCardTitle
        ) {
            contentFragment = null;
        }
    }
    return contentFragment as HelpCardsProps;
};
