import { useContent } from '../../../../hooks/use-server-content';

export interface RedeemRebatesTopBannerProps {
    redeemRebatesBackgroundImageDesktop: string;
    redeemRebatesBackgroundImageMobile: string;
    bannerTitle: string;
    bannerDescription: string;
    linkText: string;
    ctaLink: string;
    redeemInfo: string;
    onWebTitle: string;
    onWebDescription: string;
    onMailTitle: string;
    onMailDescription: string;
    usOffersDescription: string;
    onWebImage: string;
    onMailImage: string;
    viewCouponsOffersText: string;
    viewCouponsOffersLink: string;
}

export const useRedeemRebatesTopBannerContent = (): RedeemRebatesTopBannerProps | null => {
    const [content, getValueByName] = useContent(
        'redeem-rebates',
        'redeem-rebates-top-banner'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as RedeemRebatesTopBannerProps;
};
