import { DSL_BASE_URL, DSL_API_PATH } from '../../../../../constants';
import HttpService from '../../../../../services/http-service/http-service';
import { AxiosResponse } from 'axios';
import AppConfigurationService from '../../../../../services/app-configuration-service/app-configuration-service';

interface AcceptanceStatusResponse {
    acceptedLLIDs: string[];
    allLLIDsAccepted: boolean;
}

interface LegalLanguages {
    effectiveEndDate: string;
    legalLanguageType: string;
    languageISOCode: string;
    notificationStatus: string;
    daysRequiredToPostLegalLanguage: string;
    legalLanguageNameCode: string;
    sourceCode: string;
    legalLanguageText: string;
}

export interface TermsContent {
    legalLanguages: LegalLanguages[];
}

export interface TermsAndConditionsServiceInterface {
    getAcceptanceStatus(): Promise<boolean>;
    setAcceptance(): Promise<boolean>;
    getTermsContent(feature: string): Promise<TermsContent>;
}

export class TermsAndConditionsService
    implements TermsAndConditionsServiceInterface {
    private appConfig = new AppConfigurationService().getAppConfiguration();
    public async getAcceptanceStatus(consentsKey?: string): Promise<boolean> {
        let url = DSL_BASE_URL + DSL_API_PATH.TERMS_AND_CONDITIONS_CONSENT;
        consentsKey && (url += `?consentsKey=${consentsKey}`);
        return HttpService.get<any>(url, false, {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
        })
            .then((response: AxiosResponse<AcceptanceStatusResponse>) => {
                return response.data.allLLIDsAccepted;
            })
            .catch(err => {
                console.error('Error retrieving consents', err);
                return false;
            });
    }
    public async setAcceptance(): Promise<boolean> {
        const url = DSL_BASE_URL + DSL_API_PATH.TERMS_AND_CONDITIONS_CONSENT;
        return HttpService.post<any>(url, null, {
            headers: Object.assign(
                { 'Content-Type': 'application/json' },
                HttpService.getAppIdAndAuthTokenRequestHeaders()
            ),
        })
            .then((response: AxiosResponse<AcceptanceStatusResponse>) => {
                return response.status === 200
                    ? response.data.allLLIDsAccepted
                    : false;
            })
            .catch(() => {
                return false;
            });
    }

    public async getTermsContent(feature: string): Promise<TermsContent> {
        const url =
            DSL_BASE_URL + DSL_API_PATH.TERMS_AND_CONDITIONS + `/${feature}`;
        return HttpService.get(url, true)
            .then((response: AxiosResponse<TermsContent>) => {
                return response.data;
            })
            .catch(err => {
                console.error('Error retrieving consents', err);
                return { legalLanguages: [] };
            });
    }
}
