import React, { ReactNode } from 'react';

interface Props {
    firstName: string;
    testId: string;
    content: any;
    children?: ReactNode;
}
export const Greeting = (props: Props) => {
    const setTime = (hours: number, minutes: number, seconds: number) => {
        const currentTime: Date = new Date();
        currentTime.setHours(hours);
        currentTime.setMinutes(minutes);
        currentTime.setSeconds(seconds);
        return currentTime;
    };
    const greetingByTime = () => {
        const currentTime: Date = new Date();
        const goodMorningTime = setTime(5, 0, 1);
        const goodAfterNoonTime = setTime(12, 0, 0);
        const goodEveningTime = setTime(17, 0, 0);

        if (
            currentTime >= goodMorningTime &&
            currentTime <= goodAfterNoonTime
        ) {
            return props.content.goodMorningGreeting;
        } else if (
            currentTime > goodAfterNoonTime &&
            currentTime <= goodEveningTime
        ) {
            return props.content.goodAfternoonGreeting;
        } else {
            return props.content.goodEveningGreeting;
        }
    };
    return (
        <>
            {props.firstName && (
                <h1 className="greeting-header" data-testid={props.testId}>
                    {greetingByTime()} {props.firstName}
                    {props.children}
                </h1>
            )}
        </>
    );
};
