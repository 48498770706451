import { ContentResponse } from './content-service.interface';
import { VehicleAttributes } from '../../models/vehicle-attributes';

export const getTitleFromContent = (
    content: ContentResponse,
    title: string
) => {
    const contentTitle = content.elements.find(
        ele => ele.title.toLowerCase() === title.toLowerCase()
    );
    if (contentTitle != null) return contentTitle.value;
    else return '';
};

export const getNameFromContent = (
    content: ContentResponse,
    name: string | undefined
) => {
    if (!name) {
        return '';
    }
    const contentName = content.elements.find(
        ele => ele.name!.toLowerCase() === name.toLowerCase()
    );
    if (contentName != null) return contentName.value;
    else return '';
};

export const getValueByName = (
    content: ContentResponse,
    name: string
): string | boolean => {
    if (content) return getNameFromContent(content, name);
    else return '';
};

export const formatModelName = (modelName: string) => {
    if (modelName) {
        modelName = modelName
            .trim()
            .replace(/ /g, '-')
            .replace(/\./g, '')
            .replace('/', '-');
        modelName = modelName.toLowerCase();
    }
    return modelName;
};

export const getVehicleDataSelector = (
    name: string,
    vehicleAttributes?: VehicleAttributes
) => {
    let selector = 'vehicle-data.';
    if (vehicleAttributes) {
        selector =
            selector +
            vehicleAttributes.year +
            '.' +
            (vehicleAttributes.seoKey ||
                formatModelName(vehicleAttributes.model)) +
            '.' +
            name;
    } else {
        selector = selector + 'default.' + 'default.' + name;
    }
    return selector;
};
