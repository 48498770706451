import { PRICE_CALCULATOR_ANALYTICS } from '../price-calculator-constant';
import { PCPageLoad } from '../../../../services/shortcode-service/shortcode-service';
import { getBrand } from '../../owners-osb/osb-utils';

export const clearDigitalData = () => {
    (window as any).digitaldata = {};
};

export const clearErrorCodes = () => {
    if ((window as any).digitaldata?.page?.errorTracking) {
        delete (window as any).digitaldata?.page?.errorTracking;
    }
    if ((window as any).digitaldata?.page?.errorCodes) {
        delete (window as any).digitaldata?.page?.errorCodes;
    }
    if ((window as any).digitaldata?.onclick?.errorCodes) {
        delete (window as any).digitaldata?.onclick?.errorCodes;
    }
};

export const triggerDealerSearchLoadAnalytics = (fireEvents: Function) => {
    clearErrorCodes();
    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_DEALER_SEARCH_START,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        {},
        false
    );
};

export const triggerGetQuoteAnalytics = (fireEvents: Function) => {
    clearErrorCodes();

    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_GET_QUOTE,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        {},
        false
    );
};

export const triggerLocationSignInAnalytics = (
    fireEvents: Function,
    ctaType: string
) => {
    clearErrorCodes();
    const pcCtaType: PCPageLoad = {
        pcCta: ctaType,
    };
    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_LOCATION_SIGNIN,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        { pcPageLoad: pcCtaType },
        false
    );
};

export const triggerDealerSearchResultAnalytics = (fireEvents: Function) => {
    clearErrorCodes();
    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_DEALER_SEARCH_RESULT,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        {},
        false
    );
};

export const triggerUserInputAnalytics = (
    fireEvents: Function,
    selectedInputField?: string
) => {
    clearErrorCodes();
    const pcInputs: PCPageLoad = {
        selectedInputField: selectedInputField,
    };
    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_USER_INPUT,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        { pcPageLoad: pcInputs },
        false
    );
};

export const triggerInternalCtaAnalytics = (
    fireEvents: Function,
    ctaType: string
) => {
    clearErrorCodes();
    const pcCtaType: PCPageLoad = {
        pcCta: ctaType,
    };
    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_INTERNAL_CTA_CLICK,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        { pcPageLoad: pcCtaType },
        false
    );
};

export const triggerFiltersClick = (
    fireEvents: Function,
    modelName: string,
    modelYear: string
) => {
    clearErrorCodes();
    const vehicle = {
        year: Number(modelYear),
        model: modelName,
        make: getBrand(),
    };
    fireEvents(
        PRICE_CALCULATOR_ANALYTICS.EVENT_PRICE_CALCULATOR_FILTER_CLICK,
        PRICE_CALCULATOR_ANALYTICS.TARGET_TRIGGER_VIEW,
        { ymm: Promise.resolve(vehicle) },
        false
    );
};
