import React from 'react';
import parse from 'html-react-parser';
import './vin-information.scss';
import { useOSBContentFragment } from '../../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import vinNumberWindowVehicle from '../../../../../assets/osb-v3/icons-vin-number-window-vehicle.svg';
import vinNumberDocumentation from '../../../../../assets/osb-v3/icons-vin-number-documentation.svg';
import vinNumberWindowVehicleFront from '../../../../../assets/osb-v3/icons-vin-number-window-vehicle-front.svg';
import regPlateNumber from '../../../../../assets/osb-v3/icons-reg-plate-number.svg';

const VinInformation = () => {
    const vehicleStepContent =
        useOSBContentFragment('common/osb-v3', 'vehicle-step') || {};

    return (
        <div>
            <div className="vin-title-container">
                <span className="vin-title" data-testid="vin-title">
                    {vehicleStepContent?.modalFindYourVinTitle?.toString()}
                </span>
                <span className="vin-subtitle" data-testid="vin-placeholder">
                    {vehicleStepContent?.findYourVinPlaceholder?.toString()}
                </span>
            </div>
            <div className="vin-content" data-testId="vin-content">
                <div className="find-my-vin-info-body-content">
                    <div className="vin-subtitle" data-testId="vin-subtitle">
                        {vehicleStepContent?.modalFindYourVinSubtitle}
                    </div>
                    <div className="vin-details" data-testId="vin-details">
                        <ul className="vin-info-list">
                            <li>
                                <img
                                    alt="vinNumberWindowVehicle"
                                    className=""
                                    src={vinNumberWindowVehicle}
                                />
                                {parse(
                                    (
                                        vehicleStepContent?.modalDriversSideDoorframeDescription ||
                                        ''
                                    ).toString()
                                )}
                            </li>
                            <li>
                                <img
                                    alt="vinNumberDocumentation"
                                    className=""
                                    src={vinNumberDocumentation}
                                />
                                {parse(
                                    (
                                        vehicleStepContent?.modalYourVehicleDocumentationDescription ||
                                        ''
                                    ).toString()
                                )}
                            </li>
                        </ul>
                        <ul className="vin-info-list">
                            <li>
                                <img
                                    alt="vinNumberWindowVehicleFront"
                                    className=""
                                    src={vinNumberWindowVehicleFront}
                                />
                                {parse(
                                    (
                                        vehicleStepContent?.modalVisibleThroughWindshieldDescription ||
                                        ''
                                    ).toString()
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="modal-line" />
            <div
                className="reg-info-container"
                data-testId="reg-info-container"
            >
                <div className="reg-title-container">
                    <span
                        className="reg-info-title"
                        data-testid="reg-info-title"
                    >
                        {vehicleStepContent?.modalFindYourRegistrationNumberLabel?.toString()}
                    </span>
                    <span
                        className="reg-info-subtitle"
                        data-testid="reg-info-subtitle"
                    >
                        {vehicleStepContent?.findYourRegistrationPlaceholder?.toString()}
                    </span>
                </div>

                <div className="reg-content" data-testId="reg-content">
                    <img
                        alt="regPlateNumber"
                        className=""
                        src={regPlateNumber}
                    />
                    {parse(
                        (
                            vehicleStepContent?.modalRegistrationNumberDescription ||
                            ''
                        ).toString()
                    )}
                </div>
            </div>
        </div>
    );
};

export default VinInformation;
