import HttpService from '../http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import { AxiosResponse } from 'axios';
import { FPRValidationResponse } from './fpr-url-validation.interface';

export default class FprUrlValidationService {
    public async validateFordPassUrl(
        brand: string,
        locale: string,
        path: string
    ): Promise<FPRValidationResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.FPR_URL_VALIDATION;
        const body = {
            brand: brand,
            locale: locale,
            path: path.replace(/^(.+?)\/*$/, '$1'),
        };
        return HttpService.post<any>(url, body)
            .then((response: AxiosResponse<FPRValidationResponse>) => {
                if (response.data) {
                    return { isValidUrl: true };
                } else {
                    return { isValidUrl: false };
                }
            })
            .catch(err => {
                console.log('FPR Validation failed: ', err);
                return { isValidUrl: false };
            });
    }
}
