export default class DateFnsLocaleSupportService {
    DATE_FNS_CONF_MAP = new Map<string, string>();
    constructor() {
        this.DATE_FNS_CONF_MAP.set('af-za', 'af'); //Afrikaans (South Africa)
        this.DATE_FNS_CONF_MAP.set('ar-dz', 'ar-DZ'); //Algeria
        this.DATE_FNS_CONF_MAP.set('ar-ma', 'ar-MA'); //Morocco
        this.DATE_FNS_CONF_MAP.set('ar-sa', 'ar-SA'); //Saudi Arabia
        this.DATE_FNS_CONF_MAP.set('az-az', 'az'); //Azeri (Latin) (Azerbaijan)
        this.DATE_FNS_CONF_MAP.set('be-by', 'be'); //Belarusian (Belarus)
        this.DATE_FNS_CONF_MAP.set('bg-bg', 'bg'); //Bulgarian (Bulgaria)
        this.DATE_FNS_CONF_MAP.set('bn-bd', 'bn'); //Bengali (Bangladesh)
        this.DATE_FNS_CONF_MAP.set('ca-es', 'ca'); //Catalan (Spain)
        this.DATE_FNS_CONF_MAP.set('cs-cz', 'cs'); //Czech (Czech Republic)
        this.DATE_FNS_CONF_MAP.set('cy-gb', 'cy'); //Welsh (United Kingdom)
        this.DATE_FNS_CONF_MAP.set('da-dk', 'da'); //Danish (Denmark)
        this.DATE_FNS_CONF_MAP.set('de-de', 'de'); //German (Germany)
        this.DATE_FNS_CONF_MAP.set('de-at', 'de'); //German (Austria)
        this.DATE_FNS_CONF_MAP.set('de-ch', 'de'); //German (Swiss)
        this.DATE_FNS_CONF_MAP.set('el-gr', 'el'); //Greek (Greece)
        this.DATE_FNS_CONF_MAP.set('en-au', 'en-AU'); //Australia
        this.DATE_FNS_CONF_MAP.set('en-ca', 'en-CA'); //Canada
        this.DATE_FNS_CONF_MAP.set('en-gb', 'en-GB'); //United Kingdom
        this.DATE_FNS_CONF_MAP.set('en-in', 'en-IN'); //India
        this.DATE_FNS_CONF_MAP.set('en-nz', 'en-NZ'); //New Zealand
        this.DATE_FNS_CONF_MAP.set('en-us', 'en-US'); //United States
        this.DATE_FNS_CONF_MAP.set('en-za', 'en-ZA'); //South Africa
        this.DATE_FNS_CONF_MAP.set('es-es', 'es'); //Spanish (Spain)
        this.DATE_FNS_CONF_MAP.set('et-ee', 'et'); // Estonian (Estonia)
        this.DATE_FNS_CONF_MAP.set('eu-es', 'eu'); //Basque (Spain)
        this.DATE_FNS_CONF_MAP.set('fa-ir', 'fa-IR'); //Farsi (Iran)
        this.DATE_FNS_CONF_MAP.set('fi-fi', 'fi'); // Finnish (Finland)
        this.DATE_FNS_CONF_MAP.set('fr-fr', 'fr'); // French (France)
        this.DATE_FNS_CONF_MAP.set('fr-ca', 'fr-CA'); //French (Canada)
        this.DATE_FNS_CONF_MAP.set('fr-ch', 'fr-CH'); //French (Switzerland)
        this.DATE_FNS_CONF_MAP.set('gl-es', 'gl'); //Galician (Spain)
        this.DATE_FNS_CONF_MAP.set('gu-in', 'gu'); //Gujarati (India)
        this.DATE_FNS_CONF_MAP.set('he-il', 'he'); //Hebrew (Israel)
        this.DATE_FNS_CONF_MAP.set('hi-in', 'hi'); //Hindi (India)
        this.DATE_FNS_CONF_MAP.set('hr-hr', 'hr'); //Croatian (Croatia)
        this.DATE_FNS_CONF_MAP.set('hu-hu', 'hu'); //Hungarian (Hungary)
        this.DATE_FNS_CONF_MAP.set('hy-am', 'hy'); //Armenian (Armenia)
        this.DATE_FNS_CONF_MAP.set('id-id', 'id'); //Indonesian (Indonesia)
        this.DATE_FNS_CONF_MAP.set('is-is', 'is'); //Icelandic (Iceland)
        this.DATE_FNS_CONF_MAP.set('it-it', 'it'); //Italian (Italy)
        this.DATE_FNS_CONF_MAP.set('ja-jp', 'ja'); //Japanese (Japan)
        this.DATE_FNS_CONF_MAP.set('ka-ge', 'ka'); //Georgian (Georgia)
        this.DATE_FNS_CONF_MAP.set('kk-kz', 'kk'); //Kazakh (Kazakhstan)
        this.DATE_FNS_CONF_MAP.set('kn-in', 'kn'); //Kannada (India)
        this.DATE_FNS_CONF_MAP.set('ko-kr', 'ko'); //Korean (South Korea)
        this.DATE_FNS_CONF_MAP.set('lt-lt', 'lt'); //Lithuanian (Lithuania)
        this.DATE_FNS_CONF_MAP.set('lv-lv', 'lv'); //Latvian (Latvia)
        this.DATE_FNS_CONF_MAP.set('mk-mk', 'mk'); //Macedonian (Macedonia)
        this.DATE_FNS_CONF_MAP.set('ms-my', 'ms'); //Malay (Malaysia)
        this.DATE_FNS_CONF_MAP.set('mt-mt', 'mt'); //Maltese (Malta)
        this.DATE_FNS_CONF_MAP.set('nb-no', 'nb'); //Norwegian (Bokm?l) (Norway)
        this.DATE_FNS_CONF_MAP.set('nl-nl', 'nl'); //Dutch (Netherlands)
        this.DATE_FNS_CONF_MAP.set('nl-be', 'nl-BE'); //Dutch (Belgium)
        this.DATE_FNS_CONF_MAP.set('nn-no', 'nn'); //Norwegian (Nynorsk) (Norway)
        this.DATE_FNS_CONF_MAP.set('pl-pl', 'pl'); //Polish (Poland)
        this.DATE_FNS_CONF_MAP.set('pt-pt', 'pt'); //Portuguese (Portugal)
        this.DATE_FNS_CONF_MAP.set('pt-br', 'pt-BR'); //Portuguese (Brazil)
        this.DATE_FNS_CONF_MAP.set('ro-ro', 'ro'); //Romanian (Romania)
        this.DATE_FNS_CONF_MAP.set('ru-ru', 'ru'); //Russian (Russia)
        this.DATE_FNS_CONF_MAP.set('sk-sk', 'sk'); //Slovak (Slovakia)
        this.DATE_FNS_CONF_MAP.set('sl-si', 'sl'); //Slovenian (Slovenia)
        this.DATE_FNS_CONF_MAP.set('sr-rs', 'sr'); //Serbian (Serbia)
        this.DATE_FNS_CONF_MAP.set('sv-se', 'sv'); //Swedish (Sweden)
        this.DATE_FNS_CONF_MAP.set('ta-in', 'ta'); //Tamil (India)
        this.DATE_FNS_CONF_MAP.set('te-in', 'te'); //Telugu (India)
        this.DATE_FNS_CONF_MAP.set('th-th', 'th'); //Thai (Thailand)
        this.DATE_FNS_CONF_MAP.set('tr-tr', 'tr'); //Turkish (Turkey)
        this.DATE_FNS_CONF_MAP.set('uk-ua', 'uk'); //Ukrainian (Ukraine)
        this.DATE_FNS_CONF_MAP.set('uz-uz', 'uz'); //Uzbek (Latin) (Uzbekistan)
        this.DATE_FNS_CONF_MAP.set('vi-vn', 'vi'); //Vietnamese (Vietnam)
        this.DATE_FNS_CONF_MAP.set('zh-cn', 'zh-CN'); //Chinese (China)
        this.DATE_FNS_CONF_MAP.set('zh-tw', 'zh-TW'); //Chinese (Taiwan)
    }

    getDateFnsLocale(locale?: string) {
        //incase locale not provided, default to UK locale
        const localeReceived = locale || 'en-gb';
        let dateFnsLocale = this.DATE_FNS_CONF_MAP.get(
            localeReceived.toLowerCase()
        );

        if (!dateFnsLocale) {
            dateFnsLocale = 'en-GB';
        }
        return this.DATE_FNS_CONF_MAP.get(localeReceived.toLowerCase());
    }
}
