import React from 'react';
import { Modal } from '../../../common/modal/modal';
import { RecallsNotification } from '../recalls/recalls';
import './notifications-modal.scss';
import {
    SyncUpdatesNotification,
    SyncUpdatesNotificationObject,
} from '../sync-updates/sync-updates';
import { NotificationsFragment } from '../../../../models/experiencefragments/vehicle-notifications';
import { ListItem } from '../detailed-notification-item/detailed-notification-item';
import { VhaNotifications } from '../vha/vha-notifications';
import { VhaServiceResponse } from '../../../../services/vha-service/vha-service.interface';
import { SoftwareUpdatesNotifications } from '../ota/software-update-notifications';
import { MMOTAStatusResponse } from '../../../../services/mmota-status-service/mmota-status.interface';
import {
    ServiceRemindersNotification,
    ServiceRemindersNotificationObject,
} from '../service-reminders/service-reminders';
import { BRAND } from '../../../../constants';
import { FMModal } from '@own/fds-react';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
interface NotificationsModalProps {
    isVisible: boolean;
    content: NotificationsFragment | null;
    closeModal: () => void;
    total: number;
    hasRecall: boolean;
    recallDetails: ListItem[];
    syncUpdates: SyncUpdatesNotificationObject;
    vin?: string;
    vhaData: VhaServiceResponse | null | undefined;
    otaData: MMOTAStatusResponse | null | undefined;
    modelName?: string;
    modelYear?: number;
    serviceReminderDetails: ServiceRemindersNotificationObject | null;
}

export const NotificationsModal = (props: NotificationsModalProps) => {
    const {
        isVisible,
        content,
        closeModal,
        total,
        hasRecall,
        recallDetails,
        syncUpdates,
        vin,
        serviceReminderDetails,
    } = props;
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;

    return (
        <>
            {currentVersion ? (
                <Modal
                    name={`notifications-modal`}
                    isVisible={isVisible}
                    onClose={closeModal}
                >
                    <div className="notifications-header">
                        {total}{' '}
                        {total === 1
                            ? content?.singleNotificationModalTitle?.toUpperCase()
                            : content?.multipleNotificationsModalTitle?.toUpperCase()}
                    </div>
                    <div className="notifications-container">
                        {hasRecall && content && content.recalls && (
                            <>
                                <hr className="notifications-divider" />
                                <RecallsNotification
                                    recallDetails={recallDetails}
                                    content={content}
                                    vin={vin}
                                />
                            </>
                        )}
                        {content &&
                            content.serviceReminders &&
                            serviceReminderDetails &&
                            (serviceReminderDetails.srCount > 0 ||
                                serviceReminderDetails.olCount > 0 ||
                                serviceReminderDetails.smCount > 0) && (
                                <>
                                    <hr className="notifications-divider" />
                                    <ServiceRemindersNotification
                                        serviceReminderDetails={
                                            serviceReminderDetails
                                        }
                                        content={content.serviceReminders}
                                        vin={vin}
                                    />
                                </>
                            )}
                        {content && content.vha && props.vhaData && (
                            <VhaNotifications
                                content={content.vha}
                                vhaData={props.vhaData}
                                vin={props.vin}
                            />
                        )}
                        {content && content.otau && props.otaData && (
                            <SoftwareUpdatesNotifications
                                content={content.otau}
                                mmotaData={props.otaData}
                            ></SoftwareUpdatesNotifications>
                        )}
                        {syncUpdates.count > 0 && (
                            <>
                                <hr className="notifications-divider" />
                                <SyncUpdatesNotification
                                    syncUpdates={syncUpdates}
                                    content={content}
                                    vin={vin}
                                />
                            </>
                        )}
                    </div>
                </Modal>
            ) : (
                <FMModal
                    name={`fm-notifications-modal`}
                    isVisible={isVisible}
                    onClose={closeModal}
                    title={`${total + ' '}
                    ${
                        total === 1
                            ? content?.singleNotificationModalTitle?.toUpperCase()
                            : content?.multipleNotificationsModalTitle?.toUpperCase()
                    }`}
                    closeButtonAriaLabel={'close'}
                >
                    <div className="fm-notifications-container">
                        {hasRecall && content && content.recalls && (
                            <>
                                <hr className="notifications-divider" />
                                <RecallsNotification
                                    recallDetails={recallDetails}
                                    content={content}
                                    vin={vin}
                                />
                            </>
                        )}
                        {content &&
                            content.serviceReminders &&
                            serviceReminderDetails &&
                            (serviceReminderDetails.srCount > 0 ||
                                serviceReminderDetails.olCount > 0 ||
                                serviceReminderDetails.smCount > 0) && (
                                <>
                                    <hr className="notifications-divider" />
                                    <ServiceRemindersNotification
                                        serviceReminderDetails={
                                            serviceReminderDetails
                                        }
                                        content={content.serviceReminders}
                                        vin={vin}
                                    />
                                </>
                            )}
                        {content && content.vha && props.vhaData && (
                            <VhaNotifications
                                content={content.vha}
                                vhaData={props.vhaData}
                                vin={props.vin}
                            />
                        )}
                        {content && content.otau && props.otaData && (
                            <SoftwareUpdatesNotifications
                                content={content.otau}
                                mmotaData={props.otaData}
                            ></SoftwareUpdatesNotifications>
                        )}
                        {syncUpdates.count > 0 && (
                            <>
                                <hr className="notifications-divider" />
                                <SyncUpdatesNotification
                                    syncUpdates={syncUpdates}
                                    content={content}
                                    vin={vin}
                                />
                            </>
                        )}
                    </div>
                </FMModal>
            )}
        </>
    );
};
