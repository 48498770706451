import {
    DSL_API_PATH,
    DSL_BASE_URL,
    USE_CHECKSHEET_API,
} from '../../constants';
import HttpService from '../http-service/http-service';
import ServiceHandler from '../service-handler';
import {
    MaintenanceSchedule,
    ChecksheetMaintenanceSchedule,
} from '../../models/maintenance-schedule';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';

export default class MaintenanceScheduleService {
    public async getMaintenanceSchedule(
        vin: string
    ): Promise<MaintenanceSchedule | ChecksheetMaintenanceSchedule | null> {
        const {
            current3LetterCountryCode,
            currentLanguageCode,
        } = ServiceHandler.AppConfigurationService;
        const baseUrl = DSL_BASE_URL + DSL_API_PATH.MAINTENANCE_SCHEDULE;
        const countryCode =
            current3LetterCountryCode === 'lux'
                ? 'fra'
                : current3LetterCountryCode;
        const requestUrl = `${baseUrl}?country=${countryCode.toUpperCase()}&language=${currentLanguageCode.toUpperCase()}`;
        const headers = { ...HttpService.getAppIdRequestHeaders(), vin: vin };
        const isNA = new AppConfigurationService().isRegionNA();
        if (USE_CHECKSHEET_API && !isNA) {
            const checksheetResponse = HttpService.get<
                ChecksheetMaintenanceSchedule
            >(requestUrl, true, {
                headers,
            });
            return checksheetResponse.then(response => {
                if (response.data) {
                    const csData = response.data;
                    csData.lastRequested = csData.lastRequest;
                    for (let i = 0; i < csData.values.length; i++) {
                        csData.values[i].serviceTimeInterval =
                            csData.values[i].year;
                        csData.values[i].serviceTimeIntervalUnit =
                            csData.values[i].year === 1 ? 'year' : 'years';
                        csData.values[i].mileage = csData.values[
                            i
                        ].mileage.toString();
                        if (csData.values[i].footers.length > 0) {
                            csData.values[i].footer_description =
                                csData.values[i].footers[0].description;
                        }
                        for (
                            let j = 0;
                            j < csData.values[i].operations.length;
                            j++
                        ) {
                            csData.values[i].operations[j].id = csData.values[
                                i
                            ].operations[j].id.toString();
                            csData.values[i].operations[j].subHeading =
                                csData.values[i].operations[j].subheading;
                            csData.values[i].operations[j].description =
                                csData.values[i].operations[j].operation;
                        }
                    }
                    return csData;
                }
                return null;
            });
        } else {
            const response = HttpService.get<MaintenanceSchedule>(
                requestUrl,
                true,
                {
                    headers,
                }
            );
            return response.then(response => {
                if (response.data) {
                    return response.data;
                }
                return null;
            });
        }
    }
}
