import React from 'react';
import { VhaContent } from '../../../../models/experiencefragments/vehicle-notifications';
import {
    ActiveAlert,
    VhaServiceResponse,
} from '../../../../services/vha-service/vha-service.interface';
import { PrimaryButton } from '../../../common';
import { SimpleAccordion } from '../../../common/simple-accordion/simple-accordion';
import { NotificationItem } from '../notification-item/notification-item';
import './vha-notifications.scss';
import { useAnalytics } from '../../../../hooks/use-analytics';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { FMAccordion, FMButton } from '@own/fds-react';
import { PreImagineNotificationItem } from '../notification-item/pre-imagine-notification-item';
import { FMAccordionItemProps } from '@own/fds-react/dist/types/fm-accordion.types';

interface Props {
    content: VhaContent;
    vhaData: VhaServiceResponse;
    vin?: string;
}

export const VhaNotifications = (props: Props) => {
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    const getIconPath = (iconPath: string): string => {
        if (!iconPath) return '';
        return process.env.REACT_APP_AEM_BASE_URL + iconPath;
    };

    const alertHeadline = (activeAlert: ActiveAlert) => {
        return (
            <div className="vha-alert-headline">
                <div>
                    <img
                        className="vha-alert-headline__icon"
                        src={getIconPath(props.content.alertIconPath)}
                        alt=""
                    />
                </div>
                <div className="vha-alert-headline__title">
                    {activeAlert.headline}
                </div>
            </div>
        );
    };

    const urlWithVin = (ctaUrl: string) => {
        const shortcode = '[selected-vehicle-vin]';
        if (ctaUrl.includes(shortcode) && props.vin)
            return ctaUrl.replace(shortcode, props.vin);
        else return ctaUrl;
    };

    const [fireEvents] = useAnalytics();
    const triggerOnClickAnalytics = () => {
        fireEvents(
            ['notifications-cta-onclick-event'],
            undefined,
            {
                notificationCategory: 'vha',
                notificationCtaName: props.content.ctaTitle.toLowerCase(),
            },
            false
        );
    };

    const panels: FMAccordionItemProps[] = props.vhaData.vhaAlertResponseList[0].activeAlerts.map(
        (alert, idx) => {
            return {
                index: idx,
                title: alert.headline,
                panel: (
                    <div className="vha-alert-body">
                        <div className="whatIsHappening">
                            {props.content.whatIsHappening}
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: alert.body.whatIsHappening,
                            }}
                        ></div>
                        <div className="whatShouldIdo">
                            {props.content.whatShouldIdo}
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: alert.body.whatShouldIdo,
                            }}
                        ></div>
                    </div>
                ),
            };
        }
    );

    return (
        <>
            {props.vhaData.vhaAlertResponseList &&
            props.vhaData.vhaAlertResponseList.length > 0 &&
            props.vhaData.vhaAlertResponseList[0].activeAlerts &&
            props.vhaData.vhaAlertResponseList[0].activeAlerts.length > 0 &&
            currentVersion ? (
                <>
                    <hr className="notifications-divider" />
                    <div className="vha-alerts-container">
                        <NotificationItem
                            headText={`${props.vhaData.vhaAlertResponseList[0].activeAlerts.length} ${props.content.title}`}
                            subText={props.content.description}
                            iconPath={getIconPath(props.content.titleIconPath)}
                        ></NotificationItem>
                        <div className="vha-alerts-accordion-container">
                            {props.vhaData.vhaAlertResponseList[0].activeAlerts.map(
                                (alert, index) => {
                                    return (
                                        <SimpleAccordion
                                            key={`vhaAlert_${index}`}
                                            id={`vhaAlert_${index}`}
                                            header={alertHeadline(alert)}
                                            cta={alert.headline}
                                            category={props.content.title}
                                        >
                                            <div className="vha-alert-body">
                                                <div className="whatIsHappening">
                                                    {
                                                        props.content
                                                            .whatIsHappening
                                                    }
                                                </div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            alert.body
                                                                .whatIsHappening,
                                                    }}
                                                />
                                                <div className="whatShouldIdo">
                                                    {
                                                        props.content
                                                            .whatShouldIdo
                                                    }
                                                </div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            alert.body
                                                                .whatShouldIdo,
                                                    }}
                                                />
                                            </div>
                                        </SimpleAccordion>
                                    );
                                }
                            )}
                        </div>
                        {props.content.ctaTitle &&
                            props.content.ctaUrl &&
                            currentVersion && (
                                <div className="vha-alerts-cta">
                                    <PrimaryButton
                                        color="dark"
                                        fill="fill"
                                        chevron={true}
                                        ariaLabel={props.content.ctaAriaLabel}
                                        href={urlWithVin(props.content.ctaUrl)}
                                        target={
                                            props.content.targetBlank
                                                ? '_blank'
                                                : '_self'
                                        }
                                        onClick={triggerOnClickAnalytics}
                                    >
                                        {props.content.ctaTitle}
                                    </PrimaryButton>
                                </div>
                            )}
                    </div>
                </>
            ) : (
                props.vhaData.vhaAlertResponseList &&
                props.vhaData.vhaAlertResponseList.length > 0 &&
                props.vhaData.vhaAlertResponseList[0].activeAlerts &&
                props.vhaData.vhaAlertResponseList[0].activeAlerts.length >
                    0 && (
                    <>
                        <hr className="notifications-divider" />
                        <div className="vha-alerts-container">
                            <PreImagineNotificationItem
                                headText={`${props.vhaData.vhaAlertResponseList[0].activeAlerts.length} ${props.content.title}`}
                                subText={props.content.description}
                                iconPath={getIconPath(
                                    props.content.titleIconPath
                                )}
                            ></PreImagineNotificationItem>
                            <div className="vha-alerts-accordion-container">
                                <FMAccordion panels={panels}></FMAccordion>
                            </div>
                            {props.content.ctaTitle && props.content.ctaUrl && (
                                <div className="vha-alerts-cta preimagine-vha-cta">
                                    <FMButton
                                        type={'tertiary'}
                                        label={props.content.ctaTitle}
                                        chevron={'right'}
                                        ariaLabel={props.content.ctaAriaLabel}
                                        href={urlWithVin(props.content.ctaUrl)}
                                        target={
                                            props.content.targetBlank
                                                ? '_blank'
                                                : '_self'
                                        }
                                        onClick={triggerOnClickAnalytics}
                                    ></FMButton>
                                </div>
                            )}
                        </div>
                    </>
                )
            )}
        </>
    );
};
