import React, { useCallback, useEffect, useState } from 'react';

import './preimagine-quiet-cards.scss';
import { Article } from '../../../../../../models/experiencefragments/category-view';
import { FMCardFiftyFifty } from '@own/fds-react';
import { NewAnalyticsService } from '../../../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { CategoryOnClickCtaInfo } from '../../../../../../services/shortcode-service/shortcode-service';
import ServerSideService from '../../../../../../services/server-side-service/server-side-service';
import { transformRelativeUrlValidateRoot } from '../../../../../utils/router-util/router-util';

interface Props {
    className?: string;
    articles: Article[];
    firstCardImageContent?: boolean;
    categoryType?: string;
    categoryPath?: string;
    root?: string;
}

const PreImagineQuietCards = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const quietCards = props
        ? {
              ...props,
              articles: props?.articles.map(article => {
                  return {
                      ...article,
                      mobileImagePath: `${process.env.REACT_APP_AEM_BASE_URL}${article.mobileImagePath}`,
                      desktopImagePath: `${process.env.REACT_APP_AEM_BASE_URL}${article.desktopImagePath}`,
                  };
              }),
          }
        : null;

    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 991px)').matches
            : false
    );
    const handleWindowResize = useCallback(() => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 991px)').matches
                : false
        );
    }, [setMobile]);

    const cardFormat = (index: number) => {
        if (props.firstCardImageContent) {
            return index % 2 === 0 ? 'left' : 'right';
        } else return index % 2 === 0 ? 'right' : 'left';
    };

    const triggerAnalytics = async (card: Article) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            ctaName: card.ctaLabel,
            categoryType: props.categoryType,
            categoryPath: props.categoryPath,
            ctaUrl: card.ctaPath,
        };
        if (
            props.categoryType == 'category' ||
            props.categoryType == 'subcategory'
        ) {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                card.ctaPath
            );
            if (referralExit) {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            } else {
                fireEvents('category-onclick-event', undefined, {
                    categoryOnClickCtaInfo,
                });
            }
        }
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <article
            className={`preimagine-quiet-cards ${
                props.className ? props.className : ''
            }`}
        >
            {quietCards?.articles.map((card, index) => {
                return (
                    <section
                        key={card.plainTitle}
                        className="preimagine-quiet-card"
                    >
                        <FMCardFiftyFifty
                            {...{
                                title: card.title,
                                headline: card.headline,
                                description: card.description,
                                mediaSide: cardFormat(index),
                                media: {
                                    src: isMobile
                                        ? card.mobileImagePath
                                        : card.desktopImagePath,
                                    alt: card.imageAltText,
                                },
                                button: {
                                    type: 'secondary',
                                    label: card.ctaLabel,
                                    href: transformRelativeUrlValidateRoot(
                                        card.ctaPath ?? '',
                                        props?.root ?? ''
                                    ),
                                    disabled: false,
                                    onClick: () => triggerAnalytics(card),
                                    target: card.targetBlank ? '_blank' : '',
                                },
                            }}
                        />
                    </section>
                );
            })}
        </article>
    );
};

export default PreImagineQuietCards;
