import { AppEnvironmentConfiguration } from '../app-configuration';

export const appConfigurationMock: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'United States',
            countryCode: 'us',
            threeLetterCountryCode: 'usa',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-us',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 1,
                    languageISOCode: 'ENG',
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryCode: 'gb',
            threeLetterCountryCode: 'gbr',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-gb',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Australia',
            countryCode: 'au',
            threeLetterCountryCode: 'aus',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'English',
                    langScript: 'LATN',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Sweden',
            countryCode: 'se',
            threeLetterCountryCode: 'swe',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            apimUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            regionConfigurations: [
                {
                    domain: '',
                    root: '/',
                    language: 'Swedish',
                    langScript: 'LATN',
                    languageRegionCode: 'sv-se',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    languageSetId: 1,
                },
            ],
        },
    ],
};
