import { HttpsProxyAgent } from 'https-proxy-agent';
import HttpService from '../../../services/http-service/http-service';
import {
    BRAND_ATTRIBUTES,
    ASTUTE,
    SUPPORT_HUB_REGEX,
} from '../../support-constants';

const httpsAgent = new HttpsProxyAgent(
    process.env.http_proxy || 'http://internet.ford.com:83'
);

export default class EmplifiService {
    private auth = {
        username: process.env.REACT_APP_ASTUTE_USER_NAME || '',
        password: process.env.REACT_APP_ASTUTE_USER_PASSWORD || '',
    };

    public async astuteCallByTopicId(
        topicId: number,
        languageRegionCode: string,
        brand: string
    ) {
        languageRegionCode =
            languageRegionCode === 'fr-lu' ? 'fr-be' : languageRegionCode;
        const touchPointId = (BRAND_ATTRIBUTES as any)[brand].touchpoint;
        const params = {
            privilegedArea: ASTUTE.PRIVILEGED_AREA_WEB_ID,
        };
        return await HttpService.get(
            `https://${ASTUTE.BASE_URL}/${ASTUTE.KB_ID}/intents/${topicId}/locale/${languageRegionCode}/touchpoints/${touchPointId}/response`,
            false,
            {
                httpsAgent,
                auth: this.auth,
                params,
                proxy: false,
            }
        )
            .then(response => {
                return response.data;
            })
            .catch(e => {
                console.error(
                    `astuteCallByTopicId - Astute Upstream Failure - ${e.message} for topic Id - ${topicId}`
                );
                throw new Error(
                    `astuteCallByTopicId - Astute Upstream Failure - ${e.message} for topic Id - ${topicId}`
                );
            });
    }
    public async getSiteMap(
        pageNumber: number,
        supportedMarket: string,
        brand: string
    ) {
        supportedMarket =
            supportedMarket === 'fr-lu' ? 'fr-be' : supportedMarket;
        const touchPointId = (BRAND_ATTRIBUTES as any)[brand].touchpoint;
        const params = {
            touchpoints: touchPointId,
        };
        return await HttpService.get(
            `https://${ASTUTE.BASE_URL}/${ASTUTE.KB_ID}/intents/locale/${supportedMarket}/page/${pageNumber}`,
            false,
            {
                httpsAgent,
                auth: this.auth,
                params,
                proxy: false,
            }
        )
            .then(response => {
                return response.data;
            })
            .catch(e => {
                console.error(
                    `getSiteMap - Astute Upstream Failure - ${e.message} for supportedMarket - ${supportedMarket}`
                );
                throw new Error(
                    `getSiteMap - Astute Upstream Failure - ${e.message} for supportedMarket - ${supportedMarket}`
                );
            });
    }
    public async getSuggestedResults(
        currentSearchTerm: string,
        sessionID: string,
        brand: string
    ) {
        const searchUrl = (BRAND_ATTRIBUTES as any)[brand].searchUrl;

        if (sessionID) {
            return await HttpService.post(
                searchUrl.https + '/autocomplete',
                {
                    sessionID,
                    utterance: currentSearchTerm,
                    suggestionCount: 10,
                },
                { httpsAgent, auth: this.auth, proxy: false }
            )
                .then(result => {
                    let suggestions = result.data.suggestions;
                    suggestions = suggestions.map((item: string) =>
                        item.replace(SUPPORT_HUB_REGEX.ALL_MARKUP_TAGS, '')
                    );
                    return suggestions;
                })
                .catch(e => {
                    console.error(
                        `Get suggested results failure, couldn't get autocomplete: ${e.message}`
                    );
                    throw new Error(
                        `Get suggested results failure, couldn't get autocomplete: ${e.message}`
                    );
                });
        }
    }
    public async getSessionIdForSearch(
        languageRegionCode: string,
        brand: string
    ): Promise<string> {
        languageRegionCode =
            languageRegionCode === 'fr-lu' ? 'fr-be' : languageRegionCode;
        const searchUrl = (BRAND_ATTRIBUTES as any)[brand].searchUrl;
        return await HttpService.post(
            searchUrl.https + '/init',
            {
                market: languageRegionCode,
            },
            { httpsAgent, auth: this.auth, proxy: false }
        )
            .then(value => {
                return value.data.sessionID;
            })

            .catch(e => {
                console.error(
                    `getSessionIdForSearch - Astute Upstream Failure - ${e.message} for supportedMarket - ${languageRegionCode}`
                );
            });
    }
    public async astuteSearch(
        currentSearchTerm: string,
        sessionID: string,
        brand: string,
        questionID?: number
    ) {
        const searchUrl = (BRAND_ATTRIBUTES as any)[brand].searchUrl;
        if (sessionID) {
            return await HttpService.post(
                searchUrl.https + '/ask',
                {
                    sessionID,
                    requestUtterance: currentSearchTerm,
                    questionID: questionID ? questionID : '',
                },
                { httpsAgent, auth: this.auth, proxy: false }
            )
                .then(result => {
                    return result.data;
                })
                .catch(e => {
                    console.error(
                        `astuteSearch - Astute Upstream Failure - ${e.message} for supportedMarket - ${currentSearchTerm} ${questionID}`
                    );
                    throw new Error(
                        `astuteSearch - Astute Upstream Failure - ${e.message} for supportedMarket - ${currentSearchTerm} ${questionID}`
                    );
                });
        }
    }

    public async sendEmplifiFeedback(
        sessionID: string,
        topicId: number,
        rating: number,
        comments: string
    ) {
        const searchUrl = BRAND_ATTRIBUTES.ford.searchUrl;

        return HttpService.post(
            searchUrl.https + '/rating',
            {
                sessionID,
                rating: rating,
                comment: comments,
                intent: topicId,
            },
            { httpsAgent, auth: this.auth, proxy: false }
        )
            .then(result => {
                return result.data;
            })
            .catch(async e => {
                console.error(
                    `sendEmplifiFeedback - Astute Upstream Failure - ${e.message} for supportedMarket - ${topicId}`
                );
                throw new Error(
                    `sendEmplifiFeedback - Astute Upstream Failure - ${e.message} for supportedMarket - ${topicId}`
                );
            });
    }

    public async getTopicAttributes(
        brand: string,
        topicId: number,
        languageRegionCode: string
    ) {
        const attributesUrl = (BRAND_ATTRIBUTES as any)[brand].attributesUrl;
        const attributesRequestHeaders = (BRAND_ATTRIBUTES as any)[brand]
            .attributesRequestHeader;
        return await HttpService.post(
            attributesUrl + languageRegionCode + '/search',
            {
                filters: [
                    {
                        name: 'topicId',
                        values: [topicId],
                    },
                ],
            },
            { httpsAgent, proxy: false, headers: attributesRequestHeaders }
        )
            .then(result => {
                return result.data?.responses[0]?.attributes;
            })
            .catch((e: { message: any }) => {
                console.error(
                    `getTopicAttributes - Astute UpstABCream Failure - ${e.message} for supportedMarket - ${languageRegionCode} and Topic Id - ${topicId}`
                );
                throw new Error(
                    `getTopicAttributes - Astute UpstABCD2ream Failure - ${e.message} for supportedMarket - ${languageRegionCode} and Topic Id - ${topicId}`
                );
            });
    }
}
