interface PlaceHolderObj {
    [name: string]: string | undefined;
}

export const replacePlaceholderByValue = (
    data: string,
    placeHolderObj: PlaceHolderObj
): string => {
    if (data) {
        const placeHolderKeys = Object.keys(placeHolderObj);
        placeHolderKeys.forEach(key => {
            const [placeHolderString] = data.match('##' + key + '##') || [''];
            data = placeHolderString
                ? data.split(placeHolderString).join(placeHolderObj[key])
                : data;
        });
    }
    return data;
};
