import React, { useContext } from 'react';
import { DisclosuresLink } from '..';
import { SYNDICATED_HEADER_COUNTRIES } from '../../../constants';
import ServerContext from '../../../contexts/serverContext';

function DisclosuresLinkSyndicationAware() {
    const context = useContext(ServerContext);
    return SYNDICATED_HEADER_COUNTRIES.includes(context.currentRegionCode) ? (
        <></>
    ) : (
        <DisclosuresLink />
    );
}

export default DisclosuresLinkSyndicationAware;
