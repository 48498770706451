import React, { useRef, useEffect, useState } from 'react';
import './search-input-group.scss';
import { Search } from '../../../../models/experiencefragments/header';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import { KEYBOARD_KEYS } from '../../../../constants';

interface Props {
    content: Search;
    class?: string;
}

export const SearchInputGroup = (props: Props) => {
    const inputGroupRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const determinePlaceholder = () => {
        return serverSideService.isClientSide() &&
            document.body.clientWidth <= 768
            ? props.content.placeHolderTextForMobile
            : props.content.placeHolderText;
    };

    const [placeholder, setPlaceholder] = useState<string>(
        determinePlaceholder
    );

    const handleWindowResize = () => {
        setPlaceholder(determinePlaceholder);
    };
    useEffect(() => {
        if (inputRef.current) inputRef.current.click();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const search = (e: React.KeyboardEvent | React.MouseEvent) => {
        const searchUrl = props.content.url;
        let allowSearch = false;
        if (e.type === 'keyup') {
            const event = e as React.KeyboardEvent;
            event.key === KEYBOARD_KEYS.ENTER && (allowSearch = true);
        } else allowSearch = true;
        if (allowSearch)
            window.location.href = searchUrl.replace(
                'searchQuery',
                inputRef.current ? inputRef.current.value : ''
            );
    };

    return (
        <div
            ref={inputGroupRef}
            className={`search-input-group ${props.class ? props.class : ''}`}
        >
            <input
                ref={inputRef}
                onKeyUp={e => search(e)}
                placeholder={placeholder ? placeholder?.toUpperCase() : ''}
                aria-label="Search Input"
            ></input>
            <button
                onClick={e => search(e)}
                aria-label="Submit Search"
                className="search-icon-submit"
            >
                <span className="icon icon-search-glass-25px"></span>
            </button>
        </div>
    );
};
