import React from 'react';
import { SecondaryButton } from '../../../../../components/common';
import './redeem-rebates-banner-details.scss';

interface Props {
    bannerTitle: string;
    bannerDescription: string;
    href: string;
    linkText: string;
}

export const RedeemRebatesBannerDetails = (props: Props) => {
    return (
        <div className="redeem-rebates-top-text">
            <h1 className="redeem-rebates-title">{props.bannerTitle}</h1>
            <p className="banner-description">{props.bannerDescription}</p>
            <SecondaryButton link={props.href} linkTarget="_blank">
                {props.linkText}
            </SecondaryButton>
        </div>
    );
};
