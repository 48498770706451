import React, { useEffect, useState } from 'react';
import './timeslot-picker-lincoln.scss';
import Dropdown from '../../../common/dropdown/dropdown';

interface Props {
    data: string[];
    selectedDate: string;
    setSelectedTime?: (time: string) => void;
    setSelectedTimeRange?: (time: string) => void;
    message?: string;
    initSelectTime?: string;
    initSelectTimeRange?: string;
    localeAsString?: string;
    isMobileView: boolean;
    selectTimeLabel?: string;
    dropDownClickHandler?: () => void;
    hasMobileService?: boolean;
    isMobileServiceSelected?: boolean;
    timeRanges?: string[];
}
export const TimeslotPicker = (props: Props) => {
    const [userSelectedTime, setUserSelectedTime] = useState(
        props.initSelectTime && props.initSelectTime?.length > 0
            ? props.initSelectTime
            : props.data[0]
    );
    const [userSelectedTimeRange, setUserSelectedTimeRange] = useState(
        props.initSelectTimeRange && props.initSelectTimeRange?.length > 0
            ? props.initSelectTimeRange
            : props.timeRanges && props.timeRanges[0]
    );

    const selecetedTimeRange = (label: string) => {
        props.setSelectedTimeRange && props.setSelectedTimeRange(label);
        setUserSelectedTimeRange(label);
    };

    useEffect(() => {
        setUserSelectedTime(props.initSelectTime || props.data[0]);
        setUserSelectedTimeRange(
            props.initSelectTimeRange
                ? props.initSelectTimeRange
                : props.timeRanges
                ? props.timeRanges[0]
                : ''
        );
    }, [props.selectedDate]);

    const handleChange = (e: any) => {
        props.setSelectedTime && props.setSelectedTime(e.target.value);
        setUserSelectedTime(e.target.value);
    };
    const postTimeSlots = props.data.map((timeSlot, index) => (
        <span key={index}>
            <input
                data-testid={`Select ${timeSlot}`}
                type="radio"
                id={timeSlot + '-field'}
                name={timeSlot + '-field'}
                value={timeSlot}
                className="radio-custom"
                checked={userSelectedTime === timeSlot}
                onChange={e => handleChange(e)}
            />
            <label htmlFor={timeSlot + '-field'}>{timeSlot}</label>
        </span>
    ));

    return (
        <div className="osb-timeslot-picker-container">
            {!props.isMobileServiceSelected || !props.hasMobileService ? (
                <>
                    {props.isMobileView && (
                        <div className="main-head">{props.message}</div>
                    )}
                    <div className="timeslot-picker">
                        <div className="radio-toolbar">{postTimeSlots}</div>
                    </div>
                </>
            ) : (
                <>
                    {props.isMobileView && (
                        <div className="time-range-main-head">
                            {props.message}
                        </div>
                    )}
                    <div className="timerange-picker">
                        {props.isMobileView && (
                            <div className="input-label">
                                {props.selectTimeLabel}
                            </div>
                        )}
                        <Dropdown
                            showLabel={false}
                            label={userSelectedTimeRange || ''}
                            options={props.timeRanges || []}
                            value={userSelectedTimeRange}
                            className="osb-dropdown-input"
                            onChange={e => selecetedTimeRange(e)}
                            onDropdownClick={props.dropDownClickHandler}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
