/*
localstorage does not exist during builds, so we've created a
wrapper for localstorage that ensures it exists before it uses
localstorage functions
 */
const localStorageWrapper = {
    getItem(key: string) {
        if (this.isLocalStorageAvailable()) {
            return localStorage.getItem(key);
        }
    },
    setItem(key: string, item: any) {
        if (this.isLocalStorageAvailable()) {
            localStorage.setItem(key, item);
        }
    },
    isLocalStorageAvailable() {
        return typeof window !== 'undefined';
    },
    removeItem(key: string) {
        if (this.isLocalStorageAvailable()) {
            localStorage.removeItem(key);
        }
    },
};

export default localStorageWrapper;
