import React, { useState } from 'react';
export const UteContext = React.createContext<any>('');
export const UteProvider = ({ children }: any) => {
    const [uteContextFormData, setUteContextFormData] = useState({
        name: '',
        trailerList: {
            mfgName: '',
            mfgMake: '',
            mfgModel: '',
            mfgYear: '',
            smartNavLength: '',
            smartNavWidth: '',
            smartNavHeight: '',
            smartNavWeight: '',
            trailerCameraType: '0',
            trailerTpms: '0',
            trailerFrontalArea: '',
            trailerAxleCount: '0',
            trailerCouplerAxle: '',
            type: '',
            brakeType: '',
            distance: '',
        },
        errors: {
            mfgName: false,
            mfgMake: false,
            mfgModel: false,
            mfgYear: false,
        },
    });

    return (
        <UteContext.Provider
            value={{ uteContextFormData, setUteContextFormData }}
        >
            {children}
        </UteContext.Provider>
    );
};
