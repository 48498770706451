import React, { useEffect, useState } from 'react';
import { ViewTemplate } from '..';
import { PageType } from '../view-template/page-type';
import { useVehicleHealthReportContent } from './hooks/use-vehicle-health-report-content';
import { useQuietCardContent } from './hooks/use-quiet-card-content';
import { useGenericAppErrorContent } from '../error-message/hook/use-generic-app-error-content';
import { ErrorMessage } from '../error-message/error-message';
import { useVehiclePreferenceContent } from './hooks/use-vehicle-preference-content';
import { useRewardsContent } from './hooks/use-rewards-content';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { GoBackButton } from '../go-back-button/go-back-button';
import { Accordion, PrimaryButton, SecondaryButton } from '../../common';
import './vehicle-health-report.scss';
import warningIcon from '../../../assets/warning-filled.png';
import goodHealthIcon from '../../../assets/green-checkmark.svg';
import lincolnGoodHealthIcon from '../../../assets/lincoln-icons/checkmark.svg';
import lincolnWarningIcon from '../../../assets/lincoln-icons/error-notification.svg';
import { QuietCard } from '../../common/quiet-card/quiet-card';
import VhrService from '../../../services/vhr-service/vhr-service';
import VhrReportService from '../../../services/vhr-service/vhr-report';
import DateTimeUtil from '../../utils/date-time-util/date-time-util';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import PreferredDealerVHR from './preferred-dealer/preferred-dealer';
import { useAnalytics } from '../../../hooks/use-analytics';
import { BrandUtil } from '../../utils/brand-util/brand-util';
import { useOffersCouponContent } from './hooks/use-offers-coupons-content';
import { usePreferredDealer } from './hooks/use-preferred-dealer';
import { ViewportUtil } from '../../utils/viewport-util/viewport-util';
import { ScheduleReport } from './schedule-report/schedule-report';
import isWebviewCheck from '../../utils/webviewCheck/webviewCheck';

interface Props {
    page: PageType;
    vehicleAttributes: VehicleAttributes;
    vinNumber: string;
    uomDistance: number;
}

export const VehicleHealthReport = (props: Props) => {
    const vehicleHealthReportContent = useVehicleHealthReportContent();
    const genericAppErrorContent = useGenericAppErrorContent();
    const vehiclePreferenceContent = useVehiclePreferenceContent();
    const { year, make, model } = props.vehicleAttributes;
    const vehicleName = `${year} ${make} ${model}`;
    const [vhrReportAvailable, setVhrReportAvailable] = useState<boolean>(
        false
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        headline,
        downloadLabel,
        scheduleReportLabel,
        backButtonLabel,
        backButtonTarget,
        reportCaption,
        reportDisclaimer,
        oilLifeRemainingHeadline,
        oilLifeStatusLabel,
        oilLifeEstimatedDateLabel,
        oilLifeEstimatedDistanceLabel,
        milesUnitLabel,
        kmUnitLabel,
        vehicleInformationStatus,
        vehicleMileage,
        singularIssuesFound,
        pluralIssuesFound,
        reportStatus,
        reportAvailability,
        engineAndTransmissionAccordionHeadline,
        brakesAndSuspensionAccordionHeadline,
        restraintsAndDriverAssistanceAccordionHeadline,
        checkFluidsAndFilterAccordionHeadline,
        customerActionSubHeadline,
        warningSymptomSubHeadline,
        warningDescSubHeadline,
        eligibleSubheadline,
        eligibleDescription,
        eligibleWithUpgradeSubheadline,
        eligibleWithUpgradeDescription,
        expiredSubheadline,
        expiredDescription,
        ineligibleSubheadline,
        ineligibleDescription,
        vhrPageDisclaimer,
        goodIconAltText,
        warningIconAltText,
        vhrPageCTALabel,
        vhrPageCTAHref,
        vhrPageCTATargetBlank,
    } = vehicleHealthReportContent;
    const {
        vehPrefTitle,
        vehPrefTitle1Heading,
        vehPrefTitle1IconPath,
        vehPrefTitle1Description,
        vehPrefTitle1CTALabel,
        vehPrefTitle1CTALink,
        vehPrefTitle2Heading,
        vehPrefTitle2IconPath,
        vehPrefTitle2Description,
        vehPrefTitle2CTALabel,
        vehPrefTitle2CTALink,
        vehPrefTitle3Heading,
        vehPrefTitle3IconPath,
        vehPrefTitle3Description,
        vehPrefTitle3CTALabel,
        vehPrefTitle3CTALink,
    } = vehiclePreferenceContent;
    const { genericErrorMessage } = genericAppErrorContent;
    const [showError, setShowError] = useState<boolean>(false);
    const quietCardContent = useQuietCardContent();
    const offersCouponContent = useOffersCouponContent();
    const rewardsContent = useRewardsContent();

    const {
        rewardsTitle,
        colOneIcon: rewardsColOneIcon,
        colOneDescription: rewardsColOneDescription,
        colTwoIcon: rewardsColTwoIcon,
        colTwoDescription: rewardsColTwoDescription,
        colThreeIcon: rewardsColThreeIcon,
        colThreeDescription: rewardsColThreeDescription,
        ctaLabel: rewardsCtaLabel,
        ctaHref: rewardsCtaHref,
        rewardsDisclaimer,
    } = rewardsContent;

    const preferredDealerData = usePreferredDealer(props.vinNumber)
        ?.dealerResultList[0];
    const prefDealerScheduleServiceUrl =
        preferredDealerData?.scheduleServiceUrl;
    const [vhrAvailable, setVhrAvailable] = useState<boolean>(false);
    const [isVhrNoDataEligible, setIsVhrNoDataEligible] = useState<boolean>(
        false
    );
    const [isVhrNoDataIneligible, setIsVhrNoDataIneligible] = useState<boolean>(
        false
    );
    const [isVhrNoDataExpired, setIsVhrNoDataExpired] = useState<boolean>(
        false
    );
    const [
        isVhrNoDataEligibleWithUpgrade,
        setIsVhrNoDataEligibleWithUpgrade,
    ] = useState<boolean>(false);
    const [vhrReportResponse, setVhrReportResponse] = useState<any>();

    const isWebview = isWebviewCheck();

    let accordionKey = 0;
    const dateTimeUtil = new DateTimeUtil();
    const [fireEvents] = useAnalytics();
    const { brandName, isLincoln } = new BrandUtil();
    const { isMobileView } = ViewportUtil();
    /** Constants **/
    const VHR_STATUS_AVAILABLE = 'AVAILABLE';
    const VHR_STATUS_ELIGIBLE = 'ELIGIBLE';
    const VHR_STATUS_EXPIRED = 'EXPIRED';
    const VHR_STATUS_ELIGIBLE_WITH_UPGRADE = 'ELIGIBLE_WITH_UPGRADE';
    const VHR_OIL_STATUS_UNCERTAIN = 'UNCERTAIN';
    const VHR_OIL_STATUS_OK = 'OK';
    const VHR_OIL_LIFE_URGENCY_L = 'L';
    const VHR_OIL_LIFE_URGENCY_N = 'N';
    const VHR_STATUS_INELIGIBLE = 'INELIGIBLE';
    const VHR_VEHICLE_SYSTEM_OK = 'OK';

    const triggerLoadAnalytics = () => {
        fireEvents(
            ['target-trigger-view', 'vehicle-health-report'],
            'vehicle-health-report',
            { ymm: props.vehicleAttributes }
        );
    };

    const isNonEmptyArray = (element: any) =>
        Array.isArray(element) && element.length > 0;

    const scrollPageToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    useEffect(() => {
        scrollPageToTop();
        triggerLoadAnalytics();
        if (props.vinNumber) {
            setIsLoading(true);
            new VhrService()
                .request(props.vinNumber)
                .then(vhrResponse => {
                    if (vhrResponse?.vhrStatus === VHR_STATUS_AVAILABLE) {
                        setVhrAvailable(true);
                    } else if (vhrResponse?.vhrStatus === VHR_STATUS_ELIGIBLE) {
                        setIsVhrNoDataEligible(true);
                    } else if (
                        vhrResponse?.vhrStatus ===
                        VHR_STATUS_ELIGIBLE_WITH_UPGRADE
                    ) {
                        setIsVhrNoDataEligibleWithUpgrade(true);
                    } else if (vhrResponse?.vhrStatus === VHR_STATUS_EXPIRED) {
                        setIsVhrNoDataExpired(true);
                    } else if (
                        vhrResponse?.vhrStatus === VHR_STATUS_INELIGIBLE
                    ) {
                        setIsVhrNoDataIneligible(true);
                    } else {
                        setVhrAvailable(false);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setShowError(true);
                    setVhrAvailable(false);
                    setIsLoading(false);
                });
        }
    }, []);

    const isVhrReportEmpty = (vhrReportResponse: any) => {
        return (
            !isNonEmptyArray(vhrReportResponse?.coupons) &&
            !isNonEmptyArray(vhrReportResponse?.vehicleSystems) &&
            vhrReportResponse?.oilLife === null &&
            (vhrReportResponse?.scheduledMaintenance?.vehicleMileage === null ||
                vhrReportResponse?.scheduledMaintenance?.vehicleMileage === 0)
        );
    };

    useEffect(() => {
        if (vhrAvailable) {
            setIsLoading(true);
            new VhrReportService()
                .request(props.vinNumber)
                .then(vhrResponse => {
                    if (!isVhrReportEmpty(vhrResponse)) {
                        setVhrReportResponse(vhrResponse);
                        setVhrReportAvailable(true);
                    } else {
                        setVhrReportAvailable(false);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setShowError(true);
                    setVhrReportAvailable(false);
                    setIsLoading(false);
                });
        }
    }, [vhrAvailable]);

    const triggerDownloadAnalytics = () => {
        fireEvents(
            'vehicle-health-report-download-onclick-event',
            undefined,
            undefined,
            false
        );
    };
    const triggerScheduleServiceAnalytics = (ctaContext: string) => {
        fireEvents(
            ['vhr-cta-onclick-event'],
            undefined,
            {
                fieldName: ctaContext?.toLowerCase(),
            },
            false
        );
    };
    const getStatusIcon = (isError: boolean) => {
        if (isError) {
            return isLincoln ? lincolnWarningIcon : warningIcon;
        } else {
            return isLincoln ? lincolnGoodHealthIcon : goodHealthIcon;
        }
    };

    const downloadCopy = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.media = 'print';

        style.appendChild(
            document.createTextNode(
                '@page { size: A4 Portrait !important;margin:15mm;}'
            )
        );
        head.appendChild(style);
        window.print();
        triggerDownloadAnalytics();
    };

    const renderMessage = (title: string, message: string) => {
        return (
            <>
                <div className="vhr-section-title">
                    <div className="vhr-accordion-icon">
                        <img alt={warningIconAltText} />
                    </div>
                    <h2>{title}</h2>
                </div>
                <div
                    className="vhr-section-data"
                    dangerouslySetInnerHTML={{
                        __html: message,
                    }}
                ></div>
            </>
        );
    };

    const renderNoDataAvailable = () => {
        let noVhrHeadLine = '';
        let noVhrDesc = '';
        if (isVhrNoDataEligible) {
            noVhrHeadLine = eligibleSubheadline;
            noVhrDesc = eligibleDescription;
        } else if (isVhrNoDataEligibleWithUpgrade) {
            noVhrHeadLine = eligibleWithUpgradeSubheadline;
            noVhrDesc = eligibleWithUpgradeDescription;
        } else if (isVhrNoDataExpired) {
            noVhrHeadLine = expiredSubheadline;
            noVhrDesc = expiredDescription;
        } else if (isVhrNoDataIneligible) {
            noVhrHeadLine = ineligibleSubheadline;
            noVhrDesc = ineligibleDescription;
        }
        return renderMessage(noVhrHeadLine, noVhrDesc);
    };

    const getVehicleSystemHeader = (report: any) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        const symbolIdToAuthoredHeading: any = {
            vhr_sys_enginetrans: engineAndTransmissionAccordionHeadline,
            vhr_sys_brakes: brakesAndSuspensionAccordionHeadline,
            vhr_sys_restraints: restraintsAndDriverAssistanceAccordionHeadline,
            vhr_sys_checksfluids: checkFluidsAndFilterAccordionHeadline,
        };
        /* eslint-enable @typescript-eslint/naming-convention */
        const isSystemHealthOK =
            report.status?.toUpperCase() === VHR_VEHICLE_SYSTEM_OK;
        return (
            <>
                <div className="vhr-accordion-icon">
                    <img
                        src={getStatusIcon(!isSystemHealthOK)}
                        alt={
                            isSystemHealthOK
                                ? goodIconAltText
                                : warningIconAltText
                        }
                    />
                </div>
                <div className="vhr-accordion-title">
                    {symbolIdToAuthoredHeading[report.symbolId]}
                </div>
            </>
        );
    };

    const getVehicleSystemDetails = (report: any) => {
        return (
            <>
                <div className="vhr-accordion-details">
                    {report?.description && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: report.description,
                            }}
                        />
                    )}
                    {isNonEmptyArray(report?.warnings) &&
                        report.warnings.map((warning: any, index: number) => (
                            <div
                                key={warning?.description}
                                id={`${report.symbolId}_${index}`}
                                className="warning"
                            >
                                {warning?.customerAction && (
                                    <div className="customer-action">
                                        <span className="headline">
                                            {customerActionSubHeadline}
                                        </span>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: warning.customerAction,
                                            }}
                                        />
                                    </div>
                                )}
                                {warning?.symptom && (
                                    <div className="symptom">
                                        <span className="headline">
                                            {warningSymptomSubHeadline}
                                        </span>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: warning.symptom,
                                            }}
                                        />
                                    </div>
                                )}
                                {warning?.description && (
                                    <div className="description">
                                        <span className="headline">
                                            {warningDescSubHeadline}
                                        </span>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: warning.description,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </>
        );
    };

    const renderVehicleHealthSystemsAccordion = () => {
        const { vehicleSystems } = vhrReportResponse;
        const vehicleSystemsOrder = [
            'vhr_sys_enginetrans',
            'vhr_sys_brakes',
            'vhr_sys_restraints',
            'vhr_sys_checksfluids',
        ];
        const sortedVehicleSystems = vehicleSystems.sort((a: any, b: any) => {
            return (
                vehicleSystemsOrder.indexOf(a.symbolId) -
                vehicleSystemsOrder.indexOf(b.symbolId)
            );
        });
        return sortedVehicleSystems.map((report: any, key: number) => {
            return (
                <Accordion
                    key={report.symbolId}
                    header={getVehicleSystemHeader(report)}
                    panel={getVehicleSystemDetails(report)}
                    className={'vhr-accordion'}
                    index={`${key + 1}`}
                    expandMultiplePanels={true}
                />
            );
        });
    };

    const getFormattedDate = () => {
        const date = vhrReportResponse?.collectionDate;
        if (date) {
            return new DateTimeUtil().convertISOToDate(date);
        }
        return '';
    };

    const getVehicleMileage = () => {
        const vehicleMileage =
            vhrReportResponse?.scheduledMaintenance?.vehicleMileage;
        if (vehicleMileage > 0 && vehicleMileage !== null) {
            return vehicleMileage;
        }
        return '';
    };

    const getSeperator = () => {
        if (getFormattedDate() !== '' && getVehicleMileage() !== '') {
            return '|';
        }
        return '';
    };

    const getFormattedDistance = () => {
        const estimatedDistance = vhrReportResponse?.oilLife?.estimatedDistance;
        if (
            !estimatedDistance ||
            !estimatedDistance.miles ||
            !estimatedDistance.kms
        ) {
            return '';
        }
        if (props.uomDistance == 1) {
            return `${estimatedDistance?.miles} ${milesUnitLabel}`;
        }
        return `${estimatedDistance?.kms} ${kmUnitLabel}`;
    };

    const hasOilLifeIssue = () => {
        if (vhrReportResponse) {
            const oilLife = vhrReportResponse.oilLife;
            if (oilLife && vhrReportResponse.prognosticsOilCapable) {
                const urgency = oilLife.urgency;
                if (
                    urgency != null &&
                    urgency !== VHR_OIL_LIFE_URGENCY_N &&
                    urgency !== VHR_OIL_LIFE_URGENCY_L
                ) {
                    return 1;
                }
            }
            const oilStatus = vhrReportResponse.oilStatus;
            if (
                oilStatus != null &&
                oilStatus !== VHR_OIL_STATUS_OK &&
                oilStatus !== VHR_OIL_STATUS_UNCERTAIN
            ) {
                return 1;
            }
        }
        return 0;
    };

    const getNumberOfIssues = () => {
        if (!vhrReportResponse) {
            return 0;
        }
        let noOfIssues = 0;
        const vehicleSystems = vhrReportResponse.vehicleSystems;
        if (Array.isArray(vehicleSystems) && vehicleSystems.length > 0) {
            vehicleSystems.forEach((vehicleSystem: any) => {
                const warnings = vehicleSystem.warnings;
                if (Array.isArray(warnings) && warnings.length > 0) {
                    noOfIssues += warnings.length;
                }
            });
        }
        return noOfIssues + hasOilLifeIssue();
    };

    const getFormattedOilLifeDate = () => {
        const estimatedDate = vhrReportResponse?.oilLife?.estimatedDate;
        if (!estimatedDate) {
            return '';
        }
        let dateString = `${estimatedDate.year}-${estimatedDate.month}`;
        if (estimatedDate.date) {
            dateString += `-${estimatedDate.date}`;
        }
        return dateTimeUtil.formatDateByRegion(
            dateString,
            !!estimatedDate.date
        );
    };

    const showOilLifeAccordion = () => {
        return !!vhrReportResponse?.oilLife?.iolm;
    };

    const showVehicleSystemsAccordion = () =>
        vhrAvailable && vhrReportResponse?.vehicleSystems?.length > 0;

    const renderOilLifeAccordion = () => {
        const oilLife = vhrReportResponse?.oilLife;
        const oilLifeIssue = hasOilLifeIssue();
        const oilLifeHeaderIcon = getStatusIcon(oilLifeIssue > 0);
        const oilLifeHeaderIconAltText =
            oilLifeIssue > 0 ? warningIconAltText : goodIconAltText;

        const title = (
            <>
                <div className="vhr-accordion-icon">
                    <img
                        src={oilLifeHeaderIcon}
                        alt={oilLifeHeaderIconAltText}
                    />
                </div>
                <div className="vhr-accordion-title">
                    <h3>
                        {oilLifeRemainingHeadline} {oilLife?.iolm}
                    </h3>
                    <span>
                        {oilLifeStatusLabel} {oilLife?.status}
                    </span>
                    <br />
                    <span>
                        {oilLifeEstimatedDateLabel} {getFormattedOilLifeDate()}
                    </span>
                    <br />
                    <span>
                        {oilLifeEstimatedDistanceLabel} {getFormattedDistance()}
                    </span>
                </div>
            </>
        );

        const expandableSection = oilLife?.expandableSection;

        const description = (
            <div className="vhr-accordion-details">
                {expandableSection?.paragraph1 && (
                    <p>{expandableSection.paragraph1}</p>
                )}
                {expandableSection?.paragraph2 && (
                    <p>{expandableSection.paragraph2}</p>
                )}
                {expandableSection?.paragraph3 && (
                    <p>{expandableSection.paragraph3}</p>
                )}
            </div>
        );

        return (
            <Accordion
                key={accordionKey}
                header={title}
                panel={description}
                className={'vhr-accordion'}
                index={(accordionKey++).toString()}
                expandMultiplePanels={true}
            />
        );
    };

    const renderAccordion = () => {
        return (
            <>
                {showOilLifeAccordion() && renderOilLifeAccordion()}
                {showVehicleSystemsAccordion() &&
                    renderVehicleHealthSystemsAccordion()}
            </>
        );
    };

    const renderVHRCta = () => {
        if (vhrPageCTALabel) {
            let ctaHref;
            if (prefDealerScheduleServiceUrl) {
                ctaHref = prefDealerScheduleServiceUrl;
            } else if (vhrPageCTAHref) {
                ctaHref = vhrPageCTAHref;
            }
            if (ctaHref) {
                return (
                    <div className="vhr-cta-container">
                        <PrimaryButton
                            color="dark"
                            fill="fill"
                            chevron={true}
                            ariaLabel={vhrPageCTALabel}
                            href={ctaHref}
                            onClick={() =>
                                triggerScheduleServiceAnalytics(vhrPageCTALabel)
                            }
                            target={vhrPageCTATargetBlank ? '_blank' : ''}
                        >
                            {vhrPageCTALabel}
                        </PrimaryButton>
                    </div>
                );
            }
        }
    };

    const numberOfIssues = getNumberOfIssues();
    const healthIcon = getStatusIcon(numberOfIssues > 0);
    const healthIconAltText =
        numberOfIssues > 0 ? warningIconAltText : goodIconAltText;

    const renderDownloadButton = () => {
        return downloadLabel && vhrAvailable ? (
            <div className="vhr-download-cta">
                <PrimaryButton
                    color={'light'}
                    fill={'fill'}
                    chevron={false}
                    className={' no-shadow-transparent vhr-download'}
                    onClick={downloadCopy}
                >
                    <div className="vhr-download-button">
                        <img
                            className="vhr-download-icon"
                            alt="Vehicle health report Download"
                        />
                        {downloadLabel}
                    </div>
                </PrimaryButton>
            </div>
        ) : null;
    };

    const handleScheduleReport = () => {
        return (
            scheduleReportLabel && (
                <ScheduleReport
                    vinNumber={props.vinNumber}
                    scheduleReportLabel={scheduleReportLabel}
                    aem_category="vehicles"
                    aem_name="vehicle-health-reports/schedule-report"
                />
            )
        );
    };

    return isLoading ? (
        <ActivityIndicator />
    ) : (
        <>
            <ViewTemplate
                page={props.page}
                breadcrumb={!isWebview ? vehicleName : ''}
                className="vehicle-health-report-view"
                vehicleAttributes={props.vehicleAttributes}
                hideLinksList={true}
                hideSearchBar={isWebview}
            >
                <section className="vehicle-health-report-page">
                    <section className="vhr-page-header">
                        <div className="header-links">
                            {backButtonLabel && !isWebview && (
                                <GoBackButton
                                    goBackLabel={backButtonLabel}
                                    goBackUrl={backButtonTarget}
                                />
                            )}
                            {!showError &&
                                !isMobileView &&
                                !isWebview &&
                                renderDownloadButton()}
                        </div>
                        <div className="print-header print-only">
                            <img
                                alt={`${brandName}-logo`}
                                className="print-header-ford-logo"
                            />
                        </div>
                        {showError && (
                            <>
                                <div className="vhr-page-title vhr-page-error">
                                    <h1>{headline}</h1>
                                    <div className="oops-error">
                                        <ErrorMessage
                                            errorMessage={genericErrorMessage}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {!showError ? (
                            <>
                                <div className="vhr-page-title">
                                    <p className="vehicle-name">
                                        {vehicleName}
                                    </p>
                                    <h1>{headline}</h1>
                                    {!showError && vhrReportAvailable && (
                                        <p className="vhr-title-subtext">
                                            {getFormattedDate() !== '' ? (
                                                <span
                                                    className={'vehicle-status'}
                                                >
                                                    {vehicleInformationStatus}{' '}
                                                    {getFormattedDate()}
                                                </span>
                                            ) : (
                                                ''
                                            )}{' '}
                                            {getSeperator()}{' '}
                                            {getVehicleMileage() !== '' ? (
                                                <span
                                                    className={
                                                        'vehicle-mileage'
                                                    }
                                                >
                                                    {vehicleMileage}{' '}
                                                    {getVehicleMileage()}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </p>
                                    )}
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </section>
                    {!showError && (
                        <section className="vhr-page-content">
                            {vhrReportAvailable && (
                                <div className="vhr-alert-notification">
                                    <img
                                        className="vhr-alert-notification-icon"
                                        src={healthIcon}
                                        alt={healthIconAltText}
                                    />
                                    <span className="vhr-alert-notification-title">
                                        {numberOfIssues}{' '}
                                        {numberOfIssues === 1
                                            ? singularIssuesFound
                                            : pluralIssuesFound}
                                    </span>
                                    {vhrReportResponse?.collectionDate && (
                                        <p className="note">
                                            {reportStatus} {getFormattedDate()}.{' '}
                                            {reportAvailability}
                                        </p>
                                    )}
                                    {renderVHRCta() && !isWebview}
                                    {isMobileView &&
                                        !isWebview &&
                                        renderDownloadButton()}
                                    {isMobileView && handleScheduleReport()}
                                </div>
                            )}
                            {!vhrReportAvailable &&
                                isMobileView &&
                                isVhrNoDataEligible && (
                                    <div className="vhr-alert-notification">
                                        {handleScheduleReport()}
                                    </div>
                                )}
                            {reportCaption && !isVhrNoDataIneligible && (
                                <>
                                    <div
                                        className="vhr-text-content"
                                        dangerouslySetInnerHTML={{
                                            __html: reportCaption,
                                        }}
                                    ></div>
                                    {reportDisclaimer && (
                                        <div
                                            className="note"
                                            dangerouslySetInnerHTML={{
                                                __html: reportDisclaimer,
                                            }}
                                        ></div>
                                    )}
                                </>
                            )}
                            {!isMobileView && handleScheduleReport()}
                            <section className="vhr-accordion-section">
                                {!vhrReportAvailable
                                    ? renderNoDataAvailable()
                                    : renderAccordion()}
                            </section>
                        </section>
                    )}
                </section>
                <div className="vhr-brochureware-wrapper">
                    {quietCardContent && !isWebview && (
                        <section className="vhr-service-section">
                            <QuietCard
                                title={quietCardContent.headline}
                                body={quietCardContent.description}
                                ctaLabel={quietCardContent.ctaLabel}
                                ctaUrl={quietCardContent.ctaHref}
                                ctaAriaLabel={quietCardContent.ctaAriaLabel}
                                targetBlank={true}
                                mobileImageUrl={`${process.env.REACT_APP_AEM_BASE_URL}${quietCardContent.mobileImagePath}`}
                                desktopImageUrl={`${process.env.REACT_APP_AEM_BASE_URL}${quietCardContent.desktopImagePath}`}
                                format="image-content"
                            />
                        </section>
                    )}

                    {rewardsTitle && !isWebview && (
                        <section className="rewards-section">
                            <h2>{rewardsTitle}</h2>
                            <div className="rewards-3col-container">
                                <div>
                                    <img
                                        className="icon"
                                        alt=""
                                        src={`${process.env.REACT_APP_AEM_BASE_URL}${rewardsColOneIcon}`}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: rewardsColOneDescription,
                                        }}
                                    ></p>
                                </div>
                                <div>
                                    <img
                                        className="icon"
                                        alt=""
                                        src={`${process.env.REACT_APP_AEM_BASE_URL}${rewardsColTwoIcon}`}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: rewardsColTwoDescription,
                                        }}
                                    ></p>
                                </div>
                                <div>
                                    <img
                                        className="icon"
                                        alt=""
                                        src={`${process.env.REACT_APP_AEM_BASE_URL}${rewardsColThreeIcon}`}
                                    />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: rewardsColThreeDescription,
                                        }}
                                    ></p>
                                </div>
                            </div>
                            {rewardsCtaLabel && rewardsCtaHref && (
                                <div className="rewards-cta-container">
                                    <PrimaryButton
                                        color="dark"
                                        fill="fill"
                                        chevron={true}
                                        ariaLabel={rewardsCtaLabel}
                                        href={rewardsCtaHref}
                                        target="_blank"
                                    >
                                        {rewardsCtaLabel}
                                    </PrimaryButton>
                                </div>
                            )}
                            {rewardsDisclaimer && (
                                <p
                                    className="note"
                                    dangerouslySetInnerHTML={{
                                        __html: rewardsDisclaimer,
                                    }}
                                ></p>
                            )}
                        </section>
                    )}

                    <PreferredDealerVHR
                        vinNumber={props.vinNumber}
                        preferredDealerData={preferredDealerData}
                    />

                    {Object.keys(offersCouponContent).length > 0 &&
                    !isWebview ? (
                        <section className="offers-coupons-section">
                            <QuietCard
                                subtitle={offersCouponContent.subHeadline}
                                title={offersCouponContent.headline}
                                body={offersCouponContent.description}
                                ctaLabel={offersCouponContent.ctaLabel}
                                ctaUrl={offersCouponContent.ctaHref}
                                targetBlank={true}
                                ctaAriaLabel={offersCouponContent.ctaLabel}
                                mobileImageUrl={`${process.env.REACT_APP_AEM_BASE_URL}${offersCouponContent.mobileImagePath}`}
                                desktopImageUrl={`${process.env.REACT_APP_AEM_BASE_URL}${offersCouponContent.desktopImagePath}`}
                                format="image-content"
                            />
                        </section>
                    ) : (
                        ''
                    )}

                    {!isWebview && (
                        <section className="vhr-preferences-section smart-tile-grid-wrapper">
                            <h2>{vehPrefTitle}</h2>
                            <div className="inner-smart-tile-grid">
                                {vehPrefTitle1Heading &&
                                    vehPrefTitle1Description && (
                                        <div>
                                            <div className="smart-tile smart-tile-icon">
                                                <h3 className="heading-h3 smart-tile-header  none">
                                                    {vehPrefTitle1Heading}
                                                </h3>
                                                <span>
                                                    <img
                                                        className="icon"
                                                        alt=""
                                                        src={`${process.env.REACT_APP_AEM_BASE_URL}${vehPrefTitle1IconPath}`}
                                                    />
                                                </span>
                                                <div className="description">
                                                    {vehPrefTitle1Description}
                                                </div>
                                                {vehPrefTitle1CTALink &&
                                                    vehPrefTitle1CTALabel && (
                                                        <SecondaryButton
                                                            linkTarget="_blank"
                                                            link={
                                                                vehPrefTitle1CTALink
                                                            }
                                                            noChevron={false}
                                                            aria-label={
                                                                vehPrefTitle1CTALabel
                                                            }
                                                            dataTestId="manage-notifications"
                                                        >
                                                            {
                                                                vehPrefTitle1CTALabel
                                                            }
                                                        </SecondaryButton>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                {vehPrefTitle2Heading &&
                                    vehPrefTitle2Description && (
                                        <div>
                                            <div className="smart-tile smart-tile-icon">
                                                <h3 className="heading-h3 smart-tile-header  none">
                                                    {vehPrefTitle2Heading}
                                                </h3>
                                                <span>
                                                    <img
                                                        className="icon"
                                                        alt=""
                                                        src={`${process.env.REACT_APP_AEM_BASE_URL}${vehPrefTitle2IconPath}`}
                                                    />
                                                </span>
                                                <div className="description">
                                                    {vehPrefTitle2Description}
                                                </div>
                                                {vehPrefTitle2CTALink &&
                                                    vehPrefTitle2CTALabel && (
                                                        <SecondaryButton
                                                            linkTarget="_blank"
                                                            link={
                                                                vehPrefTitle2CTALink
                                                            }
                                                            noChevron={false}
                                                            aria-label={
                                                                vehPrefTitle2CTALabel
                                                            }
                                                            dataTestId="view-vehicle-details"
                                                        >
                                                            {
                                                                vehPrefTitle2CTALabel
                                                            }
                                                        </SecondaryButton>
                                                    )}
                                            </div>
                                        </div>
                                    )}

                                {vehPrefTitle3Heading &&
                                    vehPrefTitle3Description && (
                                        <div>
                                            <div className="smart-tile smart-tile-icon">
                                                <h3 className="heading-h3 smart-tile-header  none">
                                                    {vehPrefTitle3Heading}
                                                </h3>
                                                <span>
                                                    <img
                                                        className="icon"
                                                        alt=""
                                                        src={`${process.env.REACT_APP_AEM_BASE_URL}${vehPrefTitle3IconPath}`}
                                                    />
                                                </span>
                                                <div className="description">
                                                    {vehPrefTitle3Description}
                                                </div>
                                                {vehPrefTitle3CTALink &&
                                                    vehPrefTitle3CTALabel && (
                                                        <SecondaryButton
                                                            linkTarget="_blank"
                                                            link={
                                                                vehPrefTitle3CTALink
                                                            }
                                                            noChevron={false}
                                                            aria-label={
                                                                vehPrefTitle3CTALabel
                                                            }
                                                            dataTestId="learn-more"
                                                        >
                                                            {
                                                                vehPrefTitle3CTALabel
                                                            }
                                                        </SecondaryButton>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </section>
                    )}
                    {vhrReportAvailable && vhrPageDisclaimer && (
                        <section className="vhr-page-disclaimer">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: vhrPageDisclaimer,
                                }}
                            />
                        </section>
                    )}
                </div>
            </ViewTemplate>
        </>
    );
};
