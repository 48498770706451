import React, { useEffect, useReducer } from 'react';
import { LightCancelBookingConfirmation } from '../../../components/sections/owners-osb/cancel-booking-confirmation/light-journey/light-cancel-booking-confirmation';
import { SERVICE_FLOW } from '../../../components/sections/owners-osb/osb-constant';
import { useRetrieveBookingStep } from '../../../hooks/owners-osb';
import './cancel-booking-confirmation-view.scss';

interface CancelBookingState {
    mainServiceName: string;
    dealerName: string;
    appointmentDate: string;
    appointmentTime: string;
}

interface CancelBookingAction {
    type: string;
    payload: CancelBookingState;
}

const cancelBookingReducer = (
    state: CancelBookingState,
    action: CancelBookingAction
): CancelBookingState => {
    return action.type === 'SET' ? { ...state, ...action.payload } : state;
};

export const CancelBookingConfirmationView = () => {
    const [cancelBookingDetail, dispatch] = useReducer(cancelBookingReducer, {
        mainServiceName: '',
        dealerName: '',
        appointmentDate: '',
        appointmentTime: '',
    });

    const { osbRetrieveBookingStep } = useRetrieveBookingStep();

    const initBookingDetails = () => {
        const bookingDetails = osbRetrieveBookingStep.retrieveBookingDetails;

        if (bookingDetails && bookingDetails?.bookings.length > 0) {
            const booking = bookingDetails.bookings[0];
            if (booking) {
                const splitDateTime = booking.appointmentDateTimeLocal?.split(
                    'T'
                );

                dispatch({
                    type: 'SET',
                    payload: {
                        appointmentDate:
                            splitDateTime?.length > 0 ? splitDateTime[0] : '',
                        appointmentTime:
                            splitDateTime?.length > 1
                                ? splitDateTime[1].substring(
                                      0,
                                      splitDateTime[1].lastIndexOf(':')
                                  )
                                : '',
                        dealerName: booking.dealer?.dealerName,
                        mainServiceName:
                            booking.mainService?.name !== undefined
                                ? booking.mainService?.name
                                : booking.oldServices.filter(
                                      item =>
                                          item.subType ==
                                          SERVICE_FLOW.SUBTYPE_VALUE_SERVICE
                                  )[0].name,
                    },
                });
            }
        }
    };
    useEffect(() => {
        initBookingDetails();
    }, []);
    return (
        <div className="cancel-booking-confirmation-container">
            <LightCancelBookingConfirmation
                mainService={cancelBookingDetail.mainServiceName}
                dealerName={cancelBookingDetail.dealerName}
                appointmentDate={cancelBookingDetail.appointmentDate}
                appointmentTime={cancelBookingDetail.appointmentTime}
            />
        </div>
    );
};
