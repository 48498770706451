import { useContent } from '../../../../hooks/use-server-content';

export interface FmaLoginProps {
    hide?: string;
    unauthenticatedTitle: string;
    unauthenticatedDescription: string;
    unauthenticatedButtonText: string;
    authenticatedTitle: string;
    authenticatedButtonText: string;
    noVehiclesMessage: string;
    loginDisabledCheck: boolean;
}
export const useFmaLoginValueContent = (
    componentName?: string
): FmaLoginProps | null => {
    const [content, getValueByName] = useContent(
        componentName || 'common',
        'fma-login'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.unauthenticatedTitle) {
            contentFragment = null;
        }
    }
    return contentFragment as FmaLoginProps;
};
