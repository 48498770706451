import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
export interface VehicleData {
    smartCharge?: string;
    wifiHotspot?: string;
    sdn?: any;
    model: string;
    tirePressureMonitoring?: string;
    VINLookupCanITow?: string;
    grossVehicleWeightRatingPounds?: string;
    grossCombinedWeightRatingPounds?: string;
    maximumPayloadPounds?: string;
    maximumConventionalPounds?: string;
    maxFifthPounds?: string;
    maxGooseneckPounds?: string;
    warrantyStartDate?: string;
    year: string;
    make: string;
    asBuiltCountry?: string;
    bodyStyleDescription?: string;
    fuelType?: string;
    engineType?: string;
    vin: string;
    citivanVin?: boolean;
    blueCruiseGeneration?: string;
}
export interface VehicleType {
    vehicleData?: VehicleData;
    isBev: boolean;
    isWifiCapable?: boolean;
    isConnected?: boolean;
    isIolmCapable?: boolean;
    isTpmAvailable?: boolean;
}

export interface VehicleInfo {
    vehicleData?: VehicleData;
    brand: string;
    modelDescription: string;
    modelYear: string;
    vin: string;
    citivanVin: string;
    status: string;
}

export default class VehicleTypeService {
    async request(
        vin: string,
        locale: string,
        country: string
    ): Promise<VehicleType> {
        const params = {
            locale,
            country,
        };
        const headers = { ...HttpService.getAppIdRequestHeaders(), vin };
        const vehicleTypeUrl = DSL_BASE_URL + DSL_API_PATH.VEHICLE_TYPE;
        const response = HttpService.get<VehicleType>(vehicleTypeUrl, true, {
            params,
            headers,
        });
        response.catch(error => {
            console.error(error);
            return;
        });
        return response.then(this.parseResponse);
    }

    private parseResponse(response: AxiosResponse<VehicleType>): VehicleType {
        if (response.data) {
            return response.data;
        } else {
            return {
                isBev: false,
            };
        }
    }

    async vehicleInfo(vin: string, market: string): Promise<VehicleData> {
        const params = {
            market: market,
        };
        const url =
            DSL_BASE_URL +
            DSL_API_PATH.GPRS_VEHICLE_DATA +
            HttpService.buildQueryParams(params);
        const headers = { vin: vin };
        return HttpService.get<VehicleInfo>(url, true, { headers })
            .then(res => {
                const vehicleData: VehicleData = {
                    vin: res?.data?.vin,
                    year: res?.data?.modelYear,
                    make: res?.data?.brand,
                    model: res?.data?.modelDescription,
                    citivanVin: res?.data?.citivanVin == 'T' ? true : false,
                };
                return vehicleData;
            })
            .catch(err => {
                return ({
                    error: err?.message,
                } as unknown) as VehicleData;
            });
    }
}
