import React, { useEffect, useState } from 'react';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';
import './category-view-masthead.scss';
import { Article } from '../../../../../models/experiencefragments/category-view';
import { PrimaryButton } from '../../../../common';
import { Heading } from '../../../../common/heading/heading';
import { Modal } from '../../../../common/modal/modal';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { CategoryOnClickCtaInfo } from '../../../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';

interface Props extends Article {
    class?: string;
    categoryId?: string;
    showLogo?: boolean;
    leftLogoPath?: string;
    rightLogoPath?: string;
    cxCta?: any;
    eventName?: string;
    categoryPath?: string;
}

export const CategoryViewMasthead = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const [backgroundUrl, setBackgroundUrl] = useState<string>('');

    const isMobile = (): boolean => {
        return window.matchMedia('(max-width: 767px)').matches;
    };
    const [isQRCode, setQRCode] = useState(false);
    const mobileCTA = (): any => {
        if (isMobile() === true) {
            return props.cxCta.mobileCTALink;
        } else return '';
    };

    const onShowQRCode = () => {
        fireEvents(
            'image-recog-cta-onclick-event',
            undefined,
            undefined,
            false
        );
        if (isMobile() === false) {
            setQRCode(true);
        } else {
            setQRCode(false);
        }
    };

    const onCancel = () => {
        setQRCode(false);
    };

    const onClose = () => {
        setQRCode(false);
    };

    const handleWindowResize = () => {
        setBackgroundUrl(
            ServerSideService.isClientSide() && isMobile()
                ? encodeURI(props.mobileImagePath)
                : encodeURI(props.desktopImagePath)
        );
    };

    const eventClick = async (ctaLabel: string, ctaPath: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            ctaName: ctaLabel,
            categoryPath: props.categoryPath,
            ctaUrl: ctaPath,
        };
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (props.eventName) {
            fireEvents(props.eventName, undefined, {
                categoryOnClickCtaInfo,
            });
        }
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className="category-view-masthead-container">
            <section
                className={`category-view-masthead ${
                    props.class ? props.class : ''
                }`}
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${backgroundUrl})`,
                }}
            >
                <div className="category-view-masthead__wrapper">
                    {(props.headline || props.categoryId) && (
                        <h1 className="category-view-masthead__category-headline">
                            {props.headline || props.categoryId}
                        </h1>
                    )}
                    {props.title && (
                        <Heading
                            type={
                                props.headline || props.categoryId ? 'h2' : 'h1'
                            }
                            className="category-view-masthead__category-title"
                        >
                            {props.title}
                        </Heading>
                    )}
                    <div>
                        <p className="category-view-masthead__description">
                            {props.description}
                        </p>
                    </div>
                    {props.ctaPath && (
                        <div className="category-view-masthead__cta">
                            <PrimaryButton
                                href={props.ctaPath}
                                color="light"
                                fill="outline"
                                chevron={true}
                                ariaLabel={props.ctaAriaLabel}
                                target={props.targetBlank ? '_blank' : ''}
                                onClick={() =>
                                    eventClick(props.ctaLabel, props.ctaPath)
                                }
                            >
                                {props.ctaLabel}
                            </PrimaryButton>
                        </div>
                    )}
                    {props.cxCta && !props.cxCta.hideCTA && (
                        <>
                            <div className="category-view-masthead__cta">
                                <PrimaryButton
                                    href={mobileCTA()}
                                    color="light"
                                    fill="outline"
                                    chevron={true}
                                    ariaLabel={
                                        props.cxCta.mastheadButtonAriaLabel
                                    }
                                    target={props.targetBlank ? '_blank' : ''}
                                    onClick={() => {
                                        eventClick(
                                            props.cxCta.mastheadButtonName,
                                            mobileCTA()
                                        );
                                        onShowQRCode();
                                    }}
                                >
                                    {props.cxCta.mastheadButtonName}
                                </PrimaryButton>
                            </div>
                            <Modal
                                name="qr-code-model"
                                role="dialog"
                                aria-label={props.cxCta.desktopDialogAriaLabel}
                                isVisible={isQRCode}
                                onClose={onClose}
                                id="qr-code-model-id"
                            >
                                <div className="modal-text-center">
                                    <h2 className="qr-code-modal-header">
                                        {props.cxCta.desktopDialogTitle}
                                    </h2>
                                    <img
                                        style={{ height: '400px' }}
                                        src={
                                            process.env.REACT_APP_AEM_BASE_URL +
                                            props.cxCta.desktopQRCodeImagePath
                                        }
                                        alt={
                                            props.cxCta
                                                .desktopQRCodeImageAltText
                                        }
                                    ></img>
                                    <div className="qr-code-modal-description">
                                        {props.cxCta.desktopDialogDescription}
                                    </div>
                                    <PrimaryButton
                                        color="dark"
                                        fill="outline"
                                        chevron={true}
                                        ariaLabel={
                                            props.cxCta
                                                .desktopDialogCancelAriaLabel
                                        }
                                        target={
                                            props.targetBlank ? '_blank' : ''
                                        }
                                        onClick={() => onCancel()}
                                    >
                                        {props.cxCta.desktopDialogCancelText}
                                    </PrimaryButton>
                                </div>
                            </Modal>
                        </>
                    )}
                </div>
            </section>
            {props.showLogo && (
                <div className="category-view-masthead__logo">
                    <div className="logo">
                        <img
                            className="logo-left"
                            src={props.leftLogoPath}
                            alt=""
                        ></img>
                    </div>
                    <div className="line"></div>
                    <div className="logo">
                        <img
                            className="logo-right"
                            src={props.rightLogoPath}
                            alt=""
                        ></img>
                    </div>
                </div>
            )}
        </div>
    );
};
