import React from 'react';
import { VehicleChecklistContent } from '../../../models/experiencefragments/vehicle-checklist';
import VehicleChecklist from '../vehicle-checklist/vehicle-checklist';
import { useHistory } from 'react-router-dom';

interface Props {
    isYfaEligible: boolean;
}
const VehicleCheckListYFA = (props: Props & VehicleChecklistContent) => {
    const { isYfaEligible } = props;
    const history = useHistory();
    return (
        <>
            {isYfaEligible && (
                <VehicleChecklist
                    header={props.title}
                    type={props.cardTypes}
                    description={props.staticDescription}
                    ctaTitle={props.ctaTitle}
                    ctaPath={`${props.staticCtaPath}`}
                    iconPath={props.iconPath}
                    ctaAriaLabel={props.ctaAriaLabel}
                    ctaTargetBlank={props.ctaTargetBlank}
                    onCtaHrefClick={() => {
                        history.push('/yfa-optin', {
                            vin: sessionStorage.getItem('selectedVin'),
                        });
                    }}
                />
            )}
        </>
    );
};

export default VehicleCheckListYFA;
