import React from 'react';
import parse from 'html-react-parser';
import { useOSBContentFragment } from '../../../../hooks/owners-osb/aem-content-util/use-osb-content-fragment';
import { useViewport } from '../../../../hooks/owners-osb';
import { FMButton } from '@own/fds-react';
import './booking-confirmation.scss';

const BookingConfirmation: React.FC = () => {
    const { isMobileView } = useViewport();
    const osbContentData =
        useOSBContentFragment('common/osb-v3', 'booking-confirmation-view') ||
        [];

    const mobielImagePath = osbContentData?.contentImageFilePathForMobile || '';
    const desktopImagePath =
        osbContentData?.contentImageFilePathForDesktop || '';
    return (
        <div className="osbV3bookingConfirmationWrapper">
            <div className="bookingConfirmationContentWrapper">
                <div className="bookingConfirmationInnerContentWrapper">
                    <div className="bookingConfirmationtitleWrapper">
                        {osbContentData?.reservationConfirmationHeading || ''}
                    </div>
                    <div className="bookingConfirmationDetailsWrapper">
                        <div className="bookingConfirmationDescriptionWrapper">
                            <div className="bookingConfirmationDescriptionSubheading">
                                {osbContentData?.whatHappensNextSubheading}
                            </div>
                            <div className="bookingConfirmationDescription">
                                {parse(
                                    osbContentData?.whatHappensNextDescription?.toString() ||
                                        ''
                                )}
                            </div>
                        </div>
                        <div className="bookingConfirmationDivider"></div>
                        <div className="manageBookingWrapper">
                            <div className="manageBookingContentWrapper">
                                <div className="manageBookingHeadingWrapper">
                                    {osbContentData?.manageBookingSubheading}
                                </div>
                                <div className="manageBookingDescriptionWrapper">
                                    {parse(
                                        osbContentData?.manageBookingDescription?.toString() ||
                                            ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="manageBookingButtonWrapper">
                            <FMButton
                                type="primary"
                                label={osbContentData?.retrieveBookingCtaLabel}
                            />
                        </div>
                        <div className="bookingConfirmationDivider"></div>
                        <div className="fordAccountWrapper">
                            <div className="fordAccountSubheadingWrapper">
                                {osbContentData?.fordAccountSubheading}
                            </div>
                            <div className="fordAccountDescriptionWrapper">
                                {osbContentData?.fordAccountDescription}
                            </div>
                        </div>
                        <div className="fordAccountButtonWrapper">
                            <FMButton
                                type="primary"
                                label={osbContentData?.loginCtaLabel}
                            />
                            <p className="orDividerLabel">
                                {osbContentData?.orDividerLabel}
                            </p>
                            <FMButton
                                className="createAccountButton"
                                type="secondary"
                                label={osbContentData?.createAccountCtaLabel}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bookingConfirmationimageContentWrapper">
                <img
                    src={
                        isMobileView
                            ? `${process.env.REACT_APP_AEM_BASE_URL}${mobielImagePath}`
                            : `${process.env.REACT_APP_AEM_BASE_URL}${desktopImagePath}`
                    }
                    alt="Booking Confirmation"
                />
            </div>
        </div>
    );
};

export default BookingConfirmation;
