import React, {
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../../hooks/use-authentication';
import { charCheck } from '../../../../services/valid-vin-service/ValidVinService';
import { decodeVin } from '../../../../services/vin-decoder-service/VinDecoderService';
import localStorageWrapper from '../../../utils/local-storage-wrapper/localStorageWrapper';

import './vin-selector.scss';
import { useVinSelectorContent } from './hook/use-vin-selector';
import { RedirectData } from '../../../../support/components/FMALogin/FMALogin';
import { VinVehicleService } from '../../../../services/vin-vehicle-service/vin-vehicle-service';
import ServerContext from '../../../../contexts/serverContext';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import { buildPath, findByAlias } from '../../../../routes';
import { Modal } from '../../../common/modal/modal';
import { useConfirmModalContent } from '../hooks/use-confirm-modal-context';
import {
    notify,
    SlackMessages,
} from '../../../../services/slack-message-service/SlackMessageService';
import { DSL_API_PATH } from '../../../../constants';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { PageType } from '../../view-template/page-type';
import { ShortcodeProviders } from '../../../../services/shortcode-service/shortcode-service';
import { useBreakpoints } from '../../../../hooks/use-breakpoints';
import VinInput from '../../../common/vin-input/vin-input';
import { Heading } from '../../../common/heading/heading';
import { SyncMapsConsolidatedResponse } from '../../../../views/sync-maps-updates-view/hooks/use-syncs-maps-status-updates';
import { useExperienceContent } from '../../../../hooks/use-server-content';
import { CxYmm } from '../../../../models/experiencefragments/cx-ymm';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { pageTypesForH3 } from '../../../../support/support-constants';
import VehicleTypeService from '../../../../services/vehicle-type-service/vehicle-type-service';
import RecallsService from '../../../../services/recalls-service/recalls-service';
import { scrollToTop } from '../../vehicle-health/vha-utils';
import { VinSelectorButtons } from './vin-selector-buttons';

interface Props {
    vinHeaderLabel?: string;
    vinPlaceholderLabel: string;
    submitLabel: string;
    tooltip?: ReactNode;
    isFmaLogin?: boolean;
    redirectData?: RedirectData;
    callbackMethod?: (
        syncMapsConsolidatedResponse: SyncMapsConsolidatedResponse
    ) => void;
    eventName?: string;
    page?: PageType;
    whereDoIFindVinCta?: string;
    handleVinSubmit?: (vin: string) => void;
    selectedFmaVin?: string;
    findMyVinLabel?: string;
    findMyVinLink?: string;
    isTabs?: boolean;
    altLabel?: string;
}

const DEFAULT_CX_YEAR = '2022';

export const getVinFromLocalStorageIfExists = () => {
    let queryVin = null;
    if (serverSideService.isClientSide()) {
        queryVin =
            sessionStorage.getItem('QUERY_VIN') ||
            sessionStorage.getItem('selectedVin');
    }
    const vin = localStorageWrapper.getItem('USER_VIN') || queryVin;
    return vin || '';
};

const VINSelector = (props: Props) => {
    const breakpoints = useBreakpoints();
    const [dFlex, setdFlex] = useState<boolean | undefined>();
    const { handleVinSubmit } = props;
    useEffect(() => {
        if (props.page === 'SMASH' && breakpoints) {
            setdFlex(!breakpoints.mobile);
        }
    }, [props.page, breakpoints]);

    const [experienceContent] = useExperienceContent<CxYmm>(
        'img-cx-ymm',
        'cx-ymm',
        'img_cx_ymm'
    );
    const serverContext = useContext(ServerContext);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const [authenticationState, ,] = useAuthentication();
    const vinSelectorContent = useVinSelectorContent();
    const confirmModal = useConfirmModalContent();
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [vin, setVin] = useState(getVinFromLocalStorageIfExists());
    const [fireEvents] = useAnalytics();
    const inputFieldSubmitBtnref = useRef<HTMLButtonElement>(null);
    const onSubmit = (vin: string) => {
        if (confirmModal && !charCheck(vin)) {
            setModalVisible(!modalVisible);
            sessionStorage.removeItem('QUERY_VIN');
            return;
        }
        if (
            props.eventName &&
            props.eventName !== 'owner-dyf-landing-page-cta'
        ) {
            let shortcodeProviders: ShortcodeProviders = {
                ctaType: 'vin',
            };
            if (props.page && props.page === 'SMASH') {
                shortcodeProviders = {
                    smashHomeClick: {
                        smashHomeSearchContentCta: 'vin',
                    },
                };
            }
            fireEvents(props.eventName, undefined, shortcodeProviders, false);
        }
        localStorageWrapper.setItem('USER_VIN', vin);
        switch (props.redirectData?.id) {
            case 'recalls':
                sessionStorage.setItem('selectedVin', vin);
                new RecallsService()
                    .request(vin)
                    .then(() => {
                        if (props.redirectData) {
                            if (props.redirectData.path?.includes(':vin?')) {
                                history.push(
                                    props.redirectData.path.replace(
                                        ':vin?/',
                                        ''
                                    )
                                );
                            } else if (
                                props.redirectData.path?.includes(
                                    ':vinOrModel?/'
                                )
                            ) {
                                history.push(
                                    props.redirectData.path.replace(
                                        ':vinOrModel?/:year?/',
                                        ''
                                    )
                                );
                            } else {
                                history.push(props.redirectData.path);
                            }
                        }
                    })
                    .catch(error => {
                        new VehicleTypeService()
                            .request(
                                vin,
                                currentLanguageRegionCode,
                                current3LetterCountryCode?.toUpperCase()
                            )
                            .then(() => {
                                if (props.redirectData) {
                                    if (
                                        props.redirectData.path?.includes(
                                            ':vin?'
                                        )
                                    ) {
                                        window.location.href = `${currentRoot}${props.redirectData.path.replace(
                                            ':vin?/',
                                            ''
                                        )}`;
                                    } else if (
                                        props.redirectData.path?.includes(
                                            ':vinOrModel?/'
                                        )
                                    ) {
                                        window.location.href = `${currentRoot}${props.redirectData.path.replace(
                                            ':vinOrModel?/:year?/',
                                            ''
                                        )}`;
                                    } else {
                                        window.location.href = `${currentRoot}${props.redirectData.path}`;
                                    }
                                }
                            })
                            .catch(error => {
                                notify(
                                    SlackMessages.VIN_API_FAILURE +
                                        vin +
                                        ' for api ' +
                                        DSL_API_PATH.VEHICLE_TYPE
                                );
                                console.error('error', error.message);
                                setErrorMessage(vinSelectorContent?.invalidVin);
                            });
                        notify(
                            SlackMessages.VIN_API_FAILURE +
                                vin +
                                ' for api ' +
                                DSL_API_PATH.VEHICLE_TYPE
                        );
                        console.error('error', error.message);
                        setErrorMessage(vinSelectorContent?.invalidVin);
                    });
                break;
            case 'owner-manual':
                sessionStorage.setItem('QUERY_VIN', vin);
                window.location.href = `${currentRoot}${props.redirectData.path}`;
                break;
            case 'warranty-information':
                if (handleVinSubmit) {
                    handleVinSubmit(vin);
                }
                break;
            case 'recalls-details':
            case 'sync-maps':
                new VehicleTypeService()
                    .request(
                        vin,
                        currentLanguageRegionCode,
                        current3LetterCountryCode?.toUpperCase()
                    )
                    .then(() => {
                        props.handleVinSubmit && props.handleVinSubmit(vin);
                    })
                    .catch(error => {
                        console.error('error', error.message);
                        setErrorMessage(vinSelectorContent?.invalidVin);
                    });
                break;
            case 'owner-manual-details':
                props.handleVinSubmit && props.handleVinSubmit(vin);
                history.push(buildPath('OwnerManualWithVin'));

                scrollToTop();
                break;

            case 'learning-hub':
                new VinVehicleService(vin)
                    .getYearAndModel()
                    .then(vehicleData => {
                        if (
                            ['null', undefined, '{}', '""'].includes(
                                JSON.stringify(vehicleData)
                            )
                        ) {
                            throw new Error(
                                experienceContent?.apiFailureErrorMessage
                            );
                        }

                        if (props.redirectData) {
                            const model = vehicleData.getOwnerInformationByVinResult.model.toLowerCase();
                            const year =
                                vehicleData.getOwnerInformationByVinResult
                                    .modelYear;
                            let isSupportedModel = false,
                                isSupportedModelYear = false;
                            let modelIs = '',
                                yearIs = '';
                            experienceContent &&
                                experienceContent.modelYearInclude.forEach(
                                    modelYears => {
                                        if (modelYears.modelYear == year) {
                                            isSupportedModelYear = true;
                                            yearIs = experienceContent?.isDropdownBypassInHomePage
                                                ? DEFAULT_CX_YEAR
                                                : modelYears.modelYear;
                                            modelYears.supportedModels.forEach(
                                                supportedModel => {
                                                    if (
                                                        model.includes(
                                                            supportedModel.seoKey
                                                        )
                                                    ) {
                                                        isSupportedModel = true;
                                                        modelIs =
                                                            supportedModel.seoKey;
                                                    }
                                                }
                                            );
                                        }
                                    }
                                );
                            if (isSupportedModel && isSupportedModelYear) {
                                if (
                                    props.eventName ==
                                        'owner-dyf-landing-page-cta' &&
                                    vin
                                ) {
                                    fireEvents(
                                        props.eventName,
                                        undefined,
                                        {
                                            cxVehicle: {
                                                cxVinOrYmm: vin,
                                            },
                                        },
                                        false
                                    );
                                }

                                history.push(
                                    props.redirectData.path
                                        .replace(':model', modelIs)
                                        .replace(':year', yearIs)
                                );
                            } else if (
                                isSupportedModelYear &&
                                !isSupportedModel
                            ) {
                                throw new Error(
                                    experienceContent?.modelNameMismatch
                                );
                            } else {
                                throw new Error(
                                    experienceContent?.modelYearMismatch
                                );
                            }
                        }
                    })
                    .catch((err: AxiosError) => {
                        if (
                            err.message == experienceContent?.modelNameMismatch
                        ) {
                            setErrorMessage(
                                experienceContent?.modelNameMismatch
                            );
                        } else if (
                            err.message == experienceContent?.modelYearMismatch
                        ) {
                            setErrorMessage(
                                experienceContent?.modelYearMismatch
                            );
                        } else if (
                            err.code === 'ERR_NETWORK' ||
                            err.message ==
                                experienceContent?.apiFailureErrorMessage
                        ) {
                            setErrorMessage(
                                experienceContent?.apiFailureErrorMessage
                            );
                        } else {
                            setErrorMessage(vinSelectorContent?.invalidVin);
                        }
                    });
                break;
            case 'TSB':
                if (handleVinSubmit) {
                    handleVinSubmit(vin);
                }
                break;
            default:
                history.push(
                    findByAlias('VehicleVinView').replace(':vin', vin)
                );
                break;
        }
    };
    const validateVin = (vin?: string) => {
        setVin(vin || '');
        inputFieldSubmitBtnref.current?.click();
    };
    useEffect(() => {
        if (
            props.redirectData &&
            props.redirectData.id !== 'owner-manual-details'
        ) {
            if (sessionStorage.getItem('QUERY_VIN')) {
                if (errorMessage) sessionStorage.removeItem('QUERY_VIN');
                else validateVin(sessionStorage.getItem('QUERY_VIN') || '');
            }
        }
    }, [props.redirectData, errorMessage, vinSelectorContent]);
    useEffect(() => {
        if (props.redirectData?.id === 'sync-maps') {
            if (
                authenticationState === AuthenticationState.Authenticated &&
                vin !== props.selectedFmaVin &&
                props.selectedFmaVin !== ''
            ) {
                if (serverSideService.isClientSide()) {
                    sessionStorage.removeItem('QUERY_VIN');
                    localStorageWrapper.removeItem('USER_VIN');
                }
                setVin('');
                setErrorMessage('');
            }
        }
    }, [props.selectedFmaVin]);
    const type =
        props.redirectData?.id && pageTypesForH3.includes(props.redirectData.id)
            ? 'h3'
            : 'h2';
    const inputIsntFullVin = () => {
        const vinRegex = new AppConfigurationService().isRegionEU()
            ? /^[a-zA-Z0-9 -]+$/
            : /^[A-Za-z0-9]{17}$/;
        if (!vinRegex.test(vin) && props?.isTabs) {
            return true;
        }
        return false;
    };

    return (
        <div className="ymm-vin-container vin-container-tabs">
            <div
                className="ymm-vin-header"
                {...(props.vinHeaderLabel && {
                    'aria-labelledby': 'vin-label',
                })}
            >
                {props.vinHeaderLabel && (
                    <Heading
                        type={type}
                        id="vin-label"
                        className={`selector-label ${props.isFmaLogin &&
                            authenticationState ===
                                AuthenticationState.Authenticated &&
                            'flex-grow-1'}`}
                    >
                        {props.vinHeaderLabel}
                    </Heading>
                )}

                {props.tooltip && (
                    <div className="ymm-vin-tooltip">{props.tooltip}</div>
                )}
            </div>
            <div className={`ymm-vin-body ${dFlex ? 'd-flex' : ''}`}>
                <div
                    className={`vin-input-container ${dFlex ? 'd-flex' : ''}`}
                    aria-atomic={true}
                    aria-live="assertive"
                >
                    {vinSelectorContent && (
                        <VinInput
                            ref={inputFieldSubmitBtnref}
                            name="vin-selector"
                            vinSelectorContent={vinSelectorContent}
                            vin={vin}
                            setParentVin={setVin}
                            placeholder={props.vinPlaceholderLabel}
                            errorMessage={errorMessage}
                            setParentError={setErrorMessage}
                            onSubmit={onSubmit}
                            decodeVin={!confirmModal}
                            altLabel={props.altLabel}
                        />
                    )}
                    {confirmModal && (
                        <Modal
                            name={'badCharModal'}
                            role="dialog"
                            aria-label={confirmModal?.ariaLabel}
                            onClose={() => {
                                setModalVisible(!modalVisible);
                            }}
                            primaryBtnLabel={confirmModal?.primaryButtonLabel}
                            secondaryBtnLabel={
                                confirmModal?.secondaryButtonLabel
                            }
                            onPrimaryBtnClick={() => {
                                const decodedVin = decodeVin(vin);
                                onSubmit(decodedVin);
                                setModalVisible(!modalVisible);
                            }}
                            onSecondaryBtnClick={() => {
                                setModalVisible(!modalVisible);
                            }}
                            isVisible={modalVisible}
                            chevron={true}
                        >
                            <div className="modal-title">
                                {confirmModal?.title}
                            </div>

                            {confirmModal?.body.replace(
                                ':decodedVin',
                                decodeVin(vin)
                            )}
                        </Modal>
                    )}
                </div>
                <VinSelectorButtons
                    redirectData={props.redirectData}
                    submitLabel={props.submitLabel}
                    findMyVinLabel={props.findMyVinLabel}
                    findMyVinLink={props.findMyVinLink}
                    dFlex={dFlex}
                    errorMessage={errorMessage}
                    validateVin={validateVin}
                    vin={vin}
                    inputIsntFullVin={inputIsntFullVin}
                />
            </div>
            {props?.whereDoIFindVinCta && (
                <div
                    className="find-vin-cta"
                    dangerouslySetInnerHTML={{
                        __html: props?.whereDoIFindVinCta || '',
                    }}
                />
            )}
        </div>
    );
};

export default VINSelector;
