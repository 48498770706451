import { useContent } from '../../../../../hooks/use-server-content';

export interface PopularVideoProps {
    popularVideosTitle: string;
    popularVideosCtaTitle: string;
    popularVideosCta: string;
    popularVideosCtaAriaLabel: string;
}
export const usePopularVideosContent = (): PopularVideoProps | null => {
    const [content, getValueByName] = useContent('dyf', 'popular-videos');
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });

    return contentFragment as PopularVideoProps;
};
