import { PrimaryButton } from '../../../../common';
import printIcon from '../../../../../assets/print-icon.svg';
import { SuccessStatusResponse } from '../../../../../models/success-status-response';
import { ReactNode } from 'react';

interface RebateDetailsProps {
    rebatesStatus: any;
    apiResponse: SuccessStatusResponse;
    printCard: ReactNode | null;
    data: any;
    primaryButtonRef: React.RefObject<HTMLButtonElement>;
    handleRebatesPrintClick: (index: number) => void;
    handleRebateResponseClick: () => void;
}
export const RebateDetails = (props: RebateDetailsProps) => {
    return (
        props.rebatesStatus && (
            <>
                <div className="rebates-offer-print">{props.printCard}</div>
                <div className="whole-rebate-status-response">
                    <div className="rebate-status-response">
                        <h5 className="header-text" id="heading">
                            {props.rebatesStatus.rebateRequestedHeader}
                        </h5>
                        {props.apiResponse &&
                            props.apiResponse.submitStatusResponse.submitStatusRequestResult.anyType
                                .filter(
                                    (obj: any) =>
                                        obj.rebateTrackingNumber ===
                                        props.data.trackingNumber
                                )
                                .map((list: any, index: number) => {
                                    return (
                                        <>
                                            <hr />
                                            <div
                                                className="rebates-content"
                                                id={`rebates${index}`}
                                                key={list.rebateName}
                                            >
                                                <div className="rebates-print">
                                                    <button
                                                        className="print-button"
                                                        aria-label="Click to print offer"
                                                        onClick={() =>
                                                            props.handleRebatesPrintClick(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={printIcon}
                                                            alt=""
                                                            className="close-icon"
                                                        />
                                                    </button>
                                                </div>
                                                <h3 className="rewards-point">
                                                    {list.rebateName}
                                                </h3>
                                                <div className="rebates-row">
                                                    <div className="rebates-elementals">
                                                        <div className="rebatesRes-elements">
                                                            <div className="rebate-status">
                                                                {
                                                                    props
                                                                        .rebatesStatus
                                                                        .rebateRequestedStatus
                                                                }
                                                            </div>
                                                            <div
                                                                data-testid="rebate-status"
                                                                className="rebate-response"
                                                            >
                                                                {
                                                                    list.rebateStatus
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="rebatesRes-elements">
                                                            <div className="rebate-status">
                                                                {
                                                                    props
                                                                        .rebatesStatus
                                                                        .rebateRequestedDate
                                                                }
                                                            </div>
                                                            <div className="rebate-response">
                                                                {
                                                                    list.serviceDate
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="rebatesRes-elements">
                                                            <div className="rebate-status">
                                                                {
                                                                    props
                                                                        .rebatesStatus
                                                                        .rebateRequestedVin
                                                                }
                                                            </div>
                                                            <div className="rebate-response">
                                                                {list.vin}
                                                            </div>
                                                        </div>
                                                        <div className="rebatesRes-elements">
                                                            <div className="rebate-status">
                                                                {
                                                                    props
                                                                        .rebatesStatus
                                                                        .rebateRequestedDealer
                                                                }
                                                            </div>
                                                            <div className="rebate-response">
                                                                {
                                                                    list.dealerName
                                                                }
                                                                <br />
                                                                {
                                                                    list.dealerAddress
                                                                }
                                                                {list.dealerAddress.endsWith(
                                                                    '.'
                                                                )
                                                                    ? ' '
                                                                    : '. '}
                                                                {
                                                                    list.dealerCity
                                                                }
                                                                {', '}
                                                                {
                                                                    list.dealerState
                                                                }{' '}
                                                                {list.dealerZip}
                                                                <br />
                                                                {
                                                                    list.dealerPhone
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rebates-elementals">
                                                        <div className="rebatesRes-elements">
                                                            <div className="rebate-status">
                                                                {
                                                                    props
                                                                        .rebatesStatus
                                                                        .rebateRequestedServiceDate
                                                                }
                                                            </div>
                                                            <div className="rebate-response">
                                                                {
                                                                    list.rebateRequestDate
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="rebatesRes-elements">
                                                            <div className="rebate-status">
                                                                {
                                                                    props
                                                                        .rebatesStatus
                                                                        .rebateRequestedTrackingNumber
                                                                }
                                                            </div>
                                                            <div className="rebate-response">
                                                                {
                                                                    list.rebateTrackingNumber
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                        <div className="rebate-response-button">
                            <PrimaryButton
                                ref={props.primaryButtonRef}
                                chevron={true}
                                color="dark"
                                fill="fill"
                                type={'button'}
                                onClick={props.handleRebateResponseClick}
                            >
                                {
                                    props.rebatesStatus
                                        .rebateRequestedAnotherStatus
                                }
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </>
        )
    );
};
