import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import HttpService from '../http-service/http-service';

export interface JiraGetTicketStatusResponse {
    vin: string;
    jiraTicketId: string;
    jiraTicketLink?: string;
    summary: string;
    ticketCreationDate?: string;
    ticketLastModifiedDate?: string;
    ticketStatus: string;
    incidentType: string;
    modifiedBy?: string;
}

export default class JiraService {
    async getTicketStatus(vin: string): Promise<string> {
        const headers = {
            vin: vin,
        };
        const jiraDbUrl = DSL_BASE_URL + DSL_API_PATH.SYNC_HAS_JIRA_TICKET_OPEN;
        const response = HttpService.get<JiraGetTicketStatusResponse>(
            jiraDbUrl,
            true,
            {
                headers,
            }
        );
        response.catch(error => {
            console.error(error);
            return false;
        });
        return response.then(response => {
            return response.data.ticketStatus;
        });
    }
}
