import * as React from 'react';
import { useState, useEffect } from 'react';
import { Chevron, Link } from '../../../common';

import './subnav.scss';
import {
    Category,
    CategoryItem,
} from '../../../../models/experiencefragments/header';
import { PageLink } from '../page-link.interface';
import ServerSideService from '../../../../services/server-side-service/server-side-service';

type Props = {
    isHeader?: boolean;
    title?: string;
    drawers: Category[];
};

export const SubnavMobile = (props: Props) => {
    const [activeDrawer, setActiveDrawer] = useState('');
    const [size, setSize] = useState({
        height: window.innerHeight,
    });

    let isHeader,
        isItHeader = false;

    if ((props.title && props.isHeader === undefined) || props.isHeader) {
        isItHeader = true;
        isHeader = '';
    } else if (
        !props.title &&
        !props.isHeader &&
        props.isHeader !== undefined &&
        props.drawers
    ) {
        isItHeader = false;
        isHeader = '';
    } else {
        isHeader = 'hide-me';
    }
    function toggleLinks(drawer: Category) {
        if (drawer.categoryTitle === activeDrawer) {
            setActiveDrawer('');
        } else {
            setActiveDrawer(drawer.categoryTitle);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (ServerSideService.isClientSide()) {
                setSize({
                    ...size,
                    height: window.innerHeight,
                });
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const makeLinks = (links: PageLink[], showLinks: boolean, id: string) => (
        <ul
            data-testid={id}
            className={`links-container ${showLinks ? '' : 'hide-me'}`}
        >
            {links.map((link, linkIndex) => (
                <li key={linkIndex} className="subnav-link">
                    <Link
                        target={link.openInTab ? '_blank' : ''}
                        href={link.url}
                        aria-label={link.title}
                    >
                        {link.title}
                    </Link>
                </li>
            ))}
        </ul>
    );

    const makeImageLinks = (
        categoryItem: CategoryItem[],
        showLinks: boolean,
        id: string
    ) => {
        return (
            <div
                data-testid={id}
                className={`image-container ${showLinks ? '' : 'hide-me'}`}
            >
                {categoryItem.map((item, itemIndex) => (
                    <ul key={itemIndex}>
                        <li className="subnav-image">
                            <Link
                                target={item.targetBlank ? '_blank' : ''}
                                href={item.url}
                                aria-label={item.title}
                            >
                                <img
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        item.thumbnailImagePath
                                    }
                                    alt={item.title}
                                />
                            </Link>
                        </li>
                        <li className="title">{item.title}</li>
                        <li className="description">{item.description}</li>
                        <li className="price">{item.price}</li>
                        <li className="link">
                            <Link
                                target={item.targetBlank ? '_blank' : ''}
                                href={item.url}
                                aria-label={item.ariaLabel}
                            >
                                {item.urlText}
                            </Link>
                        </li>
                        <li className="link">
                            <Link
                                target={item.targetBlank1 ? '_blank' : ''}
                                href={item.url1}
                                aria-label={item.ariaLabel1}
                            >
                                {item.urlText1}
                            </Link>
                        </li>
                    </ul>
                ))}
            </div>
        );
    };

    function makeMobileDrawer(drawer: Category, index: number) {
        return (
            <div key={index} className="subnav-drawer">
                <button
                    key={index}
                    className={`subnav-title-container ${
                        activeDrawer === drawer.categoryTitle
                            ? 'selected-drawer'
                            : ''
                    }`}
                    aria-expanded={activeDrawer === drawer.categoryTitle}
                    onClick={() => {
                        toggleLinks(drawer);
                    }}
                >
                    <span className={'drawer-title'}>
                        {drawer.categoryTitle}
                    </span>
                    <Chevron
                        direction={
                            activeDrawer === drawer.categoryTitle
                                ? 'up'
                                : 'down'
                        }
                        selected={activeDrawer === drawer.categoryTitle}
                    />
                </button>
                {!drawer.categoryItems[0].urlText1 &&
                    !drawer.categoryItems[0].urlText &&
                    makeLinks(
                        drawer.categoryItems,
                        activeDrawer === drawer.categoryTitle,
                        `drawer-links-${index}`
                    )}
                {drawer.categoryItems[0].urlText1 &&
                    drawer.categoryItems[0].urlText &&
                    makeImageLinks(
                        drawer.categoryItems,
                        activeDrawer === drawer.categoryTitle,
                        `drawer-links-${index}`
                    )}
            </div>
        );
    }

    return (
        <div className={`sidebar-subnav ${isHeader}`}>
            {props.title && (
                <div className={'sidebar-subnav-title-container'}>
                    <span className={'sidebar-subnav-title'}>
                        {props.title}
                    </span>
                </div>
            )}
            <div
                className="subnav-drawers"
                style={{
                    maxHeight: isItHeader ? size.height - 150 : 'auto',
                    overflowY: isItHeader ? 'scroll' : 'visible',
                }}
            >
                {props.drawers.map(makeMobileDrawer)}
            </div>
        </div>
    );
};
