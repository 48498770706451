import { useContent } from '../../../../hooks/use-server-content';

export interface ServiceHistoryAddRecordContentProps {
    addService: string;
    dateLabel: string;
    businessNameLabel: string;
    serviceLocationLabel: string;
    garageBodyShop: string;
    other: string;
    selfService: string;
    servicesPerformedCategory: string;
    saveCtaLabel: string;
    cancelCtaLabel: string;
    mileageErrorMessage: string;
    emptyDateErrorMessage: string;
    futureDateErrorMessage: string;
    invalidDateErrorMessage: string;
    totalCostErrorMessage: string;
    currencySymbol: string;
    mileageUnit: string;
    termsAndConditionsError: string;
    privacyPolicyLink: string;
    safetyInspectionLabel: string;
    emissionsInspectionLabel: string;
    addSuccessMessage: string;
    addFailureMessage: string;
    editSuccessMessage: string;
    editFailureMessage: string;
    serviceLocationError: string;
    businessNameError: string;
    locationNameError: string;
    servicesPerformedError: string;
    inspectionsPerformedErrorMessage: string;
    totalCostDisclaimer: string;
    serviceLocationDropdownLabel: string;
    businessNameDropdownLabel: string;
    locationNameLabel: string;
}
export const useServiceHistoryAddRecordContent = (): ServiceHistoryAddRecordContentProps => {
    const [content, getValueByName] = useContent(
        'common',
        'service-history-add-record-form'
    );
    const contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    return contentFragment as ServiceHistoryAddRecordContentProps;
};
