import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../../../common/primary-button/primary-button';
import './horizontal-card.scss';
import ServerSideService from '../../../../../../services/server-side-service/server-side-service';
import { Article } from '../../../../../../models/experiencefragments/category-view';
import { Heading } from '../../../../../common/heading/heading';
import { CategoryOnClickCtaInfo } from '../../../../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../../../hooks/use-analytics';

export interface Props {
    categoryType?: string;
    categoryPath?: string;
}
export const HorizontalCard = (props: Article & Props) => {
    const [isMobile, setMobile] = useState<boolean>(
        ServerSideService.isClientSide()
            ? window.matchMedia('(max-width: 500px)').matches
            : false
    );
    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
        ctaName: props.ctaLabel,
        categoryType: props.categoryType,
        categoryPath: props.categoryPath,
        ctaUrl: props.ctaPath,
    };
    const [fireEvents] = useAnalytics();
    const handleWindowResize = () => {
        setMobile(
            ServerSideService.isClientSide()
                ? window.matchMedia('(max-width: 500px)').matches
                : false
        );
    };
    const eventClick = async () => {
        if (
            props.categoryType == 'category' ||
            props.categoryType == 'subcategory'
        ) {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                props.ctaPath
            );
            if (referralExit) {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            } else {
                fireEvents('category-onclick-event', undefined, {
                    categoryOnClickCtaInfo,
                });
            }
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <section className="horizontal-card">
            <div
                className="horizontal-card__image"
                style={{
                    backgroundImage: `url(${
                        process.env.REACT_APP_AEM_BASE_URL
                    }${
                        isMobile
                            ? props.mobileImagePath
                            : props.desktopImagePath
                    })`,
                }}
            />
            <div className="horizontal-card__content-wrapper">
                {props?.title && (
                    <Heading type="h2" className="horizontal-card__title">
                        {props.title}
                    </Heading>
                )}

                {props?.title && <hr className="horizontal-card__divider"></hr>}

                {props?.headline && (
                    <Heading
                        type={props?.title ? 'h3' : 'h2'}
                        className={
                            props?.title
                                ? 'horizontal-card__subtitle'
                                : 'horizontal-card__subtitle_h2'
                        }
                    >
                        {props.headline}
                    </Heading>
                )}
                <div className="horizontal-card__body">{props.description}</div>
                {props.ctaLabel && (
                    <PrimaryButton
                        chevron={true}
                        color="dark"
                        fill="fill"
                        className="horizontal-card__primary-button"
                        ariaLabel={props.ctaAriaLabel}
                        href={props.ctaPath}
                        target={props.targetBlank ? '_blank' : ''}
                        onClick={() => eventClick()}
                    >
                        {props.ctaLabel}
                    </PrimaryButton>
                )}
            </div>
        </section>
    );
};
