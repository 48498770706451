import React from 'react';
import { KEYBOARD_KEYS } from '../../../constants';
import './toggle-switch.scss';

interface Props {
    id?: string;
    className: string;
    onLabel: string;
    offLabel: string;
    checked?: boolean;
    onChange: (e: any) => void;
    onClick?: (e: any) => void;
    onKeyDown?: (e: any) => void;
    ariaLabel: string;
    name: string;
    value?: string;
    testId?: string;
    target?: string;
    ariadescribedBy?: string;
}

export const ToggleSwitch = (props: Props) => {
    const OnKeyDown = (event: any) => {
        if (event.key === KEYBOARD_KEYS.ENTER) {
            props.onChange(event);
        }
    };
    return (
        <div
            id={props.id}
            className={['toggle-switch', props.className].join(' ')}
        >
            {props.offLabel && (
                <label className="off-label">{props.offLabel}</label>
            )}
            <label className="switch">
                <input
                    id={props.name + '-toggle-field'}
                    data-testid="switch-text"
                    type="checkbox"
                    tabIndex={-1}
                    className={`checkbox ${props?.checked ? 'checked' : ''}`}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    onClick={props.onClick}
                    onKeyDown={props.onKeyDown}
                    checked={props.checked}
                />
                <div
                    className="slider"
                    role="switch"
                    aria-label={props.ariaLabel}
                    onClick={props.onChange}
                    aria-checked={props?.checked}
                    onKeyDown={OnKeyDown}
                    tabIndex={0}
                ></div>
            </label>
            {props.onLabel && (
                <label className="on-label">{props.onLabel}</label>
            )}
        </div>
    );
};
