import { AxiosRequestConfig } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import HttpService from '../http-service/http-service';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export interface DealerListResponse {
    dealerResultList: DealerResultList[];
}

export interface DealerResultList {
    unEncryptedDlrpa: number;
    dealerName: string;
    streetAddress: string;
    city: string;
    phone: string;
    state: string;
    zip: string;
    ownerAdvAwdsMember: boolean;
    creditMember: boolean;
}

export interface DecodeVinResponse {
    decodedVin: DecodedVin;
}

export interface DecodedVin {
    make: VinAttributes;
    modelYear: VinAttributes;
    model: VinAttributes;
    vinvalidationResult: VinvalidationResult;
}

export interface VinAttributes {
    attributeCode: string;
    attributeValue: string;
}

export interface VinvalidationResult {
    invalidVIN: boolean;
}

export default class RedeemRebateService {
    requestUrl = DSL_BASE_URL + DSL_API_PATH.DEALER_LIST;
    public async getByZipCode(zipCode: string): Promise<DealerListResponse> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url =
            this.requestUrl +
            `?client=myford&count=100&db=US&pc=${zipCode}&f_brandcode=1,2,3,5,7&f_country=USA&radius=50`;
        return HttpService.get<DealerListResponse>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }

    public async getByCityState(
        city: string,
        state: string
    ): Promise<DealerListResponse> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url =
            this.requestUrl +
            `?state=${state}&addr3=${city}&client=myford&count=100&db=US&f_brandcode=1,2,3,5,7&f_country=USA&radius=25`;
        return HttpService.get<DealerListResponse>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }

    public async getByDealerName(
        dealerName: string
    ): Promise<DealerListResponse> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url =
            this.requestUrl +
            `?client=myford&count=100&db=US&f_brandcode=1,2,3,5,7&f_busname=${dealerName}&f_country=USA&ovtype=2&radius=100`;
        return HttpService.get<DealerListResponse>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }

    public getByDealerCode(
        dealerCode: string[],
        count: number
    ): Promise<DealerListResponse> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };

        const url =
            this.requestUrl +
            `?client=myford&count=${count}&f_cupid=${dealerCode}&ovtype=2`;
        return HttpService.get<DealerListResponse>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }

    public async decodeAnyVin(vin: string): Promise<DecodeVinResponse> {
        const config: AxiosRequestConfig = {
            proxy: false,
            httpsAgent: proxyAgent,
        };
        if (vin) config.headers = { ...config?.headers, vin };

        const url = DSL_BASE_URL + DSL_API_PATH.VIN_VALIDATION;
        return HttpService.get<DecodeVinResponse>(url, true, config).then(
            response => {
                return response.data;
            }
        );
    }
}
