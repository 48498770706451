import React from 'react';
import FMActionItem from '../../common/fm-action-item/fm-action-item';
import { ActionItemProps } from '../../../models/experiencefragments/action-item';

interface Props {
    mmotaCallback?: (isVisible: boolean) => void;
}

const FMActionItemSoftwareUpdate = (props: Props & ActionItemProps) => {
    const { content, requiredLabel, recommendedLabel, mmotaCallback } = props;

    const onClick = () => {
        mmotaCallback && mmotaCallback(true);
    };

    return (
        <FMActionItem
            required={true}
            content={content}
            requiredLabel={requiredLabel}
            recommendedLabel={recommendedLabel}
            onClick={onClick}
        />
    );
};

export default FMActionItemSoftwareUpdate;
