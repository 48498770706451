import { useState } from 'react';
import {
    StoreState,
    StoreAction,
    StorePayload,
} from '../../models/osb-model/osb-store-details';

//initialized only once
let globalState = {} as StoreState;
//Actions that are dispatched by each component to transform the state.
let actions: StoreAction = {};

//This custom hook act as the global state store for all OSB stages
export const useStore = () => {
    const setGlobalState = useState(globalState)[1]; //initialize the state with default global state object

    const dispatch = (
        actionKey: string,
        payload: StorePayload,
        shouldReload = true,
        localStorageExpiry = 0
    ) => {
        //dispatches a action with action key and payload to update the current state and returns a new state
        const newGlobalState = actions[actionKey](
            globalState,
            payload,
            localStorageExpiry
        );

        globalState = { ...globalState, ...newGlobalState };
        //updates the global state with newly updated state
        if (shouldReload) {
            //Pass shouldReload has false if the state reload is not required
            setGlobalState(globalState);
        }
    };
    // console.log('globalState', globalState);
    return { globalState, dispatch }; //returns the current global state and a dispatch method to trigger the reducer function
};

// initializes the global store by the actions and initial state object provide by each component that uses this hook
export const initializeStore = (
    userActions: StoreAction,
    initialState: StoreState
) => {
    if (initialState) {
        globalState = { ...globalState, ...initialState };
    }
    actions = { ...actions, ...userActions };
};
