import { ExemptWordsListProps } from '../../../support/pages/recalls/hook/use-exempt-word-list';

export class StringUtil {
    public concatenate(delimiter: string, ...strValues: any[]): string {
        if (
            typeof strValues == 'undefined' ||
            strValues.length == 0 ||
            typeof delimiter == 'undefined'
        ) {
            return '';
        }
        let strOutput = '';
        for (let i = 0; i < strValues.length; i++) {
            if (i != strValues.length - 1) {
                strOutput += strValues[i] + delimiter;
            } else {
                strOutput += strValues[i];
            }
        }
        return strOutput;
    }

    private static isUpperCaseWord = (
        word: string,
        exemptList: string[]
    ): boolean => {
        return (
            exemptList?.some(exemptWord => {
                exemptWord.toLowerCase() === word;
            }) || /\d/.test(word)
        );
    };
    private static isCapitalWord = (
        word: string,
        exemptList: string[]
    ): boolean => {
        return exemptList.some(exemptWord => exemptWord.toLowerCase() === word);
    };
    public static sanitizeWord = (word: string) => {
        return word.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
    };

    public static sentenceCase(
        str: string,
        exemptWords: ExemptWordsListProps
    ): string {
        const sentences = str.split('. ');
        const transform = sentences?.map(sentence => {
            const words = sentence
                ?.split(' ')
                ?.filter(word => word?.length > 0);
            const wordCasing = words?.map((word, idx) => {
                const sanitizedWord = this.sanitizeWord(word);

                if (
                    this.isUpperCaseWord(
                        sanitizedWord,
                        exemptWords.exemptUpperCaseWords
                    )
                ) {
                    return word?.toUpperCase();
                } else if (
                    (idx === 0 && word?.length > 0) ||
                    this.isCapitalWord(
                        sanitizedWord,
                        exemptWords.exemptCapitalWords
                    )
                ) {
                    return this.capitalizeFirstLetter(word?.toLowerCase());
                } else {
                    return word.toLowerCase();
                }
            });
            return wordCasing?.join(' ');
        });
        return transform?.join('. ');
    }

    public static sentenceCaseWithoutSanitize(str: string): string {
        const sentences = str.split('. ');
        const transform = sentences?.map(sentence => {
            const words = sentence
                ?.split(' ')
                ?.filter(word => word?.length > 0);
            const wordCasing = words?.map((word, idx) => {
                if (idx === 0 && word?.length > 0) {
                    return this.capitalizeFirstLetter(word?.toLowerCase());
                } else {
                    return word.toLowerCase();
                }
            });
            return wordCasing?.join(' ');
        });
        return transform?.join('. ');
    }

    public static capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public static capitalizeModel = (str: string) => {
        return str
            .split(' ')
            .map(e => e.charAt(0).toUpperCase() + e.slice(1))
            .join(' ');
    };
    public static removeSpaces = (str: string) => {
        return str
            .toLowerCase()
            .replace(/[^a-zA-Z0-9- ]/g, '')
            .trim()
            .split(' ')
            .join('-');
    };
    public static camelCaseToWords = (input: string) => {
        const result = input.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    };

    public static isHTMLString(str: string) {
        const htmlPattern = /<\/?[a-z][\s\S]*>/i;
        return htmlPattern.test(str);
    }

    public static removeHyphen(input: string): string {
        return input
            .split('-')
            .map(word => word.charAt(0).toLocaleUpperCase() + word.slice(1))
            .join(' ');
    }
}
