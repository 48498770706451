import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';

export const getCtrAccordionContent = (
    syncOption: string,
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined,
    ctrCards: SyncMapsCard[] | undefined,
    syncMapContent: SyncMapStatus | null,
    experienceContent: SyncMapStatus | null,
    syncGeneration: string
) => {
    return {
        selectedOption: syncOption,
        hasCtr:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.hasCtr &&
            !experienceContent?.ctrHide &&
            experienceContent?.ctrHeading !== undefined,
        hasUpdate:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.hasFirmwareUpdate,
        syncGeneration,
        cards: ctrCards || [],
        heading: syncMapContent?.ctrHeading,
        title: syncMapContent?.ctrUpdateAvailable,
        subtitle: syncMapContent?.ctrUpdateAvailableDescription,
        radioButtons: true,
        upToDate: syncMapContent?.ctrIsUpToDate,
        syncMapContent: syncMapContent,
        downloadUrl:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.downloadUrl,
        downloadUrlError: !syncMapsUpdates?.syncMapUpdateResponse
            ?.syncUpdateResponse?.downloadUrl,
        notAvailable: syncMapContent?.notAvailableLabel,
        accordionType: 'ctr',
        eulaURL:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.eulaURL,
        error:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.syncError,
    };
};
