import React from 'react';
import { useTireFinderContent } from '../../hooks/use-tirefinder-banner-details';
import './tirefinder-banner-details.scss';

export const TireFinderDetails = () => {
    const TireFinderContent = useTireFinderContent();
    return (
        <div className="tire-finder-text">
            {TireFinderContent?.bannerTitle && (
                <h1 className="tire-finder-title">
                    {TireFinderContent?.bannerTitle}
                </h1>
            )}

            <div className="tire-finder-description">
                <div className="tire-finder-description-text">
                    {TireFinderContent?.bannerDescription}
                </div>
            </div>
        </div>
    );
};
