import React from 'react';
import { useAnalytics } from '../../../hooks/use-analytics';
import './radio-button.scss';
interface Props {
    value: string | number;
    label: string;
    checked: boolean;
    imageNode?: string;
    onChange?: (event: any) => void;
    eventName?: string;
    eventValue?: string;
    disabled?: boolean;
    dataTestId?: string;
}

const RadioButton = (props: Props) => {
    const [fireEvents] = useAnalytics();
    return (
        <label
            className={`radio-button-container${
                props.disabled ? ' disabled' : ''
            }`}
        >
            <input
                data-testid={props.dataTestId}
                type="radio"
                value={props.value}
                checked={props.checked}
                className="radio-button-input"
                onChange={props.onChange}
                onClick={() => {
                    if (props.eventName) {
                        fireEvents(
                            props.eventName,
                            undefined,
                            {
                                ctaType:
                                    props.eventValue || props.value.toString(),
                            },
                            false
                        );
                    }
                }}
                disabled={props.disabled}
            />
            <span className="radio-button-label">{props.label}</span>
            <div className="radio-button-image">
                {props.imageNode ? <img src={props.imageNode} alt="" /> : <></>}
            </div>
        </label>
    );
};

export default RadioButton;
