import { Carousel } from '../../../../common/carousel/carousel';
import { Card } from '../../../card/card';
import React from 'react';
import './trio-carousel.scss';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

interface Props {
    openInNewTabAriaLabel?: string;
    cards: FMCardProps[];
    dynamicCardId?: string;
    analyticsEventName?: string;
}
export const TrioCarousel = (props: Props) => {
    return (
        <Carousel
            items={props.cards}
            render={card => {
                return (
                    <Card
                        data={card}
                        analyticsClickEventName={props.analyticsEventName}
                        openInNewTabAriaLabel={props.openInNewTabAriaLabel}
                    />
                );
            }}
        />
    );
};
