import React, { ReactNode, useEffect, useState } from 'react';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import './osb-banner-lincoln.scss';
interface Props {
    applicationName?: string;
    webview?: boolean;
    children?: ReactNode;
}
const OSBBanner = (props: Props) => {
    const [top, setTop] = useState<number>(0);
    useEffect(() => {
        const handleTop = () => {
            setTop(0);
        };
        if (serverSideService.isClientSide()) {
            window.addEventListener('scroll', handleTop);
            window.addEventListener('resize', handleTop);
            return () => {
                window.removeEventListener('scroll', handleTop);
                window.removeEventListener('resize', handleTop);
            };
        }
    }, []);
    return (
        <>
            {props.applicationName && (
                <div
                    className={`osb-banner ${props.webview ? 'webview' : ''}`}
                    style={{ top }}
                    id="osbBannerId"
                >
                    <div className="leftSide">
                        <span>
                            {props.applicationName}
                            {props.children && (
                                <div className="banner-child">
                                    {props.children}
                                </div>
                            )}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};
export default OSBBanner;
