import { useContent } from '../../../../../hooks/use-server-content';

export interface RedeemRebateProps {
    rebatesPageTitle: string;
    rebatesFormDescriptionText: string;
    dateOfServicePlaceholderText: string;
    dateOfServiceErrorMessage: string;
    vinPlaceholderText: string;
    vinErrorMessage: string;
    invalidVinErrorMessage: string;
    vinHelpText: string;
    vinHelpTooltipText: string;
    searchByText: string;
    searchTabOption1: string;
    searchTabOption2: string;
    searchTabOption3: string;
    zipCodePlaceholderText: string;
    zipCodeErrorMessage: string;
    cityPlaceholderText: string;
    cityErrorMessage: string;
    statePlaceholderText: string;
    stateErrorMessage: string;
    dealerNamePlaceholderText: string;
    dealerNameErrorMessage: string;
    signInText: string;
    textAfterSignIn: string;
    clickButtonText: string;
    sigInUrl: string;
}

export const useRedeemRebateContent = (): RedeemRebateProps => {
    const [content, getValueByName] = useContent(
        'redeem-rebates/request-form-steps',
        'redeem-rebate'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (Object.entries(contentFragment).length === 0) {
        contentFragment = null;
    }
    return contentFragment as RedeemRebateProps;
};
