import { useEffect, useState } from 'react';

import { useExperienceContent } from '../../../../hooks/use-server-content';
import {
    AemArticle,
    Article,
} from '../../../../models/experiencefragments/category-view';

export const useDfyLivestream = (
    category: string,
    name: string,
    componentName: string,
    ymmServlet: boolean
): Article | null => {
    const [content, setContent] = useState<Article | null>(null);
    const [experienceContent] = useExperienceContent<AemArticle>(
        category,
        name,
        componentName,
        undefined,
        ymmServlet
    );
    useEffect(() => {
        if (experienceContent) {
            setContent(experienceContent);
        }
    }, [experienceContent]);
    return content;
};
