import React from 'react';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import HttpService from './services/http-service/http-service';
import { BRAND, REGIONAL_LOCALES } from './constants';
import { loadableReady } from '@loadable/component';
import { createRoot, hydrateRoot } from 'react-dom/client';

loadableReady(async () => {
    const context = (window as any).SERVER_CONTEXT;
    const baseForRouting: string | undefined = (window as any).baseForRouting;
    let routeBrand = window.location.pathname.substring(1).split('/')[0];
    let routeLocale = window.location.pathname.substring(1).split('/')[1];
    if (!Object.keys(BRAND).find(key => key === routeBrand))
        routeBrand = 'ford';
    if (!REGIONAL_LOCALES.includes(routeLocale)) routeLocale = 'en-us';
    // possible values window.baseForRouting can have are null/undefined, '/' or values like '/support/' etc.
    const docBase: string =
        baseForRouting && baseForRouting.length > 1
            ? baseForRouting
            : `/${routeBrand}/${routeLocale}`;
    if (context) {
        console.debug('Server Context--------', context);

        if (!context.applicationId) {
            const applicationIdKey = Buffer.from(
                HttpService.getAppIdRequestHeaders(context?.config)[
                    'Consumer-Key'
                ],
                'base64'
            ).toString('utf8');
            const applicationIdJson: any = (
                process.env.REACT_APP_APPLICATION_ID ?? ''
            )
                ?.split(',')
                .reduce((obj, item) => {
                    const [key, value] = item.split(':');
                    return { ...obj, [key?.trim()]: value?.trim() };
                }, {});
            context.applicationId = applicationIdJson[applicationIdKey];
        }
        const root = document.getElementById('root');
        if (root) {
            if (root.innerHTML !== '')
                hydrateRoot(
                    root,
                    <CookiesProvider>
                        <BrowserRouter basename={docBase}>
                            <App serverContext={context} />
                        </BrowserRouter>
                    </CookiesProvider>
                );
            else
                createRoot(root).render(
                    <CookiesProvider>
                        <BrowserRouter basename={docBase}>
                            <App serverContext={context} />
                        </BrowserRouter>
                    </CookiesProvider>
                );
        }
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
