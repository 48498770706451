import './vha-card.lincoln.scss';
import { FdsChevron } from '../../../../common/chevron/fds-chevron';
import { CardState } from '../../../../../models/service-maintenance-models/vehicle-health-alerts';

interface Props {
    icon: string;
    title: string;
    description: string;
    cardState: string;
    redirectUrl?: string;
    target?: string;
    handleVHACardClick?: () => void;
}

export const VHACard = (props: Props) => {
    const getCardStyle = () => {
        if (props.cardState === CardState.ALERT) {
            return 'vha-card-wrapper--alert';
        } else if (props.cardState === CardState.WARNING) {
            return 'vha-card-wrapper--warning';
        }
    };

    const handleCardClick = () => {
        props.handleVHACardClick?.();
    };
    return (
        <div
            className={`vha-card-wrapper ${getCardStyle()}`}
            onClick={handleCardClick}
        >
            <a
                href={props.redirectUrl}
                target={props.target}
                data-testid="testVhaCard"
                className="vha-card-link"
                aria-label="vehicle alert card"
            >
                <div className="card-content">
                    <div className="icon-title-wrapper">
                        <div className="card-icon" data-testid="testCardIcon">
                            <img
                                src={props.icon}
                                className="card-icon-style"
                                alt="vhaIcon"
                            />
                        </div>
                        <div className="card-title" data-testid="testCardTitle">
                            {props.title}
                        </div>
                    </div>
                    <div
                        className="card-description"
                        data-testid="testCardDesc"
                    >
                        {props.description}
                    </div>
                </div>
                <div className="arrow-right">
                    <FdsChevron type="unfilled" direction="right" />
                </div>
            </a>
        </div>
    );
};
