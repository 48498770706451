import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { Article } from '../../models/experiencefragments/category-view';
import { NewAnalyticsService } from '../../services/new-analytics-service/new-analytics-service';
import { URLUtil } from '../../components/utils/url-util/url-util';
import AppConfigurationService from '../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../constants';
import { transformRelativeUrlValidateRoot } from '../../components/utils/router-util/router-util';

export default class CategoryViewUtil {
    static readonly currentVersion =
        new AppConfigurationService().getAppConfiguration().brand ===
        BRAND.lincoln.LONG_NAME;

    static readonly updateFMCardDynamicCtaPath = (
        fMCardProps: FMCardProps[],
        root?: string
    ): FMCardProps[] => {
        return fMCardProps?.map((fmCard: FMCardProps) => {
            return {
                ...fmCard,
                ctaHref: transformRelativeUrlValidateRoot(
                    fmCard.ctaHref ?? '',
                    root ?? ''
                ),
            };
        });
    };
    static readonly MapArticlesToFMCardProps = (
        articles: Article[],
        root?: string
    ): FMCardProps[] => {
        return articles?.map((article: Article) => {
            return {
                title: article?.plainTitle,
                titleWithDisclaimer: article.title,
                description: article?.plainDescription,
                descriptionWithDisclaimer: article?.description,
                imageSrc: `${process.env.REACT_APP_AEM_BASE_URL}${article.desktopImagePath}`,
                imageAlt: article.imageAltText,
                ctaHref: transformRelativeUrlValidateRoot(
                    article?.ctaPath,
                    root ?? ''
                ),
                ctaLabel: article?.ctaLabel,
                ctaAriaLabel: article?.ctaAriaLabel,
                ctaType: 'primary',
                ctaTheme: 'light',
                borderless: true,
                ctaTarget: article.targetBlank ? '_blank' : '',
            } as FMCardProps;
        });
    };

    static readonly HandleFMCardClick = (
        item: FMCardProps,
        eventName: string,
        fireEvents: (
            eventName: string,
            data?: any,
            context?: any,
            clearDigitalData?: boolean
        ) => void,
        history: any
    ) => {
        return async () => {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                item.ctaHref ?? ''
            );
            eventName &&
                !referralExit &&
                fireEvents(
                    eventName,
                    undefined,
                    {
                        smashHomeClick: {
                            cardName: item.ctaLabel,
                        },
                    },
                    false
                );
            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                item.ctaHref ?? '',
                fireEvents
            );
            if (item.ctaHref && URLUtil.isRelativeUrl(item.ctaHref)) {
                history?.push(item.ctaHref);
            }
        };
    };
}
